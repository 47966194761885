import Gateway from "../gateway/gateway";

const addCategoryList = (params) => Gateway.post("/category", params);
const deleteCategory = (id) => Gateway.delete(`/category/${id}`);
const getDepartmentList = (params) => Gateway.get("/category", { params });

const getDepartmentPermissions = (id) => Gateway.get(`/permissions/${id}`);
const updateDepartment = ({ departmentId, permissions, name, description }) =>
  Gateway.patch(`/permissions/${departmentId}`, {
    name,
    description,
    permissions,
  });

export default {
  getDepartmentList,
  getDepartmentPermissions,
  deleteCategory,
  updateDepartment,
  addCategoryList,
};
