import PropTypes from "prop-types";
import { formatDateStructure } from "../../utils/utils";

const ContentLayout = ({ children, date }) => {
  return (
    <div className="task-details-wrapper d-flex">
      <div className="task-details-block d-flex align-items-center">
        {children}
      </div>
      <div className="task-date-block">{formatDateStructure(date)}</div>
    </div>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,
};

export default ContentLayout;
