import { createSlice } from "@reduxjs/toolkit";

export const name = "notification";

const createReducers = () => {
  const setNotificationData = (state, { payload }) => {
    state.notificationData = payload.data;
    state.successMessage = payload.successMessage;
  };
  const setNotificationUpdate = (state, { payload }) => {
    state.updateData = payload.data;
    state.errorMessage = "";
  };
  const setAllNotificationUpdate = (state, { payload }) => {
    state.updateData = payload.data;
    state.errorMessage = "";
  };
  const setLatestMessageData = (state, { payload }) => {
    state.latestNotificationData = { ...payload };
  };
  const clearLatestMessage = (state) => {
    state.latestNotificationData = null;
  };
  return {
    setNotificationData,
    setNotificationUpdate,
    setAllNotificationUpdate,
    setLatestMessageData,
    clearLatestMessage,
  };
};

function createInitialState() {
  return {
    notificationData: null,
    latestNotificationData: null,
    errorMessage: "",
    successMessage: "",
    updateData: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const notificationActions = slice.actions;
export const notificationReducer = slice.reducer;
