import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { projectActions } from "..";
import { alertActions } from "../../alert/index";
import { patchList } from "../../../../services/listService";
import { getUserProjects } from "./getUserProjects";

export const listPatch = createAsyncThunk(
  "list/patch",
  async function ({ id, patchData, project, list, dropIndex }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      if (project || list || dropIndex)
        dispatch(
          projectActions.patchListAction({
            data: { _id: id, ...patchData, project, list, dropIndex },
          }),
        );
      const {
        data: { message: successMessage },
      } = await patchList(id, patchData);
      dispatch(alertActions.success(successMessage));
      if (!dropIndex) dispatch(getUserProjects({}));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
