import { useEffect, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import CustomModal from "../../../../components/CustomModal";
import { useThunk } from "../../../../hooks/useThunk";
import { getDesignList, updateDesign } from "../../../../store";
import { alertActions } from "../../../../store/slices/alert";

const ImageCrop = ({
  showModal,
  closeModal,
  cropImage: image,
  designList,
  imageDetails,
  loading,
  image: setUrl,
}) => {
  // state
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  console.log("imageDetails", imageDetails);

  // action
  const [UpdateDesign, isLoading] = useThunk(updateDesign);
  const dispatch = useDispatch();
  const [DesignList] = useThunk(getDesignList);

  // reducer
  const { value: values } = useSelector((state) => state.alert);

  useEffect(() => {
    if (values?.type === "success") {
      closeModal();
      dispatch(alertActions.clear());
      DesignList();
    }
  }, [values]);

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        width,
        height,
      ),
      width,
      height,
    );
    setCrop(crop);
  };

  const ImageFile = (dataURL, fileName) => {
    const byteString = atob(dataURL.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: imageDetails?.file[0]?.type });
    // Convert the Blob to a File
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  };

  const onCropComplete = () => {
    if (image) {
      const imageElement = new Image();
      imageElement.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          imageElement,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height,
        );
        const croppedImageData = canvas.toDataURL();
        setUrl({ [imageDetails?.key]: croppedImageData });
        setCroppedImageUrl(
          ImageFile(croppedImageData, imageDetails?.file[0]?.name),
        );
      };
      imageElement.src = image;
    }
  };

  const renderHeader = () => (
    <h5 className="modal-title" id="exampleModalLabel">
      Image Crop
    </h5>
  );

  const renderBody = () => (
    <div className="crop-area-outer">
      <ReactCrop
        crop={crop}
        onChange={(c) => setCrop(c)}
        ruleOfThirds
        onImageLoaded={onImageLoad}
        onComplete={onCropComplete}
      >
        <img src={image} alt="" />
      </ReactCrop>
    </div>
  );

  const handleClick = () => {
    const formData = new FormData();
    formData.append(
      imageDetails?.key,
      croppedImageUrl || imageDetails?.file[0],
    );
    loading(imageDetails?.key);
    UpdateDesign({
      id: designList._id,
      data: formData,
    });
  };

  const renderFooter = () => (
    <div className="modal-footer border-0">
      <button
        type="button"
        className="btn btn-001 btn-002"
        onClick={handleClick}
      >
        {isLoading ? "Loading..." : "Save"}
      </button>
    </div>
  );

  return (
    <CustomModal
      className="modal fade modal-lg-custom show crop-modal-outer-main"
      dialgName="modal-lg"
      bodyClassname=""
      header={renderHeader()}
      body={renderBody()}
      footer={renderFooter()}
      createModal
      show={!!showModal}
      closeModal={closeModal}
      closeButton
    />
  );
};

ImageCrop.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.bool.isRequired,
  cropImage: PropTypes.isRequired,
  designList: PropTypes.isRequired,
  imageDetails: PropTypes.isRequired,
  loading: PropTypes.isRequired,
  image: PropTypes.isRequired,
};
export default ImageCrop;
