import PropTypes from "prop-types";
import editIcon from "../../../../assets/images/icon-edit.svg";
import deleteIcon from "../../../../assets/images/delete_icon.svg";
import okIcon from "../../../../assets/images/ok-icon-001.svg";
import CommonSkeleton from "../../../../components/SkeletonLoader/CommonSkeleton";
import Threedots from "../../../../components/DropDowns/Threedots";
import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";

function EmailTable({
  emailData,
  edit,
  delete: deletedetails,
  isLoading,
  hasPermission,
  isDefault,
}) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  let ActionButtons = [
    {
      name: "Edit",
      onclickfunction: edit,
      className: "edit-text-color",
      icon: editIcon,
      permission: permissionConstants.permissions.EMAIL_ACCOUNT_EDIT,
    },
    {
      name: "Delete",
      onclickfunction: deletedetails,
      className: "text-danger",
      icon: deleteIcon,
      permission: permissionConstants.permissions.EMAIL_ACCOUNT_DELETE,
    },
    {
      name: "Default",
      onclickfunction: isDefault,
      className: "edit-text-color",
      icon: okIcon,
      permission: permissionConstants.permissions.EMAIL_ACCOUNT_SET_DEFAULT,
    },
  ];

  let ActionButtonDefault = [
    {
      name: "Edit",
      onclickfunction: edit,
      className: "edit-text-color",
      icon: editIcon,
      permission: permissionConstants.permissions.EMAIL_ACCOUNT_EDIT,
    },
  ];

  if (hasPermission)
    ActionButtons = ActionButtons.filter(
      (e) => e.permission && hasPermission(e.permission),
    );

  if (hasPermission)
    ActionButtonDefault = ActionButtonDefault.filter(
      (e) => e.permission && hasPermission(e.permission),
    );

  // fix tooltip flickering
  const handleMouseEnter = () => {
    document.body.classList.add("tooltip-active");
  };

  const handleMouseLeave = () => {
    document.body.classList.remove("tooltip-active");
  };

  return (
    <div
      className="email-account-details row"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {emailData?.data?.map((data, index) => (
        <div className="email-bx-outer col-4" key={index}>
          <div className="email-bx">
            {isLoading ? (
              <div className="img-sec">
                <CommonSkeleton width={45} height={55} />
              </div>
            ) : (
              <div className="img-sec">
                <img src={data?.userPhoto} alt="" />
              </div>
            )}
            <div className="right-content-section">
              {isLoading ? (
                <h4>
                  {" "}
                  <CommonSkeleton width={65} height={12} />
                </h4>
              ) : (
                <h4>{data?.name}</h4>
              )}
              {isLoading ? (
                <p className="mail-txt">
                  <CommonSkeleton width={95} height={12} />
                </p>
              ) : (
                <p className="mail-txt">{data?.emailAddress}</p>
              )}
              {isLoading ? (
                <p className="date-added">
                  <CommonSkeleton width={135} height={12} />
                </p>
              ) : (
                <p className="date-added">
                  <span>Added on : </span>
                  <span>{formatDate(data?.createdAt)}</span>
                </p>
              )}
              {data.isDefault === true ? (
                <>
                  <div className="label_cover">
                    <div className="label-default">Default</div>
                  </div>
                  {!!ActionButtons.length && (
                    <Threedots
                      type={data?.name}
                      dropDownVisibility
                      TaskData={data}
                      ActionButtons={ActionButtonDefault}
                      className="more-option"
                    />
                  )}
                </>
              ) : (
                !!ActionButtons.length && (
                  <Threedots
                    type={data?.name}
                    dropDownVisibility
                    TaskData={data}
                    ActionButtons={ActionButtons}
                    className="more-option"
                  />
                )
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

EmailTable.propTypes = {
  emailData: PropTypes.isRequired,
  edit: PropTypes.func,
  delete: PropTypes.func,
  isDefault: PropTypes.func,
  hasPermission: PropTypes.func,
  isLoading: PropTypes.isRequired,
};

export default EmailTable;
