import { createAsyncThunk } from "@reduxjs/toolkit";

import { alertActions } from "../../alert/index";
import { getDesignationListService } from "../../../../services/employeeService";
import { designationList } from "../index";

export const getDesignationList = createAsyncThunk(
  "employee/List",
  async function (params, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data } = await getDesignationListService(params);
      // dispatch(alertActions.success(data.message));
      dispatch(designationList(data));
    } catch (error) {
      // const errorMessage = _.isString(error)
      //   ? error
      //   : error.response.data.message;
      // dispatch(cronjobActions.setErrorMesage(errorMessage));
      // dispatch(alertActions.error(errorMessage));
    }
  },
);
