export const DeleteIco = () => (
  <svg
    id="trash"
    xmlns="http://www.w3.org/2000/svg"
    width="18.063"
    height="18.063"
    viewBox="0 0 18.063 18.063"
  >
    <path
      id="Vector"
      d="M13.523.827l-.047,0C10.971.576,8.45.451,5.981.451A44.188,44.188,0,0,0,1.558.674L.022.825A.475.475,0,0,1-.5.4.475.475,0,0,1-.071-.121L1.464-.272A45.133,45.133,0,0,1,5.981-.5c2.5,0,5.053.127,7.588.379a.475.475,0,0,1-.046.948Z"
      transform="translate(2.282 4.149)"
      fill="#de4841"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M5.243,2.686a.475.475,0,0,1-.468-.4l-.166-.978a1.479,1.479,0,0,0-.2-.656A1.126,1.126,0,0,0,3.6.451H1.624a1.152,1.152,0,0,0-.817.2,1.5,1.5,0,0,0-.2.657l-.166.986a.475.475,0,0,1-.547.39.475.475,0,0,1-.39-.547l.166-.986C-.2.413-.051-.5,1.624-.5H3.6c1.682,0,1.838.957,1.952,1.656l.165.976a.476.476,0,0,1-.469.555Z"
      transform="translate(6.422 1.53)"
      fill="#de4841"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M7.547,10.129H2.715C.168,10.129.074,8.779-.01,7.587L-.5.006A.475.475,0,0,1-.055-.5a.475.475,0,0,1,.5.444L.939,7.523A2.447,2.447,0,0,0,1.21,8.757c.218.283.71.421,1.5.421H7.547c.795,0,1.287-.138,1.5-.421a2.453,2.453,0,0,0,.272-1.237L9.812-.055a.475.475,0,0,1,.5-.444.475.475,0,0,1,.444.5l-.489,7.579C10.188,8.779,10.093,10.129,7.547,10.129Z"
      transform="translate(3.901 6.903)"
      fill="#de4841"
    />
    <path
      id="Vector-4"
      data-name="Vector"
      d="M2.482.451H-.025A.475.475,0,0,1-.5-.025.475.475,0,0,1-.025-.5H2.482a.475.475,0,0,1,.475.475A.475.475,0,0,1,2.482.451Z"
      transform="translate(7.799 12.443)"
      fill="#de4841"
    />
    <path
      id="Vector-5"
      data-name="Vector"
      d="M3.738.451H-.025A.475.475,0,0,1-.5-.025.475.475,0,0,1-.025-.5H3.738a.475.475,0,0,1,.475.475A.475.475,0,0,1,3.738.451Z"
      transform="translate(7.174 9.432)"
      fill="#de4841"
    />
    <path
      id="Vector-6"
      data-name="Vector"
      d="M0,0H18.063V18.063H0Z"
      fill="none"
      opacity="0"
    />
  </svg>
);
export const EditIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.254"
    height="14.254"
    viewBox="0 0 14.254 14.254"
  >
    <g id="Group_60235" data-name="Group 60235" transform="translate(-2 -2)">
      <path
        id="Path_10621"
        data-name="Path 10621"
        d="M10.719,7.57l-.681,2.045a.718.718,0,0,0,.9.9l2.045-.681a.718.718,0,0,0,.278-.172l4.8-4.8a1.676,1.676,0,0,0-2.37-2.371l-4.8,4.8A.713.713,0,0,0,10.719,7.57Z"
        transform="translate(-2.3)"
        fill="#777"
      />
      <path
        id="Path_10622"
        data-name="Path 10622"
        d="M15.541,6.989a.713.713,0,0,0-.713.713V12.69a2.138,2.138,0,0,1-2.138,2.138H5.563A2.138,2.138,0,0,1,3.425,12.69V5.563A2.138,2.138,0,0,1,5.563,3.425h4.989a.713.713,0,1,0,0-1.425H5.563A3.568,3.568,0,0,0,2,5.563V12.69a3.568,3.568,0,0,0,3.563,3.563H12.69a3.568,3.568,0,0,0,3.563-3.563V7.7A.713.713,0,0,0,15.541,6.989Z"
        fill="#777"
      />
    </g>
  </svg>
);
export const SettingsIco = () => (
  <svg
    id="setting-2"
    xmlns="http://www.w3.org/2000/svg"
    width="17.964"
    height="17.964"
    viewBox="0 0 17.964 17.964"
  >
    <path
      id="Vector"
      d="M4.491,2.245A2.245,2.245,0,1,1,2.245,0,2.245,2.245,0,0,1,4.491,2.245Z"
      transform="translate(6.736 6.736)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M0,7.724V6.406A1.426,1.426,0,0,1,1.422,4.984c1.355,0,1.909-.958,1.228-2.133A1.421,1.421,0,0,1,3.174.912L4.468.171A1.249,1.249,0,0,1,6.175.62l.082.142a1.3,1.3,0,0,0,2.463,0L8.8.62A1.249,1.249,0,0,1,10.509.171L11.8.912a1.421,1.421,0,0,1,.524,1.939c-.681,1.175-.127,2.133,1.228,2.133a1.426,1.426,0,0,1,1.422,1.422V7.724a1.426,1.426,0,0,1-1.422,1.422c-1.355,0-1.909.958-1.228,2.133a1.42,1.42,0,0,1-.524,1.939l-1.295.741A1.249,1.249,0,0,1,8.8,13.509l-.082-.142a1.3,1.3,0,0,0-2.463,0l-.082.142a1.249,1.249,0,0,1-1.707.449l-1.295-.741a1.421,1.421,0,0,1-.524-1.939c.681-1.175.127-2.133-1.228-2.133A1.426,1.426,0,0,1,0,7.724Z"
      transform="translate(1.497 1.917)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M0,0H17.964V17.964H0Z"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const SearchNormalIcon = () => (
  <svg
    id="search-normal"
    xmlns="http://www.w3.org/2000/svg"
    width="11.621"
    height="11.621"
    viewBox="0 0 11.621 11.621"
  >
    <path
      id="Vector"
      d="M8.724,4.362A4.362,4.362,0,1,1,4.362,0,4.362,4.362,0,0,1,8.724,4.362Z"
      transform="translate(0.918 0.918)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M.918.918,0,0"
      transform="translate(9.642 9.642)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M0,0H11.02V11.02H0Z"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const HomeIcon = () => (
  <svg
    id="home"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Vector"
      d="M0,2V0"
      transform="translate(8 10)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M5.382.424l-4.62,3.7a2.249,2.249,0,0,0-.74,1.94l.887,5.307a2.127,2.127,0,0,0,2.027,1.713H10.4a2.139,2.139,0,0,0,2.027-1.713l.887-5.307a2.286,2.286,0,0,0-.74-1.94L7.955.431A2.15,2.15,0,0,0,5.382.424Z"
      transform="translate(1.331 1.456)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M0,0H16V16H0Z"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const ArrowRightIcon = () => (
  <svg
    id="arrow-right"
    xmlns="http://www.w3.org/2000/svg"
    width="13.156"
    height="13.156"
    viewBox="0 0 13.156 13.156"
  >
    <path
      id="Vector"
      d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
      transform="translate(4.884 2.236)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M0,0H13.156V13.156H0Z"
      transform="translate(13.156 13.156) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      id="vuesax_linear_document-text"
      data-name="vuesax/linear/document-text"
      transform="translate(-492 -252)"
    >
      <g id="document-text" transform="translate(492 252)">
        <path
          id="Vector"
          d="M12,3.333V10a2.99,2.99,0,0,1-3.333,3.333H3.333A2.99,2.99,0,0,1,0,10V3.333A2.99,2.99,0,0,1,3.333,0H8.667A2.99,2.99,0,0,1,12,3.333Z"
          transform="translate(2 1.333)"
          fill="none"
          stroke="#53575e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0V1.333A1.337,1.337,0,0,0,1.333,2.667H2.667"
          transform="translate(9.667 3)"
          fill="none"
          stroke="#53575e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H2.667"
          transform="translate(5.333 8.667)"
          fill="none"
          stroke="#53575e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H5.333"
          transform="translate(5.333 11.333)"
          fill="none"
          stroke="#53575e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M0,0H16V16H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
);

export const SubOneMenuLinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_58845" data-name="Group 58845" transform="translate(-304 -10)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="24"
        height="24"
        rx="8"
        transform="translate(304 10)"
        fill="#ac80f7"
      />
      <g id="start-up" transform="translate(310 16)">
        <path
          id="Path_22"
          data-name="Path 22"
          d="M73.186,282.856a.351.351,0,0,0,.065.405l.086.086c.248-.294.537-.612.887-.962.025-.025.05-.037.075-.06l-.552-.552C73.427,282.353,73.24,282.75,73.186,282.856Zm0,0"
          transform="translate(-71.439 -275.185)"
          fill="#fff"
        />
        <path
          id="Path_23"
          data-name="Path 23"
          d="M163.851,389.637c-.351.351-.669.64-.961.888l.116.116a.35.35,0,0,0,.408.064c.182-.094.467-.228,1.074-.566l-.578-.578c-.023.025-.035.05-.06.075Zm0,0"
          transform="translate(-159.082 -380.454)"
          fill="#fff"
        />
        <path
          id="Path_24"
          data-name="Path 24"
          d="M62.777,311.41c-.253-.253-.966-.026-1.3.309a9.327,9.327,0,0,0-1.853,2.383.351.351,0,0,0,.469.468,9.394,9.394,0,0,0,2.376-1.859c.368-.368.55-1.06.309-1.3Zm0,0"
          transform="translate(-58.195 -304.024)"
          fill="#fff"
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M1.1,164.624l-.992.992a.351.351,0,0,0,.339.587,2.882,2.882,0,0,1,1.325-.123,18.82,18.82,0,0,1,1.452-2.215,2.143,2.143,0,0,0-2.124.759Zm0,0"
          transform="translate(-0.001 -159.954)"
          fill="#fff"
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M245.833,379.623a.35.35,0,0,0,.406-.065l.992-.992a2.2,2.2,0,0,0,.729-2.074,20.021,20.021,0,0,1-2.216,1.441,4.3,4.3,0,0,1-.1,1.325A.35.35,0,0,0,245.833,379.623Zm0,0"
          transform="translate(-239.897 -367.69)"
          fill="#fff"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M284.951,137.616a1.052,1.052,0,1,0,1.488,0A1.053,1.053,0,0,0,284.951,137.616Zm0,0"
          transform="translate(-277.989 -134.098)"
          fill="#fff"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M390.657.343a.35.35,0,0,0-.324-.323,6.933,6.933,0,0,0-2.56.3,2.944,2.944,0,0,0,.872,1.713,2.935,2.935,0,0,0,1.747.871,7.252,7.252,0,0,0,.265-2.559Zm0,0"
          transform="translate(-378.707 -0.001)"
          fill="#fff"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M121.109,25.961A3.684,3.684,0,0,1,120.054,24a12.716,12.716,0,0,0-5.715,5.4l.791.791a1.257,1.257,0,0,1,1.752,1.752l.817.817c2.039-1.229,4.412-3.164,5.421-5.74a3.667,3.667,0,0,1-2.013-1.056Zm-.5,2.976a1.754,1.754,0,1,1,0-2.48A1.756,1.756,0,0,1,120.612,28.937Zm0,0"
          transform="translate(-111.666 -23.435)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export const FolderOpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.863"
    height="10.171"
    viewBox="0 0 11.863 10.171"
  >
    <g
      id="Icon_ionic-ios-folder-open"
      data-name="Icon ionic-ios-folder-open"
      transform="translate(-2.252 -4.5)"
    >
      <path
        id="Path_30"
        data-name="Path 30"
        d="M14.671,5.957a.584.584,0,0,0-.6-.609H8.921a.2.2,0,0,1-.162-.064l-.6-.6-.005-.005A.576.576,0,0,0,7.7,4.5H5.154a.63.63,0,0,0-.654.609V7.043H14.671Z"
        transform="translate(-1.401)"
        fill="#66bd50"
      />
      <path
        id="Path_31"
        data-name="Path 31"
        d="M3.1,13.219h-.22c-.339,0-.67.135-.622.644s.622,5.581.622,5.581c.072.471.31.662.662.662h9.336c.336,0,.556-.207.609-.662,0,0,.588-4.9.625-5.443s-.236-.781-.625-.781H3.1Z"
        transform="translate(0 -5.434)"
        fill="#66bd50"
      />
    </g>
  </svg>
);

export const ActionIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="4"
    viewBox="0 0 18 4"
  >
    <g
      id="Group_59398"
      data-name="Group 59398"
      transform="translate(-1886.226 -68)"
    >
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="2"
        cy="2"
        r="2"
        transform="translate(1886.226 68)"
        fill="#868991"
      />
      <circle
        id="Ellipse_5"
        data-name="Ellipse 5"
        cx="2"
        cy="2"
        r="2"
        transform="translate(1893.226 68)"
        fill="#868991"
      />
      <circle
        id="Ellipse_6"
        data-name="Ellipse 6"
        cx="2"
        cy="2"
        r="2"
        transform="translate(1900.226 68)"
        fill="#868991"
      />
    </g>
  </svg>
);

export const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.601"
    height="8.601"
    viewBox="0 0 8.601 8.601"
  >
    <path
      id="Icon_ionic-ios-add"
      data-name="Icon ionic-ios-add"
      d="M17.031,12.73H13.8V9.5a.535.535,0,1,0-1.071,0v3.23H9.5a.535.535,0,0,0,0,1.071h3.23v3.23a.535.535,0,1,0,1.071,0V13.8h3.23a.535.535,0,1,0,0-1.071Z"
      transform="translate(-8.965 -8.965)"
      fill="currentcolor"
    />
  </svg>
);

export const TaskSquareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.345"
    height="15.345"
    viewBox="0 0 15.345 15.345"
  >
    <g
      id="vuesax_linear_task-square"
      data-name="vuesax/linear/task-square"
      transform="translate(-492 -316)"
    >
      <g id="task-square" transform="translate(492 316)">
        <path
          id="Vector"
          d="M0,0H3.357"
          transform="translate(7.909 5.678)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,.959l.48.48L1.918,0"
          transform="translate(4.079 4.719)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H3.357"
          transform="translate(7.909 10.153)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,.959l.48.48L1.918,0"
          transform="translate(4.079 9.194)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M4.476,12.787H8.312c3.2,0,4.476-1.279,4.476-4.476V4.476C12.787,1.279,11.509,0,8.312,0H4.476C1.279,0,0,1.279,0,4.476V8.312C0,11.509,1.279,12.787,4.476,12.787Z"
          transform="translate(1.279 1.279)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M0,0H15.345V15.345H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
);

export const SubMenuLinkIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_58846"
      data-name="Group 58846"
      transform="translate(-20 -455.171)"
    >
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="24"
        height="24"
        rx="8"
        transform="translate(20 455.171)"
        fill="#ef934d"
      />
      <path
        id="Icon_awesome-project-diagram"
        data-name="Icon awesome-project-diagram"
        d="M7.407,6.172H4.938a.617.617,0,0,0-.617.617V9.259a.617.617,0,0,0,.617.617H7.407a.617.617,0,0,0,.617-.617V6.79A.617.617,0,0,0,7.407,6.172ZM3.7.617A.617.617,0,0,0,3.086,0H.617A.617.617,0,0,0,0,.617V3.086A.617.617,0,0,0,.617,3.7H2.464l1.411,2.47a1.23,1.23,0,0,1,1.063-.618h.005L3.7,3.385V2.469H8.024V1.234H3.7ZM11.728,0H9.259a.617.617,0,0,0-.617.617V3.086a.617.617,0,0,0,.617.617h2.469a.617.617,0,0,0,.617-.617V.617A.617.617,0,0,0,11.728,0Z"
        transform="translate(26 463.171)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const SubMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_58847"
      data-name="Group 58847"
      transform="translate(-20 -495.171)"
    >
      <rect
        id="Rectangle_3460"
        data-name="Rectangle 3460"
        width="24"
        height="24"
        rx="8"
        transform="translate(20 495.171)"
        fill="#66bd50"
      />
      <path
        id="Icon_awesome-project-diagram"
        data-name="Icon awesome-project-diagram"
        d="M7.407,6.172H4.938a.617.617,0,0,0-.617.617V9.259a.617.617,0,0,0,.617.617H7.407a.617.617,0,0,0,.617-.617V6.79A.617.617,0,0,0,7.407,6.172ZM3.7.617A.617.617,0,0,0,3.086,0H.617A.617.617,0,0,0,0,.617V3.086A.617.617,0,0,0,.617,3.7H2.464l1.411,2.47a1.23,1.23,0,0,1,1.063-.618h.005L3.7,3.385V2.469H8.024V1.234H3.7ZM11.728,0H9.259a.617.617,0,0,0-.617.617V3.086a.617.617,0,0,0,.617.617h2.469a.617.617,0,0,0,.617-.617V.617A.617.617,0,0,0,11.728,0Z"
        transform="translate(26 503.171)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const GraphIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.346"
    height="14.346"
    viewBox="0 0 14.346 14.346"
  >
    <g id="graph" transform="translate(-2 -2)">
      <path
        id="Path_10402"
        data-name="Path 10402"
        d="M6.3,2A4.3,4.3,0,0,0,2,6.3v5.738a4.3,4.3,0,0,0,4.3,4.3h5.738a4.3,4.3,0,0,0,4.3-4.3V9.412a.478.478,0,0,0-.956,0v2.63a3.347,3.347,0,0,1-3.347,3.347H6.3a3.347,3.347,0,0,1-3.347-3.347V6.3A3.347,3.347,0,0,1,6.3,2.956h5.738A3.347,3.347,0,0,1,15.389,6.3v.717a.478.478,0,1,0,.956,0V6.3a4.3,4.3,0,0,0-4.3-4.3Z"
        fill="#53575e"
      />
      <path
        id="Path_10403"
        data-name="Path 10403"
        d="M30.956,17.478a.478.478,0,1,0-.956,0v5.977a.478.478,0,0,0,.956,0Z"
        transform="translate(-21.305 -11.414)"
        fill="#53575e"
      />
      <path
        id="Path_10404"
        data-name="Path 10404"
        d="M18.956,24.478a.478.478,0,0,0-.956,0v4.3a.478.478,0,0,0,.956,0Z"
        transform="translate(-12.174 -16.74)"
        fill="#53575e"
      />
      <path
        id="Path_10405"
        data-name="Path 10405"
        d="M42.956,32.478a.478.478,0,0,0-.956,0v2.391a.478.478,0,1,0,.956,0Z"
        transform="translate(-30.436 -22.827)"
        fill="#53575e"
      />
    </g>
  </svg>
);

export const NotificationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      id="vuesax_linear_notification"
      data-name="vuesax/linear/notification"
      transform="translate(-171 -188)"
    >
      <g id="notification" transform="translate(171 188)">
        <path
          id="Vector"
          d="M5.364,0a4,4,0,0,0-4,4V5.927A3.186,3.186,0,0,1,.984,7.3L.217,8.573a1.279,1.279,0,0,0,.72,1.953,13.947,13.947,0,0,0,8.847,0,1.335,1.335,0,0,0,.72-1.953L9.737,7.3a3.274,3.274,0,0,1-.373-1.373V4A4.012,4.012,0,0,0,5.364,0Z"
          transform="translate(2.649 1.94)"
          fill="none"
          stroke="#53575e"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M2.467.84a4.036,4.036,0,0,0-.64-.133A4.5,4.5,0,0,0,0,.84a1.325,1.325,0,0,1,2.467,0Z"
          transform="translate(6.78 1.293)"
          fill="none"
          stroke="#53575e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M4,0A2.006,2.006,0,0,1,2,2,2.007,2.007,0,0,1,.587,1.413,2.007,2.007,0,0,1,0,0"
          transform="translate(6.013 12.707)"
          fill="none"
          stroke="#53575e"
          strokeWidth="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H16V16H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
);

export const InvLinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_75" data-name="Group 75" transform="translate(-1380 -855)">
      <g
        id="Ellipse_7"
        data-name="Ellipse 7"
        transform="translate(1380 855)"
        fill="none"
        stroke="currentcolor"
        strokeWidth="0.8"
      >
        <circle cx="12" cy="12" r="12" stroke="none" />
        <circle cx="12" cy="12" r="11.6" fill="none" />
      </g>
      <g
        id="vuesax_outline_user-add"
        data-name="vuesax/outline/user-add"
        transform="translate(1385.596 860.341)"
      >
        <g id="user-add" transform="translate(0 0)">
          <path
            id="Vector"
            d="M3.035,6.07A3.035,3.035,0,1,1,6.07,3.035,3.039,3.039,0,0,1,3.035,6.07Zm0-5.278A2.243,2.243,0,1,0,5.278,3.035,2.248,2.248,0,0,0,3.035.792Z"
            transform="translate(3.299 0.66)"
            fill="currentcolor"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M.4,4.487a.4.4,0,0,1-.4-.4C0,1.837,2.212,0,4.93,0A5.75,5.75,0,0,1,6.492.211a.394.394,0,1,1-.211.76A5,5,0,0,0,4.93.792C2.65.792.792,2.27.792,4.091A.4.4,0,0,1,.4,4.487Z"
            transform="translate(1.404 7.522)"
            fill="currentcolor"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M2.507,5.014A2.481,2.481,0,0,1,.834,4.365,2.26,2.26,0,0,1,.359,3.8,2.5,2.5,0,0,1,.707.76,2.5,2.5,0,0,1,4.37.834a2.5,2.5,0,0,1,.644,1.673,2.218,2.218,0,0,1-.079.591,2.25,2.25,0,0,1-.29.7A2.483,2.483,0,0,1,2.507,5.014Zm0-4.223A1.712,1.712,0,0,0,.792,2.507a1.672,1.672,0,0,0,.248.881,1.533,1.533,0,0,0,.322.391,1.691,1.691,0,0,0,1.145.449A1.719,1.719,0,0,0,3.975,3.4a1.644,1.644,0,0,0,.2-.48,1.535,1.535,0,0,0,.053-.406,1.711,1.711,0,0,0-.443-1.145A1.667,1.667,0,0,0,2.507.792Z"
            transform="translate(6.994 6.994)"
            fill="currentcolor"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M1.974.792H.4A.4.4,0,0,1,0,.4.4.4,0,0,1,.4,0H1.974a.4.4,0,0,1,.4.4A.4.4,0,0,1,1.974.792Z"
            transform="translate(8.319 9.095)"
            fill="currentcolor"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M.4,2.37a.4.4,0,0,1-.4-.4V.4A.4.4,0,0,1,.4,0a.4.4,0,0,1,.4.4V1.974A.4.4,0,0,1,.4,2.37Z"
            transform="translate(9.105 8.324)"
            fill="currentcolor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.457"
    height="10.458"
    viewBox="0 0 10.457 10.458"
  >
    <path
      id="close"
      d="M12.5,11.529l4.054-4.054a.688.688,0,1,0-.973-.973l-4.054,4.054L7.475,6.5a.688.688,0,0,0-.973.973l4.054,4.054L6.5,15.583a.688.688,0,1,0,.972.973L11.528,12.5l4.054,4.054a.688.688,0,0,0,.972-.973L12.5,11.529Z"
      transform="translate(-6.3 -6.3)"
      fill="#FF8079"
    />
  </svg>
);

export const AddDocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.07"
    height="13.93"
    viewBox="0 0 20.5 21.5"
  >
    <g id="add-document" transform="translate(-2.25 -1.25)">
      <path
        id="Path_4021"
        data-name="Path 4021"
        d="M7,2.75A3.25,3.25,0,0,0,3.75,6V18A3.25,3.25,0,0,0,7,21.25h5a.75.75,0,0,1,0,1.5H7A4.75,4.75,0,0,1,2.25,18V6A4.75,4.75,0,0,1,7,1.25h6.063a2.75,2.75,0,0,1,2.113.989l3.937,4.724a2.75,2.75,0,0,1,.637,1.761V12a.75.75,0,0,1-1.5,0V8.724a1.25,1.25,0,0,0-.29-.8L14.024,3.2a1.25,1.25,0,0,0-.96-.45Z"
        fill="currentcolor"
        fillRule="evenodd"
      />
      <path
        id="Path_4022"
        data-name="Path 4022"
        d="M22.75,19a.75.75,0,0,1-.75.75H16a.75.75,0,0,1,0-1.5h6A.75.75,0,0,1,22.75,19Z"
        fill="currentcolor"
        fillRule="evenodd"
      />
      <path
        id="Path_4023"
        data-name="Path 4023"
        d="M19,22.75a.75.75,0,0,1-.75-.75V16a.75.75,0,0,1,1.5,0v6A.75.75,0,0,1,19,22.75Z"
        fill="currentcolor"
        fillRule="evenodd"
      />
      <path
        id="Path_4024"
        data-name="Path 4024"
        d="M14,1.75a.75.75,0,0,1,.75.75V6A1.25,1.25,0,0,0,16,7.25h2.5a.75.75,0,0,1,0,1.5H16A2.75,2.75,0,0,1,13.25,6V2.5A.75.75,0,0,1,14,1.75Z"
        fill="currentcolor"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="7.9"
    viewBox="0 0 10.453 12.842"
  >
    <g
      id="Group_59899"
      data-name="Group 59899"
      transform="translate(-1844.273 -206)"
    >
      <path
        id="Path_45"
        data-name="Path 45"
        d="M13.508,5.7a.448.448,0,0,0-.448-.448H4.7A.448.448,0,0,0,4.25,5.7v8.064A1.941,1.941,0,0,0,6.191,15.7h5.376a1.941,1.941,0,0,0,1.941-1.941Zm-.9.448v7.616a1.046,1.046,0,0,1-1.045,1.045H6.191a1.046,1.046,0,0,1-1.045-1.045V6.146Z"
        transform="translate(1840.621 203.139)"
        fill="currentcolor"
        fillRule="evenodd"
      />
      <path
        id="Path_46"
        data-name="Path 46"
        d="M12.574,4.535a.448.448,0,0,0,.448-.448V2.893A1.642,1.642,0,0,0,11.379,1.25H8.393A1.642,1.642,0,0,0,6.75,2.893V4.087a.448.448,0,0,0,.448.448Zm-.448-.9H7.646V2.893a.746.746,0,0,1,.747-.747h2.987a.746.746,0,0,1,.747.747Z"
        transform="translate(1839.614 204.75)"
        fill="currentcolor"
        fillRule="evenodd"
      />
      <path
        id="Path_47"
        data-name="Path 47"
        d="M13.255,5.25H3.7a.448.448,0,0,0,0,.9h9.557a.448.448,0,1,0,0-.9Z"
        transform="translate(1841.023 203.139)"
        fill="currentcolor"
        fillRule="evenodd"
      />
      <path
        id="Path_48"
        data-name="Path 48"
        d="M8.75,10.2v4.181a.448.448,0,1,0,.9,0V10.2a.448.448,0,1,0-.9,0Z"
        transform="translate(1838.809 201.327)"
        fill="currentcolor"
        fillRule="evenodd"
      />
      <path
        id="Path_49"
        data-name="Path 49"
        d="M13.75,10.2v4.181a.448.448,0,1,0,.9,0V10.2a.448.448,0,0,0-.9,0Z"
        transform="translate(1836.795 201.327)"
        fill="currentcolor"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.319"
    height="9.186"
    viewBox="0 0 12.319 9.186"
  >
    <path
      id="Icon_awesome-check"
      data-name="Icon awesome-check"
      d="M4.184,13.583l-4-4a.616.616,0,0,1,0-.871l.871-.871a.616.616,0,0,1,.871,0l2.7,2.7L10.4,4.758a.616.616,0,0,1,.871,0l.871.871a.616.616,0,0,1,0,.871L5.055,13.583A.616.616,0,0,1,4.184,13.583Z"
      transform="translate(0 -4.577)"
      fill="#c7cad0"
    />
  </svg>
);

export const BranchIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.268"
    height="15.268"
    viewBox="0 0 15.268 15.268"
  >
    <g
      id="Group_60146"
      data-name="Group 60146"
      transform="translate(-869.119 -379.35)"
    >
      <g
        id="vuesax_bold_hierarchy"
        data-name="vuesax/bold/hierarchy"
        transform="translate(869.119 379.35)"
      >
        <g id="hierarchy" transform="translate(0 0)">
          <path id="Vector" d="M0,15.268H15.268V0H0Z" fill="none" opacity="0" />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M4.135,2.067A2.067,2.067,0,1,0,2.067,4.135,2.067,2.067,0,0,0,4.135,2.067Z"
            transform="translate(1.272 9.86)"
            fill="currentcolor"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M3.817,1.908A1.908,1.908,0,1,0,1.908,3.817,1.908,1.908,0,0,0,3.817,1.908Z"
            transform="translate(1.272 1.272)"
            fill="currentcolor"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M3.817,1.908A1.908,1.908,0,1,0,1.908,3.817,1.908,1.908,0,0,0,3.817,1.908Z"
            transform="translate(10.178 1.272)"
            fill="currentcolor"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M9.491,1.915a4.408,4.408,0,0,1-4.16,2.958H5.312L3.066,4.867A2.157,2.157,0,0,0,.973,6.451V7.621a.485.485,0,0,1-.49.483A.479.479,0,0,1,0,7.621V.483A.483.483,0,0,1,.483,0a.489.489,0,0,1,.49.483V4.695a3.143,3.143,0,0,1,2.087-.8h.013L5.318,3.9h.013A3.426,3.426,0,0,0,8.569,1.6a.5.5,0,0,1,.464-.331.462.462,0,0,1,.159.025A.489.489,0,0,1,9.491,1.915Z"
            transform="translate(2.704 3.187)"
            fill="currentcolor"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const CopyMainIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.97"
    height="14.22"
    viewBox="0 0 11.97 14.22"
  >
    <g
      id="Group_59366"
      data-name="Group 59366"
      transform="translate(-638.677 -665)"
    >
      <path
        id="copy"
        d="M7.527,14.22h-5.3A2.224,2.224,0,0,1,0,12V4.472A2.224,2.224,0,0,1,2.222,2.25h5.3A2.224,2.224,0,0,1,9.748,4.472V12A2.224,2.224,0,0,1,7.527,14.22ZM2.222,3.361A1.112,1.112,0,0,0,1.111,4.472V12a1.112,1.112,0,0,0,1.111,1.111h5.3A1.112,1.112,0,0,0,8.638,12V4.472A1.112,1.112,0,0,0,7.527,3.361Zm9.748,7.249V2.222A2.224,2.224,0,0,0,9.748,0H3.583a.555.555,0,0,0,0,1.111H9.748a1.112,1.112,0,0,1,1.111,1.111v8.388a.555.555,0,1,0,1.111,0Zm0,0"
        transform="translate(638.677 665)"
        fill="currentcolor"
      />
    </g>
  </svg>
);

export const ClipBoardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.073"
    height="13.932"
    viewBox="0 0 11.073 13.932"
  >
    <g
      id="Icon_ionic-ios-folder-open"
      data-name="Icon ionic-ios-folder-open"
      transform="translate(4.108 1.332)"
    >
      <g id="music-file" transform="translate(-4.108 -1.332)">
        <g id="Group_60102" data-name="Group 60102" transform="translate(0)">
          <g id="Group_60101" data-name="Group 60101">
            <path
              id="Path_10697"
              data-name="Path 10697"
              d="M52.767,3.875,48.981.106A.347.347,0,0,0,48.732,0H43.585A1.784,1.784,0,0,0,41.8,1.785V12.147a1.784,1.784,0,0,0,1.785,1.785h7.5a1.784,1.784,0,0,0,1.785-1.785V4.124A.355.355,0,0,0,52.767,3.875Zm-3.642-.3V1.252l2.517,2.5H49.3a.159.159,0,0,1-.178-.14Zm3.037,8.577a1.07,1.07,0,0,1-1.07,1.07H43.585a1.07,1.07,0,0,1-1.07-1.07V1.785a1.07,1.07,0,0,1,1.07-1.07H48.41V3.574a.893.893,0,0,0,.893.893h2.859Z"
              transform="translate(-41.8 0)"
              fill="currentcolor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CloseBranchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.394"
    height="11.263"
    viewBox="0 0 11.394 11.263"
  >
    <g
      id="Group_58851"
      data-name="Group 58851"
      transform="translate(-1612 -548.387)"
    >
      <g
        id="Group_60096"
        data-name="Group 60096"
        transform="translate(1612 548.388)"
      >
        <path
          id="Path_1459"
          data-name="Path 1459"
          d="M1.081,13.706a1.079,1.079,0,0,1-.766-.259,1.079,1.079,0,0,1,0-1.522L9.478,2.762A1.079,1.079,0,0,1,11.053,4.23L1.836,13.447a1.079,1.079,0,0,1-.755.259Z"
          transform="translate(0 -2.449)"
          fill="#FF807A"
        />
        <path
          id="Path_1460"
          data-name="Path 1460"
          d="M11.707,13.1a1.079,1.079,0,0,1-.756-.313L1.788,3.625A1.079,1.079,0,0,1,3.31,2.1l9.217,9.163a1.079,1.079,0,0,1,.05,1.526q-.024.026-.05.05A1.079,1.079,0,0,1,11.707,13.1Z"
          transform="translate(-1.474 -1.844)"
          fill="#FF807A"
        />
      </g>
    </g>
  </svg>
);

export const CommitIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.5"
    height="6"
    viewBox="0 0 12.5 6"
  >
    <g
      id="Group_60141"
      data-name="Group 60141"
      transform="translate(-601 -242)"
    >
      <g
        id="Ellipse_776"
        data-name="Ellipse 776"
        transform="translate(604 242)"
        fill="none"
        stroke="currentcolor"
        strokeWidth="1"
      >
        <circle cx="3" cy="3" r="3" stroke="none" />
        <circle cx="3" cy="3" r="2.5" fill="none" />
      </g>
      <line
        id="Line_148"
        data-name="Line 148"
        x1="3"
        transform="translate(601.5 245)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <line
        id="Line_149"
        data-name="Line 149"
        x1="3"
        transform="translate(610 245)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
export const TrashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g
      id="Group_60042"
      data-name="Group 60042"
      transform="translate(-1748 -184)"
    >
      <circle
        id="Ellipse_73"
        data-name="Ellipse 73"
        cx="13"
        cy="13"
        r="13"
        transform="translate(1748 184)"
        fill="#de4841"
        opacity="0.18"
      />
      <g id="Icon" transform="translate(1752.75 189.75)">
        <path
          id="Path_45"
          data-name="Path 45"
          d="M12.983,5.673a.423.423,0,0,0-.423-.423H4.673a.423.423,0,0,0-.423.423v7.606A1.831,1.831,0,0,0,6.081,15.11h5.071a1.831,1.831,0,0,0,1.831-1.831Zm-.845.423v7.184a.987.987,0,0,1-.986.986H6.081a.987.987,0,0,1-.986-.986V6.1Z"
          transform="translate(-0.437 -1.746)"
          fill="#fa4343"
          fillRule="evenodd"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M12.243,4.349a.423.423,0,0,0,.423-.423V2.8A1.549,1.549,0,0,0,11.117,1.25H8.3A1.549,1.549,0,0,0,6.75,2.8V3.926a.423.423,0,0,0,.423.423ZM11.821,3.5H7.6V2.8a.7.7,0,0,1,.7-.7h2.817a.7.7,0,0,1,.7.7Z"
          transform="translate(-1.528)"
          fill="#fa4343"
          fillRule="evenodd"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M12.687,5.25H3.673a.423.423,0,0,0,0,.845h9.015a.423.423,0,1,0,0-.845Z"
          transform="translate(0 -1.746)"
          fill="#fa4343"
          fillRule="evenodd"
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M8.75,10.173v3.944a.423.423,0,0,0,.845,0V10.173a.423.423,0,0,0-.845,0Z"
          transform="translate(-2.401 -3.711)"
          fill="#fa4343"
          fillRule="evenodd"
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M13.75,10.173v3.944a.423.423,0,0,0,.845,0V10.173a.423.423,0,0,0-.845,0Z"
          transform="translate(-4.584 -3.711)"
          fill="#fa4343"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export const ExpandIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Group_60271"
    data-name="Group 60271"
    width="8.947"
    height="9.092"
    viewBox="0 0 8.947 9.092"
  >
    <g id="right-arrow" transform="translate(0.894 6.004) rotate(-90)">
      <g id="Group_60269" data-name="Group 60269" transform="translate(0 0)">
        <path
          id="Path_10715"
          data-name="Path 10715"
          d="M5.957,3.285,2.708.047A.164.164,0,0,0,2.531.012a.162.162,0,0,0-.1.15V1.947H.162A.162.162,0,0,0,0,2.11v2.6a.162.162,0,0,0,.162.162H2.431V6.648a.163.163,0,0,0,.1.15.16.16,0,0,0,.062.012.164.164,0,0,0,.115-.047L5.957,3.515a.163.163,0,0,0,0-.23Z"
          fill="#a98dd9"
        />
      </g>
    </g>
    <path
      id="Path_10716"
      data-name="Path 10716"
      d="M0,0H8.947"
      transform="translate(0 8.792)"
      fill="#a98dd9"
      stroke="#a88dd9"
      strokeWidth="0.6"
      strokeDasharray="0.7 0.2"
    />
  </svg>
);

export const BlameIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="12"
    viewBox="0 0 88 7"
  >
    <g
      id="Group_69467"
      data-name="Group 69467"
      transform="translate(11987 -2272)"
    >
      <rect
        id="Rectangle_4204"
        data-name="Rectangle 4204"
        width="7"
        height="7"
        transform="translate(-11987 2272)"
        fill="#6f25ef"
      />
      <rect
        id="Rectangle_4205"
        data-name="Rectangle 4205"
        width="7"
        height="7"
        transform="translate(-11978 2272)"
        fill="#6d2def"
      />
      <rect
        id="Rectangle_4206"
        data-name="Rectangle 4206"
        width="7"
        height="7"
        transform="translate(-11969 2272)"
        fill="#6941f2"
      />
      <rect
        id="Rectangle_4207"
        data-name="Rectangle 4207"
        width="7"
        height="7"
        transform="translate(-11960 2272)"
        fill="#6750f4"
      />
      <rect
        id="Rectangle_4208"
        data-name="Rectangle 4208"
        width="7"
        height="7"
        transform="translate(-11951 2272)"
        fill="#6364f6"
      />
      <rect
        id="Rectangle_4209"
        data-name="Rectangle 4209"
        width="7"
        height="7"
        transform="translate(-11942 2272)"
        fill="#6075f8"
      />
      <rect
        id="Rectangle_4210"
        data-name="Rectangle 4210"
        width="7"
        height="7"
        transform="translate(-11933 2272)"
        fill="#5d87fa"
      />
      <rect
        id="Rectangle_4211"
        data-name="Rectangle 4211"
        width="7"
        height="7"
        transform="translate(-11924 2272)"
        fill="#599dfc"
      />
      <rect
        id="Rectangle_4212"
        data-name="Rectangle 4212"
        width="7"
        height="7"
        transform="translate(-11915 2272)"
        fill="#53b1fe"
      />
      <rect
        id="Rectangle_4213"
        data-name="Rectangle 4213"
        width="7"
        height="7"
        transform="translate(-11906 2272)"
        fill="#53bbff"
      />
    </g>
  </svg>
);

export const ContributorsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.98"
    height="11.273"
    viewBox="0 0 13.98 11.273"
  >
    <g id="users" transform="translate(-2 -4.711)">
      <path
        id="Path_10709"
        data-name="Path 10709"
        d="M19.309,9.374a2.331,2.331,0,1,0-2.331-2.331A2.334,2.334,0,0,0,19.309,9.374Zm1.332-2.331A1.332,1.332,0,1,1,19.309,5.71,1.334,1.334,0,0,1,20.641,7.043Z"
        transform="translate(-7.499)"
        fill="currentcolor"
      />
      <path
        id="Path_10710"
        data-name="Path 10710"
        d="M19.978,15.548a3.972,3.972,0,0,0-1.337.224.5.5,0,0,0-.3.638.493.493,0,0,0,.57.313.472.472,0,0,0,.068-.01,2.976,2.976,0,0,1,1-.166,3.166,3.166,0,0,1,3.171,3.171.694.694,0,0,1-.689.694H19.773a.477.477,0,0,0-.1.02.492.492,0,0,0-.4.479.5.5,0,0,0,.5.5h2.686a1.693,1.693,0,0,0,1.688-1.693,4.165,4.165,0,0,0-4.169-4.169Z"
        transform="translate(-8.166 -5.426)"
        fill="currentcolor"
      />
      <path
        id="Path_10711"
        data-name="Path 10711"
        d="M8.016,9.374A2.331,2.331,0,1,0,5.686,7.043,2.334,2.334,0,0,0,8.016,9.374Zm0-3.664A1.332,1.332,0,1,1,6.684,7.043,1.334,1.334,0,0,1,8.016,5.71Z"
        transform="translate(-1.845)"
        fill="currentcolor"
      />
      <path
        id="Path_10712"
        data-name="Path 10712"
        d="M3.689,21.409H8.654a1.691,1.691,0,0,0,1.689-1.689A4.171,4.171,0,1,0,2,19.72,1.691,1.691,0,0,0,3.689,21.409Zm2.482-4.862A3.177,3.177,0,0,1,9.344,19.72a.691.691,0,0,1-.69.69H3.689A.691.691,0,0,1,3,19.72,3.176,3.176,0,0,1,6.171,16.547Z"
        transform="translate(0 -5.426)"
        fill="currentcolor"
      />
    </g>
  </svg>
);

export const SubmitButtonIcon = () => (
  <svg width="13.539" height="13.539" viewBox="0 0 13.539 13.539">
    <g
      id="Group_59440"
      data-name="Group 59440"
      transform="translate(-1847.461 -191)"
    >
      <path
        id="Path_20"
        data-name="Path 20"
        d="M16.738,14.222H14.222v2.516H12.993V14.222H10.477V12.993h2.516V10.477h1.229v2.516h2.516Z"
        transform="translate(1840.623 184.162)"
        fill="currentcolor"
      />
      <path
        id="Path_21"
        data-name="Path 21"
        d="M10.144,4.742A5.4,5.4,0,1,1,6.324,6.324a5.38,5.38,0,0,1,3.821-1.582m0-1.367a6.769,6.769,0,1,0,6.769,6.769,6.768,6.768,0,0,0-6.769-6.769Z"
        transform="translate(1844.086 187.625)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
