import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import todoService from "../../../../services/todoService";
import { todoActions } from "../index";

export const getTodoCounts = createAsyncThunk(
  "todo-List",
  async function (todoId, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data } = await todoService.getTodoCount(todoId);
      dispatch(todoActions.setTodoCount(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(todoActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
