import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ticketService from "../../../../services/ticketService";
import { alertActions } from "../../alert";
import { setGetState, setSuccess } from "..";
import { getTicketAllocations } from "./getTicketAllocations";
import { getTicket } from "./getTicket";
import { getUserProjects } from "../../project/thunks/getUserProjects";

export const deleteTickets = createAsyncThunk(
  "delete/ticket",
  async ({ id, listId, key }, { dispatch }) => {
    try {
      const { data } = await ticketService.deleteTicketData(id);
      dispatch(setGetState(false));
      dispatch(
        setSuccess({
          key: "delete",
          success: data?.data?.ticketData[0],
          type: "Ticket",
        }),
      );
      dispatch(alertActions.success(data?.message));

      dispatch(getUserProjects({}));
      if (listId) {
        dispatch(
          getTicketAllocations({
            params: listId,
            key: key ?? "list",
            reset: true,
          }),
        );
        dispatch(getTicket(listId));
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
