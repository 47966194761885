import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import Select from "react-select";
import {
  oneLight,
  nightOwl,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { useSelector, useDispatch } from "react-redux";
import { useThunk } from "../../../../../hooks/useThunk";
import permissionConstants from "../../../../../utils/permissionUtils/permissionConstants";
import { alertActions } from "../../../../../store/slices/alert";
import copyTwoIcon from "../../../../../assets/images/copy-2.svg";
import copyOneIcon from "../../../../../assets/images/copy-1.svg";
import searchIcon from "../../../../../assets/images/search.svg";
import downloadArrowIcon from "../../../../../assets/images/download-arrow-svgrepo-com.svg";
import CommonSkeleton from "../../../../../components/SkeletonLoader/CommonSkeleton";
import folderGreyIcon from "../../../../../assets/images/folder-gray.svg";
import fileIcon from "../../../../../assets/images/folder-open.svg";
import { ActionIco, CloseIcon } from "./svgIcons";
import UserNoData from "../../../../UserProfile/UserNoData";
import { FolderLists } from "./FolderLists";
import { NestedFolderItem } from "./NestedFolderItem";
import {
  formatDate,
  handleDownload,
  viewRawCode,
  Base64ImageDisplay,
  processFolderStructure,
} from "./Utils";

import { getFolderStructures } from "../../../../../store/slices/gitIntegration/thunks/getFolderStructure";
import { BlameData } from "./BlameData";
import { getGitContents } from "../../../../../store/slices/gitIntegration/thunks/getGitContents";
import branchIcon from "../../../../../assets/images/branch.svg";

SyntaxHighlighter.registerLanguage("jsx", jsx);

export const FileManager = ({
  commonSl,
  setShowBlame,
  showBlame,
  hasPermission,
  setBranches,
  branches,
  setSelectedFile,
  selectedFile,
  setSelectedFolderPath,
  selectedFolderPath,
  setLocalContents,
  localContents,
  setSearchValue,
  searchValue,
  setDecodedContent,
  decodedContent,
  getAllBranchsData,
  isLoadingBranches,
}) => {
  const dispatch = useDispatch();

  const [getFolderandFiles, isLoadingFolders] = useThunk(getFolderStructures);
  const [isLoadingFolder, setIsLoadingFolders] = useState(isLoadingFolders);
  const [getGitFileContents, isLoadingContents] = useThunk(getGitContents);

  const { userProfile } = useSelector((state) => state.userProfile);

  const highlighterStyle = userProfile?.theme === "Dark" ? nightOwl : oneLight;
  const [searchOptions, setSearchOptions] = useState([]);
  const [userBranches, setUserBranch] = useState("");
  const [fileStructure, setFileStructure] = useState([]);
  const [folderContents, setFolderContents] = useState([]);
  const { gitContents } = useSelector((state) => state.github);
  const { gitFolders } = useSelector((state) => state.github);

  /* eslint-disable */
  const getItemsInPath = (items, path) => {
    const selectedItems = [];

    if (path === "/") {
      selectedItems.push({
        path: "/",
        mode: "040000",
        type: "tree",
        sha: "",
        url: "",
        commit: {
          message: "",
          dateTime: "",
        },
        sub: items,
      });
    } else {
      for (const item of items) {
        if (item?.path === path) {
          selectedItems.push(item);
        }

        if (item?.sub) {
          const nestedItems = getItemsInPath(item?.sub, path);
          selectedItems.push(...nestedItems);
        }
      }
    }

    return selectedItems;
  };
  /* eslint-enable */

  // fetch contents from branch
  useEffect(() => {
    setLocalContents(gitFolders?.folderStructure);
  }, [gitFolders]);

  const fileClose = () => {
    setDecodedContent("");
    setSelectedFile("");
  };

  // file folder structure

  const [openItems, setOpenItems] = useState([]);

  const handleFileClick = (path) => {
    setSelectedFolderPath(null);
    setDecodedContent("");
    getGitFileContents({
      repo: commonSl?.value,
      branch: userBranches,
      path,
      owner: commonSl?.owner,
      type: showBlame ? "blame" : "code",
    });
  };

  // saving file contents
  useEffect(() => {
    if (commonSl?.value) {
      setSelectedFile(gitContents?.githubContents);
    }
  }, [gitContents]);

  // decode file contents
  useEffect(() => {
    if (selectedFile?.content) {
      const decoded = atob(selectedFile?.content);
      setDecodedContent(decoded);
    } else setDecodedContent("");
  }, [selectedFile]);

  // selecting from search
  const handleItemClick = (option) => {
    if (option?.type === "tree") {
      setSelectedFolderPath(option?.value);
    } else if (option?.type === "blob") {
      handleFileClick(option?.label);
    }
  };

  // set branches
  useEffect(() => {
    if (commonSl === "") {
      setBranches([]);
    }
    setBranches(getAllBranchsData);
  }, [getAllBranchsData]);

  // options for branch select
  const listBranches = branches?.map((item) => ({
    value: item?.name,
    label: item?.name,
  }));

  // custom place holder for drop down
  const customPlaceholder = (
    <span className="icons">
      <img style={{ height: "12px" }} src={branchIcon} alt="Branch Icon" />{" "}
      Select a branch
    </span>
  );

  // arranging folder & file structure
  useEffect(() => {
    const processedData = processFolderStructure(localContents);
    setFileStructure(processedData);
    setIsLoadingFolders(false);
  }, [localContents]);

  // set items for selected folder
  useEffect(() => {
    if (selectedFolderPath) {
      const items = getItemsInPath(fileStructure, selectedFolderPath);

      setFolderContents(items);
    } else {
      setFolderContents([]);
    }
  }, [selectedFolderPath]);

  useEffect(() => {
    if (searchValue && commonSl?.value && userBranches) {
      const filteredOptions = localContents
        ?.filter((item) =>
          // eslint-disable-next-line
          item?.path.toLowerCase().includes(searchValue.toLowerCase()),
        )
        .map((item) => ({
          value: item?.path,
          label: item?.path,
          type: item?.type,
        }))
        .sort((a, b) => {
          if (a.type === "tree" && b.type !== "tree") return -1;
          if (a.type !== "tree" && b.type === "tree") return 1;
          return 0;
        });

      setSearchOptions(filteredOptions);
    }
  }, [searchValue]);

  const onChange = (key, value) => {
    if (key === "branch") {
      setFileStructure([]);
      setOpenItems([]);
      setUserBranch(value);
      setSelectedFile("");
    }
    if (!commonSl?.value) {
      dispatch(alertActions.error("Repository is not selected."));
      return;
    }
    setIsLoadingFolders(true);
    getFolderandFiles({
      repo: commonSl?.value,
      branch: value,
      owner: commonSl?.owner,
    });
  };

  useEffect(() => {
    if (showBlame && commonSl?.value && userBranches)
      getGitFileContents({
        repo: commonSl?.value,
        branch: userBranches,
        path: selectedFile?.path,
        owner: commonSl?.owner,
        type: "blame",
      });
  }, [showBlame]);
  const getCommitDateTime = (path) => {
    const commit = gitFolders?.folderStructure?.find(
      (item) => item?.path === path,
    )?.commit;

    if (commit) {
      return commit?.dateTime;
    }

    return null;
  };

  const handleSearchChange = (event) => {
    const selectedOption = event?.target?.value;
    setSearchValue(selectedOption);
  };

  const renderContent = () => {
    if (!selectedFile) {
      let customData = "No repository selected";

      if (commonSl?.value) {
        customData = userBranches ? "No data" : "No branch selected";
      }

      return <UserNoData customData={customData} />;
    }

    if (showBlame && selectedFile?.resultBlame) {
      return <BlameData bData={selectedFile?.resultBlame} />;
    }

    if (
      selectedFile?.name.endsWith(".jpeg") ||
      selectedFile?.name.endsWith(".jpg") ||
      selectedFile?.name.endsWith(".gif") ||
      selectedFile?.name.endsWith(".svg") ||
      selectedFile?.name.endsWith(".png") ||
      selectedFile?.name.endsWith(".ico")
    ) {
      return (
        <Base64ImageDisplay
          base64Content={selectedFile?.content}
          fileExtension={selectedFile?.name.split(".").pop()}
        />
      );
    }

    return (
      <SyntaxHighlighter
        className="coding"
        language="jsx"
        showLineNumbers
        style={highlighterStyle}
        wrapLines
      >
        {commonSl?.value && userBranches ? decodedContent : null}
      </SyntaxHighlighter>
    );
  };

  return (
    <div className="integration-area-content">
      <div className="integration-area-sidepanel">
        {hasPermission(
          permissionConstants.permissions.TASK_DETAILS_MODAL_BRANCHES,
        ) && (
          <div className="inp-block">
            <Select
              classNamePrefix="react-select"
              placeholder={customPlaceholder}
              className="react-select-container"
              options={commonSl?.value ? listBranches : []}
              onChange={(selectedBranch) =>
                onChange("branch", selectedBranch.value)
              }
              isLoading={isLoadingBranches}
              // menuIsOpen
            />
          </div>
        )}
        {hasPermission(
          permissionConstants.permissions.TASK_DETAILS_FILE_SEARCH,
        ) && (
          <div className="search-area">
            <input
              type="text"
              className="form-control"
              placeholder="Go to file"
              onChange={handleSearchChange}
            />
            <img src={searchIcon} className="search-icon" alt="" />
            {searchValue ? (
              <div className="suggestion-container custom-scroll ">
                {searchOptions.length > 0 ? (
                  searchOptions.map((option, index) => (
                    <div
                      key={index}
                      onClick={() => handleItemClick(option)}
                      className="suggestionItem"
                    >
                      {option.type === "blob" ? (
                        <img src={fileIcon} alt="File Icon" />
                      ) : (
                        <img src={folderGreyIcon} alt="Folder Icon" />
                      )}
                      {option.label}
                    </div>
                  ))
                ) : (
                  <div className="no-file-found">No matches found</div>
                )}
              </div>
            ) : null}
          </div>
        )}
        <div className="accordion custom-scroll" id="accordionExample">
          <div
            id="main-collapse"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body main-acco-list-view">
              <div className="accordion" id="sub-accordionExample">
                {isLoadingFolder && (
                  <>
                    {[1, 2, 3, 4, 5, 6].map((_, index) => (
                      <div key={index} style={{ marginBottom: "15px" }}>
                        <CommonSkeleton height={25} />
                      </div>
                    ))}
                  </>
                )}

                {!isLoadingFolder && commonSl?.value && userBranches && (
                  <div>
                    {fileStructure?.map((item, index) => (
                      <NestedFolderItem
                        key={index}
                        item={item}
                        openItems={openItems}
                        setOpenItems={setOpenItems}
                        handleFileClick={handleFileClick}
                        setSelectedFolderPath={setSelectedFolderPath}
                        setSelectedFile={setSelectedFile}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {hasPermission(
        permissionConstants.permissions.TASK_DETAILS_FILE_CONTENT_VIEW,
      ) && (
        <div className="integration-area-cntpanel">
          <div className="integration-area-top-head">
            {selectedFile?.name || selectedFolderPath ? (
              <ul className="header-sec-breadcrumb">
                {hasPermission(
                  permissionConstants.permissions.TASK_DETAILS_FILE_PATH,
                ) && (
                  <li>
                    {isLoadingContents ? (
                      <CommonSkeleton height={15} width={50} />
                    ) : (
                      <>
                        <span onClick={() => setSelectedFolderPath("/")}>
                          {commonSl?.value}
                        </span>
                        /{selectedFile ? selectedFile.path : selectedFolderPath}
                      </>
                    )}
                  </li>
                )}

                {hasPermission(
                  permissionConstants.permissions.TASK_DETAILS_FILE_PATH_COPY,
                ) && (
                  <li>
                    {isLoadingContents ? null : (
                      <button className="copybtn position-static" type="button">
                        <img
                          src={copyTwoIcon}
                          alt=""
                          onClick={() => {
                            const copyText = `${commonSl?.value}/${
                              selectedFile
                                ? selectedFile?.path
                                : selectedFolderPath
                            }`;

                            navigator.clipboard.writeText(copyText);
                            dispatch(
                              alertActions.success("Copied to clipboard."),
                            );
                          }}
                        />
                      </button>
                    )}
                  </li>
                )}
              </ul>
            ) : null}
            {selectedFolderPath ? null : (
              <div className="rgt-btn-grp">
                <button type="button" className="btn-sm-rds">
                  View Runs
                </button>
                <button type="button" className="btn-sm-rds">
                  <ActionIco />
                </button>
              </div>
            )}
          </div>
          {selectedFolderPath ? (
            <FolderLists
              folderContents={folderContents}
              handleFileClick={handleFileClick}
              setSelectedFolderPath={setSelectedFolderPath}
            />
          ) : (
            <>
              {selectedFile ? (
                <div className="sm-header-git">
                  <div className="avthar-sc-lft">
                    {selectedFile?.name ? (
                      <>
                        {hasPermission(
                          permissionConstants.permissions
                            .TASK_DETAILS_FILE_ONWER,
                        ) && (
                          <div className="avathar-round">
                            <img src={selectedFile?.avatarUrl} alt="" />
                          </div>
                        )}
                        <div className="cnt">
                          {selectedFile?.lastCommitAuthorName}{" "}
                          <span>
                            Update{" "}
                            {isLoadingContents ? (
                              <CommonSkeleton height={10} width={25} />
                            ) : (
                              selectedFile?.name
                            )}
                          </span>
                        </div>
                        {isLoadingContents ? null : (
                          <button
                            type="button"
                            className="cls"
                            onClick={fileClose}
                          >
                            <CloseIcon />
                          </button>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="right-side-control">
                    <ul className="list">
                      {hasPermission(
                        permissionConstants.permissions
                          .TASK_DETAILS_FILE_COMMIT_ID,
                      ) && <li>{selectedFile?.sha?.slice(0, 7)} · </li>}
                      <li>
                        {selectedFile && (
                          <span>
                            {formatDate(getCommitDateTime(selectedFile?.path))}
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}

              <div
                className={`gitdetials-cover ${
                  selectedFile ? "file-opened" : ""
                }`}
              >
                <div className="gitdetials-head">
                  <div className="lft-btngrp">
                    <div className="btn-group">
                      <button
                        type="button"
                        className={`btn-01 ${!showBlame ? "active" : ""}`}
                        onClick={() => setShowBlame(false)}
                      >
                        Code
                      </button>
                      <button
                        type="button"
                        className={`btn-01 ${showBlame ? "active" : ""}`}
                        onClick={() => setShowBlame(true)}
                      >
                        Blame
                      </button>
                    </div>

                    {selectedFile?.name ? (
                      <span className="info">{selectedFile?.size} Bytes</span>
                    ) : null}
                  </div>
                  {selectedFile ? (
                    <div className="btn-grp-sm">
                      <div
                        className="btn-group btn-group-sm "
                        role="group"
                        aria-label="..."
                      >
                        {" "}
                        {hasPermission(
                          permissionConstants.permissions
                            .TASK_DETAILS_FILE_RAW_VIEW,
                        ) &&
                          ![".png", ".jpg", ".jpeg"].some((extension) =>
                            selectedFile?.name
                              ?.toLowerCase()
                              .endsWith(extension),
                          ) && (
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => {
                                if (decodedContent) {
                                  viewRawCode(
                                    decodedContent,
                                    selectedFile?.name,
                                  );
                                }
                              }}
                            >
                              Raw
                            </button>
                          )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TASK_DETAILS_FILE_COPY,
                        ) &&
                          ![".png", ".jpg", ".jpeg"].some((extension) =>
                            selectedFile?.name
                              ?.toLowerCase()
                              .endsWith(extension),
                          ) && (
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => {
                                const copyText = decodedContent;
                                navigator.clipboard.writeText(copyText);
                                if (!selectedFile) {
                                  dispatch(
                                    alertActions.error("No file selected."),
                                  );
                                } else
                                  dispatch(
                                    alertActions.success(
                                      "Copied to clipboard.",
                                    ),
                                  );
                              }}
                            >
                              <img src={copyOneIcon} alt="" />
                            </button>
                          )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TASK_DETAILS_FILE_DOWNLOAD,
                        ) && (
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() =>
                              handleDownload(selectedFile, dispatch)
                            }
                          >
                            <img src={downloadArrowIcon} alt="" />
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="gitdetials-box custom-scroll">
                  {isLoadingContents ? (
                    <CommonSkeleton height={500} />
                  ) : (
                    renderContent()
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

FileManager.propTypes = {
  commonSl: PropTypes.shape({
    value: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
  }).isRequired,
  setBranches: PropTypes.func.isRequired,
  setShowBlame: PropTypes.func.isRequired,
  showBlame: PropTypes.bool.isRequired,
  hasPermission: PropTypes.func.isRequired,
  branches: PropTypes.string.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.string.isRequired,
  setSelectedFolderPath: PropTypes.func.isRequired,
  selectedFolderPath: PropTypes.string.isRequired,
  setLocalContents: PropTypes.func.isRequired,
  localContents: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setDecodedContent: PropTypes.func.isRequired,
  decodedContent: PropTypes.string.isRequired,
  getAllBranchsData: PropTypes.string.isRequired,
  isLoadingBranches: PropTypes.string.isRequired,
};
