import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import projectServices from "../../../../services/projectmanagement";
import { projectActions } from "..";
import { alertActions } from "../../alert/index";

export const getTicketById = createAsyncThunk(
  "project-id",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data: singleProject, message: successMessage },
      } = await projectServices.getTicketById(id);

      dispatch(
        projectActions.setSingleProject({
          singleProject,
          successMessage,
        }),
      );
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
