/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { reportActions } from "../index";
import reportService from "../../../../services/reportService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getReportDashboardPerformanceChart = createAsyncThunk(
  `get/reportDashboardPerformanceChart`,
  async ({ startDate, endDate, id }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await reportService.getReportDashboardPerformanceData({
        startDate,
        endDate,
        id,
      });

      dispatch(
        reportActions.setReportDashboardPerformanceChartData({
          data,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(reportActions.setReportErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
