import { createSlice } from "@reduxjs/toolkit";

export const name = "sprint";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.sprintData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const getSprint = (state, action) => {
    state.sprintData = action.payload;
  };
  const setSprint = (state, action) => {
    state.successMessage = action.payload;
  };
  const setTicketCount = (state, action) => {
    state.ticketCount = action.payload;
  };
  const setHolidays = (state, action) => {
    state.holidaysDetail = action.payload;
  };
  const setCompleteSprint = (state, action) => {
    state.completeSuccess = action.payload;
  };

  return {
    setSprint,
    setTicketCount,
    setErrorMesage,
    resetErrorMesage,
    getSprint,
    setHolidays,
    setCompleteSprint,
  };
};

function createInitialState() {
  return {
    sprintData: [],
    errorMessage: "",
    successMessage: "",
    ticketCount: {},
    holidaysDetail: [],
    completeSuccess: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const sprintActions = slice.actions;
export const sprintReducer = slice.reducer;
