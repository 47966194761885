export const DashboardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.6"
    height="10.237"
    viewBox="0 0 13.6 10.237"
  >
    <g id="list_1_" data-name="list (1)" transform="translate(0 -7.434)">
      <path
        id="Path_12"
        data-name="Path 12"
        d="M23.693,47.25H14.6a.679.679,0,1,1,0-1.357h9.094a.679.679,0,0,1,0,1.357Z"
        transform="translate(-10.771 -29.759)"
        fill="currentcolor"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M23.693,28.419H14.6a.679.679,0,1,1,0-1.357h9.094a.679.679,0,0,1,0,1.357Z"
        transform="translate(-10.771 -15.188)"
        fill="currentcolor"
      />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M23.693,9.588H14.6a.679.679,0,1,1,0-1.357h9.094a.679.679,0,0,1,0,1.357Z"
        transform="translate(-10.771 -0.617)"
        fill="currentcolor"
      />
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="0.911"
        cy="0.911"
        r="0.911"
        transform="translate(0 7.434)"
        fill="currentcolor"
      />
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="0.911"
        cy="0.911"
        r="0.911"
        transform="translate(0 11.641)"
        fill="currentcolor"
      />
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="0.911"
        cy="0.911"
        r="0.911"
        transform="translate(0 15.848)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
export const OverviewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.327"
    height="13.333"
    viewBox="0 0 13.327 13.333"
  >
    <path
      id="Vector"
      d="M9.46,0H3.873A3.558,3.558,0,0,0,0,3.873v5.58a3.56,3.56,0,0,0,3.873,3.88h5.58A3.558,3.558,0,0,0,13.327,9.46V3.873A3.55,3.55,0,0,0,9.46,0ZM5.273,9.46a.777.777,0,0,1-1.553,0V7.287a.777.777,0,0,1,1.553,0Zm4.34,0a.777.777,0,0,1-1.553,0V3.873a.777.777,0,0,1,1.553,0Z"
      fill="currentcolor"
    />
  </svg>
);
export const TeamsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.217"
    height="14.15"
    viewBox="0 0 16.217 14.15"
  >
    <g id="customer" transform="translate(0 -32.631)">
      <circle
        id="Ellipse_33"
        data-name="Ellipse 33"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(5.598 32.631)"
        fill="currentcolor"
      />
      <circle
        id="Ellipse_34"
        data-name="Ellipse 34"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(11.829 34.87)"
        fill="currentcolor"
      />
      <circle
        id="Ellipse_35"
        data-name="Ellipse 35"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(0.635 34.87)"
        fill="currentcolor"
      />
      <path
        id="Path_1446"
        data-name="Path 1446"
        d="M4.25,241.019a2.817,2.817,0,0,0-2.1-.487A2.147,2.147,0,0,0,0,242.669v3.459a.931.931,0,0,0,.932.928c2.218,0,1.951.04,1.951-.1C2.882,244.509,2.592,242.712,4.25,241.019Z"
        transform="translate(0 -201.312)"
        fill="currentcolor"
      />
      <path
        id="Path_1447"
        data-name="Path 1447"
        d="M125.24,239.887a4.7,4.7,0,0,0-3.627.858c-1.737,1.392-1.4,3.266-1.4,5.558a1.111,1.111,0,0,0,1.109,1.109c6.686,0,6.952.216,7.348-.662.13-.3.094-.2.094-3.043A3.84,3.84,0,0,0,125.24,239.887Z"
        transform="translate(-116.378 -200.656)"
        fill="currentcolor"
      />
      <path
        id="Path_1448"
        data-name="Path 1448"
        d="M379.91,240.533a2.815,2.815,0,0,0-2.1.487c1.646,1.68,1.368,3.355,1.368,5.942,0,.137-.222.1,1.917.1a.964.964,0,0,0,.965-.961V242.67A2.147,2.147,0,0,0,379.91,240.533Z"
        transform="translate(-365.844 -201.314)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
export const TechnologyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.066"
    height="17.032"
    viewBox="0 0 17.066 17.032"
  >
    <g
      id="Group_59679"
      data-name="Group 59679"
      transform="translate(497.999 -86.886)"
    >
      <path
        id="Path_9775"
        data-name="Path 9775"
        d="M-480.945,102.861c-.077-.069-.124-.108-.167-.15-.7-.695-1.39-1.393-2.092-2.082a.282.282,0,0,1-.077-.368,2.483,2.483,0,0,0-1.539-3.315A2.485,2.485,0,0,0-488,98.754a2.348,2.348,0,0,0,.8,2.438,2.367,2.367,0,0,0,2.556.422.341.341,0,0,1,.44.093c.65.664,1.313,1.316,1.971,1.973.062.062.12.127.21.224-.126.006-.211.013-.3.013h-14.663c-.717,0-1.015-.3-1.015-1.019q0-7.48,0-14.959c0-.765.292-1.053,1.068-1.053h6.978c-.069.083-.108.137-.154.184-.67.67-1.345,1.335-2.009,2.012a.317.317,0,0,1-.394.081,2.4,2.4,0,0,0-2.6.57,2.342,2.342,0,0,0-.609,2.539,2.348,2.348,0,0,0,2.031,1.681,2.353,2.353,0,0,0,2.324-.985,2.376,2.376,0,0,0,.272-2.48.327.327,0,0,1,.084-.435c1.01-.994,2.007-2,3.017-3a.587.587,0,0,1,.366-.164c1.924-.01,3.849-.009,5.773-.007a.848.848,0,0,1,.918.91q0,7.466,0,14.932C-480.933,102.757-480.938,102.784-480.945,102.861Zm-4.664-8.829a2.576,2.576,0,0,0,2.521-2.426,2.492,2.492,0,0,0-1.474-2.381,2.5,2.5,0,0,0-2.72.458,2.459,2.459,0,0,0-.606,2.727.253.253,0,0,1-.061.326q-2.1,2.088-4.2,4.187a.28.28,0,0,1-.354.068,2.411,2.411,0,0,0-1.356-.112,2.5,2.5,0,0,0-1.975,2.783,2.511,2.511,0,0,0,2.584,2.142,2.487,2.487,0,0,0,2.142-3.526.279.279,0,0,1,.062-.372q2.057-2.039,4.1-4.092a.264.264,0,0,1,.328-.066C-486.285,93.862-485.945,93.939-485.609,94.032Z"
        transform="translate(0)"
        fill="currentcolor"
      />
      <path
        id="Path_9776"
        data-name="Path 9776"
        d="M-282.99,301.285a.868.868,0,0,1-.852.866.877.877,0,0,1-.86-.862.866.866,0,0,1,.841-.849A.863.863,0,0,1-282.99,301.285Z"
        transform="translate(-201.734 -201.978)"
        fill="currentcolor"
      />
      <path
        id="Path_9777"
        data-name="Path 9777"
        d="M-426.412,157.088a.841.841,0,0,1-.855.855.838.838,0,0,1-.84-.841.838.838,0,0,1,.854-.855A.839.839,0,0,1-426.412,157.088Z"
        transform="translate(-66.103 -65.601)"
        fill="currentcolor"
      />
      <path
        id="Path_9778"
        data-name="Path 9778"
        d="M-283.867,157.945a.858.858,0,0,1-.851-.841.861.861,0,0,1,.867-.855.854.854,0,0,1,.846.843A.85.85,0,0,1-283.867,157.945Z"
        transform="translate(-201.719 -65.604)"
        fill="currentcolor"
      />
      <path
        id="Path_9779"
        data-name="Path 9779"
        d="M-428.107,301.28a.848.848,0,0,1,.863-.851.849.849,0,0,1,.832.856.857.857,0,0,1-.837.854A.856.856,0,0,1-428.107,301.28Z"
        transform="translate(-66.103 -201.967)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
export const DocsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.292"
    height="16.291"
    viewBox="0 0 16.292 16.291"
  >
    <g
      id="Group_59437"
      data-name="Group 59437"
      transform="translate(-1.629 -1.63)"
    >
      <path
        id="Vector"
        d="M16.023,5.906H0V3.6A3.6,3.6,0,0,1,3.6,0H5.49A2.335,2.335,0,0,1,7.763,1.14L8.9,2.656c.253.334.285.375.758.375h2.273A4.346,4.346,0,0,1,16.023,5.906Z"
        transform="translate(1.629 1.63)"
        fill="currentcolor"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M16.276,0l.016,4.806a4.363,4.363,0,0,1-4.358,4.358H4.358A4.363,4.363,0,0,1,0,4.806V0Z"
        transform="translate(1.629 8.757)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
export const MoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.135"
    height="12.134"
    viewBox="0 0 12.135 12.134"
  >
    <g
      id="Group_59678"
      data-name="Group 59678"
      transform="translate(-1.213 -1.215)"
    >
      <path
        id="Vector"
        d="M4.1,1.82H2.73V.455a.455.455,0,0,0-.91,0V1.82H.455a.455.455,0,0,0,0,.91H1.82V4.1a.455.455,0,0,0,.91,0V2.73H4.1a.455.455,0,0,0,0-.91Z"
        transform="translate(8.343 8.344)"
        fill="#9d9d9d"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M5.157,3.956V1.2C5.157.346,4.769,0,3.8,0H1.353C.388,0,0,.346,0,1.2V3.95c0,.862.388,1.2,1.353,1.2H3.8C4.769,5.157,5.157,4.811,5.157,3.956Z"
        transform="translate(8.191 1.215)"
        fill="#9d9d9d"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M5.157,3.956V1.2C5.157.346,4.769,0,3.8,0H1.353C.388,0,0,.346,0,1.2V3.95c0,.862.388,1.2,1.353,1.2H3.8C4.769,5.157,5.157,4.811,5.157,3.956Z"
        transform="translate(1.213 1.215)"
        fill="#9d9d9d"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M5.157,3.8V1.353C5.157.388,4.769,0,3.8,0H1.353C.388,0,0,.388,0,1.353V3.8c0,.965.388,1.353,1.353,1.353H3.8C4.769,5.157,5.157,4.769,5.157,3.8Z"
        transform="translate(1.213 8.191)"
        fill="#9d9d9d"
      />
    </g>
  </svg>
);
