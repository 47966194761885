import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getProjectsCounts } from "../../../../services/projectService";
import { projectActions } from "..";

// counts for doc,teams,technology

export const getProjectsTabCounts = createAsyncThunk(
  "projects/tabCounts",
  async (id, { dispatch }) => {
    try {
      const { data } = await getProjectsCounts(id);
      console.log("data", data);
      dispatch(projectActions.projectTabCounts(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
