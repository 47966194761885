import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import trashService from "../../../../services/trashService";
import { alertActions } from "../../alert";
import { getTrash } from "./getTrash";

export const deleteTrash = createAsyncThunk(
  "delete/trash",
  async ({ id, key, params }, { dispatch }) => {
    try {
      const {
        data: { message: successMessage },
      } = await trashService.deleteTrashData(id, key);
      dispatch(getTrash(params));
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
