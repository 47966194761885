import {
  Navigate,
  Outlet,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const PermissionRoutes = ({ permission, isSubModule, to }) => {
  const { userProfile } = useSelector((state) => state.userProfile);
  const { shortCode, listId, type } = useParams();
  const context = useOutletContext();

  if ((shortCode, listId, type)) {
    to = to ? `/project/${shortCode}/${type}/${listId}/${to}` : "/dashboard";
  }

  const hasPermission = isSubModule
    ? userProfile?.formattedPermissions?.some((e) =>
        e.permissions.some(({ subModule }) => subModule === permission),
      )
    : userProfile?.formattedPermissions?.find(
        ({ name }) => name === permission,
      );

  if (!userProfile)
    return (
      <div className="page-container">
        <div className="loader-container">
          <div className="loader" />
        </div>
      </div>
    );

  return hasPermission ? (
    <Outlet context={context && [...context]} />
  ) : (
    <Navigate to={to ?? "/"} replace />
  );
};

PermissionRoutes.propTypes = {
  permission: PropTypes.string,
  isSubModule: PropTypes.bool,
  to: PropTypes.string,
};

export default PermissionRoutes;
