import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { getUserProjects } from "./getUserProjects";
import { postFolder } from "../../../../services/folderService";
import { projectActions } from "..";

export const folderCreate = createAsyncThunk(
  "folder/create",
  async function ({ postData, navigate }, { dispatch }) {
    dispatch(alertActions.clear());
    dispatch(
      projectActions.setFolderCreateSuccessMessage({ successMessage: null }),
    );
    try {
      const response = await postFolder(postData);
      const { message: successMessage, data } = response.data;
      dispatch(
        projectActions.setFolderCreateSuccessMessage({ successMessage }),
      );
      dispatch(alertActions.success(successMessage));
      if (navigate && data?.listData[0]?._id) navigate(data.listData[0]._id);
      dispatch(getUserProjects({}));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
