import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { getMeetingService } from "../../../../services/meetingService";
import { alertActions } from "../../alert/index";
import { meetingScheduleActions } from "..";

export const getMeetingData = createAsyncThunk(
  "meeting-get",
  async function ({ params, reset }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await getMeetingService(params);
      dispatch(meetingScheduleActions.setMeetingSchedule({ data, reset }));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(meetingScheduleActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
