import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../index";
import { removeItem } from "../../../../helpers/localStorage";

const auth_doLogout = createAsyncThunk(
  "auth/logout",
  function (payload, { dispatch }) {
    dispatch(
      authActions.setAuth({
        userProfile: null,
        auth: null,
        successMessage: "",
        isLoggedIn: false,
      }),
    );
    removeItem("accessToken");
  },
);

export { auth_doLogout };
