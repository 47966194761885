import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
import {
  DashboardIcon,
  TeamsIcon,
  TechnologyIcon,
  DocsIcon,
} from "./navbar-icons";

const menuItems = {
  componentNavbar: [
    {
      id: 1,
      name: "Dashboard",
      key: "Dashboard",
      path: "",
      hasPermission: permissionConstants.submodules.PROJECT_DASHBOARD,
      svg: <DashboardIcon />,
    },
    {
      id: 2,
      name: "Teams",
      key: "Teams",
      path: "teams",
      hasPermission: permissionConstants.submodules.PROJECT_MANAGEMENT_TEAMS,
      svg: <TeamsIcon />,
    },
    {
      id: 3,
      name: "Technology",
      key: "technology",
      path: "technology",
      hasPermission:
        permissionConstants.submodules.PROJECT_MANAGEMENT_TECHNOLOGY,
      svg: <TechnologyIcon />,
    },
    {
      id: 4,
      name: "Docs",
      key: "Docs",
      path: "docs",
      hasPermission: permissionConstants.submodules.PROJECT_DOCUMENTS,
      svg: <DocsIcon />,
    },
  ],
};

export default menuItems;
