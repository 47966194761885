import Gateway from "../gateway/gateway";

// get team data of a project
const getReportData = (params) => Gateway.get("/report/list", { params });
const getReportTask = (params) => Gateway.get("/report/task", { params });
const getReportDashboardData = (params) =>
  Gateway.get("/report/dashboard", { params });
const getReportDashboardPerformanceData = (params) =>
  Gateway.get("/report/p-index", { params });
const getReportDashboardQualityData = (params) =>
  Gateway.get("/report/q-index", { params });
const getReportDashboardTaskData = (params) =>
  Gateway.get("/report/t-index", { params });

export default {
  getReportData,
  getReportTask,
  getReportDashboardData,
  getReportDashboardPerformanceData,
  getReportDashboardQualityData,
  getReportDashboardTaskData,
};
