/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import {
  DeleteIco,
  EditIco,
  SettingsIco,
  TrashIcon,
  SubmitButtonIcon,
} from "./svgIcons";
import { useThunk } from "../../../../../hooks/useThunk";
import { Deletemodal } from "../../../../../components/DropDowns/DeleteModal";
import { deleteTableData } from "../../../../../store/slices/gitIntegration/thunks/deleteTableData";
import { getAllUsers } from "../../../../../store/slices/common/thunks/getUsers";
import { getFirstLetters } from "../../../../../utils/utils";
import {
  getGitRepos,
  getRepoDetailsTable,
  postConnectedRepoDetails,
} from "../../../../../store";
import { editTableData } from "../../../../../store/slices/gitIntegration/thunks/editTableData";
import CustomModal from "../../../../../components/CustomModal";
import usertableicons from "../../../../../assets/images/user-table-icons.svg";
import searchSvg from "../../../../../assets/images/search-normal.svg";
import CommonSkeleton from "../../../../../components/SkeletonLoader/CommonSkeleton";
import UserNoData from "../../../../UserProfile/UserNoData";
import permissionConstants from "../../../../../utils/permissionUtils/permissionConstants";
import { getNameAndUniqId } from "../../../../../components/common/iconDefaultName";

export const ManageGitModal = ({
  showModal,
  setShowModal,
  gitStatus,
  hasPermission,
}) => {
  const [userRepos, setUserRepos] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [showField, setShowField] = useState(false);

  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [search, setSearch] = useState("");
  const [state, setstate] = useState({
    deleteModal: { openclose: false, type: "" },
  });
  const [filterSelectValue, setFilterSelectValue] = useState(null);
  const [secondSelectValue, setSecondSelectValue] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    value1: "",
    value2: "",
  });
  const [selectedValue, setSelectedValue] = useState("");
  const firstSelectOptions = [
    { value: "Repo", label: "Repo" },
    { value: "Project", label: "Project" },
    { value: "Users", label: "Users" },
  ];

  const [data, setData] = useState(null);
  const [SubmitReposDetails, isLoadingSubmitReposDetails] = useThunk(
    postConnectedRepoDetails,
  );
  const [EditReposDetails] = useThunk(editTableData);
  const [gitTableData, isLoading] = useThunk(getRepoDetailsTable);
  const [gitTableDataDelete] = useThunk(deleteTableData);
  const [gitAllRepos] = useThunk(getGitRepos);
  const [getAllGituserDetails] = useThunk(getAllUsers);
  const { gitRepos } = useSelector((state) => state.github);
  const { projectData } = useSelector(({ project }) => project);
  const { allUsers } = useSelector((state) => state.common);
  const { getUserTableDetails } = useSelector((state) => state.github);
  const { authData } = useSelector((state) => state.auth);
  const { deleteModal } = state;
  const [imgArray, setimgArray] = useState([]);

  // image error handling
  const [imgError, setimgError] = useState(false);

  const handleOnProjectError = () => {
    setimgError(true);
  };

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  useEffect(() => {
    if (gitStatus === true) {
      gitTableData(data);
      getAllGituserDetails();
      gitAllRepos();
    }
  }, [gitStatus]);

  useEffect(() => {
    if (gitStatus === true) {
      gitTableData(data);
    }
  }, [gitStatus, data]);

  useEffect(() => {
    if (gitStatus === true) {
      gitTableData(data);
      setData({
        search,
        filter: { [selectedFilter.value1]: selectedFilter.value2 },
      });
    }
  }, [search]);

  const closeDeleteModal = () => {
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: false,
      },
    });
  };
  const handleDeleteModalClick = (item) => {
    const type = `${item.repository.repoName} repo from project`;
    setDeleteId(item._id);
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: true,
        type,
      },
    });
  };

  const handleDelete = () => {
    gitTableDataDelete(deleteId);
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: false,
      },
    });
    gitTableData(data);
  };

  const handleEdit = (item) => {
    setEditId(item._id);
    setEdit(true);
    setUserRepos({
      label: item?.repository?.repoName,
      value: item?.repository?.repoName,
      owner: item?.repository?.owner,
      repoUrl: item?.repository?.repoUrl,
      id: item?.repository?.nodeId,
    });

    projectData.map((data) =>
      data._id === item?.project
        ? setSelectedProject({
            label: data.name,
            value: data.name,
            id: data._id,
          })
        : "",
    );
    const userIds = item.users;
    const Users = allUsers.filter((user) => userIds.includes(user._id));
    const listEditUsers =
      Users?.map((item) => ({
        value: item.employeeName,
        label: item.employeeName,
        icon: item.photo,
        id: item._id,
      })) || [];
    setSelectedUsers(listEditUsers);
  };

  const listRepos =
    gitRepos?.userRepos?.map((item) => ({
      name: item.name,
      label: item.name,
      id: item.nodeId,
      repoUrl: item.repoUrl,
      owner: item.owner,
    })) || [];

  const listprojects =
    projectData?.map((item) => ({
      value: item.name,
      label: item.name,
      icon: item.logo,
      id: item._id,
    })) || [];

  const authUserId = authData ? authData._id : null;

  const listUsers =
    allUsers
      ?.filter((item) => item._id !== authUserId)
      .map((item) => ({
        value: item.employeeName,
        label: item.employeeName,
        icon: item.photo,
        id: item._id,
      })) || [];

  const listAddedRepos =
    getUserTableDetails?.getProjectRepo?.repository?.map((item) => ({
      name: item.repoName,
      label: item.repoName,
      id: item.nodeId,
      repoUrl: item.repoUrl,
      owner: item.owner,
    })) || [];

  const onChange = (value) => {
    setUserRepos(value);
  };
  const handleSelectChangeUser = (selectedValues) => {
    setSelectedUsers(selectedValues);
  };
  const handleSelectChangeProject = (value) => {
    setSelectedProject(value);
  };

  const secondSelectOptionsMap = {
    Repo: listAddedRepos,
    Project: listprojects,
    Users: listUsers,
  };

  const handleFirstFilterSelectChange = (selectedOption) => {
    const { value, label } = selectedOption;
    setSelectedFilter({ value1: label, value2: "" });
    setFilterSelectValue(selectedOption);
    setSecondSelectValue(secondSelectOptionsMap[value] || []);
  };

  const handleSecondFilterSelectChange = (selectedOption) => {
    const { label, id } = selectedOption;
    const updatedFilter = { ...selectedFilter, value2: id };
    setSelectedValue({ label, value: id });
    setSelectedFilter(updatedFilter);
    setData({
      search,
      filter: {
        [updatedFilter.value1]: id,
      },
    });
  };

  const onSubmit = () => {
    const pojectData = { ...selectedProject };
    const RepoData = { ...userRepos };
    const modifiedUsers = selectedUsers?.map((user) => user.id);
    const modifiedRepo = {
      nodeId: RepoData.id,
      repoName: RepoData.label,
      repoUrl: RepoData.repoUrl,
      owner: RepoData.owner,
    };

    const submitDetails = (details) => {
      if (edit === false) {
        SubmitReposDetails(details);
        setShowField(false);
      } else {
        EditReposDetails({ ...details, id: editId });
        setShowField(false);
      }
      setSelectedProject(null);
      setSelectedUsers(null);
      setUserRepos(null);
      setEdit(false);
      setTimeout(() => {
        gitTableData(data);
      }, 500);
    };

    const details = {
      project: pojectData.id,
      repository: modifiedRepo,
      users: modifiedUsers,
    };

    submitDetails(details);
  };

  const customOption = ({
    Icon,
    isSelected,
    children,
    innerProps,
    data,
    ...rest
  }) => {
    const style = {};
    const props = {
      ...innerProps,
      style,
    };

    return (
      <components.Option {...rest} isSelected={isSelected} innerProps={props}>
        <div
          className="custom"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="ch-bx-otr">
            <label className="check-bx-cstm ">
              <input type="checkbox" checked={isSelected} />
              <span className="checkmark_01" />
            </label>
          </div>
          {data.icon ? (
            imgArray?.includes(data?.id) ? (
              <div className="name userProf bg-7">
                {getFirstLetters(children)}
                {console.log("data", data)}
              </div>
            ) : (
              <div className="user-profile-thumb">
                <img
                  onError={(target) => handleOnError(target, data?.id)}
                  onLoad={(target) => {
                    target.target.style.display = "block";
                  }}
                  src={`https://erptest.spericorn.com/repo/uploads/${data.icon}`}
                  alt=""
                />
              </div>
            )
          ) : (
            <div className="name userProf bg-7">
              {getFirstLetters(children)}
            </div>
          )}
          <span>{children}</span>
        </div>
      </components.Option>
    );
  };
  const CustomValueContainer = ({ getValue, children, ...props }) => {
    const selectedOptions = getValue();
    const threshold = 1;

    if (selectedOptions.length <= threshold) {
      return children;
    }
    const firstChildLabel = children[0][0].props.children;
    return (
      <div {...props}>
        {firstChildLabel} + {selectedOptions.length - 1} more
      </div>
    );
  };
  CustomValueContainer.propTypes = {
    getValue: PropTypes.func.isRequired,
    children: PropTypes.node,
  };

  const customOption2 = ({
    Icon,
    isSelected,
    children,
    innerProps,
    data,
    ...rest
  }) => {
    const style = {};
    const props = {
      ...innerProps,
      style,
    };

    return (
      <components.Option {...rest} isSelected={isSelected} innerProps={props}>
        <div
          className="custom"
          style={{ display: "flex", alignItems: "center" }}
        >
          {data.icon ? (
            imgArray?.includes(data?.id) ? (
              <div className="name userProf bg-7">
                {getFirstLetters(children)}
              </div>
            ) : (
              <div className="user-profile-thumb">
                <img
                  onError={(target) => handleOnError(target, data?.id)}
                  onLoad={(target) => {
                    target.target.style.display = "block";
                  }}
                  src={`https://erptest.spericorn.com/repo/uploads/${data.icon}`}
                  alt=""
                  className="w-100"
                />
              </div>
            )
          ) : (
            <div className="name userProf bg-7">
              {getFirstLetters(children)}
            </div>
          )}
          <span>{children}</span>
        </div>
      </components.Option>
    );
  };

  const ProjectOption = (props) => (
    <components.Option {...props}>
      {props.data.icon && !imgError ? (
        <div className="custom">
          <img
            className="user-profile-thumb avathar-sm "
            onError={handleOnProjectError}
            src={`https://erptest.spericorn.com/repo/uploads/${props.data.icon}`}
            // src={props.data.icon}
            alt=""
            style={{
              maxWidth: "20px",
              maxHeight: "20px",
              marginLeft: "10px",
            }}
          />
        </div>
      ) : (
        <div className="name userProf bg-7">
          {getFirstLetters(props.data.label)}
        </div>
      )}
      {props.data.label}
    </components.Option>
  );
  ProjectOption.propTypes = {
    data: PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
    }).isRequired,
  };
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {selectedProject?.icon ? (
        <div className="custom">
          <img
            className="user-profile-thumb avathar-sm "
            src={`https://erptest.spericorn.com/repo/uploads/${selectedProject?.icon}`}
            alt=""
            style={{
              maxWidth: "20px",
              maxHeight: "20px",
              marginLeft: "10px",
            }}
          />
        </div>
      ) : (
        <div className="name userProf bg-7">{getFirstLetters(children)}</div>
      )}
      {children}
    </components.SingleValue>
  );
  SingleValue.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const renderHeader = () => (
    <>
      <h1 className="modal-title" id="exampleModalLabel">
        <span className="ico">
          <SettingsIco />
        </span>
        <div className="txt">Manage GitHub</div>
      </h1>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={() => {
          setShowModal(false);
          setUserRepos(null);
          setSelectedProject(null);
          setSelectedUsers(null);
          setFilterSelectValue(null);
          setSecondSelectValue([]);
          setEdit(false);
          setSelectedFilter({
            value1: "",
            value2: "",
          });
          setShowField(false);
        }}
      />
    </>
  );

  const renderBody = () => (
    <div className="row">
      <div className="col-md-12">
        <div className="repositories_git custom-scroll">
          <div className="connectTitle">
            <h3 className="card-title">
              Connect your repositories to the project :
            </h3>
            <div className="drop-area-btn">
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => setShowField(!showField)}
              >
                {" "}
                <SubmitButtonIcon />
              </button>
            </div>
          </div>
          <div className="card-grop-area">
            <div
              className="form-area-top"
              style={{ display: showField === false && "none" }}
            >
              <div className="row w-100">
                <div className="col-lg-4">
                  <div className="drop-area ">
                    <label className="lable-nme">Add Repository</label>
                    {hasPermission(
                      permissionConstants.permissions.GITHUB_REPO_NAME,
                    ) && (
                      <Select
                        placeholder="Select repository"
                        value={userRepos}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        options={listRepos}
                        onChange={(selectedRepo) =>
                          onChange({
                            repoName: selectedRepo.label,
                            label: selectedRepo.label,
                            owner: selectedRepo.owner,
                            value: selectedRepo.label,
                            repoUrl: selectedRepo.repoUrl,
                            id: selectedRepo.id,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="drop-area ">
                    <label className="lable-nme">Add Project</label>
                    {hasPermission(
                      permissionConstants.permissions.GITHUB_PROJECT,
                    ) && (
                      <Select
                        value={selectedProject}
                        menuPosition="fixed"
                        classNamePrefix="react-select"
                        className="react-select-container"
                        placeholder="Select Project"
                        options={listprojects}
                        onChange={handleSelectChangeProject}
                        styles={{
                          singleValue: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                          }),
                        }}
                        components={{
                          Option: ProjectOption,
                          SingleValue,
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="drop-area">
                    <label className="lable-nme">Add Users</label>
                    <div className="d-flex">
                      {hasPermission(
                        permissionConstants.permissions.GITHUB_USERS,
                      ) && (
                        <Select
                          isMulti
                          menuPosition="fixed"
                          isClearable={false}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          placeholder="Add Users"
                          classNamePrefix="react-select"
                          className="react-select-container w-100"
                          options={listUsers}
                          value={selectedUsers}
                          onChange={handleSelectChangeUser}
                          components={{
                            Option: customOption,
                            ValueContainer: CustomValueContainer,
                          }}
                        />
                      )}
                      <div className="drop-area-btn ms-3">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={
                            selectedProject === null ||
                            userRepos === null ||
                            selectedUsers === null ||
                            selectedUsers.length === 0
                          }
                          onClick={() => {
                            onSubmit();
                          }}
                        >
                          {isLoadingSubmitReposDetails ? "Loading.." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-wrp">
              <div className="filter-hld">
                {hasPermission(
                  permissionConstants.permissions.GITHUB_SEARCH,
                ) && (
                  <div className="search-area d-flex align-items-center">
                    <div className="search-icon">
                      <img src={searchSvg} alt="Icon" />
                    </div>
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Search"
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                    />
                  </div>
                )}
                {hasPermission(
                  permissionConstants.permissions.GITHUB_FILTER,
                ) && (
                  <>
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container w-100"
                      options={firstSelectOptions}
                      onChange={handleFirstFilterSelectChange}
                      value={filterSelectValue}
                    />
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container proj-itm-select w-100"
                      options={secondSelectValue}
                      onChange={handleSecondFilterSelectChange}
                      value={selectedValue}
                      isDisabled={filterSelectValue === null}
                      components={{
                        Option: customOption2,
                        ValueContainer: CustomValueContainer,
                      }}
                    />
                  </>
                )}
                {data === null ||
                (data.search === "" && data.filter[""] === "") ? null : (
                  <div
                    className="trash-wrp"
                    onClick={() => {
                      setFilterSelectValue(null);
                      setSecondSelectValue([]);
                      setSelectedValue(null);
                      setSelectedFilter({
                        value1: "",
                        value2: "",
                      });
                      setData(null);
                      setSearch("");
                    }}
                  >
                    <TrashIcon />
                  </div>
                )}
              </div>
            </div>
            <div className="table-responsive custom-scroll max-h-100">
              {getUserTableDetails?.getProjectRepo?.repoProjectDetails &&
              getUserTableDetails.getProjectRepo?.repoProjectDetails?.length >
                0 ? (
                <BottomScrollListener
                  // onBottom={() => {
                  //   if (increment < cronjobData?.totalCounts) {
                  //     setIncrement(increment + 5);
                  //     setPaginationData({ ...paginationData, limit: 5 });
                  //   }
                  // }}
                  onBottom={(e) => {
                    console.log("BottomScrollListener", e);
                  }}
                >
                  <table className="table custom-table">
                    <tr>
                      <th className="icon" />
                      <th className="nme">NAME OF REPO</th>
                      <th className="project">PROJECT</th>
                      <th className="user">USERS</th>
                      <th className="action">ACTION</th>
                    </tr>
                    {isLoading
                      ? Array?.from({
                          length: 5,
                        })?.map((_, index) => (
                          <td key={index}>
                            {Array?.from({
                              length:
                                getUserTableDetails?.getProjectRepo
                                  ?.repoProjectDetails?.length,
                            })?.map((_, index) => (
                              <div className="td-cnt" key={index}>
                                <div className="txt">
                                  <CommonSkeleton width={100} />
                                </div>
                              </div>
                            ))}
                          </td>
                        ))
                      : getUserTableDetails?.getProjectRepo?.repoProjectDetails?.map(
                          (item) => (
                            <tr>
                              <td>
                                <div className="td-cnt">
                                  <img src={usertableicons} alt="" />
                                </div>
                              </td>
                              <td>
                                <div className="td-cnt">
                                  <div className="txt">
                                    {item?.repository?.repoName}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="td-cnt">
                                  <div className="txt">
                                    {projectData.map((data) =>
                                      // eslint-disable-next-line react/prop-types
                                      data._id === item?.project
                                        ? data.name
                                        : "",
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="td-cnt">
                                  <div className="info-td d-flex">
                                    {allUsers
                                      .filter((e) =>
                                        item.users.some(
                                          (userId) => userId === e._id,
                                        ),
                                      )
                                      .map((each) => (
                                        <div className="avatar__group">
                                          <div className="avathar-sm avathar-01">
                                            <div className="online" />
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>
                                                  <div className="name-block align-items-center d-flex">
                                                    <span className="name">
                                                      {each.employeeName}
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                              }
                                            >
                                              {each.photo ? (
                                                <div className="profile-thumb">
                                                  <img
                                                    className="avatharImg"
                                                    src={`https://erptest.spericorn.com/repo/uploads/${each.photo}`}
                                                    alt=""
                                                  />
                                                </div>
                                              ) : (
                                                <span>
                                                  {
                                                    getNameAndUniqId(
                                                      each.employeeName,
                                                    ).initials
                                                  }
                                                </span>
                                              )}
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="td-cnt">
                                  {hasPermission(
                                    permissionConstants.permissions.GITHUB_EDIT,
                                  ) && (
                                    <button
                                      className="btn btn-icon"
                                      type="button"
                                      onClick={() => {
                                        handleEdit(item);
                                        setShowField(true);
                                      }}
                                    >
                                      <EditIco />
                                    </button>
                                  )}
                                  {hasPermission(
                                    permissionConstants.permissions
                                      .GITHUB_DELETE,
                                  ) && (
                                    <button
                                      className="btn btn-icon"
                                      type="button"
                                      onClick={() => {
                                        handleDeleteModalClick(item);
                                        setShowField(false);
                                      }}
                                    >
                                      <DeleteIco />
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ),
                        )}
                  </table>
                </BottomScrollListener>
              ) : (
                <UserNoData />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <CustomModal
        className="modal fade manage-integ"
        dialgName="modal-dialog modal-xl modal-dialog-centered"
        header={renderHeader()}
        bodyClassname=""
        createModal={7}
        disableCenter={7}
        show={!!showModal}
        closeModal={() => {
          setShowModal(false);
        }}
        body={renderBody()}
      />
      <Deletemodal
        ModalShow={deleteModal}
        closeModal={closeDeleteModal}
        cancelModal={closeDeleteModal}
        deleteFunc={handleDelete}
        type={deleteModal?.type}
      />
    </>
  );
};
ManageGitModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  gitStatus: PropTypes.bool,
  hasPermission: PropTypes.func,
};
