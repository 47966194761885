import { createAsyncThunk } from "@reduxjs/toolkit";
// import _ from "lodash";
import { alertActions } from "../../alert/index";
import { getUserRepositories } from "../../../../services/gitHubService";
import { gitHubAction } from "..";

export const getUserRepos = createAsyncThunk(
  "github",
  async (params, { dispatch }) => {
    console.log("params", params);
    dispatch(alertActions.clear());
    try {
      const { data } = await getUserRepositories(params);
      dispatch(gitHubAction.getUserProjectRepos(data));
    } catch (error) {
      dispatch(alertActions.error("Repository not connected"));
    }
  },
);
