import Gateway from "../gateway/gateway";

const getDesignList = () => Gateway.get("/design");

const updateDesignList = ({ id, data }) =>
  Gateway.patch(`/design/${id}`, data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

export default { getDesignList, updateDesignList };
