import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { deleteTableDetails } from "../../../../services/gitHubService";

export const deleteTableData = createAsyncThunk(
  "github/delete_table_data",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { deleteProjectRepo },
      } = await deleteTableDetails(id);
      console.log("deleteProjectRepo", deleteProjectRepo);
      dispatch(alertActions.success(deleteProjectRepo));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.deleteProjectRepo;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
