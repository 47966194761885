import { createSlice } from "@reduxjs/toolkit";

export const name = "report";

const createReducers = () => {
  const setReportData = (state, { payload }) => {
    state.reportData = payload.reports;
    state.successMessage = payload.successMessage;
  };
  const clearReportData = (state) => {
    state.reportData = [];
  };
  const setReportDashboardData = (state, { payload }) => {
    state.reportDashboardData = payload.reports;
    state.successMessage = payload.successMessage;
  };
  const setReportDashboardPerformanceChartData = (state, { payload }) => {
    state.reportDashboardPerformanceChartData = payload.data;
    state.successMessage = payload.successMessage;
  };
  const setReportDashboardQualityChartData = (state, { payload }) => {
    state.reportDashboardQualityChartData = payload.data;
    state.successMessage = payload.successMessage;
  };
  const setReportDashboardTaskChartData = (state, { payload }) => {
    state.reportDashboardTaskData = payload.data;
    state.successMessage = payload.successMessage;
  };
  const setReportErrorMessage = (state, { payload }) => {
    state.errorMessage = payload;
  };
  const setReportTask = (state, { payload }) => {
    state.reportTask = payload.data;
  };
  const setPatchedReportTaskData = (state, { payload }) => {
    state.reportTask = state.reportTask.map((eachTicket) => {
      if (eachTicket._id === payload._id)
        return {
          ...eachTicket,
          title: payload.title,
          priority: payload.priority,
          ticketStatus: payload.ticketStatus,
          startDate: payload.startDate,
          dueDate: payload.dueDate,
        };
      return eachTicket;
    });
  };

  return {
    setReportErrorMessage,
    setReportData,
    clearReportData,
    setReportTask,
    setReportDashboardData,
    setPatchedReportTaskData,
    setReportDashboardPerformanceChartData,
    setReportDashboardQualityChartData,
    setReportDashboardTaskChartData,
  };
};

function createInitialState() {
  return {
    reportData: [],
    reportTask: null,
    reportDashboardData: [],
    reportDashboardPerformanceChartData: [],
    reportDashboardQualityChartData: [],
    reportDashboardTaskData: [],
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const reportActions = slice.actions;
export const reportReducer = slice.reducer;
