import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import "../../design/custom/pages/dashboard.scss";
import "../../design/custom/pages/profilecard.scss";
import "../../design/custom/pages/counter.scss";
import "../../design/custom/pages/TaskCard.scss";
import "../../design/custom/pages/projectmanagement-list.scss";
import { withTitle } from "../../hoc/withTitle";
import Counters from "./Counters";
import ProfileCard from "../../components/DashBoard/Profile";
import TaskCard from "../../components/DashBoard/Tasks";
import Performancegraph from "../../components/DashBoard/Charts/PerformanceGraph";
import QualityIndex from "../../components/DashBoard/Charts/QualityIndex";
import LanguageContext from "../../context/LanguageContext";
import TicketTypeProject from "../../assets/images/project-icon.svg";
import TicketTypeTasks from "../../assets/images/open-tasks-icon.svg";
import TicketTypeHoursCompleted from "../../assets/images/hours-completed-icon.svg";
import TicketTypeProductive from "../../assets/images/productive-icon.svg";
import TicketTypeQuality from "../../assets/images/quality-icon.svg";
import TicketTypePerformance from "../../assets/images/performance-icon.svg";
import { useThunk } from "../../hooks/useThunk";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import { getDashboardChartsPerformData } from "../../store";
import { getDashboardQualityData } from "../../store/slices/dashboard/thunks/getDashboardQualityIndex";
import { getDashboarCounts } from "../../store/slices/dashboard/thunks/getDashboardCount";
import { getDashboarTasks } from "../../store/slices/dashboard/thunks/getDashboardTasks";
import { permissionsDashboardCounts } from "./permissionConstants";
import {
  modulePermissionHasPermissions,
  modulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import { getLeaderboard } from "../../store/slices/dashboard/thunks/getLeaderBoard";

function DashBoard() {
  // get counter data
  const [getCounts, isLoading] = useThunk(getDashboarCounts);

  // get dashboard tasks
  const [getTasks, isLoadingTasks] = useThunk(getDashboarTasks);

  const [dashboardPerformCharts, isPerformChartLoading] = useThunk(
    getDashboardChartsPerformData,
  );
  const [dashboardQualityCharts, isQualityChartLoading] = useThunk(
    getDashboardQualityData,
  );
  const [getLeaderboardData] = useThunk(getLeaderboard);

  const {
    dashboardCount,
    dashboardTasks,
    dashboardPerformData,
    dashboardQualityData,
    leaderboard,
  } = useSelector((state) => state.dashboard);

  const { userProfile } = useSelector((state) => state.userProfile);

  const { ticketStatusData } = useSelector((state) => state.common);
  const [performanceDate, setPerformanceDate] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });
  const [qualityIndexDate, setQualityIndexDate] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });

  const handlePerformanceDateChange = (newPerformanceDate) => {
    setPerformanceDate(newPerformanceDate);
  };
  const handleQualityIndexDate = (newDate) => {
    setQualityIndexDate(newDate);
  };
  // convert status to color
  const convertColorCodeToStatusName = (colorCode) => {
    switch (colorCode) {
      case "IN PROGRESS":
        return "inprgs";
      case "TODO":
        return "todo";
      case "READY FOR QA":
        return "redy-qa";
      case "COMPLETE":
        return "completed";
      default:
        return "todo";
    }
  };

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissionList = modulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.DASHBOARD,
      );
      return modulePermissionHasPermissions(permissionList, permission);
    }
  };

  // today tasks
  const todayTasks = dashboardTasks?.TodayTasks?.tasks?.map((e) => {
    const matchingStatus = ticketStatusData?.find(
      (status) => status._id === e?.ticketStatus,
    );

    const sprint = (e?.sprintInfo?.name || "-")?.replace(/\([^)]*\)/, "");
    const projectName = e?.projectInfo?.name || "-";
    const ticketStatusColor = matchingStatus
      ? convertColorCodeToStatusName(matchingStatus.title)
      : "status not available";

    return {
      ...e,
      dueDateFormatted: e?.dueDate ? moment(e.dueDate).format("MMMM D") : "-",
      createdDateFormatted: e?.createdAt
        ? moment(e.createdAt).format("MMMM D")
        : "-",
      ticketStatusColor,
      sprint,

      ticketStatusName: matchingStatus
        ? matchingStatus.title
        : "Status Not Found",
      projectName,
    };
  });

  // queued Tasks for formatting date
  const queuedTasks = dashboardTasks?.QueuedTasks?.tasks?.map((e) => {
    const matchingStatus = ticketStatusData?.find(
      (status) => status._id === e.ticketStatus,
    );
    const sprint = (e?.sprintInfo?.name || "-")?.replace(/\([^)]*\)/, "");
    const projectName = e?.projectInfo?.name || "-";

    const ticketStatusColor = matchingStatus
      ? convertColorCodeToStatusName(matchingStatus.title)
      : "status not available";
    return {
      ...e,
      dueDateFormatted: e.dueDate ? moment(e.dueDate).format("MMMM D") : "-",
      createdDateFormatted: e.createdAt
        ? moment(e.createdAt).format("MMMM D")
        : "-",
      ticketStatusColor,
      sprint,
      ticketStatusName: matchingStatus
        ? matchingStatus.title
        : "Status Not Found",
      projectName,
    };
  });

  const chartPerformIndexData = dashboardPerformData?.map(
    (e) => e?.PerformanceIndex,
  );
  const chartPerformIndexDates = dashboardPerformData?.map((e) => e?.label);

  const chartQualityIndexData = dashboardQualityData?.map(
    (e) => e?.QualityIndex,
  );
  const chartQualityIndexDates = dashboardQualityData?.map((e) => e?.label);

  useEffect(() => {
    getCounts();
    getTasks();
    getLeaderboardData();
  }, []);

  useEffect(() => {
    if (performanceDate?.start?._d && performanceDate?.end?._d)
      dashboardPerformCharts({
        dates: {
          startDate: moment(performanceDate?.start?._d)?.format("YYYY-MM-DD"),
          endDate: moment(performanceDate?.end?._d)?.format("YYYY-MM-DD"),
        },
      });
  }, [performanceDate]);

  useEffect(() => {
    if (qualityIndexDate?.start?._d && qualityIndexDate?.end?._d)
      dashboardQualityCharts({
        dates: {
          startDate: moment(qualityIndexDate?.start?._d)?.format("YYYY-MM-DD"),
          endDate: moment(qualityIndexDate?.end?._d)?.format("YYYY-MM-DD"),
        },
      });
  }, [qualityIndexDate]);

  // counter images
  const counterImageMap = [
    TicketTypeProject,
    TicketTypeTasks,
    TicketTypeHoursCompleted,
    TicketTypeProductive,
    TicketTypeQuality,
    TicketTypePerformance,
  ];

  // counter data and its image
  const CounterData = dashboardCount
    ?.filter((each) =>
      permissionsDashboardCounts.some(
        ({ key, permission }) =>
          key === each.title && hasPermission(permission),
      ),
    )
    .map((counter, index) => ({
      ...counter,
      icon: counterImageMap[index],
    }));

  const {
    languageData: { tasks },
  } = useContext(LanguageContext);

  return (
    <div className="page-contents">
      <div className="container-fluid">
        <div className="row card-row">
          {hasPermission(
            permissionConstants.permissions.DASHBOARD_SHOW_PROFILE,
          ) && (
            <div className="col-lg-4 mb-4">
              {/* profile details */}
              {isLoading ? (
                <CommonSkeleton height={202} />
              ) : (
                <ProfileCard data={userProfile} leaderboard={leaderboard} />
              )}
            </div>
          )}
          <div className="col-lg-8">
            {/* Counter */}
            <Counters data={CounterData} isLoading={isLoading} />
          </div>
        </div>
        <div className="row cutom-row dashboard-row">
          {hasPermission(
            permissionConstants.permissions.DASHBOARD_PERFORMANCE_INDEX_GRAPH,
          ) && (
            <div className="col-md-6">
              {/* line chart */}
              {/* {isChartLoading ? (
              <div className="mb-3">
                <CommonSkeleton height={200} />
              </div>
            ) : ( */}
              <Performancegraph
                data={chartPerformIndexData}
                label={chartPerformIndexDates}
                loading={isPerformChartLoading}
                showCustomDateRange={hasPermission(
                  permissionConstants.permissions
                    .DASHBOARD_PERFORMANCE_INDEX_GRAPH_FILTER,
                )}
                onPerformanceDateChange={handlePerformanceDateChange}
              />
              {/* // )} */}
            </div>
          )}
          {hasPermission(
            permissionConstants.permissions.DASHBOARD_QUALITY_INDEX_GRAPH,
          ) && (
            <div className="col-md-6">
              {/* bar chart */}
              {/* {isChartLoading ? (
              <div className="mb-3">
                <CommonSkeleton height={200} />
              </div>
            ) : ( */}
              <QualityIndex
                data={chartQualityIndexData}
                loading={isQualityChartLoading}
                label={chartQualityIndexDates}
                onQualityChange={handleQualityIndexDate}
                showCustomDateRange={hasPermission(
                  permissionConstants.permissions
                    .DASHBOARD_QUALITY_INDEX_GRAPH_FILTER,
                )}
              />
              {/* // )} */}
            </div>
          )}
        </div>
        <div className="row cutom-row align-items-stretch dashboard-row">
          {hasPermission(
            permissionConstants.permissions.DASHBOARD_ASSIGNED_TASKS_TODAY,
          ) && (
            <div className="col-md-6">
              {/* tasks details */}
              <TaskCard
                title={tasks?.assignedTasks}
                tasks={todayTasks}
                isLoading={isLoadingTasks}
              />
            </div>
          )}
          {hasPermission(
            permissionConstants.permissions.DASHBOARD_QUEUED_TASKS,
          ) && (
            <div className="col-md-6">
              <TaskCard
                title={tasks?.queuedTasks}
                tasks={queuedTasks}
                isLoading={isLoadingTasks}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withTitle(DashBoard);
