const validator = (values) => {
  const errors = {};

  if (!values.department) {
    errors.department = "Department is required";
  }

  if (!values.role) {
    errors.role = "Project Role is required";
  }

  if (!values.members) {
    errors.members = "Select Members is required";
  }

  if (!values.allocation) {
    errors.allocation = "Allocation Hrs/day is required.";
  } else {
    const allocation = parseInt(values.allocation, 10);

    if (Number.isNaN(allocation)) {
      errors.allocation = "Allocation Hrs/day must be an integer";
    } else if (allocation < 0 || allocation > 12) {
      errors.allocation = "Allocation Hrs/day must be between 0 and 12";
    } else if (allocation !== parseFloat(values.allocation)) {
      errors.allocation = "Allocation Hrs/day must be an integer";
    }
    const selectedMembers = values.members || [];
    selectedMembers.forEach((member, index) => {
      if (member.totalAllocationHours + allocation > 12) {
        errors.allocation_tooltip = [
          ...(errors.allocation_tooltip ?? []),
          `${selectedMembers.length > 1 ? `${index + 1}. ` : ""}${
            member.label
          } exceeds 12 hours already allocated for ${
            member.totalAllocationHours
          } hours`,
        ];
      }
    });
  }
  return errors;
};

export default validator;
