export const ListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.6"
    height="10.237"
    viewBox="0 0 13.6 10.237"
  >
    <g id="list_1_" data-name="list (1)" transform="translate(0 -7.434)">
      <path
        id="Path_12"
        data-name="Path 12"
        d="M23.693,47.25H14.6a.679.679,0,1,1,0-1.357h9.094a.679.679,0,0,1,0,1.357Z"
        transform="translate(-10.771 -29.759)"
        fill="currentcolor"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M23.693,28.419H14.6a.679.679,0,1,1,0-1.357h9.094a.679.679,0,0,1,0,1.357Z"
        transform="translate(-10.771 -15.188)"
        fill="currentcolor"
      />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M23.693,9.588H14.6a.679.679,0,1,1,0-1.357h9.094a.679.679,0,0,1,0,1.357Z"
        transform="translate(-10.771 -0.617)"
        fill="currentcolor"
      />
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="0.911"
        cy="0.911"
        r="0.911"
        transform="translate(0 7.434)"
        fill="currentcolor"
      />
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="0.911"
        cy="0.911"
        r="0.911"
        transform="translate(0 11.641)"
        fill="currentcolor"
      />
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="0.911"
        cy="0.911"
        r="0.911"
        transform="translate(0 15.848)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
export const BoardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.422"
    height="11.095"
    viewBox="0 0 15.422 11.095"
  >
    <g
      id="vuesax_linear_align-bottom"
      data-name="vuesax/linear/align-bottom"
      transform="translate(-492.942 -319.105)"
    >
      <g id="align-bottom" transform="translate(492 316)">
        <path
          id="Vector"
          d="M3.966,0V8.509c0,1.082-.461,1.514-1.608,1.514h-.75C.461,10.023,0,9.591,0,8.509V0"
          transform="translate(9.922 3.678)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0V4.9C0,5.985.461,6.418,1.608,6.418h.75c1.147,0,1.608-.433,1.608-1.514V0"
          transform="translate(3.793 3.678)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H14.422"
          transform="translate(1.442 3.605)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
export const GanttIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.095"
    height="15.422"
    viewBox="0 0 11.095 15.422"
  >
    <g
      id="Group_59679"
      data-name="Group 59679"
      transform="translate(-3.105 -0.935)"
    >
      <path
        id="Vector"
        d="M0,3.966H8.509c1.082,0,1.514-.461,1.514-1.608v-.75C10.023.461,9.591,0,8.509,0H0"
        transform="translate(3.678 9.915)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0H4.9C5.985,0,6.418.461,6.418,1.608v.75c0,1.147-.433,1.608-1.514,1.608H0"
        transform="translate(3.678 3.786)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0V14.422"
        transform="translate(3.605 1.435)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
export const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.98"
    height="15.421"
    viewBox="0 0 13.98 15.421"
  >
    <g
      id="Group_59680"
      data-name="Group 59680"
      transform="translate(-1.663 -0.942)"
    >
      <path
        id="Vector"
        d="M0,0V2.163"
        transform="translate(5.769 1.442)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0V2.163"
        transform="translate(11.537 1.442)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H12.259"
        transform="translate(2.523 6.555)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M12.98,3.605V9.735c0,2.163-1.082,3.605-3.605,3.605H3.605C1.082,13.34,0,11.9,0,9.735V3.605C0,1.442,1.082,0,3.605,0H9.374C11.9,0,12.98,1.442,12.98,3.605Z"
        transform="translate(2.163 2.523)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M.495.5H.5"
        transform="translate(8.155 9.379)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-6"
        data-name="Vector"
        d="M.495.5H.5"
        transform="translate(5.486 9.379)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-7"
        data-name="Vector"
        d="M.495.5H.5"
        transform="translate(5.486 11.542)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
export const ActivityLogIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.94"
    height="15.939"
    viewBox="0 0 15.94 15.939"
  >
    <g
      id="Group_58963"
      data-name="Group 58963"
      transform="translate(410.911 -250.491)"
      style={{ isolation: "isolate" }}
    >
      <path
        id="Path_9776"
        data-name="Path 9776"
        d="M-408.457,252.332c0-.231,0-.435,0-.639a1.214,1.214,0,0,1,1.2-1.2q2.48-.011,4.961,0a1.211,1.211,0,0,1,1.192,1.186c.006.209,0,.419,0,.661a6.538,6.538,0,0,1,.832.005,1.82,1.82,0,0,1,1.62,1.851c0,1.538,0,3.077,0,4.615v.236c.25.034.5.055.737.1a3.678,3.678,0,0,1,2.939,3.77,3.679,3.679,0,0,1-3.22,3.473,4.64,4.64,0,0,1-.534.032q-5.143,0-10.286,0a1.83,1.83,0,0,1-1.878-1.591,2.16,2.16,0,0,1-.019-.343q0-5.113,0-10.226a1.841,1.841,0,0,1,1.935-1.936Zm7.366,1.227a1.245,1.245,0,0,1-1.132,1.218c-.229.017-.46,0-.689.007a1.255,1.255,0,0,1-1.3-.834.726.726,0,0,0-.3-.324.586.586,0,0,0-.831.331,1.233,1.233,0,0,1-1.267.828c-.217,0-.434,0-.651,0a1.2,1.2,0,0,1-.877-.409,1.3,1.3,0,0,1-.32-.814c-.191,0-.375,0-.559,0a.612.612,0,0,0-.658.658q0,5.161,0,10.323a.616.616,0,0,0,.671.665h7.432c.051,0,.1-.007.178-.013a3.816,3.816,0,0,1-.928-2.3a.693.693,0,0,1-.744.479c-.3-.012-.6,0-.9,0h-3.812a.631.631,0,0,1-.627-.384.615.615,0,0,1,.617-.841c1.558,0,3.116,0,4.674-.005a.721.721,0,0,1,.774.43c.013-.016.019-.02.019-.025.007-.044.014-.088.02-.132a3.624,3.624,0,0,1,2.32-3.074c.107-.045.116-.11.116-.205,0-.549,0-1.1,0-1.647q0-1.638,0-3.275a.609.609,0,0,0-.572-.645C-400.658,253.55-400.868,253.559-401.091,253.559Zm4.895,9.193a2.455,2.455,0,0,0-2.428-2.451,2.454,2.454,0,0,0-2.477,2.441,2.454,2.454,0,0,0,2.456,2.461A2.455,2.455,0,0,0-396.2,262.752Zm-11.019-11.029v1.834c.2,0,.4.006.6,0a.2.2,0,0,0,.131-.1,1.8,1.8,0,0,1,1.729-1.113,1.777,1.777,0,0,1,1.687,1.143a.188.188,0,0,0,.129.1c.2,0,.4-.02.6-.032v-1.828Z"
        transform="translate(0 0)"
        fill="currentcolor"
      />
      <path
        id="Path_9777"
        data-name="Path 9777"
        d="M-359.892,430.23h2.356a.625.625,0,0,1,.7.617a.623.623,0,0,1-.689.608h-4.75a.623.623,0,0,1-.69-.608a.622.622,0,0,1,.7-.617Z"
        transform="translate(-45.492 -170.543)"
        fill="currentcolor"
      />
      <path
        id="Path_9778"
        data-name="Path 9778"
        d="M-360.822,382.308c.492,0,.983,0,1.475,0a.62.62,0,0,1,.672.606a.62.62,0,0,1-.678.619q-1.465,0-2.93,0a.621.621,0,0,1-.681-.616a.621.621,0,0,1,.688-.609Z"
        transform="translate(-45.492 -125.072)"
        fill="currentcolor"
      />
      <path
        id="Path_9779"
        data-name="Path 9779"
        d="M-182.119,455.24c-.13.437.129.7.411.966a.6.6,0,0,1-.267,1.039a.574.574,0,0,1-.565-.152a8.222,8.222,0,0,1-.631-.64a.748.748,0,0,1-.165-.412c-.022-.407-.012-.816-.006-1.225a.609.609,0,0,1,.6-.616a.615.615,0,0,1,.622.618C-182.116,454.959-182.119,455.1-182.119,455.24Z"
        transform="translate(-215.917 -193.287)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
