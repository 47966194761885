export function NotesOptionsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="4"
      viewBox="0 0 18 4"
    >
      <g
        id="Group_59398"
        data-name="Group 59398"
        transform="translate(-1886.226 -68)"
      >
        <circle
          id="Ellipse_4"
          data-name="Ellipse 4"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1886.226 68)"
          fill="currentcolor"
        />
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1893.226 68)"
          fill="currentcolor"
        />
        <circle
          id="Ellipse_6"
          data-name="Ellipse 6"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1900.226 68)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}

export function ArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.5"
      height="16.5"
      viewBox="0 0 16.5 16.5"
    >
      <g
        id="Group_59729"
        data-name="Group 59729"
        transform="translate(-0.75 -0.75)"
      >
        <g
          id="Group_59726"
          data-name="Group 59726"
          transform="translate(0 18) rotate(-90)"
        >
          <path
            id="Vector"
            d="M15,7.5A7.5,7.5,0,1,1,7.5,0,7.5,7.5,0,0,1,15,7.5Z"
            transform="translate(1.5 1.5)"
            fill="currentcolor"
            stroke="currentcolor"
            // stroke-linecap="round"
            // stroke-linejoin="round"
            // stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0,2.647,2.64,5.295,0"
            transform="translate(6.353 8.055)"
            fill="none"
            stroke="#C7CAD0"
            // stroke-linecap="round"
            // stroke-linejoin="round"
            // stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
}

export function MiniArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="arrow-right"
      width="13.156"
      height="13.156"
      viewBox="0 0 13.156 13.156"
    >
      <path
        id="Vector"
        d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
        transform="translate(4.884 2.236)"
        fill="none"
        stroke="#868991"
        // stroke-linecap="round"
        // stroke-linejoin="round"
        // stroke-width="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0H13.156V13.156H0Z"
        transform="translate(13.156 13.156) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}
