import { createSlice } from "@reduxjs/toolkit";

export const name = "myNotifications";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.departmentData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setActivityNotification = (state, action) => {
    state.activityNotification = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setNotificationSettings = (state, action) => {
    state.notificationsettings = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };

  return {
    setActivityNotification,
    setNotificationSettings,
    setErrorMesage,
    resetErrorMesage,
  };
};

function createInitialState() {
  return {
    activityNotification: [],
    notificationsettings: [],
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const myNotificationsActions = slice.actions;
export const myNotificationsReducer = slice.reducer;
