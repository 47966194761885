export const RightArrow = () => (
  <svg
    id="arrow-right"
    xmlns="http://www.w3.org/2000/svg"
    width="13.156"
    height="13.156"
    viewBox="0 0 13.156 13.156"
  >
    <path
      id="Vector"
      d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
      transform="translate(4.884 2.236)"
      fill="none"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M0,0H13.156V13.156H0Z"
      transform="translate(13.156 13.156) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const Estimate = () => (
  <svg width="9.86" height="13.001" viewBox="0 0 9.86 13.001">
    <g
      id="Group_60081"
      data-name="Group 60081"
      transform="translate(-541 -626)"
    >
      <g
        id="Group_60112"
        data-name="Group 60112"
        transform="translate(-823 -335)"
      >
        <g id="Group_58874" data-name="Group 58874">
          <path
            id="wait"
            d="M45.295,12.109H44.53v-1.37a4.351,4.351,0,0,0-2.142-3.588.777.777,0,0,1-.279-.65.777.777,0,0,1,.279-.65,4.351,4.351,0,0,0,2.142-3.588V.892h.764a.446.446,0,0,0,0-.892H36.327a.446.446,0,1,0,0,.892h.764v1.37a4.351,4.351,0,0,0,2.142,3.588.778.778,0,0,1,.279.65.777.777,0,0,1-.279.65,4.351,4.351,0,0,0-2.142,3.588v1.37h-.764a.446.446,0,0,0,0,.892h8.968a.446.446,0,0,0,0-.892Zm-7.311-1.37a3.578,3.578,0,0,1,1.747-2.847A1.647,1.647,0,0,0,40.4,6.5a1.647,1.647,0,0,0-.673-1.391,3.578,3.578,0,0,1-1.747-2.847V.892h5.655v1.37A3.578,3.578,0,0,1,41.891,5.11,1.647,1.647,0,0,0,41.217,6.5a1.647,1.647,0,0,0,.673,1.391,3.578,3.578,0,0,1,1.747,2.847v1.37H37.984Z"
            transform="translate(1328.119 961)"
            fill="currentcolor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const UserGroup = () => (
  <svg width="16.662" height="11.502" viewBox="0 0 16.662 11.502">
    <g
      id="Group_58849"
      data-name="Group 58849"
      transform="translate(-6418.949 -6942.496)"
    >
      <g
        id="user_4_"
        data-name="user (4)"
        transform="translate(6421.829 6899.93)"
      >
        <g
          id="Group_1149"
          data-name="Group 1149"
          transform="translate(0 42.666)"
        >
          <g id="Group_1148" data-name="Group 1148" transform="translate(0 0)">
            <path
              id="Path_57"
              data-name="Path 57"
              d="M6.82,48.212a2.974,2.974,0,1,0-2.933,0A5.008,5.008,0,0,0,0,52.94v.73a.3.3,0,0,0,.595,0v-.73a4.563,4.563,0,0,1,4.759-4.326,4.563,4.563,0,0,1,4.759,4.326v.73a.3.3,0,0,0,.595,0v-.73A5.008,5.008,0,0,0,6.82,48.212ZM2.974,45.64A2.379,2.379,0,1,1,5.354,48.02,2.382,2.382,0,0,1,2.974,45.64Z"
              transform="translate(0 -42.666)"
              fill="currentcolor"
              stroke="currentcolor"
              strokeWidth="0.2"
            />
            <path
              id="Path_58"
              data-name="Path 58"
              d="M332.272,89.7a2.377,2.377,0,0,0-1.307-4.364.3.3,0,1,0,0,.595,1.785,1.785,0,1,1,0,3.569.3.3,0,1,0,0,.595,3.445,3.445,0,0,1,3.569,3.3v.568a.3.3,0,1,0,.595,0v-.568A3.932,3.932,0,0,0,332.272,89.7Z"
              transform="translate(-321.447 -84.144)"
              fill="currentcolor"
              stroke="currentcolor"
              strokeWidth="0.2"
            />
          </g>
        </g>
      </g>
      <path
        id="Path_1464"
        data-name="Path 1464"
        d="M333.523,89.7a2.377,2.377,0,0,1,1.307-4.364.3.3,0,1,1,0,.595,1.784,1.784,0,1,0,0,3.569.3.3,0,1,1,0,.595,3.445,3.445,0,0,0-3.569,3.3v.568a.3.3,0,0,1-.595,0v-.568A3.932,3.932,0,0,1,333.523,89.7Z"
        transform="translate(6088.382 6858.451)"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.2"
      />
    </g>
  </svg>
);

export const CloseBtn = () => (
  <svg width="11.394" height="11.263" viewBox="0 0 11.394 11.263">
    <g
      id="Group_58851"
      data-name="Group 58851"
      transform="translate(-1612 -548.387)"
    >
      <g
        id="Group_60096"
        data-name="Group 60096"
        transform="translate(1612 548.388)"
      >
        <path
          id="Path_1459"
          data-name="Path 1459"
          d="M1.081,13.706a1.079,1.079,0,0,1-.766-.259,1.079,1.079,0,0,1,0-1.522L9.478,2.762A1.079,1.079,0,0,1,11.053,4.23L1.836,13.447a1.079,1.079,0,0,1-.755.259Z"
          transform="translate(0 -2.449)"
          fill="currentcolor"
        />
        <path
          id="Path_1460"
          data-name="Path 1460"
          d="M11.707,13.1a1.079,1.079,0,0,1-.756-.313L1.788,3.625A1.079,1.079,0,0,1,3.31,2.1l9.217,9.163a1.079,1.079,0,0,1,.05,1.526q-.024.026-.05.05A1.079,1.079,0,0,1,11.707,13.1Z"
          transform="translate(-1.474 -1.844)"
          fill="currentcolor"
        />
      </g>
    </g>
  </svg>
);

export const AddBtn = () => (
  <svg width="9.87" height="9.87" viewBox="0 0 9.87 9.87">
    <path
      id="Icon_ionic-md-add"
      data-name="Icon ionic-md-add"
      d="M16.62,12.343H12.343V16.62H11.027V12.343H6.75V11.027h4.277V6.75h1.316v4.277H16.62Z"
      transform="translate(-6.75 -6.75)"
      fill="currentcolor"
    />
  </svg>
);

export const Check = () => (
  <svg width="9.119" height="6.8" viewBox="0 0 9.119 6.8">
    <path
      id="Icon_awesome-check"
      data-name="Icon awesome-check"
      d="M3.1,11.244.134,8.28a.456.456,0,0,1,0-.645L.778,6.99a.456.456,0,0,1,.645,0l2,2L7.7,4.711a.456.456,0,0,1,.645,0l.645.645a.456.456,0,0,1,0,.645L3.742,11.244A.456.456,0,0,1,3.1,11.244Z"
      transform="translate(0 -4.577)"
      fill="currentcolor"
    />
  </svg>
);
export const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.601"
    height="8.601"
    viewBox="0 0 8.601 8.601"
  >
    <path
      id="Icon_ionic-ios-add"
      data-name="Icon ionic-ios-add"
      d="M17.031,12.73H13.8V9.5a.535.535,0,1,0-1.071,0v3.23H9.5a.535.535,0,0,0,0,1.071h3.23v3.23a.535.535,0,1,0,1.071,0V13.8h3.23a.535.535,0,1,0,0-1.071Z"
      transform="translate(-8.965 -8.965)"
      fill="#C7CAD0"
    />
  </svg>
);
export const UserIcon = () => (
  <svg
    id="user_account_people_man"
    data-name="user, account, people, man"
    xmlns="http://www.w3.org/2000/svg"
    width="11.624"
    height="13.737"
    viewBox="0 0 11.624 13.737"
  >
    <path
      id="Path_1424"
      data-name="Path 1424"
      d="M14.9,16.045a.528.528,0,1,0-.743.751,4.722,4.722,0,0,1,1.41,3.38c0,.646-1.852,1.585-4.755,1.585s-4.755-.939-4.755-1.586a4.723,4.723,0,0,1,1.392-3.362.528.528,0,1,0-.747-.747A5.773,5.773,0,0,0,5,20.176c0,1.716,2.994,2.642,5.812,2.642s5.812-.926,5.812-2.642A5.77,5.77,0,0,0,14.9,16.045Z"
      transform="translate(-5 -9.081)"
      fill="#b08bfc"
    />
    <path
      id="Path_1425"
      data-name="Path 1425"
      d="M12.7,10.4A3.7,3.7,0,1,0,9,6.7,3.7,3.7,0,0,0,12.7,10.4Zm0-6.34A2.642,2.642,0,1,1,10.057,6.7,2.642,2.642,0,0,1,12.7,4.057Z"
      transform="translate(-6.887 -3)"
      fill="#b08bfc"
    />
  </svg>
);

export const ArrowRight = () => (
  <svg
    id="arrow-right"
    xmlns="http://www.w3.org/2000/svg"
    width="13.156"
    height="13.156"
    viewBox="0 0 13.156 13.156"
  >
    <path
      id="Vector"
      d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
      transform="translate(4.884 2.236)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M0,0H13.156V13.156H0Z"
      transform="translate(13.156 13.156) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const CheckListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g
      id="Ellipse_625"
      data-name="Ellipse 625"
      fill="none"
      stroke="currentcolor"
      strokeWidth="1"
    >
      <circle cx="6" cy="6" r="6" stroke="none" />
      <circle cx="6" cy="6" r="5.5" fill="none" />
    </g>
  </svg>
);

export const SubChecklistIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.955"
    height="11.955"
    viewBox="0 0 11.955 11.955"
  >
    <g
      id="vuesax_linear_hierarchy-3"
      data-name="vuesax/linear/hierarchy-3"
      transform="translate(-172 -252)"
    >
      <g id="hierarchy-3" transform="translate(172 252)">
        <path
          id="Vector"
          d="M2.989,1.494A1.494,1.494,0,1,1,1.494,0,1.494,1.494,0,0,1,2.989,1.494Z"
          transform="translate(0.996 0.996)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M2.989,1.494A1.494,1.494,0,1,1,1.494,0,1.494,1.494,0,0,1,2.989,1.494Z"
          transform="translate(7.97 4.483)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M2.989,1.494A1.494,1.494,0,1,1,1.494,0,1.494,1.494,0,0,1,2.989,1.494Z"
          transform="translate(0.996 7.97)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M5.479,1.992H1.992A1.783,1.783,0,0,1,0,0V3.985"
          transform="translate(2.491 3.985)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M0,0H11.955V11.955H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
);

export const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.54"
    height="11.543"
    viewBox="0 0 11.54 11.543"
  >
    <g
      id="Group_1168"
      data-name="Group 1168"
      transform="translate(-380 -231.035)"
    >
      <g
        id="Group_1167"
        data-name="Group 1167"
        transform="translate(380 231.035)"
      >
        <path
          id="Path_48"
          data-name="Path 48"
          d="M-580.489,190.5l1.062,1.061a.6.6,0,0,0-.14.082c-.8.791-1.583,1.591-2.385,2.374a.666.666,0,0,0-.2.66A2.3,2.3,0,0,0-580.5,196.3a.623.623,0,0,0,.6-.185c.788-.8,1.584-1.589,2.377-2.383.045-.045.086-.093.119-.13l1.034,1.038c-.019.021-.056.063-.1.1-.786.786-1.584,1.56-2.353,2.361a2.052,2.052,0,0,1-2.263.568,3.554,3.554,0,0,1-2.512-2.719,1.952,1.952,0,0,1,.574-1.929c.816-.8,1.619-1.614,2.427-2.423C-580.558,190.565-580.523,190.533-580.489,190.5Z"
          transform="translate(583.651 -186.252)"
          fill="currentcolor"
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M-445.954,64.172l-1.03-1.03c.032-.034.069-.076.108-.116.789-.79,1.573-1.585,2.372-2.365a.662.662,0,0,0,.2-.731,2.238,2.238,0,0,0-1.583-1.549.632.632,0,0,0-.675.19c-.783.8-1.583,1.59-2.374,2.385a.667.667,0,0,0-.087.146l-1.058-1.066c.031-.033.069-.074.109-.114.786-.786,1.583-1.56,2.353-2.361A2.049,2.049,0,0,1-445.358,57a3.554,3.554,0,0,1,2.5,2.688,1.965,1.965,0,0,1-.573,1.96C-444.276,62.482-445.112,63.329-445.954,64.172Z"
          transform="translate(454.33 -56.872)"
          fill="currentcolor"
        />
        <path
          id="Path_50"
          data-name="Path 50"
          d="M-455.014,182.59a.73.73,0,0,1-.207.526c-.709.712-1.417,1.425-2.132,2.131a.719.719,0,0,1-1.027,0,.714.714,0,0,1,0-1.026q1.057-1.074,2.132-2.131a.682.682,0,0,1,.806-.14A.706.706,0,0,1-455.014,182.59Z"
          transform="translate(462.576 -177.901)"
          fill="currentcolor"
        />
      </g>
    </g>
  </svg>
);
export const NewTab = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.89"
    height="9.89"
    viewBox="0 0 9.89 9.89"
  >
    <g id="_25._Open" data-name="25. Open" transform="translate(-2 -2)">
      <path
        id="Path_10694"
        data-name="Path 10694"
        d="M10.9,5.956v4.45a.494.494,0,0,1-.494.494H3.483a.494.494,0,0,1-.494-.494V3.483a.494.494,0,0,1,.494-.494h4.45a.494.494,0,1,0,0-.989H3.483A1.483,1.483,0,0,0,2,3.483v6.923A1.483,1.483,0,0,0,3.483,11.89h6.923a1.483,1.483,0,0,0,1.483-1.483V5.956a.494.494,0,1,0-.989,0Z"
        transform="translate(0)"
        fill="currentcolor"
      />
      <path
        id="Path_10695"
        data-name="Path 10695"
        d="M15.943,2H14.459a.494.494,0,0,0,0,.989h.292l-3.61,3.6a.5.5,0,0,0,.7.7l3.6-3.61v.292a.494.494,0,0,0,.989,0V2.494A.494.494,0,0,0,15.943,2Z"
        transform="translate(-4.548)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
export const CopyId = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.61"
    height="12.612"
    viewBox="0 0 12.61 12.612"
  >
    <g id="Group_1171" data-name="Group 1171" transform="translate(0.25 0.25)">
      <path
        id="Path_51"
        data-name="Path 51"
        d="M56.622,571.751c0-.7,0-1.4,0-2.107a1.447,1.447,0,0,0-1.553-1.551h-4.25a1.442,1.442,0,0,0-1.533,1.534q0,2.134,0,4.269a1.441,1.441,0,0,0,1.516,1.534q2.152.007,4.3,0a1.438,1.438,0,0,0,1.516-1.516C56.626,573.192,56.622,572.472,56.622,571.751Zm-3.675-3.082h2.162a.865.865,0,0,1,.935.931q.005,2.163,0,4.325a.86.86,0,0,1-.922.926q-2.171.006-4.342,0a.862.862,0,0,1-.917-.931q0-2.153,0-4.307a.868.868,0,0,1,.94-.944Z"
        transform="translate(-49.285 -568.092)"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.5"
      />
      <path
        id="Path_52"
        data-name="Path 52"
        d="M-75.554,703.9q0-1.081,0-2.162a1.432,1.432,0,0,0-1.465-1.511c-.738-.013-1.477,0-2.216,0-.224,0-.358.117-.354.294s.135.28.366.281c.7,0,1.405,0,2.108,0a.88.88,0,0,1,.985.991q0,2.117,0,4.234a.873.873,0,0,1-.962.959h-4.269a.87.87,0,0,1-.951-.952c0-.691,0-1.381,0-2.072a1.355,1.355,0,0,0-.006-.18.265.265,0,0,0-.278-.253.266.266,0,0,0-.284.247.985.985,0,0,0-.009.162c0,.691,0,1.381,0,2.072a1.447,1.447,0,0,0,1.553,1.553h4.251a1.444,1.444,0,0,0,1.532-1.536Q-75.553,704.966-75.554,703.9Z"
        transform="translate(87.664 -695.453)"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export const ArrowRightLg = () => (
  <svg width="7.326" height="27.327" viewBox="0 0 7.326 27.327">
    <g
      id="Group_58793"
      data-name="Group 58793"
      transform="translate(-9058.837 -1961.837)"
    >
      <line
        id="Line_50"
        data-name="Line 50"
        x2="6"
        y2="13"
        transform="translate(9059.5 1962.5)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <line
        id="Line_51"
        data-name="Line 51"
        y1="13"
        x2="6"
        transform="translate(9059.5 1975.5)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const SetProgress = () => (
  <svg
    id="Group_59659"
    data-name="Group 59659"
    width="14.055"
    height="8.438"
    viewBox="0 0 14.055 8.438"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4036"
          data-name="Rectangle 4036"
          width="14.055"
          height="8.438"
          transform="translate(0 0)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_59658" data-name="Group 59658" clipPath="url(#clip-path)">
      <path
        id="Path_10596"
        data-name="Path 10596"
        d="M4.018,3.165h.5a1.508,1.508,0,0,1,0,3.016h-.5a1.508,1.508,0,1,1,0-3.016"
        fill="currentcolor"
      />
      <path
        id="Path_10597"
        data-name="Path 10597"
        d="M12.94,5.426a2.76,2.76,0,0,1-2.648,2.012H3.766a2.765,2.765,0,1,1,0-5.53H8.033v-1H3.766a3.765,3.765,0,1,0,0,7.53h6.526a3.764,3.764,0,0,0,3.689-3.012Z"
        fill="currentcolor"
      />
      <path
        id="Path_10598"
        data-name="Path 10598"
        d="M12.084,5.376a.444.444,0,0,1-.378-.216l-.031-.055a.553.553,0,0,0-1.041,0L10.6,5.16a.441.441,0,0,1-.377.216.417.417,0,0,1-.215-.06l-.5-.287a.5.5,0,0,1-.185-.683.625.625,0,0,0,.058-.634A.624.624,0,0,0,8.8,3.444a.5.5,0,0,1-.5-.5V2.432a.5.5,0,0,1,.5-.5.626.626,0,0,0,.578-.268.622.622,0,0,0-.059-.631A.5.5,0,0,1,9.507.347l.5-.287A.414.414,0,0,1,10.225,0,.428.428,0,0,1,10.6.215l.032.055a.628.628,0,0,0,.522.367A.623.623,0,0,0,11.669.27L11.7.215A.441.441,0,0,1,12.078,0a.423.423,0,0,1,.216.059l.5.288a.5.5,0,0,1,.185.683.626.626,0,0,0-.056.634.629.629,0,0,0,.578.267.507.507,0,0,1,.5.5v.51a.5.5,0,0,1-.5.5.627.627,0,0,0-.578.268.628.628,0,0,0,.059.634.5.5,0,0,1-.185.683l-.5.287a.424.424,0,0,1-.216.06M11.153,1.7a.993.993,0,1,0,.993.993.994.994,0,0,0-.993-.993"
        fill="currentcolor"
      />
    </g>
  </svg>
);

export const Download = () => (
  <svg width="16.021" height="15.2" viewBox="0 0 16.021 15.2">
    <g
      id="Group_59903"
      data-name="Group 59903"
      transform="translate(-1771 -204)"
    >
      <g id="Group_59902" data-name="Group 59902">
        <path
          id="Path_10623"
          data-name="Path 10623"
          d="M19.366,4.583a.616.616,0,0,1,.616.616v9.785a.616.616,0,1,1-1.232,0V5.2A.616.616,0,0,1,19.366,4.583Z"
          transform="translate(1759.645 199.417)"
          fill="currentcolor"
          fillRule="evenodd"
        />
        <path
          id="Path_10624"
          data-name="Path 10624"
          d="M12.347,18.931a.616.616,0,0,1,.871,0l2.81,2.8,2.81-2.8a.616.616,0,0,1,.87.873l-3.015,3.006h0a.949.949,0,0,1-1.317.014l-.014-.013L12.348,19.8A.616.616,0,0,1,12.347,18.931Z"
          transform="translate(1762.982 192.233)"
          fill="currentcolor"
          fillRule="evenodd"
        />
        <path
          id="Path_10625"
          data-name="Path 10625"
          d="M4.366,25.05a.616.616,0,0,1,.616.616v1.553A1.714,1.714,0,0,0,6.7,28.928H16.822a1.714,1.714,0,0,0,1.717-1.709V25.666a.616.616,0,1,1,1.232,0v1.553a2.946,2.946,0,0,1-2.95,2.941H6.7a2.946,2.946,0,0,1-2.95-2.941V25.666a.616.616,0,0,1,.616-.616Z"
          transform="translate(1767.25 189.039)"
          fill="currentcolor"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
export const ArrowRightIcon = () => (
  <svg
    id="arrow-right"
    xmlns="http://www.w3.org/2000/svg"
    width="13.156"
    height="13.156"
    viewBox="0 0 13.156 13.156"
  >
    <path
      id="Vector"
      d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
      transform="translate(4.884 2.236)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M0,0H13.156V13.156H0Z"
      transform="translate(13.156 13.156) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);
