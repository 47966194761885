import PropTypes from "prop-types";

import Counter from "../../components/DashBoard/Counter";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";

const CounterDashboard = ({ data, isLoading }) => {
  return (
    <div className="row h-100">
      {isLoading
        ? Array?.from({ length: 6 }).map((num, index) => (
            <div
              key={index}
              className="col-lg-6 col-xl-4 col-md-6 mb-4 count-card-col"
            >
              <div className="count-tickets d-flex align-items-center">
                <div className="card-title-block">
                  <CommonSkeleton height={50} />
                </div>
              </div>
            </div>
          ))
        : data?.map(({ title, value, icon }, index) => (
            <Counter
              key={index}
              title={title}
              value={value || "-"}
              icon={icon}
            />
          ))}
    </div>
  );
};

CounterDashboard.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
};

export default CounterDashboard;
