import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { updateMeetingService } from "../../../../services/meetingService";
import { alertActions } from "../../alert/index";
import { meetingScheduleActions } from "..";

export const updateMeeting = createAsyncThunk(
  "meeting-update",
  async function ({ postData, id }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage, data },
      } = await updateMeetingService(postData, id);
      dispatch(
        meetingScheduleActions.setSuccessData({
          data: data?.data[0],
          type: "patch",
        }),
      );
      dispatch(meetingScheduleActions.setShowMeetingSlider(false));
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(meetingScheduleActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
