import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import sprintService from "../../../../services/sprintService";
import { sprintActions } from "../index";
import { getUserProjects } from "../../project/thunks/getUserProjects";
import { getTicketAllocations } from "../../ticket/thunks/getTicketAllocations";
import { getTicket } from "../../ticket/thunks/getTicket";

export const completeSprint = createAsyncThunk(
  "update/sprint",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data } = await sprintService.updateSprint({
        projectId: payload.projectId,
        currentSprintId: payload.currentSprintId,
        sprintId: payload.newSprintId,
      });
      dispatch(sprintActions.setTicketCount(data.data));
      dispatch(sprintActions.setCompleteSprint(data.message));
      dispatch(getUserProjects({}));
      if (payload.currentSprintId) {
        dispatch(
          getTicketAllocations({
            params: {
              sprintId: payload.currentSprintId,
            },
            key: "gantt",
            reset: true,
          }),
        );
        dispatch(
          getTicket({
            sprintId: payload.currentSprintId,
          }),
        );
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
