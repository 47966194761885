import { useSelector } from "react-redux";
import { useEffect } from "react";
import PropTypes from "prop-types";
import CustomModal from "../../components/CustomModal";
import { useThunk } from "../../hooks/useThunk";
import { PatchSprintActive } from "../../store/slices/sprint/thunks/patchSprintActive";

const ActiveSprintModal = ({
  activeSprintModal,
  setActiveSprintModal,
  sprint,
  project,
}) => {
  const handleClose = () => {
    setActiveSprintModal(false);
  };
  const [Activesprint, isPatch] = useThunk(PatchSprintActive);
  const { successMessage } = useSelector((state) => state.sprint);
  useEffect(() => {
    if (successMessage) {
      setActiveSprintModal(false);
    }
  }, [successMessage]);
  const renderBody = () => {
    return (
      <div className="text-center mx-auto">
        <div className="icons-delete">
          {/* <img src={icon} alt="trash" /> */}
        </div>
        <h5 className="modal-title fs-6" id="exampleModalLabel">
          Are you sure you want to active <br />
          <span>{sprint?.name}</span> ?
        </h5>
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <div className="btn-block">
          <button
            className="modal-btn btn-confirm btn"
            type="button"
            onClick={() => {
              Activesprint({ sprint: sprint?._id, project });
              setActiveSprintModal("");
            }}
          >
            {isPatch ? (
              <span className="btn-loader">
                <span className="spinner-border spinner-border-sm" />
              </span>
            ) : (
              <span> Yes, Continue</span>
            )}
          </button>

          <button
            className="modal-btn btn-cancel btn"
            type="button"
            onClick={handleClose}
            disabled={isPatch}
          >
            <span> Cancel</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <CustomModal
      className="modal fade delete-conf"
      dialgName="modal-dialog-centered modal-lg"
      body={renderBody()}
      footer={renderFooter()}
      bodyClassname="modal-body"
      show={activeSprintModal}
      closeModal={() => {
        setActiveSprintModal(false);
      }}
    />
  );
};

ActiveSprintModal.propTypes = {
  activeSprintModal: PropTypes.bool,
  setActiveSprintModal: PropTypes.func,
  sprint: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
  project: PropTypes.string,
};

export default ActiveSprintModal;
