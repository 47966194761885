import TaskStatusModal from "../../../../components/TaskStatusModal";
import { hasSubModulePermission } from "../../../../utils/permissionUtils";
import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";
import { ChartMenuIcon } from "./assets/svgIcons";

const RenderGanttData = ({
  formattedData,
  showStatusPopUp,
  setShowStatusPopUp,
  ticketStatus,
  selectedRows,
  setSelectedRows,
  FindStatusColor,
  updateTaskStatus,
  setSelectedTicket,
  isTicket,
  userProfile,
}) => {
  const formattedRenderData =
    formattedData &&
    formattedData.map(({ list, tickets }) => ({
      renderTitle: (
        <div className="gc-table-sub-title" key={list}>
          <div className="dwn-btn def-btn">
            <ChartMenuIcon />
          </div>
          <h4>{list}</h4>
        </div>
      ),
      title: list,
      children: tickets.map((eachChild) => ({
        ...eachChild,
        endDate: eachChild.dueDate,
        dueDate: undefined,
        renderTitle: (
          <div className="gc-content-block" key={eachChild._id}>
            <div className="chk-col">
              <input
                checked={selectedRows.some((e) => e._id === eachChild._id)}
                type="checkbox"
                className={
                  selectedRows.some((e) => e._id === eachChild._id)
                    ? "checkbox-round active"
                    : "checkbox-round"
                }
                onChange={() => {
                  if (selectedRows.some((e) => e._id === eachChild._id))
                    setSelectedRows(
                      selectedRows.filter(
                        (eachRow) => eachRow._id !== eachChild._id,
                      ),
                    );
                  else setSelectedRows([...selectedRows, eachChild]);
                }}
              />
            </div>
            <div className="det-block">
              <span
                style={{
                  backgroundColor: FindStatusColor(eachChild.ticketStatus),
                }}
                className="status status-3"
                type="button"
                onClick={(e) => {
                  if (showStatusPopUp === eachChild._id)
                    setShowStatusPopUp(null);
                  else setShowStatusPopUp(eachChild._id);
                  e.stopPropagation();
                }}
              >
                {showStatusPopUp === eachChild._id && (
                  <TaskStatusModal
                    updateTaskStatus={(ticketStatus) =>
                      updateTaskStatus(eachChild._id, { ticketStatus })
                    }
                    overlay="gantt-overlay"
                    data={ticketStatus}
                  />
                )}
              </span>

              <div className="gcInfo">
                <span
                  type="button"
                  className="ticket-title fw-light text-capitalize"
                  onClick={() => {
                    if (
                      hasSubModulePermission(
                        userProfile?.formattedPermissions,
                        permissionConstants.submodules.TASK_DETAILS_MODAL,
                      )
                    ) {
                      setSelectedTicket(eachChild.ticketInfo._id);
                      isTicket(true);
                    }
                  }}
                >
                  {eachChild.ticketInfo.title}
                </span>
                <span
                  type="button"
                  className="text-capitalize"
                  onClick={() => {
                    if (
                      hasSubModulePermission(
                        userProfile?.formattedPermissions,
                        permissionConstants.submodules.TASK_DETAILS_MODAL,
                      )
                    ) {
                      setSelectedTicket(eachChild._id);
                      isTicket(false);
                    }
                  }}
                >
                  {eachChild.title}
                </span>
              </div>
            </div>
          </div>
        ),
      })),
    }));

  if (formattedRenderData && !formattedRenderData.length)
    return [
      {
        title: "No Data Found",
        renderTitle: <div className="no-data-text">No Data Found</div>,
        disabled: true,
      },
    ];

  return formattedRenderData;
};

export default RenderGanttData;
