import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import AuthService from "../../../../services/authService";
import { authActions } from "../index";
import { setItem } from "../../../../helpers/localStorage";
import { encryptAccessToken } from "../../../../utils/encryptionUtils";
// import { siteConfig } from "../../../../config";
// import createWebSocketConnection from "../../../../helpers/socketIO";

export const auth_doLogin = createAsyncThunk(
  "auth/login",
  async function ({ token, deviceToken, deviceType, tokenType }, { dispatch }) {
    dispatch(alertActions.clear());

    try {
      const {
        data: { data: auth, message: successMessage },
      } = await AuthService.doLoginValidate(
        token.access_token,
        deviceToken.notifyToken,
        deviceType.deviceTypeData,
        tokenType,
      );
      const encryptedAccessToken =
        auth.accessToken &&
        encryptAccessToken(auth.accessToken, process.env.REACT_APP_SECRET_KEY);
      if (!encryptAccessToken) throw new Error("Something went wrong");
      setItem("accessToken", encryptedAccessToken);
      dispatch(
        authActions.setAuth({
          auth,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error?.message || "Something went wrong."
        : _.get(error, "response.data.message", "Something went wrong.");
      dispatch(authActions.setErrorMesage(errorMessage));
    }
  },
);
