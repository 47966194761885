import Gateway from "../gateway/gateway";

const postSprintService = (payload) => Gateway.post("/sprint", payload);
const getSprintService = (id) => Gateway.get(`/sprint/list?id=${id}`);
const getTicketCount = (id) => Gateway.get(`/sprint?id=${id}`);
const getHolidays = () => Gateway.get("/holiday");
const deleteSprint = (id) => Gateway.delete(`/sprint/${id}`);
const PatchSprintActiveService = (sprint, project) =>
  Gateway.patch(`/sprint/active?sprintId=${sprint}&&projectId=${project}`);
const updateSprint = ({ projectId, currentSprintId, sprintId }) =>
  Gateway.patch("/sprint", { projectId, currentSprintId, sprintId });

export default {
  postSprintService,
  getSprintService,
  getTicketCount,
  updateSprint,
  getHolidays,
  deleteSprint,
  PatchSprintActiveService,
};
