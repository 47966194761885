export const NoProj = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.002"
    height="30"
    viewBox="0 0 30.002 30"
  >
    <g
      id="Group_69575"
      data-name="Group 69575"
      transform="translate(-3681.998 24031.999)"
    >
      <g
        id="Group_69574"
        data-name="Group 69574"
        transform="translate(3329 -24381)"
      >
        <g id="hours" transform="translate(352 348)">
          <path
            id="Path_43649"
            data-name="Path 43649"
            d="M16,3.727a12.275,12.275,0,0,0-3.682,23.984l-.817,2.6A15,15,0,1,1,31,16H28.273A12.273,12.273,0,0,0,16,3.727Z"
            fill="currentcolor"
            fillRule="evenodd"
          />
          <path
            id="Path_43650"
            data-name="Path 43650"
            d="M12.091,12.091V8h2.727v5.455a1.364,1.364,0,0,1-1.364,1.364H8V12.091Z"
            transform="translate(2.545 2.545)"
            fill="currentcolor"
            fillRule="evenodd"
          />
          <path
            id="Path_43651"
            data-name="Path 43651"
            d="M12,16.364A1.364,1.364,0,0,1,13.364,15H25.636A1.364,1.364,0,0,1,27,16.364v.981l-1.677.419L19.5,19.058l-5.8-1.289L12,17.283Zm0,3.756v4.426a1.364,1.364,0,0,0,1.364,1.364H25.636A1.364,1.364,0,0,0,27,24.545v-4.39l-1.033.258-.035.008L19.8,21.786a1.365,1.365,0,0,1-.592,0l-6.136-1.364-.079-.02Z"
            transform="translate(4 5.091)"
            fill="currentcolor"
            fillRule="evenodd"
          />
          <path
            id="Path_43652"
            data-name="Path 43652"
            d="M18.091,14.727a1.364,1.364,0,0,0-1.364,1.364h4.091a1.364,1.364,0,0,0-1.364-1.364ZM14,16.091A4.091,4.091,0,0,1,18.091,12h1.364a4.091,4.091,0,0,1,4.091,4.091v1.364a1.364,1.364,0,0,1-1.364,1.364H15.364A1.364,1.364,0,0,1,14,17.455Z"
            transform="translate(4.727 4)"
            fill="currentcolor"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
);
