import { createSlice } from "@reduxjs/toolkit";

export const name = "icon";

const createReducers = () => {
  const getNotificationtList = (state, { payload }) => {
    state.notificationIconList = payload.data.data;
  };

  const singleNotification = (state, { payload }) => {
    state.singleNotification = payload.data.data;
  };

  const getTechnologyIconList = (state, { payload }) => {
    state.getTechnologyIconList = payload.data.data;
  };

  const singleTechnologyIcon = (state, { payload }) => {
    state.singleTechnologyIcon = payload;
  };

  const getKey = (state, { payload }) => {
    state.key = payload.data.data;
  };

  return {
    getTechnologyIconList,
    getNotificationtList,
    singleNotification,
    singleTechnologyIcon,
    getKey,
  };
};

function createInitialState() {
  return {
    notificationIconList: [],
    singleNotification: [],
    singleTechnologyIcon: [],
    getTechnologyIconList: [],
    key: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const iconActions = slice.actions;
export const iconReducer = slice.reducer;
