import { createSlice } from "@reduxjs/toolkit";

export const name = "allocation";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.allocationData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setAllocationData = (state, action) => {
    state.allocationData = Object.keys(action.payload).length
      ? action.payload
      : null;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setAllocationProjectData = (state, action) => {
    state.allocatioProjects = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setAllocationUserReport = (state, action) => {
    state.userReportData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setAllUsersReport = (state, action) => {
    state.allUserReportData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const clearAllUsersReport = (state) => {
    state.allUserReportData = [];
  };

  return {
    setErrorMesage,
    resetErrorMesage,
    setAllocationData,
    setAllocationProjectData,
    setAllocationUserReport,
    setAllUsersReport,
    clearAllUsersReport,
  };
};

function createInitialState() {
  return {
    errorMessage: "",
    successMessage: "",
    allocationData: null,
    allocatioProjects: [],
    userReportData: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const allocationActions = slice.actions;
export const allocationReducer = slice.reducer;
