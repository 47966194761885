import * as XLSX from "xlsx";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../components/CustomTable";
import { withTitle } from "../../hoc/withTitle";
import SubHeader from "../../structure/SubHeader/SubHeader";
import { getAllActivityLog } from "../../store/slices/activitylog/thunks/getAllActivity";
import { useThunk } from "../../hooks/useThunk";
import { cols } from "./constants";
import { useFilter } from "../../context/useFilter";
import { getFilterDropdown } from "../../store/slices/filter/thunks/getFilterDropdown";
import { filterQuery } from "./filter";
import { alertActions } from "../../store/slices/alert";
import { formatDateStructure, getInitials } from "../../utils/utils";
import { activityLogActions } from "../../store/slices/activitylog";
import { getMultyUserProfile } from "../../store";
import { userProfileActions } from "../../store/slices/userProfile";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";

const ActivityLogs = () => {
  const [getActivityALLLogData, isLoading] = useThunk(getAllActivityLog);
  const { allActivityData, activityDataforExport } = useSelector(
    ({ activityLog }) => activityLog,
  );
  const { userProfile } = useSelector((state) => state.userProfile);

  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [filterData] = useThunk(getFilterDropdown);
  const [activityPagination, setActivityPagination] = useState({
    currentPage: 1,
  });
  const dispatch = useDispatch();
  const { filter } = useFilter();

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.ACTIVITY_LOG,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  const handlePageNumberChange = (e) => {
    const newPageNumber = parseInt(e.target.value, 10);
    const total = allActivityData?.data.total;
    const doesPageExist = Math.ceil(total / (activityPagination?.limit ?? 10));
    if (newPageNumber >= 1 && newPageNumber <= doesPageExist) {
      setActivityPagination({
        ...activityPagination,
        currentPage: newPageNumber,
      });
    } else {
      // eslint-disable-next-line no-lonely-if, no-restricted-globals
      if (!isNaN(newPageNumber)) dispatch(alertActions.error("Page not exist"));
    }
  };

  const tableClassName = "table td-center";
  const divClassName = "table-responsive custom-scroll table-helight";

  useEffect(() => {
    fetchAllActivityList();
  }, [activityPagination]);

  useEffect(() => {
    filterData("activity");
  }, []);

  useEffect(() => {
    if (activityDataforExport) {
      exportToExcel();
      dispatch(activityLogActions.clearExportData());
    }
  }, [activityDataforExport]);

  const matches = filterQuery(filter.filter);

  const stateUpdate = useCallback(
    matches && matches[Object.keys(matches)[0]].map(({ value }) => value),
    [filter.filter],
  );
  useEffect(() => {
    if (filter?.search !== null || activityPagination.limit) {
      if (activityPagination?.currentPage > 1) {
        setActivityPagination({ ...activityPagination, currentPage: 1 });
      } else {
        fetchAllActivityList();
      }
    }
  }, [
    filter?.search,
    activityPagination.limit,
    stateUpdate,
    filter.me,
    filter.users,
  ]);

  const fetchAllActivityList = () => {
    const { currentPage: page, limit = 10 } = activityPagination;
    const params = {
      page,
      limit,
      search: filter?.search?.trim(),
      matches: filter.filter,
      me: filter?.me,
      users: filter?.users,
      sortOrder: activityPagination.order,
      sortBy: activityPagination.column,
    };
    if (!params.search || !params.search.trim().length) {
      delete params.search;
    }
    if (!params.me || !params.me.length) delete params.me;
    if (!params.users || !params.users.length) delete params.users;
    getActivityALLLogData({ ...params, matches });
  };

  const setSortingOrder = (column) => {
    setActivityPagination((prevSorting) => {
      if (prevSorting?.column === column) {
        return {
          ...prevSorting,
          order: prevSorting.order === "ASC" ? "DESC" : "ASC",
        };
      }
      return {
        column,
        order: "DESC",
      };
    });
  };

  const onLimitChange = (newLimit) => {
    setActivityPagination({
      ...activityPagination,
      limit: newLimit,
      currentPage: 1,
    });
  };

  const exportToExcel = async () => {
    const data = activityDataforExport?.data.data || [];

    const dataWithHeaders = [
      ["Category", "User", "Action", "Remark", "Date & Time"],
      ...data.map((item) => [
        item?.payload?.category || "-",
        item?.payload?.userName || "-",
        item?.payload?.remarks || "-",
        item?.payload?.action || "-",
        formatDateStructure(item.createdAt) || "-",
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    const columnWidths = [
      { wch: 15 }, // Category
      { wch: 15 }, // User
      { wch: 40 }, // Action
      { wch: 30 }, // Remark
      { wch: 20 }, // Date & Time
    ];

    worksheet["!cols"] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "ActivityLog");

    const fileName = "activity_log.xlsx";
    XLSX.writeFile(wb, fileName);
  };
  const userProfileData = (e, id) => {
    e.stopPropagation();
    getMultyUsers(id);
    dispatch(userProfileActions.setShowUserProfile(true)); // Open the profile modal
  };
  const renderImage = ({ row, index }) => {
    return (
      <div
        className="tbl-cont "
        style={{ cursor: "pointer" }}
        onClick={(e) => userProfileData(e, row?.payload?.userId)}
      >
        <div className="name-block align-items-center d-flex">
          <span className={`name-ltr bg-${(index % 4) + 1}`}>
            {getInitials(row?.payload?.userName)}
          </span>
          <div className="">{row?.payload?.userName}</div>
        </div>
      </div>
    );
  };

  const handleExport = () => {
    const params = {
      page: 1,
      limit: activityPagination?.limit,
      search: filter?.search?.trim(),
      matches: filter.filter,
      me: filter?.me,
      users: filter?.users,
      sortOrder: activityPagination.order,
      sortBy: activityPagination.column,
      forReport: true, // Add the forReport parameter
    };
    if (!params.search || !params.search.trim().length) {
      delete params.search;
    }
    if (!params.me || !params.me.length) delete params.me;
    if (!params.users || !params.users.length) delete params.users;
    getActivityALLLogData({ ...params, matches });
  };

  const visibleColumns = cols(hasPermission).filter(
    ({ permission }) => permission,
  );

  const enhancedCols = visibleColumns?.map((col) => {
    const enhancedCol = { ...col };
    if (col.selector === "userName") {
      enhancedCol.cell = renderImage;
    }
    return enhancedCol;
  });

  return (
    <>
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.ACTIVITY_SEARCH,
        )}
        showFilter={hasPermission(
          permissionConstants.permissions.ACTIVITY_FILTER,
        )}
        showMe={hasPermission(permissionConstants.permissions.ACTIVITY_ME)}
        showUsers={hasPermission(
          permissionConstants.permissions.ACTIVITY_ASSIGNEE,
        )}
        showExport={hasPermission(
          permissionConstants.permissions.ACTIVITY_EXPORT,
        )}
        exportFnc={handleExport}
      />
      <div className="page-contents inner-page-contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 activity-log-area">
              <div className="card-box">
                <div className="content-tables ">
                  <div className="table-helight">
                    <CustomTable
                      pagination={activityPagination}
                      isLoading={isLoading}
                      tableClassName={tableClassName}
                      divClassName={divClassName}
                      columns={enhancedCols}
                      count={allActivityData?.data.total}
                      showInput
                      data={allActivityData?.data.data}
                      showPagination
                      onLimitChange={onLimitChange}
                      handlePageNumberChange={handlePageNumberChange}
                      onPageChange={(currentPage) =>
                        setActivityPagination({
                          ...activityPagination,
                          currentPage,
                        })
                      }
                      onSortChange={(selector) => {
                        setSortingOrder(selector);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTitle(ActivityLogs);
