import Gateway from "../gateway/gateway";

const getCronjobById = (id) => Gateway.get(`/cron-job/${id}`);

const getCronjobHistoryList = ({ id, params }) =>
  Gateway.get(`/cron-job/history/${id}`, { params });

const triggerCronjob = ({ cronId }) =>
  Gateway.get("/cron-job/trigger", { params: { cronId } });

const getActivityLog = (params) =>
  Gateway.get(`/cron-job/log/${params.id}`, { params });

const cronjobLists = (params) => Gateway.get("/cron-job", { params });

const postCronjob = (id) => Gateway.post("/cron-job", id);

const updateCronjob = ({ updatedValue, id }) =>
  Gateway.patch(`/cron-job/${id}`, updatedValue);

const deleteCronjobById = (id) => Gateway.delete(`/cron-job/${id}`);

const commandListAPI = () => Gateway.get("/cron-job/cron/commands");

export default {
  cronjobLists,
  getCronjobById,
  getCronjobHistoryList,
  getActivityLog,
  postCronjob,
  deleteCronjobById,
  updateCronjob,
  triggerCronjob,
  commandListAPI,
};
