import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import technologyService from "../../../../services/technology";
import { technologyActions } from "../index";

export const getdepartmentsTechnology = createAsyncThunk(
  "technology",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { technologies },
      } = await technologyService.departmentsTechnology(payload);
      dispatch(technologyActions.setDepartmentTechnology(technologies));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(technologyActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
