import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import todoService from "../../../../services/todoService";
import { todoActions } from "../index";

export const deleteAttachments = createAsyncThunk(
  "delete/attachments",
  async function ({ editId, attachmentId }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await todoService.deleteAttachments(editId, attachmentId);
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(todoActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
