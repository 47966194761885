export const FileUpload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.662"
    height="11.51"
    viewBox="0 0 14.662 11.51"
  >
    <g id="cloud-computing" transform="translate(0 -55.031)">
      <g id="Group_1262" data-name="Group 1262" transform="translate(0 55.032)">
        <g id="Group_1261" data-name="Group 1261" transform="translate(0 0)">
          <path
            id="Path_4026"
            data-name="Path 4026"
            d="M12.216,58.846a5.04,5.04,0,0,0-9.914.861,2.749,2.749,0,0,0,.458,5.461H5.051v-.916H2.76a1.833,1.833,0,0,1,0-3.665.458.458,0,0,0,.458-.458,4.123,4.123,0,0,1,8.169-.8.458.458,0,0,0,.389.367,2.291,2.291,0,0,1-.312,4.559H9.632v.916h1.833a3.207,3.207,0,0,0,.751-6.322Z"
            transform="translate(0 -55.032)"
            fill="currentcolor"
          />
          <path
            id="Path_4027"
            data-name="Path 4027"
            d="M182.868,249.144l-1.833,1.833.646.646,1.054-1.049v4.394h.916v-4.394l1.049,1.049.646-.646-1.833-1.833A.458.458,0,0,0,182.868,249.144Z"
            transform="translate(-175.851 -243.457)"
            fill="currentcolor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const ToolTip = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="info"
    width="10.24"
    height="10.24"
    viewBox="0 0 10.24 10.24"
  >
    <g id="Group_59384" data-name="Group 59384">
      <g id="Group_59383" data-name="Group 59383">
        <path
          id="Path_10390"
          data-name="Path 10390"
          d="M5.12,0a5.12,5.12,0,1,0,5.12,5.12A5.117,5.117,0,0,0,5.12,0Zm0,9.526A4.406,4.406,0,1,1,9.526,5.12,4.411,4.411,0,0,1,5.12,9.526Z"
          fill="currentcolor"
        />
      </g>
    </g>
    <g
      id="Group_59386"
      data-name="Group 59386"
      transform="translate(4.601 4.268)"
    >
      <g id="Group_59385" data-name="Group 59385">
        <path
          id="Path_10391"
          data-name="Path 10391"
          d="M230.578,213.4c-.3,0-.519.128-.519.317v2.568c0,.162.216.323.519.323s.526-.162.526-.323v-2.568C231.1,213.525,230.868,213.4,230.578,213.4Z"
          transform="translate(-230.059 -213.397)"
          fill="currentcolor"
        />
      </g>
    </g>
    <g
      id="Group_59388"
      data-name="Group 59388"
      transform="translate(4.568 2.684)"
    >
      <g id="Group_59387" data-name="Group 59387">
        <path
          id="Path_10392"
          data-name="Path 10392"
          d="M228.928,134.208a.486.486,0,1,0,.546.479A.522.522,0,0,0,228.928,134.208Z"
          transform="translate(-228.375 -134.208)"
          fill="currentcolor"
        />
      </g>
    </g>
  </svg>
);
