export const RightDoubleArrow = () => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <title />
    <g data-name="Layer 2" id="Layer_2">
      <path
        className="cls-1"
        d="M16.88,15.53,7,5.66A1,1,0,0,0,5.59,7.07l9.06,9.06-8.8,8.8a1,1,0,0,0,0,1.41h0a1,1,0,0,0,1.42,0l9.61-9.61A.85.85,0,0,0,16.88,15.53Z"
      />
      <path
        className="cls-1"
        d="M26.46,15.53,16.58,5.66a1,1,0,0,0-1.41,1.41l9.06,9.06-8.8,8.8a1,1,0,0,0,0,1.41h0a1,1,0,0,0,1.41,0l9.62-9.61A.85.85,0,0,0,26.46,15.53Z"
      />
    </g>
  </svg>
);

export const TrashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g
      id="Group_60042"
      data-name="Group 60042"
      transform="translate(-1748 -184)"
    >
      <circle
        id="Ellipse_73"
        data-name="Ellipse 73"
        cx="13"
        cy="13"
        r="13"
        transform="translate(1748 184)"
        fill="#de4841"
        opacity="0.18"
      />
      <g id="Icon" transform="translate(1752.75 189.75)">
        <path
          id="Path_45"
          data-name="Path 45"
          d="M12.983,5.673a.423.423,0,0,0-.423-.423H4.673a.423.423,0,0,0-.423.423v7.606A1.831,1.831,0,0,0,6.081,15.11h5.071a1.831,1.831,0,0,0,1.831-1.831Zm-.845.423v7.184a.987.987,0,0,1-.986.986H6.081a.987.987,0,0,1-.986-.986V6.1Z"
          transform="translate(-0.437 -1.746)"
          fill="#fa4343"
          fillRule="evenodd"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M12.243,4.349a.423.423,0,0,0,.423-.423V2.8A1.549,1.549,0,0,0,11.117,1.25H8.3A1.549,1.549,0,0,0,6.75,2.8V3.926a.423.423,0,0,0,.423.423ZM11.821,3.5H7.6V2.8a.7.7,0,0,1,.7-.7h2.817a.7.7,0,0,1,.7.7Z"
          transform="translate(-1.528)"
          fill="#fa4343"
          fillRule="evenodd"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M12.687,5.25H3.673a.423.423,0,0,0,0,.845h9.015a.423.423,0,1,0,0-.845Z"
          transform="translate(0 -1.746)"
          fill="#fa4343"
          fillRule="evenodd"
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M8.75,10.173v3.944a.423.423,0,0,0,.845,0V10.173a.423.423,0,0,0-.845,0Z"
          transform="translate(-2.401 -3.711)"
          fill="#fa4343"
          fillRule="evenodd"
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M13.75,10.173v3.944a.423.423,0,0,0,.845,0V10.173a.423.423,0,0,0-.845,0Z"
          transform="translate(-4.584 -3.711)"
          fill="#fa4343"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export const AddFilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="add"
    width="10.461"
    height="10.461"
    viewBox="0 0 10.461 10.461"
  >
    <g id="Group_60005" data-name="Group 60005">
      <g id="Group_60004" data-name="Group 60004">
        <path
          id="Path_10408"
          data-name="Path 10408"
          d="M5.23,0a5.23,5.23,0,1,0,5.23,5.23A5.236,5.236,0,0,0,5.23,0Zm0,9.651a4.42,4.42,0,1,1,4.42-4.42A4.425,4.425,0,0,1,5.23,9.651Z"
          fill="#ab7ff5"
        />
      </g>
    </g>
    <g
      id="Group_60007"
      data-name="Group 60007"
      transform="translate(2.8 2.763)"
    >
      <g id="Group_60006" data-name="Group 60006">
        <path
          id="Path_10409"
          data-name="Path 10409"
          d="M141.479,137.264h-1.621v-1.621a.405.405,0,1,0-.81,0v1.621h-1.621a.405.405,0,1,0,0,.81h1.621v1.621a.405.405,0,1,0,.81,0v-1.621h1.621a.405.405,0,0,0,0-.81Z"
          transform="translate(-137.022 -135.238)"
          fill="#ab7ff5"
        />
      </g>
    </g>
  </svg>
);

export const ClearAllIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.517"
    height="13.651"
    viewBox="0 0 12.517 13.651"
  >
    <g
      id="Group_1188"
      data-name="Group 1188"
      transform="translate(-1003.026 -544.101)"
    >
      <path
        id="Path_62"
        data-name="Path 62"
        d="M1004.17,551.947c0-1.4,0-2.805,0-4.208,0-.182-.046-.248-.232-.234a5.885,5.885,0,0,1-.6,0c-.221,0-.31-.1-.315-.32,0-.153,0-.306,0-.459,0-.259.091-.349.347-.349.824,0,1.648,0,2.473,0a.26.26,0,0,0,.294-.19c.163-.421.339-.837.52-1.251a1.3,1.3,0,0,1,1.264-.836q1.366-.01,2.732,0a1.294,1.294,0,0,1,1.248.83q.275.619.521,1.25a.259.259,0,0,0,.289.2c.811-.01,1.622-.005,2.433,0,.32,0,.393.076.394.4,0,.146,0,.293,0,.439a.265.265,0,0,1-.287.287c-.219,0-.439.011-.658,0-.163-.01-.2.052-.2.205.005,1.5,0,3,0,4.507q0,1.864,0,3.729a1.99,1.99,0,0,1-.46,1.326,1.264,1.264,0,0,1-1.026.48c-2.373-.006-4.746,0-7.119,0a1.487,1.487,0,0,1-1.572-1.281,2.92,2.92,0,0,1-.051-.574C1004.168,554.579,1004.17,553.263,1004.17,551.947Zm1.138-4.425c0,.059-.009.092-.009.125q0,4.148,0,8.3a1.424,1.424,0,0,0,.091.425.354.354,0,0,0,.39.26c2.3-.008,4.6-.005,6.9-.005.384,0,.479-.075.558-.461a1.478,1.478,0,0,0,.028-.3q0-4.058,0-8.116v-.227Zm1.989-1.156h3.976c-.145-.351-.274-.685-.425-1.007a.3.3,0,0,0-.227-.12q-1.335-.013-2.67,0a.3.3,0,0,0-.224.124C1007.574,545.684,1007.444,546.016,1007.3,546.366Z"
        transform="translate(0)"
        fill="#DE4841"
      />
      <path
        id="Path_63"
        data-name="Path 63"
        d="M1089.834,675.258q0,1.236,0,2.473c0,.286-.083.37-.363.373-.146,0-.293,0-.439,0-.231,0-.324-.095-.325-.33,0-.572,0-1.143,0-1.715q0-1.625,0-3.25c0-.315.077-.391.385-.392.14,0,.279,0,.419,0,.232.005.324.1.325.33Q1089.836,674,1089.834,675.258Z"
        transform="translate(-82.26 -123.195)"
        fill="#DE4841"
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M1202.843,675.26q0-1.236,0-2.473c0-.286.083-.37.363-.373.146,0,.292,0,.439,0,.233.005.324.094.326.33,0,.552,0,1.1,0,1.655q0,1.655,0,3.31c0,.315-.076.391-.384.393-.14,0-.279,0-.419,0-.232-.005-.324-.1-.325-.33C1202.842,676.935,1202.844,676.1,1202.843,675.26Z"
        transform="translate(-191.847 -123.195)"
        fill="#DE4841"
      />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M1146.912,675.278q0,1.236,0,2.473c0,.287-.081.368-.365.37q-.2,0-.4,0c-.281,0-.364-.084-.364-.372q0-1.276,0-2.552,0-1.2,0-2.393c0-.286.083-.369.366-.371q.2,0,.4,0c.281,0,.363.085.364.373Q1146.914,674.042,1146.912,675.278Z"
        transform="translate(-137.063 -123.212)"
        fill="#DE4841"
      />
    </g>
  </svg>
);

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.453"
    height="12.842"
    viewBox="0 0 10.453 12.842"
  >
    <g
      id="Group_59899"
      data-name="Group 59899"
      transform="translate(-1844.273 -206)"
    >
      <path
        id="Path_45"
        data-name="Path 45"
        d="M13.508,5.7a.448.448,0,0,0-.448-.448H4.7A.448.448,0,0,0,4.25,5.7v8.064A1.941,1.941,0,0,0,6.191,15.7h5.376a1.941,1.941,0,0,0,1.941-1.941Zm-.9.448v7.616a1.046,1.046,0,0,1-1.045,1.045H6.191a1.046,1.046,0,0,1-1.045-1.045V6.146Z"
        transform="translate(1840.621 203.139)"
        fill="#a2a5ac"
        fillRule="evenodd"
      />
      <path
        id="Path_46"
        data-name="Path 46"
        d="M12.574,4.535a.448.448,0,0,0,.448-.448V2.893A1.642,1.642,0,0,0,11.379,1.25H8.393A1.642,1.642,0,0,0,6.75,2.893V4.087a.448.448,0,0,0,.448.448Zm-.448-.9H7.646V2.893a.746.746,0,0,1,.747-.747h2.987a.746.746,0,0,1,.747.747Z"
        transform="translate(1839.614 204.75)"
        fill="#a2a5ac"
        fillRule="evenodd"
      />
      <path
        id="Path_47"
        data-name="Path 47"
        d="M13.255,5.25H3.7a.448.448,0,0,0,0,.9h9.557a.448.448,0,1,0,0-.9Z"
        transform="translate(1841.023 203.139)"
        fill="#a2a5ac"
        fillRule="evenodd"
      />
      <path
        id="Path_48"
        data-name="Path 48"
        d="M8.75,10.2v4.181a.448.448,0,1,0,.9,0V10.2a.448.448,0,1,0-.9,0Z"
        transform="translate(1838.809 201.327)"
        fill="#a2a5ac"
        fillRule="evenodd"
      />
      <path
        id="Path_49"
        data-name="Path 49"
        d="M13.75,10.2v4.181a.448.448,0,1,0,.9,0V10.2a.448.448,0,0,0-.9,0Z"
        transform="translate(1836.795 201.327)"
        fill="#a2a5ac"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
