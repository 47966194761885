import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";

export const cols = [
  {
    name: "Name",
    selector: "user",
    icon: true,
    hasPermission:
      permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_NAME,
  },
  {
    name: "Role",
    selector: "roleName",
    icon: true,
    hasPermission:
      permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_ROLE,
  },
  {
    name: "Hours",
    selector: "allocationHours",
    icon: true,
    hasPermission:
      permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_HOURS,
  },
  {
    name: "Last Worked On",
    selector: "lastWorked",
    icon: true,
    hasPermission:
      permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_LAST_WORKED_ON,
  },
  {
    name: "Tickets Taken",
    selector: "totalTickets",
    icon: true,
    hasPermission:
      permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_TICKETS_TAKEN,
  },
  {
    name: "Tickets Completed",
    selector: "completed",
    icon: true,
    hasPermission:
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_TEAMS_TICKETS_COMPLETED,
  },
  {
    name: "Tickets To Do",
    selector: "todo",
    icon: true,
    hasPermission:
      permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_TICKETS_TODO,
  },
  {
    name: "Tickets In-progress",
    selector: "inprogress",
    icon: true,
    hasPermission:
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_TEAMS_TICKETS_IN_PROGRESS,
  },
  {
    name: "Tickets Under Review",
    selector: "readyforQA",
    icon: true,
    hasPermission:
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_TEAMS_TICKETS_UNDER_REVIEW,
  },
  {
    name: "Tickets First Approved",
    selector: "ticketsFirstApproved",
    icon: true,
    hasPermission:
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_TEAMS_TICKETS_FIRST_APPROVED,
  },
  {
    name: "Tickets Rejection Count",
    selector: "rejectionCount",
    icon: true,
    hasPermission:
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_TEAMS_TICKETS_IN_PROGRESS,
  },
  {
    name: "Performance Index",
    selector: "performanceIndex",
    icon: true,
    hasPermission:
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_TEAMS_TICKETS_UNDER_REVIEW,
  },
  {
    name: "Quality Index",
    selector: "qualityIndex",
    icon: true,
    hasPermission:
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_TEAMS_TICKETS_FIRST_APPROVED,
  },
];
