import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { getUserProjects } from "./getUserProjects";
import { patchFolder } from "../../../../services/folderService";

export const folderPatch = createAsyncThunk(
  "folder/patch",
  async function ({ id, patchData }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const response = await patchFolder(id, patchData);
      const { message: successMessage } = response.data;
      dispatch(alertActions.success(successMessage));
      dispatch(getUserProjects({}));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
