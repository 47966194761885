import Gateway from "../gateway/gateway";

const addTechnologyIcon = (data) =>
  Gateway.post("/technologyicon", data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

const getTechnologyIconList = (params) =>
  Gateway.get("/technologyicon", { params });

const getTechnologyIconById = ({ id, techId }) =>
  Gateway.get(`/technologyicon/${id}`, {
    params: {
      techId,
    },
  });

const updateTechnologyIconList = ({ id, data }) =>
  Gateway.patch(`/technologyicon/${id}`, data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

const deleteTechnologyIcon = ({ id, technologyId }) =>
  Gateway.delete(`/technologyicon/${id}`, {
    params: {
      technologyId,
    },
  });

export default {
  addTechnologyIcon,
  getTechnologyIconList,
  updateTechnologyIconList,
  getTechnologyIconById,
  deleteTechnologyIcon,
};
