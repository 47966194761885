import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteTicketAllocationData } from "../../../../services/ticketAllocationService";
import { alertActions } from "../../alert";
import { setGetState, setSuccess } from "..";
import { getTicket } from "./getTicket";
import { getTicketAllocations } from "./getTicketAllocations";
import { getUserProjects } from "../../project/thunks/getUserProjects";

export const deleteTicketAllocation = createAsyncThunk(
  "delete/ticket",
  async ({ id, listId, key, needCount }, { dispatch }) => {
    try {
      let data;
      let successMessage;
      if (Array.isArray(id)) {
        data = await Promise.all(
          id.map(async (i) => {
            const data = await deleteTicketAllocationData(i, needCount);
            return data?.data?.data?.data[0];
          }),
        );
        successMessage = "Allocations Deleted Successfully";
        dispatch(setGetState(false));
        dispatch(
          setSuccess({
            key: "delete",
            success: data,
            type: "allocation",
          }),
        );
      } else {
        const { data: responseData } = await deleteTicketAllocationData(
          id,
          needCount,
        );
        successMessage = responseData.message;
        dispatch(setGetState(false));

        dispatch(
          setSuccess({
            key: "delete",
            success: responseData?.data?.data[0],
            type: "allocation",
          }),
        );
      }

      dispatch(alertActions.success(successMessage));
      dispatch(getUserProjects({}));
      if (listId) {
        dispatch(
          getTicketAllocations({
            params: listId,
            key: key ?? "list",
            reset: true,
          }),
        );
        dispatch(getTicket(listId));
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
