import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { patchPullRequest } from "../../../../services/gitHubService";
import { getAllPullRequests } from "./getPullrequests";

export const patchPullRequests = createAsyncThunk(
  "github/deletepull",
  async (datas, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { removePr: successMessage },
      } = await patchPullRequest(
        datas?.pullNumber,
        datas?.repoName,
        datas?.owner,
        datas?.projectId,
        datas?.ticket,
      );

      await dispatch(
        getAllPullRequests({ value: datas?.repoName, owner: datas?.owner }),
      );
      await dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
