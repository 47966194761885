import axios from "axios";
import { decryptAccessToken } from "../utils/encryptionUtils";

const Gateway = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}api`,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 300000,
});
Gateway.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Gateway.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    const decryptedAccessToken =
      token && decryptAccessToken(token, process.env.REACT_APP_SECRET_KEY);
    if (decryptedAccessToken)
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${decryptedAccessToken}`,
      };
    return config;
  },
  (error) => {
    console.log("Interceptor error:", error);

    return Promise.reject(error);
  },
);

export default Gateway;
