import Gateway from "../gateway/gateway";

const addRole = (params) => Gateway.post("/designationPMS", params);
const deleteRole = (id) => Gateway.delete(`/designationPMS/${id}`);
const getRoleList = (params) => Gateway.get("/designationPMS", { params });

const getRolePermissions = ({ departmentId, roleId }) =>
  Gateway.get(`/permissions/${departmentId}/${roleId}`);
const updateRole = ({ departmentId, roleId, name, category, permissions }) =>
  Gateway.patch(`/permissions/${departmentId}/${roleId}`, {
    name,
    category,
    permissions,
  });

export default {
  getRoleList,
  getRolePermissions,
  updateRole,
  addRole,
  deleteRole,
};
