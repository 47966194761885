import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import dashboardServices from "../../../../services/dashboard";
import { dashboardActions } from "../index";

export const getDashboarTasks = createAsyncThunk(
  "dashboard-task",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await dashboardServices.dashboardTasks(payload);
      dispatch(dashboardActions.setDashboardTasks(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(dashboardActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
