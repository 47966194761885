export function EmployeeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="vuesax_bold_user"
        data-name="vuesax/bold/user"
        transform="translate(-108 -188)"
      >
        <g id="user" transform="translate(108 188)">
          <path id="Vector" d="M0,0H18V18H0Z" fill="none" opacity="0" />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M7.5,3.75A3.75,3.75,0,1,1,3.75,0,3.75,3.75,0,0,1,7.5,3.75Z"
            transform="translate(5.25 1.5)"
            fill="#53575e"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M6.818,0C3.06,0,0,2.52,0,5.625A.371.371,0,0,0,.375,6H13.26a.371.371,0,0,0,.375-.375C13.635,2.52,10.575,0,6.818,0Z"
            transform="translate(2.183 10.875)"
            fill="#53575e"
          />
        </g>
      </g>
    </svg>
  );
}
export function DepartmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="vuesax_bold_data-2"
        data-name="vuesax/bold/data-2"
        transform="translate(200 -684) rotate(90)"
      >
        <g id="data-2" transform="translate(684 182)">
          <path id="Vector" d="M0,0H18V18H0Z" fill="none" opacity="0" />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M3.75,4.5H1.5A1.5,1.5,0,0,1,0,3V1.5A1.5,1.5,0,0,1,1.5,0H3.75a1.5,1.5,0,0,1,1.5,1.5V3A1.5,1.5,0,0,1,3.75,4.5Z"
            transform="translate(1.5 1.5)"
            fill="#53575e"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M3.6,3H.9A.9.9,0,0,1,0,2.1V.9A.9.9,0,0,1,.9,0H3.6a.9.9,0,0,1,.9.9V2.1A.9.9,0,0,1,3.6,3Z"
            transform="translate(12 2.25)"
            fill="#53575e"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M3.6,3H.9A.9.9,0,0,1,0,2.1V.9A.9.9,0,0,1,.9,0H3.6a.9.9,0,0,1,.9.9V2.1A.9.9,0,0,1,3.6,3Z"
            transform="translate(12 7.875)"
            fill="#53575e"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M5.813,6.75a.563.563,0,0,0,0-1.125H3.75v-4.5H5.813A.567.567,0,0,0,6.375.563.567.567,0,0,0,5.813,0H.563A.567.567,0,0,0,0,.563a.567.567,0,0,0,.563.563H2.625v9.188a2.061,2.061,0,0,0,2.063,2.063H5.813a.563.563,0,0,0,0-1.125H4.688a.938.938,0,0,1-.937-.937V6.75Z"
            transform="translate(6.188 3.188)"
            fill="#53575e"
            opacity="0.96"
          />
          <path
            id="Vector-6"
            data-name="Vector"
            d="M3.6,3H.9A.9.9,0,0,1,0,2.1V.9A.9.9,0,0,1,.9,0H3.6a.9.9,0,0,1,.9.9V2.1A.9.9,0,0,1,3.6,3Z"
            transform="translate(12 13.5)"
            fill="#53575e"
          />
        </g>
      </g>
    </svg>
  );
}
export function ContactIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="vuesax_bold_call"
        data-name="vuesax/bold/call"
        transform="translate(-108 -188)"
      >
        <g id="call" transform="translate(108 188)">
          <path
            id="Vector"
            d="M6.788,9.712,5.4,11.1a.754.754,0,0,1-1.057.008c-.082-.083-.165-.158-.247-.24A21.31,21.31,0,0,1,2,8.415,13.371,13.371,0,0,1,.533,5.858,6.439,6.439,0,0,1,0,3.4,3.88,3.88,0,0,1,.27,1.958,3.452,3.452,0,0,1,1.132.705,2.2,2.2,0,0,1,2.693,0,1.409,1.409,0,0,1,3.3.135a1.223,1.223,0,0,1,.5.42l1.74,2.453a2.567,2.567,0,0,1,.3.525,1.185,1.185,0,0,1,.105.458,1.018,1.018,0,0,1-.158.532,2.551,2.551,0,0,1-.42.532l-.57.593a.4.4,0,0,0-.12.3A.681.681,0,0,0,4.7,6.12c.023.06.045.1.06.15a6.224,6.224,0,0,0,.7.96c.337.39.7.787,1.087,1.185.075.075.158.15.233.225A.752.752,0,0,1,6.788,9.712Z"
            transform="translate(1.5 1.5)"
            fill="#53575e"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M9.277,3.217a1.9,1.9,0,0,1-.113.638,1.832,1.832,0,0,1-.075.18,3.1,3.1,0,0,1-.51.765,3.381,3.381,0,0,1-1.23.885c-.008,0-.015.007-.022.007a3.789,3.789,0,0,1-1.44.278,6.25,6.25,0,0,1-2.445-.547A13.173,13.173,0,0,1,.862,3.938C.57,3.72.278,3.5,0,3.27L2.452.818a4.214,4.214,0,0,0,.555.36c.038.015.083.037.135.06a.518.518,0,0,0,.188.03.413.413,0,0,0,.307-.127l.57-.562a2.3,2.3,0,0,1,.54-.42A1,1,0,0,1,5.28,0a1.2,1.2,0,0,1,.458.1A2.9,2.9,0,0,1,6.263.39L8.745,2.152a1.138,1.138,0,0,1,.412.48A1.539,1.539,0,0,1,9.277,3.217Z"
            transform="translate(7.2 10.53)"
            fill="#53575e"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H18V18H0Z"
            transform="translate(18 18) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
}
export function GenderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="vuesax_bold_call"
        data-name="vuesax/bold/call"
        transform="translate(-108 -188)"
      >
        <g id="call" transform="translate(108 188)">
          <path
            id="Vector"
            d="M0,0H18V18H0Z"
            transform="translate(18 18) rotate(180)"
            fill="none"
            opacity="0"
          />
          <g id="gender-symbols-svgrepo-com" transform="translate(1.5 -26.135)">
            <path
              id="Path_9778"
              data-name="Path 9778"
              d="M125.034,43.844V42.771h.646V41.414h-.646v-.969h-1.616v.969h-.711v1.357h.711v1.061a4.127,4.127,0,1,0,1.616.012Zm-.817,6.541a2.505,2.505,0,1,1,2.506-2.505A2.508,2.508,0,0,1,124.217,50.385Z"
              transform="translate(-113.325 -8.982)"
              fill="#53575e"
            />
            <path
              id="Path_9779"
              data-name="Path 9779"
              d="M8.245,31.756A4.122,4.122,0,1,0,3.339,35.8v1.809l-.529-.456-.871,1.037,2.216,1.871L6.439,38.2l-.868-1.051-.616.495V35.79A4.133,4.133,0,0,0,8.245,31.756Zm-6.616,0a2.506,2.506,0,1,1,2.506,2.506A2.508,2.508,0,0,1,1.629,31.756Z"
              fill="#53575e"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export function BloodGrpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="vuesax_bold_call"
        data-name="vuesax/bold/call"
        transform="translate(-108 -188)"
      >
        <g id="call" transform="translate(108 188)">
          <path
            id="Vector"
            d="M0,0H18V18H0Z"
            transform="translate(18 18) rotate(180)"
            fill="none"
            opacity="0"
          />
          <g id="blood-svgrepo-com" transform="translate(-89.752 1.308)">
            <g
              id="Group_59068"
              data-name="Group 59068"
              transform="translate(94.011)"
            >
              <path
                id="Path_9780"
                data-name="Path 9780"
                d="M98.749,0s-4.738,6.314-4.738,10.709a4.706,4.706,0,0,0,4.738,4.675,4.8,4.8,0,0,0,4.738-4.675C103.681,6.69,98.749,0,98.749,0Zm.807,10.709v1.563a.807.807,0,0,1-.807.807h0a.807.807,0,0,1-.807-.807V10.709H96.369a.807.807,0,0,1-.807-.807h0a.807.807,0,0,1,.807-.807h1.563V7.519a.807.807,0,0,1,.807-.807h0a.807.807,0,0,1,.807.807V9.083h1.563a.807.807,0,0,1,.807.807V9.9a.807.807,0,0,1-.807.807Z"
                transform="translate(-94.011)"
                fill="#53575e"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function NoAllocation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="74.335"
      height="74.335"
      viewBox="0 0 74.335 74.335"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="path2553"
            d="M0-682.665H74.335v74.335H0Z"
            transform="translate(0 682.665)"
            fill="#858991"
          />
        </clipPath>
      </defs>
      <g id="g2551" transform="translate(0 0)" clipPath="url(#clip-path)">
        <g id="g2557" transform="translate(11.615 1.452)">
          <path
            id="path2559"
            d="M-11.881-47.527H-1.719a5.808,5.808,0,0,1,5.808-5.808A5.808,5.808,0,0,1,9.9-47.527H20.059a1.456,1.456,0,0,1,1.452,1.452v5.807a1.456,1.456,0,0,1-1.452,1.452H-11.881a1.456,1.456,0,0,1-1.452-1.452v-5.807A1.456,1.456,0,0,1-11.881-47.527Z"
            transform="translate(13.333 53.335)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="22.926"
            strokeWidth="2"
          />
        </g>
        <g id="g2561" transform="translate(1.451 8.711)">
          <path
            id="path2563"
            d="M-370.821,0h6.954a2.912,2.912,0,0,1,2.9,2.9V61.269a2.912,2.912,0,0,1-2.9,2.9H-413.23a2.912,2.912,0,0,1-2.9-2.9V2.9a2.912,2.912,0,0,1,2.9-2.9h7"
            transform="translate(416.134 0)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="22.926"
            strokeWidth="2"
          />
        </g>
        <g id="g2565" transform="translate(7.259 14.518)">
          <path
            id="path2567"
            d="M-124.75-430.11h-15.244v-52.557h4.094m35.411,0h4.051v52.557h-15.245"
            transform="translate(139.994 482.667)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="22.926"
            strokeWidth="2"
          />
        </g>
        <g id="g2569" transform="translate(29.036 7.259)">
          <path
            id="path2571"
            d="M0,0H0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="2"
          />
        </g>
        <g id="g2573" transform="translate(62.575 16.454)">
          <path
            id="path2575"
            d="M0,0H4.356L0,5.807H4.356"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="22.926"
            strokeWidth="2"
          />
        </g>
        <g id="g2577" transform="translate(67.076 1.452)">
          <path
            id="path2579"
            d="M0,0H5.807L0,7.743H5.807"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="22.926"
            strokeWidth="2"
          />
        </g>
        <g id="g2581" transform="translate(16.801 35.915)">
          <path
            id="path2583"
            d="M0,0A4.33,4.33,0,0,0,3.062,1.268,4.33,4.33,0,0,0,6.123,0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="22.926"
            strokeWidth="2"
          />
        </g>
        <g id="g2585" transform="translate(35.15 35.915)">
          <path
            id="path2587"
            d="M0,0A4.33,4.33,0,0,0,3.062,1.268,4.33,4.33,0,0,0,6.123,0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="22.926"
            strokeWidth="2"
          />
        </g>
        <g id="g2589" transform="translate(26.986 45.679)">
          <path
            id="path2591"
            d="M0,0H4.1"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="22.926"
            strokeWidth="2"
          />
        </g>
        <g id="g2593" transform="translate(29.036 67.075)">
          <path
            id="path2595"
            d="M0,0H0"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}

export function TimeAlertRed() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.139"
      height="17.326"
      viewBox="0 0 13.139 17.326"
    >
      <path
        id="wait"
        d="M48.426,16.137H47.407V14.311a5.8,5.8,0,0,0-2.855-4.782,1.036,1.036,0,0,1-.371-.866,1.036,1.036,0,0,1,.371-.866,5.8,5.8,0,0,0,2.855-4.782V1.189h1.019a.594.594,0,0,0,0-1.189H36.475a.594.594,0,1,0,0,1.189h1.019V3.015A5.8,5.8,0,0,0,40.349,7.8a1.036,1.036,0,0,1,.371.866,1.036,1.036,0,0,1-.371.867,5.8,5.8,0,0,0-2.855,4.782v1.826H36.475a.594.594,0,0,0,0,1.189H48.426a.595.595,0,0,0,0-1.189Zm-9.743-1.826a4.768,4.768,0,0,1,2.328-3.794,2.2,2.2,0,0,0,.9-1.854,2.195,2.195,0,0,0-.9-1.854,4.768,4.768,0,0,1-2.328-3.794V1.189h7.535V3.015A4.768,4.768,0,0,1,43.89,6.809a2.2,2.2,0,0,0-.9,1.854,2.2,2.2,0,0,0,.9,1.854,4.768,4.768,0,0,1,2.329,3.794v1.826H38.683Z"
        transform="translate(-35.881)"
        fill="#fd7878"
      />
    </svg>
  );
}

export function LikeButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.663"
      height="17.684"
      viewBox="0 0 19.663 17.684"
    >
      <path
        id="Vector"
        d="M9.645,16.083a1.98,1.98,0,0,1-1.126,0C5.885,15.184,0,11.434,0,5.077A5.062,5.062,0,0,1,5.049,0,5.009,5.009,0,0,1,9.082,2.034a5.037,5.037,0,0,1,9.082,3.042C18.163,11.434,12.278,15.184,9.645,16.083Z"
        transform="translate(0.75 0.75)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
export function CommentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.021"
      height="16.332"
      viewBox="0 0 17.021 16.332"
    >
      <g
        id="Group_59439"
        data-name="Group 59439"
        transform="translate(45.01 -1.338)"
      >
        <path
          id="Vector"
          d="M6.135,1.188H.594A.6.6,0,0,1,0,.594.6.6,0,0,1,.594,0H6.135a.6.6,0,0,1,.594.594A.6.6,0,0,1,6.135,1.188Z"
          transform="translate(-39.865 7.719)"
          fill="currentcolor"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M11.677,16.332a1.407,1.407,0,0,1-.768-.23L7.537,13.854H4.552A4.309,4.309,0,0,1,0,9.3V4.552A4.309,4.309,0,0,1,4.552,0h7.917a4.309,4.309,0,0,1,4.552,4.552V9.3a4.269,4.269,0,0,1-3.958,4.52v1.124a1.369,1.369,0,0,1-.728,1.219A1.4,1.4,0,0,1,11.677,16.332ZM4.552,1.18A3.154,3.154,0,0,0,1.188,4.544v4.75a3.154,3.154,0,0,0,3.365,3.365H7.719a.569.569,0,0,1,.333.1l3.523,2.343a.2.2,0,0,0,.309-.166V13.252a.6.6,0,0,1,.594-.594,3.154,3.154,0,0,0,3.365-3.365V4.544A3.154,3.154,0,0,0,12.477,1.18Z"
          transform="translate(-45.01 1.338)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}
export function SaveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.294"
      height="17.402"
      viewBox="0 0 15.294 17.402"
    >
      <path
        id="Vector"
        d="M10.721,0H3.065A3.072,3.072,0,0,0,0,3.065V14.255c0,1.429,1.024,2.033,2.279,1.342l3.875-2.152a1.682,1.682,0,0,1,1.485,0L11.515,15.6c1.255.7,2.279.1,2.279-1.342V3.065A3.084,3.084,0,0,0,10.721,0Z"
        transform="translate(0.75 0.75)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
export function TagUserEmoji() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 19 19"
    >
      <g
        id="Group_60192"
        data-name="Group 60192"
        transform="translate(-6605 -2178.186)"
      >
        <g
          id="vuesax_outline_user-add"
          data-name="vuesax/outline/user-add"
          transform="translate(6608.12 2180.454)"
        >
          <g id="user-add" transform="translate(0 0)">
            <path
              id="Vector"
              d="M2.818,5.635A2.818,2.818,0,1,1,5.635,2.818,2.821,2.821,0,0,1,2.818,5.635Zm0-4.9A2.083,2.083,0,1,0,4.9,2.818,2.087,2.087,0,0,0,2.818.735Z"
              transform="translate(3.063 0.613)"
              fill="currentcolor"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M.368,4.165A.37.37,0,0,1,0,3.8C0,1.705,2.053,0,4.577,0A5.339,5.339,0,0,1,6.027.2a.366.366,0,1,1-.2.706A4.639,4.639,0,0,0,4.577.735C2.46.735.735,2.107.735,3.8A.37.37,0,0,1,.368,4.165Z"
              transform="translate(1.304 6.983)"
              fill="currentcolor"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M2.328,4.655a2.3,2.3,0,0,1-1.553-.6,2.1,2.1,0,0,1-.441-.529A2.324,2.324,0,0,1,.657.706a2.325,2.325,0,0,1,3.4.069,2.323,2.323,0,0,1,.6,1.553,2.059,2.059,0,0,1-.074.549,2.089,2.089,0,0,1-.27.652A2.306,2.306,0,0,1,2.328,4.655Zm0-3.92A1.589,1.589,0,0,0,.735,2.328a1.553,1.553,0,0,0,.23.818,1.424,1.424,0,0,0,.3.363,1.57,1.57,0,0,0,1.063.417A1.6,1.6,0,0,0,3.69,3.156a1.526,1.526,0,0,0,.181-.446,1.425,1.425,0,0,0,.049-.377,1.589,1.589,0,0,0-.412-1.063A1.548,1.548,0,0,0,2.328.735Z"
              transform="translate(6.493 6.493)"
              fill="currentcolor"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M1.833.735H.368A.37.37,0,0,1,0,.368.37.37,0,0,1,.368,0H1.833A.37.37,0,0,1,2.2.368.37.37,0,0,1,1.833.735Z"
              transform="translate(7.723 8.443)"
              fill="currentcolor"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M.368,2.2A.37.37,0,0,1,0,1.833V.368A.37.37,0,0,1,.368,0,.37.37,0,0,1,.735.368V1.833A.367.367,0,0,1,.368,2.2Z"
              transform="translate(8.453 7.728)"
              fill="currentcolor"
            />
            <path
              id="Vector-6"
              data-name="Vector"
              d="M0,0H11.761V11.761H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
        <g
          id="Ellipse_624"
          data-name="Ellipse 624"
          transform="translate(6605 2178.186)"
          fill="none"
          stroke="currentcolor"
          strokeWidth="1"
        >
          <circle cx="9" cy="9" r="9" stroke="none" />
          <circle cx="9" cy="9" r="8.5" fill="none" />
        </g>
      </g>
    </svg>
  );
}
export function ExportMediaEmoji() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.677"
      height="19.816"
      viewBox="0 0 21.677 19.816"
    >
      <path
        id="Path_10709"
        data-name="Path 10709"
        d="M-1308.471,73.083a5.977,5.977,0,0,1-1.906,3.033q-5.054,5.171-10.066,10.384a5.677,5.677,0,0,1-4.389,1.926,5.924,5.924,0,0,1-5.25-4.67,5.406,5.406,0,0,1,1.658-4.808c2.689-2.719,5.4-5.412,8.109-8.115.055-.055.118-.1.174-.147l1.166,1.164c-.107.115-.219.244-.339.364q-3.936,3.939-7.874,7.876a3.563,3.563,0,0,0-1.12,3.8,3.954,3.954,0,0,0,3.235,2.821,3.633,3.633,0,0,0,3.316-1.271q3.443-3.545,6.874-7.1c1.341-1.386,2.689-2.765,4.015-4.164a2.231,2.231,0,0,0-.882-3.742,1.735,1.735,0,0,0-1.885.341c-.787.707-1.52,1.476-2.264,2.23q-3.773,3.828-7.539,7.664c-.257.261-.5.521-.3.924.264.55.77.61,1.248.126,1.478-1.5,2.936-3.018,4.424-4.506.725-.725,1.5-1.4,2.256-2.1l1.04,1.11a.727.727,0,0,1-.079.1c-2.194,2.237-4.381,4.481-6.587,6.706a2.276,2.276,0,0,1-3.14.153,2.336,2.336,0,0,1-.519-3.149,3.784,3.784,0,0,1,.433-.516q4.766-4.846,9.54-9.685a3.541,3.541,0,0,1,3.782-1.029,4.019,4.019,0,0,1,2.779,3.059,2.651,2.651,0,0,0,.092.261Z"
        transform="translate(1330.148 -68.612)"
        fill="currentcolor"
      />
    </svg>
  );
}

export function AllEmojiShow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.994"
      height="22.994"
      viewBox="0 0 22.994 22.994"
    >
      <g
        id="Group_60632"
        data-name="Group 60632"
        transform="translate(-1288 -1130.999)"
      >
        <path
          id="Path_10705"
          data-name="Path 10705"
          d="M-1033.544-144.088a11.5,11.5,0,0,1,11.5,11.6,11.5,11.5,0,0,1-11.628,11.389,11.491,11.491,0,0,1-11.364-11.628A11.494,11.494,0,0,1-1033.544-144.088Zm0,21.432a9.951,9.951,0,0,0,9.947-9.784,9.961,9.961,0,0,0-9.958-10.089,9.972,9.972,0,0,0-9.917,9.88A9.959,9.959,0,0,0-1033.549-122.656Z"
          transform="translate(2333.035 1275.087)"
          fill="currentcolor"
        />
        <path
          id="Path_10706"
          data-name="Path 10706"
          d="M-798.884,405.179a6.047,6.047,0,0,1-4.26-1.647,4.866,4.866,0,0,1-1.238-1.9.776.776,0,0,1,.472-1.015.78.78,0,0,1,.993.481,3.638,3.638,0,0,0,1.759,1.965,4.363,4.363,0,0,0,2.182.548,4.257,4.257,0,0,0,3.54-1.688,4.614,4.614,0,0,0,.45-.845.777.777,0,0,1,.687-.5.735.735,0,0,1,.736.42.793.793,0,0,1,.021.677,5.134,5.134,0,0,1-1.986,2.473,5.864,5.864,0,0,1-2.7.977C-798.468,405.154-798.714,405.166-798.884,405.179Z"
          transform="translate(2098.451 744.049)"
          fill="currentcolor"
        />
        <path
          id="Path_10707"
          data-name="Path 10707"
          d="M-785.11,145.079a1.314,1.314,0,0,1-1.323-1.312,1.321,1.321,0,0,1,1.33-1.321,1.322,1.322,0,0,1,1.311,1.328A1.316,1.316,0,0,1-785.11,145.079Z"
          transform="translate(2080.902 995.721)"
          fill="currentcolor"
        />
        <path
          id="Path_10708"
          data-name="Path 10708"
          d="M-488.763,145.082a1.317,1.317,0,0,1-1.317-1.306,1.322,1.322,0,0,1,1.324-1.327,1.322,1.322,0,0,1,1.317,1.322A1.316,1.316,0,0,1-488.763,145.082Z"
          transform="translate(1791.963 995.718)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}

export function ImageUploadEmoji() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_60634"
        data-name="Group 60634"
        transform="translate(-1327 -1116)"
      >
        <g
          id="Group_60633"
          data-name="Group 60633"
          transform="translate(0 -14)"
        >
          <path
            id="Vector"
            d="M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z"
            transform="translate(1329 1132)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
            transform="translate(1334 1136)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,6.953l4.93-3.31a2.253,2.253,0,0,1,2.64.14l.33.29a2.229,2.229,0,0,0,2.82,0L14.88.5A2.229,2.229,0,0,1,17.7.5l1.63,1.4"
            transform="translate(1329.67 1141.998)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(1327 1130)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
}

export function CommandSend() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#AC80F7"
      height="24px"
      width="24px"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 495.003 495.003"
      xmlSpace="preserve"
    >
      <g id="XMLID_51_">
        <path
          id="XMLID_53_"
          d="M164.711,456.687c0,2.966,1.647,5.686,4.266,7.072c2.617,1.385,5.799,1.207,8.245-0.468l55.09-37.616   l-67.6-32.22V456.687z"
        />
        <path
          id="XMLID_52_"
          d="M492.431,32.443c-1.513-1.395-3.466-2.125-5.44-2.125c-1.19,0-2.377,0.264-3.5,0.816L7.905,264.422   c-4.861,2.389-7.937,7.353-7.904,12.783c0.033,5.423,3.161,10.353,8.057,12.689l125.342,59.724l250.62-205.99L164.455,364.414   l156.145,74.4c1.918,0.919,4.012,1.376,6.084,1.376c1.768,0,3.519-0.322,5.186-0.977c3.637-1.438,6.527-4.318,7.97-7.956   L494.436,41.257C495.66,38.188,494.862,34.679,492.431,32.443z"
        />
      </g>
    </svg>
  );
}
