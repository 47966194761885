import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteTicketAllocationAtmData } from "../../../../services/ticketAllocationService";
import { alertActions } from "../../alert";
import { setTicketAllocationDataById } from "..";

export const deleteTicketAllocationAttachment = createAsyncThunk(
  "delete/ticketAllocationAttachment",
  async ({ id, file }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await deleteTicketAllocationAtmData(id, file);
      dispatch(setTicketAllocationDataById(data));
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
