/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import { createSlice } from "@reduxjs/toolkit";

export const name = "feed";

function createInitialState() {
  return {
    feedList: null,
    errorMessage: "",
    successMessage: "",
    feedUser: null,
    usersFeed: null,
    feedById: {},
    feedUserDetail: null,
    savedFeed: [],
    pagination: { page: 1, limit: 5 },
    noMoreFeeds: false,
  };
}

const initialState = createInitialState();
const feedSlice = createSlice({
  name,
  initialState,
  reducers: {
    setErrorMesage: (state, action) => {
      state.sprintData = null;
      state.errorMessage = action.payload;
      state.successMessage = "";
    },
    resetErrorMesage: (state) => {
      state.errorMessage = "";
    },
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },

    getFeeds: (state, action) => {
      // eslint-disable-next-line no-nested-ternary
      state.feedList = state.feedList
        ? action.payload.reset
          ? action.payload.data
          : {
              ...state.feedList,
              data: {
                updatedData: [
                  ...state.feedList.data.updatedData,
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  ...action.payload.data?.data?.updatedData,
                ],
                pagination: {
                  ...state.feedList.data.pagination,
                  ...action.payload.data?.data?.pagination,
                },
              },
            }
        : action.payload.data;
    },
    getBlogData: (state, action) => {
      state.feedUser = state.feedUser
        ? {
            ...state.feedUser,
            blog: [...state.feedUser.blog, ...action.payload.data?.blog],
            pagination: {
              ...state.feedUser?.pagination,
              ...action.payload?.data?.pagination,
            },
          }
        : action.payload.data;
    },
    getFeedUsers: (state, { payload }) => {
      state.feedUserDetail = payload.data;
    },
    savedFeedByuser: (state, { payload }) => {
      state.savedFeed = { data: payload.data };
    },
    setUsersFeedByID: (state, action) => {
      if (!action.payload.data?.data.updatedData?.length)
        state.noMoreFeeds = true;
      // eslint-disable-next-line no-nested-ternary
      state.usersFeed = state.usersFeed
        ? action.payload.reset
          ? action.payload.data
          : {
              ...state.usersFeed,
              data: {
                updatedData: [
                  ...state.usersFeed.data.updatedData,
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  ...action.payload.data?.data?.updatedData,
                ],
                pagination: {
                  ...state.usersFeed.data.pagination,
                  ...action.payload.data?.data.pagination,
                },
              },
            }
        : action.payload?.data;
      // state.usersFeed = action.payload;
    },
    postFeeds: (state, action) => {
      state.feedList.data = {
        ...state.feedList.data,
        updatedData: [action.payload, ...state.feedList.data?.updatedData],
      };
      state.successMessage = true;
    },
    Pagination: (state, { payload }) => {
      state.pagination = { ...payload.data };
    },
    setPatchedFeed: (state, { payload }) => {
      state.feedById = payload.data.updatedData;
      const { updatedData } = payload.data;

      state.feedList.data = {
        ...state.feedList.data,
        updatedData: state.feedList.data?.updatedData.map((eachTicket) => {
          if (eachTicket._id === updatedData._id) {
            return {
              ...eachTicket,
              likes: updatedData.likes,
              likeCount: updatedData.likeCount,
              saved: updatedData.saved,
              attachments: updatedData.attachments,
              title: updatedData.title,
              comments: updatedData.comments,
              commentLength: updatedData?.commentLength,
            };
          }
          return eachTicket;
        }),
      };

      state.savedFeed.data = {
        ...state.savedFeed.data,
        updatedData: state.savedFeed.data?.updatedData.map((eachTicket) => {
          if (eachTicket._id === updatedData._id) {
            return {
              ...eachTicket,
              likes: updatedData.likes,
              likeCount: updatedData.likeCount,
              saved: updatedData.saved,
              attachments: updatedData.attachments,
              title: updatedData.title,
              comments: updatedData.comments,
              commentLength: updatedData?.commentLength,
            };
          }
          return eachTicket;
        }),
      };
    },
    setFeed: (state, { payload }) => {
      console.log("ENTERRRR");
      state.feedList = {
        ...state.feedList,
        data: {
          updatedData: [...payload.data],
          pagination: {},
        },
      };
    },

    setdeleteFeed: (state, { payload }) => {
      state.feedList.data = {
        ...state.feedList.data,
        updatedData: state.feedList.data?.updatedData?.filter(
          (eachTicket) => eachTicket._id !== payload.data._id,
        ),
      };
    },
    setPatchedFeedUserProfile: (state, { payload }) => {
      const { updatedData } = payload.data;
      if (state.usersFeed) {
        state.usersFeed.data = {
          ...state.usersFeed.data,
          updatedData: state.usersFeed.data?.updatedData.map((eachTicket) => {
            if (eachTicket._id === updatedData._id) {
              return {
                ...eachTicket,
                likes: updatedData.likes,
                likeCount: updatedData.likeCount,
                saved: updatedData.saved,
                attachments: updatedData.attachments,
                title: updatedData.title,
                comments: updatedData.comments,
                commentLength: updatedData?.commentLength,
              };
            }
            return eachTicket;
          }),
        };
      }

      if (state.feedList) {
        state.feedList.data = {
          ...state.feedList.data,
          updatedData: state.feedList.data?.updatedData.map((eachTicket) => {
            if (eachTicket._id === updatedData._id) {
              return {
                ...eachTicket,
                likes: updatedData.likes,
                likeCount: updatedData.likeCount,
                saved: updatedData.saved,
                attachments: updatedData.attachments,
                title: updatedData.title,
                comments: updatedData.comments,
                commentLength: updatedData?.commentLength,
              };
            }
            return eachTicket;
          }),
        };
      }
      console.log(
        "payload",
        state.usersFeed,
        state.feedList,
        state.savedFeed.data,
      );

      if (state.savedFeed && state.feedList) {
        state.savedFeed.data = {
          ...state.savedFeed.data,
          updatedData: state.savedFeed.data?.updatedData.map((eachTicket) => {
            if (eachTicket._id === updatedData._id) {
              return {
                ...eachTicket,
                likes: updatedData.likes,
                likeCount: updatedData.likeCount,
                saved: updatedData.saved,
                attachments: updatedData.attachments,
                title: updatedData.title,
                comments: updatedData.comments,
                commentLength: updatedData?.commentLength,
              };
            }
            return eachTicket;
          }),
        };
      }
      console.log("payload", state.usersFeed, state.feedList, state.savedFeed);
    },
    setFeedById: (state, { payload }) => {
      state.feedById = payload.data;
      state.feedList.data = {
        ...state.feedList.data,
        updatedData: state.feedList.data?.updatedData.map((eachTicket) => {
          if (eachTicket._id === payload.data._id) {
            return {
              ...eachTicket,
              comments: payload.data.comments,
            };
          }
          return eachTicket;
        }),
      };
      state.savedFeed.data = {
        ...state.feedList.data,
        updatedData: state.savedFeed.data?.updatedData.map((eachTicket) => {
          if (eachTicket._id === payload.data._id) {
            return {
              ...eachTicket,
              comments: payload.data.comments,
            };
          }
          return eachTicket;
        }),
      };
    },

    resetUserFeed: (state) => {
      state.usersFeed = null;
      state.noMoreFeeds = false;
      state.pagination = { ...state.pagination, page: 1, limit: 5 };
    },
  },
});

export const {
  setErrorMesage,
  setFeed,
  resetErrorMesage,
  postFeeds,
  getFeeds,
  getFeedUsers,
  setUsersFeedByID,
  setdeleteFeed,
  setPatchedFeed,
  setFeedById,
  setPatchedFeedUserProfile,
  setSuccessMessage,
  getBlogData,
  Pagination,
  savedFeedByuser,
  resetUserFeed,
  resetSuccessMessage,
} = feedSlice.actions;
export const feed = feedSlice.reducer;

export default feed;
