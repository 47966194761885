export function ClockIcon() {
  return (
    <svg
      id="clock"
      xmlns="http://www.w3.org/2000/svg"
      width="13.619"
      height="13.619"
      viewBox="0 0 13.619 13.619"
    >
      <g
        id="Group_69471"
        data-name="Group 69471"
        transform="translate(6.282 3.159)"
      >
        <g id="Group_69470" data-name="Group 69470">
          <path
            id="Path_10746"
            data-name="Path 10746"
            d="M239.123,123.631l-1.9-1.424v-2.9a.527.527,0,1,0-1.055,0v3.165a.526.526,0,0,0,.211.422l2.11,1.582a.527.527,0,0,0,.633-.844Z"
            transform="translate(-236.169 -118.779)"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Group_69473" data-name="Group 69473">
        <g id="Group_69472" data-name="Group 69472">
          <path
            id="Path_10747"
            data-name="Path 10747"
            d="M6.809,0a6.809,6.809,0,1,0,6.809,6.809A6.817,6.817,0,0,0,6.809,0Zm0,12.564a5.754,5.754,0,1,1,5.754-5.754A5.761,5.761,0,0,1,6.809,12.564Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export function CalendarIcon() {
  return (
    <svg
      id="calendar"
      xmlns="http://www.w3.org/2000/svg"
      width="11.273"
      height="11.273"
      viewBox="0 0 11.273 11.273"
    >
      <path
        id="Path_10725"
        data-name="Path 10725"
        d="M9.82.881H8.807V.352a.352.352,0,0,0-.7,0V.881H3.171V.352a.352.352,0,0,0-.7,0V.881H1.453A1.455,1.455,0,0,0,0,2.334V9.82a1.455,1.455,0,0,0,1.453,1.453H9.82A1.455,1.455,0,0,0,11.273,9.82V2.334A1.455,1.455,0,0,0,9.82.881Zm.749,8.939a.749.749,0,0,1-.749.749H1.453A.749.749,0,0,1,.7,9.82V3.985a.11.11,0,0,1,.11-.11h9.644a.11.11,0,0,1,.11.11Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AddUsersIcon() {
  return (
    <svg
      id="user_account_people_man"
      data-name="user, account, people, man"
      xmlns="http://www.w3.org/2000/svg"
      width="11.624"
      height="13.737"
      viewBox="0 0 11.624 13.737"
    >
      <path
        id="Path_1424"
        data-name="Path 1424"
        d="M14.9,16.045a.528.528,0,1,0-.743.751,4.722,4.722,0,0,1,1.41,3.38c0,.646-1.852,1.585-4.755,1.585s-4.755-.939-4.755-1.586a4.723,4.723,0,0,1,1.392-3.362.528.528,0,1,0-.747-.747A5.773,5.773,0,0,0,5,20.176c0,1.716,2.994,2.642,5.812,2.642s5.812-.926,5.812-2.642A5.77,5.77,0,0,0,14.9,16.045Z"
        transform="translate(-5 -9.081)"
        fill="#b08bfc"
      />
      <path
        id="Path_1425"
        data-name="Path 1425"
        d="M12.7,10.4A3.7,3.7,0,1,0,9,6.7,3.7,3.7,0,0,0,12.7,10.4Zm0-6.34A2.642,2.642,0,1,1,10.057,6.7,2.642,2.642,0,0,1,12.7,4.057Z"
        transform="translate(-6.887 -3)"
        fill="#b08bfc"
      />
    </svg>
  );
}

export function ReloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.871"
      height="13.065"
      viewBox="0 0 11.871 13.065"
    >
      <g id="repeat" transform="translate(-2.009 -0.995)">
        <path
          id="Path_10732"
          data-name="Path 10732"
          d="M9.583,4.263H6.757a3.267,3.267,0,0,0-3.1,4.289.869.869,0,0,1-.173.892l-.051.051a.644.644,0,0,1-1.068-.159A4.751,4.751,0,0,1,6.757,2.779H9.583l-.517-.517a.742.742,0,0,1,1.049-1.049L11.9,3a.742.742,0,0,1,0,1.049L10.115,5.829A.742.742,0,0,1,9.066,4.78Z"
          transform="translate(0 0)"
          fill="currentcolor"
        />
        <path
          id="Path_10733"
          data-name="Path 10733"
          d="M8.031,15.772l-.517-.517H10.34A4.751,4.751,0,0,0,14.732,8.7a.644.644,0,0,0-1.068-.159l-.051.051a.869.869,0,0,0-.173.892,3.267,3.267,0,0,1-3.1,4.289H7.514l.517-.517A.742.742,0,0,0,6.982,12.2L5.2,13.989a.742.742,0,0,0,0,1.049l1.784,1.784a.742.742,0,1,0,1.049-1.049Z"
          transform="translate(-1.208 -2.98)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}

export function ReminderICon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.994"
      height="12.723"
      viewBox="0 0 13.994 12.723"
    >
      <g id="alarm-clock" transform="translate(0 -23.256)">
        <g
          id="Group_60445"
          data-name="Group 60445"
          transform="translate(1.66 24.725)"
        >
          <g
            id="Group_60444"
            data-name="Group 60444"
            transform="translate(0 0)"
          >
            <path
              id="Path_10734"
              data-name="Path 10734"
              d="M66.093,77.017a5.336,5.336,0,1,0,5.336,5.336A5.342,5.342,0,0,0,66.093,77.017Zm0,9.706a4.369,4.369,0,1,1,4.369-4.369A4.374,4.374,0,0,1,66.093,86.723Z"
              transform="translate(-60.757 -77.017)"
              fill="currentcolor"
            />
          </g>
        </g>
        <g
          id="Group_60447"
          data-name="Group 60447"
          transform="translate(6.223 27.546)"
        >
          <g id="Group_60446" data-name="Group 60446">
            <path
              id="Path_10735"
              data-name="Path 10735"
              d="M229.945,182.6h-1.29v-1.87a.484.484,0,1,0-.967,0v2.354a.484.484,0,0,0,.484.484h1.773a.484.484,0,0,0,0-.967Z"
              transform="translate(-227.688 -180.243)"
              fill="currentcolor"
            />
          </g>
        </g>
        <g
          id="Group_60449"
          data-name="Group 60449"
          transform="translate(1.999 33.399)"
        >
          <g
            id="Group_60448"
            data-name="Group 60448"
            transform="translate(0 0)"
          >
            <path
              id="Path_10736"
              data-name="Path 10736"
              d="M75.4,394.492a.484.484,0,0,0-.683.036l-1.451,1.612a.484.484,0,1,0,.719.647l1.451-1.612A.484.484,0,0,0,75.4,394.492Z"
              transform="translate(-73.143 -394.368)"
              fill="currentcolor"
            />
          </g>
        </g>
        <g
          id="Group_60451"
          data-name="Group 60451"
          transform="translate(9.576 33.399)"
        >
          <g
            id="Group_60450"
            data-name="Group 60450"
            transform="translate(0 0)"
          >
            <path
              id="Path_10737"
              data-name="Path 10737"
              d="M352.683,396.144l-1.451-1.612a.484.484,0,0,0-.719.647l1.451,1.612a.484.484,0,1,0,.719-.647Z"
              transform="translate(-350.389 -394.371)"
              fill="currentcolor"
            />
          </g>
        </g>
        <g
          id="Group_60453"
          data-name="Group 60453"
          transform="translate(0 23.256)"
        >
          <g
            id="Group_60452"
            data-name="Group 60452"
            transform="translate(0 0)"
          >
            <path
              id="Path_10738"
              data-name="Path 10738"
              d="M4.175,23.964a2.463,2.463,0,0,0-3.453,0A2.437,2.437,0,0,0,0,25.715a2.4,2.4,0,0,0,.726,1.713.484.484,0,0,0,.68,0l2.773-2.773a.484.484,0,0,0,0-.688ZM1.117,26.346a1.442,1.442,0,0,1-.15-.636,1.476,1.476,0,0,1,1.477-1.485,1.511,1.511,0,0,1,.647.146Z"
              transform="translate(0 -23.26)"
              fill="currentcolor"
            />
          </g>
        </g>
        <g
          id="Group_60455"
          data-name="Group 60455"
          transform="translate(9.673 23.256)"
        >
          <g
            id="Group_60454"
            data-name="Group 60454"
            transform="translate(0 0)"
          >
            <path
              id="Path_10739"
              data-name="Path 10739"
              d="M357.516,23.964a2.463,2.463,0,0,0-3.453,0,.484.484,0,0,0,0,.688l2.773,2.773a.483.483,0,0,0,.68,0,2.4,2.4,0,0,0,.726-1.713A2.438,2.438,0,0,0,357.516,23.964Zm-.395,2.378-1.975-1.975a1.48,1.48,0,0,1,2.124,1.339A1.442,1.442,0,0,1,357.121,26.342Z"
              transform="translate(-353.918 -23.256)"
              fill="currentcolor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function UsersAddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g
        id="Group_59404"
        data-name="Group 59404"
        transform="translate(-0.273 0.091)"
      >
        <circle
          id="Ellipse_25"
          data-name="Ellipse 25"
          cx="6"
          cy="6"
          r="6"
          transform="translate(0.273 -0.091)"
          fill="#b08bfc"
        />
        <path
          id="Icon_awesome-plus"
          data-name="Icon awesome-plus"
          d="M4.588,4.191H3V2.6a.353.353,0,0,0-.353-.353H2.294a.353.353,0,0,0-.353.353V4.191H.353A.353.353,0,0,0,0,4.544V4.9a.353.353,0,0,0,.353.353H1.941V6.838a.353.353,0,0,0,.353.353h.353A.353.353,0,0,0,3,6.838V5.25H4.588A.353.353,0,0,0,4.941,4.9V4.544A.353.353,0,0,0,4.588,4.191Z"
          transform="translate(3.803 1.189)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
