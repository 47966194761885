export default {
  modules: {
    DASHBOARD: "DASHBOARD",
    PROJECTS_MANAGEMENT: "PROJECTS_MANAGEMENT",
    REPORTS: "REPORTS",
    DOCUMENTS: "DOCUMENTS",
    NOTIFICATIONS: "NOTIFICATIONS",
    PERMISSIONS: "PERMISSIONS",
    ALLOCATION: "ALLOCATION",
    ACTIVITY_LOG: "ACTIVITY_LOG",
    TIME_SHEETS: "TIME_SHEETS",
    SETTINGS: "SETTINGS",
    SETUP: "SETUP",
    TRASH: "TRASH",
    FEED: "FEED",
    WIDGETS: "WIDGETS",
  },
  submodules: {
    BASIC_PROJECT_SETTINGS: "BASIC_PROJECT_SETTINGS",
    PROJECT_DASHBOARD: "PROJECT_DASHBOARD",
    PROJECT_MANAGEMENT_TEAMS: "PROJECT_MANAGEMENT_TEAMS",
    PROJECT_MANAGEMENT_TECHNOLOGY: "PROJECT_MANAGEMENT_TECHNOLOGY",
    COMMON_TICKET_SETTINGS: "COMMON_TICKET_SETTINGS",
    PROJECT_MANAGEMENT_LIST_VIEW: "PROJECT_MANAGEMENT_LIST_VIEW",
    PROJECT_MANAGEMENT_BOARD_VIEW: "PROJECT_MANAGEMENT_BOARD_VIEW",
    PROJECT_MANAGEMENT_GANTT_VIEW: "PROJECT_MANAGEMENT_GANTT_VIEW",
    PROJECT_MANAGEMENT_CALENDER: "PROJECT_MANAGEMENT_CALENDER",
    PROJECT_MANAGEMENT_ACTIVITY_LOG: "PROJECT_MANAGEMENT_ACTIVITY_LOG",
    BULK_ACTIONS: "BULK_ACTIONS",
    TASK_DETAILS_MODAL: "TASK_DETAILS_MODAL",
    REPORTS_DASHBOARD: "REPORTS_DASHBOARD",
    REPORTS_CALENDER: "REPORTS_CALENDER",
    REPORTS_LIST: "REPORTS_LIST",
    NOTIFICATIONS_NEW: "NOTIFICATIONS_NEW",
    NOTIFICATIONS_CLEARED: "NOTIFICATIONS_CLEARED",
    DEPARTMENT: "DEPARTMENT",
    ROLE: "ROLE",
    ALLOCATION_LIST: "ALLOCATION_LIST",
    ALLOCATION_USER_REPORT: "ALLOCATION_USER_REPORT",
    TIME_SHEET_LIST: "TIME_SHEET_LIST",
    BASIC_SETTINGS: "BASIC_SETTINGS",
    INTEGRATIONS: "INTEGRATIONS",
    WHITELABEL_DESIGN: "WHITELABEL_DESIGN",
    WHITELABEL_EMAIL_ACCOUNT: "WHITELABEL_EMAIL_ACCOUNT",
    WHITELABEL_ICONS: "WHITELABEL_ICONS",
    CRONJOB: "CRONJOB",
    PROJECT_DOCUMENTS: "PROJECT_DOCUMENTS",
    TODO: "TODO",
    EMPLOYEE_LIST: "EMPLOYEE_LIST",
    CLIENT_LIST: "CLIENT_LIST",
    MEETING_SCHEDULER: "MEETING_SCHEDULER",
    NOTES: "NOTES",
  },
  permissions: {
    DASHBOARD_SHOW_PROFILE: "DASHBOARD_SHOW_PROFILE",
    DASHBOARD_COUNTERS_PROJECTS_COUNT: "DASHBOARD_COUNTERS_PROJECTS_COUNT",
    DASHBOARD_COUNTERS_OPEN_TASKS_COUNT: "DASHBOARD_COUNTERS_OPEN_TASKS_COUNT",
    DASHBOARD_COUNTERS_PROJECTS_PRODUCTIVE_HOURS_COMPLETED:
      "DASHBOARD_COUNTERS_PROJECTS_PRODUCTIVE_HOURS_COMPLETED",
    DASHBOARD_COUNTERS_PRODUCTIVE_HOURS_DUE:
      "DASHBOARD_COUNTERS_PRODUCTIVE_HOURS_DUE",
    DASHBOARD_COUNTERS_QUALITY_INDEX_PERCENT:
      "DASHBOARD_COUNTERS_QUALITY_INDEX_PERCENT",
    DASHBOARD_COUNTERS_PERFORMANCE_INDEX_PERCENT:
      "DASHBOARD_COUNTERS_PERFORMANCE_INDEX_PERCENT",
    DASHBOARD_PERFORMANCE_INDEX_GRAPH: "DASHBOARD_PERFORMANCE_INDEX_GRAPH",
    DASHBOARD_PERFORMANCE_INDEX_GRAPH_FILTER:
      "DASHBOARD_PERFORMANCE_INDEX_GRAPH_FILTER",
    DASHBOARD_QUALITY_INDEX_GRAPH: "DASHBOARD_QUALITY_INDEX_GRAPH",
    DASHBOARD_QUALITY_INDEX_GRAPH_FILTER:
      "DASHBOARD_QUALITY_INDEX_GRAPH_FILTER",
    DASHBOARD_QUEUED_TASKS: "DASHBOARD_QUEUED_TASKS",
    DASHBOARD_ASSIGNED_TASKS_TODAY: "DASHBOARD_ASSIGNED_TASKS_TODAY",
    PROJECT_LISTING: "PROJECT_LISTING",
    PROJECT_FOLDER_LISTING: "PROJECT_FOLDER_LISTING",
    PROJECT_FOLDER_CREATE: "PROJECT_FOLDER_CREATE",
    PROJECT_FOLDER_RENAME: "PROJECT_FOLDER_RENAME",
    PROJECT_FOLDER_DELETE: "PROJECT_FOLDER_DELETE",
    PROJECT_LIST_CREATE: "PROJECT_LIST_CREATE",
    PROJECT_LIST_UPLOAD_SHEET: "PROJECT_LIST_UPLOAD_SHEET",
    PROJECT_LIST_UPDATE: "PROJECT_LIST_UPDATE",
    PROJECT_SPRINT_CREATE: "PROJECT_SPRINT_CREATE",
    PROJECT_LISTS_LISTING: "PROJECT_LISTS_LISTING",
    PROJECT_SPRINT_LISTING: "PROJECT_SPRINT_LISTING",
    PROJECT_DASHBOARD_COUNTERS_TODO_COUNT:
      "PROJECT_DASHBOARD_COUNTERS_TODO_COUNT",
    PROJECT_DASHBOARD_COUNTERS_INPROGRESS_COUNT:
      "PROJECT_DASHBOARD_COUNTERS_INPROGRESS_COUNT",
    PROJECT_DASHBOARD_COUNTERS_COMPLETED_COUNT:
      "PROJECT_DASHBOARD_COUNTERS_COMPLETED_COUNT",
    PROJECT_DASHBOARD_COUNTERS_PRODUCTIVE_BY_TOTAL_HOURS:
      "PROJECT_DASHBOARD_COUNTERS_PRODUCTIVE_BY_TOTAL_HOURS",
    PROJECT_DASHBOARD_COUNTERS_QUALITY_INDEX_PERCENT:
      "PROJECT_DASHBOARD_COUNTERS_QUALITY_INDEX_PERCENT",
    PROJECT_DASHBOARD_COUNTERS_PERFORMANCE_INDEX_PERCENT:
      "PROJECT_DASHBOARD_COUNTERS_PERFORMANCE_INDEX_PERCENT",
    PROJECT_DASHBOARD_PERFORMANCE_INDEX_GRAPH:
      "PROJECT_DASHBOARD_PERFORMANCE_INDEX_GRAPH",
    PROJECT_DASHBOARD_PERFORMANCE_INDEX_GRAPH_FILTER:
      "PROJECT_DASHBOARD_PERFORMANCE_INDEX_GRAPH_FILTER",
    PROJECT_DASHBOARD_QUALITY_INDEX_GRAPH:
      "PROJECT_DASHBOARD_QUALITY_INDEX_GRAPH",
    PROJECT_DASHBOARD_QUALITY_INDEX_GRAPH_FILTER:
      "PROJECT_DASHBOARD_QUALITY_INDEX_GRAPH_FILTER",
    PROJECT_DASHBOARD_QUEUED_TASKS: "PROJECT_DASHBOARD_QUEUED_TASKS",
    PROJECT_DASHBOARD_ASSIGNED_TASKS_TODAY:
      "PROJECT_DASHBOARD_ASSIGNED_TASKS_TODAY",
    PROJECT_DASHBOARD_RECENT_TASKS: "PROJECT_DASHBOARD_RECENT_TASKS",
    PROJECT_DASHBOARD_ASSETS: "PROJECT_DASHBOARD_ASSETS",
    PROJECT_MANAGEMENT_TEAMS_SHOW: "PROJECT_MANAGEMENT_TEAMS_SHOW",
    PROJECT_MANAGEMENT_TEAMS_ADD: "PROJECT_MANAGEMENT_TEAMS_ADD",
    PROJECT_MANAGEMENT_TEAMS_CUSTOM_FILTER:
      "PROJECT_MANAGEMENT_TEAMS_CUSTOM_FILTER",
    PROJECT_MANAGEMENT_TEAMS_EXPORT: "PROJECT_MANAGEMENT_TEAMS_EXPORT",
    PROJECT_MANAGEMENT_TEAMS_NAME: "PROJECT_MANAGEMENT_TEAMS_NAME",
    PROJECT_MANAGEMENT_TEAMS_ROLE: "PROJECT_MANAGEMENT_TEAMS_ROLE",
    PROJECT_MANAGEMENT_TEAMS_HOURS: "PROJECT_MANAGEMENT_TEAMS_HOURS",
    PROJECT_MANAGEMENT_TEAMS_LAST_WORKED_ON:
      "PROJECT_MANAGEMENT_TEAMS_LAST_WORKED_ON",
    PROJECT_MANAGEMENT_TEAMS_TICKETS_TAKEN:
      "PROJECT_MANAGEMENT_TEAMS_TICKETS_TAKEN",
    PROJECT_MANAGEMENT_TEAMS_TICKETS_COMPLETED:
      "PROJECT_MANAGEMENT_TEAMS_TICKETS_COMPLETED",
    PROJECT_MANAGEMENT_TEAMS_TICKETS_TODO:
      "PROJECT_MANAGEMENT_TEAMS_TICKETS_TODO",
    PROJECT_MANAGEMENT_TEAMS_TICKETS_IN_PROGRESS:
      "PROJECT_MANAGEMENT_TEAMS_TICKETS_IN_PROGRESS",
    PROJECT_MANAGEMENT_TEAMS_TICKETS_UNDER_REVIEW:
      "PROJECT_MANAGEMENT_TEAMS_TICKETS_UNDER_REVIEW",
    PROJECT_MANAGEMENT_TEAMS_TICKETS_FIRST_APPROVED:
      "PROJECT_MANAGEMENT_TEAMS_TICKETS_FIRST_APPROVED",
    PROJECT_MANAGEMENT_TECHNOLOGY_ADD_TECHNOLOGY:
      "PROJECT_MANAGEMENT_TECHNOLOGY_ADD_TECHNOLOGY",
    PROJECT_MANAGEMENT_TECHNOLOGY_LIST: "PROJECT_MANAGEMENT_TECHNOLOGY_LIST",
    PROJECT_MANAGEMENT_TECHNOLOGY_DELETE:
      "PROJECT_MANAGEMENT_TECHNOLOGY_DELETE",
    COMMON_TICKET_MARK_AS_COMPLETED: "COMMON_TICKET_MARK_AS_COMPLETED",
    COMMON_TICKET_LIST_ADD_TASK: "COMMON_TICKET_LIST_ADD_TASK",
    COMMON_TICKET_LIST_ASSIGNEE: "COMMON_TICKET_LIST_ASSIGNEE",
    COMMON_TICKET_LIST_ASSIGNEE_EDIT: "COMMON_TICKET_LIST_ASSIGNEE_EDIT",
    COMMON_TICKET_LIST_STATUS: "COMMON_TICKET_LIST_STATUS",
    COMMON_TICKET_LIST_STATUS_EDIT: "COMMON_TICKET_LIST_STATUS_EDIT",
    COMMON_TICKET_LIST_PRIORITY: "COMMON_TICKET_LIST_PRIORITY",
    COMMON_TICKET_LIST_PRIORITY_EDIT: "COMMON_TICKET_LIST_PRIORITY_EDIT",
    COMMON_TICKET_LIST_STARTDATE: "COMMON_TICKET_LIST_STARTDATE",
    COMMON_TICKET_LIST_STARTDATE_EDIT: "COMMON_TICKET_LIST_STARTDATE_EDIT",
    COMMON_TICKET_LIST_DUE_DATE: "COMMON_TICKET_LIST_DUE_DATE",
    COMMON_TICKET_LIST_DUE_DATE_EDIT: "COMMON_TICKET_LIST_DUE_DATE_EDIT",
    PROJECT_MANAGEMENT_LIST_SHOW: "PROJECT_MANAGEMENT_LIST_SHOW",
    COMMON_TICKET_LIST_ACTIONS_DELETE: "COMMON_TICKET_LIST_ACTIONS_DELETE",
    PROJECT_MANAGEMENT_LIST_SEARCH: "PROJECT_MANAGEMENT_LIST_SEARCH",
    PROJECT_MANAGEMENT_LIST_TASKLIST_VIEW:
      "PROJECT_MANAGEMENT_LIST_TASKLIST_VIEW",
    PROJECT_MANAGEMENT_LIST_CUSTOM_FILTER:
      "PROJECT_MANAGEMENT_LIST_CUSTOM_FILTER",
    PROJECT_MANAGEMENT_LIST_GROUP_BY: "PROJECT_MANAGEMENT_LIST_GROUP_BY",
    PROJECT_MANAGEMENT_LIST_ADD_TASK: "PROJECT_MANAGEMENT_LIST_ADD_TASK",
    PROJECT_MANAGEMENT_LIST_SHOW_BUTTON: "PROJECT_MANAGEMENT_LIST_SHOW_BUTTON",
    PROJECT_MANAGEMENT_LIST_ASSIGNEE: "PROJECT_MANAGEMENT_LIST_ASSIGNEE",
    PROJECT_MANAGEMENT_LIST_ASSIGNEE_EDIT:
      "PROJECT_MANAGEMENT_LIST_ASSIGNEE_EDIT",
    PROJECT_MANAGEMENT_LIST_DUE_DATE: "PROJECT_MANAGEMENT_LIST_DUE_DATE",
    PROJECT_MANAGEMENT_LIST_DUE_DATE_EDIT:
      "PROJECT_MANAGEMENT_LIST_DUE_DATE_EDIT",
    PROJECT_MANAGEMENT_LIST_PRIORITY: "PROJECT_MANAGEMENT_LIST_PRIORITY",
    PROJECT_MANAGEMENT_LIST_PRIORITY_EDIT:
      "PROJECT_MANAGEMENT_LIST_PRIORITY_EDIT",
    PROJECT_MANAGEMENT_LIST_STATUS: "PROJECT_MANAGEMENT_LIST_STATUS",
    PROJECT_MANAGEMENT_LIST_STATUS_EDIT: "PROJECT_MANAGEMENT_LIST_STATUS_EDIT",
    PROJECT_MANAGEMENT_LIST_DATE_CREATED:
      "PROJECT_MANAGEMENT_LIST_DATE_CREATED",
    PROJECT_MANAGEMENT_LIST_SPRINTS: "PROJECT_MANAGEMENT_LIST_SPRINTS",
    PROJECT_MANAGEMENT_LIST_TICKETID: "PROJECT_MANAGEMENT_LIST_TICKETID",
    PROJECT_MANAGEMENT_LIST_CREATEDBY: "PROJECT_MANAGEMENT_LIST_CREATEDBY",
    PROJECT_MANAGEMENT_LIST_COMMENTS: "PROJECT_MANAGEMENT_LIST_COMMENTS",
    PROJECT_MANAGEMENT_LIST_STARTDATE: "PROJECT_MANAGEMENT_LIST_STARTDATE",
    PROJECT_MANAGEMENT_LIST_STARTDATE_EDIT:
      "PROJECT_MANAGEMENT_LIST_STARTDATE_EDIT",
    PROJECT_MANAGEMENT_LIST_LISTINFO: "PROJECT_MANAGEMENT_LIST_LISTINFO",
    PROJECT_MANAGEMENT_LIST_UPDATEDAT: "PROJECT_MANAGEMENT_LIST_UPDATEDAT",
    PROJECT_MANAGEMENT_LIST_EDIT: "PROJECT_MANAGEMENT_LIST_EDIT",
    PROJECT_MANAGEMENT_LIST_VIEW: "PROJECT_MANAGEMENT_LIST_VIEW",
    PROJECT_MANAGEMENT_LIST_ACTIONS: "PROJECT_MANAGEMENT_LIST_ACTIONS",
    PROJECT_MANAGEMENT_LIST_DELETED: "PROJECT_MANAGEMENT_LIST_DELETED",
    PROJECT_MANAGEMENT_LIST_ME: "PROJECT_MANAGEMENT_LIST_ME",
    PROJECT_MANAGEMENT_LIST_ACTIONS_DELETE:
      "PROJECT_MANAGEMENT_LIST_ACTIONS_DELETE",

    PROJECT_MANAGEMENT_BOARD_SHOW: "PROJECT_MANAGEMENT_BOARD_SHOW",
    PROJECT_MANAGEMENT_BOARD_SEARCH: "PROJECT_MANAGEMENT_BOARD_SEARCH",
    PROJECT_MANAGEMENT_BOARD_CUSTOM_FILTER:
      "PROJECT_MANAGEMENT_BOARD_CUSTOM_FILTER",
    PROJECT_MANAGEMENT_BOARD_ADD_TASK: "PROJECT_MANAGEMENT_BOARD_ADD_TASK",
    PROJECT_MANAGEMENT_BOARD_SHOW_TIME_ESTIMATE:
      "PROJECT_MANAGEMENT_BOARD_SHOW_TIME_ESTIMATE",
    PROJECT_MANAGEMENT_BOARD_SHOW_TIME_ESTIMATE_EDIT:
      "PROJECT_MANAGEMENT_BOARD_SHOW_TIME_ESTIMATE_EDIT",
    PROJECT_MANAGEMENT_BOARD_GROUPBY: "PROJECT_MANAGEMENT_BOARD_GROUPBY",
    PROJECT_MANAGEMENT_BOARD_ASSIGNEES: "PROJECT_MANAGEMENT_BOARD_ASSIGNEES",
    PROJECT_MANAGEMENT_BOARD_ASSIGNEES_EDIT:
      "PROJECT_MANAGEMENT_BOARD_ASSIGNEES_EDIT",
    PROJECT_MANAGEMENT_BOARD_VIEW: "PROJECT_MANAGEMENT_BOARD_VIEW",
    PROJECT_MANAGEMENT_BOARD_PRIORITY: "PROJECT_MANAGEMENT_BOARD_PRIORITY",
    PROJECT_MANAGEMENT_BOARD_PRIORITY_EDIT:
      "PROJECT_MANAGEMENT_BOARD_PRIORITY_EDIT",
    PROJECT_MANAGEMENT_BOARD_DUEDATE: "PROJECT_MANAGEMENT_BOARD_DUEDATE",
    PROJECT_MANAGEMENT_BOARD_DUEDATE_EDIT:
      "PROJECT_MANAGEMENT_BOARD_DUEDATE_EDIT",
    PROJECT_MANAGEMENT_BOARD_ACTIONS: "PROJECT_MANAGEMENT_BOARD_ACTIONS",
    PROJECT_MANAGEMENT_BOARD_DELETE: "PROJECT_MANAGEMENT_BOARD_DELETE",
    PROJECT_MANAGEMENT_BOARD_RENAME: "PROJECT_MANAGEMENT_BOARD_RENAME",
    PROJECT_MANAGEMENT_BOARD_ME: "PROJECT_MANAGEMENT_BOARD_ME",
    PROJECT_MANAGEMENT_BOARD_TASKLIST_VIEW:
      "PROJECT_MANAGEMENT_BOARD_TASKLIST_VIEW",

    GANTT_SHOW: "GANTT_SHOW",
    GANTT_SEARCH: "GANTT_SEARCH",
    GANTT_CUSTOM_FILTER: "GANTT_CUSTOM_FILTER",
    GANTT_SORT: "GANTT_SORT",
    GANTT_ADD_TASK: "GANTT_ADD_TASK",
    GANTT_UPDATE_STATUS: "GANTT_UPDATE_STATUS",
    GANTT_UPDATE_DATES: "GANTT_UPDATE_DATES",
    PROJECT_MANAGEMENT_CALENDER_SHOW: "PROJECT_MANAGEMENT_CALENDER_SHOW",
    PROJECT_MANAGEMENT_CALENDER_SEARCH: "PROJECT_MANAGEMENT_CALENDER_SEARCH",
    PROJECT_MANAGEMENT_CALENDER_ASSIGNEES:
      "PROJECT_MANAGEMENT_CALENDER_ASSIGNEES",
    PROJECT_MANAGEMENT_CALENDER_ADD_TASK:
      "PROJECT_MANAGEMENT_CALENDER_ADD_TASK",
    PROJECT_MANAGEMENT_CALENDER_UPDATE_STATUS:
      "PROJECT_MANAGEMENT_CALENDER_UPDATE_STATUS",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_CUSTOM_FILTER:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_CUSTOM_FILTER",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_SEARCH:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_SEARCH",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_TICKET_ID:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_TICKET_ID",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_TASK_TITLE:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_TASK_TITLE",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_SPRINT:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_SPRINT",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_CATEGORY:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_CATEGORY",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_USER:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_USER",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_REMARK:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_REMARK",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_DATE_AND_TIME:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_DATE_AND_TIME",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_ME: "PROJECT_MANAGEMENT_ACTIVITY_LOG_ME",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_ASSIGNEES:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_ASSIGNEES",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_EXPORT:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_EXPORT",
    PROJECT_MANAGEMENT_ACTIVITY_LOG_LIST:
      "PROJECT_MANAGEMENT_ACTIVITY_LOG_LIST",
    BULK_ACTION_SET_ASSIGNEES: "BULK_ACTION_SET_ASSIGNEES",
    BULK_ACTION_MOVE_SUBTASKS: "BULK_ACTION_MOVE_SUBTASKS",
    BULK_ACTION_SET_DATES: "BULK_ACTION_SET_DATES",
    BULK_ACTION_SET_PRIORITY: "BULK_ACTION_SET_PRIORITY",
    BULK_ACTION_SET_DELETE: "BULK_ACTION_SET_DELETE",
    TASK_DETAILS_MODAL_BREADCRUMBS: "TASK_DETAILS_MODAL_BREADCRUMBS",
    TASK_DETAILS_MODAL_TITLE: "TASK_DETAILS_MODAL_TITLE",
    TASK_DETAILS_MODAL_DESCRIPTION: "TASK_DETAILS_MODAL_DESCRIPTION",
    TASK_DETAILS_MODAL_ESTIMATION_HOUR: "TASK_DETAILS_MODAL_ESTIMATION_HOUR",
    TASK_DETAILS_MODAL_STATUS_UPDATE: "TASK_DETAILS_MODAL_STATUS_UPDATE",
    TASK_DETAILS_MODAL_PRIORITY_UPDATE: "TASK_DETAILS_MODAL_PRIORITY_UPDATE",
    TASK_DETAILS_MODAL_ASSIGNED_USER_UPDATE:
      "TASK_DETAILS_MODAL_ASSIGNED_USER_UPDATE",
    TASK_DETAILS_MODAL_TIME_TRACKER: "TASK_DETAILS_MODAL_TIME_TRACKER",
    TASK_DETAILS_MODAL_LOGS: "TASK_DETAILS_MODAL_LOGS",
    TASK_DETAILS_MODAL_LIST_COMMENTS: "TASK_DETAILS_MODAL_LIST_COMMENTS",
    TASK_DETAILS_MODAL_ADD_COMMENTS: "TASK_DETAILS_MODAL_ADD_COMMENTS",
    TASK_DETAILS_MODAL_DELETE_COMMENTS: "TASK_DETAILS_MODAL_DELETE_COMMENTS",
    TASK_DETAILS_MODAL_GITHUB_MODAL: "TASK_DETAILS_MODAL_GITHUB_MODAL",
    TASK_DETAILS_MODAL_COMMENT_REPLY: "TASK_DETAILS_MODAL_COMMENT_REPLY",
    TASK_DETAILS_MODAL_COMMENTS_EMOJIS: "TASK_DETAILS_MODAL_COMMENTS_EMOJIS",
    TASK_DETAILS_MODAL_CUSTOMID: "TASK_DETAILS_MODAL_CUSTOMID",
    TASK_DETAILS_MODAL_COMMENTS_MENTIONS:
      "TASK_DETAILS_MODAL_COMMENTS_MENTIONS",
    TASK_DETAILS_MODAL_COMMENTS_ATTACHMENTS:
      "TASK_DETAILS_MODAL_COMMENTS_ATTACHMENTS",
    TASK_DETAILS_MODAL_ATTACHMENT_LIST: "TASK_DETAILS_MODAL_ATTACHMENT_LIST",
    TASK_DETAILS_MODAL_ATTACHMENT_UPLOAD:
      "TASK_DETAILS_MODAL_ATTACHMENT_UPLOAD",
    TASK_DETAILS_MODAL_ATTACHMENT_DELETE:
      "TASK_DETAILS_MODAL_ATTACHMENT_DELETE",
    TASK_DETAILS_MODAL_MORE_OPTIONS: "TASK_DETAILS_MODAL_MORE_OPTIONS",
    TASK_DETAILS_MODAL_COPY_LINK: "TASK_DETAILS_MODAL_COPY_LINK",
    TASK_DETAILS_MODAL_COPY_ID: "TASK_DETAILS_MODAL_COPY_ID",
    TASK_DETAILS_MODAL_NEW_TAB: "TASK_DETAILS_MODAL_NEW_TAB",
    TASK_DETAILS_MODAL_DELETE: "TASK_DETAILS_MODAL_DELETE",
    TASK_DETAILS_MODAL_CREATE_SUBTASK: "TASK_DETAILS_MODAL_CREATE_SUBTASK",
    TASK_DETAILS_MODAL_CREATE_CHECKLIST: "TASK_DETAILS_MODAL_CREATE_CHECKLIST",
    TASK_DETAILS_MODAL_CHECKLIST_LIST: "TASK_DETAILS_MODAL_CHECKLIST_LIST",
    TASK_DETAILS_MODAL_LIST_ALLOCATIONS: "TASK_DETAILS_MODAL_LIST_ALLOCATIONS",
    TASK_DETAILS_MODAL_ALLOCATIONS_HOURS:
      "TASK_DETAILS_MODAL_ALLOCATIONS_HOURS",
    TASK_DETAILS_MODAL_ALLOCATIONS_USERS:
      "TASK_DETAILS_MODAL_ALLOCATIONS_USERS",
    TASK_DETAILS_MODAL_ALLOCATIONS_DESCRIPTION:
      "TASK_DETAILS_MODAL_ALLOCATIONS_DESCRIPTION",
    TASK_DETAILS_MODAL_ALLOCATIONS_ATTACHMENTS:
      "TASK_DETAILS_MODAL_ALLOCATIONS_ATTACHMENTS",
    TASK_DETAILS_MODAL_ALLOCATIONS_FILTERS:
      "TASK_DETAILS_MODAL_ALLOCATIONS_FILTERS",
    TASK_DETAILS_MODAL_PROGRESS: "TASK_DETAILS_MODAL_PROGRESS",
    TASK_DETAILS_MODAL_CREATEDBY: "TASK_DETAILS_MODAL_CREATEDBY",
    TASK_DETAILS_MODAL_CREATEDAT: "TASK_DETAILS_MODAL_CREATEDAT",
    TASK_DETAILS_MODAL_STARTDATE: "TASK_DETAILS_MODAL_STARTDATE",
    TASK_DETAILS_MODAL_DUEDATE: "TASK_DETAILS_MODAL_DUEDATE",
    TASK_DETAILS_MODAL_WATCHERS: "TASK_DETAILS_MODAL_WATCHERS",
    REPORTS_TOTAL_HOURS_WORKED: "REPORTS_TOTAL_HOURS_WORKED",
    REPORTS_NUMBER_OF_PROJECTS_ALLOCATED:
      "REPORTS_NUMBER_OF_PROJECTS_ALLOCATED",
    REPORTS_TOTAL_TICKETS: "REPORTS_TOTAL_TICKETS",
    REPORTS_DAYS_WORKING_IN_MONTH: "REPORTS_DAYS_WORKING_IN_MONTH",
    REPORTS_NUMBER_OF_LEAVES: "REPORTS_NUMBER_OF_LEAVES",
    REPORTS_PROJECT_WISE_TASK_COMPLETION_GRAPH:
      "REPORTS_PROJECT_WISE_TASK_COMPLETION_GRAPH",
    REPORTS_PERFORMANCE_INDEX_GRAPH: "REPORTS_PERFORMANCE_INDEX_GRAPH",
    REPORTS_QUALITY_INDEX_GRAPH: "REPORTS_QUALITY_INDEX_GRAPH",
    REPORTS_ACTIVITY_LOG: "REPORTS_ACTIVITY_LOG",
    REPORTS_ACTIVITY_LOG_TASK: "REPORTS_ACTIVITY_LOG_TASK",
    REPORTS_ACTIVITY_LOG_DUE_DATE: "REPORTS_ACTIVITY_LOG_DUE_DATE",
    REPORTS_ACTIVITY_LOG_PRIORITY: "REPORTS_ACTIVITY_LOG_PRIORITY",
    REPORTS_ACTIVITY_LOG_DATE_CREATED: "REPORTS_ACTIVITY_LOG_DATE_CREATED",
    REPORTS_ACTIVITY_LOG_TIME_ESTIMATION:
      "REPORTS_ACTIVITY_LOG_TIME_ESTIMATION",
    REPORTS_ACTIVITY_LOG_SPRINT: "REPORTS_ACTIVITY_LOG_SPRINT",
    REPORTS_CALENDER_SEARCH: "REPORTS_CALENDER_SEARCH",
    REPORTS_CALENDER_CUSTOM_FILTER: "REPORTS_CALENDER_CUSTOM_FILTER",
    REPORTS_CALENDER_ASSIGNEES: "REPORTS_CALENDER_ASSIGNEES",
    REPORTS_CALENDER_EXPORT: "REPORTS_CALENDER_EXPORT",
    REPORTS_LIST_SEARCH: "REPORTS_LIST_SEARCH",
    REPORTS_LIST_CUSTOM_FILTER: "REPORTS_LIST_CUSTOM_FILTER",
    REPORTS_LIST_ASSIGNEES: "REPORTS_LIST_ASSIGNEES",
    REPORTS_LIST_EXPORT: "REPORTS_LIST_EXPORT",
    REPORTS_LIST_NAME: "REPORTS_LIST_NAME",
    REPORTS_LIST_TOTAL_HOURS_WORKED: "REPORTS_LIST_TOTAL_HOURS_WORKED",
    REPORTS_LIST_TOTAL_ACTIVE_PROJECTS: "REPORTS_LIST_TOTAL_ACTIVE_PROJECTS",
    REPORTS_LIST_TOTAL_TICKETS: "REPORTS_LIST_TOTAL_TICKETS",
    REPORTS_LIST_LAST_WORKED_ON: "REPORTS_LIST_LAST_WORKED_ON",
    REPORTS_LIST_HOURS_ALLOCATED_PER_DAY:
      "REPORTS_LIST_HOURS_ALLOCATED_PER_DAY",
    REPORTS_LIST_NO_OF_PROJECTS_ALLOCATED:
      "REPORTS_LIST_NO_OF_PROJECTS_ALLOCATED",
    REPORTS_LIST_NO_OF_QA_REJECTIONS: "REPORTS_LIST_NO_OF_QA_REJECTIONS",
    REPORTS_LIST_TOTAL_NUMBER_OF_WORKING_DAYS:
      "REPORTS_LIST_TOTAL_NUMBER_OF_WORKING_DAYS",
    REPORTS_LIST_TOTAL_NUMBER_OF_LEAVES: "REPORTS_LIST_TOTAL_NUMBER_OF_LEAVES",
    // PERMISSION FOR DOCUMENTS INSIDE PROJECT
    PROJECT_DOCUMENTS_SEARCH: "PROJECT_DOCUMENTS_SEARCH",
    PROJECT_DOCUMENTS_TAGS: "PROJECT_DOCUMENTS_TAGS",
    PROJECT_DOCUMENTS_ADD_NEW_DOC: "PROJECT_DOCUMENTS_ADD_NEW_DOC",
    PROJECT_DOCUMENTS_DATE_VIEWED: "PROJECT_DOCUMENTS_DATE_VIEWED",
    PROJECT_DOCUMENTS_LIST: "PROJECT_DOCUMENTS_LIST",
    PROJECT_DOCUMENTS_DOCS: "PROJECT_DOCUMENTS_DOCS",
    PROJECT_DOCUMENTS_LOCATED_IN: "PROJECT_DOCUMENTS_LOCATED_IN",
    PROJECT_DOCUMENTS_DESCRIPTION: "PROJECT_DOCUMENTS_DESCRIPTION",
    PROJECT_DOCUMENTS_UPLOADED_ON: "PROJECT_DOCUMENTS_UPLOADED_ON",
    PROJECT_DOCUMENTS_FILESIZE: "PROJECT_DOCUMENTS_FILESIZE",
    PROJECT_DOCUMENTS_UPLOADED_BY: "PROJECT_DOCUMENTS_UPLOADED_BY",
    PROJECT_DOCUMENTS_ACTION: "PROJECT_DOCUMENTS_ACTION",
    PROJECT_DOCUMENTS_DOWNLOAD: "PROJECT_DOCUMENTS_DOWNLOAD",
    PROJECT_DOCUMENTS_VIEW: "PROJECT_DOCUMENTS_VIEW",
    PROJECT_DOCUMENTS_DELETE: "PROJECT_DOCUMENTS_DELETE",
    NOTIFICATIONS_MARK_AS_READ: "NOTIFICATIONS_MARK_AS_READ",
    NOTIFICATIONS_MARK_All_AS_READ: "NOTIFICATIONS_MARK_All_AS_READ",
    NOTIFICATIONS_MARK_AS_UNREAD: "NOTIFICATIONS_MARK_AS_UNREAD",
    NOTIFICATIONS_CLEARED_ALL_NOTIFICATIONS:
      "NOTIFICATIONS_CLEARED_ALL_NOTIFICATIONS",
    DEPARTMENT_LIST: "DEPARTMENT_LIST",
    DEPARTMENT_SEARCH: "DEPARTMENT_SEARCH",
    DEPARTMENT_SORT: "DEPARTMENT_SORT",
    DEPARTMENT_EXPORT: "DEPARTMENT_EXPORT",
    DEPARTMENT_PERMISSIONS_UPDATE: "DEPARTMENT_PERMISSIONS_UPDATE",
    DEPARTMENT_DESCRIPTION: "DEPARTMENT_DESCRIPTION",
    DEPARTMENT_DESIGNATION: "DEPARTMENT_DESIGNATION",
    DEPARTMENT_ROLE_COUNT: "DEPARTMENT_ROLE_COUNT",
    DEPARTMENT_SKILL_COUNT: "DEPARTMENT_SKILL_COUNT",
    DISPLAY_EMPLOYEES: "DISPLAY_EMPLOYEES",
    EMPLOYEE_ID: "EMPLOYEE_ID",
    EMPLOYEE_NAME: "EMPLOYEE_NAME",
    EMPLOYEE_DESIGNATION: "EMPLOYEE_DESIGNATION",
    EMPLOYEE_DESIGNATION_UPDATE: "EMPLOYEE_DESIGNATION_UPDATE",
    EMPLOYEE_PNO: "EMPLOYEE_PNO",
    EMPLOYEE_EMAIL: "EMPLOYEE_EMAIL",
    DISPLAY_CLIENT: "DISPLAY_CLIENT",
    CLIENT_NAME: "CLIENT_NAME",
    CLIENT_PNO: "CLIENT_PNO",
    CLIENT_EMAIL: "CLIENT_EMAIL",
    ROLE_SEARCH: "ROLE_SEARCH",
    ROLE_EXPORT: "ROLE_EXPORT",
    ROLE_SORT: "ROLE_SORT",
    ROLE_PERMISSION_UPDATE: "ROLE_PERMISSION_UPDATE",
    ROLE_LIST: "ROLE_LIST",
    ROLE_NAME: "ROLE_NAME",
    ROLE_PERMISSION_DERIVE_DEPARTMENT_PERMISSIONS:
      "ROLE_PERMISSION_DERIVE_DEPARTMENT_PERMISSIONS",
    ROLE_DEPARTMENT_LABEL: "ROLE_DEPARTMENT_LABEL",
    ROLE_SHORT_KEY_LABEL: "ROLE_SHORT_KEY_LABEL",
    ALLOCATION_SEARCH: "ALLOCATION_SEARCH",
    ALLOCATION_FILTER: "ALLOCATION_FILTER",
    ALLOCATION_ME_FILTER: "ALLOCATION_ME_FILTER",
    ALLOCATION_ASSIGNEE: "ALLOCATION_ASSIGNEE",
    ALLOCATION_LIST: "ALLOCATION_LIST",
    ALLOCATION_USER_COUNT: "ALLOCATION_USER_COUNT",
    ALLOCATION_DESIGNATION: "ALLOCATION_DESIGNATION",
    ALLOCATION_PM_LEAD: "ALLOCATION_PM_LEAD",
    ALLOCATION_HOURS: "ALLOCATION_HOURS",
    ALLOCATION_OTHER_PROJECTS: "ALLOCATION_OTHER_PROJECTS",
    ALLOCATION_UPDATED_BY: "ALLOCATION_UPDATED_BY",
    ALLOCATION_TOTAL_WORKING_HOURS: "ALLOCATION_TOTAL_WORKING_HOURS",
    ALLOCATION_REMARKS: "ALLOCATION_REMARKS",
    ALLOCATION_USER_REPORT_SELECT_USERS: "ALLOCATION_USER_REPORT_SELECT_USERS",
    ALLOCATION_USER_REPORT_TIMELINE: "ALLOCATION_USER_REPORT_TIMELINE",
    ALLOCATION_USER_REPORT_COUNTERS_TOTAL_WORKING_HOURS:
      "ALLOCATION_USER_REPORT_COUNTERS_TOTAL_WORKING_HOURS",
    ALLOCATION_USER_REPORT_COUNTERS_PROJECTS_ALLOCATED_COUNT:
      "ALLOCATION_USER_REPORT_COUNTERS_PROJECTS_ALLOCATED_COUNT",
    ALLOCATION_USER_REPORT_COUNTERS_LEAD_INFO:
      "ALLOCATION_USER_REPORT_COUNTERS_LEAD_INFO",
    ALLOCATION_USER_REPORT_COUNTERS_CLIENT_INFO:
      "ALLOCATION_USER_REPORT_COUNTERS_CLIENT_INFO",
    ALLOCATION_USER_REPORT_COUNTERS_REMARKS:
      "ALLOCATION_USER_REPORT_COUNTERS_REMARKS",
    DOCUMENTS_SEARCH: "DOCUMENTS_SEARCH",
    DOCUMENTS_TAGS: "DOCUMENTS_TAGS",
    DOCUMENTS_ADD_NEW_DOC: "DOCUMENTS_ADD_NEW_DOC",
    DOCUMENTS_DATE_VIEWED: "DOCUMENTS_DATE_VIEWED",
    DOCUMENTS_LIST: "DOCUMENTS_LIST",
    DOCUMENTS_DOCS: "DOCUMENTS_DOCS",
    DOCUMENTS_LOCATED_IN: "DOCUMENTS_LOCATED_IN",
    DOCUMENTS_DESCRIPTION: "DOCUMENTS_DESCRIPTION",
    DOCUMENTS_UPLOADED_ON: "DOCUMENTS_UPLOADED_ON",
    DOCUMENTS_FILESIZE: "DOCUMENTS_FILESIZE",
    DOCUMENTS_UPLOADED_BY: "DOCUMENTS_UPLOADED_BY",
    DOCUMENTS_ACTION: "DOCUMENTS_ACTION",
    DOCUMENTS_DOWNLOAD: "DOCUMENTS_DOWNLOAD",
    DOCUMENTS_VIEW: "DOCUMENTS_VIEW",
    DOCUMENTS_DELETE: "DOCUMENTS_DELETE",
    TIME_SHEET_FILTER: "TIME_SHEET_FILTER",
    TIME_SHEET_DATEPICKER: "TIME_SHEET_DATEPICKER",
    TIME_SHEET_EXPORT_PDF: "TIME_SHEET_EXPORT_PDF",
    TIME_SHEET_EXPORT_XLX: "TIME_SHEET_EXPORT_XLX",
    TIME_SHEET_LIST_VIEW: "TIME_SHEET_LIST_VIEW",
    TIME_SHEET_LIST_USER_SELECT: "TIME_SHEET_LIST_USER_SELECT",
    TIME_SHEET_LIST_DATE: "TIME_SHEET_LIST_DATE",
    TIME_SHEET_LIST_PRODUCTIVE_HOURS: "TIME_SHEET_LIST_PRODUCTIVE_HOURS",
    TIME_SHEET_LIST_TOTAL_TICKETS: "TIME_SHEET_LIST_TOTAL_TICKETS",
    TIME_SHEET_LIST_HOURS_ALLOCATED: "TIME_SHEET_LIST_HOURS_ALLOCATED",
    TIME_SHEET_LIST_TOTAL_HOURS_WORKED: "TIME_SHEET_LIST_TOTAL_HOURS_WORKED",
    TIME_SHEET_LIST_NO_OF_PROJECTS_ALLOCATED:
      "TIME_SHEET_LIST_NO_OF_PROJECTS_ALLOCATED",
    TIME_SHEET_LIST_TODO: "TIME_SHEET_LIST_TODO",
    TIME_SHEET_LIST_COMPLETED: "TIME_SHEET_LIST_COMPLETED",
    TIME_SHEET_LIST_INPROGRESS: "TIME_SHEET_LIST_INPROGRESS",
    TIME_SHEET_LIST_PERFORMANCE_INDEX: "TIME_SHEET_LIST_PERFORMANCE_INDEX",
    TIME_SHEET_LIST_QUALITY_INDEX: "TIME_SHEET_LIST_QUALITY_INDEX",
    TIME_SHEET_LIST_QA_REJECTIONS: "TIME_SHEET_LIST_QA_REJECTIONS",
    TIME_SHEET_LIST_PROGRESSBAR: "TIME_SHEET_LIST_PROGRESSBAR",
    TIME_SHEET_LIST_COUNTERS_TODO_COUNT: "TIME_SHEET_LIST_COUNTERS_TODO_COUNT",
    TIME_SHEET_LIST_COUNTERS_INPROGRESS_COUNT:
      "TIME_SHEET_LIST_COUNTERS_INPROGRESS_COUNT",
    TIME_SHEET_LIST_COUNTERS_COMPLETED_COUNT:
      "TIME_SHEET_LIST_COUNTERS_COMPLETED_COUNT",
    TIME_SHEET_LIST_COUNTERS_PERFORMANCE_INDEX_PERCENT:
      "TIME_SHEET_LIST_COUNTERS_PERFORMANCE_INDEX_PERCENT",
    TIME_SHEET_LIST_COUNTERS_QUALITY_INDEX_PERCENT:
      "TIME_SHEET_LIST_COUNTERS_QUALITY_INDEX_PERCENT",
    TIME_SHEET_LIST_COUNTERS_HOURS_PRODUCTIVE_BY_TOTAL:
      "TIME_SHEET_LIST_COUNTERS_HOURS_PRODUCTIVE_BY_TOTAL",
    MY_SETTINGS_SHOW_PROFILE: "MY_SETTINGS_SHOW_PROFILE",
    MY_SETTINGS_SHOW_USER_NAME: "MY_SETTINGS_SHOW_USER_NAME",
    MY_SETTINGS_SHOW_PHONE_NUMBER: "MY_SETTINGS_SHOW_PHONE_NUMBER",
    MY_SETTINGS_SHOW_EMAIL_ADDRESS: "MY_SETTINGS_SHOW_EMAIL_ADDRESS",
    MY_SETTINGS_SHOW_USER_DESIGNATION: "MY_SETTINGS_SHOW_USER_DESIGNATION",
    MY_SETTINGS_SHOW_USER_DEPARTMENT: "MY_SETTINGS_SHOW_USER_DEPARTMENT",
    MY_SETTINGS_UPDATE: "MY_SETTINGS_UPDATE",
    MY_SETTINGS_YOUR_COLOR_SCHEME: "MY_SETTINGS_YOUR_COLOR_SCHEME",
    MY_SETTINGS_LANGUAGE: "MY_SETTINGS_LANGUAGE",
    MY_SETTINGS_TIME_FORMAT: "MY_SETTINGS_TIME_FORMAT",
    MY_SETTINGS_DATE_FORMAT: "MY_SETTINGS_DATE_FORMAT",
    MY_SETTINGS_FLYOUT_TOAST_MESSAGE: "MY_SETTINGS_FLYOUT_TOAST_MESSAGE",
    INTEGRATION_GITHUB: "INTEGRATION_GITHUB",
    INTEGRATION_CLOCKIFY: "INTEGRATION_CLOCKIFY",
    INTEGRATION_FIGMA: "INTEGRATION_FIGMA",
    INTEGRATION_GOOGLE_CALENDER: "INTEGRATION_GOOGLE_CALENDER",
    INTEGRATION_INSTALL_GITHUB: "INTEGRATION_INSTALL_GITHUB",
    INTEGRATION_SYNC_ACTIVITY_TO_TASKS: "INTEGRATION_SYNC_ACTIVITY_TO_TASKS",
    INTEGRATION_CREATE_BRANCHES_AND_PRS_FROM_TASKS:
      "INTEGRATION_CREATE_BRANCHES_AND_PRS_FROM_TASKS",
    GITHUB_REPO_CONNECT: "GITHUB_REPO_CONNECT",
    GITHUB_REPO_NAME: "GITHUB_REPO_NAME",
    GITHUB_PROJECT: "GITHUB_PROJECT",
    GITHUB_USERS: "GITHUB_USERS",
    GITHUB_EDIT: "GITHUB_EDIT",
    GITHUB_DELETE: "GITHUB_DELETE",
    GITHUB_FILTER: "GITHUB_FILTER",
    GITHUB_SEARCH: "GITHUB_SEARCH",
    TRASH_CLEAR_ALL: "TRASH_CLEAR_ALL",
    TRASH_SEARCH: "TRASH_SEARCH",
    TRASH_FILTER: "TRASH_FILTER",
    TRASH_ACTIONS: "TRASH_ACTIONS",
    TRASH_DELETE_FOREVER: "TRASH_DELETE_FOREVER",
    TRASH_RESTORE: "TRASH_RESTORE",
    EMAIL_ACCOUNT_LIST: "EMAIL_ACCOUNT_LIST",
    EMAIL_ACCOUNT_IMAGE: "EMAIL_ACCOUNT_IMAGE",
    EMAIL_ACCOUNT_TITLE: "EMAIL_ACCOUNT_TITLE",
    EMAIL_ACCOUNT_CREATEDBY_LABEL: "EMAIL_ACCOUNT_CREATEDBY_LABEL",
    EMAIL_ACCOUNT_ADD: "EMAIL_ACCOUNT_ADD",
    EMAIL_ACCOUNT_EDIT: "EMAIL_ACCOUNT_EDIT",
    EMAIL_ACCOUNT_DELETE: "EMAIL_ACCOUNT_DELETE",
    EMAIL_ACCOUNT_SET_DEFAULT: "EMAIL_ACCOUNT_SET_DEFAULT",
    EMAIL_ACCOUNT_EMAIL_ADDRESS: "EMAIL_ACCOUNT_EMAIL_ADDRESS",
    TECHNOLOGY_ICON_ADD: "TECHNOLOGY_ICON_ADD",
    NOTIFICATION_ICON_ADD: "NOTIFICATION_ICON_ADD",
    NOTIFICATIONS_ICON_LIST: "NOTIFICATIONS_ICON_LIST",
    NOTIFICATIONS_ICON_TITLE: "NOTIFICATIONS_ICON_TITLE",
    NOTIFICATIONS_ICON_ICON: "NOTIFICATIONS_ICON_ICON",
    NOTIFICATIONS_ICON_KEY: "NOTIFICATIONS_ICON_KEY",
    NOTIFICATIONS_ICON_EDIT: "NOTIFICATIONS_ICON_EDIT",
    NOTIFICATIONS_ICON_DELETE: "NOTIFICATIONS_ICON_DELETE",
    TECHNOLOGY_ICON_LIST: "TECHNOLOGY_ICON_LIST",
    TECHNOLOGY_ICON_DEPARTMENT_LABEL: "TECHNOLOGY_ICON_DEPARTMENT_LABEL",
    TECHNOLOGY_ICON_CATEGORY_LABEL: "TECHNOLOGY_ICON_CATEGORY_LABEL",
    TECHNOLOGY_ICON_TECHNOLOGY_LABEL: "TECHNOLOGY_ICON_TECHNOLOGY_LABEL",
    TECHNOLOGY_ICON_ICON_LABEL: "TECHNOLOGY_ICON_ICON_LABEL",
    TECHNOLOGY_ICON_EDIT: "TECHNOLOGY_ICON_EDIT",
    TECHNOLOGY_ICON_DELETE: "TECHNOLOGY_ICON_DELETE",
    CRONJOB_LIST: "CRONJOB_LIST",
    CRONJOB_TITLE_LABEL: "CRONJOB_TITLE_LABEL",
    CRONJOB_DESCRIPTION_LABEL: "CRONJOB_DESCRIPTION_LABEL",
    CRONJOB_INTERVAL_LABEL: "CRONJOB_INTERVAL_LABEL",
    CRONJOB_NEXTRUNDATE_LABEL: "CRONJOB_NEXTRUNDATE_LABEL",
    CRONJOB_LASTRUNDATE_LABEL: "CRONJOB_LASTRUNDATE_LABEL",
    CRONJOB_STARTDATE_LABEL: "CRONJOB_STARTDATE_LABEL",
    CRONJOB_ENDDATE_LABEL: "CRONJOB_ENDDATE_LABEL",
    CRONJOB_LASTERROR_LABEL: "CRONJOB_LASTERROR_LABEL",
    CRONJOB_ACTIONS: "CRONJOB_ACTIONS",
    CRONJOB_VIEW: "CRONJOB_VIEW",
    CRONJOB_DELETE: "CRONJOB_DELETE",
    CRONJOB_ADD: "CRONJOB_ADD",
    DESIGN_DISPLAY: "DESIGN_DISPLAY",
    DESIGN_PRODUCT_NAME: "DESIGN_PRODUCT_NAME",
    DESIGN_PRODUCT_NAME_EDIT: "DESIGN_PRODUCT_NAME_EDIT",
    DESIGN_DESCRIPTION: "DESIGN_DESCRIPTION",
    DESIGN_DESCRIPTION_EDIT: "DESIGN_DESCRIPTION_EDIT",
    DESIGN_DEFAULT_IMAGES: "DESIGN_DEFAULT_IMAGES",
    DESIGN_PROFILE_IMAGE: "DESIGN_PROFILE_IMAGE",
    DESIGN_PROFILE_IMAGE_EDIT: "DESIGN_PROFILE_IMAGE_EDIT",
    DESIGN_FAVICON: "DESIGN_FAVICON",
    DESIGN_FAVICON_UPDATE: "DESIGN_FAVICON_UPDATE",
    DESIGN_NO_IMAGE: "DESIGN_NO_IMAGE",
    DESIGN_NO_IMAGE_UPDATE: "DESIGN_NO_IMAGE_UPDATE",
    DESIGN_LOGO: "DESIGN_LOGO",
    DESIGN_LOGO_UPDATE: "DESIGN_LOGO_UPDATE",
    DESIGN_LOADER: "DESIGN_LOADER",
    DESIGN_LOADER_UPDATE: "DESIGN_LOADER_UPDATE",
    DESIGN_SEO: "DESIGN_SEO",
    DESIGN_META_TITLE: "DESIGN_META_TITLE",
    DESIGN_META_TITLE_EDIT: "DESIGN_META_TITLE_EDIT",
    DESIGN_META_DESCRIPTION: "DESIGN_META_DESCRIPTION",
    DESIGN_META_DESCRIPTION_EDIT: "DESIGN_META_DESCRIPTION_EDIT",
    DESIGN_META_KEYWORDS_ADD: "DESIGN_META_KEYWORDS_ADD",
    DESIGN_META_KEYWORDS_LIST: "DESIGN_META_KEYWORDS_LIST",
    DESIGN_META_KEYWORDS_DELETE: "DESIGN_META_KEYWORDS_DELETE",
    TASK_DETAILS_MODAL_GITHUB_REPOSITORY:
      "TASK_DETAILS_MODAL_GITHUB_REPOSITORY",
    TASK_DETAILS_MODAL_GITHUB_TASKID: "TASK_DETAILS_MODAL_GITHUB_TASKID",
    TASK_DETAILS_MODAL_GITHUB_COPYTASKID:
      "TASK_DETAILS_MODAL_GITHUB_COPYTASKID",
    TASK_DETAILS_MODAL_GITHUB_REASONABLE_BRANCH_NAME:
      "TASK_DETAILS_MODAL_GITHUB_REASONABLE_BRANCH_NAME",
    TASK_DETAILS_MODAL_GITHUB_COPY_REASONABLE_BRANCH_NAME:
      "TASK_DETAILS_MODAL_GITHUB_COPY_REASONABLE_BRANCH_NAME",
    TASK_DETAILS_MODAL_GITHUB_CHECKOUT_COMMAND:
      "TASK_DETAILS_MODAL_GITHUB_CHECKOUT_COMMAND",
    TASK_DETAILS_MODAL_GITHUB_COPY_CHECKOUT_COMMAND:
      "TASK_DETAILS_MODAL_GITHUB_COPY_CHECKOUT_COMMAND",
    TASK_DETAILS_MODAL_GITHUB_COMMIT_COMMAND:
      "TASK_DETAILS_MODAL_GITHUB_COMMIT_COMMAND",
    TASK_DETAILS_MODAL_GITHUB_COPY_COMMIT_COMMAND:
      "TASK_DETAILS_MODAL_GITHUB_COPY_COMMIT_COMMAND",
    TASK_DETAILS_MODAL_GITHUB_CREATE_BRANCH:
      "TASK_DETAILS_MODAL_GITHUB_CREATE_BRANCH",
    TASK_DETAILS_MODAL_GITHUB_NEW_PULL_REQUEST:
      "TASK_DETAILS_MODAL_GITHUB_NEW_PULL_REQUEST",
    TASK_DETAILS_MODAL_GITHUB_FILE_MANAGER:
      "TASK_DETAILS_MODAL_GITHUB_FILE_MANAGER",
    TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS:
      "TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS",
    TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_VIEW:
      "TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_VIEW",
    TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_CLOSE:
      "TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_CLOSE",
    TASK_DETAILS_MODAL_GITHUB_BRANCHES: "TASK_DETAILS_MODAL_GITHUB_BRANCHES",
    TASK_DETAILS_MODAL_GITHUB_BRANCHES_VIEW:
      "TASK_DETAILS_MODAL_GITHUB_BRANCHES_VIEW",
    TASK_DETAILS_MODAL_GITHUB_BRANCHES_DELETE:
      "TASK_DETAILS_MODAL_GITHUB_BRANCHES_DELETE",
    TASK_DETAILS_MODAL_GITHUB_COMMITS: "TASK_DETAILS_MODAL_GITHUB_COMMITS",
    TASK_DETAILS_MODAL_GITHUB_COMMITS_VIEW:
      "TASK_DETAILS_MODAL_GITHUB_COMMITS_VIEW",
    TASK_DETAILS_MODAL_GITHUB_COMMITS_CHANGED_FILES:
      "TASK_DETAILS_MODAL_GITHUB_COMMITS_CHANGED_FILES",
    TASK_DETAILS_MODAL_BRANCHES: "TASK_DETAILS_MODAL_BRANCHES",
    TASK_DETAILS_FILE_SEARCH: "TASK_DETAILS_FILE_SEARCH",
    TASK_DETAILS_FILE_DOWNLOAD: "TASK_DETAILS_FILE_DOWNLOAD",
    TASK_DETAILS_FILE_COPY: "TASK_DETAILS_FILE_COPY",
    TASK_DETAILS_FILE_RAW_VIEW: "TASK_DETAILS_FILE_RAW_VIEW",
    TASK_DETAILS_FILE_PATH: "TASK_DETAILS_FILE_PATH",
    TASK_DETAILS_FILE_PATH_COPY: "TASK_DETAILS_FILE_PATH_COPY",
    TASK_DETAILS_FILE_COMMIT_ID: "TASK_DETAILS_FILE_COMMIT_ID",
    TASK_DETAILS_FILE_ONWER: "TASK_DETAILS_FILE_ONWER",
    TASK_DETAILS_FILE_CONTENT_VIEW: "TASK_DETAILS_FILE_CONTENT_VIEW",
    FEED_DISPLAY_POSTS: "FEED_DISPLAY_POSTS",
    FEED_ADD_POST: "FEED_ADD_POST",
    FEED_VIEW_LIKES: "FEED_VIEW_LIKES",
    FEED_ADD_LIKES: "FEED_ADD_LIKES",
    FEED_VIEW_COMMENTS: "FEED_VIEW_COMMENTS",
    FEED_ADD_COMMENTS: "FEED_ADD_COMMENTS",
    FEED_SAVE_POSTS: "FEED_SAVE_POSTS",
    FEED_VIEW_BLOGS: "FEED_VIEW_BLOGS",
    FEED_VIEW_BIRTHDAYS: "FEED_VIEW_BIRTHDAYS",
    FEED_VIEW_WORK_ANNIVERSARY: "FEED_VIEW_WORK_ANNIVERSARY",
    // MEETING SCHEDULER
    MEETING_SCHEDULER_UPCOMING_SCHEDULE: "MEETING_SCHEDULER_UPCOMING_SCHEDULE",
    MEETING_SCHEDULER_COMPLETED: "MEETING_SCHEDULER_COMPLETED",
    MEETING_SCHEDULER_FILTER: "MEETING_SCHEDULER_FILTER",
    MEETING_SCHEDULER_ME_FILTER: "MEETING_SCHEDULER_ME_FILTER",
    MEETING_SCHEDULER_ADD: "MEETING_SCHEDULER_ADD",
    MEETING_SCHEDULER_EDIT: "MEETING_SCHEDULER_EDIT",
    MEETING_SCHEDULER_DELETE: "MEETING_SCHEDULER_DELETE",
    MEETING_SCHEDULER_MARK_COMPLETED: "MEETING_SCHEDULER_MARK_COMPLETED",
    MEETING_SCHEDULER_SEARCH: "MEETING_SCHEDULER_SEARCH",
    NOTES_LIST: "NOTES_LIST",
    NOTES_ADD: "NOTES_ADD",
    NOTES_DELETE: "NOTES_DELETE",
    NOTES_EDIT: "NOTES_EDIT",
    NOTES_EDIT_HISTORY: "NOTES_EDIT_HISTORY",
    NOTES_RENAME: "NOTES_RENAME",
    NOTES_SEARCH: "NOTES_SEARCH",
    TODO_LIST: "TODO_LIST",
    TODO_ADD: "TODO_ADD",
    TODO_MY_LISTS_LIST: "TODO_MY_LISTS_LIST",
    TODO_MY_LISTS_ADD: "TODO_MY_LISTS_ADD",
    TAG_LISTS: "TAG_LISTS",
    TAG_DELETE: "TAG_DELETE",
    TODO_SEARCH: "TODO_SEARCH",
    TODO_MY_LISTS_RENAME: "TODO_MY_LISTS_RENAME",
    TODO_MY_LISTS_CHANGE_COLOUR: "TODO_MY_LISTS_CHANGE_COLOUR",
    TODO_MY_LISTS_DELETE: "TODO_MY_LISTS_DELETE",
    ACTIVITY_SEARCH: "ACTIVITY_SEARCH",
    ACTIVITY_FILTER: "ACTIVITY_FILTER",
    ACTIVITY_ME: "ACTIVITY_ME",
    ACTIVITY_ASSIGNEE: "ACTIVITY_ASSIGNEE",
    ACTIVITY_EXPORT: "ACTIVITY_EXPORT",
    ACTIVITY_LIST: "ACTIVITY_LIST",
    ACTIVITY_CATEGORY: "ACTIVITY_CATEGORY",
    ACTIVITY_USER: "ACTIVITY_USER",
    ACTIVITY_ACTION: "ACTIVITY_ACTION",
    ACTIVITY_REMARKS: "ACTIVITY_REMARKS",
    ACTIVITY_DATE: "ACTIVITY_DATE",
  },
};
