import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import timesheetService from "../../../../services/timesheet";
import { timesheetActions } from "..";
import { alertActions } from "../../alert/index";

export const getTimesheetUsersTable = createAsyncThunk(
  "table-timesheet",
  async (payload, { dispatch }) => {
    dispatch(alertActions.clear());
    console.log("fsssssssss", payload);

    try {
      const {
        data: { data },
      } = await timesheetService.getTimesheetTable(payload);
      dispatch(timesheetActions.setTimesheetTable(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
