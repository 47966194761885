import { createSlice } from "@reduxjs/toolkit";

export const name = "employeeReducer";
const counterSlice = createSlice({
  name: "employeeReducer",
  initialState: { employeeList: [], isSuccess: false },
  reducers: {
    employeeList: (state, { payload }) => {
      state.employeeList = payload;
    },
    designationList: (state, { payload }) => {
      state.designationList = payload.data;
    },
    designationUpdate: (state, { payload }) => {
      state.isSuccess = payload.message;
    },
    resetReducer: (state) => {
      state.employeeList = [];
      state.isSuccess = false;
    },
  },
});

export const {
  employeeList,
  designationList,
  designationUpdate,
  resetReducer,
} = counterSlice.actions;
export const employeeReducer = counterSlice.reducer;
