/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import dragIcon from "../../assets/images/drag__icon-svg.svg";
import editSvg from "../../assets/images/edit.svg";
import noteIcon from "../../assets/images/note__icon.svg";
import subtask from "../../assets/images/Subtask.svg";
import SubTask from "./SubTask";
import numPlus from "../../assets/images/num-plus.svg";
import Threedots from "../DropDowns/Threedots";
import CommonSkeleton from "../SkeletonLoader/CommonSkeleton";

const Task = ({
  CustomToggle,
  taskAdd,
  taskEdit,
  configData,
  ActionButtons,
  ModalShow,
  // handleDragOver,
  // handleDragStart,
  // handleDrop,
  TicketData,
  isLoading,
  groupById,
  statusTitle,
  patchFunction,
  setSelectedTicket,
  isTicket,
  groupBy,
  AddTask,
  setSelectedRows,
  selectedRows,
  EditLoader,
  showSubTasks,
  userProfile,
  taskView,
  ExpandAll,
}) => {
  const [state, setstate] = useState({
    accordionToggle: [],
    expandAll: ExpandAll,
  });

  const { accordionToggle, expandAll } = state;

  useEffect(() => {
    const accordionIndex = TicketData?.map((e, index) => index);
    setstate({
      ...state,
      expandAll: ExpandAll,
      accordionToggle: ExpandAll ? accordionIndex : [],
    });
  }, [ExpandAll]);

  if (groupBy === "status" || groupBy === "") groupBy = "ticketStatus";
  const setAccordionOpen = (id) => {
    if (accordionToggle?.includes(id)) {
      const updatedArray = accordionToggle.filter((element) => element !== id);
      setstate({ ...state, accordionToggle: updatedArray, expandAll: false });
    } else {
      setstate({
        ...state,
        accordionToggle: [...accordionToggle, id],
        expandAll: false,
      });
    }
  };

  return TicketData?.map((Ticket, index) => {
    // const data = JSON.stringify({
    //   id: Ticket?._id,
    //   itemtype: "Ticket",
    //   [groupBy]: groupById,
    //   index,
    // });
    const checkAllocationsExists = !Ticket?.allocations
      ? true
      : Ticket?.allocations?.length > 0;

    return (
      checkAllocationsExists && (
        <Accordion
          activeKey={
            expandAll || accordionToggle?.includes(index) ? index : undefined
          }
          key={index}
        >
          <Card>
            <div
              // content={data}
              className="table-list-cover"
              id="collapseExample"
              // draggable
              // onDragStart={(e) => handleDragStart(e, Ticket)}
              // onDragOver={handleDragOver}
              // onDrop={handleDrop}
            >
              <div
                // content={data}
                className="main-container-row"
              >
                {EditLoader === Ticket?._id ? (
                  <CommonSkeleton height={40} />
                ) : (
                  <Card.Header>
                    <div
                      // content={data}
                      className="task-list-rows"
                    >
                      <div
                        // content={data}
                        style={{ marginTop: "0.4%" }}
                        className="row-controler ms-2"
                      >
                        {Ticket?.type === "allocations" && (
                          <div
                            //  content={data}
                            className="chekbxOtr "
                          >
                            <input
                              onChange={() => {
                                if (
                                  selectedRows.some((e) => e._id === Ticket._id)
                                )
                                  setSelectedRows(
                                    selectedRows.filter(
                                      (eachRow) => eachRow._id !== Ticket._id,
                                    ),
                                  );
                                else setSelectedRows([...selectedRows, Ticket]);
                              }}
                              checked={selectedRows.some(
                                (e) => e._id === Ticket._id,
                              )}
                              type="checkbox"
                              className={
                                selectedRows.some((e) => e._id === Ticket._id)
                                  ? "checkbox-round active"
                                  : "checkbox-round"
                              }
                            />
                          </div>
                        )}
                        <div
                          // content={data}
                          className="drag_icon"
                          style={{ cursor: "pointer" }}
                        >
                          <img src={dragIcon} alt="icon" />
                        </div>
                      </div>
                      <div
                        // content={data}
                        className="row__task_lft sticky-element"
                      >
                        <div
                          // content={data}
                          className="button-grp"
                        >
                          {statusTitle === "COMPLETE" ? (
                            showSubTasks && (
                              <CustomToggle
                                onAccordioOpenClick={() =>
                                  setAccordionOpen(index)
                                }
                                eventKey="0"
                                ButtonclassName={`${
                                  Ticket?.allocations?.length >= 1
                                    ? "sm-collapse"
                                    : ""
                                } w-100 mt-1 ${
                                  expandAll
                                    ? "arrow-down"
                                    : accordionToggle?.includes(index)
                                    ? "arrow-down"
                                    : "arrow-right"
                                }`}
                              >
                                {" "}
                              </CustomToggle>
                            )
                          ) : (
                            <CustomToggle
                              onAccordioOpenClick={() =>
                                setAccordionOpen(index)
                              }
                              eventKey="0"
                              ButtonclassName={`${
                                Ticket?.allocations?.length >= 1
                                  ? "sm-collapse"
                                  : ""
                              } w-100 mt-1 ${
                                expandAll
                                  ? "arrow-down"
                                  : accordionToggle?.includes(index)
                                  ? "arrow-down"
                                  : "arrow-right"
                              }`}
                            >
                              {" "}
                            </CustomToggle>
                          )}
                        </div>

                        <div
                          //  content={data}
                          className="contents_info "
                        >
                          <div
                            // content={data}
                            className="link-text-inner"
                            style={{ cursor: "pointer" }}
                          >
                            {Ticket?.ticketInfo?.title && (
                              <span
                                onClick={() => {
                                  if (taskView) {
                                    setSelectedTicket(Ticket?.ticketInfo?._id);
                                    isTicket(true);
                                  }
                                }}
                              >
                                {Ticket?.ticketInfo?.title}
                              </span>
                            )}
                            {Ticket?.ticketInfo?.title && <> &nbsp;/&nbsp; </>}
                            <span
                              onClick={() => {
                                if (taskView) {
                                  setSelectedTicket(Ticket._id);
                                  isTicket(Ticket?.type === "Ticket");
                                }
                              }}
                            >
                              {Ticket?.title}
                            </span>
                          </div>
                        </div>
                        <div
                          // content={data}
                          className="action-control d-flex"
                          style={{ marginTop: "0%" }}
                        >
                          <div
                            // content={data}
                            className="btn-sm sub__task"
                          >
                            <div
                              //  content={data}
                              className="icon-small d-flex"
                            >
                              <img src={subtask} alt="Subtask" />
                              <span>{Ticket?.allocations?.length}</span>
                            </div>
                            {taskAdd ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Create Ticket</Tooltip>}
                              >
                                <div
                                  // content={data}
                                  className="img divider d-flex"
                                  onClick={AddTask}
                                >
                                  <img src={numPlus} alt="" />
                                </div>
                              </OverlayTrigger>
                            ) : null}
                          </div>
                          {Ticket?.description && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={Ticket?._id}>
                                  {Ticket?.description}
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn-sm border-0">
                                <img src={noteIcon} alt="icon" />
                              </button>
                            </OverlayTrigger>
                          )}
                          {taskEdit || taskView ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Edit Ticket</Tooltip>}
                            >
                              <button
                                type="button"
                                className="btn-sm ms-3 edit__btn"
                                onClick={() => {
                                  setSelectedTicket(Ticket._id);
                                  isTicket(Ticket?.type === "Ticket");
                                }}
                              >
                                <img src={editSvg} alt="" />
                              </button>
                            </OverlayTrigger>
                          ) : null}
                        </div>
                      </div>
                      <div
                        // content={data}
                        className="info-right d-flex align-items-center"
                      >
                        {configData?.map(
                          (item) =>
                            item?.isVisible &&
                            item?.name !== "title" && (
                              <div
                                // content={data}
                                className="info-td"
                                key={item?.dbName}
                              >
                                <div
                                  // content={data}
                                  className="content-inr"
                                >
                                  {item?.cell ? (
                                    <item.cell
                                      TaskData={Ticket}
                                      index={index}
                                      dropDownVisibility={item?.dropDownVisibility?.includes(
                                        Ticket?.type,
                                      )}
                                      type={Ticket?.type}
                                      patchFunction={patchFunction}
                                      dbName={item?.dbName}
                                      className="d-flex justify-content-center ms-3"
                                      dropDownVisibilityPermissionConstant={
                                        item?.dropDownVisibilityPermissionConstant
                                      }
                                    />
                                  ) : Ticket[item?.dbName]?.length >= 11 ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={Ticket?._id}>
                                          {Ticket[item?.dbName]}
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        // content={data}
                                        className="content-inr"
                                      >
                                        {Ticket[item?.dbName]?.slice(0, 10)}...
                                      </div>
                                    </OverlayTrigger>
                                  ) : (
                                    Ticket[item?.dbName]
                                  )}
                                </div>
                              </div>
                            ),
                        )}
                        {ActionButtons ? (
                          <Threedots
                            type={Ticket?.type}
                            dropDownVisibility
                            TaskData={Ticket}
                            ActionButtons={ActionButtons}
                            className="info-td"
                          />
                        ) : (
                          <div className="info-td" />
                        )}
                      </div>
                    </div>
                  </Card.Header>
                )}
              </div>
            </div>

            <Accordion.Collapse eventKey={index}>
              <Card.Body>
                {statusTitle === "COMPLETE" ? (
                  showSubTasks && (
                    <SubTask
                      ModalShow={ModalShow}
                      Ticket={Ticket}
                      taskAdd={taskAdd}
                      taskEdit={taskEdit}
                      configData={configData}
                      ActionButtons={ActionButtons}
                      // handleDragStart={handleDragStart}
                      // handleDragOver={handleDragOver}
                      // handleDrop={handleDrop}
                      groupById={groupById}
                      patchFunction={patchFunction}
                      isLoading={isLoading}
                      setSelectedTicket={setSelectedTicket}
                      userProfile={userProfile}
                      isTicket={isTicket}
                      groupBy={groupBy}
                      AddTask={AddTask}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                      EditLoader={EditLoader}
                      taskView={taskView}
                      view={expandAll || accordionToggle?.includes(index)}
                    />
                  )
                ) : (
                  <SubTask
                    ModalShow={ModalShow}
                    Ticket={Ticket}
                    taskAdd={taskAdd}
                    taskEdit={taskEdit}
                    configData={configData}
                    ActionButtons={ActionButtons}
                    // handleDragStart={handleDragStart}
                    // handleDragOver={handleDragOver}
                    // handleDrop={handleDrop}
                    groupById={groupById}
                    patchFunction={patchFunction}
                    isLoading={isLoading}
                    setSelectedTicket={setSelectedTicket}
                    userProfile={userProfile}
                    isTicket={isTicket}
                    groupBy={groupBy}
                    AddTask={AddTask}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    EditLoader={EditLoader}
                    taskView={taskView}
                    view={expandAll || accordionToggle?.includes(index)}
                  />
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )
    );
  });
};

Task.propTypes = {
  CustomToggle: PropTypes.elementType.isRequired,
  taskAdd: PropTypes.bool.isRequired,
  taskEdit: PropTypes.bool.isRequired,
  configData: PropTypes.isRequired,
  ActionButtons: PropTypes.elementType.isRequired,
  ModalShow: PropTypes.func.isRequired,
  setSelectedTicket: PropTypes.func,
  isTicket: PropTypes.func,
  userProfile: PropTypes.node,
};
export default Task;
