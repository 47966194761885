/* eslint-disable react/prop-types */
import { Status } from "../../../components/DropDowns/Status";
import { Opencalender } from "../../../components/DropDowns/Calender";
import Profile from "../../../components/DropDowns/Profile";
import { CreatedBy } from "../../../components/DropDowns/createdBy";
import { Priority } from "../../../components/DropDowns/priority";
import { DateFormat } from "../../../components/DropDowns/FormatDate";

const TicketId = ({ TaskData, dbName, type }) => {
  if (type === "allocations" && TaskData[dbName]) {
    const parts = TaskData[dbName]?.split("#");
    const lettersAfterSplit = parts?.length > 1 ? parts[1] : null;
    return `#${lettersAfterSplit}`;
  }
  return TaskData[dbName];
};

export const config = [
  {
    name: "Allocations",
    isVisible: true,
    dbName: "assignedUsers",
    cell: Profile,
    dropDownVisibility: ["allocations"],
    dropDownVisibilityPermissionConstant: "COMMON_TICKET_LIST_ASSIGNEE_EDIT",
    permissionConstant: "COMMON_TICKET_LIST_ASSIGNEE",
    subModuleConstant: "COMMON_TICKET_SETTINGS",
  },
  {
    name: "Priority",
    isVisible: true,
    dbName: "priority",
    cell: Priority,
    dropDownVisibility: ["Ticket", "allocations"],
    dropDownVisibilityPermissionConstant: "COMMON_TICKET_LIST_PRIORITY_EDIT",
    permissionConstant: "COMMON_TICKET_LIST_PRIORITY",
    subModuleConstant: "COMMON_TICKET_SETTINGS",
  },
  {
    name: "Start Date",
    isVisible: true,
    dbName: "startDate",
    cell: Opencalender,
    dropDownVisibility: ["Ticket", "allocations"],
    dropDownVisibilityPermissionConstant: "COMMON_TICKET_LIST_STARTDATE_EDIT",
    subModuleConstant: "COMMON_TICKET_SETTINGS",
    permissionConstant: "COMMON_TICKET_LIST_STARTDATE",
  },
  {
    name: "Due Date",
    isVisible: true,
    dbName: "dueDate",
    cell: Opencalender,
    dropDownVisibility: ["Ticket", "allocations"],
    dropDownVisibilityPermissionConstant: "COMMON_TICKET_LIST_DUE_DATE_EDIT",
    subModuleConstant: "COMMON_TICKET_SETTINGS",
    permissionConstant: "COMMON_TICKET_LIST_DUE_DATE",
  },
  {
    name: "Status",
    isVisible: true,
    dbName: "ticketStatus",
    cell: Status,
    dropDownVisibility: ["allocations"],
    dropDownVisibilityPermissionConstant: "COMMON_TICKET_LIST_STATUS_EDIT",
    subModuleConstant: "COMMON_TICKET_SETTINGS",
    permissionConstant: "COMMON_TICKET_LIST_STATUS",
  },
  {
    name: "Ticket ID",
    isVisible: true,
    dbName: "customId",
    permissionConstant: "PROJECT_MANAGEMENT_LIST_TICKETID",
    cell: TicketId,
    subModuleConstant: "PROJECT_MANAGEMENT_LIST_VIEW",
  },
  // {
  //   name: "Sprint",
  //   isVisible: true,
  //   dbName: "sprint",
  //   subModuleConstant: "PROJECT_MANAGEMENT_LIST_VIEW",
  //   permissionConstant: "PROJECT_MANAGEMENT_LIST_SPRINTS",
  // },
  {
    name: "Updated By",
    isVisible: true,
    dbName: "updatedBy",
    cell: CreatedBy,
    subModuleConstant: "PROJECT_MANAGEMENT_LIST_VIEW",
    permissionConstant: "PROJECT_MANAGEMENT_LIST_CREATEDBY",
  },
  {
    name: "Estimation",
    isVisible: true,
    dbName: "estimationHours",
    subModuleConstant: "PROJECT_MANAGEMENT_LIST_VIEW",
    permissionConstant: "PROJECT_MANAGEMENT_LIST_SPRINTS",
  },
  {
    name: "Created By",
    isVisible: true,
    dbName: "createdBy",
    cell: CreatedBy,
    subModuleConstant: "PROJECT_MANAGEMENT_LIST_VIEW",
    permissionConstant: "PROJECT_MANAGEMENT_LIST_CREATEDBY",
  },
  // {
  //   name: "List",
  //   isVisible: true,
  //   dbName: "listInfo",
  //   subModuleConstant: "PROJECT_MANAGEMENT_LIST_VIEW",
  //   permissionConstant: "PROJECT_MANAGEMENT_LIST_LISTINFO",
  // },
  {
    name: "Created At",
    isVisible: false,
    dbName: "createdAt",
    subModuleConstant: "PROJECT_MANAGEMENT_LIST_VIEW",
    permissionConstant: "PROJECT_MANAGEMENT_LIST_DATE_CREATED",
    cell: DateFormat,
  },
  {
    name: "Updated At",
    isVisible: false,
    dbName: "updatedAt",
    subModuleConstant: "PROJECT_MANAGEMENT_LIST_VIEW",
    permissionConstant: "PROJECT_MANAGEMENT_LIST_UPDATEDAT",
    cell: DateFormat,
  },
];
