import Gateway from "../gateway/gateway";

const activityNotifications = () =>
  Gateway.get("/notifications/has_notification");

const notificationsSetting = () =>
  Gateway.get("/notifications/notification_Settings");

const notificationSettingsUpdate = (id, patchData) =>
  Gateway.put(`/notifications/notification_Settings/${id}`, patchData);

export default {
  activityNotifications,
  notificationsSetting,
  notificationSettingsUpdate,
};
