import permissionConstants from "../../utils/permissionUtils/permissionConstants";

export const permissionsDashboardCounts = [
  {
    key: "Number of Projects",
    permission:
      permissionConstants.permissions.DASHBOARD_COUNTERS_PROJECTS_COUNT,
  },
  {
    key: "Number of Open Tasks",
    permission:
      permissionConstants.permissions.DASHBOARD_COUNTERS_OPEN_TASKS_COUNT,
  },
  {
    key: "Productive Hours Completed",
    permission:
      permissionConstants.permissions
        .DASHBOARD_COUNTERS_PROJECTS_PRODUCTIVE_HOURS_COMPLETED,
  },
  {
    key: "Productive Hours Due",
    permission:
      permissionConstants.permissions.DASHBOARD_COUNTERS_PRODUCTIVE_HOURS_DUE,
  },
  {
    key: "Quality Index",
    permission:
      permissionConstants.permissions.DASHBOARD_COUNTERS_QUALITY_INDEX_PERCENT,
  },
  {
    key: "Performance Index",
    permission:
      permissionConstants.permissions
        .DASHBOARD_COUNTERS_PERFORMANCE_INDEX_PERCENT,
  },
];
