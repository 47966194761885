import PropTypes from "prop-types";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

function CommonSkeleton({ height = 30, width }) {
  return (
    <Skeleton
      width={width}
      height={height}
      baseColor="#4a4b4c"
      highlightColor="#3a3b3c"
    />
  );
}

CommonSkeleton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CommonSkeleton;
