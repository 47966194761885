import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { deleteBranch } from "../../../../services/gitHubService";
import { getGitBranchs } from "./getBranchs";

export const deleteBranches = createAsyncThunk(
  "github/deletebranch",
  async (data, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { branch: successMessage },
      } = await deleteBranch(
        data?.branchName,
        data?.repoName,
        data?.owner,
        data?.projectId,
        data?.ticket,
      );

      await dispatch(
        getGitBranchs({ value: data?.repoName, owner: data?.owner }),
      );
      await dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.success;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
