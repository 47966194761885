/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import closeIcon from "../../assets/images/close__icon.svg";
import user from "../../assets/images/user-mod.svg";
import addFill from "../../assets/images/add-fill.svg";
import userWithoutName from "../../assets/images/userWithoutImage.svg";
import { getMultyUserProfile } from "../../store";
import { useThunk } from "../../hooks/useThunk";
import { userProfileActions } from "../../store/slices/userProfile";
import "../../design/custom/pages/createTask.scss";
import { getFirstLetters } from "../../utils/utils";

const Profile = ({
  selectedTasks,
  TaskData,
  patchFunction,
  dbName,
  type,
  icon,
  className,
  dropDownVisibility,
  dropDownVisibilityPermissionConstant,
  index,
  needCount,
  // setDropdownVisible,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [val, setVal] = useState("");

  const { Users } = useSelector((s) => s.ticketReducer);

  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);

  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const handleAllocateUserClick = (data) => {
    let modifiedData = TaskData[dbName]?.map((item) => {
      return { user: item?._id };
    });
    if (TaskData[dbName] === undefined) {
      modifiedData = [];
    }
    if (TaskData.length > 0) {
      let newPatchData = [];
      TaskData.forEach((e) => {
        let modifiedData = [];

        if (e[dbName]) {
          modifiedData = e[dbName].map((item) => ({ user: item?._id }));
        }

        newPatchData = [
          ...newPatchData,
          {
            [dbName]: [...modifiedData, data],
          },
        ];
      });
      console.log({
        id: selectedTasks.map((task) => task?._id),
        patchData: newPatchData,
      });
      patchFunction(type, {
        needCount,
        id: selectedTasks.map((task) => task?._id),
        patchData: newPatchData,
      });
    } else {
      patchFunction(type, {
        id: TaskData?._id,
        patchData: { [dbName]: [...modifiedData, data] },
        needCount,
      });
    }
  };

  const handleUallocateUserClick = (data) => {
    const modifiedData = TaskData[dbName].map((item) => {
      return { user: item?._id };
    });

    patchFunction(type, {
      id: TaskData?._id,
      needCount,
      patchData: {
        [dbName]: modifiedData?.filter((e) => e?.user !== data?.user),
      },
    });
  };

  useEffect(() => {
    if (val === "") {
      setUsers(Users?.map(({ user }) => user));
    } else {
      setUsers(
        Users?.map(({ user }) => user)?.filter(({ employeeName }) =>
          employeeName.toLowerCase().includes(val.toLowerCase()),
        ),
      );
    }
  }, [val, Users]);

  let assignedUsers = [];
  if (type === "Ticket") {
    const updatedUsers = TaskData?.allocations?.map(({ assignedUsers }) =>
      assignedUsers?.map((e) => e?._id),
    );
    assignedUsers = [
      ...new Set(
        updatedUsers?.reduce((result, currentArray) => {
          return result.concat(currentArray);
        }, []),
      ),
    ];
  } else {
    assignedUsers = TaskData[dbName]?.map((e) => e?._id);
  }
  const userDetails = users?.filter(({ _id }) => assignedUsers?.includes(_id));

  const uniqueData = new Set();
  const result = userDetails.filter((item) => {
    const isUnique = !uniqueData.has(item._id);
    if (isUnique) {
      uniqueData.add(item._id);
    }
    return isUnique;
  });

  const restOfTheUserDetails = users?.filter(
    ({ _id }) => !assignedUsers?.includes(_id),
  );

  const [imgArray, setimgArray] = useState([]);

  const handleOnError = (e, id) => {
    e.stopPropagation();
    setimgArray((prevImgArray) => [...prevImgArray, id]);

    e.target.style.display = "none";
  };
  const CustomMenu = React.forwardRef(
    ({ children, className, "aria-labelledby": labeledBy, style }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          {children}
        </div>
      );
    },
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="avatar__group"
      ref={ref}
      onClick={(e) => {
        if (dropDownVisibility && dropDownVisibilityPermissionConstant) {
          e.preventDefault();
          // setDropdownVisible(TaskData?._id);
          onClick(e);
        }
      }}
    >
      {children}
    </div>
  ));
  return (
    <Dropdown key={index} className="profile-main">
      <div className={className}>
        {type === "Ticket" && result?.length <= 0 ? (
          <div className="avatar__group" style={{ cursor: "pointer" }}>
            {selectedTasks ? icon : <img src={userWithoutName} alt="" />}
          </div>
        ) : (
          result?.map(
            ({ employeeName, _id, photo }, index) =>
              index < 1 && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      <span className="name">{employeeName}</span>
                    </Tooltip>
                  }
                >
                  <div className="avatar__group">
                    <div className={`avathar-sm avathar-0${(index % 3) + 1}`}>
                      {dropDownVisibilityPermissionConstant
                        ? TaskData[dbName] && (
                            <div
                              onClick={() =>
                                handleUallocateUserClick({ user: _id })
                              }
                              className="close_circle"
                              style={{ zIndex: 10 }}
                            >
                              <img src={closeIcon} alt="" />
                            </div>
                          )
                        : null}
                      {photo ? (
                        imgArray?.includes(_id) ? (
                          <div
                            className={`avathar-sm avathar-0${(index % 3) + 1}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              getMultyUsers(_id);
                              dispatch(
                                userProfileActions.setShowUserProfile(true),
                              );
                            }}
                          >
                            <span>{getFirstLetters(employeeName)} </span>
                          </div>
                        ) : (
                          <img
                            onError={(target) => handleOnError(target, _id)}
                            onClick={(e) => {
                              e.stopPropagation();
                              getMultyUsers(_id);
                              dispatch(
                                userProfileActions.setShowUserProfile(true),
                              );
                            }}
                            onLoad={(target) => {
                              target.target.style.display = "block";
                            }}
                            className="usrImg"
                            src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
                            alt=""
                          />
                        )
                      ) : (
                        <div
                          className={`avathar-sm avathar-0${(index % 3) + 1}`}
                        >
                          {!employeeName ? (
                            <img src={userWithoutName} alt="" />
                          ) : null}
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              getMultyUsers(_id);
                              dispatch(
                                userProfileActions.setShowUserProfile(true),
                              );
                            }}
                          >
                            {employeeName?.slice(0, 2).toUpperCase()}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
              ),
          )
        )}

        <Dropdown key={index}>
          {result?.length > 1 && (
            <Dropdown.Toggle id="checklist-toggles" className="moreUser">
              + {(result?.length ?? 0) - 1}
            </Dropdown.Toggle>
          )}
          {result?.length > 1 && (
            <Dropdown.Menu className="no-bg  pb-1 dropdown-menu right ">
              <div className="list-items-main custom-scroll list-items-1 ps-2 pe-1 allocation-drp-dwn right">
                <ul className="p-0">
                  {result?.map(
                    ({ employeeName, _id, photo }, index) =>
                      index >= 1 && (
                        <li
                          className="d-flex align-items-center p-1 pb-0"
                          style={{ minWidth: "150px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            onClick={(e) => {
                              e.stopPropagation();
                              getMultyUsers(_id);
                              dispatch(
                                userProfileActions.setShowUserProfile(true),
                              );
                            }}
                          >
                            {photo ? (
                              imgArray?.includes(_id) ? (
                                <div
                                  className={`avathar-sm ms-0  avathar-0${
                                    (index % 3) + 1
                                  }`}
                                >
                                  <span>{getFirstLetters(employeeName)}</span>
                                </div>
                              ) : (
                                <img
                                  onError={(target) =>
                                    handleOnError(target, _id)
                                  }
                                  onLoad={(target) => {
                                    target.target.style.display = "block";
                                  }}
                                  className="usrImg"
                                  src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
                                  alt=""
                                />
                              )
                            ) : (
                              <div
                                className={`avathar-sm ms-0  avathar-0${
                                  (index % 4) + 1
                                }`}
                              >
                                <span>
                                  {employeeName?.slice(0, 2).toUpperCase()}
                                </span>
                              </div>
                            )}
                            <div className="rgt_content d-flex flex-column">
                              <div
                                className={
                                  photo ? "title_fst ms-1" : "title_fst"
                                }
                              >
                                {employeeName}
                              </div>
                            </div>
                          </div>

                          {type === "allocations" && (
                            <div
                              className="ms-auto profile-btn"
                              onClick={() =>
                                handleUallocateUserClick({ user: _id })
                              }
                            >
                              Remove
                            </div>
                          )}
                        </li>
                      ),
                  )}
                </ul>
              </div>
            </Dropdown.Menu>
          )}
        </Dropdown>
        {type !== "Ticket" ? (
          <Dropdown.Toggle as={CustomToggle}>
            {icon || (
              <div className="user-group ">
                <button className="user-def add-user-btn" type="button">
                  <img src={user} alt="" />
                  <span className="add-icon">
                    <img src={addFill} alt="addFill" />
                  </span>
                </button>
              </div>
            )}
          </Dropdown.Toggle>
        ) : null}
      </div>

      <Dropdown.Menu as={CustomMenu} className="no-bg  p-3 dropdown-menu right">
        <div className="search-icon " style={{ minWidth: "150px" }}>
          {/* <input
            type="text"
            className="p-1 no-hover"
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              fontSize: "14px",
              color: "#888",
            }}
            placeholder="Search"
            autoFocus
            value={val}
            onChange={(e) => setVal(e.target.value)}
          /> */}{" "}
        </div>
        {/* <div className="divitions pb-1 pt-1">
          <hr className="mt-0 mb-0" />
        </div> */}
        <div className="list-items-main custom-scroll list-items-1 ps-2 pe-1 allocation-drp-dwn right">
          <ul className="p-0">
            {restOfTheUserDetails?.length >= 1
              ? restOfTheUserDetails?.map(
                  ({ employeeName, _id, photo }, index) => (
                    <li className="d-flex align-items-center">
                      <div
                        className="d-flex align-items-center"
                        onClick={() => handleAllocateUserClick({ user: _id })}
                      >
                        {photo ? (
                          imgArray?.includes(_id) ? (
                            <div
                              className={`avathar-sm ms-0  avathar-0${
                                (index % 3) + 1
                              }`}
                            >
                              <span>{getFirstLetters(employeeName)}</span>
                            </div>
                          ) : (
                            <img
                              onError={(target) => handleOnError(target, _id)}
                              onLoad={(target) => {
                                target.target.style.display = "block";
                              }}
                              className="usrImg"
                              src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
                              alt=""
                            />
                          )
                        ) : (
                          <div
                            className={`avathar-sm ms-0  avathar-0${
                              (index % 4) + 1
                            }`}
                          >
                            <span>
                              {employeeName?.slice(0, 2).toUpperCase()}
                            </span>
                          </div>
                        )}
                        <div className="rgt_content d-flex flex-column">
                          <div
                            className={photo ? "title_fst ms-1" : "title_fst"}
                          >
                            {employeeName}
                          </div>
                        </div>
                      </div>
                      <div
                        className="ms-auto profile-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          getMultyUsers(_id);
                          dispatch(userProfileActions.setShowUserProfile(true));
                        }}
                      >
                        Profile
                      </div>
                    </li>
                  ),
                )
              : null}
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
Profile.propTypes = {
  TaskData: PropTypes.isRequired,
};
export default Profile;
