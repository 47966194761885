import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import infoImage from "../../../../assets/images/info.svg";
import "../../../../design/custom/pages/user-settings.scss";
import "../../../../design/custom/pages/user-notifications.scss";
import { getFirstLetters } from "../../../../utils/utils";
import NotificationTable from "./NotificationTable";

import { useThunk } from "../../../../hooks/useThunk";
import { getActivityNotifications } from "../../../../store";

function MyNotification() {
  const [shownav, setShownav] = useOutletContext();
  const { authData } = useSelector((state) => state.auth);
  const [getActivity, isLoading] = useThunk(getActivityNotifications);
  const { activityNotification } = useSelector(
    (state) => state.myNotifications,
  );

  const titles = Object?.keys(activityNotification);

  useEffect(() => {
    getActivity();
  }, []);
  //   // sidenav toggle
  const sidenavToggle = () => {
    setShownav(!shownav);
  };

  return (
    <div className="page-container">
      <div className="page-contents inner-page-contents settings-page">
        <div className="row">
          <div className="col d-flex align-items-start offset-blk">
            <button
              className="btn btn-hamburg"
              onClick={sidenavToggle}
              type="button"
            >
              <span />
              <span />
              <span />
            </button>
            <div className="main-title notification-title">
              Notifications for the{" "}
              <span className="profile-pic">
                {authData?.photo ? (
                  <img
                    className="img-fluid"
                    src={`https://erptest.spericorn.com/repo/uploads/${authData?.photo}`}
                    alt="photos"
                  />
                ) : (
                  <div className="name userProf bg-7">
                    {getFirstLetters(authData?.employeeName)}
                  </div>
                )}
              </span>
              <span className="user-name">
                {" "}
                {authData?.employeeName}'s Workspace{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="note-wrp">
              <span className="me-1">
                <img src={infoImage} alt="" />
              </span>
              Notification settings can be customized for each Workspace you're
              part of.
            </div>
          </div>
        </div>
        <div className="table notification-table">
          {isLoading ||
            titles?.map((each, index) => (
              <NotificationTable
                key={index}
                isLoading={isLoading}
                title={each}
                data={activityNotification[each]}
              />
            ))}
          {/* <NotificationTable title={filterTasks} /> */}
        </div>
      </div>
    </div>
  );
}

export default MyNotification;
