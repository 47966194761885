import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const CustomModal = ({
  className,
  dialgName,
  show,
  closeModal,
  body,
  footer,
  header,
  bodyClassname,
  createModal,
  disableCenter,
  contentClassName,
  closeButton,
  renderOnScroll,
}) => {
  return (
    <Modal
      className={className || ""}
      dialogClassName={dialgName || ""}
      contentClassName={contentClassName || ""}
      show={show}
      onHide={closeModal}
      backdrop="static"
      centered={!disableCenter}
    >
      <Modal.Header closeButton={closeButton}>{header || null}</Modal.Header>
      {createModal ? (
        <Modal.Body className={bodyClassname || ""} ref={renderOnScroll}>
          {body}
        </Modal.Body>
      ) : (
        body
      )}
      {footer || null}
    </Modal>
  );
};
CustomModal.propTypes = {
  className: PropTypes.string,
  dialgName: PropTypes.string,
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  body: PropTypes.node,
  footer: PropTypes.node,
  header: PropTypes.node,
  bodyClassname: PropTypes.string,
  disableCenter: PropTypes.bool,
  createModal: PropTypes.bool,
  contentClassName: PropTypes.string,
  closeButton: PropTypes.bool,
  renderOnScroll: PropTypes.node,
};
export default CustomModal;
