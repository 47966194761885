import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "../../pages/UserSettings/assets/svgIcons";
import { useThunk } from "../../hooks/useThunk";
import { fetchUserData } from "../../store/slices/auth/thunks/fetchUserData";
import Button from "../../components/Button/index";
import useWindowSize from "../../hooks/useWindowSize";
import { auth_doLogout } from "../../store/slices/auth/thunks/doLogout";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import { hasSubModulePermission } from "../../utils/permissionUtils";

const SettingsLayout = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [shownav, setShownav] = useState(false);
  const [fetchUserDataThunk] = useThunk(fetchUserData);
  const { userProfile } = useSelector(({ auth }) => auth);
  const {
    userProfile: { userProfile: userData },
  } = useSelector((state) => state);
  const [logOut] = useThunk(auth_doLogout);

  useEffect(() => {
    fetchUserDataThunk();
  }, []);

  useEffect(() => {
    if (width <= 991) setShownav(false);
    else setShownav(true);
  }, [width]);

  let navLinks = [
    {
      link: "My Settings",
      to: "/user-settings",
      hasPermission: permissionConstants.submodules.BASIC_SETTINGS,
    },
    { link: "Integrations", to: "/user-settings/integrations" },
    { link: "Notifications", to: "/user-settings/notifications" },
  ];

  if (userData && userData.formattedPermissions)
    navLinks = navLinks.filter((e) =>
      e.hasPermission
        ? hasSubModulePermission(userData.formattedPermissions, e.hasPermission)
        : true,
    );

  return (
    <div className={shownav ? "sidenav-open" : ""}>
      <div className="page-wrap settings-wrapper">
        <div className="sidenav settings">
          <div className="hamburgBtn">
            <button className="btn" type="button">
              <svg
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6H20M4 12H20M4 18H20"
                  stroke="currentcolor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </button>
          </div>
          <div className="sidenav-wrap">
            <div className="col d-flex align-items-start offset-blk">
              <Button
                className="btn back-btn"
                onClick={() => navigate("/dashboard")}
              >
                <span className="btn-icon">
                  <ArrowRightIcon />
                </span>
                Back
              </Button>
              <Button
                className="btn btn-hamburg"
                onClick={() => setShownav(!shownav)}
              >
                <span />
                <span />
                <span />
              </Button>
            </div>
            <h3 className="menu-title">Settings</h3>
            <h4 className="workspace-title">
              {userProfile &&
                `${userProfile.employeeName
                  ?.trim()
                  .split(" ")[0]
                  .toUpperCase()}’S WORKSPACE`}
            </h4>
            <ul className="nav flex-column settings-menu">
              {navLinks.map((eachLink) => (
                <li className="nav-item" key={eachLink.link}>
                  <Link
                    className={`nav-link ${
                      pathname === eachLink.to ? "active" : ""
                    } `}
                    aria-current="page"
                    to={eachLink.to}
                  >
                    {eachLink.link}
                  </Link>
                </li>
              ))}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="# "
                  onClick={() => {
                    logOut();
                  }}
                >
                  Log out
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Outlet context={[shownav, setShownav]} />
      </div>
    </div>
  );
};

export default SettingsLayout;
