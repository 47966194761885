/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { getFirstLetters } from "../../utils/utils";

const UserMention = ({ dropDownVisibility, onAllocateUser, atText }) => {
  const [val, setVal] = useState("");
  const [userData, setUsers] = useState([]);
  const [imgArray, setimgArray] = useState([]);

  const { allUsers } = useSelector((state) => state.common);
  const { showUserprofile } = useSelector(({ userProfile }) => userProfile);

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  const handleAllocateUserClick = (data) => {
    onAllocateUser(data);
  };

  useEffect(() => {
    setVal("");
  }, []);
  useEffect(() => {
    if (atText) {
      setUsers(
        allUsers?.filter((item) =>
          item.employeeName?.toLowerCase().includes(atText?.toLowerCase()),
        ),
      );
    } else if (val === "") {
      setUsers(allUsers?.map((user) => user));
      // setVal("");
    } else {
      setUsers(
        allUsers?.filter((item) =>
          item.employeeName?.toLowerCase().includes(val?.toLowerCase()),
        ),
      );
    }
  }, [atText, val]);
  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setVal("");
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`userList ps-2 ${showUserprofile ? "feed-dropdown" : ""} `}
    >
      <Dropdown show={dropDownVisibility}>
        <Dropdown.Toggle className={`${dropDownVisibility} ? "show" : ""`} />
        <Dropdown.Menu
          className={`custom-scroll usermention-drp ${
            dropDownVisibility ? "show" : ""
          }`}
        >
          <div style={{ display: "block" }} className="no-bg">
            <div
              className="search-icon "
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="text"
                className="p-1 no-hover"
                value={val}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                  fontSize: "14px",
                  color: "#888",
                }}
                placeholder="Search"
                onChange={(e) => setVal(e.target.value)}
              />
            </div>
            <div className="divisions pb-1 pt-1">
              <hr className="mt-0 mb-0" />
            </div>
            <div className="list-items-main custom-scroll list-items-1 ps-2 pe-1 allocation-drp-dwn">
              <ul className="p-0">
                {userData?.length >= 1 ? (
                  userData.map((e, index) => (
                    <li
                      className="d-flex w-100 align-items-center"
                      key={index}
                      onClick={(event) => {
                        handleAllocateUserClick({ user: e._id });
                        event.stopPropagation();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        {e.photo ? (
                          imgArray?.includes(e._id) ? (
                            <div className="avathar-sm avathar-01">
                              <span>{getFirstLetters(e.employeeName)}</span>
                            </div>
                          ) : (
                            <div className="user-pic">
                              <img
                                onError={(target) =>
                                  handleOnError(target, e._id)
                                }
                                onLoad={(target) => {
                                  target.target.style.display = "block";
                                }}
                                src={`https://erptest.spericorn.com/repo/uploads/${e.photo}`}
                                alt="profile-pic"
                              />
                            </div>
                          )
                        ) : (
                          <div className="avathar-sm avathar-01">
                            <span>{getFirstLetters(e?.employeeName)}</span>
                          </div>
                        )}
                        <div className="rgt_content d-flex flex-column">
                          <div className="title_fst">{e.employeeName}</div>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <span>No data </span>
                )}
              </ul>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
UserMention.propTypes = {
  dropDownVisibility: PropTypes.bool,
  allocations: PropTypes.shape({}),
  onAllocateUser: PropTypes.func,
  atText: PropTypes.string,
};
export default UserMention;
