export const RightArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="7.75"
    viewBox="0 0 16 7.75"
  >
    <g
      id="right-arrow_2_"
      data-name="right-arrow (2)"
      transform="translate(0 -132)"
    >
      <g id="Group_1214" data-name="Group 1214" transform="translate(0 132)">
        <path
          id="Path_4013"
          data-name="Path 4013"
          d="M15.817,135.433h0l-3.266-3.25a.625.625,0,0,0-.882.886l2.193,2.182H.625a.625.625,0,1,0,0,1.25H13.861l-2.193,2.182a.625.625,0,0,0,.882.886l3.266-3.25h0A.626.626,0,0,0,15.817,135.433Z"
          transform="translate(0 -132)"
          fill="#808387"
        />
      </g>
    </g>
  </svg>
);
