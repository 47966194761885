export function TodayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.553"
      height="15.623"
      viewBox="0 0 13.553 15.623"
    >
      <g
        id="Group_59670"
        data-name="Group 59670"
        transform="translate(-471 -167.833)"
      >
        <path
          id="Vector"
          d="M10.323,1.739V.565a.565.565,0,1,0-1.129,0V1.694H4.3V.565a.565.565,0,1,0-1.129,0V1.739A3.2,3.2,0,0,0,0,4.939a.378.378,0,0,0,.376.4H13.117a.374.374,0,0,0,.376-.4A3.2,3.2,0,0,0,10.323,1.739Z"
          transform="translate(471.029 167.833)"
          fill="currentcolor"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M12.8,0H.753A.755.755,0,0,0,0,.753V5.391C0,7.65,1.129,9.156,3.765,9.156H9.788c2.635,0,3.765-1.506,3.765-3.765V.753A.755.755,0,0,0,12.8,0Z"
          transform="translate(471 174.301)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}
export function UpcomingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.333"
      height="16.523"
      viewBox="0 0 14.333 16.523"
    >
      <g
        id="Group_59672"
        data-name="Group 59672"
        transform="translate(-624 -132.25)"
      >
        <path
          id="Vector"
          d="M10.918,1.839V.6A.6.6,0,0,0,9.724.6V1.792H4.548V.6A.6.6,0,0,0,3.353.6V1.839A3.382,3.382,0,0,0,0,5.224a.4.4,0,0,0,.4.422H13.872a.4.4,0,0,0,.4-.422A3.382,3.382,0,0,0,10.918,1.839Z"
          transform="translate(624.031 132.25)"
          fill="currentcolor"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M13.537,0H.8A.8.8,0,0,0,0,.8V5.7C0,8.09,1.194,9.683,3.981,9.683h6.37c2.787,0,3.981-1.593,3.981-3.981V.8A.8.8,0,0,0,13.537,0ZM4.945,6.665a1.255,1.255,0,0,1-.119.1.6.6,0,0,1-.143.072.506.506,0,0,1-.143.048A1.079,1.079,0,0,1,4.38,6.9a.793.793,0,0,1-.3-.064.822.822,0,0,1-.263-.167.808.808,0,0,1,0-1.131.822.822,0,0,1,.263-.167.761.761,0,0,1,.462-.048.506.506,0,0,1,.143.048.6.6,0,0,1,.143.072l.119.1a.808.808,0,0,1,0,1.131Zm0-2.787a.808.808,0,0,1-1.131,0,.808.808,0,0,1,0-1.131.8.8,0,0,1,.868-.167.743.743,0,0,1,.263.167.808.808,0,0,1,0,1.131ZM7.732,6.665A.8.8,0,0,1,6.6,5.534.8.8,0,0,1,7.732,6.665Zm0-2.787-.119.1a.6.6,0,0,1-.143.072.506.506,0,0,1-.143.048,1.079,1.079,0,0,1-.159.016.789.789,0,0,1-.8-.8A.837.837,0,0,1,6.6,2.747a.743.743,0,0,1,.263-.167.8.8,0,0,1,.868.167.808.808,0,0,1,0,1.131Zm2.787,2.787A.8.8,0,0,1,9.388,5.534a.8.8,0,0,1,1.131,1.131Zm0-2.787-.119.1a.6.6,0,0,1-.143.072.506.506,0,0,1-.143.048,1.2,1.2,0,0,1-.159.016.789.789,0,0,1-.8-.8.837.837,0,0,1,.231-.565.822.822,0,0,1,.263-.167.761.761,0,0,1,.462-.048.506.506,0,0,1,.143.048.6.6,0,0,1,.143.072l.119.1a.808.808,0,0,1,0,1.131Z"
          transform="translate(624 139.09)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}
export function RemindersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.329"
      height="17.328"
      viewBox="0 0 13.329 17.328"
    >
      <g
        id="Group_60356"
        data-name="Group 60356"
        transform="translate(-927.311 -345)"
      >
        <path
          id="Vector"
          d="M13.022,10.821l-.866-1.438A3.219,3.219,0,0,1,11.809,8.1V5.909a5.12,5.12,0,0,0-2.92-4.618A2.535,2.535,0,0,0,6.654,0,2.565,2.565,0,0,0,4.41,1.317,5.136,5.136,0,0,0,1.55,5.909V8.1A3.142,3.142,0,0,1,1.2,9.375L.329,10.821A2.025,2.025,0,0,0,1.464,13.88a16.2,16.2,0,0,0,5.216.849,16.435,16.435,0,0,0,5.216-.84,2.089,2.089,0,0,0,1.126-3.067Z"
          transform="translate(927.311 345)"
          fill="currentcolor"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M4.9.009A2.612,2.612,0,0,1,2.443,1.733,2.569,2.569,0,0,1,.606.962,2.392,2.392,0,0,1,0,0C.113.017.225.026.347.043.546.069.754.1.962.113c.494.043,1,.069,1.5.069S3.448.156,3.934.113C4.115.1,4.3.087,4.471.061Z"
          transform="translate(931.529 360.595)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}
// eslint-disable-next-line react/prop-types
export function DocIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.408"
      height="15.167"
      viewBox="0 0 14.408 15.167"
    >
      <g
        id="Group_59674"
        data-name="Group 59674"
        transform="translate(-696.5 -85)"
      >
        <path
          id="Vector"
          d="M13.65,6.211H11.458A3.266,3.266,0,0,1,8.2,2.95V.758A.761.761,0,0,0,7.439,0H4.224A4,4,0,0,0,0,4.224v6.719a4,4,0,0,0,4.224,4.224h5.961a4,4,0,0,0,4.224-4.224V6.969A.761.761,0,0,0,13.65,6.211Z"
          transform="translate(696.5 85)"
          fill={color}
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M.849.148A.5.5,0,0,0,0,.481V3.128A2.067,2.067,0,0,0,2.085,5.153c.72.008,1.721.008,2.578.008a.478.478,0,0,0,.356-.811C3.928,3.249,1.972,1.27.849.148Z"
          transform="translate(705.737 85.011)"
          fill={color}
        />
      </g>
    </svg>
  );
}
export function CalenderIcon() {
  return (
    <svg width="8.81" height="8.99" viewBox="0 0 8.81 8.99">
      <g id="agenda" transform="translate(-2 -2.5)">
        <path
          id="Path_4015"
          data-name="Path 4015"
          d="M9.866,3.192H8.922V2.846a.316.316,0,1,0-.629,0v.346H4.517V2.846a.316.316,0,1,0-.629,0v.346H2.944A.994.994,0,0,0,2,4.229v6.224a.994.994,0,0,0,.944,1.037H9.866a.994.994,0,0,0,.944-1.037V4.229A.994.994,0,0,0,9.866,3.192Zm-6.922.692h.944a.316.316,0,1,0,.629,0H8.293a.316.316,0,1,0,.629,0h.944a.331.331,0,0,1,.315.346V4.92H2.629V4.229A.331.331,0,0,1,2.944,3.883ZM9.866,10.8H2.944a.331.331,0,0,1-.315-.346V5.612h7.552v4.841A.331.331,0,0,1,9.866,10.8Z"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}
// eslint-disable-next-line react/prop-types
export function TodocategoryIcon({ color }) {
  return (
    <svg width="14.408" height="15.167" viewBox="0 0 14.408 15.167">
      <g
        id="Group_59674"
        data-name="Group 59674"
        transform="translate(-696.5 -85)"
      >
        <path
          id="Vector"
          d="M13.65,6.211H11.458A3.266,3.266,0,0,1,8.2,2.95V.758A.761.761,0,0,0,7.439,0H4.224A4,4,0,0,0,0,4.224v6.719a4,4,0,0,0,4.224,4.224h5.961a4,4,0,0,0,4.224-4.224V6.969A.761.761,0,0,0,13.65,6.211Z"
          transform="translate(696.5 85)"
          fill={color}
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M.849.148A.5.5,0,0,0,0,.481V3.128A2.067,2.067,0,0,0,2.085,5.153c.72.008,1.721.008,2.578.008a.478.478,0,0,0,.356-.811C3.928,3.249,1.972,1.27.849.148Z"
          transform="translate(705.737 85.011)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export function ThreeDots() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="4"
      viewBox="0 0 18 4"
    >
      <g
        id="Group_59398"
        data-name="Group 59398"
        transform="translate(-1886.226 -68)"
      >
        <circle
          id="Ellipse_4"
          data-name="Ellipse 4"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1886.226 68)"
          fill="#868991"
        />
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1893.226 68)"
          fill="#868991"
        />
        <circle
          id="Ellipse_6"
          data-name="Ellipse 6"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1900.226 68)"
          fill="#868991"
        />
      </g>
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.457"
      height="10.458"
      viewBox="0 0 10.457 10.458"
    >
      <path
        id="close"
        d="M12.5,11.529l4.054-4.054a.688.688,0,1,0-.973-.973l-4.054,4.054L7.475,6.5a.688.688,0,0,0-.973.973l4.054,4.054L6.5,15.583a.688.688,0,1,0,.972.973L11.528,12.5l4.054,4.054a.688.688,0,0,0,.972-.973L12.5,11.529Z"
        transform="translate(-6.3 -6.3)"
        fill="currentcolor"
      />
    </svg>
  );
}
export function TickIcon() {
  return (
    <svg
      className="feather feather-check"
      fill="none"
      height="24"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );
}
export function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.212"
      height="24.212"
      viewBox="0 0 24.212 24.212"
    >
      <g
        id="Group_60523"
        data-name="Group 60523"
        transform="translate(-172 -852.001)"
      >
        <path
          id="Path_10694"
          data-name="Path 10694"
          d="M12.106,0A12.106,12.106,0,1,0,24.212,12.106,12.117,12.117,0,0,0,12.106,0Z"
          transform="translate(172 852.001)"
          fill="#59be23"
          fillRule="evenodd"
        />
        <path
          id="Path_10695"
          data-name="Path 10695"
          d="M136.867,165.15a.593.593,0,0,1,0,.835l-5.54,5.54a.588.588,0,0,1-.835,0l-2.77-2.77a.59.59,0,1,1,.835-.835l2.353,2.353,5.123-5.123A.586.586,0,0,1,136.867,165.15Z"
          transform="translate(51.812 696.026)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.87"
      height="9.87"
      viewBox="0 0 9.87 9.87"
    >
      <path
        id="Icon_ionic-md-add"
        data-name="Icon ionic-md-add"
        d="M16.62,12.343H12.343V16.62H11.027V12.343H6.75V11.027h4.277V6.75h1.316v4.277H16.62Z"
        transform="translate(-6.75 -6.75)"
        fill="currentcolor"
      />
    </svg>
  );
}
export const DownloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 16.021 15.2"
  >
    <g
      id="Group_59903"
      data-name="Group 59903"
      transform="translate(-1771 -204)"
    >
      <g id="Group_59902" data-name="Group 59902">
        <path
          id="Path_10623"
          data-name="Path 10623"
          d="M19.366,4.583a.616.616,0,0,1,.616.616v9.785a.616.616,0,1,1-1.232,0V5.2A.616.616,0,0,1,19.366,4.583Z"
          transform="translate(1759.645 199.417)"
          fill="#a2a5ac"
          fillRule="evenodd"
        />
        <path
          id="Path_10624"
          data-name="Path 10624"
          d="M12.347,18.931a.616.616,0,0,1,.871,0l2.81,2.8,2.81-2.8a.616.616,0,0,1,.87.873l-3.015,3.006h0a.949.949,0,0,1-1.317.014l-.014-.013L12.348,19.8A.616.616,0,0,1,12.347,18.931Z"
          transform="translate(1762.982 192.233)"
          fill="#a2a5ac"
          fillRule="evenodd"
        />
        <path
          id="Path_10625"
          data-name="Path 10625"
          d="M4.366,25.05a.616.616,0,0,1,.616.616v1.553A1.714,1.714,0,0,0,6.7,28.928H16.822a1.714,1.714,0,0,0,1.717-1.709V25.666a.616.616,0,1,1,1.232,0v1.553a2.946,2.946,0,0,1-2.95,2.941H6.7a2.946,2.946,0,0,1-2.95-2.941V25.666a.616.616,0,0,1,.616-.616Z"
          transform="translate(1767.25 189.039)"
          fill="#a2a5ac"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.9"
    height="9.71"
    viewBox="0 0 12.517 13.651"
  >
    <g
      id="Group_1188"
      data-name="Group 1188"
      transform="translate(-1003.026 -544.101)"
    >
      <path
        id="Path_62"
        data-name="Path 62"
        d="M1004.17,551.947c0-1.4,0-2.805,0-4.208,0-.182-.046-.248-.232-.234a5.885,5.885,0,0,1-.6,0c-.221,0-.31-.1-.315-.32,0-.153,0-.306,0-.459,0-.259.091-.349.347-.349.824,0,1.648,0,2.473,0a.26.26,0,0,0,.294-.19c.163-.421.339-.837.52-1.251a1.3,1.3,0,0,1,1.264-.836q1.366-.01,2.732,0a1.294,1.294,0,0,1,1.248.83q.275.619.521,1.25a.259.259,0,0,0,.289.2c.811-.01,1.622-.005,2.433,0,.32,0,.393.076.394.4,0,.146,0,.293,0,.439a.265.265,0,0,1-.287.287c-.219,0-.439.011-.658,0-.163-.01-.2.052-.2.205.005,1.5,0,3,0,4.507q0,1.864,0,3.729a1.99,1.99,0,0,1-.46,1.326,1.264,1.264,0,0,1-1.026.48c-2.373-.006-4.746,0-7.119,0a1.487,1.487,0,0,1-1.572-1.281,2.92,2.92,0,0,1-.051-.574C1004.168,554.579,1004.17,553.263,1004.17,551.947Zm1.138-4.425c0,.059-.009.092-.009.125q0,4.148,0,8.3a1.424,1.424,0,0,0,.091.425.354.354,0,0,0,.39.26c2.3-.008,4.6-.005,6.9-.005.384,0,.479-.075.558-.461a1.478,1.478,0,0,0,.028-.3q0-4.058,0-8.116v-.227Zm1.989-1.156h3.976c-.145-.351-.274-.685-.425-1.007a.3.3,0,0,0-.227-.12q-1.335-.013-2.67,0a.3.3,0,0,0-.224.124C1007.574,545.684,1007.444,546.016,1007.3,546.366Z"
        transform="translate(0)"
        fill="#de4841"
      />
      <path
        id="Path_63"
        data-name="Path 63"
        d="M1089.834,675.258q0,1.236,0,2.473c0,.286-.083.37-.363.373-.146,0-.293,0-.439,0-.231,0-.324-.095-.325-.33,0-.572,0-1.143,0-1.715q0-1.625,0-3.25c0-.315.077-.391.385-.392.14,0,.279,0,.419,0,.232.005.324.1.325.33Q1089.836,674,1089.834,675.258Z"
        transform="translate(-82.26 -123.195)"
        fill="#de4841"
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M1202.843,675.26q0-1.236,0-2.473c0-.286.083-.37.363-.373.146,0,.292,0,.439,0,.233.005.324.094.326.33,0,.552,0,1.1,0,1.655q0,1.655,0,3.31c0,.315-.076.391-.384.393-.14,0-.279,0-.419,0-.232-.005-.324-.1-.325-.33C1202.842,676.935,1202.844,676.1,1202.843,675.26Z"
        transform="translate(-191.847 -123.195)"
        fill="#de4841"
      />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M1146.912,675.278q0,1.236,0,2.473c0,.287-.081.368-.365.37q-.2,0-.4,0c-.281,0-.364-.084-.364-.372q0-1.276,0-2.552,0-1.2,0-2.393c0-.286.083-.369.366-.371q.2,0,.4,0c.281,0,.363.085.364.373Q1146.914,674.042,1146.912,675.278Z"
        transform="translate(-137.063 -123.212)"
        fill="#de4841"
      />
    </g>
  </svg>
);

export const DatePickerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
  >
    <mask id="ipSApplication0">
      <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
        <path strokeLinecap="round" d="M40.04 22v20h-32V22" />
        <path
          fill="#00000"
          d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
        />
      </g>
    </mask>
    <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSApplication0)" />
  </svg>
);

export const DropDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      id="Icon_material-block"
      data-name="Icon material-block"
      d="M13,3A10,10,0,1,1,3,13,10,10,0,0,1,13,3Zm8,10a8,8,0,0,0-8-8A7.9,7.9,0,0,0,8.1,6.69L19.31,17.9A7.9,7.9,0,0,0,21,13Zm-8,8a7.9,7.9,0,0,0,4.9-1.69L6.69,8.1A7.9,7.9,0,0,0,5,13,8,8,0,0,0,13,21Z"
      transform="translate(-3 -3)"
      fill="currentColor"
    />
  </svg>
);
