import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import colorServices from "../../../../services/colorService";
import { colorActions } from "../index";

export const getColors = createAsyncThunk(
  "colors",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await colorServices.getColors(payload);
      dispatch(colorActions.setColorData(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(colorActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
