import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { getFeedByIdService } from "../../../../services/feedService";
import { setPatchedFeed, setPatchedFeedUserProfile } from "..";

export const getFeedById = createAsyncThunk(
  "feedById",
  async function ({ id, params, type }, { dispatch }) {
    dispatch(alertActions.clear());
    console.log("type", type);
    try {
      const { data } = await getFeedByIdService(id, params);
      if (type !== "profile") {
        dispatch(setPatchedFeed(data, type));
      } else {
        dispatch(setPatchedFeedUserProfile(data, type));
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
