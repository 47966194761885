import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ticketService from "../../../../services/ticketService";
import { alertActions } from "../../alert";
import { setTicketError } from "..";
import { getTicket } from "./getTicket";
import { getTicketAllocations } from "./getTicketAllocations";
import { getUserProjects } from "../../project/thunks/getUserProjects";

export const postUploadSheetTicket = createAsyncThunk(
  "post/uploadSheetTicket",
  async ({ formData, setIsUploadSheetModalOpen }, { dispatch }) => {
    try {
      dispatch(setTicketError(""));
      const {
        data: { message: successMessage },
      } = await ticketService.postUploadSheetTicketData(formData);
      if (formData.has("listId") || formData.has("sprintId")) {
        dispatch(
          getTicketAllocations({
            params: {
              ...(formData.has("listId") && { listId: formData.get("listId") }),
              ...(formData.has("sprintId") && {
                sprintId: formData.get("sprintId"),
              }),
            },
            key: "gantt",
            reset: true,
          }),
        );
        dispatch(
          getTicket({
            ...(formData.has("listId") && { listId: formData.get("listId") }),
            ...(formData.has("sprintId") && {
              sprintId: formData.get("sprintId"),
            }),
          }),
        );
      }
      dispatch(getUserProjects({}));

      dispatch(alertActions.success(successMessage));
      setIsUploadSheetModalOpen(false);
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(setTicketError(errorMessage));

      if (Array.isArray(errorMessage)) {
        errorMessage.forEach(() => {
          dispatch(alertActions.error("Something went wrong"));
        });
      } else {
        dispatch(alertActions.error(errorMessage));
      }
    }
  },
);
