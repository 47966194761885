import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import departmentServices from "../../../../services/departmentServices";
import { alertActions } from "../../alert/index";

export const updateDepartment = createAsyncThunk(
  "update-department",
  async (
    { departmentId, permissions, name, description, cb },
    { dispatch },
  ) => {
    dispatch(alertActions.clear());

    try {
      const {
        data: { message: successMessage },
      } = await departmentServices.updateDepartment({
        departmentId,
        permissions,
        name,
        description,
      });

      dispatch(alertActions.success(successMessage));
      console.log("cb", cb);
      if (cb) cb();
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
