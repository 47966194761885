import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getDocumentsById } from "../../../../services/documentService";
import { documentActions } from "..";

export const getUserDocumentsById = createAsyncThunk(
  "documentsbyid/get",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const {
        data: { data, message: successMessage },
      } = await getDocumentsById(id);
      dispatch(
        documentActions.setDocumentDataById({
          data: { data },
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
