/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../design/custom/components/floating_notification.scss";
import CheckGreenIco from "../assets/images/check-bg-green.svg";
import CommentNotifIco from "../assets/images/comment-notif.svg";
import { notificationActions } from "../store/slices/notifications";
// import createWebSocketConnection from "../helpers/socketIO";
import { getInitials } from "../utils/utils";
import socket from "../helpers/socketIO";
import { decryptAccessToken } from "../utils/encryptionUtils";

const NotificationToaster = () => {
  const dispatch = useDispatch();
  const { latestNotificationData } = useSelector(
    ({ notification }) => notification,
  );
  const [visible, setVisible] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const { authData } = useSelector((state) => state.auth);

  const pauseTimer = () => {
    clearTimeout(timerId);
  };

  const resumeTimer = () => {
    const newTimerId = setTimeout(() => {
      setVisible(false);
    }, 3000);
    setTimerId(newTimerId);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setVisible(false);
    }, 3000);
    setTimerId(timerId);

    return () => {
      clearTimeout(timerId);
    };
  }, [visible]);

  useEffect(() => {
    if (!visible) dispatch(notificationActions.clearLatestMessage());
  }, [visible]);
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const decryptedAccessToken =
      token && decryptAccessToken(token, process.env.REACT_APP_SECRET_KEY);
    if (decryptedAccessToken) {
      socket.on("GET_UNREAD_NOTIFICATION_COUNT", (data) => {
        if (data && data.title)
          dispatch(
            notificationActions.setLatestMessageData({
              user: data?.user,
              title: data?.title ?? data?.title,
              latestMessage: data?.latestMessage,
              createdBy: data?.createdBy,
              detailMessage: data?.detailMessage,
            }),
          );
      });

      return () => {
        socket.off("GET_UNREAD_NOTIFICATION_COUNT");
      };
    }
  }, []);

  useEffect(() => {
    if (
      latestNotificationData &&
      latestNotificationData?.createdBy !== authData._id
    )
      setVisible(true);
  }, [latestNotificationData]);

  function getRandomNumber() {
    const min = 1;
    const max = 6;
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    return random;
  }
  return (
    visible &&
    latestNotificationData && (
      <div
        className="flt-notif-container position-fixed bottom-0 start-0 p-3"
        onMouseEnter={pauseTimer}
        onMouseLeave={resumeTimer}
      >
        <div className="flt-notif show resolved">
          <div className="flt-notif-content">
            <div className="flt-notif-ico ">
              {latestNotificationData?.user?.photo ? (
                <img
                  src={
                    latestNotificationData?.user?.photo
                      ? `https://erptest.spericorn.com/repo/uploads/${latestNotificationData?.user?.photo}`
                      : CommentNotifIco
                  }
                  alt="user"
                />
              ) : (
                <span className={`nameAvatar avatar-${getRandomNumber()}`}>
                  {getInitials(latestNotificationData.user?.employeeName)}
                </span>
              )}
              <span className="online" />
            </div>

            <div className="flt-notif-conts">
              <div className="flt-notif-header">
                <button
                  type="button"
                  className="btn-close notify"
                  onClick={() => setVisible(false)}
                />
                <span className="notif-item-ico">
                  <img src={CheckGreenIco} alt="" />
                </span>
                <span className="txt">
                  {latestNotificationData.detailMessage}
                </span>
                <span className="taskdt">
                  {latestNotificationData && latestNotificationData.title}
                </span>
              </div>

              <div className="flt-notif-txt-cont">
                {latestNotificationData &&
                  latestNotificationData?.latestMessage}
                {/* <span className="mention">@Roshmon</span>
                <span className="mention">@Abi</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default NotificationToaster;
