/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { RightDoubleArrow } from "../SubheaderIcons";
import searchNormalIcon from "../../../assets/images/search-normal.svg";
import { useThunk } from "../../../hooks/useThunk";

import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import { getAllUsers } from "../../../store/slices/common/thunks/getUsers";
import { useFilter } from "../../../context/useFilter";
// import usersGroupIcon from "../../../assets/images/users_group.svg";
// import statusIcon from "../../../assets/images/Status_icon.svg";

const Users = ({ toggleUsers, handleFilter }) => {
  const [userData, setUserData] = useState([]);
  const [users, isLoading] = useThunk(getAllUsers);
  const { allUsers } = useSelector((state) => state.common);
  const { filter } = useFilter();
  const [imgArray, setimgArray] = useState([]);

  useEffect(() => {
    setUserData(allUsers);
  }, [allUsers]);
  useEffect(() => {
    users();
  }, []);

  const handleSearch = (value) => {
    const filteredResults = allUsers?.filter((item) =>
      item.employeeName.toLowerCase().includes(value.toLowerCase()),
    );
    setUserData(filteredResults);
  };
  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };
  const getInitailBackground = (name) => {
    const nameParts = name?.split(" ");
    const initials = nameParts
      ?.map((part) => part.charAt(0).toUpperCase())
      .join("");
    return (
      <div className="avathar-sm avathar-01">
        <span className="name-ltr">{initials}</span>
      </div>
    );
  };

  return (
    <div className="slide-menu-inner flex-column show">
      <div className="menu-header ">
        <div className="title_otr d-flex">
          <div className="m-title">Users</div>
          <div className="closed-btn" onClick={toggleUsers}>
            <RightDoubleArrow />
          </div>
        </div>
        <div className="search-area d-flex align-items-center">
          <div className="search-icon">
            <img src={searchNormalIcon} alt="Icon" />
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="list-items-main custom-scroll">
        <ul className="p-0">
          {/* <li className="d-flex align-items-center">
            <div className="avathar-sm">
              <img src={usersGroupIcon} alt="icon" />
            </div>
            <div className="rgt_content d-flex flex-column">
              <div className="title_fst">Unassigned</div>
              <div className="text-sec">37 tasks</div>
            </div>
          </li> */}
          {isLoading
            ? Array?.from({ length: userData?.length || 0 })?.map(
                (_, index) => (
                  <li key={index}>
                    <CommonSkeleton />
                  </li>
                ),
              )
            : userData?.map((item, index) => (
                <li
                  className="d-flex align-items-center"
                  key={index}
                  onClick={() =>
                    handleFilter(filter.users === item._id ? "" : item._id)
                  }
                >
                  <div className="usr-prof-list d-flex align-items-center">
                    {item.photo ? (
                      imgArray?.includes(item?._id) ? (
                        getInitailBackground(item.employeeName)
                      ) : (
                        <div className="user-profile-thumb avathar-sm">
                          <img
                            onError={(target) =>
                              handleOnError(target, item?._id)
                            }
                            onLoad={(target) => {
                              target.target.style.display = "block";
                            }}
                            src={`https://erptest.spericorn.com/repo/uploads/${item.photo}`}
                            alt=""
                          />
                        </div>
                      )
                    ) : (
                      getInitailBackground(item.employeeName)
                    )}
                    <div className="rgt_content d-flex flex-column">
                      <div className="title_fst">{item.employeeName}</div>
                      {/* <div className="text-sec">37 tasks</div> */}
                    </div>
                  </div>
                  <div className="chkbox-wrp assignee-chkbox">
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={filter.users === item._id}
                    />
                    <span className="checkmark" />
                  </div>
                </li>
              ))}
          {/* <div className="description">Only users with tasks are shown</div> */}
        </ul>
      </div>
      <div className="slider-menu-ftr mt-auto pb-4">
        <div className="divitions">
          <hr />
        </div>
        {/* <div className="lft-rgt d-flex justify-content-between border-tb mt-0">
          <button className="title_text" type="button">
            TEAMS{" "}
          </button>
          <button className="select-text" type="button">
            Select All
          </button>
        </div> */}
        {/* <div className="dropdown-item d-flex swtich-center mt-3">
          <div className="custom_switch d-flex align-items-center">
            <label className="d-flex w-100">
              <div className="switch-txt d-flex">
                <div className="icon me-2">
                  <img src={statusIcon} alt="icon" />
                </div>
                Assigned comments
              </div>
              <div className="switch">
                <input type="checkbox" />
                <span className="slider round" />
              </div>
            </label>
          </div>
        </div> */}
      </div>
    </div>
  );
};
Users.propTypes = {
  toggleUsers: PropTypes.func,
  handleFilter: PropTypes.func,
};
export default Users;
