import { createSlice } from "@reduxjs/toolkit";

export const name = "navBar";

const createReducers = () => {
  const setProjectIcon = (state, { payload }) => {
    state.ProjectIcon = payload.data;
  };

  return {
    setProjectIcon,
  };
};

function createInitialState() {
  return {
    ProjectIcon: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const navBarActions = slice.actions;
export const navBarReducer = slice.reducer;
