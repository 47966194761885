/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import moment from "moment";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CalendarIcon, ClockIcon } from "./MeetingSvgIcons";
import Threedots from "../../../components/DropDowns/Threedots";
import editIcon from "../../../assets/images/icon-edit.svg";
import deleteIcon from "../../../assets/images/delete_icon.svg";
import okIcon from "../../../assets/images/ok-icon-001.svg";
import { getFirstLetters } from "../../../utils/utils";
import { getMultyUserProfile } from "../../../store";
import { userProfileActions } from "../../../store/slices/userProfile";
import { useThunk } from "../../../hooks/useThunk";
import { patchMeetingStatus } from "../../../store/slices/meetingSchedule/thunks/patchMeetingStatus";
import CompletedMeeting from "./CompletedMeeting";
import { deleteMeeting } from "../../../store/slices/meetingSchedule/thunks/deleteMeeting";

import { meetingScheduleActions } from "../../../store/slices/meetingSchedule";
import { Deletemodal } from "../../../components/DropDowns/DeleteModal";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

function ScheduleList({
  data,
  showCompleted,
  isLoading,
  setPagination,
  hasPermission,
}) {
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [updateComplete, completeLoading] = useThunk(patchMeetingStatus);
  const [deleteMeetingById, setdeleteMeetingById] = useState(null);
  const [completeMeetById, setCompleteMeetById] = useState(null);

  const [deleteSchedule, deleteLoading] = useThunk(deleteMeeting);

  const [imgArray, setimgArray] = useState([]);

  const [deleteModal, setDeleteModal] = useState({
    openclose: false,
    name: "",
    id: "",
  });

  const dispatch = useDispatch();

  const completeFunction = (id, type, data) => {
    if (data?._id) {
      setCompleteMeetById(data?._id);
    }
    updateComplete({
      postData: { data: data?._id },
      params: {
        page: 1,
        limit: 6,
      },
    });
    setPagination({ page: 1, limit: 6 });
  };

  const deleteFunction = (id, type, data) => {
    setDeleteModal({
      openclose: true,
      name: data?.title,
      id: data?._id,
    });
  };

  const handleEmailAccountDelete = (data) => {
    if (data) {
      setdeleteMeetingById(data);
    }
    deleteSchedule({
      postData: { data },
      params: {
        page: 1,
        limit: 100,
      },
    });
    setPagination({ page: 1, limit: 6 });

    setDeleteModal({
      openclose: false,
      name: "",
      id: "",
    });
  };

  const updateFunction = (id, type, data) => {
    dispatch(meetingScheduleActions.setShowMeetingSlider(data));
  };

  const actionButtons = [
    hasPermission(permissionConstants.permissions.MEETING_SCHEDULER_EDIT) && {
      name: "Edit",
      onclickfunction: updateFunction,
      className: "edit-text-color",
      icon: editIcon,
      // permission: permissionConstants.permissions.EMAIL_ACCOUNT_EDIT,
    },
    hasPermission(permissionConstants.permissions.MEETING_SCHEDULER_DELETE) && {
      name: "Delete",
      onclickfunction: deleteFunction,
      className: "text-danger",
      icon: deleteIcon,
      // permission: permissionConstants.permissions.EMAIL_ACCOUNT_DELETE,
    },
    hasPermission(
      permissionConstants.permissions.MEETING_SCHEDULER_MARK_COMPLETED,
    ) && {
      name: "Meeting Completed",
      onclickfunction: completeFunction,
      className: "edit-text-color",
      icon: okIcon,
      // permission: permissionConstants.permissions.EMAIL_ACCOUNT_DELETE,
    },
  ];

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };
  const handleOnLoad = (e) => {
    e.target.style.display = "block";
  };
  const upcomingSchedules = data?.filter((e) => !e?.complete);

  function calculateTimeDuration(timeSlotData) {
    const timeDuration = timeSlotData ? timeSlotData.length * 30 : 0;

    if (timeDuration > 30) {
      const timeInHours = timeDuration / 60;
      return `${timeInHours} hours`;
    } else {
      return `${timeDuration} minutes`;
    }
  }

  const tooltipRef = useRef(null);

  return (
    <div className="page-contents inner-page-contents">
      <div className="cover__inner custom-scroll">
        <div className="row  row-cards">
          {!showCompleted && !isLoading && (
            <div className="title-otr col-12">
              <div className="page-title">
                Upcoming Schedules This Month{" "}
                <span>{upcomingSchedules?.length}</span>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="page-contents inner-page-contents">
              <div className="cover__inner">
                <div className="row  row-cards">
                  <div className="title-otr col-12">
                    <CommonSkeleton />
                  </div>
                  {Array?.from({ length: 3 }).map((_, index) => (
                    <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                      <div className="hd-otr d-flex">
                        <div className="d-flex flex-grow-1 flex-column">
                          <div className="title-lg">
                            {" "}
                            <CommonSkeleton height={200} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            data?.map((e) => {
              if (!e?.complete && !showCompleted) {
                const inputTimestamp = e?.date;
                // Parse the input timestamp using moment
                const dateTime = moment.utc(inputTimestamp);

                // Format date as "DD-Month-YYYY"

                const formattedDate = dateTime.format("DD-MMMM-YYYY");

                const timeSlotData = e?.timeSlot?.map((time) => time);

                const timeDuration = calculateTimeDuration(timeSlotData);

                // Sort timeSlotData by id
                const sortedTimeSlotData = timeSlotData
                  .slice()
                  .sort((a, b) => a.id - b.id);

                // Find consecutive ranges of ids
                const idRanges = [];
                let currentRange = [sortedTimeSlotData[0]];
                for (let i = 1; i < sortedTimeSlotData?.length; i++) {
                  if (
                    sortedTimeSlotData[i].id ===
                    currentRange[currentRange.length - 1].id + 1
                  ) {
                    currentRange?.push(sortedTimeSlotData[i]);
                  } else {
                    idRanges?.push(currentRange);
                    currentRange = [sortedTimeSlotData[i]];
                  }
                }
                idRanges?.push(currentRange);

                // Extract start and end times for each range
                const displayRanges = idRanges?.map((range) => {
                  const start = range[0]?.time?.split(" - ")[0];
                  const end = range[range.length - 1]?.time?.split(" - ")[1];
                  return `${start} - ${end}`;
                });

                // const combinedTime = `${startTime} - ${endTime}`;

                return (deleteLoading && deleteMeetingById === e?._id) ||
                  (completeLoading && completeMeetById === e?._id) ? (
                  Array?.from({ length: 1 }).map((_, index) => (
                    <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                      <div className="hd-otr d-flex">
                        <div className="d-flex flex-grow-1 flex-column">
                          <div className="title-lg">
                            {" "}
                            <CommonSkeleton height={200} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="conf-card">
                      <div className="hd-otr d-flex">
                        <div className="d-flex flex-grow-1 flex-column">
                          <div className="title-lg">{e?.title}</div>
                          <small>
                            {timeDuration || "-"} , {e?.type}
                          </small>
                        </div>
                        {/* {/ three dots  /} */}
                        <Threedots
                          // type={data?.name}
                          dropDownVisibility
                          TaskData={e}
                          ActionButtons={actionButtons}
                          className="more-option position-relative"
                        />
                      </div>

                      <div className="mind-rw">
                        <div className="info">
                          <div className="icon-sm">
                            <ClockIcon />
                          </div>
                          <span>{displayRanges?.join(" ")}</span>
                        </div>

                        <div className="info">
                          <div className="icon-sm">
                            <CalendarIcon />
                          </div>
                          <span>{formattedDate}</span>
                        </div>
                      </div>
                      <div className="ftr" ref={tooltipRef}>
                        {e?.invites?.map(
                          (empl, index) =>
                            index < 2 && (
                              <OverlayTrigger
                                container={tooltipRef}
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`${empl?.employeeName}-tooltip`}>
                                    {empl?.employeeName}
                                  </Tooltip>
                                }
                              >
                                <div className="avatar__group">
                                  <div
                                    className={`avathar-sm avathar-0${
                                      (index % 4) + 1
                                    }`}
                                  >
                                    <div className="online" />

                                    {empl?.photo ? (
                                      imgArray?.includes(empl?.employeeName) ? (
                                        <div
                                          className="name userProf bg-7"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            getMultyUsers(empl?._id);
                                            dispatch(
                                              userProfileActions.setShowUserProfile(
                                                true,
                                              ),
                                            );
                                          }}
                                        >
                                          {getFirstLetters(empl?.employeeName)}
                                        </div>
                                      ) : (
                                        <img
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            getMultyUsers(empl?._id);
                                            dispatch(
                                              userProfileActions.setShowUserProfile(
                                                true,
                                              ),
                                            );
                                          }}
                                          onError={(error) =>
                                            handleOnError(
                                              error,
                                              empl?.employeeName,
                                            )
                                          }
                                          onLoad={(error) =>
                                            handleOnLoad(error)
                                          }
                                          src={`https://erptest.spericorn.com/repo/uploads/${empl?.photo}`}
                                          alt=""
                                        />
                                      )
                                    ) : (
                                      <span
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          getMultyUsers(empl?._id);
                                          dispatch(
                                            userProfileActions.setShowUserProfile(
                                              true,
                                            ),
                                          );
                                        }}
                                      >
                                        {" "}
                                        {getFirstLetters(empl?.employeeName)}
                                      </span>
                                    )}
                                  </div>

                                  {/* {/ more dropdown  /} */}
                                </div>
                              </OverlayTrigger>
                            ),
                        )}

                        <div className="avatar__group">
                          {/* {/ more dropdown  /} */}

                          <Dropdown>
                            {e?.invites?.length > 2 && (
                              <Dropdown.Toggle
                                id="checklist-toggles"
                                className="avathar-sm avathar-more"
                              >
                                <div className="avatar__group">
                                  <div className="online" />

                                  <span>
                                    {" "}
                                    + {(e?.invites?.length ?? 0) - 2}
                                  </span>
                                </div>
                              </Dropdown.Toggle>
                            )}
                            {e?.invites?.length > 2 && (
                              <Dropdown.Menu className="no-bg  pb-1 dropdown-menu right ">
                                <div className="list-items-main custom-scroll list-items-1 ps-2 pe-1 allocation-drp-dwn right">
                                  <ul className="p-0">
                                    {e?.invites?.map(
                                      ({ employeeName, _id, photo }, index) =>
                                        index >= 2 && (
                                          <li className="d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                              {photo ? (
                                                imgArray?.includes(_id) ? (
                                                  <div
                                                    className={`avathar-sm ms-0  avathar-0${
                                                      (index % 3) + 1
                                                    }`}
                                                  >
                                                    <span>
                                                      {getFirstLetters(
                                                        employeeName,
                                                      )}
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <img
                                                    onError={(target) =>
                                                      handleOnError(target, _id)
                                                    }
                                                    onLoad={(target) => {
                                                      target.target.style.display =
                                                        "block";
                                                    }}
                                                    className="usrImg"
                                                    src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
                                                    alt=""
                                                  />
                                                )
                                              ) : (
                                                <div
                                                  className={`avathar-sm ms-0  avathar-0${
                                                    (index % 4) + 1
                                                  }`}
                                                >
                                                  <span>
                                                    {employeeName
                                                      ?.slice(0, 2)
                                                      .toUpperCase()}
                                                  </span>
                                                </div>
                                              )}
                                              <div className="rgt_content d-flex flex-column">
                                                <div
                                                  className={
                                                    photo
                                                      ? "title_fst ms-1"
                                                      : "title_fst"
                                                  }
                                                >
                                                  {employeeName}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="ms-auto profile-btn"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                getMultyUsers(_id);
                                                dispatch(
                                                  userProfileActions.setShowUserProfile(
                                                    true,
                                                  ),
                                                );
                                              }}
                                            >
                                              Profile
                                            </div>
                                          </li>
                                        ),
                                    )}
                                  </ul>
                                </div>
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                        </div>
                      </div>

                      {/* delete modal  */}
                      {!!deleteModal && (
                        <Deletemodal
                          ModalShow={deleteModal}
                          deleteFunc={handleEmailAccountDelete}
                          closeModal={() => {
                            setDeleteModal({
                              openclose: false,
                              name: "",
                              id: "",
                            });
                          }}
                          cancelModal={() => {
                            setDeleteModal({
                              openclose: false,
                              name: "",
                              id: "",
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              } else {
                // If e.complete is not true, return null or an empty fragment
                return null;
              }
            })
          )}
          {isLoading && (
            <div className="page-contents inner-page-contents">
              <div className="cover__inner">
                <div className="row  row-cards">
                  <div className="title-otr col-12">
                    <CommonSkeleton />
                  </div>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                      <div className="hd-otr d-flex">
                        <div className="d-flex flex-grow-1 flex-column">
                          <div className="title-lg">
                            {" "}
                            <CommonSkeleton height={200} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {hasPermission(
            permissionConstants.permissions.MEETING_SCHEDULER_COMPLETED,
          ) && <CompletedMeeting data={data} isLoading={isLoading} />}
        </div>

        {/* completed meeting  */}
      </div>
    </div>
  );
}

export default ScheduleList;
