import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import technologyIconServices from "../../../../../services/technologyIconServices";
import { alertActions } from "../../../alert";
import { getTechnologyIcon } from "./getTechnologyIcon";

export const deleteTechnologyIcon = createAsyncThunk(
  "delete-technology",
  async ({ id, technologyId }, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await technologyIconServices.deleteTechnologyIcon({
        id,
        technologyId,
      });

      dispatch(alertActions.success(successMessage));
      dispatch(getTechnologyIcon());
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
