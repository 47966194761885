import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { todoActions } from "..";
import todoService from "../../../../services/todoService";

export const getColors = createAsyncThunk(
  "list-colors",
  async (params, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const { data } = await todoService.getColors(params);

      dispatch(
        todoActions.setColor({
          data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
