import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import myNotificationsService from "../../../../services/MyNotificationService";
import { alertActions } from "../../alert/index";
import { myNotificationsActions } from "../index";

export const getActivityNotifications = createAsyncThunk(
  "myNotifications",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await myNotificationsService.activityNotifications();
      dispatch(myNotificationsActions.setActivityNotification(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(myNotificationsActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
