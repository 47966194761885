import {
  ActivityLogIcon,
  BoardIcon,
  CalendarIcon,
  GanttIcon,
  ListIcon,
} from "./navbar-icons";

import colorIcon from "../../assets/images/color_icon.svg";
import copyIcon from "../../assets/images/copy_icon.svg";
import { UserReport } from "../../pages/Allocations/AllocationSvg";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

export const menuItems = {
  project: [
    {
      id: 1,
      name: "List",
      key: "list",
      path: "",
      svg: <ListIcon />,
      module: permissionConstants.modules.PROJECTS_MANAGEMENT,
      hasPermission:
        permissionConstants.submodules.PROJECT_MANAGEMENT_LIST_VIEW,
    },
    {
      id: 2,
      name: "Board",
      key: "board",
      path: "board",
      svg: <BoardIcon />,
      module: permissionConstants.modules.PROJECTS_MANAGEMENT,
      hasPermission:
        permissionConstants.submodules.PROJECT_MANAGEMENT_BOARD_VIEW,
    },
    {
      id: 3,
      name: "Gantt",
      key: "gantt",
      path: "gantt",
      svg: <GanttIcon />,
      module: permissionConstants.modules.PROJECTS_MANAGEMENT,
      hasPermission:
        permissionConstants.submodules.PROJECT_MANAGEMENT_GANTT_VIEW,
    },
    {
      id: 4,
      name: "Calendar",
      key: "calendar",
      path: "calendar",
      svg: <CalendarIcon />,
      module: permissionConstants.modules.PROJECTS_MANAGEMENT,
      hasPermission: permissionConstants.submodules.PROJECT_MANAGEMENT_CALENDER,
    },
    {
      id: 5,
      name: "Activity Log",
      key: "activityLog",
      path: "activitylog",
      svg: <ActivityLogIcon />,
      module: permissionConstants.modules.PROJECTS_MANAGEMENT,
      hasPermission:
        permissionConstants.submodules.PROJECT_MANAGEMENT_ACTIVITY_LOG,
    },
  ],
  notifications: [
    {
      id: 1,
      name: "New",
      key: "new",
      path: "/new",
      svg: "",
      module: permissionConstants.modules.NOTIFICATIONS,
      hasPermission: permissionConstants.submodules.NOTIFICATIONS_NEW,
    },
    {
      id: 2,
      name: "Cleared",
      key: "cleared",
      path: "/isRead",
      svg: "",
      module: permissionConstants.modules.NOTIFICATIONS,
      hasPermission: permissionConstants.submodules.NOTIFICATIONS_CLEARED,
    },
  ],
  allocation: [
    {
      id: 1,
      name: "List",
      key: "listUser",
      path: "",
      svg: <ListIcon />,
      module: permissionConstants.modules.ALLOCATION,
      hasPermission: permissionConstants.submodules.ALLOCATION_LIST,
    },
    {
      id: 2,
      name: "User Report",
      key: "allocationDashboard",
      path: "/dashboard",
      svg: <UserReport />,
      module: permissionConstants.modules.ALLOCATION,
      hasPermission: permissionConstants.submodules.ALLOCATION_USER_REPORT,
    },
  ],
  report: [
    {
      id: 1,
      name: "List",
      key: "reportlist",
      path: "",
      svg: <ListIcon />,
      module: permissionConstants.modules.REPORTS,
      hasPermission: permissionConstants.submodules.REPORTS_LIST,
    },
    {
      id: 2,
      name: "Calendar",
      key: "reportcalendar",
      path: "/calendar",
      svg: <CalendarIcon />,
      module: permissionConstants.modules.REPORTS,
      hasPermission: permissionConstants.submodules.REPORTS_CALENDER,
    },
    {
      id: 3,
      name: "Dashboard",
      key: "reportDashboard",
      path: "/dashboard",
      svg: <ListIcon />,
      module: permissionConstants.modules.REPORTS,
      hasPermission: permissionConstants.submodules.REPORTS_DASHBOARD,
    },
  ],
  todo: [
    {
      id: 1,
      name: "List",
      key: "todolist",
      path: "",
      svg: <ListIcon />,
      // module: permissionConstants.modules.WIDGETS,
      // hasPermission: permissionConstants.submodules.TODO,
    },
    {
      id: 2,
      name: "Calendar",
      key: "todocalendar",
      path: "/calendar",
      svg: <CalendarIcon />,
      // module: permissionConstants.modules.WIDGETS,
      // hasPermission: permissionConstants.submodules.TODO,
    },
  ],
};
export const dropDownItems = [
  {
    name: "Color & Avatar",
    value: "colorAvatar",
    svg: colorIcon,
  },
  {
    name: "Copy link",
    value: "copyLink",
    svg: copyIcon,
  },
];

export const notificationDropdown = [
  {
    value: "action1",
    label: "action1",
  },
  {
    value: "action2",
    label: "action2",
  },
];
