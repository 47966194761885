import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import dashboardServices from "../../../../services/dashboard";
import { dashboardActions } from "../index";

export const getDashboardQualityData = createAsyncThunk(
  "dashboard",
  async function ({ dates, projectId }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      if (projectId) {
        const {
          data: { data },
        } = await dashboardServices.qualityChartswithId(dates, projectId);
        dispatch(dashboardActions.setProjectQualityChart(data));
      } else {
        const {
          data: { data },
        } = await dashboardServices.dashboardQualityCharts(dates);
        dispatch(dashboardActions.setDashboardQualityChart(data));
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(dashboardActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
