import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import departmentServices from "../../../../services/departmentServices";
import { departmentActions } from "..";
import { alertActions } from "../../alert/index";

export const getDepartmentPermissions = createAsyncThunk(
  "department-id",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data: departmentPermissions },
      } = await departmentServices.getDepartmentPermissions(id);

      dispatch(
        departmentActions.setDepartmentPermission({
          departmentPermissions,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
