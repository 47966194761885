// import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { useSelector, useDispatch } from "react-redux";
// import { withTitle } from "../../hoc/withTitle";
// import "../../design/custom/pages/notifications.scss";
// import CheckLg from "../../assets/images/check-lg.svg";
// import Undo from "../../assets/images/undo_without_bg.svg";
// import Double from "../../assets/images/double-check.svg";
// import { getMultyUserProfile, getNotification } from "../../store";
// import { useThunk } from "../../hooks/useThunk";
// import { readThunk } from "../../store/slices/notifications/thunks/isRead";
// import renderNotificationContent from "./NotificationContent";
// import { allReadNotification } from "../../store/slices/notifications/thunks/isReadAll";
// import { userProfileActions } from "../../store/slices/userProfile";
// import NoNotificationYet from "./NoNotificationYet";
// import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
// import { RightArrow } from "./icons";

// const Notification = () => {
//   const { isRead } = useParams();
//   const [getNotificationData, isLoading] = useThunk(getNotification);
//   const [setNotificationUpdate] = useThunk(readThunk);
//   const [setAllNotificationUpdate] = useThunk(allReadNotification);
//   const { notificationData } = useSelector(({ notification }) => notification);
//   const [shouldFetchData, setShouldFetchData] = useState(false);
//   const dispatch = useDispatch();
//   const [getMultyUsers] = useThunk(getMultyUserProfile);

//   useEffect(() => {
//     if (isRead === "isRead") getNotificationData({ isRead: true });
//     else getNotificationData();
//   }, [isRead]);

//   const markNotificationAsRead = async (notificationId) => {
//     await setNotificationUpdate(notificationId);
//     setShouldFetchData(true);
//   };

//   const readAllNotifications = async (notificationIds) => {
//     await setAllNotificationUpdate(notificationIds);
//     setShouldFetchData(true);
//   };

//   useEffect(() => {
//     if (shouldFetchData) {
//       if (isRead === "isRead") getNotificationData({ isRead: true });
//       else getNotificationData();
//       setShouldFetchData(false);
//     }
//   }, [shouldFetchData, isRead]);

//   const userProfileData = (e, id) => {
//     e.stopPropagation();
//     getMultyUsers(id);
//     dispatch(userProfileActions.setShowUserProfile(true)); // Open the profile modal
//   };
//   const renderCommentTooltip = (comment) => {
//     const tooltip = <Tooltip className="comment-tooltip">{comment}</Tooltip>;
//     return (
//       <OverlayTrigger placement="bottom" overlay={tooltip}>
//         <span className="comment">{comment}</span>
//       </OverlayTrigger>
//     );
//   };

//   return isLoading ? (
//     <div className="notifications-container">
//       {Array.from({ length: 10 }).map((_, i) => (
//         <div className="notifications-card-cover" key={i}>
//           <div className="notifications-card" key={i}>
//             <div className="sprint-body-cover">
//               <CommonSkeleton />
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   ) : (
//     <div className="notifications-container">
//       {notificationData?.countNewAll === 0 ? (
//         <NoNotificationYet height={50} />
//       ) : (
//         <div className="notifications-card-cover">
//           {isRead === "isRead" ? null : (
//             <button
//               onClick={() =>
//                 readAllNotifications(
//                   notificationData?.data.map(({ _id }) => _id),
//                 )
//               }
//               className="notification-btn btn-float"
//               type="button"
//             >
//               <img src={Double} alt="" />
//             </button>
//           )}

//           <h6 className="mt-4">
//             Notifications{" "}
//             <span className="not-num">{notificationData?.countNewAll}</span>
//           </h6>

//           {notificationData?.data?.map((item, index) => (
//             <div className="notifications-card" key={item?._id}>
//               <button
//                 className="notification-btn"
//                 type="button"
//                 onClick={() => {
//                   markNotificationAsRead(item?._id);
//                 }}
//               >
//                 {isRead === "isRead" ? (
//                   <img src={Undo} alt="" />
//                 ) : (
//                   <img src={CheckLg} alt="" />
//                 )}
//               </button>
//               {item.ActivityLogs?.payload?.projectName &&
//               item.ActivityLogs?.payload?.ticketName ? (
//                 <h4 className="card-title">
//                   {item.ActivityLogs?.payload?.projectName}
//                   <img src={RightArrow} alt="" />
//                   {item.ActivityLogs?.payload?.ticketName}
//                 </h4>
//               ) : (
//                 item.activityTypeData?.name
//               )}
//               <div className="sprint-body-cover">
//                 <div className="task-details-cover">
//                   {renderNotificationContent(
//                     item,
//                     index,
//                     userProfileData,
//                     renderCommentTooltip,
//                   )}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default withTitle(Notification);

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { withTitle } from "../../hoc/withTitle";
import "../../design/custom/pages/notifications.scss";
import CheckLg from "../../assets/images/check-lg.svg";
import Undo from "../../assets/images/undo_without_bg.svg";
import Double from "../../assets/images/double-check.svg";
import { getMultyUserProfile, getNotification } from "../../store";
import { useThunk } from "../../hooks/useThunk";
import { readThunk } from "../../store/slices/notifications/thunks/isRead";
import renderNotificationContent from "./NotificationContent";
import { allReadNotification } from "../../store/slices/notifications/thunks/isReadAll";
import { userProfileActions } from "../../store/slices/userProfile";
import NoNotificationYet from "./NoNotificationYet";
import { RightArrow } from "./icons";

const Notification = () => {
  const { isRead } = useParams();
  const [getNotificationData] = useThunk(getNotification);
  const [setNotificationUpdate] = useThunk(readThunk);
  const [setAllNotificationUpdate] = useThunk(allReadNotification);
  const { notificationData } = useSelector(({ notification }) => notification);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const dispatch = useDispatch();
  const [getMultyUsers] = useThunk(getMultyUserProfile);

  useEffect(() => {
    if (isRead === "isRead") getNotificationData({ isRead: true });
    else getNotificationData();
  }, [isRead, getNotificationData]);

  const markNotificationAsRead = async (notificationId) => {
    await setNotificationUpdate(notificationId);
    setShouldFetchData(true);
  };

  const readAllNotifications = async (notificationIds) => {
    await setAllNotificationUpdate(notificationIds);
    setShouldFetchData(true);
  };

  useEffect(() => {
    if (shouldFetchData) {
      if (isRead === "isRead") getNotificationData({ isRead: true });
      else getNotificationData();
      setShouldFetchData(false);
    }
  }, [shouldFetchData, isRead, getNotificationData]);

  const userProfileData = (e, id) => {
    e.stopPropagation();
    getMultyUsers(id);
    dispatch(userProfileActions.setShowUserProfile(true)); // Open the profile modal
  };

  const renderCommentTooltip = (comment) => {
    const tooltip = <Tooltip className="comment-tooltip">{comment}</Tooltip>;
    return (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <span className="comment">{comment}</span>
      </OverlayTrigger>
    );
  };

  return (
    <div className="notifications-container">
      {notificationData?.countNewAll === 0 ? (
        <NoNotificationYet height={50} />
      ) : (
        <div className="notifications-card-cover">
          {isRead === "isRead" ? null : (
            <button
              onClick={() =>
                readAllNotifications(
                  notificationData?.data.map(({ _id }) => _id),
                )
              }
              className="notification-btn btn-float"
              type="button"
            >
              <img src={Double} alt="" />
            </button>
          )}

          <h6 className="mt-4">
            Notifications{" "}
            <span className="not-num">{notificationData?.countNewAll}</span>
          </h6>

          {notificationData?.data?.map((item, index) => (
            <div className="notifications-card" key={item?._id}>
              <button
                className="notification-btn"
                type="button"
                onClick={() => {
                  markNotificationAsRead(item?._id);
                }}
              >
                {isRead === "isRead" ? (
                  <img src={Undo} alt="" />
                ) : (
                  <img src={CheckLg} alt="" />
                )}
              </button>
              {item.ActivityLogs?.payload?.projectName &&
              item.ActivityLogs?.payload?.ticketName ? (
                <h4 className="card-title">
                  {item.ActivityLogs?.payload?.projectName}
                  <img src={RightArrow} alt="" />
                  {item.ActivityLogs?.payload?.ticketName}
                </h4>
              ) : (
                item.activityTypeData?.name
              )}
              <div className="sprint-body-cover">
                <div className="task-details-cover">
                  {renderNotificationContent(
                    item,
                    index,
                    userProfileData,
                    renderCommentTooltip,
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withTitle(Notification);
