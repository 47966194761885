import PropTypes from "prop-types";

const Counter = ({ title, value, icon }) => {
  console.log("value", value);
  return (
    <div className="col-lg-4 col-xl col-md-6 mb-4">
      <div className="count-tickets d-flex align-items-center">
        <div className="card-title-block">
          <h2 className="card-title">{title} </h2>
          <p className="card-count">{value}</p>
        </div>
        <div className="card-icon-block">
          <img src={icon} alt={title} />
        </div>
      </div>
    </div>
  );
};

Counter.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
};
export default Counter;
