/* eslint-disable  */
import { alertActions } from "../../../../../store/slices/alert";

// date formatter
export const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  const currentDate = new Date();
  const timeDifference = (currentDate - providedDate) / (1000 * 3600 * 24);

  switch (true) {
    case timeDifference === 0:
      return "today";
    case timeDifference === 1:
      return "yesterday";
    case timeDifference < 4:
      return `${timeDifference} days ago`;
    case timeDifference < 7:
      return "one week ago";
    default:
      const monthsDifference =
        (currentDate.getFullYear() - providedDate.getFullYear()) * 12 +
        (currentDate.getMonth() - providedDate.getMonth());
      if (monthsDifference === 1) {
        return "one month ago";
      } else if (monthsDifference > 1) {
        return `${monthsDifference} months ago`;
      } else {
        const yearsDifference =
          currentDate.getFullYear() - providedDate.getFullYear();
        if (yearsDifference === 1) {
          return "one year ago";
        } else {
          return `${yearsDifference} years ago`;
        }
      }
  }
};

// download file
export const handleDownload = (selectedFile, dispatch) => {
  if (selectedFile) {
    const { content, name } = selectedFile;
    const decodedContent = atob(content);
    const blob = new Blob([decodedContent], {
      type: "application/octet-stream",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } else {
    dispatch(alertActions.error("File is not selected."));
  }
};

// view row code
export const viewRawCode = (Content) => {
  if (Content) {
    let rawWindow = window.open("", "_blank");
    rawWindow.document.write("<pre>" + Content + "</pre>");
    rawWindow.document.close();
  }
};

// arrange file & file structure
export const processFolderStructure = (data = []) => {
  const reduceFunction = (acc, crr) => {
    const accLength = acc.length;
    const lastItem = structuredClone(acc[accLength - 1]);
    if (lastItem?.type === "blob") {
      return [...acc, crr];
    }
    const { type, path, ...rest } = crr;
    if (path?.includes(lastItem?.path)) {
      lastItem.sub = reduceFunction(lastItem?.sub || [], crr);
      acc.pop();
      return [...acc, lastItem];
    }
    return [...acc, crr];
  };

  let result = data.reduce(reduceFunction, []);

  result = result.map((item) => {
    if (item.type === "tree" && item.sub) {
      item.sub.sort((a, b) => {
        if (a.type === "tree") return -1;
        if (b.type === "tree") return 1;
        return 0;
      });
    }
    return item;
  });

  result.sort((a, b) => {
    if (a.type === "tree") return -1;
    if (b.type === "tree") return 1;
    return 0;
  });

  return result;
};

// media display
export const Base64ImageDisplay = ({ base64Content, fileExtension }) => {
  let mimeType = `image/${fileExtension}`;
  if (fileExtension === "svg") {
    mimeType = "image/svg+xml";
  }

  const dataUrl = `data:${mimeType};base64,${base64Content}`;

  return (
    <img
      style={{ width: "100px", height: "100px" }}
      src={dataUrl}
      alt="Content"
    />
  );
};
