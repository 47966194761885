import { Link } from "react-router-dom";
import "../../design/custom/structure/header.scss";

function Header() {
  return (
    <header className="header d-flex align-items-center">
      <div className="headerLinks">
        <Link to="/dashboard" className="headerLink active">
          Dashboard
        </Link>
      </div>

      {/* <div className="headerDatepicker">
        <div className="datePickWrap">
          <input
            type="text"
            className="form-control"
            placeholder="Date Range"
          />
        </div>
      </div> */}
    </header>
  );
}

export default Header;
