import Gateway from "../gateway/gateway";

const getTimesheet = (params) => Gateway.get("/timesheet", { params });

const getTimesheetTable = (params) =>
  Gateway.get("/timesheet/monthlytable", { params });

const getTimesheetPdf = (params) => Gateway.get("/timesheet/pdf", { params });

export default {
  getTimesheet,
  getTimesheetTable,
  getTimesheetPdf,
};
