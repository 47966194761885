import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import sprintService from "../../../../services/sprintService";
import { getUserProjects } from "../../project/thunks/getUserProjects";
import { sprintActions } from "..";

export const PatchSprintActive = createAsyncThunk(
  "holiday/get",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data } = await sprintService.PatchSprintActiveService(
        payload.sprint,
        payload.project,
      );
      dispatch(getUserProjects({}));
      dispatch(sprintActions.setSprint(data.message));
      dispatch(alertActions.success(data.message));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
