/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { teamActions } from "../index";
import teamService from "../../../../services/teamService";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getProjectsTabCounts } from "../../project/thunks/projectCounts";

export const getTeam = createAsyncThunk(
  `get/team`,
  async (params, { dispatch }) => {
    try {
      console.log("params", params);
      const {
        data: { allocatedUsers, message: successMessage },
      } = await teamService.getTeamData(params);
      dispatch(getProjectsTabCounts(params?.projectId));
      await dispatch(
        teamActions.setTeamData({
          allocatedUsers,
          params,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(teamActions.setTeamErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
