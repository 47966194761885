import PropTypes from "prop-types";
import { getFirstLetters } from "../../../utils/utils";
import NoTasksBoard from "./NoTasksBoard";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";

function ReviewContents({ projectInfo, isLoading }) {
  // validation profile pic url
  const isValidURL = (url) => {
    const pattern =
      /^(https?:\/\/)?(www\.)?[\da-z.-]+\.[a-z.]{2,6}\/?([^\s]*)$/;
    return pattern.test(url);
  };

  // Generate formattedArray based on allocationHours for each project

  const formattedArray = projectInfo?.allProjects?.reduce((acc, e) => {
    const timeCalculation = Array?.from({ length: e?.allocationHours }, () => ({
      projectName: e?.projectName,
      allocationHours: e?.allocationHours,
      description: e?.description,
      logo: e?.logo,
    }));
    acc = [...acc, ...timeCalculation];
    return acc;
  }, []);

  // Initialize the hour count
  const hourCount = 1;

  // Ensure 12 items in formattedArray with null placeholders
  while (formattedArray?.length < 12) {
    formattedArray.push(null);
  }

  return (
    <div className="row">
      <div className="col">
        <div className="allocation-sheet-wrp custom-scroll">
          {isLoading
            ? Array.from({ length: 12 }).map((_, i) => (
                <div className="allocation-col" key={i}>
                  <CommonSkeleton height={320} />
                </div>
              ))
            : formattedArray?.map((item, index) => {
                // Increment the hour count
                const currentHour = hourCount + index;
                return (
                  <div className="allocation-col" key={index}>
                    <span className="hourly-tasks">{`Hour ${currentHour}`}</span>

                    <div className="allocation-blk">
                      {item !== null ? (
                        <>
                          <span className="assigned-task">
                            ASSIGNED PROJECT
                          </span>
                          <div className="project-details-blk btm-border">
                            <div className="profile-task">
                              <span>
                                {item?.photo && isValidURL(item?.photo) ? (
                                  <img src={item?.photo} alt="profile pic" />
                                ) : (
                                  getFirstLetters(item?.projectName)
                                )}
                              </span>
                            </div>
                            <div className="task-details">
                              <div className="name">{item?.projectName}</div>
                              <div className="hours-total">
                                Total Approved Hours : {item?.allocationHours}
                              </div>
                            </div>
                          </div>
                          <div className="task-info-blk">
                            <div className="info-title">PROJECT INFO:</div>
                            <div className="desc">
                              <p>{item?.description}</p>
                            </div>
                          </div>
                          <span className="assigned-task mb-0">
                            TASK ASSIGNED BY:
                          </span>
                          <div className="project-details-blk assigned-blk">
                            {/* <div className="profile-task">
                              <span>S</span>
                            </div> */}
                            <div className="task-details">
                              <div className="name">-</div>
                              <div className="hours-total">-</div>
                            </div>
                          </div>
                          <span className="assigned-task mb-0">CLIENT:</span>
                          <div className="project-details-blk client">
                            <div className="profile-task">
                              <img src="/img/user1.png" alt="" />
                            </div>
                            <div className="task-details">
                              <div className="name">-</div>
                              <div className="hours-total">-</div>
                              <div className="link">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="arrow-right"
                                  width="13.156"
                                  height="13.156"
                                  viewBox="0 0 13.156 13.156"
                                >
                                  <path
                                    id="Vector"
                                    d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
                                    transform="translate(4.884 2.236)"
                                    fill="none"
                                    stroke="currentcolor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Vector-2"
                                    data-name="Vector"
                                    d="M0,0H13.156V13.156H0Z"
                                    transform="translate(13.156 13.156) rotate(180)"
                                    fill="none"
                                    opacity={0}
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <NoTasksBoard key={`no-task-${index}`} />
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
ReviewContents.propTypes = {
  isLoading: PropTypes.bool,
  projectInfo: PropTypes.arrayOf(),
};

export default ReviewContents;
