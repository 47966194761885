import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../../design/custom/pages/teams.scss";
import Popup from "../../TaskStatusModal";
import { useThunk } from "../../../hooks/useThunk";
import { patchTicketAllocation } from "../../../store/slices/ticket/thunks/patchTicketAllocation";
import clock from "../../../assets/images/clock.svg";
import profiles from "../../../assets/images/profile.svg";
import { hasSubModulePermission } from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
// import CommonSkeleton from "../../SkeletonLoader/CommonSkeleton";

const CustomEvent = ({
  event,
  setSelectedTicket,
  isTicket,
  isLoading,
  hasPermission,
}) => {
  console.log("hasPermission", hasPermission);
  const [updateTicketThunk] = useThunk(patchTicketAllocation);
  const { ticketStatus } = useSelector((state) => state.ticketReducer);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isActiveTask, setIsActiveTask] = useState(false);
  const [eventData, setEventData] = useState(null);
  const { userProfile } = useSelector((state) => state.userProfile);
  const containerRef = useRef(null);
  const route = useLocation();

  const ModalShow = async () => {
    if (
      hasSubModulePermission(
        userProfile?.formattedPermissions,
        permissionConstants.submodules.TASK_DETAILS_MODAL,
      )
    ) {
      if (setSelectedTicket) setSelectedTicket(event.id);
      if (isTicket) isTicket(false);
    }
  };

  const handleButtonClick = () => {
    setIsActiveTask(!isActiveTask);
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (events) => {
    if (containerRef.current && !containerRef.current.contains(events.target)) {
      setIsActiveTask(false);
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (eventData) {
      updateTicketThunk({
        id: event.id,
        patchData: { ticketStatus: eventData },
      });
      if (document.getElementsByClassName("rbc-overlay")[0])
        document.getElementsByClassName("rbc-overlay")[0].style.display =
          "none";
    }
  }, [eventData]);
  useEffect(() => {
    const elementsWithTitles = document.querySelectorAll(".rbc-event [title]");
    elementsWithTitles.forEach((element) => {
      element.removeAttribute("title");
    });

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const dayCol = document.getElementsByClassName("rbc-day-bg");
    const custEvt = document.querySelectorAll(".custom-event");
    const daywidth = dayCol[0].offsetWidth;
    custEvt.forEach((evt) => {
      evt.style.width = `${daywidth}px`;
      evt.classList.add("evt-load");
    });
  }, []);
  const matchedStatus =
    ticketStatus &&
    ticketStatus.status?.find((status) => status._id === event.status);
  const eventStatusColor = matchedStatus ? matchedStatus.colorInfo : null;

  function hexToRGBA(hex, opacity = 1) {
    hex = hex?.replace("#", "");
    const r = parseInt(hex?.substring(0, 2), 16);
    const g = parseInt(hex?.substring(2, 4), 16);
    const b = parseInt(hex?.substring(4, 6), 16);

    if (opacity === 1) {
      return `#${hex}`;
    }
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  const getPopupTopPosition = () => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const popupHeight = 163;
    const windowHeight = window.innerHeight;
    if (containerRect.bottom + popupHeight <= windowHeight) {
      return "27%";
    }
    return `-${popupHeight}px`;
  };
  const getFirstLetters = (name) => {
    const words = name?.split(" ");

    if (words?.length >= 2) {
      const firstLetters = words?.slice(0, 2).map((word) => word[0]);
      return firstLetters?.join("").toUpperCase();
    }
    if (words?.length === 1) {
      return words[0][0].toUpperCase();
    }
    return "";
  };
  const isValidURL = (url) => {
    const pattern =
      /^(https?:\/\/)?(www\.)?[\da-z.-]+\.[a-z.]{2,6}\/?([^\s]*)$/;
    return pattern.test(url);
  };
  const numberOfUser = event.users?.length;

  return (
    <>
      {isLoading ? (
        <div ref={containerRef}>
          <div
            className="color-overlay"
            style={{ backgroundColor: `${hexToRGBA(eventStatusColor, 0.2)}` }}
          >
            <div className="custom-event" />
            {/* <CommonSkeleton /> */}
          </div>
        </div>
      ) : (
        <div ref={containerRef}>
          <div
            className="color-overlay"
            style={{ backgroundColor: `${hexToRGBA(eventStatusColor, 0.2)}` }}
          >
            <div className="custom-event">
              <div className="overlay-content-wrp">
                <div className="d-flex  align-self-stretch">
                  <button
                    className={`elastic-btn ${isActiveTask ? "active" : ""} ${
                      route?.pathname === "/report/calendar"
                        ? "route-report"
                        : ""
                    }`}
                    style={{
                      backgroundColor: `${hexToRGBA(eventStatusColor)}`,
                    }}
                    type="button"
                    onClick={() => {
                      if (route?.pathname !== "/report") {
                        handleButtonClick();
                      }
                    }}
                  >
                    <span className="ico">
                      <svg width="18" height="4" viewBox="0 0 18 4">
                        <g
                          id="Group_59398"
                          data-name="Group 59398"
                          transform="translate(-1886.226 -68)"
                        >
                          <circle
                            id="Ellipse_4"
                            data-name="Ellipse 4"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(1886.226 68)"
                            fill="currentcolor"
                          />
                          <circle
                            id="Ellipse_5"
                            data-name="Ellipse 5"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(1893.226 68)"
                            fill="currentcolor"
                          />
                          <circle
                            id="Ellipse_6"
                            data-name="Ellipse 6"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(1900.226 68)"
                            fill="currentcolor"
                          />
                        </g>
                      </svg>
                    </span>
                  </button>
                  <div>
                    {isPopupVisible &&
                      isActiveTask &&
                      route?.pathname !== "/report/calendar" && (
                        <div
                          className={`${
                            isActiveTask ? "show" : ""
                          } color-overlay`}
                          style={{
                            top: getPopupTopPosition(),
                            right: "27px",
                          }}
                        >
                          <Popup
                            data={ticketStatus?.status}
                            updateTaskStatus={setEventData}
                          />
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className={`user-pic ${
                    route?.pathname === "/report/calendar" ? "report" : ""
                  }`}
                >
                  {event.users[0]?.photo &&
                  isValidURL(event.users[0]?.photo) ? (
                    <span className="profTxt">
                      <img
                        src={event.users[0] && event.users[0]?.photo}
                        alt="profile-pic"
                      />
                    </span>
                  ) : (
                    event.users[0]?.employeeName && (
                      <span className="profTxt">
                        {getFirstLetters(event.users[0]?.employeeName)}
                      </span>
                    )
                  )}
                  {numberOfUser > 1 && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${event.id}`}>
                          {event.users?.map((eachUser, index) => (
                            <div key={index} className="d-flex pb-2">
                              {eachUser.photo && isValidURL(eachUser.photo) ? (
                                <span className="profTxt">
                                  <img src={eachUser.photo} alt="profile-pic" />
                                </span>
                              ) : (
                                eachUser.employeeName && (
                                  <span className="profTxt">
                                    {getFirstLetters(eachUser.employeeName)}
                                  </span>
                                )
                              )}
                              <div className="user-tooltip-name pt-1">
                                {eachUser.employeeName}
                              </div>
                            </div>
                          ))}
                        </Tooltip>
                      }
                    >
                      <span className="profTxt">{`+${numberOfUser - 1}`}</span>
                    </OverlayTrigger>
                  )}
                </div>
                <div
                  className="user-entry"
                  onClick={(e) => {
                    ModalShow(e);
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${event.id}`}>
                        {event.title}
                      </Tooltip>
                    }
                  >
                    <h5 className="user-entry-title">
                      <span
                        className="task-title"
                        style={{
                          paddingTop: !(
                            event?.users && event.users[0]?.employeeName
                          )
                            ? "6px"
                            : "0",
                        }}
                      >
                        {event.title}
                      </span>
                      {route.pathname !== "/report/calendar" ? (
                        <span
                          className="hr-tag"
                          style={{
                            backgroundColor: `${hexToRGBA(eventStatusColor)}`,
                          }}
                        >
                          {event.estimationHours} hr
                        </span>
                      ) : null}
                    </h5>
                  </OverlayTrigger>
                  {route.pathname === "/report/calendar" ? (
                    <span className="user-entry-dtls">
                      {event?.users && event.users[0]?.employeeName && (
                        <span className="ico">
                          <img src={profiles} alt="profile" />
                        </span>
                      )}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${event.id}`}>
                            {event?.users && event.users[0]?.employeeName}
                          </Tooltip>
                        }
                      >
                        <span
                          className="name"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                        >
                          {event?.users && event.users[0]?.employeeName}
                        </span>
                      </OverlayTrigger>
                      {event.estimationHours && (
                        <>
                          <span className="ico ms-auto">
                            <img src={clock} alt="time" />
                          </span>
                          <span className="time">
                            {event.estimationHours}hr
                          </span>
                        </>
                      )}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {showModal ? (
        <TaskDetail showModal={showModal} setShowModal={setSelectedTicket} />
      ) : null} */}
    </>
  );
};

CustomEvent.propTypes = {
  event: PropTypes.shape({
    ticketInfo: PropTypes.string,
    id: PropTypes.string,
    users: PropTypes.node,
    color: PropTypes.string,
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    status: PropTypes.string,
    estimationHours: PropTypes.string,
    end: PropTypes.instanceOf(Date),
  }),
  setSelectedTicket: PropTypes.func,
  isTicket: PropTypes.func,
  isLoading: PropTypes.bool,
  hasPermission: PropTypes.func,
};

export default CustomEvent;
