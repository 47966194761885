import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { iconActions } from "..";

import notificationIconServices from "../../../../../services/notificationIconServices";
import { alertActions } from "../../../alert";

export const getNotificationIconById = createAsyncThunk(
  "list-notificationIconByid",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const { data } = await notificationIconServices.getNotificationById(id);

      dispatch(
        iconActions.singleNotification({
          data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
