import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { listFeedService } from "../../../../services/feedService";
import { getFeeds, Pagination } from "..";

export const getFeed = createAsyncThunk(
  "getFeed",
  async function ({ params, reset }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data, message: successMessage } = await listFeedService(params);
      dispatch(
        getFeeds({
          data,
          successMessage,
          reset,
        }),
      );
      dispatch(Pagination({ data: data?.data?.pagination }));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
