/* eslint-disable */

import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DoubleTick, SingleTick, DisableClick } from "./notificationIcons";
import infoImage from "../../../../assets/images/info.svg";
import { useThunk } from "../../../../hooks/useThunk";
import { updateNotificationSettings } from "../../../../store";
import CommonSkeletonTableRows from "../../../../components/SkeletonLoader/TableSkeleton";
import { useState } from "react";

const NotificationTable = ({ title, data, isLoading }) => {
  console.log("table", isLoading);
  const [upDateSettingsStatus, updateLoading] = useThunk(
    updateNotificationSettings,
  );
  const [updatedId, setupdatedId] = useState(null);

  console.log("ggg", upDateSettingsStatus);
  const handleClick = (name, id) => {
    upDateSettingsStatus({ patchData: name, id });
    setupdatedId(id);
  };

  // Function to generate the tooltip content based on the title
  const generateTooltipContent = (title) => {
    switch (title) {
      case "Assigned Comments":
        return "Will notify you when any comment is assigned to a team member in a task you're watching.";
      case "Comments Assigned":
        return "Will notify you when team member assigns you a comment.";
      case "Due Date overdue":
        return "Send a notification as soon as the task becomes overdue.";
      default:
        return null;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table user-notification-table mb-0">
        <thead>
          <tr>
            <th className="text-capitalize">{title}</th>
            <th>Email</th>
            <th>SMS</th>
            <th>In-App</th>
            <th>
              Browser <span>(enable notifications)</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {isLoading ? (
            <CommonSkeletonTableRows
              limit={data?.length}
              cols={5}
              height={20}
            />
          ) : data && data?.length ? (
            data?.map((e, index) => {
              const tooltipContent = generateTooltipContent(e?.name);
              return updateLoading && updatedId === e?._id ? (
                <CommonSkeletonTableRows limit={1} cols={5} height={20} />
              ) : (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center first-col-data">
                      {tooltipContent ? (
                        <span>
                          {e?.name}{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`${e?.name}-tooltip`}>
                                {tooltipContent}
                              </Tooltip>
                            }
                          >
                            <img
                              className="cursonPointer"
                              src={infoImage}
                              alt="info"
                            />
                          </OverlayTrigger>
                        </span>
                      ) : (
                        e?.name
                      )}
                      <span className="icon-tick">
                        {e?.notificationSettings &&
                        e?.notificationSettings?.email &&
                        e?.notificationSettings?.sms &&
                        e?.notificationSettings?.inApp &&
                        e?.notificationSettings?.browser ? (
                          <DoubleTick />
                        ) : (
                          <DisableClick />
                        )}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span
                      className={`icon-tick ${
                        e?.notificationSettings &&
                        e?.notificationSettings?.email
                          ? ""
                          : "disabled"
                      }`}
                      onClick={() => {
                        if (e?.notificationSettings)
                          handleClick(
                            {
                              ...e.notificationSettings,
                              email: !e.notificationSettings?.email,
                              _id: undefined,
                            },
                            e?._id,
                          );
                      }}
                    >
                      {e?.notificationSettings &&
                      e?.notificationSettings?.email ? (
                        <SingleTick />
                      ) : (
                        <DisableClick />
                      )}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-6">
                      <span
                        className={`icon-tick ${
                          e?.notificationSettings &&
                          e?.notificationSettings?.sms
                            ? ""
                            : "disabled"
                        }`}
                        onClick={() => {
                          if (e?.notificationSettings)
                            handleClick(
                              {
                                ...e?.notificationSettings,
                                sms: !e?.notificationSettings?.sms,
                                _id: undefined,
                              },
                              e?._id,
                            );
                        }}
                      >
                        {e?.notificationSettings &&
                        e?.notificationSettings?.sms ? (
                          <SingleTick />
                        ) : (
                          <DisableClick />
                        )}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span
                      className={`icon-tick ${
                        e?.notificationSettings &&
                        e?.notificationSettings?.inApp
                          ? ""
                          : "disabled"
                      }`}
                      onClick={() => {
                        if (e?.notificationSettings)
                          handleClick(
                            {
                              ...e?.notificationSettings,
                              inApp: !e?.notificationSettings?.inApp,
                              _id: undefined,
                            },
                            e?._id,
                          );
                      }}
                    >
                      {e?.notificationSettings &&
                      e?.notificationSettings?.inApp ? (
                        <SingleTick />
                      ) : (
                        <DisableClick />
                      )}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`icon-tick ${
                        e?.notificationSettings &&
                        e?.notificationSettings?.browser
                          ? ""
                          : "disabled"
                      }`}
                      onClick={() => {
                        if (e?.notificationSettings)
                          handleClick(
                            {
                              ...e?.notificationSettings,
                              browser: !e?.notificationSettings?.browser,
                              _id: undefined,
                            },
                            e?._id,
                          );
                      }}
                    >
                      {" "}
                      {e?.notificationSettings &&
                      e?.notificationSettings?.browser ? (
                        <SingleTick />
                      ) : (
                        <DisableClick />
                      )}
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            // Render "No Data Found" if data is empty
            <tr>
              <td colSpan="5">No Data Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
NotificationTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
};

export default NotificationTable;
