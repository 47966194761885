/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { teamActions } from "../index";
import teamService from "../../../../services/teamService";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getTeam } from "./getTeam";

export const patchHour = createAsyncThunk(
  `patch/teamHour`,
  async ({ projectId, userId, patchData }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await teamService.patchTeamHour(projectId, userId, patchData);

      dispatch(
        teamActions.editTeamHour({
          data,
          successMessage,
        }),
      );

      await dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(teamActions.setTeamErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
