import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import roleServices from "../../../../services/roleService";
import { alertActions } from "../../alert/index";

export const updateRole = createAsyncThunk(
  "update-Role",
  async (
    { departmentId, roleId, permissions, name, category, cb },
    { dispatch },
  ) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await roleServices.updateRole({
        departmentId,
        roleId,
        name,
        category,
        permissions,
      });

      dispatch(alertActions.success(successMessage));
      console.log("cb", cb);

      if (cb) cb();
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
