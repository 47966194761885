import Select from "react-select";
import PropTypes from "prop-types";
import { ClockIco } from "../../../pages/TicketManagement/components/Gantt/assets/svgIcons";

const GanttSightSelect = ({ setUnit }) => {
  const options = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
    { label: "Quarter", value: "quarter" },
    { label: "Half Year", value: "halfYear" },
  ];

  const onChange = ({ value }) => setUnit(value);

  return (
    <div className="todo-drp d-flex align-items-center">
      <span className="d-flex">
        <ClockIco />
      </span>
      <Select
        classNamePrefix="react-select"
        className="react-select-container"
        options={options}
        onChange={onChange}
      />
    </div>
  );
};

GanttSightSelect.propTypes = {
  setUnit: PropTypes.func,
};

export default GanttSightSelect;
