import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { activityLogActions } from "../index";
import activityService from "../../../../services/activityService";
import { alertActions } from "../../alert";

export const getAllActivityLog = createAsyncThunk(
  "get/activity/all",
  async (params, { dispatch }) => {
    try {
      const {
        data,
        data: { message: successMessage },
      } = await activityService.getActivityALLLogData(params);

      dispatch(
        activityLogActions.setAllActivityLogData({
          data,
          params,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
