import Gateway from "../gateway/gateway";

const addNotification = (data) =>
  Gateway.post("/notificationicon", data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

const getNotificationtList = (params) =>
  Gateway.get("/notificationicon", { params });

const getNotificationById = (id) => Gateway.get(`/notificationicon/${id}`);

const updateNotificationList = ({ id, data }) =>
  Gateway.patch(`/notificationicon/${id}`, data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

const deleteNotification = (id) => Gateway.delete(`/notificationicon/${id}`);

const getKey = () => Gateway.get("/key");

export default {
  addNotification,
  getNotificationtList,
  updateNotificationList,
  getNotificationById,
  deleteNotification,
  getKey,
};
