import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { emailAccountActions } from "..";
import emailAccountServices from "../../../../../services/emailAccountServices";
import { alertActions } from "../../../alert";

export const getEmailAccountById = createAsyncThunk(
  "list-emailAccountByid",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const { data } = await emailAccountServices.getEmailAccountById(id);

      dispatch(
        emailAccountActions.singleEmailAccount({
          data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
