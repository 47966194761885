import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types"; //
import { useState, useEffect, useRef } from "react";
import plusIcon from "../../assets/images/+Icon.svg";

export const TableConfig = ({
  handleSelectClick,
  configData,
  progress,
  dropDownVisibility,
}) => {
  const [state, setState] = useState("");

  const dropdownRef = useRef(null);

  const OnPlusIconToggle = (progress) => {
    if (state === progress) {
      setState("");
    } else {
      setState(progress);
    }
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setState("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <div
      className="field__th"
      style={{ zIndex: state === progress ? 3 : 0, marginLeft: "0.4%" }}
      ref={dropdownRef}
    >
      <OverlayTrigger placement="top" overlay={<Tooltip>Add columns</Tooltip>}>
        <img
          style={{ cursor: "pointer" }}
          src={plusIcon}
          alt=""
          onClick={dropDownVisibility ? () => OnPlusIconToggle(progress) : null}
        />
      </OverlayTrigger>
      {state === progress ? (
        <div className="dropdown-menu" style={{ display: "block" }}>
          {configData?.map(({ name, isVisible }) => (
            <div key={name} className="d-flex drp-items">
              <p> {name}</p>
              <Form.Check
                type="switch"
                id="toggle-switch"
                checked={isVisible}
                onChange={() => handleSelectClick(name)}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
TableConfig.propTypes = {
  handleSelectClick: PropTypes.func.isRequired,
  configData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isVisible: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  progress: PropTypes.string.isRequired,
  dropDownVisibility: PropTypes.bool.isRequired,
};
