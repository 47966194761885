import { createContext, useState } from "react";
import { Helmet } from "react-helmet-async";

import PropTypes from "prop-types";

const TitleContext = createContext();

function TitleProvider({ children }) {
  const [title, setTitle] = useState("SPERICORN PMS");
  return (
    <TitleContext.Provider value={{ setTitle, title }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      {children}
    </TitleContext.Provider>
  );
}
TitleProvider.propTypes = {
  children: PropTypes.element,
};
export { TitleProvider };
export default TitleContext;
