import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import "../../../design/custom/pages/TaskCard.scss";
import "../../../design/custom/pages/teams.scss";

import menuItems from "./config";
// import Button from "../../../components/Button";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import { getTeam } from "../../../store/slices/team/thunks/getTeam";
import {
  hasSubModulePermission,
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import { useThunk } from "../../../hooks/useThunk";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
import { teamActions } from "../../../store/slices/team";
import { getProjectsTabCounts } from "../../../store/slices/project/thunks/projectCounts";

const TicketManagementLayout = () => {
  const dispatch = useDispatch();
  const route = useLocation();
  const { shortCode } = useParams();

  const [projectId, setProjectId] = useState(null);

  const [team] = useThunk(getTeam);
  const [projectTabCount] = useThunk(getProjectsTabCounts);

  const { userProfile } = useSelector((state) => state.userProfile);

  const { teamDataforExport } = useSelector((state) => state.team);
  const { projectData, projectTabCount: tabCounts } = useSelector(
    ({ project }) => project,
  );

  const options = {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formatDateStructure = (lastWorked) => {
    if (!lastWorked) {
      return "-";
    }

    return new Date(lastWorked).toLocaleString("en-US", options);
  };

  useEffect(() => {
    if (projectId) projectTabCount(projectId);
  }, []);

  useEffect(() => {
    if (projectId) {
      team({ projectId, page: 1, limit: 10 });
    }
  }, [projectId, shortCode]);

  useEffect(() => {
    if (projectData)
      setProjectId(
        projectData.find((each) => each.shortCode === shortCode)?._id,
      );
  }, [projectData, shortCode]);

  useEffect(() => {
    if (teamDataforExport) {
      exportToExcel();
      dispatch(teamActions.clearExportData());
    }
  }, [teamDataforExport]);

  const hasPermission = (permission) =>
    hasSubModulePermission(userProfile?.formattedPermissions ?? [], permission);

  const hasTeamPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.PROJECT_MANAGEMENT_TEAMS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const activeNavLink = (shortCode, path) => {
    if (path) {
      return `/project/${shortCode}/${path}` === route.pathname;
    }
    return `/project/${shortCode}` === route.pathname;
  };

  const renderListItems = () => {
    const menuItemData = menuItems.componentNavbar || [];

    if (menuItemData.length === 0) return null;
    return menuItemData
      .filter(
        (each) =>
          each.hasPermission === true || hasPermission(each.hasPermission),
      )
      .map((listItem) => (
        <li key={listItem.id} className="nav-item d-flex align-items-center">
          <Link
            to={listItem.path}
            className={
              activeNavLink(shortCode, listItem.path)
                ? "nav-link active"
                : "nav-link"
            }
          >
            <div className="icon icon-02">{listItem.svg}</div>
            {listItem.name}
            {listItem.name === "Teams" && (
              <span className="count">{tabCounts?.totalAllocatedUsers}</span>
            )}
            {listItem.name === "Technology" && (
              <span className="count">{tabCounts?.technologyCount}</span>
            )}
            {listItem.name === "Docs" && (
              <span className="count">{tabCounts?.totalDocuments}</span>
            )}
          </Link>
        </li>
      ));
  };
  const exportToExcel = async () => {
    const data = teamDataforExport || [];
    const dataWithHeaders = [
      [
        "Name",
        "Role",
        "Hours",
        "Last Worked On",
        "Tickets Taken",
        "Tickets Completed",
        "Tickets To Do",
        "Tickets In-progress",
        "Tickets Under Review",
        "Tickets First Approved",
        "Tickets Rejection Count",
        "Performance Index",
        "Quality Index",
      ],
      ...data.map((item) => [
        item?.user || "-",
        item?.roleName || "-",
        item?.allocationHours || "-",
        formatDateStructure(item?.lastWorked) || "-",
        item?.totalTickets || "-",
        item?.completed || "-",
        item?.todo || "-",
        item?.inprogress || "-",
        item?.readyforQA || "-",
        item?.ticketsFirstApproved || "-",
        item?.rejectionCount || "-",
        item?.performanceIndex || "-",
        item?.qualityIndex || "-",
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    const columnWidths = [
      { wch: 30 },
      { wch: 30 },
      { wch: 8 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    worksheet["!cols"] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Teams");

    const fileName = "teams.xlsx";
    XLSX.writeFile(wb, fileName);
  };
  const handleExport = () => {
    const params = {
      // page: 1,
      // limit: activityPagination?.limit,
      // search: filter?.search?.trim(),
      // matches: filter.filter,
      // me: filter?.me,
      // users: filter?.users,
      // sortOrder: activityPagination.order,
      // sortBy: activityPagination.column,
      forReport: true, // Add the forReport
      projectId,
    };
    // if (!params.search || !params.search.trim().length) {
    //   delete params.search;
    // }
    // if (!params.me || !params.me.length) delete params.me;
    // if (!params.users || !params.users.length) delete params.users;
    team(params);
  };
  return (
    <>
      <SubHeader
        showSearchBar={
          route.pathname === `/project/${shortCode}/teams` ||
          route.pathname === `/project/${shortCode}/docs`
        }
        showFilter={
          (route.pathname === `/project/${shortCode}/teams` &&
            hasTeamPermission(
              permissionConstants.permissions
                .PROJECT_MANAGEMENT_TEAMS_CUSTOM_FILTER,
            )) ||
          route.pathname === `/project/${shortCode}/docs`
        }
        showExport={
          route.pathname === `/project/${shortCode}/teams` &&
          hasTeamPermission(
            permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_EXPORT,
          )
        }
        exportFnc={handleExport}
      />

      <div className="page-contents inner-page-contents">
        {/* <div className="row">
          {route.pathname === `/project/${shortCode}/teams` && (
            <div className="title-otr d-flex flex-wrap align-items-center">
              <div className="ms-auto rgt-sctn">
                <div className="add-dropdown-block">
                  <div className="dropdown">
                    <Dropdown autoClose="inside">
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        bsPrefix="dropdown-toggle-btn"
                      >
                        <div onClick={() => setShowForm(true)}>Add</div>
                      </Dropdown.Toggle>
                      {showForm && (
                        <Add showForm={showForm} setShowForm={setShowForm} />
                      )}
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="d-flex tab-menu-otr bg_light">
                <nav className="navbar navbar-expand-lg">
                  <ul className="nav nav-pills">{renderListItems()}</ul>
                </nav>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default TicketManagementLayout;
