import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { emailAccountActions } from "..";
import emailAccountServices from "../../../../../services/emailAccountServices";
import { alertActions } from "../../../alert";
// import { getEmailAccountList } from "./getEmailAccountDetails";

export const addEmailAccount = createAsyncThunk(
  "emailAccount",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await emailAccountServices.addEmailAccount(payload);

      // dispatch(getEmailAccountList());
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error?.response?.data?.message;
      dispatch(emailAccountActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
