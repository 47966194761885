import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import defaultFlag from "../../assets/images/flag-outline.svg";

// import ImgSubtask from "../../assets/images/Subtask.svg";
import ImgEstimate from "../../assets/images/Estimate.svg";

import { FlagIcon } from "../../pages/Dashboard/flagSvgIcon";

const tasksIcon = ({ row }) => (
  <div className={`task-row-status ${row?.ticketStatusColor}-task`} />
);

const taskCell = ({ row }) => (
  <>
    <div className="tbl-cont short-text ">{row?.title || "-"}</div>
    <div className="tbl-cont-light d-flex align-items-center">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={row?.projectName}>Project Name</Tooltip>}
      >
        <span className="short-text">{row?.projectName || "-"}</span>
      </OverlayTrigger>
      {/* <div className="subtasks-item d-flex align-items-center">
        <div className="icon-small d-flex">
          <img src={ImgSubtask} alt="Subtask" />
          <span>{row?.subtaskCount}</span>
        </div>
      </div> */}
    </div>
  </>
);

const priorityCell = ({ row }) => (
  <div className="tbl-cont flag" data-tooltip-id={row?._id}>
    {row?.priorityColor?.colorCode ? (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id={row?.priorityColor?.colorCode}>
            {row?.priorityColor?.name}
          </Tooltip>
        }
      >
        <div className="flag-icon">
          <FlagIcon fillColor={row?.priorityColor?.colorCode} />
        </div>
      </OverlayTrigger>
    ) : (
      <div className="flag-icon">
        <img src={defaultFlag} alt="" />
      </div>
    )}
  </div>
);

const statusCell = ({ row }) => (
  <div className="status-info">
    <div className={`status ${row?.ticketStatusColor}`}>
      {row?.ticketStatusName || "-"}
    </div>
  </div>
);

const timeEstimateCell = ({ row }) => (
  <div className="d-flex justify-content-center">
    {row?.estimate && (
      <div className="icon">
        <img src={ImgEstimate} alt="Estimate" />
      </div>
    )}
    &nbsp;
    <div className="conten">{`${row?.estimate || "-"}h`}</div>
  </div>
);

taskCell.propTypes = {
  row: PropTypes.shape({
    status: PropTypes.string,
    taskName: PropTypes.string,
    subtask: PropTypes.string,
    subtaskCount: PropTypes.string,
  }).isRequired,
};

priorityCell.propTypes = {
  row: PropTypes.shape({
    flagIcon: PropTypes.string,
  }).isRequired,
};
statusCell.propTypes = {
  row: PropTypes.shape({
    status: PropTypes.string,
    statusText: PropTypes.string,
  }).isRequired,
};
timeEstimateCell.propTypes = {
  row: PropTypes.shape({
    estimate: PropTypes.string,
  }).isRequired,
};
tasksIcon.propTypes = {
  row: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};
export { taskCell, priorityCell, statusCell, timeEstimateCell, tasksIcon };
