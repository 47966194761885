// const validTableFields = (value) => {
//   const fields = {
//     "Date Created": "createdAt",
//     "Created By": "postedBy",
//   };
//   const properFieldName = fields[value] ?? value;
//   return properFieldName;
// };

// export const filterQuery = (filters) => {

//   const completeFilters = filters.filter((eachFilter) => {
//     if (
//       ["is set", "is not set"].includes(eachFilter.condition?.value) &&
//       eachFilter.where
//     )
//       return true;
//     if (eachFilter.where && eachFilter.condition && eachFilter.option)
//       return true;
//     return false;
//   });

//   const gate =
//     completeFilters.length && completeFilters[0].gate === "AND"
//       ? "$and"
//       : "$or";

//   const queryCondition = {
//     [gate]: [],
//   };
//   completeFilters?.forEach((eachFilter) => {
//     let condition = {};
//     const field = validTableFields(eachFilter.where.label);
//     console.log("FIELDS", field);
//     condition = { ...condition, fieldName: field };
//     if (eachFilter.condition.value === "is") {
//       condition = {
//         ...condition,
//         value: Array.isArray(eachFilter.option)
//           ? !!eachFilter.option.length &&
//             eachFilter.option.map(({ value }) => value)
//           : eachFilter.option.value ?? eachFilter.option,
//         query: Array.isArray(eachFilter.option) ? "$in" : "$eq",
//         isDate: ["createdAt"].includes(field) ? "true" : undefined,
//       };
//     } else if (eachFilter.condition.value === "is not") {
//       condition = {
//         ...condition,
//         value: Array.isArray(eachFilter.option)
//           ? eachFilter.option.map(({ value }) => value)
//           : eachFilter.option.value ?? eachFilter.option,
//         query: Array.isArray(eachFilter.option) ? "$nin" : "$ne",
//         isDate: ["createdAt"].includes(field) ? "true" : undefined,
//       };
//     } else if (eachFilter.condition.value === "is not set") {
//       condition = { ...condition, value: "true", query: "$eq" };
//     } else if (eachFilter.condition.value === "is set") {
//       condition = { ...condition, value: "false", query: "$ne" };
//     }
//     if (condition.value) queryCondition[gate].push(condition);
//     return eachFilter;
//   });
//   return queryCondition[gate].length ? queryCondition : null;
// };

const validTableFields = (value) => {
  const fields = {
    "Date Created": "createdAt",
    "Created By": "postedBy",
    "No. Of Leaves": "leavesTakenInMonth",
    // "Total Active Projects": "totalActiveProjects",
  };
  const properFieldName = fields[value] ?? value;
  return properFieldName;
};

export const filterQuery = (filters) => {
  const completeFilters = filters.filter((eachFilter) => {
    if (
      ["less than", "equal to", "greater than"].includes(
        eachFilter.condition?.value,
      ) &&
      eachFilter.where
    )
      return true;
    if (eachFilter.where && eachFilter.condition && eachFilter.option)
      return true;
    return false;
  });

  const gate =
    completeFilters.length && completeFilters[0].gate === "AND"
      ? "$and"
      : "$or";

  const queryCondition = {
    [gate]: [],
  };
  completeFilters?.forEach((eachFilter) => {
    let condition = {};
    const field = validTableFields(eachFilter.where.label);
    condition = { ...condition, fieldName: field };
    if (eachFilter.condition.value === "is") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: Array.isArray(eachFilter.option) ? "$in" : "$eq",
        isDate: ["createdAt"].includes(field) ? "true" : undefined,
      };
    } else if (eachFilter.condition.value === "is not") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: Array.isArray(eachFilter.option) ? "$nin" : "$ne",
        isDate: ["createdAt"].includes(field) ? "true" : undefined,
      };
    } else if (eachFilter.condition.value === "is not set") {
      condition = { ...condition, value: "true", query: "$eq" };
    } else if (eachFilter.condition.value === "is set") {
      condition = { ...condition, value: "false", query: "$ne" };
    } else if (eachFilter.condition.value === "less than") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: "$lt",
      };
    } else if (eachFilter.condition.value === "greater than") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: "$gt",
      };
    } else if (eachFilter.condition.value === "equal to") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: "$eq",
      };
    }
    if (condition.value) queryCondition[gate].push(condition);
    return eachFilter;
  });
  return queryCondition[gate].length ? queryCondition : null;
};
