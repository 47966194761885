import { createSlice } from "@reduxjs/toolkit";

import { createReducers } from "./reducers";

function createInitialState() {
  return {
    value: null,
  };
}

// create slice

export const name = "alert";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports

export const alertActions = { ...slice.actions };
export const alertReducer = slice.reducer;

// implementation
