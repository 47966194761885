import { createSlice } from "@reduxjs/toolkit";

export const name = "team";

const createReducers = () => {
  const setDepartmentData = (state, { payload }) => {
    state.deptData = payload.data;
    state.successMessage = payload.successMessage;
  };
  const setTeamData = (state, { payload }) => {
    if (payload?.params?.forReport) {
      state.teamDataforExport = payload.allocatedUsers;
      state.successMessage = payload.successMessage;
      return;
    }
    state.teamData = payload.allocatedUsers;
    state.successMessage = payload.successMessage;
  };
  const setRoleData = (state, { payload }) => {
    state.roleData = payload.Roles;
    state.successMessage = payload.successMessage;
  };
  const setMemberData = (state, { payload }) => {
    state.memberData = payload.Members;
    state.successMessage = payload.successMessage;
  };

  const editTeamData = (state, { payload }) => {
    state.editTeamData = payload.data;
    state.successMessage = payload.successMessage;
  };
  const editTeamHour = (state, { payload }) => {
    state.editTeamHour = payload.data;
    state.teamData = {
      ...state.teamData,
      projectAggregation: state.teamData?.projectAggregation.map((e) => {
        if (e.userId === payload.data.user) {
          return { ...e, allocationHours: payload.data.allocationHours };
        }
        return e;
      }),
    };
    state.successMessage = payload.successMessage;
  };
  const setTeamErrorMessage = (state, { payload }) => {
    state.errorMessage = payload;
  };
  const clearExportData = (state) => {
    state.teamDataforExport = null;
  };
  return {
    setTeamData,
    editTeamData,
    editTeamHour,
    setTeamErrorMessage,
    setDepartmentData,
    setRoleData,
    setMemberData,
    clearExportData,
  };
};

function createInitialState() {
  return {
    editTeamData: null,
    editTeamHour: null,
    activityDataforExport: null,
    teamData: [],
    deptData: [],
    roleData: [],
    memberData: [],
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const teamActions = slice.actions;
export const teamReducer = slice.reducer;
