import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getTicketPriority } from "./getTicketPriority";
import { getTicketStatus } from "./getTicketStatus";
// import { getAllUsers } from "./getUsers";

export const commondatas = createAsyncThunk(
  "commonactions",
  function (payload, { dispatch }) {
    try {
      dispatch(getTicketStatus());
      dispatch(getTicketPriority());
      // dispatch(getAllUsers());
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
