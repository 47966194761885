/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setTicketAllocationById } from "../index";
import { getTicketAllocationDataById } from "../../../../services/ticketAllocationService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getTicketAllocationsById = createAsyncThunk(
  `get/ticketAllocationsById`,
  async ({ params, listId }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await getTicketAllocationDataById(params, listId);
      dispatch(setTicketAllocationById(...data));
      return data[0];
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
