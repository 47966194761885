import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import filterService from "../../../../services/filterService";
import { filterDropdownAction } from "..";
import { alertActions } from "../../alert/index";

export const getFilterById = createAsyncThunk(
  "list-filterById",
  async (filterQuery, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const { data } = await filterService.getFilterById(filterQuery);
      dispatch(filterDropdownAction.setFilterById(data.filterDataById));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
