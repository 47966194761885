import { TimeEstimate } from "./TimesheetSvg";

/* eslint-disable react/prop-types */
const TicketsAllocatedModal = ({ ticketListView, data }) => {
  // function to show hours color className
  function getHoursColorClass(totalAllocationHours) {
    if (totalAllocationHours === "COMPLETE") {
      return "green";
    }
    if (totalAllocationHours === "READY FOR QA") {
      return "orange";
    }
    if (totalAllocationHours === "TODO") {
      return "";
    }
    if (totalAllocationHours === "IN PROGRESS") {
      return "blue";
    }
  }
  // const allocationLength = data?.daysInMonth.map(
  //   (len) => len?.data?.allocatedListOrSprint,
  // );
  // console.log("fff", allocationLength?.length);
  return (
    <div
      className={`dropdown-menu dropeffect allocation-project-list ticket-list custom-scroll ${
        ticketListView !== null ? "show" : ""
      }`}
      aria-labelledby="dropdownMenuButton2"
    >
      <div className="allocation-count">{data?.length} Tickets Allocated</div>

      {data?.map((list) => (
        <>
          <div className="divitions">
            <hr className="mt-0 mb-0" />
          </div>
          <div
            className="dropdown-item notes-row"
            // id="dropdownMenuButton2"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <div className="blks-list">
              <span className="flex-grow-1 ps-0">
                <span className="highlight">{list?.ticketId || "-"}</span> /{" "}
                {list?.allocation || "-"}
              </span>
              <div className="updates">
                <div className="btn-notes hours-otr text-center">
                  <TimeEstimate />
                  <span>{`${list?.allocatedHours} h ` || "-"}</span>
                </div>
              </div>
            </div>
            <div
              className={`blks-list pro-desc ${getHoursColorClass(
                list?.status,
              )}`}
            >
              {list?.ticketName || "-"}
            </div>
            <div className="blks-list">
              <div className="pro-icon">
                <img src="img/pro-propbase.svg" alt="" />
              </div>
              <span>{list?.projectName || "-"}</span>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default TicketsAllocatedModal;
