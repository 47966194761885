import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import searchNormal from "../../../../assets/images/search-normal.svg";
import noAllocationImage from "../../../../assets/images/no_allocation.svg";
import { useThunk } from "../../../../hooks/useThunk";
// import { updateAllocationLists } from "../../../../store";
import { getAllocationProjectsLists } from "../../../../store/slices/allocationList/thunks/getAllocationProjects";
import CommonSkeleton from "../../../../components/SkeletonLoader/CommonSkeleton";
import { getNameAndUniqId } from "../../../../components/common/iconDefaultName";
import { getFirstLetters } from "../../../../utils/utils";

const NoAllocationModal = ({
  show,
  onShowProjectInfo,
  descriptions,
  projectWithId,
  noAllocationIndex,
  isNoAllocation,
  allocationprojectId,
  setIsNoAllocationModalOpen,
  updateLists,
  setUpdatelistId,
  params,
}) => {
  console.log("myparams", params);
  // project info modal
  const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  // image error handling
  const [imgError, setimgError] = useState(false);

  const handleOnProjectError = () => {
    setimgError(true);
  };

  // allocation id map
  // const allocationRoleIdFetch = projectWithId?.projectAllocation?.map(
  //   ({ projectId }) => projectId,
  // );

  // const allocationHourdFetch = projectWithId?.projectAllocation?.map(
  //   ({ allocationHours }) => allocationHours,
  // );

  const { authData } = useSelector((state) => state.auth);

  // upade functions for list

  // const [updateLists, updateListLoading] = useThunk(updateAllocationLists);

  const onSubmitUpdateLists = (updatedData) => {
    setUpdatelistId(projectWithId?._id);
    updateLists({ updatedData, params });

    setIsNoAllocationModalOpen(null);
  };

  const [getProjects, isLoadingProjects] = useThunk(getAllocationProjectsLists);
  useEffect(() => {
    getProjects();
  }, []);

  const { allocatioProjects } = useSelector((state) => state.allocation);

  const handleProjectInfoButtonClick = (val, indexValue) => {
    // Assuming isProjectInfoModalOpen is a state variable
    if (isProjectInfoModalOpen === val?._id) {
      // No need to set state here, just toggle the modal
      // setIsProjectInfoModalOpen(prevIsProjectInfoModalOpen => !prevIsProjectInfoModalOpen);

      // Perform the desired action here, such as opening or closing the modal
      onShowProjectInfo(!isProjectInfoModalOpen);
      // description(val?.description);
    } else {
      // If the id is different, set the new modal state
      setIsProjectInfoModalOpen(val?._id, noAllocationIndex);
      descriptions(val, indexValue);

      // Perform the desired action here, such as opening the modal
      onShowProjectInfo(val?._id, noAllocationIndex);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the allocation projects based on the searchQuery
  const filteredProjects = allocatioProjects?.filter((project) =>
    project?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()),
  );

  return (
    <div
      className={`dropdown-menu dropeffect pt-2 pb-2 ${show ? "show" : ""}`}
      aria-labelledby="dropdownMenuButton1"
    >
      <div className="dropdown-item no-hover searcharea d-flex align-items-center no-bg pb-1 ">
        <div className="search-icon">
          <img src={searchNormal} alt="Icon" />
        </div>
        <input
          type="text"
          className="form-control no-hover"
          placeholder="Search Projects"
          value={searchQuery} // Bind the value of the input to the searchQuery state
          onChange={handleSearchInputChange} //
        />
      </div>
      <div className="divitions pb-1 pt-1">
        <hr className="mt-0 mb-0" />
      </div>
      {!isNoAllocation && (
        <>
          <button
            type="button"
            className="dropdown-item d-flex dropdown-toggle no-allocations"
            onClick={() =>
              onSubmitUpdateLists({
                projectId: allocationprojectId,
                userId: projectWithId?._id,
                // deletedProjectId: projectWithId?.projectId,
                // allocationRoleId: allocationRoleIdFetch[0],
                updatedBy: authData?._id,
                allocationHour: -1,
              })
            }
            // id="dropdownMenuButton2"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <div className="pro-icon">
              <img className="sm-icon" src={noAllocationImage} alt="" />
            </div>{" "}
            <span className="noAllocation">No Allocation</span>
          </button>
          <div className="divitions pb-1 pt-1">
            <hr className="mt-0 mb-0" />
          </div>
        </>
      )}
      <div className="custom-scroll drop-content-scroll">
        {isLoadingProjects
          ? Array?.from({ length: filteredProjects?.length || 0 })?.map(
              (_, indexs) => (
                <div className="custom-scroll drop-content-scroll" key={indexs}>
                  {/* <div className="divitions pb-1 pt-1">
                  <hr className="mt-0 mb-0" />
                </div> */}
                  <button
                    type="button"
                    className="dropdown-item d-flex dropdown-toggle"

                    // id="dropdownMenuButton2"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                  >
                    <div className="pro-icon">
                      <CommonSkeleton />
                    </div>{" "}
                    <span>
                      <CommonSkeleton />
                    </span>
                  </button>
                </div>
              ),
            )
          : filteredProjects?.map((e, index) => (
              <button
                type="button"
                className="dropdown-item d-flex dropdown-toggle"
                onClick={() =>
                  onSubmitUpdateLists({
                    projectId: e?._id,
                    userId: projectWithId?._id,
                    deletedProjectId: !isNoAllocation
                      ? allocationprojectId
                      : undefined,

                    // allocationRoleId: allocationRoleIdFetch[0],
                    updatedBy: authData?._id,
                    allocationHour: 1,
                  })
                }
                // id="dropdownMenuButton2"
                // data-bs-toggle="dropdown"
                // aria-expanded="false"
              >
                {e?.logo && !imgError ? (
                  <div className="pro-icon">
                    {console.log(e)}
                    <img
                      src={`https://erptest.spericorn.com/repo/uploads/${e?.logo}`}
                      alt={e?.image}
                      onError={handleOnProjectError}
                    />
                  </div>
                ) : (
                  <div
                    className={`avathar-sm avathar-0${
                      (getNameAndUniqId(e?._id).uniqId % 4) + 1
                    }`}
                  >
                    {getFirstLetters(e?.name)}
                  </div>
                )}
                <span>{e?.name}</span>
                <div
                  className="info"
                  onClick={(event) => {
                    handleProjectInfoButtonClick(e, index);
                    event.stopPropagation();
                  }}
                >
                  Info
                </div>
              </button>
            ))}
      </div>
    </div>
  );
};

NoAllocationModal.propTypes = {
  show: PropTypes.string,
  params: PropTypes.string,
  onShowProjectInfo: PropTypes.func,
  noAllocationIndex: PropTypes.number,
  isNoAllocation: PropTypes.bool,
  allocationprojectId: PropTypes.string,
  setIsNoAllocationModalOpen: PropTypes.func,
  descriptions: PropTypes.func,
  updateLists: PropTypes.func,
  setUpdatelistId: PropTypes.func,
  projectWithId: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default NoAllocationModal;
