import { createAsyncThunk } from "@reduxjs/toolkit";

import { alertActions } from "../../alert/index";
import { getEmployeeListService } from "../../../../services/employeeService";
import { employeeList } from "../index";

export const getEmployeeList = createAsyncThunk(
  "employee/List",
  async function (params, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data } = await getEmployeeListService(params);
      // dispatch(alertActions.success(data.message));
      dispatch(employeeList(data));
    } catch (error) {
      // const errorMessage = _.isString(error)
      //   ? error
      //   : error.response.data.message;
      // dispatch(cronjobActions.setErrorMesage(errorMessage));
      // dispatch(alertActions.error(errorMessage));
    }
  },
);
