import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { notesActions } from "..";
import notesServices from "../../../../services/notesServices";
import { alertActions } from "../../alert";
import { getNotes } from "./getNotes";

export const addNotes = createAsyncThunk(
  "notes",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await notesServices.addNotes(payload);

      dispatch(
        getNotes({
          page: 1,
          limit: 50,
          // search: filter?.search,
        }),
      );
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;

      dispatch(alertActions.error(error.response.data.message));
      dispatch(notesActions.setErrorMesage(errorMessage));
    }
  },
);
