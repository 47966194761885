/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setAllocationKey,
  setTicketAllocationData,
  setTicketAllocationParams,
} from "../index";
import { getTicketAllocationData } from "../../../../services/ticketAllocationService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getTicketAllocations = createAsyncThunk(
  `get/ticketAllocations`,
  async ({ setEditLoader, params, key, reset }, { dispatch }) => {
    try {
      if (reset) {
        dispatch(setTicketAllocationParams({ page: 1, limit: 20 }));
        dispatch(setTicketAllocationData({ data: [], reset }));
      }
      const {
        data: {
          ticketAllocations: data,
          message: successMessage,
          totalCount,
          TableHeading,
        },
      } = await getTicketAllocationData(params);

      if (setEditLoader) {
        setEditLoader("");
      }

      dispatch(
        setTicketAllocationData({
          data,
          totalCount,
          TableHeading,
          successMessage,
          reset: reset || params?.page === 1 ? true : false,
        }),
      );
      dispatch(setAllocationKey(key));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(ticketActions.setTicketErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
