import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ticketService from "../../../../services/ticketService";
import { alertActions } from "../../alert";
import { setTicketAllocationDataById } from "..";

export const patchTicketAttachment = createAsyncThunk(
  "patch/ticketAttchment",
  async ({ id, formData }, { dispatch }) => {
    try {
      console.log(formData);

      const {
        data: { data, message: successMessage },
      } = await ticketService.patchTicketAtmData(id, formData);
      dispatch(alertActions.success(successMessage));
      dispatch(setTicketAllocationDataById(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
