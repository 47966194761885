import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { notesActions } from "..";
import { alertActions } from "../../alert";
import notesServices from "../../../../services/notesServices";

export const getNotes = createAsyncThunk(
  "list-notes",
  async (params, { dispatch }) => {
    // dispatch(alertActions.clear());

    try {
      const { data } = await notesServices.getNotes(params);

      dispatch(
        notesActions.setNotesList({
          data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
