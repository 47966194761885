/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useLocation,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import PropTypes from "prop-types";
import { Accordion, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import greenArrow from "../../assets/images/arrow-green-circle-down.svg";
import Task from "./Task";
import { TableConfig } from "../DropDowns/TableConfig";
import Button from "../Button/index";
import { SortingIco } from "../../structure/SideNav/svgIcons";
import arrowRightIcon from "../../assets/images/arrow_right_green.svg";
import { getJokes } from "../../store";
import { useThunk } from "../../hooks/useThunk";
import TableHeaderGroupBySwither from "./TableHeaderGroupBySwither";
import UserNoData from "../../pages/UserProfile/UserNoData";
import CommonSkeleton from "../SkeletonLoader/CommonSkeleton";

const Table = ({
  CustomToggle,
  configData,
  onSortChange,
  TaskData,
  taskAdd,
  taskEdit,
  onAccordionClick,
  ActionButtons,
  handleSelectClick,
  ModalShow,
  // handleDragOver,
  // handleDrop,
  // handleDragStart,
  isLoading,
  patchFunction,
  groupBy,
  AddTask,
  setSelectedRows,
  selectedRows,
  EditLoader,
  taskView,
  showSubTasks,
  ExpandAll,
  nextTable,
  TicketCount,
  filter,
  dataCount,
  checkComplete,
  completeStatus,
}) => {
  const [state, setState] = useState({
    accordionToggle: [],
  });
  const { listId } = useParams();

  const param = useLocation();
  const { userProfile } = useSelector((state) => state.userProfile);
  const { accordionToggle } = state;
  if (groupBy === "status" || groupBy === "") groupBy = "ticketStatus";
  const setAccordionOpen = (id) => {
    nextTable(id, accordionToggle?.includes(id));
    if (accordionToggle?.includes(id)) {
      const updatedArray = accordionToggle?.filter((element) => element !== id);
      setState({ ...state, accordionToggle: updatedArray });
    } else {
      setState({
        ...state,
        accordionToggle: [...accordionToggle, id],
      });
    }
  };
  const [selectedTicket, setSelectedTicket, setShowModal, ticket, isTicket] =
    useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [getJokesData] = useThunk(getJokes);

  useEffect(() => {
    if (selectedTicket) {
      if (ticket) {
        setSearchParams({ Task: selectedTicket });
      } else {
        setSearchParams({ TicketAllocationId: selectedTicket });
      }
      setShowModal(true);
    }
  }, [selectedTicket]);

  useEffect(() => {
    setState({ ...state, accordionToggle: [] });
  }, [
    listId,
    filter.search,
    filter.me,
    filter.assignee,
    filter.groupBy,
    filter.show.includes("SeperateTasks"),
  ]);

  useEffect(() => {
    const queryString = param.search;
    if (queryString) {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    if (TaskData?.length <= 0) getJokesData();
  }, [TaskData?.length]);

  const checkData = TaskData?.filter((e) => e?.data?.length >= 1);

  return TaskData?.length <= 0 && !isLoading ? (
    <UserNoData />
  ) : isLoading && TaskData?.length <= 0 ? (
    <div style={{ marginLeft: "2%", marginTop: "1%" }}>
      {Array?.from({ length: 100 }).map(() => (
        <CommonSkeleton height={35} />
      ))}
    </div>
  ) : checkData?.length >= 1 ? (
    TaskData?.filter(({ data }) => data?.length >= 1)?.map(
      ({ groupById, data, statusTitle }, index) => {
        if (!checkComplete) {
          if (groupById === completeStatus?._id) return;
        }
        return (
          <Card.Body
          // draggable onDragOver={handleDragOver} onDrop={handleDrop}
          >
            <div className="table-cover-main">
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <div className="group-list-cover d-flex pb-0">
                      <div className="task-list-header__item_main sticky-element">
                        <CustomToggle
                          eventKey="0"
                          ButtonclassName="arrow-controler "
                          onAccordioOpenClick={() => setAccordionOpen(index)}
                        >
                          {accordionToggle.includes(index) &&
                          data?.length > 0 ? (
                            <img
                              className="img-down"
                              src={arrowRightIcon}
                              alt=""
                            />
                          ) : (
                            <img className="img-down" src={greenArrow} alt="" />
                          )}
                        </CustomToggle>
                        <TableHeaderGroupBySwither
                          groupById={groupById}
                          groupBy={groupBy}
                        />
                        <button type="button" className="tab_button">
                          {TicketCount?.[index]?.count} TASK
                        </button>
                      </div>
                      <div className="list-header__heading">
                        {configData?.map(
                          ({ name, isVisible, icon, selector }) =>
                            isVisible && name !== "title" ? (
                              name?.length >= 11 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={name}>{name}</Tooltip>}
                                >
                                  <div key={name} className="field__th">
                                    <Button type="button" className="title-th">
                                      {name}
                                    </Button>
                                    {icon ? (
                                      <span
                                        className="sort-block"
                                        onClick={() => onSortChange(selector)}
                                      >
                                        <SortingIco />
                                      </span>
                                    ) : null}
                                  </div>
                                </OverlayTrigger>
                              ) : (
                                <div key={name} className="field__th">
                                  <Button type="button" className="title-th">
                                    {name}
                                  </Button>
                                  {icon ? (
                                    <span
                                      className="sort-block"
                                      onClick={() => onSortChange(selector)}
                                    >
                                      <SortingIco />
                                    </span>
                                  ) : null}
                                </div>
                              )
                            ) : null,
                        )}
                        <TableConfig
                          dropDownVisibility
                          handleSelectClick={handleSelectClick}
                          configData={configData}
                          status={groupById}
                        />
                      </div>
                    </div>
                  </Card.Header>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Task
                        ModalShow={ModalShow}
                        ActionButtons={ActionButtons}
                        onAccordionClick={onAccordionClick}
                        taskEdit={taskEdit}
                        taskAdd={taskAdd}
                        CustomToggle={CustomToggle}
                        configData={configData}
                        // handleDragStart={handleDragStart}
                        // handleDragOver={handleDragOver}
                        // handleDrop={handleDrop}
                        isLoading={isLoading}
                        TicketData={data}
                        groupById={groupById}
                        patchFunction={patchFunction}
                        setSelectedTicket={setSelectedTicket}
                        userProfile={userProfile}
                        isTicket={isTicket}
                        groupBy={groupBy}
                        AddTask={AddTask}
                        statusTitle={statusTitle}
                        setSelectedRows={setSelectedRows}
                        selectedRows={selectedRows}
                        EditLoader={EditLoader}
                        taskView={taskView}
                        showSubTasks={showSubTasks}
                        ExpandAll={ExpandAll}
                      />
                    </Card.Body>
                  </Accordion.Collapse>

                  {!accordionToggle?.includes(index) &&
                    data?.length < TicketCount?.[index]?.count && (
                      <div className="main-container-row w-100">
                        {Array.from({
                          length:
                            TaskData?.length > 0 &&
                            TicketCount?.length !== TaskData?.length
                              ? 0
                              : 5,
                        }).map((_, index) => (
                          <CommonSkeleton key={index} height={35} />
                        ))}
                      </div>
                    )}
                </Card>
              </Accordion>
            </div>
          </Card.Body>
        );
      },
    )
  ) : (
    <UserNoData />
  );
};

Table.propTypes = {
  CustomToggle: PropTypes.elementType.isRequired,
  configData: PropTypes.isRequired,
  onSortChange: PropTypes.func.isRequired,
  TaskData: PropTypes.isRequired,
  taskAdd: PropTypes.bool.isRequired,
  taskEdit: PropTypes.bool.isRequired,
  onAccordionClick: PropTypes.func.isRequired,
  ActionButtons: PropTypes.elementType.isRequired,
  handleSelectClick: PropTypes.func.isRequired,
  ModalShow: PropTypes.func.isRequired,
};
export default Table;
