import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import playBtn from "../../assets/images/play-grn.svg";
import pauseBtn from "../../assets/images/pause_green.svg";
import { useThunk } from "../../hooks/useThunk";
import { patchTicket, patchTicketAllocation } from "../../store";

const Timer = ({ task, ticket, newTab }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [patchTicketAllocationAction] = useThunk(patchTicketAllocation);
  const [updateTicketThunk] = useThunk(patchTicket);

  const handleStart = () => {
    setIsRunning(true);
    const newTimeEntry = {
      startTime: new Date(),
      pauseTime: null,
    };
    if (!ticket) {
      patchTicketAllocationAction({
        id: task?._id,
        patchData: {
          timeTracked: [...(task?.timeTracked || []), newTimeEntry],
        },
      });
    } else {
      updateTicketThunk({
        id: task?._id,
        patchData: {
          timeTracked: [...(task?.timeTracked || []), newTimeEntry],
        },
      });
    }
  };

  const handleEnd = () => {
    setIsRunning(false);
    const TicketData = {
      timeTracked: (task?.timeTracked || []).map((timeEntry) => {
        if (!timeEntry.pauseTime) {
          return {
            ...timeEntry,
            pauseTime: new Date(),
          };
        }

        return timeEntry;
      }),
    };
    if (!ticket) {
      patchTicketAllocationAction({
        id: task?._id,
        patchData: TicketData,
      });
    } else {
      updateTicketThunk({
        id: task?._id,
        patchData: TicketData,
      });
    }
  };
  useEffect(() => {
    const shouldStartRunning =
      task?.timeTracked &&
      task?.timeTracked.length > 0 &&
      !task?.timeTracked[task && task.timeTracked.length - 1].pauseTime;

    setIsRunning(shouldStartRunning);
  }, [task]);

  useEffect(() => {
    if (task?.timeTracked) {
      const totalMilliseconds = task?.timeTracked.reduce((total, entry) => {
        if (entry.startTime) {
          const startTime = new Date(entry.startTime);
          let endTime;

          if (entry.pauseTime) {
            endTime = new Date(entry.pauseTime);
          } else {
            endTime = new Date();
          }

          const timeDifference = endTime - startTime;
          return total + timeDifference;
        }
        return total;
      }, 0);

      setTime(totalMilliseconds);
    } else {
      setTime(0);
    }
  }, [task]);
  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRunning]);
  return (
    <div className="timer-block timer-b">
      <div className="t-label">TIME TRACKED</div>
      <div className="t-det d-flex align-items-center">
        <div className="icon-block">
          {isRunning ? (
            <img
              src={pauseBtn}
              alt="Pause"
              onClick={!newTab ? handleEnd : undefined}
            />
          ) : (
            <img
              src={playBtn}
              alt="Start"
              onClick={!newTab ? handleStart : undefined}
            />
          )}
        </div>
        <span className="digits">
          {`0${Math.floor((time / (1000 * 60 * 60)) % 24)}`.slice(-2)}:
        </span>
        <span className="digits">
          {`0${Math.floor(time / (1000 * 60)) % 60}`.slice(-2)}:
        </span>
        <span className="digits mili-sec">
          {`0${Math.floor(time / 1000) % 60}`.slice(-2)}
        </span>
      </div>
    </div>
  );
};

Timer.propTypes = {
  task: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    timeTracked: PropTypes.node,
  }),
  ticket: PropTypes.bool,
  newTab: PropTypes.bool,
};

export default Timer;
