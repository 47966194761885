import { Outlet } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import SideNav from "../SideNav";
import "../../design/custom/structure/pageContents.scss";
import PrimaryNavbar from "../PrimaryNavbar/PrimaryNavbar";

import { FilterProvider } from "../../context/useFilter";
// import BulkActions from "../../components/BulkActions/BulkActions";
import UserProfile from "../../pages/UserProfile";
import TodoModal from "../../pages/widgets/todo/todoModal";
import AddMeetingSlider from "../../pages/widgets/meetingSchedule/AddMeetingSlider";
// import BulkActions from "../../components/BulkActions/BulkActions";

function Layout() {
  const location = useLocation();
  const { showUserprofile } = useSelector(({ userProfile }) => userProfile);

  const renderHeader = () => {
    if (location.pathname === "/dashboard") {
      return <Header />;
    }
    return <PrimaryNavbar />;
  };

  return (
    <>
      {/* <BulkActions selectedTasks={[{}]} /> */}
      <AddMeetingSlider />
      <TodoModal />
      <FilterProvider>
        <UserProfile />
        <div className={`profile-overlay ${showUserprofile ? "show" : ""}`} />
        <div className="page-wrap">
          <SideNav />
          <div className="page-container">
            {renderHeader()}
            <Outlet />
          </div>
          <div className="overlay" />
        </div>
      </FilterProvider>
    </>
  );
}

export default Layout;
