import { useState } from "react";
import PropTypes from "prop-types";
import { useThunk } from "../../../../../hooks/useThunk";
import { Deletemodal } from "../../../../../components/DropDowns/DeleteModal";
import { BranchIco, DeleteIcon } from "./svgIcons";
import { deleteBranches } from "../../../../../store/slices/gitIntegration/thunks/deletBranches";
import CommonSkeleton from "../../../../../components/SkeletonLoader/CommonSkeleton";
import UserNoData from "../../../../UserProfile/UserNoData";
import permissionConstants from "../../../../../utils/permissionUtils/permissionConstants";

// Branche list component
export const GitBranches = ({
  projectId,
  commonSl,
  tablesearchValue,
  hasPermission,
  ticket,
  getAllBranchsData,
  isLoadingBranches,
}) => {
  const [deletebrch, isdeleting] = useThunk(deleteBranches);

  // state variable
  const [deleteId, setDeleteId] = useState("");
  const [state, setstate] = useState({
    deleteModal: { openclose: false, type: "" },
  });
  const { deleteModal } = state;

  // delete branch details

  const closeDeleteModal = () => {
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: false,
      },
    });
  };
  const handleDeleteModalClick = (datas) => {
    setDeleteId(datas);
    const type = `${datas} Branch`;
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: true,
        type,
      },
    });
  };

  const handleDelete = () => {
    const data = {
      branchName: deleteId,
      repoName: commonSl?.value,
      owner: commonSl?.owner,
      projectId,
      ticket,
    };
    deletebrch(data);
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: false,
      },
    });
  };

  // Filter branches based on searchQuery
  const filteredBranches = tablesearchValue
    ? getAllBranchsData.filter((branch) =>
        branch.name.toLowerCase().includes(tablesearchValue.toLowerCase()),
      )
    : getAllBranchsData;

  // set username

  function getShortName(user) {
    const names = user?.split(" ");
    const shortName = names
      ?.slice(0, 2)
      .map((name) => name[0].toUpperCase())
      .join("");
    return shortName;
  }

  // date formatting
  function formatDate(originalDate) {
    const date = new Date(originalDate);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  // Sort branches by date in descending order
  const sortedBranches = filteredBranches.slice().sort((a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateB - dateA;
  });

  return (
    <div className="single-bg-wrp">
      <div className="integration-table-wrp">
        <div className="table-responsive custom-scroll">
          {commonSl !== "" && sortedBranches && sortedBranches.length > 0 ? (
            <table className="table custom-table docs-table">
              <tbody>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">date</th>
                  <th scope="col">By</th>
                  <th scope="col">File</th>
                  {hasPermission(
                    permissionConstants.permissions
                      .TASK_DETAILS_MODAL_GITHUB_BRANCHES_DELETE,
                  ) && <th scope="col">Actions</th>}
                </tr>
                {isLoadingBranches
                  ? Array?.from({
                      length: 5,
                    })?.map((_, index) => (
                      <td key={index}>
                        {Array?.from({
                          length: sortedBranches?.length,
                        })?.map((_, index) => (
                          <div className="td-cnt" key={index}>
                            <div className="txt">
                              <CommonSkeleton width={100} />
                            </div>
                          </div>
                        ))}
                      </td>
                    ))
                  : sortedBranches?.map((item, index) =>
                      isdeleting && deleteId === item.name ? (
                        Array?.from({
                          length: 5,
                        })?.map((_, index) => (
                          <td key={index}>
                            <div className="td-cnt" key={index}>
                              <div className="txt">
                                <CommonSkeleton width={100} />
                              </div>
                            </div>
                          </td>
                        ))
                      ) : (
                        <tr key={index}>
                          <td>
                            <ul className="folder-structure">
                              <li>
                                <span className="icons">
                                  {hasPermission(
                                    permissionConstants.permissions
                                      .TASK_DETAILS_MODAL_GITHUB_BRANCHES_VIEW,
                                  ) ? (
                                    <a
                                      href={`https://github.com/${commonSl?.owner}/${commonSl?.value}/tree/${item.name}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <BranchIco />
                                    </a>
                                  ) : (
                                    <BranchIco />
                                  )}
                                </span>
                                <span>{item?.name}</span>
                              </li>
                            </ul>
                          </td>
                          <td>{formatDate(item?.createdAt)}</td>
                          <td>
                            <div className="usr-blk">
                              <span className="usr">
                                {item?.photo ? (
                                  <img
                                    className="name-ltr"
                                    src={item?.photo}
                                    alt="profile pic"
                                  />
                                ) : (
                                  getShortName(item?.createdBy)
                                )}
                              </span>
                              {item?.createdBy}
                            </div>
                          </td>
                          <td>{item?.number_of_files} Files</td>
                          <td>
                            {hasPermission(
                              permissionConstants.permissions
                                .TASK_DETAILS_MODAL_GITHUB_BRANCHES_DELETE,
                            ) && (
                              <button
                                className="btn btn-del-status"
                                type="button"
                                onClick={() =>
                                  handleDeleteModalClick(item.name)
                                }
                              >
                                <DeleteIcon />
                              </button>
                            )}
                          </td>
                        </tr>
                      ),
                    )}
              </tbody>
            </table>
          ) : (
            <UserNoData />
          )}
        </div>
      </div>
      {deleteModal.openclose ? (
        <Deletemodal
          ModalShow={deleteModal}
          closeModal={closeDeleteModal}
          cancelModal={closeDeleteModal}
          deleteFunc={handleDelete}
          type={deleteModal?.type}
        />
      ) : null}
    </div>
  );
};
GitBranches.propTypes = {
  showModal: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    docId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,

  projectId: PropTypes.string,
  commonSl: PropTypes.string,
  tablesearchValue: PropTypes.string,
  hasPermission: PropTypes.func,
  getAllBranchsData: PropTypes.string,
  ticket: PropTypes.string,
  isLoadingBranches: PropTypes.string,
};
