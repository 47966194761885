/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
import { createSlice } from "@reduxjs/toolkit";

export const name = "ticketReducer";

function createInitialState() {
  return {
    ticketCount: [],
    ticketData: [],
    ticketAllocations: [],
    ticketStatus: null,
    allocationRole: null,
    errorMessage: "",
    successMessage: "",
    activeTask: null,
    Users: [],
    priority: [],
    ticketError: [],
    ticketDocs: [],
    TableHeading: "",
    Success: false,
    setTicketAllocationDatas: {},
    setTicketDatas: {},
    addTaskModal: false,
    allocationKey: "",
    fileUploadData: null,
    ticketAllocationParams: {
      page: 1,
      limit: 20,
    },
    ticketParams: {
      page: 1,
      limit: 20,
    },
    GetState: true,
  };
}

const initialState = createInitialState();
const ticketSlice = createSlice({
  name,
  initialState,
  reducers: {
    setTicketData: (state, { payload }) => {
      state.ticketData = payload.data;
      state.ticketCount =
        payload?.totalCount?.length > 0
          ? payload.totalCount
          : state?.ticketCount;
      state.ticketParams = payload.pagination;
      state.TableHeading = payload.TableHeading;
    },
    setTicketCount: (state, { payload }) => {
      state.ticketCount = payload.totalCount;
    },
    setGetState: (state, { payload }) => {
      state.GetState = payload;
    },
    setTicketAllocationData: (state, { payload }) => {
      if (!payload.TableHeading?.name) {
        state.ticketAllocations = state.ticketAllocations
          ? payload.reset
            ? payload.data
            : [...state.ticketAllocations, ...payload.data]
          : payload.data;
      }
      state.ticketAllocations = payload.data;
      state.ticketCount =
        payload?.totalCount?.length > 0
          ? payload.totalCount
          : state?.ticketCount;
      state.TableHeading = payload.TableHeading;
    },
    setAllocationKey: (state, { payload }) => {
      state.allocationKey = payload;
    },
    setStatusData: (state, { payload }) => {
      state.ticketStatus = payload.data;
    },
    setActiveTask: (state, { payload }) => {
      state.activeTask = payload;
    },
    setPriority: (state, { payload }) => {
      state.priority = payload.data;
    },
    setTicketAllocationRole: (state, { payload }) => {
      state.allocationRole = payload.data;
    },
    setPatchedData: (state, { payload }) => {
      state.ticketData = [payload];
    },
    setPatchedTicketAllocationData: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.ticketAllocations = state.ticketAllocations.map((each) => {
          if (payload.find((e) => e._id === each._id)) {
            const data = payload.find((e) => e._id === each._id);
            return {
              ...each,
              title: data.title,
              priority: data.priority,
              ticketStatus: data.ticketStatus,
              startDate: data.startDate,
              dueDate: data.dueDate,
            };
          }

          return each;
        });
      } else
        state.ticketAllocations = state.ticketAllocations.map((eachTicket) => {
          if (eachTicket._id === payload._id)
            return {
              ...eachTicket,
              title: payload.title,
              priority: payload.priority,
              ticketStatus: payload.ticketStatus,
              startDate: payload.startDate,
              dueDate: payload.dueDate,
            };
          return eachTicket;
        });
    },
    setUsersData: (state, { payload }) => {
      state.Users = payload;
    },
    setAddTaskModal: (state, { payload }) => {
      state.addTaskModal = payload;
    },
    setTicketError: (state, { payload }) => {
      state.ticketError = payload;
    },
    setTicketDocs: (state, { payload }) => {
      state.ticketDocs = payload;
    },
    setTicketAllocationDataById: (state, { payload }) => {
      if (payload.allocations) state.setTicketAllocationDatas = payload;
      else
        state.setTicketAllocationDatas = {
          ...state.setTicketAllocationDatas,
          ...payload,
        };
    },

    setTicketAllocationById: (state, { payload }) => {
      state.setTicketAllocationDatas = payload;
    },
    setFileUploadData: (state, { payload }) => {
      state.fileUploadData = payload.data;
    },
    setSuccess: (state, { payload }) => {
      state.Success = payload;
    },
    setTicketAllocationParams: (state, { payload }) => {
      state.ticketAllocationParams = { ...payload };
    },
  },
});

export const {
  setTicketData,
  setActiveTask,
  setStatusData,
  setPatchedData,
  setTicketAllocationRole,
  setTicketAllocationData,
  setPatchedTicketAllocationData,
  setUsersData,
  setPriority,
  setTicketError,
  setTicketDocs,
  setTicketAllocationDataById,
  setTicketAllocationById,
  setSuccess,
  setAddTaskModal,
  setAllocationKey,
  setFileUploadData,
  setTicketAllocationParams,
  setTicketCount,
  setGetState,
} = ticketSlice.actions;
export const ticketReducer = ticketSlice.reducer;

export default ticketReducer;
