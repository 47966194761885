import Gateway from "../gateway/gateway";

export const getProjects = (params) =>
  Gateway.get("/project/list", {
    params,
  });

// counts for doc,teams,technology
export const getProjectsCounts = (id) => Gateway.get(`/project/counts/${id}`);

export const getProjectById = (id) => Gateway.get(`/project/${id}`);
