import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import userService from "../../../../services/userService";
import { authActions } from "..";

export const fetchUserData = createAsyncThunk(
  "user/get",
  async (payload, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await userService.getUserData();
      dispatch(
        authActions.setUserData({
          data,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(authActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
