/* eslint-disable import/no-cycle */
import axios from "axios";
import API_ROUTE from "./serviceEndpoints";
import { errorNotify } from "../utils/utils";
import Notifications from "../components/Toaster";
import { decryptAccessToken } from "../utils/encryptionUtils";

const pmstestGateway = axios.create({
  baseURL: API_ROUTE,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 20000,
});

pmstestGateway.interceptors.response.use(
  (response) => response,
  ({ response }) => {
    if (response?.data?.statusCode === 400) {
      if (!errorNotify.includes(response.data?.errorName)) {
        if (Array.isArray(response?.data?.message)) {
          response?.data?.message.forEach((item) =>
            Notifications(item ?? "Something went wrong", "error"),
          );
        } else
          Notifications(
            response?.data?.message ?? "Something went wrong",
            "error",
          );
      }
      return response;
    }

    if (response?.data?.statusCode === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    }
    return response;
  },
);

pmstestGateway.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  const decryptedAccessToken =
    token && decryptAccessToken(token, process.env.REACT_APP_SECRET_KEY);
  if (decryptedAccessToken)
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${decryptedAccessToken}`,
    };
  return config;
});
export default pmstestGateway;
