/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import DrawingBoard from "react-drawing-board";
import CustomModal from "../../../components/CustomModal";
import "../../../design/custom/pages/notes-list.scss";
import { ArrowIcon, MiniArrowIcon } from "./svgIconNotes";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import { alertActions } from "../../../store/slices/alert";

const AddNotes = ({
  showModal,
  closeModal,
  createNotes,
  isLoading,
  UpdateNotes,
}) => {
  // state
  const [operations, setOperations] = useState([]);
  const [notes, setNotes] = useState({ title: "", file: "" });
  // const [errors, setErrors] = useState({ title: "", file: "" });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  console.log("errors", errors);
  console.log("operations", operations);

  // reducer
  const { value } = useSelector((state) => state.alert);

  useEffect(() => {
    if (value?.type === "success") {
      closeModal();
      dispatch(alertActions.clear());
    }
  }, [value]);

  useEffect(() => {
    if (showModal?._id) {
      setNotes({ title: showModal?.fileName, file: showModal?.file });
      // eslint-disable-next-line no-unsafe-optional-chaining
      setOperations([...showModal?.file]);
    }
  }, [showModal?._id]);

  const renderHeader = () => (
    <div className="modal-hdr-wrp">
      <button type="button" className="btn back-btn" onClick={closeModal}>
        <span>
          <ArrowIcon />
        </span>
        Back
      </button>
      <div className="modal-title">
        {isLoading ? (
          ""
        ) : (
          <>
            <span>Notes</span>
            <span className="arrow">
              <MiniArrowIcon />
            </span>
          </>
        )}
        <div className={`edit-title-field ${errors?.title}`}>
          {isLoading ? (
            <CommonSkeleton width={180} height={20} />
          ) : (
            <input
              value={notes.title}
              type="text"
              className="form-control"
              placeholder="Enter Title"
              onChange={({ target }) => {
                setNotes((prevNotes) => ({
                  ...prevNotes,
                  title: target.value,
                }));
                if (target.value === "") {
                  setErrors({ title: "error" });
                } else {
                  setErrors({ ...errors, title: "" });
                }
              }}
              disabled={showModal?._id}
            />
          )}
        </div>
      </div>
    </div>
  );
  const validate = () => {
    const newErrors = {};
    if (!notes?.title) {
      newErrors.title = "error";
    }
    if (operations.length === 0) {
      newErrors.file = "error";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const onSave = (dataUrl) => {
    console.log("dataUrl", dataUrl);
    const newErrors = validate();
    if (!Object.keys(newErrors).length) {
      const formData = new FormData();
      formData.append("fileName", notes?.title);
      formData.append("dataUrl", dataUrl);
      operations.forEach((file) => {
        console.log("file", file);
        formData.append("file", JSON.stringify(file));
      });

      if (showModal?._id) {
        UpdateNotes({ id: showModal?._id, data: formData });
      } else {
        createNotes(formData);
      }
    }
  };

  const renderBody = () => (
    <div className="chart-wrp">
      {isLoading ? (
        <div className="page-container">
          <div className="loader-container">
            <div className="loader" />
          </div>
        </div>
      ) : (
        <div className={`img-wrp ${errors?.file}`}>
          <DrawingBoard
            userId="user1"
            operations={operations}
            onChange={(newOperation, afterOperation) => {
              setOperations(afterOperation);
              if (!operations?.length === 0) {
                setErrors({ ...errors, file: "error" });
              } else {
                setErrors({ ...errors, file: "" });
              }
              console.log(`TODO: send ${newOperation}`);
              console.log("afterOperation", afterOperation);
            }}
            onSave={({ dataUrl }) => onSave(dataUrl)}
          />
        </div>
      )}
    </div>
  );

  return (
    <CustomModal
      className="modal fade note-list-modal show"
      show={!!showModal}
      closeModal={closeModal}
      header={renderHeader()}
      body={renderBody()}
      createModal
      closeButton
    />
  );
};
AddNotes.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  createNotes: PropTypes.func,
  isLoading: PropTypes.isRequired,
  UpdateNotes: PropTypes.isRequired,
};
export default AddNotes;
