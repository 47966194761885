/* eslint-disable no-restricted-globals */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useThunk } from "../../hooks/useThunk";
import { getBlog, getMultyUserProfile, getTodaysUser } from "../../store";
// import useElementScrollPosition from "../../components/Feed/scroll";
import { getFirstLetters } from "../../utils/utils";
import star from "../../assets/images/star-year.svg";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import { userProfileActions } from "../../store/slices/userProfile";
import { replaceUserMentions } from "../../components/Feed/Common";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

const BlogSection = ({ handleOnViewClick, isUserGet, hasPermission }) => {
  const [users] = useThunk(getTodaysUser);
  const [Blog, isBlog] = useThunk(getBlog);
  const { allUsers } = useSelector((state) => state.common);
  const { feedUser, feedUserDetail, savedFeed } = useSelector(
    (state) => state.feed,
  );
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [imgArray, setimgArray] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    users({});
  }, []);
  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };
  useEffect(() => {
    Blog({
      params: { page: 1, limit: 30 },
    });
  }, []);

  return (
    <div className="announcements-rt custom-scroll">
      {hasPermission(permissionConstants.permissions.FEED_VIEW_BIRTHDAYS) && (
        <div className="rt-listing-blks">
          <div className="rt-title-bar">
            <div className="icon-holder">
              {isUserGet ? (
                <CommonSkeleton className="circle-skeleton" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.5"
                  height="14.5"
                  viewBox="0 0 14.5 14.5"
                >
                  <g
                    id="Group_60251"
                    data-name="Group 60251"
                    transform="translate(-1868.5 -294.5)"
                  >
                    <path
                      id="Vector"
                      d="M0,0H13.5"
                      transform="translate(1869 308.5)"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M0,8.1V2.025A2.143,2.143,0,0,1,2.248,0h7.5a2.143,2.143,0,0,1,2.248,2.025V8.1"
                      transform="translate(1869.749 300.4)"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,3.375V1.465A1.554,1.554,0,0,1,1.634,0H7.067A1.552,1.552,0,0,1,8.694,1.465v1.91"
                      transform="translate(1871.403 297.025)"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M0,0,.25.007a.909.909,0,0,1,.9.911v.223a.911.911,0,1,0,1.822,0V.932a.911.911,0,0,1,1.823,0v.209a.911.911,0,1,0,1.822,0V.932a.911.911,0,0,1,1.823,0v.209a.911.911,0,1,0,1.822,0V.932A.912.912,0,0,1,11.171.02h.3"
                      transform="translate(1870.033 303.087)"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Vector-5"
                      data-name="Vector"
                      d="M0,1.35V0"
                      transform="translate(1873.05 295.675)"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Vector-6"
                      data-name="Vector"
                      d="M0,1.35V0"
                      transform="translate(1878.45 295.675)"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Vector-7"
                      data-name="Vector"
                      d="M0,2.025V0"
                      transform="translate(1875.75 295)"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              )}
            </div>
            {isUserGet ? <CommonSkeleton width={180} /> : "Birthday"}
          </div>
          {isUserGet ? (
            Array.from({ length: 3 || 0 })?.map((_, index) => (
              <div className="event-listing" key={index}>
                <div className="user-profile">
                  <div className="user-pic">
                    <CommonSkeleton className="circle-skeleton" />
                  </div>
                  <div className="user-wrp">
                    <div className="user-title">
                      <CommonSkeleton width={200} />
                    </div>
                    <div className="reminder">
                      <CommonSkeleton width={100} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : feedUserDetail?.birthdayUsers?.length > 0 ? (
            feedUserDetail?.birthdayUsers.map((e) => (
              <div className="event-listing">
                <div
                  className="user-profile"
                  key={e._id}
                  type="button"
                  onClick={(f) => {
                    f.stopPropagation();
                    getMultyUsers(e._id);
                    dispatch(userProfileActions.setShowUserProfile(true));
                  }}
                >
                  <div className="user-pic">
                    {e?.photo ? (
                      <div className="user-pic">
                        <img
                          src={`https://erptest.spericorn.com/repo/uploads/${e.photo}`}
                          alt="profile-pic"
                        />
                      </div>
                    ) : (
                      <div className="avathar-sm avathar-01">
                        <span>
                          {e?.employeeName
                            ?.split(" ")
                            .map((word) => word.slice(0, 1).toUpperCase())
                            .join("")}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="user-wrp">
                    <div className="user-title">{e?.employeeName}</div>
                    <div className="reminder">
                      {e?.dob
                        ? new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }).format(new Date(e.dob))
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <span className="no-data-message text-center w-100 d-block pb-3">
              No Data Found
            </span>
          )}
        </div>
      )}
      {hasPermission(
        permissionConstants.permissions.FEED_VIEW_WORK_ANNIVERSARY,
      ) && (
        <div className="rt-listing-blks">
          <div className="rt-title-bar">
            <div className="icon-holder">
              {isUserGet ? (
                <CommonSkeleton className="circle-skeleton" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.608"
                  height="11.46"
                  viewBox="0 0 13.608 11.46"
                >
                  <path
                    id="Vector"
                    d="M0,7.3V1.262C0,.424.485.222,1.078.815L2.711,2.448a.628.628,0,0,0,.889,0L5.856.184a.628.628,0,0,1,.889,0L9.008,2.448a.628.628,0,0,0,.889,0L11.53.815c.593-.593,1.078-.391,1.078.448V7.308A2.978,2.978,0,0,1,9.456,10.46h-6.3A3.163,3.163,0,0,1,0,7.3Z"
                    transform="translate(0.5 0.5)"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </svg>
              )}
            </div>
            {isUserGet ? (
              <CommonSkeleton width={180} />
            ) : (
              "  Work Anniversaries"
            )}
          </div>
          {isUserGet ? (
            Array.from({ length: 3 || 0 })?.map((_, index) => (
              <div className="event-listing" key={index}>
                <div className="user-profile">
                  <div className="user-pic">
                    <CommonSkeleton className="circle-skeleton" />
                  </div>
                  <div className="user-wrp">
                    <div className="user-title">
                      <CommonSkeleton width={200} />
                    </div>
                    <div className="reminder">
                      <CommonSkeleton width={100} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : feedUserDetail?.joinedAnniversaryUsers?.length > 0 ? (
            feedUserDetail?.joinedAnniversaryUsers.map((e) => (
              <div className="event-listing">
                <div
                  className="user-profile"
                  key={e._id}
                  type="button"
                  onClick={(f) => {
                    f.stopPropagation();
                    getMultyUsers(e._id);
                    dispatch(userProfileActions.setShowUserProfile(true));
                  }}
                >
                  <div className="user-pic">
                    {e?.photo ? (
                      imgArray?.includes(e._id) ? (
                        <div className="avathar-sm avathar-01">
                          <span>{getFirstLetters(e.employeeName)}</span>
                        </div>
                      ) : (
                        <div className="user-pic">
                          <img
                            onError={(target) => handleOnError(target, e._id)}
                            onLoad={(target) => {
                              target.target.style.display = "block";
                            }}
                            src={`https://erptest.spericorn.com/repo/uploads/${e?.photo}`}
                            alt="profile pic"
                          />
                        </div>
                      )
                    ) : (
                      <div className="avathar-sm avathar-01">
                        <span>
                          {e?.employeeName
                            ?.split(" ")
                            .map((word) => word.slice(0, 1).toUpperCase())
                            .join("")}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="user-wrp">
                    <div className="user-title">{e?.employeeName}</div>
                    <div className="reminder">
                      {e?.joinedDate
                        ? new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }).format(new Date(e.joinedDate))
                        : ""}
                      <strong>
                        <img src={star} alt="" />{" "}
                        {e?.joinedDate
                          ? `${
                              new Date().getFullYear() -
                              new Date(e.joinedDate).getFullYear()
                            }`
                          : null}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-data-message text-center w-100 d-block pb-3">
              No Data Found
            </div>
          )}
        </div>
      )}
      {hasPermission(permissionConstants.permissions.FEED_VIEW_BLOGS) && (
        <div className="rt-listing-blks blog">
          <div className="rt-title-bar">
            <div className="icon-holder">
              {isBlog ? (
                <CommonSkeleton className="circle-skeleton" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.31"
                  height="14.31"
                  viewBox="0 0 14.31 14.31"
                >
                  <g
                    id="Group_60267"
                    data-name="Group 60267"
                    transform="translate(-748 -314)"
                  >
                    <path
                      id="Vector"
                      d="M8.347,0H3.577Q0,0,0,3.577v7.751a.6.6,0,0,0,.6.6H8.347q3.577,0,3.577-3.577V3.577Q11.925,0,8.347,0Z"
                      transform="translate(749.193 315.192)"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <g id="Group" transform="translate(752.174 319.664)">
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M0,0H5.962"
                        fill="#fff"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H4.174"
                        transform="translate(0 2.981)"
                        fill="#fff"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M0,0H14.31V14.31H0Z"
                      transform="translate(748 314)"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </svg>
              )}
            </div>
            {isBlog ? <CommonSkeleton width={180} /> : "Blog"}
          </div>
          <div className="blog-listing-main custom-scroll">
            {isBlog
              ? Array.from({ length: 3 || 0 })?.map((_, index) => (
                  <div className="blog-listing" key={index}>
                    <div className="blog-thumb">
                      <CommonSkeleton width={200} height={200} />
                    </div>
                    <div className="blog-content">
                      <div className="title">
                        <CommonSkeleton width={200} />
                      </div>
                      <div className="desc">
                        <CommonSkeleton width={200} />
                      </div>
                    </div>
                  </div>
                ))
              : feedUser?.blog?.map((blog) => (
                  <Link
                    className="blog-listing"
                    key={blog.id}
                    to={blog.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="blog-thumb">
                      <img src={blog.image} alt="blog thumb" />
                    </div>
                    <div className="blog-content">
                      <div className="title">{blog?.title}</div>
                      <div className="desc">{blog?.description}</div>
                    </div>
                  </Link>
                ))}
          </div>
        </div>
      )}
      {hasPermission(permissionConstants.permissions.FEED_SAVE_POSTS) && (
        <div className="rt-listing-blks">
          <div className="rt-title-bar">
            <div className="title-wrp">
              <div className="icon-holder">
                {isBlog ? (
                  <CommonSkeleton className="circle-skeleton" />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.5"
                    height="14.5"
                    viewBox="0 0 14.5 14.5"
                  >
                    <g
                      id="Group_60251"
                      data-name="Group 60251"
                      transform="translate(-1868.5 -294.5)"
                    >
                      <path
                        id="Vector"
                        d="M0,0H13.5"
                        transform="translate(1869 308.5)"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M0,8.1V2.025A2.143,2.143,0,0,1,2.248,0h7.5a2.143,2.143,0,0,1,2.248,2.025V8.1"
                        transform="translate(1869.749 300.4)"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,3.375V1.465A1.554,1.554,0,0,1,1.634,0H7.067A1.552,1.552,0,0,1,8.694,1.465v1.91"
                        transform="translate(1871.403 297.025)"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0,.25.007a.909.909,0,0,1,.9.911v.223a.911.911,0,1,0,1.822,0V.932a.911.911,0,0,1,1.823,0v.209a.911.911,0,1,0,1.822,0V.932a.911.911,0,0,1,1.823,0v.209a.911.911,0,1,0,1.822,0V.932A.912.912,0,0,1,11.171.02h.3"
                        transform="translate(1870.033 303.087)"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M0,1.35V0"
                        transform="translate(1873.05 295.675)"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Vector-6"
                        data-name="Vector"
                        d="M0,1.35V0"
                        transform="translate(1878.45 295.675)"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Vector-7"
                        data-name="Vector"
                        d="M0,2.025V0"
                        transform="translate(1875.75 295)"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                )}
              </div>

              {savedFeed?.data?.updatedData?.length > 0 ? (
                <span>
                  {isBlog ? (
                    <CommonSkeleton width={180} />
                  ) : (
                    `Saved (${savedFeed?.data?.updatedData?.length})`
                  )}
                </span>
              ) : (
                <span>Saved</span>
              )}
            </div>
            <span
              onClick={() => {
                handleOnViewClick();
              }}
              className="font_weight_normal"
              type="button"
            >
              {isBlog ? <CommonSkeleton width={180} /> : "View all"}
            </span>
          </div>

          <ul className="groups-listing">
            {isBlog ? (
              Array.from({ length: 5 || 0 })?.map((_, index) => (
                <li key={index}>
                  <CommonSkeleton width={80} height={80} />
                </li>
              ))
            ) : savedFeed?.data?.updatedData.length > 0 ? (
              savedFeed?.data?.updatedData
                ?.filter((e) => e.attachments && e.attachments.length > 0)
                .concat(
                  savedFeed?.data?.updatedData?.filter(
                    (e) => e.attachments && e.attachments.length === 0,
                  ),
                )
                .slice(0, 4)
                .map((e) => (
                  <li key={e.id}>
                    {e.attachments && e.attachments.length > 0 ? (
                      <img src={e.attachments[0].path} alt="blog thumb" />
                    ) : (
                      <li
                        className="screenshot"
                        style={{ backgroundColor: "blue" }}
                        key={e.id}
                      >
                        <p>
                          {replaceUserMentions(
                            e?.title,
                            allUsers,
                            getMultyUsers,
                            dispatch,
                          ).slice(0, 3)}
                        </p>
                      </li>
                    )}
                  </li>
                ))
            ) : (
              <div>No Data found</div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
BlogSection.propTypes = {
  handleOnViewClick: PropTypes.func,
  isUserGet: PropTypes.bool,
  hasPermission: PropTypes.func,
};
export default BlogSection;
