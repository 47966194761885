import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomTable from "../../../components/CustomTable";
import { cols } from "./constants";
import { useThunk } from "../../../hooks/useThunk";
import { getReports } from "../../../store/slices/report/thunks/getReport";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import "../../../design/custom/pages/report-list.scss";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import { useFilter } from "../../../context/useFilter";
import { filterQuery } from "./filter";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
import { getMultyUserProfile } from "../../../store";
import { userProfileActions } from "../../../store/slices/userProfile";

function List() {
  const [report, isLoading] = useThunk(getReports);
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const { filter } = useFilter();
  const [filterData] = useThunk(getFilterDropdown);
  const { reportData } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const [imgArray, setimgArray] = useState([]);
  const [performanceDate, setPerformanceDate] = useState({
    start: "",
    end: "",
  });
  const { userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    filterData("report");
  }, []);

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.REPORTS,
        permissionConstants.submodules.REPORTS_LIST,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  // Callback function to handle performanceDate changes
  const handlePerformanceDateChange = (newPerformanceDate) => {
    setPerformanceDate(newPerformanceDate);
  };
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
  });
  const [sortOrders, setSortOrders] = useState({
    name: "",
    totalHoursWorked: "",
    totalActiveProjects: "",
    totalTickets: "",
    lastWorkedOn: "",
    totalAllocatedHoursPerDay: "",
    totalAllocatedProjects: "",
    totalQaRejections: "",
    workingDaysInMonth: "",
    leavesTakenInMonth: "",
  });

  const start =
    performanceDate.start && performanceDate.start.format("YYYY-MM-DD");
  const end = performanceDate.end && performanceDate.end.format("YYYY-MM-DD");
  const [sortBy, setSortBy] = useState(null);
  const { currentPage: page, limit = 10 } = paginationData;
  const matches = filterQuery(filter.filter);

  const initialParams = {
    start,
    end,
    page,
    limit,
    sortOrder: sortOrders[sortBy] ? sortOrders[sortBy] : null,
    sortBy: sortOrders[sortBy] ? sortBy : null,
    search: filter.search.trim(),
    Me: filter.me,
    match: matches,
  };
  const [params, setParams] = useState(initialParams);

  const previousMatchesRef = useRef(matches);
  useEffect(() => {
    if (performanceDate?.start && performanceDate?.end) {
      setParams({
        ...params,
        start: performanceDate?.start.format("YYYY-MM-DD"),
        end: performanceDate?.end.format("YYYY-MM-DD"),
        page: paginationData?.currentPage,
        limit: paginationData?.limit || 10,
      });
    }
  }, [
    performanceDate?.start,
    performanceDate?.end,
    paginationData?.currentPage,
    paginationData?.limit,
  ]);

  // fix tooltip flickering
  const handleMouseEnter = () => {
    document.body.classList.add("tooltip-active");
  };

  const handleMouseLeave = () => {
    document.body.classList.remove("tooltip-active");
  };

  useEffect(() => {
    if (paginationData?.currentPage > 1) {
      setPaginationData({ ...paginationData, currentPage: 1 });
    }
    setParams({ ...params, search: filter.search });
  }, [filter.search]);
  useEffect(() => {
    if (paginationData?.currentPage > 1) {
      setPaginationData({ ...paginationData, currentPage: 1 });
    }
    setParams({ ...params, Me: filter.me });
  }, [filter.me]);
  useEffect(() => {
    if (!isEqual(matches, previousMatchesRef.current)) {
      setParams({ ...params, match: matches });
      previousMatchesRef.current = matches;
    }
  }, [matches]);
  useEffect(() => {
    if (paginationData.currentPage !== 1)
      setPaginationData({ ...paginationData, currentPage: 1 });
    setParams({
      ...params,
      sortOrder: sortOrders[sortBy],
      sortBy: sortOrders[sortBy],
    });
  }, [sortBy, sortOrders]);
  useEffect(() => {
    if (params?.start && params?.end) report(params);
  }, [
    performanceDate?.start,
    performanceDate?.end,
    paginationData.currentPage,
    paginationData.limit,
    sortBy,
    sortOrders,
    params,
  ]);
  const formulas = {
    Name: "Name",
    " Total Hours Worked":
      "Total No. Of Working Days * Hours Allocated Per Day ",
    "Total Active Projects": "Total number of active project",
    "Total Tickets": "Counts of tickets which comes under Completed list. ",
    " Last Worked On": "last updated date on tickets in the list",
    "Hours Allocated Per Day": "Hours Allocated Per Day",
    "No. Of Projects Allocated": "No. Of Projects Allocated",
    "No. Of QA Rejections": "Count of QA Rejections",
    "No. Of First Approved Tickets": "Count of First Approved Tickets",
    "Total No. Of Working Days":
      "DaysDifference - (weekendCount + totalHolidays + totalOtherLeave + totalLeavesTaken)",
    "No. Of Leaves": "No. Of Leaves",
    "Quality Index":
      " ((Completed Count - Total tickets rejected) / Completed count) * 100",
    "Performance Index":
      "(Total No. of Tickets First Approved /Total Ticket) * 100",
    " Total Productive Hours":
      "estimation/((( Working-days ) - ( ( Holiday Count + (Other Leave Other Leave Exception) + (Leave Count  0.5))))*8)  100",
  };
  const userProfileData = (e, id) => {
    e.stopPropagation();
    getMultyUsers(id);
    dispatch(userProfileActions.setShowUserProfile(true)); // Open the profile modal
  };
  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };
  const renderImage = ({ row }) => {
    const names = row?.name?.split(" ");
    const shortName = names
      ?.slice(0, 2)
      .map((name) => name[0]?.toUpperCase())
      .join("");
    return (
      <div
        style={{ cursor: "pointer" }}
        className="name-block align-items-center d-flex"
        onClick={(e) => userProfileData(e, row._id)}
      >
        {row?.photo && !imgArray?.includes(row?._id) ? (
          <img
            onError={(target) => handleOnError(target, row?._id)}
            className="name-ltr"
            src={`https://erptest.spericorn.com/repo/uploads/${row.photo}`}
            alt="profile pic"
          />
        ) : (
          <span className="name-ltr">{shortName}</span>
        )}

        <span className="name text-nowrap">{row.name}</span>
      </div>
    );
  };
  const renderHeadingWithTooltip = (name) => {
    const tooltipText = formulas[name] || "";

    const tooltip = <Tooltip id={`tooltip-${name}`}>{tooltipText}</Tooltip>;

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span>{name}</span>
      </OverlayTrigger>
    );
  };
  const renderLastWorkedOn = ({ row }) => {
    return (
      <span className="name text-nowrap">
        {" "}
        {row.lastWorkedOn ? row.lastWorkedOn : "-"}
      </span>
    );
  };
  const renderleaves = ({ row }) => {
    return <span className="leaveCount">{row.leavesTakenInMonth}</span>;
  };

  const renderColor = ({ row }) => {
    let color = ""; // Default color

    if (row.qualityIndex < 50 || row.performanceIndex < 50) {
      color = "red"; // Set color to red if qualityIndex is less than 50
    } else if (row.qualityIndex > 75 || row.performanceIndex < 75) {
      color = "orange"; // Set color to orange if qualityIndex is greater than 75
    } else {
      color = "green"; // Set color to green for other cases
    }

    return (
      <span style={{ color }}>{row.qualityIndex || row.performanceIndex}</span>
    );
  };

  const setSortingOrder = (selector) => {
    const newSortOrders = { ...sortOrders };

    if (newSortOrders[selector]) {
      newSortOrders[selector] = newSortOrders[selector] === "asc" ? "desc" : "";
    } else {
      newSortOrders[selector] = "asc";
    }

    setSortOrders(newSortOrders);
    setSortBy(selector);
  };
  const renderProjectTooltip = ({ row }) => {
    const { totalAllocatedProjects, allocatedProjectNames } = row;
    const tooltip = (
      <Tooltip>
        {allocatedProjectNames &&
          allocatedProjectNames.map((project, index) => (
            <div key={index}>{project}</div>
          ))}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span>{totalAllocatedProjects}</span>
      </OverlayTrigger>
    );
  };
  const enhancedCols = cols
    .filter(
      (each) =>
        each.hasPermission === true || hasPermission(each.hasPermission),
    )
    .map((col) => {
      const enhancedCol = {
        ...col,
        name: renderHeadingWithTooltip(col.name),
      };

      if (col.selector === "name") {
        enhancedCol.cell = renderImage;
      } else if (col.selector === "lastWorkedOn") {
        enhancedCol.cell = renderLastWorkedOn;
      } else if (col.selector === "leavesTakenInMonth") {
        enhancedCol.cell = renderleaves;
      } else if (col.selector === "qualityIndex") {
        enhancedCol.cell = renderColor;
      } else if (col.selector === "performanceIndex") {
        enhancedCol.cell = renderColor;
      } else if (col.selector === "totalAllocatedProjects") {
        enhancedCol.cell = renderProjectTooltip;
      }

      return enhancedCol;
    });

  const tableClassName = "table custom-table text-left";
  const divClassName = "table-responsive table-min-h custom-scroll";

  const onLimitChange = (newLimit) => {
    setPaginationData({
      ...paginationData,
      limit: newLimit,
      currentPage: 1,
    });
  };

  return (
    <>
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.REPORTS_LIST_SEARCH,
        )}
        showReportListCalender
        showFilter={hasPermission(
          permissionConstants.permissions.REPORTS_LIST_CUSTOM_FILTER,
        )}
        showMe
        onPerformanceDateChange={handlePerformanceDateChange}
      />
      <div className="page-contents inner-page-contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 activity-log-area">
              <div className="content-tables ">
                <div
                  className="reports-table-cover"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {hasPermission(
                    permissionConstants.permissions.REPORTS_LIST_NAME,
                  ) && (
                    <CustomTable
                      isLoading={isLoading}
                      pagination={paginationData}
                      columns={enhancedCols}
                      data={reportData?.reports}
                      count={reportData?.totalCount}
                      tableClassName={tableClassName}
                      divClassName={divClassName}
                      showPagination
                      onLimitChange={onLimitChange}
                      onPageChange={(currentPage) =>
                        setPaginationData({ ...paginationData, currentPage })
                      }
                      onSortChange={(selector) => {
                        setSortingOrder(selector);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
