import Gateway from "../gateway/gateway";

export const getEmployeeListService = (params) =>
  Gateway.get("/employee", { params });

export const getDesignationListService = (params) =>
  Gateway.get("/designationPMS", { params });

export const updateEmployeeDesignationService = ({ id, params }) =>
  Gateway.patch(`/employee/${id}`, params);
