import { createContext, useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

const FilterContext = createContext();
// eslint-disable-next-line no-unused-vars
const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  const values = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      const value = cookie.substring(name.length, cookie.length);
      const valueArray = value.split(",");
      values.push(...valueArray);
    }
  }
  return values;
};
export const FilterProvider = ({ children }) => {
  const initalFilterValue = {
    where: "",
    condition: "",
    option: "",
    gate: "AND",
  };
  // console.log('getCookie("show")', getCookie("show"));
  const [filter, setFilter] = useState({
    search: "",
    filter: [initalFilterValue],
    groupBy: "",
    subTask: "",
    assignee: [],
    me: "",
    show: getCookie("show") || [],
    otherOptions: [],
    users: "",
  });

  const updatedFilter = useMemo(() => ({ filter, setFilter }), [filter]);

  return (
    <FilterContext.Provider value={updatedFilter}>
      {children}
    </FilterContext.Provider>
  );
};

FilterProvider.propTypes = {
  children: PropTypes.node,
};

export const useFilter = () => useContext(FilterContext);
