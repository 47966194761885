export function UploadSheet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.545"
      height="19.545"
      viewBox="0 0 16.545 19.545"
    >
      <path
        id="file-upload"
        d="M20.53,8.47l-6-6A.747.747,0,0,0,14,2.25H8A3.383,3.383,0,0,0,4.25,6V18A3.383,3.383,0,0,0,8,21.75h9A3.383,3.383,0,0,0,20.75,18V9A.747.747,0,0,0,20.53,8.47ZM14.75,4.811,18.189,8.25H17c-1.577,0-2.25-.673-2.25-2.25ZM17,20.25H8c-1.577,0-2.25-.673-2.25-2.25V6c0-1.577.673-2.25,2.25-2.25h5.25V6A3.383,3.383,0,0,0,17,9.75h2.25V18C19.25,19.577,18.577,20.25,17,20.25Zm-3.47-6.78a.75.75,0,1,1-1.06,1.06l-.72-.719V17a.75.75,0,1,1-1.5,0V13.811l-.72.719a.75.75,0,0,1-1.06-1.06l2-2a.771.771,0,0,1,.244-.162.751.751,0,0,1,.574,0,.771.771,0,0,1,.244.162Z"
        transform="translate(-4.228 -2.228)"
        fill="currentcolor"
      />
    </svg>
  );
}

export function UploadsheetImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="info"
      width="10.24"
      height="10.24"
      viewBox="0 0 10.24 10.24"
    >
      <g id="Group_59384" data-name="Group 59384">
        <g id="Group_59383" data-name="Group 59383">
          <path
            id="Path_10390"
            data-name="Path 10390"
            d="M5.12,0a5.12,5.12,0,1,0,5.12,5.12A5.117,5.117,0,0,0,5.12,0Zm0,9.526A4.406,4.406,0,1,1,9.526,5.12,4.411,4.411,0,0,1,5.12,9.526Z"
            fill="currentcolor"
          />
        </g>
      </g>
      <g
        id="Group_59386"
        data-name="Group 59386"
        transform="translate(4.601 4.268)"
      >
        <g id="Group_59385" data-name="Group 59385">
          <path
            id="Path_10391"
            data-name="Path 10391"
            d="M230.578,213.4c-.3,0-.519.128-.519.317v2.568c0,.162.216.323.519.323s.526-.162.526-.323v-2.568C231.1,213.525,230.868,213.4,230.578,213.4Z"
            transform="translate(-230.059 -213.397)"
            fill="currentcolor"
          />
        </g>
      </g>
      <g
        id="Group_59388"
        data-name="Group 59388"
        transform="translate(4.568 2.684)"
      >
        <g id="Group_59387" data-name="Group 59387">
          <path
            id="Path_10392"
            data-name="Path 10392"
            d="M228.928,134.208a.486.486,0,1,0,.546.479A.522.522,0,0,0,228.928,134.208Z"
            transform="translate(-228.375 -134.208)"
            fill="currentcolor"
          />
        </g>
      </g>
    </svg>
  );
}

export function UploadTask() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.662"
      height="11.51"
      viewBox="0 0 14.662 11.51"
    >
      <g id="cloud-computing" transform="translate(0 -55.031)">
        <g
          id="Group_1262"
          data-name="Group 1262"
          transform="translate(0 55.032)"
        >
          <g id="Group_1261" data-name="Group 1261" transform="translate(0 0)">
            <path
              id="Path_4026"
              data-name="Path 4026"
              d="M12.216,58.846a5.04,5.04,0,0,0-9.914.861,2.749,2.749,0,0,0,.458,5.461H5.051v-.916H2.76a1.833,1.833,0,0,1,0-3.665.458.458,0,0,0,.458-.458,4.123,4.123,0,0,1,8.169-.8.458.458,0,0,0,.389.367,2.291,2.291,0,0,1-.312,4.559H9.632v.916h1.833a3.207,3.207,0,0,0,.751-6.322Z"
              transform="translate(0 -55.032)"
              fill="currentcolor"
            />
            <path
              id="Path_4027"
              data-name="Path 4027"
              d="M182.868,249.144l-1.833,1.833.646.646,1.054-1.049v4.394h.916v-4.394l1.049,1.049.646-.646-1.833-1.833A.458.458,0,0,0,182.868,249.144Z"
              transform="translate(-175.851 -243.457)"
              fill="currentcolor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Download() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 22.362 20.779"
    >
      <g id="Layer_41" data-name="Layer 41" transform="translate(-4.38 -6.35)">
        <path
          id="Path_4039"
          data-name="Path 4039"
          d="M25.789,38.6a.8.8,0,0,0-.8.8v2.743a2.438,2.438,0,0,1-2.438,2.438H8.574a2.438,2.438,0,0,1-2.438-2.438V39.4a.8.8,0,1,0-1.606,0v2.743A4.048,4.048,0,0,0,8.574,46.19H22.548a4.048,4.048,0,0,0,4.044-4.044V39.4A.8.8,0,0,0,25.789,38.6Z"
          transform="translate(0 -19.21)"
          fill="currentcolor"
        />
        <path
          id="Path_4039_-_Outline"
          data-name="Path 4039 - Outline"
          d="M5.333,38.45a.954.954,0,0,1,.953.953v2.743a2.29,2.29,0,0,0,2.288,2.288H22.548a2.29,2.29,0,0,0,2.288-2.288V39.4a.953.953,0,0,1,1.906,0v2.743a4.2,4.2,0,0,1-4.194,4.194H8.574A4.2,4.2,0,0,1,4.38,42.146V39.4A.954.954,0,0,1,5.333,38.45Zm17.215,6.283H8.574a2.59,2.59,0,0,1-2.587-2.588V39.4a.653.653,0,0,0-1.306,0v2.743A3.9,3.9,0,0,0,8.574,46.04H22.548a3.9,3.9,0,0,0,3.894-3.894V39.4a.653.653,0,0,0-1.306,0v2.743A2.59,2.59,0,0,1,22.548,44.733Z"
          transform="translate(0 -19.21)"
          fill="currentcolor"
        />
        <path
          id="Path_4040"
          data-name="Path 4040"
          d="M23.4,21.735a.8.8,0,0,0,1.14,0l4.57-4.57a.8.8,0,0,0-1.124-1.136l-3.213,3.213V7.3a.8.8,0,0,0-1.606,0V19.23l-3.213-3.213a.8.8,0,1,0-1.136,1.136Z"
          transform="translate(-8.41 0)"
          fill="currentcolor"
        />
        <path
          id="Path_4040_-_Outline"
          data-name="Path 4040 - Outline"
          d="M23.971,22.123a.959.959,0,0,1-.677-.282L18.713,17.26a.954.954,0,0,1,1.349-1.349l2.956,2.956V7.3a.953.953,0,0,1,1.906,0V18.88l2.968-2.967a.953.953,0,0,1,1.334,1.349l-.009.01-4.57,4.57A.959.959,0,0,1,23.971,22.123Zm-4.584-6.191a.654.654,0,0,0-.462,1.116l4.582,4.582a.653.653,0,0,0,.928,0L29,17.065a.653.653,0,0,0-.913-.924L24.624,19.6V7.3a.653.653,0,1,0-1.306,0V19.592L19.85,16.123A.649.649,0,0,0,19.387,15.932Z"
          transform="translate(-8.41 0)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}

export function Info() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="info"
      width="10.24"
      height="10.24"
      viewBox="0 0 10.24 10.24"
    >
      <g id="Group_59384" data-name="Group 59384">
        <g id="Group_59383" data-name="Group 59383">
          <path
            id="Path_10390"
            data-name="Path 10390"
            d="M5.12,0a5.12,5.12,0,1,0,5.12,5.12A5.117,5.117,0,0,0,5.12,0Zm0,9.526A4.406,4.406,0,1,1,9.526,5.12,4.411,4.411,0,0,1,5.12,9.526Z"
            fill="currentcolor"
          />
        </g>
      </g>
      <g
        id="Group_59386"
        data-name="Group 59386"
        transform="translate(4.601 4.268)"
      >
        <g id="Group_59385" data-name="Group 59385">
          <path
            id="Path_10391"
            data-name="Path 10391"
            d="M230.578,213.4c-.3,0-.519.128-.519.317v2.568c0,.162.216.323.519.323s.526-.162.526-.323v-2.568C231.1,213.525,230.868,213.4,230.578,213.4Z"
            transform="translate(-230.059 -213.397)"
            fill="currentcolor"
          />
        </g>
      </g>
      <g
        id="Group_59388"
        data-name="Group 59388"
        transform="translate(4.568 2.684)"
      >
        <g id="Group_59387" data-name="Group 59387">
          <path
            id="Path_10392"
            data-name="Path 10392"
            d="M228.928,134.208a.486.486,0,1,0,.546.479A.522.522,0,0,0,228.928,134.208Z"
            transform="translate(-228.375 -134.208)"
            fill="currentcolor"
          />
        </g>
      </g>
    </svg>
  );
}

export function Excel() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.49"
      height="12.75"
      viewBox="0 0 33.242 44.452"
    >
      <g
        id="Group_59906"
        data-name="Group 59906"
        transform="translate(-5644.484 -7698.743)"
      >
        <g id="Vrstva_x0020_1_30_" transform="translate(5644.484 7698.743)">
          <path
            id="Path_10637"
            data-name="Path 10637"
            d="M105.575,0h14.877l12.589,13.141v25.52a5.781,5.781,0,0,1-5.776,5.792H105.575A5.792,5.792,0,0,1,99.8,38.661V5.776A5.788,5.788,0,0,1,105.575,0Z"
            transform="translate(-99.799 0)"
            fill="#00733b"
            fillRule="evenodd"
          />
          <g
            id="Group_59814"
            data-name="Group 59814"
            transform="translate(6.538 0)"
          >
            <path
              id="Path_10638"
              data-name="Path 10638"
              d="M467.218,0V13.027h12.606Z"
              transform="translate(-453.12)"
              fill="#fff"
              fillRule="evenodd"
              opacity="0.302"
            />
            <path
              id="Path_10639"
              data-name="Path 10639"
              d="M223.49,439.128h-2.158l-1.476-2.482-1.476,2.482h-2.174l2.563-4.315-2.255-3.747h2.174l1.168,1.947,1.152-1.947h2.174l-2.239,3.764Zm.762,0v-8.063h2.06v6.311h3.5v1.752Zm8.972.1a3.3,3.3,0,0,1-2.141-.762,2.519,2.519,0,0,1-.99-1.833l1.768-.519a1.6,1.6,0,0,0,.487.99,1.431,1.431,0,0,0,.973.389,1.289,1.289,0,0,0,.73-.195.626.626,0,0,0,.276-.519.593.593,0,0,0-.227-.454,2.064,2.064,0,0,0-.568-.292,7.5,7.5,0,0,0-.779-.227,5.411,5.411,0,0,1-.86-.308,3.021,3.021,0,0,1-.779-.438,1.687,1.687,0,0,1-.568-.714,2.352,2.352,0,0,1-.227-1.055,2,2,0,0,1,.844-1.655,3.16,3.16,0,0,1,2.044-.665,3.791,3.791,0,0,1,2.077.568,2.477,2.477,0,0,1,1.1,1.509l-1.849.779a1.615,1.615,0,0,0-.471-.827,1.3,1.3,0,0,0-.86-.308,1.154,1.154,0,0,0-.617.162.476.476,0,0,0-.211.422.5.5,0,0,0,.292.422,2.709,2.709,0,0,0,.746.227,6.388,6.388,0,0,1,.957.244,5.894,5.894,0,0,1,.973.438,1.942,1.942,0,0,1,.73.795,2.625,2.625,0,0,1,.308,1.314,2.2,2.2,0,0,1-.876,1.817A3.625,3.625,0,0,1,233.224,439.226Z"
              transform="translate(-216.206 -406.762)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
