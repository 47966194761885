/* eslint-disable */

import { useEffect } from "react";
import useTitle from "../hooks/useTitle";

function withTitle(HocComponent) {
  return function WihLoadingComponent({
    title = "PMS Management Spericorn",
    ...props
  }) {
    const { setTitle } = useTitle();
    useEffect(() => {
      if (title) {
        setTitle(title);
      }
    }, []);

    return <HocComponent {...props} />;
  };
}

export { withTitle };
