import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import technologyIconServices from "../../../../../services/technologyIconServices";
import { alertActions } from "../../../alert";
import { getTechnologyIcon } from "./getTechnologyIcon";

export const updateTechnologyIcon = createAsyncThunk(
  "update-technologyIcon",
  async ({ id, data }, { dispatch }) => {
    console.log("data", data);
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await technologyIconServices.updateTechnologyIconList({
        id,
        data,
      });
      dispatch(getTechnologyIcon());
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
