import Gateway from "../gateway/gateway";

export const postfeedService = (data) =>
  Gateway.post("/feed", data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

export const patchFeedService = (data) =>
  Gateway.patch("/feed", data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

export const listFeedService = (params) => Gateway.get("/feed", { params });
export const savedFeedService = (params) =>
  Gateway.get(`/feed/saved/${params}`);
export const TodaysUserService = () => Gateway.get("/feed/user");
export const getBlogService = (params) => Gateway.get("/feed/blog", { params });
export const getUsersFeed = (id, params) =>
  Gateway.get(`/feed/${id}`, { params });
export const getFeedByIdService = (id, params) =>
  Gateway.get(`/feed/id/${id}`, { params });
export const deleteFeedService = (id) => Gateway.delete(`/feed/${id}`);
