import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { tableDetails } from "../../../../services/gitHubService";
import { gitHubAction } from "..";

export const getRepoDetailsTable = createAsyncThunk(
  "github/Table",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const { data } = await tableDetails(id);
      dispatch(gitHubAction.getTableDetails(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
