import Select from "react-select";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

function CustomSelect({ options, onChange, input, isLoading }) {
  const [selectedOptions, setSelectedOptions] = useState(input.value || []);

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions.length > 0 ? selectedOptions : null);
  };

  useEffect(() => {
    setSelectedOptions(input.value || []);
  }, [input.value]);

  return (
    <Select
      options={options}
      placeholder="Select Members"
      isSearchable
      isMulti
      classNamePrefix="react-select"
      className={`react-select-container ${input.value ? "val-selected" : ""}`}
      value={selectedOptions}
      onChange={handleChange}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      components={{ Option: CustomOption }}
      isLoading={isLoading}
    />
  );
}

function CustomOption({ innerProps, data }) {
  const [imgArray, setimgArray] = useState([]);
  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };
  const names = data.label.split(" ");
  const shortName = names
    .slice(0, 2)
    .map((name) => name[0].toUpperCase())
    .join("");

  const shouldDisplayImage = data?.photo && !imgArray?.includes(data?.value);

  return (
    <div {...innerProps} className="name-block align-items-center d-flex">
      {shouldDisplayImage ? (
        <img
          onError={(target) => handleOnError(target, data?.value)}
          className="name-ltr"
          src={`https://erptest.spericorn.com/repo/uploads/${data?.photo}`}
          alt="profile pic"
        />
      ) : (
        <span className="name-ltr">
          {shortName}
          <div className="online-status" />
        </span>
      )}

      <div className="member-list">
        <span className="name">{data.label}</span>
        <span className="ticket-count">{data.ticketCount} Tasks</span>
      </div>
    </div>
  );
}

CustomOption.propTypes = {
  innerProps: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    tabIndex: PropTypes.string,
    "aria-disabled": PropTypes.bool,
  }).isRequired,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    ticketCount: PropTypes.number.isRequired,
    photo: PropTypes.string.isRequired,
  }).isRequired,
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CustomSelect;
