import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../../design/custom/structure/sidenav.scss";

// icons
import {
  DashboardIco,
  DocsIco,
  ReportsIco,
  PermissionIcon,
  TrashIcon,
  NotifyIco,
  Logo,
  ProjectsIco,
  SettingsIco,
  TimesheetIco,
  DocumentIco,
} from "./svgIcons";
import RenderProjectMenu from "./ProjectMenu";
import DefaultMenu from "./components/DefaultMenu";
import SearchBar from "./components/SearchBar";
import { userProfileActions } from "../../store/slices/userProfile";
import { useThunk } from "../../hooks/useThunk";
import { auth_doLogout, getMultyUserProfile } from "../../store";
import { patchUserData } from "../../store/slices/auth/thunks/patchUserData";
import { isDarkMode } from "../../store/slices/theme";
// import createWebSocketConnection from "../../helpers/socketIO";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import {
  hasModulePermission,
  hasSubModulePermission,
} from "../../utils/permissionUtils";
import { ActivityLogIcon } from "../PrimaryNavbar/navbar-icons";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import socket from "../../helpers/socketIO";
import { decryptAccessToken } from "../../utils/encryptionUtils";

function SideNav() {
  const menus = [
    {
      menu: "Dashboard",
      isDefaultMenu: true,
      to: "/dashboard",
      icon: () => <DashboardIco />,
      hasPermission: permissionConstants.modules.DASHBOARD,
    },
    {
      menu: "Projects",
      component: (projectData) => (
        <li>
          <RenderProjectMenu projects={projectData ?? []} />
        </li>
      ),
      icon: () => <ProjectsIco />,
      // hasPermission: permissionConstants.modules.PROJECTS_MANAGEMENT,
      hasPermission: true,
    },
    {
      menu: "Reports",
      isDefaultMenu: true,
      to: "/report",
      icon: () => <ReportsIco />,
      hasPermission: permissionConstants.modules.REPORTS,
    },
    {
      menu: "Feed",
      isDefaultMenu: true,
      to: "/feed",
      icon: () => <ReportsIco />,
      hasPermission: permissionConstants.modules.FEED,
    },
    {
      menu: "Timesheet",
      isDefaultMenu: true,
      to: "/timesheet",
      icon: () => <TimesheetIco />,
      hasPermission: permissionConstants.modules.TIME_SHEETS,
    },
    {
      menu: "Documents",
      isDefaultMenu: true,
      to: "/docs",
      icon: () => <DocumentIco />,
      hasPermission: permissionConstants.modules.DOCUMENTS,
    },
    {
      menu: "Allocation",
      isDefaultMenu: true,
      to: "/allocation",
      icon: () => <DocsIco />,
      hasPermission: permissionConstants.modules.ALLOCATION,
    },
    {
      menu: "Widgets",
      isOpen: false,
      isDefaultMenu: true,
      hasPermission: permissionConstants.modules.WIDGETS,
      subMenus: [
        {
          menu: "To Do",
          to: "/todo",
          hasPermission: permissionConstants.submodules.TODO,
        },
        {
          menu: "Notes",
          to: "/notes",
          hasPermission: permissionConstants.submodules.NOTES,
        },
        {
          menu: "Meeting Scheduler",
          to: "/meeting",
          hasPermission: permissionConstants.submodules.MEETING_SCHEDULER,
        },
      ],
      icon: () => <DocsIco />,
    },
    {
      menu: "Activity Log",
      isDefaultMenu: true,
      to: "/activitylogs",
      icon: () => <ActivityLogIcon />,
      hasPermission: permissionConstants.modules.ACTIVITY_LOG,
    },
    {
      menu: "User Management",
      isOpen: false,
      isDefaultMenu: true,
      subMenus: [
        {
          menu: "Category",
          to: "/departments",
          hasPermission: permissionConstants.submodules.DEPARTMENT,
        },
        {
          menu: "Permissions",
          to: "/roles",
          hasPermission: permissionConstants.submodules.ROLE,
        },
        {
          menu: "Employee",
          to: "/employee",
          hasPermission: permissionConstants.submodules.EMPLOYEE_LIST,
        },
        {
          menu: "Client",
          to: "/client",
          hasPermission: permissionConstants.submodules.CLIENT_LIST,
        },
      ],
      icon: () => <PermissionIcon />,
      hasPermission: permissionConstants.modules.PERMISSIONS,
    },
    {
      menu: "Setup",
      isOpen: false,
      isDefaultMenu: true,
      hasPermission: true,
      subMenus: [
        {
          menu: "Server",
          isOpen: false,
          subMenus: [
            {
              menu: "Cron Jobs",
              to: "/cron-job",
              hasPermission: permissionConstants.submodules.CRONJOB,
            },
          ],
        },
        {
          menu: "Whitelabel",
          isOpen: false,
          subMenus: [
            {
              menu: "Design",
              to: "/design",
              hasPermission: permissionConstants.submodules.WHITELABEL_DESIGN,
            },
            {
              menu: "Email Account",
              to: "/email-account",
              hasPermission:
                permissionConstants.submodules.WHITELABEL_EMAIL_ACCOUNT,
            },
            {
              menu: "Icons",
              to: "/icons/notification",
              hasPermission: permissionConstants.submodules.WHITELABEL_ICONS,
            },
          ],
        },
      ],

      icon: () => <SettingsIco />,
    },
    {
      menu: "Trash",
      isDefaultMenu: true,
      to: "/trash",
      icon: () => <TrashIcon />,
      hasPermission: permissionConstants.modules.TRASH,
    },
  ];

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSideNav, setShowSideNav] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [menuState, setMenuState] = useState(menus);
  const [notificationCount, setNotificationCount] = useState(0);

  const [patchUserDataThunk] = useThunk(patchUserData);

  const { projectData } = useSelector(({ project }) => project);
  const { showUserprofile } = useSelector(({ userProfile }) => userProfile);
  const { userProfile } = useSelector((state) => state.userProfile);
  const { colorData } = useSelector((state) => state.colors);
  const { darkMode } = useSelector((state) => state.theme);
  const [logOut] = useThunk(auth_doLogout);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const decryptedAccessToken =
      token && decryptAccessToken(token, process.env.REACT_APP_SECRET_KEY);
    if (decryptedAccessToken) {
      socket.on("GET_UNREAD_NOTIFICATION_COUNT", (data) => {
        console.log("data---->>", data);
        setNotificationCount(data.count);
      });

      return () => {
        socket.off("GET_UNREAD_NOTIFICATION_COUNT");
      };
    }
  }, []);

  // image error handling
  const [imgError, setimgError] = useState(false);

  const handleOnError = () => {
    setimgError(true);
  };

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchText(value);
  };

  const sidenavMenu = ({ key, toggle }) => {
    if (key) key = key.toLowerCase();
    if (toggle) {
      setMenuState(
        menuState.map((e) => {
          if (e.menu === toggle) {
            return { ...e, isOpen: !e.isOpen };
          }
          if (e.subMenus && e.subMenus.some(({ menu }) => menu === toggle)) {
            return {
              ...e,
              subMenus: e.subMenus.map((each) => {
                if (each.menu === toggle)
                  return { ...each, isOpen: !each.isOpen };
                return each;
              }),
            };
          }
          return e;
        }),
      );
    }
    if (key) {
      const filterMenus = (input, menuList) => {
        return menuList.reduce((filtered, menu) => {
          const menuNameIncludesInput = menu.menu
            .toLowerCase()
            .startsWith(input.toLowerCase());

          if (menuNameIncludesInput) {
            const filteredMenu = {
              ...menu,
              isOpen: true,
              subMenus: menu.subMenus,
            };
            filtered.push(filteredMenu);

            return filtered;
          }

          if (menu.subMenus) {
            const matchingSubMenus = filterMenus(input, menu.subMenus);
            if (matchingSubMenus.length > 0) {
              const filteredMenu = {
                ...menu,
                isOpen: true,
                subMenus: matchingSubMenus,
              };
              filtered.push(filteredMenu);
            }
          }

          return filtered;
        }, []);
      };

      const filteredMenu = filterMenus(searchText, menus);

      setMenuState(filteredMenu);
    } else if (key === "") setMenuState(menus);
  };

  const [getMultyUsers] = useThunk(getMultyUserProfile);

  useEffect(() => {
    sidenavMenu({ key: searchText });
  }, [searchText]);

  useEffect(() => {
    if (pathname)
      setMenuState([
        ...menuState.map((each) => {
          if (
            each.subMenus?.some((e) =>
              pathname.startsWith(`/${e.to?.split("/")[1]}`),
            )
          )
            return {
              ...each,
              isOpen: true,
            };
          if (
            each.subMenus &&
            each.subMenus.some(
              (e) =>
                e.subMenus &&
                e.subMenus.some(({ to }) =>
                  pathname.startsWith(`/${to?.split("/")[1]}`),
                ),
            )
          )
            return {
              ...each,
              isOpen: true,
              subMenus: each.subMenus.map((e) => {
                if (
                  e.subMenus.some(({ to }) =>
                    pathname.startsWith(`/${to?.split("/")[1]}`),
                  )
                )
                  return { ...e, isOpen: true };
                return e;
              }),
            };
          return each;
        }),
      ]);
  }, [pathname]);

  const getFirstLetters = (name) => {
    const words = name?.split(" ");

    if (words && words.length >= 2) {
      const firstLetters = words.slice(0, 2).map((word) => word[0]);
      return firstLetters.join("").toUpperCase();
    }
    if (words && words.length === 1) {
      return words[0][0].toUpperCase();
    }
    return "";
  };

  const hasPermission = (module) => {
    const hasModule = hasModulePermission(
      userProfile?.formattedPermissions ?? [],
      module,
    );
    return hasModule;
  };

  const hasSubModule = (subModule) =>
    hasSubModulePermission(userProfile?.formattedPermissions ?? [], subModule);

  return (
    <div className={showSideNav ? "sidenav opened" : "sidenav collapsed"}>
      <div className="sidenavHead d-flex align-items-center">
        {showSideNav && (
          <>
            <div className="logoWrp">
              <Logo />
            </div>

            <button
              type="button"
              className="btn-notif"
              onClick={() => navigate("/notifications/new")}
            >
              <span className="ico">
                <NotifyIco />
              </span>
              <span className="count">{notificationCount}</span>
            </button>
          </>
        )}
        <button
          className={showSideNav ? "btn btn-hamburg" : "btn btn-hamburg"}
          type="button"
          onClick={() => setShowSideNav(!showSideNav)}
        >
          <span />
          <span />
          <span />
        </button>
      </div>

      <div className="sidenavWrap">
        <SearchBar value={searchText} onChange={onSearchTextChange} />
        <div className="sidenavMenuWrap custom-scroll">
          <ul className="sidenavMenu">
            {menuState
              .filter((e) =>
                userProfile?.formattedPermissions
                  ? e.hasPermission === true || hasPermission(e.hasPermission)
                  : true,
              )
              .filter((each) => {
                if (each.subMenus) {
                  return each.subMenus.some((subMenu) =>
                    subMenu.subMenus
                      ? subMenu.subMenus.filter((e) =>
                          e.hasPermission
                            ? hasSubModule(e.hasPermission)
                            : true,
                        ).length
                      : true,
                  );
                }
                return each;
              })
              .map(
                ({
                  menu,
                  subMenus,
                  to,
                  isDefaultMenu,
                  component,
                  icon,
                  isOpen,
                }) => {
                  if (!userProfile?.formattedPermissions)
                    return (
                      <li>
                        <CommonSkeleton />
                      </li>
                    );
                  if (isDefaultMenu)
                    return (
                      <DefaultMenu
                        menu={menu}
                        subMenus={subMenus}
                        to={to}
                        key={menu}
                        icon={icon}
                        sidenavMenu={sidenavMenu}
                        isOpen={isOpen}
                      />
                    );
                  return component(projectData);
                },
              )}
          </ul>
        </div>

        <div className="sidenavBottom d-flex align-items-center">
          <div className="userSelect">
            <Dropdown autoClose="outside">
              <Dropdown.Toggle variant="" bsPrefix="p-0 border border-0">
                <div
                  className="userIco"
                  style={{
                    backgroundColor: colorData?.find(
                      (e) => e._id === userProfile?.primaryColor,
                    )?.colorCode,
                  }}
                >
                  {userProfile?.photo && !imgError ? (
                    <img
                      onError={handleOnError}
                      src={`https://erptest.spericorn.com/repo/uploads/${userProfile?.photo}`}
                      alt=""
                    />
                  ) : (
                    getFirstLetters(userProfile?.employeeName)
                  )}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu bsPrefix="dropdown-menu prof-axns">
                <Dropdown.Item
                  className="d-flex align-items-center prof-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!showUserprofile) {
                      getMultyUsers(userProfile?._id);
                    }
                    dispatch(userProfileActions.setShowUserProfile(true));
                  }}
                >
                  <div
                    className="proPic"
                    style={{
                      backgroundColor: colorData?.find(
                        (e) => e._id === userProfile?.primaryColor,
                      )?.colorCode,
                    }}
                  >
                    {userProfile?.photo && !imgError ? (
                      <img
                        onError={handleOnError}
                        src={`https://erptest.spericorn.com/repo/uploads/${userProfile?.photo}`}
                        alt=""
                      />
                    ) : (
                      getFirstLetters(userProfile?.employeeName)
                    )}
                    <div className="on-off online" />
                  </div>
                  <span className="name">{userProfile?.employeeName}</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/notifications/new")}>
                  Notifications
                </Dropdown.Item>
                {hasPermission(permissionConstants.modules.SETTINGS) && (
                  <Dropdown.Item onClick={() => navigate("/user-settings")}>
                    Settings
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    if (userProfile?.theme === "Light") {
                      localStorage.setItem("theme", "Dark");
                      dispatch(isDarkMode(true));
                      patchUserDataThunk({ theme: "Dark" });
                    }
                    if (userProfile?.theme === "Dark") {
                      localStorage.removeItem("theme");
                      dispatch(isDarkMode(false));
                      patchUserDataThunk({ theme: "Light" });
                    }
                  }}
                  className="d-flex align-items-center"
                >
                  <span className="pe-2">Dark Mode</span>
                  <label className="switch ms-auto">
                    <input checked={darkMode} type="checkbox" readOnly />
                    <span className="slider round" />
                  </label>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    logOut();
                  }}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideNav;
