import { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../design/custom/pages/profilecard.scss";
import "../../design/custom/pages/feed.scss";
import PostList from "../../components/Feed/feedPost";
import SavedIcon from "../../assets/images/saved_03.svg";
import { useThunk } from "../../hooks/useThunk";
import { patchFeed } from "../../store/slices/feed/thunks/patchFeed";
import { deleteFeed } from "../../store/slices/feed/thunks/deleteFeed";

const ViewAll = ({ setView, authData, hasPermission }) => {
  const { savedFeed } = useSelector((state) => state.feed);
  const [feedPatch, isPatch] = useThunk(patchFeed);
  const [feedDelete] = useThunk(deleteFeed);
  const [selectDropdownOption, setSelectDropdownOption] = useState({
    type: "",
    id: "",
  });
  const [showComment, setShowComment] = useState(false);
  const { allUsers } = useSelector((state) => state.common);

  const handlePatch = (feedId, reaction, saved) => {
    const formData = new FormData();
    if (saved) {
      formData.append("feedId", feedId);
      formData.append("saved", saved);
    } else {
      formData.append("emoji", reaction);
      formData.append("userId", authData._id);
      formData.append("feedId", feedId);
    }
    feedPatch({ formData, isEdit: false, userId: authData?._id });
  };
  const handleDeleteFeed = (feedId) => {
    feedDelete({ feedId, userId: authData?._id });
    setSelectDropdownOption({ type: "", id: "" });
  };
  return (
    <div
      className="slide-details-lg-cvr custom-scroll view-all show"
      onClick={() => setShowComment("")}
    >
      <div className="profle-hdr spacing pb-0">
        <div className="step-1">
          <div className="title-saved">
            <span className="icon">
              <img src={SavedIcon} alt="saved" />
            </span>
            Saved
          </div>
          <button
            className="btn-close"
            type="button"
            onClick={() => {
              setView(false);
            }}
          />
        </div>
      </div>

      <div className="tab-area-wrap ">
        <div className="tab-menus spacing pt-0 pb-2 ">
          <PostList
            listData={savedFeed}
            handleLikePatch={handlePatch}
            isPatch={isPatch}
            isFeed
            handleDeleteFeed={handleDeleteFeed}
            setSelectDropdownOption={setSelectDropdownOption}
            selectDropdownOption={selectDropdownOption}
            type="view"
            setShowComment={setShowComment}
            showComment={showComment}
            allUsers={allUsers}
            hasPermission={hasPermission}
          />
        </div>
      </div>
    </div>
  );
};
ViewAll.propTypes = {
  setView: PropTypes.func,
  hasPermission: PropTypes.func,
  authData: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

export default ViewAll;
