import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState } from "react";
import DownloadIcon from "../../assets/images/download-icon.svg";
import pdf from "../../assets/images/pdf.svg";
import deleteIcon from "../../assets/images/delete_icon.svg";
import xlsx from "../../assets/images/xls.svg";
import docx from "../../assets/images/doc.svg";
import textIcon from "../../assets/images/text.svg";
import { useThunk } from "../../hooks/useThunk";
import trash from "../../assets/images/confirm-trash.svg";
import {
  deleteTicketAllocationAttachment,
  deleteTicketAttachment,
} from "../../store";
import CustomModal from "../../components/CustomModal";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import { convertBytesToKBMB } from "../Document/utility";

const FileAttachment = ({
  attachment,
  id,
  ticket,
  selectedFile,
  newTab,
  hasPermission,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState({});
  const getFileIcon = (extension) => {
    const iconMappings = {
      pdf,
      xlsx,
      docx,
      doc: docx,
      jpg: docx,
      png: docx,
      jpeg: docx,
      txt: textIcon,
    };
    return iconMappings[extension] || DownloadIcon;
  };
  const [TicketAttachmentDelete, isTicketAttachmentDeleted] = useThunk(
    deleteTicketAttachment,
  );
  const [TicketDelete, isTaskAtmDeleted] = useThunk(
    deleteTicketAllocationAttachment,
  );
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const deleteAttachment = () => {
    if (ticket) {
      TicketAttachmentDelete({ id, file });
    } else {
      TicketDelete({ id, file });
    }
    setFile("");
  };
  const handleClose = () => {
    setFile("");
  };
  const handleFileDownload = (file) => {
    setDownloadingFile(file);

    setTimeout(() => {
      setDownloadingFile(false);
    }, 2000);
  };

  const renderHeader = () => {
    return (
      <div className="text-center mx-auto">
        <div className="icons-delete mb-3">
          <img src={trash} alt="trash" />
        </div>
        <h5 id="exampleModalLabel" className="conf-msg">
          Are you sure you want to delete the document
        </h5>
        <h5 className="conf-msg">
          <b>{fileName && fileName.split("/")[1]} ?</b>
        </h5>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <div className="btn-block">
          <button
            className="modal-btn btn-confirm btn"
            type="button"
            onClick={deleteAttachment}
          >
            Confirm
          </button>
          <button
            className="modal-btn btn-cancel btn"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  return (
    hasPermission(
      permissionConstants.permissions.TASK_DETAILS_MODAL_ATTACHMENT_LIST,
    ) && (
      <>
        <div className="title__otr attachTitle position-relative">
          <div className="title__md">Attachments</div>
          <small>
            {attachment?.length} {attachment?.length === 1 ? "file" : "files"}{" "}
            attached
          </small>
          {selectedFile && <div className="animate-progress" />}
        </div>
        <div className="attachments__wrp">
          <div className="uploadedFiles mb-2">
            {attachment?.map((e) =>
              (isTicketAttachmentDeleted || isTaskAtmDeleted) &&
              fileName === e.name ? (
                <CommonSkeleton />
              ) : (
                <div className="uploadedItem mb-2">
                  {!isImageLoaded[e._id] &&
                    Array.from({
                      length: attachment?.length,
                    }).map((_, i) => (
                      <div key={i}>
                        <CommonSkeleton className="circle-skeleton" />
                      </div>
                    ))}
                  <div className="ico">
                    {["pdf", "xlsx", "docx", "doc", "txt"].includes(
                      e.name?.split(".").pop(),
                    ) ? (
                      <img
                        src={getFileIcon(e.name?.split(".").pop())}
                        alt=""
                        onLoad={() =>
                          setIsImageLoaded((prev) => ({
                            ...prev,
                            [e._id]: true,
                          }))
                        }
                      />
                    ) : (
                      <img
                        src={e.path}
                        alt="imageFile"
                        onLoad={() =>
                          setIsImageLoaded((prev) => ({
                            ...prev,
                            [e._id]: true,
                          }))
                        }
                      />
                    )}
                  </div>
                  <div className="fileDetails">
                    <span className="fileName">{e.name}</span>
                    <span className="fileSize">
                      {convertBytesToKBMB(e?.size)}
                    </span>
                  </div>

                  <div className="btn-group">
                    <p>
                      Added on {new Date(e?.date)?.toLocaleDateString()}{" "}
                      {new Date(e?.date)?.toLocaleTimeString()}
                    </p>
                    {!newTab ? (
                      <Link
                        to={e.path}
                        className="btn btn-download"
                        download
                        onClick={() => handleFileDownload(e)}
                      >
                        {downloadingFile === e ? (
                          <span className="btn-loader m-0">
                            <span className="spinner-border spinner-border-sm m-0" />
                          </span>
                        ) : (
                          <img src={DownloadIcon} alt="" />
                        )}
                      </Link>
                    ) : (
                      <img src={DownloadIcon} alt="" />
                    )}
                    {hasPermission(
                      permissionConstants.permissions
                        .TASK_DETAILS_MODAL_ATTACHMENT_DELETE,
                    ) && (
                      <button
                        className="btn btn-remove"
                        type="button"
                        onClick={() => {
                          if (!newTab) {
                            setFile(e._id);
                            setFileName(e.name);
                          }
                        }}
                      >
                        <img src={deleteIcon} alt="" style={{ height: 13 }} />
                      </button>
                    )}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>

        <CustomModal
          className="modal fade delete-conf"
          dialgName="modal-dialog-centered modal-lg"
          header={renderHeader()}
          footer={renderFooter()}
          bodyClassname="modal-body"
          createModal={7}
          disableCenter={7}
          show={file}
          closeModal={() => {
            setFile("");
          }}
        />
      </>
    )
  );
};
FileAttachment.propTypes = {
  attachment: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      size: PropTypes.string,
    }),
  ),
  id: PropTypes.string,
  ticket: PropTypes.string,
  selectedFile: PropTypes.bool,
  newTab: PropTypes.bool,
  hasPermission: PropTypes.func,
};
export default FileAttachment;
