import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  ArrowRightIco,
  PortfolioIco,
  ProjManageIco,
  ProposalIco,
  SearchIco,
} from "../svgIcons";
import { iconsRender } from "../utils";
import AddSprintPopUp from "./AddSprintPopUp";

const ProjectTile = ({
  id,
  children,
  name,
  folders,
  shortCode,
  projectIndex,
  toggleState,
  setToggleState,
  lists,
  sprint,
  switchModal,
  modalSwitch,
  filterEachProject,
  eachProjectSearchInput,
  setEachProjectSearchInput,
}) => {
  const iconsArray = iconsRender([ProposalIco, PortfolioIco, ProjManageIco]);
  const [projectForSprint, setProject] = useState("");
  const { shortCode: projectCode } = useParams();

  useEffect(() => {
    filterEachProject(eachProjectSearchInput[id]?.value, id);
  }, [eachProjectSearchInput]);
  return (
    <div key={name} className="sub1_menuLink d-flex align-items-center">
      <div
        className={shortCode === projectCode ? "projName active" : "projName"}
      >
        <div
          type="button"
          className="projLink"
          onClick={() => {
            setToggleState(projectIndex.toString());
          }}
        >
          <span className="icon">{iconsArray[projectIndex]()}</span>
          <Link to={`/project/${shortCode}`} className="txt">
            {name}
          </Link>
          <span
            className="search projSearch"
            onClick={(e) => {
              if (!toggleState.find((e) => e === projectIndex.toString()))
                setToggleState(projectIndex.toString());
              setEachProjectSearchInput({
                [id]: {
                  show: !toggleState.find((e) => e === projectIndex.toString())
                    ? true
                    : !eachProjectSearchInput[id]?.show,
                  value: "",
                },
              });
              e.stopPropagation();
            }}
          >
            <SearchIco />
          </span>
          <span
            className={
              (!!folders.length || !!lists?.length || !!sprint?.length) &&
              toggleState.includes(projectIndex.toString())
                ? "dropdIcon open"
                : "dropdIcon"
            }
          >
            {(!!folders.length || !!lists?.length || !!sprint?.length) && (
              <ArrowRightIco />
            )}
          </span>
        </div>
        <AddSprintPopUp
          key={id}
          switchModal={switchModal}
          modalSwitch={modalSwitch}
          selectedProject={id}
          showList
          showFolder
          showSprint
          projectForSprint={projectForSprint}
          setProject={setProject}
          shortCode={shortCode}
        />
      </div>
      {eachProjectSearchInput[id]?.show && (
        <div className="sidenavSearch">
          <div className="searchOuter">
            <div className="ico">
              <SearchIco />
            </div>
            <input
              value={eachProjectSearchInput[id]?.value ?? ""}
              onChange={({ target: { value } }) => {
                setEachProjectSearchInput({
                  [id]: { ...eachProjectSearchInput[id], value },
                });
              }}
              className="form-control"
              placeholder="search list/folders"
            />
          </div>
        </div>
      )}

      {/* submenu Level2  */}
      <div
        className={
          (!!folders.length || !!lists?.length || !!sprint?.length) &&
          toggleState.includes(projectIndex.toString())
            ? "submenu2"
            : "submenu2 collapse"
        }
        id="projManagement"
      >
        <div className="sub2">{children}</div>
      </div>
    </div>
  );
};

ProjectTile.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  folders: PropTypes.arrayOf(PropTypes.shape()),
  lists: PropTypes.arrayOf(PropTypes.shape()),
  sprint: PropTypes.arrayOf(PropTypes.shape()),
  name: PropTypes.string,
  shortCode: PropTypes.string,
  projectIndex: PropTypes.number,
  toggleState: PropTypes.arrayOf(PropTypes.string),
  setToggleState: PropTypes.func,
  switchModal: PropTypes.func,
  filterEachProject: PropTypes.func,
  modalSwitch: PropTypes.string,
  eachProjectSearchInput: PropTypes.node,
  setEachProjectSearchInput: PropTypes.func,
};

export default ProjectTile;
