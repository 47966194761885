import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { AddIco, DeleteIcon, Pencil, ThreeDotIco } from "../svgIcons";
import AddListOption from "./AddListOption";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

const EditPopup = ({
  toggleInput,
  deleteFolder,
  addListOnSubmit,
  uploadFunction,
  showAdd,
  showEdit,
  showDelete,
  showUpload,
  onCreateListLoading,
}) => {
  const [showAddList, setShowAddList] = useState(false);
  const [listName, setListName] = useState("");
  const { listCreateSuccessMessage } = useSelector(({ project }) => project);

  const handleChange = (key, value) => {
    if (key === "listName") setListName(value);
  };

  useEffect(() => {
    if (listCreateSuccessMessage) {
      setListName("");
    }
  }, [listCreateSuccessMessage]);

  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.BASIC_PROJECT_SETTINGS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  return (showAdd &&
    hasPermission(permissionConstants.permissions.PROJECT_LIST_CREATE)) ||
    (showUpload &&
      hasPermission(
        permissionConstants.permissions.PROJECT_LIST_UPLOAD_SHEET,
      )) ||
    (showEdit &&
      hasPermission(permissionConstants.permissions.PROJECT_FOLDER_RENAME)) ||
    (showDelete &&
      hasPermission(permissionConstants.permissions.PROJECT_FOLDER_DELETE)) ? (
    <Dropdown
      onToggle={() => {
        setShowAddList(false);
        setListName("");
      }}
      bsPrefix="add-sprint"
      autoClose="outside"
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <ThreeDotIco />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {showAdd &&
          hasPermission(
            permissionConstants.permissions.PROJECT_LIST_CREATE,
          ) && (
            <Dropdown.Item
              onClick={() => {
                setShowAddList(!showAddList);
                setListName("");
              }}
            >
              <span className="pe-2">
                <AddIco />
              </span>
              <span>Add List</span>
            </Dropdown.Item>
          )}
        {showUpload &&
          hasPermission(
            permissionConstants.permissions.PROJECT_LIST_UPLOAD_SHEET,
          ) && (
            <Dropdown.Item onClick={() => uploadFunction && uploadFunction()}>
              <span className="pe-2">
                <AddIco />
              </span>
              <span>Upload Sheet</span>
            </Dropdown.Item>
          )}
        {showEdit &&
          hasPermission(
            permissionConstants.permissions.PROJECT_FOLDER_RENAME,
          ) && (
            <Dropdown.Item
              onClick={() => {
                document.querySelector(".add-sprint").click();
                toggleInput();
              }}
            >
              <span className="pe-2">
                <Pencil />
              </span>
              Rename
            </Dropdown.Item>
          )}
        {showDelete &&
          hasPermission(
            permissionConstants.permissions.PROJECT_FOLDER_DELETE,
          ) && (
            <Dropdown.Item
              onClick={() => {
                document.querySelector(".add-sprint").click();
                deleteFolder();
              }}
            >
              <span className="pe-2 text-danger">
                <DeleteIcon />
              </span>
              <span className="text-danger">Delete</span>
            </Dropdown.Item>
          )}
        {showAddList && (
          <AddListOption
            handleChange={handleChange}
            value={listName}
            onSubmit={() => {
              addListOnSubmit(listName);
            }}
            loading={onCreateListLoading}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

EditPopup.propTypes = {
  toggleInput: PropTypes.func,
  deleteFolder: PropTypes.func,
  addListOnSubmit: PropTypes.func,
  uploadFunction: PropTypes.func,
  showAdd: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  showUpload: PropTypes.bool,
  onCreateListLoading: PropTypes.bool,
};

export default EditPopup;
