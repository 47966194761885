/* eslint-disable no-unused-vars */
import RcGantt, { enUS } from "rc-gantt";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

import "../../../../design/custom/pages/ganttChartComponents.scss";

import RenderGanttData from "./RenderGantt";

import { getTicketAllocations } from "../../../../store/slices/ticket/thunks/getTicketAllocations";
import { patchTicketAllocation } from "../../../../store/slices/ticket/thunks/patchTicketAllocation";
import { useThunk } from "../../../../hooks/useThunk";
import SubHeader from "../../../../structure/SubHeader/SubHeader";
import { useFilter } from "../../../../context/useFilter";
import { getFilterDropdown } from "../../../../store/slices/filter/thunks/getFilterDropdown";
import BulkActions from "../../../../components/BulkActions/BulkActions";
import { filterQuery } from "./filter";
import CommonSkeleton from "../../../../components/SkeletonLoader/CommonSkeleton";
import useElementScrollPosition from "./hooks/scroll";
import {
  setTicketAllocationData,
  setTicketAllocationParams,
} from "../../../../store/slices/ticket";

const Gantt = () => {
  const {
    ticketStatus,
    ticketAllocations,
    allocationKey,
    ticketAllocationParams,
  } = useSelector(({ ticketReducer }) => ticketReducer);

  const { colorData } = useSelector((state) => state.colors);

  const dispatch = useDispatch();
  const { filter } = useFilter();
  const [selectedTicket, setSelectedTicket, setShowModal, ticket, isTicket] =
    useOutletContext();
  const { listId, type } = useParams();
  const [showStatusPopUp, setShowStatusPopUp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const initialParams = {
    [type === "sprint" ? "sprintId" : "listId"]: listId,
  };
  const [params, setParams] = useState(initialParams);
  const [unit, setUnit] = useState("day");
  const [ganttTicketData, setTicketData] = useState([
    {
      title: "No Data Found",
      renderTitle: <div className="no-data-text">No Data Found</div>,
      disabled: true,
    },
  ]);
  const scroll = useElementScrollPosition("gantt-body");

  const [fetchTicketThunk, isLoading] = useThunk(getTicketAllocations);
  const [patchTicketThunk] = useThunk(patchTicketAllocation);
  const [filterData] = useThunk(getFilterDropdown);
  const [searchParams, setSearchParams] = useSearchParams();
  const { userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    filterData("gantt");
  }, []);

  useEffect(() => {
    dispatch(setTicketAllocationData({ data: [], reset: true }));
    dispatch(setTicketAllocationParams({ page: 1, limit: 20 }));
  }, [listId, filter.search, filter.me, filter.assignee, params.matches]);

  useEffect(() => {
    if (ticketAllocationParams.page === 1)
      dispatch(setTicketAllocationData({ data: [], reset: true }));
    fetchTicketThunk({
      params: { ...ticketAllocationParams, ...params },
      key: "gantt",
    });
  }, [params, ticketAllocationParams.page]);

  useEffect(() => {
    if (scroll >= 0 && scroll <= 1 && !isLoading) {
      dispatch(
        setTicketAllocationParams({
          ...ticketAllocationParams,
          page: ticketAllocationParams.page + 1,
        }),
      );
    }
  }, [scroll]);

  const matches = filterQuery(filter.filter);
  const previousMatchesRef = useRef(matches);

  useEffect(() => {
    if (!isEqual(matches, previousMatchesRef.current)) {
      setParams({ ...params, matches });
      previousMatchesRef.current = matches;
    }
  }, [matches]);

  useEffect(() => {
    let updatedParams = { ...params };
    if (filter.me) updatedParams = { ...updatedParams, me: filter.me };
    else if (updatedParams.me) {
      const { me, ...rest } = updatedParams;
      updatedParams = rest;
    }

    if (filter.search)
      updatedParams = { ...updatedParams, title: filter.search };
    else if (updatedParams.title) {
      const { title, ...rest } = updatedParams;
      updatedParams = rest;
    }

    if (filter.assignee)
      updatedParams = { ...updatedParams, assignee: filter.assignee };
    else if (updatedParams.assignee) {
      const { assignee, ...rest } = updatedParams;
      updatedParams = rest;
    }

    setParams(updatedParams);
  }, [filter.search, filter.me, filter.assignee]);

  const formattedData =
    ticketAllocations &&
    allocationKey === "gantt" &&
    ticketAllocations.reduce((acc, each) => {
      const { listInfo, sprintData } = each;
      const existingGroup = acc.find((group) =>
        listInfo && listInfo.name
          ? group.list === listInfo?.name
          : group.list === sprintData[0]?.name,
      );
      if (existingGroup) {
        existingGroup.tickets.push(each);
      } else {
        acc.push({
          list: listInfo && listInfo.name ? listInfo.name : sprintData[0].name,
          tickets: [each],
        });
      }
      return acc;
    }, []);

  const FindStatusColor = (statusId) => {
    if (ticketStatus && ticketStatus.status.length && statusId) {
      const status = ticketStatus.status.find(({ _id }) => _id === statusId);
      return status && status.colorInfo;
    }
  };

  const updateTaskStatus = (id, patchData) => {
    patchTicketThunk({ id, patchData });
  };

  // To remove the chinese text that displays up on render
  useEffect(() => {
    if (!ticketAllocations || ticketAllocations?.length === 0) {
      const parentElement = document.querySelector(".gantt-table-body");
      const childrenElement = parentElement?.children[0];
      if (childrenElement) childrenElement.style.display = "none";
    }
  }, [ticketAllocations, formattedData]);

  useEffect(() => {
    const formattedRenderData = RenderGanttData({
      formattedData,
      showStatusPopUp,
      setShowStatusPopUp,
      ticketStatus: ticketStatus ? ticketStatus.status : [],
      selectedRows,
      setSelectedRows,
      FindStatusColor,
      updateTaskStatus,
      setSelectedTicket,
      userProfile,
      isTicket,
    });
    if (formattedRenderData) setTicketData(formattedRenderData);
  }, [showStatusPopUp, selectedRows, ticketAllocations, ticketStatus]);

  useEffect(() => {
    if (selectedTicket) {
      if (ticket) {
        setSearchParams({ Task: selectedTicket });
      } else {
        setSearchParams({ TicketAllocationId: selectedTicket });
      }
      setShowModal(true);
    }
  }, [selectedTicket]);

  const onUpdate = async (row, startDate, endDate) => {
    if (row.children || row.title === "No Data Found") {
      const updatedTickets = [...ganttTicketData];
      setTicketData(updatedTickets);
    } else {
      startDate = startDate.substring(0, 10);
      endDate = endDate.substring(0, 10);
      const updatedTickets = ganttTicketData.map((eachTicket) => {
        const title =
          row.listInfo && row.listInfo.name
            ? row.listInfo.name
            : row.sprintData[0].name;
        if (eachTicket?.title === title) {
          return {
            ...eachTicket,
            children: eachTicket.children.map((each) =>
              row._id === each._id ? { ...each, startDate, endDate } : each,
            ),
          };
        }
        return eachTicket;
      });

      setTicketData(updatedTickets);
      patchTicketThunk({
        id: row._id,
        patchData: {
          startDate,
          dueDate: endDate,
        },
      });
    }
    return true;
  };

  const closeAction = () => {
    setSelectedRows([]);
  };

  const getFirstLetters = (name) => {
    const words = name.split(" ");

    if (words.length >= 2) {
      const firstLetters = words.slice(0, 2).map((word) => word[0]);
      return firstLetters.join("").toUpperCase();
    }
    if (words.length === 1) {
      return words[0][0].toUpperCase();
    }
    return "";
  };

  const selectedItems =
    ticketAllocations &&
    ticketAllocations.filter((each) =>
      selectedRows.some((eachRow) => eachRow === each._id),
    );

  const renderBar = (barInfo, { width }) => {
    const {
      ticketStatus: status,
      title,
      estimationHours,
      assignedUsers,
    } = barInfo.record;
    return (
      <div className={width < 150 ? "gcTktWrp gcTicket-min" : "gcTktWrp"}>
        <div
          className="completed gcBG"
          style={{ width, backgroundColor: FindStatusColor(status) }}
        >
          &nbsp;
        </div>
        <div className="gcTicket" style={{ left: `${width}px`, width }}>
          <span className="usr">
            {assignedUsers &&
              assignedUsers.map((eachUser) =>
                eachUser.photo ? (
                  <span className="profTxt">
                    <img
                      src={`https://erptest.spericorn.com/repo/uploads/${eachUser.photo}`}
                      alt="profile-pic"
                    />
                  </span>
                ) : (
                  <span
                    className="profTxt"
                    style={{
                      backgroundColor: colorData?.find(
                        (e) => e._id === eachUser?.primaryColor,
                      )?.colorCode,
                    }}
                  >
                    {getFirstLetters(eachUser.employeeName)}
                  </span>
                ),
              )}
          </span>
          <span className="txt">{title}</span>
          <span
            className={`${
              ticketStatus &&
              ticketStatus.status?.find(({ _id }) => _id === status)?.title
            } count`}
          >
            {estimationHours}
          </span>
        </div>
      </div>
    );
  };

  function addSkeletonToGanttData(data) {
    return data.map((e) => {
      if (
        e.children &&
        !e.children.some((each) => each?.isLoaderComponent === true)
      ) {
        e.children = [
          ...e.children,
          ...Array.from({ length: 5 }, () => ({
            title: "No Data Found",
            renderTitle: <CommonSkeleton />,
            isLoaderComponent: true,
          })),
        ];
      }
      return e;
    });
  }

  function createNoDataFoundSkeleton() {
    return [
      {
        title: "No Data Found",
        renderTitle: <CommonSkeleton />,
        children: Array.from({ length: 20 }, () => ({
          title: "No Data Found",
          renderTitle: <CommonSkeleton />,
        })),
        disabled: true,
      },
    ];
  }

  const renderData = () => {
    if (isLoading) {
      if (ticketAllocations && ticketAllocations.length) {
        return addSkeletonToGanttData(ganttTicketData);
      }
      return createNoDataFoundSkeleton();
    }
    return ganttTicketData;
  };

  return (
    <>
      <BulkActions
        selectedTasks={selectedRows}
        closeAction={closeAction}
        showAssignee
        showCopy
        showDelete
        showDueDate
        showMoveSubtask
        showPriority
        showStatus
      />
      <SubHeader
        showFilter
        showSearchBar
        showAssignee
        showMe
        showGanttSelect
        setUnit={setUnit}
      />

      <div className="gc-cover" onClick={() => setShowStatusPopUp(null)}>
        <div className="gc-container">
          <RcGantt
            renderGroupBar={() => null}
            locale={enUS}
            renderLeftText={() => null}
            renderRightText={() => null}
            renderBar={renderBar}
            showUnitSwitch={false}
            unit={unit}
            data={renderData()}
            columns={[
              {
                name: "renderTitle",
                label: <div className="chartTitle">Name</div>,
                width: 100,
              },
            ]}
            rowHeight={40}
            onUpdate={onUpdate}
            showBackToday
          />
        </div>
      </div>
    </>
  );
};

export default Gantt;
