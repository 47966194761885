// eslint-disable-next-line import/no-cycle
import pmstestGateway from "../config/service";

export const errorNotify = [
  "VALIDATION_ERROR",
  "INCORRECT_PASSWORD",
  "INVALID_EMAIL",
  "EMAIL_NOT_FOUN",
];
export const ResponseApiConfig = async (
  rejectWithValue,
  endPoint,
  method,
  body = {},
  headers = {},
) => {
  try {
    const response = await pmstestGateway[method](endPoint, body, {
      headers,
    });
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(response.data);
    }
    return response?.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 400
    ) {
      return rejectWithValue(error.response.data);
    }
    throw error;
  }
};

// export const getInitials = (fullName) => {
//   const nameParts = fullName?.split(" ");
//   const firstName = nameParts[0];
//   const lastName = nameParts[1];
//   const initials = (
//     firstName.charAt(0) + (lastName ? lastName.charAt(0) : "")
//   ).toUpperCase();
//   return initials;
// };
export const getInitials = (fullName) => {
  const nameParts = fullName?.split(" ");
  if (nameParts && nameParts.length > 0) {
    const firstName = nameParts[0];
    const lastName = nameParts[1];
    const initials = (
      firstName.charAt(0) + (lastName ? lastName.charAt(0) : "")
    ).toUpperCase();
    return initials;
  }
};
export function formatDateStructure(dateString) {
  const dateObj = new Date(dateString);
  const day = dateObj.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    dateObj,
  );
  const year = dateObj.getFullYear();
  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  const amPM = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12;
  const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes
    .toString()
    .padStart(2, "0")} ${amPM}`;
  return formattedDate;
}

export const getFirstLetters = (name) => {
  const words = name?.split(" ");

  if (words?.length >= 2) {
    const firstLetters = words?.slice(0, 2)?.map((word) => word[0]);
    return firstLetters?.join("")?.toUpperCase();
  }
  if (words?.length === 1) {
    return words[0][0]?.toUpperCase();
  }
  return "";
};

export const extractSprintName = (inputString) => {
  const match = inputString.match(/Sprint \d+/);
  return match[0];
};

export const parseStringWithSpans = (inputString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(inputString, "text/html");

  const notificationText = doc.body.textContent.trim();

  const spanElements = doc.querySelectorAll("span");
  const spanData = [];

  spanElements.forEach((spanElement) => {
    const spanContent = spanElement.textContent;
    const dataAttributes = {};

    // Get all data attributes of the span element
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < spanElement.attributes.length; i++) {
      const attribute = spanElement.attributes[i];
      dataAttributes[attribute.name] = attribute.value;
    }

    spanData.push({
      content: spanContent,
      payload: dataAttributes,
    });
  });

  return {
    notificationText,
    spans: spanData,
  };
};
