import { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { AddSprintIcon } from "../svgIcons";
import EditPopup from "./EditPopup";
import { useThunk } from "../../../hooks/useThunk";
import { getAllTechnologies } from "../../../store";
import UploadSheetModal from "../../../pages/uploadSheet/index";
import { sprintDelete } from "../../../store/slices/sprint/thunks/deleteSprint";
import { ticketDocs } from "../../../store/slices/ticket/thunks/getTicketDocs";

const SprintTile = ({
  shortCode,
  eachSprint,
  projectId,
  sprintId,
  sprintName,
}) => {
  const { listId } = useParams();
  const currentDate = moment(); // current date and time
  const dateRange = eachSprint?.name?.split(/\(|\)/)[1];
  let sprintStart = "";

  if (dateRange) {
    sprintStart = dateRange.split("/");
    sprintStart = moment(sprintStart?.[0], "DD-MM-YYYY").startOf("day");
  }
  const navigate = useNavigate();

  let buttonClass = "listIco";
  if (eachSprint?.status === "Completed") {
    buttonClass += " done";
  } else if (
    (currentDate.isSameOrAfter(sprintStart) && eachSprint?.active === true) ||
    eachSprint?.active === true
  ) {
    buttonClass += " inProg";
  } else {
    buttonClass += " notStarted";
  }
  const [isUploadSheetModalOpen, setIsUploadSheetModalOpen] = useState(false);
  const [technologyGet] = useThunk(getAllTechnologies);
  const [deleteSprint] = useThunk(sprintDelete);
  const [TicketDocsGet, isGetTicketDocs] = useThunk(ticketDocs);

  const toggleUploadSheetModal = () => {
    setIsUploadSheetModalOpen(!isUploadSheetModalOpen);
    technologyGet(projectId);
    TicketDocsGet({ projectId, sprintId });
  };

  return (
    <li
      type="button"
      className={`d-flex align-items-center ${
        sprintId === listId ? "active" : ""
      }`}
      key={eachSprint.name}
    >
      <NavLink
        style={{ all: "unset" }}
        to={`project/${shortCode}/sprint/${eachSprint._id}`}
      >
        <span className={buttonClass}>
          <AddSprintIcon />
        </span>
        <span className="txt">{eachSprint.name?.split("(")[0]}</span>
      </NavLink>
      <span className="actions">
        <span className="list-count">{eachSprint?.ticketCounts}</span>
        <EditPopup
          showUpload={eachSprint?.status !== "Completed"}
          showDelete
          uploadFunction={() => {
            toggleUploadSheetModal();
          }}
          deleteFolder={() => {
            deleteSprint(eachSprint._id);
            navigate(`project/${shortCode}`);
          }}
        />
      </span>
      {isUploadSheetModalOpen && (
        <UploadSheetModal
          setIsUploadSheetModalOpen={() => setIsUploadSheetModalOpen("")}
          projectId={projectId}
          sprintId={sprintId}
          sprintName={sprintName}
          isGetTicketDocs={isGetTicketDocs}
        />
      )}
    </li>
  );
};

SprintTile.propTypes = {
  shortCode: PropTypes.string,
  projectId: PropTypes.string,
  sprintId: PropTypes.string,
  sprintName: PropTypes.string,
  eachSprint: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
    status: PropTypes.string,
    active: PropTypes.bool,
    ticketCounts: PropTypes.number,
  }),
};

export default SprintTile;
