import PropTypes from "prop-types";
import classNames from "classnames";

function Button({
  children,
  primary,
  success,
  outline,
  as: Element,
  type,
  ...rest
}) {
  const classes = classNames(rest.className, "btn", {
    "btn-primary": primary,
    "btn-success": success,
    "btn-outline": outline,
  });

  return (
    <Element {...rest} type={type} className={classes}>
      {children}
    </Element>
  );
}

Button.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  primary: PropTypes.bool,
  outline: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"]), // Define the type prop
};

Button.defaultProps = {
  as: "button",
  type: "button",
};

export default Button;

// usuage
// <Button
// className="dropdown-toggle-btn dropdown-toggle"   give if any additional classname or style required then give like this
// data-bs-toggle="dropdown"
// >
// Add
// </Button>
