/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-globals */
import { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../design/custom/pages/feed.scss";
import "../../design/custom/pages/integrations.scss";
import { Dropdown } from "react-bootstrap";
import BlogSection from "./blogSection";
import PostList from "../../components/Feed/feedPost";
import { PhotoIcon, PublicIcon } from "./svg";
import { useThunk } from "../../hooks/useThunk";
import { postFeed, getFeed, savedFeedOfUser } from "../../store/index";
import { withTitle } from "../../hoc/withTitle";
import close from "../../assets/images/close-01.svg";
import { patchFeed } from "../../store/slices/feed/thunks/patchFeed";
import { deleteFeed } from "../../store/slices/feed/thunks/deleteFeed";
import ViewAll from "./ViewAll";
import UserMention from "../../components/Feed/userMention";
import { findUserWithVariations } from "../../components/Feed/Common";
import { getAllUsers } from "../../store/slices/common/thunks/getUsers";
import assignedUser from "../../assets/images/tags.svg";
import {
  Pagination,
  getFeeds,
  setSuccessMessage,
} from "../../store/slices/feed";
import useElementScrollPosition from "../../components/Feed/scroll";
import { getFirstLetters } from "../../utils/utils";
import SubHeader from "../../structure/SubHeader/SubHeader";
import { useFilter } from "../../context/useFilter";
import { filterQuery } from "./filter";
import { getFilterDropdown } from "../../store/slices/filter/thunks/getFilterDropdown";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

const FeedPost = () => {
  const [selectedOption, setSelectedOption] = useState({
    isPublic: true,
    projectId: [],
  });
  const [feedPatch, isPatch] = useThunk(patchFeed);
  const [feedPost, isPost] = useThunk(postFeed);
  const { filter } = useFilter();
  const { feedList, successMessage, pagination } = useSelector(
    (state) => state.feed,
  );
  const [feedGet, isGet] = useThunk(getFeed);
  const [savedFeedAction, isSaved] = useThunk(savedFeedOfUser);
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const { authData } = useSelector((state) => state.auth);
  const { projectData } = useSelector(({ project }) => project);
  const [feedDelete] = useThunk(deleteFeed);
  const [showComment, setShowComment] = useState(false);

  const [selectDropdownOption, setSelectDropdownOption] = useState({
    type: "",
    id: "",
  });
  const [atPressed, setAtPressed] = useState(false);
  const [postFooter, setPostFooter] = useState(false);
  const { allUsers } = useSelector((state) => state.common);
  const [viewAll, setView] = useState(false);
  const [atText, setAtText] = useState("");
  const [users, isUserGet] = useThunk(getAllUsers);
  const scroll = useElementScrollPosition("feed-page-wrp", ".feeds-left");
  const dispatch = useDispatch();
  const matches = filterQuery(filter.filter);
  const [params, setParams] = useState([]);
  const [filterData] = useThunk(getFilterDropdown);
  const stateUpdate = useCallback(
    matches && matches[Object.keys(matches)[0]].map(({ value }) => value),
    [filter.filter],
  );
  const { userProfile } = useSelector((state) => state.userProfile);
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.FEED,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  useEffect(() => {
    setParams({ ...params, matches });
  }, [stateUpdate]);
  useEffect(() => {
    filterData("feed");
  }, []);
  useEffect(() => {
    dispatch(getFeeds({ data: [], reset: true }));
    dispatch(Pagination({ page: 1, limit: 20 }));
  }, [filter.search, filter.me, filter.assignee, params.matches]);

  useEffect(() => {
    let updatedParams = { ...params };
    if (filter.me) updatedParams = { ...updatedParams, me: filter.me };
    else if (updatedParams.me) {
      const { me, ...rest } = updatedParams;
      updatedParams = rest;
    }
    if (filter.search) {
      updatedParams = { ...updatedParams, search: filter.search };
    }
    setParams(updatedParams);
  }, [filter.search, filter.me, filter.assignee]);

  useEffect(() => {
    if (!filter.search || !filter.search.trim().length) {
      delete params.search;
    }
    feedGet({
      params: {
        page: 1,
        limit: 5,
        ...params,
      },
      reset: true,
    });
  }, [params]);

  useEffect(() => {
    users();
  }, []);
  useEffect(() => {
    if (authData?._id) {
      savedFeedAction({ params: authData?._id });
    }
  }, [authData]);
  console.log("successMessage", successMessage);
  useEffect(() => {
    if (successMessage) {
      setPostFooter(false);
    }
  }, [successMessage]);
  useEffect(() => {
    if (authData) {
      if (
        typeof pagination?.page === "number" &&
        !isNaN(pagination?.page) &&
        scroll !== null
      ) {
        feedGet({
          params: {
            ...pagination,
            userId: authData?._id,
            ...params,
          },
          reset: pagination?.page === 1,
        });
      } else {
        feedGet({
          params: {
            page: 1,
            limit: 5,
            userId: authData?._id,
            ...params,
          },
          reset: pagination?.page === 1,
        });
      }
    }
  }, [pagination?.page, authData]);
  useEffect(() => {
    if (atPressed) {
      const lastIndex = title.lastIndexOf("@");
      if (lastIndex >= 0) {
        const textAfterAt = title.slice(lastIndex + 1);
        if (textAfterAt.includes(" ")) {
          setAtText("");
        } else {
          setAtText(textAfterAt);
        }
      }
    }
  }, [title, atPressed]);
  console.log(
    scroll >= 0,
    scroll <= 1,
    scroll !== null,
    !isGet,
    feedList?.data?.updatedData?.length < pagination.total,
  );
  useEffect(() => {
    if (
      scroll <= 1 &&
      scroll !== null &&
      !isGet &&
      feedList?.data?.updatedData?.length < pagination.total
    ) {
      dispatch(
        Pagination({
          data: {
            ...pagination,
            page: pagination?.page + 1,
          },
        }),
      );
    }
  }, [scroll]);
  const handleSelect = (eventKey) => {
    if (eventKey === "true") {
      setSelectedOption({ isPublic: true, projectId: [] });
    } else {
      setSelectedOption({ isPublic: false, projectId: [eventKey] });
    }
  };
  const handleImageChange = (e) => {
    const fileArray = Array.from(e.target.files);
    const selectedImages = fileArray.filter((file) =>
      file.type?.startsWith("image/"),
    );

    if (selectedImages.length > 0) {
      const imageUrls = selectedImages.map((image) => image);
      setImages([...images, ...imageUrls]);
      setError("");
    } else {
      setError("Please select valid image files (JPEG, PNG, GIF).");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const imageValue = Array.from(images);

    imageValue.forEach((value) => {
      formData.append("images", value);
    });
    const usernamePattern = /@(\w+(\s\w+){0,2})/g;
    const mentionedUserIds = [];
    let modifiedMessage = title;
    const matches = title.match(usernamePattern);
    if (matches) {
      // eslint-disable-next-line no-restricted-syntax
      for (const match of matches) {
        const mentionedUsername = match.substr(1);

        const mentionedUser = findUserWithVariations(
          mentionedUsername,
          allUsers,
        );

        if (mentionedUser) {
          mentionedUserIds.push(mentionedUser._id);

          modifiedMessage = modifiedMessage.replace(
            new RegExp(`@${mentionedUser?.employeeName}`, "g"),
            `{{${mentionedUser._id}}}`,
          );
        }
      }
    }

    formData.append("title", modifiedMessage);
    formData.append("isPublic", selectedOption.isPublic);
    formData.append("projectIds", selectedOption?.projectId);
    feedPost(formData);
    setTitle("");
    setImages([]);
    setError("");
    setSelectedOption({ isPublic: true, projectId: [] });
  };
  const handleContentChange = (e) => {
    setTitle(e.target.value);
  };
  const removeImage = (index) => {
    const newImages = images.filter((_, indexValue) => index !== indexValue);
    setImages(newImages);
  };
  const handlePatch = (feedId, reaction, saved) => {
    const formData = new FormData();
    if (saved) {
      formData.append("feedId", feedId);
      formData.append("saved", saved);
    } else {
      formData.append("emoji", reaction);
      formData.append("userId", authData?._id);
      formData.append("feedId", feedId);
    }
    feedPatch({ formData, isEdit: false, userId: authData?._id });
  };
  const handleDeleteFeed = (feedId) => {
    feedDelete({ feedId, userId: authData?._id });
    setSelectDropdownOption({ type: "", id: "" });
  };
  const handleOnViewClick = () => {
    setView(true);
  };
  return (
    <>
      <SubHeader showMe showSearchBar showFilter />
      {viewAll && (
        <ViewAll
          setView={setView}
          authData={authData}
          hasPermission={hasPermission}
        />
      )}
      <div
        className="page-contents inner-page-contents feed-page-wrp"
        onClick={() => {
          setPostFooter(false);
          setAtPressed(false);
          setShowComment("");
        }}
      >
        <div className="feeds-left custom-scroll">
          {hasPermission(permissionConstants.permissions.FEED_ADD_POST) && (
            <div className="feeds-lists update-status">
              <form onSubmit={handleSubmit}>
                <div className="profile-head">
                  <div className="user-profile">
                    <div className="user-pic">
                      {authData?.photo ? (
                        <div className="user-pic">
                          <img
                            src={`https://erptest.spericorn.com/repo/uploads/${authData?.photo}`}
                            alt="profile-pic"
                          />
                        </div>
                      ) : (
                        <div className="avathar-sm avathar-01">
                          <span>{getFirstLetters(authData?.employeeName)}</span>
                        </div>
                      )}
                    </div>
                    <div className="user-info">
                      <textarea
                        placeholder="What's On Your Mind"
                        className="place-holder-txt"
                        value={title}
                        onChange={handleContentChange}
                        onClick={(e) => {
                          setPostFooter(true);
                          dispatch(setSuccessMessage(false));
                          e.stopPropagation();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "@") {
                            const isAtStartOfWordOrSentence =
                              title
                                .charAt(e.target.selectionStart - 1)
                                .match(/\s/) || e.target.selectionStart === 0;

                            if (isAtStartOfWordOrSentence) {
                              setAtPressed(true);
                              setAtText("");
                            } else {
                              setAtPressed(false);
                            }
                          } else if (atPressed && e.key === " ") {
                            setAtPressed(false);
                          } else if (e.key === "Backspace") {
                            const caretPosition = e.target.selectionStart;
                            const deletedChar = title.charAt(caretPosition - 1);
                            if (deletedChar === "@") {
                              setAtPressed(false);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {postFooter && (
                  <>
                    {images.length > 0 && (
                      <div className="edit-comment-modal">
                        <div className="img-upload-wrp">
                          {images.map((file, index) => (
                            <div className="img-preview" key={index}>
                              <button
                                type="button"
                                className="closeBtn"
                                onClick={(e) => {
                                  removeImage(index);
                                  setPostFooter(true);
                                  e.stopPropagation();
                                }}
                              >
                                <img src={close} alt="close" />
                              </button>
                              <img
                                src={URL.createObjectURL(file)}
                                alt="preview"
                              />
                            </div>
                          ))}

                          {error && <p className="error-message">{error}</p>}
                        </div>
                      </div>
                    )}
                    <div className="status-action-blk">
                      <div className="update-action">
                        <div className="status-action-blk">
                          <div className="update-action">
                            <UserMention
                              dropDownVisibility={atPressed}
                              onAllocateUser={(userId) => {
                                const user = allUsers.find(
                                  (e) => e._id === userId?.user,
                                );
                                if (user && atText) {
                                  const updatedMessage = title.replace(
                                    `@${atText}`,
                                    `@${user?.employeeName}`,
                                  );
                                  setTitle(updatedMessage);
                                  setAtText("");
                                } else {
                                  setTitle((prevMessage) => {
                                    const lastIndex =
                                      prevMessage.lastIndexOf("@");
                                    if (lastIndex !== -1) {
                                      const messageAfterLastAt =
                                        prevMessage.substring(lastIndex + 1);
                                      if (!messageAfterLastAt.includes(" ")) {
                                        const updatedMessage = `${prevMessage.substring(
                                          0,
                                          lastIndex,
                                        )}@${user?.employeeName} `;
                                        return updatedMessage;
                                      }
                                    }

                                    return `${prevMessage}@${
                                      user.employeeName
                                    } ${" "}`;
                                  });
                                  setAtText("");
                                }
                                setAtPressed(false);
                              }}
                              atText={atText}
                            />
                            <button
                              className="status-btn"
                              type="button"
                              onClick={(e) => {
                                setAtPressed(!atPressed);
                                // setShowEmojiPicker(false);
                                setAtText("");
                                e.stopPropagation();
                              }}
                            >
                              <span className="icon">
                                <img src={assignedUser} alt="" />
                              </span>
                              Tag
                            </button>
                            <button
                              className="status-btn"
                              type="button"
                              onClick={(each) => {
                                fileInputRef.current.click();
                                setPostFooter(true);
                                each.stopPropagation();
                              }}
                            >
                              <span className="icon">
                                <PhotoIcon />
                              </span>
                              Photos
                            </button>
                            {/* Hidden input field */}
                            <input
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={handleImageChange}
                              ref={fileInputRef}
                              style={{ display: "none" }}
                            />

                            <Dropdown
                              onSelect={handleSelect}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Dropdown.Toggle
                                className="status-btn"
                                id="dropdown-basic"
                              >
                                <span className="icon">
                                  <PublicIcon />
                                </span>
                                {projectData &&
                                  projectData.length > 0 &&
                                  (selectedOption.projectId.length > 0
                                    ? projectData.find(
                                        (project) =>
                                          project._id ===
                                          selectedOption.projectId[0],
                                      )?.name
                                    : "Public")}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="drop-menu-bg-sm">
                                <Dropdown.Item eventKey="true">
                                  {/* <span className="icon">
                            <PublicIcon />
                          </span> */}
                                  Public
                                </Dropdown.Item>
                                {projectData &&
                                  projectData.length > 0 &&
                                  projectData.map((e) => (
                                    <Dropdown.Item eventKey={e._id}>
                                      {e.name}
                                    </Dropdown.Item>
                                  ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="share">
                            <button
                              type="submit"
                              className="status-btn"
                              onClick={(e) => {
                                setPostFooter(true);
                                e.stopPropagation();
                              }}
                            >
                              {isPost ? (
                                <span className="btn-loader">
                                  <span className="spinner-border spinner-border-sm" />
                                </span>
                              ) : (
                                "Post"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
              {error && <p className="error-message">{error}</p>}{" "}
            </div>
          )}
          {hasPermission(
            permissionConstants.permissions.FEED_DISPLAY_POSTS,
          ) && (
            <PostList
              listData={feedList}
              isGet={isGet}
              handleLikePatch={handlePatch}
              isPatch={isPatch}
              isFeed
              handleDeleteFeed={handleDeleteFeed}
              setSelectDropdownOption={setSelectDropdownOption}
              selectDropdownOption={selectDropdownOption}
              allUsers={allUsers}
              isPost={isPost}
              isPostSuccess={successMessage}
              type="feed"
              setShowComment={setShowComment}
              showComment={showComment}
              isSaved={isSaved}
              hasPermission={hasPermission}
            />
          )}
        </div>
        <BlogSection
          listData={feedList}
          authData={authData}
          handleOnViewClick={handleOnViewClick}
          isUserGet={isUserGet}
          hasPermission={hasPermission}
        />
      </div>
    </>
  );
};
export default withTitle(FeedPost);
