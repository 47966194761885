import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { commonAction } from "..";
import commonService from "../../../../services/common";

export const getAllUsers = createAsyncThunk(
  "allusers",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { userdata },
      } = await commonService.getAllUsers();
      dispatch(commonAction.setUsers({ userdata }));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
