export const OFF = "OFF";
export const TOP_RIGHT = "TOP_RIGHT";
export const BOTTOM_RIGHT = "BOTTOM_RIGHT";
export const TOP_LEFT = "TOP_LEFT";
export const BOTTOM_LEFT = "BOTTOM_LEFT";

export const FULL_DAY = "24hr";
export const HALF_DAY = "12hr";

export const DATE_MONTH_YEAR = "dd-mm-yyyy";
export const MONTH_DATE_YEAR = "mm-dd-yyyy";
export const YEAR_MONTH_DATE = "yyyy-mm-dd";

export const toastPosition = [
  { label: "Right Top", value: TOP_RIGHT },
  { label: "Right Bottom", value: BOTTOM_RIGHT },
  { label: "Left Top", value: TOP_LEFT },
  { label: "Left Bottom", value: BOTTOM_LEFT },
];

export const timeFormat = [
  { label: "24 hour", value: FULL_DAY },
  { label: "12 hour", value: HALF_DAY },
];

export const dateFormat = [
  { label: DATE_MONTH_YEAR, value: DATE_MONTH_YEAR },
  { label: MONTH_DATE_YEAR, value: MONTH_DATE_YEAR },
  { label: YEAR_MONTH_DATE, value: YEAR_MONTH_DATE },
];

export const timeZones = [
  { value: "Asia/Kolkata", label: "Asia/Kolkata" },
  { value: "French", label: "French" },
  { value: "Chinese", label: "Chinese" },
  { value: "Japanese", label: "Japanese" },
];

export const colors = [
  "orange",
  "yellow",
  "red",
  "dark-red",
  "purple",
  "pink",
  "green",
  "dim-blue",
  "light-blue",
  "sea-green",
  "bright-green",
  "gray-color",
];
