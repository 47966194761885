/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ArrowRight } from "./svgicons";
import {
  getMultyUserProfile,
  getTicketAllocationsById,
  getTicketsById,
} from "../../store";
import { useThunk } from "../../hooks/useThunk";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import { userProfileActions } from "../../store/slices/userProfile";
import { getAllUsers } from "../../store/slices/common/thunks/getUsers";

const Log = ({ task, ticket, NewTab }) => {
  const [visibleLogs, setVisibleLogs] = useState(5);
  const { ticketStatus } = useSelector((state) => state.ticketReducer);
  const { ticketPriority } = useSelector((state) => state.common);
  const [TicketAllocationByIdGet, isTicketAllocationLoader] = useThunk(
    getTicketAllocationsById,
  );
  const [TicketByIdGet, isTicketLoader] = useThunk(getTicketsById);
  const [users] = useThunk(getAllUsers);
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const { authData } = useSelector((state) => state.auth);
  const { allUsers } = useSelector((state) => state.common);
  const { listId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    users();
  }, []);

  const loadMore = () => {
    if (ticket) {
      TicketByIdGet({
        params: task._id,
        listId: { listId, page: 1, limit: visibleLogs + 5 },
      });
    } else {
      TicketAllocationByIdGet({
        params: task._id,
        listId: { listId, page: 1, limit: visibleLogs + 5 },
      });
    }

    setVisibleLogs((prevVisibleLogs) => prevVisibleLogs + 5);
  };

  function formatDate(dateString) {
    if (!dateString) return "";

    const dateObject = new Date(dateString);
    const month = dateObject.toLocaleString("en-US", { month: "short" });
    const day = dateObject.getDate();
    const hour = dateObject.getHours();
    let minute = dateObject.getMinutes();
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

    minute = minute < 10 ? `0${minute}` : minute;

    const formattedDate = `${month} ${day} at ${formattedHour}:${minute} ${period}`;

    return formattedDate;
  }
  const findUserNameById = (userId) => {
    if (userId) {
      const user = allUsers?.find((user) => user?._id === userId);
      return user?.employeeName ?? "null";
    }
    return "";
  };
  const getStatusName = (id) => {
    const statusObject = ticketStatus?.status?.find((e) => e._id === id);
    return statusObject ? statusObject.title : "";
  };
  const getPriorityName = (id) => {
    const filteredPriorities = ticketPriority?.find((item) => item._id === id);
    return filteredPriorities?.title;
  };
  function getUpdateDetails(e) {
    if (e.action?.startsWith("Changed ticketStatus")) {
      return `${e.action} from ${getStatusName(e.oldValue)} to ${getStatusName(
        e.newValue,
      )}`;
    }
    if (e.action === "Changed priority") {
      return `${e.action} from  ${getPriorityName(
        e.oldValue,
      )} to ${getPriorityName(e.newValue)}`;
    }
    if (
      e.action === "Uploaded file" ||
      e.action === "Created checklist" ||
      e.action === "Subtask created" ||
      e.action === "Removed file" ||
      e.action === "Removed checklist"
    ) {
      return `${e.action} ${e.newValue}`;
    }
    if (
      e.action === "Changed title" ||
      e.action === "Changed description" ||
      e.action === "Changed due date" ||
      e.action === "Changed start date" ||
      e.action === "Changed updated" ||
      e.action === "Updated checklist" ||
      e.action === "Changed estimationHours" ||
      e.action?.startsWith("Changed assignedUsers")
    ) {
      if (
        e.oldValue?.length === 0 &&
        e.action?.startsWith("Changed assignedUsers")
      ) {
        return `Assigned to ${e.newValue}`;
      }
      if (e.oldValue === "" || e.oldValue == null) {
        return `${e.action} to ${e.newValue}`;
      }
      if (
        e.newValue.length === 0 &&
        e.action?.startsWith("Changed assignedUsers")
      ) {
        return ` ${e.action} to No allocation`;
      }
      if (e.newValue === "") {
        return ` ${e.action} from ${e.oldValue} to No description`;
      }
      return `${e.action} from ${e.oldValue} to ${e.newValue}`;
    }

    if (e.action === "TimeTracker") {
      const pauseTime = e.newValue[e.newValue.length - 1]?.pauseTime;
      if (e.newValue?.length === 1) {
        if (e.newValue[0]?.pauseTime === null) {
          return `${e.action} started`;
        }
      }
      if (pauseTime === null) {
        return `${e.action} resumed`;
      }
      return `${e.action} paused`;
    }
    return `${e.action} ${
      Array.isArray(e.oldValue)
        ? e.oldValue.map((e) => findUserNameById(e?.user))
        : findUserNameById(e?.oldValue?.user)
    } to ${
      Array.isArray(e.newValue)
        ? e.newValue.map((e) => findUserNameById(e?.user))
        : findUserNameById(e?.newValue?.user)
    }`;
  }
  return (
    <>
      <div className="task-auth-details info-row d-flex">
        <div className="name-det info-col-left">
          Created By{" "}
          {!ticket
            ? task?.createdBy?.employeeName
              ? task?.createdBy?.employeeName
              : task?.createdBy?.[0]?.employeeName
            : findUserNameById(task?.createdBy)}
        </div>
        <div className="date-det info-col-right">
          {formatDate(task?.createdAt)}
        </div>
      </div>
      {isTicketAllocationLoader || isTicketLoader ? (
        <div className="info-updates-cover">
          <div className="info-list-cover">
            {Array.from({ length: visibleLogs }).map((_, i) => (
              <div className="info-list-row info-row d-flex">
                <div className="info-det info-col-left" key={i}>
                  <CommonSkeleton height={20} />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="info-updates-cover">
          {task?.log?.slice(0, visibleLogs).map((e, index) => (
            <div className="info-list-cover" key={index}>
              <div className="info-list-row info-row d-flex">
                <div className="info-det info-col-left">
                  <span
                    className="user"
                    type="button"
                    onClick={(f) => {
                      f.stopPropagation();
                      getMultyUsers(e?.user);
                      dispatch(userProfileActions.setShowUserProfile(true));
                    }}
                  >
                    {e?.user === authData?._id
                      ? "You"
                      : findUserNameById(e?.user)}
                  </span>{" "}
                  {getUpdateDetails(e)}
                </div>
                <div className="date-det info-col-right">
                  {formatDate(e.date)}
                </div>
              </div>
            </div>
          ))}
          {task && task.logLength - visibleLogs >= 1 && (
            <div
              className="updates-title"
              onClick={NewTab !== "newTab" ? loadMore : () => {}}
              style={{ cursor: "pointer" }}
            >
              <ArrowRight />
              <span className="title-text">
                {task && task.logLength - visibleLogs}
                more updates
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

Log.propTypes = {
  task: PropTypes.arrayOf({}),
  ticket: PropTypes.bool,
  NewTab: PropTypes.string,
};

export default Log;
