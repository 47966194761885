import { createAsyncThunk } from "@reduxjs/toolkit";

import { resetReducer } from "../index";

export const resetEmployeeReducer = createAsyncThunk(
  "employee/clear-reducer",
  async function (_, { dispatch }) {
    dispatch(resetReducer());
    try {
      // dispatch(alertActions.success(data.message));
    } catch (error) {
      // const errorMessage = _.isString(error)
      //   ? error
      //   : error.response.data.message;
      // dispatch(cronjobActions.setErrorMesage(errorMessage));
      // dispatch(alertActions.error(errorMessage));
    }
  },
);
