import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import AddEditCronjob from "./AddEditCronjob";
import CustomInfinityTable from "../../../components/CustomInfinityTable";
import { Deletemodal } from "../../../components/DropDowns/DeleteModal";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import deleteIcon from "../../../assets/images/delete_icon_01.svg";
import addIcon from "../../../assets/images/addBtn-White.svg";
import viewIcon from "../../../assets/images/icon-view.svg";
import "../../../design/custom/pages/cron-Job-lists.scss";
import { withTitle } from "../../../hoc/withTitle";
import { useThunk } from "../../../hooks/useThunk";
import { updateCronjob } from "../../../store/slices/cronjob/thunk/updateCronjob";
import { getCronJobLists } from "../../../store/slices/cronjob/thunk/getAllCronjob";
import { deleteCronjob } from "../../../store/slices/cronjob/thunk/deleteCronjob";
import { useFilter } from "../../../context/useFilter";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import { filterQuery } from "./CronFilters/CronListFilter";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

const Cronjob = () => {
  const tooltipRef = useRef(null);

  const [toggle, setToggle] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [onEdit, setOnEdit] = useState(null);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    limit: 15,
  });
  const [deleteModal, setDeleteModal] = useState(null);
  const [sortBy, setSortBy] = useState("title");
  const [sortOrders, setSortOrders] = useState({
    title: "",
    desc: "",
    interval: "",
  });
  const [params, setParams] = useState(null);

  const [cronjobList, isLoading] = useThunk(getCronJobLists);
  const [deleteData, deleteLoader] = useThunk(deleteCronjob);
  const [updateToggle] = useThunk(updateCronjob);
  const { filter } = useFilter();
  const [filterData] = useThunk(getFilterDropdown);

  const { cronjobData, successMessage } = useSelector((state) => state.cronjob);
  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const checkPermission = permissionConstants.permissions;

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.SETUP,
        permissionConstants.submodules.CRONJOB,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  useEffect(() => {
    filterData("cron");
    renderCronjobList();
  }, []);

  useEffect(() => {
    if (successMessage) {
      // setOnEdit(null);
      setShowModal(false);
      setDeleteModal(null);
      setToggle(null);
      renderCronjobList();
    }
  }, [successMessage]);

  useEffect(() => {
    if (params) {
      cronjobList(params);
    }
  }, [params]);

  useEffect(() => {
    renderCronjobList();
  }, [sortBy, sortOrders, filter.search]);

  useEffect(() => {
    if (toggle)
      updateToggle({
        id: toggle.id,
        updatedValue: { isActive: toggle.change },
      });
  }, [toggle]);

  const matches = filterQuery(filter.filter);

  const stateUpdate = useCallback(
    matches && matches[Object.keys(matches)[0]].map(({ value }) => value),
    [filter.filter],
  );
  useEffect(() => {
    if (params && !onEdit) setParams({ ...params, matches });
  }, [stateUpdate]);

  const renderCronjobList = () => {
    setParams({
      page: 1,
      limit: 100,
      sortOrder: sortOrders[sortBy] ? sortOrders[sortBy] : null,
      sortBy: sortOrders[sortBy] ? sortBy : null,
      search: filter.search,
    });
  };

  const setSortingOrder = (selector) => {
    const newSortOrders = { ...sortOrders };

    if (newSortOrders[selector]) {
      newSortOrders[selector] = newSortOrders[selector] === "asc" ? "desc" : "";
    } else {
      newSortOrders[selector] = "asc";
    }

    setSortOrders(newSortOrders);
    setSortBy(selector);
  };

  const renderAction = ({ row }) => (
    <div className="tbl-cont action-controls">
      {/* <div className="swich-otr d-flex align-items-center">
        <div className="span me-2">{row.isActive ? "On" : "Off"}</div>
        <label className="switch">
          <input
            checked={row.isActive}
            onChange={() => setToggle({ id: row._id, change: !row.isActive })}
            type="checkbox"
          />
          <span className="slider round">{}</span>
        </label>
      </div> */}

      {/* button to run cronjob instantly */}
      {/* <button className="table-def-btn btn" type="button">
        <img src={activate} alt="..." />
      </button> */}
      {hasPermission(checkPermission.CRONJOB_VIEW) && (
        <button
          className="table-def-btn btn"
          type="button"
          onClick={() => setOnEdit(row._id)}
        >
          <img className="view_icon" src={viewIcon} alt="..." />
        </button>
      )}
      {hasPermission(checkPermission.CRONJOB_DELETE) && (
        <button
          className="table-def-btn btn"
          type="button"
          onClick={() =>
            setDeleteModal({
              name: row.title,
              docId: row._id,
              openclose: true,
            })
          }
        >
          <img src={deleteIcon} alt="..." />
        </button>
      )}
    </div>
  );

  const renderDateFormat = (row) =>
    row ? moment.utc(row).format("DD/MM/YYYY") : "-";

  const renderInterval = (row) => {
    const intervalMap = {
      0: "ONCE",
      1: "BY MINUTE",
      2: "HOURLY",
      3: "DAILY",
      4: "WEEKLY",
      5: "MONTHLY",
      6: "YEARLY",
    };

    return intervalMap[row] || "";
  };

  const renderTitleWithStatus = (row) => (
    <span className={row.isActive ? "true-line" : "false-line"}>
      {row.title}
    </span>
  );

  const renderDescToolip = (row) =>
    row.length > 20 ? (
      <OverlayTrigger
        placement="top"
        container={tooltipRef}
        overlay={<Tooltip id="cron_desc">{row}</Tooltip>}
      >
        <div className="tbl-cont">{row}</div>
      </OverlayTrigger>
    ) : (
      row
    );

  const cols = [
    {
      name: "Title",
      selector: "title",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      icon: true,
      cell: ({ row }) => renderTitleWithStatus(row),
      permission: hasPermission(checkPermission.CRONJOB_TITLE_LABEL),
    },
    {
      name: "Description",
      selector: "desc",
      activitySort: true,
      titleClasses: "left-txt ps-0 width-001",
      colClassName: "left-txt",
      contentClass: "tbl-cont lg-content",
      icon: true,
      cell: ({ row }) => renderDescToolip(row.desc),
      permission: hasPermission(checkPermission.CRONJOB_DESCRIPTION_LABEL),
    },
    {
      name: "Interval",
      selector: "interval",
      activitySort: true,
      titleClasses: "left-txt ",
      colClassName: "left-txt",
      contentClass: "tbl-cont",
      icon: true,
      cell: ({ row }) => renderInterval(row.interval),
      permission: hasPermission(checkPermission.CRONJOB_INTERVAL_LABEL),
    },
    {
      name: "Start Date",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt",
      contentClass: "tbl-cont",
      cell: ({ row }) => renderDateFormat(row.startDate),
      permission: hasPermission(checkPermission.CRONJOB_STARTDATE_LABEL),
    },
    {
      name: "Next Run Date",
      activitySort: true,
      selector: "nextRunDate",
      titleClasses: "left-txt",
      colClassName: "left-txt",
      contentClass: "tbl-cont",
      cell: ({ row }) => renderDateFormat(row.nextRunDate),
      permission: hasPermission(checkPermission.CRONJOB_NEXTRUNDATE_LABEL),
    },
    {
      name: "Last Run Date",
      activitySort: true,
      selector: "lastRunDate",
      titleClasses: "left-txt",
      colClassName: "left-txt",
      contentClass: "tbl-cont",
      cell: ({ row }) => renderDateFormat(row.lastRunDate),
      permission: hasPermission(checkPermission.CRONJOB_LASTRUNDATE_LABEL),
    },
    {
      name: "Last Error",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt",
      contentClass: "tbl-cont",
      cell: ({ row }) => renderDateFormat(row.lastError),
      permission: hasPermission(checkPermission.CRONJOB_LASTERROR_LABEL),
    },
    {
      name: "Actions",
      activitySort: true,
      selector: "",
      cell: (row) => renderAction(row),
      permission: hasPermission(checkPermission.CRONJOB_ACTIONS),
    },
  ];

  const permissionBasedData = () => cols.filter(({ permission }) => permission);

  return (
    <>
      <SubHeader showFilter showSearchBar showShowBtn showOtherOptions />
      <div className="page-contents inner-page-contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 activity-log-area cron-job-otr">
              <div className="card-box">
                <div className="header-otr d-flex flex-wrap">
                  <div className="title-otr d-flex flex-wrap align-items-center">
                    <small>
                      {hasPermission(checkPermission.CRONJOB_LIST) &&
                        cronjobData?.totalCounts}
                      {"  "}
                      Jobs Listed
                    </small>
                    <div className="title">Cron Job Lists</div>
                  </div>
                  <div className="rgt-scnt">
                    {hasPermission(checkPermission.CRONJOB_ADD) && (
                      <button
                        className="btn-sm"
                        type="button"
                        onClick={() => {
                          setShowModal(!showModal);
                        }}
                      >
                        Add
                        <span>
                          <img src={addIcon} alt="..." />
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <div className="content-tables" ref={tooltipRef}>
                  <CustomInfinityTable
                    tableClassName="table td-center"
                    divClassName="table-responsive custom-scroll "
                    columns={permissionBasedData()}
                    data={
                      hasPermission(checkPermission.CRONJOB_LIST) &&
                      cronjobData?.data
                    }
                    count={cronjobData?.totalCounts}
                    isLoading={isLoading}
                    pagination={paginationData}
                    onPageChange={(currentPage) =>
                      setPaginationData({ ...paginationData, currentPage })
                    }
                    onSortChange={(selector) => {
                      setSortingOrder(selector);
                    }}
                  />
                  <AddEditCronjob
                    showModal={showModal}
                    onEdit={onEdit}
                    toggleChange={({ id, isActive }) => {
                      setToggle({ id, change: isActive });
                    }}
                    closeModal={() => {
                      filterData("cron");
                      setShowModal(false);
                      setOnEdit(null);
                    }}
                  />
                  {deleteModal && (
                    <Deletemodal
                      ModalShow={deleteModal}
                      cancelModal={() => setDeleteModal(null)}
                      deleteFunc={() => deleteData(deleteModal.docId)}
                      closeModal={() => setDeleteModal(null)}
                      loaderButton={deleteLoader}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTitle(Cronjob);
