import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useRef, useState } from "react";
import { AddBtn, AddIcon, UserIcon } from "./svgicons";
import addFill from "../../assets/images/add-fill.svg";
import DeleteModal from "./deleteModal";
import Profile from "./userDetail";
import del from "../../assets/images/delete-01.svg";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

const CheckList = ({
  task,
  ticket,
  updateTicketThunk,
  patchTicketAllocationAction,
  ispatchTicketAllocationAction,
  isUpdateTicketThunk,
  checked,
  setChecked,
  hasPermission,
}) => {
  const [newItemText, setNewItemText] = useState("");
  const [subchecklistInputs, setSubchecklistInputs] = useState({});
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [checklist, setChecklist] = useState({ value: "", type: "" });
  const [addUser, setAddUser] = useState("");
  const [newTitle, setNewTitle] = useState("");

  const handleAllocateUser = (data, e, value) => {
    const updatedTask = { ...task };
    const updatedSubChecklist = updatedTask.checklist.map((item) => {
      if (item._id === e._id) {
        const subList = item.subChecklist.map((subItem) => {
          if (subItem._id === value) {
            let newUserArray = [];
            if (!Array.isArray(subItem.user)) {
              newUserArray.push(subItem.user);
            } else {
              newUserArray = [...subItem.user];
            }

            newUserArray.push(data.user);

            return {
              ...subItem,
              user: newUserArray,
            };
          }
          return subItem;
        });

        return {
          ...item,
          subChecklist: subList,
        };
      }
      return item;
    });
    if (ticket) {
      updateTicketThunk({
        id: task?._id,
        patchData: { checklist: updatedSubChecklist },
      });
    } else {
      patchTicketAllocationAction({
        id: task?._id,
        patchData: {
          checklist: updatedSubChecklist,
        },
      });
    }
  };
  const handleDeallocateUser = (data, e, value) => {
    const updatedTask = { ...task };
    const updatedSubChecklist = updatedTask.checklist.map((item) => {
      if (item._id === e._id) {
        const subList = item.subChecklist.map((subItem) => {
          if (subItem._id === value) {
            let newUserArray = [];
            if (!Array.isArray(subItem.user)) {
              newUserArray.push(subItem.user);
            } else {
              newUserArray = subItem.user?.filter(
                (userId) => userId !== data.user,
              );
            }

            return {
              ...subItem,
              user: newUserArray,
            };
          }
          return subItem;
        });

        return {
          ...item,
          subChecklist: subList,
        };
      }
      return item;
    });
    if (ticket) {
      updateTicketThunk({
        id: task?._id,
        patchData: { checklist: updatedSubChecklist },
      });
    } else {
      patchTicketAllocationAction({
        id: task?._id,
        patchData: {
          checklist: updatedSubChecklist,
        },
      });
    }
  };

  const handleDeleteItemClick = (item, value) => {
    setChecklist({ value, type: "subChecklist" });
    setFileToDelete(item);
    setDeleteModalOpen(true);
  };
  const handleDeleteChecklistItemClick = (value) => {
    setChecklist({ value, type: "checklist" });
    setDeleteModalOpen(true);
  };
  const handleConfirmDeleteItem = () => {
    if (checklist.type === "subChecklist") {
      const updatedTask = { ...task };

      updatedTask.checklist = updatedTask.checklist.map((item) => {
        if (item._id === checklist?.value?._id) {
          const updatedSubChecklist = item.subChecklist?.filter(
            (subItem) => subItem._id !== fileToDelete._id,
          );
          return {
            ...item,
            subChecklist: updatedSubChecklist,
          };
        }
        return item;
      });
      if (ticket) {
        updateTicketThunk({
          id: updatedTask._id,
          patchData: { checklist: updatedTask.checklist },
        });
      } else {
        patchTicketAllocationAction({
          id: task?._id,
          patchData: {
            checklist: updatedTask.checklist,
          },
        });
      }

      setDeleteModalOpen(false);
      setFileToDelete(null);
    } else {
      const updatedTask = { ...task };

      updatedTask.checklist = updatedTask.checklist.filter((item) => {
        return item._id !== checklist?.value;
      });
      if (ticket) {
        updateTicketThunk({
          id: updatedTask._id,
          patchData: { checklist: updatedTask.checklist },
        });
      } else {
        patchTicketAllocationAction({
          id: task?._id,
          patchData: {
            checklist: updatedTask.checklist,
          },
        });
      }

      setDeleteModalOpen(false);
      setFileToDelete(null);
    }
  };

  const handleSubChecklistInputKeyPress = (e, checklistIndex) => {
    if (e.key === "Enter" || e._reactName === "onBlur") {
      e.preventDefault();
      const newText = subchecklistInputs[checklistIndex] || "";

      const updatedTask = { ...task };

      const checklistItemIndex = updatedTask.checklist.map((item) => {
        if (item._id === checklistIndex) {
          const subChecklist = Array.isArray(item.subChecklist)
            ? [...item.subChecklist]
            : [];
          return {
            ...item,
            subChecklist: [...subChecklist, { title: newText }],
          };
        }
        return item;
      });
      if (ticket) {
        updateTicketThunk({
          id: updatedTask._id,
          patchData: { checklist: checklistItemIndex },
        });
      } else {
        patchTicketAllocationAction({
          id: task?._id,
          patchData: {
            checklist: checklistItemIndex,
          },
        });
      }

      setSubchecklistInputs((prevInputs) => ({
        ...prevInputs,
        [checklistIndex]: "",
      }));
    }
  };
  const toggleCompletion = (itemId, value) => {
    const updatedTask = { ...task };
    updatedTask.checklist = updatedTask.checklist.map((item) => {
      if (item._id === itemId) {
        const updatedSubChecklist = item.subChecklist.map((e) => {
          if (e._id === value) {
            return {
              ...e,
              status: e.status === "Todo" ? "Complete" : "Todo",
            };
          }
          return e;
        });
        return {
          ...item,
          subChecklist: updatedSubChecklist,
        };
      }
      return item;
    });

    if (ticket) {
      updateTicketThunk({
        id: updatedTask._id,
        patchData: { checklist: updatedTask.checklist },
      });
    } else {
      patchTicketAllocationAction({
        id: task?._id,
        patchData: {
          checklist: updatedTask.checklist,
        },
      });
    }
  };

  const handleInputChange = (e) => {
    setNewItemText(e.target.value);
  };
  const handleInputSubChecklistChange = (e, checklistItemId) => {
    const newText = e.target.value;

    setSubchecklistInputs((prevInputs) => ({
      ...prevInputs,
      [checklistItemId]: newText,
    }));
  };
  const handleAddChecklistItem = () => {
    if (newItemText?.trim() !== "") {
      if (ticket) {
        updateTicketThunk({
          id: task?._id,
          patchData: { checklist: [...task.checklist, { title: newItemText }] },
        });
      } else {
        let updatedChecklist = [];
        if (task && task.checklist) {
          updatedChecklist = [...task.checklist, { title: newItemText }];
        } else {
          updatedChecklist = [{ title: newItemText }];
        }
        patchTicketAllocationAction({
          id: task?._id,
          patchData: {
            checklist: updatedChecklist,
          },
        });
      }

      setNewItemText("");
    }
  };
  function calculateChecklistStatus(checklistItem) {
    let completedCount = 0;
    let totalCount = 0;

    if (checklistItem.subChecklist) {
      totalCount = checklistItem.subChecklist.length;

      completedCount = checklistItem.subChecklist.reduce((count, subItem) => {
        if (subItem.status === "Complete") {
          return count + 1;
        }
        return count;
      }, 0);
    }

    return { completedCount, totalCount };
  }
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination, draggableId } = result;
    const updatedTask = { ...task };
    updatedTask.checklist = updatedTask.checklist.map((item) => {
      if (item._id === source.droppableId.split("-")[1]) {
        const updatedSubChecklist = item.subChecklist.map((e) => {
          if (e._id === draggableId) {
            if (destination.droppableId.split("-")[0] === "Todo") {
              return {
                ...e,
                status: "Todo",
              };
            }
            if (destination.droppableId.split("-")[0] === "Complete") {
              return {
                ...e,
                status: "Complete",
              };
            }
          }
          return e;
        });
        return {
          ...item,
          subChecklist: updatedSubChecklist,
        };
      }
      return item;
    });

    updateTicketThunk({
      id: updatedTask._id,
      patchData: { checklist: updatedTask.checklist },
    });
  };
  const handleTitleChange = (event, checklistItemId) => {
    const newTitleValue = event.target.value;

    if (newTitleValue === "") {
      setNewTitle((prevTitles) => ({
        ...prevTitles,
        [checklistItemId]: "",
      }));
    } else {
      setNewTitle((prevTitles) => ({
        ...prevTitles,
        [checklistItemId]: newTitleValue,
      }));
    }
  };

  const handleTitleKeyPress = (item, event) => {
    if (event.key === "Enter" || event._reactName === "onBlur") {
      const updatedTask = { ...task };
      updatedTask.checklist = updatedTask.checklist.map((checklistItem) => {
        if (checklistItem._id === item._id) {
          return {
            ...checklistItem,
            title: newTitle[item._id] || item.title,
          };
        }
        return checklistItem;
      });
      if (ticket) {
        updateTicketThunk({
          id: updatedTask._id,
          patchData: { checklist: updatedTask.checklist },
        });
      } else {
        patchTicketAllocationAction({
          id: updatedTask._id,
          patchData: {
            checklist: updatedTask.checklist,
          },
        });
      }
    }
  };
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <>
      <div
        className="sub-task-block input-data "
        onClick={() => {
          setChecked(false);
        }}
      >
        {hasPermission &&
          hasPermission(
            permissionConstants.permissions.TASK_DETAILS_MODAL_CREATE_CHECKLIST,
          ) &&
          task?.checklist?.map((e) => {
            const { completedCount, totalCount } = calculateChecklistStatus(e);
            return (
              <DragDropContext onDragEnd={handleDragEnd}>
                <div className="title-block checklist-delete-col">
                  <input
                    style={{ fontWeight: "bold" }}
                    type="text"
                    value={
                      newTitle[e._id] !== undefined ? newTitle[e._id] : e.title
                    }
                    onChange={(event) => handleTitleChange(event, e._id)}
                    onKeyPress={(event) => handleTitleKeyPress(e, event)}
                    onBlur={(event) => {
                      if (newTitle[e._id]?.trim().length !== 0) {
                        handleTitleKeyPress(e, event);
                      } else {
                        event.target.value = e.title;
                        setNewTitle((prevTitles) => ({
                          ...prevTitles,
                          [e._id]: e.title,
                        }));
                      }
                    }}
                    className="disabled-input"
                  />
                  <span>
                    ({completedCount}/{totalCount})
                  </span>
                  <button
                    className={`checklist-del ${
                      fileToDelete &&
                      fileToDelete?._id === e._id &&
                      "opacity-100"
                    }`}
                    type="button"
                    onClick={() => handleDeleteChecklistItemClick(e._id)}
                  >
                    <img src={del} alt="" />
                  </button>
                </div>
                {e.subChecklist?.some((value) => value.status === "Complete") &&
                  e.subChecklist?.some((value) => value.status === "Todo") && (
                    <div className="chk_subtitle">Todo</div>
                  )}
                <div className="chk_subtitle">
                  <Droppable droppableId={`Todo-${e._id}`}>
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="todo-list sub-task-block-01"
                      >
                        {e.subChecklist
                          ?.filter((values) => values.status !== "Complete")
                          .map((value, checklistIndex) => (
                            <Draggable
                              key={value._id}
                              draggableId={value._id}
                              index={checklistIndex}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="todo-list"
                                >
                                  {(ispatchTicketAllocationAction &&
                                    checked === value._id) ||
                                  (isUpdateTicketThunk &&
                                    checked === value._id) ? (
                                    <li className="check-list-item check-list-item-02">
                                      <CommonSkeleton width={250} />
                                    </li>
                                  ) : (
                                    <li className="check-list-item check-list-item-02">
                                      <div className="chk-col">
                                        <input
                                          className="checkbox-round"
                                          type="checkbox"
                                          name="checkbox1"
                                          id="checkbox1"
                                          onClick={(each) => {
                                            setChecked(value._id);
                                            toggleCompletion(e._id, value._id);
                                            each.stopPropagation();
                                          }}
                                        />
                                        <label className="form-check-label">
                                          {value?.title}
                                        </label>
                                      </div>
                                      {console.log(checked, value._id)}
                                      <Profile
                                        TaskData={value}
                                        dropDownVisibility={
                                          addUser === value._id
                                        }
                                        addUser={addUser}
                                        setAddUser={setAddUser}
                                        checklist="checklist"
                                        checklistValue={e?._id}
                                        ticket={ticket}
                                        onAllocateUser={(data) => {
                                          setChecked(value._id);
                                          handleAllocateUser(
                                            data,
                                            e,
                                            value._id,
                                          );
                                        }}
                                        onDeallocateUser={(data) => {
                                          setChecked(value._id);
                                          handleDeallocateUser(
                                            data,
                                            e,
                                            value._id,
                                          );
                                        }}
                                      />
                                      <button
                                        className="btn add-user-btn"
                                        type="button"
                                        onClick={(event) => {
                                          setChecked(value._id);
                                          setAddUser(value._id);
                                          setChecklist(e);
                                          event.stopPropagation();
                                        }}
                                      >
                                        <UserIcon />

                                        <span className="add-icon">
                                          <img src={addFill} alt="" />
                                        </span>
                                      </button>

                                      <button
                                        type="button"
                                        className={`checklist-del ${
                                          fileToDelete &&
                                          fileToDelete._id === value._id &&
                                          "opacity-100"
                                        }`}
                                        onClick={(each) => {
                                          handleDeleteItemClick(value, e);
                                          setChecked(value._id);
                                          each.stopPropagation();
                                        }}
                                      >
                                        <img src={del} alt="" />
                                      </button>
                                    </li>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </div>
                {e.subChecklist?.some(
                  (value) => value.status === "Complete",
                ) && <div className="chk_subtitle">Completed</div>}
                <div className="chk_subtitle">
                  <Droppable droppableId={`Complete-${e._id}`}>
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="completed-list sub-task-block-01"
                      >
                        {e.subChecklist
                          ?.filter((value) => value.status === "Complete")
                          .map((value, checklistIndex) => (
                            <Draggable
                              key={value._id}
                              draggableId={value._id}
                              index={checklistIndex}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {(ispatchTicketAllocationAction &&
                                    checked === value._id) ||
                                  (isUpdateTicketThunk &&
                                    checked === value._id) ? (
                                    <li className="check-list-item check-list-item-02">
                                      <CommonSkeleton width={250} />
                                    </li>
                                  ) : (
                                    <li className="check-list-item check-list-item-02">
                                      <div className="chk-col">
                                        <input
                                          className="checkbox-round"
                                          type="checkbox"
                                          name="checkbox1"
                                          id="checkbox1"
                                          onClick={(event) => {
                                            toggleCompletion(e._id, value._id);
                                            setChecked(value._id);
                                            event.stopPropagation();
                                          }}
                                        />
                                        <label className="form-check-label">
                                          {value?.title}
                                        </label>
                                      </div>

                                      <Profile
                                        TaskData={value}
                                        dropDownVisibility={
                                          addUser === value._id
                                        }
                                        addUser={addUser}
                                        setAddUser={setAddUser}
                                        checklist="checklist"
                                        checklistValue={e?._id}
                                        ticket={ticket}
                                        onAllocateUser={(data) => {
                                          setChecked(value._id);
                                          handleAllocateUser(
                                            data,
                                            e,
                                            value._id,
                                          );
                                        }}
                                        onDeallocateUser={(data) => {
                                          setChecked(value._id);
                                          handleDeallocateUser(
                                            data,
                                            e,
                                            value._id,
                                          );
                                        }}
                                      />
                                      <button
                                        className="btn add-user-btn"
                                        type="button"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setAddUser(value._id);
                                          setChecked(value._id);
                                          setChecklist(e);
                                        }}
                                      >
                                        <UserIcon />

                                        <span className="add-icon">
                                          <img src={addFill} alt="" />
                                        </span>
                                      </button>
                                      <button
                                        className={`checklist-del ${
                                          fileToDelete &&
                                          fileToDelete._id === value._id &&
                                          "opacity-100"
                                        }`}
                                        type="button"
                                        onClick={(event) => {
                                          setChecked(value._id);
                                          handleDeleteItemClick(value, e);
                                          event.stopPropagation();
                                        }}
                                      >
                                        <img src={del} alt="" />
                                      </button>
                                    </li>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </div>
                {hasPermission &&
                  hasPermission(
                    permissionConstants.permissions
                      .TASK_DETAILS_MODAL_CREATE_CHECKLIST,
                  ) && (
                    <div className="add-wishlist">
                      <button className="btn btn-add" type="button">
                        <AddIcon />
                      </button>
                      <input
                        type="text"
                        className="form-control chk-list-input"
                        placeholder="New SubChecklist"
                        value={subchecklistInputs[e._id]}
                        onChange={(each) =>
                          handleInputSubChecklistChange(each, e._id)
                        }
                        onKeyPress={(each) =>
                          handleSubChecklistInputKeyPress(each, e._id)
                        }
                        onBlur={(each) => {
                          if (subchecklistInputs[e._id]?.trim().length > 0) {
                            handleSubChecklistInputKeyPress(each, e._id);
                          }
                        }}
                      />
                    </div>
                  )}
              </DragDropContext>
            );
          })}
        {hasPermission &&
          hasPermission(
            permissionConstants.permissions.TASK_DETAILS_MODAL_CREATE_CHECKLIST,
          ) && (
            <div className="new-checklist-add">
              <AddBtn />
              <input
                type="text"
                className="form-control chk-list-input"
                placeholder="New Checklist Item"
                value={newItemText}
                onChange={handleInputChange}
                onBlur={() => {
                  if (newItemText?.trim().length !== 0) {
                    handleAddChecklistItem();
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleAddChecklistItem();
                  }
                }}
                ref={inputRef}
              />
              {/* )} */}
            </div>
          )}
      </div>
      <DeleteModal
        name={fileToDelete?.name}
        onDelete={handleConfirmDeleteItem}
        onCancel={() => {
          setDeleteModalOpen(false);
          setFileToDelete(null);
        }}
        show={isDeleteModalOpen}
      />
    </>
  );
};
CheckList.propTypes = {
  task: PropTypes.node,
  ticket: PropTypes.bool,
  updateTicketThunk: PropTypes.func,
  patchTicketAllocationAction: PropTypes.func,
  ispatchTicketAllocationAction: PropTypes.bool,
  isUpdateTicketThunk: PropTypes.bool,
  checked: PropTypes.string,
  setChecked: PropTypes.func,
  hasPermission: PropTypes.func,
};

export default CheckList;
