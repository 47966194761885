import PropTypes from "prop-types";
import CommonSkeleton from "./CommonSkeleton";

function SkeletonRows({ columns, limit = 10 }) {
  return (
    <tbody>
      {Array?.from({ length: limit }).map((index, inx) => (
        <tr key={`${index}${inx}`}>
          {columns?.map(({ colClassName = "", selector }, index) => (
            <td className={colClassName} key={`cell${selector}${index}`}>
              <CommonSkeleton height={30} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

SkeletonRows.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      colClassName: PropTypes.string,
      selector: PropTypes.string.isRequired,
    }),
  ).isRequired,
  limit: PropTypes.number,
};

export default SkeletonRows;
