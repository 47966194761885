/* eslint-disable react/prop-types */
// import { useState } from "react";
// import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "../../design/custom/pages/projectmanagement-list.scss";
import info from "../../assets/images/info.svg";
import Table from "./Table";
// import { handleAllocationDrag, handleTicketDrag } from "./handleDrag";
import CommonSkeleton from "../SkeletonLoader/CommonSkeleton";
// import {
//   setTicketAllocationData,
//   setTicketData,
// } from "../../store/slices/ticket";

const TicketManagementList = ({
  showTableName,
  tableName1,
  tableName2,
  onSortChange,
  taskAdd,
  headerActionConfig,
  configData,
  checkComplete,
  taskEdit,
  TaskData,
  onAccordionClick,
  ActionButtons,
  handleSelectClick,
  ModalShow,
  isLoading,
  patchFunction,
  groupBy,
  AddTask,
  setSelectedRows,
  selectedRows,
  EditLoader,
  taskView,
  showSubTasks,
  ExpandAll,
  TicketCount,
  dataCount,
  scrollContainerRef,
  nextTable,
  filter,
  completeStatus,
}) => {
  // const [state, setState] = useState({
  //   draggedItem: null,
  // });
  // const { draggedItem } = state;
  const CustomToggle = ({
    children,
    eventKey,
    callback,
    ButtonclassName,
    onAccordioOpenClick,
  }) => {
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );

    if (groupBy === "status" || groupBy === "") groupBy = "ticketStatus";
    return (
      <div
        type="button"
        onClick={() => {
          decoratedOnClick();
          if (typeof onAccordioOpenClick === "function") {
            onAccordioOpenClick();
          }
        }}
        className={ButtonclassName}
      >
        {children}
      </div>
    );
  };

  // const handleDragStart = (e, item) => {
  //   setState({ ...state, draggedItem: item });
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  // const dispatch = useDispatch();

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (draggedItem) {
  //     const droppedItem = JSON.parse(e.target.getAttribute("content"));
  //     if (
  //       droppedItem?.itemtype === "Ticket" &&
  //       draggedItem?.type === "Ticket"
  //     ) {
  //       const UpdatedData = handleTicketDrag(
  //         droppedItem,
  //         draggedItem,
  //         TaskData,
  //         patchFunction,
  //         groupBy,
  //       );
  //       dispatch(setTicketData({ data: UpdatedData }));
  //       setState({ ...state, draggedItem: null });
  //     }
  //     if (
  //       droppedItem?.itemtype === "allocation" &&
  //       draggedItem?.type === "allocations"
  //     ) {
  //       const UpdatedData = handleAllocationDrag(
  //         droppedItem,
  //         draggedItem,
  //         TaskData,
  //         patchFunction,
  //         groupBy,
  //       );
  //       dispatch(setTicketData({ data: UpdatedData }));

  //       setState({ ...state, draggedItem: null });
  //     }
  //     if (
  //       droppedItem?.itemtype === "Ticket" &&
  //       draggedItem?.type === "allocations"
  //     ) {
  //       const UpdatedData = handleAllocationDrag(
  //         droppedItem,
  //         draggedItem,
  //         TaskData,
  //         patchFunction,
  //         groupBy,
  //       );
  //       if (groupBy === "none") {
  //         dispatch(setTicketAllocationData({ data: UpdatedData[0].data }));
  //       } else dispatch(setTicketData({ data: UpdatedData }));
  //       setState({ ...state, draggedItem: null });
  //     }
  //   }
  // };

  return (
    <div
      className="container-fluid"
      // onDragOver={handleDragOver}
      // onDrop={handleDrop}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="group-list-cover d-flex flex-column overflow-main z-90">
            <div ref={scrollContainerRef} className="group-outer custom-scroll">
              {isLoading && TaskData?.length <= 0 ? (
                <>
                  <div style={{ width: "10%", marginLeft: "2%" }}>
                    <CommonSkeleton height={30} />
                  </div>
                  <div style={{ width: "20%", marginLeft: "2%" }}>
                    <CommonSkeleton height={30} />
                  </div>
                </>
              ) : (
                <div className="group-head-main d-flex sticky-element ms-2">
                  <div className="group-head" style={{ marginBottom: "0.23%" }}>
                    {showTableName ? (
                      <>
                        <small>{tableName1}</small>
                        {tableName2}
                      </>
                    ) : null}
                  </div>

                  <div className="items-list mb-1 ms-1">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Ticket List</Tooltip>}
                    >
                      <span className="infoicon">
                        <img style={{ cursor: "pointer" }} src={info} alt="" />
                      </span>
                    </OverlayTrigger>
                    {headerActionConfig?.map(
                      ({
                        ButtonInnerText,
                        onClickFunction,
                        isVisible,
                        toolTipContent,
                      }) =>
                        isVisible && (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{toolTipContent}</Tooltip>}
                          >
                            <span
                              onClick={onClickFunction}
                              className="item__btn pe-auto"
                              style={{ cursor: "pointer" }}
                            >
                              {ButtonInnerText}
                            </span>
                          </OverlayTrigger>
                        ),
                    )}
                  </div>
                </div>
              )}
              <Table
                checkComplete={checkComplete}
                onAccordionClick={onAccordionClick}
                taskEdit={taskEdit}
                taskAdd={taskAdd}
                onSortChange={onSortChange}
                configData={configData}
                CustomToggle={CustomToggle}
                TaskData={TaskData}
                ActionButtons={ActionButtons}
                handleSelectClick={handleSelectClick}
                ModalShow={ModalShow}
                // handleDragStart={handleDragStart}
                // handleDrop={handleDrop}
                // handleDragOver={handleDragOver}
                isLoading={isLoading}
                patchFunction={patchFunction}
                groupBy={groupBy}
                AddTask={AddTask}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                EditLoader={EditLoader}
                taskView={taskView}
                showSubTasks={showSubTasks}
                ExpandAll={ExpandAll}
                TicketCount={TicketCount}
                dataCount={dataCount}
                nextTable={nextTable}
                filter={filter}
                completeStatus={completeStatus}
              />

              {TaskData?.length > 0 &&
                TicketCount?.length !==
                  TaskData?.filter((e) => e?.data?.length > 0)?.length && (
                  <div className="main-container-row w-100">
                    {Array.from({
                      length: 5,
                    }).map((_, index) => (
                      <CommonSkeleton key={index} height={35} />
                    ))}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TicketManagementList.propTypes = {
  showTableName: PropTypes.bool.isRequired,
  tableName1: PropTypes.string,
  isLoading: PropTypes.isRequired,
  tableName2: PropTypes.node,
  onSortChange: PropTypes.func.isRequired,
  taskAdd: PropTypes.bool.isRequired,
  headerActionConfig: PropTypes.isRequired,
  configData: PropTypes.isRequired,
  taskEdit: PropTypes.bool.isRequired,
  TaskData: PropTypes.arrayOf(
    PropTypes.shape({
      statusId: PropTypes.string.isRequired,
      data: PropTypes.isRequired,
    }),
  ).isRequired,
  onAccordionClick: PropTypes.func,
  ActionButtons: PropTypes.elementType.isRequired,
  handleSelectClick: PropTypes.func.isRequired,
  ModalShow: PropTypes.func.isRequired,
};

export default TicketManagementList;
