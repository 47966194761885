import { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useParams } from "react-router-dom";
import EditPopup from "./EditPopup";
import UploadSheetModal from "../../../pages/uploadSheet/index";
import { useThunk } from "../../../hooks/useThunk";
import { getAllTechnologies } from "../../../store";
import { ticketDocs } from "../../../store/slices/ticket/thunks/getTicketDocs";
import { listDelete } from "../../../store/slices/project/thunks/deleteList";
import { listPatch } from "../../../store/slices/project/thunks/patchList";

const ListTile = ({
  shortCode,
  eachList,
  projectId,
  listId,
  listName,
  listToBeRenamed,
  setListToBeRenamed,
}) => {
  const [isUploadSheetModalOpen, setIsUploadSheetModalOpen] = useState(false);
  const [listPatchName, setListPatchName] = useState("");

  const [technologyGet] = useThunk(getAllTechnologies);
  const [TicketDocsGet, isGetTicketDocs] = useThunk(ticketDocs);
  const [listDeleteThunk] = useThunk(listDelete);
  const [listPatchThunk] = useThunk(listPatch);

  const { listId: list } = useParams();

  const toggleUploadSheetModal = () => {
    setIsUploadSheetModalOpen(!isUploadSheetModalOpen);
    technologyGet(projectId);
    TicketDocsGet({ projectId, listId });
  };
  return listToBeRenamed === eachList._id ? (
    <form
      className="rename-folder"
      onSubmit={(e) => {
        e.preventDefault();
        if (listPatchName.trim().length)
          listPatchThunk({
            id: listToBeRenamed,
            patchData: { name: listPatchName },
          });
        setListToBeRenamed("");
      }}
    >
      <input
        onBlur={() => {
          setListToBeRenamed("");
        }}
        className="form-control"
        value={listPatchName}
        onChange={({ target: { value } }) => setListPatchName(value)}
      />
    </form>
  ) : (
    <li
      type="button"
      className={`d-flex align-items-center menu-bullet ${
        listId === list ? "active" : ""
      }`}
      key={eachList.name}
    >
      <NavLink
        style={{ all: "unset" }}
        to={`project/${shortCode}/list/${eachList._id}`}
      >
        <span className="txt">{eachList.name}</span>
      </NavLink>

      <span className="actions">
        <span className="list-count">{eachList.ticketCount}</span>
        <EditPopup
          showUpload
          showDelete
          showEdit
          uploadFunction={() => {
            toggleUploadSheetModal();
          }}
          toggleInput={() => {
            setListToBeRenamed(eachList._id);
            setListPatchName(eachList.name);
          }}
          deleteFolder={() => {
            listDeleteThunk(eachList._id);
          }}
        />
        {isUploadSheetModalOpen && (
          <UploadSheetModal
            setIsUploadSheetModalOpen={() => setIsUploadSheetModalOpen("")}
            projectId={projectId}
            listId={listId}
            listName={listName}
            isGetTicketDocs={isGetTicketDocs}
          />
        )}
      </span>
    </li>
  );
};

ListTile.propTypes = {
  shortCode: PropTypes.string,
  projectId: PropTypes.string,
  listId: PropTypes.string,
  listName: PropTypes.string,
  eachList: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
    ticketCount: PropTypes.number,
  }),
  listToBeRenamed: PropTypes.string,
  setListToBeRenamed: PropTypes.func,
};

export default ListTile;
