import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import "../../../../design/custom/pages/teams.scss";
import Add from "./add";
import { useThunk } from "../../../../hooks/useThunk";
import CustomTableComponent from "./list";
import { AddDownArrow } from "./svgIcons";

import { getFilterDropdown } from "../../../../store/slices/filter/thunks/getFilterDropdown";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../../utils/permissionUtils";
import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";

function Teams() {
  const [showForm, setShowForm] = useState(true);
  const [filterData] = useThunk(getFilterDropdown);
  const { userProfile } = useSelector((state) => state.userProfile);

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.PROJECT_MANAGEMENT_TEAMS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  useEffect(() => {
    filterData("teams");
  }, []);

  return (
    <>
      <div className="title-otr d-flex flex-wrap align-items-center">
        <div className="page-title">
          {hasPermission(
            permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_SHOW,
          ) && <h2 className="table-title">Allocated Users</h2>}
        </div>
        {hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_ADD,
        ) && (
          <div className="ms-auto rgt-sctn">
            <div className="add-dropdown-block">
              <div className="dropdown">
                <Dropdown autoClose="inside" align="end">
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    bsPrefix="dropdown-toggle-btn d-flex justify-content-between"
                  >
                    <div onClick={() => setShowForm(true)}>Add</div>
                    <span className="d-flex">
                      <AddDownArrow />
                    </span>
                  </Dropdown.Toggle>
                  {showForm && (
                    <Add showForm={showForm} setShowForm={setShowForm} />
                  )}
                </Dropdown>
              </div>
            </div>
          </div>
        )}
      </div>
      {hasPermission(
        permissionConstants.permissions.PROJECT_MANAGEMENT_TEAMS_SHOW,
      ) && (
        <div className="page-contents inner-page-contents">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 activity-log-area">
                <div className="content-tables ">
                  <div className="teams-cover">
                    <CustomTableComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Teams;
