const validTableFields = (value) => {
  const fields = {
    Role: "role",
    Assignee: "userId",
    "Last Worked On": "lastWorkedOn",
    "Tickets Taken": "totalTickets",
    "Tickets Completed": "completed",
    "Tickets To Do": "todo",
    "Tickets In Progress": "inprogress",
    "Tickets Under Review": "readyforQA",
  };
  const properFieldName = fields[value] ?? value;
  return properFieldName;
};

export const filterQuery = (filters) => {
  const completeFilters = filters.filter((eachFilter) => {
    if (
      ["less than", "equal to", "greater than"].includes(
        eachFilter.condition?.value,
      ) &&
      eachFilter.where
    )
      return true;
    if (eachFilter.where && eachFilter.condition && eachFilter.option)
      return true;
    return false;
  });

  const gate =
    completeFilters.length && completeFilters[0].gate === "AND"
      ? "$and"
      : "$or";

  const queryCondition = {
    [gate]: [],
  };
  completeFilters?.forEach((eachFilter) => {
    let condition = {};
    const field = validTableFields(eachFilter.where.label);
    condition = { ...condition, fieldName: field };
    if (eachFilter.condition.value === "is") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: Array.isArray(eachFilter.option) ? "$in" : "$eq",
        isDate: ["startDate", "dueDate", "createdAt", "updatedAt"].includes(
          field,
        )
          ? "true"
          : undefined,
      };
    } else if (eachFilter.condition.value === "is not") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: Array.isArray(eachFilter.option) ? "$nin" : "$ne",
        isDate: ["startDate", "dueDate", "createdAt", "updatedAt"].includes(
          field,
        )
          ? "true"
          : undefined,
      };
    } else if (eachFilter.condition.value === "is not set") {
      condition = { ...condition, value: "true", query: "$eq" };
    } else if (eachFilter.condition.value === "is set") {
      condition = { ...condition, value: "false", query: "$ne" };
    } else if (eachFilter.condition.value === "less than") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: "$lt",
      };
    } else if (eachFilter.condition.value === "greater than") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: "$gt",
      };
    } else if (eachFilter.condition.value === "equal to") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: "$eq",
      };
    }
    if (condition.value) queryCondition[gate].push(condition);
    return eachFilter;
  });
  return queryCondition[gate].length ? queryCondition : null;
};

// const validTableFields = (value) => {
//   const fields = {
//     Role: "role",
//     Assignee: "_id",
//     "Last Worked On": "lastWorkedOn",
//     "Tickets Taken": "totalTickets",
//     "Tickets Completed": "completed",
//     "Tickets To Do": "todo",
//     "Tickets In Progress": "inprogress",
//     "Tickets Under Review": "readyforQA",
//   };
//   const properFieldName = fields[value] ?? value;
//   return properFieldName;
// };

// export const filterQuery = (filters) => {
//   const completeFilters = filters.filter((eachFilter) => {
//     if (
//       ["is set", "is not set"].includes(eachFilter.condition?.value) &&
//       eachFilter.where
//     )
//       return true;
//     if (eachFilter.where && eachFilter.condition && eachFilter.option)
//       return true;
//     return false;
//   });

//   const gate =
//     completeFilters.length && completeFilters[0].gate === "AND"
//       ? "$and"
//       : "$or";

//   const queryCondition = {
//     [gate]: [],
//   };
//   completeFilters?.forEach((eachFilter) => {
//     let condition = {};
//     const field = validTableFields(eachFilter.where.label);
//     condition = { ...condition, fieldName: field };
//     if (eachFilter.condition.value === "is") {
//       condition = {
//         ...condition,
//         value: Array.isArray(eachFilter.option)
//           ? !!eachFilter.option.length &&
//             eachFilter.option.map(({ value }) => value)
//           : eachFilter.option.value ?? eachFilter.option,
//         query: Array.isArray(eachFilter.option) ? "$in" : " $eq",
//         isDate: [
//           "startDate",
//           "dueDate",
//           "lastWorkedOn",
//           "createdAt",
//           "updatedAt",
//         ].includes(field)
//           ? "true"
//           : undefined,
//       };
//     } else if (eachFilter.condition.value === "is not") {
//       condition = {
//         ...condition,
//         value: Array.isArray(eachFilter.option)
//           ? eachFilter.option.map(({ value }) => value)
//           : eachFilter.option.value ?? eachFilter.option,
//         query: Array.isArray(eachFilter.option) ? "$nin" : "$ne",
//         isDate: [
//           "startDate",
//           "dueDate",
//           "lastWorkedOn",
//           "createdAt",
//           "updatedAt",
//         ].includes(field)
//           ? "true"
//           : undefined,
//       };
//     } else if (eachFilter.condition.value === "is not") {
//       condition = {
//         ...condition,
//         value: Array.isArray(eachFilter.option)
//           ? eachFilter.option.map(({ value }) => value)
//           : eachFilter.option.value ?? eachFilter.option,
//         query: Array.isArray(eachFilter.option) ? "$nin" : "$ne",
//         isDate: [
//           "startDate",
//           "dueDate",
//           "lastWorkedOn",
//           "createdAt",
//           "updatedAt",
//         ].includes(field)
//           ? "true"
//           : undefined,
//       };
//     } else if (eachFilter.condition.value === "is not set") {
//       condition = { ...condition, value: "true", query: "$eq" };
//     } else if (eachFilter.condition.value === "is set") {
//       condition = { ...condition, value: "false", query: "$ne" };
//     }
//     if (condition.value) queryCondition[gate].push(condition);
//     return eachFilter;
//   });
//   return queryCondition[gate].length ? queryCondition : null;
// };
