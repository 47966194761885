import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import PropTypes from "prop-types";
import { FolderIco } from "../svgIcons";
import EditPopup from "./EditPopup";
import { useThunk } from "../../../hooks/useThunk";
import { folderPatch } from "../../../store/slices/project/thunks/patchFolder";
import { folderDelete } from "../../../store/slices/project/thunks/deleteFolder";
import { listCreate } from "../../../store/slices/project/thunks/postList";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

const FolderTile = ({
  children,
  eachFolder,
  setToggleState,
  toggleState,
  projectIndex,
  folderIndex,
  projectId,
  shortCode,
  listCount,
  folderToBeRenamed,
  setFolderToBeRenamed,
}) => {
  const [folderName, setFolderName] = useState("");
  const [patchFolder] = useThunk(folderPatch);
  const [folderDeleteThunk] = useThunk(folderDelete);
  const [addListThunk, onCreateListLoading] = useThunk(listCreate);
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.BASIC_PROJECT_SETTINGS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  if (!hasPermission(permissionConstants.permissions.PROJECT_FOLDER_LISTING)) {
    return null; // Return null if user doesn't have permission to view folder
  }

  return (
    <div className="sub2_item  d-flex align-items-center" key={eachFolder.name}>
      {folderToBeRenamed === eachFolder._id ? (
        <form
          className="rename-folder"
          onSubmit={(e) => {
            e.preventDefault();
            if (folderName.trim().length)
              patchFolder({
                id: folderToBeRenamed,
                patchData: { name: folderName },
              });
            setFolderToBeRenamed("");
          }}
        >
          <input
            onBlur={() => {
              setFolderToBeRenamed("");
            }}
            className="form-control"
            value={folderName}
            onChange={({ target: { value } }) => setFolderName(value)}
          />
        </form>
      ) : (
        <Link
          to="# "
          className="sub2_menuLink"
          type="Button"
          onClick={() => setToggleState(`${projectIndex}-${folderIndex}`)}
        >
          <span className="icon">
            <FolderIco />
          </span>
          <span className="txt">{eachFolder.name}</span>
        </Link>
      )}
      <span className="actions">
        <span className="list-count"> {listCount}</span>
        <EditPopup
          showAdd
          showDelete
          showEdit
          onCreateListLoading={onCreateListLoading}
          toggleInput={() => {
            setFolderToBeRenamed(eachFolder._id);
            setFolderName(eachFolder.name);
          }}
          deleteFolder={() => {
            folderDeleteThunk(eachFolder._id);
          }}
          addListOnSubmit={(listName) => {
            if (projectId) {
              let postData = {
                name: listName,
                project: projectId,
              };
              if (eachFolder._id)
                postData = { ...postData, folder: eachFolder._id };
              addListThunk({
                postData,
                navigate: (id) => navigate(`/project/${shortCode}/list/${id}`),
              });
            }
          }}
        />
      </span>

      {/* submenu Level3  */}
      <div
        className={
          toggleState.includes(`${projectIndex}-${folderIndex}`)
            ? "sub3_item"
            : "sub3_item collapse"
        }
        id="collapseExample"
      >
        <ul>{children}</ul>
      </div>
    </div>
  );
};

FolderTile.propTypes = {
  children: PropTypes.node,
  eachFolder: PropTypes.shape(),
  projectIndex: PropTypes.number,
  folderIndex: PropTypes.number,
  toggleState: PropTypes.arrayOf(PropTypes.string),
  setToggleState: PropTypes.func,
  projectId: PropTypes.string,
  folderToBeRenamed: PropTypes.string,
  setFolderToBeRenamed: PropTypes.func,
  listCount: PropTypes.number,
  shortCode: PropTypes.string,
};

export default FolderTile;
