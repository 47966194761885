import { createSlice } from "@reduxjs/toolkit";

export const name = "document";

const createReducers = () => {
  const setDocumentData = (state, { payload }) => {
    state.documentData = payload.documents;
    state.projectId = payload.documents.projectId;
  };
  const postDocumentData = (state, { payload }) => {
    state.successMessage = payload.success;
  };
  const deleteDocumentData = (state, { payload }) => {
    state.successMessage = payload.success;
  };

  const setDocumentDataById = (state, { payload }) => {
    state.documentDataById = payload.data;
  };

  const setClearDocument = (state, { payload }) => {
    if (payload.includes("clearInfoPanel")) {
      state.documentDataById = {};
    }
    if (payload.includes("clearAll")) {
      state.documentData = [];
      state.successMessage = false;
    }
  };
  return {
    setClearDocument,
    setDocumentData,
    postDocumentData,
    setDocumentDataById,
    deleteDocumentData,
  };
};

const initialState = {
  documentData: [],
  documentDataById: [],
  successMessage: false,
};

const reducers = createReducers();

const documentSlice = createSlice({
  name,
  initialState,
  reducers,
});

export const documentActions = documentSlice.actions;
export const documentReducer = documentSlice.reducer;

export default documentReducer;
