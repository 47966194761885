import PropTypes from "prop-types";
/* eslint-disable */

const Counter = ({ title, value, icon, showCardsRow }) => {
  const isNumber = !Number?.isNaN(parseFloat(value));

  // Determine whether to display a percentage sign
  const isPercentage =
    title === "Performance Index" ||
    title === "Quality Index" ||
    title === "Perform Index";

  // Define a function to format the value
  const formatValue = (value) => {
    if (typeof value === "string") {
      return value; // Display string values as-is
    } else if (isNumber) {
      const floatValue = parseFloat(value);
      if (Number.isInteger(floatValue)) {
        return floatValue.toString(); // Display whole numbers without decimal places
      } else {
        return isPercentage ? floatValue.toFixed(2) : floatValue.toString();
      }
    }
    return value;
  };

  const displayValue = formatValue(value);

  // to show cards in a single row
  const classNameRow = showCardsRow
    ? "col-lg-4 col-xl col-md-6 lg-mb-4"
    : "col-lg-6 col-xl-4 col-md-6 mb-4 count-card-col";
  // col-lg-4 col-xl col-md-6 mb-4 card-col
  return (
    <div className={classNameRow}>
      <div className="count-tickets d-flex align-items-center">
        <div className="card-title-block">
          <h2 className="card-title">{title} </h2>

          <p className="card-count">
            {displayValue !== "-"
              ? `${displayValue} ${isPercentage ? "%" : ""}`
              : "-"}
          </p>
        </div>
        <div className="card-icon-block">
          <img src={icon} alt={title} />
        </div>
      </div>
    </div>
  );
};

Counter.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  showCardsRow: PropTypes.bool,
};
export default Counter;
