/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import useTitleContext from "../../../hooks/useTitle";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import plusAddIcon from "../../../assets/images/add-plus.svg";
import arrowRightIcon from "../../../assets/images/arrow-right.svg";
import "../../../design/custom/pages/todoItems.scss";
import clear from "../../../assets/images/trash-red.svg";
import editSvg from "../../../assets/images/edit.svg";
import { deleteTag } from "../../../store/slices/todoList/thunks/deleteTodo";

import {
  CalenderIcon,
  CloseIcon,
  DocIcon,
  DropDownIcon,
  RemindersIcon,
  ThreeDots,
  TickIcon,
  TodayIcon,
  TodocategoryIcon,
  UpcomingIcon,
} from "./todoSvgIcons";
import { todoActions } from "../../../store/slices/todoList";
import { createTodoList } from "../../../store/slices/todoList/thunks/createTodoList";
import { todoList } from "../../../store/slices/todoList/thunks/getTodoList";
import { getAllTodo } from "../../../store/slices/todoList/thunks/getAllTodo";
import { useThunk } from "../../../hooks/useThunk";
import UserNoData from "../../UserProfile/UserNoData";
import { deleteTodoList } from "../../../store/slices/todoList/thunks/deleteTodoList";
import { getColors } from "../../../store/slices/todoList/thunks/colors";
import { editTodoList } from "../../../store/slices/todoList/thunks/editList";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
// import { detailsTodoDatas } from "../../../store/slices/todoList/thunks/detailsTodoData";
import { tagList } from "../../../store/slices/todoList/thunks/tags";
import { getTodoCounts } from "../../../store/slices/todoList/thunks/getTaskCount";
import { useFilter } from "../../../context/useFilter";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

export default function Todo() {
  const { setTitle } = useTitleContext();
  const [activeList, setActiveList] = useState("");
  const [activeTab, setActiveTab] = useState("today");
  const [showAddInput, setShowAddInput] = useState(false);
  const [todoName, setTodoName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [tags, setTags] = useState([]);
  const { filter } = useFilter();

  const dispatch = useDispatch();
  const [addToDoList] = useThunk(createTodoList);
  const [toDoList, isLoading] = useThunk(todoList);
  const [toDoData, isLoadingTodo] = useThunk(getAllTodo);
  const [getColor] = useThunk(getColors);
  const [deleteToDoList] = useThunk(deleteTodoList);
  const [editList] = useThunk(editTodoList);
  // const [singleTodoDetails] = useThunk(detailsTodoDatas);
  const [getTagList] = useThunk(tagList);
  const [deletetag] = useThunk(deleteTag);
  const [getTodoCount] = useThunk(getTodoCounts);
  const [params, setparams] = useState("");

  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.WIDGETS,
        permissionConstants.submodules.TODO,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  console.log("hasPermission", hasPermission);
  const {
    allTodoList,
    allTodoListData,
    colorData,
    tagsList,
    todoCount,
    createdTodo,
  } = useSelector((state) => state.todo);
  useEffect(() => {
    if (tagsList?.tags) {
      const extractedTags = tagsList.tags.map((tag) => ({
        id: tag._id,
        name: tag.tag,
      }));
      setTags(extractedTags);
    }
  }, [tagsList]);

  const tabLabels = {
    today: "Today",
    upcoming: "Upcoming",
    default: "Reminders",
  };
  const tabLabel = tabLabels[activeTab] || tabLabels.default;

  useEffect(() => {
    setTitle("To Do");
    toDoList();
    getColor("data");
    toDoData(activeTab);
    getTagList("data");
    getTodoCount();
  }, [activeTab, createdTodo]);

  useEffect(() => {
    setparams(filter.search);
  }, [filter.search]);

  console.log("paramsIndex", activeTab);
  useEffect(() => {
    toDoData({ activeTab, params });
  }, [params]);
  const validateTodoName = (name) => {
    const trimmedName = name.trim();
    const maxLength = 30;

    if (trimmedName === "") {
      return "Todo Name cannot be empty";
    }

    if (trimmedName?.length > maxLength) {
      return `Todo Name must be ${maxLength} characters or less`;
    }

    return "";
  };

  const createTodoListHandler = () => {
    const errorMessage = validateTodoName(todoName);
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }

    const data = {
      listName: todoName.trim(),
      iconColor: "#ff0000",
    };

    addToDoList(data);
    toDoList();
    setShowAddInput(false);
  };

  const editListHandler = (item) => {
    setEdit(true);
    setShowAddInput(true);
    setTodoName(item.listName);
    setEditData(item);
  };

  const editSubmitHandler = () => {
    const errorMessage = validateTodoName(todoName);
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }
    const data = {
      listName: todoName.trim(),
      iconColor: editData.iconColor,
    };

    editList({ id: editData._id, data });
    setShowAddInput(false);
    setTodoName("");
    toDoList();
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    setTodoName(e.target.value);
  };

  const filteredTodos =
    activeList === ""
      ? allTodoListData?.todo?.todos
      : allTodoListData?.todo?.todos.filter(
          (data) =>
            activeList === data?.listDetails[0]?.listName?.toLowerCase(),
        );
  const filteredList = allTodoListData?.todo?.todos.filter(
    (data) => activeList === data?.listDetails[0]?.listName?.toLowerCase(),
  );

  const count = filteredList ? filteredList.length : 0;
  console.log(count, "filteredTodos");

  const filteredCompletedTodos =
    filteredTodos &&
    filteredTodos.filter((item) => item.status === "Completed");

  const filteredActiveTodos =
    filteredTodos && filteredTodos.filter((item) => item.status === "IsActive");

  const handleDeleteTodoList = (id) => {
    deleteToDoList(id);
    toDoList();
  };

  const colorPickHandler = (item, style) => {
    const data = {
      iconColor: style.colorCode,
    };
    editList({ id: item._id, data });
    toDoList();
  };

  const handleListClick = (listId) => {
    console.log("List clicked:", listId);
    const ListID = listId?._id;
    setActiveList(listId?.listName?.toLowerCase());
    toDoData({ activeTab, params, ListID });
  };

  // const removeTag = (tagToRemove) => {
  //   console.log("tagToRemove", tagToRemove);
  //   setTags(tags?.filter((item) => item !== tagToRemove));
  // };

  return (
    <>
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.TODO_SEARCH,
        )}
      />
      <div className="page-contents inner-page-contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 todo-contents">
              <div className="todo-sidebar">
                <div className="task-links">
                  <h3 className="todo-sidebar-title">TASKS</h3>

                  <div
                    className={`task-link ${
                      activeTab === "today" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("today")}
                  >
                    <span className="ico">
                      <TodayIcon />
                    </span>
                    <span className="txt">Today</span>
                    <span className="count">{todoCount?.todo?.today}</span>
                  </div>

                  <div
                    className={`task-link ${
                      activeTab === "upcoming" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("upcoming")}
                  >
                    <span className="ico">
                      <UpcomingIcon />
                    </span>
                    <span className="txt">Upcoming</span>
                    <span className="count">{todoCount?.todo?.upcoming}</span>
                  </div>

                  <div
                    className={`task-link ${
                      activeTab === "reminders" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("reminders")}
                  >
                    <span className="ico">
                      <RemindersIcon />
                    </span>
                    <span className="txt">Reminders</span>
                    <span className="count">{todoCount?.todo?.reminders}</span>
                  </div>
                </div>
                {hasPermission(
                  permissionConstants.permissions.TODO_MY_LISTS_LIST,
                ) && (
                  <div className="task-lists">
                    <h3 className="todo-sidebar-title">
                      MY LISTS
                      {hasPermission(
                        permissionConstants.permissions.TODO_MY_LISTS_ADD,
                      ) && (
                        <button
                          type="button"
                          className="btn btn-primary addNew"
                          onClick={() => {
                            setEdit(false);
                            setTodoName("");
                            setShowAddInput(!showAddInput);
                          }}
                        >
                          <img src={plusAddIcon} alt="" />
                        </button>
                      )}
                    </h3>

                    <div className="newListGrp-fields">
                      {showAddInput && (
                        <div className="input-wrp">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              value={todoName}
                              onChange={handleInputChange}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  edit
                                    ? editSubmitHandler()
                                    : createTodoListHandler();
                                }
                              }}
                            />
                            <button
                              type="button"
                              className="listaddSubmit"
                              onClick={() =>
                                edit
                                  ? editSubmitHandler()
                                  : createTodoListHandler()
                              }
                            >
                              <TickIcon />
                            </button>
                          </div>
                          {errorMessage && (
                            <span className="text-danger">
                              <small>{errorMessage}</small>
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    {isLoading
                      ? Array.from({
                          length: allTodoList?.myList?.length || 5,
                        }).map((_, i) => <CommonSkeleton height={50} key={i} />)
                      : allTodoList &&
                        allTodoList?.myList?.map((item, index) => (
                          <div
                            key={index}
                            className={`task-link ${
                              activeList === item?.listName?.toLowerCase()
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleListClick(item)}
                          >
                            <span className="ico">
                              <DocIcon color={item.iconColor} />
                            </span>
                            <span className="txt">{item.listName}</span>
                            {/* <span className="count">5</span> */}
                            <span className="dd-link">
                              {hasPermission(
                                permissionConstants.permissions
                                  .TODO_MY_LISTS_RENAME ||
                                  permissionConstants.permissions
                                    .TODO_MY_LISTS_CHANGE_COLOUR ||
                                  permissionConstants.permissions
                                    .TODO_MY_LISTS_DELETE,
                              ) && (
                                <Dropdown>
                                  <Dropdown.Toggle className="drop-dwn-btn">
                                    <ThreeDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {hasPermission(
                                      permissionConstants.permissions
                                        .TODO_MY_LISTS_RENAME,
                                    ) && (
                                      <Dropdown.Item
                                        onClick={() => editListHandler(item)}
                                      >
                                        <span className="itm-icon">
                                          <img src={editSvg} alt="Restore" />
                                        </span>
                                        Rename
                                      </Dropdown.Item>
                                    )}
                                    {hasPermission(
                                      permissionConstants.permissions
                                        .TODO_MY_LISTS_CHANGE_COLOUR,
                                    ) && (
                                      <Dropdown.Item
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Dropdown className="colorChooser">
                                          <Dropdown.Toggle variant="">
                                            <span
                                              className="itm-icon"
                                              style={{
                                                backgroundColor: item.iconColor,
                                              }}
                                            >
                                              <img src={clear} alt="Remove" />
                                            </span>
                                            Change color
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <div className="palettes-wrp">
                                                <div className="title">
                                                  SPACE COLOR
                                                </div>
                                                <ul className="palettes custom-scroll">
                                                  <li className="none">
                                                    <span className="icon">
                                                      <DropDownIcon />
                                                    </span>
                                                  </li>
                                                  {colorData?.data?.colors.map(
                                                    (style, index) => (
                                                      <li
                                                        onClick={() =>
                                                          colorPickHandler(
                                                            item,
                                                            style,
                                                          )
                                                        }
                                                        key={(index, style)}
                                                        style={{
                                                          backgroundColor:
                                                            style?.colorCode,
                                                        }}
                                                      />
                                                    ),
                                                  )}
                                                </ul>
                                              </div>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Dropdown.Item>
                                    )}
                                    {hasPermission(
                                      permissionConstants.permissions
                                        .TODO_MY_LISTS_DELETE,
                                    ) && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleDeleteTodoList(item._id)
                                        }
                                      >
                                        <span className="itm-icon">
                                          <img src={clear} alt="Remove" />
                                        </span>
                                        Delete
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </span>
                          </div>
                        ))}
                  </div>
                )}
                {hasPermission(permissionConstants.permissions.TAG_LISTS) && (
                  <div className="task-lists">
                    <h3 className="todo-sidebar-title">TAGS</h3>
                    <div className="tags-items">
                      {tagsList &&
                        tags?.map((item, index) => (
                          <div className="tag-item bg-1" key={index}>
                            <span className="txt">{item.name}</span>
                            {hasPermission(
                              permissionConstants.permissions.TAG_DELETE,
                            ) && (
                              <span
                                className="close"
                                onClick={() => {
                                  deletetag(item.id);
                                  getTagList("data");
                                }}
                              >
                                <CloseIcon />
                              </span>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="todo-details">
                <div className="todo-del-wrp">
                  <h3 className="todo-det-title">
                    <span className="txt">{tabLabel}</span>
                    <span className="count">{filteredActiveTodos?.length}</span>
                  </h3>
                  {isLoadingTodo ? (
                    Array.from({ length: 10 }).map((i) => (
                      <CommonSkeleton height={80} key={i} />
                    ))
                  ) : filteredActiveTodos && filteredActiveTodos?.length > 0 ? (
                    filteredActiveTodos.map((item, index) => (
                      <div className="todo-item" key={index}>
                        {(activeTab === "today" &&
                          new Date(item.dueDate).toDateString() ===
                            new Date().toDateString()) ||
                        (activeTab === "upcoming" &&
                          new Date(item.dueDate) > new Date()) ||
                        (activeTab === "reminders" && item.Reminder) ? (
                          <>
                            <div className="stat-chk">
                              <div className="check">
                                <input className="" type="checkbox" />
                              </div>
                            </div>
                            <div className="todo-item-txt">
                              <span className="txt">{item.title}</span>
                              <span className="date expired">
                                <span className="ico">
                                  <CalenderIcon />
                                </span>
                                <span className="dt">
                                  {moment(item.dueDate).format("DD MMM")}
                                </span>
                              </span>
                            </div>

                            <div className="todo-category">
                              <span className="ico">
                                <TodocategoryIcon
                                  color={item.listDetails[0].iconColor}
                                />
                              </span>
                              <span className="txt">
                                {item.listDetails[0].listName}
                              </span>
                            </div>
                            {/* <div
                              className="todo-actBtn"
                              onClick={() => {
                                dispatch(
                                  todoActions.setShowDetailModal({
                                    id: item._id,
                                    true: true,
                                  }),
                                );
                                singleTodoDetails(item._id);
                              }}
                            >
                              <span className="ico">
                                <img src={arrowRightIcon} alt="" />
                              </span>
                            </div> */}
                          </>
                        ) : (
                          <UserNoData />
                        )}
                      </div>
                    ))
                  ) : (
                    <UserNoData />
                  )}
                </div>
                {filteredCompletedTodos?.length > 0 && (
                  <div className="todo-del-wrp completed">
                    <h3 className="todo-det-title">
                      <span className="txt">Completed</span>
                      <span className="count">
                        {filteredCompletedTodos?.length}
                      </span>
                    </h3>

                    {isLoadingTodo
                      ? Array.from({
                          length: 10,
                        }).map((_, i) => <CommonSkeleton height={80} key={i} />)
                      : filteredCompletedTodos &&
                        filteredCompletedTodos?.length > 0
                      ? filteredCompletedTodos.map((item, index) => (
                          <div className="todo-item" key={index}>
                            {(activeTab === "today" &&
                              new Date(item.dueDate).toDateString() ===
                                new Date().toDateString()) ||
                            (activeTab === "upcoming" &&
                              new Date(item.dueDate) > new Date()) ||
                            (activeTab === "reminders" && item.Remainder) ? (
                              <>
                                <div className="stat-chk">
                                  <div className="check">
                                    <input className="check" type="checkbox" />
                                    {/* <CheckIcon /> */}
                                  </div>
                                </div>
                                <div className="todo-item-txt">
                                  <span className="txt">{item.title}</span>
                                  <span className="date">
                                    <span className="ico">
                                      <CalenderIcon />
                                    </span>
                                    <span className="dt">
                                      {moment(item.dueDate).format("DD MMM")}
                                    </span>
                                  </span>
                                </div>

                                <div className="todo-category">
                                  <span className="ico">
                                    <TodocategoryIcon
                                      color={item.listDetails[0].iconColor}
                                    />
                                  </span>
                                  <span className="txt">
                                    {item.listDetails[0].listName}
                                  </span>
                                </div>
                                <div
                                  className="todo-actBtn"
                                  // onClick={() => {
                                  //   dispatch(
                                  //     todoActions.setShowDetailModal({
                                  //       id: item._id,
                                  //       true: true,
                                  //     }),
                                  //   );
                                  //   singleTodoDetails(item._id);
                                  // }}
                                >
                                  <span className="ico">
                                    <img src={arrowRightIcon} alt="" />
                                  </span>
                                </div>
                              </>
                            ) : (
                              !isLoadingTodo && <UserNoData />
                            )}
                          </div>
                        ))
                      : !isLoadingTodo && <UserNoData />}
                  </div>
                )}
              </div>
              <div className="floating-btn-wrp">
                {hasPermission(permissionConstants.permissions.TODO_ADD) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => dispatch(todoActions.setShowTodoModal(true))}
                  >
                    <span>
                      <img src={plusAddIcon} alt="" />
                    </span>
                    <span>Add</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
