/* eslint-disable object-shorthand */
export const qualityIndexOptions = (data, label) => {
  return {
    chart: {
      type: "column",
      height: null,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      align: "left",
    },
    credits: {
      enabled: false,
    },
    labels: {
      enabled: false,
    },
    xAxis: {
      categories: [...label],
      lineColor: "#eae7e7",
      crosshair: true,
      labels: {
        style: {
          color: "#A2A5AC",
        },
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      tickInterval: 20,
      title: null, // Property shorthand
      labels: {
        style: {
          color: "#A2A5AC",
        },
      },
    },
    tooltip: {
      formatter: function () {
        // Check if the value is an integer or a decimal
        const formattedY = Number.isInteger(this.point.y)
          ? this?.point.y
          : this?.point?.y?.toFixed(2);

        // Construct the tooltip HTML content
        return (
          `<span style="font-size:10px">${this.key}</span><table>` +
          `<tr><td style="color:${this.series.color};padding:0">${this.series.name}: </td>` +
          `<td style="text-align:right; padding:0"><b>${formattedY}</b></td></tr></table>`
        );
      },
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.45,
        borderWidth: 1,
      },
    },
    legend: {
      enabled: false,
    },

    series: [
      {
        name: "Quality Index",
        data, // Property shorthand
        color: "orange",
      },
    ],
  };
};

export const performanceGraphOptions = (data, label) => {
  console.log("label", label);
  return {
    chart: {
      inverted: false,
      type: "spline",
      height: null,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      align: "left",
    },
    credits: {
      enabled: false,
    },
    labels: {
      enabled: false,
    },
    yAxis: {
      title: null, // Property shorthand
      accessibility: {
        rangeDescription: "Range: 0 to 100",
      },
      min: 0,
      max: 100,
      tickInterval: 20,
      labels: {
        style: {
          color: "#A2A5AC",
        },
      },
    },
    xAxis: {
      categories: [...label],
      lineColor: "#eae7e7",
      labels: {
        style: {
          color: "#A2A5AC",
        },
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    tooltip: {
      formatter: function () {
        // Check if the value is an integer or a decimal
        const formattedY = Number.isInteger(this.point.y)
          ? this?.point.y
          : this?.point?.y?.toFixed(2);

        // Construct the tooltip HTML content
        return (
          `<span style="font-size:10px">${this.key}</span><table>` +
          `<tr><td style="color:${this.series.color};padding:0">${this.series.name}: </td>` +
          `<td style="text-align:right; padding:0"><b>${formattedY}</b></td></tr></table>`
        );
      },
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
        borderRadius: 10,
      },
    },
    series: [
      {
        name: "Performance Index",
        showInLegend: false,
        lineWidth: 2,
        color: "#AC80F7",
        data, // Property shorthand
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
            // minWidth
          },
          legend: {
            enabled: false,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
};
export const projectGraphOptions = (data, label) => {
  console.log("datachart", data);

  data = data?.map((charts) => ({
    ...charts,
    data: charts.data.map((e) => e * 10),
  }));
  return {
    chart: {
      inverted: false,
      type: "column",
      height: null,
    },
    title: {
      text: "",
      align: "left",
    },
    credits: {
      enabled: false,
    },
    labels: {
      enabled: false,
    },
    yAxis: {
      title: null, // Property shorthand
      accessibility: {
        rangeDescription: "Range: 0 to 100",
      },
      min: 0,
      max: 100,
      tickInterval: 20,
    },
    xAxis: { categories: [...label] },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    // plotOptions: {
    //   series: {
    //     label: {
    //       connectorAllowed: false,
    //     },
    //     pointStart: 0,
    //     borderRadius: 10,
    //   },
    // },
    tooltip: {
      headerFormat: "<table>",
      // pointFormat:
      //   '<tr><td  style="color:{series.color}">{data.projectShortcode}:</td style="text-align: right"><td ><b>{point.y}</b> ({point.percentage:.0f}%)</td></tr>',
      pointFormat:
        '<tr><td style="color:{series.color}">{series.name}:</td><td style="text-align: right"><b>{point.y}</b> ({point.percentage:.0f}%)</td></tr>',

      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 20,
      },
    },
    // series: [
    //   {
    //     showInLegend: false,
    //     lineWidth: 2,
    //     color: "#8ceb34",
    //     data, // Property shorthand
    //   },
    // ],
    series: data,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 200,
            // minWidth
          },
          legend: {
            enabled: false,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
};
