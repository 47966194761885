/* eslint-disable react/prop-types */
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";

export const DateFormat = ({ TaskData, dbName }) => {
  function formatDate(dateString) {
    if (!dateString) return "";
    const parsedDate = new Date(dateString);
    return format(parsedDate, "dd-MM-yyyy HH:mm");
  }

  const formattedDate = formatDate(TaskData[dbName]);

  return (
    <OverlayTrigger
      trigger="hover"
      placement="top"
      overlay={<Tooltip id={TaskData?._id}>{formattedDate}</Tooltip>}
    >
      <div>{formattedDate?.slice(0, 10)}...</div>
    </OverlayTrigger>
  );
};
