import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  ArrowRightIco,
  ProjectsIco,
  SearchIco,
  SprintFolderIcon,
  UncategorizedListIcon,
} from "./svgIcons";
import ProjectTile from "./components/ProjectTile";
import FolderTile from "./components/FolderTile";
import ListTile from "./components/ListTile";
import SprintTile from "./components/SprintTile";
import { useThunk } from "../../hooks/useThunk";
import { listPatch } from "../../store/slices/project/thunks/patchList";
import { searchFunction } from "./utils";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

const RenderProjectMenu = ({ projects }) => {
  const { shortCode, listId: paramId } = useParams();
  const [modalSwitch, setModalSwitch] = useState("");
  const [projectState, setProjectState] = useState([]);
  const [listPatchThunk] = useThunk(listPatch);
  const switchModal = (value) => setModalSwitch(value);
  const [folderToBeRenamed, setFolderToBeRenamed] = useState("");
  const [listToBeRenamed, setListToBeRenamed] = useState("");
  const [projectOptionsToBeShown, setProjectOptionsToBeShown] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const toggleProjectOptions = (value) => {
    if (projectOptionsToBeShown.includes(value))
      setProjectOptionsToBeShown(
        projectOptionsToBeShown.filter((e) => e !== value),
      );
    else setProjectOptionsToBeShown([...projectOptionsToBeShown, value]);
  };
  const [dropIndex, setDropIndex] = useState(null);
  const [eachProjectSearchInput, setEachProjectSearchInput] = useState({});

  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.BASIC_PROJECT_SETTINGS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const hasListDisplayPermission = hasPermission(
    permissionConstants.permissions.PROJECT_LISTS_LISTING,
  );
  const hasSprintDisplayPermission = hasPermission(
    permissionConstants.permissions.PROJECT_SPRINT_LISTING,
  );

  useEffect(() => {
    setProjectState(projects);
  }, [projects]);

  useEffect(() => {
    let tempOptions = [...projectOptionsToBeShown];
    if (dropIndex) tempOptions = [...tempOptions, dropIndex];
    if (shortCode) tempOptions = [...tempOptions, "default"];
    if (paramId && projectState) {
      const projectIndex = projectState.findIndex(
        (e) => e.shortCode === shortCode,
      );
      const folders = projectState.find(
        (e) => e?.shortCode === shortCode,
      )?.folders;
      const folderIndex = folders
        ? folders.findIndex((e) => e?.list.some((e) => e?._id === paramId))
        : -1;
      if (projectIndex >= 0) {
        tempOptions = [...tempOptions, projectIndex.toString()];
        if (folderIndex >= 0)
          tempOptions = [...tempOptions, `${projectIndex}-${folderIndex}`];
      }
    }
    setProjectOptionsToBeShown([...new Set(tempOptions)]);
  }, [paramId, projectState, shortCode, dropIndex]);

  useEffect(() => {
    if (projects && projects[0]) {
      const searchValue = searchInput.trim().toLowerCase();
      let tempOptions = [
        ...projectOptionsToBeShown.filter((e) => e === "default"),
      ];
      if (!searchValue && projectOptionsToBeShown.length)
        tempOptions = ["default"];
      const { filteredState, toggleOptions } = searchFunction(
        searchValue,
        projects,
        tempOptions,
      );
      setProjectOptionsToBeShown([...toggleOptions]);
      setProjectState(searchInput.trim().length ? filteredState : projects);
    }
  }, [searchInput]);

  const filterEachProject = (value, id) => {
    if (projects && projects[0]) {
      const searchValue = value?.trim().toLowerCase();
      const tempOptions = [...projectOptionsToBeShown];
      const { filteredState, toggleOptions } = searchFunction(
        searchValue,
        projects,
        tempOptions,
        id,
      );
      setProjectOptionsToBeShown([...toggleOptions]);
      if (typeof value === "string")
        setProjectState(value?.trim().length ? filteredState : projects);
    }
  };

  return (
    <div className="menuItem  d-flex align-items-center">
      <div
        className="linksActions"
        type="button"
        onClick={() => toggleProjectOptions("default")}
      >
        <div className="menuLink d-flex align-items-center">
          <span className="icon">
            <ProjectsIco />
          </span>
          <span className="txt">Projects</span>
        </div>
        <span className="actions d-flex">
          <span
            className="search"
            onClick={(e) => {
              setShowSearch(
                projectOptionsToBeShown.includes("default")
                  ? !showSearch
                  : true,
              );
              if (!projectOptionsToBeShown.includes("default"))
                setProjectOptionsToBeShown([
                  ...projectOptionsToBeShown,
                  "default",
                ]);
              e.stopPropagation();
            }}
          >
            <SearchIco />
          </span>

          <span
            className={
              projectOptionsToBeShown.includes("default")
                ? "dropdown open"
                : "dropdown"
            }
          >
            <ArrowRightIco />
          </span>
        </span>
      </div>
      <div
        className={
          projectOptionsToBeShown.includes("default")
            ? "submenu"
            : "submenu menu-hide"
        }
        id="projectsMenu"
      >
        {showSearch && (
          <div className="sidenavSearch">
            <div className="searchOuter">
              <div className="ico">
                <SearchIco />
              </div>
              <input
                value={searchInput}
                onChange={({ target: { value } }) => {
                  setSearchInput(value);
                }}
                className="form-control"
                placeholder="search projects"
              />
            </div>
          </div>
        )}
        <div className="submenuLevel1">
          {!projectState.length && <div className="txt">No Projects Found</div>}
          {/* submenu level1 list  */}
          <ul>
            {/* submenu level1 item */}

            <li>
              {/* submenu Level1  */}
              {projectState &&
                projectState.map(
                  (
                    { name, folders, shortCode, lists, _id, sprint },
                    projectIndex,
                  ) => (
                    <DragDropContext
                      key={projectIndex}
                      onDragEnd={(result) => {
                        if (result.destination) {
                          const list =
                            folders
                              .flatMap((folder) => folder.list)
                              .find(
                                (eachList) =>
                                  eachList._id === result.draggableId,
                              ) ??
                            lists.find(({ _id }) => _id === result.draggableId);
                          if (
                            result.destination.droppableId.split(" ")[1] ===
                            "folder"
                          )
                            listPatchThunk({
                              id: result.draggableId,
                              patchData: {
                                isIndependent: true,
                              },
                              dropIndex: result.destination.index,
                              project: _id,
                              list,
                            });
                          else {
                            listPatchThunk({
                              id: result.draggableId,
                              patchData: {
                                folder: result.destination.droppableId,
                              },
                              project: _id,
                              list,
                              dropIndex: result.destination.index,
                            });
                            const folderIndex = folders.findIndex(
                              (e) => e._id === result.destination.droppableId,
                            );
                            if (folderIndex >= 0)
                              setDropIndex(`${projectIndex}-${folderIndex}`);
                          }
                        }
                      }}
                    >
                      <ProjectTile
                        id={_id}
                        key={projectIndex}
                        name={name}
                        folders={folders}
                        shortCode={shortCode}
                        projectIndex={projectIndex}
                        toggleState={projectOptionsToBeShown}
                        setToggleState={toggleProjectOptions}
                        lists={lists}
                        sprint={sprint}
                        modalSwitch={modalSwitch}
                        switchModal={switchModal}
                        filterEachProject={filterEachProject}
                        eachProjectSearchInput={eachProjectSearchInput}
                        setEachProjectSearchInput={setEachProjectSearchInput}
                      >
                        {folders.map((eachFolder, folderIndex) => (
                          <Droppable
                            droppableId={eachFolder._id}
                            key={eachFolder._id}
                          >
                            {(provided) => (
                              <div
                                className="sub2_item  d-flex align-items-center"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <FolderTile
                                  key={folderIndex}
                                  eachFolder={eachFolder}
                                  folderIndex={folderIndex}
                                  projectIndex={projectIndex}
                                  setToggleState={toggleProjectOptions}
                                  toggleState={projectOptionsToBeShown}
                                  switchModal={switchModal}
                                  modalSwitch={modalSwitch}
                                  projectId={_id}
                                  listCount={eachFolder.list?.length ?? 0}
                                  folderToBeRenamed={folderToBeRenamed}
                                  setFolderToBeRenamed={setFolderToBeRenamed}
                                  shortCode={shortCode}
                                >
                                  {eachFolder.list.map(
                                    (eachList, eachListIndx) => (
                                      <Draggable
                                        draggableId={eachList._id}
                                        index={eachListIndx}
                                        key={eachList._id}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <ListTile
                                              key={eachListIndx}
                                              eachList={eachList}
                                              shortCode={shortCode}
                                              projectId={_id}
                                              listId={eachList._id}
                                              listName={eachList.name}
                                              listToBeRenamed={listToBeRenamed}
                                              setListToBeRenamed={
                                                setListToBeRenamed
                                              }
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ),
                                  )}
                                </FolderTile>
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        ))}
                        {hasSprintDisplayPermission && (
                          <div className="sub2_item d-flex align-items-center">
                            {!!sprint?.length && (
                              <div className="unlist-menu">
                                <span className="text-success">
                                  <SprintFolderIcon />
                                </span>
                                Sprints
                              </div>
                            )}
                            <div className="sub3_item extList">
                              <ul>
                                {sprint.map((eachSprint) => (
                                  <SprintTile
                                    key={eachSprint._id}
                                    eachSprint={eachSprint}
                                    shortCode={shortCode}
                                    projectId={_id}
                                    sprintId={eachSprint._id}
                                    sprintName={eachSprint.name}
                                  />
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        <Droppable droppableId={`${_id} folder`}>
                          {(provided) => (
                            <div
                              className="sub2_item  d-flex align-items-center"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {hasListDisplayPermission && (
                                <div className="sub2_item d-flex align-items-center">
                                  <div className="unlist-menu">
                                    <span className="text-success">
                                      <UncategorizedListIcon />
                                    </span>
                                    Uncategorized List
                                  </div>
                                  <div className="sub3_item extList">
                                    <ul>
                                      {lists?.map((eachList, eachListIndex) => (
                                        <Draggable
                                          draggableId={eachList._id}
                                          index={eachListIndex}
                                          key={eachList._id}
                                        >
                                          {(provided) => (
                                            <div
                                              key={eachList._id}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <ListTile
                                                eachList={eachList}
                                                shortCode={shortCode}
                                                projectId={_id}
                                                listId={eachList._id}
                                                listName={eachList.name}
                                                listToBeRenamed={
                                                  listToBeRenamed
                                                }
                                                setListToBeRenamed={
                                                  setListToBeRenamed
                                                }
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </ProjectTile>
                    </DragDropContext>
                  ),
                )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

RenderProjectMenu.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape()),
};

export default RenderProjectMenu;
