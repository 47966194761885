import { createSlice } from "@reduxjs/toolkit";

export const name = "role";

const createReducers = () => {
  const setRoleList = (state, { payload }) => {
    state.roleList = payload.data;
  };
  const setRolePermissions = (state, { payload }) => {
    state.rolePermissions = payload.rolePermissions;
  };
  return {
    setRoleList,
    setRolePermissions,
  };
};

function createInitialState() {
  return {
    roleList: [],
    rolePermissions: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const roleActions = slice.actions;
export const roleReducer = slice.reducer;
