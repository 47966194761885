export const DoubleTick = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.519"
    height="12.728"
    viewBox="0 0 26.519 12.728"
  >
    <g
      id="Group_59008"
      data-name="Group 59008"
      transform="translate(-6398.427 -3916.355)"
    >
      <path
        id="Icon_awesome-check"
        data-name="Icon awesome-check"
        d="M5.1,15.556.22,10.675a.751.751,0,0,1,0-1.062L1.282,8.552a.751.751,0,0,1,1.062,0l3.288,3.288,8.543-8.572a.751.751,0,0,1,1.062,0L16.3,4.33a.751.751,0,0,1,0,1.062L6.163,15.556A.751.751,0,0,1,5.1,15.556Z"
        transform="translate(6398.427 3913.307)"
        fill="#ac80f7"
      />
      <path
        id="Icon_awesome-check-2"
        data-name="Icon awesome-check"
        d="M5.1,15.556,1.637,12.348a.751.751,0,0,1,0-1.062L2.7,10.224a.751.751,0,0,1,1.062,0l1.871,1.615,8.543-8.572a.751.751,0,0,1,1.062,0L16.3,4.33a.751.751,0,0,1,0,1.062L6.163,15.556A.751.751,0,0,1,5.1,15.556Z"
        transform="translate(6408.427 3913.307)"
        fill="#ac80f7"
      />
    </g>
  </svg>
);

export const SingleTick = () => (
  <svg
    className="feather feather-check"
    fill="none"
    height="24"
    stroke="#AC80F7"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

export const MandatoryIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.888"
    height="12.728"
    viewBox="0 0 25.888 12.728"
  >
    <g
      id="Group_59750"
      data-name="Group 59750"
      transform="translate(-1507 -440)"
    >
      <path
        id="Icon_awesome-check"
        data-name="Icon awesome-check"
        d="M5.1,15.556.22,10.675a.751.751,0,0,1,0-1.062L1.282,8.552a.751.751,0,0,1,1.062,0l3.288,3.288,8.543-8.572a.751.751,0,0,1,1.062,0L16.3,4.33a.751.751,0,0,1,0,1.062L6.163,15.556A.751.751,0,0,1,5.1,15.556Z"
        transform="translate(1507 436.952)"
        fill="#ac80f7"
      />
      <path
        id="Path_9790"
        data-name="Path 9790"
        d="M-2.232-6.672-.984-8.4l-1.9-.672.48-1.088L-.7-9.536v-1.648H.7v1.648l1.712-.64.48,1.1-1.9.656L2.248-6.688l-1.088.7L.008-7.6-1.144-5.968Z"
        transform="translate(1530 451.184)"
        fill="#f56b6b"
      />
    </g>
  </svg>
);

export const DisableClick = () => (
  <svg
    className="feather feather-check"
    fill="none"
    height="24"
    stroke="#AC80F7"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);
