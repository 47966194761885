import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { getNotes } from "./getNotes";
import { alertActions } from "../../alert";
import notesServices from "../../../../services/notesServices";

export const updateNotes = createAsyncThunk(
  "update-emailAccount",
  async ({ id, data }, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await notesServices.updateNotes({
        id,
        data,
      });
      dispatch(
        getNotes({
          page: 1,
          limit: 50,
        }),
      );
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
