import { createSlice } from "@reduxjs/toolkit";

export const name = "project";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.projectList = null;
    state.isLoggedIn = false;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setProjectList = (state, { payload }) => {
    state.projectList = payload.data;
    state.successMessage = payload.successMessage;
    state.errorMessage = "";
    state.isLoggedIn = true;
  };
  const setSingleProject = (state, { payload }) => {
    state.singleProject = payload.singleProject;
    state.successMessage = payload.successMessage;
    state.errorMessage = "";
    state.isLoggedIn = true;
  };
  const setSingleNotification = (state, { payload }) => {
    state.singleNotification = payload.singleNotification;
    state.successMessage = payload.successMessage;
    state.errorMessage = "";
    state.isLoggedIn = true;
  };
  return {
    setSingleProject,
    setSingleNotification,
    setProjectList,
    setErrorMesage,
    resetErrorMesage,
  };
};

function createInitialState() {
  return {
    errorMessage: "",
    successMessage: "",
    singleProject: null,
    singleNotification: null,
    projectList: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const projectActions = slice.actions;
export const projectReducer = slice.reducer;
