import PropTypes from "prop-types"; // Import prop-types
import CommonSkeleton from "./CommonSkeleton";

const CommonSkeletonTableRows = ({ limit, cols, height, istableHead }) => {
  return Array?.from({ length: limit || 0 })?.map((_, index) =>
    istableHead ? (
      <tr key={index}>
        {Array?.from({ length: cols || 0 })?.map((_, each) => (
          <th key={each}>
            <CommonSkeleton height={height} />
          </th>
        ))}
      </tr>
    ) : (
      <tr key={index}>
        {Array?.from({ length: cols || 0 })?.map((_, each) => (
          <td key={each}>
            <CommonSkeleton height={height} />
          </td>
        ))}
      </tr>
    ),
  );
};

// Add prop validation
CommonSkeletonTableRows.propTypes = {
  limit: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CommonSkeletonTableRows;
