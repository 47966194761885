import PropTypes from "prop-types";
import { useThunk } from "../../../../../hooks/useThunk";
import CustomModal from "../../../../../components/CustomModal";
import disconnet from "../../../../../assets/images/disconnet.svg";
import { dissconnectGit } from "../../../../../store/slices/gitIntegration/thunks/dissconnectGit";
// import { getUserProfile } from "../../../../../store";

export const DisconectModal = ({ showModal, setShowModal }) => {
  const [dissconnectGitHub, isLoading] = useThunk(dissconnectGit);
  // const [getUserDetails] = useThunk(getUserProfile);
  const renderHeader = () => (
    <h1 className="modal-title" id="exampleModalLabel">
      <div className="txt">Disconnect GitHub?</div>
    </h1>
  );

  const renderBody = () => (
    <div className="disconnect-info">
      <div className="disconnect-img">
        <img src={disconnet} alt="" />
      </div>
      <div className="disconnect-content">
        <p>
          if you disconnect your github account, the github integration for your
          entire workspace will be deactivated. are you sure?
        </p>
      </div>
    </div>
  );
  const renderFooter = () => (
    <div className="modal-footer">
      <div className="btn-block">
        <button
          className="modal-btn btn-confirm btn"
          type="button"
          onClick={() => {
            dissconnectGitHub();
            setShowModal(false);
            // getUserDetails();
          }}
        >
          {isLoading ? "Loading..." : "Yes, Continue"}
        </button>
        <button
          className="modal-btn btn-cancel btn"
          type="button"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
  return (
    <CustomModal
      className="modal fade manage-integ disconnect-conf"
      dialgName="modal-md modal-dialog-centered"
      header={renderHeader()}
      footer={renderFooter()}
      createModal={7}
      disableCenter={7}
      show={!!showModal}
      closeModal={() => {
        setShowModal(false);
      }}
      body={renderBody()}
    />
  );
};
DisconectModal.propTypes = {
  showModal: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    docId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
DisconectModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
