import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import userProileById from "../../../../services/userProfileService";
import { userProfileActions } from "..";
import { alertActions } from "../../alert/index";

export const getMultyUserProfile = createAsyncThunk(
  "getUsers",
  async (payload, { dispatch }) => {
    dispatch(userProfileActions.setUserProfileLoader(true));
    dispatch(alertActions.clear());

    try {
      const {
        data: { data },
      } = await userProileById.getUsersById(payload);

      dispatch(userProfileActions.setMultyUserProfileData(data));
      dispatch(userProfileActions.setUserProfileLoader(false));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
      dispatch(userProfileActions.setUserProfileLoader(false));
      dispatch(userProfileActions.setShowUserProfile(false));
    }
  },
);
