import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import technologyService from "../../../../services/technology";
import { technologyActions } from "../index";
import { getAllTechnologies } from "./getAllTechnology";

export const addTechnologyById = createAsyncThunk(
  "technology",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await technologyService.addTechnology(payload);
      dispatch(getAllTechnologies(payload.project));
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(technologyActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
