import { createSlice } from "@reduxjs/toolkit";

export const name = "project";

const createInitialState = () => {
  return {
    projectData: [],
    projectById: [],
    projectTabCount: {},
    folderCreateSuccessMessage: null,
    listCreateSuccessMessage: null,
  };
};

const createReducers = () => {
  const setProjectData = (state, { payload }) => {
    state.projectData = payload.data;
  };
  const setProjectDataById = (state, { payload }) => {
    state.projectById = payload.data;
  };
  const setFolderCreateSuccessMessage = (state, { payload }) => {
    state.folderCreateSuccessMessage = payload.successMessage;
  };
  const setListCreateSuccessMessage = (state, { payload }) => {
    state.listCreateSuccessMessage = payload.successMessage;
  };
  const projectTabCounts = (state, { payload }) => {
    state.projectTabCount = payload.data;
  };
  const patchListAction = (state, { payload }) => {
    console.log("ddd", payload.data.dropIndex);
    const updatedProjects = [...state.projectData];

    const projectIndex = updatedProjects.findIndex(
      (project) => project._id === payload.data.project,
    );

    if (
      updatedProjects[projectIndex].folders
        .flatMap((folder) => folder.list)
        .find((eachList) => eachList._id === payload.data._id)
    )
      updatedProjects[projectIndex].folders = updatedProjects[
        projectIndex
      ].folders.map((each) => ({
        ...each,
        list: each.list.filter(({ _id }) => _id !== payload.data._id),
      }));

    if (
      updatedProjects[projectIndex].folders.find(
        ({ _id }) => _id === payload.data.folder,
      )
    )
      updatedProjects[projectIndex].folders = updatedProjects[
        projectIndex
      ].folders.map((each) => {
        if (each._id === payload.data.folder)
          return {
            ...each,
            list: [
              ...each.list.slice(0, payload.data.dropIndex),
              payload.data.list,
              ...each.list.slice(payload.data.dropIndex),
            ],
          };
        return each;
      });
    if (
      updatedProjects[projectIndex].lists.find(
        (eachList) => eachList._id === payload.data._id,
      )
    )
      updatedProjects[projectIndex].lists = updatedProjects[
        projectIndex
      ].lists.filter((eachList) => eachList._id !== payload.data._id);
    if (payload.data.isIndependent)
      updatedProjects[projectIndex].lists = [
        ...updatedProjects[projectIndex].lists.slice(0, payload.data.dropIndex),
        payload.data.list,
        ...updatedProjects[projectIndex].lists.slice(payload.data.dropIndex),
      ];

    state.projectData = updatedProjects;
  };

  return {
    setProjectData,
    setProjectDataById,
    patchListAction,
    projectTabCounts,
    setFolderCreateSuccessMessage,
    setListCreateSuccessMessage,
  };
};

const initialState = createInitialState();
const reducers = createReducers();
const projectSlice = createSlice({
  name,
  initialState,
  reducers,
});

export const projectActions = projectSlice.actions;
export const projectReducer = projectSlice.reducer;

export default projectReducer;
