import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import "../../design/custom/pages/dashboard.scss";
import { useLocation, useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoModal } from "./components/infoModal";
import { Deletemodal } from "./components/DeleteModal";
import CustomInfinityTable from "../../components/CustomInfinityTable";
import SubHeader from "../../structure/SubHeader/SubHeader";
import AddModal from "./components/AddModal";
import { useThunk } from "../../hooks/useThunk";
import { userProfileActions } from "../../store/slices/userProfile";
import { getMultyUserProfile } from "../../store";
import { getUserDocuments } from "../../store/slices/documents/thunks/getdocuments";
import { clearDocument } from "../../store/slices/documents/thunks/clear";
import { deleteDocs } from "../../store/slices/documents/thunks/deletedocument";
import { useFilter } from "../../context/useFilter";
import { filterQuery } from "./docFilter/DocListFilter";
import { getFilterDropdown } from "../../store/slices/filter/thunks/getFilterDropdown";
import {
  modulePermissionHasPermissions,
  modulePermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import { getNameAndUniqId } from "../../components/common/iconDefaultName";
import { convertBytesToKBMB } from "./utility";
import infoImage from "../../assets/images/infopanel.svg";
import deleteImage from "../../assets/images/docdelete.svg";
import docImage from "../../assets/images/doc-1.svg";
import pdfImage from "../../assets/images/pdf.svg";
import xlsImage from "../../assets/images/xls.svg";
import pptImage from "../../assets/images/ppt.svg";
import textLogo from "../../assets/images/text.svg";
import imageLogo from "../../assets/images/image.svg";
import { AddBtnWhite, Multipleimage } from "./svgicons";
import t1 from "../../assets/images/t1.svg";
import downloadImg from "../../assets/images/download-icon.svg";
import useTitleContext from "../../hooks/useTitle";

function Documents() {
  const { setTitle } = useTitleContext();

  const dispatch = useDispatch();
  const { shortCode } = useParams();
  const { pathname } = useLocation();

  const [deleteModal, setDeleteModal] = useState({
    state: false,
    id: "",
    name: "",
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    limit: 15,
  });
  const [showInfoModal, setshowInfoModal] = useState({
    state: "!panel-open",
    userId: "",
  });
  const [increment, setIncrement] = useState(100);
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(null);

  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [deleteDocuments, deleteLoader] = useThunk(deleteDocs);
  const [getDocuments, isLoading] = useThunk(getUserDocuments);
  const [clear] = useThunk(clearDocument);
  const [filterData] = useThunk(getFilterDropdown);
  const { filter } = useFilter();

  const { documentData, successMessage, projectId } = useSelector(
    (state) => state.document,
  );
  const { userProfile } = useSelector((state) => state.userProfile);

  console.log("DocssuccessMessage", successMessage);

  // permission
  const checkPermission = permissionConstants.permissions;

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      // PERMISSION FOR MAIN DOCS
      if (!shortCode) {
        const permissions = modulePermissions(
          userProfile?.formattedPermissions,
          permissionConstants.modules.DOCUMENTS,
        );
        return modulePermissionHasPermissions(permissions, permission);
      }
      // PERMISSION FOR PROJECT DOCS
      if (shortCode) {
        const permissions = subModulePermissions(
          userProfile?.formattedPermissions,
          permissionConstants.modules.PROJECTS_MANAGEMENT,
          permissionConstants.submodules.PROJECT_DOCUMENTS,
        );
        return modulePermissionHasPermissions(permissions, permission);
      }
    }
    return false;
  };

  const matches = filterQuery(filter.filter);

  const stateUpdate = useCallback(
    matches && matches[Object.keys(matches)[0]].map(({ value }) => value),
    [filter.filter],
  );

  useEffect(() => {
    console.log("pathname", pathname);
    if (pathname === "/docs") setTitle("Documents");
  }, [pathname]);

  useEffect(() => {
    if (params) getDocuments(params);
  }, [params]);

  useEffect(() => {
    if (params) setParams({ ...params, matches });
  }, [stateUpdate]);

  useEffect(() => {
    filterData("docs");
  }, []);

  useEffect(() => {
    return () => {
      clear("clearAll");
      filterData("docs");
    };
  }, []);

  useEffect(() => {
    if (successMessage) {
      getDocumentList();
      setshowInfoModal({ state: "!panel-open", userId: "" });
      setDeleteModal({ state: false, docId: "", name: "" });
      clear("clearAll");
    }
  }, [successMessage]);

  useEffect(() => {
    getDocumentList();
  }, [increment, filter.search]);

  const getDocumentList = () => {
    setParams({
      page: 1,
      limit: increment,
      location: shortCode ?? "Global",
      search: filter.search,
      projectId: projectId ?? null,
    });
  };

  const handleInfoClick = (id) => {
    setshowInfoModal({ state: "panel-open", userId: id });
  };

  const handleDeleteClick = (id, title) => {
    setDeleteModal({ state: true, docId: id, name: title });
  };

  const getIconForFileType = (fileType) => {
    const fileName = fileType?.split("/").pop();
    const extension = fileName?.split(".").pop().toLowerCase();
    const iconMapping = {
      pdf: pdfImage,
      docx: docImage,
      doc: docImage,
      xlsx: xlsImage,
      xls: xlsImage,
      ppt: pptImage,
      pptx: pptImage,
      png: imageLogo,
      jpg: imageLogo,
      jpeg: imageLogo,
      csv: xlsImage,
      txt: textLogo,
    };
    return iconMapping[extension];
  };

  const displayData = (documentData) => {
    return documentData.map((item, index) => ({
      id: index,
      FileName: (
        <div className="docsList">
          {item.files.length > 1 ? (
            <span className="ico">
              <Multipleimage />
            </span>
          ) : (
            <span className="ico">
              <img
                className="img-fluid"
                src={getIconForFileType(item.files[0]?.fileType)}
                alt=""
              />
            </span>
          )}
          <span className="txt">{item.title}</span>
        </div>
      ),
      locatedIn: (
        <div className="located">
          <span className="ico">
            <img src={t1} alt="" />
          </span>
          <div className="txt">{item.location}</div>
        </div>
      ),
      description: (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="docs_delete">{item.description}</Tooltip>}
        >
          <div className="tbl-cont">{item.description}</div>
        </OverlayTrigger>
      ),
      uploadedOn: (
        <div className="tbl-cont">
          {new Date(item.createdAt).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
          })}
          {" at "}
          {new Date(item.createdAt).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </div>
      ),
      fileSize: (
        <div className="tbl-cont">
          {(() => {
            const totalSizeKB = item.files.reduce(
              (total, subItem) => total + subItem.size,
              0,
            );
            return convertBytesToKBMB(totalSizeKB);
          })()}
        </div>
      ),
      uploadedBy: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            getMultyUsers(item.createdByUser._id);
            dispatch(userProfileActions.setShowUserProfile(true));
          }}
          style={{ cursor: "pointer" }}
        >
          <span className="ico usr">
            {item.createdByUser?.photo ? (
              <img
                className="img-fluid profile__picture"
                src={`https://erptest.spericorn.com/repo/uploads/${item.createdByUser.photo}`}
                alt=""
              />
            ) : (
              <span
                className={`name userProf bg-${
                  getNameAndUniqId(item.createdByUser?.employeeName)?.uniqId
                }`}
              >
                {getNameAndUniqId(item.createdByUser?.employeeName)?.initials}
              </span>
            )}
          </span>
          {item.createdByUser?.employeeName}
        </div>
      ),

      action: (
        <div className="d-flex">
          {hasPermission(
            shortCode
              ? checkPermission.PROJECT_DOCUMENTS_DOWNLOAD
              : checkPermission.DOCUMENTS_DOWNLOAD,
          ) && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="docs_download">Download</Tooltip>}
            >
              {downloadLoader !== item._id ? (
                <span className="mx-2 share">
                  <a
                    href={item?.files[0]?.url}
                    onClick={() => {
                      setDownloadLoader(item._id);
                      setTimeout(() => {
                        setDownloadLoader(null);
                      }, 2000);
                    }}
                    download
                  >
                    <img src={downloadImg} alt="" />
                  </a>
                </span>
              ) : (
                <span className="btn-loader mx-2">
                  <span className="spinner-border spinner-border-sm" />
                </span>
              )}
            </OverlayTrigger>
          )}
          {hasPermission(
            shortCode
              ? checkPermission.PROJECT_DOCUMENTS_VIEW
              : checkPermission.DOCUMENTS_VIEW,
          ) && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="docs_view">View</Tooltip>}
            >
              <span
                className="mx-2 share cursor-point"
                onClick={() => handleInfoClick(item._id)}
              >
                <img src={infoImage} alt="" />
              </span>
            </OverlayTrigger>
          )}
          {hasPermission(
            shortCode
              ? checkPermission.PROJECT_DOCUMENTS_DELETE
              : checkPermission.DOCUMENTS_DELETE,
          ) && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="docs_delete">Delete</Tooltip>}
            >
              <span
                className="mx-2 share cursor-point"
                onClick={() => handleDeleteClick(item._id, item.title)}
              >
                <img src={deleteImage} alt="" />
              </span>
            </OverlayTrigger>
          )}
        </div>
      ),
    }));
  };

  const data = displayData(documentData.requests ?? []);

  const cols = [
    {
      name: "Title",
      selector: "FileName",
      titleClasses: "",
      activitySort: true,
      permission: hasPermission(
        shortCode
          ? checkPermission.PROJECT_DOCUMENTS_LIST
          : checkPermission.DOCUMENTS_LIST,
      ),
    },
    {
      name: "Located in",
      titleClasses: "",
      selector: "locatedIn",
      activitySort: true,
      permission: hasPermission(
        shortCode
          ? checkPermission.PROJECT_DOCUMENTS_LOCATED_IN
          : checkPermission.DOCUMENTS_LOCATED_IN,
      ),
    },
    {
      name: "Description",
      titleClasses: "",
      selector: "description",
      activitySort: true,
      permission: hasPermission(
        shortCode
          ? checkPermission.PROJECT_DOCUMENTS_DESCRIPTION
          : checkPermission.DOCUMENTS_DESCRIPTION,
      ),
    },
    {
      name: "Uploaded on",
      titleClasses: "",
      selector: "uploadedOn",
      activitySort: true,
      permission: hasPermission(
        shortCode
          ? checkPermission.PROJECT_DOCUMENTS_UPLOADED_ON
          : checkPermission.DOCUMENTS_UPLOADED_ON,
      ),
    },
    {
      name: "File size",
      titleClasses: "",
      selector: "fileSize",
      activitySort: true,
      permission: hasPermission(
        shortCode
          ? checkPermission.PROJECT_DOCUMENTS_FILESIZE
          : checkPermission.DOCUMENTS_FILESIZE,
      ),
    },
    {
      name: "Uploaded by",
      titleClasses: "",
      selector: "uploadedBy",
      activitySort: true,
      permission: hasPermission(
        shortCode
          ? checkPermission.PROJECT_DOCUMENTS_UPLOADED_BY
          : checkPermission.DOCUMENTS_UPLOADED_BY,
      ),
    },
    {
      name: "Action",
      titleClasses: "",
      selector: "action",
      activitySort: true,
      permission: hasPermission(
        shortCode
          ? checkPermission.PROJECT_DOCUMENTS_ACTION
          : checkPermission.DOCUMENTS_ACTION,
      ),
    },
  ];

  const documentCol = cols.filter(({ permission }) => permission);

  const handleDeletes = (docId) => {
    deleteDocuments({ id: docId, location: shortCode ?? "Global" });
  };

  return (
    <>
      {!shortCode && hasPermission(checkPermission.DOCUMENTS_SEARCH) && (
        <SubHeader showSearchBar showFilter />
      )}
      <div
        className={`title-otr d-flex flex-wrap align-items-center ${
          shortCode ? "" : "mt-3"
        }`}
      >
        <div className="page-title">
          <h2 className="table-title">{shortCode ? "Documents" : ""}</h2>
        </div>
        <div className="ms-auto rgt-sctn">
          {hasPermission(
            shortCode
              ? checkPermission.PROJECT_DOCUMENTS_ADD_NEW_DOC
              : checkPermission.DOCUMENTS_ADD_NEW_DOC,
          ) && (
            <button
              className="btn btn-primary add-btn-hdr"
              type="button"
              onClick={() => setShowModal(true)}
            >
              <span>
                <AddBtnWhite />
              </span>
              {"  "}
              New Doc
            </button>
          )}
        </div>
      </div>
      <div className="department-list-cover docs-outer-wrap">
        {deleteModal.state && (
          <Deletemodal
            ModalShow={deleteModal}
            handleDeletes={handleDeletes}
            loaderButton={deleteLoader}
            closeModal={() => {
              setDeleteModal({ state: false, docId: "", name: "" });
            }}
            cancelModal={() => {
              setDeleteModal({ state: false, docId: "", name: "" });
            }}
          />
        )}

        {showInfoModal.state && (
          <InfoModal
            showInfoModal={showInfoModal}
            onClose={() => {
              setshowInfoModal({ state: "!panel-open", userId: "" });
              clear("clearInfoPanel");
            }}
          />
        )}
        <AddModal
          showModal={showModal}
          closeModal={() => {
            setShowModal(false);
          }}
          cancelModal={() => {
            setShowModal(false);
          }}
        />

        <BottomScrollListener
          onBottom={() => {
            if (increment < documentData.totalCount) {
              setIncrement(increment + 5);
              setPagination({ ...pagination, limit: 5 });
            }
          }}
        >
          <CustomInfinityTable
            showtableName={false}
            tableClassName="table custom-table docs-table"
            divClassName="table-responsive"
            columns={documentCol}
            data={
              hasPermission(
                shortCode
                  ? checkPermission.PROJECT_DOCUMENTS_LIST
                  : checkPermission.DOCUMENTS_LIST,
              ) && data
            }
            isLoading={isLoading}
            pagination={pagination}
            showPagination={false}
            onPageChange={(currentPage) =>
              setPagination({ ...pagination, currentPage })
            }
          />
        </BottomScrollListener>
      </div>
    </>
  );
}

export default Documents;
