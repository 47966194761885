/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
// import { useState } from "react";
// import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { isEqual } from "lodash";
import * as XLSX from "xlsx";

// import { useState } from "react";
import { withTitle } from "../../hoc/withTitle";
import TodoImage from "../../assets/images/card-sm-1.svg";
import InprogressImage from "../../assets/images/card-sm-2.svg";
import completedImage from "../../assets/images/card-sm-3.svg";
import performImage from "../../assets/images/card-sm-4.svg";
import HoursImage from "../../assets/images/card-sm-5.svg";
import qualityImage from "../../assets/images/card-sm-6.svg";
import Counter from "../../components/DashBoard/Counter";
import "../../design/custom/pages/time-sheet.scss";
import SubHeader from "../../structure/SubHeader/SubHeader";
import { getAllUsersList } from "../../store/slices/allocationList/thunks/getAllUsersList";
import { useThunk } from "../../hooks/useThunk";
import { getJokes, getMultyUserProfile, getUserTimesheet } from "../../store";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import { getTimesheetUsersTable } from "../../store/slices/Timesheet/thunk/getTimesheetTable";
import TimesheetTable from "./TimesheetTable";
import NoDataPage from "../NoDataPage/NoDataPage";
import { getFilterDropdown } from "../../store/slices/filter/thunks/getFilterDropdown";
import { useFilter } from "../../context/useFilter";
import { filterQuery } from "./TimesheetFilter";
import { getTimesheetPdfTable } from "../../store/slices/Timesheet/thunk/getPdfTimesheet";
import { timesheetActions } from "../../store/slices/Timesheet";

// import UserCustomSelect from "./UserCustomSelect";

function TimeSheetPage() {
  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.TIME_SHEETS,
        permissionConstants.submodules.TIME_SHEET_LIST,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  // Function to handle month and year selection
  const handleMonthYearSelect = (month, year) => {
    // Use Moment.js to format the month
    const formattedMonth = moment().month(month).format("MMMM");

    setSelectedMonth(formattedMonth);
    setSelectedYear(year);
  };
  const { timesheetData, timesheetTablePdfData } = useSelector(
    (state) => state.timesheet,
  );

  const { authData } = useSelector((state) => state.auth);
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  useEffect(() => {
    if (authData) {
      getMultyUsers(authData?._id);
    }
  }, []);

  const { MultyUsersData } = useSelector((state) => state.userProfile);

  const [timesheet, isLoading] = useThunk(getUserTimesheet);

  const [timesheetPdfDownload, timesheetpdfLoader] =
    useThunk(getTimesheetPdfTable);

  const [timesheetTable, loadingTable] = useThunk(getTimesheetUsersTable);

  const [allUserReports] = useThunk(getAllUsersList);

  const [getJokesData] = useThunk(getJokes);
  const [filterData] = useThunk(getFilterDropdown);

  const { timesheetTableData } = useSelector((state) => state.timesheet);
  // Define state variables for month and year
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [loaderPdf, setLoaderPdf] = useState(null);

  const [params, setParams] = useState({});

  const { filter } = useFilter();

  const matches = filterQuery(filter.filter);

  const previousMatchesRef = useRef(matches);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEqual(matches, previousMatchesRef.current)) {
      setParams({ ...params, matches });
      previousMatchesRef.current = matches;
    }
  }, [matches]);

  useEffect(() => {
    let updatedParams = { ...params };
    updatedParams = { ...updatedParams, users: filter.users };
    updatedParams = { ...updatedParams, search: filter.search };
    updatedParams = { ...updatedParams, date: filter.date };
    setParams(updatedParams);
  }, [filter.search, filter.users, filter.date]);

  useEffect(() => {
    filterData("timesheet");
  }, []);

  useEffect(() => {
    if (!timesheetData) getJokesData();
  }, [timesheetData]);

  useEffect(() => {
    allUserReports();
  }, []);

  const usersId = params?.users ? params?.users : authData?._id;

  useEffect(() => {
    if (selectedMonth && selectedYear && usersId)
      timesheet({
        year: selectedYear,
        month: selectedMonth,
        id: params?.users ? params?.users : authData?._id,
      });
  }, [selectedMonth, selectedYear, params, authData?._id]);

  useEffect(() => {
    if (selectedMonth && selectedYear && usersId) {
      timesheetTable({
        year: selectedYear,
        month: selectedMonth,
        id: params?.users ? params?.users : authData?._id,
        params,
      });
    }
  }, [selectedMonth, selectedYear, params, authData?._id]);

  function calculateProgressPercentage(completed, totalTicketsCount) {
    if (
      typeof completed !== "number" ||
      typeof totalTicketsCount !== "number" ||
      totalTicketsCount <= 0
    ) {
      return "0%";
    }

    const progress = (completed / totalTicketsCount) * 100;

    return `${progress?.toFixed(0)}%`;
  }

  const { allUsers } = useSelector((state) => state.common);
  // Find the user with the matching id
  const user = allUsers.find((user) => user._id === params.users);

  // Extract the employeeName if the user is found
  const employeeName = user ? user.employeeName : null;

  const employeeJobId = user ? user?.employeeId : null;

  const employeeRole = user
    ? user.employeeRoleDetails.map(({ name }) => name)
    : null;

  // excel export
  const exportToExcel = () => {
    const headers = [
      "Date",
      "Total Hours Worked",
      "Productive Hours",
      "Total Tickets",
      "Hours Allocated Per Day",
      "No. Of Projects Allocated",
      "To Do",
      "In Progress",
      "Performance Index",
      "Quality Index",
      "Completed",
      "No. of QA Rejections",
      "Progress",
    ];

    const counterHeaders = [
      "Todo",
      "In Progress",
      "Completed",
      "Perform Index",
      "Hours(Productive/Total)",
      "Quality Index",
    ];

    const thisMonthData = selectedMonth;
    const thisYearData = selectedYear;
    const employeeNameTitle = "EmployeeName";
    const employeedata = employeeName ?? authData?.employeeName;
    const employeeIdTitle = "Id";
    const employeeIddata = employeeJobId ?? MultyUsersData?.employeeId;

    const employeeRoleName = "Role";
    const employeeNameRoleData =
      employeeRole || MultyUsersData?.designation?.name;
    const projectTitle = "Projects";
    const projectsList = [];

    timesheetTableData?.projectNames?.map((e) => projectsList.push(e?.name));

    // Initialize data with the employee's name
    const data = [
      [thisMonthData, thisYearData],
      [employeeNameTitle, employeedata],
      [employeeIdTitle, employeeIddata],
      [employeeRoleName, employeeNameRoleData],

      [projectTitle, [projectsList]],
    ];

    data.push([]);

    // Add counterHeaders
    data.push(counterHeaders);

    const counterData = timesheetData.map((count) => [
      count?.statusCountObject?.TODO || "-",
      count?.statusCountObject["IN PROGRESS"] || "-",
      count?.statusCountObject?.COMPLETE || "-",
      count?.performanceIndex || "-",
      count?.productiveHours || "-",
      count?.qualityIndex || "-",
    ]);

    // Add counter data beneath counterHeaders
    data.push(...counterData);

    // Add an empty row to separate counter data from main headers
    data.push([]);

    // Add the main headers
    data.push(headers);

    timesheetTableData?.daysInMonth.forEach((e) => {
      const formattedDate = moment(e?.date, "DD-MM-YYYY").format(
        "D MMMM YYYY, dddd",
      );
      const isWeekend = moment(e?.date, "DD-MM-YYYY").isoWeekday() >= 6;

      if (isWeekend) {
        // If it's a weekend, add a row for it
        data.push([formattedDate, "Holiday"]);
      } else if (e?.isHoliday === true) {
        // If it's a holiday, add a row for it
        data.push([formattedDate, "Holiday"]);
      } else if (e?.isLeaveDate === true) {
        // If it's a leave date, add a row for it
        data.push([formattedDate, "Full Day Leave"]);
      } else {
        // Add the data for the table as well
        const rowData = [
          formattedDate,
          e?.data?.totalWorkingHours != null ? e?.data?.totalWorkingHours : "-",
          e?.data?.productiveHours != null ? e?.data?.productiveHours : "-",
          e?.data?.totalTicketsCount != null ? e?.data?.totalTicketsCount : "-",
          "-",
          e?.data?.totalProjects != null ? e?.data?.totalProjects : "-",
          e?.data?.todoCount != null ? e?.data?.todoCount : "-",
          e?.data?.inProgressCount != null ? e?.data?.inProgressCount : "-",
          `${
            e?.data?.performanceIndex != null
              ? e?.data.performanceIndex !== 0
                ? e.data.performanceIndex % 1 !== 0
                  ? `${e?.data.performanceIndex?.toFixed(4).slice(0, 4)}%`
                  : `${e?.data.performanceIndex?.toFixed(0)}%`
                : "0%"
              : "-"
          }`,
          `${
            e?.data?.qualityIndex != null
              ? e?.data.qualityIndex !== 0
                ? e.data.qualityIndex % 1 !== 0
                  ? `${e?.data.qualityIndex?.toFixed(4).slice(0, 4)}%`
                  : `${e?.data.qualityIndex?.toFixed(0)}%`
                : "0%"
              : "-"
          }`,
          e?.data?.completedCount != null ? e.data.completedCount : "-",
          e?.data?.rejectedCount != null ? e?.data?.rejectedCount : "-",
          calculateProgressPercentage(
            e?.data?.completedCount,
            e?.data?.totalTicketsCount,
          ),
        ];

        // Add counter values to the row
        data.push(rowData);
      }
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Timesheet");

    XLSX.writeFile(workbook, "timesheet.xlsx");
  };

  useEffect(() => {
    if (timesheetTablePdfData) {
      downloadPdf();
    }
  }, [timesheetTablePdfData]);

  const exportToPdf = () => {
    if (selectedMonth && selectedYear && usersId) {
      timesheetPdfDownload({
        year: selectedYear,
        month: selectedMonth,
        id: usersId,
      });
    }
    setLoaderPdf(true);
  };

  const downloadPdf = () => {
    if (timesheetTablePdfData) {
      fetch(timesheetTablePdfData, {})
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "timesheet.pdf"; // Specify the desired filename
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          dispatch(timesheetActions.clearTimesheetPdf());
        })
        .catch((error) => console.error("Error downloading the file:", error))
        .finally(() => {
          // Set loading state to false
          setLoaderPdf(false);
        });
    }
  };

  const exportToExcelAllowed = hasPermission(
    permissionConstants.permissions.TIME_SHEET_EXPORT_XLX,
  );
  const exportToPdfAllowed = hasPermission(
    permissionConstants.permissions.TIME_SHEET_EXPORT_PDF,
  );

  return (
    <>
      <SubHeader
        // showSearchBar
        showFilter={hasPermission(
          permissionConstants.permissions.TIME_SHEET_FILTER,
        )}
        // showMe
        showUsers={hasPermission(
          permissionConstants.permissions.TIME_SHEET_LIST_USER_SELECT,
        )}
        // showShowBtn
        // showOtherOptions
        showTimeSheetCalendar={hasPermission(
          permissionConstants.permissions.TIME_SHEET_DATEPICKER,
        )}
        showExportDropdown={
          timesheetData?.length &&
          timesheetTableData?.daysInMonth?.length &&
          (hasPermission(
            permissionConstants.permissions.TIME_SHEET_EXPORT_PDF,
          ) ||
            hasPermission(
              permissionConstants.permissions.TIME_SHEET_EXPORT_XLX,
            ))
        }
        exportFnc={{
          exportToExcel: exportToExcelAllowed && exportToExcel,
          exportToPdf: exportToPdfAllowed && exportToPdf,
        }}
        pdfThunkLoading={timesheetpdfLoader}
        loaderPdf={loaderPdf}
        onMonthYearSelect={handleMonthYearSelect} // Pass the function as a prop
        // showTimeSheetCalendar
      />
      <div className="page-contents">
        <div className="timesheet-cover">
          {timesheetData?.length && timesheetTableData?.daysInMonth?.length ? (
            <>
              <div className="container-fluid">
                <div className="row">
                  {/* <div className="col">
                <div className="usr-allocation-drp">
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    aria-label="Default select example"
                    options={allUserReportData?.map((user) => ({
                      value: user._id,
                      label: user.employeeName,
                      data: { photo: user.photo },
                    }))}
                    onChange={handleUserSelect}
                  />
                </div>
              </div> */}
                </div>

                <div className="row">
                  <div className="col">
                    <div className="row h-100 g-3 ticket-count-wrp">
                      {isLoading
                        ? Array?.from({ length: 6 }).map((num, index) => (
                            <div
                              key={index}
                              className="col-lg-4 col-xl col-md-6 lg-mb-4"
                            >
                              <div className="count-tickets d-flex align-items-center">
                                <div className="card-title-block">
                                  <CommonSkeleton height={60} />
                                </div>
                              </div>
                            </div>
                          ))
                        : timesheetData?.map((e) => (
                            <>
                              {hasPermission(
                                permissionConstants.permissions
                                  .TIME_SHEET_LIST_COUNTERS_TODO_COUNT,
                              ) && (
                                <Counter
                                  title="Todo"
                                  icon={TodoImage}
                                  value={e?.statusCountObject?.TODO || "-"}
                                  showCardsRow
                                />
                              )}
                              {hasPermission(
                                permissionConstants.permissions
                                  .TIME_SHEET_LIST_COUNTERS_INPROGRESS_COUNT,
                              ) && (
                                <Counter
                                  title="In Progress"
                                  icon={InprogressImage}
                                  value={
                                    e?.statusCountObject["IN PROGRESS"] || "-"
                                  }
                                  showCardsRow
                                />
                              )}
                              {hasPermission(
                                permissionConstants.permissions
                                  .TIME_SHEET_LIST_COUNTERS_COMPLETED_COUNT,
                              ) && (
                                <Counter
                                  title="Completed"
                                  icon={completedImage}
                                  value={e?.statusCountObject?.COMPLETE || "-"}
                                  showCardsRow
                                />
                              )}
                              {hasPermission(
                                permissionConstants.permissions
                                  .TIME_SHEET_LIST_COUNTERS_PERFORMANCE_INDEX_PERCENT,
                              ) && (
                                <Counter
                                  title="Performance Index"
                                  icon={performImage}
                                  value={e?.performanceIndex || "-"}
                                  showCardsRow
                                />
                              )}
                              {hasPermission(
                                permissionConstants.permissions
                                  .TIME_SHEET_LIST_COUNTERS_HOURS_PRODUCTIVE_BY_TOTAL,
                              ) && (
                                <Counter
                                  title="Hours (Productive/Total)"
                                  icon={HoursImage}
                                  value={e?.totalProductiveHours[0] || "-"}
                                  showCardsRow
                                />
                              )}
                              {hasPermission(
                                permissionConstants.permissions
                                  .TIME_SHEET_LIST_COUNTERS_QUALITY_INDEX_PERCENT,
                              ) && (
                                <Counter
                                  title="Quality Index"
                                  icon={qualityImage}
                                  value={e?.qualityIndex || "-"}
                                  showCardsRow
                                />
                              )}
                            </>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
              {hasPermission(
                permissionConstants.permissions.TIME_SHEET_LIST_VIEW,
              ) && (
                <TimesheetTable
                  timesheetTableDates={timesheetTableData}
                  // key={index}
                  isLoading={loadingTable}
                  params={params}
                  hasPermission={hasPermission}
                  // title={each}
                  // data={timesheetTableData[each]}
                />
              )}
            </>
          ) : (
            <NoDataPage />
          )}
        </div>
      </div>
    </>
  );
}

export default withTitle(TimeSheetPage);
