import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useThunk } from "../../../../../hooks/useThunk";
import { getAllPullRequests } from "../../../../../store/slices/gitIntegration/thunks/getPullrequests";
import { AddDocumentIcon } from "./svgIcons";
import checkIcon from "../../../../../assets/images/check_icon.svg";
import CommonSkeleton from "../../../../../components/SkeletonLoader/CommonSkeleton";
import { patchPullRequests } from "../../../../../store/slices/gitIntegration/thunks/patchPullRequest";
import UserNoData from "../../../../UserProfile/UserNoData";
import close from "../../../../../assets/images/cancel.svg";
import { Deletemodal } from "../../../../../components/DropDowns/DeleteModal";
import permissionConstants from "../../../../../utils/permissionUtils/permissionConstants";

// list pull request component
const ListPr = ({
  commonSl,
  prCount,
  tablesearchValue,
  hasPermission,
  projectId,
  ticket,
}) => {
  const [allpullrequests, isLoading] = useThunk(getAllPullRequests);
  const [closeStatepull, isclosing] = useThunk(patchPullRequests);

  useEffect(() => {
    if (commonSl !== "") allpullrequests(commonSl);
  }, [commonSl]);

  const { getAllPullRequestsData } = useSelector((state) => state.github);

  const prdatacound = getAllPullRequestsData?.length;

  // Filter branches based on searchQuery
  const filteredPullrequest = tablesearchValue
    ? getAllPullRequestsData.filter(
        (pr) =>
          pr.targetBranch
            .toLowerCase()
            .includes(tablesearchValue.toLowerCase()) ||
          pr.sourceBranch
            .toLowerCase()
            .includes(tablesearchValue.toLowerCase()) ||
          pr.userDetails?.userName
            .toLowerCase()
            .includes(tablesearchValue.toLowerCase()) ||
          pr.state.toLowerCase().includes(tablesearchValue.toLowerCase()),
      )
    : getAllPullRequestsData;

  useEffect(() => {
    prCount(prdatacound);
  }, [prCount, prdatacound]);

  function getShortName(user) {
    const names = user?.split(" ");
    const shortName = names
      ?.slice(0, 2)
      .map((name) => name[0].toUpperCase())
      .join("");
    return shortName;
  }

  const [patchId, setpatchId] = useState("");
  const [state, setstate] = useState({
    deleteModal: { openclose: false, type: "" },
  });
  const { deleteModal } = state;

  // close a pull request

  const closeDeleteModal = () => {
    setpatchId("");
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: false,
      },
    });
  };
  const handleDeleteModalClick = (datas) => {
    setpatchId(datas);
    const type = `the pull request (${datas})`;
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: true,
        type,
      },
    });
  };

  const handleDelete = () => {
    const data = {
      pullNumber: patchId,
      repoName: commonSl?.value,
      owner: commonSl?.owner,
      projectId,
      ticket,
    };
    closeStatepull(data);
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: false,
      },
    });
  };

  return (
    <div className="single-bg-wrp">
      <div className="integration-table-wrp">
        <div className="table-responsive custom-scroll">
          {commonSl !== "" &&
          filteredPullrequest &&
          filteredPullrequest.length > 0 ? (
            <table className="table custom-table docs-table">
              <tbody>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Source</th>
                  <th scope="col">Target</th>
                  <th scope="col">By</th>
                  <th scope="col">Reviewer</th>
                  <th scope="col">CI/CD</th>
                  <th scope="col">Status</th>
                  {hasPermission(
                    permissionConstants.permissions
                      .TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_VIEW,
                  ) ||
                  hasPermission(
                    permissionConstants.permissions
                      .TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_CLOSE,
                  ) ? (
                    <th scope="col">Action</th>
                  ) : null}
                </tr>
                {isLoading
                  ? Array?.from({
                      length: 8,
                    })?.map((_, index) => (
                      <td key={index}>
                        {Array?.from({
                          length: filteredPullrequest?.length,
                        })?.map((_, index) => (
                          <div className="td-cnt" key={index}>
                            <div className="txt">
                              <CommonSkeleton width={100} />
                            </div>
                          </div>
                        ))}
                      </td>
                    ))
                  : filteredPullrequest?.map((item, index) =>
                      isclosing && patchId === item.pullNumber ? (
                        Array?.from({
                          length: 8,
                        })?.map((_, index) => (
                          <td key={index}>
                            <div className="td-cnt" key={index}>
                              <div className="txt">
                                <CommonSkeleton width={100} />
                              </div>
                            </div>
                          </td>
                        ))
                      ) : (
                        <tr key={index}>
                          <td>{item.url}</td>
                          <td>{item.sourceBranch}</td>
                          <td>{item.targetBranch}</td>
                          <td>
                            <div className="usr-blk">
                              <span className="usr">
                                {item?.userDetails?.photo ? (
                                  <img
                                    className="name-ltr"
                                    src={`https://avatars.githubusercontent.com/u/${item?.userDetails?.id}?v=4`}
                                    alt="profile pic"
                                  />
                                ) : (
                                  getShortName(item?.userDetails?.userName)
                                )}
                              </span>
                              {item?.userDetails?.userName}
                            </div>
                          </td>
                          <td>
                            <div className="usr-blk">
                              <span className="usr">
                                {item?.reviewers && item.reviewers.length > 0
                                  ? item.reviewers.map((reviewer, index) => (
                                      <div key={index}>
                                        {reviewer.avatar_url ? (
                                          <img
                                            className="name-ltr"
                                            src={reviewer.avatar_url}
                                            alt="profile pic"
                                          />
                                        ) : (
                                          getShortName(reviewer.login)
                                        )}
                                      </div>
                                    ))
                                  : ""}
                              </span>
                              {item?.reviewers && item.reviewers.length > 0
                                ? item.reviewers.map((review, index) => (
                                    <div key={index}>{review?.login}</div>
                                  ))
                                : ""}
                            </div>
                          </td>
                          <td>———</td>
                          <td>
                            <div
                              className={`status-blk ${
                                item?.state === "closed" ? "close" : "open"
                              }`}
                            >
                              {item.state}
                            </div>
                          </td>
                          <td>
                            {item.state === "closed" ? (
                              <div className="prActions">
                                {hasPermission(
                                  permissionConstants.permissions
                                    .TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_VIEW,
                                ) && (
                                  <span className="icons">
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <AddDocumentIcon />
                                    </a>
                                  </span>
                                )}
                              </div>
                            ) : (
                              <div className="prActions">
                                {hasPermission(
                                  permissionConstants.permissions
                                    .TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_VIEW,
                                ) && (
                                  <span className="icons">
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <AddDocumentIcon />
                                    </a>
                                  </span>
                                )}
                                {hasPermission(
                                  permissionConstants.permissions
                                    .TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS_CLOSE,
                                ) && (
                                  <span
                                    className="icons"
                                    onClick={() =>
                                      handleDeleteModalClick(item?.pullNumber)
                                    }
                                  >
                                    <img
                                      src={checkIcon}
                                      alt="nothing"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ),
                    )}
              </tbody>
            </table>
          ) : (
            <UserNoData />
          )}
        </div>
      </div>
      {deleteModal.openclose ? (
        <Deletemodal
          text="Are you sure you want to close"
          icon={close}
          ModalShow={deleteModal}
          closeModal={closeDeleteModal}
          cancelModal={closeDeleteModal}
          deleteFunc={handleDelete}
          type={deleteModal?.type}
        />
      ) : null}
    </div>
  );
};
export default ListPr;

ListPr.propTypes = {
  commonSl: PropTypes.string,
  prCount: PropTypes.func,
  tablesearchValue: PropTypes.string,
  hasPermission: PropTypes.func,
  projectId: PropTypes.string,
  ticket: PropTypes.string,
};
