import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ticketService from "../../../../services/ticketService";
import { alertActions } from "../../alert";
import {
  setGetState,
  //  setPatchedData,
  setSuccess,
  setTicketAllocationDataById,
} from "..";

export const patchTicket = createAsyncThunk(
  "patch/ticket",
  async ({ id, patchData }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await ticketService.patchTicketData(id, patchData);
      dispatch(alertActions.success(successMessage));
      dispatch(setGetState(false));
      dispatch(setSuccess({ key: "patch", success: data?.data }));
      // dispatch(setPatchedData(data));
      dispatch(setTicketAllocationDataById(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
