import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { useContext, useEffect, useCallback, useState } from "react";
import Highcharts from "highcharts";
import { projectGraphOptions } from "../../../config/ChartOptions";
import LanguageContext from "../../../context/LanguageContext";
import CustomDateRange from "../../common/DateRangePicker";
import CommonSkeleton from "../../SkeletonLoader/CommonSkeleton";

const Projectgraph = ({ data, label = [], showCustomDateRange, loading }) => {
  const generateChart = useCallback(() => {
    if (!loading) {
      const options = projectGraphOptions(data, label);
      Highcharts.chart("projectChart", options);
    }
  }, [data, label]);

  useEffect(() => {
    generateChart();
  }, [generateChart]);

  const {
    languageData: { lineChart },
  } = useContext(LanguageContext);

  const titleTooltip = (
    <Tooltip id={lineChart.title}>Project wise Task completion</Tooltip>
  );

  const handlePerformanceDateCallback = (start, end) => {
    setPerformanceDate({ start, end });
  };
  const [performanceDate, setPerformanceDate] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });

  return (
    <div className="card-box card-box-h dashboard-cards">
      <div className="header-otr d-flex flex-wrap">
        <OverlayTrigger placement="top" overlay={titleTooltip}>
          <div className="title-md">Task completion</div>
        </OverlayTrigger>
        {showCustomDateRange && (
          <CustomDateRange
            start={performanceDate.start}
            end={performanceDate.end}
            change={handlePerformanceDateCallback}
          />
        )}
      </div>
      {loading ? (
        <div className="mb-3">
          <CommonSkeleton height={200} />
        </div>
      ) : (
        <div className="chart-cover">
          <figure className="highcharts-figure">
            <div id="projectChart" className="chart-content" />
          </figure>
        </div>
      )}
    </div>
  );
};

Projectgraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  showCustomDateRange: PropTypes.bool,
  loading: PropTypes.bool,

  label: PropTypes.arrayOf(PropTypes.string),
};

export default Projectgraph;
