const success = (state, action) => {
  state.value = {
    type: "success",
    message: action.payload,
  };
};

const error = (state, action) => {
  state.value = {
    type: "error",
    message: action.payload,
  };
};

const clear = (state) => {
  state.value = null;
};

export const createReducers = () => {
  return {
    success,
    error,
    clear,
  };
};
