export function TimeSheetNav() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g id="Group_60" data-name="Group 60" transform="translate(-304 -10)">
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="32"
          height="32"
          rx="8"
          transform="translate(304 10)"
          fill="#e59a45"
        />
        <g
          id="file_3_"
          data-name="file (3)"
          transform="translate(310.251 18.602)"
        >
          <path
            id="Path_10617"
            data-name="Path 10617"
            d="M411,8.782v2.636h2.636Z"
            transform="translate(-398.151 -8.528)"
            fill="#fff"
          />
          <path
            id="Path_10618"
            data-name="Path 10618"
            d="M128.947,3.757a.434.434,0,0,1-.434-.433V0H122.3A1.3,1.3,0,0,0,121,1.3V6.147c.143-.013.287-.02.433-.02a4.761,4.761,0,0,1,3.676,1.734h4.705a.433.433,0,0,1,0,.867H125.68a4.732,4.732,0,0,1,.5,1.734h3.632a.433.433,0,0,1,0,.867h-3.632a4.77,4.77,0,0,1-2.01,3.468h6.8a1.3,1.3,0,0,0,1.3-1.3V3.757Zm.867,2.37h-6.358a.433.433,0,0,1,0-.867h6.358a.433.433,0,0,1,0,.867Z"
            transform="translate(-116.532)"
            fill="#fff"
          />
          <path
            id="Path_10619"
            data-name="Path 10619"
            d="M4.9,242a3.9,3.9,0,1,0,3.9,3.9A3.906,3.906,0,0,0,4.9,242Zm1.156,4.335H4.9a.433.433,0,0,1-.433-.433v-1.734a.433.433,0,1,1,.867,0v1.3h.722a.433.433,0,1,1,0,.867Z"
            transform="translate(0 -235.006)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export function PreviousIcons() {
  return (
    <svg
      id="arrow-right"
      xmlns="http://www.w3.org/2000/svg"
      width="13.156"
      height="13.156"
      viewBox="0 0 13.156 13.156"
    >
      <path
        id="Vector"
        d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
        transform="translate(4.884 2.236)"
        fill="none"
        stroke="#868991"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0H13.156V13.156H0Z"
        transform="translate(13.156 13.156) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}
export function NextIcons() {
  return (
    <svg
      id="arrow-right"
      xmlns="http://www.w3.org/2000/svg"
      width="13.156"
      height="13.156"
      viewBox="0 0 13.156 13.156"
    >
      <path
        id="Vector"
        d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
        transform="translate(4.884 2.236)"
        fill="none"
        stroke="#868991"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0H13.156V13.156H0Z"
        transform="translate(13.156 13.156) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}

export function ProjectRemarks() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.445"
      height="20.445"
      viewBox="0 0 20.445 20.445"
    >
      <g
        id="vuesax_bold_message-text"
        data-name="vuesax/bold/message-text"
        transform="translate(-748 -316)"
      >
        <g id="message-text" transform="translate(748 316)">
          <path id="Vector" d="M0,0H20.445V20.445H0Z" fill="none" opacity="0" />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M11.926,0H5.111Q0,0,0,5.111V16.186a.854.854,0,0,0,.852.852H11.926q5.111,0,5.111-5.111V5.111Q17.038,0,11.926,0Zm-1.7,11.288H4.259a.639.639,0,0,1,0-1.278h5.963a.639.639,0,0,1,0,1.278Zm2.556-4.259H4.259a.639.639,0,0,1,0-1.278h8.519a.639.639,0,0,1,0,1.278Z"
            transform="translate(1.704 1.704)"
            fill="currentcolor"
          />
        </g>
      </g>
    </svg>
  );
}

export function TimeEstimate() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      data-name="Layer 2"
      width="5.244"
      height="6.84"
      viewBox="0 0 5.244 6.84"
    >
      <g id="Layer_1" data-name="Layer 1" transform="translate(0 0)">
        <path
          id="hourglass"
          d="M4.9,6.156H4.636V5.292a1.794,1.794,0,0,0-.8-1.5L3.268,3.42l.564-.374a1.794,1.794,0,0,0,.8-1.5V.684H4.9A.342.342,0,0,0,4.9,0H.342a.342.342,0,1,0,0,.684h.32v.864a1.792,1.792,0,0,0,.8,1.5l.564.374-.564.374a1.792,1.792,0,0,0-.8,1.5v.864H.342a.342.342,0,1,0,0,.684H4.9a.342.342,0,0,0,0-.684ZM1.346,5.292a1.113,1.113,0,0,1,.5-.928l.806-.534.8.534a1.114,1.114,0,0,1,.5.928v.864H1.346Z"
          transform="translate(0 0)"
          fill="#9c62ff"
        />
      </g>
    </svg>
  );
}
