export function PreviousIcons() {
  return (
    <svg
      id="arrow-right"
      xmlns="http://www.w3.org/2000/svg"
      width="13.156"
      height="13.156"
      viewBox="0 0 13.156 13.156"
    >
      <path
        id="Vector"
        d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
        transform="translate(4.884 2.236)"
        fill="none"
        stroke="#868991"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0H13.156V13.156H0Z"
        transform="translate(13.156 13.156) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}
export function NextIcons() {
  return (
    <svg
      id="arrow-right"
      xmlns="http://www.w3.org/2000/svg"
      width="13.156"
      height="13.156"
      viewBox="0 0 13.156 13.156"
    >
      <path
        id="Vector"
        d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
        transform="translate(4.884 2.236)"
        fill="none"
        stroke="#868991"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0H13.156V13.156H0Z"
        transform="translate(13.156 13.156) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}
