import { Link, useMatches, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { useSelector } from "react-redux";
// import { Dropdown } from "react-bootstrap";
import moment from "moment";

import _ from "lodash";

import projectManagement from "../../assets/images/project_management.svg";
// import threeDotsSvg from "../../assets/images/three-dots.svg";

import "../../design/custom/structure/sub-header.scss";

import { useThunk } from "../../hooks/useThunk";
// import { menuItems, dropDownItems } from "./config";
import { menuItems } from "./config";
import useTitleContext from "../../hooks/useTitle";
import calendar from "../../assets/images/calendar-sm.svg";
import CompleteSprint from "../../pages/Sprint/completeSprint";
import { hasSubModulePermission } from "../../utils/permissionUtils";
// import { alertActions } from "../../store/slices/alert";
// import { AvadarModal } from "./avadarModal";
import ActiveSprintModal from "../../pages/Sprint/ActiveSprintModal";
import { sprintActions } from "../../store/slices/sprint";
import { getProjectIcon } from "../../store";

const PrimaryNavbar = () => {
  const dispatch = useDispatch();
  const { shortCode, listId, type } = useParams();
  const [GetProjectIcon] = useThunk(getProjectIcon);
  const match = useMatches();
  const { title: pageTitle } = useTitleContext();
  const { isRead } = useParams();
  const { projectData } = useSelector((state) => state.project);
  const { userProfile } = useSelector((state) => state.userProfile);
  const [completeSprintModal, setCompleteSprintModal] = useState(false);
  const [activeSprintModal, setActiveSprintModal] = useState(false);

  useEffect(() => {
    GetProjectIcon();
  }, []);

  // const { ProjectIcon } = useSelector((state) => state.navBar);

  const matchingSprint = projectData
    .map((project) => project?.sprint.find((sprint) => sprint._id === listId))
    .find(Boolean);
  const currentDate = moment(); // current date and time
  const dateRange = matchingSprint?.name?.split(/\(|\)/)[1];
  let sprintStart = "";

  if (dateRange) {
    sprintStart = dateRange.split("/");
    sprintStart = moment(sprintStart?.[0], "DD-MM-YYYY").startOf("day");
  }

  let buttonText;
  let buttonClass = "btn btn-secondary status-btn";

  if (matchingSprint?.status === "Completed") {
    buttonText = "Completed";
    buttonClass += " done";
  } else if (
    (currentDate.isSameOrAfter(sprintStart) &&
      matchingSprint?.active === true) ||
    matchingSprint?.active === true
  ) {
    buttonText = "In Progress";
    buttonClass += " inProg";
  } else {
    buttonText = "Not Yet Started";
    buttonClass += " notStarted";
  }
  const hasPermission = (permission) =>
    hasSubModulePermission(userProfile?.formattedPermissions ?? [], permission);

  const basePathConfig = _.find(match, ({ id }) =>
    _.includes(_.keys(menuItems), id),
  );

  const basePath = basePathConfig ? basePathConfig?.id : null;

  const basePathItems = basePath ? menuItems[basePath] : [];

  const renderBasePathItems = () => {
    return (shortCode && listId) ||
      isRead ||
      ["report", "allocation"].includes(basePathConfig?.id)
      ? basePathItems
          .filter(
            (each) =>
              each.hasPermission === true || hasPermission(each.hasPermission),
          )
          .map((listItem, index) => (
            <li key={index}>
              <div className="dropdown custom-drop-menu">
                <div>
                  {shortCode && listId ? (
                    <Link
                      to={`/project/${shortCode}/${type}/${listId}/${listItem.path}`}
                      className={
                        match.find((eachMatch) => eachMatch.id === listItem.key)
                          ? "link-nv active"
                          : "link-nv"
                      }
                    >
                      <div className="nav-icon">{listItem.svg}</div>
                      <span>{listItem.name}</span>
                    </Link>
                  ) : (
                    <Link
                      to={`${basePath}${listItem.path}`}
                      className={
                        match.find(
                          (eachMatch) => eachMatch.id === listItem.key,
                        ) ||
                        (isRead === "isRead" && listItem.key === "cleared") ||
                        (isRead !== "isRead" && listItem.key === "new")
                          ? "link-nv active"
                          : "link-nv"
                      }
                    >
                      <div className="nav-icon">{listItem.svg}</div>
                      <span>{listItem.name}</span>
                    </Link>
                  )}
                </div>
              </div>
            </li>
          ))
      : [];
  };
  // const copyLinkToClipboard = () => {
  //   const currentURL = window.location.href;
  //   navigator.clipboard
  //     .writeText(currentURL)
  //     .then(() => {
  //       console.log("Page link copied to clipboard!");
  //       dispatch(alertActions.success("Copied"));
  //     })
  //     .catch((error) => console.error("Failed to copy:", error));
  // };

  return (
    <header
      className={`header d-flex align-items-center min-header ${
        type === "sprint" ? "sprint-view" : ""
      }`}
    >
      <div className="headerLinks d-flex align-items-center">
        <span className="headerLink active txt">
          <span className="icon">
            <img src={projectManagement} alt="icon" />
          </span>
          {pageTitle}
        </span>

        <div className="dropdown custom-drop-menu  ms-2">
          {/* {basePath === "notificatons" ? ( */}
          {/* <Dropdown bsPrefix="dropdown-toggle">
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              bsPrefix="dropdown-toggle headerDropdown"
            >
              <img src={threeDotsSvg} alt="Three Dots" />
            </Dropdown.Toggle>

            <Dropdown.Menu bsPrefix="dropdown-menu dropeffect">
              {dropDownItems?.map((item, index) => (
                <div key={index}>
                  <div className="xtend-drop">
                    <Dropdown.Item
                      bsPrefix="dropdown-item d-flex"
                      key={item.value}
                      onClick={
                        item.name === "Copy link" ? copyLinkToClipboard : null
                      }
                    >
                      <div className="icon">
                        <img src={item.svg} alt="icon" />
                      </div>
                      <span>{item.name}</span>
                    </Dropdown.Item>
                    {item.name === "Move" && (
                      <div className="divitions">
                        <hr />
                      </div>
                    )}
                    {item.name === "Color & Avatar" && (
                      <AvadarModal ProjectIcon={ProjectIcon} />
                    )}
                  </div>
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
        {type === "sprint" ? (
          <div className="status-area">
            <div>
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  if (buttonText === "Not Yet Started") {
                    dispatch(sprintActions.setSprint(""));
                    setActiveSprintModal(true);
                  } else {
                    dispatch(sprintActions.setCompleteSprint(""));
                    setCompleteSprintModal(
                      !["Not Yet Started", "Completed"]?.includes(buttonText),
                    );
                  }
                }}
              >
                {buttonText}
              </button>
            </div>
            <div className="sprint-status-area">
              <div className="icon-info">
                <img src={calendar} alt="cal" />
              </div>
              <div className="value-info-area">
                <span className="vlue">
                  {matchingSprint?.name?.split("(")[1]?.replace(")", "")}
                </span>
              </div>
            </div>
          </div>
        ) : null}

        {completeSprintModal && buttonText === "In Progress" ? (
          <CompleteSprint
            completeSprintModal={completeSprintModal}
            setCompleteSprintModal={() => setCompleteSprintModal("")}
          />
        ) : null}
        {activeSprintModal && buttonText === "Not Yet Started" ? (
          <ActiveSprintModal
            activeSprintModal={activeSprintModal}
            setActiveSprintModal={() => setActiveSprintModal("")}
            sprint={matchingSprint}
            project={matchingSprint?.project}
          />
        ) : null}
      </div>
      <div className="sub_nav d-flex">
        <ul className="d-flex">{renderBasePathItems()}</ul>
      </div>
      <div className="ms-auto">
        {/* <Button className="sm-radius-btn me-0" type="button">
          <img src={threeDotsSvg} alt="ImgFillter" /> 
          <span>Share</span>
        </Button> */}
      </div>
    </header>
  );
};

export default PrimaryNavbar;
