/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { reportActions } from "../index";
import reportService from "../../../../services/reportService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getReportDashboard = createAsyncThunk(
  `get/reportDashboard`,
  async ({ start, end, loginUser }, { dispatch }) => {
    try {
      const {
        data: { reports, message: successMessage },
      } = await reportService.getReportDashboardData({
        start,
        end,
        loginUser,
      });

      dispatch(
        reportActions.setReportDashboardData({
          reports,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(reportActions.setReportErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
