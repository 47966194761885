import defFlagImg from "../../../assets/images/flagFilled.svg";
import orangeFlagImg from "../../../assets/images/flag-orange.svg";
import blueFlagImg from "../../../assets/images/flag-blue.svg";
import redFlagImg from "../../../assets/images/flag-red.svg";
import defaultFlag from "../../../assets/images/flag-outline.svg";
import Circle1 from "../../../assets/images/circle-icon1.svg";
import docImage from "../../../assets/images/doc.svg";
import xlsImage from "../../../assets/images/xls.svg";
import pdfIcon from "../../../assets/images/pdf.svg";
import imgeIcon from "../../../assets/images/image-demo.svg";
import txtIcon from "../../../assets/images/text.svg";

export const estimateTooltip = () =>
  "This time estimate represents the projected number of hours required for completing the specified tasks.You can adjust the estimates for each sub-task in the table below. Additionally, you can assign team members to specific sub-tasks. Please make sure to enter at least one estimation hour for each sub-task before submitting the task.";

export const FileExtensionImages = {
  pdf: pdfIcon,
  doc: docImage,
  docx: docImage,
  xlsx: xlsImage,
  xls: xlsImage,
  png: imgeIcon,
  jpg: imgeIcon,
  jpeg: imgeIcon,
  txt: txtIcon,
};

export const Priority = (title) => {
  let flagImg = Circle1;
  switch (title) {
    case "Low":
      flagImg = (
        <span>
          <img src={defFlagImg} alt="" />
        </span>
      );
      break;
    case "Normal":
      flagImg = (
        <span>
          <img src={blueFlagImg} alt="" />
        </span>
      );
      break;
    case "High":
      flagImg = (
        <span>
          <img src={orangeFlagImg} alt="" />
        </span>
      );
      break;
    case "Urgent":
      flagImg = (
        <span>
          <img src={redFlagImg} alt="" />
        </span>
      );
      break;
    case "None":
      flagImg = (
        <span>
          <img src={defaultFlag} alt="" />
        </span>
      );
      break;
    default:
      flagImg = (
        <span>
          <img src={Circle1} alt="" />
        </span>
      );
  }
  return flagImg;
};

export const getFileExtension = (fileName) => {
  const parts = fileName?.split(".");
  return parts[parts.length - 1].toLowerCase();
};

export const convertFileSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
};

export const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

// export const isWithinNextWeek = (date) => {
//   const currentDate = new Date();
//   const nextWeek = new Date();
//   nextWeek.setDate(currentDate.getDate() + 7);
//   return date >= currentDate && date < nextWeek;
// };

export const isWithinNextWeek = (date) => {
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();

  const daysUntilNextSunday = 7 - currentDayOfWeek;

  const nextSunday = new Date(
    currentDate.getTime() + daysUntilNextSunday * 24 * 60 * 60 * 1000,
  );
  nextSunday.setHours(0, 0, 0, 0);

  const nextSaturday = new Date(nextSunday.getTime() + 6 * 24 * 60 * 60 * 1000);
  nextSaturday.setHours(23, 59, 59, 999);

  return date >= nextSunday && date <= nextSaturday;
};

export const isWithinCurrentWeek = (date) => {
  const currentDate = new Date();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Set to the first day of the current week (Sunday)
  const endOfWeek = new Date(currentDate);
  endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay())); // Set to the last day of the current week (Saturday)

  return date >= startOfWeek && date <= endOfWeek;
};
