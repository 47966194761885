export const ArrowRightIcon = () => (
  <svg
    id="arrow-right"
    xmlns="http://www.w3.org/2000/svg"
    width="13.156"
    height="13.156"
    viewBox="0 0 13.156 13.156"
  >
    <path
      id="Vector"
      d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
      transform="translate(4.884 2.236)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M0,0H13.156V13.156H0Z"
      transform="translate(13.156 13.156) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const ProfilePicIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="88"
    height="88"
    viewBox="0 0 88 88"
  >
    <g
      id="Group_59727"
      data-name="Group 59727"
      transform="translate(-298 -264)"
    >
      <circle
        id="Ellipse_57"
        data-name="Ellipse 57"
        cx="44"
        cy="44"
        r="44"
        transform="translate(298 264)"
        fill="#ac80f7"
      />
      <path
        id="Path_10119"
        data-name="Path 10119"
        d="M2.76-32.154H16.192q5.336,0,8.234,2.622t2.9,7.59a10.2,10.2,0,0,1-1.748,6.026,9.478,9.478,0,0,1-4.968,3.542L27.14,0H21.16L14.95-11.776H8.6V0H2.76ZM15.134-16.606a7.383,7.383,0,0,0,4.6-1.334,4.717,4.717,0,0,0,1.748-4q0-5.382-6.348-5.382H8.6v10.718Z"
        transform="translate(327.24 323.154)"
        fill="#fff"
      />
    </g>
  </svg>
);
