import { createSlice } from "@reduxjs/toolkit";

export const name = "design";

const createReducers = () => {
  const setDesignList = (state, { payload }) => {
    state.designList = payload.data;
  };

  return {
    setDesignList,
  };
};

function createInitialState() {
  return {
    designList: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const designActions = slice.actions;
export const designReducer = slice.reducer;
