import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import userProfileServices from "../../../../services/useProfileService";
import { userProfileActions } from "..";
import { alertActions } from "../../alert/index";
import { auth_doLogout } from "../../auth/thunks/doLogout";
import { gitHubAction } from "../../gitIntegration";
import { authActions } from "../../auth";

export const getUserProfile = createAsyncThunk(
  "list-department",
  async (data, { dispatch }) => {
    dispatch(userProfileActions.setUserProfileLoader(true));
    dispatch(alertActions.clear());

    try {
      const {
        data: { data },
      } = await userProfileServices.getUserProfile();
      dispatch(userProfileActions.setUserProfile(data));
      dispatch(
        authActions.setAuth({
          auth: {
            _id: data._id,
            employeeName: data.employeeName,
            photo: data.photo,
          },
        }),
      );
      dispatch(gitHubAction.gitSuccessStatus(data?.gitStatus));
      dispatch(userProfileActions.setUserProfileLoader(false));
    } catch (error) {
      dispatch(auth_doLogout());
      const errorMessage = _.isString(error)
        ? error
        : error?.message ?? error.response.data.message;
      dispatch(alertActions.error(errorMessage));
      dispatch(userProfileActions.setShowUserProfile(false));
    }
  },
);
