import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { getBlogService } from "../../../../services/feedService";
import { getBlogData } from "..";

export const getBlog = createAsyncThunk(
  "getBlog",
  async function ({ params }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      console.log("params", params);
      const { data } = await getBlogService(params);
      dispatch(getBlogData(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
