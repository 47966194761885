import { useSelector } from "react-redux";
import "../design/custom/pages/ganttChartComponents.scss";
import PropTypes from "prop-types";
import permissionConstants from "../utils/permissionUtils/permissionConstants";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../utils/permissionUtils";

const TaskStatusModal = ({
  overlay = "color-overlay",
  data,
  updateTaskStatus,
}) => {
  const {
    userProfile: { userProfile },
  } = useSelector((state) => state);

  const formattedpermissionList = subModulePermissions(
    userProfile?.formattedPermissions ?? [],
    permissionConstants.modules.PROJECTS_MANAGEMENT,
    permissionConstants.submodules.COMMON_TICKET_SETTINGS,
  );

  const hasPermssion = (permission) => {
    return modulePermissionHasPermissions(formattedpermissionList, permission);
  };

  return (
    <div className={overlay}>
      <div className="dropdown-menu dropdown status-drp-dwn status-action">
        {data
          ?.filter((e) => {
            if (
              !hasPermssion(
                permissionConstants.permissions.COMMON_TICKET_MARK_AS_COMPLETED,
              ) &&
              e.title === "COMPLETE"
            )
              return false;
            return true;
          })
          .map((e) => (
            <div
              key={e.title}
              onClick={(event) => {
                updateTaskStatus(e._id);
                event.stopPropagation();
              }}
            >
              <span className="status-color" style={{ color: e.colorInfo }}>
                <span
                  className="color-square"
                  style={{
                    backgroundColor: e.colorInfo,
                    marginRight: "8px",
                  }}
                />
                {e.title}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

TaskStatusModal.propTypes = {
  overlay: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  updateTaskStatus: PropTypes.func,
};

export default TaskStatusModal;
