/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { teamActions } from "../index";
import teamService from "../../../../services/teamService";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getTeam } from "./getTeam";

export const patchTeam = createAsyncThunk(
  `patch/team`,
  async ({ id, patchData }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await teamService.patchTeamData(id, patchData);

      dispatch(
        teamActions.editTeamData({
          data,
          successMessage,
        }),
      );
      await dispatch(alertActions.success(successMessage));
      await dispatch(getTeam({ projectId: id, page: 1, limit: 10 }));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(teamActions.setTeamErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
