import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomModal from "../../components/CustomModal";
import { patchFeed } from "../../store/slices/feed/thunks/patchFeed";
import { useThunk } from "../../hooks/useThunk";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import UserMention from "../../components/Feed/userMention";
import { findUserWithVariations } from "../../components/Feed/Common";
import assignedUser from "../../assets/images/tags.svg";

const EditFeed = ({
  selectDropdownOption,
  setSelectDropdownOption,
  authData,
}) => {
  const { feedById, successMessage } = useSelector((state) => state.feed);
  const [editedValue, setEditedValue] = useState({});
  const [feedPatch, isPatch] = useThunk(patchFeed);
  const textareaRef = useRef(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [atPressed, setAtPressed] = useState(false);
  const [atText, setAtText] = useState("");
  const { allUsers } = useSelector((state) => state.common);

  useEffect(() => {
    textareaRef.current.focus();
  }, []);
  console.log(feedById);
  useEffect(() => {
    const data = replaceUserMentionsInText(feedById?.title, allUsers);
    setEditedValue({ ...feedById, title: data });
  }, [feedById]);
  useEffect(() => {
    if (successMessage) {
      setSelectDropdownOption({ type: "", id: "" });
    }
  }, [successMessage]);
  useEffect(() => {
    setImgLoading(true);
    const timeoutId = setTimeout(() => {
      setImgLoading(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {
    if (atPressed) {
      const lastIndex = editedValue?.title?.lastIndexOf("@");
      if (lastIndex >= 0) {
        const textAfterAt = editedValue?.title?.slice(lastIndex + 1);
        if (textAfterAt.includes(" ")) {
          setAtText("");
        } else {
          setAtText(textAfterAt);
        }
      }
    }
  }, [editedValue, atPressed]);
  console.log(editedValue);

  const onSubmit = () => {
    const formData = new FormData();
    formData.append("feedId", editedValue._id);
    const usernamePattern = /@(\w+(\s\w+){0,2})/g;
    const mentionedUserIds = [];
    let modifiedMessage = editedValue?.title;
    const matches = editedValue?.title.match(usernamePattern);
    if (matches) {
      // eslint-disable-next-line no-restricted-syntax
      for (const match of matches) {
        const mentionedUsername = match.substr(1);
        const mentionedUser = findUserWithVariations(
          mentionedUsername,
          allUsers,
        );
        if (mentionedUser) {
          mentionedUserIds.push(mentionedUser._id);
          modifiedMessage = modifiedMessage.replace(
            new RegExp(`@${mentionedUser?.employeeName}`, "g"),
            `{{${mentionedUser._id}}}`,
          );
        }
      }
    }

    formData.append("title", modifiedMessage);
    formData.append("postEdit", true);

    feedPatch({ formData, isEdit: true, userId: authData?._id });
  };
  function replaceUserMentionsInText(text, userList) {
    if (typeof text !== "string") {
      return text; // Return unchanged if not a string
    }
    const userIdPattern = /{{(\w+)}}/g;

    const modifiedText = text.replace(userIdPattern, (match, userId) => {
      const mentionedUser = userList.find((user) => user._id === userId);
      return mentionedUser ? `@${mentionedUser.employeeName}` : match;
    });

    return modifiedText;
  }

  const renderBody = () => (
    <>
      <div className="edit-field-editor">
        <textarea
          ref={textareaRef}
          type="text"
          className="form-control"
          value={editedValue.title}
          onChange={(e) => {
            setEditedValue((prevValue) => ({
              ...prevValue,
              title: e.target.value,
            }));
          }}
          onKeyDown={(e) => {
            if (e.key === "@") {
              const isAtStartOfWordOrSentence =
                editedValue?.title
                  ?.charAt(e.target.selectionStart - 1)
                  .match(/\s/) || e.target.selectionStart === 0;

              if (isAtStartOfWordOrSentence) {
                setAtPressed(true);
                setAtText("");
              } else {
                setAtPressed(false);
              }
            } else if (atPressed && e.key === " ") {
              setAtPressed(false);
            } else if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
            } else if (e.key === "Backspace") {
              const caretPosition = e.target.selectionStart;
              const deletedChar = editedValue?.title?.charAt(caretPosition - 1);
              if (deletedChar === "@") {
                setAtPressed(false);
              }
            }
          }}
        />
        <UserMention
          dropDownVisibility={atPressed}
          onAllocateUser={(userId) => {
            const user = allUsers.find((e) => e._id === userId?.user);
            if (user && atText) {
              const updatedMessage = editedValue?.title?.replace(
                `@${atText}`,
                `@${user?.employeeName}`,
              );
              setEditedValue({
                ...editedValue,
                title: {
                  ...editedValue.title,
                  updatedMessage, // Replace 'new value' with the actual updated value
                },
              });
              setAtText("");
            } else {
              setEditedValue((prevValue) => ({
                ...prevValue,
                title: (() => {
                  const lastIndex = prevValue.title.lastIndexOf("@");
                  if (lastIndex !== -1) {
                    const messageAfterLastAt = prevValue.title.substring(
                      lastIndex + 1,
                    );
                    if (!messageAfterLastAt.includes(" ")) {
                      const updatedMessage = `${prevValue.title.substring(
                        0,
                        lastIndex,
                      )}@${user?.employeeName} `;
                      return updatedMessage;
                    }
                  }

                  return `${prevValue.title}@${user.employeeName} `;
                })(),
              }));
              setAtText("");
            }
            setAtPressed(false);
          }}
          atText={atText}
        />
        <span
          className="icon"
          onClick={(e) => {
            setAtPressed(!atPressed);
            setAtText("");
            e.stopPropagation();
          }}
        >
          <img src={assignedUser} alt="" />
        </span>
      </div>
      <div className="img-upload-wrp">
        {editedValue.attachments?.map((attachment) =>
          imgLoading ? (
            <CommonSkeleton width={150} height={100} />
          ) : (
            <div className="img-preview">
              <img src={attachment?.path} alt="preview" />
            </div>
          ),
        )}
      </div>
    </>
  );
  const renderHeader = () => (
    <h1 className="modal-title fs-5" id="exampleModalLabel">
      Edit Post
    </h1>
  );
  const renderFooter = () => (
    <div className="modal-footer">
      <button type="button" className="btn btn-save" onClick={onSubmit}>
        {isPatch ? (
          <span className="btn-loader">
            <span className="spinner-border spinner-border-sm" />
          </span>
        ) : (
          "Save"
        )}
      </button>
    </div>
  );
  return (
    <CustomModal
      className="edit-comment-modal edit-modal-01"
      dialgName="modal-dialog-centered modal-lg"
      body={renderBody()}
      footer={renderFooter()}
      header={renderHeader()}
      bodyClassname="modal-body"
      createModal={7}
      disableCenter={7}
      show={selectDropdownOption}
      closeButton
      closeModal={() => setSelectDropdownOption({ type: "", id: "" })}
    />
  );
};
EditFeed.propTypes = {
  selectDropdownOption: PropTypes.node,
  setSelectDropdownOption: PropTypes.func,
  authData: PropTypes.shape({
    _id: PropTypes.string,
  }),
};
export default EditFeed;
