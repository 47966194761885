/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { teamActions } from "../index";
import teamService from "../../../../services/teamService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getAllocationRole = createAsyncThunk(
  `get/allocationrole`,
  async ({ deptId }, { dispatch }) => {
    try {
      const {
        data: { Roles, message: successMessage },
      } = await teamService.getRoleData(deptId);
      dispatch(
        teamActions.setRoleData({
          Roles,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(teamActions.setTeamErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
