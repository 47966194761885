/* eslint-disable no-nested-ternary */
import { createSlice } from "@reduxjs/toolkit";

export const name = "meeting";

const createReducers = () => {
  const setShowMeetingSlider = (state, { payload }) => {
    state.showMeetingSlider = payload;
  };
  const setSuccessData = (state, { payload }) => {
    state.successData = payload;
  };
  const setMeetingSchedule = (state, action) => {
    state.meetingData = action.payload.data?.data;

    state.meetingTotalCount = action.payload.data?.totalCount;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };

  return {
    setShowMeetingSlider,
    setMeetingSchedule,
    setSuccessData,
  };
};

function createInitialState() {
  return {
    meetingData: [],
    successData: [],
    meetingTotalCount: null,
    showMeetingSlider: false,
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const meetingScheduleActions = slice.actions;
export const meetingScheduleReducer = slice.reducer;
