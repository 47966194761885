import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTokens, onMessageListener } from "../../config/firebaseConfig";
// import { addPushNotification } from "../../store/slices/PushNotification/thunk/addPushNotification";
// import { useThunk } from "../../hooks/useThunk";

const PushNotification = () => {
  // const { authData } = useSelector((state) => state.auth);
  const { notifyToken } = useSelector((state) => state.pushNotification);
  console.log("notifyToken", notifyToken);

  const dispatch = useDispatch();

  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  console.log("isTokenFound", isTokenFound);
  getTokens(setTokenFound, dispatch);

  // const [postPushNotification] = useThunk(addPushNotification);

  useEffect(() => {
    onMessageListener()
      ?.then((payload) => {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        console.log("myPayload", payload);
      })
      .catch((err) => console.log("failed: ", err));
    console.log("notification", notification);
  }, []);

  // identify device type
  const [deviceTypeData, setDeviceTypeData] = useState("Unknown");
  const { userAgent } = window.navigator;

  console.log("deviceType", deviceTypeData);

  function getDeviceType(userAgent) {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent,
      )
    ) {
      return "mobile";
    }
    if (
      /iPad|Tablet|PlayBook|SC-01C|SC-04D|SH-10B|SGH-T849|Nexus 7|Nexus 10|KFAPWI/i.test(
        userAgent,
      )
    ) {
      return "tablet";
    }
    return "desktop";
  }

  useEffect(() => {
    const deviceType = getDeviceType(userAgent);
    setDeviceTypeData(deviceType);
  }, [userAgent]);

  // const isLoggedIn = useSelector(({ auth: { isLoggedIn } }) => isLoggedIn);

  // const token = localStorage.getItem("accessToken");

  // useEffect(() => {
  //   if (notifyToken) {
  //     postPushNotification({
  //       userId: authData?._id,
  //       deviceToken: notifyToken,
  //       deviceType: deviceTypeData,
  //     });
  //   }
  // }, [isLoggedIn, notifyToken]);

  return (
    <div>
      {/* Your JSX for displaying notifications */}
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
    </div>
  );
};

export default PushNotification;
