/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import CustomModal from "../CustomModal";
import trash from "../../assets/images/confirm-trash.svg";
import Button from "../Button/index";

// Used on Cronjob,TicketManagement,EmailManagement

export const Deletemodal = ({
  ModalShow,
  closeModal,
  cancelModal,
  deleteFunc,
  type,
  icon = trash,
  text = "Are you sure you want to delete",
  loaderButton = false,
  needCount,
}) => {
  const handleClose = () => {
    cancelModal(false);
  };
  const renderBody = () => {
    return (
      <div className="text-center mx-auto">
        <div className="icons-delete">
          <img src={icon} alt="trash" />
        </div>
        <h5 className="modal-title fs-6" id="exampleModalLabel">
          {text} {type} <br />
          <span>{ModalShow.name}</span> ?
        </h5>
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <div className="btn-block">
          <Button
            type="button"
            onClick={() =>
              deleteFunc(ModalShow?.id, ModalShow?.type, needCount)
            }
            disabled={loaderButton}
            className={`modal-btn btn-confirm btn ${
              loaderButton && "isLoading disable"
            }`}
          >
            {loaderButton && (
              <span className="btn-loader">
                <span className="spinner-border spinner-border-sm" />
              </span>
            )}
            Yes, Continue
          </Button>
          <button
            className="modal-btn btn-cancel btn"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <CustomModal
      className="modal fade delete-conf"
      dialgName="modal-dialog-centered modal-lg"
      body={renderBody()}
      footer={renderFooter()}
      bodyClassname="modal-body"
      createModal={7}
      disableCenter={7}
      show={!!ModalShow?.openclose}
      closeModal={() => {
        closeModal(false);
      }}
    />
  );
};

Deletemodal.propTypes = {
  ModalShow: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    docId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelModal: PropTypes.func.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string, // Assuming icon is a string (path to an image)
  text: PropTypes.string,
  loaderButton: PropTypes.bool,
};
Deletemodal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
Deletemodal.propTypes = {
  cancelModal: PropTypes.func.isRequired,
};
