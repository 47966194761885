import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { iconActions } from "..";

import notificationIconServices from "../../../../../services/notificationIconServices";
import { alertActions } from "../../../alert";

export const getKey = createAsyncThunk("key", async (params, { dispatch }) => {
  console.log("Gunddu");
  dispatch(alertActions.clear());

  try {
    const { data } = await notificationIconServices.getKey(params);

    dispatch(
      iconActions.getKey({
        data,
      }),
    );
  } catch (error) {
    const errorMessage = _.isString(error)
      ? error
      : error.response.data.message;
    dispatch(alertActions.error(errorMessage));
  }
});
