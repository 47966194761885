const validTableFields = (value) => {
  const fields = {
    "Uploaded On": "createdAt",
    "Uploaded By": "userDetails._id",
    "File Type": "files",
  };
  const properFieldName = fields[value] ?? value;
  return properFieldName;
};

export const filterQuery = (filters) => {
  const completeFilters = filters.filter((eachFilter) => {
    if (
      ["is set", "is not set"].includes(eachFilter.condition?.value) &&
      eachFilter.where
    )
      return true;
    if (eachFilter.where && eachFilter.condition && eachFilter.option)
      return true;
    return false;
  });

  const gate =
    completeFilters.length && completeFilters[0].gate === "AND"
      ? "$and"
      : "$or";

  const queryCondition = {
    [gate]: [],
  };
  completeFilters?.forEach((eachFilter) => {
    let condition = {};
    const field = validTableFields(eachFilter.where.label);
    condition = { ...condition, fieldName: field };
    if (eachFilter.condition.value === "is") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: Array.isArray(eachFilter.option) ? "$in" : "$eq",
        isDate: ["createdAt"].includes(field) ? "true" : undefined,
      };
    } else if (eachFilter.condition.value === "is not") {
      condition = {
        ...condition,
        value: Array.isArray(eachFilter.option)
          ? !!eachFilter.option.length &&
            eachFilter.option.map(({ value }) => value)
          : eachFilter.option.value ?? eachFilter.option,
        query: Array.isArray(eachFilter.option) ? "$nin" : "$ne",
        isDate: ["createdAt"].includes(field) ? "true" : undefined,
      };
    } else if (eachFilter.condition.value === "is not set") {
      condition = { ...condition, value: "true", query: "$eq" };
    } else if (eachFilter.condition.value === "is set") {
      condition = { ...condition, value: "false", query: "$ne" };
    }
    if (condition.value) queryCondition[gate].push(condition);
    return eachFilter;
  });
  return queryCondition[gate].length ? queryCondition : null;
};
