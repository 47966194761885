import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { alertActions } from "../../alert/index";
import { updateEmployeeDesignationService } from "../../../../services/employeeService";
import { designationUpdate } from "../index";

export const updateDesignation = createAsyncThunk(
  "employee/update-designaton",
  async function (params, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data } = await updateEmployeeDesignationService(params);
      dispatch(alertActions.success(data.message));
      dispatch(designationUpdate(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
