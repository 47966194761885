import PropTypes from "prop-types";
import { getFirstLetters } from "../../utils/utils";
import { NoAllocation } from "./svgIcons";
import { getNameAndUniqId } from "../../components/common/iconDefaultName";

const UserAllocationTable = ({ userReport }) => {
  // Generate formattedArray based on allocationHours for each project

  const formattedArray = userReport?.allProjects?.reduce((acc, e) => {
    const timeCalculation = Array?.from({ length: e?.allocationHours }, () => ({
      projectName: e?.projectName,
      allocationHours: e?.allocationHours,
      description: e?.description,
      logo: e?.logo,
    }));
    acc = [...acc, ...timeCalculation];
    return acc;
  }, []);

  // Initialize the hour count
  const hourCount = 1;

  // Ensure 12 items in formattedArray with null placeholders
  while (formattedArray?.length < 12) {
    formattedArray.push(null);
  }

  return (
    <table className="table allocation-table ">
      <tbody>
        <tr>
          <th>HOUR</th>
          <th>ASSIGNED PROJECT</th>
          <th>TASK ASSIGNED BY:</th>
        </tr>
        {formattedArray?.map((item, index) => {
          const currentHour = hourCount + index;
          return (
            <tr key={index}>
              <td className={`${item === null ? "no-task" : ""}`}>
                {currentHour}
              </td>
              {item !== null ? (
                <>
                  <td>
                    <div className="project-details-blk">
                      <div
                        className={`profile-task bg-${
                          getNameAndUniqId(item?.projectName).uniqId
                        }`}
                      >
                        <span>{getFirstLetters(item?.projectName)}</span>
                      </div>
                      <div className="task-details">
                        <div className="name">{item?.projectName}</div>
                        <div className="hours-total">
                          Total Approved Hours : {item?.allocationHours}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="project-details-blk">
                      {item?.pms ? (
                        <>
                          <div className="profile-task">
                            <span>N</span>
                          </div>
                          <div className="task-details">
                            <div className="name">-</div>
                            <div className="hours-total">-</div>
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                </>
              ) : (
                <td colSpan="2" className="no-task">
                  <div className="no-task">
                    <span>
                      <NoAllocation />
                    </span>
                    No Tasks Assigned
                  </div>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
UserAllocationTable.propTypes = {
  userReport: PropTypes.arrayOf(),
};

export default UserAllocationTable;
