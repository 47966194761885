import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import trashService from "../../../../services/trashService";
import { alertActions } from "../../alert";
import { getTrash } from "./getTrash";
import { getUserProjects } from "../../project/thunks/getUserProjects";

export const restoreTrash = createAsyncThunk(
  "restore/trash",
  async ({ id, type, params }, { dispatch }) => {
    try {
      const {
        data: { message: successMessage },
      } = await trashService.restoreTrashData({ id, type });
      dispatch(getTrash(params));
      dispatch(getUserProjects({}));
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
