import { createSlice } from "@reduxjs/toolkit";

export const name = "activityLog";

const createReducers = () => {
  const setActivityLogData = (state, { payload }) => {
    if (payload?.params?.forReport) {
      state.activityDataforExport = payload.data;
      state.successMessage = payload.successMessage;
    }
    state.activityLogData = payload;
    state.successMessage = payload.successMessage;
  };
  const setAllActivityLogData = (state, { payload }) => {
    if (payload?.params?.forReport) {
      state.activityDataforExport = payload.data;
      state.successMessage = payload.successMessage;
    }
    state.allActivityData = payload.data;
    state.successMessage = payload.successMessage;
  };
  const clearExportData = (state) => {
    state.activityDataforExport = null;
  };

  return {
    setActivityLogData,
    setAllActivityLogData,
    clearExportData,
  };
};

function createInitialState() {
  return {
    activityLogData: null,
    activityDataforExport: null,
    errorMessage: "",
    successMessage: "",
    allActivityData: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const activityLogActions = slice.actions;
export const activityLogReducer = slice.reducer;
