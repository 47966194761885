import { configureStore } from "@reduxjs/toolkit";

// Import other reducers here
import { alertReducer, name as alertReducerName } from "./slices/alert";
import { authReducer, name as authReducerName } from "./slices/auth";
import {
  activityLogReducer,
  name as activityLogName,
} from "./slices/activitylog";
import {
  filterDropdownReducer,
  name as filterReducerName,
} from "./slices/filter";
import { projectReducer, name as projectReducerName } from "./slices/project";
import { sprintReducer, name as sprintReducerName } from "./slices/sprint";
import { trashReducer, name as trashReducerName } from "./slices/trash";
import {
  dashboardReducer,
  name as dashboardReducerName,
} from "./slices/dashboard";

import {
  ticketReducer,
  name as ticketReducerName,
} from "./slices/ticket/index";
import {
  departmentReducer,
  name as departmentReducerName,
} from "./slices/department";
import {
  permissionReducer,
  name as permissionReducerName,
} from "./slices/permissions";
import { roleReducer, name as roleReducerName } from "./slices/role";
import {
  userProfileReducer,
  name as userProfileName,
} from "./slices/userProfile";
import { teamReducer, name as teamReducerName } from "./slices/team";
import { reportReducer, name as reportReducerName } from "./slices/report";
import {
  technologyReducer,
  name as technologyReducerName,
} from "./slices/technology";
import { commonReducer, name as commonReducerName } from "./slices/common";
import { leaveReducer, name as leaveReducerName } from "./slices/leave";
import {
  projectManagementReducer,
  name as projectManagementReducerName,
} from "./slices/projectManagement";
import {
  notificationReducer,
  name as notificationReducerName,
} from "./slices/notifications";
import {
  documentReducer,
  name as documentReducerName,
} from "./slices/documents";
import { jokesReducer, name as jokesReducerName } from "./slices/jokes";
import {
  myNotificationsReducer,
  name as myNotificationsReducerName,
} from "./slices/MyNotifications";
import { themeReducer, name as themeReducerName } from "./slices/theme";
import {
  gitHubReducer,
  name as gitHubReducerName,
} from "./slices/gitIntegration";
import {
  allocationReducer,
  name as allocationReducerName,
} from "./slices/allocationList";
import { cronjobReducer, name as cronjobReducerName } from "./slices/cronjob";

import {
  designReducer,
  name as designReducerName,
} from "./slices/whiteLabel/design";

import {
  emailAccountReducer,
  name as emailAccountReducerName,
} from "./slices/whiteLabel/emailAccount";

import {
  iconReducer,
  name as iconReducerName,
} from "./slices/whiteLabel/icons";

import {
  pushNotificationReducer,
  name as PushNotificationReducerName,
} from "./slices/PushNotification";
import {
  timesheetReducer,
  name as timesheetReducerName,
} from "./slices/Timesheet";
import { feed, name as feedReducerName } from "./slices/feed";
import { navBarReducer, name as navBarReducerName } from "./slices/navBar";
import { todoReducer, name as todoReducerName } from "./slices/todoList";
import { colorReducer, name as colorReducerName } from "./slices/color";
import {
  meetingScheduleReducer,
  name as meetingScheduleReducerName,
} from "./slices/meetingSchedule";
import { notesReducer, name as notesReducerName } from "./slices/notes";
import {
  employeeReducer,
  name as employeeReducerName,
} from "./slices/employee";

export const store = configureStore({
  reducer: {
    [meetingScheduleReducerName]: meetingScheduleReducer,
    [timesheetReducerName]: timesheetReducer,
    [PushNotificationReducerName]: pushNotificationReducer,
    [allocationReducerName]: allocationReducer,
    [alertReducerName]: alertReducer,
    [authReducerName]: authReducer,
    [activityLogName]: activityLogReducer,
    [filterReducerName]: filterDropdownReducer,
    [projectReducerName]: projectReducer,
    [sprintReducerName]: sprintReducer,
    [teamReducerName]: teamReducer,
    [trashReducerName]: trashReducer,
    [dashboardReducerName]: dashboardReducer,
    [ticketReducerName]: ticketReducer,
    [departmentReducerName]: departmentReducer,
    [roleReducerName]: roleReducer,
    [permissionReducerName]: permissionReducer,
    [userProfileName]: userProfileReducer,
    [ticketReducerName]: ticketReducer,
    [teamReducerName]: teamReducer,
    [reportReducerName]: reportReducer,
    [commonReducerName]: commonReducer,
    [technologyReducerName]: technologyReducer,
    [projectManagementReducerName]: projectManagementReducer,
    [leaveReducerName]: leaveReducer,
    [notificationReducerName]: notificationReducer,
    [documentReducerName]: documentReducer,
    [jokesReducerName]: jokesReducer,
    [myNotificationsReducerName]: myNotificationsReducer,
    [themeReducerName]: themeReducer,
    [gitHubReducerName]: gitHubReducer,
    [designReducerName]: designReducer,
    [emailAccountReducerName]: emailAccountReducer,
    [cronjobReducerName]: cronjobReducer,
    [iconReducerName]: iconReducer,
    [feedReducerName]: feed,
    [navBarReducerName]: navBarReducer,
    [todoReducerName]: todoReducer,
    [colorReducerName]: colorReducer,
    [notesReducerName]: notesReducer,
    [employeeReducerName]: employeeReducer,
  },
});

// Exporting everything here to remove circular dependency

// const reducer = combineReducers({ dummy: () => "", authReducer });

export * from "./slices/auth/thunks/doLogin";
export * from "./slices/auth/thunks/doLogout";
export * from "./slices/sprint/thunks/getHolidays";
export * from "./slices/activitylog/thunks/getActivity";
export * from "./slices/trash/thunks/getTrash";
export * from "./slices/trash/thunks/deleteTrash";
export * from "./slices/trash/thunks/restoreTrash";

// Permissiom Reducer
export * from "./slices/department/thunks/getDepartmentList";
export * from "./slices/department/thunks/getDepartmentPermissions";
export * from "./slices/permissions/thunks/getPermissionsList";
export * from "./slices/department/thunks/updateDepartment";
export * from "./slices/role/thunks/getRoleList";
export * from "./slices/role/thunks/getRolePermissions";
export * from "./slices/role/thunks/updateRole";

// UserProfile Reducer
export * from "./slices/userProfile/thunks/getUserProfile";
export * from "./slices/ticket/thunks/getTicket";
export * from "./slices/userProfile/thunks/getMultyUsers";

// Team Reducer specific exports
export * from "./slices/team/thunks/getTeam";
export * from "./slices/team/thunks/patchTeam";
export * from "./slices/team/thunks/getAllocationRole";
export * from "./slices/team/thunks/getDepartment";
export * from "./slices/team/thunks/getMember";
export * from "./slices/ticket/thunks/patchTicket";
export * from "./slices/ticket/thunks/deleteTicketAllocation";
export * from "./slices/ticket/thunks/getUsers";
export * from "./slices/ticket/thunks/deleteTicket";

// dashboard reducer
export * from "./slices/dashboard/thunks/getDashBoardPerformIndex";
export * from "./slices/dashboard/thunks/getDashboardQualityIndex";

// Common Reducer
export * from "./slices/common/thunks/getTicketStatus";

// Technology Reducer
export * from "./slices/technology/thunks/getdepartmentsTechnology";
export * from "./slices/technology/thunks/getAllTechnology";
export * from "./slices/technology/thunks/addTechnology";

// projectManagement Reducer
export * from "./slices/projectManagement/thunks/getProjectDashboardCount";

export * from "./slices/notifications/thunks/getNotifications";
export * from "./slices/sprint/thunks/postsprint";
export * from "./slices/projectview/thunks/updateTicket";
export * from "./slices/projectview/thunks/getTicketById";
export * from "./slices/projectview/thunks/getnotificationbyId";
export * from "./slices/sprint/thunks/getSprint";
export * from "./slices/sprint/thunks/getTicketCount";
export * from "./slices/sprint/thunks/completeSprint";
export * from "./slices/report/thunks/getReport";
export * from "./slices/ticket/thunks/getTicketAllocationById";
export * from "./slices/ticket/thunks/getTicketsById";

// Ticket reducer

export * from "./slices/ticket/thunks/postTicket";
export * from "./slices/ticket/thunks/getTicketAllocations";
export * from "./slices/ticket/thunks/patchTicketAllocation";
export * from "./slices/ticket/thunks/patchTicketAttachment";
export * from "./slices/ticket/thunks/patchTicketAllocationAtm";
export * from "./slices/ticket/thunks/postTicketAllocation";
export * from "./slices/ticket/thunks/deleteTicketAttachment";
export * from "./slices/ticket/thunks/deleteTicketAllocationAttachment";

// get jokes content
export * from "./slices/jokes/thunks/getJokes";

//  settings >>Git integration

export * from "./slices/gitIntegration/thunks/getGitAccessToken";
export * from "./slices/gitIntegration/thunks/dissconnectGit";
export * from "./slices/gitIntegration/thunks/getGitRepos";
export * from "./slices/gitIntegration/thunks/getUserDetails";
export * from "./slices/gitIntegration/thunks/postSubmit";
export * from "./slices/gitIntegration/thunks/tableDetails";
export * from "./slices/gitIntegration/thunks/getBranchs";

// uploadsheet ticket
export * from "./slices/ticket/thunks/uploadSheetTicket";
//  settings >>my Notifications
export * from "./slices/MyNotifications/thunks/getActivityNotifications";
export * from "./slices/MyNotifications/thunks/updateNotificationSettings";

// allocation reducer
export * from "./slices/allocationList/thunks/getAllocationList";
export * from "./slices/allocationList/thunks/updateAllocationList";

// design reducer
export * from "./slices/whiteLabel/design/thunk/getDesignDetails";
export * from "./slices/whiteLabel/design/thunk/updateDesignDetails";

// cron-job reducer
export * from "./slices/cronjob/thunk/getAllCronjob";

// emailAccount reducer
export * from "./slices/whiteLabel/emailAccount/thunk/getEmailAccountDetails";
export * from "./slices/whiteLabel/emailAccount/thunk/postEmailAccountDetails";
export * from "./slices/whiteLabel/emailAccount/thunk/updateEmailAccountDetails";
export * from "./slices/whiteLabel/emailAccount/thunk/getEmailAccountDetailsById";
export * from "./slices/whiteLabel/emailAccount/thunk/deleteEmailAccount";

// icon reducer
export * from "./slices/whiteLabel/icons/thunk/getNotificationDetails";
export * from "./slices/whiteLabel/icons/thunk/postNotificationDetails";
export * from "./slices/whiteLabel/icons/thunk/updateNotificationDetails";
export * from "./slices/whiteLabel/icons/thunk/getNotificationDetailsById";
export * from "./slices/whiteLabel/icons/thunk/deleteNotification";
export * from "./slices/whiteLabel/icons/thunk/getTechnologyIconById";
export * from "./slices/whiteLabel/icons/thunk/postTechnologyIcon";
export * from "./slices/whiteLabel/icons/thunk/getTechnologyIcon";
export * from "./slices/whiteLabel/icons/thunk/updateTechnologyIcon";
export * from "./slices/whiteLabel/icons/thunk/deleteTechnologyIcon";
export * from "./slices/whiteLabel/icons/thunk/getKeys";

// time sheet
export * from "./slices/Timesheet/thunk/getUserTimesheet";

// feedreducer
export * from "./slices/feed/thunks/getFeed";
export * from "./slices/feed/thunks/postFeed";
export * from "./slices/feed/thunks/getTodaysUser";
export * from "./slices/feed/thunks/getBlog";
export * from "./slices/feed/thunks/savedFeed";

// navbarreducer
export * from "./slices/navBar/thunks/getProjectIcon";

// notesReducer
export * from "./slices/notes/thunks/postNotes";
export * from "./slices/notes/thunks/getNotes";
export * from "./slices/notes/thunks/deleteNotes";
export * from "./slices/notes/thunks/updateNotes";
