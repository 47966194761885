/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import dragIcon from "../../assets/images/drag__icon-svg.svg";
import noteIcon from "../../assets/images/note__icon.svg";
import editSvg from "../../assets/images/edit.svg";
import Threedots from "../DropDowns/Threedots";
import { StatusOutSide } from "../DropDowns/statusOutSide";
import CommonSkeleton from "../SkeletonLoader/CommonSkeleton";
// import { hasSubModulePermission } from "../../utils/permissionUtils";
// import permissionConstants from "../../utils/permissionUtils/permissionConstants";

const SubTask = ({
  TaskData,
  taskEdit,
  configData,
  ActionButtons,
  // handleDragOver,
  // handleDragStart,
  // handleDrop,
  Ticket,
  // groupById,
  patchFunction,
  setSelectedTicket,
  isTicket,
  groupBy,
  setSelectedRows,
  selectedRows,
  EditLoader,
  // userProfile,
  taskView,
  // view,
}) => {
  if (groupBy === "status" || groupBy === "") groupBy = "ticketStatus";
  return Ticket?.allocations?.map((Allocations, index) => {
    // const data = JSON.stringify({
    //   id: Allocations?._id,
    //   ticketId: Allocations?.ticket,
    //   itemtype: "allocation",
    //   index,
    //   [groupBy]: groupById,
    // });
    return (
      <div
        // content={data}
        key={index}
        // draggable
        // onDragStart={(e) => handleDragStart(e, Allocations)}
        // onDragOver={handleDragOver}
        // onDrop={handleDrop}
        className="subTaskList-cover inner-row "
        id="collapseExample-1"
      >
        {EditLoader === Allocations?._id ? (
          <div style={{ marginLeft: "2.2%" }}>
            <CommonSkeleton height={40} />
          </div>
        ) : (
          <div
            // content={data}
            className="task-list-rows"
          >
            <div
              //  content={data}
              className="row-controler ms-2"
            >
              <div
                //  content={data}
                className="chekbxOtr "
              >
                <input
                  onChange={() => {
                    if (selectedRows.some((e) => e._id === Allocations._id))
                      setSelectedRows(
                        selectedRows.filter(
                          (eachRow) => eachRow._id !== Allocations._id,
                        ),
                      );
                    else setSelectedRows([...selectedRows, Allocations]);
                  }}
                  checked={selectedRows.some((e) => e._id === Allocations._id)}
                  type="checkbox"
                  className={
                    selectedRows.some((e) => e._id === Allocations._id)
                      ? "checkbox-round active"
                      : "checkbox-round"
                  }
                />
              </div>
              <div
                // content={data}
                className="drag_icon"
                style={{ cursor: "pointer" }}
              >
                <img src={dragIcon} alt="icon" />
              </div>
            </div>

            <div
              // content={data}
              className="row__task_lft sticky-element"
            >
              <div
                // content={data}
                className="button-grp  ms-4"
              >
                <StatusOutSide
                  dropDownVisibilityPermissionConstant
                  TaskData={Allocations}
                  dropDownVisibility
                  type={Allocations?.type}
                  patchFunction={patchFunction}
                  dbName="ticketStatus"
                />
              </div>
              <div
                //  content={data}
                className="contents_info d-flex "
              >
                <div
                  // content={data}
                  onClick={() => {
                    if (taskView) {
                      setSelectedTicket(Allocations._id);
                      isTicket(false);
                    }
                  }}
                  className="link-text-inner "
                  style={{ cursor: "pointer" }}
                >
                  {Allocations?.title}
                </div>
                <div
                  // content={data}
                  className="link-text-inner"
                >
                  <div
                    //  content={data}
                    className="action-control d-flex "
                  >
                    {Allocations?.description && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={Allocations?._id}>
                            {Allocations?.description}
                          </Tooltip>
                        }
                      >
                        <button type="button" className="btn-sm border-0">
                          <img src={noteIcon} alt="icon" />
                        </button>
                      </OverlayTrigger>
                    )}
                    {taskEdit || taskView ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit Ticket</Tooltip>}
                      >
                        <button
                          onClick={() => {
                            setSelectedTicket(Allocations._id);
                            isTicket(false);
                          }}
                          type="button"
                          className="btn-sm edit__btn"
                        >
                          <img src={editSvg} alt="" />
                        </button>
                      </OverlayTrigger>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div
              // content={data}
              className="info-right d-flex align-items-center"
            >
              {configData?.map(
                (item) =>
                  item?.isVisible &&
                  item?.name !== "title" && (
                    <div
                      // content={data}
                      className="info-td"
                      key={item?.dbName}
                    >
                      <div
                        // content={data}
                        className="content-inr"
                      >
                        {item?.cell ? (
                          <item.cell
                            TaskData={Allocations}
                            fullTaskData={TaskData}
                            dropDownVisibility={item?.dropDownVisibility?.includes(
                              Allocations?.type,
                            )}
                            type={Allocations?.type}
                            patchFunction={patchFunction}
                            dbName={item?.dbName}
                            className="d-flex justify-content-center ms-3"
                            index={index}
                            dropDownVisibilityPermissionConstant={
                              item?.dropDownVisibilityPermissionConstant
                            }
                            needCount
                          />
                        ) : Allocations[item?.dbName]?.length >= 11 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={Allocations?._id}>
                                {Allocations[item?.dbName]}
                              </Tooltip>
                            }
                          >
                            <div
                              //  content={data}
                              className="content-inr"
                            >
                              {Allocations[item?.dbName]?.slice(0, 10)}...
                            </div>
                          </OverlayTrigger>
                        ) : (
                          Allocations[item?.dbName]
                        )}
                      </div>
                    </div>
                  ),
              )}
              {ActionButtons ? (
                <Threedots
                  dropDownVisibility
                  type={Allocations?.type}
                  TaskData={Allocations}
                  fullTaskData={TaskData}
                  ActionButtons={ActionButtons}
                  className="info-td"
                  needCount
                />
              ) : (
                <div className="info-td" />
              )}
            </div>
          </div>
        )}
      </div>
    );
  });
};

SubTask.propTypes = {
  TaskData: PropTypes.isRequired,
  taskEdit: PropTypes.bool.isRequired,
  configData: PropTypes.isRequired,
  ActionButtons: PropTypes.node.isRequired,
  Allocations: PropTypes.isRequired,
  index: PropTypes.isRequired,
  userProfile: PropTypes.node,
};

export default SubTask;
