import { createSlice } from "@reduxjs/toolkit";

export const name = "userProfile";

const createReducers = () => {
  const setUserProfile = (state, { payload }) => {
    state.userProfile = { ...state.userProfile, ...payload };
  };
  const setMultyUserProfileData = (state, { payload }) => {
    if (!payload) {
      // If closing the modal, reset user profile data
      state.userProfile = null;
    }
    state.MultyUsersData = payload;
  };

  const setShowUserProfile = (state, { payload }) => {
    if (!payload) {
      // If closing the modal, reset user profile data
      state.MultyUsersData = null;
    }
    state.showUserprofile = payload;
  };

  const setUserProfileLoader = (state, { payload }) => {
    state.userProfileLoader = payload;
  };

  return {
    setUserProfileLoader,
    setUserProfile,
    setShowUserProfile,
    setMultyUserProfileData,
  };
};

function createInitialState() {
  return {
    userProfile: null,
    MultyUsersData: null,
    showUserprofile: false,
    userProfileLoader: false,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const userProfileActions = slice.actions;
export const userProfileReducer = slice.reducer;
