import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import userService from "../../../../services/userService";
import { authActions } from "..";
import { userProfileActions } from "../../userProfile";

export const patchUserData = createAsyncThunk(
  "user/patch",
  async (patchData, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await userService.patchUserData(patchData);
      if (data) {
        dispatch(
          authActions.setUserData({
            data,
            successMessage,
          }),
        );
        dispatch(userProfileActions.setUserProfile(data));
      }
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
