import { createContext } from "react";
import PropTypes from "prop-types";
import en from "../locale/en";

const LanguageContext = createContext();

function LanguageProvider({ children }) {
  // const [languageData, setLanguageData] = useState(en");
  // useEffect(() => {
  //   setLanguageData(en);
  // }, []);
  return (
    <LanguageContext.Provider value={{ languageData: en }}>
      {children}
    </LanguageContext.Provider>
  );
}
LanguageProvider.propTypes = {
  children: PropTypes.element,
};
export { LanguageProvider };
export default LanguageContext;
