import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CustomModal from "../../../../components/CustomModal";
import { useThunk } from "../../../../hooks/useThunk";
import {
  addEmailAccount,
  updateEmailAccount,
  getEmailAccountById,
} from "../../../../store";
import { alertActions } from "../../../../store/slices/alert";

import closeIcon from "../../../../assets/images/close-01.svg";
import icon from "../../../../assets/images/info.svg";
import { FileUpload } from "../svg";

const AddModalEmail = ({ showModal, closeModal, EmailAccountList }) => {
  const emailAccountFields = {
    name: { value: "" },
    emailAddress: { value: "" },
    hostURL: { value: "" },
    port: { value: "" },
    userPhoto: { value: "" },
  };

  // state
  const [emailAccountDetails, setEmailAccountDetails] =
    useState(emailAccountFields);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});

  // action
  const [AddEmailAccount, isLoading] = useThunk(addEmailAccount);
  const [UpdateEmailAccount, isLoadingUpdate] = useThunk(updateEmailAccount);
  const [GetEmailAccountById] = useThunk(getEmailAccountById);

  // reducer
  const { singleEmailAccount } = useSelector((state) => state.emailAccount);
  const { value } = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value?.type === "success") {
      closeModal();
      EmailAccountList();
      dispatch(alertActions.clear());
    }
  }, [value]);

  useEffect(() => {
    if (showModal?._id) {
      GetEmailAccountById(showModal?._id);
    } else {
      setEmailAccountDetails({
        name: { value: "" },
        emailAddress: { value: "" },
        hostURL: { value: "" },
        port: { value: "" },
        userPhoto: { value: "" },
      });
    }
  }, []);

  useEffect(() => {
    if (showModal?._id && singleEmailAccount)
      setEmailAccountDetails({
        name: { value: singleEmailAccount?.name },
        emailAddress: { value: singleEmailAccount?.emailAddress },
        hostURL: { value: singleEmailAccount?.hostURL },
        port: { value: singleEmailAccount?.port },
        userPhoto: { value: singleEmailAccount?.userPhoto },
      });
  }, [singleEmailAccount]);

  const handleChange = (value, key) => {
    const tempData = structuredClone(emailAccountDetails);
    tempData[key].value = value;
    if (Object.keys(errors).length) validate(tempData);
    setEmailAccountDetails(tempData);
  };

  const onImageChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFormats = [".jpg", ".png"];
      const maxSizeInBytes = 10 * 1024 * 1024;
      const isFormatAllowed = allowedFormats.some((format) =>
        selectedFile.name.endsWith(format),
      );
      if (isFormatAllowed && selectedFile.size <= maxSizeInBytes) {
        setErrors({ ...errors, userPhoto: "" });
        setEmailAccountDetails({
          ...emailAccountDetails,
          userPhoto: { value: selectedFile },
        });
        setImageFile(selectedFile);
        setImagePreview(URL.createObjectURL(selectedFile));
      } else {
        let errorMessage =
          "Invalid file format. Please select a .jpg or .png file.";
        if (selectedFile.size > maxSizeInBytes) {
          errorMessage =
            "File size exceeds 10mb. Please select a smaller file.";
        }
        setErrors({
          ...errors,
          userPhoto: errorMessage,
        });
        e.target.value = "";
        setEmailAccountDetails({
          ...emailAccountDetails,
          userPhoto: { value: "" },
        });
        setImageFile(null);
        setImagePreview(null);
      }
    }
  };

  const validate = (tempData = emailAccountDetails) => {
    const newErrors = {};
    if (!tempData?.name?.value) {
      newErrors.name = "Name is Required";
    } else if (!/^[a-zA-Z ]+$/.test(tempData?.name?.value)) {
      newErrors.name = "Name is not valid";
    }
    if (!tempData?.emailAddress?.value) {
      newErrors.emailAddress = "Email Address is Required";
    } else if (!/\S+@\S+\.\S+/.test(tempData?.emailAddress?.value)) {
      newErrors.emailAddress = "Email Address is not valid";
    }
    if (!tempData?.hostURL?.value) {
      newErrors.hostURL = "Host URL is Required";
    } else if (!/^https?:\/\/\S+/.test(tempData?.hostURL?.value)) {
      newErrors.hostURL = "Host URL is not a valid";
    }
    if (!tempData?.port?.value) {
      newErrors.port = "Port is Required";
    } else if (
      !/^\d+$/.test(tempData.port.value) ||
      +tempData.port.value <= 0
    ) {
      newErrors.port = "Port is not a valid";
    }
    if (!tempData?.userPhoto?.value) {
      newErrors.userPhoto = "User Photo is Required";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleClick = () => {
    const newErrors = validate();
    if (!Object.keys(newErrors).length) {
      const formData = new FormData();
      formData.append("name", emailAccountDetails?.name?.value);
      formData.append("emailAddress", emailAccountDetails?.emailAddress?.value);
      formData.append("hostURL", emailAccountDetails?.hostURL?.value);
      formData.append("port", emailAccountDetails?.port?.value);
      formData.append(
        "userPhoto",
        emailAccountDetails?.userPhoto?.value || imageFile,
      );

      if (showModal?._id) {
        UpdateEmailAccount({ id: showModal?._id, data: formData });
      } else {
        AddEmailAccount(formData);
      }
    }
  };

  const renderHeader = () => (
    <h4 className="modal-title">
      {showModal?._id ? "Edit" : "Add"} Email Account
    </h4>
  );

  const renderBody = () => (
    <div className="add-account-modal-body">
      <div className="form-cover row">
        <div className="inp-block alert-cover col-6">
          <label htmlFor="calSprint" className="form-label">
            Name<span>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="calSprint"
            placeholder=""
            value={emailAccountDetails?.name?.value}
            onChange={({ target: { value } }) => handleChange(value, "name")}
          />
          <span className="error__msg">{errors?.name}</span>

          <div className="form-alert">*Required</div>
        </div>
        <div className="inp-block form-group alert-cover col-6">
          <label htmlFor="" className="form-label">
            Email Address<span>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="calSprint"
            placeholder=""
            value={emailAccountDetails?.emailAddress?.value}
            onChange={({ target: { value } }) =>
              handleChange(value, "emailAddress")
            }
          />
          <span className="error__msg">{errors?.emailAddress}</span>
        </div>
        <div className="inp-block form-group alert-cover col-6">
          <label htmlFor="" className="form-label">
            Host URL<span>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="calSprint"
            placeholder=""
            value={emailAccountDetails?.hostURL?.value}
            onChange={({ target: { value } }) => handleChange(value, "hostURL")}
          />
          <span className="error__msg">{errors?.hostURL}</span>
        </div>
        <div className="inp-block form-group alert-cover col-6">
          <label htmlFor="" className="form-label">
            Port<span>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="calSprint"
            placeholder=""
            value={emailAccountDetails?.port?.value}
            onChange={({ target: { value } }) => handleChange(value, "port")}
          />
          <span className="error__msg">{errors?.port}</span>
        </div>
        <div className="inp-block form-group alert-cover col-6">
          <label htmlFor="" className="form-label">
            User Photo
            <span>*</span>
            <span className="tooltip_icon">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="id"
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      left: 0,
                    }}
                  >
                    <table className="text-start">
                      <tr>
                        <td>
                          <span className="fw-semibold">Max size:</span>
                        </td>
                        <td>10mb</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">Valid type:</span>
                        </td>
                        <td>JPG, PNG</td>
                      </tr>
                    </table>
                  </Tooltip>
                }
              >
                <img src={icon} alt="" />
              </OverlayTrigger>
            </span>
          </label>
          <div className="upload-btn-wrapper">
            <div className="hiddenUpload">
              <input
                type="file"
                className=""
                accept=".jpg,.png"
                onChange={onImageChange}
              />
              <span className="error__msg">{errors?.userPhoto}</span>
              {imagePreview || emailAccountDetails?.userPhoto?.value ? (
                <div className="uploaded-itm d-flex">
                  {" "}
                  <img
                    src={imagePreview || emailAccountDetails?.userPhoto?.value}
                    alt="icon"
                  />
                  <button
                    type="button"
                    className="btn close"
                    value={imagePreview}
                    onClick={() => {
                      setImagePreview(null);
                      setEmailAccountDetails({
                        ...emailAccountDetails,
                        userPhoto: { value: "" },
                      });
                      setImageFile(null);
                    }}
                  >
                    <img src={closeIcon} alt="close" />
                  </button>
                </div>
              ) : (
                <div className="upload-label-task">
                  <span>
                    <FileUpload />
                  </span>{" "}
                  Upload File
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="btn-block">
        <button
          className="btn-primary btn-create"
          type="button"
          onClick={handleClick}
          disabled={isLoading || isLoadingUpdate}
        >
          {isLoading || isLoadingUpdate ? "Loading.." : "Save"}
        </button>
      </div>
    </div>
  );

  return (
    <CustomModal
      className="modal-default add-mail-account show"
      dialgName="modal-lg "
      show={!!showModal}
      closeModal={closeModal}
      header={renderHeader()}
      body={renderBody()}
      createModal
      closeButton
    />
  );
};
AddModalEmail.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  EmailAccountList: PropTypes.func,
};
export default AddModalEmail;
