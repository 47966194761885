import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { useSelector } from "react-redux";
import { withTitle } from "../../../../hoc/withTitle";
import { getNotificationtList } from "../../../../store/slices/whiteLabel/icons/thunk/getNotificationDetails";
import { addNotification } from "../../../../store/slices/whiteLabel/icons/thunk/postNotificationDetails";
import { useThunk } from "../../../../hooks/useThunk";
import { getNotificationIconById } from "../../../../store/slices/whiteLabel/icons/thunk/getNotificationDetailsById";
import { deleteNotification } from "../../../../store/slices/whiteLabel/icons/thunk/deleteNotification";
import { updateNotification } from "../../../../store/slices/whiteLabel/icons/thunk/updateNotificationDetails";
import {
  deleteTechnologyIcon,
  getKey,
  getTechnologyIcon,
} from "../../../../store";
import { useFilter } from "../../../../context/useFilter";

import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../../utils/permissionUtils";
import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";

import "../../../../design/custom/pages/manage-icons.scss";
import deleteIcon from "../../../../assets/images/delete_icon_01.svg";
import editIcon from "../../../../assets/images/edit_icon.svg";
import addIcon from "../../../../assets/images/add-btn.svg";
import closeIcon from "../../../../assets/images/close-01.svg";

import SubHeader from "../../../../structure/SubHeader/SubHeader";
import CustomSelect from "../../../../components/CustomSelect";
import CustomTable from "../../../../components/CustomTable";
import { Deletemodal } from "../../../../components/DropDowns/DeleteModal";
import AddModalTechnology from "./AddModalTechnology";

const Icons = () => {
  // const { filter, setFilter } = useFilter();
  const { filter } = useFilter();
  const { iconHandler } = useParams();

  // state
  const [addshowModal, setAddShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    openclose: false,
    name: "",
    id: "",
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState({
    title: { value: "", error: "" },
    icon: { value: "", error: "" },
    key: { value: "", error: "" },
  });
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState({});
  const [techId, setTechId] = useState("");
  const [params, setParams] = useState("");
  const [incrementNotification, setIncrementNotification] = useState(15);
  const [incrementTechnologyIcon, setIncrementTechnologyIcon] = useState(15);

  // action
  const [GetNotificationtList, isLoadingGetNotification] =
    useThunk(getNotificationtList);
  const [AddNotification, isLoadingAddNotification] = useThunk(addNotification);
  const [GetNotificationIconById] = useThunk(getNotificationIconById);
  const [DeleteNotification, isLoadingDeleteNotification] =
    useThunk(deleteNotification);
  const [UpdateNotification, isLoadingUpdateNotification] =
    useThunk(updateNotification);
  const [GetTechnologyIcon, isLoadingGetTechnology] =
    useThunk(getTechnologyIcon);
  const [DeleteTechnologyIcon, isLoadingDeleteTechnology] =
    useThunk(deleteTechnologyIcon);
  const [GetKey] = useThunk(getKey);

  // reducer
  const {
    notificationIconList,
    singleNotification,
    getTechnologyIconList,
    key,
  } = useSelector((state) => state.icon);
  const { userProfile } = useSelector((state) => state.userProfile);

  const renderNotificationList = () => {
    GetNotificationtList(params);
  };

  const renderTechnologyList = () => {
    GetTechnologyIcon(params);
  };

  useEffect(() => {
    setParams({
      page: 1,
      limit:
        iconHandler === "notification"
          ? incrementNotification
          : incrementTechnologyIcon,
      search: filter?.search,
    });
  }, [filter?.search]);

  useEffect(() => {
    if (iconHandler === "notification") {
      renderNotificationList();
    }
    if (iconHandler === "technology") {
      renderTechnologyList();
    }
  }, [iconHandler]);

  useEffect(() => {
    if (iconHandler === "notification") renderNotificationList();
  }, [incrementNotification, filter.search]);

  useEffect(() => {
    if (iconHandler === "technology") renderTechnologyList();
  }, [incrementTechnologyIcon, filter.search]);

  useEffect(() => {
    if (modalOpen?.row?._id && singleNotification?._id)
      setNotificationDetails({
        title: { value: singleNotification?.title, error: "" },
        icon: { value: singleNotification?.icon, error: "" },
        key: { value: singleNotification?.key?._id, error: "" },
      });
    setImage(singleNotification?.icon);
  }, [singleNotification?._id]);

  const buttonShow = () => {
    if (iconHandler === "technology") {
      return hasPermission(permissionConstants.permissions.TECHNOLOGY_ICON_ADD);
    }
    if (iconHandler === "notification")
      return hasPermission(
        permissionConstants.permissions.NOTIFICATION_ICON_ADD,
      );
  };
  const navigate = useNavigate();

  // notificationIcon
  const getKeyOptions = () =>
    key
      ?.map(({ _id, name }) => ({ value: _id, label: name }))
      ?.sort((a, b) => a?.label?.localeCompare(b?.label));

  const validate = (tempData = notificationDetails) => {
    const newErrors = {};
    if (!tempData?.title?.value) {
      newErrors.title = "Title is required";
    } else if (!/^[a-zA-Z ]+$/.test(tempData?.title?.value)) {
      newErrors.title = "Title is not valid";
    }
    if (!tempData?.icon?.value && !image) {
      newErrors.icon = "Icon is required";
    }
    if (!tempData?.key?.value) {
      newErrors.key = "Key is required";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleChange = (value, key) => {
    if (key === "icon") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageUrl = event.target.result;
        setImage(imageUrl);
      };

      reader.readAsDataURL(value);
    }

    const tempData = structuredClone(notificationDetails);
    tempData[key].value = value;
    if (Object.keys(errors).length) validate(tempData);
    setNotificationDetails(tempData);
  };

  const handleClick = () => {
    const newErrors = validate();
    if (!Object.keys(newErrors).length) {
      const formData = new FormData();
      formData.append("title", notificationDetails?.title?.value);
      formData.append("key", notificationDetails?.key?.value);
      if (typeof notificationDetails?.icon?.value !== "string")
        formData.append("icon", notificationDetails?.icon?.value);

      setModalOpen(false);
      setNotificationDetails({
        title: { value: "", error: "" },
        icon: { value: "", error: "" },
        key: { value: "", error: "" },
      });
      if (isEdit) {
        UpdateNotification({ id: modalOpen?.row?._id, data: formData });
      } else AddNotification(formData);
    }
  };

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.SETUP,
        permissionConstants.submodules.WHITELABEL_ICONS,
      );
      if (Array.isArray(permission))
        return permission.some((e) =>
          modulePermissionHasPermissions(permissions, e),
        );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const renderImage = (row) => <img src={row?.row?.icon} alt="" />;

  let colNotification = [
    {
      name: "Title",
      selector: "title",
      activitySort: true,
      titleClasses: "left-txt th_firstcol",
      colClassName: "left-txt firstcol",
      contentClass: "tbl-cont",
      hasPermission: permissionConstants.permissions.NOTIFICATIONS_ICON_TITLE,
    },
    {
      name: "Key",
      selector: "keyName",
      activitySort: true,
      titleClasses: "left-txt ps-0",
      colClassName: "left-txt",
      hasPermission: permissionConstants.permissions.NOTIFICATIONS_ICON_KEY,
    },
    {
      name: "Icon",
      selector: "icon",
      activitySort: true,
      titleClasses: "text-center",
      contentClass: "managed-ico tech-icon",
      cell: renderImage,
      hasPermission: permissionConstants.permissions.NOTIFICATIONS_ICON_ICON,
    },
    {
      name: "Actions",
      activitySort: true,
      selector: "",
      cell: (row) => renderAction(row),
      hasPermission: [
        permissionConstants.permissions.NOTIFICATIONS_ICON_EDIT,
        permissionConstants.permissions.NOTIFICATIONS_ICON_DELETE,
      ],
    },
  ];

  if (userProfile?.formattedPermissions) {
    colNotification = colNotification.filter(
      (each) =>
        each.hasPermission === true || hasPermission(each.hasPermission),
    );
  }
  const renderAction = (row) => (
    <div className="tbl-cont action-controls">
      {hasPermission(
        permissionConstants.permissions.NOTIFICATIONS_ICON_EDIT,
      ) && (
        <button
          className="table-def-btn btn"
          type="button"
          onClick={() => {
            setIsEdit(true);
            setModalOpen(row);
            GetKey();
            GetNotificationIconById(row?.row?._id);
          }}
        >
          <img src={editIcon} alt="..." />
        </button>
      )}
      {hasPermission(
        permissionConstants.permissions.NOTIFICATIONS_ICON_DELETE,
      ) && (
        <button
          className="table-def-btn btn"
          type="button"
          onClick={() => {
            setShowDeleteModal({
              openclose: true,
              name: row?.row?.title,
              id: row?.row?._id,
            });
          }}
        >
          <img src={deleteIcon} alt="..." />
        </button>
      )}
    </div>
  );

  const handleDeleteNotification = (id) => {
    DeleteNotification(id);
    setShowDeleteModal({
      openclose: false,
      name: "",
      id: "",
    });
  };

  // technology
  const techRenderImage = (row) => <img src={row?.row?.icon} alt="" />;
  let colTechnology = [
    {
      name: "Department",
      selector: "department",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt",
      hasPermission:
        permissionConstants.permissions.TECHNOLOGY_ICON_DEPARTMENT_LABEL,
    },
    {
      name: "Category",
      selector: "category",
      activitySort: true,
      titleClasses: "left-txt ps-0",
      colClassName: "left-txt",
      hasPermission:
        permissionConstants.permissions.TECHNOLOGY_ICON_CATEGORY_LABEL,
    },
    {
      name: "Technology",
      selector: "technology",
      activitySort: true,
      titleClasses: "left-txt ps-0",
      colClassName: "left-txt",
      hasPermission:
        permissionConstants.permissions.TECHNOLOGY_ICON_TECHNOLOGY_LABEL,
    },
    {
      name: "Icon",
      selector: "icon",
      activitySort: true,
      titleClasses: "text-center",
      contentClass: "managed-ico tech-icon",
      cell: techRenderImage,
      hasPermission: permissionConstants.permissions.TECHNOLOGY_ICON_ICON_LABEL,
    },
    {
      name: "Actions",
      activitySort: true,
      selector: "",
      cell: (row) => techRenderAction(row),
      hasPermission: [
        permissionConstants.permissions.TECHNOLOGY_ICON_EDIT,
        permissionConstants.permissions.TECHNOLOGY_ICON_DELETE,
      ],
    },
  ];

  if (userProfile?.formattedPermissions) {
    colTechnology = colTechnology.filter(
      (each) =>
        each.hasPermission === true || hasPermission(each.hasPermission),
    );
  }

  const techRenderAction = (row) => (
    <div className="tbl-cont action-controls">
      {hasPermission(permissionConstants.permissions.TECHNOLOGY_ICON_EDIT) && (
        <button
          className="table-def-btn btn"
          type="button"
          onClick={() => {
            setIsEdit(true);
            setAddShowModal(row?.row);
          }}
        >
          <img src={editIcon} alt="..." />
        </button>
      )}
      {hasPermission(
        permissionConstants.permissions.TECHNOLOGY_ICON_DELETE,
      ) && (
        <button
          className="table-def-btn btn"
          type="button"
          onClick={() => {
            setTechId(row?.row?.technologyId);
            setShowDeleteModal({
              openclose: true,
              name: row?.row?.department,
              id: row?.row?._id,
            });
          }}
        >
          <img src={deleteIcon} alt="..." />
        </button>
      )}
    </div>
  );

  const handleDeleteTechnologyIcon = (ids) => {
    DeleteTechnologyIcon({ id: ids, technologyId: techId });
    setShowDeleteModal({
      openclose: false,
      name: "",
      id: "",
    });
  };

  return (
    <>
      <SubHeader showSearchBar />
      <div className="page-contents inner-page-contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 activity-log-area manage-ico-outer">
              <div className="card-box">
                <div className="header-otr d-flex flex-wrap">
                  <div className="title-otr d-flex flex-wrap align-items-center">
                    <div className="title">Manage Icons</div>
                  </div>
                  <div className="rgt-scnt">
                    <div className="d-flex gap-2">
                      <div className="dropdown">
                        {buttonShow() && (
                          <button
                            type="button"
                            className={`btn btn-primary d-flex align-items-center gap-1 dropdown-toggle ${
                              modalOpen ? "show" : ""
                            }`}
                            id="dropdownMenuButton1"
                            aria-expanded={modalOpen ? "true" : "false"}
                            onClick={() => {
                              if (iconHandler === "notification") {
                                GetKey();
                                setModalOpen(true);
                                setIsEdit(false);

                                setImage("");
                              } else {
                                setAddShowModal(true);
                              }
                            }}
                          >
                            {iconHandler === "notification"
                              ? "Add"
                              : "Add Tech"}
                            <img
                              src={addIcon}
                              alt="add"
                              className="icon-white"
                            />
                          </button>
                        )}
                        {iconHandler === "notification" ? (
                          <div
                            className={`dropdown-menu dropdown__box ${
                              modalOpen ? "show" : ""
                            }`}
                            aria-labelledby="dropdownMenuButton1"
                            style={{
                              position: modalOpen ? "absolute" : "static",
                              margin: modalOpen ? "0px" : "0",
                              right: modalOpen ? "0px" : "0",
                              marginTop: modalOpen ? "6px" : "6",
                            }}
                          >
                            <div className="contentarea d-flex flex-wrap">
                              <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={() => {
                                  setModalOpen(false);
                                  setNotificationDetails({
                                    title: { value: "", error: "" },
                                    icon: { value: "", error: "" },
                                    key: { value: "", error: "" },
                                  });
                                  setErrors({});
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </button>
                              <div className="row">
                                <div className="col">
                                  <div className="tilte-sm">
                                    {isEdit ? "Edit Icon" : "Add Icon"}
                                  </div>
                                </div>
                                <div className="col-lg-12 form-outer-col">
                                  <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label"
                                  >
                                    Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Title"
                                    value={notificationDetails?.title?.value}
                                    onChange={({ target: { value } }) =>
                                      handleChange(value, "title")
                                    }
                                  />
                                  <span className="error__msg">
                                    {errors?.title}
                                  </span>
                                </div>
                                <div className="col-lg-12 form-outer-col form-outer-col-01">
                                  <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label"
                                  >
                                    Key
                                  </label>
                                  <CustomSelect
                                    options={getKeyOptions()}
                                    placeholder="Enter Key"
                                    classNamePrefix="react-select"
                                    className="react-select-container form-control"
                                    value={notificationDetails?.key?.value}
                                    onChange={({ value }) => {
                                      handleChange(value, "key");
                                    }}
                                  />
                                  <span className="error__msg">
                                    {errors?.key}
                                  </span>
                                </div>
                                <div className="col-lg-12 form-outer-col mb-2 add-whitelabels-modal">
                                  <div className="upload-btn-wrapper">
                                    <label className="file-label" htmlFor="">
                                      Upload File{" "}
                                      <span>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip
                                              id="id"
                                              style={{
                                                textAlign: "left",
                                                whiteSpace: "nowrap",
                                                left: 0,
                                              }}
                                            >
                                              <table className="text-start">
                                                <tr>
                                                  <td>
                                                    <span className="fw-semibold">
                                                      Max size:
                                                    </span>
                                                  </td>
                                                  <td>500kb</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <span className="fw-semibold">
                                                      Dimension:
                                                    </span>
                                                  </td>
                                                  <td>30x30px</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <span className="fw-semibold">
                                                      Valid type:
                                                    </span>
                                                  </td>
                                                  <td>JPG, PNG</td>
                                                </tr>
                                              </table>
                                            </Tooltip>
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            id="info"
                                            width="10.24"
                                            height="10.24"
                                            viewBox="0 0 10.24 10.24"
                                          >
                                            <g
                                              id="Group_59384"
                                              data-name="Group 59384"
                                            >
                                              <g
                                                id="Group_59383"
                                                data-name="Group 59383"
                                              >
                                                <path
                                                  id="Path_10390"
                                                  data-name="Path 10390"
                                                  d="M5.12,0a5.12,5.12,0,1,0,5.12,5.12A5.117,5.117,0,0,0,5.12,0Zm0,9.526A4.406,4.406,0,1,1,9.526,5.12,4.411,4.411,0,0,1,5.12,9.526Z"
                                                  fill="currentcolor"
                                                />
                                              </g>
                                            </g>
                                            <g
                                              id="Group_59386"
                                              data-name="Group 59386"
                                              transform="translate(4.601 4.268)"
                                            >
                                              <g
                                                id="Group_59385"
                                                data-name="Group 59385"
                                              >
                                                <path
                                                  id="Path_10391"
                                                  data-name="Path 10391"
                                                  d="M230.578,213.4c-.3,0-.519.128-.519.317v2.568c0,.162.216.323.519.323s.526-.162.526-.323v-2.568C231.1,213.525,230.868,213.4,230.578,213.4Z"
                                                  transform="translate(-230.059 -213.397)"
                                                  fill="currentcolor"
                                                />
                                              </g>
                                            </g>
                                            <g
                                              id="Group_59388"
                                              data-name="Group 59388"
                                              transform="translate(4.568 2.684)"
                                            >
                                              <g
                                                id="Group_59387"
                                                data-name="Group 59387"
                                              >
                                                <path
                                                  id="Path_10392"
                                                  data-name="Path 10392"
                                                  d="M228.928,134.208a.486.486,0,1,0,.546.479A.522.522,0,0,0,228.928,134.208Z"
                                                  transform="translate(-228.375 -134.208)"
                                                  fill="currentcolor"
                                                />
                                              </g>
                                            </g>
                                          </svg>
                                        </OverlayTrigger>
                                      </span>{" "}
                                    </label>
                                    <div
                                      className={
                                        image
                                          ? "hiddenUpload uploaded"
                                          : "hiddenUpload"
                                      }
                                    >
                                      <input
                                        accept=".jpg, .jpeg, .png"
                                        type="file"
                                        onChange={({ target: { files } }) => {
                                          const selectedFile = files[0];
                                          if (selectedFile) {
                                            const allowedExtensions = [
                                              ".jpg",
                                              ".jpeg",
                                              ".png",
                                            ];
                                            const fileExtension =
                                              selectedFile.name
                                                .toLowerCase()
                                                .slice(-4);

                                            if (
                                              allowedExtensions.includes(
                                                fileExtension,
                                              )
                                            ) {
                                              if (
                                                selectedFile.size >
                                                500 * 1024
                                              ) {
                                                setErrors({
                                                  icon: "File size should be less than 500kb.",
                                                });
                                              } else {
                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  const image = new Image();
                                                  image.src = e.target.result;

                                                  image.onload = () => {
                                                    if (
                                                      image.width === 30 &&
                                                      image.height === 30
                                                    ) {
                                                      handleChange(
                                                        selectedFile,
                                                        "icon",
                                                      );
                                                      setErrors({});
                                                    } else {
                                                      setErrors({
                                                        icon: "The image needs to be 30x30 pixels in size.",
                                                      });
                                                    }
                                                  };
                                                };

                                                reader.readAsDataURL(
                                                  selectedFile,
                                                );
                                              }
                                            } else {
                                              setErrors({
                                                icon: "Please select a JPG, JPEG, or PNG file.",
                                              });
                                            }
                                          }
                                        }}
                                      />

                                      <span className="error__msg">
                                        {errors?.icon}
                                      </span>
                                      <div className="uploaded-itm">
                                        <div className="uploaded-itm-inner">
                                          <img src={image} alt="icon" />
                                        </div>
                                        <button
                                          type="button"
                                          className="btn close"
                                          onClick={() => {
                                            setImage("");
                                            setNotificationDetails({
                                              ...notificationDetails,
                                              icon: {
                                                value: "",
                                              },
                                            });
                                          }}
                                        >
                                          <img src={closeIcon} alt="close" />
                                        </button>
                                      </div>
                                      <div className="upload-label-task">
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.662"
                                            height="11.51"
                                            viewBox="0 0 14.662 11.51"
                                          >
                                            <g
                                              id="cloud-computing"
                                              transform="translate(0 -55.031)"
                                            >
                                              <g
                                                id="Group_1262"
                                                transform="translate(0 55.032)"
                                              >
                                                <g
                                                  id="Group_1261"
                                                  transform="translate(0 0)"
                                                >
                                                  <path
                                                    id="Path_4026"
                                                    d="M12.216,58.846a5.04,5.04,0,0,0-9.914.861,2.749,2.749,0,0,0,.458,5.461H5.051v-.916H2.76a1.833,1.833,0,0,1,0-3.665.458.458,0,0,0,.458-.458,4.123,4.123,0,0,1,8.169-.8.458.458,0,0,0,.389.367,2.291,2.291,0,0,1-.312,4.559H9.632v.916h1.833a3.207,3.207,0,0,0,.751-6.322Z"
                                                    transform="translate(0 -55.032)"
                                                    fill="currentcolor"
                                                  />
                                                  <path
                                                    id="Path_4027"
                                                    d="M182.868,249.144l-1.833,1.833.646.646,1.054-1.049v4.394h.916v-4.394l1.049,1.049.646-.646-1.833-1.833A.458.458,0,0,0,182.868,249.144Z"
                                                    transform="translate(-175.851 -243.457)"
                                                    fill="currentcolor"
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                                        </span>{" "}
                                        Upload File
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="ftr d-flex">
                                    <button
                                      type="button"
                                      className="btn btn-primary d-flex align-items-center gap-1 ms-auto  ps-4 pe-4"
                                      onClick={handleClick}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-header-lg cstm-spcg">
                  <ul>
                    <li>
                      <button
                        type="button"
                        className={`tb-btn ${
                          iconHandler === "notification" ? "active" : ""
                        }`}
                        onClick={() => {
                          navigate("/icons/notification");
                          setModalOpen(false);
                        }}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <g
                              id="vuesax_linear_notification"
                              data-name="vuesax/linear/notification"
                              transform="translate(-171 -188)"
                            >
                              <g
                                id="notification"
                                transform="translate(171 188)"
                              >
                                <path
                                  id="Vector"
                                  d="M5.364,0a4,4,0,0,0-4,4V5.927A3.186,3.186,0,0,1,.984,7.3L.217,8.573a1.279,1.279,0,0,0,.72,1.953,13.947,13.947,0,0,0,8.847,0,1.335,1.335,0,0,0,.72-1.953L9.737,7.3a3.274,3.274,0,0,1-.373-1.373V4A4.012,4.012,0,0,0,5.364,0Z"
                                  transform="translate(2.649 1.94)"
                                  fill="none"
                                  stroke="currentcolor"
                                  strokeLinecap="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Vector-2"
                                  data-name="Vector"
                                  d="M2.467.84a4.036,4.036,0,0,0-.64-.133A4.5,4.5,0,0,0,0,.84a1.325,1.325,0,0,1,2.467,0Z"
                                  transform="translate(6.78 1.293)"
                                  fill="none"
                                  stroke="currentcolor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Vector-3"
                                  data-name="Vector"
                                  d="M4,0A2.006,2.006,0,0,1,2,2,2.007,2.007,0,0,1,.587,1.413,2.007,2.007,0,0,1,0,0"
                                  transform="translate(6.013 12.707)"
                                  fill="none"
                                  stroke="currentcolor"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Vector-4"
                                  data-name="Vector"
                                  d="M0,0H16V16H0Z"
                                  fill="none"
                                  opacity="0"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        Notifications
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        className={`tb-btn ${
                          iconHandler === "technology" ? "active" : ""
                        }`}
                        onClick={() => {
                          navigate("/icons/technology");
                          // setFilter({ search: "" });
                          setParams("");
                          GetTechnologyIcon({
                            page: 1,
                            limit: incrementTechnologyIcon,
                            search: "",
                          });
                        }}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.236"
                            height="13.391"
                            viewBox="0 0 15.236 13.391"
                          >
                            <g id="nanotechnology" transform="translate(0 -31)">
                              <path
                                id="Path_11241"
                                data-name="Path 11241"
                                d="M2.262,35.464a2.229,2.229,0,0,0,1.966-1.175,1.267,1.267,0,0,1,1.123-.61h.964a1.266,1.266,0,0,1,1.122.61,2.232,2.232,0,1,0,0-2.113,1.267,1.267,0,0,1-1.123.61H5.351a1.266,1.266,0,0,1-1.122-.61A2.23,2.23,0,0,0,2.262,31a2.232,2.232,0,1,0,0,4.464Zm6.7-2.678H9.85v.893H8.957Z"
                                fill="currentcolor"
                              />
                              <path
                                id="Path_11242"
                                data-name="Path 11242"
                                d="M2.262,335.464a2.229,2.229,0,0,0,1.966-1.175,1.267,1.267,0,0,1,1.123-.61h.964a1.266,1.266,0,0,1,1.122.61,2.232,2.232,0,1,0,0-2.113,1.267,1.267,0,0,1-1.123.61H5.351a1.266,1.266,0,0,1-1.122-.61A2.23,2.23,0,0,0,2.262,331a2.232,2.232,0,1,0,0,4.464Zm6.7-2.678H9.85v.893H8.957Z"
                                transform="translate(0 -291.073)"
                                fill="currentcolor"
                              />
                              <path
                                id="Path_11243"
                                data-name="Path 11243"
                                d="M128.408,182.175a1.267,1.267,0,0,1-1.123.61h-.964a1.266,1.266,0,0,1-1.122-.61,2.232,2.232,0,1,0,0,2.113,1.267,1.267,0,0,1,1.123-.61h.964a1.266,1.266,0,0,1,1.122.61,2.23,2.23,0,0,0,1.966,1.176,2.232,2.232,0,1,0,0-4.464A2.229,2.229,0,0,0,128.408,182.175Zm-4.73,1.5h-.893v-.893h.893Z"
                                transform="translate(-117.399 -145.536)"
                                fill="currentcolor"
                              />
                            </g>
                          </svg>
                        </span>
                        Technology
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="content-tables">
                  {iconHandler === "notification" ? (
                    <BottomScrollListener
                      onBottom={() =>
                        incrementNotification < notificationIconList?.total &&
                        setIncrementNotification(incrementNotification + 5)
                      }
                    >
                      {hasPermission(
                        permissionConstants.permissions.NOTIFICATIONS_ICON_LIST,
                      ) && (
                        <CustomTable
                          tableClassName="td-center"
                          divClassName="table-helight"
                          columns={colNotification}
                          data={notificationIconList?.data}
                          pagination={{
                            paginationData,
                          }}
                          count={5}
                          isLoading={
                            isLoadingGetNotification ||
                            isLoadingAddNotification ||
                            isLoadingUpdateNotification ||
                            isLoadingDeleteNotification
                          }
                          onPageChange={(currentPage) =>
                            setPaginationData({
                              ...paginationData,
                              currentPage,
                            })
                          }
                        />
                      )}
                    </BottomScrollListener>
                  ) : (
                    <BottomScrollListener
                      onBottom={() =>
                        incrementTechnologyIcon <
                          getTechnologyIconList?.total &&
                        setIncrementTechnologyIcon(incrementTechnologyIcon + 5)
                      }
                    >
                      {hasPermission(
                        permissionConstants.permissions.TECHNOLOGY_ICON_LIST,
                      ) && (
                        <CustomTable
                          tableClassName="td-center"
                          divClassName="table-helight"
                          columns={colTechnology}
                          data={getTechnologyIconList?.updatedResult}
                          pagination={{
                            paginationData,
                          }}
                          count={5}
                          isLoading={
                            isLoadingGetTechnology || isLoadingDeleteTechnology
                          }
                          onPageChange={(currentPage) =>
                            setPaginationData({
                              ...paginationData,
                              currentPage,
                            })
                          }
                        />
                      )}
                    </BottomScrollListener>
                  )}
                  {iconHandler === "notification" ? (
                    <Deletemodal
                      ModalShow={showDeleteModal}
                      deleteFunc={handleDeleteNotification}
                      closeModal={() => {
                        setShowDeleteModal({
                          openclose: false,
                          name: "",
                          id: "",
                        });
                      }}
                      cancelModal={() => {
                        setShowDeleteModal({
                          openclose: false,
                          name: "",
                          id: "",
                        });
                      }}
                    />
                  ) : (
                    <Deletemodal
                      ModalShow={showDeleteModal}
                      deleteFunc={handleDeleteTechnologyIcon}
                      closeModal={() => {
                        setShowDeleteModal({
                          openclose: false,
                          name: "",
                          id: "",
                        });
                      }}
                      cancelModal={() => {
                        setShowDeleteModal({
                          openclose: false,
                          name: "",
                          id: "",
                        });
                      }}
                    />
                  )}
                  {!!addshowModal && (
                    <AddModalTechnology
                      showModal={addshowModal}
                      closeModal={() => setAddShowModal(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTitle(Icons);
