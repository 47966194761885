import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import permissionsService from "../../../../services/permissionsService";
import { permissionActions } from "..";
import { alertActions } from "../../alert/index";

export const getPermissionList = createAsyncThunk(
  "list-permissions",
  async (params, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const { data } = await permissionsService.getPermissionsList(params);

      dispatch(
        permissionActions.setPermissionList({
          data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
