import { createSlice } from "@reduxjs/toolkit";

export const name = "filterDropdownData";

const createReducers = () => {
  const setFilterDropdown = (state, { payload }) => {
    state.filterDropdown = payload;
  };
  const setFilterById = (state, { payload }) => {
    state.filterIdData = payload;
  };
  return {
    setFilterDropdown,
    setFilterById,
  };
};

function createInitialState() {
  return {
    filterDropdown: [],
    filterIdData: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const filterDropdownAction = slice.actions;
export const filterDropdownReducer = slice.reducer;
