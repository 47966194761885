import PropTypes from "prop-types";

export const FlagIcon = ({ fillColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.099"
      height="14.53"
      viewBox="0 0 12.099 14.53"
    >
      <path
        id="Icon_ionic-md-flag"
        data-name="Icon ionic-md-flag"
        d="M18.093,5.226a14.884,14.884,0,0,1-1.875.159,15.1,15.1,0,0,1-3.082-.461A15.728,15.728,0,0,0,9.956,4.5a6.031,6.031,0,0,0-3.048.507l-.159.11v13.9h.756l.535.01V12.292c.367-.045,1.351-.086,1.918-.086a15.3,15.3,0,0,1,3.017.586,16.217,16.217,0,0,0,3.286.435,15.006,15.006,0,0,0,1.83-.151c.284-.034.529-.064.756-.1V5.113C18.66,5.15,18.377,5.192,18.093,5.226Z"
        transform="translate(-6.75 -4.5)"
        fill={fillColor}
      />
    </svg>
  );
};

FlagIcon.propTypes = {
  fillColor: PropTypes.string,
};
