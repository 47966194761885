/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setPriority } from "../index";
import ticketService from "../../../../services/ticketService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getPriority = createAsyncThunk(
  `get/priority`,
  async (payload, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await ticketService.getTicketPriority();
      dispatch(
        setPriority({
          data,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(ticketActions.setTicketErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
