import { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { CloseIco } from "./svgIcons";
import { patchTeam } from "../../../../store/slices/team/thunks/patchTeam";
import Button from "../../../../components/Button/index";
import validator from "./validator";
import { useThunk } from "../../../../hooks/useThunk";
import { getDepartment } from "../../../../store/slices/team/thunks/getDepartment";
import { getAllocationRole } from "../../../../store/slices/team/thunks/getAllocationRole";
import { getMembers } from "../../../../store/slices/team/thunks/getMember";
import CustomSelect from "./customSelect";

function Add({ setShowForm }) {
  const { shortCode } = useParams();
  const [dept, isLoadingDept] = useThunk(getDepartment);
  const [role, isLoadingRole] = useThunk(getAllocationRole);
  const [member, isLoadingMember] = useThunk(getMembers);
  const [editTeam] = useThunk(patchTeam);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);

  const [projectId, setProjectId] = useState(null);
  const { deptData, roleData, memberData } = useSelector((state) => state.team);

  const { projectData } = useSelector(({ project }) => project);
  const params = { page: 1, limit: 10 };

  const [selectedRoleValue, setSelectedRoleValue] = useState("");
  const [selectedDeptValue, setselectedDeptValue] = useState("");

  useEffect(() => {
    if (projectData)
      setProjectId(
        projectData?.find((each) => each.shortCode === shortCode)?._id,
      );
  }, [projectData]);
  useEffect(() => {
    dept({ params });
  }, []);
  useEffect(() => {
    if (selectedDeptValue) {
      role({ deptId: selectedDeptValue });
    }
  }, [selectedDeptValue]);

  useEffect(() => {
    if (selectedRoleValue) {
      member({ roleId: selectedRoleValue, projectId });
    }
  }, [selectedRoleValue]);

  useEffect(() => {
    if (deptData) {
      const mappedOptions = deptData?.result?.map((dept) => ({
        value: dept._id,
        label: dept.name,
      }));
      setDepartmentOptions(mappedOptions);
    }
  }, [deptData]);
  useEffect(() => {
    if (memberData) {
      const mappedOptions = memberData?.map((dataItem) => ({
        value: dataItem._id,
        label: dataItem.user,
        photo: dataItem.userPhoto,
        ticketCount: dataItem.ticketCount,
        totalAllocationHours: dataItem.totalAllocationHours,
      }));
      setMemberOptions(mappedOptions);
    }
  }, [memberData]);

  useEffect(() => {
    if (roleData) {
      const mappedOptions = roleData?.map((role) => ({
        value: role._id,
        label: role.name,
      }));
      setRoleOptions(mappedOptions);
    }
  }, [roleData]);

  const initialValues = {
    department: "",
    role: "",
    members: "",
    allocation: "",
  };

  // const resetFields = (form, fields) => {
  //   console.log("form", form);
  //   fields.forEach((field) => {
  //     form.resetFieldState(field);
  //   });
  // };
  const resetFields = (form, fields) => {
    fields.forEach((field) => {
      if (Array.isArray(field)) {
        field.forEach((nestedField) => {
          if (nestedField === "members") {
            form.resetFieldState(nestedField, []);
          } else {
            form.resetFieldState(nestedField, undefined);
          }
        });
      } else {
        form.resetFieldState(field, undefined);
      }
    });
  };

  const onSubmit = (formValues, form) => {
    const selectedDepartmentValue = formValues.department.value || "";
    const selectedRoleValue = formValues.role.value || "";
    const allocation = formValues.allocation || "";

    const patchData = formValues.members.map((member) => ({
      department: selectedDepartmentValue,
      allocationRole: selectedRoleValue,
      user: member.value,
      allocationHours: allocation,
    }));

    editTeam({ id: projectId, patchData });
    setShowForm(false);
    form.reset(initialValues); // Reset the form
    resetFields(form, ["department", "role", ["members"], "allocation"]);
  };
  const clearErrors = (form) => {
    const registeredFields = form.getRegisteredFields();
    registeredFields.forEach((field) => {
      form.resetFieldState(field);
    });
  };
  return (
    <Dropdown.Menu bsPrefix="dropdown-menu border-0 dropdown-menu-lg dropdown-menu-end">
      <Form
        onSubmit={(formValues, form) => {
          onSubmit(formValues, form);
          form.reset(initialValues);
          resetFields(form, ["department", "role", ["members"], "allocation"]);
        }}
        validate={validator}
        initialValues={initialValues}
        mutators={{
          clearErrors: (args, state, utils) => {
            const { change, getRegisteredFields } = utils;
            getRegisteredFields().forEach((field) => {
              change(field, undefined);
            });
          },
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="dropdown-form-cover">
              <h2>Add Team</h2>
              <Dropdown.Item bsPrefix="dropdown-close-btn">
                {CloseIco(() => {
                  form.reset(initialValues);
                  resetFields(form, [
                    "department",
                    "role",
                    ["members"],
                    "allocation",
                  ]);
                  clearErrors(form); // Clear errors when CloseIco is clicked
                })}
              </Dropdown.Item>

              <div className="dropdown-form-block">
                <div className="row inp-row">
                  <div className="col-sm-4">
                    <label className="inp-label" htmlFor="">
                      Department
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <Field name="department">
                      {({ input }) => (
                        <Select
                          {...input}
                          options={departmentOptions}
                          placeholder="Select Department"
                          classNamePrefix="react-select"
                          className={`react-select-container ${
                            selectedDeptValue ? "val-selected" : ""
                          }`}
                          onChange={(value) => {
                            input.onChange(value);
                            setselectedDeptValue(value ? value.value : "");
                          }}
                          isLoading={isLoadingDept}
                        />
                      )}
                    </Field>
                    <Field name="department">
                      {({ meta }) =>
                        meta.error &&
                        meta.touched && (
                          <span className="text-danger">{meta.error}</span>
                        )
                      }
                    </Field>
                  </div>
                </div>
                <div className="row inp-row">
                  <div className="col-sm-4">
                    <label className="inp-label" htmlFor="">
                      Project Role
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <Field name="role">
                      {({ input }) => (
                        <Select
                          {...input}
                          classNamePrefix="react-select"
                          className={`react-select-container ${
                            selectedRoleValue ? "val-selected" : ""
                          }`}
                          options={roleOptions}
                          placeholder="Select Project Role"
                          onChange={(value) => {
                            input.onChange(value);
                            setSelectedRoleValue(value ? value.value : "");
                          }}
                          isLoading={isLoadingRole}
                        />
                      )}
                    </Field>
                    <Field name="role">
                      {({ meta }) =>
                        meta.error &&
                        meta.touched && (
                          <span className="text-danger">{meta.error}</span>
                        )
                      }
                    </Field>
                  </div>
                </div>
                <div className="row inp-row sel_members">
                  <div className="col-sm-4">
                    <label className="inp-label" htmlFor="">
                      Select Members
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <Field name="members">
                      {({ input }) => (
                        <CustomSelect
                          options={memberOptions}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                          isLoading={isLoadingMember}
                          getOptionLabel={(option) =>
                            `${option.label} (${option.ticketCount})${option.photo}`
                          } // Include ticketCount in the label
                          input={input}
                        />
                      )}
                    </Field>
                    <Field name="members">
                      {({ meta }) =>
                        meta.error &&
                        meta.touched && (
                          <span className="text-danger">{meta.error}</span>
                        )
                      }
                    </Field>
                  </div>
                </div>
                <div className="row inp-row">
                  <div className="col-sm-4">
                    <label className="inp-label" htmlFor="">
                      Allocation Hrs/day
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <Field name="allocation">
                      {({ input }) => (
                        <div>
                          <input
                            {...input}
                            type="text"
                            className={`inp-item ${
                              input.value ? "val-selected" : ""
                            }`}
                            placeholder=""
                            onChange={(e) => {
                              input.onChange(e);
                            }}
                          />
                        </div>
                      )}
                    </Field>
                    {/* for single error  */}
                    <Field name="allocation">
                      {({ meta }) =>
                        meta.error &&
                        meta.touched && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                id="tooltip-allocation"
                                hidden={!meta.error || !meta.touched}
                              >
                                <div className="errorTooltxt">
                                  {meta.error && meta.touched ? meta.error : ""}
                                </div>
                              </Tooltip>
                            }
                          >
                            <span className="text-danger">{meta.error}</span>
                          </OverlayTrigger>
                        )
                      }
                    </Field>
                  </div>
                  <Field name="allocation_tooltip">
                    {({ meta }) =>
                      meta.error &&
                      meta.touched && (
                        <div className="long-error custom-scroll">
                          {meta.error.map((e, index) => (
                            <div key={index}>{e}</div>
                          ))}
                        </div>
                      )
                    }
                  </Field>
                </div>

                <div className="row">
                  <div className="col d-flex">
                    <Button
                      primary
                      type="submit"
                      className="btn btn-save ms-auto"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </Dropdown.Menu>
  );
}

Add.propTypes = {
  setShowForm: PropTypes.func,
  CloseButton: PropTypes.func,
};

export default Add;
