import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { useParams } from "react-router-dom";
import { useThunk } from "../../../hooks/useThunk";
import Button from "../../../components/Button/index";
import CustomModal from "../../../components/CustomModal";
import { postUserDocuments } from "../../../store/slices/documents/thunks/postdocuments";
import { clearDocument } from "../../../store/slices/documents/thunks/clear";
import UploadImg from "../../../assets/images/Upload File_Light.svg";
import { convertBytesToKBMB } from "../utility";
import UploadLightImg from "../../../assets/images/upload_attach_Light.svg";
import RemoveImage from "../../../assets/images/remove-red.svg";
import PdfImage from "../../../assets/images/pdf.svg";
import SelectGreen from "../../../assets/images/select-green.svg";
import xlsImage from "../../../assets/images/xls.svg";
import DocImage from "../../../assets/images/doc-1.svg";
import PptImage from "../../../assets/images/ppt.svg";
import imageLogo from "../../../assets/images/image.svg";
import textLogo from "../../../assets/images/text.svg";
import "../../../design/custom/pages/table-sort.scss";
import "../../../design/custom/pages/documentsTable.scss";

function AddModal({ showModal, closeModal, cancelModal }) {
  const [hoveredFiles, setHoveredFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [validFiles, setValidFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [newValidFiles, setNewValidFiles] = useState([]);
  const [fileInputTouched, setFileInputTouched] = useState(false);
  const [postDocuments, isLoading] = useThunk(postUserDocuments);
  const [clear] = useThunk(clearDocument);
  const allowedExtensions = [
    "xlsx",
    "xls",
    "pdf",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "png",
    "jpg",
    "jpeg",
    "gif",
    "csv",
    "txt",
  ];
  const fileTypeIcons = {
    pdf: PdfImage,
    doc: DocImage,
    docx: DocImage,
    xls: xlsImage,
    xlsx: xlsImage,
    ppt: PptImage,
    pptx: PptImage,
    png: imageLogo,
    jpg: imageLogo,
    jpeg: imageLogo,
    gif: imageLogo,
    csv: xlsImage,
    txt: textLogo,
  };

  const { successMessage } = useSelector((state) => state.document);

  useEffect(() => {
    if (successMessage) {
      closeModal();
      clear();
    }
  }, [successMessage]);

  const hasValidExtensions = (files) => {
    return files.some((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    });
  };
  const { shortCode } = useParams();
  const handleMouseEnter = (fileid) => {
    if (!hoveredFiles.includes(fileid)) {
      setHoveredFiles([...hoveredFiles, fileid]);
    }
  };
  const handleMouseLeave = (fileid) => {
    setHoveredFiles(hoveredFiles.filter((id) => id !== fileid));
  };

  const handleFileChange = (e) => {
    setFileInputTouched(true);
    const selectedFiles = Array.from(e.target.files);

    const newFiles = selectedFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    });

    const duplicateFiles = newFiles.filter((newFile) => {
      return files.some((existingFile) => existingFile.name === newFile.name);
    });

    if (duplicateFiles.length > 0) {
      setErrors({
        ...errors,
        file: "File already selected.",
      });
    } else if (!hasValidExtensions(selectedFiles)) {
      setErrors({
        ...errors,
        file: "Please select a valid file (Excel, PDF, Word, PowerPoint, Image).",
      });
    } else if (validFiles.length + newFiles.length > 5) {
      setErrors({
        ...errors,
        file: "File size exceeded",
      });
    } else {
      setValidFiles((prevValidFiles) => [...prevValidFiles, ...newFiles]);
      setFiles([...files, ...selectedFiles]);
      setErrors({ ...errors, file: "" });

      // Initialize progress for each new valid file
      const newUploadProgress = { ...uploadProgress };
      newFiles.forEach((file) => {
        newUploadProgress[file.name] = 0;
      });
      setUploadProgress(newUploadProgress);
      setNewValidFiles(newFiles);
      newFiles.forEach((file, index) => {
        setTimeout(() => {
          simulateProgress(file.name);
        }, index * 4000);
      });
    }
  };

  const simulateProgress = (fileName) => {
    const interval = 300; // Interval for updating the progress
    const totalSteps = 10; // Number of steps
    const step = 100 / totalSteps; // Progress step size

    let currentStep = 0;

    const progressInterval = setInterval(() => {
      currentStep += 1; // Increment currentStep by 1
      const updatedProgress = { ...uploadProgress };
      updatedProgress[fileName] = currentStep * step;
      setUploadProgress(updatedProgress);

      if (currentStep === totalSteps) {
        clearInterval(progressInterval); // Clear the interval when progress reaches 100
      }
    }, interval);
  };

  const handleRemoveFile = (fileName) => {
    const updatedValidFiles = validFiles.filter(
      (file) => file.name !== fileName,
    );
    setValidFiles(updatedValidFiles);
    setErrors({});
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);

    // Remove the progress entry for the removed file
    const updatedProgress = { ...uploadProgress };
    delete updatedProgress[fileName];
    setUploadProgress(updatedProgress);
    setNewValidFiles(newValidFiles.filter((file) => file.name !== fileName));
  };

  const validateForm = (values) => {
    const errors = {};

    const newFiles = newValidFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    });

    if (validFiles.length - 1 + newFiles.length > 5) {
      setErrors({
        ...errors,
        file: "File size exceeded",
      });
    }

    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required.";
    }

    if (!values.description || values.description.trim() === "") {
      errors.description = "Description is required.";
    }

    if (!fileInputTouched && newFiles.length === 0) {
      errors.file = "Please select at least one file.";
    }

    return errors;
  };

  const handleSubmit = async (values) => {
    const validationErrors = validateForm(values);
    const data = {
      title: values.title,
      description: values.description,
      files,
      tags: [],
    };
    if (Object.keys(validationErrors).length === 0) {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("location", shortCode ?? "Global");
      data.files.forEach((file) => {
        formData.append("files", file);
      });
      postDocuments(formData);
      setFiles([]);
    } else {
      closeModal();
      setErrors(validationErrors);
    }
  };

  const renderHeader = () => {
    return (
      <>
        <h5 className="modal-title fs-6" id="exampleModalLabel">
          <span className="icon-title">
            <img src={UploadImg} alt="" />
          </span>{" "}
          Upload File
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          disabled={isLoading}
          onClick={cancelModal}
        />
      </>
    );
  };
  const renderImageSrc = (file) => {
    const isFileUploaded = uploadProgress[file.name] >= 100;
    const isFileHovered = hoveredFiles.includes(file.name);

    if (isFileUploaded) {
      return isFileHovered ? RemoveImage : SelectGreen;
    }
    return RemoveImage;
  };

  const renderBody = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        validate={validateForm}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="uploadFileWrap">
                <div className="mb-4 position-relative">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <Field
                    name="title"
                    render={({ input, meta }) => (
                      <>
                        <input
                          {...input}
                          type="text"
                          id="title"
                          className="form-control theme"
                          placeholder="Title"
                        />
                        {meta.error && meta.touched && (
                          <span className="error position-absolute top-100 text-danger">
                            <small>{meta.error}</small>
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="mb-4 position-relative">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <Field
                    name="description"
                    render={({ input, meta }) => (
                      <>
                        <textarea
                          {...input}
                          type="text"
                          id="description"
                          className="form-control theme custom-scroll"
                          placeholder="Write something..."
                        />
                        {meta.error && meta.touched && (
                          <span className="error position-absolute top-100 text-danger">
                            <small>{meta.error}</small>
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="mb-4 position-relative">
                  <div className="position-relative">
                    <label
                      htmlFor="fileUp"
                      className="form-label d-flex align-items-center"
                    >
                      Upload File
                      <span className="info d-inline-flex">
                        <span className="ico">
                          <img src="img/info.svg" alt="" />
                        </span>
                        Supported File Formats Doc, Excel, PPT, PDF, PNG, JPG,
                        TXT, ...
                      </span>
                    </label>

                    <div className="hiddenUpload">
                      <Field
                        name="file"
                        render={({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="file"
                              className="hiddenUploadInput"
                              onChange={handleFileChange}
                              multiple
                            />
                            {meta.error && meta.touched && (
                              <span className="error position-absolute top-100 text-danger w-100">
                                <small>{meta.error}</small>
                              </span>
                            )}
                          </>
                        )}
                      />

                      <div className="upload-label">
                        <span>
                          <img src={UploadLightImg} alt="" />
                        </span>
                        &nbsp; Upload File
                      </div>
                    </div>
                    {errors.file && (
                      <span className="error position-absolute top-100 text-danger w-100">
                        <small>{errors.file}</small>
                      </span>
                    )}
                  </div>
                  {files.length > 0 && (
                    <ul className="uploaded-files pt-1">
                      {files.map((file) => (
                        <li key={file.id}>
                          <div
                            className="upload-wrp"
                            onMouseEnter={() => handleMouseEnter(file.name)}
                            onMouseLeave={() => handleMouseLeave(file.name)}
                          >
                            <span className="ico">
                              <img
                                src={
                                  fileTypeIcons[
                                    file.name.split(".").pop().toLowerCase()
                                  ] || PdfImage
                                }
                                alt=""
                              />
                            </span>
                            {uploadProgress[file.name] < 100 && (
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  aria-valuenow={uploadProgress[file.name] || 0}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{
                                    width: `${uploadProgress[file.name] || 0}%`,
                                  }}
                                />
                              </div>
                            )}

                            <button
                              type="button"
                              className="remove-file"
                              onClick={() => {
                                handleRemoveFile(file.name);
                                handleMouseLeave(file.name);
                              }}
                            >
                              <img
                                src={renderImageSrc(file)}
                                alt={
                                  uploadProgress[file.name] >= 100
                                    ? "Remove"
                                    : "Select Green"
                                }
                              />
                            </button>
                          </div>
                          <span className="fileName">{file.name}</span>
                          <span>{convertBytesToKBMB(file.size)}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="mb-5 d-flex">
                <Button
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-primary ms-auto btn-save ${
                    isLoading && "isLoading disable"
                  }`}
                >
                  {isLoading && (
                    <span className="btn-loader">
                      <span className="spinner-border spinner-border-sm" />
                    </span>
                  )}
                  Save
                </Button>
              </div>

              <div className="mb-3" />
            </div>
          </form>
        )}
      />
    );
  };

  return (
    <CustomModal
      className="modal fade upload-modal"
      dialgName="modal-dialog-centered modal-lg"
      header={renderHeader()}
      body={renderBody()}
      footer={null}
      bodyClassname="modal-body"
      createModal
      disableCenter
      show={!!showModal}
    />
  );
}

AddModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.bool,
};

AddModal.propTypes = {
  cancelModal: PropTypes.func.isRequired,
};

export default AddModal;
