import { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import CustomTable from "../../../components/CustomTable";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import { getCronjobActivityLog } from "../../../store/slices/cronjob/thunk/getCronjobActivityLog";
import { useThunk } from "../../../hooks/useThunk";
import { useFilter } from "../../../context/useFilter";
import { getNameAndUniqId } from "../../../components/common/iconDefaultName";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import { filterQuery } from "./CronFilters/ActivityLogFilter";

const CronActivityLog = ({ ActivityId }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrders, setSortOrders] = useState({
    date: "",
  });
  const [params, setParams] = useState(null);

  const [getActivityLogById, activityLoader] = useThunk(getCronjobActivityLog);
  const [filterData] = useThunk(getFilterDropdown);
  const { filter } = useFilter();

  const { activityLog, successMessage } = useSelector((state) => state.cronjob);

  const matches = filterQuery(filter.filter);

  const stateUpdate = useCallback(
    matches && matches[Object.keys(matches)[0]].map(({ value }) => value),
    [filter.filter],
  );

  useEffect(() => {
    if (params) getActivityLogById(params);
  }, [params]);

  useEffect(() => {
    setParams({ ...params, matches });
  }, [stateUpdate]);

  useEffect(() => {
    if (ActivityId) {
      filterData("cronActivity");
      renderActivityList();
    }
  }, [ActivityId, sortBy, sortOrders, successMessage, filter?.search]);

  // useEffect(() => {
  //   let updatedParams = { ...params };
  //   if (filter.me) updatedParams = { ...updatedParams, me: filter.me };
  //   if (!filter.me) updatedParams = { ...updatedParams, me: "" };
  //   if (!filter.search) updatedParams = { ...updatedParams, title: "" };
  //   if (filter.assignee)
  //     updatedParams = { ...updatedParams, assignee: filter.assignee };
  //   if (filter.groupBy)
  //     updatedParams = { ...updatedParams, groupBy: filter.groupBy };
  //   setParams(updatedParams);
  // }, [filter.me, filter.assignee, filter.groupBy]);

  const renderActivityList = () => {
    setParams({
      id: ActivityId,
      page: 1,
      limit: 100,
      sortOrder: sortOrders[sortBy] ? sortOrders[sortBy] : null,
      sortBy: sortOrders[sortBy] ? sortBy : null,
      search: filter?.search,
    });
  };

  const setSortingOrder = (selector) => {
    const newSortOrders = { ...sortOrders };

    if (newSortOrders[selector]) {
      newSortOrders[selector] = newSortOrders[selector] === "asc" ? "desc" : "";
    } else {
      newSortOrders[selector] = "asc";
    }

    setSortOrders(newSortOrders);
    setSortBy(selector);
  };

  // Listing value update functions

  const renderUpdatedBy = (row) => {
    return (
      <div className="name-block align-items-center d-flex">
        {!row.user_photo ? (
          <span
            className={`name-ltr bg-${getNameAndUniqId(row.user_name)?.uniqId}`}
          >
            {getNameAndUniqId(row.user_name)?.initials}
          </span>
        ) : (
          <img
            className="img-fluid profile__picture"
            src={`https://erptest.spericorn.com/repo/uploads/${row.user_photo}`}
            alt=""
          />
        )}
        <span className="name">{row.user_name}</span>
      </div>
    );
  };

  const renderDateFormat = (row) =>
    row ? moment(row).format("DD MMM YYYY, hh:mm A") : "-";

  const renderChangedValue = (row) => {
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(row.newValue)) {
      return moment(row.newValue).format("DD MMM YYYY");
    }

    if (row.newValue?.length > 20)
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="cron-activity-desc">{row.newValue}</Tooltip>}
        >
          <div className="tbl-cont">{row.newValue}</div>
        </OverlayTrigger>
      );

    return `${row.oldValue} > ${row.newValue}`;
  };

  return (
    <BottomScrollListener onBottom={() => console.log("trigger")}>
      <div className="conetnt_area_main cstm-spcg table-spcg">
        <div className="content-tables tables_modal pt-0">
          <div className="modal-serch-cvr">
            <SubHeader showFilter showSearchBar />
          </div>
          <div className="table-responsive custom-scroll table-helight">
            <div className="table-responsive">
              <CustomTable
                tableClassName=""
                divClassName=""
                columns={[
                  {
                    name: "Date & Time",
                    selector: "date",
                    activitySort: true,
                    colClassName: "left-txt",
                    contentClass: "tbl-cont fisrt_th",
                    icon: true,
                    cell: ({ row }) => renderDateFormat(row.date),
                  },
                  {
                    name: "Updated By",
                    selector: "user_name",
                    activitySort: true,
                    colClassName: "left-txt",
                    contentClass: "tbl-cont",
                    cell: ({ row }) => renderUpdatedBy(row),
                  },
                  {
                    name: "Category",
                    selector: "action",
                    activitySort: true,
                    colClassName: "left-txt",
                    contentClass: "tbl-cont",
                  },
                  {
                    name: "Activity",
                    selector: "newValue",
                    activitySort: true,
                    colClassName: "left-txt",
                    contentClass: "tbl-cont",
                    cell: ({ row }) => renderChangedValue(row),
                  },
                ]}
                data={activityLog?.logs}
                isLoading={activityLoader}
                pagination={{ limit: activityLog?.totalCount }}
                onSortChange={(selector) => {
                  setSortingOrder(selector);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </BottomScrollListener>
  );
};

CronActivityLog.propTypes = {
  ActivityId: PropTypes.string,
};

export default CronActivityLog;
