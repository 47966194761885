import Gateway from "../gateway/gateway";

export const getTicketAllocationData = (params) =>
  Gateway.get("/ticket-allocation", { params });

export const getTicketAllocationDataById = (id, listId) =>
  Gateway.get(`/ticket-allocation/${id}`, { params: listId });

export const patchTicketAllocationData = (id, patchData, needCount) =>
  Gateway.patch(`/ticket-allocation/${id}`, { ...patchData, needCount });

export const deleteTicketAllocationData = (id, needCount) =>
  Gateway.delete(`/ticket-allocation/${id}?needCount=${needCount}`);

export const patchTicketAllocationAtmData = (id, data) =>
  Gateway.patch(`/ticket-allocation/attachment/${id}`, data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

export const postTicketAllocationData = (data) =>
  Gateway.post("/ticket-allocation", data);

export const deleteTicketAllocationAtmData = (id, file) =>
  Gateway.patch(`/ticket-allocation/attachment/deletes/${id}`, { file });
