import { createSlice } from "@reduxjs/toolkit";

export const name = "todo";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.todoData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setAllTodo = (state, action) => {
    state.allTodoList = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setAllTodoData = (state, action) => {
    state.allTodoListData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setSingleTodoData = (state, action) => {
    state.singleTodoData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setTodoCount = (state, action) => {
    state.todoCount = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setCreateTodo = (state) => {
    state.createdTodo = true;
  };
  const setTags = (state, action) => {
    state.tagsList = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setColor = (state, { payload }) => {
    state.colorData = payload.data;
  };
  const setShowTodoModal = (state) => {
    state.showTodoModal = !state.showTodoModal;
    state.singleTodoData = "";
  };
  const setShowDetailModal = (state, payload) => {
    return {
      ...state,
      showDetailModal: !state.showDetailModal,
      editId: payload.payload.id,
    };
  };

  return {
    setAllTodo,
    setErrorMesage,
    resetErrorMesage,
    setShowTodoModal,
    setShowDetailModal,
    setAllTodoData,
    setColor,
    setSingleTodoData,
    setTodoCount,
    setCreateTodo,
    setTags,
  };
};

function createInitialState() {
  return {
    todoData: [],
    allTodoList: [],
    allTodoListData: [],
    colorData: [],
    singleTodoData: [],
    todoCount: [],
    createdTodo: [],
    tagsList: [],
    errorMessage: "",
    successMessage: "",
    showTodoModal: false,
    showDetailModal: false,
    editId: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const todoActions = slice.actions;
export const todoReducer = slice.reducer;
