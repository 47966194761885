import Gateway from "../gateway/gateway";

const addEmailAccount = (data) =>
  Gateway.post("/emailaccount", data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

const getEmailAccountList = (params) =>
  Gateway.get("/emailaccount", { params });

const getEmailAccountById = (id) => Gateway.get(`/emailaccount/${id}`);

const updateEmailAccountList = ({ id, data }) =>
  Gateway.patch(`/emailaccount/${id}`, data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

const deleteEmailAccount = (id) => Gateway.delete(`/emailaccount/${id}`);

export default {
  addEmailAccount,
  getEmailAccountList,
  updateEmailAccountList,
  getEmailAccountById,
  deleteEmailAccount,
};
