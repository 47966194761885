import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { deleteGitConnection } from "../../../../services/gitHubService";
import { gitHubAction } from "..";
import { getUserProfile } from "../../userProfile/thunks/getUserProfile";

export const dissconnectGit = createAsyncThunk(
  "github/dissconnect",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data: message },
      } = await deleteGitConnection(id);
      console.log("message", message);
      dispatch(gitHubAction.gitSuccessStatus(false));
      dispatch(getUserProfile());
      dispatch(alertActions.success(message));
      dispatch(gitHubAction.setSuccess(false));
    } catch (error) {
      const errorMessage = _.isString(error) ? error : error.response.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
