import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import projectServices from "../../../../services/projectmanagement";
import { projectActions } from "..";
import { alertActions } from "../../alert/index";

export const getnotificationbyId = createAsyncThunk(
  "notification-id",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data: singleNotification, message: successMessage },
      } = await projectServices.getnotificationbyId(id);

      dispatch(
        projectActions.setSingleNotification({
          singleNotification,
          successMessage,
        }),
      );
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
