import * as XLSX from "xlsx";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatDateStructure } from "../../utils/utils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

export const cols = (hasPermission) => [
  {
    name: "Category",
    selector: "category",
    cell: ({ row }) => (
      <div className="tbl-cont ">{row?.payload?.category}</div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions.ACTIVITY_CATEGORY,
    ),
  },
  {
    name: "User",
    selector: "userName",
    // cell: ({ row, index }) => (
    //   <div className="tbl-cont ">
    //     <div className="name-block align-items-center d-flex">
    //       <span className={`name-ltr bg-${(index % 4) + 1}`}>
    //         {getInitials(row?.payload?.userName)}
    //       </span>
    //       <div className="">{row?.payload?.userName}</div>
    //     </div>
    //   </div>
    // ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(permissionConstants.permissions.ACTIVITY_USER),
  },
  {
    name: "Action",
    selector: "action",
    cell: ({ row }) => <div className="tbl-cont ">{row?.payload?.action}</div>,
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(permissionConstants.permissions.ACTIVITY_ACTION),
  },
  {
    name: "Remark",
    selector: "remarks",
    cell: ({ row }) => (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{row?.payload?.remarks}</Tooltip>}
      >
        <div className="tbl-cont " data-for="tooltip">
          {row?.payload?.remarks || "-"}
        </div>
      </OverlayTrigger>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(permissionConstants.permissions.ACTIVITY_REMARKS),
  },
  {
    name: "Date & Time",
    selector: "date",
    cell: ({ row }) => (
      <div className="tbl-cont ">{formatDateStructure(row.createdAt)}</div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(permissionConstants.permissions.ACTIVITY_DATE),
  },
];

export const generateExcelData = (data, cols) => {
  const headers = cols.map((col) => col.name);
  const excelData = data.map((item) =>
    cols.map((col) => {
      switch (col.selector) {
        case "date":
          return formatDateStructure(item.createdAt);
        default:
          return item.payload ? item.payload[col.selector] : "";
      }
    }),
  );
  const excelDataWithHeaders = [headers, ...excelData];
  const ws = XLSX.utils.aoa_to_sheet(excelDataWithHeaders);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Activity Log");
  return wb;
};
