import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { getTicketStatus } from "../../store/slices/ticket/thunks/getTicketStatus";
import { withTitle } from "../../hoc/withTitle";
import { useThunk } from "../../hooks/useThunk";

const ReportManagement = () => {
  const [status] = useThunk(getTicketStatus);

  useEffect(() => {
    status();
  }, []);

  return <Outlet />;
};

export default withTitle(ReportManagement);
