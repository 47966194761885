import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getDelete } from "../../../../services/documentService";
import { documentActions } from "..";

export const deleteDocs = createAsyncThunk(
  "documents/delete",
  async (params, { dispatch }) => {
    try {
      const { data } = await getDelete(params);
      dispatch(alertActions.success(data.message));
      dispatch(documentActions.deleteDocumentData(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
