import { createSlice } from "@reduxjs/toolkit";

export const name = "technology";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.departmentData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setDepartment = (state, action) => {
    state.departmentData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setDepartmentTechnology = (state, action) => {
    state.departmentTechnologyData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setAllTechnologies = (state, action) => {
    state.allTechnologies = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  return {
    setDepartment,
    setDepartmentTechnology,
    setAllTechnologies,
    setErrorMesage,
    resetErrorMesage,
  };
};

function createInitialState() {
  return {
    departmentData: [],
    departmentTechnologyData: [],
    allTechnologies: [],
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const technologyActions = slice.actions;
export const technologyReducer = slice.reducer;
