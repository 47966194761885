import { useContext } from "react";
import { useSelector } from "react-redux";
import LanguageContext from "../../context/LanguageContext";
import noresults_lightImage from "../../assets/images/Group 59771.svg";
import "../../design/custom/pages/warning-page.scss";

const NoDataPage = () => {
  const {
    languageData: { results },
  } = useContext(LanguageContext);

  const { jokesData } = useSelector((state) => state.jokes);

  return (
    <div className="warning-page-cover">
      <div className="warning-content-block text-center">
        <div className="text-center img-block">
          <img src={noresults_lightImage} alt="" />
        </div>
        <h4>{results.resultsFound}</h4>
        <p>{jokesData}</p>
      </div>
    </div>
  );
};

export default NoDataPage;
