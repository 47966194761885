/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CalendarIcon, ClockIcon } from "./MeetingSvgIcons";
import { getFirstLetters } from "../../../utils/utils";
import { getMultyUserProfile } from "../../../store";
import { userProfileActions } from "../../../store/slices/userProfile";
import { useThunk } from "../../../hooks/useThunk";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";

/* eslint-disable react/prop-types */
function CompletedMeeting({ data, isLoading }) {
  const [imgArray, setimgArray] = useState([]);

  const dispatch = useDispatch();

  const [getMultyUsers] = useThunk(getMultyUserProfile);

  const upcomingSchedules = data?.filter((e) => e?.complete);

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  const handleOnLoad = (e) => {
    e.target.style.display = "block";
  };

  return (
    <div className="row  row-cards">
      {!isLoading && (
        <div className="title-otr col-12">
          <div className="page-title">
            Completed <span>{upcomingSchedules?.length}</span>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="page-contents inner-page-contents">
          <div className="cover__inner">
            <div className="row  row-cards">
              <div className="title-otr col-12">
                <CommonSkeleton />
              </div>
              {Array.from({ length: 3 }).map((_, index) => (
                <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                  <div className="hd-otr d-flex">
                    <div className="d-flex flex-grow-1 flex-column">
                      <div className="title-lg">
                        {" "}
                        <CommonSkeleton height={200} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        data?.map((e) => {
          if (e?.complete) {
            const inputTimestamp = e?.date;
            // Parse the input timestamp using moment
            const dateTime = moment.utc(inputTimestamp);

            // Format date as "DD-Month-YYYY"
            const formattedDate = dateTime.format("DD-MMMM-YYYY");
            const timeSlotData = e?.timeSlot?.map((time) => time);

            // Extract the start time from the first object
            const startTime = timeSlotData[0].time.split(" - ")[0];
            const startId = timeSlotData[0].id;

            // Extract the end time from the last object
            const endTime =
              timeSlotData[timeSlotData.length - 1]?.time.split(" - ")[1];
            const endId = timeSlotData[timeSlotData.length - 1]?.id;

            // Check if the id of the start time is less than the id of the end time
            const displayId = startId < endId ? startId : "less";

            // Combine the start and end times
            let combinedTime;
            if (displayId === "less") {
              combinedTime = `${endTime} - ${startTime}`;
            } else {
              combinedTime = `${startTime} - ${endTime}`;
            }

            return (
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="conf-card completed-card">
                  <div className="hd-otr">
                    <div className="title-lg">{e?.title}</div>
                    <small>
                      {e?.timeSlot?.length} Hour, {e?.type}
                    </small>
                  </div>

                  <div className="mind-rw">
                    <div className="info">
                      <div className="icon-sm">
                        <ClockIcon />
                      </div>
                      {combinedTime}
                    </div>

                    <div className="info">
                      <div className="icon-sm">
                        <CalendarIcon />
                      </div>
                      <span>{formattedDate}</span>
                    </div>
                  </div>

                  <div className="ftr">
                    {e?.invites?.map(
                      (empl, index) =>
                        index < 2 && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`${empl?.employeeName}-tooltip`}>
                                {empl?.employeeName}
                              </Tooltip>
                            }
                          >
                            <div className="avatar__group">
                              <div
                                className={`avathar-sm avathar-0${
                                  (index % 4) + 1
                                }`}
                              >
                                <div className="online" />

                                {empl?.photo ? (
                                  imgArray?.includes(empl?.employeeName) ? (
                                    <div
                                      className="name userProf bg-7"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getMultyUsers(empl?._id);
                                        dispatch(
                                          userProfileActions.setShowUserProfile(
                                            true,
                                          ),
                                        );
                                      }}
                                    >
                                      {getFirstLetters(empl?.employeeName)}
                                    </div>
                                  ) : (
                                    <img
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getMultyUsers(empl?._id);
                                        dispatch(
                                          userProfileActions.setShowUserProfile(
                                            true,
                                          ),
                                        );
                                      }}
                                      onError={(error) =>
                                        handleOnError(error, empl?.employeeName)
                                      }
                                      onLoad={(error) => handleOnLoad(error)}
                                      src={`https://erptest.spericorn.com/repo/uploads/${empl?.photo}`}
                                      alt=""
                                    />
                                  )
                                ) : (
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      getMultyUsers(empl?._id);
                                      dispatch(
                                        userProfileActions.setShowUserProfile(
                                          true,
                                        ),
                                      );
                                    }}
                                  >
                                    {" "}
                                    {getFirstLetters(empl?.employeeName)}
                                  </span>
                                )}
                              </div>

                              {/* {/ more dropdown  /} */}
                            </div>
                          </OverlayTrigger>
                        ),
                    )}

                    <div className="avatar__group">
                      {/* {/ more dropdown  /} */}

                      <Dropdown>
                        {e?.invites?.length > 2 && (
                          <Dropdown.Toggle
                            id="checklist-toggles"
                            className="avathar-sm avathar-more"
                          >
                            <div className="avatar__group">
                              <div className="online" />

                              <span> + {(e?.invites?.length ?? 0) - 2}</span>
                            </div>
                          </Dropdown.Toggle>
                        )}
                        {e?.invites?.length > 2 && (
                          <Dropdown.Menu className="no-bg  pb-1 dropdown-menu right ">
                            <div className="list-items-main custom-scroll list-items-1 ps-2 pe-1 allocation-drp-dwn right">
                              <ul className="p-0">
                                {e?.invites?.map(
                                  ({ employeeName, _id, photo }, index) =>
                                    index >= 2 && (
                                      <li className="d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                          {photo ? (
                                            imgArray?.includes(_id) ? (
                                              <div
                                                className={`avathar-sm ms-0  avathar-0${
                                                  (index % 3) + 1
                                                }`}
                                              >
                                                <span>
                                                  {getFirstLetters(
                                                    employeeName,
                                                  )}
                                                </span>
                                              </div>
                                            ) : (
                                              <img
                                                onError={(target) =>
                                                  handleOnError(target, _id)
                                                }
                                                onLoad={(target) => {
                                                  target.target.style.display =
                                                    "block";
                                                }}
                                                className="usrImg"
                                                src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
                                                alt=""
                                              />
                                            )
                                          ) : (
                                            <div
                                              className={`avathar-sm ms-0  avathar-0${
                                                (index % 4) + 1
                                              }`}
                                            >
                                              <span>
                                                {employeeName
                                                  ?.slice(0, 2)
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                          )}
                                          <div className="rgt_content d-flex flex-column">
                                            <div
                                              className={
                                                photo
                                                  ? "title_fst ms-1"
                                                  : "title_fst"
                                              }
                                            >
                                              {employeeName}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="ms-auto profile-btn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            getMultyUsers(_id);
                                            dispatch(
                                              userProfileActions.setShowUserProfile(
                                                true,
                                              ),
                                            );
                                          }}
                                        >
                                          Profile
                                        </div>
                                      </li>
                                    ),
                                )}
                              </ul>
                            </div>
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            // If e.complete is not true, return null or an empty fragment
            return null;
          }
        })
      )}
      {isLoading && (
        <div className="page-contents inner-page-contents">
          <div className="cover__inner">
            <div className="row  row-cards">
              <div className="title-otr col-12">
                <CommonSkeleton />
              </div>
              {Array.from({ length: 3 }).map((_, index) => (
                <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                  <div className="hd-otr d-flex">
                    <div className="d-flex flex-grow-1 flex-column">
                      <div className="title-lg">
                        {" "}
                        <CommonSkeleton height={200} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompletedMeeting;
