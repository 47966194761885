import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getDocuments } from "../../../../services/documentService";
import { documentActions } from "..";
import { getProjectsTabCounts } from "../../project/thunks/projectCounts";

export const getUserDocuments = createAsyncThunk(
  "documents/get",
  async (params, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const { data } = await getDocuments(params);
      dispatch(documentActions.setDocumentData(data));
      if (params?.projectId) dispatch(getProjectsTabCounts(params?.projectId));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
