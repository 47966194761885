import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useThunk } from "../../hooks/useThunk";
import CustomTable from "../../components/CustomTable";
import SubHeader from "../../structure/SubHeader/SubHeader";
import { useFilter } from "../../context/useFilter";
import { withTitle } from "../../hoc/withTitle";
import { getNameAndUniqId } from "../../components/common/iconDefaultName";
import { getDesignationList } from "../../store/slices/employee/thunk/designationList";
import { getEmployeeList } from "../../store/slices/employee/thunk/employeeList";
import { updateDesignation } from "../../store/slices/employee/thunk/updateDesignation";
import { resetEmployeeReducer } from "../../store/slices/employee/thunk/clearReducer";
import { getMultyUserProfile } from "../../store";
import { userProfileActions } from "../../store/slices/userProfile";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

import "../../design/custom/pages/employee-details.scss";
import searchNormal from "../../assets/images/search-normal.svg";

const EmployeeListing = () => {
  const dispatch = useDispatch();
  const myDivRef = useRef(null);

  const [employeeListAPI, isLoading] = useThunk(getEmployeeList);
  const [designationListAPI] = useThunk(getDesignationList);
  const [updateDesignationAPI] = useThunk(updateDesignation);
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [clearReducerVal] = useThunk(resetEmployeeReducer);
  const { filter } = useFilter();

  const { employeeList, designationList, isSuccess } = useSelector(
    (state) => state.employeeReducer,
  );
  const { userProfile } = useSelector((state) => state.userProfile);

  const [changeDesignation, setChangeDesignation] = useState(null);
  const [imgArray, setImgArray] = useState([]);
  const [params, setParams] = useState(null);
  const [sortOrder, setSortOrder] = useState({
    employeeName: "1",
    employeeId: "1",
  });
  const [sortBy, setSortBy] = useState("employeeName");
  const [searchDesigtn, setSearchDesigtn] = useState("");
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });

  console.log("searchDesigtn", searchDesigtn);
  console.log("designationList", designationList);
  console.log("employeeList", employeeList);

  useEffect(() => {
    designationListAPI({ search: searchDesigtn, limit: 100 });
  }, [searchDesigtn]);

  useEffect(() => {
    setPagination({ ...pagination, currentPage: 1 });
  }, [filter.search]);

  useEffect(() => {
    if (params) employeeListAPI(params);
  }, [params]);

  useEffect(() => {
    renderEmployeeList();
  }, [sortOrder, pagination, filter.search]);

  useEffect(() => {
    if (isSuccess) setChangeDesignation(null);
    renderEmployeeList();
    clearReducerVal();
  }, [isSuccess]);

  useEffect(() => {
    return () => {
      clearReducerVal();
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (myDivRef.current && !myDivRef.current.contains(event.target)) {
        setChangeDesignation(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PERMISSIONS,
        permissionConstants.submodules.EMPLOYEE_LIST,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const renderEmployeeList = () => {
    setParams({
      sortOrder: sortOrder[sortBy],
      sortBy,
      page: pagination.currentPage,
      limit: pagination.limit,
      search: filter.search,
    });
  };

  const handleOnError = (e, id) => {
    setImgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  const renderEmpDesignation = ({ row }) =>
    hasPermission(
      permissionConstants.permissions.EMPLOYEE_DESIGNATION_UPDATE,
    ) && row?._id === changeDesignation ? (
      <div
        className="dropdown-menu dropdown-scroll dropeffect pt-2 pb-2 show"
        aria-labelledby="dropdownMenuButton1"
        ref={myDivRef}
      >
        <div className="dropdown-item no-hover searcharea d-flex align-items-center no-bg pb-1 ">
          <div className="search-icon">
            <img src={searchNormal} alt="Icon" />
          </div>
          <input
            type="text"
            className="form-control no-hover"
            placeholder="Search Designation"
            value={searchDesigtn}
            onChange={(e) => setSearchDesigtn(e.target.value)}
          />
        </div>

        {designationList?.result?.map(({ _id, name }) => (
          <>
            <div className="divitions pb-1 pt-1">
              <hr className="mt-0 mb-0" />
            </div>
            <button
              type="button"
              className="dropdown-item d-flex "
              id="employee-designation-update"
              onClick={() => {
                updateDesignationAPI({
                  id: row?._id,
                  params: { designationPms: _id },
                });
              }}
            >
              <span className="noAllocation">{name}</span>
            </button>
          </>
        ))}
      </div>
    ) : (
      <span
        className="designation-update"
        onClick={() => setChangeDesignation(row?._id)}
        style={{ cursor: "pointer" }}
      >
        {row?.designationPms ? row?.designationPms?.name : "-"}
      </span>
    );

  const renderProfileName = ({ row }) => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          getMultyUsers(row._id);
          dispatch(userProfileActions.setShowUserProfile(true));
        }}
        style={{ cursor: "pointer" }}
        className="name-block align-items-center d-flex"
      >
        {!row.photo || imgArray.includes(row._id) ? (
          <span
            className={`name-ltr bg-${
              getNameAndUniqId(row.employeeName)?.uniqId
            }`}
          >
            {getNameAndUniqId(row.employeeName)?.initials}
          </span>
        ) : (
          <img
            className="img-fluid profile__picture"
            src={`https://erptest.spericorn.com/repo/uploads/${row.photo}`}
            alt="..."
            onError={(target) => handleOnError(target, row?._id)}
            onLoad={(target) => {
              target.target.style.display = "block";
            }}
          />
        )}
        <span className="name">{row.employeeName}</span>
      </div>
    );
  };

  const cols = [
    {
      name: "Employee ID",
      selector: "employeeId",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      icon: true,
      permission: hasPermission(permissionConstants.permissions.EMPLOYEE_ID),
    },
    {
      name: "Name",
      selector: "employeeName",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      icon: true,
      cell: (row) => renderProfileName(row),
      permission: hasPermission(permissionConstants.permissions.EMPLOYEE_NAME),
    },
    {
      name: "Designation",
      selector: "contactNumber",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      cell: (row) => renderEmpDesignation(row),
      permission: hasPermission(
        permissionConstants.permissions.EMPLOYEE_DESIGNATION,
      ),
    },
    {
      name: "Phone Number",
      selector: "contactNumber",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      permission: hasPermission(permissionConstants.permissions.EMPLOYEE_PNO),
    },
    {
      name: "Email",
      selector: "email",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      permission: hasPermission(permissionConstants.permissions.EMPLOYEE_EMAIL),
    },
  ];

  const permissionBasedCol = cols.filter(({ permission }) => permission);

  return (
    <>
      <SubHeader showSearchBar />
      <CustomTable
        tableClassName="table td-center"
        divClassName="table-responsive custom-scroll emplyoee-bg"
        columns={permissionBasedCol}
        data={employeeList.data}
        count={employeeList.totalCount}
        showPagination
        onSortChange={(data) => {
          setSortOrder({
            ...sortOrder,
            [data]: sortOrder[data] === "1" ? "-1" : "1",
          });
          setSortBy(data);
        }}
        isLoading={isLoading}
        pagination={pagination}
        onPageChange={(pageNo) =>
          setPagination({ ...pagination, currentPage: pageNo })
        }
        onLimitChange={(limit) =>
          setPagination({ ...pagination, limit, currentPage: 1 })
        }
      />
    </>
  );
};

export default withTitle(EmployeeListing);
