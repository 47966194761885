export const CopyToClipboard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    id="Group_58863"
    data-name="Group 58863"
    width="13.944"
    height="14.696"
    viewBox="0 0 13.944 14.696"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_3703"
          data-name="Rectangle 3703"
          width="13.944"
          height="14.696"
          transform="translate(0 0)"
          fill="currentcolor"
        />
      </clipPath>
    </defs>
    <g
      id="Group_58871"
      data-name="Group 58871"
      transform="translate(0 0)"
      clipPath="url(#clip-path)"
      fill="currentColor"
    >
      <path
        id="Path_4054"
        data-name="Path 4054"
        d="M10.624,2.211V1.274a1.31,1.31,0,0,0-.336-.883A1.181,1.181,0,0,0,9.419,0H3.587L0,4.328v9.094A1.242,1.242,0,0,0,1.2,14.7H9.419a1.242,1.242,0,0,0,1.2-1.274v-.642H9.6v.642a.229.229,0,0,1-.181.251H1.2a.229.229,0,0,1-.181-.251v-8.4H4.488v-4H9.419a.159.159,0,0,1,.111.055.292.292,0,0,1,.07.2v.937ZM1.6,4,3.464,1.752V4Z"
        transform="translate(0 0)"
        fill="currentcolor"
      />
      <path
        id="Path_4055"
        data-name="Path 4055"
        d="M36.68,22.316H32.506a1.343,1.343,0,0,1-1.341-1.341v-6.1a1.343,1.343,0,0,1,1.341-1.341H36.68a1.343,1.343,0,0,1,1.341,1.341v6.1a1.343,1.343,0,0,1-1.341,1.341m-4.174-7.759a.318.318,0,0,0-.318.318v6.1a.318.318,0,0,0,.318.318H36.68A.318.318,0,0,0,37,20.974v-6.1a.318.318,0,0,0-.318-.318Z"
        transform="translate(-24.077 -10.455)"
        fill="currentcolor"
      />
    </g>
  </svg>
);

export const Tool2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.505"
    height="16.378"
    viewBox="0 0 15.505 16.378"
  >
    <g
      id="Group_1206"
      data-name="Group 1206"
      transform="translate(-4181.157 -3968.35)"
      fill="currentColor"
    >
      <path
        id="Union_27"
        data-name="Union 27"
        d="M2835.41,1171.779a6.817,6.817,0,0,1,4.609-6.209,4.478,4.478,0,1,1,5.288-.279q.113.03.226.063a.65.65,0,1,1-.365,1.248,7.386,7.386,0,0,0-2.075-.3c-3.521,0-6.384,2.456-6.384,5.475a.65.65,0,1,1-1.3,0Zm3.886-9.952a3.177,3.177,0,1,0,3.177-3.177A3.181,3.181,0,0,0,2839.3,1161.827Z"
        transform="translate(1345.747 2811)"
        fill="currentColor"
      />
      <g id="Group" transform="translate(4191.097 3979.146)">
        <path
          id="Union_28"
          data-name="Union 28"
          d="M-1353.155-2816.213v-2.1h-2.032a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h2.032v-1.982a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v1.982h2.032a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-2.032v2.1a.5.5,0,0,1-.5.5A.5.5,0,0,1-1353.155-2816.213Z"
          transform="translate(1355.188 2821.295)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export const Tool3 = () => (
  <svg
    id="Group_58856"
    data-name="Group 58856"
    xmlns="http://www.w3.org/2000/svg"
    width="15.356"
    height="15.356"
    viewBox="0 0 15.356 15.356"
    fill="currentColor"
  >
    <path
      id="texture-box"
      d="M11.088,2H3.01A1.013,1.013,0,0,0,2,3.01v8.076A1.01,1.01,0,0,0,3.01,12.1h8.076a1.006,1.006,0,0,0,1.01-1.01V3.01A1.01,1.01,0,0,0,11.088,2M3.01,4.02,4.02,3.01H6.493L3.01,6.493Zm0,3.887,4.9-4.9h2.474L3.01,10.381Zm8.076,2.171-1.01,1.01H7.6L11.086,7.6Zm0-3.887-4.9,4.9H3.717l7.371-7.371Z"
      transform="translate(0.647 0.647)"
      fill="currentColor"
    />
    <path
      id="Rectangle_3471"
      data-name="Rectangle 3471"
      d="M2.275,1.138A1.139,1.139,0,0,0,1.138,2.275V13.081a1.139,1.139,0,0,0,1.138,1.138H13.081a1.139,1.139,0,0,0,1.138-1.138V2.275a1.139,1.139,0,0,0-1.138-1.138H2.275M2.275,0H13.081a2.275,2.275,0,0,1,2.275,2.275V13.081a2.275,2.275,0,0,1-2.275,2.275H2.275A2.275,2.275,0,0,1,0,13.081V2.275A2.275,2.275,0,0,1,2.275,0Z"
      fill="currentColor"
    />
  </svg>
);

export const Tool6 = () => (
  <svg
    id="Group_58857"
    data-name="Group 58857"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    width="15.171"
    height="15.329"
    viewBox="0 0 15.171 15.329"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_3697"
          data-name="Rectangle 3697"
          width="15.171"
          height="15.329"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g
      id="Group_58859"
      data-name="Group 58859"
      transform="translate(0 0)"
      clipPath="url(#clip-path)"
      fill="currentColor"
    >
      <path
        id="Path_4042"
        data-name="Path 4042"
        d="M11.649,3.849V1.465A1.547,1.547,0,0,0,11.281.449,1.266,1.266,0,0,0,10.328,0h-6.4L0,4.976v8.887a1.4,1.4,0,0,0,1.321,1.465h9.007a1.4,1.4,0,0,0,1.321-1.465V11.818H10.527v2.046c0,.17-.1.288-.2.288H1.321c-.094,0-.2-.118-.2-.288V5.777h3.8v-4.6h5.408a.171.171,0,0,1,.122.064.345.345,0,0,1,.077.224V3.849ZM1.755,4.6,3.8,2.014V4.6Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_4043"
        data-name="Path 4043"
        d="M36.919,19.985l-2.641-2.769a.378.378,0,0,0-.549-.045.423.423,0,0,0-.043.575.326.326,0,0,0,.036.038L35.7,19.866H29.513a.409.409,0,0,0,0,.817H35.7l-1.975,2.071a.423.423,0,0,0-.042.576.381.381,0,0,0,.266.141h.031a.375.375,0,0,0,.252-.1.319.319,0,0,0,.036-.038l2.648-2.777a.423.423,0,0,0,0-.576"
        transform="translate(-21.861 -12.608)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const Tool9 = () => (
  <svg
    id="Group_58860"
    data-name="Group 58860"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    width="11.386"
    height="16.562"
    viewBox="0 0 11.386 16.562"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_3700"
          data-name="Rectangle 3700"
          width="11.386"
          height="16.561"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g
      id="Group_58865"
      data-name="Group 58865"
      transform="translate(0 0)"
      clipPath="url(#clip-path)"
      fill="currentColor"
    >
      <path
        id="Path_4048"
        data-name="Path 4048"
        d="M11.234,8.432,7.977,5.175l3.257-3.257a.518.518,0,0,0-.366-.884H1.035V.518A.518.518,0,0,0,0,.518V16.044a.518.518,0,0,0,1.035,0V9.316h9.833a.518.518,0,0,0,.366-.884m-10.2-.152V2.07H9.619L6.88,4.81a.517.517,0,0,0,0,.732L9.619,8.281Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const Tool16 = () => (
  <svg
    id="Group_58867"
    data-name="Group 58867"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    width="11.701"
    height="13.281"
    viewBox="0 0 11.701 13.281"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_3707"
          data-name="Rectangle 3707"
          width="11.701"
          height="13.281"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g
      id="Group_58879"
      data-name="Group 58879"
      transform="translate(0 0)"
      clipPath="url(#clip-path)"
      fill="currentColor"
    >
      <path
        id="Path_4060"
        data-name="Path 4060"
        d="M11.7,2.977a1.411,1.411,0,0,0-1.409-1.409H8.165V.7a.7.7,0,0,0-.7-.7H4.3a.761.761,0,0,0-.761.761v.807H1.409A1.408,1.408,0,0,0,.821,4.255v7.617A1.41,1.41,0,0,0,2.23,13.281H9.521a1.41,1.41,0,0,0,1.409-1.409V4.231A1.409,1.409,0,0,0,11.7,2.977M1.409,2.519h8.883a.457.457,0,1,1,0,.914H1.409a.457.457,0,1,1,0-.914m8.112,9.81H2.23a.458.458,0,0,1-.457-.457V4.386H9.978v7.487a.458.458,0,0,1-.457.457"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_4061"
        data-name="Path 4061"
        d="M19.888,28.059a.476.476,0,0,0-.476.476v3.844a.476.476,0,1,0,.952,0V28.535a.476.476,0,0,0-.476-.476"
        transform="translate(-15.306 -22.124)"
        fill="currentColor"
      />
      <path
        id="Path_4062"
        data-name="Path 4062"
        d="M31.535,28.059a.476.476,0,0,0-.476.476v3.844a.476.476,0,1,0,.952,0V28.535a.476.476,0,0,0-.476-.476"
        transform="translate(-24.49 -22.124)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const DateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.98"
    height="15.421"
    viewBox="0 0 13.98 15.421"
  >
    <g
      id="Group_59680"
      data-name="Group 59680"
      transform="translate(-1.663 -0.942)"
      fill="currentColor"
    >
      <path
        id="Vector"
        d="M0,0V2.163"
        transform="translate(5.769 1.442)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0V2.163"
        transform="translate(11.537 1.442)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H12.259"
        transform="translate(2.523 6.555)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M12.98,3.605V9.735c0,2.163-1.082,3.605-3.605,3.605H3.605C1.082,13.34,0,11.9,0,9.735V3.605C0,1.442,1.082,0,3.605,0H9.374C11.9,0,12.98,1.442,12.98,3.605Z"
        transform="translate(2.163 2.523)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M.495.5H.5"
        transform="translate(8.155 9.379)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-6"
        data-name="Vector"
        d="M.495.5H.5"
        transform="translate(5.486 9.379)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-7"
        data-name="Vector"
        d="M.495.5H.5"
        transform="translate(5.486 11.542)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
