import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import roleServices from "../../../../services/roleService";
import { roleActions } from "..";
import { alertActions } from "../../alert/index";

export const getRolePermissions = createAsyncThunk(
  "Role-id",
  async ({ departmentId, roleId }, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data: rolePermissions },
      } = await roleServices.getRolePermissions({ departmentId, roleId });

      dispatch(
        roleActions.setRolePermissions({
          rolePermissions,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
