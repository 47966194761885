/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setTicketAllocationById } from "../index";
import service from "../../../../services/ticketService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getTicketsById = createAsyncThunk(
  `get/ticketById`,
  async ({ params, listId }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await service.getTicketDataById(params, listId);
      console.log("data", successMessage);
      dispatch(setTicketAllocationById(data[0]));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
