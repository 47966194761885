import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getProjects } from "../../../../services/projectService";
import { projectActions } from "..";

export const getUserProjects = createAsyncThunk(
  "projects/get",
  async ({ params }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await getProjects(params);
      dispatch(
        projectActions.setProjectData({
          data,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
