import { createSlice } from "@reduxjs/toolkit";

export const name = "permissions";

const createReducers = () => {
  const setPermissionList = (state, { payload }) => {
    state.permissionsList = payload.data.data;
  };

  return {
    setPermissionList,
  };
};

function createInitialState() {
  return {
    permissionsList: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const permissionActions = slice.actions;
export const permissionReducer = slice.reducer;
