import Gateway from "../gateway/gateway";

const departments = (projectId) =>
  Gateway.get(`/technology/department/${projectId}`);

const departmentsTechnology = (id) =>
  Gateway.get(`/technology?department=${id}`);

const deleteTechnology = (id) => Gateway.delete(`/technology/${id}`);

const addTechnology = (id) => Gateway.post("/technology", id);

const getTechnologiesList = (projectId) =>
  Gateway.get(`/technology/select/${projectId}`);

export default {
  departments,
  departmentsTechnology,
  deleteTechnology,
  addTechnology,
  getTechnologiesList,
};
