import Gateway from "../gateway/gateway";

const projectDashboardCounts = (projectId) =>
  Gateway.get(`/stats/counts/${projectId}`);

const projectDashboardTasks = (projectId) =>
  Gateway.get(`/stats/tasks/${projectId}`);

export default {
  projectDashboardCounts,
  projectDashboardTasks,
};
