import Gateway from "../gateway/gateway";

const allocationProjectsLists = () =>
  Gateway.get("/project_allocation/project_list");

const allocationLists = (params) =>
  Gateway.get("/project_allocation", { params });

const allocationListsUpdate = (data) =>
  Gateway.put("/project_allocation", data);

const userReportList = (id) => Gateway.get(`/project_allocation/${id}`);

const allUsersReportList = () =>
  Gateway.get("/project_allocation/employee_list");

export default {
  allocationProjectsLists,
  allocationLists,
  allocationListsUpdate,
  userReportList,
  allUsersReportList,
};
