import PropTypes from "prop-types";

import Counter from "./cards";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";

const CounterDashboard = ({ data, isLoading }) => {
  return (
    <div className="row">
      <div className="col">
        <div className="row h-100 g-3 ticket-count-wrp">
          {isLoading
            ? Array?.from({ length: 6 }).map(() => (
                <div className="col-lg-4 col-xl col-md-6 mb-4">
                  <div className="count-tickets d-flex align-items-center">
                    <div className="card-title-block">
                      <CommonSkeleton height={50} />
                    </div>
                  </div>
                </div>
              ))
            : data?.map(({ title, value, icon }, index) => (
                <Counter
                  key={index}
                  title={title}
                  value={value || "-"}
                  icon={icon}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

CounterDashboard.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
};
export default CounterDashboard;
