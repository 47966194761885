import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { useThunk } from "../../../../../hooks/useThunk";
import { getGitBranchs } from "../../../../../store/slices/gitIntegration/thunks/getBranchs";
import { postCreateBranch } from "../../../../../store/slices/gitIntegration/thunks/postCreateBranch";
import { gitHubAction } from "../../../../../store/slices/gitIntegration";
import { alertActions } from "../../../../../store/slices/alert";
import CustomModal from "../../../../../components/CustomModal";
import closeIcon from "../../../../../assets/images/modal-close.svg";
import branchSuccess from "../../../../../assets/images/branch-success.svg";
import copyTwo from "../../../../../assets/images/copy-2.svg";

export const CreateBranchModal = ({
  userRepositories,
  setOpenCreateBranch,
  format,
  mainRepo,
  projectId,
  ticket,
}) => {
  console.log("ticket", ticket);
  console.log("projectId", projectId);
  console.log("mainRepo", mainRepo);
  const dispatch = useDispatch();
  const [getGitHubBranches, branchLoading] = useThunk(getGitBranchs);
  const [postCreateGitBranch, isLoading] = useThunk(postCreateBranch);
  // const [successModal, setSuccessModal] = useState(false);
  const [userRepos, setUserRepos] = useState("");
  console.log("userRepos", userRepos);
  const [userBranches, setUserBranch] = useState("");
  const [branchFormat, setBranchFormat] = useState(format || "");
  const [errors, setErrors] = useState({
    name: "",
    repository: "",
    source_branch: "",
  });
  console.log("tickettttt", ticket);

  const { getAllBranchsData, getCreatedGitBranch } = useSelector(
    (state) => state.github,
  );
  console.log("postCreateGitBranch", isLoading);

  // validate
  const validateInputs = () => {
    const newErrors = {
      name: !branchFormat ? "Branch Name is required" : "",
      repository: !userRepos ? "Repository is required" : "",
      source_branch: !userBranches ? "Source Branch is required" : "",
    };

    if (branchFormat && branchFormat.trim() === format.trim()) {
      newErrors.name = "Branch Name must be different from the current branch";
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error);
  };

  // options for repos
  const listRepos = userRepositories?.map((item) => ({
    value: item?.repository?.repoName,
    label: item?.repository?.repoName,
    owner: item?.repository?.owner,
  }));

  const [branches, setBranches] = useState([]);

  // set branches
  useEffect(() => {
    if (userRepos === "") {
      setBranches([]);
    }
    setBranches(getAllBranchsData);
  }, [getAllBranchsData]);

  // options for branch select
  const listBranches = branches?.map((item) => ({
    value: item?.name,
    label: item?.name,
  }));

  const handleInputChange = (e) => {
    setBranchFormat(e.target.value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      name: "",
    }));
  };

  // submit form function
  const createGitBranch = () => {
    if (validateInputs()) {
      const branchNames = branchFormat.replace(/ /g, "_");
      console.log("branchNames", branchNames);
      postCreateGitBranch({
        branchName: branchNames,
        repoName: userRepos?.value,
        baseBranch: userBranches,
        owner: userRepos?.owner,
        projectId,
        ticket,
      });
    }
  };

  useEffect(() => {
    if (getCreatedGitBranch === true) {
      // setSuccessModal(true);
      getGitHubBranches({
        value: userRepos?.value,
        owner: userRepos?.owner,
      });
    }
  }, [getCreatedGitBranch]);

  useEffect(() => {
    if (userRepos !== "") {
      getGitHubBranches({
        value: userRepos?.value,
        owner: userRepos?.owner,
      });
    }
  }, [userRepos]);

  const renderHeader = () => (
    <>
      <h4 className="modal-title">Create Branch</h4>
      <button
        className="close-btn ms-auto"
        data-bs-dismiss="modal"
        aria-label="Close"
        type="button"
        onClick={() => {
          setOpenCreateBranch(false);
          dispatch(gitHubAction.resetCreatedGitBranch());
        }}
      >
        <img src={closeIcon} alt="" />
      </button>
    </>
  );

  const renderBody = () => (
    <div className="create-sprint-modal-body">
      <div className="modal-body-title" />
      {!getCreatedGitBranch ? (
        <div className="form-cover">
          <div className="inp-block alert-cover">
            <label htmlFor="calSprint" className="form-label">
              Branch Name
            </label>
            <input
              type="text"
              className="form-control"
              id="calSprint"
              placeholder=""
              defaultValue={format}
              onChange={handleInputChange}
            />
            <span className="error__msg">{errors?.name}</span>
            <div className="form-alert">*Required</div>
          </div>
          <div className="inp-block form-group alert-cover">
            <label htmlFor="" className="form-label">
              Repository
            </label>
            <Select
              classNamePrefix="react-select"
              className="react-select-container"
              options={listRepos}
              onChange={(selectedRepo) => {
                setBranches([]);
                // Update the userRepos
                setUserRepos({
                  value: selectedRepo?.value,
                  owner: selectedRepo?.owner,
                });

                setErrors((prevErrors) => ({
                  ...prevErrors,
                  repository: "",
                }));
              }}
            />
            <span className="error__msg">{errors?.repository}</span>
          </div>
          <div className="inp-block form-group alert-cover">
            <label htmlFor="" className="form-label">
              Source Branch
            </label>
            <Select
              classNamePrefix="react-select"
              className="react-select-container"
              options={userRepos?.value ? listBranches : []}
              isLoading={branchLoading}
              onChange={(e) => {
                // Update the selected branch
                setUserBranch(e?.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  source_branch: "",
                }));
              }}
            />
            <span className="error__msg">{errors?.source_branch}</span>
          </div>
          <div className="btn-block">
            <button
              className="btn-primary btn-cancel"
              type="button"
              onClick={() => {
                setOpenCreateBranch(false);
                dispatch(gitHubAction.resetCreatedGitBranch());
              }}
            >
              Cancel
            </button>

            <button
              className="btn-primary btn-create"
              type="submit"
              onClick={createGitBranch}
            >
              {isLoading ? (
                <span className="btn-loader">
                  <span className="spinner-border spinner-border-sm" />
                </span>
              ) : (
                "Create Branch"
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="branch-success-confirmation text-center">
          <img src={branchSuccess} alt="" className="branch-img" />
          <h3>Branch Successfully Created</h3>
          <div className="label-copied">
            <span>{branchFormat}</span>
            <span className="clipboard">
              <img
                src={copyTwo}
                alt=""
                onClick={() => {
                  const copyText = branchFormat;
                  navigator.clipboard.writeText(copyText);
                  dispatch(alertActions.success("Copied to clipboard."));
                }}
              />
            </span>
          </div>
          <button
            className="btn btn-primary button-done"
            type="button"
            onClick={() => {
              setOpenCreateBranch(false);
              dispatch(gitHubAction.resetCreatedGitBranch());
            }}
          >
            Done
          </button>
        </div>
      )}
    </div>
  );

  return (
    <CustomModal
      className="modal fade modal-default create-branch-modal"
      dialgName="modal-dialog modal-lg modal-dialog-centered"
      bodyClassname="modal-body"
      header={renderHeader()}
      createModal={7}
      disableCenter={7}
      show={!!setOpenCreateBranch}
      closeModal={() => {
        setOpenCreateBranch(false);
        // setSuccessModal(false);
        dispatch(gitHubAction.resetCreatedGitBranch());
        setUserBranch("");
        setUserRepos("");
      }}
      body={renderBody()}
    />
  );
};

CreateBranchModal.propTypes = {
  showModal: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    docId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

CreateBranchModal.propTypes = {
  setOpenCreateBranch: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  ticket: PropTypes.string.isRequired,
  mainRepo: PropTypes.string,
  userRepositories: PropTypes.arrayOf(
    PropTypes.shape({
      repoName: PropTypes.string,
    }),
  ),
};
