import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { gituserDetails } from "../../../../services/gitHubService";
import { gitHubAction } from "..";

export const gitUserDetails = createAsyncThunk(
  "github/userdetails",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const { data } = await gituserDetails(id);
      dispatch(gitHubAction.getUserDetails(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
