import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { trashActions } from "../index";
import trashService from "../../../../services/trashService";
import { alertActions } from "../../alert";

export const getTrash = createAsyncThunk(
  "get/trash",
  async (params, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await trashService.getTrashData(params);
      dispatch(
        trashActions.setTrashData({
          data,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
