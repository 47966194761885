import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "../../structure/SubHeader/SubHeader";
import CustomTable from "../../components/CustomTable";
import { useFilter } from "../../context/useFilter";
import { useThunk } from "../../hooks/useThunk";
import { withTitle } from "../../hoc/withTitle";
import { getEmployeeList } from "../../store/slices/employee/thunk/employeeList";
import { userProfileActions } from "../../store/slices/userProfile";
import { getMultyUserProfile } from "../../store";
import { getNameAndUniqId } from "../../components/common/iconDefaultName";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

const ClientManagement = () => {
  const dispatch = useDispatch();

  const [getClientList, isLoading] = useThunk(getEmployeeList);
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const { filter } = useFilter();

  const [params, setParams] = useState(null);
  const [imgArray, setImgArray] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });

  const { employeeList } = useSelector((state) => state.employeeReducer);
  const { userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    renderClientList();
  }, [pagination, filter.search]);

  useEffect(() => {
    if (params) getClientList(params);
  }, [params]);

  useEffect(() => {
    setPagination({ ...pagination, currentPage: 1 });
  }, [filter.search]);

  const renderClientList = () => {
    setParams({
      limit: pagination.limit,
      page: pagination.currentPage,
      user: "client",
      search: filter.search,
    });
  };

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PERMISSIONS,
        permissionConstants.submodules.CLIENT_LIST,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const handleOnError = (e, id) => {
    setImgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  const renderProfileName = ({ row }) => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          getMultyUsers(row._id);
          dispatch(userProfileActions.setShowUserProfile(true));
        }}
        style={{ cursor: "pointer" }}
        className="name-block align-items-center d-flex"
      >
        {!row.photo || imgArray.includes(row._id) ? (
          <span
            className={`name-ltr bg-${
              getNameAndUniqId(row.employeeName)?.uniqId
            }`}
          >
            {getNameAndUniqId(row.employeeName)?.initials}
          </span>
        ) : (
          <img
            className="img-fluid profile__picture"
            src={`https://erptest.spericorn.com/repo/uploads/${row.photo}`}
            alt="..."
            onError={(target) => handleOnError(target, row?._id)}
            onLoad={(target) => {
              target.target.style.display = "block";
            }}
          />
        )}
        <span className="name">{row.employeeName}</span>
      </div>
    );
  };

  const cols = [
    {
      name: "Name",
      selector: "employeeName",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      // icon: true,
      cell: (row) => renderProfileName(row),
      permission: hasPermission(permissionConstants.permissions.CLIENT_NAME),
    },
    {
      name: "Phone Number",
      selector: "contactNumber",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      permission: hasPermission(permissionConstants.permissions.CLIENT_PNO),
    },
    {
      name: "Email",
      selector: "email",
      activitySort: true,
      titleClasses: "left-txt",
      colClassName: "left-txt cron-status",
      contentClass: "tbl-cont",
      permission: hasPermission(permissionConstants.permissions.CLIENT_EMAIL),
    },
  ];

  const permissionBasedCol = cols.filter(({ permission }) => permission);

  return (
    <>
      <SubHeader showSearchBar />
      <CustomTable
        tableClassName="table td-center"
        divClassName="table-responsive custom-scroll emplyoee-bg"
        columns={permissionBasedCol}
        data={employeeList.data}
        count={employeeList.totalCount}
        //   onSortChange={(data) => {
        //     setSortOrder({
        //       ...sortOrder,
        //       [data]: sortOrder[data] === "1" ? "-1" : "1",
        //     });
        //     setSortBy(data);
        //   }}
        showPagination
        isLoading={isLoading}
        pagination={pagination}
        onPageChange={(pageNo) =>
          setPagination({ ...pagination, currentPage: pageNo })
        }
        onLimitChange={(limit) =>
          setPagination({ ...pagination, limit, currentPage: 1 })
        }
        // onSortChange={(selector) => {
        //   setSortingOrder(selector);
      />
    </>
  );
};

export default withTitle(ClientManagement);
