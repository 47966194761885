const serviceEndpoints = {
  signin: "auth/sign-in/google",
  activityLog: "api/activitylog",
  department: "/department",
  role: "/role",
  permissionslist: "/permissions",
  technology: "technology",
};

export default serviceEndpoints;
