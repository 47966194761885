/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useParams } from "react-router-dom";
import { isEqual } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import CustomTable from "../../../../components/CustomTable";
import { TimerIco } from "./svgIcons";
import { useThunk } from "../../../../hooks/useThunk";
import { getTeam } from "../../../../store/slices/team/thunks/getTeam";
import { patchHour } from "../../../../store/slices/team/thunks/patchHour";
import { cols } from "./constants";
import { useFilter } from "../../../../context/useFilter";
import { filterQuery } from "./filter";
import { getNameAndUniqId } from "../../../../components/common/iconDefaultName";
import { userProfileActions } from "../../../../store/slices/userProfile";
import { getMultyUserProfile } from "../../../../store";

function List() {
  const { shortCode } = useParams();
  const dispatch = useDispatch();

  const { filter } = useFilter();

  const [team, isLoading] = useThunk(getTeam);
  const [editHour] = useThunk(patchHour);
  const { projectData } = useSelector(({ project }) => project);
  const [sortOrders, setSortOrders] = useState({
    user: "",
    role: "",
    allocationHours: "",
    completed: "",
    todo: "",
    inprogress: "",
    readyforQA: "",
    ticketsFirstApproved: "",
    rejectionCount: "",
    performanceIndex: "",
    qualityIndex: "",
  });
  const matches = filterQuery(filter.filter);
  const [sortBy, setSortBy] = useState(null);
  const [imgArray, setimgArray] = useState([]);
  const { teamData } = useSelector((state) => state.team);
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
  });
  const { currentPage: page, limit = 10 } = paginationData;
  const [projectId, setProjectId] = useState(null);
  const [editStates, setEditStates] = useState({});
  const [newAllocationHours, setNewAllocationHours] = useState({});

  // const toggleEdit = (userId) => {
  //   setEditStates((prevEditStates) => ({
  //     ...prevEditStates,
  //     [userId]: !prevEditStates[userId],
  //   }));
  // };

  const handleChange = (userId, value) => {
    setNewAllocationHours((prevHours) => ({
      ...prevHours,
      [userId]: value,
    }));
  };

  const handleSubmit = (userId, e, allocationHours) => {
    if (e.key === "Enter" || e.type === "blur") {
      const parsedAllocationHours = parseFloat(newAllocationHours[userId]) || 0;
      if (parsedAllocationHours !== allocationHours) {
        editHour({
          projectId,
          userId: userId?.userId,
          patchData: parsedAllocationHours,
        });
      }
      setEditStates({});
    }
  };

  const handleHourClick = (userId, allocationHours) => {
    setEditStates(userId);
    setNewAllocationHours({
      [userId]: allocationHours || newAllocationHours[userId],
    });
  };
  const initialParams = {
    page,
    limit,
    sortOrder: sortOrders[sortBy] ? sortOrders[sortBy] : "",
    sortBy: sortOrders[sortBy] ? sortBy : "",
    search: filter.search.trim(),
    filter: matches || {},
  };
  const [params, setParams] = useState(initialParams);
  const previousMatchesRef = useRef(matches);
  const userProfileData = (e, id) => {
    e.stopPropagation();
    getMultyUsers(id);
    dispatch(userProfileActions.setShowUserProfile(true)); // Open the profile modal
  };
  useEffect(() => {
    if (!isEqual(matches, previousMatchesRef.current)) {
      setParams({ ...params, matches });
      previousMatchesRef.current = matches;
    }
  }, [matches]);

  useEffect(() => {
    if (projectData)
      setProjectId(
        projectData.find((each) => each.shortCode === shortCode)?._id,
      );
  }, [projectData]);

  useEffect(() => {
    if (paginationData.currentPage !== 1)
      setPaginationData({ ...paginationData, currentPage: 1 });
  }, [sortBy, sortOrders]);

  useEffect(() => {
    if (projectId)
      team({
        ...initialParams,
        projectId,
      });
  }, [
    paginationData.currentPage,
    paginationData.limit,
    sortBy,
    sortOrders,
    params,
    projectId,
  ]);

  useEffect(() => {
    if (projectId) {
      if (paginationData?.currentPage > 1) {
        setPaginationData({ ...paginationData, currentPage: 1 });
      } else {
        team({
          ...initialParams,
          projectId,
        });
      }
    }
  }, [filter.search]);

  const formulas = {
    Name: "Name",
    Role: "Role",
    Hours: "Allocated Hours",
    "Last Worked On": "last updated date on tickets in the list ",
    "Tickets Taken":
      "count of  tickets in todo, inprogress, completed and readyforQA",
    "Tickets Completed": "count of tickets in completed status",
    "Tickets To Do": "count of tickets in toDo status",
    "Tickets In-progress": "Count of tickets in in-progress status",
    "Tickets Under Review": "count of tickets in ready-for-QA status",
    "Tickets First Approved": "count of first approved tickets ",
    "Tickets Rejection Count": "count of rejected tickets",
    "Performance Index":
      "(Total No. of Tickets First Approved /Total Ticket) * 100",
    "Quality Index":
      "((Completed Count - Total tickets rejected) / Completed count) * 100 ",
  };
  const renderHour = ({ row }) => {
    const userId = row;
    return (
      <div className="align-items-center d-flex">
        {editStates?.userId === userId?.userId ? (
          <input
            type="number"
            value={newAllocationHours[userId] || ""}
            onChange={(e) => handleChange(userId, e.target.value)}
            onBlur={(e) => handleSubmit(userId, e, row.allocationHours)}
            onKeyDown={(e) => handleSubmit(userId, e, row.allocationHours)}
            className="form-control edit-hour"
          />
        ) : (
          <div onClick={() => handleHourClick(userId, row.allocationHours)}>
            <span className="timer">
              <TimerIco />
            </span>
            {row.allocationHours}
          </div>
        )}
      </div>
    );
  };
  const renderLastWorkedOn = ({ row }) => {
    if (row.lastWorked === null) {
      return "-";
    }

    const options = {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedLastWorked = new Date(row.lastWorked).toLocaleString(
      "en-US",
      options,
    );

    return <span className="name text-nowrap">{formattedLastWorked}</span>;
  };
  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };
  const renderImage = ({ row }) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        className="name-block align-items-center d-flex"
        onClick={(e) => userProfileData(e, row?.userId)}
      >
        {row?.photo && !imgArray?.includes(row?.userId) ? (
          <img
            onError={(target) => handleOnError(target, row?.userId)}
            className="name-ltr"
            src={`https://erptest.spericorn.com/repo/uploads/${row.photo}`}
            alt="profile pic"
          />
        ) : (
          <span className={`name-ltr bg-${getNameAndUniqId(row?.user).uniqId}`}>
            {getNameAndUniqId(row?.user).initials}
          </span>
        )}
        <span className="name text-nowrap">{row.user}</span>
      </div>
    );
  };

  const renderRole = ({ row }) => {
    return <span className="name text-nowrap">{row.roleName}</span>;
  };
  const renderHeadingWithTooltip = (name) => {
    const tooltipText = formulas[name] || "";

    const tooltip = <Tooltip id={`tooltip-${name}`}>{tooltipText}</Tooltip>;

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <span>{name}</span>
      </OverlayTrigger>
    );
  };
  const renderColor = ({ row }) => {
    let color = ""; // Default color

    if (row.qualityIndex < 50 || row.performanceIndex < 50) {
      color = "red"; // Set color to red if qualityIndex is less than 50
    } else if (row.qualityIndex > 75 || row.performanceIndex < 75) {
      color = "orange"; // Set color to orange if qualityIndex is greater than 75
    } else {
      color = "green"; // Set color to green for other cases
    }

    return (
      <span style={{ color }}>{row.qualityIndex || row.performanceIndex}</span>
    );
  };
  const setSortingOrder = (selector) => {
    const newSortOrders = { ...sortOrders };

    if (newSortOrders[selector]) {
      newSortOrders[selector] = newSortOrders[selector] === "asc" ? "desc" : "";
    } else {
      newSortOrders[selector] = "asc";
    }

    setSortOrders(newSortOrders);
    setSortBy(selector);
  };
  const enhancedCols = cols.map((col) => {
    const enhancedCol = { ...col, name: renderHeadingWithTooltip(col.name) };

    if (col.selector === "allocationHours") {
      enhancedCol.cell = renderHour;
    } else if (col.selector === "user") {
      enhancedCol.cell = renderImage || col.cell;
    } else if (col.selector === "roleName") {
      enhancedCol.cell = renderRole;
    } else if (col.selector === "lastWorked") {
      enhancedCol.cell = renderLastWorkedOn;
    } else if (col.selector === "performanceIndex") {
      enhancedCol.cell = renderColor;
    } else if (col.selector === "qualityIndex") {
      enhancedCol.cell = renderColor;
    }
    return enhancedCol;
  });

  const tableClassName = "table custom-table text-left";
  const divClassName = "table-responsive table-min-h custom-scroll";
  const onLimitChange = (newLimit) => {
    setPaginationData({
      ...paginationData,
      limit: newLimit,
      currentPage: 1,
    });
  };
  return (
    <CustomTable
      isLoading={isLoading}
      pagination={paginationData}
      columns={enhancedCols}
      data={teamData?.projectAggregation}
      count={teamData?.totalAllocatedUsers}
      tableClassName={tableClassName}
      divClassName={divClassName}
      showPagination
      onLimitChange={onLimitChange}
      onPageChange={(currentPage) =>
        setPaginationData({ ...paginationData, currentPage })
      }
      onSortChange={(selector) => {
        setSortingOrder(selector);
      }}
    />
  );
}

export default List;
