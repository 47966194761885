import { createSlice } from "@reduxjs/toolkit";

export const name = "jokes";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.jokesData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setJokesData = (state, action) => {
    state.jokesData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };

  return {
    setJokesData,
    setErrorMesage,
    resetErrorMesage,
  };
};

function createInitialState() {
  return {
    jokesData: [],
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const jokesActions = slice.actions;
export const jokesReducer = slice.reducer;
