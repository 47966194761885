import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { emailAccountActions } from "..";
import emailAccountServices from "../../../../../services/emailAccountServices";
import { alertActions } from "../../../alert";

export const getEmailAccountList = createAsyncThunk(
  "list-emailAccount",
  async (params, { dispatch }) => {
    console.log("dfparams", params);
    dispatch(alertActions.clear());

    try {
      const { data } = await emailAccountServices.getEmailAccountList(params);

      dispatch(
        emailAccountActions.setEmailAccountList({
          data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
