import { createSlice } from "@reduxjs/toolkit";

export const name = "leaveReducer";

function createInitialState() {
  return {
    leaveData: null,
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const leaveSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLeave: (state, { payload }) => {
      state.leaveData = payload.data;
    },
  },
});

export const leaveActions = leaveSlice.actions;
export const leaveReducer = leaveSlice.reducer;

export default leaveReducer;
