import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { postDocuments } from "../../../../services/documentService";
import { documentActions } from "..";

export const postUserDocuments = createAsyncThunk(
  "documents/post",
  async (updatedValue, { dispatch }) => {
    try {
      const { data } = await postDocuments(updatedValue);
      dispatch(alertActions.success(data.message));
      dispatch(documentActions.postDocumentData(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
