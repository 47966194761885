import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import Select from "react-select";
import { useThunk } from "../../../../../hooks/useThunk";
import CustomModal from "../../../../../components/CustomModal";
import { getGitRepos } from "../../../../../store/slices/gitIntegration/thunks/getGitRepos";
import { getGitBranchs } from "../../../../../store/slices/gitIntegration/thunks/getBranchs";
import { postCreatePull } from "../../../../../store/slices/gitIntegration/thunks/postCreatePullRequest";
import { getUserRepos } from "../../../../../store/slices/gitIntegration/thunks/getUserRepos";
import { gitHubAction } from "../../../../../store/slices/gitIntegration";

// CreatePr Component definition
export const CreatePrModal = ({ setOpenCreatePr, projectId, ticket }) => {
  const dispatch = useDispatch();
  const [getGitHubRepos] = useThunk(getGitRepos);
  const [getGitHubBranches, isLoadingBranche] = useThunk(getGitBranchs);
  const [createrpullreuest, isLoading] = useThunk(postCreatePull);
  const [userdata] = useThunk(getUserRepos);

  const [owner, setowner] = useState("");

  useEffect(() => {
    getGitHubRepos();
    userdata(projectId);
  }, [getGitHubRepos, projectId, userdata]);

  const { getAllBranchsData, userRepositories, getCreateGitPr } = useSelector(
    (state) => state.github,
  );

  const listRepos = userRepositories?.map((item) => ({
    value: item?.repository?.repoName,
    label: item?.repository?.repoName,
    id: item._id,
    owner: item?.repository?.owner,
  }));

  const options = listRepos?.map((repo) => ({
    value: repo.value,
    label: repo.label,
    owner: repo.owner,
  }));

  const branoptions = (getAllBranchsData || []).map((item) => ({
    value: item.name,
    label: item.name,
  }));

  useEffect(() => {
    if (getCreateGitPr) {
      setOpenCreatePr(false);
      dispatch(gitHubAction.resetCreatePr());
    }
  }, [getCreateGitPr]);

  const areAllFieldsFilled = (values) => {
    return (
      values.pullRequestTitle &&
      values.selectedRepo &&
      values.selectedSourceBranch &&
      values.selectedTargetBranch &&
      values.description
    );
  };

  const onSubmit = (values) => {
    if (values) {
      const postdata = {
        repoName: values.selectedRepo,
        branchName: values.selectedTargetBranch,
        baseBranch: values.selectedSourceBranch,
        title: values.pullRequestTitle,
        body: values.description,
        owner,
        projectId,
        ticket,
      };
      createrpullreuest(postdata);
    }
  };

  const renderHeader = () => <h4 className="modal-title">New Pull Request</h4>;

  // func to render modal
  const renderBody = () => (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        pullRequestTitle: "",
        selectedRepo: "",
        selectedSourceBranch: "",
        selectedTargetBranch: "",
        description: "",
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="create-sprint-modal-body">
            <div className="modal-body-title" />
            <div className="form-cover">
              {/* Pull Request Title */}
              <div className="inp-block alert-cover">
                <label htmlFor="calSprint" className="form-label">
                  Pull Request Title
                </label>
                <Field
                  name="pullRequestTitle"
                  render={({ input }) => (
                    <input
                      {...input}
                      type="text"
                      className="form-control"
                      id="calSprint"
                      placeholder="Add pr title"
                    />
                  )}
                />
              </div>

              {/* Repository Selection */}
              <div className="inp-block form-group alert-cover">
                <label htmlFor="" className="form-label">
                  Repository
                </label>
                <Field
                  name="selectedRepo"
                  render={({ input }) => (
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container"
                      id="repository"
                      value={options.find(
                        (option) => option.value === input.value,
                      )}
                      onChange={(selectedpr) => {
                        const selectedValue = selectedpr;
                        const selectedRepo = listRepos.find(
                          (repo) => repo.value === selectedValue?.value,
                        );
                        if (selectedRepo) {
                          const data = {
                            value: selectedValue?.value,
                            owner: selectedRepo?.owner,
                          };
                          getGitHubBranches(data);
                          setowner(selectedRepo?.owner);
                          input.onChange(selectedValue?.value);
                        } else {
                          setowner(null);
                          input.onChange("");
                        }
                      }}
                      options={options}
                    />
                  )}
                />
              </div>

              {/* Source Branch */}
              <Field
                name="selectedSourceBranch"
                render={({ input }) => (
                  <div className="inp-block form-group alert-cover">
                    <label htmlFor="" className="form-label">
                      Source Branch
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container"
                      id="sourceBranch"
                      value={branoptions.find(
                        (option) => option.value === input.value,
                      )}
                      onChange={(selectedOption) => {
                        input.onChange(selectedOption.value);
                      }}
                      options={branoptions}
                      isLoading={isLoadingBranche}
                    />
                  </div>
                )}
              />

              {/* Target Branch */}
              <Field
                name="selectedTargetBranch"
                render={({ input }) => (
                  <div className="inp-block form-group alert-cover">
                    <label htmlFor="" className="form-label">
                      Target Branch
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container"
                      id="targetBranch"
                      value={branoptions.find(
                        (option) => option.value === input.value,
                      )}
                      onChange={(selectedOption) => {
                        input.onChange(selectedOption.value);
                      }}
                      options={branoptions.filter(
                        (item) => item.value !== values.selectedSourceBranch,
                      )}
                      placeholder="Select Target Branch"
                      isDisabled={options.length < 1}
                      isSearchable={false}
                      noOptionsMessage={() => "No available target branches"}
                      isLoading={isLoadingBranche}
                    />
                  </div>
                )}
              />

              {/* Description */}
              <Field
                name="description"
                render={({ input }) => (
                  <div className="inp-block form-group alert-cover">
                    <label className="form-label">Description</label>
                    <textarea
                      {...input}
                      className="form-control"
                      placeholder="Add your Comments"
                    />
                  </div>
                )}
              />

              {/* Buttons */}
              <div className="btn-block">
                <button
                  className="btn-primary btn-cancel"
                  type="button"
                  onClick={() => setOpenCreatePr(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn-primary btn-create"
                  type="submit"
                  disabled={!areAllFieldsFilled(values)}
                  style={{
                    cursor: areAllFieldsFilled(values)
                      ? "pointer"
                      : "not-allowed",
                  }}
                >
                  {isLoading ? (
                    <span className="btn-loader">
                      <span className="spinner-border spinner-border-sm" />
                    </span>
                  ) : (
                    "Create Pull Request"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );

  return (
    <CustomModal
      className="modal fade modal-default create-branch-modal new-pull-req"
      dialgName="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
      bodyClassname="modal-body custom-scroll"
      header={renderHeader()}
      createModal={7}
      disableCenter={7}
      show={!!setOpenCreatePr}
      closeModal={() => {
        setOpenCreatePr(false);
      }}
      body={renderBody()}
    />
  );
};
CreatePrModal.propTypes = {
  showModal: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    docId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
CreatePrModal.propTypes = {
  setOpenCreatePr: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  ticket: PropTypes.string,
};
