export function DashboardIco() {
  return (
    <svg
      id="home"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Vector"
        d="M0,2V0"
        transform="translate(8 10)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M5.382.424l-4.62,3.7a2.249,2.249,0,0,0-.74,1.94l.887,5.307a2.127,2.127,0,0,0,2.027,1.713H10.4a2.139,2.139,0,0,0,2.027-1.713l.887-5.307a2.286,2.286,0,0,0-.74-1.94L7.955.431A2.15,2.15,0,0,0,5.382.424Z"
        transform="translate(1.331 1.456)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H16V16H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}

export function SettingsIco() {
  return (
    <svg
      id="setting-2"
      xmlns="http://www.w3.org/2000/svg"
      width="17.964"
      height="17.964"
      viewBox="0 0 17.964 17.964"
    >
      <path
        id="Vector"
        d="M4.491,2.245A2.245,2.245,0,1,1,2.245,0,2.245,2.245,0,0,1,4.491,2.245Z"
        transform="translate(6.736 6.736)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,7.724V6.406A1.426,1.426,0,0,1,1.422,4.984c1.355,0,1.909-.958,1.228-2.133A1.421,1.421,0,0,1,3.174.912L4.468.171A1.249,1.249,0,0,1,6.175.62l.082.142a1.3,1.3,0,0,0,2.463,0L8.8.62A1.249,1.249,0,0,1,10.509.171L11.8.912a1.421,1.421,0,0,1,.524,1.939c-.681,1.175-.127,2.133,1.228,2.133a1.426,1.426,0,0,1,1.422,1.422V7.724a1.426,1.426,0,0,1-1.422,1.422c-1.355,0-1.909.958-1.228,2.133a1.42,1.42,0,0,1-.524,1.939l-1.295.741A1.249,1.249,0,0,1,8.8,13.509l-.082-.142a1.3,1.3,0,0,0-2.463,0l-.082.142a1.249,1.249,0,0,1-1.707.449l-1.295-.741a1.421,1.421,0,0,1-.524-1.939c.681-1.175.127-2.133-1.228-2.133A1.426,1.426,0,0,1,0,7.724Z"
        transform="translate(1.497 1.917)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H17.964V17.964H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}

export function SearchIco() {
  return (
    <svg
      id="search-normal"
      xmlns="http://www.w3.org/2000/svg"
      width="11.621"
      height="11.621"
      viewBox="0 0 11.621 11.621"
    >
      <path
        id="Vector"
        d="M8.724,4.362A4.362,4.362,0,1,1,4.362,0,4.362,4.362,0,0,1,8.724,4.362Z"
        transform="translate(0.918 0.918)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M.918.918,0,0"
        transform="translate(9.642 9.642)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H11.02V11.02H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}

export function ArrowRightIco() {
  return (
    <svg
      id="arrow-right"
      xmlns="http://www.w3.org/2000/svg"
      width="13.156"
      height="13.156"
      viewBox="0 0 13.156 13.156"
    >
      <path
        id="Vector"
        d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
        transform="translate(4.884 2.236)"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0H13.156V13.156H0Z"
        transform="translate(13.156 13.156) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  );
}

export function AddIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.601"
      height="8.601"
      viewBox="0 0 8.601 8.601"
    >
      <path
        id="Icon_ionic-ios-add"
        data-name="Icon ionic-ios-add"
        d="M17.031,12.73H13.8V9.5a.535.535,0,1,0-1.071,0v3.23H9.5a.535.535,0,0,0,0,1.071h3.23v3.23a.535.535,0,1,0,1.071,0V13.8h3.23a.535.535,0,1,0,0-1.071Z"
        transform="translate(-8.965 -8.965)"
        fill="currentcolor"
      />
    </svg>
  );
}

export function DocsIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.345"
      height="15.345"
      viewBox="0 0 15.345 15.345"
    >
      <g
        id="vuesax_linear_task-square"
        data-name="vuesax/linear/task-square"
        transform="translate(-492 -316)"
      >
        <g id="task-square" transform="translate(492 316)">
          <path
            id="Vector"
            d="M0,0H3.357"
            transform="translate(7.909 5.678)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,.959l.48.48L1.918,0"
            transform="translate(4.079 4.719)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H3.357"
            transform="translate(7.909 10.153)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,.959l.48.48L1.918,0"
            transform="translate(4.079 9.194)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M4.476,12.787H8.312c3.2,0,4.476-1.279,4.476-4.476V4.476C12.787,1.279,11.509,0,8.312,0H4.476C1.279,0,0,1.279,0,4.476V8.312C0,11.509,1.279,12.787,4.476,12.787Z"
            transform="translate(1.279 1.279)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-6"
            data-name="Vector"
            d="M0,0H15.345V15.345H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
}

export function FolderIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.863"
      height="10.171"
      viewBox="0 0 11.863 10.171"
    >
      <g
        id="Icon_ionic-ios-folder-open"
        data-name="Icon ionic-ios-folder-open"
        transform="translate(-2.252 -4.5)"
      >
        <path
          id="Path_30"
          data-name="Path 30"
          d="M14.671,5.957a.584.584,0,0,0-.6-.609H8.921a.2.2,0,0,1-.162-.064l-.6-.6-.005-.005A.576.576,0,0,0,7.7,4.5H5.154a.63.63,0,0,0-.654.609V7.043H14.671Z"
          transform="translate(-1.401)"
          fill="#66bd50"
        />
        <path
          id="Path_31"
          data-name="Path 31"
          d="M3.1,13.219h-.22c-.339,0-.67.135-.622.644s.622,5.581.622,5.581c.072.471.31.662.662.662h9.336c.336,0,.556-.207.609-.662,0,0,.588-4.9.625-5.443s-.236-.781-.625-.781H3.1Z"
          transform="translate(0 -5.434)"
          fill="#66bd50"
        />
      </g>
    </svg>
  );
}

export function LogoIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.055"
      height="34.85"
      viewBox="0 0 35.055 34.85"
    >
      <g
        id="Group_5906"
        data-name="Group 5906"
        transform="translate(5.06 -69.302)"
      >
        <g id="spericorn-black-logo" transform="translate(-5.06 69.302)">
          <g id="Group_6457" data-name="Group 6457" transform="translate(0 0)">
            <g id="Group_6456" data-name="Group 6456">
              <path
                id="Path_2269"
                data-name="Path 2269"
                d="M129.884,76.178a17.258,17.258,0,0,0-12.521,5.085,16.619,16.619,0,0,0-5.171,12.2,16.909,16.909,0,0,0,5.171,12.47,17.243,17.243,0,0,0,12.521,5.085A17.4,17.4,0,0,0,147.242,93.8a17.182,17.182,0,0,0-5.09-12.452A16.693,16.693,0,0,0,129.884,76.178Zm-6.4,10.977a8.746,8.746,0,0,1,6.4-2.59,8.124,8.124,0,0,1,6.13,2.655A9.092,9.092,0,0,1,138.58,93.8a8.508,8.508,0,0,1-2.59,6.2,8.04,8.04,0,0,1-5.973,2.646,8.943,8.943,0,0,1-9.168-8.714q0-.229,0-.458A8.4,8.4,0,0,1,123.486,87.155Z"
                transform="translate(-112.189 -76.175)"
                fill="#06b2ff"
              />
              <path
                id="Path_2270"
                data-name="Path 2270"
                d="M132.111,89.652a6.382,6.382,0,1,1-6.382-6.382h0A6.383,6.383,0,0,1,132.111,89.652Z"
                transform="translate(-108.206 -72.227)"
                fill="#06b2ff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function NotifyIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="vuesax_linear_notification"
        data-name="vuesax/linear/notification"
        transform="translate(-171 -188)"
      >
        <g id="notification" transform="translate(171 188)">
          <path
            id="Vector"
            d="M5.364,0a4,4,0,0,0-4,4V5.927A3.186,3.186,0,0,1,.984,7.3L.217,8.573a1.279,1.279,0,0,0,.72,1.953,13.947,13.947,0,0,0,8.847,0,1.335,1.335,0,0,0,.72-1.953L9.737,7.3a3.274,3.274,0,0,1-.373-1.373V4A4.012,4.012,0,0,0,5.364,0Z"
            transform="translate(2.649 1.94)"
            fill="none"
            stroke="#53575e"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M2.467.84a4.036,4.036,0,0,0-.64-.133A4.5,4.5,0,0,0,0,.84a1.325,1.325,0,0,1,2.467,0Z"
            transform="translate(6.78 1.293)"
            fill="none"
            stroke="#53575e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M4,0A2.006,2.006,0,0,1,2,2,2.007,2.007,0,0,1,.587,1.413,2.007,2.007,0,0,1,0,0"
            transform="translate(6.013 12.707)"
            fill="none"
            stroke="#53575e"
            strokeWidth="1"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H16V16H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
}

export function ThreeDotIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="4"
      viewBox="0 0 18 4"
    >
      <g
        id="Group_59398"
        data-name="Group 59398"
        transform="translate(-1886.226 -68)"
      >
        <circle
          id="Ellipse_4"
          data-name="Ellipse 4"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1886.226 68)"
          fill="#868991"
        />
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1893.226 68)"
          fill="#868991"
        />
        <circle
          id="Ellipse_6"
          data-name="Ellipse 6"
          cx="2"
          cy="2"
          r="2"
          transform="translate(1900.226 68)"
          fill="#868991"
        />
      </g>
    </svg>
  );
}

export function PortfolioIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_58846"
        data-name="Group 58846"
        transform="translate(-20 -455.171)"
      >
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="24"
          height="24"
          rx="8"
          transform="translate(20 455.171)"
          fill="#ef934d"
        />
        <path
          id="Icon_awesome-project-diagram"
          data-name="Icon awesome-project-diagram"
          d="M7.407,6.172H4.938a.617.617,0,0,0-.617.617V9.259a.617.617,0,0,0,.617.617H7.407a.617.617,0,0,0,.617-.617V6.79A.617.617,0,0,0,7.407,6.172ZM3.7.617A.617.617,0,0,0,3.086,0H.617A.617.617,0,0,0,0,.617V3.086A.617.617,0,0,0,.617,3.7H2.464l1.411,2.47a1.23,1.23,0,0,1,1.063-.618h.005L3.7,3.385V2.469H8.024V1.234H3.7ZM11.728,0H9.259a.617.617,0,0,0-.617.617V3.086a.617.617,0,0,0,.617.617h2.469a.617.617,0,0,0,.617-.617V.617A.617.617,0,0,0,11.728,0Z"
          transform="translate(26 463.171)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export function ProjManageIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_58845"
        data-name="Group 58845"
        transform="translate(-304 -10)"
      >
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="24"
          height="24"
          rx="8"
          transform="translate(304 10)"
          fill="#ac80f7"
        />
        <g id="start-up" transform="translate(310 16)">
          <path
            id="Path_22"
            data-name="Path 22"
            d="M73.186,282.856a.351.351,0,0,0,.065.405l.086.086c.248-.294.537-.612.887-.962.025-.025.05-.037.075-.06l-.552-.552C73.427,282.353,73.24,282.75,73.186,282.856Zm0,0"
            transform="translate(-71.439 -275.185)"
            fill="#fff"
          />
          <path
            id="Path_23"
            data-name="Path 23"
            d="M163.851,389.637c-.351.351-.669.64-.961.888l.116.116a.35.35,0,0,0,.408.064c.182-.094.467-.228,1.074-.566l-.578-.578c-.023.025-.035.05-.06.075Zm0,0"
            transform="translate(-159.082 -380.454)"
            fill="#fff"
          />
          <path
            id="Path_24"
            data-name="Path 24"
            d="M62.777,311.41c-.253-.253-.966-.026-1.3.309a9.327,9.327,0,0,0-1.853,2.383.351.351,0,0,0,.469.468,9.394,9.394,0,0,0,2.376-1.859c.368-.368.55-1.06.309-1.3Zm0,0"
            transform="translate(-58.195 -304.024)"
            fill="#fff"
          />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M1.1,164.624l-.992.992a.351.351,0,0,0,.339.587,2.882,2.882,0,0,1,1.325-.123,18.82,18.82,0,0,1,1.452-2.215,2.143,2.143,0,0,0-2.124.759Zm0,0"
            transform="translate(-0.001 -159.954)"
            fill="#fff"
          />
          <path
            id="Path_26"
            data-name="Path 26"
            d="M245.833,379.623a.35.35,0,0,0,.406-.065l.992-.992a2.2,2.2,0,0,0,.729-2.074,20.021,20.021,0,0,1-2.216,1.441,4.3,4.3,0,0,1-.1,1.325A.35.35,0,0,0,245.833,379.623Zm0,0"
            transform="translate(-239.897 -367.69)"
            fill="#fff"
          />
          <path
            id="Path_27"
            data-name="Path 27"
            d="M284.951,137.616a1.052,1.052,0,1,0,1.488,0A1.053,1.053,0,0,0,284.951,137.616Zm0,0"
            transform="translate(-277.989 -134.098)"
            fill="#fff"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M390.657.343a.35.35,0,0,0-.324-.323,6.933,6.933,0,0,0-2.56.3,2.944,2.944,0,0,0,.872,1.713,2.935,2.935,0,0,0,1.747.871,7.252,7.252,0,0,0,.265-2.559Zm0,0"
            transform="translate(-378.707 -0.001)"
            fill="#fff"
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M121.109,25.961A3.684,3.684,0,0,1,120.054,24a12.716,12.716,0,0,0-5.715,5.4l.791.791a1.257,1.257,0,0,1,1.752,1.752l.817.817c2.039-1.229,4.412-3.164,5.421-5.74a3.667,3.667,0,0,1-2.013-1.056Zm-.5,2.976a1.754,1.754,0,1,1,0-2.48A1.756,1.756,0,0,1,120.612,28.937Zm0,0"
            transform="translate(-111.666 -23.435)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export function ProjectsIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="vuesax_linear_document-text"
        data-name="vuesax/linear/document-text"
        transform="translate(-492 -252)"
      >
        <g id="document-text" transform="translate(492 252)">
          <path
            id="Vector"
            d="M12,3.333V10a2.99,2.99,0,0,1-3.333,3.333H3.333A2.99,2.99,0,0,1,0,10V3.333A2.99,2.99,0,0,1,3.333,0H8.667A2.99,2.99,0,0,1,12,3.333Z"
            transform="translate(2 1.333)"
            fill="none"
            stroke="#53575e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0V1.333A1.337,1.337,0,0,0,1.333,2.667H2.667"
            transform="translate(9.667 3)"
            fill="none"
            stroke="#53575e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H2.667"
            transform="translate(5.333 8.667)"
            fill="none"
            stroke="#53575e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H5.333"
            transform="translate(5.333 11.333)"
            fill="none"
            stroke="#53575e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H16V16H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
}

export function ProposalIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_58847"
        data-name="Group 58847"
        transform="translate(-20 -495.171)"
      >
        <rect
          id="Rectangle_3460"
          data-name="Rectangle 3460"
          width="24"
          height="24"
          rx="8"
          transform="translate(20 495.171)"
          fill="#66bd50"
        />
        <path
          id="Icon_awesome-project-diagram"
          data-name="Icon awesome-project-diagram"
          d="M7.407,6.172H4.938a.617.617,0,0,0-.617.617V9.259a.617.617,0,0,0,.617.617H7.407a.617.617,0,0,0,.617-.617V6.79A.617.617,0,0,0,7.407,6.172ZM3.7.617A.617.617,0,0,0,3.086,0H.617A.617.617,0,0,0,0,.617V3.086A.617.617,0,0,0,.617,3.7H2.464l1.411,2.47a1.23,1.23,0,0,1,1.063-.618h.005L3.7,3.385V2.469H8.024V1.234H3.7ZM11.728,0H9.259a.617.617,0,0,0-.617.617V3.086a.617.617,0,0,0,.617.617h2.469a.617.617,0,0,0,.617-.617V.617A.617.617,0,0,0,11.728,0Z"
          transform="translate(26 503.171)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export function ReportsIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.346"
      height="14.346"
      viewBox="0 0 14.346 14.346"
    >
      <g id="graph" transform="translate(-2 -2)">
        <path
          id="Path_10402"
          data-name="Path 10402"
          d="M6.3,2A4.3,4.3,0,0,0,2,6.3v5.738a4.3,4.3,0,0,0,4.3,4.3h5.738a4.3,4.3,0,0,0,4.3-4.3V9.412a.478.478,0,0,0-.956,0v2.63a3.347,3.347,0,0,1-3.347,3.347H6.3a3.347,3.347,0,0,1-3.347-3.347V6.3A3.347,3.347,0,0,1,6.3,2.956h5.738A3.347,3.347,0,0,1,15.389,6.3v.717a.478.478,0,1,0,.956,0V6.3a4.3,4.3,0,0,0-4.3-4.3Z"
          fill="#53575e"
        />
        <path
          id="Path_10403"
          data-name="Path 10403"
          d="M30.956,17.478a.478.478,0,1,0-.956,0v5.977a.478.478,0,0,0,.956,0Z"
          transform="translate(-21.305 -11.414)"
          fill="#53575e"
        />
        <path
          id="Path_10404"
          data-name="Path 10404"
          d="M18.956,24.478a.478.478,0,0,0-.956,0v4.3a.478.478,0,0,0,.956,0Z"
          transform="translate(-12.174 -16.74)"
          fill="#53575e"
        />
        <path
          id="Path_10405"
          data-name="Path 10405"
          d="M42.956,32.478a.478.478,0,0,0-.956,0v2.391a.478.478,0,1,0,.956,0Z"
          transform="translate(-30.436 -22.827)"
          fill="#53575e"
        />
      </g>
    </svg>
  );
}

export function InviteUserIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_75" data-name="Group 75" transform="translate(-1380 -855)">
        <g
          id="Ellipse_7"
          data-name="Ellipse 7"
          transform="translate(1380 855)"
          fill="none"
          stroke="currentcolor"
          strokeWidth="0.8"
        >
          <circle cx="12" cy="12" r="12" stroke="none" />
          <circle cx="12" cy="12" r="11.6" fill="none" />
        </g>
        <g
          id="vuesax_outline_user-add"
          data-name="vuesax/outline/user-add"
          transform="translate(1385.596 860.341)"
        >
          <g id="user-add" transform="translate(0 0)">
            <path
              id="Vector"
              d="M3.035,6.07A3.035,3.035,0,1,1,6.07,3.035,3.039,3.039,0,0,1,3.035,6.07Zm0-5.278A2.243,2.243,0,1,0,5.278,3.035,2.248,2.248,0,0,0,3.035.792Z"
              transform="translate(3.299 0.66)"
              fill="currentcolor"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M.4,4.487a.4.4,0,0,1-.4-.4C0,1.837,2.212,0,4.93,0A5.75,5.75,0,0,1,6.492.211a.394.394,0,1,1-.211.76A5,5,0,0,0,4.93.792C2.65.792.792,2.27.792,4.091A.4.4,0,0,1,.4,4.487Z"
              transform="translate(1.404 7.522)"
              fill="currentcolor"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M2.507,5.014A2.481,2.481,0,0,1,.834,4.365,2.26,2.26,0,0,1,.359,3.8,2.5,2.5,0,0,1,.707.76,2.5,2.5,0,0,1,4.37.834a2.5,2.5,0,0,1,.644,1.673,2.218,2.218,0,0,1-.079.591,2.25,2.25,0,0,1-.29.7A2.483,2.483,0,0,1,2.507,5.014Zm0-4.223A1.712,1.712,0,0,0,.792,2.507a1.672,1.672,0,0,0,.248.881,1.533,1.533,0,0,0,.322.391,1.691,1.691,0,0,0,1.145.449A1.719,1.719,0,0,0,3.975,3.4a1.644,1.644,0,0,0,.2-.48,1.535,1.535,0,0,0,.053-.406,1.711,1.711,0,0,0-.443-1.145A1.667,1.667,0,0,0,2.507.792Z"
              transform="translate(6.994 6.994)"
              fill="currentcolor"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M1.974.792H.4A.4.4,0,0,1,0,.4.4.4,0,0,1,.4,0H1.974a.4.4,0,0,1,.4.4A.4.4,0,0,1,1.974.792Z"
              transform="translate(8.319 9.095)"
              fill="currentcolor"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M.4,2.37a.4.4,0,0,1-.4-.4V.4A.4.4,0,0,1,.4,0a.4.4,0,0,1,.4.4V1.974A.4.4,0,0,1,.4,2.37Z"
              transform="translate(9.105 8.324)"
              fill="currentcolor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function FlagOuLline() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.099"
      height="14.53"
      viewBox="0 0 12.099 14.53"
    >
      <path
        id="Icon_ionic-md-flag"
        data-name="Icon ionic-md-flag"
        d="M9.956,5.5a8.036,8.036,0,0,0-2.206.239V11.32l.169-.021c.426-.053,1.447-.094,2.041-.094a8.954,8.954,0,0,1,2.308.386c.314.082.61.16.908.22l.066.013a15.137,15.137,0,0,0,3.021.4,11.826,11.826,0,0,0,1.452-.113l.134-.016V6.264a13.114,13.114,0,0,1-1.632.12,13.043,13.043,0,0,1-2.791-.379c-.163-.035-.327-.07-.491-.1l-.008,0a14.929,14.929,0,0,0-2.972-.4m0-1a15.728,15.728,0,0,1,3.18.423,15.1,15.1,0,0,0,3.082.461,14.883,14.883,0,0,0,1.875-.159c.284-.034.567-.076.756-.113v7.861c-.227.038-.473.068-.756.1a15.006,15.006,0,0,1-1.83.151,16.217,16.217,0,0,1-3.286-.435,15.3,15.3,0,0,0-3.017-.586c-.567,0-1.552.041-1.918.086V19.03l-.535-.01H6.75V5.116l.159-.11A6.031,6.031,0,0,1,9.956,4.5Z"
        transform="translate(-6.75 -4.5)"
        fill="currentcolor"
      />
    </svg>
  );
}

export function CheckImg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.319"
      height="9.186"
      viewBox="0 0 12.319 9.186"
    >
      <path
        id="Icon_awesome-check"
        data-name="Icon awesome-check"
        d="M4.184,13.583l-4-4a.616.616,0,0,1,0-.871l.871-.871a.616.616,0,0,1,.871,0l2.7,2.7L10.4,4.758a.616.616,0,0,1,.871,0l.871.871a.616.616,0,0,1,0,.871L5.055,13.583A.616.616,0,0,1,4.184,13.583Z"
        transform="translate(0 -4.577)"
        fill="currentcolor"
      />
    </svg>
  );
}

export function StarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.645"
      height="16.645"
      viewBox="0 0 16.645 16.645"
    >
      <g
        id="Group_59436"
        data-name="Group 59436"
        transform="translate(-326 -716)"
      >
        <path
          id="Ellipse_33"
          data-name="Ellipse 33"
          d="M8.323.832a7.49,7.49,0,1,0,7.49,7.49A7.5,7.5,0,0,0,8.323.832m0-.832A8.323,8.323,0,1,1,0,8.323,8.323,8.323,0,0,1,8.323,0Z"
          transform="translate(326 716)"
          fill="currentcolor"
        />
        <path
          id="Icon_feather-star"
          data-name="Icon feather-star"
          d="M8.374,2.5a.416.416,0,0,1,.373.232l1.59,3.221,3.555.52a.416.416,0,0,1,.23.71L11.55,9.687l.607,3.539a.416.416,0,0,1-.6.439L8.374,11.993,5.195,13.665a.416.416,0,0,1-.6-.439L5.2,9.687,2.626,7.182a.416.416,0,0,1,.23-.71l3.555-.52L8,2.732A.416.416,0,0,1,8.374,2.5Zm4.564,4.674L10,6.745a.416.416,0,0,1-.313-.228L8.374,3.856,7.061,6.517a.416.416,0,0,1-.313.228L3.81,7.174l2.125,2.07a.416.416,0,0,1,.12.368l-.5,2.924L8.18,11.155a.416.416,0,0,1,.387,0l2.627,1.381-.5-2.924a.416.416,0,0,1,.12-.368Z"
          transform="translate(325.948 715.798)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}

export function TimerIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.492"
      height="13.255"
      viewBox="0 0 11.492 13.255"
    >
      <g id="timer" transform="translate(-2.207 -1.325)">
        <path
          id="Vector"
          d="M5.746,0a5.749,5.749,0,1,0,5.746,5.752A5.753,5.753,0,0,0,5.746,0Zm.5,5.534a.5.5,0,0,1-.994,0V2.22a.5.5,0,1,1,.994,0Z"
          transform="translate(2.207 3.082)"
          fill="currentcolor"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M4.308.961H.477A.475.475,0,0,1,0,.484.481.481,0,0,1,.477,0H4.308a.475.475,0,0,1,.477.477A.481.481,0,0,1,4.308.961Z"
          transform="translate(5.56 1.325)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}

export function CloseIco(CloseButton) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.457"
      height="10.458"
      viewBox="0 0 10.457 10.458"
      onClick={() => CloseButton()}
    >
      <path
        id="close"
        d="M12.5,11.529l4.054-4.054a.688.688,0,1,0-.973-.973l-4.054,4.054L7.475,6.5a.688.688,0,0,0-.973.973l4.054,4.054L6.5,15.583a.688.688,0,1,0,.972.973L11.528,12.5l4.054,4.054a.688.688,0,0,0,.972-.973L12.5,11.529Z"
        transform="translate(-6.3 -6.3)"
        fill="currentcolor"
      />
    </svg>
  );
}

export function Pencil() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.621"
      height="9.621"
      viewBox="0 0 9.621 9.621"
    >
      <path
        id="Icon_feather-edit-2"
        data-name="Icon feather-edit-2"
        d="M9.434,1.757a1.687,1.687,0,0,1,1.193,2.881l-5.95,5.95a.441.441,0,0,1-.2.114l-2.424.661a.441.441,0,0,1-.541-.541L2.177,8.4a.441.441,0,0,1,.114-.2l5.95-5.95A1.676,1.676,0,0,1,9.434,1.757Zm-5.3,8.125L10,4.015a.806.806,0,1,0-1.14-1.14L3,8.743,2.569,10.31Z"
        transform="translate(-1.5 -1.757)"
        fill="currentcolor"
      />
    </svg>
  );
}

export function SortingIco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.308"
      height="8.776"
      viewBox="0 0 6.308 8.776"
    >
      <path
        id="Icon_awesome-sort"
        data-name="Icon awesome-sort"
        d="M1.721,9.1H6.968a.53.53,0,0,1,.375.9L4.719,12.625a.527.527,0,0,1-.747,0L1.346,10A.53.53,0,0,1,1.721,9.1ZM7.343,6.783,4.719,4.16a.527.527,0,0,0-.747,0L1.346,6.783a.53.53,0,0,0,.375.9H6.968A.53.53,0,0,0,7.343,6.783Z"
        transform="translate(-1.191 -4.004)"
        fill="currentcolor"
      />
    </svg>
  );
}

export const AddDownArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.156"
    height="13.156"
    viewBox="0 0 13.156 13.156"
  >
    <g
      id="vuesax_linear_arrow-right"
      data-name="vuesax/linear/arrow-right"
      transform="translate(265.156 -300) rotate(90)"
    >
      <g id="arrow-right" transform="translate(300 252)">
        <path
          id="Vector"
          d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
          transform="translate(4.884 2.236)"
          fill="none"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H13.156V13.156H0Z"
          transform="translate(13.156 13.156) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
);
