/* eslint-disable no-restricted-globals */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { withTitle } from "../../../hoc/withTitle";
import { meetingScheduleActions } from "../../../store/slices/meetingSchedule";
import "../../../design/custom/pages/conf_hall_booking.scss";
import ScheduleList from "./ScheduleList";
import Plus from "../../../assets/images/add-plus.svg";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import { useThunk } from "../../../hooks/useThunk";
import { getMeetingData } from "../../../store/slices/meetingSchedule/thunks/getMeeting";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import { filterQuery } from "./meetingFilter";
import { useFilter } from "../../../context/useFilter";
import useElementScrollPosition from "../../../components/Feed/scroll";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

function MeetingSchedule() {
  const { meetingData, meetingTotalCount, successData } = useSelector(
    (state) => state.meeting,
  );

  const [filterData] = useThunk(getFilterDropdown);

  const [getMeeting, meetingLoading] = useThunk(getMeetingData);

  const dispatch = useDispatch();
  // search query
  const [params, setParams] = useState({});

  const { filter } = useFilter();

  const matches = filterQuery(filter.filter);

  const previousMatchesRef = useRef(matches);

  const scroll = useElementScrollPosition("page-contents", ".cover__inner");

  const [pagination, setPagination] = useState({ page: 1, limit: 6 });
  const [showCompleted, setShowCompleted] = useState(false);
  const [meetingUpdatedData, setmeetingUpdatedData] = useState([]);

  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.WIDGETS,
        permissionConstants.submodules.MEETING_SCHEDULER,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  useEffect(() => {
    if (!isEqual(matches, previousMatchesRef.current)) {
      setParams({ ...params, matches });
      previousMatchesRef.current = matches;
    }
  }, [matches]);

  const completeFunction = () => {
    setShowCompleted(!showCompleted);
  };

  useEffect(() => {
    let updatedParams = { ...params };
    updatedParams = { ...updatedParams, me: filter.me };

    updatedParams = { ...updatedParams, search: filter.search };

    if (filter.role) updatedParams = { ...updatedParams, role: filter.role };
    if (filter.project)
      updatedParams = { ...updatedParams, role: filter.project };

    setParams(updatedParams);
    setPagination({ page: 1, limit: 6 });
  }, [filter.search, filter.me, filter.assignee, filter.role]);

  useEffect(() => {
    filterData("meeting");
  }, []);

  useEffect(() => {
    const { data, type } = successData;
    if (data) {
      if (type === "post") {
        setmeetingUpdatedData([data, ...meetingUpdatedData]);
      }
      if (type === "patch") {
        setmeetingUpdatedData((prevData) => {
          return prevData?.map((e) => {
            if (e?._id === data?._id) {
              return data;
            }
            return e;
          });
        });
      }

      if (type === "delete") {
        setmeetingUpdatedData((prevData) => {
          return prevData?.filter((q) => q && q._id !== data._id);
        });
      }
    }
  }, [successData.data, successData.type]);

  useEffect(() => {
    if (meetingData && meetingUpdatedData) {
      // Check if all elements in meetingData are already present in meetingUpdatedData
      const newData = meetingData.filter(
        (e) => !meetingUpdatedData?.some((item) => item?._id === e?._id),
      );

      if (newData?.length > 0) {
        setmeetingUpdatedData([...meetingUpdatedData, ...newData]);
      }
    }
  }, [meetingData]);

  // scroll
  useEffect(() => {
    if (
      scroll >= 0 &&
      scroll <= 1 &&
      scroll !== null &&
      scroll &&
      meetingTotalCount !== meetingUpdatedData?.length &&
      !params.search
    ) {
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }, [scroll]);

  useEffect(() => {
    if (!showCompleted)
      if (
        typeof pagination?.page === "number" &&
        !isNaN(pagination?.page) &&
        scroll !== null &&
        meetingTotalCount !== meetingUpdatedData?.length &&
        scroll >= 0
      ) {
        getMeeting({
          params: {
            page: pagination.page,
            limit: pagination.limit,
            me: params.me,
            search: params.search,
            matches: params.matches,
            sort: "complete",
          },
          reset:
            params?.search || showCompleted || params.matches
              ? true
              : pagination?.page === 1,
        });
      } else {
        getMeeting({
          params: {
            page: 1,
            limit: 6,
            me: params.me,
            search: params.search,
            matches: params.matches,
            sort: "complete",
          },
          reset: true,
        });
      }
  }, [pagination?.page, params, showCompleted, meetingTotalCount]);

  useEffect(() => {
    setmeetingUpdatedData([]);
    if (showCompleted) {
      getMeeting({
        params: {
          page: 1,
          limit: 100,
          me: params.me,
          search: params.search,
          matches: params.matches,
          sort: "complete",
        },
        reset:
          params?.search || showCompleted || params.matches
            ? true
            : pagination?.page === 1,
      });
    }
    setPagination({ page: 1, limit: 6 });
  }, [showCompleted, params]);

  return (
    <>
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.MEETING_SCHEDULER_SEARCH,
        )}
        showFilter={hasPermission(
          permissionConstants.permissions.MEETING_SCHEDULER_FILTER,
        )}
        showMe={hasPermission(
          permissionConstants.permissions.MEETING_SCHEDULER_ME_FILTER,
        )}
        showCompletedIcon={hasPermission(
          permissionConstants.permissions.MEETING_SCHEDULER_COMPLETED,
        )}
        completeFunction={completeFunction}
        showCompleted={showCompleted}
      />

      {hasPermission(
        permissionConstants.permissions.MEETING_SCHEDULER_UPCOMING_SCHEDULE,
      ) && (
        <ScheduleList
          data={meetingUpdatedData}
          isLoading={meetingLoading}
          isScrollEnd={meetingTotalCount}
          showCompleted={showCompleted}
          params={params}
          setPagination={setPagination}
          hasPermission={hasPermission}
        />
      )}
      <div className="btn-float d-flex flex-row-reverse">
        {hasPermission(
          permissionConstants.permissions.MEETING_SCHEDULER_ADD,
        ) && (
          <div className="dropup addtask-dp order-1">
            <button
              type="button"
              className="btn add-task me-1"
              onClick={() => {
                dispatch(meetingScheduleActions.setShowMeetingSlider(true));
              }}
            >
              <span>
                <img src={Plus} alt="" />
              </span>
              Create
            </button>
          </div>
        )}
      </div>
    </>
  );
}
export default withTitle(MeetingSchedule);
