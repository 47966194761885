import _ from "lodash";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setStatusData } from "../index";
import ticketService from "../../../../services/ticketService";
import { alertActions } from "../../alert";

export const getTicketStatus = createAsyncThunk(
  "get/ticketStatus",
  async (params, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await ticketService.getStatus(params);
      dispatch(
        setStatusData({
          data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
