import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { postTicketAllocationData } from "../../../../services/ticketAllocationService";
import { alertActions } from "../../alert";
import { getTicketsById } from "./getTicketsById";
import { getTicketAllocations } from "./getTicketAllocations";
import { getTicket } from "./getTicket";

export const postTicketAllocation = createAsyncThunk(
  "post/ticketAllocation",
  async ({ value, listId, key }, { dispatch }) => {
    try {
      const {
        data: { message: successMessage },
      } = await postTicketAllocationData(value);

      dispatch(getTicketsById({ params: value.ticket, listId }));
      dispatch(getTicket(listId));
      dispatch(
        getTicketAllocations({
          params: listId,
          key: key ?? "list",
          reset: true,
        }),
      );

      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
