import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import jokesServices from "../../../../services/jokesService";
import { jokesActions } from "../index";

export const getJokes = createAsyncThunk(
  "jokes",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await jokesServices.jokes(payload);
      dispatch(jokesActions.setJokesData(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(jokesActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
