/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
// import { useParams } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Accordion, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import greenArrowDown from "../../../assets/images/arrow_circle_down_grey.svg";
import arrowRightIcon from "../../../assets/images/arrow_right__grey.svg";
import NoAllocationModal from "./Modal/NoAllocationModal";

// import comments from "../../../assets/images/comment-01.svg";
// import commentGreen from "../../../assets/images/comment-green.svg";
import otherProjects from "../../../assets/images/other_projects.svg";
import OtherProjectsModal from "./Modal/OtherProjectsModal";
import RemarksModal from "./Modal/RemarksModal";
import ProjectInfoModal from "./Modal/ProjectInfoModal";

import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import { getFirstLetters } from "../../../utils/utils";
import {
  CommentGreen,
  LeaveIcon,
  NoCommentIcon,
  ResourceIcon,
  WorkingHourNormal,
  WorkingHourOrange,
  WorkingHourRed,
} from "../AllocationSvg";
import { userProfileActions } from "../../../store/slices/userProfile";
import {
  // getAllocationLists,
  // getAllocationLists,
  getMultyUserProfile,
  updateAllocationLists,
} from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
import { getNameAndUniqId } from "../../../components/common/iconDefaultName";

// import NoAllocationModal from "./Modal/NoAllocationModal";
// import OtherProjectsModal from "./Modal/OtherProjectsModal";
// import RemarksModal from "./Modal/RemarksModal";

const AllocationTable = ({
  title,
  data,
  isLoading,
  buttonClassName,
  hasPermission,
  params,
}) => {
  // No allocation modal
  const [isNoAllocationModalOpen, setIsNoAllocationModalOpen] = useState(null);
  const [isNoAllocationModalIndex, setIsNoAllocationModalIndex] =
    useState(null);

  const tooltipRef = useRef(null);

  const [getMultyUsers] = useThunk(getMultyUserProfile);

  const [updateLists, updateListLoading] = useThunk(updateAllocationLists);

  // const [updateLists] = useThunk(updateAllocationLists);

  const dispatch = useDispatch();

  // check employee name length
  const employeeNameLength = data?.map((e) => e?.employeeName);

  // function to handle no allocation modal click
  const handleNoAllocationButtonClick = (id, index) => {
    // Close the ProjectInfoModal
    setprojectInfoModal(false);
    setIsRemarkModalOpen(null);
    setIsOtherProjectModalOpen(null);

    if (isNoAllocationModalOpen === id) {
      setIsNoAllocationModalIndex(null);
      setIsNoAllocationModalOpen(null); // Close the NoAllocationModal if it's already open
    } else {
      setIsNoAllocationModalIndex(index);
      setIsNoAllocationModalOpen(id);
    }
  };

  // state to handel skeleton loader for single row
  const [updatelistId, setUpdatelistId] = useState(null);

  //  project discription
  const [descriptions, setDescriptionsList] = useState();
  // const [descriptionIndex, setDescriptionIndex] = useState();

  const descriptionsList = (disc) => {
    setDescriptionsList(disc);
    // setDescriptionIndex(nameIndex);
  };

  // function to show hours classname
  function getTaskAllocationClass(totalAllocationHours) {
    if (totalAllocationHours < 8) {
      return "task-alloc-under";
    }
    if (totalAllocationHours === 8) {
      return "task-alloc-full";
    }
    return "task-alloc-over";
  }

  // //   other projects modal state
  const [isOtherProjectModalOpen, setIsOtherProjectModalOpen] = useState(null);

  const handleOtherProjectButtonClick = (id) => {
    setIsNoAllocationModalOpen(false);
    setIsRemarkModalOpen(null);
    if (isOtherProjectModalOpen === id) {
      setIsOtherProjectModalOpen(null);
      // setprojectInfoModal(false);
      // Close the modal if it's already open
    } else {
      setIsOtherProjectModalOpen(id);
      // Open the modal for the selected project
    }
  };

  // //   remarks modal
  const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(null);
  const [isRemarkAddModalOpen, setIsRemarkAddModalOpen] = useState(false);

  const handleRemarkModalClick = (id, isTrue) => {
    setIsNoAllocationModalOpen(null);
    setIsOtherProjectModalOpen(null);

    if (isRemarkModalOpen === id) {
      setIsRemarkModalOpen(null); // Close the modal if it's already open
    } else {
      setIsRemarkModalOpen(id);
      setIsRemarkAddModalOpen(isTrue);
      // Open the modal for the selected project
    }
  };

  // Show ProjectInfo modal
  const [projectInfoModal, setprojectInfoModal] = useState(false);
  // const [projectInfoModalIndex, setprojectInfoModalIndex] = useState(false);

  const onShowProjectInfo = (show) => {
    setprojectInfoModal(show);
    // setprojectInfoModalIndex(noAllocationIndex);
  };

  const handleCloseProjectInfoModal = () => {
    setprojectInfoModal(false);
  };

  // function to show hours color className
  function getHoursColorClass(totalAllocationHours) {
    if (totalAllocationHours < 8) {
      return "hours-red";
    }
    if (totalAllocationHours === 8) {
      return "";
    }
    return "hours-orange";
  }

  // fuction to show image according to hour
  function renderHourComponent(totalAllocationHours) {
    if (totalAllocationHours < 8) {
      return <WorkingHourRed />;
    }
    if (totalAllocationHours === 8) {
      return <WorkingHourNormal />;
    }
    return <WorkingHourOrange />;
  }

  const modalContainerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      // Check if the click is outside the modal container
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(e.target)
      ) {
        // Close all modals here

        setIsNoAllocationModalOpen(null);
        setIsOtherProjectModalOpen(false);
        setIsRemarkModalOpen(null);
        setprojectInfoModal(false);
        // Add similar logic for other modals if needed
      }
    };

    // Add the click event listener to the document
    document.addEventListener("click", handleOutsideClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // accordian click
  const [activeKey, setActiveKey] = useState("0");
  const [imgArray, setimgArray] = useState([]);

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  function CustomToggle({ children, eventKey, activeKey, onToggle }) {
    const isOpen = activeKey === eventKey;

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (isOpen) {
        onToggle(""); // Close the accordion if it's already open
      } else {
        onToggle(eventKey); // Open the accordion if it's not open
      }
    });

    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className={`arrow-controler ${isOpen ? "expanded" : "collapsed"}`}
      >
        {children}
      </button>
    );
  }

  CustomToggle.propTypes = {
    children: PropTypes.node,
    eventKey: PropTypes.string.isRequired,
    activeKey: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
  };

  return (
    <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
      <Card>
        <div className="group-list-cover d-flex pb-0">
          <div className="task-list-header__item_main sticky-element">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                activeKey={activeKey}
                onToggle={(key) => setActiveKey(key)}
              >
                {activeKey === "0" ? (
                  <img className="img-down" src={greenArrowDown} alt="icon" />
                ) : (
                  <img className="img-right" src={arrowRightIcon} alt="icon" />
                )}
              </CustomToggle>
            </Card.Header>
            {isLoading ? (
              <CommonSkeleton />
            ) : (
              <>
                <button
                  className={`tab_button tab_in_01 ${buttonClassName}`}
                  type="button"
                >
                  {title}
                </button>
                {hasPermission(
                  permissionConstants.permissions.ALLOCATION_USER_COUNT,
                ) && (
                  <button className="tab_button" type="button">
                    <span>
                      <span className="ico pe-1">
                        <ResourceIcon />
                      </span>
                      {employeeNameLength?.length}
                    </span>
                  </button>
                )}
              </>
            )}
          </div>
          <div className="list-header__heading" ref={tooltipRef}>
            {hasPermission(
              permissionConstants.permissions.ALLOCATION_DESIGNATION,
            ) && (
              <div className="field__th">
                <button className="title-th first-th" type="button">
                  Designation
                </button>
              </div>
            )}
            {hasPermission(
              permissionConstants.permissions.ALLOCATION_PM_LEAD,
            ) && (
              <div className="field__th">
                <button className="title-th" type="button">
                  PM/Team Lead
                </button>
              </div>
            )}
            {hasPermission(
              permissionConstants.permissions.ALLOCATION_HOURS,
            ) && (
              <>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 1
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 2
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 3
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 4
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 5
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 6
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 7
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 8
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 9
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 10
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 11
                  </button>
                </div>
                <div className="field__th">
                  <button className="title-th" type="button">
                    Hour 12
                  </button>
                </div>
              </>
            )}
            {/* stick columns  */}
            <div className="sticky-right">
              {hasPermission(
                permissionConstants.permissions.ALLOCATION_OTHER_PROJECTS,
              ) && (
                <div className="field__th">
                  <button className="title-th" type="button">
                    Other Projects
                  </button>
                </div>
              )}
              {hasPermission(
                permissionConstants.permissions.ALLOCATION_UPDATED_BY,
              ) && (
                <div className="field__th">
                  <button className="title-th" type="button">
                    Updated by
                  </button>
                </div>
              )}
              {hasPermission(
                permissionConstants.permissions.ALLOCATION_TOTAL_WORKING_HOURS,
              ) && (
                <div className="field__th">
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={
                      <Tooltip id="Total Working Hrs">
                        Total Working Hrs
                      </Tooltip>
                    }
                  >
                    <button className="title-th" type="button">
                      Total Working Hrs
                    </button>
                  </OverlayTrigger>
                </div>
              )}
              {hasPermission(
                permissionConstants.permissions.ALLOCATION_REMARKS,
              ) && (
                <div className="field__th">
                  <button className="title-th" type="button">
                    Remarks
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="table-list-cover collapse show cutom-list-table"
          id="collapseExample"
          ref={tooltipRef}
        >
          <Accordion.Collapse eventKey="0">
            <Card.Body ref={modalContainerRef}>
              <div className="main-container-row ps-0">
                {isLoading
                  ? Array?.from({ length: data?.length || 0 })?.map(
                      (_, index) => (
                        <div className="task-list-rows out-subtask" key={index}>
                          {/* {Array.from({ length: title?.length }).map((_, i) => ( */}
                          <div className="info-td">
                            <CommonSkeleton height={20} width={2050} />
                          </div>
                          {/* ))} */}
                        </div>
                      ),
                    )
                  : data?.map((e, index) => {
                      const timeCalculation = e?.projectAllocation?.reduce(
                        (acc, item) => {
                          const a = Array.from(
                            { length: item?.allocationHours },
                            () => ({
                              projectName: item?.projectName,
                              projectId: item?.projectId,
                            }),
                          );
                          acc = [...acc, ...a];
                          return acc;
                        },
                        [],
                      );
                      const twelveLengthArray = Array?.from(
                        { length: 12 },
                        (_, index) => {
                          if (index < timeCalculation?.length) {
                            // If the index is within the length of the 8-length array, display its value
                            return timeCalculation[index];
                          }

                          return "No Allocation";
                        },
                      );

                      //  skeleton loader for single raw

                      return updateListLoading && updatelistId === e?._id ? (
                        Array?.from({ length: 1 })?.map((_, index) => (
                          <div
                            className="task-list-rows out-subtask"
                            key={index}
                          >
                            {/* {Array.from({ length: 12 }).map((_, i) => ( */}
                            <div className="info-td">
                              <CommonSkeleton height={20} width={2050} />
                            </div>
                            {/* ))} */}
                          </div>
                        ))
                      ) : (
                        <div className="task-list-rows out-subtask" key={index}>
                          <div className="row__task_lft sticky-element">
                            <div className="contents_info">
                              <div
                                className={`info-td task-identify ${getTaskAllocationClass(
                                  e.totalAllocationHours,
                                )}`}
                              >
                                <div className="name-grp">
                                  <OverlayTrigger
                                    placement="bottom"
                                    container={tooltipRef}
                                    overlay={
                                      <Tooltip
                                        id={`${e?.employeeName}-tooltip`}
                                      >
                                        {e?.employeeName}
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className={`avathar-sm avathar-0${
                                        (index % 4) + 1
                                      }`}
                                      onClick={(f) => {
                                        f.stopPropagation();

                                        getMultyUsers(e?._id);
                                        dispatch(
                                          userProfileActions.setShowUserProfile(
                                            true,
                                          ),
                                        );
                                      }}
                                    >
                                      <span>
                                        {e?.photo ? (
                                          imgArray?.includes(e?._id) ? (
                                            getFirstLetters(e?.employeeName)
                                          ) : (
                                            <img
                                              onError={(target) =>
                                                handleOnError(target, e?._id)
                                              }
                                              onLoad={(target) =>
                                                (target.target.style.display =
                                                  "block")
                                              } // Ensure the image is displayed when it successfully loads
                                              src={`https://erptest.spericorn.com/repo/uploads/${e?.photo}`}
                                              alt=""
                                              style={{ display: "none" }} // Initially hide the image
                                            />
                                          )
                                        ) : (
                                          getFirstLetters(e?.employeeName)
                                        )}
                                      </span>
                                    </div>
                                  </OverlayTrigger>

                                  {/* employeeName  */}
                                  <div className="name content-inr strong">
                                    {e?.employeeName || "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="info-right d-flex align-items-center">
                            {hasPermission(
                              permissionConstants.permissions
                                .ALLOCATION_DESIGNATION,
                            ) && (
                              <div className="info-td">
                                {/* {designation } */}
                                <OverlayTrigger
                                  placement="top"
                                  container={tooltipRef}
                                  overlay={
                                    <Tooltip id={`${e?.designation}-tooltip`}>
                                      {e?.designation}
                                    </Tooltip>
                                  }
                                >
                                  <div className="content-inr lg-content designation-col">
                                    {e?.designation || "-"}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            )}
                            {hasPermission(
                              permissionConstants.permissions
                                .ALLOCATION_PM_LEAD,
                            ) && (
                              <div className="info-td d-flex justify-content-center">
                                <div className="avatar__group">
                                  -
                                  {/* <div
                                    className={`avathar-sm avathar-0${
                                      (index % 4) + 1
                                    }`}
                                  >
                                    <div className="online" />
                                    <div className="close_circle">
                                    <img src={closeIcon} alt="" />
                                  </div>
                                    <span>AR</span>
                                  </div> */}
                                </div>
                              </div>
                            )}

                            {/* { display projectName according to hour} */}
                            {twelveLengthArray?.map((item, index) => (
                              /* eslint-disable */
                              <div
                                className={
                                  (e?.leaveData?.session === "1" &&
                                    e?.leaveData?.duration === "1" &&
                                    index >= 0 &&
                                    index < 4) ||
                                  (e?.leaveData?.duration === "2" &&
                                    index >= 0 &&
                                    index < 8)
                                    ? "position-relative"
                                    : "info-td"
                                }
                                key={index}
                              >
                                {/* add leave section  */}
                                {e.leaveData?.session === "1" &&
                                e.leaveData?.duration === "1" &&
                                index >= 0 &&
                                index < 4 ? (
                                  index === 0 ? (
                                    <div className="leave-wrp d-flex align-items-center half-day">
                                      <div className="leave-cont">
                                        <span className="ico">
                                          <LeaveIcon />
                                        </span>
                                        <div className="txt">
                                          Half Day Leave
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                                ) : e?.leaveData?.session === "2" &&
                                  e.leaveData?.duration === "1" &&
                                  index >= 4 &&
                                  index < 8 ? (
                                  index === 4 ? (
                                    <div className="leave-wrp d-flex align-items-center half-day">
                                      <div className="leave-cont">
                                        <span className="ico">
                                          {" "}
                                          <LeaveIcon />
                                        </span>
                                        <div className="txt">
                                          Half Day Leave
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                                ) : e?.leaveData?.duration === "2" &&
                                  index >= 0 &&
                                  index < 8 ? (
                                  index === 0 ? (
                                    <div className="leave-wrp d-flex align-items-center full-day">
                                      <div className="leave-cont">
                                        <span className="ico">
                                          <LeaveIcon />
                                        </span>
                                        <div className="txt">
                                          Full Day Leave
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                                ) : (
                                  <div
                                    className={`content-inr text_overflow ${
                                      isNoAllocationModalOpen
                                        ? "overflow-visible"
                                        : ""
                                    } `}
                                  >
                                    <div className="dropdown custom-drop-menu drop-menu-lg search-custom info-all">
                                      <button
                                        type="button"
                                        className={`d-flex btn-sm dropdown-toggle ${
                                          item === "No Allocation"
                                            ? "no-allocation"
                                            : "allocated"
                                        } `}
                                        onClick={() =>
                                          handleNoAllocationButtonClick(
                                            e?._id,
                                            index,
                                          )
                                        }
                                        // id="dropdownMenuButton1"
                                        // data-bs-toggle="dropdown"
                                        // aria-expanded="false"
                                      >
                                        {hasPermission(
                                          permissionConstants.permissions
                                            .ALLOCATION_HOURS,
                                        ) && (
                                          <OverlayTrigger
                                            placement="top"
                                            container={tooltipRef}
                                            overlay={
                                              <Tooltip
                                                id={`${item?.projectName}-tooltip`}
                                              >
                                                {item !== "No Allocation"
                                                  ? item?.projectName
                                                  : item}
                                              </Tooltip>
                                            }
                                          >
                                            <span className="d-block">
                                              {item !== "No Allocation"
                                                ? item?.projectName
                                                : item}
                                            </span>
                                          </OverlayTrigger>
                                        )}
                                      </button>

                                      {!projectInfoModal &&
                                      isNoAllocationModalOpen === e?._id &&
                                      isNoAllocationModalIndex === index ? (
                                        <NoAllocationModal
                                          show={isNoAllocationModalOpen}
                                          setIsNoAllocationModalOpen={
                                            setIsNoAllocationModalOpen
                                          }
                                          allocationprojectId={item?.projectId}
                                          onShowProjectInfo={onShowProjectInfo}
                                          projectWithId={e}
                                          descriptions={descriptionsList}
                                          noAllocationIndex={index}
                                          isNoAllocation={
                                            item === "No Allocation"
                                          }
                                          updateLists={updateLists}
                                          params={params}
                                          updateListLoading={updateListLoading}
                                          setUpdatelistId={setUpdatelistId}
                                        />
                                      ) : null}

                                      {isNoAllocationModalOpen === e?._id &&
                                        isNoAllocationModalIndex === index && (
                                          <ProjectInfoModal
                                            show={projectInfoModal}
                                            onClose={
                                              handleCloseProjectInfoModal
                                            }
                                            descriptions={descriptions}
                                          />
                                        )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}

                            <div
                              className={`sticky-right ${
                                isRemarkModalOpen === e?._id ||
                                isOtherProjectModalOpen === e?._id
                                  ? "z-5"
                                  : ""
                              }`}
                              // style={
                              //   isRemarkModalOpen === e?._id ||
                              //   isOtherProjectModalOpen === e?._id
                              //     ? { zIndex: 5 }
                              //     : {}
                              // }
                            >
                              {hasPermission(
                                permissionConstants.permissions
                                  .ALLOCATION_OTHER_PROJECTS,
                              ) && (
                                <div className="info-td">
                                  <div className="content-inr icon-text hours-otr text-center btn-click overflow-visible">
                                    <div className="dropdown custom-drop-menu drop-menu-lg search-custom">
                                      <button
                                        type="button"
                                        className="d-flex btn-sm dropdown-toggle no-allocation m-auto"
                                        onClick={() =>
                                          handleOtherProjectButtonClick(e?._id)
                                        }
                                        // id="dropdownMenuButton1"
                                        // data-bs-toggle="dropdown"
                                        // aria-expanded="false"
                                      >
                                        <img src={otherProjects} alt="" />
                                      </button>

                                      {isOtherProjectModalOpen === e?._id && (
                                        <OtherProjectsModal
                                          data={e?.otherProjects}
                                          show={isOtherProjectModalOpen}
                                        />
                                      )}

                                      {/* {otherProjectInfo && (
                                <ProjectInfoModal
                                  show={otherProjectInfo}
                                  onClose={handleCloseProjectInfoModal}
                                />
                              )} */}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* updated by  */}

                              {hasPermission(
                                permissionConstants.permissions
                                  .ALLOCATION_UPDATED_BY,
                              ) && (
                                <div className="info-td">
                                  {e?.lastUpdated?.updatedBy?.employeeName ? (
                                    <OverlayTrigger
                                      placement="bottom"
                                      container={tooltipRef}
                                      overlay={
                                        <Tooltip
                                          id={`${e?.lastUpdated?.updatedBy?.employeeName}-tooltip`}
                                        >
                                          {
                                            e?.lastUpdated?.updatedBy
                                              ?.employeeName
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className={`avathar-sm avathar-0${
                                          (getNameAndUniqId(
                                            e?.lastUpdated?.userId,
                                          ).uniqId %
                                            4) +
                                          1
                                        }`}
                                        onClick={(f) => {
                                          f.stopPropagation();

                                          getMultyUsers(e?.lastUpdated?.userId);
                                          dispatch(
                                            userProfileActions.setShowUserProfile(
                                              true,
                                            ),
                                          );
                                        }}
                                      >
                                        <div className="online" />
                                        {/* <div className="close_circle">
                                  <img src={closeIcon} alt="" />
                                </div> */}

                                        <span>
                                          {e?.lastUpdated?.updatedBy.photo ? (
                                            <img
                                              src={`https://erptest.spericorn.com/repo/uploads/${e?.lastUpdated?.updatedBy.photo}`}
                                              alt="profile pic"
                                            />
                                          ) : (
                                            getFirstLetters(
                                              e?.lastUpdated?.updatedBy
                                                ?.employeeName,
                                            )
                                          )}
                                        </span>
                                      </div>
                                    </OverlayTrigger>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              )}
                              {hasPermission(
                                permissionConstants.permissions
                                  .ALLOCATION_TOTAL_WORKING_HOURS,
                              ) && (
                                <div className="info-td">
                                  {e.totalAllocationHours ? (
                                    <div
                                      className={`content-inr icon-text hours-otr text-center ${getHoursColorClass(
                                        e.totalAllocationHours,
                                      )}`}
                                    >
                                      {renderHourComponent(
                                        e.totalAllocationHours,
                                      )}
                                      <span>{e?.totalAllocationHours} h</span>
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              )}
                              {/* <div
                                className={`sticky-right ${
                                  isRemarkModalOpen || isOtherProjectModalOpen
                                    ? "z-3"
                                    : ""
                                }`}
                              > */}
                              {hasPermission(
                                permissionConstants.permissions
                                  .ALLOCATION_REMARKS,
                              ) && (
                                <div className="info-td">
                                  <div className="content-inr icon-text comment__otr text-center overflow-visible">
                                    <div className="dropdown custom-drop-menu drop-menu-lg search-custom info-all comments_otr">
                                      <button
                                        type="button"
                                        className="d-flex btn-sm dropdown-toggle no-allocation"

                                        // id="dropdownMenuButton1"
                                        // data-bs-toggle="dropdown"
                                        // aria-expanded="false"
                                      >
                                        {e?.allocationRemarks?.[0]?.remark?.trim()
                                          ?.length > 0 ? (
                                          <span
                                            className="cmnticon"
                                            onClick={() =>
                                              handleRemarkModalClick(e?._id)
                                            }
                                          >
                                            <CommentGreen />
                                          </span>
                                        ) : (
                                          <span
                                            className="cmnticon"
                                            onClick={() =>
                                              handleRemarkModalClick(
                                                e?._id,
                                                true,
                                              )
                                            }
                                          >
                                            <NoCommentIcon />
                                          </span>
                                          // <img
                                          //   src={comments}
                                          //   className="cmnticon"
                                          //   alt=""
                                          // />
                                        )}

                                        <span />
                                      </button>

                                      {isRemarkModalOpen === e?._id && (
                                        <RemarksModal
                                          isRemarkAddModalOpen={
                                            isRemarkAddModalOpen
                                          }
                                          setIsRemarkAddModalOpen={
                                            setIsRemarkAddModalOpen
                                          }
                                          projectWithId={e}
                                          remarksdiscription={
                                            e?.allocationRemarks
                                          }
                                          allData={data}
                                          params={params}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </div>
      </Card>
    </Accordion>
  );
};

AllocationTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string, // Adjust the property names and types accordingly
      value: PropTypes.string,
      // Add more PropTypes for other properties as needed
    }),
  ),
  buttonClassName: PropTypes.string,
  title: PropTypes.string,
  params: PropTypes.string,
  isLoading: PropTypes.bool,
  hasPermission: PropTypes.func,
};

export default AllocationTable;
