import PropTypes from "prop-types";
import moment from "moment";
import { useSelector } from "react-redux";
import { useState } from "react";
import comments from "../../../../assets/images/comment-green.svg";
import ImgEdit from "../../../../assets/images/edit-001.svg";
import { updateAllocationLists } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { getFirstLetters } from "../../../../utils/utils";

const RemarksModal = ({
  remarksdiscription,
  projectWithId,
  isRemarkAddModalOpen,
  setIsRemarkAddModalOpen,
  params,
  // allData,
}) => {
  const [addComment, setAddComment] = useState(null);

  // const [showAddRemarks, setshowAddRemarks] = useState(false);
  const onClickEditRemarks = (event) => {
    setIsRemarkAddModalOpen(true);
    // Map over the array and extract the "remark" values into a new array
    const remarkArray = remarksdiscription?.map((item) => item.remark);

    // Join the "remark" values into a single string with spaces in between
    const concatenatedRemarks = remarkArray.join(" ");

    setAddComment(concatenatedRemarks);

    // Check if addComment is empty, and close the modal
    if (!addComment) {
      setIsRemarkAddModalOpen(false);
    }
    event.stopPropagation();
  };

  const { authData } = useSelector((state) => state.auth);

  const [updateLists] = useThunk(updateAllocationLists);

  const onClickaddRemarks = (event) => {
    // Remove leading and trailing spaces
    const remarkId = remarksdiscription?.map((remark) => remark?._id);

    updateLists({
      updatedData: {
        userId: projectWithId?._id,
        remarks: {
          remark: addComment?.length > 0 ? addComment : undefined,
          remarkedBy: authData?._id,
          id: remarksdiscription?.length ? remarkId[0] : undefined, // Set to undefined when adding new remarks
        },
      },
      params,
    });

    setIsRemarkAddModalOpen(false);
    event.stopPropagation();
  };
  // {
  //   //   "userId": "64ba1d56e1c231fcb011ba52",
  //   //   "remarks": {
  //   //     "remark": "remarks updationhhh",
  //   //     "remarkedBy": "64ba22e46084ff3b591186a8",
  //   // "id": "651ea7aaaddc1665f4e3bfb5"
  //   //   }
  //   // }
  // }

  return (
    <div
      className={`dropdown-menu dropeffect pt-2 pb-2 ${
        isRemarkAddModalOpen ||
        (addComment !== null &&
          remarksdiscription?.[0]?.remark?.trim()?.length > 0) ||
        remarksdiscription?.[0]?.remark?.trim()?.length > 0
          ? "show"
          : ""
      }`}
      aria-labelledby="dropdownMenuButton1"
    >
      <div className="dropdown-item no-hover searcharea d-flex align-items-center no-bg pb-1">
        <button className="title" type="button">
          <span className="bk ">
            <img className="cmnt" src={comments} alt="" />
          </span>{" "}
          {isRemarkAddModalOpen ? "Add Remarks" : "Remarks"}
        </button>
        {!isRemarkAddModalOpen && (
          <button
            className="edit-icon"
            type="button"
            onClick={onClickEditRemarks}
          >
            <img className="editicon" src={ImgEdit} alt="" />
          </button>
        )}
      </div>
      <div className="divitions pb-1 pt-1">
        <hr className="mt-0 mb-0" />
      </div>

      {isRemarkAddModalOpen && (
        <div className="commtent-ares">
          <textarea
            className="text-area"
            name=""
            placeholder="Comments"
            value={addComment}
            onChange={(e) => {
              setAddComment(e?.target.value);
              e.stopPropagation();
            }}
          />
          <button
            className="add-btn-01"
            type="button"
            onClick={onClickaddRemarks}
          >
            Add
          </button>
        </div>
      )}

      {!isRemarkAddModalOpen &&
        remarksdiscription?.[0]?.remark?.trim()?.length > 0 && (
          <>
            <div className="dropdown-item d-flex flex-wrap content">
              {remarksdiscription?.map((e, index) => (
                <div key={index}>
                  {e?.remark?.split("\n")?.map((line, lineIndex) => (
                    <p key={lineIndex}>{line}</p>
                  ))}
                </div>
              ))}
            </div>

            <button
              className="dropdown-item d-flex flex-wrap avathar-otr"
              type="button"
            >
              <div className="cvr">
                <div className="avatharImg">
                  {projectWithId?.remarkedBy?.photo ? (
                    <img
                      src={`https://erptest.spericorn.com/repo/uploads/${projectWithId?.remarkedBy?.photo}`}
                      alt="profile pic"
                    />
                  ) : (
                    getFirstLetters(projectWithId?.remarkedBy?.employeeName)
                  )}
                </div>
                {remarksdiscription?.map((time, index) => {
                  // const matchingEmployee = allData?.filter(
                  //   (employee) => employee?._id === time?.remarkedBy,
                  // );
                  return (
                    <div>
                      <p>{projectWithId?.remarkedBy?.employeeName || "-"}</p>
                      <small key={index}>
                        {moment(time?.updatedAt).format(
                          "MMMM DD, YYYY, hh:mm a",
                        )}
                      </small>
                    </div>
                  );
                })}
              </div>
            </button>
          </>
        )}
    </div>
  );
};
RemarksModal.propTypes = {
  remarksdiscription: PropTypes.arrayOf(
    PropTypes.shape({
      remark: PropTypes.string, // Add this line
      remarkedBy: PropTypes.string,
    }),
  ),
  // allData: PropTypes.arrayOf(),
  projectWithId: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    remarkedBy: PropTypes.string,
  }),
  params: PropTypes.string,
  isRemarkAddModalOpen: PropTypes.bool,
  setIsRemarkAddModalOpen: PropTypes.func,
};

export default RemarksModal;
