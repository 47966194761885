import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Picker from "emoji-picker-react";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import Log from "./log";
import attach from "../../assets/images/attach.svg";
import emoji from "../../assets/images/emoji.svg";
import assignedUser from "../../assets/images/assign_user.svg";
import UserDetail from "./userDetail";
import DownloadIcon from "../../assets/images/download-icon.svg";
import pdf from "../../assets/images/pdf.svg";
import xlsx from "../../assets/images/xls.svg";
import docx from "../../assets/images/doc.svg";
import textIcon from "../../assets/images/text.svg";
import { useThunk } from "../../hooks/useThunk";
import { userProfileActions } from "../../store/slices/userProfile";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import {
  getMultyUserProfile,
  patchTicket,
  patchTicketAllocation,
  patchTicketAllocationAtm,
  patchTicketAttachment,
} from "../../store";
import DeleteModal from "./deleteModal";
import { Download } from "./svgicons";
import OutsideClickHandler from "./OutsideClickHandler";
import { convertBytesToKBMB } from "../Document/utility";

const ChatSection = ({
  isGetTicket,
  isGetTicketAllocation,
  setTicketAllocationDatas,
  ticket,
  setAddUser,
  NewTab,
  hasPermission,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [atPressed, setAtPressed] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [atText, setAtText] = useState("");
  const [fileError, setFileError] = useState("");
  const { teamData } = useSelector((state) => state.team);
  const [patchTicketAttachments, selectedTicketAttchment] = useThunk(
    patchTicketAttachment,
  );
  const [isImageLoaded, setIsImageLoaded] = useState({});
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [updateTicketThunk] = useThunk(patchTicket);
  const [patchTicketAllocationAttachments, selectedTicketAllocationAttchment] =
    useThunk(patchTicketAllocationAtm);
  const [patchTicketAllocationThunk] = useThunk(patchTicketAllocation);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [deletedModal, setDeleteModal] = useState(false);
  const [mentionClick, setMentionClick] = useState(false);
  const { authData } = useSelector((state) => state.auth);
  const [image, setImage] = useState([]);

  const dispatch = useDispatch();
  const messagesRef = useRef(null);

  const toggleEmojiPicker = () => {
    setAtPressed(false);
    setShowEmojiPicker(!showEmojiPicker);
  };
  const getFileIcon = (extension) => {
    const iconMappings = {
      pdf,
      xlsx,
      docx,
      doc: docx,
      txt: textIcon,
    };
    return iconMappings[extension] || DownloadIcon;
  };
  const fileInputRef = useRef(null);

  const handleEmojiClick = (emojiObject, e) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    e.stopPropagation();
  };
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);

    let isFileTypeValid = true;
    let isSizeValid = true;

    const validFiles = files.filter((file) => {
      if (
        !(
          file.type?.toLowerCase().startsWith("application/") ||
          file.type?.toLowerCase().startsWith("image/") ||
          file.type?.toLowerCase().startsWith("text/")
        )
      ) {
        isFileTypeValid = false;
      }

      if (file.size > 10 * 1024 * 1024) {
        isSizeValid = false;
      }

      return isFileTypeValid && isSizeValid;
    });

    if (validFiles.length > 0) {
      const newSelectedFiles = [...selectedFiles];
      const urlArray = [...image];
      validFiles.forEach((file) => {
        newSelectedFiles.push(file);
        urlArray.push(URL.createObjectURL(file));
      });

      setSelectedFiles(newSelectedFiles);
      setImage(urlArray);
      setFileError("");
    } else if (!isFileTypeValid) {
      setFileError(
        "Invalid file type. Please select valid images, PDFs, or documents.",
      );
    } else if (!isSizeValid) {
      setFileError("File size exceeds the maximum allowed size (10 MB).");
    } else {
      setFileError(
        "Invalid file selection. Please select valid images, PDFs, or documents.",
      );
    }
  };
  console.log("isImageLoaded", isImageLoaded);
  const handleRemoveFile = (fileName) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== fileName));
  };
  const handleCommentSend = () => {
    if (selectedFiles.length > 0 || message.trim() !== "") {
      const formData = new FormData();

      formData.append("type", "comment");

      const usernamePattern = /@(\w+(\s\w+){0,2})/g;
      const mentionedUserIds = [];
      let modifiedMessage = message;
      const matches = message.match(usernamePattern);
      if (matches) {
        // eslint-disable-next-line no-restricted-syntax
        for (const match of matches) {
          const mentionedUsername = match.substr(1);

          const mentionedUser = findUserWithVariations(
            mentionedUsername,
            teamData,
          );

          if (mentionedUser) {
            mentionedUserIds.push(mentionedUser.userId);

            modifiedMessage = modifiedMessage.replace(
              new RegExp(`@${mentionedUser?.user}`, "g"),
              `{{${mentionedUser.userId}}}`,
            );
          }
        }
      }

      formData.append("message", modifiedMessage);

      selectedFiles.forEach((file) => {
        formData.append("attachment", file);
      });

      if (ticket) {
        patchTicketAttachments({
          id: setTicketAllocationDatas._id,
          formData,
        });
      } else {
        patchTicketAllocationAttachments({
          id: setTicketAllocationDatas._id,
          formData,
        });
      }

      setSelectedFiles([]);
      setMessage("");
      setShowFooter(false);
      setShowEmojiPicker(false);
    }

    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  function findUserWithVariations(mentionedUsername, teamData) {
    const usernames = mentionedUsername.split(" ");
    let i = 0;

    while (i < usernames.length) {
      let usernameToSearch = usernames[i];

      const mentionedUser = teamData.projectAggregation.find(
        (user) => user.user === usernameToSearch,
      );

      if (mentionedUser) {
        return mentionedUser;
      }

      let j = 1;

      while (j < 3 && i + j < usernames.length) {
        usernameToSearch += ` ${usernames[i + j]}`;

        const mentionedUserWithInitial = teamData.projectAggregation.find(
          // eslint-disable-next-line no-loop-func
          (user) => user.user === usernameToSearch,
        );

        if (mentionedUserWithInitial) {
          return mentionedUserWithInitial;
        }

        j += 1;
      }

      i += 1;
    }

    return null;
  }

  useEffect(() => {
    if (atPressed) {
      const lastIndex = message.lastIndexOf("@");
      if (lastIndex >= 0) {
        const textAfterAt = message.slice(lastIndex + 1);
        if (textAfterAt.includes(" ")) {
          setAtText("");
        } else {
          setAtText(textAfterAt);
        }
      }
    }
  }, [message, atPressed]);
  function formatCommentTime(commentDate) {
    const currentDate = new Date();
    const commentTime = new Date(commentDate);

    const isToday = currentDate.toDateString() === commentTime.toDateString();

    if (isToday) {
      const timeDifference = Math.floor((currentDate - commentTime) / 1000);

      if (timeDifference < 60) {
        return "just now";
      }
      if (timeDifference < 3600) {
        const minutesAgo = Math.floor(timeDifference / 60);
        return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
      }
      const hoursAgo = Math.floor(timeDifference / 3600);
      return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    }

    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return commentTime.toLocaleString("en-US", options);
  }
  function replaceUserMentions(text) {
    const userIdPattern = /{{(\w+)}}/g;

    const parts = text.split(userIdPattern);

    return parts.map((userId, index) => {
      if (index % 2 === 1) {
        const mentionedUser = teamData?.projectAggregation?.find(
          (user) => user.userId === userId,
        );

        if (mentionedUser) {
          return (
            <span
              key={index}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={(f) => {
                f.stopPropagation();

                getMultyUsers(userId);
                dispatch(userProfileActions.setShowUserProfile(true));
              }}
            >
              @{mentionedUser.user}
            </span>
          );
        }
      }
      return userId;
    });
  }
  const handleMessageDelete = () => {
    const comments = setTicketAllocationDatas.comments?.filter((e) => {
      return e._id !== deletedModal;
    });
    if (ticket) {
      updateTicketThunk({
        id: setTicketAllocationDatas?._id,
        patchData: { comments },
      });
    } else {
      patchTicketAllocationThunk({
        id: setTicketAllocationDatas._id,
        patchData: {
          comments,
        },
      });
    }
    setDeleteModal(false);
  };
  const handleFileDownload = (file) => {
    setDownloadingFile(file);

    setTimeout(() => {
      setDownloadingFile(false);
    }, 2000);
  };

  return (
    <div
      className={`task-body-col task-body-col-right ${
        showFooter ? "comment-inp-open" : ""
      }`}
    >
      <div
        className="task-body-block task-info-details custom-scroll"
        ref={messagesRef}
      >
        {isGetTicket || isGetTicketAllocation ? (
          <CommonSkeleton height={200} />
        ) : (
          hasPermission(
            permissionConstants.permissions.TASK_DETAILS_MODAL_LOGS,
          ) && <Log ticket={ticket} task={setTicketAllocationDatas} />
        )}
        <OutsideClickHandler
          showFooter={showFooter}
          onOutsideClick={() => {
            setAtPressed(false);
            setShowEmojiPicker(false);
            setMentionClick(false);
            setShowFooter(false);
          }}
          innerClass="EmojiPickerReact"
        >
          {isGetTicket || isGetTicketAllocation ? (
            <CommonSkeleton height={200} />
          ) : (
            hasPermission(
              permissionConstants.permissions.TASK_DETAILS_MODAL_LIST_COMMENTS,
            ) &&
            setTicketAllocationDatas?.comments?.map((e) => (
              <div
                className={`comments-cover ${
                  e.user === authData?._id ? "my-comment" : ""
                }`}
              >
                <div className="cmnt-otr">
                  {teamData?.projectAggregation?.find(
                    (user) => user?.userId === e?.user,
                  )?.photo ? (
                    <div
                      className="user-pic"
                      type="button"
                      onClick={(f) => {
                        f.stopPropagation();
                        getMultyUsers(e?.user);
                        dispatch(userProfileActions.setShowUserProfile(true));
                      }}
                    >
                      <img
                        src={`https://erptest.spericorn.com/repo/uploads/${
                          teamData.projectAggregation.find(
                            (user) => user.userId === e.user,
                          ).photo
                        }`}
                        alt="profile-pic"
                      />
                    </div>
                  ) : (
                    <div
                      className="cmnt-avathar"
                      type="button"
                      onClick={(f) => {
                        f.stopPropagation();
                        getMultyUsers(e?.user);
                        dispatch(userProfileActions.setShowUserProfile(true));
                      }}
                    >
                      <div className="user-name-avatar">
                        {teamData?.projectAggregation
                          ?.find((user) => user?.userId === e?.user)
                          ?.user?.split(" ")
                          .map((word) => word.slice(0, 1).toUpperCase())
                          .join("")}
                      </div>
                    </div>
                  )}

                  <div className="cmnt_main">
                    <div className="cmt-text-area">
                      {/* {selectedTicketAllocationAttchment ||
                      (selectedTicketAttchment && ( */}
                      {/* <div className="animate-progress" /> */}
                      {/* ))} */}
                      <div className="cmt_padding">
                        <div className="title-cmnt">
                          <span
                            type="button"
                            onClick={(f) => {
                              f.stopPropagation();
                              getMultyUsers(e?.user);
                              dispatch(
                                userProfileActions.setShowUserProfile(true),
                              );
                            }}
                          >
                            {
                              teamData?.projectAggregation?.find(
                                (user) => user?.userId === e?.user,
                              )?.user
                            }
                          </span>{" "}
                        </div>
                        {hasPermission(
                          permissionConstants.permissions
                            .TASK_DETAILS_MODAL_DELETE_COMMENTS,
                        ) && (
                          <div className="hover-action-toolbar">
                            <button
                              type="button"
                              className="btn cta"
                              onClick={() => setDeleteModal(e._id)}
                            >
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="7.902"
                                  height="9.708"
                                  viewBox="0 0 7.902 9.708"
                                >
                                  <g
                                    id="Icon"
                                    transform="translate(-3.25 -1.25)"
                                  >
                                    <path
                                      id="Path_45"
                                      data-name="Path 45"
                                      d="M11.249,5.589a.339.339,0,0,0-.339-.339H4.589a.339.339,0,0,0-.339.339v6.1a1.468,1.468,0,0,0,1.467,1.467H9.781a1.468,1.468,0,0,0,1.467-1.467Zm-.677.339v5.757a.791.791,0,0,1-.79.79H5.717a.791.791,0,0,1-.79-.79V5.927Z"
                                      transform="translate(-0.548 -2.194)"
                                      fill="#ff8079"
                                      fillRule="evenodd"
                                    />
                                    <path
                                      id="Path_46"
                                      data-name="Path 46"
                                      d="M11.152,3.733a.339.339,0,0,0,.339-.339v-.9A1.241,1.241,0,0,0,10.249,1.25H7.992A1.241,1.241,0,0,0,6.75,2.492v.9a.339.339,0,0,0,.339.339Zm-.339-.677H7.427V2.492a.564.564,0,0,1,.564-.564h2.258a.564.564,0,0,1,.564.564Z"
                                      transform="translate(-1.92)"
                                      fill="#ff8079"
                                      fillRule="evenodd"
                                    />
                                    <path
                                      id="Path_47"
                                      data-name="Path 47"
                                      d="M10.813,5.25H3.589a.339.339,0,1,0,0,.677h7.224a.339.339,0,1,0,0-.677Z"
                                      transform="translate(0 -2.194)"
                                      fill="#ff8079"
                                      fillRule="evenodd"
                                    />
                                    <path
                                      id="Path_48"
                                      data-name="Path 48"
                                      d="M8.75,10.089v3.161a.339.339,0,1,0,.677,0V10.089a.339.339,0,0,0-.677,0Z"
                                      transform="translate(-3.017 -4.662)"
                                      fill="#ff8079"
                                      fillRule="evenodd"
                                    />
                                    <path
                                      id="Path_49"
                                      data-name="Path 49"
                                      d="M13.75,10.089v3.161a.339.339,0,1,0,.677,0V10.089a.339.339,0,0,0-.677,0Z"
                                      transform="translate(-5.759 -4.662)"
                                      fill="#ff8079"
                                      fillRule="evenodd"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </div>
                        )}
                        <div className="cmnt-text">
                          {replaceUserMentions(e.comment?.text)}
                        </div>

                        {e.comment.attachment?.length > 0 &&
                          e.comment.attachment?.map((each) => (
                            <div
                              className={`image-attached ${
                                ["jpg", "pdf", "jpeg"].includes(
                                  each.name?.split(".")[1],
                                )
                                  ? "attachment-comment"
                                  : ""
                              } ${
                                !["jpg", "png", "jpeg"].includes(
                                  each.name?.split(".")[1],
                                )
                                  ? "pdf"
                                  : ""
                              }`}
                            >
                              <Link
                                to={each.path}
                                className="btn download-attachments"
                                download
                                onClick={() => handleFileDownload(each)}
                              >
                                {downloadingFile === each ? (
                                  <span className="btn-loader m-0">
                                    <span className="spinner-border spinner-border-sm m-0" />
                                  </span>
                                ) : (
                                  <Download />
                                )}
                              </Link>
                              {!isImageLoaded[each._id] &&
                                Array.from({
                                  length: e.comment.attachment?.length,
                                }).map((_, i) => (
                                  <div key={i}>
                                    <CommonSkeleton width={180} height={100} />
                                  </div>
                                ))}
                              {["jpg", "png", "jpeg"].includes(
                                each.name?.split(".")[1],
                              ) ? (
                                <img
                                  src={each.path}
                                  alt={each?.name}
                                  onLoad={() =>
                                    setIsImageLoaded((prev) => ({
                                      ...prev,
                                      [each._id]: true,
                                    }))
                                  }
                                />
                              ) : (
                                <img
                                  src={getFileIcon(each?.name?.split(".")[1])}
                                  alt={each?.name}
                                  onLoad={() =>
                                    setIsImageLoaded((prev) => ({
                                      ...prev,
                                      [each._id]: true,
                                    }))
                                  }
                                />
                              )}
                              {console.log(
                                "jjjjj",
                                convertBytesToKBMB(each?.size),
                              )}

                              <span className="attachInfo">
                                <span className="attachName">{each?.name}</span>
                                {["jpg", "pdf", "jpeg"].includes(
                                  each.name?.split(".")[1],
                                ) && (
                                  <span className="attachTime">
                                    {convertBytesToKBMB(each?.size)}
                                  </span>
                                )}
                              </span>
                            </div>
                          ))}
                        <div className="time">{formatCommentTime(e.date)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </OutsideClickHandler>
      </div>

      {isGetTicket || isGetTicketAllocation ? (
        <CommonSkeleton height={100} />
      ) : (
        <>
          <span className="error text-danger">
            {fileError && <span>{fileError}</span>}
          </span>

          {hasPermission(
            permissionConstants.permissions.TASK_DETAILS_MODAL_ADD_COMMENTS,
          ) && (
            <div className="chat-block-new">
              {(selectedTicketAllocationAttchment ||
                selectedTicketAttchment) && (
                <div className="animate-progress top-position" />
              )}
              <UserDetail
                TaskData={[]}
                dropDownVisibility={atPressed}
                addUser
                setAddUser={setAddUser}
                ticket={ticket}
                assignedUserClick={mentionClick}
                onAllocateUser={(userId) => {
                  const user = teamData?.projectAggregation.find(
                    (e) => e.userId === userId.user,
                  );

                  if (user && atText) {
                    const updatedMessage = message.replace(
                      `@${atText}`,
                      `@${user.user}`,
                    );
                    setMessage(updatedMessage);
                    setAtText("");
                  } else {
                    setMessage((prevMessage) => {
                      const lastIndex = prevMessage.lastIndexOf("@");
                      if (lastIndex !== -1) {
                        const messageAfterLastAt = prevMessage.substring(
                          lastIndex + 1,
                        );
                        if (!messageAfterLastAt.includes(" ")) {
                          const updatedMessage = `${prevMessage.substring(
                            0,
                            lastIndex,
                          )}@${user.user} `;
                          return updatedMessage;
                        }
                      }

                      return `${prevMessage}@${user.user} ${" "}`;
                    });
                    setAtText("");
                  }
                  setAtPressed(false);
                }}
                onDeallocateUser={() => {}}
                atText={atText}
              />
              <textarea
                className="chat-input"
                placeholder="Comment"
                onClick={(event) => {
                  if (NewTab !== "newTab") {
                    event.stopPropagation();
                    setShowFooter(true);
                  }
                }}
                value={message}
                disabled={NewTab === "newTab"}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "@") {
                    const isAtStartOfWordOrSentence =
                      message.charAt(e.target.selectionStart - 1).match(/\s/) ||
                      e.target.selectionStart === 0;

                    if (isAtStartOfWordOrSentence) {
                      setAtPressed(true);
                      setAtText("");
                    } else {
                      setAtPressed(false);
                    }
                  } else if (atPressed && e.key === " ") {
                    setAtPressed(false);
                  } else if (e.key === "Backspace") {
                    const caretPosition = e.target.selectionStart;
                    const deletedChar = message.charAt(caretPosition - 1);
                    if (deletedChar === "@") {
                      setAtPressed(false);
                    }
                  } else if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleCommentSend();
                  }
                }}
              />
              {selectedFiles.length > 0 && (
                <div className="fileAttachments">
                  <ul className="files">
                    {selectedFiles.map((file, index) => (
                      <li key={file.id}>
                        <span className="fileType">
                          {["jpg", "png", "jpeg"].includes(
                            file.name.split(".")[1],
                          ) ? (
                            <img src={image[index]} alt="ImageDescription" />
                          ) : (
                            <img
                              src={getFileIcon(
                                file.name.split(".").pop().toLowerCase(),
                              )}
                              alt=""
                            />
                          )}
                        </span>
                        <span className="fileName">{file.name}</span>
                        <button
                          type="button"
                          className="close"
                          onClick={(e) => {
                            if (NewTab !== "newTab") {
                              handleRemoveFile(file.name, "file");
                              e.stopPropagation();
                            }
                          }}
                        >
                          <svg
                            width="10.457"
                            height="10.458"
                            viewBox="0 0 10.457 10.458"
                          >
                            <path
                              id="close"
                              d="M12.5,11.529l4.054-4.054a.688.688,0,1,0-.973-.973l-4.054,4.054L7.475,6.5a.688.688,0,0,0-.973.973l4.054,4.054L6.5,15.583a.688.688,0,1,0,.972.973L11.528,12.5l4.054,4.054a.688.688,0,0,0,.972-.973L12.5,11.529Z"
                              transform="translate(-6.3 -6.3)"
                              fill="currentcolor"
                            />
                          </svg>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {showFooter && (
                <div className="cmnt_footer">
                  <div className="btn-group">
                    {hasPermission(
                      permissionConstants.permissions
                        .TASK_DETAILS_MODAL_COMMENTS_MENTIONS,
                    ) && (
                      <button
                        className="emo"
                        type="button"
                        onClick={(e) => {
                          setAtPressed(!atPressed);
                          setMentionClick(!mentionClick);
                          setShowEmojiPicker(false);
                          setAtText("");
                          e.stopPropagation();
                        }}
                      >
                        <img src={assignedUser} alt="" />
                      </button>
                    )}
                    {hasPermission(
                      permissionConstants.permissions
                        .TASK_DETAILS_MODAL_COMMENTS_EMOJIS,
                    ) && (
                      <button
                        className={`emo ${showEmojiPicker && "active-emo"}`}
                        type="button"
                        onClick={(e) => {
                          toggleEmojiPicker(e);
                          e.stopPropagation();
                        }}
                      >
                        <img src={emoji} alt="" />
                      </button>
                    )}
                    <input
                      multiple
                      type="file"
                      onChange={handleFileSelect}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    {hasPermission(
                      permissionConstants.permissions
                        .TASK_DETAILS_MODAL_COMMENTS_ATTACHMENTS,
                    ) && (
                      <button
                        className="attch"
                        type="button"
                        onClick={(e) => {
                          fileInputRef.current.click();
                          setShowFooter(true);
                          e.stopPropagation();
                        }}
                      >
                        <img src={attach} alt="" />
                      </button>
                    )}
                  </div>
                  <button
                    className="comment-send"
                    type="button"
                    onClick={handleCommentSend}
                  >
                    COMMENT
                  </button>
                </div>
              )}

              {showEmojiPicker && (
                <Picker
                  onEmojiClick={handleEmojiClick}
                  disableSearchBar
                  native
                  autoFocus
                  width={800}
                  lazyLoadEmojis
                />
              )}
              <DeleteModal
                name="message"
                onDelete={handleMessageDelete}
                onCancel={() => setDeleteModal(false)}
                show={deletedModal}
                message="Are you sure you want to delete the comment?"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
ChatSection.propTypes = {
  isGetTicket: PropTypes.bool,
  isGetTicketAllocation: PropTypes.bool,
  setTicketAllocationDatas: PropTypes.func,
  ticket: PropTypes.bool,
  setAddUser: PropTypes.func,
  NewTab: PropTypes.string,
  hasPermission: PropTypes.func,
};
export default ChatSection;
