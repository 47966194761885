/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import LanguageContext from "../../context/LanguageContext";
import { getFirstLetters } from "../../utils/utils";
import useTitleContext from "../../hooks/useTitle";
import { NoProj } from "./dashBIcons";

function ProfileCard({ data, projectDetails, leaderboard }) {
  const { title } = useTitleContext();

  const {
    languageData: { profile, projectManagement },
  } = useContext(LanguageContext);

  const {
    colors: { colorData },
  } = useSelector((state) => state);

  const route = useLocation();
  const { shortCode } = useParams();

  // image error handling
  const [imgError, setimgError] = useState(false);

  const handleOnError = () => {
    setimgError(true);
  };

  return (
    <div className="profile-card d-flex flex-column">
      <div className="top">
        <h2 className="card-title">{profile?.title}</h2>
        <p className="card-s-title">
          {route.pathname === `/project/${shortCode}`
            ? projectManagement.projectTitle
            : profile.projectTitle}
        </p>
      </div>
      <div className="btm-info">
        <div className="profile-pic">
          {route.pathname === `/project/${shortCode}` ? (
            projectDetails?.photo && !imgError ? (
              <img
                onError={handleOnError}
                src={`https://erptest.spericorn.com/repo/uploads/${projectDetails?.photo}`}
                alt="profile pic"
              />
            ) : (
              <div
                className="profTxt no-proj"
                style={{
                  backgroundColor: colorData?.find(
                    (e) => e._id === projectDetails?.primaryColor,
                  )?.colorCode,
                }}
              >
                <NoProj />
                {/* {getFirstLetters(title)} */}
              </div>
            )
          ) : data?.photo && !imgError ? (
            <img
              onError={handleOnError}
              src={`https://erptest.spericorn.com/repo/uploads/${data?.photo}`}
              alt="profile pic"
            />
          ) : (
            <div
              className="profTxt"
              style={{
                backgroundColor: colorData?.find(
                  (e) => e._id === data?.primaryColor,
                )?.colorCode,
              }}
            >
              {getFirstLetters(data?.employeeName)}
            </div>
          )}
        </div>
        <div className="user-info">
          <ul className="d-flex no-list-usr">
            <li>
              <h2 className="usr-name">
                {route.pathname === `/project/${shortCode}`
                  ? title
                  : data?.employeeName}
              </h2>
              <p className="usr-title">{data?.role?.name}</p>
            </li>
            <li>
              <span className="count">{data?.employeeId || "-"}</span>
              <p className="c-data">
                {route.pathname === `/project/${shortCode}`
                  ? "Client Name"
                  : profile.companyId}
              </p>
            </li>
            <li>
              <span className="count">
                {route.pathname !== `/project/${shortCode}`
                  ? leaderboard?.ranking || "-"
                  : projectDetails?.projectManager || "-"}
              </span>
              <p className="c-data">
                {route.pathname === `/project/${shortCode}`
                  ? // ? projectManagement.totalTasks
                    "Project Manager "
                  : profile.leaderboard}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
ProfileCard.propTypes = {
  data: PropTypes.shape({
    employeeName: PropTypes.string,
    primaryColor: PropTypes.string,
    photo: PropTypes.string,
    _id: PropTypes.string,
    role: PropTypes.shape({
      name: PropTypes.string,
    }),
    employeeId: PropTypes.string,
  }),
  leaderboard: PropTypes.shape({
    ranking: PropTypes.number,
  }),
};

export default ProfileCard;
