import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertActions } from "../../alert";
import { setdeleteFeed } from "..";
import { deleteFeedService } from "../../../../services/feedService";
import { savedFeedOfUser } from "./savedFeed";

export const deleteFeed = createAsyncThunk(
  "delete/feed",
  async ({ feedId, userId }, { dispatch }) => {
    try {
      const { data: responseData } = await deleteFeedService(feedId);
      const successMessage = responseData.message;
      dispatch(alertActions.success(successMessage));
      dispatch(setdeleteFeed(responseData));
      dispatch(savedFeedOfUser({ params: userId }));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
