/* eslint-disable no-nested-ternary */
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Comment, Like } from "../../pages/Feed/svg";
import userWithoutName from "../../assets/images/userWithoutImage.svg";
import { useThunk } from "../../hooks/useThunk";
import smile from "../../assets/images/emo-smile.svg";
import kiss from "../../assets/images/emo-kiss.svg";
import heart from "../../assets/images/emo-heart.svg";
import dislike from "../../assets/images/emo-dislike.svg";
import angry from "../../assets/images/emo-angry.svg";
import like from "../../assets/images/emo-like.svg";
import threeDots from "../../assets/images/three-dots.svg";
import { getMultyUserProfile } from "../../store";
import { userProfileActions } from "../../store/slices/userProfile";
import CustomModal from "../CustomModal";
import CommonSkeleton from "../SkeletonLoader/CommonSkeleton";
import CommentSection from "./Comment";
import { setSuccessMessage } from "../../store/slices/feed";
import { getFeedById } from "../../store/slices/feed/thunks/getFeedById";
import EditFeed from "../../pages/Feed/editFeed";
import DeleteModal from "../../pages/Feed/deleteFeed";
import { replaceUserMentions } from "./Common";
import { getFirstLetters } from "../../utils/utils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import NoDataPage from "../../pages/NoDataPage/NoDataPage";

const PostList = ({
  listData,
  isGet,
  handleLikePatch,
  isPatch,
  isFeed,
  handleDeleteFeed,
  setSelectDropdownOption,
  selectDropdownOption,
  allUsers,
  type,
  setShowComment,
  showComment,
  hasPermission,
  isPostSuccess,
}) => {
  console.log("listData", isPostSuccess);
  const dispatch = useDispatch();
  const { authData } = useSelector((state) => state.auth);
  const [showReactions, setShowReactions] = useState(false);
  const [fileError, setFileError] = useState("");
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLikesDropdown, setShowLikesDropdown] = useState(false);
  const [patch, setPatch] = useState({ type: "", id: "" });
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [numCommentsToShow, setNumCommentsToShow] = useState(5);

  const [startIndex, setStartIndex] = useState(0);

  const [FeedById] = useThunk(getFeedById);
  const [imgLoading, setImgLoading] = useState(false);
  const [firstImgLoading, setFirstImgLoading] = useState(false);

  useEffect(() => {
    setImgLoading(true);
    const timeoutId = setTimeout(() => {
      setImgLoading(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [isGet]);

  useEffect(() => {
    if (isPostSuccess) {
      setFirstImgLoading(true);
      const timeoutId = setTimeout(() => {
        setFirstImgLoading(false);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [isPostSuccess]);

  const handleDropdownToggle = (eId) => {
    setShowDropdown((prevState) => (prevState === eId ? null : eId));
  };
  const MoreBtnClick = (e) => {
    setNumCommentsToShow((prevNum) => prevNum + 5);
    FeedById({ id: e, params: { number: numCommentsToShow + 5 }, type });
  };
  const reactionIcons = [
    { name: "like", emoji: like },
    { name: "heart", emoji: heart },
    { name: "dislike", emoji: dislike },
    { name: "kiss", emoji: kiss },
    { name: "smile", emoji: smile },
    { name: "angry", emoji: angry },
  ];
  const handleCountClick = (index) => {
    setSelectedImageIndex(index);
    setIsGalleryOpen(true);
  };
  const handleLikeHover = (id) => {
    setShowReactions(id);
  };
  const renderBody = () => (
    <Gallery
      items={galleryImages}
      onClose={() => setIsGalleryOpen(false)}
      startIndex={startIndex}
    />
  );

  const handleLikeLeave = () => {
    setShowReactions(false);
  };

  function formatPostTime(commentDate) {
    const currentDate = new Date();
    const commentTime = new Date(commentDate);

    const isToday = currentDate.toDateString() === commentTime.toDateString();

    if (isToday) {
      const timeDifference = Math.floor((currentDate - commentTime) / 1000);

      if (timeDifference < 60) {
        return "just now";
      }
      if (timeDifference < 3600) {
        const minutesAgo = Math.floor(timeDifference / 60);
        return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
      }
      const hoursAgo = Math.floor(timeDifference / 3600);
      return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    }

    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return commentTime.toLocaleString("en-US", options);
  }
  return (
    <div
      className="feed-container "
      onClick={() => {
        setFileError("");
        setShowComment("");
      }}
    >
      {isGet && !listData ? (
        Array.from({ length: listData?.data?.updatedData?.length || 0 })?.map(
          (_, index) => (
            <div className="feeds-lists" key={index}>
              <div className="profile-head">
                <div className="user-profile" key={index}>
                  <div className="user-pic">
                    <CommonSkeleton className="circle-skeleton" />
                  </div>
                  <div className="user-info">
                    <div className="title" type="button">
                      <CommonSkeleton width={300} />
                    </div>
                    <div className="last-update">
                      <CommonSkeleton width={300} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-feed-blk">
                <div className="txt-only">
                  <CommonSkeleton width={800} height={140} />
                  <div className="public-action-blk">
                    <ul className="like-comment">
                      <li className="action-link">
                        <span className="count">
                          <CommonSkeleton width={70} />
                        </span>
                      </li>
                      <li className="action-link">
                        <CommonSkeleton width={150} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ),
        )
      ) : listData?.data?.updatedData ? (
        listData?.data?.updatedData?.length > 0 ? (
          listData?.data?.updatedData?.map((e, feedIndex) => {
            return (
              <>
                <div
                  className="feeds-lists"
                  key={feedIndex}
                  onMouseLeave={handleLikeLeave}
                >
                  <div className="profile-head">
                    <div className="user-profile">
                      <div
                        className="user-pic"
                        onClick={(f) => {
                          f.stopPropagation();
                          getMultyUsers(e.postedBy?._id);
                          dispatch(userProfileActions.setShowUserProfile(true));
                        }}
                      >
                        {e?.postedBy?.photo ? (
                          <div className="user-pic">
                            <img
                              src={`https://erptest.spericorn.com/repo/uploads/${e.postedBy?.photo}`}
                              alt="profile-pic"
                            />
                          </div>
                        ) : (
                          <div className="avathar-sm avathar-01">
                            {e.postedBy?.employeeName ? (
                              <span>
                                {getFirstLetters(e.postedBy?.employeeName)}
                              </span>
                            ) : (
                              <img src={userWithoutName} alt="" />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="user-info">
                        <div
                          className="title"
                          type="button"
                          onClick={(f) => {
                            f.stopPropagation();
                            getMultyUsers(e.postedBy?._id);
                            dispatch(
                              userProfileActions.setShowUserProfile(true),
                            );
                          }}
                        >
                          {e.postedBy?.employeeName}
                        </div>
                        <div className="last-update">
                          {formatPostTime(e.createdAt)}
                        </div>
                      </div>
                    </div>
                    {e.postedBy?._id === authData?._id && isFeed && (
                      <div className="menu-rt">
                        <Dropdown
                          show={showDropdown}
                          onToggle={() => handleDropdownToggle(e._id)}
                          className="dropdown"
                        >
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="option-menu dropdown-toggle"
                          >
                            <img src={threeDots} alt="" />
                          </Dropdown.Toggle>
                          {showDropdown === e._id && (
                            <Dropdown.Menu className="dropdown-menu edit-del">
                              <Dropdown.Item
                                className="dropdown-item"
                                onClick={() => {
                                  dispatch(setSuccessMessage(""));
                                  setSelectDropdownOption({
                                    type: "edit",
                                    id: e._id,
                                  });
                                  FeedById({ id: e._id });
                                }}
                              >
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 14.254 14.254"
                                  >
                                    <g
                                      id="Group_60235"
                                      data-name="Group 60235"
                                      transform="translate(-2 -2)"
                                    >
                                      <path
                                        id="Path_10621"
                                        data-name="Path 10621"
                                        d="M10.719,7.57l-.681,2.045a.718.718,0,0,0,.9.9l2.045-.681a.718.718,0,0,0,.278-.172l4.8-4.8a1.676,1.676,0,0,0-2.37-2.371l-4.8,4.8A.713.713,0,0,0,10.719,7.57Z"
                                        transform="translate(-2.3)"
                                        fill="currentcolor"
                                      />
                                      <path
                                        id="Path_10622"
                                        data-name="Path 10622"
                                        d="M15.541,6.989a.713.713,0,0,0-.713.713V12.69a2.138,2.138,0,0,1-2.138,2.138H5.563A2.138,2.138,0,0,1,3.425,12.69V5.563A2.138,2.138,0,0,1,5.563,3.425h4.989a.713.713,0,1,0,0-1.425H5.563A3.568,3.568,0,0,0,2,5.563V12.69a3.568,3.568,0,0,0,3.563,3.563H12.69a3.568,3.568,0,0,0,3.563-3.563V7.7A.713.713,0,0,0,15.541,6.989Z"
                                        fill="currentcolor"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectDropdownOption({
                                    type: "delete",
                                    id: e._id,
                                  });
                                }}
                              >
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="trash"
                                    width="14.66"
                                    height="14.66"
                                    viewBox="0 0 14.66 14.66"
                                  >
                                    <path
                                      id="Vector"
                                      d="M11,.305C8.961.1,6.915,0,4.875,0A36.082,36.082,0,0,0,1.246.183L0,.305"
                                      transform="translate(1.833 3.347)"
                                      fill="none"
                                      stroke="#de4841"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Vector-2"
                                      data-name="Vector"
                                      d="M0,1.814l.134-.8C.232.434.305,0,1.338,0h1.6c1.032,0,1.112.458,1.2,1.02l.134.794"
                                      transform="translate(5.192 1.222)"
                                      fill="none"
                                      stroke="#de4841"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Vector-3"
                                      data-name="Vector"
                                      d="M8.369,0l-.4,6.151c-.067.959-.122,1.7-1.826,1.7H2.223C.519,7.856.464,7.11.4,6.151L0,0"
                                      transform="translate(3.146 5.583)"
                                      fill="none"
                                      stroke="#de4841"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Vector-4"
                                      data-name="Vector"
                                      d="M0,0H2.034"
                                      transform="translate(6.31 10.079)"
                                      fill="none"
                                      stroke="#de4841"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Vector-5"
                                      data-name="Vector"
                                      d="M0,0H3.054"
                                      transform="translate(5.803 7.636)"
                                      fill="none"
                                      stroke="#de4841"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Vector-6"
                                      data-name="Vector"
                                      d="M0,0H14.66V14.66H0Z"
                                      fill="none"
                                      opacity="0"
                                    />
                                  </svg>
                                </span>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                        {selectDropdownOption?.type === "edit" &&
                          selectDropdownOption?.id === e._id && (
                            <EditFeed
                              selectDropdownOption={selectDropdownOption}
                              setSelectDropdownOption={setSelectDropdownOption}
                              authData={authData}
                            />
                          )}
                        {selectDropdownOption?.type === "delete" &&
                          selectDropdownOption?.id === e._id && (
                            <DeleteModal
                              onCancel={() =>
                                setSelectDropdownOption({ type: "", id: "" })
                              }
                              show={selectDropdownOption?.type === "delete"}
                              onDelete={() => handleDeleteFeed(e._id)}
                              text="Are you sure you want to delete this post?"
                            />
                          )}
                      </div>
                    )}
                  </div>
                  <div className="user-feed-blk">
                    <div className="txt-only">
                      {replaceUserMentions(
                        e?.title,
                        allUsers,
                        getMultyUsers,
                        dispatch,
                      )}
                    </div>
                    <div
                      className={`album-set ${
                        e?.attachments?.length === 1 ? "full-width-img" : ""
                      }${
                        e?.attachments?.length > 1 && !e?.attachments[2]
                          ? "double-img"
                          : ""
                      }`}
                    >
                      {e?.attachments?.map((each, index) =>
                        // eslint-disable-next-line no-nested-ternary
                        e?.attachments?.length === 1 || index === 0 ? (
                          <div className="img-single">
                            {imgLoading ||
                            (firstImgLoading && feedIndex === 0) ? (
                              <CommonSkeleton
                                width={type === "feed" ? 500 : 300}
                                height={type === "feed" ? 400 : 300}
                              />
                            ) : (
                              <img
                                src={each?.path}
                                alt="thumb img"
                                onLoad={(event) => {
                                  event.target.style.display = "block";
                                }}
                                onClick={() => {
                                  handleCountClick(0);
                                  setGalleryImages(
                                    e?.attachments?.map((each) => ({
                                      original: each?.path,
                                      thumbnail: each?.path,
                                    })),
                                  );
                                  setStartIndex(index);
                                  setIsGalleryOpen(true);
                                }}
                              />
                            )}
                          </div>
                        ) : (
                          e?.attachments?.length > 1 &&
                          index === 1 && (
                            <div className="img-col">
                              <div className="col-img">
                                {imgLoading ? (
                                  <CommonSkeleton
                                    width={350}
                                    height={type === "feed" ? 150 : 130}
                                  />
                                ) : (
                                  <img
                                    src={e?.attachments[1]?.path}
                                    alt="thumb img"
                                    onLoad={(target) => {
                                      target.target.style.display = "block";
                                    }}
                                    onClick={() => {
                                      handleCountClick(0);
                                      setGalleryImages(
                                        e?.attachments?.map((each) => ({
                                          original: each?.path,
                                          thumbnail: each?.path,
                                        })),
                                      );
                                      setStartIndex(index);
                                      setIsGalleryOpen(true);
                                    }}
                                  />
                                )}
                              </div>
                              {e?.attachments[2] && (
                                <div className="col-img more">
                                  {imgLoading ? (
                                    <CommonSkeleton
                                      width={350}
                                      height={type === "feed" ? 190 : 130}
                                    />
                                  ) : (
                                    <img
                                      src={e?.attachments[2]?.path}
                                      alt="thumb img"
                                      onLoad={(target) => {
                                        target.target.style.display = "block";
                                      }}
                                      onClick={() => {
                                        handleCountClick(0);
                                        setGalleryImages(
                                          e?.attachments?.map((each) => ({
                                            original: each?.path,
                                            thumbnail: each?.path,
                                          })),
                                        );
                                        setStartIndex(2);
                                        setIsGalleryOpen(true);
                                      }}
                                    />
                                  )}
                                  {e?.attachments &&
                                    e.attachments.length > 3 && (
                                      <div
                                        className="count"
                                        onClick={() => {
                                          handleCountClick(0);
                                          setGalleryImages(
                                            e?.attachments?.map((each) => ({
                                              original: each?.path,
                                              thumbnail: each?.path,
                                            })),
                                          );
                                          setStartIndex(0);
                                          setIsGalleryOpen(true);
                                        }}
                                      >
                                        +{e.attachments.length - 3}
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                          )
                        ),
                      )}
                      {showReactions === e._id && (
                        <div
                          className="reaction-sets"
                          onMouseLeave={handleLikeLeave}
                          onMouseEnter={(event) =>
                            handleLikeHover(e._id, event)
                          }
                        >
                          {reactionIcons?.map((reaction, index) => (
                            <div
                              key={index}
                              className="reaction-icon"
                              onClick={() =>
                                handleLikePatch(e._id, reaction?.name)
                              }
                              type="button"
                            >
                              <img src={reaction.emoji} alt="" />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="public-action-blk">
                    <ul className="like-comment">
                      {hasPermission(
                        permissionConstants.permissions.FEED_VIEW_LIKES,
                      ) && (
                        <li className="action-link">
                          <span
                            className="icon"
                            onMouseEnter={(event) =>
                              handleLikeHover(e._id, event)
                            }
                            style={{ display: "flex" }}
                            type="button"
                          >
                            {e.likes?.find(
                              (like) => like.user === authData?._id,
                            ) ? (
                              <span
                                key={feedIndex}
                                onClick={() => {
                                  if (
                                    hasPermission(
                                      permissionConstants.permissions
                                        .FEED_ADD_LIKES,
                                    )
                                  ) {
                                    setPatch({ type: "", id: "" });
                                    handleLikePatch(
                                      e._id,
                                      reactionIcons.find(
                                        (icon) =>
                                          icon.name ===
                                          e.likes?.find(
                                            (like) =>
                                              like.user === authData?._id,
                                          )?.emoji,
                                      )?.name,
                                    );
                                  }
                                }}
                              >
                                <img
                                  src={
                                    reactionIcons.find(
                                      (icon) =>
                                        icon.name ===
                                        e.likes?.find(
                                          (like) => like.user === authData?._id,
                                        )?.emoji,
                                    )?.emoji
                                  }
                                  alt=""
                                />
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  if (
                                    hasPermission(
                                      permissionConstants.permissions
                                        .FEED_ADD_LIKES,
                                    )
                                  ) {
                                    setPatch({ type: "", id: "" });
                                    handleLikePatch(
                                      e._id,
                                      reactionIcons.find(
                                        (icon) => icon.name === "heart",
                                      )?.name,
                                    );
                                  }
                                }}
                              >
                                <Like />
                              </span>
                            )}
                          </span>
                          <span className="count">{e.likeCount}</span>
                          <Dropdown
                            show={showLikesDropdown}
                            onToggle={() =>
                              setShowLikesDropdown((prevState) =>
                                prevState === e._id ? null : e._id,
                              )
                            }
                          >
                            <Dropdown.Toggle>
                              {e.likeCount === 1 ? "Like" : "Likes"}
                            </Dropdown.Toggle>
                            {e.likeCount > 0 && showLikesDropdown === e._id ? (
                              <Dropdown.Menu>
                                <ul className="reaction-drp custom-scroll">
                                  {e.likes?.map((like) => (
                                    <li className="dropdown-item">
                                      <div className="profile-pic">
                                        {like?.photo ? (
                                          <div
                                            className="user-pic "
                                            onClick={(f) => {
                                              f.stopPropagation();
                                              getMultyUsers(like._id);
                                              dispatch(
                                                userProfileActions.setShowUserProfile(
                                                  true,
                                                ),
                                              );
                                            }}
                                          >
                                            <img
                                              src={`https://erptest.spericorn.com/repo/uploads/${like?.photo}`}
                                              alt="profile-pic"
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            className="avathar-sm avathar-01"
                                            onClick={(f) => {
                                              f.stopPropagation();
                                              getMultyUsers(like._id);
                                              dispatch(
                                                userProfileActions.setShowUserProfile(
                                                  true,
                                                ),
                                              );
                                            }}
                                          >
                                            {like?.userName && (
                                              <span>
                                                {getFirstLetters(
                                                  like?.userName,
                                                )}
                                              </span>
                                            )}
                                            {formatPostTime(like?.date)}
                                          </div>
                                        )}
                                        <div className="reaction-icon">
                                          <img
                                            src={
                                              reactionIcons.find(
                                                (icon) =>
                                                  icon.name === like.emoji,
                                              )?.emoji
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="profile-info">
                                        <div
                                          className="title"
                                          onClick={(f) => {
                                            f.stopPropagation();
                                            getMultyUsers(like.user);
                                            dispatch(
                                              userProfileActions.setShowUserProfile(
                                                true,
                                              ),
                                            );
                                          }}
                                        >
                                          {like.userName ===
                                          authData?.employeeName
                                            ? "You"
                                            : like.userName}{" "}
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </Dropdown.Menu>
                            ) : null}
                          </Dropdown>
                        </li>
                      )}
                      {hasPermission(
                        permissionConstants.permissions.FEED_VIEW_COMMENTS,
                      ) && (
                        <li
                          className="action-link"
                          onClick={(each) => {
                            setShowComment(e._id);
                            each.stopPropagation();
                            setNumCommentsToShow(5);
                          }}
                          type="button"
                        >
                          <span className="icon">
                            <Comment />
                          </span>
                          <span className="count">{e.commentLength || 0}</span>
                          Comments
                        </li>
                      )}
                    </ul>
                    {isFeed &&
                      hasPermission(
                        permissionConstants.permissions.FEED_ADD_COMMENTS,
                      ) && (
                        <ul className="mb-0">
                          <li
                            className="action-link"
                            onClick={() => {
                              setPatch({ type: "saved", id: e._id });
                              handleLikePatch(e._id, null, authData?._id);
                            }}
                          >
                            {isPatch &&
                            patch.type === "saved" &&
                            patch.id === e._id ? (
                              <span className="btn-loader">
                                <span className="spinner-border spinner-border-sm" />
                              </span>
                            ) : (
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.294"
                                  height="17.402"
                                  viewBox="0 0 15.294 17.402"
                                >
                                  <path
                                    id="Vector"
                                    d="M10.721,0H3.065A3.072,3.072,0,0,0,0,3.065V14.255c0,1.429,1.024,2.033,2.279,1.342l3.875-2.152a1.682,1.682,0,0,1,1.485,0L11.515,15.6c1.255.7,2.279.1,2.279-1.342V3.065A3.084,3.084,0,0,0,10.721,0Z"
                                    transform="translate(0.75 0.75)"
                                    fill={
                                      Array.isArray(e.saved) &&
                                      e.saved?.includes(authData?._id)
                                        ? "#AC80F7"
                                        : "none"
                                    }
                                    // fill="none"
                                    stroke={
                                      Array.isArray(e.saved) &&
                                      e.saved?.includes(authData?._id)
                                        ? "#AC80F7"
                                        : "currentcolor"
                                    }
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </span>
                            )}
                            <span
                              style={{
                                color:
                                  Array.isArray(e.saved) &&
                                  e.saved?.includes(authData?._id)
                                    ? "#AC80F7"
                                    : "",
                              }}
                            >
                              Saved
                            </span>
                          </li>
                        </ul>
                      )}
                  </div>

                  {showComment === e._id && (
                    <CommentSection
                      feedId={e._id}
                      commentData={e.comments}
                      authData={authData}
                      setShowComment={setShowComment}
                      MoreBtnClick={() => MoreBtnClick(e._id)}
                      commentLength={e.commentLength}
                      numCommentsToShow={numCommentsToShow}
                      hasPermission={hasPermission}
                    />
                  )}
                  <span className="error text-danger w-100">
                    {fileError && <span>{fileError}</span>}
                  </span>
                  {selectedImageIndex === feedIndex && (
                    <CustomModal
                      className="modal fade delete-conf gallery-slider-modal"
                      dialgName="modal-dialog-centered modal-lg"
                      body={renderBody()}
                      bodyClassname="modal-body"
                      createModal={7}
                      disableCenter={7}
                      show={isGalleryOpen}
                      closeButton
                      closeModal={() => setIsGalleryOpen(false)}
                    />
                  )}
                </div>
                {isGet &&
                  listData &&
                  Array.from({
                    length: 5 || 0,
                  })?.map((_, index) => (
                    <div className="feeds-lists" key={index}>
                      <div className="profile-head">
                        <div className="user-profile" key={index}>
                          <div className="user-pic">
                            <CommonSkeleton className="circle-skeleton" />
                          </div>
                          <div className="user-info">
                            <div className="title" type="button">
                              <CommonSkeleton width={300} />
                            </div>
                            <div className="last-update">
                              <CommonSkeleton width={300} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-feed-blk">
                        <div className="txt-only">
                          <CommonSkeleton width={800} height={140} />
                          <div className="public-action-blk">
                            <ul className="like-comment">
                              <li className="action-link">
                                <span className="count">
                                  <CommonSkeleton width={70} />
                                </span>
                              </li>
                              <li className="action-link">
                                <CommonSkeleton width={150} />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            );
          })
        ) : (
          <NoDataPage />
          // Array.from({
          //   length: 5 || 0,
          // })?.map((_, index) => (
          //   <div className="feeds-lists" key={index}>
          //     <div className="profile-head">
          //       <div className="user-profile" key={index}>
          //         <div className="user-pic">
          //           <CommonSkeleton className="circle-skeleton" />
          //         </div>
          //         <div className="user-info">
          //           <div className="title" type="button">
          //             <CommonSkeleton width={300} />
          //           </div>
          //           <div className="last-update">
          //             <CommonSkeleton width={300} />
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //     <div className="user-feed-blk">
          //       <div className="txt-only">
          //         <CommonSkeleton width={800} height={140} />
          //         <div className="public-action-blk">
          //           <ul className="like-comment">
          //             <li className="action-link">
          //               <span className="count">
          //                 <CommonSkeleton width={70} />
          //               </span>
          //             </li>
          //             <li className="action-link">
          //               <CommonSkeleton width={150} />
          //             </li>
          //           </ul>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // ))
        )
      ) : (
        <NoDataPage />
      )}
    </div>
  );
};
PostList.propTypes = {
  listData: PropTypes.node,
  isGet: PropTypes.bool,
  handleLikePatch: PropTypes.func,
  isPatch: PropTypes.bool,
  isFeed: PropTypes.bool,
  handleDeleteFeed: PropTypes.func,
  setSelectDropdownOption: PropTypes.func,
  selectDropdownOption: PropTypes.string,
  allUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  type: PropTypes.string,
  setShowComment: PropTypes.func,
  showComment: PropTypes.string,
  hasPermission: PropTypes.func,
  isPostSuccess: PropTypes.bool,
};
export default PostList;
