/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import checkIcon from "../../../assets/images/check_icon.svg";
import arrowRight from "../../../assets/images/arrow-right.svg";

const CustomDropdown = ({
  icon,
  label,
  keyId,
  options,
  onClick,
  handleFilter,
  exportFnc,
  onExportOptionChange,
  pdfThunkLoading,
  loaderPdf,
}) => {
  // console.log("bbbbbbbbb", isLoadingPdf);

  const [optionState, setOptionState] = useState(options);

  const renderDropdownItem = (item) => {
    switch (keyId) {
      case "groupBy":
        return (
          <Dropdown.Item
            bsPrefix="dropdown-item d-flex"
            onClick={() => handleFilter(item.value)}
          >
            <div className="icon">
              <img src={item.svg} alt="icon" />
            </div>
            <span className="groupByLabel"> {item.label}</span>
          </Dropdown.Item>
        );
      case "subtask":
        return (
          <Dropdown.Item
            bsPrefix="dropdown-item d-flex flex-wrap"
            onClick={() => {
              handleFilter(item.value);
              setOptionState(
                optionState.map((views) => ({
                  ...views,
                  checked: views.value === item.value,
                })),
              );
            }}
          >
            <span>{item.label}</span>
            <p>{item.description}</p>
            {item.checked && (
              <div className="ok icons">
                <img src={checkIcon} alt="icon" />
              </div>
            )}
          </Dropdown.Item>
        );
      case "show":
        return (
          <Dropdown.Item
            key={item.value}
            bsPrefix="dropdown-item d-flex dropdown-toggle custom_switch"
            onClick={(prevState) => {
              handleFilter(item.value);
              prevState.stopPropagation();
              setOptionState(
                optionState.map((views) =>
                  views.value === item.value
                    ? {
                        ...views,
                        checked: !views.checked,
                      }
                    : views,
                ),
              );
            }}
          >
            <span className="pe-2">{item.label}</span>
            {item.value === "colomn" ? (
              <div className="arrow-right ms-auto">
                <img src={item.svg} alt="icon" />
              </div>
            ) : (
              <div className="switch">
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => {}}
                />
                <span className="slider round" />
              </div>
            )}
          </Dropdown.Item>
        );
      case "otherOption":
        return (
          <>
            <Dropdown.Item
              bsPrefix="dropdown-item d-flex"
              onClick={(event) => {
                event.stopPropagation();
                setOptionState((prevState) =>
                  prevState.map((views) =>
                    views.value === item.value
                      ? {
                          ...views,
                          checked: !views.checked,
                        }
                      : views,
                  ),
                );
                handleFilter(item.value);
              }}
            >
              <div className="custom_switch d-flex align-items-center">
                <label className="d-flex w-100">
                  <div className="switch-txt d-flex">
                    <div className="icon">
                      <img src={item.svg} alt="icon" />
                    </div>
                    <span className="pe-2">{item.label}</span>
                  </div>
                  {item.value === "exportView" ? (
                    <div className="arrow-right ms-auto">
                      <img src={arrowRight} alt="icon" />
                    </div>
                  ) : (
                    <div className="switch">
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => {}}
                      />
                      <span className="slider round" />
                    </div>
                  )}
                </label>
              </div>
            </Dropdown.Item>
            {item.value === "autosaveView" || item.value === "defaultMe" ? (
              <div className="divitions">
                <hr />
              </div>
            ) : null}
          </>
        );

      case "export":
        return (
          <Dropdown.Item
            bsPrefix="dropdown-item d-flex"
            onClick={(e) => {
              e.stopPropagation();

              if (item.value === "xls" && exportFnc.exportToExcel) {
                exportFnc.exportToExcel(); // Call exportToExcel function
              } else if (item.value === "pdf" && exportFnc.exportToPdf) {
                exportFnc.exportToPdf();
              }
            }}
          >
            {item.value === "xls" && exportFnc.exportToExcel && (
              <>
                <div className="icon">
                  <img src={item.svg} alt="icon" />
                </div>
                <span> {item.label}</span>{" "}
              </>
            )}
            {item.value === "pdf" && exportFnc.exportToPdf && (
              <>
                <div className="icon">
                  <img src={item.svg} alt="icon" />
                </div>
                <span> {item.label}</span>{" "}
              </>
            )}

            {/* Render loading spinner conditionally */}
            {item.value === "pdf" && pdfThunkLoading && loaderPdf && (
              <span className="btn-loader">
                <span className="spinner-border spinner-border-sm" />
              </span>
            )}
          </Dropdown.Item>
        );

      default:
        return (
          <Dropdown.Item
            bsPrefix="dropdown-item d-flex dropdown-toggle custom_switch"
            onClick={(event) => {
              handleFilter(item.value);
              event.stopPropagation();
              onClick(item.value);
            }}
            key={item.value}
          >
            <span>{item.label}</span>
            <div className="switch">
              <input type="checkbox" checked={item.checked} />
              <span className="slider round" />
            </div>
          </Dropdown.Item>
        );
    }
  };
  return (
    <Dropdown bsPrefix="dropdown custom-drop-menu no-width drop-menu-md">
      <Dropdown.Toggle bsPrefix="d-flex btn-sm dropdown-toggle" variant="">
        <div className="icon">
          <img src={icon} alt="icon" />
        </div>
        <span>{label}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        bsPrefix="dropdown-menu dropeffect pt-2 pb-2 drop-align-cstm"
        onClick={(e) => e.stopPropagation()}
      >
        {optionState.map((item) => (
          <React.Fragment key={item.value}>
            {item.subOptions ? (
              <Dropdown>
                <Dropdown.Toggle
                  bsPrefix="dropdown-item d-flex dropdown-toggle"
                  variant=""
                >
                  <span>{item.label}</span>
                  <div className="arrow-right ms-auto">
                    <img src={arrowRight} alt="icon" />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu bsPrefix="dropdown-menu dropeffect nestedDropdownStart pb-1 pt-1">
                  {item.subOptions.map((subItem) => (
                    <Dropdown.Item
                      bsPrefix="dropdown-item d-flex"
                      onClick={(event) => {
                        handleFilter(item.value);
                        event.stopPropagation();
                        onClick(subItem.value);
                      }}
                      key={subItem.value}
                    >
                      <div className="icon">
                        <img src={subItem.svg} alt="icon" />
                      </div>
                      <span>{subItem.label}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              renderDropdownItem(item)
            )}
            {item.value === "taskLocation" ||
            item.value === "tasksMultipleLists" ? (
              <div className="divitions">
                <hr />
              </div>
            ) : null}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CustomDropdown.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  keyId: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      subOptions: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          svg: PropTypes.string,
        }),
      ),
      checked: PropTypes.bool,
      svg: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  handleFilter: PropTypes.func,
  exportFnc: PropTypes.func,
  onExportOptionChange: PropTypes.func,
};

export default CustomDropdown;
