import { createSlice } from "@reduxjs/toolkit";

export const name = "department";

const createReducers = () => {
  const setDepartmentList = (state, { payload }) => {
    state.departmentList = payload.data;
  };
  const setDepartmentPermission = (state, { payload }) => {
    state.departmentPermissions = payload.departmentPermissions;
  };
  return {
    setDepartmentList,
    setDepartmentPermission,
  };
};

function createInitialState() {
  return {
    departmentList: [],
    departmentPermissions: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const departmentActions = slice.actions;
export const departmentReducer = slice.reducer;
