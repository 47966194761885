import Gateway from "../gateway/gateway";

export const postMeetingService = (postData) =>
  Gateway.post("/meeting", postData);

export const getMeetingService = (params) =>
  Gateway.get("/meeting", { params });

export const meetingComplete = (params) =>
  Gateway.patch(`/meeting/complete?id=${params}`);

export const deleteMeetingService = (params) =>
  Gateway.delete(`/meeting?id=${params}`);

export const updateMeetingService = (data, id) =>
  Gateway.patch(`/meeting?id=${id}`, data);
