import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import cronjobService from "../../../../services/cronjobService";
import { cronjobActions } from "../index";

export const cronjobSingleData = createAsyncThunk(
  "cronjob/getById",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await cronjobService.getCronjobById(payload);
      dispatch(cronjobActions.cronjobById(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(cronjobActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
