import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertActions } from "../../alert/index";
import { getAllCommits } from "../../../../services/gitHubService";
import { gitHubAction } from "..";

export const getGitCommits = createAsyncThunk(
  "github",
  async (datas, { dispatch }) => {
    console.log("repoName,owner", datas);
    dispatch(alertActions.clear());
    try {
      const { data } = await getAllCommits(datas?.value, datas?.owner);
      dispatch(gitHubAction.getCommits(data.commits));
    } catch (error) {
      dispatch(alertActions.error("Access Denied to this repository"));
    }
  },
);
