import { createSlice } from "@reduxjs/toolkit";

export const name = "colors";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.colorData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setColorData = (state, action) => {
    state.colorData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };

  return {
    setColorData,
    setErrorMesage,
    resetErrorMesage,
  };
};

function createInitialState() {
  return {
    colorData: [],
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const colorActions = slice.actions;
export const colorReducer = slice.reducer;
