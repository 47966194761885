import PropTypes from "prop-types";
import classNames from "classnames";

function Button({
  children,
  primary,
  success,
  outline,
  login,
  as: Element,
  type,
  ...rest
}) {
  const classes = classNames(rest.className, "btn", {
    "btn-primary": primary,
    "login-btn d-flex align-items-center justify-content-center border-0":
      login,
    "btn-success": success,
    "btn-outline": outline,
  });

  return (
    <Element {...rest} type={type} className={classes}>
      {children}
    </Element>
  );
}

Button.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  primary: PropTypes.bool,
  outline: PropTypes.bool,
  success: PropTypes.bool,
  login: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"]), // Define the type prop
};

Button.defaultProps = {
  as: "button",
  type: "button",
};

export default Button;
