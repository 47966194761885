import * as XLSX from "xlsx";
import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import LanguageContext from "../../../context/LanguageContext";
import "../../../design/custom/pages/activity-log.scss";
import CustomTable from "../../../components/CustomTable";
import { cols } from "./constants";
import { getActivityLog } from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { useFilter } from "../../../context/useFilter";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import { formatDateStructure } from "../../../utils/utils";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import { filterQuery } from "./filter";
import { alertActions } from "../../../store/slices/alert";
import { activityLogActions } from "../../../store/slices/activitylog";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";

const ActivityLog = () => {
  const {
    languageData: { activitiLogTitle },
  } = useContext(LanguageContext);
  const [filterData] = useThunk(getFilterDropdown);
  const { filter } = useFilter();
  const { shortCode } = useParams();
  const [getActivityLogData] = useThunk(getActivityLog);
  const { activityLogData, activityDataforExport } = useSelector(
    ({ activityLog }) => activityLog,
  );
  const { userProfile } = useSelector((state) => state.userProfile);
  const { projectData } = useSelector((state) => state.project);
  const [activityPagination, setActivityPagination] = useState({
    currentPage: 1,
  });

  const dispatch = useDispatch();

  const tableClassName = "table td-center";
  const divClassName = "table-responsive custom-scroll table-helight";

  useEffect(() => {
    filterData("activitylog");
  }, []);

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.PROJECT_MANAGEMENT_ACTIVITY_LOG,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  const project = projectData?.filter((project) => {
    return shortCode === project?.shortCode;
  });

  useEffect(() => {
    if (project && project?.length) fetchActivityList();
  }, [activityPagination, projectData]);

  useEffect(() => {
    if (activityDataforExport) {
      exportToExcel();
      dispatch(activityLogActions.clearExportData());
    }
  }, [activityDataforExport]);

  const matches = filterQuery(filter.filter);

  const stateUpdate = useCallback(
    matches && matches[Object.keys(matches)[0]].map(({ value }) => value),
    [filter.filter],
  );

  useEffect(() => {
    if (filter?.search !== null || activityPagination.limit) {
      if (activityPagination?.currentPage > 1) {
        setActivityPagination({ ...activityPagination, currentPage: 1 });
      } else if (project && project?.length) fetchActivityList();
    }
  }, [
    filter?.search,
    activityPagination.limit,
    stateUpdate,
    filter.me,
    filter.assignee,
    projectData,
  ]);
  const setSortingOrder = (column) => {
    setActivityPagination((prevSorting) => {
      if (prevSorting?.column === column) {
        return {
          ...prevSorting,
          order: prevSorting.order === "ASC" ? "DESC" : "ASC",
        };
      }
      return {
        column,
        order: "DESC",
      };
    });
  };

  const fetchActivityList = () => {
    const { currentPage: page, limit = 10 } = activityPagination;
    const params = {
      projectId: project[0]?._id,
      page,
      limit,
      search: filter?.search?.trim(),
      matches: filter.filter,
      me: filter?.me,
      assignee: filter?.assignee,
      sortOrder: activityPagination.order,
      sortBy: activityPagination.column,
    };
    if (!params.search || !params.search.trim().length) {
      delete params.search;
    }
    if (!params.me || !params.me.length) delete params.me;
    getActivityLogData({ ...params, matches });
  };

  const handlePageNumberChange = (e) => {
    const newPageNumber = parseInt(e.target.value, 10);
    const total = activityLogData?.data?.total;
    const doesPageExist = Math.ceil(total / (activityPagination?.limit ?? 10));
    if (newPageNumber >= 1 && newPageNumber <= doesPageExist) {
      setActivityPagination({
        ...activityPagination,
        currentPage: newPageNumber,
      });
    } else {
      // eslint-disable-next-line no-lonely-if, no-restricted-globals
      if (!isNaN(newPageNumber)) dispatch(alertActions.error("Page not exist"));
    }
  };

  const onLimitChange = (newLimit) => {
    setActivityPagination({
      ...activityPagination,
      limit: newLimit,
      currentPage: 1,
    });
  };

  const exportToExcel = async () => {
    const exportData = activityDataforExport?.result || [];
    const headers = cols.map((col) => col.name);

    const excelData = exportData.map((item) =>
      cols.map((col) => {
        switch (col.selector) {
          case "activityType":
            return item?.payload?.ticketName;
          case "user":
            return item?.payload?.userName;
          case "remark":
            return item?.payload?.remarks;
          case "date":
            return formatDateStructure(item.createdAt);
          default:
            return item.payload ? item.payload[col.selector] : "";
        }
      }),
    );
    const excelDataWithHeaders = [headers, ...excelData];
    const ws = XLSX.utils.aoa_to_sheet(excelDataWithHeaders);
    const colWidths = cols.map(() => ({ width: 15 })); // You can adjust the width as needed
    ws["!cols"] = colWidths;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Activity Log");
    XLSX.writeFile(wb, "activity_log.xlsx");
  };

  const handleExport = () => {
    const params = {
      projectId: project[0]?._id,
      page: activityPagination.currentPage,
      limit: activityPagination.limit,
      search: filter?.search?.trim(),
      matches: filter.filter,
      me: filter?.me,
      assignee: filter?.assignee,
      sortOrder: activityPagination.order,
      sortBy: activityPagination.column,
      forReport: true, // Add the forReport parameter
    };
    if (!params.search || !params.search.trim().length) {
      delete params.search;
    }
    if (!params.me || !params.me.length) delete params.me;
    getActivityLogData({ ...params, matches });
  };

  const visibleColumns = cols(hasPermission).filter(
    ({ permission }) => permission,
  );

  return (
    <div>
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions
            .PROJECT_MANAGEMENT_ACTIVITY_LOG_SEARCH,
        )}
        showFilter={hasPermission(
          permissionConstants.permissions
            .PROJECT_MANAGEMENT_ACTIVITY_LOG_CUSTOM_FILTER,
        )}
        showMe={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_ACTIVITY_LOG_ME,
        )}
        showAssignee={hasPermission(
          permissionConstants.permissions
            .PROJECT_MANAGEMENT_ACTIVITY_LOG_ASSIGNEES,
        )}
        showExport={hasPermission(
          permissionConstants.permissions
            .PROJECT_MANAGEMENT_ACTIVITY_LOG_EXPORT,
        )}
        exportFnc={handleExport}
      />
      <div className="page-contents inner-page-contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 activity-log-area">
              <div className="card-box">
                <div className="header-otr d-flex flex-wrap">
                  <div className="title-otr d-flex flex-wrap align-items-center">
                    <div className="title">{activitiLogTitle?.title}</div>
                  </div>
                </div>
                <div className="content-tables ">
                  <div className="table-helight">
                    <CustomTable
                      pagination={activityPagination}
                      columns={visibleColumns}
                      data={activityLogData?.data?.result}
                      count={activityLogData?.data?.total}
                      tableClassName={tableClassName}
                      divClassName={divClassName}
                      showPagination
                      showInput
                      onLimitChange={onLimitChange}
                      handlePageNumberChange={handlePageNumberChange}
                      onPageChange={(currentPage) =>
                        setActivityPagination({
                          ...activityPagination,
                          currentPage,
                        })
                      }
                      onSortChange={(selector) => {
                        setSortingOrder(selector);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
