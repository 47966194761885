import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import filterService from "../../../../services/filterService";
import { filterDropdownAction } from "..";
import { alertActions } from "../../alert/index";

export const getFilterDropdown = createAsyncThunk(
  "list-dropdownData",
  async (path, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const { data } = await filterService.getFilterDropdown(path);
      dispatch(filterDropdownAction.setFilterDropdown(data.filterData));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
