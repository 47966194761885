import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { userProfileActions } from "../../store/slices/userProfile";
import { useThunk } from "../../hooks/useThunk";
import { getMultyUserProfile } from "../../store";

export const CreatedBy = ({ TaskData, index, dbName }) => {
  const dispatch = useDispatch();
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  return TaskData?.[dbName]?.length ? (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={TaskData?.[dbName][0]?._id}>
          {TaskData?.[dbName][0]?.employeeName}
        </Tooltip>
      }
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          getMultyUsers(TaskData?.[dbName][0]?._id);
          dispatch(userProfileActions.setShowUserProfile(true));
        }}
        className={`avathar-sm  avathar-0${(index % 4) + 1}`}
      >
        <span>
          {TaskData?.[dbName][0]?.employeeName?.slice(0, 2).toUpperCase()}
        </span>
      </div>
    </OverlayTrigger>
  ) : (
    ""
  );
};

CreatedBy.propTypes = {
  TaskData: PropTypes.isRequired,
  index: PropTypes.isRequired,
  dbName: PropTypes.isRequired,
};
