// NestedFolderItem.js

import PropTypes from "prop-types";
import fileIcon from "../../../../../assets/images/folder-open.svg";
import folderGreyIcon from "../../../../../assets/images/folder-gray.svg";

const FolderIcon = ({ onClick, folderName, folderPath, isOpen }) => (
  <div
    className={`accordion-button sub-head collapsed ${isOpen ? "active" : ""}`}
    onClick={() => {
      onClick(folderPath);
    }}
  >
    <span className="text-tmp-img">
      <img src={folderGreyIcon} className="fld-vew" alt="" />
    </span>
    {folderName}
  </div>
);

const FileIcon = ({ onClick, fileName }) => (
  <span className="fs-fileItem" onClick={onClick}>
    <img src={fileIcon} alt="" />
    {fileName}
  </span>
);

export const NestedFolderItem = ({
  item,
  openItems,
  setOpenItems,
  handleFileClick,
  setSelectedFolderPath,
  setSelectedFile,
}) => {
  const isOpen = openItems?.includes(item?.path);

  const handleToggle = (folderPath) => {
    if (folderPath) {
      setSelectedFile("");
      setSelectedFolderPath(folderPath);
    }
    if (isOpen) {
      setOpenItems(openItems?.filter((path) => path !== item?.path));
    } else {
      setOpenItems([...openItems, item?.path]);
    }
  };

  FileIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
    fileName: PropTypes.string.isRequired,
  };
  FolderIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
    folderName: PropTypes.string.isRequired,
    folderPath: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  return (
    <div className="accordion-item">
      {item?.type === "tree" && (
        <>
          <FolderIcon
            folderName={item?.path?.split("/").pop()}
            onClick={handleToggle}
            folderPath={item?.path}
            isOpen={isOpen}
          />
          {isOpen && (
            <div className="accordion-collapse collapse show">
              <div className="accordion-body">
                <div className="listing-items">
                  <ul className="list-task-area">
                    {item?.sub?.map((subItem, subIndex) => (
                      <li className="none-icons" key={subIndex}>
                        <NestedFolderItem
                          key={subIndex}
                          item={subItem}
                          openItems={openItems}
                          setOpenItems={setOpenItems}
                          handleFileClick={handleFileClick}
                          setSelectedFolderPath={setSelectedFolderPath}
                          setSelectedFile={setSelectedFile}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {item.type === "blob" && (
        <div className="listing-items">
          <ul className="list-task-area">
            <li className="none-icons">
              <FileIcon
                onClick={() => handleFileClick(item?.path)}
                fileName={item?.path?.split("/").pop()}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

NestedFolderItem.propTypes = {
  item: PropTypes.node.isRequired,
  openItems: PropTypes.node.isRequired,
  setOpenItems: PropTypes.func.isRequired,
  handleFileClick: PropTypes.func.isRequired,
  setSelectedFolderPath: PropTypes.func.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
};
