export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.9"
    height="9.71"
    viewBox="0 0 12.517 13.651"
  >
    <g
      id="Group_1188"
      data-name="Group 1188"
      transform="translate(-1003.026 -544.101)"
    >
      <path
        id="Path_62"
        data-name="Path 62"
        d="M1004.17,551.947c0-1.4,0-2.805,0-4.208,0-.182-.046-.248-.232-.234a5.885,5.885,0,0,1-.6,0c-.221,0-.31-.1-.315-.32,0-.153,0-.306,0-.459,0-.259.091-.349.347-.349.824,0,1.648,0,2.473,0a.26.26,0,0,0,.294-.19c.163-.421.339-.837.52-1.251a1.3,1.3,0,0,1,1.264-.836q1.366-.01,2.732,0a1.294,1.294,0,0,1,1.248.83q.275.619.521,1.25a.259.259,0,0,0,.289.2c.811-.01,1.622-.005,2.433,0,.32,0,.393.076.394.4,0,.146,0,.293,0,.439a.265.265,0,0,1-.287.287c-.219,0-.439.011-.658,0-.163-.01-.2.052-.2.205.005,1.5,0,3,0,4.507q0,1.864,0,3.729a1.99,1.99,0,0,1-.46,1.326,1.264,1.264,0,0,1-1.026.48c-2.373-.006-4.746,0-7.119,0a1.487,1.487,0,0,1-1.572-1.281,2.92,2.92,0,0,1-.051-.574C1004.168,554.579,1004.17,553.263,1004.17,551.947Zm1.138-4.425c0,.059-.009.092-.009.125q0,4.148,0,8.3a1.424,1.424,0,0,0,.091.425.354.354,0,0,0,.39.26c2.3-.008,4.6-.005,6.9-.005.384,0,.479-.075.558-.461a1.478,1.478,0,0,0,.028-.3q0-4.058,0-8.116v-.227Zm1.989-1.156h3.976c-.145-.351-.274-.685-.425-1.007a.3.3,0,0,0-.227-.12q-1.335-.013-2.67,0a.3.3,0,0,0-.224.124C1007.574,545.684,1007.444,546.016,1007.3,546.366Z"
        transform="translate(0)"
        fill="#de4841"
      />
      <path
        id="Path_63"
        data-name="Path 63"
        d="M1089.834,675.258q0,1.236,0,2.473c0,.286-.083.37-.363.373-.146,0-.293,0-.439,0-.231,0-.324-.095-.325-.33,0-.572,0-1.143,0-1.715q0-1.625,0-3.25c0-.315.077-.391.385-.392.14,0,.279,0,.419,0,.232.005.324.1.325.33Q1089.836,674,1089.834,675.258Z"
        transform="translate(-82.26 -123.195)"
        fill="#de4841"
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M1202.843,675.26q0-1.236,0-2.473c0-.286.083-.37.363-.373.146,0,.292,0,.439,0,.233.005.324.094.326.33,0,.552,0,1.1,0,1.655q0,1.655,0,3.31c0,.315-.076.391-.384.393-.14,0-.279,0-.419,0-.232-.005-.324-.1-.325-.33C1202.842,676.935,1202.844,676.1,1202.843,675.26Z"
        transform="translate(-191.847 -123.195)"
        fill="#de4841"
      />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M1146.912,675.278q0,1.236,0,2.473c0,.287-.081.368-.365.37q-.2,0-.4,0c-.281,0-.364-.084-.364-.372q0-1.276,0-2.552,0-1.2,0-2.393c0-.286.083-.369.366-.371q.2,0,.4,0c.281,0,.363.085.364.373Q1146.914,674.042,1146.912,675.278Z"
        transform="translate(-137.063 -123.212)"
        fill="#de4841"
      />
    </g>
  </svg>
);

export const DownloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 16.021 15.2"
  >
    <g
      id="Group_59903"
      data-name="Group 59903"
      transform="translate(-1771 -204)"
    >
      <g id="Group_59902" data-name="Group 59902">
        <path
          id="Path_10623"
          data-name="Path 10623"
          d="M19.366,4.583a.616.616,0,0,1,.616.616v9.785a.616.616,0,1,1-1.232,0V5.2A.616.616,0,0,1,19.366,4.583Z"
          transform="translate(1759.645 199.417)"
          fill="#a2a5ac"
          fillRule="evenodd"
        />
        <path
          id="Path_10624"
          data-name="Path 10624"
          d="M12.347,18.931a.616.616,0,0,1,.871,0l2.81,2.8,2.81-2.8a.616.616,0,0,1,.87.873l-3.015,3.006h0a.949.949,0,0,1-1.317.014l-.014-.013L12.348,19.8A.616.616,0,0,1,12.347,18.931Z"
          transform="translate(1762.982 192.233)"
          fill="#a2a5ac"
          fillRule="evenodd"
        />
        <path
          id="Path_10625"
          data-name="Path 10625"
          d="M4.366,25.05a.616.616,0,0,1,.616.616v1.553A1.714,1.714,0,0,0,6.7,28.928H16.822a1.714,1.714,0,0,0,1.717-1.709V25.666a.616.616,0,1,1,1.232,0v1.553a2.946,2.946,0,0,1-2.95,2.941H6.7a2.946,2.946,0,0,1-2.95-2.941V25.666a.616.616,0,0,1,.616-.616Z"
          transform="translate(1767.25 189.039)"
          fill="#a2a5ac"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export const MinimizeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.733"
    height="10.733"
    viewBox="0 0 10.733 10.733"
  >
    <path
      id="expand"
      d="M9.781,3.073A.537.537,0,1,1,9.781,2H12.2a.537.537,0,0,1,.537.537V4.952a.537.537,0,1,1-1.073,0V3.832l-2.84,2.84a.537.537,0,0,1-.759-.759l2.84-2.84ZM6.673,8.06a.537.537,0,0,1,0,.759l-2.84,2.84H4.952a.537.537,0,1,1,0,1.073H2.537A.537.537,0,0,1,2,12.2V9.781a.537.537,0,1,1,1.073,0V10.9l2.84-2.84A.537.537,0,0,1,6.673,8.06Z"
      transform="translate(-2 -2)"
      fill="currentcolor"
      fillRule="evenodd"
    />
  </svg>
);

export const TimeEstimate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="info"
    width="10.24"
    height="10.24"
    viewBox="0 0 10.24 10.24"
  >
    <g id="Group_59384" data-name="Group 59384">
      <g id="Group_59383" data-name="Group 59383">
        <path
          id="Path_10390"
          data-name="Path 10390"
          d="M5.12,0a5.12,5.12,0,1,0,5.12,5.12A5.117,5.117,0,0,0,5.12,0Zm0,9.526A4.406,4.406,0,1,1,9.526,5.12,4.411,4.411,0,0,1,5.12,9.526Z"
          fill="currentcolor"
        />
      </g>
    </g>
    <g
      id="Group_59386"
      data-name="Group 59386"
      transform="translate(4.601 4.268)"
    >
      <g id="Group_59385" data-name="Group 59385">
        <path
          id="Path_10391"
          data-name="Path 10391"
          d="M230.578,213.4c-.3,0-.519.128-.519.317v2.568c0,.162.216.323.519.323s.526-.162.526-.323v-2.568C231.1,213.525,230.868,213.4,230.578,213.4Z"
          transform="translate(-230.059 -213.397)"
          fill="currentcolor"
        />
      </g>
    </g>
    <g
      id="Group_59388"
      data-name="Group 59388"
      transform="translate(4.568 2.684)"
    >
      <g id="Group_59387" data-name="Group 59387">
        <path
          id="Path_10392"
          data-name="Path 10392"
          d="M228.928,134.208a.486.486,0,1,0,.546.479A.522.522,0,0,0,228.928,134.208Z"
          transform="translate(-228.375 -134.208)"
          fill="currentcolor"
        />
      </g>
    </g>
  </svg>
);

export const Minimizemain = () => (
  <span className="icon">
    <svg
      id="Group_59660"
      data-name="Group 59660"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="17.5"
      height="17.5"
      viewBox="0 0 17.5 17.5"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_3658"
            data-name="Rectangle 3658"
            width="17.5"
            height="17.5"
            fill="currentcolor"
          />
        </clipPath>
      </defs>
      <g
        id="Group_58848"
        data-name="Group 58848"
        // clip-path="url(#clip-path)"
      >
        <g
          id="Group_58847"
          data-name="Group 58847"
          // style={{ isolation: isolate }}
        >
          <g id="Group_58846" data-name="Group 58846">
            <g
              id="Group_58845"
              data-name="Group 58845"
              // clip-path="url(#clip-path)"
            >
              <path
                id="Path_1462"
                data-name="Path 1462"
                d="M13.779,17.5H3.72A3.725,3.725,0,0,1,0,13.779V3.721A3.725,3.725,0,0,1,3.72,0H9.779V1.5H3.72A2.223,2.223,0,0,0,1.5,3.721V13.779A2.223,2.223,0,0,0,3.72,16H13.779A2.223,2.223,0,0,0,16,13.779V8.721h1.5v5.058A3.725,3.725,0,0,1,13.779,17.5"
                fill="currentcolor"
              />
            </g>
          </g>
        </g>
        <path
          id="Path_1463"
          data-name="Path 1463"
          d="M9.173,4.3a.73.73,0,0,0-1.459,0L7.7,9.673h0a.73.73,0,0,0,.73.73h0l5.377-.013a.729.729,0,1,0,0-1.458l-3.61.008,6.922-6.922A.729.729,0,0,0,16.087.988L9.164,7.91"
          fill="currentcolor"
        />
      </g>
    </svg>
  </span>
);

export const MinimizeClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.457"
    height="10.458"
    viewBox="0 0 10.457 10.458"
  >
    <path
      id="close"
      d="M12.5,11.529l4.054-4.054a.688.688,0,1,0-.973-.973l-4.054,4.054L7.475,6.5a.688.688,0,0,0-.973.973l4.054,4.054L6.5,15.583a.688.688,0,1,0,.972.973L11.528,12.5l4.054,4.054a.688.688,0,0,0,.972-.973L12.5,11.529Z"
      transform="translate(-6.3 -6.3)"
      fill="currentcolor"
    />
  </svg>
);

export const Fileupload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.336"
    height="18.572"
    viewBox="0 0 17.336 18.572"
  >
    <g id="_x38_2_attachment" transform="translate(-2.001 -1)">
      <path
        id="Path_1465"
        data-name="Path 1465"
        d="M19.337,5.953a4.921,4.921,0,0,1-1.451,3.5L8.964,18.484a3.715,3.715,0,1,1-5.257-5.251l6.446-6.409a2.436,2.436,0,0,1,3.43-.07,2.3,2.3,0,0,1,.67,1.716,2.521,2.521,0,0,1-.745,1.711L7.391,16.3a.619.619,0,0,1-.876-.876l6.117-6.117a1.284,1.284,0,0,0,.383-.872,1.077,1.077,0,0,0-.308-.8,1.2,1.2,0,0,0-1.681.072L4.582,14.11a2.477,2.477,0,0,0,3.5,3.5l8.922-9.028a3.715,3.715,0,1,0-5.251-5.256l-8.7,8.639a.619.619,0,0,1-.872-.879l8.7-8.637a4.953,4.953,0,0,1,8.454,3.5Z"
        fill="#e4e4e4"
      />
    </g>
  </svg>
);
export const MinimizeTaskIcon = () => (
  <svg width="15.217" height="15.217" viewBox="0 0 15.217 15.217">
    <g id="checklist" transform="translate(-1.25 -1.25)">
      <path
        id="Path_11248"
        data-name="Path 11248"
        d="M15.867,9.182H12.151a.531.531,0,0,1,0-1.062h3.716a.531.531,0,0,1,0,1.062Z"
        transform="translate(-3.031 -2.008)"
        fill="currentcolor"
      />
      <path
        id="Path_11249"
        data-name="Path 11249"
        d="M6.682,9.285a.525.525,0,0,1-.375-.156L5.776,8.6a.53.53,0,0,1,.75-.75L6.682,8,7.9,6.787a.53.53,0,0,1,.75.75L7.057,9.129a.53.53,0,0,1-.375.156Z"
        transform="translate(-1.278 -1.573)"
        fill="currentcolor"
      />
      <path
        id="Path_11250"
        data-name="Path 11250"
        d="M15.867,16.182H12.151a.531.531,0,0,1,0-1.062h3.716a.531.531,0,0,1,0,1.062Z"
        transform="translate(-3.031 -4.053)"
        fill="currentcolor"
      />
      <path
        id="Path_11251"
        data-name="Path 11251"
        d="M6.682,16.285a.525.525,0,0,1-.375-.156L5.776,15.6a.53.53,0,0,1,.75-.75L6.682,15,7.9,13.787a.53.53,0,0,1,.75.75L7.057,16.129a.53.53,0,0,1-.375.156Z"
        transform="translate(-1.278 -3.619)"
        fill="currentcolor"
      />
      <path
        id="Path_11252"
        data-name="Path 11252"
        d="M10.982,16.467H6.735c-3.843,0-5.485-1.642-5.485-5.485V6.735c0-3.843,1.642-5.485,5.485-5.485h4.247c3.843,0,5.485,1.642,5.485,5.485v4.247C16.467,14.825,14.825,16.467,10.982,16.467ZM6.735,2.312c-3.263,0-4.423,1.161-4.423,4.423v4.247c0,3.263,1.161,4.423,4.423,4.423h4.247c3.263,0,4.423-1.161,4.423-4.423V6.735c0-3.263-1.161-4.423-4.423-4.423Z"
        fill="currentcolor"
      />
    </g>
  </svg>
);

export const SprintIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.143"
    height="7.066"
    viewBox="0 0 9.143 7.066"
  >
    <g
      id="Group_60047"
      data-name="Group 60047"
      transform="translate(-1.22 -0.913)"
    >
      <path
        id="Path_9780"
        data-name="Path 9780"
        d="M8.884,5.307a.233.233,0,0,0-.055-.094L7.767,4.433a.337.337,0,0,0-.216-.071l-.037,0a.326.326,0,0,0-.126.036.262.262,0,0,0-.09.067l-.041.04a.363.363,0,0,0,.078.505l.316.231c-1.432.047-4.357.074-4.627.074a2.294,2.294,0,0,1,0-4.588h.008A2.3,2.3,0,0,1,4.947,1.774a.521.521,0,0,0,.025.047,2.137,2.137,0,0,1,.17.314A2.3,2.3,0,0,1,4.936,4.3a.364.364,0,0,0,.1.5.353.353,0,0,0,.4-.023.242.242,0,0,0,.1-.078.525.525,0,0,0,.044-.083,2.96,2.96,0,0,0,.2-.38c.031-.067.055-.135.079-.2a3.337,3.337,0,0,0,.1-.328c.015-.063.031-.126.042-.19a3.084,3.084,0,0,0,.032-.361A3,3,0,0,0,4.71.511,3.094,3.094,0,0,0,4.232.255L4.164.229,4.131.216a3.13,3.13,0,0,0-.45-.135A.6.6,0,0,0,3.574.055,2.975,2.975,0,0,0,3.028,0h0a3.024,3.024,0,0,0,0,6.047c.3,0,3.156-.027,4.653-.081l-.27.307L7.389,6.3h0a.359.359,0,0,0,.571.434l.877-1.008.038-.045a.257.257,0,0,0,.035-.112L8.791,5.53l.128.028,0-.012a.221.221,0,0,0,.016-.1.318.318,0,0,0-.055-.135"
        transform="translate(1.325 1.017)"
        fill="currentcolor"
      />
      <path
        id="Path_9780_-_Outline"
        data-name="Path 9780 - Outline"
        d="M7.645,6.912a.461.461,0,0,1-.4-.687h0l.042-.067L7.4,6.022C5.847,6.071,3.224,6.1,2.97,6.1a3.125,3.125,0,0,1,0-6.25h.007A3.073,3.073,0,0,1,3.542-.1q.06.011.113.025a3.237,3.237,0,0,1,.463.141L4.15.085c.024.008.048.017.073.028a3.194,3.194,0,0,1,.494.264,3.1,3.1,0,0,1,1.37,2.731,3.214,3.214,0,0,1-.034.374c-.01.057-.023.111-.035.163l-.008.034a3.468,3.468,0,0,1-.1.343v.007a2.1,2.1,0,0,1-.08.2,3.052,3.052,0,0,1-.2.386.593.593,0,0,1-.049.092v0a.342.342,0,0,1-.129.1.459.459,0,0,1-.512.02.467.467,0,0,1-.2-.295.462.462,0,0,1,.067-.35A2.2,2.2,0,0,0,5,2.124a2.052,2.052,0,0,0-.163-.3c-.011-.018-.019-.034-.026-.048h0A2.209,2.209,0,0,0,2.984.785H2.977a2.19,2.19,0,0,0-.8,4.23,2.166,2.166,0,0,0,.8.155c.24,0,2.81-.022,4.329-.067l-.078-.057a.465.465,0,0,1-.1-.646l.012-.013.036-.035a.369.369,0,0,1,.119-.092.428.428,0,0,1,.163-.047l.047,0a.438.438,0,0,1,.278.092l1.07.786a.317.317,0,0,1,.077.125l.007.012a.36.36,0,0,1,.057.161.318.318,0,0,1-.02.141v.012a.349.349,0,0,1-.049.148L8.91,5.7l-.038.045L8,6.753A.46.46,0,0,1,7.645,6.912Zm-.2-.631h0l-.016.026a.259.259,0,0,0,.412.313l.9-1.034-.031-.01.054-.195.023,0a.266.266,0,0,0-.029-.06l-.02-.04a.148.148,0,0,0-.024-.047L7.651,4.462A.235.235,0,0,0,7.5,4.413l-.03,0a.231.231,0,0,0-.089.024h0a.167.167,0,0,0-.053.04l-.011.012-.034.033a.262.262,0,0,0,.061.358l.552.4L7.6,5.3c-1.456.049-4.373.074-4.63.074A2.394,2.394,0,0,1,2.977.583h.007a2.412,2.412,0,0,1,2,1.085l.01.019.016.031a2.225,2.225,0,0,1,.177.328,2.4,2.4,0,0,1-.215,2.259.26.26,0,0,0-.038.2.264.264,0,0,0,.112.168.258.258,0,0,0,.293-.022l.015-.009a.142.142,0,0,0,.059-.045.463.463,0,0,0,.034-.067l.006-.011a2.859,2.859,0,0,0,.2-.367c.028-.062.051-.123.074-.189V3.953a3.224,3.224,0,0,0,.094-.322L5.82,3.6c.012-.051.024-.1.032-.149A3.029,3.029,0,0,0,5.884,3.1,2.9,2.9,0,0,0,4.6.545,3,3,0,0,0,4.141.3a.317.317,0,0,0-.06-.023h0L4.043.26A3.047,3.047,0,0,0,3.608.125C3.575.116,3.541.109,3.5.1A2.874,2.874,0,0,0,2.978.051h0a2.922,2.922,0,1,0,0,5.845c.266,0,3.146-.027,4.653-.081l.233-.008Z"
        transform="translate(1.375 1.067)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
