/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { teamActions } from "../index";
import teamService from "../../../../services/teamService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getDepartment = createAsyncThunk(
  `get/department`,
  async ({ params }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await teamService.getDepartmentData(params);

      dispatch(
        teamActions.setDepartmentData({
          data,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(teamActions.setTeamErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
