import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { ArrowRightIco } from "../svgIcons";
import { hasSubModulePermission } from "../../../utils/permissionUtils";

const DefaultMenu = ({ menu, subMenus, to, icon, sidenavMenu, isOpen }) => {
  const { pathname } = useLocation();
  const { userProfile } = useSelector((state) => state.userProfile);
  const hasSubModule = (subModule) =>
    hasSubModulePermission(userProfile?.formattedPermissions ?? [], subModule);

  return (
    <li>
      <div
        className={`menuItem  d-flex align-items-center ${
          pathname.startsWith(`/${to?.split("/")[1]}`) ? "active" : ""
        }`}
      >
        <div
          className="linksActions"
          onClick={() => sidenavMenu({ toggle: menu })}
        >
          <Link to={to ?? "# "} className="menuLink d-flex align-items-center">
            <span className="icon">{icon()}</span>
            <span className="txt">{menu}</span>
          </Link>
          {subMenus && (
            <span className="actions d-flex">
              <span className={isOpen ? "dropdown open" : "dropdown"}>
                <ArrowRightIco />
              </span>
            </span>
          )}
        </div>
        {subMenus && isOpen && (
          <div className="submenu" id="permissionMenu">
            <div className="submenuLevel1 customSub permissions">
              <ul>
                {subMenus
                  .filter((e) =>
                    e.hasPermission ? hasSubModule(e.hasPermission) : true,
                  )
                  .filter((e) =>
                    e.subMenus
                      ? e.subMenus.filter((subMenu) =>
                          subMenu.hasPermission
                            ? hasSubModule(subMenu.hasPermission)
                            : true,
                        ).length
                      : e,
                  )
                  .map(({ menu, to, subMenus, isOpen }) => (
                    <>
                      <li
                        className={`d-flex ${to === pathname ? "active" : ""}`}
                        onClick={() => sidenavMenu({ toggle: menu })}
                        type="button"
                      >
                        <Link to={to ?? "# "} className="sub1_menuLink">
                          <span className="bullet" />
                          {menu}
                        </Link>
                        {subMenus && (
                          <span className="actions d-flex">
                            <span
                              className={isOpen ? "dropdown open" : "dropdown"}
                            >
                              <ArrowRightIco />
                            </span>
                          </span>
                        )}
                      </li>
                      {subMenus &&
                        isOpen &&
                        subMenus
                          .filter((subMenu) =>
                            subMenu.hasPermission
                              ? hasSubModule(subMenu.hasPermission)
                              : true,
                          )
                          .map(({ menu, to }) => (
                            <li
                              className={`d-flex level_2 ${
                                pathname.startsWith(`/${to?.split("/")[1]}`)
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Link to={to ?? "# "} className="sub1_menuLink">
                                <span className="bullet" />
                                {menu}
                              </Link>
                            </li>
                          ))}
                    </>
                  ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

DefaultMenu.propTypes = {
  menu: PropTypes.string,
  isOpen: PropTypes.bool,
  to: PropTypes.string,
  icon: PropTypes.func,
  sidenavMenu: PropTypes.func,
  subMenus: PropTypes.arrayOf(),
};

export default DefaultMenu;
