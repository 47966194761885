import Gateway from "../gateway/gateway";

// get ticket data from ticket api
const getNotificationData = (params) =>
  Gateway.get("/notifications", { params });
const patchNotificationData = (id, patchData) =>
  // eslint-disable-next-line no-undef
  Gateway.patch(`/notifications/${id}`, { patchData });

const patchAllNotificationData = (patchData) =>
  Gateway.patch("/notifications", patchData);

export default {
  getNotificationData,
  patchNotificationData,
  patchAllNotificationData,
};
