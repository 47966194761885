import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import PropTypes from "prop-types";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import CustomEvent from "./components/CustomEvent";
import "../../design/custom/pages/calendar.scss";
// import CommonSkeleton from "../SkeletonLoader/CommonSkeleton";

const localizer = momentLocalizer(moment);

const CalendarMain = ({
  tickets,
  passFilter,
  holidaysDetail,
  isLoading,
  hasPermission,
}) => {
  const context = useOutletContext();
  const [selectedTicket, setSelectedTicket, setShowModal, ticket, isTicket] =
    context ?? [];

  const [searchParams, setSearchParams] = useSearchParams();
  const param = useLocation();
  const getDayStyle = (date) => {
    const isHoliday = holidaysDetail?.some((holiday) =>
      moment(holiday).isSame(date, "day"),
    );
    let backgroundColor;
    if (date.getDay() % 6 === 0) {
      backgroundColor = "holiday_calendar_bg";
    } else if (isHoliday) {
      backgroundColor = "weekday_calendar_bg";
    } else {
      backgroundColor = "weekday_calendar_bg";
    }

    return {
      className: backgroundColor,
    };
  };
  useEffect(() => {
    const queryString = param.search;
    if (queryString) {
      setShowModal(true);
    }
  }, []);
  const renderCell = (date) => {
    const isHoliday = holidaysDetail?.some((holiday) =>
      moment(holiday).isSame(date, "day"),
    );
    return (
      <>
        {isHoliday && (
          <div className="leave">
            <div>Leave</div>
          </div>
        )}
        <div>{date.getDate()}</div>
      </>
    );
  };
  const toolbar = ({ onNavigate, label }) => {
    passFilter(onNavigate, label);
  };

  useEffect(() => {
    if (selectedTicket) {
      if (!ticket) {
        setSearchParams({ Task: selectedTicket });
      }
      setShowModal(true);
    }
  }, [selectedTicket, searchParams]);
  const eventbar = (props) => {
    return (
      <CustomEvent
        {...props}
        setSelectedTicket={setSelectedTicket}
        isTicket={isTicket}
        isLoading={isLoading}
        hasPermission={hasPermission}
      />
    );
  };
  return (
    <div className="report-outer-wrap">
      <Calendar
        localizer={localizer}
        events={tickets?.map((task) => ({
          id: task._id,
          title: `${task.ticketInfo?.title || task.ticket?.title}/${
            task.title
          }`,
          users: task.assignedUsers,
          color: task.color,
          start: moment(task.startDate),
          end: moment(task.dueDate),
          status: task.ticketStatus,
          ticketInfo: task.ticketInfo,
          estimationHours: task.estimationHours,
        }))}
        views="month"
        dayPropGetter={getDayStyle}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 820 }}
        onDrillDown={() => {}}
        popup
        handleDragStart={() => {}}
        components={{
          event: eventbar,
          toolbar,
          month: {
            dateHeader: ({ date, children }) => renderCell(date, children),
          },
        }}
      />
    </div>
  );
};

CalendarMain.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  passFilter: PropTypes.func,
  holidaysDetail: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isLoading: PropTypes.bool,
  hasPermission: PropTypes.func,
};

export default CalendarMain;
