import { createAsyncThunk } from "@reduxjs/toolkit";
// import _ from "lodash";
import { alertActions } from "../../alert/index";
import { getContentsGit } from "../../../../services/gitHubService";
import { gitHubAction } from "..";

export const getGitContents = createAsyncThunk(
  "github",
  async (params, { dispatch }) => {
    console.log("params", params);
    dispatch(alertActions.clear());
    try {
      const { data } = await getContentsGit(params);
      dispatch(gitHubAction.getAllGitContents(data));
    } catch (error) {
      dispatch(alertActions.error("Access Denied to this repository"));
    }
  },
);
