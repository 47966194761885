import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "../../design/custom/pages/task-details-modal.scss";
import CustomModal from "../../components/CustomModal";
import upload from "../../assets/images/upload-mod.svg";
import addFill from "../../assets/images/add-fill.svg";
import { RightArrow, Check, ArrowRightLg, SetProgress } from "./svgicons";
import user from "../../assets/images/user-mod.svg";
import flag from "../../assets/images/flag-red.svg";
import defaultFlag from "../../assets/images/flag-001.svg";
import sandTime from "../../assets/images/sand_time.svg";
import sprint from "../../assets/images/sprint-point-star.svg";
import view from "../../assets/images/view-grn.svg";
import ham from "../../assets/images/ham.svg";
import attachment from "../../assets/images/attachment.svg";
import Imgflag from "../../assets/images/flagFilled.svg";
import ImgFlagOrange from "../../assets/images/flag-orange.svg";
import ImgFlagNormal from "../../assets/images/flag-blue.svg";
import threeDots from "../../assets/images/three-dots.svg";
import calendar from "../../assets/images/calendar-alt.svg";
import gitIcon from "../../assets/images/git.svg";
import {
  getTeam,
  getTicketAllocations,
  getTicketAllocationsById,
  getTicketsById,
} from "../../store";
import { useThunk } from "../../hooks/useThunk";
import Profile from "./userDetail";
import FileAttachment from "./FileAttachment";
import ChatSection from "./chat";
import DeleteModal from "./deleteModal";
import TimerComponent from "./timer";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";

const NewTabModal = () => {
  const { setTicketAllocationDatas } = useSelector(
    (state) => state.ticketReducer,
  );
  const [TicketAllocationByIdGet, isGetTicketAllocation] = useThunk(
    getTicketAllocationsById,
  );
  const [TicketByIdGet, isGetTicket] = useThunk(getTicketsById);
  const { ticketPriority } = useSelector((state) => state.common);
  const { ticketStatusData } = useSelector((state) => state.common);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [addUser, setAddUser] = useState(false);
  const { listId, shortCode, key, id, type } = useParams();
  const [ticket, isTicket] = useState(false);
  const [listName, setListName] = useState(null);
  const { projectData } = useSelector((state) => state.project);
  const [projectId, setProjectId] = useState(null);
  const { userProfile } = useSelector((state) => state.userProfile);
  const [matchedArray, setMatchedArray] = useState([]);
  const { ticketAllocations, ticketData } = useSelector(
    ({ ticketReducer }) => ticketReducer,
  );
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.TASK_DETAILS_MODAL,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  const commonPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.COMMON_TICKET_SETTINGS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  const [team] = useThunk(getTeam);
  function formatDate(dateString) {
    if (!dateString) return "";

    const options = {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    };

    const dateObject = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(dateObject);
  }
  useEffect(() => {
    if (projectData) {
      const foundListName = findListName(projectData, listId);
      setListName(foundListName);
    }
  }, [projectData, listId]);
  useEffect(() => {
    const conditionFunction = setTicketAllocationDatas?.ticketInfo?._id;
    const value = findAllocationByCondition(
      key === "" || !key ? ticketData : ticketAllocations,
      conditionFunction,
      setMatchedArray,
    );
    setMatchedArray(value);
  }, [ticketData, ticketAllocations, key, setTicketAllocationDatas]);
  function findAllocationByCondition(data, conditionFunction) {
    let matchedArrays = [];
    if (key === "" || !key) {
      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const group of data) {
          if (group.data && group.data.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of group.data) {
              if (item.allocations && item.allocations.length > 0) {
                const matchingAllocations = item.allocations?.filter(
                  ({ ticket }) => ticket === conditionFunction,
                );

                if (matchingAllocations?.length > 0) {
                  matchedArrays = matchingAllocations;
                }
              }
            }
          }
        }
      }
    } else if (key === "board") {
      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const group of data) {
          if (group.data && group.data.length > 0) {
            const matchingAllocations = group.data?.filter(
              (ticket) => ticket?.ticketInfo?._id === conditionFunction,
            );

            if (matchingAllocations?.length > 0) {
              matchedArrays = matchingAllocations;
            }
          }
        }
      }
    } else {
      const matchingAllocations = data?.filter(
        (e) => e.ticketInfo?._id === conditionFunction,
      );
      if (matchingAllocations?.length > 0) {
        matchedArrays = matchingAllocations;
      }
    }

    return matchedArrays;
  }

  function findListName(data, shortCode) {
    return data.reduce((result, item) => {
      if (result) return result;
      if (item.shortCode === shortCode) {
        return `${item.name} /`;
      }
      if (item.lists && item.lists.length > 0) {
        const listMatch = item.lists.find((list) => list._id === shortCode);
        if (listMatch) {
          return `${listMatch.name} /`;
        }
      }
      if (item.folders && item.folders.length > 0) {
        const matchingLists = item.folders.flatMap((folder) =>
          folder.list?.filter((list) => list._id === shortCode),
        );

        if (matchingLists.length > 0) {
          return `${matchingLists[0].name}`;
        }
      }
      return null;
    }, null);
  }

  function isDateInPast(dateObj) {
    if (!dateObj) return false;
    const currentDate = new Date();
    return new Date(dateObj) < currentDate;
  }

  useEffect(() => {
    if (type) {
      const paramValue = type.startsWith("Ticket");
      if (paramValue) {
        isTicket(true);
        TicketByIdGet({
          params: id,
          listId: { listId, page: 1, limit: 20 },
        });
      } else {
        isTicket(false);
        TicketAllocationByIdGet({
          params: id,
          listId: { listId, page: 1, limit: 20 },
        });
      }
    }
  }, []);
  useEffect(() => {
    if (projectData)
      setProjectId(
        projectData.find((each) => each.shortCode === shortCode)?._id,
      );
  }, [projectData]);
  useEffect(() => {
    if (projectId)
      team({
        projectId,
      });
  }, [projectId]);
  useEffect(() => {
    if (setTicketAllocationDatas?.title) {
      setTitle(setTicketAllocationDatas.title);
    }
    if (setTicketAllocationDatas?.description) {
      setDescription(setTicketAllocationDatas.description);
    }
  }, [setTicketAllocationDatas?.title, setTicketAllocationDatas?.description]);

  const getPriorityIcon = (priorityName) => {
    switch (priorityName) {
      case "Urgent":
        return <img src={flag} alt="Urgent" />;
      case "High":
        return <img src={ImgFlagOrange} alt="high" />;
      case "Normal":
        return <img src={ImgFlagNormal} alt="Normal" />;
      case "Low":
        return <img src={Imgflag} alt="Low" />;
      case "None":
        return <img src={defaultFlag} alt="Low" />;
      default:
        return null;
    }
  };

  const matchedStatus = ticketStatusData?.find(
    (status) => status._id === setTicketAllocationDatas?.ticketStatus,
  );

  const filteredPriorities = ticketPriority?.filter(
    (item) => item._id === setTicketAllocationDatas?.priority,
  );

  const eventStatusColor = matchedStatus ? matchedStatus.colorInfo : null;
  const completeStatus = ticketStatusData?.find((e) => e.title === "COMPLETE");

  const [fetchTicketThunk] = useThunk(getTicketAllocations);

  const [hour, setHour] = useState(null);

  useEffect(() => {
    fetchTicketThunk({ params: { listId } });
  }, []);

  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    let completedAllocations = 0;
    const totalAllocations = setTicketAllocationDatas?.allocations?.length;
    setTicketAllocationDatas?.allocations?.forEach((allocation) => {
      if (allocation.ticketStatus === completeStatus?._id) {
        completedAllocations += 1;
      }
    });

    const newProgressPercentage =
      (completedAllocations / totalAllocations) * 100;
    setProgressPercentage(Math.max(newProgressPercentage, 0));
  }, [completeStatus]);

  useEffect(() => {
    if (ticket && setTicketAllocationDatas?.allocations) {
      const initialValue = setTicketAllocationDatas?.allocations?.reduce(
        (acc, allocation) => acc + allocation.estimationHours,
        0,
      );
      setHour(initialValue);
    } else {
      setHour(setTicketAllocationDatas?.estimationHours);
    }
  }, [ticket, setTicketAllocationDatas]);

  let priorityClass = "def-btn priority-btn";

  if (filteredPriorities[0]?.title === "Low") {
    priorityClass += " priority-low";
  } else if (filteredPriorities[0]?.title === "High") {
    priorityClass += " priority-high";
  } else if (filteredPriorities[0]?.title === "Urgent") {
    priorityClass += " priority-urgent";
  } else if (filteredPriorities[0]?.title === "Normal") {
    priorityClass += " priority-normal";
  }
  const renderBody = () => (
    <>
      {isGetTicket || isGetTicketAllocation ? (
        <CommonSkeleton />
      ) : (
        <div className="task-modal-header-cover d-flex">
          <div className="task-modal-header-col task-modal-header-lft d-flex">
            <div className="h-inner-left d-flex align-items-center">
              {commonPermission(
                permissionConstants.permissions.COMMON_TICKET_LIST_STATUS,
              ) &&
                !ticket && (
                  <>
                    <div className="task-status-block">
                      <div className="task-status-btn-cover">
                        <div
                          className="status-dropdown-block"
                          style={{ backgroundColor: `${eventStatusColor}` }}
                          type="button"
                        >
                          {matchedStatus?.title}
                        </div>
                        <div style={{ backgroundColor: `${eventStatusColor}` }}>
                          <button
                            className="status-ctrl-btn d-flex align-item-center h-100"
                            type="button"
                          >
                            <RightArrow />
                          </button>
                        </div>
                      </div>
                    </div>
                    {commonPermission(
                      permissionConstants.permissions
                        .COMMON_TICKET_MARK_AS_COMPLETED,
                    ) && (
                      <button
                        className="task-complete-btn"
                        type="button"
                        style={{ backgroundColor: `${eventStatusColor}` }}
                      >
                        <Check />
                      </button>
                    )}
                  </>
                )}

              {commonPermission(
                permissionConstants.permissions.COMMON_TICKET_LIST_ASSIGNEE,
              ) &&
                !ticket && (
                  <div className="user-group d-flex">
                    {!setTicketAllocationDatas?.assignedUsers?.length > 0 && (
                      <button
                        className="user-def add-user-btn"
                        type="button"
                        disabled={
                          !commonPermission(
                            permissionConstants.permissions
                              .COMMON_TICKET_LIST_ASSIGNEE_EDIT,
                          )
                        }
                      >
                        <img src={user} alt="" />
                        <span className="add-icon">
                          <img src={addFill} alt="addFill" />
                        </span>
                      </button>
                    )}
                    <Profile
                      TaskData={setTicketAllocationDatas}
                      addUser={addUser}
                      setAddUser={setAddUser}
                      ticket={ticket}
                      onAllocateUser={() => {}}
                      onDeallocateUser={() => {}}
                      NewTab="newTab"
                    />
                  </div>
                )}
              {commonPermission(
                permissionConstants.permissions.COMMON_TICKET_LIST_PRIORITY,
              ) && (
                <div className="info-items d-flex mt-0">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="info-toggle"
                      className={`def-btn priority-btn ${
                        filteredPriorities[0]?.title && priorityClass
                      }`}
                      variant="default"
                    >
                      {getPriorityIcon(
                        filteredPriorities[0]?.title
                          ? filteredPriorities[0]?.title
                          : "None",
                      )}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              )}
            </div>
            {hasPermission(
              permissionConstants.permissions.TASK_DETAILS_MODAL_GITHUB_MODAL,
            ) &&
              (isGetTicket || isGetTicketAllocation ? (
                <div width={200}>
                  <CommonSkeleton />
                </div>
              ) : (
                <div className="github_actions d-flex align-items-center">
                  {userProfile?.gitStatus === true &&
                  setTicketAllocationDatas?.project !== null &&
                  setTicketAllocationDatas?.project !== undefined ? (
                    <div className="gitIco">
                      <img src={gitIcon} alt="" style={{ height: "30px" }} />
                      <button
                        type="button"
                        className="btn btn-share"
                        style={{ marginLeft: "3px" }}
                      >
                        G764342786
                      </button>
                    </div>
                  ) : null}
                </div>
              ))}
            <div className="task-details-block custom-Id d-flex align-items-center">
              {isGetTicket || isGetTicketAllocation ? (
                <div className="timer-block">
                  <CommonSkeleton width={100} />
                </div>
              ) : (
                <div className="timer-block">
                  <div className="t-label">Custom ID</div>
                  <div className="t-det">
                    {ticket
                      ? setTicketAllocationDatas?.customId
                      : setTicketAllocationDatas?.customId}
                  </div>
                </div>
              )}
            </div>
            {isGetTicket || isGetTicketAllocation ? (
              <CommonSkeleton />
            ) : (
              hasPermission(
                permissionConstants.permissions.TASK_DETAILS_MODAL_MORE_OPTIONS,
              ) && (
                <div className="h-inner-right d-flex align-items-center">
                  <div className="dropdown custom-drop-menu task-settings-dropdown drop-new">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="info-toggle"
                        className="def-btn share-btn border-0"
                        variant="default"
                      >
                        <img src={threeDots} alt="threeDots" />
                      </Dropdown.Toggle>
                    </Dropdown>
                    <DeleteModal />
                  </div>
                </div>
              )
            )}
          </div>
          <div className="task-modal-header-col task-modal-header-rgt d-flex align-items-center">
            <div className="task-details-block d-flex align-items-center">
              {hasPermission(
                permissionConstants.permissions.TASK_DETAILS_MODAL_CREATEDAT,
              ) && (
                <div className="timer-block timer-b">
                  <div className="t-label">CREATED</div>
                  <div className="t-det">
                    {formatDate(setTicketAllocationDatas?.createdAt)}
                  </div>
                </div>
              )}
              {hasPermission(
                permissionConstants.permissions.TASK_DETAILS_MODAL_TIME_TRACKER,
              ) && (
                <TimerComponent
                  task={setTicketAllocationDatas}
                  ticket={ticket}
                  newTab
                />
              )}
              {/* <div className="timer-block timer-b">
              <div className="t-label">TIME TRACKED</div>
              <div className="t-det d-flex align-items-center">
                <span className="icon-block">
                  <img src={playBtn} alt="" />
                </span>{" "}
                9 Feb, 12:30
              </div>
            </div> */}
              {isGetTicket || isGetTicketAllocation ? (
                <CommonSkeleton width={100} />
              ) : (
                hasPermission(
                  permissionConstants.permissions
                    .TASK_DETAILS_MODAL_ESTIMATION_HOUR,
                ) && (
                  <div className="timer-block">
                    <div className="t-label">ESTIMATE</div>
                    <div className="t-det d-flex align-items-center">
                      <span className="icon-block">
                        <img src={sandTime} alt="sandTime" />
                      </span>{" "}
                      <input
                        className="estimateInp"
                        type="text"
                        value={hour}
                        disabled={ticket}
                        onKeyDown={(event) => {
                          const { key, target } = event;
                          const currentValue = target.value;
                          if (
                            !/^\d$/.test(key) &&
                            key !== "Backspace" &&
                            key !== "Delete"
                          ) {
                            event.preventDefault();
                          }
                          const proposedValue =
                            currentValue.slice(0, target.selectionStart) +
                            key +
                            currentValue.slice(target.selectionEnd);
                          if (proposedValue < 1 || proposedValue > 100) {
                            event.preventDefault();
                          }
                        }}
                      />
                      hr
                    </div>
                  </div>
                )
              )}
              <div className="timer-block timer-block-points">
                <div className="sprint-points-btn">
                  <img src={sprint} alt="sprint" />
                </div>
              </div>
              {commonPermission(
                permissionConstants.permissions.COMMON_TICKET_LIST_STARTDATE,
              ) &&
                (isGetTicket || isGetTicketAllocation ? (
                  <CommonSkeleton width={100} />
                ) : (
                  <div className="timer-block start-date-b">
                    <div className="t-label">START DATE</div>
                    <div className="t-det text-center" type="button">
                      {setTicketAllocationDatas?.startDate ? (
                        new Intl.DateTimeFormat("en-US", {
                          month: "short",
                          day: "2-digit",
                        })?.format(
                          new Date(setTicketAllocationDatas?.startDate),
                        )
                      ) : (
                        <img src={calendar} alt="" />
                      )}
                    </div>
                    <span
                      className="arrow-st
"
                    >
                      <ArrowRightLg />
                    </span>
                  </div>
                ))}

              {commonPermission(
                permissionConstants.permissions.COMMON_TICKET_LIST_DUE_DATE,
              ) &&
                (isGetTicket || isGetTicketAllocation ? (
                  <CommonSkeleton width={100} />
                ) : (
                  <div className="timer-block">
                    <div className="t-label">DUE DATE</div>
                    <div
                      className={`t-det text-center ${
                        isDateInPast(setTicketAllocationDatas?.dueDate)
                          ? "due-date"
                          : ""
                      }`}
                    >
                      {setTicketAllocationDatas?.dueDate ? (
                        new Intl.DateTimeFormat("en-US", {
                          month: "short",
                          day: "2-digit",
                        })?.format(new Date(setTicketAllocationDatas?.dueDate))
                      ) : (
                        <img src={calendar} alt="" />
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {commonPermission(
              permissionConstants.permissions.COMMON_TICKET_LIST_ASSIGNEE,
            ) && (
              <div className="rgt-btn-block">
                <div className="dropdown task-watch-dropdown">
                  <button
                    className="def-btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={view} alt="view" />

                    <span className="view-count">
                      {ticket
                        ? [
                            ...new Set(
                              setTicketAllocationDatas?.allocations?.flatMap(
                                (allocation) =>
                                  allocation.assignedUsers?.map(
                                    (assignedUser) => assignedUser.user,
                                  ),
                              ),
                            ),
                          ]?.length
                        : setTicketAllocationDatas?.assignedUsers?.length}
                    </span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="action">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="action">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="action">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="task-body-wrapper d-flex height-auto pt-1">
        <div className="task-body-col task-body-col-left">
          <div className="task-body-block custom-scroll ps-3 pb-1 pt-3">
            {!ticket && (
              <div>
                {isGetTicket || isGetTicketAllocation ? (
                  <div className="task-title">
                    <CommonSkeleton height={30} />
                  </div>
                ) : (
                  hasPermission(
                    permissionConstants.permissions.TASK_DETAILS_MODAL_TITLE,
                  ) && (
                    <>
                      <h6>Task</h6>
                      <div className="task-title">
                        <input
                          className="task-title-name task_name"
                          style={{ border: "none" }}
                          value={setTicketAllocationDatas?.ticketInfo?.title}
                          disabled={!ticket}
                        />
                      </div>
                    </>
                  )
                )}
                {isGetTicket || isGetTicketAllocation ? (
                  <div className="sub-task-block">
                    <CommonSkeleton height={50} />
                  </div>
                ) : (
                  hasPermission(
                    permissionConstants.permissions
                      .TASK_DETAILS_MODAL_DESCRIPTION,
                  ) && (
                    <div className="sub-task-block">
                      <textarea
                        className="textarea-edit resizable-textarea custom-scroll"
                        placeholder="No Description"
                        value={
                          setTicketAllocationDatas?.ticketInfo?.description
                        }
                        disabled={!ticket}
                      />
                    </div>
                  )
                )}
              </div>
            )}
            {isGetTicket || isGetTicketAllocation ? (
              <div className="task-title">
                <CommonSkeleton height={30} />
              </div>
            ) : (
              hasPermission(
                permissionConstants.permissions.TASK_DETAILS_MODAL_TITLE,
              ) && (
                <>
                  <div className="task-title">
                    <input
                      className="task-title-name task_name"
                      placeholder="Enter The Title"
                      style={{ border: "none" }}
                      value={title}
                      disabled
                    />
                  </div>
                  <div className="sub-task-block">
                    <textarea
                      className="textarea-edit resizable-textarea"
                      placeholder="No Data"
                      value={description}
                      disabled
                    />
                  </div>
                  <div className="custom-task-cover">
                    {ticket && (
                      <div className="d-flex task-progress-wrapper">
                        <div className="task-progress-ctrl">
                          <div className="progress-ctrl">
                            <SetProgress />
                          </div>
                          <div className="progress-label">Task Progress</div>
                        </div>
                        <div className="task-progressbar-block">
                          <div className="progress-container">
                            <div
                              id="progress-bar"
                              className="progress-bar"
                              style={{ width: `${progressPercentage}%` }}
                            />
                          </div>
                          <span id="progress-bar-text">
                            {progressPercentage <= 0
                              ? "0%"
                              : `${progressPercentage.toFixed(0)}%`}
                          </span>
                        </div>
                      </div>
                    )}

                    {setTicketAllocationDatas?.allocations && (
                      <div className="todo-content-cover">
                        <div className="todo-title-wrapper d-flex align-items-center">
                          <div className="block-lft d-flex align-items-center">
                            <div className="title-block">Create</div>

                            <Dropdown className="dropdown custom-drop-menu task-settings-dropdown drop-new">
                              <Dropdown.Toggle
                                id="info-toggle"
                                className="select_cstm d-flex btn-sm dropdown-toggle"
                                name="Add"
                                variant="default"
                              >
                                Add
                              </Dropdown.Toggle>
                            </Dropdown>
                          </div>
                        </div>

                        {hasPermission(
                          permissionConstants.permissions
                            .TASK_DETAILS_MODAL_LIST_ALLOCATIONS,
                        ) && (
                          <div className="sub-task-cover">
                            <div className="sub-task-title d-flex align-items-center">
                              <div className="title-block">
                                {setTicketAllocationDatas?.allocations.length}{" "}
                                SUBTASKS
                              </div>
                            </div>
                            <div className="sub-task-list-cover ps-0">
                              {setTicketAllocationDatas?.allocations?.map(
                                (e, index) => {
                                  const subTaskStatus = ticketStatusData?.find(
                                    (status) => status._id === e.ticketStatus,
                                  );

                                  const eventStatusColors = subTaskStatus
                                    ? subTaskStatus.colorInfo
                                    : null;

                                  return (
                                    <div
                                      className="sub-task-list-wrapper d-flex align-items-center"
                                      key={index}
                                    >
                                      {commonPermission(
                                        permissionConstants.permissions
                                          .COMMON_TICKET_LIST_STATUS,
                                      ) && (
                                        <button
                                          className="sub-task-status-btn status-1"
                                          type="button"
                                          style={{
                                            backgroundColor: eventStatusColors,
                                          }}
                                          disabled={
                                            !commonPermission(
                                              permissionConstants.permissions
                                                .COMMON_TICKET_LIST_STATUS_EDIT,
                                            )
                                          }
                                        />
                                      )}
                                      <div
                                        className="user-group d-flex"
                                        key={index}
                                      >
                                        <div
                                          className="user-group subtask d-flex"
                                          key={index}
                                        >
                                          <Profile
                                            TaskData={e}
                                            dropDownVisibility={
                                              addUser === e._id
                                            }
                                            addUser={addUser}
                                            setAddUser={setAddUser}
                                            ticket
                                            allocatedId={e._id}
                                            NewTab="newTab"
                                            onAllocateUser={() => {}}
                                            onDeallocateUser={() => {}}
                                          />
                                          <button
                                            className="user-def add-user-btn"
                                            type="button"
                                          >
                                            <svg
                                              id="user_account_people_man"
                                              data-name="user, account, people, man"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="11.624"
                                              height="13.737"
                                              viewBox="0 0 11.624 13.737"
                                            >
                                              <path
                                                id="Path_1424"
                                                data-name="Path 1424"
                                                d="M14.9,16.045a.528.528,0,1,0-.743.751,4.722,4.722,0,0,1,1.41,3.38c0,.646-1.852,1.585-4.755,1.585s-4.755-.939-4.755-1.586a4.723,4.723,0,0,1,1.392-3.362.528.528,0,1,0-.747-.747A5.773,5.773,0,0,0,5,20.176c0,1.716,2.994,2.642,5.812,2.642s5.812-.926,5.812-2.642A5.77,5.77,0,0,0,14.9,16.045Z"
                                                transform="translate(-5 -9.081)"
                                                fill="#b08bfc"
                                              />
                                              <path
                                                id="Path_1425"
                                                data-name="Path 1425"
                                                d="M12.7,10.4A3.7,3.7,0,1,0,9,6.7,3.7,3.7,0,0,0,12.7,10.4Zm0-6.34A2.642,2.642,0,1,1,10.057,6.7,2.642,2.642,0,0,1,12.7,4.057Z"
                                                transform="translate(-6.887 -3)"
                                                fill="#b08bfc"
                                              />
                                            </svg>
                                            <span className="add-icon">
                                              <img src={addFill} alt="" />
                                            </span>
                                          </button>
                                        </div>

                                        <span className="usr-status" />
                                      </div>
                                      <div className="tech">{e.title}</div>

                                      <button className="def-btn" type="button">
                                        <img src={ham} alt="ham" />
                                      </button>

                                      <button className="def-btn" type="button">
                                        <img
                                          src={attachment}
                                          alt="attachment"
                                        />
                                      </button>
                                      <div className="time">
                                        <img src={sandTime} alt="sandTime" />
                                        {e.estimationHours}
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <FileAttachment
                    attachment={setTicketAllocationDatas?.attachments}
                    id={setTicketAllocationDatas?._id}
                    ticket={ticket}
                    hasPermission={hasPermission}
                    newTab
                  />
                </>
              )
            )}
          </div>

          {isGetTicket || isGetTicketAllocation ? (
            <CommonSkeleton height={400} />
          ) : (
            <div className="file-upload-block">
              <div className="file-input-cover">
                <input
                  multiple
                  name="file"
                  id="file-input"
                  className="file-input"
                />
                <label className="file-input-label" htmlFor="file-input">
                  <img src={upload} alt="upload" />
                  <div>
                    Drop Files here to attach or{" "}
                    <span className="browse">browse</span>
                  </div>
                </label>
              </div>
            </div>
          )}
        </div>
        <ChatSection
          isGetTicket={isGetTicket}
          isGetTicketAllocation={isGetTicketAllocation}
          setTicketAllocationDatas={setTicketAllocationDatas}
          ticket={ticket}
          setAddUser={setAddUser}
          hasPermission={hasPermission}
          NewTab="newTab"
        />
      </div>
    </>
  );
  const renderHeader = () => {
    return isGetTicket || isGetTicketAllocation ? (
      <CommonSkeleton />
    ) : (
      <div className="sprint-title-block">
        {hasPermission(
          permissionConstants.permissions.TASK_DETAILS_MODAL_BREADCRUMBS,
        ) && (
          <div className="d-flex sprint-block-cover align-items-center">
            <div className="d-flex sprint-sm-block-cover align-items-center">
              <div className="sprint-block">{shortCode}</div>
              <div className="sprint-block">{listName}</div>
              <div className="sprint-block">{title}</div>
            </div>
            {!ticket && (
              <div className="num-block">
                <input className="num-inp" type="text" disabled />
                <span>
                  <span>0f {matchedArray?.length} </span>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <CustomModal
        className="task-details-modal"
        dialgName="modal-dialog-centered"
        contentClassName="modal-content border-0"
        createModal
        show
        body={renderBody()}
        header={renderHeader()}
        disableCenter
      />
    </div>
  );
};

export default NewTabModal;
