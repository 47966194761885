import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { getProjectById } from "../../../../services/projectService";
import { projectActions } from "..";

export const getProjectsById = createAsyncThunk(
  "projects/getbyid",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      if (id !== undefined) {
        const {
          data: { data, message: successMessage },
        } = await getProjectById(id);
        dispatch(
          projectActions.setProjectDataById({
            data,
            successMessage,
          }),
        );
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
