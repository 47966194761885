import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import allocationServices from "../../../../services/allocationList";
import { allocationActions } from "../index";

export const getAllocationUserReport = createAsyncThunk(
  "userReport-allocation",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await allocationServices.userReportList(payload);
      dispatch(allocationActions.setAllocationUserReport(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(allocationActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
