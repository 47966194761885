import Gateway from "../gateway/gateway";

// get trash data
const getTrashData = (params) => Gateway.get("/trash", { params });
const deleteTrashData = (id, key) =>
  Gateway.delete(id ? `/trash?id=${id}&key=${key}` : `/trash?clearAll=${true}`);
const restoreTrashData = (type) => Gateway.put("/trash", type);

export default {
  getTrashData,
  deleteTrashData,
  restoreTrashData,
};
