import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "./Button/index";
import SkeletonRows from "./SkeletonLoader/SkeletonRows";
import SortIcon from "../assets/images/sort.svg";
import LanguageContext from "../context/LanguageContext";

const CustomInfinityTable = ({
  tableClassName = "",
  divClassName = "",
  columns = [],
  data = [],
  pagination: { currentPage, limit = 10 },
  onPageChange,
  count,
  showPagination,
  isLoading,
  tableName = "",
  showtableName,
  onSortChange,
}) => {
  const {
    languageData: { customTable },
  } = useContext(LanguageContext);

  const totalPages = count ? Math.ceil(count / limit) : 0;

  const pageNumbersToShow = [...Array(totalPages).keys()]
    .map((i) => 1 + i)
    .filter(
      (__, index) =>
        (index === 1 ||
          index === totalPages ||
          Math.abs(currentPage - index) < 3 ||
          index === currentPage) === true,
    );

  const renderPagination = () => {
    const onPrevClicked = () => {
      if (currentPage === 1) {
        return;
      }
      onPageChange(currentPage - 1);
    };
    const onNextClicked = () => {
      if (currentPage === totalPages) {
        return;
      }
      onPageChange(currentPage + 1);
    };
    const onPageNumberClick = (index) => {
      if (currentPage === index) {
        return;
      }
      onPageChange(index);
    };

    const renderPageStart = () => {
      if (currentPage === 1) return 1;
      return limit * (currentPage - 1) + 1;
    };
    const renderPageEnd = () => {
      if (isLoading) return " ";
      if (limit * currentPage > count) return count;
      return limit * currentPage;
    };
    if (!data?.length && !isLoading) return null;

    return (
      <div className="table-pagination-cover d-flex">
        {!!count && (
          <div className="pagination-entries">{`Showing ${renderPageStart()} to ${renderPageEnd()} entries`}</div>
        )}
        <div className="pagination-nav-block">
          <nav className="pagination-nav" aria-label="Page navigation">
            <ul className="pagination">
              {currentPage !== 1 ? (
                <li className="page-item">
                  <a
                    className="page-link-btn prev"
                    href="##"
                    onClick={() => {
                      if (currentPage !== 1) onPrevClicked();
                    }}
                  >
                    Prev
                  </a>
                </li>
              ) : null}

              {pageNumbersToShow.map((num) => (
                <React.Fragment key={`pg${num}`}>
                  {num > 1 && !pageNumbersToShow.includes(num - 1) && (
                    <span className="item-dots">...</span>
                  )}
                  <li className="page-item">
                    <a
                      className={`page-link ${
                        num === currentPage ? "active" : ""
                      }`}
                      href="##"
                      onClick={() => onPageNumberClick(num)}
                    >
                      {num}
                    </a>
                  </li>
                </React.Fragment>
              ))}
              {currentPage !== totalPages ? (
                <li className="page-item">
                  <a
                    className="page-link-btn next"
                    href="##"
                    onClick={() => {
                      if (currentPage !== totalPages) onNextClicked();
                    }}
                  >
                    Next
                  </a>
                </li>
              ) : null}
            </ul>
          </nav>
        </div>
      </div>
    );
  };

  return (
    <>
      {showtableName && (
        <div className="page-title">
          <h2>{tableName}</h2>
        </div>
      )}
      <div className={`table-responsive custom-scroll ${divClassName}`}>
        <table className={`table custom-table ${tableClassName}`}>
          <thead>
            <tr>
              {columns?.map(
                (
                  {
                    name,
                    icon,
                    titleClasses = "",
                    thProps,
                    selector,
                    activitySort,
                  },
                  index,
                ) => (
                  <th
                    scope="col"
                    className={titleClasses}
                    key={`head${name}${index}`}
                    {...thProps}
                  >
                    <div className={activitySort ? "tbl-hd " : "sort-th"}>
                      {name}

                      {Boolean(icon) && (
                        <Button
                          type="button"
                          className="btn table-sort"
                          onClick={() => onSortChange(selector)}
                        >
                          <img src={SortIcon} alt="TIcket" />
                        </Button>
                      )}
                    </div>
                  </th>
                ),
              )}
            </tr>
          </thead>

          <tbody>
            {data?.length ? (
              data?.map((row, index) => (
                <tr key={`row-${index + 1}`}>
                  {columns?.map(
                    (
                      { selector, cell, colClassName = "", contentClass = "" },
                      index,
                    ) =>
                      cell ? (
                        <td
                          className={colClassName}
                          key={`function-cell-${index + 1}`}
                        >
                          {contentClass ? (
                            <div className={contentClass}>
                              {cell({ row, index })}
                            </div>
                          ) : (
                            cell({ row, index })
                          )}
                        </td>
                      ) : (
                        <td className={colClassName} key={`cell-${index + 1}`}>
                          {contentClass ? (
                            <div className={contentClass}>{row[selector]}</div>
                          ) : (
                            row[selector]
                          )}
                        </td>
                      ),
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns?.length} className="text-center">
                  {!isLoading && customTable.noData}
                </td>
              </tr>
            )}
          </tbody>
          {isLoading && <SkeletonRows columns={columns} limit={limit} />}
        </table>
        {showPagination ? renderPagination() : null}
      </div>
    </>
  );
};
CustomInfinityTable.propTypes = {
  tableClassName: PropTypes.string,
  divClassName: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      titleClasses: PropTypes.string,
      thProps: PropTypes.objectOf(PropTypes.string),
      selector: PropTypes.string,
      cell: PropTypes.func,
      colClassName: PropTypes.string,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  theme: PropTypes.string,
  isLoading: PropTypes.bool,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    limit: PropTypes.number,
  }),
  onPageChange: PropTypes.func,
  count: PropTypes.number,
  setLimit: PropTypes.node,
  showPagination: PropTypes.bool,
  tableName: PropTypes.string,
  showtableName: PropTypes.bool,
  onSortChange: PropTypes.func,
  sortOrder: PropTypes.string,
};

export default CustomInfinityTable;
