import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { postfeedService } from "../../../../services/feedService";
import { postFeeds } from "..";

export const postFeed = createAsyncThunk(
  "postFeed",
  async function (formData, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data, message: successMessage },
      } = await postfeedService(formData);
      dispatch(postFeeds(data));
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      //   dispatch(sprintActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
