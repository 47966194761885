// import { createAsyncThunk } from "@reduxjs/toolkit";
// import _ from "lodash";
// import { iconActions } from "..";

// import technologyIconServices from "../../../../../services/technologyIconServices";
// import { alertActions } from "../../../alert";

// export const getTechnologyIconById = createAsyncThunk(
//   "list-technologyIconByid",
//   async ({ id, techId }, { dispatch }) => {
//     // dispatch(alertActions.clear());

//     try {
//       const { data } = await technologyIconServices.getTechnologyIconById({
//         id,
//         techId,
//       });
//       console.log("datadfdf", data);

//       dispatch(
//         iconActions.singleTechnologyIcon({
//           data,
//         }),
//       );
//     } catch (error) {
//       const errorMessage = _.isString(error)
//         ? error
//         : error.response.data.message;
//       dispatch(alertActions.error(errorMessage));
//     }
//   },
// );

import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { iconActions } from "..";

import technologyIconServices from "../../../../../services/technologyIconServices";
import { alertActions } from "../../../alert";

export const getTechnologyIconById = createAsyncThunk(
  "list-notificationIconByid",
  async ({ id, techId }, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const { data } = await technologyIconServices.getTechnologyIconById({
        id,
        techId,
      });

      dispatch(
        iconActions.singleTechnologyIcon({
          data: data?.data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
