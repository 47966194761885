import noneIcon from "../../assets/images/None_icon.svg";
import statusIcon from "../../assets/images/Status_icon.svg";
import assigneeIcon from "../../assets/images/Assignee_icon.svg";
import priorityIcon from "../../assets/images/Priority_icon.svg";
import dueDateIcon from "../../assets/images/Due_date_icon.svg";
// import arrowRight from "../../assets/images/arrow-right.svg";
import locationIcon from "../../assets/images/Location_icon.svg";
import xlsImage from "../../assets/images/xls_dropdown.svg";
import pdfImage from "../../assets/images/pdf_dropdown.svg";

export const groupByOptions = [
  { value: "none", label: "None", svg: noneIcon },
  { value: "ticketStatus", label: "Status", svg: statusIcon },
  { value: "assignees", label: "Assignee", svg: assigneeIcon },
  { value: "priority", label: "Priority", svg: priorityIcon },
  { value: "dueDate", label: "Due date", svg: dueDateIcon },
];
export const exportOptions = [
  { value: "xls", label: "Export as Excel", svg: xlsImage },
  { value: "pdf", label: "Export as PDF", svg: pdfImage },
];

export const customOptions = [
  { value: "endDate", label: "End Date", svg: dueDateIcon },
  { value: "location", label: "Location", svg: locationIcon },
];

export const subtaskOptions = [
  {
    value: "status",
    label: "Status",
    description: "",
    checked: true,
  },
  {
    value: "expandAll",
    label: "Expand all",
    description: "",
    checked: false,
  },
  {
    value: "seperateTask",
    label: "As separate tasks",
    description: "Use this to filter subtasks",
    checked: false,
  },
];

const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  const values = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      const value = cookie.substring(name.length, cookie.length);
      const valueArray = value.split(",");
      values.push(...valueArray);
    }
  }
  return values;
};
export const showOptions = [
  // {
  //   value: "column",
  //   label: "Column",
  //   svg: arrowRight,
  //   subOptions: [
  //     { value: "demoText1", label: "Demo Text", svg: dueDateIcon },
  //     { value: "demoText2", label: "Demo Text", svg: dueDateIcon },
  //   ],
  // },
  // {
  //   value: "taskLocation",
  //   label: "Task locations",
  //   checked: true,
  // },
  // {
  //   value: "subtaskParent",
  //   label: "Subtask parent names",
  //   checked: false,
  // },
  {
    value: "closedTasks",
    label: "Completed Tasks",
    checked: !!getCookie("show")?.includes("closedTasks"),
  },
  {
    value: "closedSubtasks",
    label: "Completed Subtasks",
    checked: !!getCookie("show")?.includes("closedSubtasks"),
  },
  {
    value: "SeperateTasks",
    label: "As Seperate Tasks",
    checked: !!getCookie("show")?.includes("SeperateTasks"),
  },
  // {
  //   value: "tasksMultipleLists",
  //   label: "Tasks in Multiple Lists",
  //   checked: true,
  // },
  // {
  //   value: "emptyStatus",
  //   label: "Empty status",
  //   checked: false,
  // },
  {
    value: "expandAll",
    label: "Expand all",
    checked: !!getCookie("show").includes("expandAll"),
  },
];

export const otherOptions = [
  {
    value: "autosaveView",
    label: "Autosave View",
    svg: statusIcon,
    checked: true,
  },
  {
    value: "protectView",
    label: "Protect View",
    svg: statusIcon,
    checked: false,
  },
  {
    value: "privateView",
    label: "Private View",
    svg: statusIcon,
    checked: true,
  },
  {
    value: "defaultEveryone",
    label: "Default for everyone",
    svg: statusIcon,
    checked: false,
  },
  {
    value: "defaultMe",
    label: "Default to Me Mode",
    svg: statusIcon,
    checked: true,
  },
  {
    value: "resetViewDefault",
    label: "Reset view to defaults",
    svg: statusIcon,
    checked: false,
  },
  {
    value: "exportView",
    label: "Export View",
    svg: statusIcon,
    subOptions: [
      { value: "demoText1", label: "Demo Text", svg: dueDateIcon },
      { value: "demoText2", label: "Demo Text", svg: dueDateIcon },
    ],
  },
];
