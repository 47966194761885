/* eslint-disable */
import PropTypes from "prop-types";
import moment from "moment";
import leaveIcon from "../../assets/images/note-remove.svg";
import CommonSkeletonTableRows from "../../components/SkeletonLoader/TableSkeleton";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import TicketsAllocatedModal from "./TicketsAllocatedModal";
import ProjectsAllocatedModal from "./ProjectsAllocatedModal";

const TimesheetTable = ({
  timesheetTableDates,
  isLoading,
  params,
  hasPermission,
}) => {
  function calculateProgressPercentage(completed, totalTicketsCount) {
    if (
      typeof completed !== "number" ||
      typeof totalTicketsCount !== "number" ||
      totalTicketsCount <= 0
    ) {
      return "0%";
    }

    const progress = (completed / totalTicketsCount) * 100;

    return `${progress.toFixed(0)}%`;
  }
  const tooltipRef = useRef();

  const [ticketListView, setTicketListView] = useState(null);

  const handleTicketListView = (index, date) => {
    console.log("datessssssssssssss", date);
    setTicketListView((prevIndex) => (prevIndex === index ? null : index));
  };

  const [projectsAllocated, setProjectsAllocated] = useState(null);

  const handleProjectsAllocated = (index) => {
    setProjectsAllocated((prevIndex) => (prevIndex === index ? null : index));
  };

  // handle outside click
  const modalContainerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      // Check if the click is outside the modal container
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(e.target)
      ) {
        // Close all modals here

        setProjectsAllocated(null);
        setTicketListView(false);
      }
    };

    // Add the click event listener to the document
    document.addEventListener("click", handleOutsideClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="timesheet-table-container" ref={modalContainerRef}>
      <div
        className="reports-table-cover timesheet-table-cover"
        ref={tooltipRef}
      >
        <div className="table-responsive custom-scroll">
          <table className="table custom-table">
            <thead>
              <tr>
                {hasPermission(
                  permissionConstants.permissions.TIME_SHEET_LIST_DATE,
                ) && <th>Date</th>}
                {hasPermission(
                  permissionConstants.permissions
                    .TIME_SHEET_LIST_PRODUCTIVE_HOURS,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={
                      <Tooltip id=" Total Hours Worked">
                        Total Hours Worked
                      </Tooltip>
                    }
                  >
                    <th>
                      Total Hours <span>Worked</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions
                    .TIME_SHEET_LIST_PRODUCTIVE_HOURS,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={
                      <Tooltip id=" Productive Hours">Productive Hours</Tooltip>
                    }
                  >
                    <th>
                      Productive <span>Hours</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions.TIME_SHEET_LIST_TOTAL_TICKETS,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={<Tooltip id=" Tickets">Total Tickets</Tooltip>}
                  >
                    <th>
                      Total<span>Tickets</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions
                    .TIME_SHEET_LIST_HOURS_ALLOCATED,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={
                      <Tooltip id="Hours Allocated">
                        Hours Allocated Per Day
                      </Tooltip>
                    }
                  >
                    <th>
                      Hours Allocated <span> Per Day</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions
                    .TIME_SHEET_LIST_NO_OF_PROJECTS_ALLOCATED,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={
                      <Tooltip id=" No. Of Projects Allocated">
                        No. Of Projects Allocated
                      </Tooltip>
                    }
                  >
                    <th>
                      No. Of Projects <span>Allocated</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions.TIME_SHEET_LIST_TODO,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={<Tooltip id=" To Do">To Do</Tooltip>}
                  >
                    <th>To Do</th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions.TIME_SHEET_LIST_INPROGRESS,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={<Tooltip id=" To Do">In Progress</Tooltip>}
                  >
                    <th>
                      In <span>Progress</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions
                    .TIME_SHEET_LIST_PERFORMANCE_INDEX,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={
                      <Tooltip id=" Performance">
                        (firstApprovedCount / totalTicketsCount) * 100{" "}
                      </Tooltip>
                    }
                  >
                    <th>
                      Performance <span>Index</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions.TIME_SHEET_LIST_QUALITY_INDEX,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={
                      <Tooltip id=" Quality">
                        ((completedCount - rejectedCount) / completedCount) *
                        100
                      </Tooltip>
                    }
                  >
                    <th>
                      Quality <span>Index</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions.TIME_SHEET_LIST_COMPLETED,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={<Tooltip id=" Completed">Completed</Tooltip>}
                  >
                    <th>Completed</th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions.TIME_SHEET_LIST_QA_REJECTIONS,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={
                      <Tooltip id=" Rejections">No. of QA Rejections</Tooltip>
                    }
                  >
                    <th>
                      No. of QA <span>Rejections</span>
                    </th>
                  </OverlayTrigger>
                )}
                {hasPermission(
                  permissionConstants.permissions.TIME_SHEET_LIST_PROGRESSBAR,
                ) && (
                  <OverlayTrigger
                    placement="top"
                    container={tooltipRef}
                    overlay={<Tooltip id=" Progress">Progress</Tooltip>}
                  >
                    <th>Progress</th>
                  </OverlayTrigger>
                )}
              </tr>
            </thead>
            {isLoading ? (
              <CommonSkeletonTableRows
                limit={timesheetTableDates?.daysInMonth?.length}
                cols={13}
                height={20}
              />
            ) : (
              <tbody>
                {timesheetTableDates?.daysInMonth?.map((e, index) => {
                  const date = e?.date;
                  console.log("fffffff", e);
                  if (!moment(date, "DD-MM-YYYY").isValid()) {
                    // Skip invalid dates
                    return null;
                  }

                  // check company Holiday
                  // const publicHoliday = e?.data?.isHoliday === true;

                  const isWeekend =
                    moment(date, "DD-MM-YYYY").isoWeekday() >= 6;

                  const isSunday =
                    moment(date, "DD-MM-YYYY").isoWeekday() === 7;
                  const isSaturday =
                    moment(date, "DD-MM-YYYY").isoWeekday() === 6;

                  // Check if the next date is also a weekend (Saturday or Sunday)
                  const isNextWeekend =
                    index + 1 < timesheetTableDates?.daysInMonth?.length &&
                    moment(
                      timesheetTableDates?.daysInMonth[index + 1].date,
                      "DD-MM-YYYY",
                    ).isoWeekday() === 6;

                  const formattedDate = moment(date, "DD-MM-YYYY").format(
                    "D MMMM YYYY, dddd",
                  );

                  if (!params?.matches && isWeekend) {
                    if (isNextWeekend) {
                      // Sunday and Saturday occurring together in the same month
                      return (
                        <tr className="holiday-off" key={index}>
                          {hasPermission(
                            permissionConstants.permissions
                              .TIME_SHEET_LIST_DATE,
                          ) && <td>{formattedDate}</td>}
                          <td colSpan="12" rowSpan={2} className="holidayTxt">
                            <span className="hh-txt">Holidays</span>
                            <span className="borderd">
                              {/* Add n-1 hr tags for n holidays in a row */}
                              <hr />
                            </span>
                          </td>
                        </tr>
                      );
                    } else if (isSunday || isSaturday) {
                      if (isNextWeekend) return null;
                      // Only Sunday or Saturday, not both
                      return (
                        <tr className="holiday-off" key={index}>
                          {hasPermission(
                            permissionConstants.permissions
                              .TIME_SHEET_LIST_DATE,
                          ) && <td>{formattedDate}</td>}
                          <td colSpan="12" className="holidayTxt">
                            <span className="hh-txt">Holiday</span>
                          </td>
                        </tr>
                      );
                    }
                  } else if (e?.isHoliday === true) {
                    // Only Sunday or Saturday, not both
                    return (
                      <tr className="holiday-off" key={index}>
                        {hasPermission(
                          permissionConstants.permissions.TIME_SHEET_LIST_DATE,
                        ) && <td>{formattedDate}</td>}
                        <td colSpan="12" className="holidayTxt">
                          <span className="hh-txt">Holiday</span>
                        </td>
                      </tr>
                    );
                  } else if (e?.isLeaveDate === true) {
                    // Only Sunday or Saturday, not both
                    return (
                      <tr className="full-day-leave">
                        {hasPermission(
                          permissionConstants.permissions.TIME_SHEET_LIST_DATE,
                        ) && <td>{formattedDate}</td>}
                        <td colSpan="12">
                          <div className="leave-info">
                            <img src={leaveIcon} alt="" />
                            Full Day Leave
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    // Render the regular data row for weekdays
                    return (
                      <tr>
                        {hasPermission(
                          permissionConstants.permissions.TIME_SHEET_LIST_DATE,
                        ) && (
                          <td>
                            {moment(e?.date, "DD-MM-YYYY").format(
                              "D MMMM YYYY, dddd",
                            )}
                          </td>
                        )}
                        <td>
                          {e?.data?.totalWorkingHours != null
                            ? e?.data?.totalWorkingHours
                            : "-"}
                        </td>
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_PRODUCTIVE_HOURS,
                        ) && (
                          <td>
                            {e?.data?.productiveHours != null
                              ? e?.data?.productiveHours
                              : "-"}
                          </td>
                        )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_TOTAL_TICKETS,
                        ) && (
                          <td>
                            <span
                              className="cursonPointer"
                              onClick={() => {
                                handleTicketListView(index, e?.date);
                              }}
                            >
                              {e?.data?.totalTicketsCount != null
                                ? e?.data?.totalTicketsCount
                                : "-"}
                            </span>

                            {ticketListView === index && (
                              <TicketsAllocatedModal
                                ticketListView={ticketListView}
                                data={e?.data?.allocatedListOrSprint}
                              />
                            )}
                          </td>
                        )}

                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_HOURS_ALLOCATED,
                        ) && (
                          <td>
                            {" "}
                            {e?.data?.hoursAllocPerDay != null
                              ? e?.data?.hoursAllocPerDay
                              : "-"}
                          </td>
                        )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_NO_OF_PROJECTS_ALLOCATED,
                        ) && (
                          <td>
                            <span
                              className="cursonPointer"
                              onClick={() => {
                                handleProjectsAllocated(index);
                              }}
                            >
                              {e?.data?.totalProjects != null
                                ? e?.data?.totalProjects
                                : "-"}
                            </span>
                            {projectsAllocated === index && (
                              <ProjectsAllocatedModal
                                show={projectsAllocated}
                                data={timesheetTableDates}
                              />
                            )}
                          </td>
                        )}

                        {hasPermission(
                          permissionConstants.permissions.TIME_SHEET_LIST_TODO,
                        ) && (
                          <td>
                            {e?.data?.todoCount != null
                              ? e?.data?.todoCount
                              : "-"}
                          </td>
                        )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_INPROGRESS,
                        ) && (
                          <td>
                            {e?.data?.inProgressCount != null
                              ? e?.data?.inProgressCount
                              : "-"}
                          </td>
                        )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_PERFORMANCE_INDEX,
                        ) && (
                          <td>
                            {e?.data?.performanceIndex != null
                              ? e?.data.performanceIndex !== 0
                                ? e.data.performanceIndex % 1 !== 0
                                  ? `${e?.data.performanceIndex
                                      .toFixed(4)
                                      .slice(0, 4)}%`
                                  : `${e?.data.performanceIndex.toFixed(0)}%`
                                : "0%"
                              : "-"}
                          </td>
                        )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_QUALITY_INDEX,
                        ) && (
                          <td>
                            {e?.data?.qualityIndex != null
                              ? e?.data.qualityIndex !== 0
                                ? e.data.qualityIndex % 1 !== 0
                                  ? `${e?.data.qualityIndex
                                      .toFixed(4)
                                      .slice(0, 4)}%`
                                  : `${e?.data.qualityIndex.toFixed(0)}%`
                                : "0%"
                              : "-"}
                          </td>
                        )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_COMPLETED,
                        ) && (
                          <td>
                            {e?.data?.completedCount != null
                              ? e.data.completedCount
                              : "-"}
                          </td>
                        )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_QA_REJECTIONS,
                        ) && (
                          <td>
                            {e?.data?.rejectedCount != null
                              ? e?.data?.rejectedCount
                              : "-"}
                          </td>
                        )}
                        {hasPermission(
                          permissionConstants.permissions
                            .TIME_SHEET_LIST_PROGRESSBAR,
                        ) && (
                          <td>
                            <div className="task-progressbar-block">
                              <div className="progress-container">
                                <div
                                  id="progress-bar"
                                  className="progress-bar"
                                  style={{
                                    width: `${
                                      (e?.data?.completedCount /
                                        e?.data?.totalTicketsCount) *
                                      100
                                    }%`,
                                  }}
                                />
                              </div>
                              <span
                                id="progress-bar-text"
                                className="progress-txt"
                              >
                                {calculateProgressPercentage(
                                  e?.data?.completedCount,
                                  e?.data?.totalTicketsCount,
                                )}
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  }
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
TimesheetTable.PropTypes = {
  isLoading: PropTypes.bool,
  params: PropTypes.string,
  timesheetTableDates: PropTypes.arrayOf(),
};

export default TimesheetTable;
