/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { Accordion, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

import "../../../design/custom/pages/documentsTable.scss";
import "../../../design/custom/pages/createTask.scss";
import "../../../design/custom/pages/time-estimate.scss";
import "../../../design/custom/components/calenderDatepicker.scss";

import { useThunk } from "../../../hooks/useThunk";
import { getUserProjects } from "../../../store/slices/project/thunks/getUserProjects";
import { getInitials } from "../../../utils/utils";
import {
  DeleteIcon,
  DownloadIcon,
  Fileupload,
  MinimizeClose,
  MinimizeIcon,
  MinimizeTaskIcon,
  Minimizemain,
  SprintIco,
  TimeEstimate,
} from "./svgIcons";
import {
  FileExtensionImages,
  Priority,
  convertFileSize,
  generateUniqueId,
  getFileExtension,
  estimateTooltip,
  isWithinNextWeek,
  isWithinCurrentWeek,
} from "./service";
import ticketService from "../../../services/ticketService";
import Plus from "../../../assets/images/add-plus.svg";
// import Circle1 from "../../../assets/images/circle-icon1.svg";
import Circle2 from "../../../assets/images/circle-icon2.svg";
import dateCheck from "../../../assets/images/calendar-chkd.svg";
// import MoreNew from "../../../assets/images/more-new.svg";
import Search from "../../../assets/images/search.svg";
import userWithoutName from "../../../assets/images/userWithoutImage.svg";
import folderImage from "../../../assets/images/green_folder.svg";
import close from "../../../assets/images/close.svg";
import spinner from "../../../assets/images/spinner-load.gif";

import {
  getAllTechnologies,
  getTeam,
  getTicketAllocationsById,
  getTicketsById,
} from "../../../store";
import { getPriority } from "../../../store/slices/ticket/thunks/ticketPriority";
import { alertActions } from "../../../store/slices/alert";
import { setAddTaskModal, setGetState } from "../../../store/slices/ticket";
import { postTicket } from "../../../store/slices/ticket/thunks/postTicket";
import TaskDetail from "../../TaskModal";

import {
  hasSubModulePermission,
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
import {
  SprintFolderIcon,
  UncategorizedListIcon,
} from "../../../structure/SideNav/svgIcons";

const TicketCreatePopup = ({
  showModal,
  setShowModal,
  selectedTicket,
  ticket,
  isTicket,
  setSelectedTicket,
  setSearchParams,
}) => {
  const { shortCode, listId, type } = useParams();
  const dispatch = useDispatch();
  const [getProjectThunk] = useThunk(getUserProjects);
  const [createTicketThunk, isLoading] = useThunk(postTicket);
  const [ticketPriority] = useThunk(getPriority);
  const [getTechnologiesList] = useThunk(getAllTechnologies);
  const [team] = useThunk(getTeam);
  const { projectData } = useSelector((state) => state.project);
  const { priority } = useSelector(({ ticketReducer }) => ticketReducer);
  const { teamData } = useSelector(({ team }) => team);
  const { allTechnologies } = useSelector(({ technology }) => technology);
  const { userProfile } = useSelector((state) => state.userProfile);
  const [selectedListName, setSelectedListName] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [TicketByIdGet] = useThunk(getTicketsById);
  const [TicketAllocationByIdGet] = useThunk(getTicketAllocationsById);
  const initialState = {
    title: "",
    description: "",
    priority: "",
    subTask: [],
    dueDate: "",
    attachment: [],
    project: "",
    list: "",
    sprint: "",
  };
  const [formValues, setFormValues] = useState(initialState);
  const [initialSubTask, setInitialSubTask] = useState([]);
  const [openFolders, setOpenFolders] = useState({});
  const [isUploadSuccessful, setIsUploadSuccessful] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [project, setProject] = useState([]);
  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [savedFormValues, setSavedFormValues] = useState(() => {
    const localStorageData = localStorage.getItem("formValues");
    return localStorageData ? JSON.parse(localStorageData) : [];
  });
  const [taskSelectedValue, setTaskSelectedValue] = useState(
    JSON.parse(localStorage.getItem("taskValue")),
  );
  const [imgArray, setimgArray] = useState([]);
  const fileInputRef = useRef(null); // Ref to file input element

  const matchingSprint = projectData
    .map((project) => project?.sprint.find((sprint) => sprint._id === listId))
    .find(Boolean);

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  useEffect(() => {
    localStorage.setItem("formValues", JSON.stringify(savedFormValues));
  }, [savedFormValues]);
  useEffect(() => {
    setTaskSelectedValue(JSON.parse(localStorage.getItem("taskValue")));
  }, [localStorage.getItem("taskValue")]);

  const { addTaskModal } = useSelector((s) => s.ticketReducer);

  useEffect(() => {
    setSelectedListName(findListNameByListId(listId, type));
  }, [listId]);

  useEffect(() => {
    getProjectThunk();
    ticketPriority();
  }, []);

  useEffect(() => {
    if (projectId) {
      team({ projectId, page: 1, limit: 50 });
      getTechnologiesList(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    const filterProject = projectData.filter((project) => {
      return shortCode === project.shortCode;
    });

    setProject(filterProject);
  }, [shortCode, projectData]);

  const proId = project.map((e) => e._id)[0] ?? null;
  useEffect(() => {
    if (project)
      setProjectId(project.find((each) => each.shortCode === shortCode)?._id);
  }, [project]);

  useEffect(() => {
    if (allTechnologies) {
      const initialSubTasks = allTechnologies.map((item) => ({
        title: item?.technology?.name,
        estimationHours: "",
        user: [],
      }));
      setInitialSubTask(initialSubTasks);
    }
  }, [allTechnologies]);

  useEffect(() => {
    if (initialSubTask)
      setFormValues({ ...formValues, subTask: [...initialSubTask] });
  }, [initialSubTask]);

  function extractSprintName(inputString) {
    const match = inputString?.match(/Sprint \d+/);
    return match ? match[0] : null;
  }

  function findListNameByListId(listId, type) {
    const folderlist = project
      .filter((item) => item.folders) // Filter out objects with 'folders' property
      .flatMap((folder) => folder.folders) // Flatten the 'folders' arrays into a single array
      .flatMap((folder) => folder.list) // Flatten the 'list' arrays in all folders into a single array
      .find((list) => list._id === listId); // Find the list with the matching listId

    const sprint = project
      .flatMap((item) => item.sprint) // Flatten the "sprint" arrays from all items
      .find((item) => item._id === listId);
    const list = project
      .flatMap((item) => item.lists) // Flatten the "sprint" arrays from all items
      .find((item) => item._id === listId);

    return type === "sprint"
      ? extractSprintName(sprint?.name)
      : list?.name || folderlist?.name;
  }

  const openModal = () => {
    if (matchingSprint?.status === "Completed") {
      dispatch(alertActions.error("Sprint is completed. Cannot create task"));
      return;
    }
    dispatch(setAddTaskModal(true));
    if (formValues) {
      setFormValues({ ...initialState, subTask: [...initialSubTask] });
    }
  };

  const closeTaskModal = () => {
    if (formValues.id) {
      const updatedSavedFormValues = savedFormValues.filter(
        (e) => e.id !== formValues.id,
      );
      setSavedFormValues(updatedSavedFormValues);
    }
    dispatch(setAddTaskModal(false));
    setFormValues({ ...initialState, subTask: [...initialSubTask] });
    setSearchInput("");
  };

  const toggleFolderDropdown = (folderId) => {
    setOpenFolders({
      ...openFolders,
      [folderId]: !openFolders[folderId],
    });
  };

  const createTicketdata = () => {
    let updatedValues = {
      title: formValues.title,
      description: formValues.description,
      subTask: formValues.subTask,
      project: proId,
    };
    if (type === "sprint") {
      updatedValues = { ...updatedValues, sprint: formValues.sprint || listId };
    } else {
      updatedValues = { ...updatedValues, list: formValues.list || listId };
    }

    if (formValues.dueDate) {
      updatedValues = { ...updatedValues, dueDate: formValues.dueDate };
    }
    if (formValues.priority) {
      updatedValues = { ...updatedValues, priority: formValues.priority };
    }
    if (formValues.attachment) {
      updatedValues = { ...updatedValues, attachments: formValues.attachment };
    }
    dispatch(setGetState(false));
    createTicketThunk(updatedValues);
    if (formValues.id) {
      const updatedSavedFormValues = savedFormValues.filter(
        (e) => e.id !== formValues.id,
      );
      setSavedFormValues(updatedSavedFormValues);
    }
    if (setAddTaskModal === false)
      setFormValues({ ...initialState, subTask: [...initialSubTask] });
  };

  const totalEstimationHours = formValues?.subTask?.reduce((total, subTask) => {
    return (
      total +
      (subTask.estimationHours ? parseInt(subTask.estimationHours, 10) : 0)
    );
  }, 0);

  const handleAttachmentChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      setErrorMessage("");
      return;
    }
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
    const allowedExtensions = [
      "pdf",
      "doc",
      "docx",
      "xlsx",
      "xls",
      "jpg",
      "jpeg",
      "png",
      "txt",
    ];
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMessage(
        "Invalid file format. Allowed formats: pdf, doc, docx, xlsx, xls, jpg, jpeg, png, txt",
      );
    } else {
      setErrorMessage("");
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        attachment: [...prevFormValues.attachment, selectedFile],
      }));

      try {
        const formData = new FormData();
        formData.append("attachment", selectedFile);
        setIsUploadSuccessful(selectedFile.name);
        const { data } = await ticketService.fileUpload(formData);
        if (data.success) {
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            attachment: [...prevFormValues.attachment.slice(0, -1), data.data],
          }));
          setIsUploadSuccessful("");
        } else {
          setErrorMessage("File upload failed. Please try again.");
        }
      } catch (error) {
        setErrorMessage("File upload failed. Please try again.", error);
      }
    }
  };

  const handleRemoveFile = (index) => {
    const updatedAttachment = [...formValues.attachment];
    updatedAttachment.splice(index, 1);
    setFormValues({ ...formValues, attachment: updatedAttachment });
    setIsUploadSuccessful("");
    setErrorMessage("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDueDateChange = (date) => {
    setFormValues({
      ...formValues,
      dueDate: date,
    });
  };
  const handleOptionClick = (option) => {
    const selectedFormValue = savedFormValues?.find(
      (data) => data.id === option.id,
    );
    if (selectedFormValue) {
      setFormValues(selectedFormValue);
      dispatch(setAddTaskModal(true));
    }
  };
  const handleDeleteClick = (option, event) => {
    event.preventDefault();
    event.stopPropagation();
    localStorage.setItem(
      "formValues",
      JSON.stringify(savedFormValues.filter(({ id }) => id !== option.id)),
    );
    setSavedFormValues(savedFormValues.filter(({ id }) => id !== option.id));
    setFormValues({
      ...initialState,
      subTask: [...initialSubTask],
    });
  };
  const searchData = () => {
    let filteredData = project;
    if (searchInput.trim() === "") {
      filteredData = project;
    } else {
      filteredData = project.map((projectItem) => {
        const folderListMatch = projectItem.folders.filter((folder) => {
          const folderNameMatch = folder.name
            .toLowerCase()
            .includes(searchInput.toLowerCase());
          const matchingLists = folder.list.filter((item) =>
            item.name.toLowerCase().includes(searchInput.toLowerCase()),
          );
          return folderNameMatch || matchingLists?.length > 0;
        });
        const listNameMatch = projectItem.lists.filter((list) =>
          list.name.toLowerCase().includes(searchInput.toLowerCase()),
        );
        const sprintNameMatch = projectItem.sprint.filter((list) =>
          extractSprintName(list.name)
            .toLowerCase()
            .includes(searchInput.toLowerCase()),
        );
        return {
          ...projectItem,
          folders: folderListMatch,
          lists: listNameMatch,
          sprint: sprintNameMatch,
        };
      });
    }
    return filteredData;
  };

  useEffect(() => {
    const searchDataResults = searchData();
    if (searchDataResults.length > 0) {
      setActiveAccordionItem(searchDataResults[0]._id);
    } else {
      setActiveAccordionItem(null);
    }
  }, [searchInput]);

  function setAccordionProps(project) {
    let props = { key: project._id, defaultActiveKey: !activeAccordionItem };
    if (searchInput) {
      props = {
        key: project._id,
        defaultActiveKey: !activeAccordionItem,
        activeKey: activeAccordionItem,
      };
    }
    return props;
  }
  const commonPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.COMMON_TICKET_SETTINGS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  function getSprintIconClassName(status, name, active) {
    const currentDate = moment();
    const dateRange = name?.match(/\(([^)]+)\)/)?.[1];
    let sprintStart = "";

    if (dateRange) {
      sprintStart = dateRange.split("/");
      sprintStart = moment(sprintStart?.[0], "DD-MM-YYYY").startOf("day");
    }

    let buttonClass = "ico";
    if (status === "Completed") {
      buttonClass += " done";
    } else if (currentDate.isSameOrAfter(sprintStart) && active === true) {
      buttonClass += " inProg";
    } else {
      return buttonClass;
    }

    return buttonClass;
  }

  return (
    shortCode &&
    listId && (
      <div className="btn-float d-flex flex-row-reverse">
        {savedFormValues.slice(0, 4).map((option) => (
          <div className="dropup order-2">
            <div className="draft">
              <div className="taskIco">
                <div className="draftIco">
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </div>
              </div>
              <div
                className="draft-title"
                onClick={() => handleOptionClick(option)}
              >
                {option.title || "Untitled"}
              </div>
              <div className="draft-actns">
                <button
                  className="expand"
                  type="button"
                  onClick={() => handleOptionClick(option)}
                >
                  <MinimizeIcon />
                </button>
                <button
                  className="close"
                  type="button"
                  onClick={(event) => handleDeleteClick(option, event)}
                >
                  <MinimizeClose />
                </button>
              </div>
            </div>
          </div>
        ))}
        {savedFormValues.length > 4 && (
          <Dropdown className="dropup order-5 draftDD">
            <Dropdown.Toggle>
              +{savedFormValues.slice(4).length} more
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {savedFormValues.slice(4).map((option) => (
                <Dropdown.Item
                  key={option.id}
                  className="draft"
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="taskIco">
                    <div className="draftIco">
                      <span className="dot" />
                      <span className="dot" />
                      <span className="dot" />
                    </div>
                  </div>
                  {option.title || "Untitled"}
                  <div className="draft-actns">
                    <button
                      className="expand"
                      type="button"
                      onClick={() => handleOptionClick(option)}
                    >
                      <MinimizeIcon />
                    </button>
                    <button
                      className="close"
                      type="button"
                      onClick={(event) => handleDeleteClick(option, event)}
                    >
                      <MinimizeClose />
                    </button>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {taskSelectedValue &&
          taskSelectedValue.slice(0, 4).map((option) => (
            <div className="dropup order-2">
              <div className="draft">
                <MinimizeTaskIcon />
                <div className="draft-title">{option.title || "Untitled"}</div>
                <div className="draft-actns">
                  <button
                    className="expand"
                    type="button"
                    onClick={() => {
                      // Get the task ID you want to remove
                      const taskIdToRemove = option.id;

                      // Get the current items from local storage
                      const existingTaskValue =
                        JSON.parse(localStorage.getItem("taskValue")) || [];

                      // Use the filter method to exclude the item with the specified ID
                      const filteredTaskValue = existingTaskValue.filter(
                        (data) => data.id !== taskIdToRemove,
                      );

                      // Save the filtered items back into local storage
                      localStorage.setItem(
                        "taskValue",
                        JSON.stringify(filteredTaskValue),
                      );

                      setShowModal(true);
                      isTicket(
                        taskSelectedValue?.find((data) => data.id === option.id)
                          ?.ticket,
                      );
                      const task = taskSelectedValue?.find(
                        (data) => data.id === option.id,
                      );

                      if (task.ticket) {
                        setSearchParams({ TicketId: task.id });
                        TicketByIdGet({
                          params: task.id,
                          listId: { listId, page: 1, limit: 20 },
                        });
                      } else {
                        setSearchParams({
                          TicketAllocationId: task.id,
                        });
                        TicketAllocationByIdGet({
                          params: task.id,
                          listId: { listId, page: 1, limit: 20 },
                        });
                      }
                    }}
                  >
                    <MinimizeIcon />
                  </button>
                  <button
                    className="close"
                    type="button"
                    onClick={() => {
                      const taskIdToRemove = option.id;
                      const indexToRemove = taskSelectedValue.findIndex(
                        (data) => data.id === taskIdToRemove,
                      );
                      if (indexToRemove !== -1) {
                        taskSelectedValue.splice(indexToRemove, 1);
                        localStorage.setItem(
                          "taskValue",
                          JSON.stringify(taskSelectedValue),
                        );
                        setTaskSelectedValue(
                          JSON.parse(localStorage.getItem("taskValue")),
                        );
                      }
                      setSelectedTicket(null);
                      setShowModal(false);
                    }}
                  >
                    <MinimizeClose />
                  </button>
                </div>
              </div>
            </div>
          ))}
        {taskSelectedValue?.length > 4 && (
          <Dropdown className="dropup order-5 draftDD">
            <Dropdown.Toggle>
              {taskSelectedValue.slice(4).length} + more
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {taskSelectedValue.slice(4)?.map((option) => (
                <Dropdown.Item key={option.id} className="draft">
                  <MinimizeTaskIcon />
                  {option.title || "Untitled"}
                  <div className="draft-actns">
                    <button
                      className="expand"
                      type="button"
                      onClick={() => {
                        const task = taskSelectedValue?.find(
                          (data) => data.id === option.id,
                        );
                        setSelectedTicket(task.id);
                        setShowModal(true);
                        isTicket(task.ticket);
                      }}
                    >
                      <MinimizeIcon />
                    </button>
                    <button
                      className="close"
                      type="button"
                      onClick={() => {
                        const taskIdToRemove = option.id;
                        const taskSelectedValueCopy = [...taskSelectedValue];
                        const taskIndexToRemove =
                          taskSelectedValueCopy.findIndex(
                            (data) => data.id === taskIdToRemove,
                          );

                        if (taskIndexToRemove !== -1) {
                          taskSelectedValueCopy.splice(taskIndexToRemove, 1);

                          localStorage.setItem(
                            "taskValue",
                            JSON.stringify(taskSelectedValueCopy),
                          );
                        }

                        setSelectedTicket(null);
                        setShowModal(false);
                      }}
                    >
                      <MinimizeClose />
                    </button>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <div className="dropup addtask-dp order-1">
          {commonPermission(
            permissionConstants.permissions.COMMON_TICKET_LIST_ADD_TASK,
          ) && (
            <button
              type="button"
              className="btn add-task me-1"
              onClick={openModal}
              // disabled={matchingSprint?.status === "Completed"}
            >
              <span>
                <img src={Plus} alt="" />
              </span>
              Task
            </button>
          )}
          {addTaskModal === true ? (
            <div className="createTaskActns">
              <div className="task-add custom-scroll">
                <div className="task-header d-flex align-items-center">
                  <input
                    className="form-control title-input"
                    type="text"
                    placeholder="Enter task name"
                    value={formValues.title}
                    onChange={(e) =>
                      setFormValues({ ...formValues, title: e.target.value })
                    }
                  />
                  <button
                    type="button"
                    className="btn-minimize"
                    data-bs-dismiss="dropdown"
                    aria-expanded="false"
                    onClick={() => {
                      dispatch(setAddTaskModal(false));
                      setSavedFormValues(
                        formValues.id
                          ? savedFormValues.map((e) => {
                              if (e.id === formValues.id) return formValues;
                              return e;
                            })
                          : [
                              ...savedFormValues,
                              { ...formValues, id: generateUniqueId() },
                            ],
                      );
                    }}
                  >
                    <span className="icon">
                      <Minimizemain />
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeTaskModal}
                  />
                </div>
                <div className="search-assignee-wrp d-flex">
                  <div className="task-search-blk d-flex align-items-ceneter">
                    <label htmlFor="">In</label>
                    <div className="task-search-wrp d-flex align-items-ceneter arrow-hide">
                      <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                          variant=""
                          className="btn task-add-drop-down"
                        >
                          {selectedListName ||
                            findListNameByListId(listId, type)}
                        </Dropdown.Toggle>
                        {searchData().map((project) => (
                          <Dropdown.Menu
                            key={project._id}
                            className="drop-lst-main"
                          >
                            <div className="drop-area-view">
                              <div className="drop-search-area">
                                <img
                                  src={Search}
                                  className="search-icon"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search Spaces, Folders, Lists"
                                  value={searchInput}
                                  onChange={(e) =>
                                    setSearchInput(e.target.value)
                                  }
                                />
                              </div>
                              <div className="drop-task-lst custom-scroll">
                                <Accordion {...setAccordionProps(project)}>
                                  <Accordion.Item eventKey={project._id}>
                                    <Accordion.Header>
                                      <span className="text-tmp">S</span>
                                      <span className="text">
                                        {project.name}
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Body className="main-acco-list-view">
                                      {project.folders.map((folder) => (
                                        <Accordion.Item eventKey={folder._id}>
                                          <Accordion key={folder._id}>
                                            <Accordion>
                                              <Accordion.Header
                                                onClick={() =>
                                                  toggleFolderDropdown(
                                                    folder._id,
                                                  )
                                                }
                                              >
                                                <span className="text-tmp-img">
                                                  <img
                                                    src={folderImage}
                                                    className="tmp-fld-vew"
                                                    alt=""
                                                  />
                                                </span>
                                                <span className="text">
                                                  {folder.name}
                                                </span>
                                              </Accordion.Header>
                                              {openFolders[folder._id] &&
                                                folder?.list?.map((item) => (
                                                  <Accordion.Body
                                                    key={item.id}
                                                    onClick={() => {
                                                      findListNameByListId(
                                                        item._id,
                                                        type,
                                                      );
                                                      setSelectedListName(
                                                        item.name,
                                                      );
                                                      setFormValues({
                                                        ...formValues,
                                                        list: item._id,
                                                      });
                                                      document
                                                        .querySelector(
                                                          ".task-add-drop-down",
                                                        )
                                                        ?.click();
                                                    }}
                                                  >
                                                    <div className="listing-items">
                                                      <ul className="list-task-area">
                                                        <li
                                                          className="none-icons"
                                                          type="button"
                                                        >
                                                          {item.name}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </Accordion.Body>
                                                ))}
                                            </Accordion>
                                          </Accordion>
                                        </Accordion.Item>
                                      ))}

                                      <span className="subfolder-itm">
                                        <span className="text-success">
                                          <UncategorizedListIcon />
                                        </span>
                                        <span className="text">
                                          Uncategorized List
                                        </span>
                                      </span>
                                      {project.lists.map((list) => (
                                        <Accordion.Body
                                          key={list.id}
                                          onClick={() => {
                                            findListNameByListId(
                                              list._id,
                                              type,
                                            );
                                            setSelectedListName(list.name);
                                            setFormValues({
                                              ...formValues,
                                              list: list._id,
                                            });
                                            document
                                              .querySelector(
                                                ".task-add-drop-down",
                                              )
                                              ?.click();
                                          }}
                                        >
                                          <div className="listing-items">
                                            <ul className="list-task-area">
                                              <li
                                                className="none-icons"
                                                type="button"
                                              >
                                                {list.name}
                                              </li>
                                            </ul>
                                          </div>
                                        </Accordion.Body>
                                      ))}
                                      <span className="subfolder-itm">
                                        <span className="text-success">
                                          <SprintFolderIcon />
                                        </span>
                                        <span className="text">Sprints</span>
                                      </span>
                                      {project.sprint?.map((sprintData) => (
                                        <Accordion.Body
                                          key={sprintData._id}
                                          onClick={() => {
                                            findListNameByListId(
                                              sprintData._id,
                                              type,
                                            );
                                            setSelectedListName(
                                              extractSprintName(
                                                sprintData.name,
                                              ),
                                            );
                                            setFormValues({
                                              ...formValues,
                                              sprint: sprintData._id,
                                            });
                                            document
                                              .querySelector(
                                                ".task-add-drop-down",
                                              )
                                              ?.click();
                                          }}
                                        >
                                          <div className="listing-items">
                                            <ul className="list-task-area">
                                              <li
                                                className="name-tasks"
                                                type="button"
                                              >
                                                <span
                                                  className={getSprintIconClassName(
                                                    sprintData.status,
                                                    sprintData.name,
                                                    sprintData.active,
                                                  )}
                                                >
                                                  <SprintIco />
                                                </span>
                                                {extractSprintName(
                                                  sprintData.name,
                                                )}
                                              </li>
                                            </ul>
                                          </div>
                                        </Accordion.Body>
                                      ))}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        ))}
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="task-descreption-detail">
                  <textarea
                    className="form-control"
                    name=""
                    id=""
                    placeholder="Task description"
                    value={formValues.description}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="attachments-wrp">
                  <div className="d-flex align-items-ceneter attach-drp-wrp">
                    <label htmlFor="">Attachments</label>
                  </div>
                  <div className="hiddenUpload create-task">
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="hiddenUploadInput"
                      onChange={handleAttachmentChange}
                      multiple
                    />
                    <div className="upload-label-task">
                      <span>
                        <Fileupload />
                      </span>
                      and drop files to attach or <span>browse</span>
                    </div>
                    {errorMessage && (
                      <span className="error text-danger">{errorMessage}</span>
                    )}
                  </div>
                  {/* list uploaded file */}
                  <div className="uploadedFiles position-relative">
                    {formValues?.attachment?.map((attachment, index) => (
                      <div className="uploadedItem">
                        <div className="ico">
                          {isUploadSuccessful === attachment?.name ? (
                            <img
                              src={spinner}
                              alt=""
                              className="spinner-load"
                            />
                          ) : (
                            <img
                              src={
                                FileExtensionImages[
                                  getFileExtension(attachment?.name)
                                ]
                              }
                              alt=""
                            />
                          )}
                        </div>
                        <div className="fileDetails">
                          <span className="fileName">{attachment.name}</span>
                          {isUploadSuccessful === attachment?.name ? (
                            <span className="fileSize">Uploading...</span>
                          ) : (
                            <span className="fileSize">
                              {convertFileSize(attachment.size)}
                            </span>
                          )}
                        </div>

                        <div className="btn-group">
                          {isUploadSuccessful === attachment?.name ? null : (
                            <Link
                              to={attachment.path}
                              className="btn btn-download"
                              type="button"
                              download
                            >
                              <DownloadIcon />
                            </Link>
                          )}
                          <button
                            className="btn btn-remove"
                            type="button"
                            onClick={() => handleRemoveFile(index)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Time  Estimation  */}
                  <div className="estimate-drp-dwn">
                    <div className="inner-wrp pt-1 ps-0">
                      <h4 className="title mb-1">
                        Time Estimate{" "}
                        <span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                id="tooltip-top"
                                style={{ zIndex: 9999 }}
                              >
                                {estimateTooltip()}
                              </Tooltip>
                            }
                            trigger="hover"
                          >
                            <span data-tip="true" data-for="tooltip-top">
                              <TimeEstimate />
                            </span>
                          </OverlayTrigger>
                        </span>
                      </h4>
                      <div className="estimate-table hr-blk">
                        <div className="label-blk column usr-w">Total</div>
                        <div className="column">
                          {totalEstimationHours || 0} hours
                        </div>
                        <div className="column" />
                      </div>
                      <div className="all-technologies">
                        {allTechnologies?.map((technology, index) => (
                          <div className="estimate-table tasks">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  id={`tooltip-tech-${index}`}
                                  style={{ zIndex: 9999 }}
                                >
                                  {technology?.technology?.name}
                                </Tooltip>
                              }
                              trigger="hover"
                            >
                              <div className="label-width">
                                {technology?.technology?.name}
                              </div>
                            </OverlayTrigger>
                            <div className="column field-wrp">
                              <input
                                className="form-control form-control-sm"
                                placeholder="0 h"
                                type="text"
                                value={
                                  formValues?.subTask &&
                                  formValues?.subTask[index] &&
                                  formValues?.subTask[index]?.estimationHours
                                    ? formValues?.subTask[index]
                                        ?.estimationHours
                                    : ""
                                }
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue !== "" &&
                                    (!/^\d+$/.test(inputValue) ||
                                      parseInt(inputValue, 10) < 0)
                                  ) {
                                    dispatch(
                                      alertActions.error(
                                        "Please enter a valid positive number.",
                                      ),
                                    );
                                    return;
                                  }
                                  const updatedFormData = { ...formValues };
                                  updatedFormData.subTask[index] = {
                                    ...updatedFormData?.subTask[index],
                                    estimationHours: inputValue,
                                  };
                                  setFormValues(updatedFormData);
                                }}
                              />
                              <button
                                className="btn btn-close"
                                type="button"
                                onClick={() => {
                                  const updatedFormData = { ...formValues };
                                  updatedFormData.subTask[index] = {
                                    ...updatedFormData.subTask[index],
                                    estimationHours: "",
                                  };
                                  setFormValues(updatedFormData);
                                }}
                                disabled={
                                  (formValues.subTask &&
                                    !formValues?.subTask[index]
                                      ?.estimationHours) ||
                                  (parseInt(
                                    formValues.subTask &&
                                      formValues?.subTask[index]
                                        ?.estimationHours,
                                    10,
                                  ) || 0) <= 0
                                }
                              />
                            </div>
                            <div className="column usr-w">
                              <Dropdown>
                                <Dropdown.Toggle className="btn-assignee ps-2">
                                  {formValues.subTask &&
                                  formValues?.subTask[index]?.user?.length ? (
                                    <div className="avatar__group">
                                      {formValues?.subTask[index]?.user
                                        ?.slice(0, 1)
                                        .map((item, i) => {
                                          const userInTeamData =
                                            teamData?.projectAggregation?.find(
                                              (user) => user?.userId === item,
                                            );
                                          const handleRemoveUser = (
                                            userToRemove,
                                            subTaskIndex,
                                          ) => {
                                            const updatedFormValues = {
                                              ...formValues,
                                            };
                                            const userIndexToRemove =
                                              updatedFormValues.subTask[
                                                subTaskIndex
                                              ].user.indexOf(userToRemove);

                                            if (userIndexToRemove !== -1) {
                                              updatedFormValues.subTask[
                                                subTaskIndex
                                              ].user.splice(
                                                userIndexToRemove,
                                                1,
                                              );

                                              setFormValues(updatedFormValues);
                                            }
                                          };
                                          if (userInTeamData) {
                                            return (
                                              <span
                                                className="assignee assignee-more"
                                                type="button"
                                                key={item.userId}
                                              >
                                                <span
                                                  className="close"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveUser(
                                                      item,
                                                      index,
                                                    );
                                                  }}
                                                >
                                                  <img alt="" src={close} />
                                                </span>
                                                {userInTeamData.photo ? (
                                                  <span className="img">
                                                    {imgArray?.includes(
                                                      userInTeamData?.userId,
                                                    ) ? (
                                                      <span
                                                        className={`name avathar-sm avathar-0${
                                                          (i % 4) + 1
                                                        }`}
                                                      >
                                                        {getInitials(
                                                          userInTeamData.user,
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <img
                                                        className="usrImg"
                                                        onError={(target) =>
                                                          handleOnError(
                                                            target,
                                                            userInTeamData?.userId,
                                                          )
                                                        }
                                                        onLoad={(event) => {
                                                          event.target.style.display =
                                                            "block";
                                                        }}
                                                        src={`https://erptest.spericorn.com/repo/uploads/${userInTeamData.photo}`}
                                                        alt={
                                                          userInTeamData.user
                                                        }
                                                      />
                                                    )}
                                                  </span>
                                                ) : (
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip
                                                        id="tooltip-user"
                                                        style={{ zIndex: 9999 }}
                                                      >
                                                        {userInTeamData.user}
                                                      </Tooltip>
                                                    }
                                                    trigger="hover"
                                                  >
                                                    <span
                                                      className={`name avathar-sm avathar-0${
                                                        (i % 4) + 1
                                                      }`}
                                                      data-for="tooltip-user"
                                                    >
                                                      {getInitials(
                                                        userInTeamData.user,
                                                      )}
                                                    </span>
                                                  </OverlayTrigger>
                                                )}
                                              </span>
                                            );
                                          }
                                          return null;
                                        })}
                                      {formValues?.subTask[index]?.user
                                        ?.length > 1 && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip
                                              id="tooltip-user"
                                              style={{ zIndex: 9999 }}
                                            >
                                              {formValues?.subTask[index]?.user
                                                ?.map((item) => {
                                                  const userInTeamData =
                                                    teamData?.projectAggregation?.find(
                                                      (user) =>
                                                        user?.userId === item,
                                                    );
                                                  return userInTeamData?.user;
                                                })
                                                .join(" , ")}
                                            </Tooltip>
                                          }
                                          trigger="hover"
                                        >
                                          <span
                                            className="assignee assignee-more"
                                            data-for="tooltip-user"
                                          >
                                            +
                                            {formValues?.subTask[index]?.user
                                              ?.length - 1}
                                          </span>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  ) : (
                                    <img src={userWithoutName} alt="" />
                                  )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="no-bg  p-1">
                                  <div className="list-items-main custom-scroll allocation-drp-dwn">
                                    <ul className="p-0">
                                      {teamData?.projectAggregation?.map(
                                        (team, i) => (
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              const updatedFormData = {
                                                ...formValues,
                                              };
                                              const selectedUser = team?.userId;
                                              if (
                                                !updatedFormData.subTask[
                                                  index
                                                ].user.includes(selectedUser)
                                              ) {
                                                updatedFormData.subTask[
                                                  index
                                                ].user = [
                                                  ...updatedFormData.subTask[
                                                    index
                                                  ].user,
                                                  selectedUser,
                                                ];
                                                setFormValues(updatedFormData);
                                              } else {
                                                dispatch(
                                                  alertActions.error(
                                                    "User is already selected.",
                                                  ),
                                                );
                                              }
                                            }}
                                          >
                                            <li className="d-flex align-items-center">
                                              <div className="d-flex align-items-center">
                                                <div
                                                  className={`avathar-sm avathar-0${
                                                    (i % 4) + 1
                                                  }`}
                                                >
                                                  {team.photo ? (
                                                    imgArray?.includes(
                                                      team?.userId,
                                                    ) ? (
                                                      <span>
                                                        {getInitials(team.user)}
                                                      </span>
                                                    ) : (
                                                      <span className="w-100">
                                                        <img
                                                          className="usrImg w-100"
                                                          onError={(target) =>
                                                            handleOnError(
                                                              target,
                                                              team?.userId,
                                                            )
                                                          }
                                                          onLoad={(event) => {
                                                            event.target.style.display =
                                                              "block";
                                                          }}
                                                          src={`https://erptest.spericorn.com/repo/uploads/${team.photo}`}
                                                          alt={team.user}
                                                        />
                                                      </span>
                                                    )
                                                  ) : (
                                                    <span>
                                                      {getInitials(team.user)}
                                                    </span>
                                                  )}
                                                </div>
                                                <div className="rgt_content d-flex flex-column">
                                                  <div className="title_fst">
                                                    {team.user}
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </Dropdown.Item>
                                        ),
                                      )}
                                    </ul>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Estimation end */}
                </div>
                <div className="btm-blks d-flex align-items-ceneter">
                  <div className="falg-icon-blk d-flex">
                    <ul className="flag-blks d-flex align-items-ceneter">
                      <li className="priority">
                        <Dropdown>
                          <Dropdown.Toggle className="blk-btn-view">
                            {formValues?.priority ? (
                              priority?.priority?.map(({ _id, title }) =>
                                _id === formValues?.priority
                                  ? Priority(title)
                                  : null,
                              )
                            ) : (
                              <>
                                {/* <span>
                                  <img src={Circle1} alt="" />
                                </span> */}
                                <span>
                                  {priority?.priority?.find(
                                    (item) => item.title === "Normal",
                                  ) && <>{Priority("Normal")}</>}
                                </span>
                              </>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {priority?.priority?.map((item) => (
                              <Dropdown.Item
                                key={item._id}
                                value={formValues.priority}
                                onClick={() =>
                                  setFormValues({
                                    ...formValues,
                                    priority: item._id,
                                  })
                                }
                              >
                                {Priority(item?.title)} {item.title}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li className="duedate">
                        {formValues?.dueDate ? (
                          <span className="dateTxt">
                            <span className="ico">
                              <span className="close">
                                <img src={close} alt="close" />
                              </span>
                              <img
                                src={dateCheck}
                                alt="calendar"
                                className="calendarIco"
                              />
                            </span>
                            <span className="txt">
                              {isWithinCurrentWeek(formValues.dueDate)
                                ? formValues.dueDate.toLocaleDateString(
                                    "en-US",
                                    { weekday: "long" },
                                  )
                                : isWithinNextWeek(formValues.dueDate)
                                ? formValues.dueDate.toLocaleDateString(
                                    "en-US",
                                    {
                                      day: "numeric",
                                      weekday: "long",
                                    },
                                  )
                                : formValues.dueDate.toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    },
                                  )}
                            </span>
                          </span>
                        ) : (
                          <span>
                            <img src={Circle2} alt="" />
                          </span>
                        )}

                        <DatePicker
                          showIcon
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          selected={formValues.dueDate}
                          onChange={handleDueDateChange}
                          showMonthDropdown
                          showYearDropdown
                          popperPlacement="top-end"
                          yearDropdownItemNumber={10}
                          yearDropdownOptions={() => {
                            const currentYear = new Date().getFullYear();
                            return Array.from(
                              { length: 10 },
                              (_, i) => currentYear + i,
                            );
                          }}
                          filterDate={(date) => {
                            const day = date.getDay();
                            return day !== 0 && day !== 6;
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="c-btn-blk d-flex">
                    <div className="btn-group">
                      <button
                        className="btn create-task-btn"
                        type="button"
                        onClick={createTicketdata}
                        disabled={
                          isUploadSuccessful
                            ? true
                            : !(
                                formValues.title &&
                                formValues.subTask &&
                                formValues.subTask.some(
                                  (subtask) => subtask.estimationHours > 0,
                                )
                              ) ||
                              !(formValues.subTask && formValues.attachment)
                        }
                      >
                        {isLoading ? (
                          <span className="btn-loader">
                            <span className="spinner-border spinner-border-sm" />
                          </span>
                        ) : (
                          "Create task"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* <div className="dropup order-0">
          <button
            type="button"
            className="btn more"
            data-bs-toggle="dropdown"
            data-bs-auto-close="false"
            aria-expanded="false"
          >
            <img src={MoreNew} alt="" />
          </button>
        </div> */}
        {showModal &&
          hasSubModulePermission(
            userProfile?.formattedPermissions,
            permissionConstants.submodules.TASK_DETAILS_MODAL,
          ) && (
            <TaskDetail
              showModal={showModal}
              setShowModal={setShowModal}
              selectedTicket={selectedTicket}
              setSelectedTicket={setSelectedTicket}
              ticket={ticket}
              isTicket={isTicket}
            />
          )}
      </div>
    )
  );
};
TicketCreatePopup.propTypes = {
  isTicket: PropTypes.func,
  selectedTicket: PropTypes.string,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  ticket: PropTypes.bool,
  setSelectedTicket: PropTypes.func,
  setSearchParams: PropTypes.func,
};

export default TicketCreatePopup;
