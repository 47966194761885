import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import ticketServices from "../../../../services/projectmanagement";
import { alertActions } from "../../alert/index";

export const updateTicket = createAsyncThunk(
  "update-ticket",
  async ({ ticketId, data }, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const {
        data: { message: successMessage },
      } = await ticketServices.updateTicket({ ticketId, data });

      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
