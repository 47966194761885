import { useEffect } from "react";
import PropTypes from "prop-types";
import CustomModal from "../../../components/CustomModal";
import trash from "../../../assets/images/confirm-trash.svg";
import Button from "../../../components/Button/index";

//  UPDATED DELETE MODAL - components/DropDowns/DeleteModal.js

export const Deletemodal = ({
  ModalShow,
  closeModal,
  cancelModal,
  handleDeletes,
  loaderButton = false,
}) => {
  console.log("ModalShow", ModalShow);
  const handleDelete = () => {
    handleDeletes(ModalShow.docId);
  };
  const handleClose = () => {
    cancelModal(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Close the modal when Enter key is pressed
        handleClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const renderHeader = () => {
    return (
      <div className="text-center mx-auto">
        <div className="icons-delete">
          <img src={trash} alt="trash" />
        </div>
        <h5 id="exampleModalLabel" className="conf-msg">
          Are you sure you want to delete the file
        </h5>
        <h5 className="conf-msg">
          <b>{ModalShow.name}</b>?
        </h5>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <div className="btn-block">
          <Button
            type="button"
            onClick={handleDelete}
            disabled={loaderButton}
            className={`modal-btn btn-confirm btn ${
              loaderButton && "isLoading disable"
            }`}
          >
            {loaderButton && (
              <span className="btn-loader">
                <span className="spinner-border spinner-border-sm" />
              </span>
            )}
            Yes, Continue
          </Button>
          <button
            className="modal-btn btn-cancel btn"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <CustomModal
      className="modal fade delete-conf"
      dialgName="modal-dialog-centered modal-lg"
      header={renderHeader()}
      footer={renderFooter()}
      bodyClassname="modal-body"
      createModal={7}
      disableCenter={7}
      show={!!ModalShow.state}
      closeModal={() => {
        closeModal(false);
      }}
    />
  );
};

Deletemodal.propTypes = {
  ModalShow: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    docId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelModal: PropTypes.func.isRequired,
  handleDeletes: PropTypes.func.isRequired,
  loaderButton: PropTypes.bool,
};

export default Deletemodal;
