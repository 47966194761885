/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
import Select from "react-select";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Counter from "../../../components/DashBoard/Counter";
import workingHour from "../../../assets/images/workingHours.svg";
import projectsAllocated from "../../../assets/images/projects-allocated.svg";
import client from "../../../assets/images/client.svg";
import teamLead from "../../../assets/images/teamLead.svg";
import remarks from "../../../assets/images/remarks.svg";
// import { SearchIcon } from "../AllocationSvg";
import "../../../design/custom/pages/allocation-hour.scss";
import ReviewContents from "./ReviewContents";
import { getAllocationUserReport } from "../../../store/slices/allocationList/thunks/getAllocationUserReport";
import { useThunk } from "../../../hooks/useThunk";
import { getFirstLetters } from "../../../utils/utils";
import { getAllUsersList } from "../../../store/slices/allocationList/thunks/getAllUsersList";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
// import SubHeader from "../../../structure/SubHeader/SubHeader";

function AllocationDashboard() {
  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.ALLOCATION,
        permissionConstants.submodules.ALLOCATION_USER_REPORT,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const handleUserSelect = (selectedOption) => {
    const selectedUserId = selectedOption?.value;

    if (selectedUserId) {
      // Call the userReports function with the selected user's ID
      userReports(selectedUserId);
    }
  };

  const [userReports, reportLoading] = useThunk(getAllocationUserReport);

  const [allUserReports] = useThunk(getAllUsersList);

  const { authData } = useSelector((state) => state.auth);

  const { userReportData, allUserReportData } = useSelector(
    (state) => state.allocation,
  );

  // image error handing
  const [imgArray, setimgArray] = useState([]);

  useEffect(() => {
    allUserReports();
  }, []);

  useEffect(() => {
    if (authData?._id) userReports(authData?._id);
  }, [authData?._id]);

  const formatOptionLabel = ({ label, data }) => {
    // Destructure the photo
    const { photo } = data;

    const handleOnError = (e, id) => {
      setimgArray((prevImgArray) => [...prevImgArray, id]);
      e.target.style.display = "none";
    };

    const handleOnLoad = (e) => {
      e.target.style.display = "block";
    };
    const shouldDisplayPlaceholder = imgArray?.includes(label);

    return (
      <div className="custom-option-label custom-scroll">
        {photo ? (
          shouldDisplayPlaceholder ? (
            <div className="name userProf bg-7"> {getFirstLetters(label)}</div>
          ) : (
            <img
              onError={(e) => handleOnError(e, label)}
              onLoad={(e) => handleOnLoad(e)}
              src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
              alt=""
            />
          )
        ) : (
          <div className="name userProf bg-7">{getFirstLetters(label)}</div>
        )}

        <div className="member-list">
          <span className="name">{label}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="page-contents pt-0">
      {/* <SubHeader
        showSearchBar
        showFilter
        showMe
        showAssignee
        showShowBtn
        showOtherOptions
      /> */}
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col full-wrp-search px-0 py-0"> */}
          {/* <div className="searchOuter border-0 py-0">
              <div className="ico">
                <SearchIcon />
              </div>
              <input
                type="text"
                placeholder="search"
                className="form-control"
                // fdprocessedid="loy8cf"
              />
            </div> */}
          {/* </div> */}
        </div>
        <div className="allocation-hour">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col ">
                  {hasPermission(
                    permissionConstants.permissions
                      .ALLOCATION_USER_REPORT_SELECT_USERS,
                  ) && (
                    <div className="usr-allocation-drp">
                      <Select
                        classNamePrefix="react-select"
                        // className="filter-select me-2"
                        className="react-select-container"
                        aria-label="Default select example"
                        options={allUserReportData?.map((user) => ({
                          value: user?._id,
                          label: user?.employeeName,
                          data: { photo: user?.photo },
                        }))}
                        formatOptionLabel={formatOptionLabel}
                        onChange={handleUserSelect} // Add the onChange event handler
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row g-3 ticket-count-wrp">
                {reportLoading ? (
                  Array?.from({ length: 6 }).map((num, index) => (
                    <div
                      key={index}
                      className="col-lg-4 col-xl col-md-6 lg-mb-4"
                    >
                      <div className="count-tickets d-flex align-items-center">
                        <div className="card-title-block">
                          <CommonSkeleton height={50} />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    {hasPermission(
                      permissionConstants.permissions
                        .ALLOCATION_USER_REPORT_COUNTERS_TOTAL_WORKING_HOURS,
                    ) && (
                      <Counter
                        title="Total working Hours"
                        icon={workingHour}
                        value={userReportData?.totalAllocationHours}
                        showCardsRow
                      />
                    )}
                    {hasPermission(
                      permissionConstants.permissions
                        .ALLOCATION_USER_REPORT_COUNTERS_PROJECTS_ALLOCATED_COUNT,
                    ) && (
                      <Counter
                        title="No of Projects Allocated"
                        icon={projectsAllocated}
                        value={userReportData?.numberOfProjects}
                        showCardsRow
                      />
                    )}
                    {hasPermission(
                      permissionConstants.permissions
                        .ALLOCATION_USER_REPORT_COUNTERS_LEAD_INFO,
                    ) && (
                      <Counter
                        title="PM/Team Lead"
                        icon={teamLead}
                        value="-"
                        showCardsRow
                      />
                    )}
                    {hasPermission(
                      permissionConstants.permissions
                        .ALLOCATION_USER_REPORT_COUNTERS_CLIENT_INFO,
                    ) && (
                      <Counter
                        title="Client"
                        icon={client}
                        value="-"
                        showCardsRow
                      />
                    )}
                    {hasPermission(
                      permissionConstants.permissions
                        .ALLOCATION_USER_REPORT_COUNTERS_REMARKS,
                    ) && (
                      <Counter
                        title="Remarks"
                        icon={remarks}
                        value={`${userReportData?.totalAllocationRemarks} Comments`}
                        showCardsRow
                      />
                    )}
                  </>
                )}
              </div>
              <div className="allocation-new-blk">
                <div className="row">
                  <div className="col filter-bridge">
                    <div className="d-flex align-items-center tab-menu-otr allocation-t-wrp">
                      <div className="allocation-title">
                        Work Allocation by Hours
                      </div>
                    </div>
                  </div>
                </div>
                <ReviewContents
                  projectInfo={userReportData}
                  isLoading={reportLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllocationDashboard;
