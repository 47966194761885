/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import { Field, Form } from "react-final-form";
import DatePicker from "react-datepicker";
import { todoActions } from "../../../store/slices/todoList";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DatePickerIcon,
  DeleteIcon,
  DownloadIcon,
} from "./todoSvgIcons";
import PdfImage from "../../../assets/images/pdf.svg";
import xlsImage from "../../../assets/images/xls.svg";
import DocImage from "../../../assets/images/doc-1.svg";
import PptImage from "../../../assets/images/ppt.svg";
import textIcon from "../../../assets/images/text.svg";
import imageIcon from "../../../assets/images/image-demo.svg";
import { convertBytesToKBMB } from "../../Document/utility";
import { useThunk } from "../../../hooks/useThunk";
import { createTodo } from "../../../store/slices/todoList/thunks/createTodo";
import { deleteTodo } from "../../../store/slices/todoList/thunks/deleteTodo";
import { editTodo } from "../../../store/slices/todoList/thunks/editTodo";
import { deleteAttachments } from "../../../store/slices/todoList/thunks/deleteAttachment";
import { detailsTodoDatas } from "../../../store/slices/todoList/thunks/detailsTodoData";

export default function TodoModal() {
  const { showTodoModal, showDetailModal } = useSelector(({ todo }) => todo);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [validFiles, setValidFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [newValidFiles, setNewValidFiles] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [startDateRepeat, setStartDateRepeat] = useState(null);
  const [startTimeRepeat, setStartTimeRepeat] = useState(null);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [markCompleted, setMarkCompleted] = useState(false);
  const formRef = useRef(null);

  console.log("files", files);

  const [repeatOption, setRepeatOption] = useState("");
  const [selectedDay, setSelectedDay] = useState(false);
  const [selectedTime, setSelectedTime] = useState(false);

  const dispatch = useDispatch();
  const [addToDo, isLoading] = useThunk(createTodo);
  const [deletetodo] = useThunk(deleteTodo);
  const [editToDoData] = useThunk(editTodo);
  const [deleteAttachment] = useThunk(deleteAttachments);
  const [singleTodoDetails] = useThunk(detailsTodoDatas);

  const { allTodoList, singleTodoData, editId } = useSelector(
    (state) => state.todo,
  );

  const listTodo =
    allTodoList?.myList?.map((item) => ({
      name: item.listName,
      label: item.listName,
      id: item._id,
      iconColor: item.iconColor,
    })) || [];

  const allowedExtensions = [
    "xlsx",
    "xls",
    "pdf",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "txt",
    "jpg",
    "png",
    "jpeg",
  ];
  const fileTypeIcons = {
    pdf: PdfImage,
    doc: DocImage,
    docx: DocImage,
    xls: xlsImage,
    xlsx: xlsImage,
    ppt: PptImage,
    pptx: PptImage,
    txt: textIcon,
    jpg: imageIcon,
    jpeg: imageIcon,
    png: imageIcon,
  };

  const reminderOptions = [
    { value: "5", label: "5 minutes" },
    { value: "15", label: "15 minutes" },
    { value: "60", label: "1 hour" },
    { value: "1440", label: "1 day" },
  ];

  const repeatOptions = [
    { value: "never", label: "Never" },
    { value: "every", label: "Every" },
    { value: "daily", label: "Daily" },
  ];
  const daysOfWeek = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];

  useEffect(() => {
    if (singleTodoData?.todo?.Tags) {
      setTags([singleTodoData.todo.Tags]);
    }
  }, [singleTodoData]);

  const hasValidExtensions = (files) => {
    return files.some((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    });
  };

  const initialValues = {
    title: singleTodoData?.todo?.title || "",
    description: singleTodoData?.todo?.description || "",
    duedate: singleTodoData?.todo?.dueDate
      ? moment(singleTodoData?.todo?.dueDate).format("DD-MM-YYYY")
      : startDate,
    time: singleTodoData?.todo?.Time
      ? moment(singleTodoData?.todo?.Time).format("h:mm a")
      : startTime,
    list: singleTodoData?.todo?.List || null,
    reminder: singleTodoData?.todo?.reminder || "",
    repeat: singleTodoData?.todo?.Repeat || "",
    files: singleTodoData?.todo?.attachments || [],
    tags: singleTodoData?.todo?.Tags || "",
    repeatDate: singleTodoData?.todo?.repeatDate || startDateRepeat,
    repeatTime: singleTodoData?.todo?.repeatTime || startTimeRepeat,
  };
  console.log("initialValues", initialValues);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    const newFiles = selectedFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    });

    const duplicateFiles = newFiles.filter((newFile) => {
      return files.some((existingFile) => existingFile.name === newFile.name);
    });

    if (duplicateFiles.length > 0) {
      setErrors({
        ...errors,
        file: "File already selected.",
      });
    } else if (!hasValidExtensions(selectedFiles)) {
      setErrors({
        ...errors,
        file: "Please select a valid file (Excel, PDF, Word, PowerPoint).",
      });
    } else if (validFiles.length + newFiles.length > 5) {
      setErrors({
        ...errors,
        file: "File size exceeded",
      });
    } else {
      setValidFiles((prevValidFiles) => [...prevValidFiles, ...newFiles]);
      setFiles([...files, ...selectedFiles]);
      setErrors({ ...errors, file: "" });

      // Initialize progress for each new valid file
      const newUploadProgress = { ...uploadProgress };
      newFiles.forEach((file) => {
        newUploadProgress[file.name] = 0;
      });
      setUploadProgress(newUploadProgress);
      setNewValidFiles(newFiles);
    }
  };

  const handleRemoveFile = (fileName) => {
    const updatedValidFiles = validFiles.filter(
      (file) => file.name !== fileName.name,
    );
    setValidFiles(updatedValidFiles);
    setErrors({});
    const updatedFiles = files.filter((file) => file.name !== fileName.name);
    setFiles(updatedFiles);

    const updatedProgress = { ...uploadProgress };
    delete updatedProgress[fileName.name];
    setUploadProgress(updatedProgress);
    setNewValidFiles(
      newValidFiles.filter((file) => file.name !== fileName.name),
    );
    if (!fileName) {
      deleteAttachment({ editId, attachmentId: fileName.id });
      singleTodoDetails(editId);
    }
  };

  const validateForm = (values, a) => {
    console.log("values", values);
    console.log("valuefds", a);
    const errors = {};
    const newFiles = newValidFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    });

    if (validFiles.length - 1 + newFiles.length > 5) {
      setErrors({
        ...errors,
        file: "File size exceeded",
      });
    }

    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required.";
    }

    if (!values.description || values.description.trim() === "") {
      errors.description = "Description is required.";
    }

    if (!values.list || values.list === "") {
      errors.list = "List is required.";
    }
    if (!values.duedate || values.duedate === "") {
      console.log("values", values);
      errors.duedate = "Duedate is required.";
    }
    if (!values.time || values.time === "") {
      errors.time = "Time is required.";
    }
    if (!values.reminder || values.reminder === "") {
      console.log("values.reminder", values);

      errors.reminder = "Reminder is required.";
    }
    if (!values.repeat || values.repeat === "") {
      errors.repeat = "Repeat is required.";
    }
    if (!values.repeat || values.repeat === "") {
      errors.repeat = "Repeat is required.";
    }
    if (!values.tags || values.tags === "") {
      errors.tags = "Tag is required.";
    }
    if (!files || files === "") {
      errors.attachment = "Attachment is required.";
    }

    console.log("errors", errors);

    return errors;
  };

  const handleSubmit = (values, form) => {
    console.log("values", values);
    const validationErrors = validateForm(values);
    function formatTime(timeString) {
      const time = new Date(timeString);
      return time.toLocaleTimeString("en-US", { hour12: true });
    }
    function formatDueDate(dueDateString) {
      const dueDate = new Date(dueDateString);
      const isoString = dueDate.toISOString();
      // eslint-disable-next-line prefer-template
      return isoString.slice(0, 19) + "Z";
    }
    const data = {
      title: values.title,
      description: values.description,
      dueDate: values.duedate ? formatDueDate(values.duedate) : null,
      List: initialValues.list ? initialValues.list : values.list.id || null,
      Reminder: initialValues.reminder
        ? initialValues.reminder
        : values.reminder.value || null,
      Repeat: initialValues.repeat
        ? initialValues.repeat
        : values.repeat || null,
      Time: values.time ? formatTime(values.time) : null,
      files,
      Tags: tags,
      status: markCompleted ? "Completed" : "IsActive",
      repeatTime: values.repeat ? formatTime(values.repeatTime) : "",
      weekDays: values.repeat ? values?.weekDays?.map((data) => data) : "",
    };

    if (Object.keys(validationErrors).length === 0) {
      const formData = new FormData();

      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("List", data.List);
      formData.append("Reminder", data.Reminder);
      formData.append("Repeat", data.Repeat);
      formData.append("Tags", data.Tags);
      // if (startDate) {
      formData.append("dueDate", data?.dueDate);
      // }
      // if (startTime) {
      formData.append("Time", data.Time);
      // }
      if (values.repeatTime !== null && values.repeatTime !== undefined) {
        formData.append("repeatTime", data.repeatTime);
      }
      if (values.weekDays !== undefined && values.weekDays.length > 0) {
        console.log("data.weekDays", data.weekDays);
        formData.append("weekDays", JSON.stringify(data.weekDays));
      }

      data.files.forEach((file) => {
        formData.append("attachments", file);
      });
      formData.append("status", data.status);
      if (showDetailModal) {
        editToDoData({ id: editId, data: formData });
        dispatch(todoActions.setShowDetailModal(false));
      }

      if (showTodoModal) {
        addToDo(formData);
        dispatch(todoActions.setShowTodoModal(false));
        setErrors({});
      }
    }

    resetForm(form);
  };

  const resetForm = () => {
    const form = formRef.current;

    form.change("title", "");
    form.change("description", "");
    form.change("duedate", null);
    form.change("time", null);
    form.change("list", null);
    form.change("reminder", "");
    form.change("repeat", "");
    form.change("repeatDate", null);
    form.change("repeatTime", null);

    form.restart();

    setErrors({});
    setFiles([]);
    setTags([]);
    setStartDate("");
    setStartTime("");
    setRepeatOption("");
  };

  const handleAddTag = () => {
    if (newTag.trim() === "") {
      return;
    }

    const updatedTags = [...tags, newTag];
    setTags(updatedTags);
    setNewTag("");
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };

  const handleCompleteTask = () => {
    setMarkCompleted(!markCompleted);
  };

  const handleRepeat = (value) => {
    setRepeatOption(value);
  };

  const renderTimePicker = ({ input, meta, input: { value, onChange } }) => (
    <>
      <DatePicker
        {...input}
        id="time"
        selected={value ? new Date(value) : null}
        autoComplete="off"
        onChange={(date) => {
          // On Change, you should use final-form Field Input prop to change the value
          if (date) {
            onChange(new Date(date), "dd-MM-yyyy");
          } else {
            onChange(null);
          }
        }}
        showIcon
        icon={<DatePickerIcon />}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        placeholderText="Select Time"
      />
      {meta.error && meta.touched && (
        <span className="text-danger">
          <small>{meta.error}</small>
        </span>
      )}
    </>
  );
  const renderDatePicker = ({ input, meta, input: { value, onChange } }) => (
    <>
      <DatePicker
        {...input}
        id="duedate"
        showIcon
        icon={<DatePickerIcon />}
        autoComplete="off"
        selected={value ? new Date(value) : null}
        minDate={new Date()}
        showMonthDropdown
        showYearDropdown
        onChange={(date) => {
          // On Change, you should use final-form Field Input prop to change the value
          if (date) {
            onChange(new Date(date), "dd-MM-yyyy");
          } else {
            onChange(null);
          }
        }}
        placeholderText="Select date"
        dateFormat="dd-MM-yy"
        // value={
        //   singleTodoData?.todo?.dueDate
        //     ? moment(singleTodoData?.todo?.dueDate).format("DD-MM-YYYY")
        //     : null
        // }
      />
      {meta.error && meta.touched && (
        <span className="text-danger">
          <small>{meta.error}</small>
        </span>
      )}
    </>
  );
  const renderRepeatDatePicker = ({ input, meta }) => (
    <>
      <DatePicker
        {...input}
        id="repeatdate"
        showIcon
        icon={<DatePickerIcon />}
        autoComplete="off"
        selected={startDateRepeat}
        minDate={new Date()}
        showMonthDropdown
        showYearDropdown
        onChange={(date) => {
          setStartDateRepeat(date);
          input.onChange(date);
        }}
        placeholderText="Select date"
        dateFormat="dd-MM-yy"
      />
      {meta.error && meta.touched && (
        <span className="text-danger">
          <small>{meta.error}</small>
        </span>
      )}
    </>
  );

  const renderRepeatTimePicker = ({
    name,
    input,
    input: { value, onChange },
    meta,
  }) => (
    <>
      <DatePicker
        {...input}
        id="repeattime"
        autoComplete="off"
        onChange={(date) => {
          // On Change, you should use final-form Field Input prop to change the value
          if (date) {
            onChange(new Date(date), "dd-MM-yyyy");
          } else {
            onChange(null);
          }
        }}
        selected={value ? new Date(value) : null}
        showIcon
        icon={<DatePickerIcon />}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        placeholderText="Select Time"
      />
      {meta.error && meta.touched && (
        <span className="text-danger">
          <small>{meta.error}</small>
        </span>
      )}
    </>
  );

  return (
    <>
      <div
        className={`overlay ${showTodoModal || showDetailModal ? "show" : ""} `}
      />

      <div
        className={`slide-details-lg-cvr addToto ${
          showTodoModal || showDetailModal ? "show" : " "
        }`}
      >
        <div className="addTodo-header">
          {showTodoModal && <h3 className="title">Add Task</h3>}
          {showDetailModal && <h3 className="title">Task Detals</h3>}

          {showDetailModal && (
            <div className="mark-wrp">
              <input
                type="checkbox"
                id="mark-complete"
                name="mark-complete"
                className="chk-input"
                onChange={handleCompleteTask}
                checked={markCompleted}
              />
              <span className="check">
                <CheckIcon />
              </span>
              <label htmlFor="mark-complete">Mark As Completed</label>
            </div>
          )}

          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              resetForm();
              if (showTodoModal) {
                dispatch(todoActions.setShowTodoModal(false));
              } else dispatch(todoActions.setShowDetailModal(false));
            }}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="addTodo-forms-wrp custom-scroll">
          <Form
            keepDirtyOnReinitialize
            onSubmit={(formValues, form) => {
              console.log("formValues", formValues);
              handleSubmit(formValues, form);
              form.reset(initialValues);
            }}
            validate={validateForm}
            initialValues={initialValues}
            render={({ handleSubmit, form }) => {
              formRef.current = form;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="inp-col">
                    <div className="inp-wrp">
                      <label htmlFor="">Title</label>
                      <Field
                        name="title"
                        render={({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="text"
                              id="title"
                              placeholder="Add Title "
                              className="form-control txt-bg"
                            />
                            {meta.error && meta.touched && (
                              <span className="text-danger">
                                <small>{meta.error}</small>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="inp-col">
                    <div className="inp-wrp">
                      <label htmlFor="">Description</label>
                      <Field
                        name="description"
                        render={({ input, meta }) => (
                          <>
                            <textarea
                              {...input}
                              type="text"
                              id="description"
                              placeholder="Add Description"
                              className="form-control txt-bg txt-area"
                              rows="5"
                            />
                            {meta.error && meta.touched && (
                              <span className="text-danger">
                                <small>{meta.error}</small>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="inp-col">
                    <div className="inp-wrp">
                      <label htmlFor="">List</label>
                      <Field
                        name="list"
                        render={({ input, meta }) => (
                          <>
                            <Select
                              {...input}
                              classNamePrefix="react-select"
                              className="react-select-container "
                              id="list"
                              placeholder="Add List"
                              options={listTodo}
                              // value={listTodo.find(
                              //   (option) => option.id === input.value,
                              // )}
                              onChange={(option) => input.onChange(option)}
                            />
                            {meta.error && meta.touched && (
                              <span className="text-danger">
                                <small>{meta.error}</small>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="inp-wrp">
                      <label htmlFor="">Due Date</label>
                      <Field name="duedate" component={renderDatePicker} />
                    </div>
                  </div>

                  <div className="inp-col">
                    <div className="inp-wrp">
                      <label htmlFor="">Time</label>
                      <Field name="time" component={renderTimePicker} />
                    </div>
                    <div className="inp-wrp">
                      <label htmlFor="">Reminder</label>
                      <Field
                        name="reminder"
                        render={({ input, meta }) => (
                          <>
                            <Select
                              {...input}
                              classNamePrefix="react-select"
                              className="react-select-container "
                              id="reminder"
                              type="text"
                              placeholder="Add Reminder"
                              options={reminderOptions}
                              // value={listTodo.find(
                              //   (option) => option.id === input.value,
                              // )}
                              // onChange={(option) => input.onChange(option)}
                            />
                            {meta.error && meta.touched && (
                              <span className="text-danger">
                                <small>{meta.error}</small>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="inp-col">
                    <div className="inp-wrp">
                      <label htmlFor="">Repeat</label>
                      <Field
                        name="repeat"
                        render={({
                          input,
                          meta,
                          input: { value, onChange },
                        }) => (
                          <>
                            <Select
                              {...input}
                              classNamePrefix="react-select"
                              className="react-select-container "
                              id="repeat"
                              type="text"
                              placeholder="Add Repeat"
                              options={repeatOptions}
                              onChange={(option) => {
                                input.onChange(option.value);
                                handleRepeat(option.value);
                              }}
                              value={listTodo?.find(
                                (option) => option.id === input.value,
                              )}
                            />
                            {meta.error && meta.touched && (
                              <span className="text-danger">
                                <small>{meta.error}</small>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="inp-wrp">
                      {repeatOption === "once" && (
                        <>
                          <label htmlFor="">Date</label>
                          <Field
                            name="repeatDate"
                            component={renderRepeatDatePicker}
                          />
                        </>
                      )}
                      {repeatOption === "daily" && (
                        <>
                          <label htmlFor="">Time</label>
                          <Field
                            name="repeatTime"
                            component={renderRepeatTimePicker}
                          />
                        </>
                      )}

                      {repeatOption === "every" && (
                        <>
                          <label htmlFor="">Every Days</label>
                          <Field
                            name="weekDays"
                            render={({ input, meta }) => (
                              <>
                                <Select
                                  {...input}
                                  isMulti
                                  classNamePrefix="react-select"
                                  className="react-select-container "
                                  id="weekDays"
                                  placeholder="Add Days"
                                  options={daysOfWeek}
                                  value={listTodo.find(
                                    (option) => option.id === input.value,
                                  )}
                                  onChange={(option) => input.onChange(option)}
                                />
                                {meta.error && meta.touched && (
                                  <span className="text-danger">
                                    <small>{meta.error}</small>
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="inp-col">
                    <div className="inp-wrp">
                      <label htmlFor="">Tags</label>
                      <div className="task-lists">
                        <div className="tags-items">
                          {tags?.map((tag, index) => (
                            <div key={index} className="tag-item bg-1">
                              <span className="txt">{tag}</span>
                              <span
                                className="close"
                                onClick={() => handleRemoveTag(index)}
                              >
                                <CloseIcon />
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Field
                        name="tags"
                        render={({
                          input,
                          meta,
                          input: { value, onChange },
                        }) => (
                          <>
                            <input
                              {...input}
                              type="text"
                              id="tag"
                              placeholder="Add Tag"
                              className="form-control tag-input"
                              value={newTag}
                              // // onChange={(e) => setNewTag(e.target.value)}
                              onChange={(e) => {
                                // On Change, you should use final-form Field Input prop to change the value
                                if (e) {
                                  onChange(e.target.value);
                                  setNewTag(e ? e.target.value : "");
                                } else {
                                  onChange(null);
                                }
                              }}
                            />
                            {meta.error && meta.touched && (
                              <span className="text-danger">
                                <small>{meta.error}</small>
                              </span>
                            )}
                          </>
                        )}
                      />

                      <button
                        type="button"
                        className="btn-add tag-btn-add"
                        onClick={handleAddTag}
                      >
                        <span className="ico">
                          <AddIcon />
                        </span>
                        <span className="txt">Add</span>
                      </button>
                    </div>
                  </div>

                  <div className="inp-col">
                    <div className="inp-wrp">
                      <label htmlFor="">Attachments</label>
                      {(files.length > 0 ? files : [])
                        .concat(initialValues.files)
                        .map((file) => (
                          <div className="attachment-wrp" key={file.id}>
                            <div className="attachments">
                              <div className="lft-col">
                                <span className="ico">
                                  <img
                                    className="img-fluid"
                                    src={
                                      fileTypeIcons[
                                        file.name.split(".").pop().toLowerCase()
                                      ] || PdfImage
                                    }
                                    alt=""
                                  />
                                </span>
                                <div className="doc-title">
                                  {file.name}
                                  <span className="file-size">
                                    {convertBytesToKBMB(file.size)}
                                  </span>
                                </div>
                              </div>
                              <div className="rt-col">
                                <a
                                  href={file.path}
                                  className="btn btn-download"
                                  download
                                >
                                  <DownloadIcon />
                                </a>
                                <a
                                  href="##"
                                  className="btn btn-delete"
                                  onClick={() => {
                                    handleRemoveFile(file);
                                  }}
                                >
                                  <DeleteIcon />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      <Field
                        name="file"
                        render={({ input, meta }) => (
                          <>
                            <button
                              type="button"
                              className="btn-add tag-btn-add"
                            >
                              <span className="ico">
                                <AddIcon />
                              </span>
                              <span className="txt">Add</span>
                              <input
                                {...input}
                                type="file"
                                className="hiddenUploadInput "
                                onChange={handleFileChange}
                                multiple
                              />
                            </button>
                            {meta.error && meta.touched && (
                              <span className="text-danger">
                                <small>{meta.error}</small>
                              </span>
                            )}
                            {errors.file && (
                              <span className="text-danger">
                                <small>{errors.file}</small>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  {errors.file && (
                    <span className="error position-absolute top-100 text-danger w-100">
                      <small>{errors.file}</small>
                    </span>
                  )}
                  <div className="save-btn-wrp">
                    {showTodoModal && (
                      <button
                        type="submit"
                        className="btn btn-primary btn-save"
                      >
                        {isLoading ? "Loading..." : "Save"}
                      </button>
                    )}
                    {showDetailModal && (
                      <>
                        <button
                          type="button"
                          className="btn btn-del-task"
                          onClick={() => deletetodo(editId)}
                        >
                          Delete Task
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary btn-save-changes"
                        >
                          Save Changes
                        </button>
                      </>
                    )}
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    </>
  );
}
