import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useEffect } from "react";
import successIcon from "../assets/images/toast-success.svg";
import failedIcon from "../assets/images/toast-fail.svg";
import infoIcon from "../assets/images/toast-info.svg";
import warnIcon from "../assets/images/toast-warning.svg";
import "react-toastify/dist/ReactToastify.css";
import { alertActions } from "../store/slices/alert";

const icons = {
  success: successIcon,
  error: failedIcon,
  warn: warnIcon,
  info: infoIcon,
};

const colors = {
  success: "#65BD50",
  error: "#FF5F60",
  warn: "#EF934D",
  info: "#0263D1",
};

const messages = {
  success: "Success",
  error: "Error!",
  warn: "Warning!",
  info: "Info",
};

export const notify = (message, type, toastPosition, dispatch) => {
  if (!_.includes(["success", "error", "warn", "info"], type)) return;
  return toast[type](
    <div className={`d-flex align-items-center ${type}`}>
      <img
        src={icons[type]}
        className="Toastify__toast-icon"
        alt="successicon"
      />
      <div className="Toastify__toast--conts">
        <h3 className="Toastify__toast--title">{messages[type]}</h3>
        <div className="Toastify__toast--msg">{message}</div>
      </div>
    </div>,
    {
      position: toastPosition
        ? toast.POSITION[toastPosition]
        : toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      pauseOnFocusLoss: true,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      toastId: message,
      progressStyle: { backgroundColor: colors[type] },
      onClose: () => dispatch && dispatch(alertActions.clear()),
    },
  );
};

const Toaster = () => {
  const dispatch = useDispatch();
  const alertValue = useSelector(
    ({ alert: { value: alertValue } }) => alertValue,
  );
  const { userProfile } = useSelector(({ userProfile }) => userProfile);
  useEffect(() => {
    if (alertValue && !(userProfile?.toastPosition === "OFF")) {
      notify(
        alertValue.message,
        alertValue.type,
        userProfile?.toastPosition,
        dispatch,
      );
    }
  }, [alertValue]);

  return null;
};

export default Toaster;
