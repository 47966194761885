import _ from "lodash";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUsersData } from "../index";
import { getProjectById } from "../../../../services/projectService";
import { alertActions } from "../../alert";

export const getUser = createAsyncThunk(
  "get/Users",
  async (params, { dispatch }) => {
    try {
      if (params !== undefined) {
        const { data } = await getProjectById(params);
        dispatch(setUsersData(data?.data[0]?.allocatedUsers));
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
