import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import cronjobService from "../../../../services/cronjobService";
import { cronjobActions } from "../index";

export const postCronjob = createAsyncThunk(
  "cronjob/post",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data } = await cronjobService.postCronjob(payload);
      dispatch(alertActions.success(data.message));
      dispatch(cronjobActions.postCronjobResult(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(cronjobActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
