export const DownArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.026"
    height="3.837"
    viewBox="0 0 7.026 3.837"
  >
    <path
      id="Vector"
      d="M6.866,2.913,5.255,1.3,4.271.313a1.07,1.07,0,0,0-1.511,0l-2.6,2.6a.542.542,0,0,0,.382.924H6.485A.541.541,0,0,0,6.866,2.913Z"
      transform="translate(7.026 3.837) rotate(180)"
      fill="#81758e"
    />
  </svg>
);

export const ChartMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.612"
    height="11.771"
    viewBox="0 0 12.612 11.771"
  >
    <g
      id="Group_58764"
      data-name="Group 58764"
      transform="translate(-346 -152)"
    >
      <rect
        id="Rectangle_3601"
        data-name="Rectangle 3601"
        width="12.612"
        height="1.682"
        rx="0.841"
        transform="translate(346 152)"
        fill="#53575e"
      />
      <rect
        id="Rectangle_3602"
        data-name="Rectangle 3602"
        width="12.612"
        height="1.682"
        rx="0.841"
        transform="translate(346 155.363)"
        fill="#53575e"
      />
      <rect
        id="Rectangle_3603"
        data-name="Rectangle 3603"
        width="12.612"
        height="1.682"
        rx="0.841"
        transform="translate(346 158.727)"
        fill="#53575e"
      />
      <rect
        id="Rectangle_3604"
        data-name="Rectangle 3604"
        width="8.408"
        height="1.682"
        rx="0.841"
        transform="translate(346 162.09)"
        fill="#53575e"
      />
    </g>
  </svg>
);

export const ClockIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="clock"
    width="9.855"
    height="9.855"
    viewBox="0 0 9.855 9.855"
  >
    <g
      id="Group_59739"
      data-name="Group 59739"
      transform="translate(4.546 2.286)"
    >
      <g id="Group_59738" data-name="Group 59738">
        <path
          id="Path_4017"
          data-name="Path 4017"
          d="M238.306,122.29l-1.374-1.031v-2.1a.382.382,0,0,0-.763,0v2.29a.381.381,0,0,0,.153.305l1.527,1.145a.382.382,0,0,0,.458-.611Z"
          transform="translate(-236.169 -118.779)"
          fill="#AC80F7"
        />
      </g>
    </g>
    <g id="Group_59741" data-name="Group 59741">
      <g id="Group_59740" data-name="Group 59740">
        <path
          id="Path_4018"
          data-name="Path 4018"
          d="M4.927,0A4.927,4.927,0,1,0,9.855,4.927,4.933,4.933,0,0,0,4.927,0Zm0,9.091A4.164,4.164,0,1,1,9.091,4.927,4.169,4.169,0,0,1,4.927,9.091Z"
          fill="#AC80F7"
        />
      </g>
    </g>
  </svg>
);
