import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { TodaysUserService } from "../../../../services/feedService";
import { getFeedUsers } from "..";

export const getTodaysUser = createAsyncThunk(
  "getTodaysUser",
  async function ({ params }, { dispatch }) {
    try {
      console.log("enter");
      const { data } = await TodaysUserService(params);
      dispatch(getFeedUsers(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
