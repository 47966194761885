import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import technologyService from "../../../../services/technology";
import { technologyActions } from "../index";
import { getProjectsTabCounts } from "../../project/thunks/projectCounts";

export const getAllTechnologies = createAsyncThunk(
  "technology",
  async function (projectId, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await technologyService.getTechnologiesList(projectId);
      dispatch(technologyActions.setAllTechnologies(data));
      dispatch(getProjectsTabCounts(projectId));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(technologyActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
