/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import "../../design/custom/structure/sub-header.scss";
import filterIcon from "../../assets/images/filter-icon.svg";
import infoIcon from "../../assets/images/info_icon.svg";
import groupIcon from "../../assets/images/group.svg";
import subtaskIcon from "../../assets/images/Subtask.svg";
import AssigneeIcon from "../../assets/images/Assignee_icon.svg";
import meIcon from "../../assets/images/me.svg";
import threeDotsIcon from "../../assets/images/three-dots.svg";
import downloadIcon from "../../assets/images/download-icon.svg";
import dateImage from "../../assets/images/calendar-sm.svg";
import okIcon from "../../assets/images/ok-icon-001.svg";

import CustomDropdown from "./components/CustomDropdown";
import Filter from "./components/Filter";
import Button from "../../components/Button/index";
import Assignees from "./components/Assignees";
import Search from "../../components/Search";
import { useFilter } from "../../context/useFilter";
import CustomToolbar from "../../components/Calender/components/CustomToolbar";

import CustomDateRange from "../../components/common/DateRangePicker";
import {
  groupByOptions,
  subtaskOptions,
  showOptions,
  otherOptions,
  exportOptions,
} from "./config";
import GanttSightSelect from "./components/GanttSightSelect";
import TimesheetCalendar from "../../pages/Timesheet/TimesheetCalendar";
import Users from "./components/Users";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
// import ExportDropdown from "./components/ExportDropdown";

// import Users from "./components/Users";

const SubHeader = ({
  showSearchBar = false,
  showAssignee = false,
  showFilter = false,
  showGroupBy = false,
  showMe = false,
  showOtherOptions = false,
  showShowBtn = false,
  showSubTask = false,
  showclearAllBtn = false,
  showclearAllBtnFunc,
  clearAllButtonTitle,
  clearAllBtnDisabled = false,
  showCalendar = false,
  showReportListCalender = false,
  showDateSelect = false,
  showExport = false,
  showExportDropdown = false,
  showGanttSelect = false,
  passFilter,
  onPerformanceDateChange,
  showUsers,
  exportFnc,
  showTimeSheetCalendar,
  onMonthYearSelect,
  setUnit,
  showAllocationDate,
  type,
  pdfThunkLoading,
  showCompletedIcon = false,
  completeFunction,
  showCompleted,
  loaderPdf,
}) => {
  const ref = useRef(null);
  const { teamData } = useSelector((state) => state.team);
  const { authData } = useSelector((state) => state.auth);
  // console.log("teamData", teamData.projectAggregation);
  const handlePerformanceDateCallback = (start, end) => {
    setPerformanceDate({ start, end });
    onPerformanceDateChange({
      start,
      end,
    });
  };
  const [performanceDate, setPerformanceDate] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });

  // const handleExportOptionChange = (selectedOption) => {
  //   console.log("sele", selectedOption);
  //   setSelectedExportOption(selectedOption);

  //   if (selectedOption && selectedOption.value === "xls") {
  //     exportFnc(); // Call your export function for Excel
  //   } else if (selectedOption && selectedOption.value === "pdf") {
  //     // Handle PDF export logic here
  //     // You can add your PDF export logic here
  //   }
  // };

  useEffect(() => {
    if (onPerformanceDateChange) onPerformanceDateChange(performanceDate);
  }, []);

  const [meOption, setMeOption] = useState(false);
  const [onAssignee, setOnAssignee] = useState(false);
  const [onUser, setOnUser] = useState(false);

  const toggleAssignee = () => {
    setOnAssignee(!onAssignee);
  };
  const toggleUsers = () => {
    setOnUser(!onUser);
  };
  const { filter, setFilter } = useFilter();
  const userId = authData?._id;
  // const location = useParams();
  const initalFilterValue = {
    where: "",
    condition: "",
    option: "",
    gate: "AND",
  };
  const initalFilter = {
    search: "",
    filter: [initalFilterValue],
    groupBy: filter.groupBy === "none" ? "" : filter.groupBy,
    // groupBy: "",
    subTask: "",
    assignee: [],
    me: "",
    show: filter.show || [],
    otherOptions: [],
  };
  // console.log("filter", filter);
  const handleFilter = (key, data) => {
    // if (type === "list") {
    //   if (!data && !key) {
    //     setFilter(initalFilter);
    //     return;
    //   }
    // } else if (!(data && key)) {
    //   setFilter(initalFilter);
    //   return;
    // }
    // if (type !== "list") {
    if (!(data && key)) {
      setFilter(initalFilter);
      return;
    }
    // }
    if (Array.isArray(data) && key !== "filter") {
      // console.log("filter1", key, data);
      if (filter[key]?.some((each) => data?.includes(each))) {
        // console.log("filter2", data);
        setFilter({
          ...filter,
          [key]: filter[key]?.filter((each) => !data?.some((e) => e === each)),
        });
        if (key === "show") {
          // console.log("filter3", data);
          document.cookie = `${key}=${filter[key]?.filter(
            (each) => !data?.some((e) => e === each),
          )}`;
        }
      } else {
        // console.log("filter4", data);
        setFilter({ ...filter, [key]: [...filter[key], ...data] });
        if (key === "show") {
          // console.log("filter5", data);
          document.cookie = `${key}=${[...filter[key], ...data]}`;
        }
      }
    } else {
      // console.log("filter6", data);
      setFilter({ ...filter, [key]: data });
    }
    return filter;
  };
  // console.log("filter", filter);
  const groupByLabel = () => {
    switch (filter.groupBy) {
      case "none":
        return "None";
      case "ticketStatus":
        return "Status";
      case "assignees":
        return "Assignee";
      case "priority":
        return "Priority";
      case "dueDate":
        return "Due date";
      default:
        return "";
    }
  };
  // console.log("filter.assignee", filter.assignee);
  const assignedUsers =
    teamData?.projectAggregation?.filter((e) =>
      filter.assignee?.some((assignee) => e.userId?.includes(assignee)),
    ) || [];
  // console.log("assignedUsers", assignedUsers);
  // console.log("filter", filter.filter);
  return (
    <div className="search-header d-flex">
      {showSearchBar && (
        <Search handleFilter={(data) => handleFilter("search", data)} />
      )}
      {showCalendar ? (
        <div className="calendar-det">
          <CustomToolbar
            onNavigate={passFilter?.onNavigate}
            label={passFilter?.label}
          />
        </div>
      ) : null}
      {showReportListCalender ? (
        <div className="calendar-det">
          <CustomDateRange
            start={performanceDate.start}
            end={performanceDate.end}
            change={handlePerformanceDateCallback}
          />
        </div>
      ) : null}

      {showTimeSheetCalendar && onMonthYearSelect ? (
        <div className="calendar-det cursonPointer">
          <TimesheetCalendar onMonthYearSelect={onMonthYearSelect} />
        </div>
      ) : null}

      {showAllocationDate && (
        <div className="calendar-det">
          <img src={dateImage} alt="" className="calendar_icon" />

          {moment().format("MMMM D, YYYY")}
        </div>
      )}

      <div
        className="right-menus d-flex ms-auto align-items-center position-relative"
        ref={ref}
      >
        {showGanttSelect && <GanttSightSelect setUnit={setUnit} />}
        {showclearAllBtn && (
          <div className="right-menus d-flex ms-auto align-items-center">
            <Button
              className="def-btn trash-btn"
              type="button"
              onClick={showclearAllBtnFunc}
              disabled={clearAllBtnDisabled}
            >
              {clearAllButtonTitle}
            </Button>
          </div>
        )}
        {/* show complete tab  */}
        {showCompletedIcon && (
          <Button
            className={`d-flex btn-sm ${showCompleted ? "active" : ""}`}
            onClick={() => completeFunction()}
          >
            <div className="icon">
              <img src={okIcon} alt="icon" />
            </div>
            <span>Completed</span>
          </Button>
        )}

        {showDateSelect && (
          <CustomDateRange
            start={performanceDate.start}
            end={performanceDate.end}
            change={handlePerformanceDateCallback}
          />
        )}
        {showExport && (
          <Button
            className="d-flex btn-sm"
            onClick={() => exportFnc && exportFnc()}
          >
            <div className="icon">
              <img src={downloadIcon} alt="icon" />
            </div>
            <span>Export</span>
          </Button>
        )}
        {showExportDropdown && exportFnc && (
          <CustomDropdown
            icon={downloadIcon}
            label="Export"
            keyId="export"
            exportFnc={exportFnc}
            options={exportOptions}
            pdfThunkLoading={pdfThunkLoading}
            loaderPdf={loaderPdf}
            handleFilter={(data) => handleFilter("export", data)}
          />
        )}
        {showFilter && (
          <Dropdown bsPrefix="from-drop-menu filter-dropdown">
            <Dropdown.Toggle
              bsPrefix={`d-flex btn-sm dropdown-toggle ${
                filter.filter.find((e) => e.where) && "active"
              }`}
              variant=""
            >
              <div className="icon">
                <img src={filterIcon} alt="icon" />
              </div>
              <span>
                {`Filter ${
                  filter.filter.filter((e) => e.where).length > 0
                    ? `(${filter.filter.filter((e) => e.where).length})`
                    : ""
                }`}
              </span>
            </Dropdown.Toggle>
            <Filter handleFilter={(data) => handleFilter("filter", data)} />
          </Dropdown>
        )}
        {showGroupBy && (
          <CustomDropdown
            icon={groupIcon}
            label={`Group by: ${groupByLabel()}`}
            keyId="groupBy"
            options={
              type === "board"
                ? groupByOptions.filter(({ value }) => value !== "none")
                : groupByOptions
            }
            handleFilter={(data) => handleFilter("groupBy", data)}
          />
        )}
        {showSubTask && (
          <CustomDropdown
            icon={subtaskIcon}
            label="Subtasks"
            keyId="subtask"
            options={subtaskOptions}
            handleFilter={(data) => handleFilter("subTask", data)}
          />
        )}
        {showMe && (
          <Button
            className={`d-flex btn-sm ${filter.me && "active"}`}
            onClick={() => {
              if (!meOption) {
                handleFilter("me", userId);
              } else {
                handleFilter("me", "");
              }
              setMeOption(!meOption);
            }}
          >
            <div className="icon">
              <img src={meIcon} alt="icon" />
            </div>
            <span>Me</span>
          </Button>
        )}

        {showAssignee && (
          <Button
            className={`d-flex btn-sm ${filter.assignee?.length && "active"}`}
            onClick={toggleAssignee}
          >
            <div className="icon">
              <img src={AssigneeIcon} alt="icon" />
            </div>
            {!filter.assignee?.length ? (
              <span>
                {`Assignee ${
                  filter.assignee?.length ? `(${filter.assignee.length})` : ""
                }`}
              </span>
            ) : (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="assignee">
                    {assignedUsers?.map((e) => (
                      <div key={e.userId}>{e.user}</div>
                    ))}
                  </Tooltip>
                }
              >
                <span>
                  {`Assignee ${
                    filter.assignee?.length ? `(${filter.assignee.length})` : ""
                  }`}
                </span>
              </OverlayTrigger>
            )}
          </Button>
        )}
        {onAssignee && (
          <Assignees
            toggleAssignee={toggleAssignee}
            handleFilter={(data) => handleFilter("assignee", [data])}
          />
        )}
        {showUsers && (
          <Button
            className={`d-flex btn-sm ${filter.users ? "active" : ""}`}
            onClick={toggleUsers}
          >
            <div className="icon">
              <img src={AssigneeIcon} alt="icon" />
            </div>
            {/* <span>Users</span> */}
            <span>{`Users ${filter.users ? "(1)" : ""}`}</span>
          </Button>
        )}
        {onUser && (
          <Users
            toggleUsers={toggleUsers}
            handleFilter={(data) => handleFilter("users", data)}
          />
        )}
        {showShowBtn && (
          <CustomDropdown
            icon={infoIcon}
            label="Show"
            keyId="show"
            options={showOptions}
            handleFilter={(data) => handleFilter("show", [data])}
          />
        )}
        {showOtherOptions && null}
        {/* <CustomDropdown
            icon={threeDotsIcon}
            options={otherOptions}
            keyId="otherOption"
            handleFilter={(data) => handleFilter("otherOptions", [data])}
          /> */}
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  clearAllButtonTitle: PropTypes.element,
  showSearchBar: PropTypes.bool,
  showAssignee: PropTypes.bool,
  showUsers: PropTypes.bool,
  showFilter: PropTypes.bool,
  showDateSelect: PropTypes.bool,
  showGroupBy: PropTypes.bool,
  showSubTask: PropTypes.bool,
  showMe: PropTypes.bool,
  showGanttSelect: PropTypes.bool,
  showOtherOptions: PropTypes.bool,
  clearAllBtnDisabled: PropTypes.bool,
  showShowBtn: PropTypes.bool,
  showclearAllBtn: PropTypes.bool,
  showclearAllBtnFunc: PropTypes.func,
  showExport: PropTypes.bool,
  onPerformanceDateChange: PropTypes.func,
  params: PropTypes.shape({}),
  filterState: PropTypes.shape({}),
  showCalendar: PropTypes.bool,
  showReportListCalender: PropTypes.bool,
  passFilter: PropTypes.func,
  onMonthYearSelect: PropTypes.func,
  exportFnc: PropTypes.func,
  showExportDropdown: PropTypes.bool,
  setUnit: PropTypes.func,
  showTimeSheetCalendar: PropTypes.bool,
  showAllocationDate: PropTypes.bool,

  pdfThunkLoading: PropTypes.bool,
};

export default SubHeader;
