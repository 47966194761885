import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Navigate, Outlet } from "react-router-dom";
import { useThunk } from "../hooks/useThunk";
import { commondatas } from "../store/slices/common/thunks/setIntializeAppData";

import { getUserProjects } from "../store/slices/project/thunks/getUserProjects";
import { getTicketStatus, getUserProfile } from "../store";
import { isDarkMode } from "../store/slices/theme";
import NotificationToaster from "../components/NotificationToaster";
import { getColors } from "../store/slices/color/thunks/getThemeColors";

function PrivateRoutes() {
  const dispatch = useDispatch();

  // COMMON ACTIONS
  const [initializeAppData] = useThunk(commondatas);
  const [getProjectThunk] = useThunk(getUserProjects);
  const [getStatus] = useThunk(getTicketStatus);
  const [getUserDetails] = useThunk(getUserProfile);

  const isLoggedIn = useSelector(({ auth: { isLoggedIn } }) => isLoggedIn);
  const { userProfile } = useSelector((state) => state.userProfile);
  const { darkMode } = useSelector((state) => state.theme);
  const [getThemeColorThunk] = useThunk(getColors);

  useEffect(() => {
    getProjectThunk({});
    getStatus();
    initializeAppData();
    getUserDetails();
    getThemeColorThunk();
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    if (darkMode) root?.setAttribute("data-bs-theme", "dark");
    else root?.removeAttribute("data-bs-theme");
  }, [darkMode]);

  useEffect(() => {
    if (userProfile) {
      if (userProfile?.theme === "Dark") {
        dispatch(isDarkMode(true));
        localStorage.setItem("theme", "Dark");
      } else {
        dispatch(isDarkMode(false));
        localStorage.removeItem("theme");
      }
    }
  }, [userProfile]);

  return isLoggedIn ? (
    <>
      <NotificationToaster />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRoutes;
