const en = {
  loginData: {
    title: "Login Your Account",
    description:
      "Login using Spericorn Google Account to access the Spericorn Project Management Application",
    loginButtonText: "Login with Spericorn Google Account",
  },
  customTable: {
    noData: "No data available.",
  },
  lineChart: {
    title: "Performance index",
    performanceGraphContent:
      "(Total No. of Tickets First Approved /Total Ticket) *100",
  },
  activitiLogTitle: {
    title: "Activity Log",
  },

  profile: {
    title: "Welcome Back!",
    projectTitle: "Spericorn Project Management Tool",
    userDesignation: "Front End Developer",
    CompanyCount: "244",
    companyId: "Spericorn ID",
    projectCount: "244",
    leaderboard: "Leaderboard",
  },
  tasks: {
    assignedTasks: "Assigned Tasks For Today",
    queuedTasks: "Queued Tasks",
    recentTasks: "Recent Task",
  },
  assets: {
    title: "Assets",
  },
  barChart: {
    title: "Quality index",
    qualityGraphContent:
      "((Completed Count - Total tickets rejected) / Completed count) * 100",
  },
  department: {
    title: "Category List",
  },
  role: {
    title: "Permission List",
  },
  permissionModal: {
    editDepartment: "Edit Category Permissions",
    addDepartment: "Add Category Permissions",
    editrole: "Edit Designation Permissions",
    addRole: "Add Designation Permissions",
    buttonText: "Add",
    editText: "Update",
  },
  projectManagement: {
    projectTitle: "Project Overview Dashboard",
    totalTasks: "Total No. Of Tasks",
  },
  userProfileDetails: {
    role: "Role",
    email: "Email ID",
    localTime: "Local Time",
    online: "Online",
    employeeId: "Employee ID",
    department: "Department",
    contactNumber: "Contact Number",
    gender: "Gender",
    bloodGroup: "Blood Group",
    education: "Education",
    year: "Year",
    certifiedBy: "Certified By",
    licenseNumber: "License Number",
    company: "Company",
    location: "Location",
  },
  results: {
    resultsFound: "No Results Found",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
  },
  activityPage: {
    activityLog: "Activity Log",
  },
};

export default en;
