/* eslint-disable */

import PropTypes from "prop-types";
import { formatDate } from "./Utils";
import { useEffect } from "react";
import { BlameIcon, ContributorsIcon } from "./svgIcons";

export const BlameData = ({ bData }) => {
  console.log("bData", bData);

  const expandBlameArray = (bData) => {
    let expandedBlame = [];
    let prevCommitMessage = null;

    for (const blame of bData) {
      if (blame.commitMessage !== prevCommitMessage) {
        expandedBlame.push({ ...blame });
        prevCommitMessage = blame?.commitMessage;
      } else {
        expandedBlame.push({
          commitHash: blame.commitHash,
          content: blame?.content,
          lineNumber: blame?.lineNumber,
        });
      }
    }

    return expandedBlame;
  };

  const cellStyle = {
    padding: "8px",
  };

  return (
    <div className="blame-table-wrp">
      <div className="blame-legend-wrp">
        <div className="blame-legend">
          <span>Older</span>
          <span>
            <BlameIcon />
          </span>
          <span>Newer</span>
        </div>

        <div className="contributors-wrp">
          <div className="ico">
            <ContributorsIcon />
          </div>
          <div className="">Contributions</div>
          <div className="count">4</div>
        </div>
      </div>
      <table
        className="table"
        cellPadding={0}
        cellSpacing={0}
        style={{
          width: "100%",
          borderCollapse: "separate",
        }}
      >
        <thead>
          <tr>
            <th>
              <span className="text-nowrap">Last Commit time</span>
            </th>
            <th>
              <span className="text-nowrap">Last Commit Author</span>{" "}
            </th>
            <th>
              <span className="text-nowrap">Last Commit Message</span>
            </th>
            <th>
              <span className="text-nowrap">Line Number</span>
            </th>
            <th>
              <span className="text-nowrap">Line Content</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {expandBlameArray(bData)?.map((item, index) => (
            <tr key={index}>
              <td className="dateCol">
                <span className="td-border">
                  {item?.date ? formatDate(item.date) : null}
                </span>
              </td>
              <td>{item.author}</td>
              <td>{item.commitMessage}</td>
              <td>{item.lineNumber}</td>
              <td>{item?.content}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

BlameData.propTypes = {
  bData: PropTypes.arrayOf(
    PropTypes.shape({
      lineNum: PropTypes.number.isRequired,
      lineContent: PropTypes.string.isRequired,
      lastCommitAuthorName: PropTypes.string.isRequired,
      lastCommitMessage: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
