import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertActions } from "../../alert/index";
import { gitBranchs } from "../../../../services/gitHubService";
import { gitHubAction } from "..";

export const getGitBranchs = createAsyncThunk(
  "github/All-branchs",
  async (name, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const { data } = await gitBranchs(name.value, name.owner);

      dispatch(gitHubAction.getAllBranchs(data));
    } catch (error) {
      dispatch(alertActions.error("Access Denied to this repository"));
    }
  },
);
