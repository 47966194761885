import { createSlice } from "@reduxjs/toolkit";

import { getAuthData } from "../../../helpers/localStorage";

export const name = "auth";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.authData = null;
    state.isLoggedIn = false;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setAuth = (state, action) => {
    state.authData = action.payload.auth;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
    state.isLoggedIn = action.payload.isLoggedIn ?? true;
    state.userProfile = action.payload.data;
  };
  const setUserData = (state, { payload }) => {
    state.userProfile = { ...state.userProfile, ...payload.data };
    state.errorMessage = "";
  };
  return {
    setAuth,
    setUserData,
    setErrorMesage,
    resetErrorMesage,
  };
};

function createInitialState() {
  const { isLoggedIn } = getAuthData();
  return {
    authData: null,
    userProfile: null,
    isLoggedIn,
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const authActions = slice.actions;
export const authReducer = slice.reducer;
