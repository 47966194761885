import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { gitHubAction } from "..";
import { createPullRequest } from "../../../../services/gitHubService";
import { getAllPullRequests } from "./getPullrequests";

export const postCreatePull = createAsyncThunk(
  "github/createPr",
  async (params, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const { data } = await createPullRequest(params);

      await dispatch(gitHubAction.createGitPr(data));

      if (data?.success) {
        await dispatch(
          getAllPullRequests({ value: params?.repoName, owner: params?.owner }),
        );

        await dispatch(alertActions.success(data.pullRequests));
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
