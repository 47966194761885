import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import emailAccountServices from "../../../../../services/emailAccountServices";
import { alertActions } from "../../../alert";
import { getEmailAccountList } from "./getEmailAccountDetails";

export const deleteEmailAccount = createAsyncThunk(
  "delete-emailAccount",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await emailAccountServices.deleteEmailAccount(id);

      dispatch(alertActions.success(successMessage));
      dispatch(getEmailAccountList());
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
