// refer cronjob view modal - activity log
const nameToUniqIdMap = {};

export function getNameAndUniqId(name) {
  if (!name) return { initials: "-", uniqId: 1 };
  const formattedName = name?.trim()?.toUpperCase();

  if (nameToUniqIdMap[formattedName]) {
    return nameToUniqIdMap[formattedName];
  }

  const nameParts = formattedName?.split(" ");
  const [firstNameInitial] = nameParts[0];
  const [lastNameInitial = ""] = nameParts[1] || "";

  const initials = firstNameInitial + lastNameInitial;

  const uniqId = (Object.keys(nameToUniqIdMap).length % 7) + 1;

  nameToUniqIdMap[formattedName] = { initials, uniqId };

  return { initials, uniqId };
}
