import { createSlice } from "@reduxjs/toolkit";

export const name = "dashboard";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.departmentData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };

  const setDashboardCounts = (state, action) => {
    state.dashboardCount = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setDashboardTasks = (state, action) => {
    state.dashboardTasks = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setDashboardPerformChart = (state, action) => {
    state.dashboardPerformData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setDashboardQualityChart = (state, action) => {
    state.dashboardQualityData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setProjectQualityChart = (state, action) => {
    state.projectChartQualityData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const setProjectPerformChart = (state, action) => {
    state.projectChartPerformData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };

  const setLeaderboardData = (state, action) => {
    state.leaderboard = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };

  return {
    setDashboardCounts,
    setDashboardTasks,
    setDashboardPerformChart,
    setDashboardQualityChart,
    setProjectQualityChart,
    setProjectPerformChart,
    setLeaderboardData,
    setErrorMesage,
    resetErrorMesage,
  };
};

function createInitialState() {
  return {
    dashboardCount: [],
    dashboardTasks: [],
    dashboardPerformData: [],
    dashboardQualityData: [],
    projectChartPerformData: [],
    projectChartQualityData: [],
    leaderboard: null,
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const dashboardActions = slice.actions;
export const dashboardReducer = slice.reducer;
