import { NoAllocation } from "../../UserProfile/svgIcons";

const NoTasksBoard = () => {
  return (
    <>
      <div className="no-task">
        <span>
          <NoAllocation />
        </span>
        No Tasks Assigned
      </div>
      <span className="assigned-task mb-0">Team Lead:</span>
      <div className="project-details-blk assigned-blk">
        {/* <div className="profile-task">
          <span>S</span>
        </div> */}
        <div className="task-details">
          <div className="name">-</div>
        </div>
      </div>
    </>
  );
};

export default NoTasksBoard;
