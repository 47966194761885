/* eslint-disable react/prop-types */
import { useState } from "react";
import searchNormal from "../../assets/images/search-normal.svg";
import { ProjectRemarks, TimeEstimate } from "./TimesheetSvg";

const ProjectsAllocatedModal = ({ show, data }) => {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter projects based on the search query
  const filteredProjects = data?.projectNames?.filter((project) =>
    project?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
  );
  return (
    <div
      className={`dropdown-menu dropeffect allocation-project-list projectListDrp custom-scroll ${
        show !== null ? "show" : ""
      }`}
    >
      <div className="dropdown-item no-hover searcharea d-flex align-items-center no-bg  pb-1">
        <div className="search-icon">
          <img src={searchNormal} alt="Icon" />
        </div>
        <input
          type="text"
          className="form-control no-hover"
          placeholder="Search Projects"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="allocation-count">
        {filteredProjects?.length
          ? `${filteredProjects?.length} Projects Allocated`
          : "No data found"}
      </div>

      {filteredProjects?.map((e, index) => (
        <>
          {index > 0 && (
            <div className="divitions pb-1 pt-1">
              <hr className="mt-0 mb-0" />
            </div>
          )}
          <button
            className="dropdown-item d-flex dropdown-toggle"
            id="dropdownMenuButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            type="button"
          >
            <div className="pro-icon">
              <img src="img/pro-proposal.svg" alt="" />
            </div>
            <span className="pro-name">{e?.name}</span>
            <div className="updates">
              <div className="btn-notes text-center">
                <ProjectRemarks />
                <span>1</span>
              </div>
              <div className="btn-notes hours-otr text-center">
                <TimeEstimate />
                <span>{e?.allocationHours} h</span>
              </div>
            </div>
          </button>
        </>
      ))}
    </div>
  );
};

export default ProjectsAllocatedModal;
