import Select from "react-select";
import PropTypes from "prop-types";

function CustomSelect({
  showIndicator,
  options = [],
  className = "",
  value,
  onChange,
  ...rest
}) {
  const { isMulti } = { ...rest };

  const fetchValue = () => {
    if (isMulti) {
      return options
        .map((item) => value?.includes(item?.value) && item)
        .filter((item) => item);
    }
    return options?.find((option) => option?.value === value) || "";
  };

  return (
    <Select
      value={fetchValue()}
      onChange={onChange}
      className={className}
      options={options}
      components={
        showIndicator
          ? {}
          : { DropdownIndicator: () => null, IndicatorSeparator: () => null }
      }
      {...rest}
    />
  );
}

CustomSelect.propTypes = {
  showIndicator: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.any.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  rest: PropTypes.node,
};

export default CustomSelect;
