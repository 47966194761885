import { createSlice } from "@reduxjs/toolkit";

export const name = "timesheet";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.timesheetData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setTimesheet = (state, { payload }) => {
    state.timesheetData = payload;
    state.successMessage = payload.successMessage;
  };
  const setTimesheetTable = (state, { payload }) => {
    state.timesheetTableData = payload;
    state.successMessage = payload.successMessage;
  };
  const setTimesheetPdf = (state, { payload }) => {
    state.timesheetTablePdfData = payload;
    state.successMessage = payload.successMessage;
  };
  const clearTimesheetPdf = (state) => {
    state.timesheetTablePdfData = null;
  };

  return {
    setErrorMesage,
    resetErrorMesage,
    setTimesheet,
    setTimesheetTable,
    setTimesheetPdf,
    clearTimesheetPdf,
  };
};

function createInitialState() {
  return {
    errorMessage: "",
    successMessage: "",
    timesheetData: null,
    timesheetTableData: null,
    timesheetTablePdfData: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const timesheetActions = slice.actions;
export const timesheetReducer = slice.reducer;
