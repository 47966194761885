/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import calender from "../../assets/images/calendar.svg";

export const Opencalender = ({
  TaskData,
  type,
  patchFunction,
  dbName,
  icon,
  selectedTasks,
  date,
  dropDownVisibility,
  dropDownVisibilityPermissionConstant,
  needCount,
  tooltipPosition = "top",
}) => {
  const startDate = TaskData[dbName] ? new Date(TaskData[dbName]) : new Date();

  const toolTipText = {
    dueDate: "Due Date",
    startDate: "Start Date",
  };

  const handleSelect = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    if (selectedTasks) {
      patchFunction(type, {
        id: selectedTasks?.map((e) => e._id),
        patchData: { [dbName]: formattedDate },
        needCount,
      });
    } else {
      patchFunction(type, {
        id: TaskData._id,
        patchData: { [dbName]: formattedDate },
        needCount,
      });
    }
  };

  const formattedDate = moment(TaskData[dbName]).format("DD-MM-YYYY");

  return dropDownVisibility && dropDownVisibilityPermissionConstant ? (
    <OverlayTrigger
      placement={tooltipPosition}
      overlay={<Tooltip>{toolTipText[dbName] ?? dbName}</Tooltip>}
    >
      <div>
        <DatePicker
          selected={startDate}
          showMonthDropdown
          showYearDropdown
          onChange={handleSelect}
          customInput={
            TaskData[dbName] ? (
              <div>{date || formattedDate}</div>
            ) : selectedTasks ? (
              <span>{icon}</span>
            ) : (
              !date && (
                <img style={{ cursor: "pointer" }} src={calender} alt="" />
              )
            )
          }
          placeholderText="select date"
          maxDate={
            TaskData?.dueDate &&
            dbName !== "dueDate" &&
            new Date(TaskData?.dueDate)
          }
          minDate={
            TaskData?.startDate && dbName !== "startDate"
              ? new Date(TaskData?.startDate)
              : new Date()
          }
          dateFormat="dd-MM-yy"
        />
      </div>
    </OverlayTrigger>
  ) : (
    <OverlayTrigger placement="top" overlay={<Tooltip>{dbName}</Tooltip>}>
      <div>
        TaskData[dbName] ? (<div>{date || formattedDate}</div>) : selectedTasks
        ? (<span>{icon}</span>) : ( !date &&{" "}
        <img style={{ cursor: "pointer" }} src={calender} alt="" />
        );{" "}
      </div>
    </OverlayTrigger>
  );
};
Opencalender.propTypes = {
  TaskData: PropTypes.isRequired,
  type: PropTypes.oneOf(["Task", "SubTask"]).isRequired,
  icon: PropTypes.node,
};
