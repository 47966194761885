import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertActions } from "../../alert/index";
import { gitPullRequests } from "../../../../services/gitHubService";
import { gitHubAction } from "..";

export const getAllPullRequests = createAsyncThunk(
  "github/All-PullRequests",
  async (datas, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const { data } = await gitPullRequests(datas.value, datas.owner);
      dispatch(gitHubAction.getAllPullRequests(data.pullRequests));
    } catch (error) {
      dispatch(alertActions.error("Access Denied to this repository"));
    }
  },
);
