import Gateway from "../gateway/gateway";

// access token
export const gitAccessTokenPost = (postData) =>
  Gateway.post(`/git/callback?code=${postData}`);

// disconnect git
export const deleteGitConnection = () => Gateway.delete("git/gitDisconnect");

// gel all repositories
export const gitRepos = () => Gateway.get("git/getRepos");

// project repositories
export const projectRepos = () => Gateway.get("git/projectRepo");

// user details
export const gituserDetails = () => Gateway.get("git/getUserDetails");

export const tableDetails = (data) =>
  Gateway.get("git/projectRepo", { params: data });

export const postSubmitConnectRepo = (projectRepoDetails) =>
  Gateway.post("git/projectRepo", projectRepoDetails);

export const createBranchName = () => Gateway.post("git/getUserDetails");

export const editTableDetails = (projectRepoDetails) =>
  Gateway.patch("git/projectRepo", projectRepoDetails);

export const deleteTableDetails = (id) =>
  Gateway.delete(`git/projectRepo?id=${id}`);

// fetch branches in repositories
export const gitBranchs = (value, owner) =>
  Gateway.get(`git/getBranches?repoName=${value}&owner=${owner}`);

// get all pull requests
export const gitPullRequests = (repoName, owner) =>
  Gateway.get(`git/getPullRequests?repoName=${repoName}&owner=${owner}`);

// create branch
export const createBranch = (
  branchName,
  repoName,
  baseBranch,
  owner,
  projectId,
  ticket,
) =>
  Gateway.post(
    `git/createBranch?repoName=${repoName}&branchName=${branchName}&baseBranch=${baseBranch}&owner=${owner}&project=${projectId}&ticket=${ticket}`,
  );

// create pull request
export const createPullRequest = (params) =>
  Gateway.post("git/pullRequest", params);

// get folder & files
export const getFolderStructure = (params) =>
  Gateway.get("git/getFolderStructure", { params });

// fetch git contents
export const getContentsGit = (params) =>
  Gateway.get("git/getGitContent", { params });

// get all user repositories
export const getUserRepositories = (params) =>
  Gateway.get(`git/fetchProjectRepos?projectId=${params}`);

// delete pull request

export const patchPullRequest = (
  pullNumber,
  repoName,
  owner,
  projectId,
  ticket,
) =>
  Gateway.patch(
    `git/pullRequest?pullNumber=${pullNumber}&repoName=${repoName}&owner=${owner}&project=${projectId}&ticket=${ticket}`,
  );

// delete branches
export const deleteBranch = (branchName, repoName, owner, projectId, ticket) =>
  Gateway.delete(
    `git/deleteBranch?branchName=${branchName}&repoName=${repoName}&owner=${owner}&project=${projectId}&ticket=${ticket}`,
  );

// get commits
export const getAllCommits = (repoName, owner) =>
  Gateway.get(`git/gitCommits?repoName=${repoName}&owner=${owner}`);
