import { useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import PropTypes from "prop-types";
import "../../design/custom/pages/task-details-modal.scss";
import "react-datepicker/dist/react-datepicker.css";
import CustomModal from "../../components/CustomModal";
import { completeSprint, getSprint, getTicektCount } from "../../store";
import { useThunk } from "../../hooks/useThunk";
import CreateSprint from "./createSprint";

const CompleteSprint = ({ completeSprintModal, setCompleteSprintModal }) => {
  const [completeSprintAction, isComplete] = useThunk(completeSprint);
  const [ticketCountGet] = useThunk(getTicektCount);
  const [getSprints] = useThunk(getSprint);
  const { listId, shortCode } = useParams();
  const { ticketCount, sprintData, completeSuccess } = useSelector(
    (state) => state.sprint,
  );
  const { projectData } = useSelector((state) => state.project);
  const [modalSwitch, setModalSwitch] = useState("");
  const matchingSprint = projectData?.find(
    (project) => project.shortCode === shortCode,
  );
  const [sprintValue, setSprintValue] = useState(listId);
  const navigate = useNavigate();
  useEffect(() => {
    ticketCountGet(listId);
    getSprints(matchingSprint?._id);
  }, []);
  useEffect(() => {
    if (completeSuccess) {
      setCompleteSprintModal(false);
      navigate(
        `${window.location.pathname.split("/sprint")[0]}/sprint/${sprintValue}`,
      );
    }
  }, [completeSuccess]);
  const filteredSprintData = sprintData?.ActiveSprint?.filter(
    (sprint) => sprint._id !== listId,
  );
  const currentSprint = sprintData?.ActiveSprint?.find(
    (sprint) => sprint._id === listId,
  );
  const renderBody = () => (
    <Form
      onSubmit={(formValues) => {
        completeSprintAction({
          projectId: matchingSprint?._id,
          currentSprintId: listId,
          newSprintId: formValues?.newSprintId?.value,
        });
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="create-sprint-modal-body">
            <div className="modal-body-title">
              <h4 className="body-title-sm">
                Complete Sprint:{currentSprint?.name}
              </h4>
            </div>
            <div className="form-cover">
              <p>
                <b>{ticketCount.completedCount}</b> Tasks were done
              </p>
              <p>
                <b>{ticketCount.incompleteCount}</b> Tasks were incomplete
              </p>
              {ticketCount?.incompleteCount > 0 && (
                <>
                  <p className="select-text">
                    Select where all the incomplete tasks should be moved:
                  </p>
                  <div className="inp-block form-group alert-cover">
                    <label className="form-label">Move to</label>
                    <Field
                      name="newSprintId"
                      validate={(value) =>
                        value ? undefined : "Sprint should be selected"
                      }
                    >
                      {({ input, meta }) => (
                        <div className="select-duration">
                          <Select
                            classNamePrefix="react-select"
                            className="react-select-container"
                            options={(filteredSprintData
                              ? filteredSprintData.map((e) => ({
                                  value: e._id,
                                  label: e.name,
                                }))
                              : []
                            ).concat({
                              value: "addSprint",
                              label: "Add Sprint",
                            })}
                            onChange={(value) => {
                              if (value?.value === "addSprint") {
                                setModalSwitch(true);
                              } else {
                                setModalSwitch(false);
                              }
                              setSprintValue(value?.value);
                              input.onChange(value);
                            }}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </>
              )}
            </div>
            <div className="btn-block">
              <button
                className="modal-btn btn-cancel"
                type="button"
                onClick={() => {
                  setCompleteSprintModal(false);
                }}
                disabled={isComplete}
              >
                <span> Cancel</span>
              </button>
              <button className="modal-btn btn-create ms-2" type="submit">
                {isComplete ? (
                  <span className="btn-loader">
                    <span className="spinner-border spinner-border-sm" />
                  </span>
                ) : (
                  <span> Complete</span>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    />
  );

  return (
    <>
      <CustomModal
        className="modal fade modal-default create-sprint-modal complete-sprint-modal"
        dialgName="modal-lg modal-dialog-centered"
        contentClassName="border-0"
        createModal
        show={!!completeSprintModal}
        closeModal={() => {
          setCompleteSprintModal(false);
        }}
        body={renderBody()}
        closeButton
      />
      <CreateSprint
        showModal={modalSwitch}
        setShowModal={() => setModalSwitch("")}
        selectedProject={matchingSprint?._id}
        backBtn
      />
    </>
  );
};

CompleteSprint.propTypes = {
  completeSprintModal: PropTypes.bool,
  setCompleteSprintModal: PropTypes.func,
};

export default CompleteSprint;
