import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { postSubmitConnectRepo } from "../../../../services/gitHubService";

export const postConnectedRepoDetails = createAsyncThunk(
  "git/submit",
  async function (projectRepoDetails, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { postProjectRepo },
      } = await postSubmitConnectRepo(projectRepoDetails);
      console.log("postProjectRepo", postProjectRepo);
      dispatch(alertActions.success(postProjectRepo));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
