import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ticketService from "../../../../services/ticketService";
import { alertActions } from "../../alert";
import { setTicketDocs, setTicketError } from "..";

export const ticketDocs = createAsyncThunk(
  "get/ticketDocs",
  async (params, { dispatch }) => {
    try {
      dispatch(setTicketError(""));
      const param = params.sprintId ? params.sprintId : params.listId;
      const {
        data: { data },
      } = await ticketService.getTicketDocs(params.projectId, param);
      dispatch(setTicketDocs(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.response.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
