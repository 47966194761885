export function convertBytesToKBMB(bytes) {
  const kb = bytes / 1024;
  const mb = kb / 1024;
  if (mb >= 1) {
    return `${mb.toFixed(1)} MB`;
  }
  if (kb >= 1) {
    return `${kb.toFixed(1)} KB`;
  }

  return `${bytes} Bytes`;
}
