import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserDocumentsById } from "../../../store/slices/documents/thunks/getdocumentbyid";
import { getNameAndUniqId } from "../../../components/common/iconDefaultName";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import circledownloadicon from "../../../assets/images/circle-download-icon.svg";
import { useThunk } from "../../../hooks/useThunk";
import { convertBytesToKBMB } from "../utility";
import docImage from "../../../assets/images/doc-1.svg";
import pdfImage from "../../../assets/images/pdf.svg";
import xlsImage from "../../../assets/images/xls.svg";
import pptImage from "../../../assets/images/ppt.svg";
import txtImage from "../../../assets/images/text.svg";
import imgImage from "../../../assets/images/image.svg";

export const InfoModal = ({ showInfoModal, onClose }) => {
  const [getDocumentsById, isLoading] = useThunk(getUserDocumentsById);

  useEffect(() => {
    if (showInfoModal.userId) {
      getDocumentsById(showInfoModal.userId);
    }
  }, [showInfoModal.userId]);

  const closeSidePanel = () => {
    onClose();
  };

  const { document } = useSelector((state) => state);
  const documents = document.documentDataById;
  const fileUrls = documents?.data?.files || [];
  console.log("fileUrls", fileUrls);
  console.log("documents info", documents);
  console.log("files urls", fileUrls);

  const getFileTypeIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    const iconMapping = {
      pdf: pdfImage,
      docx: docImage,
      doc: docImage,
      xlsx: xlsImage,
      xls: xlsImage,
      ppt: pptImage,
      pptx: pptImage,
      txt: txtImage,
      png: imgImage,
      jpeg: imgImage,
      jpg: imgImage,
    };

    return iconMapping[extension];
  };

  return (
    <div
      className={`info-panel custom-scroll ${
        showInfoModal ? showInfoModal.state : ""
      }`}
    >
      <div className="info-panel-header">
        <h4 className="title">Info</h4>
        <button
          className="btn btn-close"
          type="button"
          onClick={() => closeSidePanel()}
        />
      </div>
      <div className="info-content-wrap">
        <div className="usr-profile">
          <div className="usr-img me-0">
            {documents.data?.createdBy?.photo ? (
              <img
                className="img-fluid profile__picture"
                src={`https://erptest.spericorn.com/repo/uploads/${documents.data?.createdBy?.photo}`}
                alt=""
              />
            ) : (
              <span
                className={`name userProf bg-${
                  getNameAndUniqId(documents.data?.createdBy?.employeeName)
                    .uniqId
                }`}
              >
                {
                  getNameAndUniqId(documents.data?.createdBy?.employeeName)
                    .initials
                }{" "}
              </span>
            )}
          </div>
          <div className="author-usr">
            <span className="light-title">Uploaded by</span>
            <h4 className="name">
              {isLoading ? (
                <CommonSkeleton />
              ) : (
                documents.data?.createdBy?.employeeName
              )}
            </h4>
          </div>
        </div>
        <div className="content-title-wrp">
          <h4 className="cont-title">
            <span className="light-title">Title</span>
            {isLoading ? <CommonSkeleton /> : documents.data?.title}
          </h4>
        </div>
        <div className="desc-wrp">
          <h5 className="light-title">Description</h5>
          {isLoading ? <CommonSkeleton /> : documents.data?.description}
        </div>
        <div className="content-title-wrp">
          <h4 className="cont-title">
            <span className="light-title">Uploaded on</span>
            {isLoading ? (
              <CommonSkeleton />
            ) : (
              <>
                {new Date(documents.data?.createdAt).toLocaleString("en-US", {
                  month: "short",
                  day: "numeric",
                })}
                {" at "}
                {new Date(documents.data?.createdAt).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </>
            )}
          </h4>
        </div>
      </div>
      <div className="attachment-wrp">
        <h5 className="light-title">Attachments</h5>
        {isLoading ? (
          <CommonSkeleton />
        ) : (
          fileUrls.map((file, index) => {
            const fileTypeIcon = getFileTypeIcon(file.path);

            return (
              <div className="attachments" key={index}>
                <div className="lft-col">
                  <span className="ico">
                    <img className="img-fluid" src={fileTypeIcon} alt="" />
                  </span>
                  <div className="doc-title" key={index}>
                    {file.path}
                    <span className="file-size">
                      {convertBytesToKBMB(file.size)}
                    </span>
                  </div>
                </div>
                <div className="rt-col">
                  <a href={file.url} className="btn btn-download" download>
                    <img
                      className="img-fluid"
                      src={circledownloadicon}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
InfoModal.propTypes = {
  showInfoModal: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
  }).isRequired,
};
InfoModal.propTypes = {
  showInfoModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
