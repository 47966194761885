import Gateway from "../gateway/gateway";

const dashboardCounts = () => Gateway.get("/stats/counts");

const dashboardTasks = () => Gateway.get("/stats/tasks");

const dashboardPerformCharts = (params) =>
  Gateway.get("/stats/p-index", { params });

const dashboardQualityCharts = (params) =>
  Gateway.get("/stats/q-index", { params });

const performChartswithId = (params, projectId) =>
  Gateway.get(`/stats/p-index/${projectId}`, { params });

const qualityChartswithId = (params, projectId) =>
  Gateway.get(`/stats/q-index/${projectId}`, { params });

const leaderboardData = () => Gateway.get("/stats/leaderboard");

export default {
  dashboardCounts,
  dashboardTasks,
  dashboardQualityCharts,
  dashboardPerformCharts,
  performChartswithId,
  qualityChartswithId,
  leaderboardData,
};
