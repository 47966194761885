/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { reportActions } from "../index";
import reportService from "../../../../services/reportService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const getReports = createAsyncThunk(
  `get/reports`,
  async (
    {
      page,
      limit,
      match,
      start,
      end,
      sortBy,
      sortOrder,
      loginUser,
      search,
      Me,
    },
    { dispatch },
  ) => {
    try {
      console.log("hhh");
      const {
        data: { reports, message: successMessage },
      } = await reportService.getReportData({
        page,
        limit,
        match,
        start,
        end,
        sortBy,
        sortOrder,
        search,
        loginUser,
        Me,
      });

      dispatch(
        reportActions.setReportData({
          reports,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(reportActions.setReportErrorMessage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
