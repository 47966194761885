/* eslint-disable */
import Gateway from "../gateway/gateway";

// patch team of a project
const patchTeamData = (id, data) => Gateway.patch(`/team-member/${id}`, data);

// get team data of a project
const getTeamData = (params) => Gateway.post("/team-member", { params });

// get Department data of a project
const getDepartmentData = (params) => Gateway.get("/department", { params });

// get Allocation data of a project
const getRoleData = (id) => Gateway.get(`/employeeRole?deptId=${id}`);

// get Member data of a project
const getMemberData = (roleId, projectId) =>
  Gateway.get("/member", { params: { roleId, projectId } });
const patchTeamHour = (projectId, userId, allocationHours) =>
  Gateway.patch(`/team-member?projectId=${projectId}&userId=${userId}`, {
    allocationHours,
  });

export default {
  patchTeamData,
  getTeamData,
  getDepartmentData,
  getRoleData,
  getMemberData,
  patchTeamHour,
};
