import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Xlsx from "xlsx";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import PdfImage from "../../assets/images/pdf.svg";
import xlsImage from "../../assets/images/xls.svg";
import {
  UploadSheet,
  UploadsheetImage,
  UploadTask,
  Download,
  Info,
  Excel,
} from "./svgIcons";
import CustomModal from "../../components/CustomModal";
import "../../design/custom/pages/uploadSheet.scss";
import SelectGreen from "../../assets/images/select-green.svg";
import RemoveImage from "../../assets/images/remove-red.svg";
import { useThunk } from "../../hooks/useThunk";
import { postUploadSheetTicket } from "../../store/index";
import { setTicketError } from "../../store/slices/ticket";
import CustomTable from "../../components/CustomTable";

const ExcelImport = ({
  setIsUploadSheetModalOpen,
  projectId,
  listId,
  sprintId,
  listName,
  sprintName,
  isGetTicketDocs,
}) => {
  const [uploadSheetModal, setUploadSheetModal] = useState(false);
  const dispatch = useDispatch();
  const [hoveredFiles, setHoveredFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState({});
  const [validFiles, setValidFiles] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const [newValidFiles, setNewValidFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [CreateTicket, isLoading] = useThunk(postUploadSheetTicket);
  const { allTechnologies } = useSelector((state) => state.technology);
  const { ticketError, ticketDocs } = useSelector(
    (state) => state.ticketReducer,
  );
  const initialValues = { file: [] };
  const { projectData } = useSelector((state) => state.project);
  let expectedHeaders = allTechnologies.map((e) => e.technology.name);

  const paginationData = {
    page: 1,
    limit: 10,
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const matchingSprint = projectData.find(
    (project) => project._id === projectId,
  );
  const tableClassName = "table custom-table";
  expectedHeaders = ["Title", ...expectedHeaders];
  const fileTypeIcons = {
    xlsx: xlsImage,
  };
  const allowedExtensions = ["xlsx"];

  const handleMouseEnter = (field) => {
    if (!hoveredFiles.includes(field)) {
      setHoveredFiles([...hoveredFiles, field]);
    }
  };

  const handleMouseLeave = (field) => {
    setHoveredFiles(hoveredFiles.filter((id) => id !== field));
  };

  const simulateProgress = (fileName) => {
    const interval = 300;
    const totalSteps = 10;
    const step = 100 / totalSteps;

    let currentStep = 0;

    const progressInterval = setInterval(() => {
      currentStep += 1;
      const updatedProgress = { ...uploadProgress };
      updatedProgress[fileName] = currentStep * step;
      setUploadProgress(updatedProgress);
      if (currentStep === totalSteps) {
        clearInterval(progressInterval);
      }
    }, interval);
  };

  const validateForm = () => {
    const uploadProgressKeys = Object.values(uploadProgress);
    if (uploadProgressKeys[uploadProgressKeys.length - 1] >= 100) {
      const errors = {};
      if (newValidFiles.length === 0) {
        errors.file = "Please select a valid Excel file.";
      }

      return errors;
    }
  };

  const handleFileChange = (e) => {
    dispatch(setTicketError([]));
    setUploadProgress([]);
    const selectedFiles = Array.from(e.target.files);
    const newFiles = selectedFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    });
    if (newFiles.length > 0) {
      newFiles.forEach((file, index) => {
        const newUploadProgress = { ...uploadProgress };
        newUploadProgress[file.name] = 0;
        setUploadProgress(newUploadProgress);
        setTimeout(() => {
          simulateProgress(file.name);
        }, index * 4000);
      });
      setFiles(selectedFiles);
      setFileErrors("");
    } else {
      setFileErrors("only excel files are allowed");
    }

    setValidFiles(newFiles);
    setNewValidFiles(newFiles);
    const file = e.target.files[0];
    if (file && newFiles.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const workbook = Xlsx.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = Xlsx.utils.sheet_to_json(sheet, {
            header: 1,
            skipEmptyRows: true,
          });
          const actualHeaders = jsonData[0].filter((header) => !!header);
          const additionalHeaders = jsonData[0].filter(
            (header) => !expectedHeaders.includes(header),
          );
          if (actualHeaders.length === 1 && actualHeaders[0] === "Title") {
            setErrors({
              ...errors,
              file: "Excel file must contain at least one technology.",
            });
          } else {
            setErrors("");
          }

          if (additionalHeaders.length > 0) {
            setErrors({
              ...errors,
              file: "Additional technologies are not allowed",
            });
            return;
          }
          const repeatedHeaders = actualHeaders.filter(
            (header, index, self) => self.indexOf(header) !== index,
          );
          if (repeatedHeaders.length > 0) {
            setErrors({
              ...errors,
              file: `Repeated headers: ${repeatedHeaders.join(", ")}`,
            });
          }
        } catch (error) {
          setErrors({ file: "Excel should contain valid datas" });
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleRemoveFile = (fileName) => {
    const updatedValidFiles = validFiles.filter(
      (file) => file.name !== fileName,
    );
    setValidFiles(updatedValidFiles);
    setErrors("");
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);

    const updatedProgress = { ...uploadProgress };
    delete updatedProgress[fileName];
    setUploadProgress(updatedProgress);
    setNewValidFiles(newValidFiles.filter((file) => file.name !== fileName));
  };

  const renderImageSrc = (file) => {
    const isFileUploaded = uploadProgress[file.name] >= 100;
    const isFileHovered = hoveredFiles.includes(file.name);

    if (isFileUploaded) {
      return isFileHovered ? RemoveImage : SelectGreen;
    }
    return RemoveImage;
  };

  const exportToExcel = async () => {
    setTimeout(() => {
      setIsDownloading(true);
    }, 0);
    const workbook = Xlsx.utils.book_new();
    const worksheet = Xlsx.utils.aoa_to_sheet([expectedHeaders]);
    const columnWidths = expectedHeaders.map(() => ({ wch: 20 }));

    worksheet["!cols"] = columnWidths;

    Xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const buffer = Xlsx.write(workbook, { bookType: "xlsx", type: "array" });

    const blob = new Blob([new Uint8Array(buffer)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${matchingSprint?.name} / ${
      (listName && listName) || (sprintName && sprintName)
    }`;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setTimeout(() => {
      setIsDownloading(false);
    }, 100);
  };
  useEffect(() => {
    setUploadSheetModal(true);
  }, []);
  const renderImg = ({ row }) => {
    return (
      <span>
        <Excel />
        <span>{row.fileName}</span>
      </span>
    );
  };
  const cols = [
    {
      name: "FILE NAME",
      selector: "fileName",
      icon: false,
      activitySort: true,
      cell: renderImg,
    },
    {
      name: "UPLOADED DATE",
      selector: "createdAt",
      activitySort: true,
      icon: false,
    },
    {
      name: "UPLOADED BY",
      selector: "name",
      activitySort: true,
      icon: false,
    },
  ];
  const handleSubmit = async (values) => {
    if (files[0] === undefined) {
      setFileErrors("Please select a excel file");
    } else {
      const validationErrors = validateForm(values);
      console.log(Object.keys(validationErrors).length === 0, errors.file);
      if (
        Object.keys(validationErrors).length === 0 &&
        errors.file === undefined
      ) {
        const formData = new FormData();
        formData.append("projectId", projectId);
        formData.append(
          "docsName",
          `${matchingSprint?.name} / ${
            (listName && listName) || (sprintName && sprintName)
          }`,
        );
        if (listId) {
          formData.append("listId", listId);
        }
        if (sprintId) {
          formData.append("sprintId", sprintId);
        }
        formData.append("files", files[0]);
        CreateTicket({ formData, setIsUploadSheetModalOpen });
      } else {
        setErrors({
          file:
            Object.keys(validationErrors).length !== 0
              ? validationErrors
              : errors.file,
        });
      }
    }
  };

  const renderHeader = () => (
    <h5 className="modal-title">
      <span>
        <UploadSheet />
      </span>
      Upload Sheet
    </h5>
  );
  const renderBody = () => (
    <Form
      initialValues={initialValues}
      onSubmit={() => {
        handleSubmit();
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-inner">
            <div className="upload-btn-wrapper">
              <div className="position-relative">
                <label className="file-label">
                  Upload File{" "}
                  <span>
                    <UploadsheetImage />
                  </span>
                  <span>Supported File: Excel</span>
                </label>
                <div>
                  <div className="hiddenUpload">
                    <Field
                      name="file"
                      render={({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="file"
                            className="hiddenUploadInput"
                            onChange={handleFileChange}
                          />
                          {meta.error && meta.touched && (
                            <span className="error position-absolute top-100 text-danger w-100">
                              <small>{meta.error}</small>
                            </span>
                          )}
                          <span className="error position-absolute top-100 text-danger w-100">
                            <small>{fileErrors}</small>
                          </span>
                        </>
                      )}
                    />
                    <div className="upload-label-task">
                      <span>
                        <UploadTask />
                      </span>{" "}
                      Upload File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {files.length > 0 && (
              <ul className="uploaded-files pt-1 sprint-upload">
                {files.map((file) => (
                  <li key={file.id}>
                    <div
                      className="upload-wrp"
                      onMouseEnter={() => handleMouseEnter(file.name)}
                      onMouseLeave={() => handleMouseLeave(file.name)}
                    >
                      <span className="ico">
                        <img
                          src={
                            fileTypeIcons[
                              file.name.split(".").pop().toLowerCase()
                            ] || PdfImage
                          }
                          alt=""
                        />
                      </span>
                      {uploadProgress[file.name] < 100 && (
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={uploadProgress[file.name] || 0}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: `${uploadProgress[file.name] || 0}%`,
                            }}
                          />
                        </div>
                      )}

                      <button
                        type="button"
                        className="remove-file"
                        onClick={() => {
                          handleRemoveFile(file.name);
                          handleMouseLeave(file.name);
                        }}
                      >
                        <img
                          src={renderImageSrc(file)}
                          alt={
                            uploadProgress[file.name] >= 100
                              ? "Remove"
                              : "Select Green"
                          }
                        />
                      </button>
                    </div>
                    <span className="designation-col">{file.name}</span>
                    {uploadProgress[file.name] >= 100 && (
                      <span className="error text-danger w-100">
                        <small>{errors.file}</small>
                      </span>
                    )}
                    <div className="error-list custom-scroll">
                      {ticketError &&
                        (Array.isArray(ticketError) ? (
                          ticketError.map((errorOrArray, index) => (
                            <div key={index} className="error text-danger">
                              <small>
                                {Array.isArray(errorOrArray)
                                  ? errorOrArray.map((error, subIndex) => (
                                      <div key={subIndex}>{error.message}</div>
                                    ))
                                  : errorOrArray.message}
                              </small>
                            </div>
                          ))
                        ) : (
                          <div className="error text-danger">
                            <small>{ticketError}</small>
                          </div>
                        ))}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="upload-sheet-table">
            <CustomTable
              isLoading={isGetTicketDocs}
              columns={cols}
              data={ticketDocs}
              pagination={paginationData}
              tableClassName={tableClassName}
            />
          </div>
          <div className="modal-footer">
            {isDownloading ? (
              <span className="btn-loader btn btn-download">
                <span className="spinner-border spinner-border-sm" />
              </span>
            ) : (
              <button
                type="button"
                className="btn btn-download"
                onClick={() => {
                  setIsDownloading(true);
                  exportToExcel();
                }}
              >
                <span className="ico">
                  <Download />
                </span>{" "}
                <span className="txt">Download Excel File</span>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      It will provide the sample Excel file along with its
                      corresponding technologies.
                    </Tooltip>
                  }
                >
                  <span className="ico">
                    <Info />
                  </span>
                </OverlayTrigger>
              </button>
            )}
            <button
              type="button"
              className="btn btn-primary cancel"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsUploadSheetModalOpen(false);
              }}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                Object.values(uploadProgress).some(
                  (progress) => progress < 100,
                ) || isLoading
              }
            >
              {isLoading && (
                <span className="btn-loader">
                  <span className="spinner-border spinner-border-sm" />
                </span>
              )}
              Upload
            </button>
          </div>
        </form>
      )}
    />
  );

  return (
    <CustomModal
      className="modal fade upload-sheet"
      dialgName="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
      contentClassName="border-0"
      bodyClassname="custom-scroll"
      createModal
      closeButton
      show={!!uploadSheetModal}
      header={renderHeader()}
      closeModal={() => {
        setIsUploadSheetModalOpen(false);
      }}
      body={renderBody()}
    />
  );
};
ExcelImport.propTypes = {
  setIsUploadSheetModalOpen: PropTypes.func,

  projectId: PropTypes.string,
  listId: PropTypes.string,
  listName: PropTypes.string,
  sprintName: PropTypes.string,
  sprintId: PropTypes.string,
  isGetTicketDocs: PropTypes.bool,
};

export default ExcelImport;
