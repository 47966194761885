import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useThunk } from "../../../../hooks/useThunk";
import { withTitle } from "../../../../hoc/withTitle";
import { getDesignList, updateDesign } from "../../../../store";
import CommonSkeleton from "../../../../components/SkeletonLoader/CommonSkeleton";
import ImageCrop from "./ImageCrop";

import icon from "../../../../assets/images/info.svg";
import "../../../../design/custom/pages/white-label-elemet.scss";

import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../../utils/permissionUtils";
import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";
import { alertActions } from "../../../../store/slices/alert";

const Design = () => {
  const whiteLabelFields = {
    productName: { value: "", error: "" },
    description: { value: "", error: "" },
    profileImage: { value: "", error: "" },
    favicon: { value: "", error: "" },
    defaultNoImage: { value: "", error: "" },
    logo: { value: "", error: "" },
    loader: { value: "", error: "" },
    metaTitle: { value: "", error: "" },
    metaDescription: { value: "", error: "" },
    metaKeywords: { value: [], error: "" },
    metaKeywordText: { value: "", error: "" },
  };

  //  state
  const [whiteLabelDetails, setWhiteLabelDetails] = useState(whiteLabelFields);
  const [image, setImage] = useState({
    profileImage: "",
    favicon: "",
    defaultNoImage: "",
    logo: "",
    loader: "",
  });
  const [loading, setLoading] = useState("");
  const [isImageCrop, setIsImageCrop] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [imageDetails, setImageDetails] = useState({ key: "", file: "" });

  // action
  const [DesignList, isLoadingDesignList] = useThunk(getDesignList);
  const [UpdateDesign, isLoading] = useThunk(updateDesign);

  // reducer
  const { designList } = useSelector((state) => state.design);
  const { userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    DesignList();
  }, []);

  useEffect(() => {
    setWhiteLabelDetails({
      productName: { value: designList?.productName, error: "" },
      description: { value: designList?.description, error: "" },
      profileImage: { value: designList?.profileImage, error: "" },
      favicon: { value: designList?.favicon, error: "" },
      defaultNoImage: { value: designList?.defaultNoImage, error: "" },
      logo: { value: designList?.logo, error: "" },
      loader: { value: designList?.loader, error: "" },
      metaTitle: { value: designList?.metaTitle, error: "" },
      metaDescription: { value: designList?.metaDescription, error: "" },
      metaKeywords: { value: designList?.metaKeywords, error: "" },
      metaKeywordText: { value: "", error: "" },
    });

    setImage({
      profileImage: designList?.profileImage,
      favicon: designList?.favicon,
      defaultNoImage: designList?.defaultNoImage,
      logo: designList?.logo,
      loader: designList?.loader,
    });
  }, [designList]);

  const handleChange = (value, key) => {
    const tempData = structuredClone(whiteLabelDetails);
    tempData[key].value = value;
    setWhiteLabelDetails(tempData);
  };

  const handleOnBlur = (value, key) => {
    if (value !== designList[key]) {
      const formData = new FormData();
      formData.append([key], value);

      UpdateDesign({ id: designList._id, data: formData });
      setLoading(key);
    }
  };

  const dispatch = useDispatch();

  const handleOnKeyDown = (e, key) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();

      if (key === "metaKeywordText") {
        const newMetaKeyword = e.target.value;
        if (whiteLabelDetails?.metaKeywords?.value?.includes(newMetaKeyword)) {
          dispatch(alertActions.error("Value already exists"));
          setWhiteLabelDetails({
            ...whiteLabelDetails,
            metaKeywordText: { value: "" },
          });
          return;
        }
        setWhiteLabelDetails((prev) => ({
          ...prev,
          metaKeywords: {
            value: [...prev.metaKeywords.value, newMetaKeyword],
          },
          metaKeywordText: { value: "", error: "" },
        }));

        const formData = new FormData();
        formData.append("metaKeywords", [
          ...whiteLabelDetails.metaKeywords.value,
          newMetaKeyword,
        ]);
        UpdateDesign({
          id: designList._id,
          data: formData,
        });
        setLoading("metaKeywords");
        // DesignList();
      } else {
        const formData = new FormData();
        formData.append([key], e.target.value);
        UpdateDesign({ id: designList._id, data: formData });
        setLoading(key);
      }
    }
  };

  const handleMetaKeywordDelete = (val) => {
    setWhiteLabelDetails((prev) => ({
      ...prev,
      metaKeywords: {
        value: prev.metaKeywords.value.filter((item) => item !== val),
      },
    }));
    const formData = new FormData();
    formData.append(
      "metaKeywords",
      whiteLabelDetails?.metaKeywords.value.filter((item) => item !== val),
    );
    UpdateDesign({
      id: designList._id,
      data: formData,
    });
    setLoading("metaKeywords");
  };

  const handlePhotoChange = (files, keys) => {
    if (keys === "favicon" || keys === "loader") {
      setWhiteLabelDetails({ ...whiteLabelDetails, [keys]: { error: "" } });
      setLoading(keys);
      setIsImageCrop(false);
      const formData = new FormData();
      formData.append(keys, files[0]);
      UpdateDesign({
        id: designList._id,
        data: formData,
      });
      setImage({ [keys]: URL.createObjectURL(files[0]) });
    }

    if (
      keys === "profileImage" ||
      keys === "defaultNoImage" ||
      keys === "logo"
    ) {
      setLoading(keys);
      setIsImageCrop(true);
      setImageDetails({ key: keys, file: files });
      setIsImageCrop(true);
      setCropImage(URL.createObjectURL(files[0]));
      setLoading("");
    }
  };

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.SETUP,
        permissionConstants.submodules.WHITELABEL_DESIGN,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  return (
    <>
      <div className="white-label-wrap">
        <div className="main-title">White Label Elements</div>
        <div className="form-white-labels">
          <div className="row">
            <div className="col fields-col">
              <div className="row form-btm-offset">
                {hasPermission(
                  permissionConstants.permissions.DESIGN_PRODUCT_NAME,
                ) && (
                  <div className="col">
                    <label htmlFor="">Product Name</label>
                    {isLoadingDesignList ||
                    (isLoading && loading === "productName") ? (
                      <CommonSkeleton />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Spericorn PMS"
                        value={whiteLabelDetails?.productName?.value}
                        onChange={({ target: { value } }) =>
                          handleChange(value, "productName")
                        }
                        onKeyDown={(e) => handleOnKeyDown(e, "productName")}
                        onBlur={({ target: { value } }) =>
                          handleOnBlur(value, "productName")
                        }
                        disabled={
                          !hasPermission(
                            permissionConstants.permissions
                              .DESIGN_PRODUCT_NAME_EDIT,
                          )
                        }
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="row form-btm-offset">
                {hasPermission(
                  permissionConstants.permissions.DESIGN_DESCRIPTION,
                ) && (
                  <div className="col">
                    <label htmlFor="">Description</label>
                    {isLoadingDesignList ||
                    (isLoading && loading === "description") ? (
                      <CommonSkeleton height={180} />
                    ) : (
                      <textarea
                        className="form-control txt-area"
                        placeholder="Spericorn PMS"
                        value={whiteLabelDetails?.description?.value}
                        onChange={({ target: { value } }) =>
                          handleChange(value, "description")
                        }
                        onKeyDown={(e) => handleOnKeyDown(e, "description")}
                        onBlur={({ target: { value } }) =>
                          handleOnBlur(value, "description")
                        }
                        disabled={
                          !hasPermission(
                            permissionConstants.permissions
                              .DESIGN_DESCRIPTION_EDIT,
                          )
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col default-img-col">
              {hasPermission(
                permissionConstants.permissions.DESIGN_DEFAULT_IMAGES,
              ) && (
                <div className="upload-content-wrap">
                  <div className="upload-title-main">Default Images</div>
                  <div className="uplodables-container">
                    {hasPermission(
                      permissionConstants.permissions.DESIGN_PROFILE_IMAGE,
                    ) && (
                      <div className="uploading-blks">
                        <div className="upload-info">
                          Profile Image{" "}
                          <span className="tooltip_icon">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  data-place="right"
                                  className="text-start"
                                  id="id"
                                  style={{
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <table className="text-start">
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Max size:
                                        </span>
                                      </td>
                                      <td>10mb</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Valid type:
                                        </span>
                                      </td>
                                      <td>JPG, PNG</td>
                                    </tr>
                                  </table>
                                </Tooltip>
                              }
                            >
                              <img src={icon} alt="" />
                            </OverlayTrigger>
                          </span>
                        </div>
                        {isLoadingDesignList ||
                        (isLoading && loading === "profileImage") ? (
                          <CommonSkeleton height={140} />
                        ) : (
                          <div className="inner-blk  profile-image">
                            <div>
                              {image?.profileImage ||
                              whiteLabelDetails?.profileImage?.value ? (
                                <img
                                  src={
                                    image?.profileImage ||
                                    whiteLabelDetails?.profileImage?.value ||
                                    ""
                                  }
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <div className="overlay-blk">
                                {hasPermission(
                                  permissionConstants.permissions
                                    .DESIGN_PROFILE_IMAGE_EDIT,
                                ) && (
                                  <div className="btn btn-primary position-relative">
                                    <input
                                      accept=".jpg, .png"
                                      type="file"
                                      className="uploadInp opacity-0 position-absolute top-0 start-0 bottom-0 end-0"
                                      onChange={({ target: { files } }) => {
                                        if (files.length > 0) {
                                          const file = files[0];
                                          const allowedExtensions = [
                                            ".jpg",
                                            ".png",
                                          ];
                                          const fileExtension = file.name
                                            .substring(
                                              file.name.lastIndexOf("."),
                                            )
                                            .toLowerCase();
                                          const maxSizeInBytes =
                                            10 * 1024 * 1024;
                                          if (
                                            allowedExtensions.includes(
                                              fileExtension,
                                            ) &&
                                            file.size <= maxSizeInBytes
                                          ) {
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                              const image = new Image();
                                              image.src = e.target.result;

                                              image.onload = () => {
                                                handlePhotoChange(
                                                  files,
                                                  "profileImage",
                                                );
                                              };
                                            };
                                            reader.readAsDataURL(file);
                                          } else {
                                            let errorMessage =
                                              "Please select a ";
                                            if (
                                              !allowedExtensions.includes(
                                                fileExtension,
                                              )
                                            ) {
                                              errorMessage += "JPG or PNG file";
                                            }
                                            if (file.size > maxSizeInBytes) {
                                              errorMessage +=
                                                "file within 10mb";
                                            }

                                            setWhiteLabelDetails({
                                              ...whiteLabelDetails,
                                              profileImage: {
                                                error: errorMessage,
                                              },
                                            });
                                          }
                                        }
                                      }}
                                    />
                                    Replace
                                  </div>
                                )}
                              </div>
                            </div>
                            <span className="error__msg">
                              {whiteLabelDetails?.profileImage?.error}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {hasPermission(
                      permissionConstants.permissions.DESIGN_FAVICON,
                    ) && (
                      <div className="uploading-blks">
                        <div className="upload-info">
                          Favicon
                          <span className="tooltip_icon ms-1">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="text-start d-inline-flex align-items-start"
                                  id="id"
                                  style={{ textAlign: "left" }}
                                >
                                  <table className="text-start">
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Max size:
                                        </span>
                                      </td>
                                      <td>500kb</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Valid type:
                                        </span>
                                      </td>
                                      <td>ICO, PNG</td>
                                    </tr>
                                  </table>
                                </Tooltip>
                              }
                            >
                              <img src={icon} alt="" />
                            </OverlayTrigger>
                          </span>
                        </div>
                        {isLoadingDesignList ||
                        (isLoading && loading === "favicon") ? (
                          <CommonSkeleton height={140} />
                        ) : (
                          <div className="inner-blk fav-outer">
                            <div>
                              {image?.favicon ||
                              whiteLabelDetails?.favicon?.value ? (
                                <img
                                  src={
                                    image?.favicon ||
                                    whiteLabelDetails?.favicon?.value ||
                                    ""
                                  }
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <div className="overlay-blk">
                                {hasPermission(
                                  permissionConstants.permissions
                                    .DESIGN_FAVICON_UPDATE,
                                ) && (
                                  <div className="btn btn-primary position-relative">
                                    <input
                                      accept=".ico, .png, image/x-icon"
                                      type="file"
                                      className="uploadInp opacity-0 position-absolute top-0 start-0 bottom-0 end-0"
                                      onChange={({ target: { files } }) => {
                                        if (files.length > 0) {
                                          const file = files[0];
                                          const allowedExtensions = [
                                            ".ico",
                                            ".png",
                                          ];
                                          const fileExtension = file.name
                                            .substring(
                                              file.name.lastIndexOf("."),
                                            )
                                            .toLowerCase();
                                          const maxSizeInBytes = 500 * 1024;

                                          if (
                                            allowedExtensions.includes(
                                              fileExtension,
                                            ) &&
                                            file.size <= maxSizeInBytes
                                          ) {
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                              const image = new Image();
                                              image.src = e.target.result;

                                              image.onload = () => {
                                                handlePhotoChange(
                                                  files,
                                                  "favicon",
                                                );
                                              };
                                            };

                                            reader.readAsDataURL(file);
                                          } else {
                                            let errorMessage =
                                              "Please select a ";
                                            if (
                                              !allowedExtensions.includes(
                                                fileExtension,
                                              )
                                            ) {
                                              errorMessage += "ICO or PNG file";
                                            }
                                            if (file.size > maxSizeInBytes) {
                                              errorMessage +=
                                                "file within 500kb";
                                            }

                                            setWhiteLabelDetails({
                                              ...whiteLabelDetails,
                                              favicon: {
                                                error: errorMessage,
                                              },
                                            });
                                          }
                                        }
                                      }}
                                    />
                                    Replace
                                  </div>
                                )}
                              </div>
                            </div>
                            <span className="error__msg">
                              {whiteLabelDetails?.favicon?.error}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {hasPermission(
                      permissionConstants.permissions.DESIGN_NO_IMAGE,
                    ) && (
                      <div className="uploading-blks">
                        <div className="upload-info">
                          Default No Image
                          <span className="tooltip_icon ms-1">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  id="id"
                                  style={{
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <table className="text-start">
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Max size:
                                        </span>
                                      </td>
                                      <td>10mb</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Valid type:
                                        </span>
                                      </td>
                                      <td>JPG, PNG</td>
                                    </tr>
                                  </table>
                                </Tooltip>
                              }
                            >
                              <img src={icon} alt="" />
                            </OverlayTrigger>
                          </span>
                        </div>
                        {isLoadingDesignList ||
                        (isLoading && loading === "defaultNoImage") ? (
                          <CommonSkeleton height={140} />
                        ) : (
                          <div className="inner-blk">
                            <div>
                              {image?.defaultNoImage ||
                              whiteLabelDetails?.defaultNoImage?.value ? (
                                <img
                                  src={
                                    image?.defaultNoImage ||
                                    whiteLabelDetails?.defaultNoImage?.value ||
                                    ""
                                  }
                                  alt=""
                                />
                              ) : (
                                ""
                              )}

                              <div className="overlay-blk">
                                {hasPermission(
                                  permissionConstants.permissions
                                    .DESIGN_NO_IMAGE_UPDATE,
                                ) && (
                                  <div className="btn btn-primary position-relative">
                                    <input
                                      accept=".jpg, .png"
                                      type="file"
                                      className="uploadInp opacity-0 position-absolute top-0 start-0 bottom-0 end-0"
                                      onChange={({ target: { files } }) => {
                                        if (files.length > 0) {
                                          const file = files[0];
                                          const allowedExtensions = [
                                            ".jpg",
                                            ".png",
                                          ];
                                          const fileExtension = file.name
                                            .substring(
                                              file.name.lastIndexOf("."),
                                            )
                                            .toLowerCase();
                                          const maxSizeInBytes =
                                            10 * 1024 * 1024; // 10mb in bytes

                                          if (
                                            allowedExtensions.includes(
                                              fileExtension,
                                            ) &&
                                            file.size <= maxSizeInBytes
                                          ) {
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                              const image = new Image();
                                              image.src = e.target.result;

                                              image.onload = () => {
                                                handlePhotoChange(
                                                  files,
                                                  "defaultNoImage",
                                                );
                                              };
                                            };

                                            reader.readAsDataURL(file);
                                          } else {
                                            let errorMessage =
                                              "Please select a ";
                                            if (
                                              !allowedExtensions.includes(
                                                fileExtension,
                                              )
                                            ) {
                                              errorMessage += "JPG or PNG file";
                                            }
                                            if (file.size > maxSizeInBytes) {
                                              errorMessage +=
                                                "file within 10mb";
                                            }

                                            setWhiteLabelDetails({
                                              ...whiteLabelDetails,
                                              defaultNoImage: {
                                                error: errorMessage,
                                              },
                                            });
                                          }
                                        }
                                      }}
                                    />
                                    Replace
                                  </div>
                                )}
                              </div>
                            </div>
                            <span className="error__msg">
                              {whiteLabelDetails?.defaultNoImage?.error}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {hasPermission(
                      permissionConstants.permissions.DESIGN_LOGO,
                    ) && (
                      <div className="uploading-blks">
                        <div className="upload-info">
                          Logo
                          <span className="tooltip_icon ms-1">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  id="id"
                                  style={{
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <table className="text-start">
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Max size:
                                        </span>
                                      </td>
                                      <td>500kb</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Valid type:
                                        </span>
                                      </td>
                                      <td>JPG, PNG</td>
                                    </tr>
                                  </table>
                                </Tooltip>
                              }
                            >
                              <img src={icon} alt="" />
                            </OverlayTrigger>
                          </span>
                        </div>
                        {isLoadingDesignList ||
                        (isLoading && loading === "logo") ? (
                          <CommonSkeleton height={140} />
                        ) : (
                          <div className="inner-blk">
                            <div>
                              {image?.logo || whiteLabelDetails?.logo?.value ? (
                                <img
                                  src={
                                    image?.logo ||
                                    whiteLabelDetails?.logo?.value ||
                                    ""
                                  }
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <div className="overlay-blk">
                                {hasPermission(
                                  permissionConstants.permissions
                                    .DESIGN_LOGO_UPDATE,
                                ) && (
                                  <div className="btn btn-primary position-relative">
                                    <input
                                      accept=".jpg, .jpeg, .png"
                                      type="file"
                                      className="uploadInp opacity-0 position-absolute top-0 start-0 bottom-0 end-0"
                                      onChange={({ target: { files } }) => {
                                        if (files.length > 0) {
                                          const file = files[0];
                                          const allowedExtensions = [
                                            ".jpg",
                                            ".png",
                                          ];
                                          const fileExtension = file.name
                                            .substring(
                                              file.name.lastIndexOf("."),
                                            )
                                            .toLowerCase();
                                          const maxSizeInBytes = 500 * 1024;

                                          if (
                                            allowedExtensions.includes(
                                              fileExtension,
                                            ) &&
                                            file.size <= maxSizeInBytes
                                          ) {
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                              const image = new Image();
                                              image.src = e.target.result;

                                              image.onload = () => {
                                                handlePhotoChange(
                                                  files,
                                                  "logo",
                                                );
                                              };
                                            };

                                            reader.readAsDataURL(file);
                                          } else {
                                            let errorMessage =
                                              "Please select a ";
                                            if (
                                              !allowedExtensions.includes(
                                                fileExtension,
                                              )
                                            ) {
                                              errorMessage += "JPG or PNG file";
                                            }
                                            if (file.size > maxSizeInBytes) {
                                              errorMessage +=
                                                "file within 500kb";
                                            }

                                            setWhiteLabelDetails({
                                              ...whiteLabelDetails,
                                              logo: {
                                                error: errorMessage,
                                              },
                                            });
                                          }
                                        }
                                      }}
                                    />
                                    Replace
                                  </div>
                                )}
                              </div>
                            </div>
                            <span className="error__msg">
                              {whiteLabelDetails?.logo?.error}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {hasPermission(
                      permissionConstants.permissions.DESIGN_LOADER,
                    ) && (
                      <div className="uploading-blks">
                        <div className="upload-info">
                          Loader
                          <span className="tooltip_icon ms-1">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  id="id"
                                  style={{
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                    left: 0,
                                  }}
                                >
                                  <table className="text-start">
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Max size:
                                        </span>
                                      </td>
                                      <td>10mb</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="fw-semibold">
                                          Valid type:
                                        </span>
                                      </td>
                                      <td>GIF</td>
                                    </tr>
                                  </table>
                                </Tooltip>
                              }
                            >
                              <img src={icon} alt="" />
                            </OverlayTrigger>
                          </span>
                        </div>
                        {isLoadingDesignList ||
                        (isLoading && loading === "loader") ? (
                          <CommonSkeleton height={140} />
                        ) : (
                          <div className="inner-blk">
                            <div>
                              {image?.loader ||
                              whiteLabelDetails?.loader?.value ? (
                                <img
                                  src={
                                    image?.loader ||
                                    whiteLabelDetails?.loader?.value ||
                                    ""
                                  }
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <div className="overlay-blk">
                                {hasPermission(
                                  permissionConstants.permissions
                                    .DESIGN_LOADER_UPDATE,
                                ) && (
                                  <div className="btn btn-primary position-relative">
                                    <input
                                      type="file"
                                      accept=".gif"
                                      className="uploadInp opacity-0 position-absolute top-0 start-0 bottom-0 end-0"
                                      onChange={({ target: { files } }) => {
                                        if (files.length > 0) {
                                          const file = files[0];
                                          const allowedExtensions = [".gif"];
                                          const fileExtension = file.name
                                            .substring(
                                              file.name.lastIndexOf("."),
                                            )
                                            .toLowerCase();
                                          const maxSizeInBytes =
                                            10 * 1024 * 1024;

                                          if (
                                            allowedExtensions.includes(
                                              fileExtension,
                                            ) &&
                                            file.size <= maxSizeInBytes
                                          ) {
                                            handlePhotoChange(files, "loader");
                                          } else {
                                            let errorMessage =
                                              "Please select a GIF file";
                                            if (file.size > maxSizeInBytes) {
                                              errorMessage += " within 10mb";
                                            }
                                            setWhiteLabelDetails({
                                              ...whiteLabelDetails,
                                              loader: {
                                                error: errorMessage,
                                              },
                                            });
                                          }
                                        }
                                      }}
                                    />
                                    Replace
                                  </div>
                                )}
                              </div>
                            </div>
                            <span className="error__msg">
                              {whiteLabelDetails?.loader?.error}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {hasPermission(permissionConstants.permissions.DESIGN_SEO) && (
          <div className="form-white-labels">
            <div className="main-title">SEO</div>
            <div className="row form-btm-offset g-5">
              {hasPermission(
                permissionConstants.permissions.DESIGN_META_TITLE,
              ) && (
                <div className="col-md-4">
                  <label htmlFor="">Meta Title</label>
                  {isLoadingDesignList ||
                  (isLoading && loading === "metaTitle") ? (
                    <CommonSkeleton />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ERP Task Management Integration"
                      value={whiteLabelDetails?.metaTitle?.value}
                      onChange={({ target: { value } }) =>
                        handleChange(value, "metaTitle")
                      }
                      onKeyDown={(e) => handleOnKeyDown(e, "metaTitle")}
                      onBlur={({ target: { value } }) =>
                        handleOnBlur(value, "metaTitle")
                      }
                      disabled={
                        !hasPermission(
                          permissionConstants.permissions
                            .DESIGN_META_TITLE_EDIT,
                        )
                      }
                    />
                  )}
                </div>
              )}

              {hasPermission(
                permissionConstants.permissions.DESIGN_META_DESCRIPTION,
              ) && (
                <div className="col-md-8">
                  <label htmlFor="">Meta Description</label>
                  {isLoadingDesignList ||
                  (isLoading && loading === "metaDescription") ? (
                    <CommonSkeleton />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="The “Spericorn ERP Task Management Integration” project focuses on integrating an advanced task management system into Spericorn’s ERP solution."
                      value={whiteLabelDetails?.metaDescription?.value}
                      onChange={({ target: { value } }) =>
                        handleChange(value, "metaDescription")
                      }
                      onKeyDown={(e) => handleOnKeyDown(e, "metaDescription")}
                      onBlur={({ target: { value } }) =>
                        handleOnBlur(value, "metaDescription")
                      }
                      disabled={
                        !hasPermission(
                          permissionConstants.permissions
                            .DESIGN_META_DESCRIPTION_EDIT,
                        )
                      }
                    />
                  )}
                </div>
              )}
            </div>
            <div className="row form-btm-offset g-5">
              {hasPermission(
                permissionConstants.permissions.DESIGN_META_KEYWORDS_ADD,
              ) && (
                <div className="col-md-4">
                  <label htmlFor="">Meta Keywords</label>
                  {isLoadingDesignList && loading === "metaKeywords" ? (
                    <CommonSkeleton />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type Keywords"
                      value={whiteLabelDetails?.metaKeywordText?.value}
                      onChange={({ target: { value } }) =>
                        handleChange(value, "metaKeywordText")
                      }
                      onKeyDown={(e) => handleOnKeyDown(e, "metaKeywordText")}
                    />
                  )}
                  {/* <span className="error__msg">
                    {whiteLabelDetails?.metaKeywords?.error}
                  </span> */}
                </div>
              )}
              {hasPermission(
                permissionConstants.permissions.DESIGN_META_KEYWORDS_LIST,
              ) && (
                <div className="tag-line-wrp mt-4">
                  {whiteLabelDetails?.metaKeywords?.value?.map((item) => (
                    <div className="tag-line">
                      {item}
                      {hasPermission(
                        permissionConstants.permissions
                          .DESIGN_META_KEYWORDS_DELETE,
                      ) && (
                        <button
                          type="button"
                          className="btn close"
                          onClick={() => handleMetaKeywordDelete(item)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!!isImageCrop && (
        <ImageCrop
          showModal={isImageCrop}
          closeModal={() => {
            setIsImageCrop(false);
            setImage({
              profileImage: "",
              favicon: "",
              defaultNoImage: "",
              logo: "",
              loader: "",
            });
            setCropImage(null);
            setImageDetails({ key: "", file: "" });
          }}
          cropImage={cropImage}
          designList={designList}
          imageDetails={imageDetails}
          loading={setLoading}
          image={setImage}
        />
      )}
    </>
  );
};

export default withTitle(Design);
