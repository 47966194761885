/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ClearAllIcon, RightDoubleArrow, TrashIcon } from "../SubheaderIcons";
import searchNormalIcon from "../../../assets/images/search-normal.svg";
import { useThunk } from "../../../hooks/useThunk";
import { getTeam } from "../../../store/slices/team/thunks/getTeam";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import { useFilter } from "../../../context/useFilter";
import Button from "../../../components/button";
// import usersGroupIcon from "../../../assets/images/users_group.svg";
// import statusIcon from "../../../assets/images/Status_icon.svg";

const Assignees = ({ toggleAssignee, handleFilter }) => {
  const [userData, setUserData] = useState([]);
  const [team, isLoading] = useThunk(getTeam);
  const { teamData } = useSelector((state) => state.team);
  const [imgArray, setimgArray] = useState([]);

  const { projectData } = useSelector(({ project }) => project);
  const { shortCode } = useParams();
  const [projectId, setProjectId] = useState(null);
  const { filter, setFilter } = useFilter();

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  const params = { page: 1, limit: 100 };
  useEffect(() => {
    setUserData(teamData?.projectAggregation);
  }, [teamData]);
  useEffect(() => {
    if (projectData)
      setProjectId(
        projectData.find((each) => each.shortCode === shortCode)?._id,
      );
  }, [projectData]);
  useEffect(() => {
    if (projectId) {
      team({
        ...params,
        projectId,
      });
    }
  }, [projectId]);
  // console.log("userData", teamData);
  const handleSearch = (value) => {
    const filteredResults = teamData?.projectAggregation?.filter((item) =>
      item.user.toLowerCase().includes(value.toLowerCase()),
    );
    setUserData(filteredResults);
  };
  const getInitailBackground = (name) => {
    const nameParts = name?.split(" ");
    const initials = nameParts
      ?.map((part) => part.charAt(0).toUpperCase())
      .join("");
    return (
      <div className="avathar-sm avathar-01">
        <span className="name-ltr">{initials}</span>
      </div>
    );
  };
  // console.log("filter", filter);
  // const userLength = userData?.length;
  const sortedData = [...userData].sort((a, b) => a.user.localeCompare(b.user));
  return (
    <div className="slide-menu-inner flex-column show">
      <div className="menu-header ">
        <div className="title_otr d-flex">
          <div className="m-title">Assignees</div>
          <div className="closed-btn" onClick={toggleAssignee}>
            <RightDoubleArrow />
          </div>
        </div>
        <div className="search-area d-flex align-items-center">
          <div className="search-icon">
            <img src={searchNormalIcon} alt="Icon" />
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="list-items-main custom-scroll">
        <ul className="p-0">
          {/* <li className="d-flex align-items-center">
            <div className="avathar-sm">
              <img src={usersGroupIcon} alt="icon" />
            </div>
            <div className="rgt_content d-flex flex-column">
              <div className="title_fst">Unassigned</div>
              <div className="text-sec">37 tasks</div>
            </div>
          </li> */}
          {isLoading
            ? Array?.from({ length: userData?.length || 0 })?.map(
                (_, index) => (
                  <li key={index}>
                    <CommonSkeleton />
                  </li>
                ),
              )
            : sortedData?.map((item, index) => (
                <li
                  className={`d-flex align-items-center ${
                    filter.assignee.includes(item.userId) && "active"
                  }`}
                  key={index}
                  onClick={() => handleFilter(item.userId)}
                >
                  <div className="usr-prof-list d-flex align-items-center">
                    {item.photo ? (
                      imgArray?.includes(item?.userId) ? (
                        getInitailBackground(item.user)
                      ) : (
                        <div className="user-profile-thumb avathar-sm">
                          <img
                            onError={(target) =>
                              handleOnError(target, item?.userId)
                            }
                            onLoad={(target) => {
                              target.target.style.display = "block";
                            }}
                            src={`https://erptest.spericorn.com/repo/uploads/${item.photo}`}
                            alt=""
                          />
                        </div>
                      )
                    ) : (
                      getInitailBackground(item.user)
                    )}
                    <div className="rgt_content d-flex flex-column">
                      <div className="title_fst">{item.user}</div>
                      {/* <div className="text-sec">37 tasks</div> */}
                    </div>
                  </div>
                  <div className="chkbox-wrp assignee-chkbox">
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={filter.assignee.includes(item.userId)}
                    />
                    <span className="checkmark" />
                  </div>
                </li>
              ))}
        </ul>
      </div>
      <div className="slider-menu-ftr mt-auto pb-4">
        <div className="divitions">
          <hr />
        </div>
      </div>
    </div>
  );
};
Assignees.propTypes = {
  toggleAssignee: PropTypes.func,
  handleFilter: PropTypes.func,
};
export default Assignees;
