/* eslint-disable no-restricted-globals */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import { useSelector, useDispatch } from "react-redux";
import "../../design/custom/pages/profilecard.scss";
import "../../design/custom/pages/profile-list.scss";
// import briefcaseIcon from "../../assets/images/briefcase.svg";
import certificateIcon from "../../assets/images/certificate.svg";
import bulkTeacher from "../../assets/images/bulk-teacher-1.svg";
import {
  WorkingHourNormal,
  WorkingHourOrange,
  WorkingHourRed,
} from "../Allocations/AllocationSvg";
import LanguageContext from "../../context/LanguageContext";
import { userProfileActions } from "../../store/slices/userProfile";
import useElementScrollPosition from "../../components/Feed/scroll";
import {
  BloodGrpIcon,
  ContactIcon,
  DepartmentIcon,
  EmployeeIcon,
  GenderIcon,
} from "./svgIcons";
import { getFirstLetters } from "../../utils/utils";
import UserNoData from "./UserNoData";
import { useThunk } from "../../hooks/useThunk";
import { getJokes } from "../../store/slices/jokes/thunks/getJokes";
import UserAllocationTable from "./UserAllocationTable";
import { getUsersFeedById } from "../../store/slices/feed/thunks/getUsersFeedById";
import { getAllocationUserReport } from "../../store/slices/allocationList/thunks/getAllocationUserReport";
import { profileFeedPatch } from "../../store/slices/feed/thunks/profileFeedPatch";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import PostList from "../../components/Feed/feedPost";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import { getAllUsers } from "../../store/slices/common/thunks/getUsers";
import { resetUserFeed } from "../../store/slices/feed";
// import useElementScrollPosition from "../../components/Feed/scroll";

const UserProfile = () => {
  const [getJokesData] = useThunk(getJokes);

  const { MultyUsersData, userProfileLoader } = useSelector(
    (state) => state.userProfile,
  );
  const [userReports, allocationLoading] = useThunk(getAllocationUserReport);
  const [feedPatch] = useThunk(profileFeedPatch);
  const { usersFeed, noMoreFeeds } = useSelector((state) => state.feed);
  const { userReportData } = useSelector((state) => state.allocation);
  const { colorData } = useSelector((state) => state.colors);
  const [showComment, setShowComment] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 5 });
  const [imgError, setimgError] = useState(false);

  const { showUserprofile } = useSelector(({ userProfile }) => userProfile);
  const { allUsers } = useSelector((state) => state.common);

  const {
    languageData: { userProfileDetails },
  } = useContext(LanguageContext);

  const { authData } = useSelector((state) => state.auth);

  const scroll = useElementScrollPosition("feed-profile", ".tab_content");

  const [users] = useThunk(getAllUsers);

  const [getUsersFeed, feedLoading] = useThunk(getUsersFeedById);

  const { userProfile } = useSelector((state) => state.userProfile);

  const [activeTab, setActiveTab] = useState("information"); // Default to 'information' tab

  const scrollableRef = useRef();

  // Handle tab change event
  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const handleOnError = () => {
    setimgError(true);
  };

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.FEED,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  // get local time
  const updateCurrentTime = () => {
    const currentTime = moment()?.format("LT");
    const time = document.getElementById("time-display");
    if (time && time.textContent) time.textContent = currentTime;
    return currentTime;
  };

  updateCurrentTime();
  setInterval(updateCurrentTime, 1000);
  const dispatch = useDispatch();
  const onEmojiAddClick = (feedId, emoji) => {
    const formData = new FormData();
    formData.append("feedId", feedId);
    formData.append("emoji", emoji);
    formData.append("userId", authData?._id);
    feedPatch(formData);
  };

  const skillSetLength = MultyUsersData?.profileData?.skills?.length ?? 0;
  const certificationsLength =
    MultyUsersData?.profileData?.certification?.length ?? 0;
  const postLength = usersFeed?.data?.updatedData?.length ?? 0;

  function renderHourComponent(totalAllocationHours) {
    if (totalAllocationHours < 8) {
      return <WorkingHourRed />;
    }
    if (totalAllocationHours === 8) {
      return <WorkingHourNormal />;
    }
    return <WorkingHourOrange />;
  }

  function getHoursColorClass(totalAllocationHours) {
    if (totalAllocationHours < 8) {
      return "hours-red";
    }
    if (totalAllocationHours === 8) {
      return "";
    }
    return "hours-orange";
  }

  function getHoursBorderClass(totalAllocationHours) {
    if (totalAllocationHours < 8) {
      return "hrs-red";
    }
    if (totalAllocationHours === 8) {
      return "";
    }
    return "hrs-orange";
  }
  useEffect(() => {
    if (
      showUserprofile &&
      (!MultyUsersData?.education?.length ||
        !MultyUsersData?.certifications?.length ||
        !MultyUsersData?.work_experiance?.length)
    ) {
      getJokesData();
    }
  }, [showUserprofile]);

  useEffect(() => {
    if (MultyUsersData?._id) {
      if (
        typeof pagination?.page === "number" &&
        !isNaN(pagination?.page) &&
        scroll !== null
      ) {
        if (!noMoreFeeds)
          getUsersFeed({
            params: {
              page: pagination.page,
              limit: pagination.limit,
              id: MultyUsersData?._id,
            },
            id: MultyUsersData?._id,
            reset: pagination?.page === 1,
          });
      } else {
        getUsersFeed({
          params: {
            page: 1,
            limit: 5,
            id: MultyUsersData?._id,
          },
          id: MultyUsersData?._id,
          reset: true,
        });
      }
    }
  }, [pagination?.page, MultyUsersData?._id]);

  useEffect(() => {
    if (scroll >= 0 && scroll <= 1 && scroll !== null && scroll) {
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }, [scroll]);

  useEffect(() => {
    users();
  }, []);

  useEffect(() => {
    if (MultyUsersData) {
      userReports(MultyUsersData?._id);
    }
  }, [MultyUsersData?._id]);

  return (
    <div
      onClick={() => setShowComment("")}
      className={`slide-details-lg-cvr ${showUserprofile ? "show" : ""} `}
    >
      <div className="profle-hdr spacing pb-0">
        {userProfileLoader ? (
          <>
            <button
              className="btn-close"
              type="button"
              onClick={() => {
                dispatch(
                  userProfileActions?.setShowUserProfile(!showUserprofile),
                );
                document
                  .querySelector("#uncontrolled-tab-example-tab-information")
                  .click();
              }}
            />
            <CommonSkeleton width={400} />
          </>
        ) : (
          <>
            <div className="step-1">
              <div className="profil-info">
                <div className="avthar-lft">
                  <div
                    className={`profile-img ${
                      MultyUsersData?.photo && !imgError
                        ? "profile-imgCircle"
                        : ""
                    }`}
                  >
                    {MultyUsersData?.photo && !imgError ? (
                      <img
                        onError={handleOnError}
                        src={`https://erptest.spericorn.com/repo/uploads/${MultyUsersData?.photo}`}
                        alt="profile pic"
                      />
                    ) : (
                      <div
                        className="name userProf bg-7"
                        style={{
                          backgroundColor: colorData?.find(
                            (e) => e._id === MultyUsersData?.primaryColor,
                          )?.colorCode,
                        }}
                      >
                        {getFirstLetters(MultyUsersData?.employeeName)}
                      </div>
                    )}

                    <div className="online" />
                  </div>
                  <div className="rgt-cnt">
                    <div className="otr">
                      {MultyUsersData?.employeeName || "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="online-info">
                <div className="indicator online">
                  {userProfileDetails?.online || "-"}
                </div>
              </div>
              <button
                className="btn-close"
                type="button"
                onClick={() => {
                  dispatch(
                    userProfileActions?.setShowUserProfile(!showUserprofile),
                  );
                  dispatch(resetUserFeed());
                  setPagination({ limit: 5, page: 1 });
                  // Scroll back to the top position
                  if (scrollableRef.current) {
                    scrollableRef.current.scrollTop = 0;
                  }

                  document
                    .querySelector("#uncontrolled-tab-example-tab-information")
                    .click();
                }}
              />
            </div>
            <div className="step-2">
              <ul>
                <li>
                  <strong>{userProfileDetails?.role}</strong>
                  <p>{MultyUsersData?.designation?.name || "-"}</p>
                </li>
                <li>
                  <strong>{userProfileDetails?.email}</strong>
                  <p>{MultyUsersData?.email || "-"}</p>
                </li>
                <li>
                  <strong>{userProfileDetails?.localTime}</strong>
                  <p id="time-display">{updateCurrentTime()}</p>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>

      <div className="tab-area-wrap ">
        <div className="tab-menus spacing pt-0 pb-2 ">
          <Tabs
            activeKey={activeTab}
            onSelect={handleTabChange}
            defaultActiveKey="information"
            id="uncontrolled-tab-example"
            variant="underline"
          >
            <Tab eventKey="information" title="Information" className="bttn">
              <div className="tab_content custom-scroll">
                <div
                  className="tab-pane fade show active"
                  id="info-tab"
                  role="tabpanel"
                  aria-labelledby="info"
                  tabIndex="0"
                >
                  {userProfileLoader ? (
                    <div className="spacing px-0 tab-bar-outer pt-0">
                      {Array?.from({ length: 5 })?.map(() => (
                        <CommonSkeleton width={450} />
                      ))}
                    </div>
                  ) : (
                    <div className="spacing px-0 tab-bar-outer pt-0">
                      <div className="tab-bar">
                        <div className="icn">
                          <EmployeeIcon />
                        </div>
                        <p>
                          {userProfileDetails?.employeeId} :{" "}
                          <span>{MultyUsersData?.employeeId || "-"}</span>
                        </p>
                      </div>
                      <div className="tab-bar">
                        <div className="icn">
                          <DepartmentIcon />
                        </div>
                        <p>
                          {userProfileDetails?.department} :{" "}
                          <span>{MultyUsersData?.department?.name || "-"}</span>
                        </p>
                      </div>
                      <div className="tab-bar">
                        <div className="icn">
                          <ContactIcon />
                        </div>
                        <p>
                          {userProfileDetails?.contactNumber} :{" "}
                          <span>{MultyUsersData?.contactNumber || "-"}</span>
                        </p>
                      </div>
                      <div className="tab-bar">
                        <div className="icn">
                          <GenderIcon />
                        </div>
                        <p>
                          {userProfileDetails?.gender} :{" "}
                          <span>
                            {MultyUsersData?.profileData?.gender || "-"}
                          </span>
                        </p>
                      </div>
                      <div className="tab-bar">
                        <div className="icn">
                          <BloodGrpIcon />
                        </div>
                        <p>
                          {userProfileDetails?.bloodGroup} :{" "}
                          <span>
                            {MultyUsersData?.profileData?.blood_group || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                  {/* experience  */}

                  {/* <div className="sub_head spacing pt-0 px-0">Experience</div>

                  {userProfileLoader ? (
                    <div className="more-details spacing  px-0">
                      <CommonSkeleton width={450} />
                    </div>
                  ) : MultyUsersData?.work_experiance?.length ? (
                    <div className="more-details spacing  px-0">
                      {MultyUsersData?.work_experiance?.map((each, index) => (
                        <div className="row-dtls" key={index}>
                          <div className="icn-l">
                            <img src={briefcaseIcon} alt="icon" />
                          </div>
                          <div className="cnt-r">
                            <strong>ghghghghgh</strong>
                            <div className="sm-otr mb-1">
                              <small>
                                {userProfileDetails?.year} : {each?.from} -
                                {each?.to}
                              </small>
                              <div className="brdr" />
                              <small>
                                {userProfileDetails?.company}:{" "}
                                {each?.company || "-"}
                              </small>
                            </div>
                            <div className="sm-otr">
                              <small>
                                {userProfileDetails?.location} :{" "}
                                {each?.location || "-"}
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <UserNoData />
                  )} */}

                  {/* education  */}

                  <div className="sub_head spacing pt-0 px-0">
                    {userProfileDetails?.education}
                  </div>
                  {userProfileLoader ? (
                    <div className="more-details spacing  px-0">
                      <CommonSkeleton width={450} />
                    </div>
                  ) : MultyUsersData?.profileData?.education?.length ? (
                    <div className="more-details spacing px-0">
                      {MultyUsersData?.profileData?.education?.map(
                        (each, index) => (
                          <div className="row-dtls">
                            <div className="icn-l">
                              <img src={bulkTeacher} alt="icon" />
                            </div>
                            <div className="cnt-r" key={index}>
                              <strong>{each?.course || "-"}</strong>
                              <div className="sm-otr">
                                <small>
                                  {userProfileDetails?.year} :{" "}
                                  {each?.start_date}-{each?.end_date}
                                </small>
                                <div className="brdr" />
                                <small>{each?.certified_by || "-"}</small>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  ) : (
                    <UserNoData />
                  )}
                </div>
              </div>
            </Tab>

            <Tab
              eventKey="workAllocation"
              title={`Allocation (${
                userReportData?.totalAllocationHours || 0
              })`}
              className="bttn"
            >
              {allocationLoading ? (
                <div className="more-details spacing  px-0">
                  <CommonSkeleton />
                </div>
              ) : userReportData?.allProjects?.length ? (
                <div className="tab_content custom-scroll">
                  <div className="spacing p-0">
                    <div className="hrs-blk pt-0">
                      Total Work Hours Assigned:
                      <div className="hrs">
                        <span
                          className={`icon ${getHoursBorderClass(
                            userReportData?.totalAllocationHours,
                          )}`}
                        >
                          {renderHourComponent(
                            userReportData?.totalAllocationHours,
                          )}
                        </span>
                        <span
                          className={getHoursColorClass(
                            userReportData?.totalAllocationHours,
                          )}
                        >
                          {userReportData?.totalAllocationHours}h
                        </span>
                      </div>
                    </div>
                    <UserAllocationTable userReport={userReportData} />
                  </div>
                </div>
              ) : (
                <UserNoData />
              )}
            </Tab>

            <Tab
              eventKey="certifications"
              title={`Certifications (${certificationsLength})`}
              className="bttn"
            >
              {userProfileLoader ? (
                <div className="more-details spacing  px-0">
                  <CommonSkeleton />
                </div>
              ) : MultyUsersData?.profileData?.certification?.length ? (
                <div className="more-details spacing px-0">
                  {MultyUsersData?.profileData?.certification?.map(
                    (each, index) => (
                      <div className="row-dtls" key={index}>
                        <div className="icn-l">
                          <img src={certificateIcon} alt="icon" />
                        </div>
                        <div className="cnt-r">
                          <strong>{each?.title || "-"}</strong>
                          <div className="sm-otr mb-1">
                            <small>
                              {userProfileDetails?.year} : {each?.start_date} -{" "}
                              {each?.end_date}
                            </small>
                            <div className="brdr" />
                            <small>
                              {userProfileDetails?.certifiedBy} :{" "}
                              {each?.certified_by || "-"}
                            </small>
                          </div>
                          <div className="sm-otr">
                            <small>
                              {userProfileDetails?.licenseNumber} :{" "}
                              {each?.licence_number || "-"}
                            </small>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                  :
                </div>
              ) : (
                <UserNoData />
              )}
            </Tab>
            <Tab
              eventKey="skills"
              title={`Skills (${skillSetLength})`}
              className="bttn"
            >
              <div
                className="tab-pane"
                id="roles-tab"
                role="tabpanel"
                aria-labelledby="roles"
                tabIndex="0"
              >
                <div className="tab_content custom-scroll">
                  {userProfileLoader ? (
                    <div className="more-details spacing  px-0">
                      <CommonSkeleton />
                    </div>
                  ) : MultyUsersData?.profileData?.skills?.length ? (
                    <div className="spacing  px-0 w-100 ">
                      {MultyUsersData?.profileData?.skills?.map(
                        (each, index) => (
                          <div className="tab-bar" key={index}>
                            <p>{each || "-"}</p>
                          </div>
                        ),
                      )}
                    </div>
                  ) : (
                    <UserNoData />
                  )}
                </div>
              </div>
            </Tab>

            {/* feed  */}
            <Tab
              eventKey="feedPosts"
              title={`Posts (${postLength})`}
              className="bttn feed-profile"
            >
              <div className="tab_content custom-scroll" ref={scrollableRef}>
                {/* <!-- feeds lists loop end here --> */}
                {/* <!-- feeds lists loop start here --> */}
                {feedLoading ? (
                  Array.from({
                    length: usersFeed?.data?.updatedData?.length,
                  }).map((_, i) => (
                    <div className="feeds-lists">
                      <div className="profile-head" key={i}>
                        <div className="user-profile">
                          <div className="user-pic avathar-round">
                            <CommonSkeleton />
                          </div>
                          <div className="user-info">
                            <div className="title">
                              <CommonSkeleton height={20} width={90} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="user-feed-blk">
                        <div className="album-set full-width-img">
                          <div className="img-single">
                            <CommonSkeleton height={200} width={450} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : usersFeed?.data?.updatedData?.length ? (
                  <PostList
                    listData={usersFeed}
                    handleLikePatch={onEmojiAddClick}
                    // onScrollChange={handleScrollChange}
                    setShowComment={setShowComment}
                    showComment={showComment}
                    type="profile"
                    allUsers={allUsers}
                    scrollClassName={{
                      tabPane: "tab_content",
                      feedContainer: "feed-container",
                    }}
                    hasPermission={hasPermission}
                  />
                ) : (
                  <UserNoData />
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
