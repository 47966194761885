import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import notificationIconServices from "../../../../../services/notificationIconServices";
import { alertActions } from "../../../alert";
import { getNotificationtList } from "./getNotificationDetails";

export const addNotification = createAsyncThunk(
  "notification",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await notificationIconServices.addNotification(payload);

      dispatch(getNotificationtList());
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(notificationIconServices.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
