import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../../../../services/ticketService";
import { alertActions } from "../../alert";
import { setTicketAllocationDataById } from "..";

export const deleteTicketAttachment = createAsyncThunk(
  "delete/ticketAttachment",
  async ({ id, file }, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await service.deleteTicketAtachment(id, file);
      dispatch(setTicketAllocationDataById(data));
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
