import Gateway from "../gateway/gateway";

const getProjectList = ({ page, limit, sortOrder, sortField, ProjectId }) =>
  Gateway.get(
    `/ticket?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortField=${sortField}&ProjectId=${ProjectId}`,
  );
const getTicketById = ({ id, ProjectId }) =>
  Gateway.get(`/ticket/?id=${id}&ProjectId=${ProjectId}`);
const updateTicket = ({ ticketId, data }) =>
  Gateway.patch(`/ticket/${ticketId}`, { data });
const getnotificationbyId = ({ id }) => Gateway.get(`/ticket/?id=${id}`);
const deleteTicket = ({ ticketId }) => Gateway.patch(`/ticket/${ticketId}`);

export default {
  getTicketById,
  getProjectList,
  updateTicket,
  getnotificationbyId,
  deleteTicket,
};
