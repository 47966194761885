/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import closeIcon from "../../assets/images/close__icon.svg";
import userWithoutName from "../../assets/images/userWithoutImage.svg";
import { getMultyUserProfile } from "../../store";
import { useThunk } from "../../hooks/useThunk";
import { userProfileActions } from "../../store/slices/userProfile";
import { getFirstLetters } from "../../utils/utils";

const UserDetail = ({
  TaskData,
  dropDownVisibility,
  addUser,
  ticket,
  onAllocateUser,
  onDeallocateUser,
  allocatedId,
  setAddUser,
  setNewSubtaskUser,
  setNewSubTaskUserPopup,
  chosenUsers,
  setChosenUsers,
  checklist,
  onChosenUsersChange,
  setChecklist,
  checklistValue,
  atText,
  assignedUserClick,
  view,
}) => {
  const { teamData } = useSelector((state) => state.team);
  const [val, setVal] = useState("");

  const [users, setUsers] = useState([]);
  const [userDropDown, setUserDropDown] = useState(false);
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const dispatch = useDispatch();
  const [imgArray, setimgArray] = useState([]);

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };
  let restOfTheUserDetails = [];
  if (setNewSubtaskUser) {
    restOfTheUserDetails = users?.filter((user) => {
      return !chosenUsers.some(
        (chosenUser) => chosenUser.userId === user.userId,
      );
    });
  } else if (checklist === "checklist") {
    restOfTheUserDetails = users?.filter((user) => {
      return !TaskData?.user?.includes(user?.userId);
    });
  } else if (checklist === "ticketUserView") {
    restOfTheUserDetails = users?.filter((user) => {
      return TaskData?.allocations?.some((allocation) =>
        allocation.assignedUsers?.some(
          (assignedUser) => assignedUser.user === user.userId,
        ),
      );
    });
  } else if (checklist === "taskUserView") {
    restOfTheUserDetails = users?.filter((user) => {
      return TaskData?.assignedUsers?.some(
        (assignedUser) => assignedUser._id === user.userId,
      );
    });
  } else {
    restOfTheUserDetails = !ticket
      ? users?.filter((user) => {
          return !TaskData?.assignedUsers?.some(
            (assignedUser) => assignedUser?._id === user?.userId,
          );
        })
      : users?.filter((user) => {
          return !TaskData?.assignedUsers?.some(
            (assignedUser) => assignedUser?.user === user?.userId,
          );
        });
  }
  const handleAllocateUserClick = (data) => {
    if (!setNewSubtaskUser) {
      onAllocateUser(data, allocatedId);
      if (setChecklist) {
        setChecklist(checklistValue);
      }
    } else {
      const userToAdd = users.find((user) => user.userId === data.user);
      if (userToAdd) {
        setChosenUsers((prevChosenUsers) => [...prevChosenUsers, userToAdd]);
        onChosenUsersChange([...chosenUsers, userToAdd]);
      }
    }
  };
  const handleUallocateUserClick = (data) => {
    if (!setNewSubtaskUser) {
      onDeallocateUser(data, allocatedId);
    } else {
      const userIndexToRemove = chosenUsers.findIndex(
        (user) => user.userId === data.user,
      );

      if (userIndexToRemove !== -1) {
        const updatedChosenUsers = [...chosenUsers];
        updatedChosenUsers.splice(userIndexToRemove, 1);

        setChosenUsers(updatedChosenUsers);
      }
    }
  };
  useEffect(() => {
    setVal("");
  }, []);
  useEffect(() => {
    if (val === "") {
      setUsers(teamData?.projectAggregation?.map((user) => user));
    } else {
      setUsers(
        teamData?.projectAggregation?.filter((item) =>
          item.user?.toLowerCase().includes(val?.toLowerCase()),
        ),
      );
    }
    if (atText) {
      setUsers(
        teamData?.projectAggregation?.filter((item) =>
          item.user?.toLowerCase().includes(atText?.toLowerCase()),
        ),
      );
    }
  }, [val, teamData, atText]);
  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (setNewSubTaskUserPopup) {
        setNewSubTaskUserPopup(false);
      }
      setUserDropDown(false);
      setVal("");
      setAddUser(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  let checklistContent = null;

  if (checklist === "checklist") {
    const filteredUsers = teamData?.projectAggregation?.filter(({ userId }) =>
      TaskData.user?.some((e) => e === userId),
    );

    const displayedUsers = filteredUsers?.slice(0, 2);
    const remainingUsers = filteredUsers?.slice(2);
    checklistContent = (
      <>
        <div className="avatar__group">
          {displayedUsers?.map(({ user, userId, photo }) => (
            <div className="avathar-sm avathar-01 avathar_02" key={userId}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  handleUallocateUserClick({ user: userId });
                }}
                className="close_circle"
                style={{ zIndex: 10 }}
              >
                <img src={closeIcon} alt="" />
              </div>
              <span
                onClick={(f) => {
                  f.stopPropagation();
                  getMultyUsers(userId);
                  dispatch(userProfileActions.setShowUserProfile(true));
                }}
              >
                {photo ? (
                  imgArray?.includes(userId) ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-id">{user}</Tooltip>}
                    >
                      <span
                        onClick={(f) => {
                          f.stopPropagation();
                          getMultyUsers(userId);
                          dispatch(userProfileActions.setShowUserProfile(true));
                        }}
                      >
                        {getFirstLetters(user)}
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <div className="user-pic">
                      <img
                        onError={(target) => handleOnError(target, userId)}
                        onLoad={(target) => {
                          target.target.style.display = "block";
                        }}
                        onClick={(f) => {
                          f.stopPropagation();
                          getMultyUsers(userId);
                          dispatch(userProfileActions.setShowUserProfile(true));
                        }}
                        src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
                        alt="profile-pic"
                      />
                    </div>
                  )
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-id">{ticket ? user : user}</Tooltip>
                    }
                  >
                    <span>{user?.slice(0, 2).toUpperCase()}</span>
                  </OverlayTrigger>
                )}
              </span>
            </div>
          ))}
        </div>
        {remainingUsers.length > 0 && (
          <Dropdown>
            <Dropdown.Toggle id="checklist-toggles" className="moreUser">
              + {remainingUsers?.length}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {remainingUsers?.map((e, index) => (
                <div className="userList more-usr-dropdown">
                  <li className="d-flex align-items-center" key={index}>
                    <div className="d-flex align-items-center">
                      {e.photo ? (
                        imgArray?.includes(e.userId) ? (
                          <div className="avathar-sm avathar-01">
                            <div
                              onClick={(event) => {
                                handleUallocateUserClick({ user: e.userId });
                                event.stopPropagation();
                              }}
                              className="close_circle"
                              style={{ zIndex: 10 }}
                            >
                              <img src={closeIcon} alt="" />
                            </div>
                            <span>{getFirstLetters(e.user)}</span>
                          </div>
                        ) : (
                          <div className="avathar-sm avathar-01">
                            <div
                              onClick={(event) => {
                                handleUallocateUserClick({
                                  user:
                                    setNewSubtaskUser || ticket
                                      ? e.userId
                                      : e._id,
                                });
                                event.stopPropagation();
                              }}
                              className="close_circle"
                              style={{ zIndex: 10 }}
                            >
                              <img src={closeIcon} alt="" />
                            </div>
                            <div
                              className="user-pic"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleUallocateUserClick({ user: e.userId });
                              }}
                              style={{ zIndex: 10 }}
                            >
                              <img
                                onError={(target) =>
                                  handleOnError(target, e.userId)
                                }
                                onLoad={(target) => {
                                  target.target.style.display = "block";
                                }}
                                src={`https://erptest.spericorn.com/repo/uploads/${e.photo}`}
                                alt="profile-pic"
                              />
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="avathar-sm avathar-01">
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              handleUallocateUserClick({
                                user:
                                  setNewSubtaskUser || ticket
                                    ? e.userId
                                    : e._id,
                              });
                            }}
                            className="close_circle"
                            style={{ zIndex: 10 }}
                          >
                            <img src={closeIcon} alt="" />
                          </div>
                          <span>
                            {e.user
                              ?.split(" ")
                              .map((word) => word.slice(0, 1).toUpperCase())
                              .join("")}
                          </span>
                        </div>
                      )}
                      <div
                        className="rgt_content d-flex flex-column"
                        onClick={(f) => {
                          f.stopPropagation();
                          getMultyUsers(ticket ? e?.userId : e?._id);
                          dispatch(userProfileActions.setShowUserProfile(true));
                        }}
                      >
                        <div className="title_fst">{e.user}</div>
                      </div>
                    </div>
                  </li>
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  }
  const maxUsersToShow = 2;

  let usersToDisplay;
  let showMoreUsers;

  if (setNewSubtaskUser) {
    usersToDisplay = chosenUsers?.slice(0, maxUsersToShow);
    showMoreUsers = chosenUsers?.slice(maxUsersToShow);
  } else if (!ticket) {
    usersToDisplay = TaskData?.assignedUsers?.slice(0, maxUsersToShow);
    showMoreUsers = TaskData?.assignedUsers?.slice(maxUsersToShow);
  } else {
    usersToDisplay = teamData?.projectAggregation
      ?.filter(({ userId }) =>
        TaskData.assignedUsers?.some(({ user }) => user === userId),
      )
      ?.slice(0, maxUsersToShow);
    showMoreUsers = teamData?.projectAggregation
      ?.filter(({ userId }) =>
        TaskData.assignedUsers?.some(({ user }) => user === userId),
      )
      ?.slice(maxUsersToShow);
  }

  const otherFunction = (
    <>
      <div className="avatar__group">
        {usersToDisplay?.map((user) => (
          <div className="avathar-sm avathar-01" key={user._id}>
            <div
              onClick={() =>
                handleUallocateUserClick({
                  user: setNewSubtaskUser || ticket ? user.userId : user._id,
                })
              }
              className="close_circle"
              style={{ zIndex: 10 }}
            >
              <img src={closeIcon} alt="" />
            </div>
            {setNewSubtaskUser === false && user.employeeName}
            {setNewSubtaskUser === true && user.user && (
              <img src={userWithoutName} alt="" />
            )}
            <span
              onClick={(f) => {
                f.stopPropagation();
                getMultyUsers(ticket ? user?.userId : user?._id);
                dispatch(userProfileActions.setShowUserProfile(true));
              }}
            >
              {user?.photo ? (
                imgArray?.includes(
                  setNewSubtaskUser || ticket ? user.userId : user._id,
                ) ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-id">
                        {ticket ? user.user : user?.employeeName}
                      </Tooltip>
                    }
                  >
                    <span>
                      {getFirstLetters(
                        setNewSubtaskUser || ticket
                          ? user?.user?.slice(0, 2).toUpperCase()
                          : user?.employeeName?.slice(0, 2).toUpperCase(),
                      )}
                    </span>
                  </OverlayTrigger>
                ) : (
                  <div className="user-pic">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-id">
                          {ticket ? user.user : user?.employeeName}
                        </Tooltip>
                      }
                    >
                      <img
                        onError={(target) =>
                          handleOnError(
                            target,
                            setNewSubtaskUser || ticket
                              ? user.userId
                              : user._id,
                          )
                        }
                        onLoad={(target) => {
                          target.target.style.display = "block";
                        }}
                        src={`https://erptest.spericorn.com/repo/uploads/${user?.photo}`}
                        alt="profile-pic"
                      />
                    </OverlayTrigger>
                  </div>
                )
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-id">
                      {ticket ? user.user : user?.employeeName}
                    </Tooltip>
                  }
                >
                  <span>
                    {setNewSubtaskUser || ticket
                      ? user?.user?.slice(0, 2).toUpperCase()
                      : user?.employeeName?.slice(0, 2).toUpperCase()}
                  </span>
                </OverlayTrigger>
              )}
            </span>
          </div>
        ))}
      </div>
      {showMoreUsers?.length > 0 && (
        <Dropdown>
          <Dropdown.Toggle id="subtask-toggle" className="moreUser">
            + {showMoreUsers?.length}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {showMoreUsers?.map((e, index) => (
              <div className="userList more-usr-dropdown">
                <li className="d-flex align-items-center" key={index}>
                  <div className="d-flex align-items-center mb-1">
                    {e.photo ? (
                      imgArray?.includes(
                        setNewSubtaskUser || ticket ? e.userId : e._id,
                      ) ? (
                        <div className="avathar-sm avathar-01">
                          <div
                            onClick={() =>
                              handleUallocateUserClick({
                                user:
                                  setNewSubtaskUser || ticket
                                    ? e.userId
                                    : e._id,
                              })
                            }
                            className="close_circle"
                            style={{ zIndex: 10 }}
                          >
                            <img src={closeIcon} alt="" />
                          </div>
                          <span>
                            {getFirstLetters(ticket ? e.user : e.employeeName)}
                          </span>
                        </div>
                      ) : (
                        <div className="avathar-sm avathar-01">
                          <div
                            onClick={() =>
                              handleUallocateUserClick({
                                user:
                                  setNewSubtaskUser || ticket
                                    ? e.userId
                                    : e._id,
                              })
                            }
                            className="close_circle"
                            style={{ zIndex: 10 }}
                          >
                            <img src={closeIcon} alt="" />
                          </div>
                          <div className="user-pic">
                            <img
                              onError={(target) =>
                                handleOnError(
                                  target,
                                  setNewSubtaskUser || ticket
                                    ? e.userId
                                    : e._id,
                                )
                              }
                              onLoad={(target) => {
                                target.target.style.display = "block";
                              }}
                              src={`https://erptest.spericorn.com/repo/uploads/${e.photo}`}
                              alt="profile-pic"
                            />
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="avathar-sm avathar-01">
                        <div
                          onClick={() =>
                            handleUallocateUserClick({
                              user:
                                setNewSubtaskUser || ticket ? e.userId : e._id,
                            })
                          }
                          className="close_circle"
                          style={{ zIndex: 10 }}
                        >
                          <img src={closeIcon} alt="" />
                        </div>
                        <span>
                          {ticket
                            ? e.user
                                ?.split(" ")
                                .map((word) => word.slice(0, 1).toUpperCase())
                                .join("")
                            : e.employeeName
                                ?.split(" ")
                                .map((word) => word.slice(0, 1).toUpperCase())
                                .join("")}
                        </span>
                      </div>
                    )}
                    <div
                      className="rgt_content d-flex flex-column"
                      onClick={(f) => {
                        f.stopPropagation();
                        getMultyUsers(ticket ? e?.userId : e?._id);
                        dispatch(userProfileActions.setShowUserProfile(true));
                      }}
                    >
                      <div className="title_fst">
                        {ticket ? e.user : e.employeeName}
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
  return (
    <div
      ref={dropdownRef}
      className={`userList ps-2 ${
        checklist === "checklist" ? "d-flex ps-3" : ""
      } ${assignedUserClick ? "mention-btn" : ""} ${
        (chosenUsers?.length > 0 || checklist === "checklist") && "user-added"
      } ${TaskData?.assignedUsers?.length === 0 && "no-item"}`}
    >
      <Dropdown show={(addUser && dropDownVisibility) || userDropDown}>
        <Dropdown.Toggle
          className={`${
            (addUser && dropDownVisibility) || userDropDown
          } ? "show" : "" ${chosenUsers?.length > 0 ? "d-flex" : ""}`}
        >
          {checklist === "checklist" && checklistContent}
          {otherFunction}
        </Dropdown.Toggle>

        <Dropdown.Menu
          className={`custom-scroll ${
            (addUser && dropDownVisibility) || userDropDown ? "show" : ""
          } ${!ticket ? "taskModal" : ""}`}
        >
          <div style={{ display: "block" }} className="no-bg">
            <div
              className="search-icon "
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="text"
                className="p-1 no-hover"
                value={val}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                  fontSize: "14px",
                  color: "#888",
                }}
                placeholder="Search"
                onChange={(e) => setVal(e.target.value)}
              />
            </div>
            <div className="divisions pb-1 pt-1">
              <hr className="mt-0 mb-0" />
            </div>
            <div className="list-items-main custom-scroll list-items-1 ps-2 pe-1 allocation-drp-dwn">
              <ul className="p-0">
                {restOfTheUserDetails?.length >= 1 ? (
                  restOfTheUserDetails.map((e, index) => (
                    <li
                      className="d-flex align-items-center"
                      key={index}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleAllocateUserClick({ user: e.userId });
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        onClick={(f) => {
                          if (view) {
                            f.stopPropagation();
                            getMultyUsers(e.userId);
                            dispatch(
                              userProfileActions.setShowUserProfile(true),
                            );
                          }
                        }}
                      >
                        {e.photo ? (
                          imgArray?.includes(e.userId) ? (
                            <div className="avathar-sm avathar-01">
                              <span>{getFirstLetters(e.user)}</span>
                            </div>
                          ) : (
                            <div className="user-pic">
                              <img
                                onError={(target) =>
                                  handleOnError(target, e.userId)
                                }
                                onLoad={(target) => {
                                  target.target.style.display = "block";
                                }} // Ensure the image is displayed when it successfully loads
                                src={`https://erptest.spericorn.com/repo/uploads/${e.photo}`}
                                alt="profile-pic"
                              />
                            </div>
                          )
                        ) : (
                          <div className="avathar-sm avathar-01">
                            <span>
                              {e.user
                                ?.split(" ")
                                .map((word) => word.slice(0, 1).toUpperCase())
                                .join("")}
                            </span>
                          </div>
                        )}
                        <div className="rgt_content d-flex flex-column">
                          <div className="title_fst">{e.user}</div>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <span>No data </span>
                )}
              </ul>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
UserDetail.propTypes = {
  TaskData: PropTypes.shape({
    assignedUsers: PropTypes.node,
    _id: PropTypes.string,
    allocations: PropTypes.node,
    user: PropTypes.node,
  }),
  dropDownVisibility: PropTypes.bool,
  addUser: PropTypes.bool,
  ticket: PropTypes.bool,
  allocations: PropTypes.shape({}),
  onAllocateUser: PropTypes.func,
  onDeallocateUser: PropTypes.func,
  allocatedId: PropTypes.string,
  setAddUser: PropTypes.func,
  setNewSubtaskUser: PropTypes.func,
  chosenUsers: PropTypes.node,
  setChosenUsers: PropTypes.func,
  setNewSubTaskUserPopup: PropTypes.func,
  checklist: PropTypes.string,
  onChosenUsersChange: PropTypes.func,
  setChecklist: PropTypes.func,
  checklistValue: PropTypes.string,
  atText: PropTypes.string,
  assignedUserClick: PropTypes.bool,
  view: PropTypes.bool,
};
export default UserDetail;
