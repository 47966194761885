import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import CustomModal from "../../../../../components/CustomModal";
import { useThunk } from "../../../../../hooks/useThunk";
import { alertActions } from "../../../../../store/slices/alert";
import { getUserRepos } from "../../../../../store/slices/gitIntegration/thunks/getUserRepos";
import { getGitBranchs } from "../../../../../store/slices/gitIntegration/thunks/getBranchs";
import { CreateBranchModal } from "./createBranchModal";
import { CreatePrModal } from "./createPrModal";
import { FileManager } from "./Filemanager";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../../../utils/permissionUtils";
import permissionConstants from "../../../../../utils/permissionUtils/permissionConstants";
import "../../../../../design/custom/pages/integrations-modal.scss";
import "../../../../../design/custom/pages/github-modal-table.scss";
import gitIcon from "../../../../../assets/images/git.svg";
import copyIcon from "../../../../../assets/images/copy-sm.svg";
import closeIcon from "../../../../../assets/images/close.svg";
import modalClose from "../../../../../assets/images/modal-close.svg";
import ideaIcon from "../../../../../assets/images/idea.svg";

import Commits from "./commits";
import ListPr from "./listPr";
import { GitBranches } from "./GitBranches";

export const GitHubModal = ({
  projectId,
  taskName,
  taskId,
  setGitModalOpen,
  ticket,
}) => {
  const [getUserRepositories, isLoadingProjectRepos] = useThunk(getUserRepos);
  const [getGitHubBranches, isLoadingBranches] = useThunk(getGitBranchs);
  const [openCreateBranch, setOpenCreateBranch] = useState(false);
  const [openCreatePr, setOpenCreatePr] = useState(false);
  const [commonSl, setcommonSl] = useState("");
  const [txtVisible, setTxtVisible] = useState(true);
  const [branches, setBranches] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [decodedContent, setDecodedContent] = useState("");
  const [activeTab, setActiveTab] = useState("file");

  const [dataFromPr, setDataFromPr] = useState(null);
  const [dataFromCommit, setDataFromCommit] = useState(null);
  const [tablesearchValue, settablesearchValue] = useState(null);
  const [showBlame, setShowBlame] = useState(false);
  const dispatch = useDispatch();
  const [selectedFolderPath, setSelectedFolderPath] = useState(null);
  const [localContents, setLocalContents] = useState([]);
  const { authData } = useSelector((state) => state.auth);
  const { userRepositories, getAllBranchsData } = useSelector(
    (state) => state.github,
  );
  const { userProfile } = useSelector((state) => state.userProfile);
  const searchableTabs = ["pull", "branches", "commits"];

  // Update the tab change handler to update the active tab
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    settablesearchValue(""); // Reset search value when tab changes
  };

  // tip visibility
  const handleHideTxt = () => {
    setTxtVisible(false);
  };
  // fetch folder contents

  // permission settings
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.TASK_DETAILS_MODAL,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  // fetch repos related with project
  useEffect(() => {
    if (projectId) getUserRepositories(projectId);
  }, [projectId]);

  // fetch branches
  useEffect(() => {
    if (commonSl !== "") {
      getGitHubBranches(commonSl);
    }
  }, [commonSl]);

  // options for repositories select
  const options = userRepositories?.map((item) => ({
    value: item?.repository?.repoName,
    label: item?.repository?.repoName,
    owner: item?.repository?.owner,
  }));

  // pr count

  const pullCount = (data) => {
    setDataFromPr(data);
  };

  // commits count

  const commitsCount = (datas) => {
    setDataFromCommit(datas);
  };
  const modifyEmployeeName = (employeeName) => {
    if (employeeName) {
      const names = employeeName.split(" ");

      const modifiedName = names.filter(Boolean).join("_");
      return modifiedName;
    }
  };

  // find count for branch content
  const branchCount = getAllBranchsData?.length;

  const openCreateBr = () => {
    setOpenCreateBranch(true);
  };

  const openCreatePrModal = () => {
    setOpenCreatePr(true);
  };

  const renderBody = () => (
    <div className="gitmodal-body">
      <div className="modal-header body-header">
        <div className="top-brand-area">
          <div className="brand-icons">
            <img src={gitIcon} alt="" />
          </div>
          <div className="brand-name">
            <h3 className="brand-head">GitHub</h3>
          </div>

          <div className="brand-task-area">
            {hasPermission(
              permissionConstants.permissions.TASK_DETAILS_MODAL_GITHUB_TASKID,
            ) && (
              <div className="task-name-area">
                {" "}
                TASK ID : <span>{taskId}</span>{" "}
              </div>
            )}

            {hasPermission(
              permissionConstants.permissions
                .TASK_DETAILS_MODAL_GITHUB_COPYTASKID,
            ) && (
              <button
                className="btn btn-task-copy"
                type="button"
                onClick={() => {
                  const copyText = taskId;
                  navigator.clipboard.writeText(copyText);
                  dispatch(alertActions.success("Copied to clipboard."));
                }}
              >
                <img src={copyIcon} alt="s" />
              </button>
            )}
          </div>
        </div>

        <div className="right-top-area">
          {txtVisible === true ? (
            <div className="info-area">
              <div className="icons">
                <img src={ideaIcon} alt="s" />
              </div>
              <div className="txt">
                <span className="strong">Quick Start:</span>
                <span className="light">
                  Automatically Link tasks to Github by using the task ID
                </span>
              </div>
              <button
                className="close-area"
                onClick={handleHideTxt}
                type="button"
              >
                <img src={closeIcon} alt="s" />
              </button>
            </div>
          ) : null}
          {hasPermission(
            permissionConstants.permissions
              .TASK_DETAILS_MODAL_GITHUB_REPOSITORY,
          ) && (
            <div className="inp-block form-group alert-cover mb-0 ms-0">
              <Select
                isLoading={isLoadingProjectRepos}
                classNamePrefix="react-select"
                className="react-select-container"
                id=""
                value={commonSl?.value}
                onChange={(selectdata) => {
                  setBranches([]);
                  setSelectedFile("");
                  setSelectedFolderPath("");
                  setLocalContents([]);
                  const selectedValue = selectdata;
                  const selectedRepo = options?.find(
                    (repo) => repo?.value === selectedValue?.value,
                  );
                  if (selectedRepo) {
                    setcommonSl({
                      value: selectedRepo?.value,
                      owner: selectedRepo?.owner,
                    });
                  }
                }}
                options={projectId ? options : []}
                placeholder={commonSl?.value || "Select Repository"}
              />
            </div>
          )}
          <button
            type="button"
            className="close-btn ms-0"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setBranches([]);
              setSelectedFile("");
              setSearchValue("");
              setDecodedContent("");
              setGitModalOpen(false);
              setSelectedFolderPath("");
            }}
          >
            <img src={modalClose} alt="" />
          </button>
        </div>
      </div>

      <div className="modal-body custom-scroll">
        <div className="integration-body">
          <div className="integration-top">
            {hasPermission(
              permissionConstants.permissions
                .TASK_DETAILS_MODAL_GITHUB_REASONABLE_BRANCH_NAME,
            ) && (
              <div className="integration-item">
                <div className="form-area">
                  <label className="lable-area">Reasonable Branch Name</label>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-id">
                        `{taskId}_{taskName}_{authData?.employeeName}`
                      </Tooltip>
                    }
                  >
                    <div className="input-area">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`${taskId}_${taskName}_${modifyEmployeeName(
                          authData?.employeeName,
                        )}`}
                      />
                      {hasPermission(
                        permissionConstants.permissions
                          .TASK_DETAILS_MODAL_GITHUB_COPY_REASONABLE_BRANCH_NAME,
                      ) && (
                        <button
                          type="button"
                          className="copy-area"
                          onClick={() => {
                            const copyText = ` '${taskId}_${taskName}_${modifyEmployeeName(
                              authData?.employeeName,
                            )}'`;
                            navigator.clipboard.writeText(copyText);
                          }}
                        >
                          <img src={copyIcon} alt="" />
                        </button>
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            )}
            {hasPermission(
              permissionConstants.permissions
                .TASK_DETAILS_MODAL_GITHUB_CHECKOUT_COMMAND,
            ) && (
              <div className="integration-item">
                <div className="form-area">
                  <label className="lable-area">
                    Create & Checkout A New Branch:
                  </label>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-id">
                        `git checkout -b {taskId}
                        {taskName}
                        {authData?.employeeName}
                        :`
                      </Tooltip>
                    }
                  >
                    <div className="input-area">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`git checkout -b  ${taskId}_${taskName}_${modifyEmployeeName(
                          authData.employeeName,
                        )}`}
                      />
                      {hasPermission(
                        permissionConstants.permissions
                          .TASK_DETAILS_MODAL_GITHUB_COPY_CHECKOUT_COMMAND,
                      ) && (
                        <button
                          type="button"
                          className="copy-area"
                          onClick={() => {
                            const copyText = `git checkout -b '${taskId}_${taskName}_${modifyEmployeeName(
                              authData.employeeName,
                            )}'`;
                            navigator.clipboard.writeText(copyText);
                          }}
                        >
                          <img src={copyIcon} alt="" />
                        </button>
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            )}
            {hasPermission(
              permissionConstants.permissions
                .TASK_DETAILS_MODAL_GITHUB_COMMIT_COMMAND,
            ) && (
              <div className="integration-item">
                <div className="form-area">
                  <label className="lable-area">Link A Single Comment</label>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-id">
                        `git checkout -m {taskId}
                        {taskName}
                        {authData?.employeeName}
                        :`
                      </Tooltip>
                    }
                  >
                    <div className="input-area">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`git commit -m ${taskId}_${taskName}_${modifyEmployeeName(
                          authData?.employeeName,
                        )}`}
                      />
                      {hasPermission(
                        permissionConstants.permissions
                          .TASK_DETAILS_MODAL_GITHUB_COPY_COMMIT_COMMAND,
                      ) && (
                        <button
                          type="button"
                          className="copy-area"
                          onClick={() => {
                            const copyText = `git commit -m '${taskId}_${taskName}_${modifyEmployeeName(
                              authData.employeeName,
                            )}'`;
                            navigator.clipboard.writeText(copyText);
                          }}
                        >
                          <img src={copyIcon} alt="" />
                        </button>
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            )}
            <div className="integration-item btn-view">
              <div className="btn-area">
                {hasPermission(
                  permissionConstants.permissions
                    .TASK_DETAILS_MODAL_GITHUB_CREATE_BRANCH,
                ) && (
                  <button
                    type="button"
                    className="btn btn-branch"
                    onClick={() => openCreateBr()}
                  >
                    Create Branch
                  </button>
                )}
                {hasPermission(
                  permissionConstants.permissions
                    .TASK_DETAILS_MODAL_GITHUB_NEW_PULL_REQUEST,
                ) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => openCreatePrModal()}
                  >
                    New Pull Request
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="integration-tab-area">
            <ul className="nav nav-tabs" role="tablist">
              {hasPermission(
                permissionConstants.permissions
                  .TASK_DETAILS_MODAL_GITHUB_FILE_MANAGER,
              ) && (
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "file" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#file"
                    onClick={() => handleTabChange("file")}
                  >
                    File Manager
                  </a>
                </li>
              )}
              {hasPermission(
                permissionConstants.permissions
                  .TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS,
              ) && (
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "pull" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#pull"
                    onClick={() => handleTabChange("pull")}
                  >
                    Pull Requests
                    <span className="badge">
                      {commonSl !== "" ? dataFromPr : 0}
                    </span>
                  </a>
                </li>
              )}
              {hasPermission(
                permissionConstants.permissions
                  .TASK_DETAILS_MODAL_GITHUB_BRANCHES,
              ) && (
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "branches" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#branches"
                    onClick={() => handleTabChange("branches")}
                  >
                    Branches{" "}
                    <span className="badge">
                      {commonSl !== "" ? branchCount : 0}
                    </span>
                  </a>
                </li>
              )}
              {hasPermission(
                permissionConstants.permissions
                  .TASK_DETAILS_MODAL_GITHUB_COMMITS,
              ) && (
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "commits" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#commits"
                    onClick={() => handleTabChange("commits")}
                  >
                    Commits{" "}
                    <span className="badge">
                      {commonSl !== "" ? dataFromCommit : 0}
                    </span>
                  </a>
                </li>
              )}
              {(hasPermission(
                permissionConstants.permissions
                  .TASK_DETAILS_MODAL_GITHUB_PULL_REQUESTS,
              ) ||
                hasPermission(
                  permissionConstants.permissions
                    .TASK_DETAILS_MODAL_GITHUB_BRANCHES,
                ) ||
                hasPermission(
                  permissionConstants.permissions
                    .TASK_DETAILS_MODAL_GITHUB_COMMITS,
                )) &&
                searchableTabs.includes(activeTab) && (
                  <div className="git-searchWrp">
                    <Form className="">
                      <FormControl
                        placeholder="Search..."
                        type="text"
                        className="form-control"
                        value={tablesearchValue}
                        onChange={(e) => settablesearchValue(e.target.value)}
                      />
                    </Form>
                  </div>
                )}
            </ul>

            {/* <!-- Tab panes --> */}
            <div className="tab-content">
              {hasPermission(
                permissionConstants.permissions
                  .TASK_DETAILS_MODAL_GITHUB_FILE_MANAGER,
              ) && (
                <div id="file" className="container-fluid tab-pane active ">
                  <FileManager
                    commonSl={commonSl}
                    setShowBlame={setShowBlame}
                    showBlame={showBlame}
                    hasPermission={hasPermission}
                    setBranches={setBranches}
                    branches={branches}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    setSelectedFolderPath={setSelectedFolderPath}
                    selectedFolderPath={selectedFolderPath}
                    setLocalContents={setLocalContents}
                    localContents={localContents}
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    setDecodedContent={setDecodedContent}
                    decodedContent={decodedContent}
                    getAllBranchsData={getAllBranchsData}
                    isLoadingBranches={isLoadingBranches}
                  />
                </div>
              )}
              <div id="pull" className="container-fluid tab-pane fade">
                <ListPr
                  commonSl={commonSl}
                  prCount={pullCount}
                  tablesearchValue={tablesearchValue}
                  hasPermission={hasPermission}
                  projectId={projectId}
                  ticket={ticket}
                />
              </div>

              <div id="branches" className="container-fluid tab-pane fade">
                <GitBranches
                  projectId={projectId}
                  commonSl={commonSl}
                  tablesearchValue={tablesearchValue}
                  hasPermission={hasPermission}
                  getAllBranchsData={getAllBranchsData}
                  ticket={ticket}
                  isLoadingBranches={isLoadingBranches}
                />
              </div>

              <div id="commits" className="container-fluid tab-pane fade">
                <Commits
                  commonSl={commonSl}
                  cmtCount={commitsCount}
                  tablesearchValue={tablesearchValue}
                  hasPermission={hasPermission}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <CustomModal
        className="modal fade git-details-modal show"
        dialgName="modal-dialog-scrollable"
        bodyClassname=""
        createModal={7}
        disableCenter={7}
        show={!!setGitModalOpen}
        closeModal={() => {
          setcommonSl("");
          setGitModalOpen(false);
          setSelectedFile("");
          // setSearchOptions([]);
        }}
        body={renderBody()}
      />
      {openCreateBranch ? (
        <CreateBranchModal
          setOpenCreateBranch={setOpenCreateBranch}
          format={`${taskId}_${taskName}_${modifyEmployeeName(
            authData?.employeeName,
          )}`}
          userRepositories={userRepositories}
          mainRepo={commonSl}
          projectId={projectId}
          ticket={ticket}
        />
      ) : null}
      {openCreatePr ? (
        <CreatePrModal
          setOpenCreatePr={setOpenCreatePr}
          projectId={projectId}
          commonSl={commonSl}
          ticket={ticket}
        />
      ) : null}
    </div>
  );
};
GitHubModal.propTypes = {
  showModal: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    docId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
GitHubModal.propTypes = {
  setGitModalOpen: PropTypes.func.isRequired,
  taskName: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  ticket: PropTypes.string.isRequired,
};
