/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import defFlagImg from "../../assets/images/flag-def.svg";
import orangeFlagImg from "../../assets/images/flag-orange.svg";
import blueFlagImg from "../../assets/images/flag-blue.svg";
import redFlagImg from "../../assets/images/flag-red.svg";
import outlineFlagImg from "../../assets/images/flagFilled.svg";

export const Priority = ({
  TaskData,
  type,
  dbName,
  patchFunction,
  icon,
  selectedTasks,
  dropDownVisibility,
  dropDownVisibilityPermissionConstant,
  needCount,
  tooltipPosition = "top",
}) => {
  const Priority = (title) => {
    let flagImg;
    switch (title) {
      case "Low":
        flagImg = (
          <img
            width="12px"
            style={{ border: "black", maxWidth: "12px" }}
            src={outlineFlagImg}
            alt=""
          />
        );
        break;
      case "Normal":
        flagImg = (
          <img
            style={{ border: "blue", maxWidth: "12px" }}
            src={blueFlagImg}
            alt=""
          />
        );
        break;
      case "High":
        flagImg = (
          <img
            style={{ border: "#ffcc00", maxWidth: "12px" }}
            src={orangeFlagImg}
            alt=""
          />
        );
        break;
      case "Urgent":
        flagImg = (
          <img
            style={{ border: "#f50000", maxWidth: "12px" }}
            src={redFlagImg}
            alt=""
          />
        );
        break;
      default:
        flagImg = (
          <img
            style={{ border: "rgb(216 216 216)", maxWidth: "12px" }}
            src={defFlagImg}
            alt=""
          />
        );
    }
    return flagImg;
  };

  const onclickfunction = (id) => {
    if (selectedTasks) {
      patchFunction(type, {
        id: selectedTasks?.map((e) => e._id),
        patchData: { [dbName]: id },
        needCount,
      });
    } else {
      patchFunction(type, {
        id: TaskData._id,
        patchData: { [dbName]: id },
        needCount,
      });
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <OverlayTrigger
      placement={tooltipPosition}
      overlay={<Tooltip id={TaskData?._id}>Set Priority</Tooltip>}
    >
      <span
        ref={ref}
        onClick={(e) => {
          if (dropDownVisibility && dropDownVisibilityPermissionConstant) {
            e.preventDefault();
            onClick(e);
          }
        }}
      >
        {children}
      </span>
    </OverlayTrigger>
  ));

  const CustomMenu = React.forwardRef(({ children, ...props }, ref) => (
    <div ref={ref} {...props}>
      {children}
    </div>
  ));

  const { ticketPriority } = useSelector((s) => s.common);

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        <button type="button" className="tool content-inr info-icon-01">
          {TaskData[dbName] ? (
            ticketPriority?.map(({ _id, title }) =>
              _id === TaskData[dbName] ? Priority(title) : null,
            )
          ) : selectedTasks ? (
            icon
          ) : (
            <img
              style={{ border: "black", maxWidth: "12px" }}
              src={icon || outlineFlagImg}
              alt=""
            />
          )}
        </button>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} className="dropdown-menu">
        {ticketPriority?.map(({ _id, title }) => (
          <div
            onClick={() => onclickfunction(_id)}
            key={title}
            className="d-flex drp-items p-2"
            style={{ fontSize: "14px", cursor: "pointer" }}
          >
            {Priority(title)}
            <div className="ms-3"> {title}</div>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

Priority.propTypes = {
  // TaskData: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["Task", "SubTask"]).isRequired,
  dbName: PropTypes.string.isRequired,
  patchFunction: PropTypes.func.isRequired,
  icon: PropTypes.node,
  // selectedTasks: PropTypes.array,
  dropDownVisibility: PropTypes.bool,
  dropDownVisibilityPermissionConstant: PropTypes.bool,
};
