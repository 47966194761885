import Gateway from "../gateway/gateway";

// get ticket Status

const getTicketStatus = () => Gateway.get("/status");

// get priority

const getTicketPriority = () => Gateway.get("/priority");

// get all users

const getAllUsers = () => Gateway.get("/users");

export default { getTicketStatus, getTicketPriority, getAllUsers };
