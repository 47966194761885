import { useState } from "react";
import OauthPopup from "react-oauth-popup";
import { useSelector } from "react-redux";
import { getGitAccessToken } from "../../../../store/slices/gitIntegration/thunks/getGitAccessToken";
import { ManageGitModal } from "./GitHub/manageGitModal";
import { DisconectModal } from "./GitHub/disconectModal";
// import { getUserProfile } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import integrationImg from "../../../../assets/images/integration.svg";
import gitImg from "../../../../assets/images/git.svg";
import installImg from "../../../../assets/images/install.svg";
import syncImg from "../../../../assets/images/sync.svg";
import sync_activitysImg from "../../../../assets/images/sync_activity.svg";
import createImg from "../../../../assets/images/create.svg";
import clockifyImg from "../../../../assets/images/clockify.svg";
import google_calendarImg from "../../../../assets/images/google_calendar.svg";
import figmaImg from "../../../../assets/images/figma.svg";
import "../../../../design/custom/pages/integrations.scss";
import "../../../../design/custom/pages/integrations-modal.scss";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../../utils/permissionUtils";
import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";

export const Integrations = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDisconectModal, setShowDisconectModal] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [getAccessToken, isLoading] = useThunk(getGitAccessToken);
  // const [getUserDetails] = useThunk(getUserProfile);
  const { userProfile } = useSelector((state) => state.userProfile);
  console.log("userProfile", userProfile?.gitStatus);
  const { gitSuccess } = useSelector((state) => state.github);

  console.log("gitSuccess", gitSuccess);

  const githubClientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_GITHUB_REDIRECT_URI;
  const scope = "repo read:user";

  const onCode = (code) => {
    console.log("code", code);
    getAccessToken(code);
    // setTimeout(() => {
    //   getUserDetails();
    // }, 1500);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.SETTINGS,
        permissionConstants.submodules.INTEGRATIONS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  return (
    <>
      <div className="page-container">
        <div className="page-contents integrations">
          <div className="integrations-wrp">
            <h3 className="titles d-flex align-items-center">
              <span className="img">
                <img src={integrationImg} alt="integration" />
              </span>
              <span className="txt">Integrations</span>
            </h3>

            <div className="integrating-itm">
              <div className="integ-header">
                <div className="itm-img">
                  <img src={gitImg} alt="integration items" />
                </div>
                <div className="itm-title">
                  <h3 className="integ-title">GitHub</h3>
                  <p className="integ-desc">
                    Boost project visibility with GitHub integration.
                  </p>
                </div>
                <div className="collapseToggle">
                  {gitSuccess === true ? (
                    <div className="isConnected">Connected</div>
                  ) : (
                    ""
                  )}
                  <button
                    className="btn btn-collapseToggle"
                    onClick={toggleCollapse}
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M112 184l144 144 144-144"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className={`integ-collapsed ${collapsed ? "collapse" : ""}`}>
                <div className="integ-features">
                  <div className="integ-feat-ico">
                    <img src={installImg} alt="" />
                  </div>
                  <div className="integ-feat-txt">
                    <h4 className="integ-feat-title">Install GitHub</h4>
                    <p className="integ-feat-desc">
                      Boost project visibility with GitHub integration.
                    </p>
                  </div>

                  <div className="integ-feat-actns d-flex">
                    {gitSuccess === false ? (
                      <OauthPopup
                        onClose={() => console.log("close")}
                        onCode={onCode}
                        height={500}
                        url={`https://github.com/login/oauth/authorize?client_id=${githubClientId}&redirect_uri=${redirectUri}&scope=${scope}`}
                      >
                        <button className="btn btn-primary" type="button">
                          {isLoading ? "Loading.." : "Connect"}
                        </button>
                      </OauthPopup>
                    ) : (
                      <>
                        <button
                          className="btn btn-outline"
                          type="button"
                          onClick={() => setShowDisconectModal(true)}
                        >
                          Disconnect
                        </button>
                        {hasPermission(
                          permissionConstants.permissions.GITHUB_REPO_CONNECT,
                        ) && (
                          <button
                            className="btn btn-outline ms-1"
                            type="button"
                            onClick={() => setShowModal(true)}
                          >
                            Manage
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <h3 className="itm-subtitle">Features</h3>
                <div className="integ-features">
                  <div className="integ-feat-ico">
                    <img src={syncImg} alt="" />
                  </div>
                  <div className="integ-feat-txt">
                    <h4 className="integ-feat-title">Sync Activity to Tasks</h4>
                    <p className="integ-feat-desc">
                      Your entire team will always know where projects stand at
                      a glance.
                    </p>
                  </div>
                </div>

                <div className="integ-features">
                  <div className="integ-feat-ico">
                    <img src={sync_activitysImg} alt="" />
                  </div>
                  <div className="integ-feat-txt">
                    <h4 className="integ-feat-title">Sync Activity to Tasks</h4>
                    <p className="integ-feat-desc">
                      Change the status of your ClickUp tasks directly from
                      GitHub.
                    </p>
                  </div>
                </div>

                <div className="integ-features">
                  <div className="integ-feat-ico">
                    <img src={createImg} alt="" />
                  </div>
                  <div className="integ-feat-txt">
                    <h4 className="integ-feat-title">
                      Create Branches and PRs from Tasks
                    </h4>
                    <p className="integ-feat-desc">
                      Kick off your dev workflow directly from a task.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="integrating-itm">
              <div className="integ-header">
                <div className="itm-img">
                  <img src={clockifyImg} alt="integration items" />
                </div>

                <div className="itm-title">
                  <h3 className="integ-title">Clockify</h3>
                  <p className="integ-desc">
                    Track time on ClickUp, run reports in Clockify.
                  </p>
                </div>

                <div className="collapseToggle">
                  {/* <div className="isConnected">Connected</div> */}
                  <button className="btn btn-collapseToggle" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M112 184l144 144 144-144"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="integ-collapsed collapse">
                <div className="integ-features">
                  <div className="integ-feat-ico">
                    <img src={installImg} alt="" />
                  </div>
                  <div className="integ-feat-txt">
                    <h4 className="integ-feat-title">Feature Heading</h4>
                    <p className="integ-feat-desc">Feature Descreption</p>
                  </div>

                  <div className="integ-feat-actns">
                    <button className="btn btn-primary" type="button">
                      Connect
                    </button>
                    <button className="btn btn-outline" type="button">
                      Manage
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="integrating-itm">
              <div className="integ-header">
                <div className="itm-img">
                  <img src={figmaImg} alt="integration items" />
                </div>

                <div className="itm-title">
                  <h3 className="integ-title">Figma</h3>
                  <p className="integ-desc">
                    Streamline your design workflow inside ClickUp.
                  </p>
                </div>

                <div className="collapseToggle">
                  {/* <div className="isConnected">Connected</div> */}
                  <button className="btn btn-collapseToggle" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M112 184l144 144 144-144"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="integ-collapsed collapse">
                <div className="integ-features">
                  <div className="integ-feat-ico">
                    <img src={installImg} alt="" />
                  </div>
                  <div className="integ-feat-txt">
                    <h4 className="integ-feat-title">Feature Heading</h4>
                    <p className="integ-feat-desc">Feature Descreption</p>
                  </div>

                  <div className="integ-feat-actns">
                    <button className="btn btn-primary" type="button">
                      Connect
                    </button>
                    <button className="btn btn-outline" type="button">
                      Manage
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="integrating-itm">
              <div className="integ-header">
                <div className="itm-img">
                  <img src={google_calendarImg} alt="integration items" />
                </div>

                <div className="itm-title">
                  <h3 className="integ-title">Google Calendar</h3>
                  <p className="integ-desc">
                    Praesent eu dolor eu orci vehicula euismod. Vivamus.
                  </p>
                </div>

                <div className="collapseToggle">
                  {/* <div className="isConnected">Connected</div> */}
                  <button className="btn btn-collapseToggle" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M112 184l144 144 144-144"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="integ-collapsed collapse">
                <div className="integ-features">
                  <div className="integ-feat-ico">
                    <img src={installImg} alt="" />
                  </div>
                  <div className="integ-feat-txt">
                    <h4 className="integ-feat-title">Feature Heading</h4>
                    <p className="integ-feat-desc">Feature Descreption</p>
                  </div>

                  <div className="integ-feat-actns">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setShowModal(true)}
                    >
                      Connect
                    </button>
                    <button className="btn btn-outline" type="button">
                      Manage
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ManageGitModal
        showModal={showModal}
        setShowModal={setShowModal}
        gitStatus={userProfile?.gitStatus}
        hasPermission={hasPermission}
      />
      <DisconectModal
        showModal={showDisconectModal}
        setShowModal={setShowDisconectModal}
      />
    </>
  );
};
