import { useState, useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import threeDotIcon from "../../assets/images/three-dots.svg";

const Threedots = ({
  TaskData,
  ActionButtons,
  dropDownVisibility,
  type,
  fullTaskData,
  className,
  needCount,
}) => {
  const [state, setState] = useState(null);

  const dropdownRef = useRef(null);

  const OnThreeDotsToggle = (id) => {
    if (state === id) setState(null);
    else setState(id);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setState(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={TaskData?._id}>More Options</Tooltip>}
    >
      <div
        className={className}
        ref={dropdownRef}
        style={{ zIndex: state === TaskData?._id ? 2 : 1, cursor: "pointer" }}
        onClick={
          dropDownVisibility ? () => OnThreeDotsToggle(TaskData?._id) : null
        }
      >
        <img
          onClick={
            dropDownVisibility ? () => OnThreeDotsToggle(TaskData?._id) : null
          }
          style={{ cursor: "pointer" }}
          src={threeDotIcon}
          alt="Three Dots"
        />
        <ul
          className="dropdown-menu del-drop"
          style={{
            display: `${state === TaskData?._id ? "block" : "none"}`,
            minWidth: "180px",
            right: "0px",
          }}
          aria-labelledby="dropdownMenuButton1"
        >
          {ActionButtons?.map(({ name, onclickfunction, icon, className }) => (
            <li
              onClick={() =>
                onclickfunction(
                  TaskData._id,
                  type,
                  TaskData,
                  fullTaskData,
                  needCount,
                )
              }
            >
              <button
                type="button"
                style={{
                  flex: 1,
                  fontSize: "14px",
                  lineHeight: "18px",
                  marginBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                // href=" # "
                className={`${className} dropdown-item `}
              >
                <img className="me-1" width="16px" src={icon} alt="" />
                {name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </OverlayTrigger>
  );
};
Threedots.propTypes = {
  TaskData: PropTypes.isRequired,
  ActionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onclickfunction: PropTypes.func.isRequired,
      icon: PropTypes.string.isRequired,
      className: PropTypes.string,
    }),
  ).isRequired,
  dropDownVisibility: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  fullTaskData: PropTypes.isRequired,
  className: PropTypes.string,
  needCount: PropTypes.bool,
};
export default Threedots;
