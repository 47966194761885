import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import CalendarMain from "../../../components/Calender";
import { useThunk } from "../../../hooks/useThunk";
import { getReportTaskAction } from "../../../store/slices/report/thunks/getTask";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import { useFilter } from "../../../context/useFilter";
import { filterQuery } from "./filter";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

const Calendar = () => {
  const { ticketStatus } = useSelector((state) => state.ticketReducer);
  const { reportTask } = useSelector((state) => state.report);
  const { leaveData } = useSelector((state) => state.leaveReducer);
  const [fetchTicketThunk, isLoading] = useThunk(getReportTaskAction);
  const [filterData] = useThunk(getFilterDropdown);
  const { filter } = useFilter();
  const completeStatus = ticketStatus?.status?.find(
    (status) => status.title === "COMPLETE",
  );

  const [params, setParams] = useState([]);
  const { userProfile } = useSelector((state) => state.userProfile);
  const completeStatusId = completeStatus ? completeStatus._id : null;
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.REPORTS,
        permissionConstants.submodules.REPORTS_CALENDER,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  useEffect(() => {
    filterData("calendar");
  }, []);
  const matches = filterQuery(filter.filter);
  const stateUpdate = useCallback(
    matches && matches[Object.keys(matches)[0]].map(({ value }) => value),
    [filter.filter],
  );
  useEffect(() => {
    setParams({ ...params, matches });
  }, [stateUpdate]);

  useEffect(() => {
    let updatedParams = { ...params };
    if (filter.me) updatedParams = { ...updatedParams, me: filter.me };
    else if (updatedParams.me) {
      const { me, ...rest } = updatedParams;
      updatedParams = rest;
    }
    if (filter.search)
      updatedParams = { ...updatedParams, title: filter.search };
    else if (updatedParams.title) {
      const { title, ...rest } = updatedParams;
      updatedParams = rest;
    }

    if (filter.assignee)
      updatedParams = { ...updatedParams, assignee: filter.assignee };
    else if (updatedParams.assignee) {
      const { assignee, ...rest } = updatedParams;
      updatedParams = rest;
    }
    setParams(updatedParams);
  }, [filter.search, filter.me, filter.assignee]);
  function convertToISOString(inputDateString) {
    if (inputDateString) {
      const [month, year] = inputDateString.split(" ");

      if (!month || !year) {
        throw new Error("Invalid input date string");
      }

      const startOfMonth = new Date(`${month} 1, ${year}`);

      // Set to the last day of the current month
      const endOfMonth = new Date(
        startOfMonth.getFullYear(),
        startOfMonth.getMonth() + 1,
        0,
      );

      return {
        start: startOfMonth.toISOString(),
        end: endOfMonth.toISOString(),
      };
    }
  }
  const [calendarFilters, setCalendarFilters] = useState("");
  const [startDate, setStartDate] = useState("");
  useEffect(() => {
    setStartDate(convertToISOString(calendarFilters?.label));
    setParams({
      ...params,
      startDate: startDate?.start,
      endDate: startDate?.end,
    });
  }, [calendarFilters?.label]);
  useEffect(() => {
    if (completeStatusId) {
      fetchTicketThunk({
        status: completeStatusId,
        ...params,
        startDate: startDate?.start,
        endDate: startDate?.end,
      });
    }
  }, [params, completeStatusId]);

  const passFilter = (onNavigate, label) => ({ onNavigate, label });

  const calendarFilter = (onNavigate, label) => {
    if (calendarFilters?.label !== label) {
      setCalendarFilters(passFilter(onNavigate, label));
    }
  };

  const individualTasks = [];

  reportTask?.forEach((task) => {
    individualTasks.push({
      ...task,
      ticketStatus: task.ticketStatus?._id,
    });
  });
  return (
    <div>
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.REPORTS_CALENDER_SEARCH,
        )}
        showFilter={hasPermission(
          permissionConstants.permissions.REPORTS_CALENDER_CUSTOM_FILTER,
        )}
        showMe
        showCalendar
        passFilter={calendarFilters}
      />
      <CalendarMain
        tickets={individualTasks}
        passFilter={calendarFilter}
        holidaysDetail={leaveData?.map((holiday) => holiday.leave_date)}
        isLoading={isLoading}
        hasPermission={hasPermission}
      />
    </div>
  );
};
export default Calendar;
