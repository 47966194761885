import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import allocationServices from "../../../../services/allocationList";
import { allocationActions } from "../index";
import { getAllocationLists } from "./getAllocationList";

export const updateAllocationLists = createAsyncThunk(
  "update-allocation",
  async function ({ updatedData, params }, { dispatch }) {
    console.log("dddaaaa", params);
    dispatch(alertActions.clear());
    try {
      const {
        data: { data, message: successMessage },
      } = await allocationServices.allocationListsUpdate(updatedData);
      console.log("nanan", successMessage);
      dispatch(alertActions.success(successMessage));
      dispatch(getAllocationLists(params));
      dispatch(allocationActions.setAllocationData(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(allocationActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
