import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import Button from "./Button/index";
import SortIcon from "../assets/images/sort.svg";
import LanguageContext from "../context/LanguageContext";
import SkeletonRows from "./SkeletonLoader/SkeletonRows";
import "../design/custom/components/tablePagenation.scss";

const CustomTable = ({
  tableClassName = "",
  divClassName = "",
  columns = [],
  data = [],
  pagination: { currentPage, limit = 10 },
  onPageChange,
  count,
  showPagination,
  isLoading,
  tableName = "",
  showtableName,
  onSortChange,
  showInput,
  handlePageNumberChange,
  onLimitChange,
}) => {
  const {
    languageData: { customTable },
  } = useContext(LanguageContext);

  const totalPages = count ? Math.ceil(count / limit) : 0;

  const pageNumbersToShow = [...Array(totalPages).keys()]
    .map((i) => 1 + i)
    .filter(
      (__, index) =>
        (index === 1 ||
          index === totalPages ||
          Math.abs(currentPage - index) < 3 ||
          index === currentPage) === true,
    );

  const renderPagination = () => {
    const onPrevClicked = () => {
      if (currentPage === 1) {
        return;
      }
      onPageChange(currentPage - 1);
    };
    const onNextClicked = () => {
      if (currentPage === totalPages) {
        return;
      }
      onPageChange(currentPage + 1);
    };
    const onPageNumberClick = (index) => {
      if (currentPage === index) {
        return;
      }
      onPageChange(index);
    };

    // const renderPageStart = () => {
    //   if (currentPage === 1) return 1;
    //   return limit * (currentPage - 1) + 1;
    // };
    const renderPageEnd = () => {
      if (isLoading) return " ";
      if (limit * currentPage > count) return count;
      return limit * currentPage;
    };
    const renderFirstPage = () => {
      if (currentPage > 1) {
        onPageChange(1);
      }
    };
    const renderLastPage = () => {
      if (currentPage < totalPages) {
        onPageChange(totalPages);
      }
    };
    if (!data?.length && !isLoading) return null;
    return (
      <div className="table-pagination-cover d-flex">
        {!!count && (
          <div className="pagination-entries">
            <span>Show</span>
            <span>
              <Dropdown>
                <Dropdown.Toggle>{renderPageEnd()}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => onLimitChange && onLimitChange(10)}
                  >
                    10
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onLimitChange && onLimitChange(25)}
                  >
                    25
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onLimitChange && onLimitChange(50)}
                  >
                    50
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onLimitChange && onLimitChange(75)}
                  >
                    75
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onLimitChange && onLimitChange(100)}
                  >
                    100
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
            <span>entries</span>
            {showInput ? (
              <div className="page-search">
                Go to
                <input
                  className="form-control"
                  type="number"
                  // value={currentPage ?? ""}
                  onChange={handlePageNumberChange}
                />
                {`of ${totalPages}`}
              </div>
            ) : null}
          </div>
        )}
        <div className="pagination-nav-block">
          <nav className="pagination-nav" aria-label="Page navigation">
            <ul className="pagination">
              {currentPage !== 1 ? (
                <>
                  <li className="page-item">
                    <a
                      className="page-link-btn prev"
                      href="##"
                      onClick={() => renderFirstPage()}
                    >
                      <span className="first">
                        <svg
                          id="Group_69465"
                          data-name="Group 69465"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.645"
                          height="15.645"
                          viewBox="0 0 20.645 15.645"
                        >
                          <g
                            id="vuesax_linear_arrow-right"
                            data-name="vuesax/linear/arrow-right"
                            transform="translate(5)"
                          >
                            <g id="arrow-right">
                              <path
                                id="Vector"
                                d="M0,0,4.25,4.25a1.294,1.294,0,0,1,0,1.825L0,10.326"
                                transform="translate(5.808 2.66)"
                                fill="none"
                                stroke="currentcolor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.8"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,15.645H15.645V0H0Z"
                                transform="translate(15.645 15.645) rotate(180)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                          <g
                            id="vuesax_linear_arrow-right-2"
                            data-name="vuesax/linear/arrow-right"
                            transform="translate(0)"
                          >
                            <g id="arrow-right-2" data-name="arrow-right">
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M0,0,4.25,4.25a1.294,1.294,0,0,1,0,1.825L0,10.326"
                                transform="translate(5.808 2.66)"
                                fill="none"
                                stroke="currentcolor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.8"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,15.645H15.645V0H0Z"
                                transform="translate(15.645 15.645) rotate(180)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className="page-link-btn prev"
                      href="##"
                      onClick={() => {
                        if (currentPage !== 1) onPrevClicked();
                      }}
                    >
                      <span className="prev">
                        <svg
                          id="vuesax_linear_arrow-right"
                          data-name="vuesax/linear/arrow-right"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.646"
                          height="15.645"
                          viewBox="0 0 15.646 15.645"
                        >
                          <g id="arrow-right" transform="translate(0)">
                            <path
                              id="Vector"
                              d="M0,0,4.25,4.25a1.294,1.294,0,0,1,0,1.825L0,10.326"
                              transform="translate(5.808 2.66)"
                              fill="none"
                              stroke="currentcolor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.8"
                            />
                            <path
                              id="Vector-2"
                              data-name="Vector"
                              d="M0,15.645H15.646V0H0Z"
                              transform="translate(15.646 15.645) rotate(180)"
                              fill="none"
                              opacity="0"
                            />
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                </>
              ) : null}

              {pageNumbersToShow.map((num, index) => (
                <React.Fragment key={`pg${num}`}>
                  {index === 0 && num > 1 && (
                    <li className="page-item">
                      <a
                        className={`page-link ${
                          currentPage === 1 ? "active" : ""
                        }`}
                        href="##"
                        onClick={() => onPageNumberClick(1)}
                      >
                        1
                      </a>
                    </li>
                  )}
                  {index > 0 && num > pageNumbersToShow[index - 1] + 1 && (
                    <span className="item-dots">...</span>
                  )}
                  <li className="page-item">
                    <a
                      className={`page-link ${
                        num === currentPage ? "active" : ""
                      }`}
                      href="##"
                      onClick={() => onPageNumberClick(num)}
                    >
                      {num}
                    </a>
                  </li>
                </React.Fragment>
              ))}

              {currentPage !== totalPages ? (
                <>
                  <li className="page-item">
                    <a
                      className="page-link-btn next"
                      href="##"
                      onClick={() => {
                        if (currentPage !== totalPages) onNextClicked();
                      }}
                    >
                      <span className="next">
                        <svg
                          id="vuesax_linear_arrow-right"
                          data-name="vuesax/linear/arrow-right"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.646"
                          height="15.645"
                          viewBox="0 0 15.646 15.645"
                        >
                          <g id="arrow-right" transform="translate(0)">
                            <path
                              id="Vector"
                              d="M0,0,4.25,4.25a1.294,1.294,0,0,1,0,1.825L0,10.326"
                              transform="translate(5.808 2.66)"
                              fill="none"
                              stroke="currentcolor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.8"
                            />
                            <path
                              id="Vector-2"
                              data-name="Vector"
                              d="M0,15.645H15.646V0H0Z"
                              transform="translate(15.646 15.645) rotate(180)"
                              fill="none"
                              opacity="0"
                            />
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className="page-link-btn next"
                      href="##"
                      onClick={() => renderLastPage()}
                    >
                      <span className="last">
                        <svg
                          id="Group_69465"
                          data-name="Group 69465"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.645"
                          height="15.645"
                          viewBox="0 0 20.645 15.645"
                        >
                          <g
                            id="vuesax_linear_arrow-right"
                            data-name="vuesax/linear/arrow-right"
                            transform="translate(5)"
                          >
                            <g id="arrow-right">
                              <path
                                id="Vector"
                                d="M0,0,4.25,4.25a1.294,1.294,0,0,1,0,1.825L0,10.326"
                                transform="translate(5.808 2.66)"
                                fill="none"
                                stroke="currentcolor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.8"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,15.645H15.645V0H0Z"
                                transform="translate(15.645 15.645) rotate(180)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                          <g
                            id="vuesax_linear_arrow-right-2"
                            data-name="vuesax/linear/arrow-right"
                            transform="translate(0)"
                          >
                            <g id="arrow-right-2" data-name="arrow-right">
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M0,0,4.25,4.25a1.294,1.294,0,0,1,0,1.825L0,10.326"
                                transform="translate(5.808 2.66)"
                                fill="none"
                                stroke="currentcolor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.8"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,15.645H15.645V0H0Z"
                                transform="translate(15.645 15.645) rotate(180)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                </>
              ) : null}
            </ul>
          </nav>
        </div>
      </div>
    );
  };
  return (
    <>
      {showtableName && (
        <div className="page-title">
          <h2>{tableName}</h2>
        </div>
      )}
      <div className={`table-responsive custom-scroll ${divClassName}`}>
        <table className={`table custom-table ${tableClassName}`}>
          <thead>
            <tr>
              {columns?.map(
                (
                  {
                    name,
                    icon,
                    titleClasses = "",
                    thProps,
                    selector,
                    activitySort,
                  },
                  index,
                ) => (
                  <th
                    scope="col"
                    className={titleClasses}
                    key={`head${name}${index}`}
                    {...thProps}
                  >
                    <div className={activitySort ? "tbl-hd" : "sort-th"}>
                      <span className="tableHd">{name}</span>
                      {Boolean(icon) && (
                        <Button
                          type="button"
                          className="btn table-sort"
                          onClick={() => onSortChange(selector)}
                        >
                          <img src={SortIcon} alt="TIcket" />
                        </Button>
                      )}
                    </div>
                  </th>
                ),
              )}
            </tr>
          </thead>
          {isLoading ? (
            <SkeletonRows columns={columns} limit={limit} />
          ) : (
            <tbody>
              {data?.length ? (
                data?.map((row, index) => (
                  <tr key={`row-${index + 1}`}>
                    {columns?.map(
                      (
                        {
                          selector,
                          cell,
                          colClassName = "",
                          contentClass = "",
                        },
                        position,
                      ) =>
                        cell ? (
                          <td
                            className={colClassName}
                            key={`function-cell-${position + 1}`}
                          >
                            {contentClass ? (
                              <div className={contentClass}>
                                {cell({ row, index })}
                              </div>
                            ) : (
                              cell({ row, index })
                            )}
                          </td>
                        ) : (
                          <td
                            className={colClassName}
                            key={`cell-${position + 1}`}
                          >
                            {contentClass ? (
                              <div className={contentClass}>
                                {row[selector]}
                              </div>
                            ) : (
                              row[selector]
                            )}
                          </td>
                        ),
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns?.length} className="text-center">
                    {customTable.noData}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {showPagination ? renderPagination() : null}
    </>
  );
};
CustomTable.propTypes = {
  tableClassName: PropTypes.string,
  divClassName: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      titleClasses: PropTypes.string,
      thProps: PropTypes.objectOf(PropTypes.string),
      selector: PropTypes.string,
      cell: PropTypes.func,
      colClassName: PropTypes.string,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  theme: PropTypes.string,
  isLoading: PropTypes.bool,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    limit: PropTypes.number,
  }),
  onPageChange: PropTypes.func,
  count: PropTypes.number,
  setLimit: PropTypes.node,
  showPagination: PropTypes.bool,
  tableName: PropTypes.string,
  showtableName: PropTypes.bool,
  onSortChange: PropTypes.func,
  sortOrder: PropTypes.string,
  showInput: PropTypes.bool,
  handlePageNumberChange: PropTypes.func,
  onLimitChange: PropTypes.func,
};

export default CustomTable;
