import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import roleServices from "../../../../services/roleService";
import { roleActions } from "..";
import { alertActions } from "../../alert/index";

export const getRoleList = createAsyncThunk(
  "list-Role",
  async ({ page, limit, sortOrder, sortBy, search }, { dispatch }) => {
    dispatch(alertActions.clear());
    console.log("dany", search);
    try {
      const {
        data: { data },
      } = await roleServices.getRoleList({
        page,
        limit,
        sortOrder,
        sortBy,
        search,
      });

      dispatch(roleActions.setRoleList({ data }));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
