import { useState, useEffect } from "react";

function useMainScrollPosition(className) {
  const [scrollPosition, setScrollPosition] = useState(null);

  const handleScroll = (e) => {
    const { scrollHeight, clientHeight, scrollTop } = e.target;
    setScrollPosition(scrollHeight - (scrollTop + clientHeight));
  };

  useEffect(() => {
    const divElements = document.querySelector(`.${className}`);
    if (divElements) {
      const mainElement = divElements.querySelector("main");
      mainElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (divElements) {
        const mainElement = divElements.querySelector("main");
        mainElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [className]);

  return scrollPosition;
}

export default useMainScrollPosition;
