import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import notificationIconServices from "../../../../../services/notificationIconServices";
import { alertActions } from "../../../alert";
import { getNotificationtList } from "./getNotificationDetails";

export const deleteNotification = createAsyncThunk(
  "delete-notification",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await notificationIconServices.deleteNotification(id);

      dispatch(alertActions.success(successMessage));
      dispatch(getNotificationtList());
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
