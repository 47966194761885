import Gateway from "../gateway/gateway";

const addTodoList = (data) => Gateway.post("/todo_list/list", data);
const addTodo = (data) =>
  Gateway.post("/todo_list", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

const detailsTodoData = (id) => Gateway.get(`/todo_list/${id}`);
const getTodoList = (params) => Gateway.get("/todo_list/list", params);
const getTodoCount = () => Gateway.get("/todo_list/counts");
const getTags = () => Gateway.get("/todo_list/tags");
const getTodoData = (params) => {
  let endpoint = "/todo_list";
  if (params.activeTab) {
    endpoint += `?task=${params.activeTab}`;
    if (params.params) {
      endpoint += `&search=${params.params}`;
    }
  } else if (params.params) {
    endpoint += `?search=${params.params}`;
  } else {
    endpoint += `?task=${params}`;
  }
  if (params.ListID) {
    endpoint += `&listId=${params.ListID}`;
  }
  return Gateway.get(endpoint);
};
const getColors = () => Gateway.get("/projectIcons");
const completeTodo = (id) => Gateway.get(`/technology/department/${id}`);

const editList = (id, data) => Gateway.patch(`/todo_list/list/${id}`, data);
const editTodo = (id, data) =>
  Gateway.patch(`/todo_list/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

const deleteToDo = (id) => Gateway.delete(`/todo_list/${id}`);
const deleteTags = (id) => Gateway.delete(`/todo_list/tags/${id}`);
const deleteToDoList = (id) => Gateway.delete(`/todo_list/list/${id}`);
const deleteAttachments = (id, attachmentId) =>
  Gateway.delete(`/todo_list/${id}/attachment/${attachmentId}`);

export default {
  completeTodo,
  editTodo,
  deleteToDoList,
  addTodo,
  getTodoList,
  getTodoData,
  getTodoCount,
  addTodoList,
  getColors,
  editList,
  detailsTodoData,
  deleteToDo,
  deleteTags,
  getTags,
  deleteAttachments,
};
