import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { Form, Field } from "react-final-form";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import "../../design/custom/components/calenderDatepicker.scss";
import {
  isWithinInterval,
  isSameDay,
  format,
  isSaturday,
  isSunday,
} from "date-fns";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "../../design/custom/pages/sprint.scss";
import "react-datepicker/dist/react-datepicker.css";
import sprintImage from "../../assets/images/sprint-date.svg";
import calendar from "../../assets/images/calendar-alt.svg";
import CustomModal from "../../components/CustomModal";
import { sprintCreate } from "../../store";
import { useThunk } from "../../hooks/useThunk";

const CreateSprint = ({
  showModal,
  setShowModal,
  selectedProject,
  backBtn,
  shortCode,
  // setProject,
}) => {
  const [createSprint, isCreate] = useThunk(sprintCreate);
  const { sprintData, holidaysDetail, successMessage } = useSelector(
    (state) => state.sprint,
  );
  const navigate = useNavigate();

  const [datepicker, setDatepicker] = useState(false);
  const [endDatePicker, setEndDatePicker] = useState(false);

  const sprintDurationOptions = [
    { value: "1", label: "1 Week" },
    { value: "2", label: "2 Weeks" },
    { value: "3", label: "3 Weeks" },
    { value: "custom", label: "Custom Range" },
  ];

  const [selectedValues, setSelectedValues] = useState({
    startDate: "",
    sprintDuration: sprintDurationOptions[0],
    endDate: "",
  });

  const [isCustomRange, setIsCustomRange] = useState(false);
  const [customEndDate, setCustomEndDate] = useState(null);
  useEffect(() => {
    if (successMessage) {
      console.log("successMessage", successMessage);
      setShowModal(false);
    }
  }, [successMessage]);
  useEffect(() => {
    NextValidWorkingDay();
  }, [sprintData]);

  useEffect(() => {
    calculateEndDate(
      selectedValues.startDate,
      selectedValues.sprintDuration.value,
    );
  }, [selectedValues.startDate, selectedValues.sprintDuration]);

  const blockedDateRanges =
    sprintData?.TotalSprint?.map((range) => ({
      start: new Date(range.startDate),
      end: new Date(range.endDate),
    })) || [];
  const holidays =
    holidaysDetail?.map((holiday) => new Date(holiday.leave_date)) || [];

  const lastBlockedDate = blockedDateRanges.reduce(
    (maxEndDate, range) =>
      maxEndDate ? new Date(Math.max(maxEndDate, range.end)) : range.end,
    null,
  );

  const NextValidWorkingDay = () => {
    let nextDayDate = lastBlockedDate
      ? new Date(lastBlockedDate.getTime() + 24 * 60 * 60 * 1000)
      : new Date();

    if (nextDayDate < new Date()) {
      nextDayDate = new Date();
    }

    const adjustedNextDayDate = new Date(nextDayDate);

    while (
      adjustedNextDayDate.getDay() === 0 ||
      adjustedNextDayDate.getDay() === 6
    ) {
      adjustedNextDayDate.setDate(adjustedNextDayDate.getDate() + 1);
    }

    holidays.forEach((holiday) => {
      while (
        adjustedNextDayDate.getDay() === 0 ||
        adjustedNextDayDate.getDay() === 6 ||
        isSameDay(adjustedNextDayDate, holiday)
      ) {
        adjustedNextDayDate.setDate(adjustedNextDayDate.getDate() + 1);
      }
    });
    setSelectedValues((prevValues) => ({
      ...prevValues,
      startDate: adjustedNextDayDate,
    }));
  };

  const isBlocked = (date) =>
    blockedDateRanges.some(({ start, end }) =>
      isWithinInterval(date, { start, end }),
    ) ||
    (lastBlockedDate && date <= lastBlockedDate) ||
    holidays.some((holiday) => isSameDay(date, holiday));

  const calculateEndDate = (startDate, sprintDuration) => {
    const oneDay = 24 * 60 * 60 * 1000;
    if (sprintDuration === "custom") {
      sprintDuration = 1;
    }
    if (selectedValues.startDate && selectedValues.sprintDuration) {
      const startDateObject = new Date(startDate);
      const currentDate = new Date(startDateObject);

      const holidaysToCheck = holidays.map((holiday) =>
        new Date(holiday).toDateString(),
      );
      const weekdaysToSkip = [0, 6];
      let i = 1;
      const iterate = () => {
        if (i < sprintDuration * 5) {
          currentDate.setTime(currentDate.getTime() + oneDay);
          if (
            weekdaysToSkip.includes(currentDate.getDay()) ||
            holidaysToCheck.includes(currentDate.toDateString())
          ) {
            iterate();
          } else {
            i += 1;
            iterate();
          }
        }
      };
      iterate();
      const minEndDate = new Date(startDateObject);
      let remainingWorkingDays = 5;

      while (remainingWorkingDays > 0) {
        minEndDate.setTime(minEndDate.getTime() + oneDay);
        if (
          minEndDate.getDay() !== 0 &&
          minEndDate.getDay() !== 6 &&
          !holidaysToCheck.includes(minEndDate.toDateString())
        ) {
          remainingWorkingDays -= 1;
        }
      }

      setCustomEndDate(currentDate);
      setSelectedValues((prevValues) => ({
        ...prevValues,
        endDate: minEndDate,
      }));
    }
  };
  const [numericValue, setNumericValue] = useState(1);

  const handleInputChange = (values) => {
    setNumericValue(values.floatValue);
  };
  const currentDate = new Date();
  const maxDate = new Date(currentDate.getFullYear() + 10, 0, 1);
  const renderBody = () => (
    <Form
      onSubmit={(formValues) => {
        let numberOfDays;
        if (selectedValues.sprintDuration.value === "custom") {
          let workingDays = 0;
          const currentDate = new Date(selectedValues.startDate);
          while (currentDate <= customEndDate) {
            if (currentDate.getDay() >= 1 && currentDate.getDay() <= 5) {
              const isHoliday = holidays.some((holiday) =>
                isSameDay(currentDate, holiday),
              );
              if (!isHoliday) {
                workingDays += 1;
              }
            }
            currentDate.setDate(currentDate.getDate() + 1);
          }
          numberOfDays = workingDays;
        } else if (selectedValues.sprintDuration.value === "1") {
          numberOfDays = 5;
        } else if (selectedValues.sprintDuration.value === "2") {
          numberOfDays = 10;
        } else if (selectedValues.sprintDuration.value === "3") {
          numberOfDays = 15;
        }
        const updatedFormValues = {
          ...formValues,
          startDate: selectedValues.startDate,
          sprintDuration: numberOfDays,
          project: selectedProject,
        };
        setSelectedValues({
          startDate: "",
          sprintDuration: sprintDurationOptions[0],
          endDate: "",
        });
        createSprint({
          payload: updatedFormValues,
          navigate: !backBtn
            ? (id) => navigate(`/project/${shortCode}/sprint/${id}`)
            : undefined,
        });
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div
            className="create-sprint-modal-body custom-scroll"
            onClick={() => {
              setDatepicker(false);
            }}
          >
            {backBtn && (
              <button
                className="btn back-btn btn"
                type="button"
                onClick={() => setShowModal(false)}
              >
                <span className="btn-icon">
                  <svg
                    id="arrow-right"
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.156"
                    height="13.156"
                    viewBox="0 0 13.156 13.156"
                  >
                    <path
                      id="Vector"
                      d="M0,8.683,3.574,5.109a1.088,1.088,0,0,0,0-1.535L0,0"
                      transform="translate(4.884 2.236)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M0,0H13.156V13.156H0Z"
                      transform="translate(13.156 13.156) rotate(180)"
                      fill="none"
                      opacity="0"
                    />
                  </svg>
                </span>
                back
              </button>
            )}
            <div className="modal-body-title custom-scroll">
              <h4>Create Sprint</h4>
            </div>
            <div className="form-cover">
              <div className="inp-block alert-cover position-relative top-100">
                <label className="form-label">
                  Select start date for your sprint
                </label>
                <div className="datepicker-wrapper">
                  <div
                    className="datepicker-wrapper"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDatepicker(true);
                    }}
                  >
                    <Field
                      name="startDate"
                      validate={(value) => {
                        return value || value === undefined
                          ? undefined
                          : "Start date is required";
                      }}
                    >
                      {({ input, meta }) => (
                        <>
                          <DatePicker
                            className="form-control"
                            selected={selectedValues.startDate}
                            onChange={(date, event) => {
                              event.stopPropagation();
                              input.onChange(date);
                              setSelectedValues((prevValues) => ({
                                ...prevValues,
                                startDate: date,
                              }));
                              setDatepicker(false);
                            }}
                            filterDate={(date) =>
                              !isBlocked(date) &&
                              !isSaturday(date) &&
                              !isSunday(date)
                            }
                            dateFormat="dd/MM/yy"
                            maxDate={maxDate}
                            minDate={new Date()}
                            open={datepicker}
                            readOnly
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />

                          {meta.error && meta.touched && (
                            <span className="text-danger position-absolute">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="calenderIco">
                    <img
                      src={calendar}
                      alt=""
                      onClick={(e) => {
                        e.stopPropagation();
                        setDatepicker(true);
                      }}
                    />
                  </div>
                </div>
                <div className="inp-block form-group alert-cover position-relative top-100">
                  <label className="form-label">
                    Select duration for your sprint
                  </label>
                  <Field
                    name="sprintDuration"
                    validate={() => {
                      return selectedValues.sprintDuration
                        ? undefined
                        : "Sprint duration is required";
                    }}
                  >
                    {({ input, meta }) => (
                      <div className="select-duration">
                        <Select
                          classNamePrefix="react-select"
                          className="react-select-container"
                          options={sprintDurationOptions}
                          {...input}
                          value={selectedValues.sprintDuration}
                          onChange={(selectedOption) => {
                            input.onChange(selectedOption);
                            setSelectedValues({
                              ...selectedValues,
                              sprintDuration: selectedOption,
                            });

                            setIsCustomRange(selectedOption.value === "custom");
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span className="text-danger position-absolute">
                            {meta.error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                {isCustomRange && (
                  <div onClick={() => setEndDatePicker(true)}>
                    <label className="form-label">
                      Select custom end date for your first sprint
                    </label>
                    <div className="datepicker-wrapper">
                      <Field name="endDate">
                        {({ input, meta }) => (
                          <>
                            <DatePicker
                              className="form-control"
                              selected={customEndDate}
                              onChange={(date, event) => {
                                event.stopPropagation();
                                input.onChange(date);
                                setCustomEndDate(date);
                                setEndDatePicker(false);
                              }}
                              onClickOutside={() => setEndDatePicker(false)}
                              filterDate={(date) =>
                                !isBlocked(date) &&
                                !isSaturday(date) &&
                                !isSunday(date)
                              }
                              minDate={new Date(customEndDate)}
                              maxDate={new Date(maxDate)}
                              dateFormat="dd/MM/yy"
                              open={endDatePicker}
                              readOnly
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                            {meta.error && meta.touched && (
                              <span className="text-danger position-absolute">
                                {meta.error}
                              </span>
                            )}
                          </>
                        )}
                      </Field>
                      <div className="calenderIco">
                        <img
                          src={calendar}
                          alt=""
                          onClick={(e) => {
                            e.stopPropagation();
                            setDatepicker(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="sprint-last-date d-flex">
                  <img src={sprintImage} alt="sprint" />
                  <span>
                    Your first sprint will end on{" "}
                    {customEndDate && format(customEndDate, "dd/MM/yyyy")}
                  </span>
                </div>
                <div className="inp-block inp-num-block alert-cover">
                  <label className="form-label">
                    How many sprints do you want to create?
                  </label>
                  <Field
                    name="numberOfSprints"
                    initialValue={1}
                    validate={(value) =>
                      value > 0 && value <= 10
                        ? undefined
                        : "Value must be between 0 to 10"
                    }
                  >
                    {({ input, meta }) => (
                      <div className="inp-block-sm position-relative top-100">
                        {/* <input
                          type="number"
                          className="form-control"
                          min={1}
                          max={10}
                          onInvalid={(e) => e.preventDefault()}
                          {...input}
                          onChange={input.onChange}
                        /> */}
                        <NumericFormat
                          className="form-control"
                          allowNegative={false}
                          onChange={handleInputChange}
                          value={numericValue} // Set the value from state
                          isAllowed={(values) => {
                            if (!values.value) return true;
                            const { floatValue } = values;
                            return floatValue <= 10;
                          }}
                          {...input}
                        />
                        {meta.error && meta.touched && (
                          <span className="text-danger position-absolute">
                            {meta.error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="btn-block">
                  <button
                    className="modal-btn btn-cancel"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                    disabled={isCreate}
                  >
                    <span>Cancel</span>
                  </button>

                  <button className="modal-btn btn-create ms-2" type="submit">
                    {isCreate ? (
                      <span className="btn-loader">
                        <span className="spinner-border spinner-border-sm" />
                      </span>
                    ) : (
                      <span> Create Sprint</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );

  return (
    <CustomModal
      className="modal fade modal-default create-sprint-modal"
      dialgName="modal-lg  modal-dialog-scrollable "
      contentClassName="border-0"
      bodyClassname="custom-scroll"
      createModal
      show={!!showModal}
      closeModal={() => {
        setShowModal(false);
      }}
      body={renderBody()}
      closeButton
    />
  );
};

CreateSprint.propTypes = {
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  selectedProject: PropTypes.string,
  shortCode: PropTypes.string,
  backBtn: PropTypes.bool,
};

export default CreateSprint;
