import { GoogleOAuthProvider } from "@react-oauth/google";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { siteConfig } from "../config";

function PublicRoutes() {
  const isLoggedIn = useSelector(({ auth: { isLoggedIn } }) => isLoggedIn);
  return (
    <Suspense fallback={<div />}>
      <GoogleOAuthProvider
        clientId={siteConfig.google_auth_creds.GOOGLE_CLIENT_ID}
      >
        {isLoggedIn ? (
          <Navigate to="/dashboard" />
        ) : (
          <div className="outer">
            <Outlet />
          </div>
        )}
      </GoogleOAuthProvider>
    </Suspense>
  );
}

export default PublicRoutes;
