import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import emailAccountServices from "../../../../../services/emailAccountServices";
import { alertActions } from "../../../alert";
import { getEmailAccountList } from "./getEmailAccountDetails";

export const updateEmailAccount = createAsyncThunk(
  "update-emailAccount",
  async ({ id, data }, { dispatch }) => {
    console.log("object", id, data);
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await emailAccountServices.updateEmailAccountList({
        id,
        data,
      });
      dispatch(getEmailAccountList());
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
