import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { patchFeedService } from "../../../../services/feedService";
import { setSuccessMessage, setPatchedFeedUserProfile } from "..";
import { savedFeedOfUser } from "./savedFeed";

export const patchFeed = createAsyncThunk(
  "patchFeed",
  async function ({ formData, isEdit, userId }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data, message: successMessage } = await patchFeedService(
        formData,
      );
      if (isEdit) {
        dispatch(alertActions.success(successMessage));
        dispatch(setSuccessMessage("success"));
      }
      dispatch(setPatchedFeedUserProfile(data));
      dispatch(savedFeedOfUser({ params: userId }));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
