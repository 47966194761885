import { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { PreviousIcons, NextIcons } from "../svgIcons";

const CustomToolbar = ({ label, onNavigate }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleMonthChange = (date) => {
    const selectedMonth = moment(date);
    onNavigate("DATE", selectedMonth);
  };

  const handleYearChange = (date) => {
    const selectedYear = moment(date).year();
    const selectedMonth = moment(label).month();
    const updatedDate = moment(label).year(selectedYear).month(selectedMonth);
    onNavigate("DATE", updatedDate);
  };

  const handleToolbarNavigate = (navType) => {
    onNavigate(navType, moment(label).toDate());
  };
  const customInput = (
    <div
      className="custom-datepicker-input"
      onClick={(e) => {
        setShowDatePicker((prevState) => !prevState);
        e.stopPropagation();
      }}
      type="button"
    >
      {moment(label).format("MMMM YYYY")}
    </div>
  );

  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        {["PREV", "TODAY", "NEXT"].map((navType, index) => (
          <button
            key={index}
            onClick={() => handleToolbarNavigate(navType)}
            type="button"
          >
            {navType === "PREV" && <PreviousIcons />}
            {navType === "TODAY" && "Today"}
            {navType === "NEXT" && <NextIcons />}
          </button>
        ))}
      </div>
      <div className="custom-toolbar-label">
        <div
          className="custom-toolbar-label"
          type="button"
          onClick={(e) => {
            setShowDatePicker((prevState) => !prevState);
            e.stopPropagation();
          }}
        >
          {moment(label).format("MMMM YYYY")}
        </div>
        {showDatePicker && (
          <div
            className="custom-toolbar-datepicker"
            style={{ position: "absolute", zIndex: 999 }}
          >
            <DatePicker
              selected={moment(label).toDate()}
              customInput={customInput}
              onChange={handleMonthChange}
              onYearChange={handleYearChange}
              onClickOutside={() => setShowDatePicker(!showDatePicker)}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              inline
            />
          </div>
        )}
      </div>
    </div>
  );
};

CustomToolbar.propTypes = {
  label: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default CustomToolbar;
