import { Link } from "react-router-dom";
import pageNotFoundImage from "../../assets/images/404.svg";
import "../../design/custom/pages/warning-page.scss";

const PageNotFound = () => {
  return (
    <div className="page-contents">
      <div className="warning-page-cover">
        <div className="warning-content-block text-center">
          <div className="text-center img-block">
            <img src={pageNotFoundImage} alt="" />
          </div>
          <h4>Page Not Found</h4>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
          <div className="btn-block text-center">
            <Link to="/dashboard" className="home-btn" type="button">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
