import Gateway from "../gateway/gateway";

const getTicketData = (params) => Gateway.get("/ticket", { params });

const patchTicketData = (id, patchData) =>
  Gateway.patch(`/ticket/${id}`, patchData);

const deleteTicketData = (id) => Gateway.delete(`/ticket/${id}`);

const postTicketData = (data) => Gateway.post("/ticket", data);

const fileUpload = (data) =>
  Gateway.post("/ticket/fileupload", data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

const getStatus = () => Gateway.get("/ticketStatus");

const patchTicketAllocation = (id, patchData) =>
  Gateway.patch(`/ticket-allocation/${id}`, patchData);

const deleteTicketDataAllocation = (id) =>
  Gateway.delete(`/ticket-allocation/${id}`);

// allocation roles of project members
const getAllocationrole = () => Gateway.get("/allocationrole");
const postUploadSheetTicketData = (data) =>
  Gateway.post("/ticket-allocation/uploadSheet", data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });
const getTicketDocs = (projectId, listId) =>
  Gateway.get(`/ticketDocs?projectId=${projectId}&&params=${listId}`);

const getTicketPriority = () => Gateway.get("/priority");

const getTicketDataById = (id, listId) =>
  Gateway.get(`/ticket/${id}`, { params: listId });
const patchTicketAtmData = (id, data) =>
  Gateway.patch(`/ticket/attachment/${id}`, data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });
const deleteTicketAtachment = (id, file) =>
  Gateway.patch(`/ticket/attachment/delete/${id}`, { file });

export default {
  getTicketData,
  patchTicketData,
  deleteTicketData,
  postTicketData,
  getStatus,
  patchTicketAllocation,
  deleteTicketDataAllocation,
  getAllocationrole,
  postUploadSheetTicketData,
  getTicketPriority,
  getTicketDocs,
  getTicketDataById,
  patchTicketAtmData,
  deleteTicketAtachment,
  fileUpload,
};
