// eslint-disable-next-line react/prop-types
import { useSelector } from "react-redux";
import defFlagImg from "../../assets/images/flag-def.svg";
import orangeFlagImg from "../../assets/images/flag-orange.svg";
import blueFlagImg from "../../assets/images/flag-blue.svg";
import redFlagImg from "../../assets/images/flag-red.svg";
import outlineFlagImg from "../../assets/images/priority.svg";

const TableHeaderGroupBySwither = ({ groupById, groupBy }) => {
  const { ticketStatus } = useSelector((s) => s.ticketReducer);
  const { ticketPriority } = useSelector((s) => s.common);
  const { Users } = useSelector((s) => s.ticketReducer);
  const users = Users?.map(({ user }) => user);
  let flagImg = outlineFlagImg;

  const Priority = (title) => {
    switch (title) {
      case "Low":
        flagImg = (
          <>
            <img
              style={{ border: "rgb(216 216 216)" }}
              src={defFlagImg}
              alt=""
            />
            &nbsp; {title}
          </>
        );

        break;
      case "Normal":
        flagImg = (
          <>
            <img style={{ border: "blue" }} src={blueFlagImg} alt="" />
            &nbsp; {title}
          </>
        );
        break;
      case "High":
        flagImg = (
          <>
            <img style={{ border: "#ffcc00" }} src={orangeFlagImg} alt="" />
            &nbsp; {title}
          </>
        );
        break;
      case "Urgent":
        flagImg = (
          <>
            <img style={{ border: "#f50000" }} src={redFlagImg} alt="" />
            &nbsp; {title}
          </>
        );
        break;
      default:
        flagImg = (
          <>
            <img
              width="13px"
              style={{ border: "black" }}
              src={outlineFlagImg}
              alt=""
            />
            &nbsp; {title}
          </>
        );
    }
    return flagImg;
  };

  let Header;
  const groupByHeader = (groupBy) => {
    switch (groupBy) {
      case "ticketStatus":
        Header = ticketStatus?.status?.map(
          ({ _id, title, colorInfo }) =>
            groupById === _id && (
              <button
                style={{
                  backgroundColor: colorInfo,
                  fontSize: "9.5px",
                }}
                className="tab_button ms-1 text-white "
                type="button"
              >
                {title}
              </button>
            ),
        );
        break;

      case "priority":
        Header = ticketPriority?.map(({ _id, title }) =>
          _id === groupById ? Priority(title) : null,
        );
        break;

      case "dueDate":
        Header = <div>{groupById?.slice(0, 10)}</div>;
        break;

      case "assignees":
        Header = users?.map(({ _id, employeeName }, index) =>
          _id === groupById ? (
            <>
              <div className={`avathar-sm avathar-0${(index % 4) + 1} m-0`}>
                <span>{employeeName?.slice(0, 2).toUpperCase()}</span>
              </div>
              &nbsp; {employeeName}
            </>
          ) : null,
        );

        break;

      default:
        Header = ticketStatus?.status?.map(
          ({ _id, title, colorInfo }) =>
            groupById === _id && (
              <button
                style={{
                  backgroundColor: colorInfo,
                  fontSize: "9.5px",
                }}
                className="tab_button ms-1 text-white "
                type="button"
              >
                {title}
              </button>
            ),
        );
    }
    return Header;
  };
  return groupByHeader(groupBy);
};

export default TableHeaderGroupBySwither;
