import { createAsyncThunk } from "@reduxjs/toolkit";

import { alertActions } from "../../alert/index";
import { gitRepos } from "../../../../services/gitHubService";
import { gitHubAction } from "..";

export const getGitRepos = createAsyncThunk(
  "github",
  async (params, { dispatch }) => {
    // dispatch(alertActions.clear());
    try {
      const { data } = await gitRepos();
      dispatch(gitHubAction.getRepos(data));
    } catch (error) {
      dispatch(alertActions.error("Access Denied to this repository"));
    }
  },
);
