import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Popup from "../../components/TaskStatusModal";
import "../../design/custom/pages/task-details-modal.scss";
import CustomModal from "../../components/CustomModal";
import minimize from "../../assets/images/minimize.svg";
import upload from "../../assets/images/upload-mod.svg";
import addFill from "../../assets/images/add-fill.svg";
import defaultFlag from "../../assets/images/flag-001.svg";
import calendar from "../../assets/images/calendar-alt.svg";
import "../../design/custom/components/calenderDatepicker.scss";
import {
  ArrowRightIcon,
  RightArrow,
  UserGroup,
  Estimate,
  AddBtn,
  Check,
  CheckListIcon,
  SubChecklistIcon,
  CopyIcon,
  NewTab,
  CopyId,
  ArrowRightLg,
  SetProgress,
} from "./svgicons";

import gitIcon from "../../assets/images/git.svg";
import user from "../../assets/images/user-mod.svg";
import flag from "../../assets/images/flag-red.svg";
import sandTime from "../../assets/images/sand_time.svg";
import sprint from "../../assets/images/sprint-point-star.svg";
import view from "../../assets/images/view-grn.svg";
import ham from "../../assets/images/ham.svg";
import attachment from "../../assets/images/attachment.svg";
import { setTicketAllocationDataById } from "../../store/slices/ticket";
import Imgflag from "../../assets/images/flagFilled.svg";
import ImgFlagOrange from "../../assets/images/flag-orange.svg";
import ImgFlagNormal from "../../assets/images/flag-blue.svg";
import threeDots from "../../assets/images/three-dots.svg";
import deleteIcon from "../../assets/images/delete-01.svg";
import {
  deleteTicketAllocation,
  deleteTickets,
  getTeam,
  getTicketAllocations,
  getTicketAllocationsById,
  getTicketsById,
  patchTicket,
  patchTicketAllocation,
  patchTicketAllocationAtm,
  patchTicketAttachment,
  postTicketAllocation,
} from "../../store";
import { GitHubModal } from "../UserSettings/components/Integrations/GitHub/gitHubModal";
import { useThunk } from "../../hooks/useThunk";
import Profile from "./userDetail";
import CheckList from "./checkList";
import FileAttachment from "./FileAttachment";
import DeleteModal from "./deleteModal";
import TimerComponent from "./timer";
import CommonSkeleton from "../../components/SkeletonLoader/CommonSkeleton";
import ChatSection from "./chat";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";

const TaskDetail = ({
  showModal,
  setShowModal,
  setSelectedTicket,
  selectedTicket,
  ticket,
  isTicket,
}) => {
  const { ticketAllocations, setTicketAllocationDatas } = useSelector(
    (state) => state.ticketReducer,
  );
  const navigate = useNavigate();
  const [TicketAllocationByIdGet, isGetTicketAllocation] = useThunk(
    getTicketAllocationsById,
  );
  const [updateTicketAllocationThunk, isLoadingTaskPatch] = useThunk(
    patchTicketAllocation,
  );
  const [patchTicketAttachments, isLoadingTicket] = useThunk(
    patchTicketAttachment,
  );
  const [TicketByIdGet, isGetTicket] = useThunk(getTicketsById);
  const [patchTicketAllocationAttachments, isLoadingTicketAllocation] =
    useThunk(patchTicketAllocationAtm);
  const [deleteTicket] = useThunk(deleteTickets);
  const [deleteTicketAllocations, isTaskDelete] = useThunk(
    deleteTicketAllocation,
  );
  const [TicketAllocationCreate, isTaskCreate] = useThunk(postTicketAllocation);
  const [updateTicketThunk, isTicketPatch] = useThunk(patchTicket);
  const [eventData, setEventData] = useState(null);
  const { ticketPriority } = useSelector((state) => state.common);
  const { ticketStatus } = useSelector((state) => state.ticketReducer);
  const { authData } = useSelector((state) => state.auth);
  const { userProfile } = useSelector((state) => state.userProfile);
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState("");
  const [statusModal, setStatusModal] = useState("");
  const [addUser, setAddUser] = useState(false);
  const { listId, shortCode, type } = useParams();
  const { projectData } = useSelector((state) => state.project);
  const [showCheckList, setChekList] = useState(false);
  const { ticketData } = useSelector(({ ticketReducer }) => ticketReducer);
  const [newSubTaskUserPopup, setNewSubtaskUserPoup] = useState(false);
  const [newSubTaskUser, setNewSubtaskUser] = useState(false);
  const [subtaskErrorMessage, setSubtaskErrorMessage] = useState("");
  const params = useLocation();
  const key = params.pathname.split("/")[5];

  const [newSubtaskTitle, setNewSubtaskTitle] = useState("");
  const [newSubtaskEstimation, setNewSubtaskEstimation] = useState("");
  const [showSubTaskField, setShowSubTaskField] = useState("");
  const [chosenUsers, setChosenUsers] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isGitHubModalOpen, setGitModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [team] = useThunk(getTeam);
  const [dropdown, setDropdown] = useState(false);
  const [startDatedatePicker, setStartDatedatePicker] = useState(false);
  const [StartDate, setStartDate] = useState("");
  const [endDatedatePicker, setEndDatedatePicker] = useState(false);
  const { allTechnologies } = useSelector((state) => state.technology);
  const [subTaskState, setSubTaskState] = useState("");
  const [BackToTicket, setBackToTicket] = useState("");
  const [isPriorityDropdownOpen, setIsPriorityDropdownOpen] = useState(false);
  const [selectedTechnology, setSelectedTechnology] = useState([]);
  const [copied, setCopied] = useState(false);
  const messagesRef = useRef(null);
  const [checked, setChecked] = useState("");
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.TASK_DETAILS_MODAL,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  const commonPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.COMMON_TICKET_SETTINGS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  useEffect(() => {
    let timeoutId;

    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [copied]);
  useEffect(() => {
    if (allTechnologies)
      setSelectedTechnology(
        allTechnologies.map((item) => ({
          value: item?.technology?.name,
          label: item?.technology?.name,
          isSelected: true,
          id: item?.technology?._id,
        })),
      );
  }, [allTechnologies]);

  const handleChosenUsersChange = (chosenUsers) => {
    setNewSubtaskUser(chosenUsers);
  };
  const setNewSubTaskUserPopup = (propsss) => {
    setNewSubtaskUserPoup(propsss);
  };
  const handleDeleteClick = (file) => {
    setFileToDelete(file);
    setDeleteModalOpen(true);
  };
  useEffect(() => {
    if (projectData)
      setProjectId(
        projectData.find((each) => each.shortCode === shortCode)?._id,
      );
  }, [projectData]);
  useEffect(() => {
    if (projectId)
      team({
        projectId,
      });
  }, [projectId]);
  const handleConfirmDelete = () => {
    if (ticket) {
      deleteTicket({
        id: setTicketAllocationDatas._id,
        listId:
          type === "sprint"
            ? { sprintId: listId, page: 1, limit: 20 }
            : { listId, page: 1, limit: 20 },
        key,
      });
    } else {
      deleteTicketAllocations({
        id: setTicketAllocationDatas._id,
        listId:
          type === "sprint"
            ? { sprintId: listId, page: 1, limit: 20 }
            : { listId, page: 1, limit: 20 },
        key,
      });
    }

    setDeleteModalOpen(false);
    setShowModal(false);
    setFileToDelete(null);
    navigate(window.location.pathname);
    setSelectedTicket("");
  };

  const handleCloseModal = () => {
    setDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const dispatch = useDispatch();
  function formatDate(dateString) {
    if (!dateString) return "";

    const options = {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    };

    const dateObject = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(dateObject);
  }

  function isDateInPast(dateObj) {
    if (!dateObj) return false;
    const currentDate = new Date();
    return new Date(dateObj) < currentDate;
  }
  useEffect(() => {
    const queryString = params.search;
    if (queryString) {
      const paramValue = queryString.startsWith("?TicketId");
      if (paramValue) {
        isTicket(true);
        const id = queryString?.split("=")[1];
        TicketByIdGet({
          params: id,
          listId: { listId, page: 1, limit: 20 },
        });
      } else {
        isTicket(false);
        const id = queryString?.split("=")[1];
        TicketAllocationByIdGet({
          params: id,
          listId: { listId, page: 1, limit: 20 },
        });
      }
    }
  }, []);
  const userCount = ticket
    ? [
        ...new Set(
          setTicketAllocationDatas?.allocations?.flatMap((allocation) =>
            allocation.assignedUsers?.map((assignedUser) => assignedUser.user),
          ),
        ),
      ]?.length
    : setTicketAllocationDatas?.assignedUsers?.length;

  useEffect(() => {
    if (eventData) {
      if (!ticket) {
        updateTicketAllocationThunk({
          id: setTicketAllocationDatas?._id,
          patchData: { ticketStatus: eventData },
        });
      } else {
        updateTicketThunk({
          id: setTicketAllocationDatas?._id,
          patchData: { ticketStatus: eventData },
        });
      }
    }
  }, [eventData]);
  useEffect(() => {
    if (setTicketAllocationDatas?.title) {
      setTitle(setTicketAllocationDatas.title);
    }
    if (setTicketAllocationDatas?.description) {
      setDescription(setTicketAllocationDatas.description);
    }
  }, [setTicketAllocationDatas?.title, setTicketAllocationDatas?.description]);

  const [listName, setListName] = useState(null);

  useEffect(() => {
    if (projectData) {
      const foundListName = findListName(projectData, listId);
      setListName(foundListName);
    }
  }, [projectData, listId]);
  function findListName(data, shortCode) {
    return data.reduce((result, item) => {
      if (result) return result;
      if (item.shortCode === shortCode) {
        return `${item.name} /`;
      }
      if (item.lists && item.lists.length > 0) {
        const listMatch = item.lists.find((list) => list._id === shortCode);
        if (listMatch) {
          return `${listMatch.name} /`;
        }
      }
      if (item.folders && item.folders.length > 0) {
        const matchingLists = item.folders.flatMap((folder) =>
          folder.list?.filter((list) => list._id === shortCode),
        );

        if (matchingLists.length > 0) {
          return `${matchingLists[0].name}`;
        }
      }
      if (item.sprint && item.sprint.length > 0) {
        const sprintMatch = item.sprint.find(
          (sprint) => sprint._id === shortCode,
        );
        if (sprintMatch) {
          return `${sprintMatch.name?.split("(")[0]}`;
        }
      }
      return null;
    }, null);
  }
  const getPriorityIcon = (priorityName) => {
    switch (priorityName) {
      case "Urgent":
        return <img src={flag} alt="Urgent" />;
      case "High":
        return <img src={ImgFlagOrange} alt="high" />;
      case "Normal":
        return <img src={ImgFlagNormal} alt="Normal" />;
      case "Low":
        return <img src={Imgflag} alt="Low" />;
      case "None":
        return <img src={defaultFlag} alt="None" />;
      default:
        return null;
    }
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const matchedStatus = ticketStatus?.status?.find(
    (status) => status._id === setTicketAllocationDatas?.ticketStatus,
  );

  const filteredPriorities = ticketPriority?.filter(
    (item) => item._id === setTicketAllocationDatas?.priority,
  );

  const eventStatusColor = matchedStatus ? matchedStatus.colorInfo : null;
  const completeStatus = ticketStatus?.status?.find(
    (e) => e.title === "COMPLETE",
  );
  const handlePrioritySelect = (priorityId) => {
    if (!ticket) {
      updateTicketAllocationThunk({
        id: setTicketAllocationDatas?._id,
        patchData: { priority: priorityId },
      });
    } else {
      updateTicketThunk({
        id: setTicketAllocationDatas?._id,
        patchData: { priority: priorityId },
      });
    }
  };
  const handleFieldEditFinish = (field) => {
    const patchData = { [field]: field === "title" ? title : description };

    if (!ticket) {
      updateTicketAllocationThunk({
        id: setTicketAllocationDatas?._id,
        patchData,
      });
    } else {
      updateTicketThunk({
        id: setTicketAllocationDatas?._id,
        patchData,
      });
    }
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [inputValue, setInputValue] = useState(null);
  const [fetchTicketThunk] = useThunk(getTicketAllocations);
  const validateFile = (file) => {
    const allowedExtensions = [
      ".pdf",
      ".docx",
      ".doc",
      ".xlsx",
      ".jpg",
      ".png",
      ".jpeg",
    ];
    const maxSizeInBytes = 10 * 1024 * 1024;

    if (!file) {
      return "No file selected.";
    }

    const fileType = file.name?.toLowerCase();
    const fileSize = file.size;

    if (fileSize > maxSizeInBytes) {
      return "File size exceeds the limit of 10 MB.";
    }

    if (
      !allowedExtensions.some((extension) =>
        fileType.endsWith(extension.toLowerCase()),
      )
    ) {
      return "Invalid file extension.";
    }

    return "";
  };
  const resetFields = () => {
    setNewSubtaskTitle("");
    setNewSubtaskEstimation("");
    setIsDropdownOpen(false);
    setInputValue("");
    setNewSubtaskUser([]);
    setChosenUsers([]);
    setSubtaskErrorMessage("");
  };
  const handleAddSubtask = (each) => {
    each.preventDefault();
    const estimationValue = parseInt(newSubtaskEstimation, 10);
    const todoStatus = ticketStatus?.status?.find((e) => e.title === "TODO");
    if (!newSubtaskTitle || newSubtaskEstimation.trim() === "") {
      setSubtaskErrorMessage(
        'Both "Title" and "Estimation" fields are required.',
      );
      return;
    }
    if (
      Number.isNaN(estimationValue) ||
      estimationValue < 1 ||
      estimationValue > 10
    ) {
      setSubtaskErrorMessage("Estimation must be a number between 1 and 10.");
      return;
    }
    if (newSubtaskTitle && newSubtaskEstimation) {
      let assignedUsers;
      if (newSubTaskUser.length > 0) {
        assignedUsers = newSubTaskUser?.map((user) => ({
          user: user.userId,
        }));
      }

      const newSubtask = {
        project: setTicketAllocationDatas?.project,
        title: newSubtaskTitle.value,
        estimationHours: newSubtaskEstimation,
        ticket: setTicketAllocationDatas._id,
        createdBy: authData._id,
        ticketStatus: todoStatus._id,
        assignedUsers,
        [type === "sprint" ? "sprint" : "list"]: listId,
      };
      TicketAllocationCreate({
        value: newSubtask,
        listId:
          type === "sprint"
            ? { sprintId: listId, page: 1, limit: 20 }
            : { listId, page: 1, limit: 20 },
        key,
      });
      resetFields();
    }
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    if (!fileInput.files) {
      return;
    }

    const fileList = Array.from(fileInput.files);

    if (fileList.length > 0) {
      const formData = new FormData();
      let validFiles = "";
      formData.append("type", "attachment");
      fileList.forEach((file) => {
        const validationError = validateFile(file);
        if (validationError !== "") {
          validFiles = validationError;
          return;
        }
        formData.append("files", file);
      });

      if (validFiles === "") {
        setErrorMessage("");
        if (ticket) {
          patchTicketAttachments({
            id: setTicketAllocationDatas._id,
            formData,
          });
        } else {
          patchTicketAllocationAttachments({
            id: setTicketAllocationDatas._id,
            formData,
          });
        }
        setSelectedFile(true);
      } else {
        setErrorMessage(validFiles);
        fileInput.value = null;
      }
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    }
  };
  const [hour, setHour] = useState(null);

  useEffect(() => {
    fetchTicketThunk({ params: listId });
  }, [listId]);
  const [selectedOption, setSelectedOption] = useState("");
  const [matchedArray, setMatchedArray] = useState([]);

  const handleInputChange = (e) => {
    if (/^[0-9]+$/.test(e.target.value)) {
      setInputValue(e.target.value);
      if (e.target.value) {
        const index = parseInt(e.target.value, 10);

        const maxIndex = matchedArray?.length;

        if (!Number.isNaN(index) && index >= 1 && index <= maxIndex) {
          const foundAllocation = matchedArray[index - 1];
          const foundAllocationId = foundAllocation?._id;

          if (foundAllocationId) {
            TicketAllocationByIdGet({
              params: foundAllocationId,
              listId: { listId, page: 1, limit: 20 },
            });
          }

          dispatch(setTicketAllocationDataById(foundAllocation));
        }
      } else {
        setInputValue("");
      }
    } else {
      setInputValue("");
    }
  };

  const handleAllocateUser = (data, allocatedId) => {
    if (!ticket) {
      const TicketData = {
        assignedUsers: [
          ...(setTicketAllocationDatas.assignedUsers || []).map(
            (assignedUser) => ({
              user: assignedUser._id,
            }),
          ),
          { user: data.user },
        ],
      };
      updateTicketAllocationThunk({
        id: setTicketAllocationDatas?._id,
        patchData: {
          assignedUsers: TicketData.assignedUsers,
        },
      });
    } else {
      const TicketData = {
        allocations: (setTicketAllocationDatas?.allocations || [])
          .map((allocation) => {
            if (allocation._id === allocatedId) {
              setSubTaskState(allocation._id);
              const newAssignedUser = {
                user: data.user,
              };
              return {
                _id: allocation._id,
                assignedUsers: [...allocation.assignedUsers, newAssignedUser],
                ticketStatus: allocation.ticketStatus,
                title: allocation.title,
                estimationHours: allocation.estimationHours,
              };
            }
            return {
              _id: allocation._id,
              assignedUsers: allocation.assignedUsers,
              ticketStatus: allocation.ticketStatus,
              title: allocation.title,
              estimationHours: allocation.estimationHours,
            };
          })
          .map(
            ({ _id, assignedUsers, ticketStatus, title, estimationHours }) => ({
              _id,
              assignedUsers,
              ticketStatus,
              title,
              estimationHours,
            }),
          )
          .filter((entry) => entry._id),
      };
      updateTicketThunk({
        id: setTicketAllocationDatas?._id,
        patchData: TicketData,
      });
    }
  };
  const handleDeallocateUser = (data, allocatedId) => {
    if (!ticket) {
      const TaskData = {
        ...setTicketAllocationDatas,
        assignedUsers: setTicketAllocationDatas.assignedUsers
          ?.filter((item) => item._id !== data.user)
          .map((assignedUser) => ({
            user: assignedUser._id,
          })),
      };
      updateTicketAllocationThunk({
        id: TaskData?._id,
        patchData: {
          assignedUsers: TaskData.assignedUsers,
        },
      });
    } else {
      const TicketData = {
        allocations: (setTicketAllocationDatas.allocations || [])
          .map((allocation) => {
            if (allocation._id === allocatedId) {
              setSubTaskState(allocation._id);
              const updatedAssignedUsers = allocation.assignedUsers.filter(
                (assignedUser) => {
                  return assignedUser.user !== data.user;
                },
              );

              return {
                _id: allocation._id,
                assignedUsers: updatedAssignedUsers,
                ticketStatus: allocation.ticketStatus,
                title: allocation.title,
                estimationHours: allocation.estimationHours,
              };
            }

            return {
              _id: allocation._id,
              assignedUsers: allocation.assignedUsers,
              ticketStatus: allocation.ticketStatus,
              title: allocation.title,
              estimationHours: allocation.estimationHours,
            };
          })
          .map(
            ({ _id, assignedUsers, ticketStatus, title, estimationHours }) => ({
              _id,
              assignedUsers,
              ticketStatus,
              title,
              estimationHours,
            }),
          ),
      };
      updateTicketThunk({
        id: setTicketAllocationDatas?._id,
        patchData: TicketData,
      });
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e);

    if (e === "subtask") {
      setShowSubTaskField(true);
      setChekList(false);
    } else if (e === "checklist") {
      setChekList(true);
      setShowSubTaskField(false);
    } else {
      setChekList(false);
    }
  };
  const [progressPercentage, setProgressPercentage] = useState(0);
  useEffect(() => {
    if (ticket && setTicketAllocationDatas) {
      let completedAllocations = 0;
      const totalAllocations = setTicketAllocationDatas?.allocations?.length;

      setTicketAllocationDatas?.allocations?.forEach((allocation) => {
        if (allocation.ticketStatus === completeStatus?._id) {
          completedAllocations += 1;
        }
      });

      const newProgressPercentage = Math.round(
        (completedAllocations / totalAllocations) * 100,
      );
      setProgressPercentage(Math.max(newProgressPercentage, 0));
    }
  }, [ticket, setTicketAllocationDatas]);

  useEffect(() => {
    if (ticket && setTicketAllocationDatas?.allocations) {
      const initialValue = setTicketAllocationDatas?.allocations?.reduce(
        (acc, allocation) => acc + allocation.estimationHours,
        0,
      );
      setHour(initialValue);
    } else {
      setHour(setTicketAllocationDatas?.estimationHours);
    }
  }, [ticket, setTicketAllocationDatas]);

  const handleInputChanges = (event) => {
    setHour(event.target.value);
    const TicketData = {
      estimationHours: event.target.value,
    };
    if (event.target.value !== "") {
      updateTicketAllocationThunk({
        id: setTicketAllocationDatas?._id,
        patchData: TicketData,
      });
    }
  };
  let priorityClass = "def-btn priority-btn";
  if (filteredPriorities[0]?.title === "Low") {
    priorityClass += " priority-low";
  } else if (filteredPriorities[0]?.title === "High") {
    priorityClass += " priority-high";
  } else if (filteredPriorities[0]?.title === "Urgent") {
    priorityClass += " priority-urgent";
  } else if (filteredPriorities[0]?.title === "Normal") {
    priorityClass += " priority-normal";
  }
  const renderBody = () => (
    <>
      <div
        className="task-modal-header-cover d-flex"
        onClick={() => setSubTaskState(false)}
      >
        <div className="task-modal-header-col task-modal-header-lft d-flex">
          <div className="h-inner-left d-flex align-items-center">
            {commonPermission(
              permissionConstants.permissions.COMMON_TICKET_LIST_STATUS,
            ) &&
              !ticket &&
              (isGetTicketAllocation ? (
                <CommonSkeleton width={150} />
              ) : (
                <>
                  <div className="task-status-block">
                    <div className="task-status-btn-cover">
                      <div
                        className="status-dropdown-block"
                        style={{ backgroundColor: `${eventStatusColor}` }}
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setStatusModal(setTicketAllocationDatas?._id);
                        }}
                      >
                        {matchedStatus?.title}
                      </div>

                      <div style={{ backgroundColor: `${eventStatusColor}` }}>
                        <button
                          className="status-ctrl-btn d-flex align-item-center h-100"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setStatusModal(setTicketAllocationDatas?._id);
                          }}
                        >
                          <RightArrow />
                        </button>
                        {commonPermission(
                          permissionConstants.permissions
                            .COMMON_TICKET_LIST_STATUS_EDIT,
                        ) && statusModal === setTicketAllocationDatas?._id ? (
                          <Popup
                            data={ticketStatus?.status}
                            updateTaskStatus={setEventData}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {commonPermission(
                    permissionConstants.permissions
                      .COMMON_TICKET_MARK_AS_COMPLETED,
                  ) && (
                    <button
                      className="task-complete-btn"
                      type="button"
                      onClick={() => {
                        setEventData(completeStatus?._id);
                      }}
                      style={{ backgroundColor: `${eventStatusColor}` }}
                    >
                      <Check />
                    </button>
                  )}
                </>
              ))}
            {commonPermission(
              permissionConstants.permissions.COMMON_TICKET_LIST_ASSIGNEE,
            ) &&
              (isGetTicketAllocation ? (
                <CommonSkeleton className="circle-skeleton" />
              ) : (
                !ticket && (
                  <div className="user-group d-flex">
                    <button
                      className="user-def add-user-btn"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddUser(true);
                        setIsPriorityDropdownOpen(false);
                      }}
                      disabled={
                        !commonPermission(
                          permissionConstants.permissions
                            .COMMON_TICKET_LIST_ASSIGNEE_EDIT,
                        )
                      }
                    >
                      <img src={user} alt="" />
                      <span className="add-icon">
                        <img src={addFill} alt="addFill" />
                      </span>
                    </button>
                    <Profile
                      TaskData={setTicketAllocationDatas}
                      dropDownVisibility
                      addUser={addUser}
                      setAddUser={setAddUser}
                      ticket={ticket}
                      setNewSubTaskUserPopup={setNewSubTaskUserPopup}
                      onAllocateUser={handleAllocateUser}
                      onDeallocateUser={handleDeallocateUser}
                    />
                  </div>
                )
              ))}

            {commonPermission(
              permissionConstants.permissions.COMMON_TICKET_LIST_PRIORITY,
            ) &&
              (isGetTicket || isGetTicketAllocation ? (
                <div className="circle-skeleton">
                  <CommonSkeleton />
                </div>
              ) : (
                <div className="info-items d-flex mt-0">
                  <Dropdown
                    show={isPriorityDropdownOpen}
                    onToggle={() =>
                      setIsPriorityDropdownOpen(!isPriorityDropdownOpen)
                    }
                  >
                    {commonPermission(
                      permissionConstants.permissions
                        .COMMON_TICKET_LIST_PRIORITY,
                    ) && (
                      <Dropdown.Toggle
                        id="info-toggless"
                        className={`def-btn priority-btn ${
                          filteredPriorities[0]?.title && priorityClass
                        }`}
                        disabled={
                          !commonPermission(
                            permissionConstants.permissions
                              .COMMON_TICKET_LIST_PRIORITY_EDIT,
                          )
                        }
                        variant="default"
                      >
                        {getPriorityIcon(
                          filteredPriorities[0]?.title
                            ? filteredPriorities[0]?.title
                            : "None",
                        )}
                      </Dropdown.Toggle>
                    )}
                    <Dropdown.Menu>
                      {ticketPriority.map((priority) => (
                        <Dropdown.Item
                          key={priority._id}
                          eventKey={priority._id}
                          onClick={() => handlePrioritySelect(priority._id)}
                        >
                          {getPriorityIcon(priority.title)} {priority.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ))}
          </div>

          {hasPermission(
            permissionConstants.permissions.TASK_DETAILS_MODAL_GITHUB_MODAL,
          ) &&
            (isGetTicket || isGetTicketAllocation ? (
              <div width={200}>
                <CommonSkeleton />
              </div>
            ) : (
              <div className="github_actions d-flex align-items-center">
                {userProfile?.gitStatus === true &&
                setTicketAllocationDatas?.project !== null &&
                setTicketAllocationDatas?.project !== undefined ? (
                  <div className="gitIco" onClick={() => onGitModalOpen()}>
                    <img src={gitIcon} alt="" style={{ height: "30px" }} />
                    <button
                      type="button"
                      className="btn btn-share"
                      style={{ marginLeft: "3px" }}
                    >
                      G764342786
                    </button>
                  </div>
                ) : null}
              </div>
            ))}
          {hasPermission(
            permissionConstants.permissions.TASK_DETAILS_MODAL_CUSTOMID,
          ) && (
            <div className="task-details-block custom-Id d-flex align-items-center">
              {isGetTicket || isGetTicketAllocation ? (
                <div className="timer-block">
                  <CommonSkeleton width={100} />
                </div>
              ) : (
                <div className="timer-block">
                  <div className="t-label">Custom ID</div>
                  <div className="t-det">
                    {ticket
                      ? setTicketAllocationDatas?.customId
                      : setTicketAllocationDatas?.customId}
                  </div>
                </div>
              )}
            </div>
          )}

          {isGetTicket || isGetTicketAllocation ? (
            <CommonSkeleton width={40} />
          ) : (
            hasPermission(
              permissionConstants.permissions.TASK_DETAILS_MODAL_MORE_OPTIONS,
            ) && (
              <div className="h-inner-right d-flex align-items-center">
                <div className="dropdown custom-drop-menu task-settings-dropdown drop-new">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="info-toggle"
                      className="def-btn share-btn border-0"
                      variant="default"
                    >
                      <img src={threeDots} alt="threeDots" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropeffect">
                      {hasPermission(
                        permissionConstants.permissions
                          .TASK_DETAILS_MODAL_COPY_LINK,
                      ) && (
                        <Dropdown.Item
                          className="dropdown-item d-flex"
                          onClick={(e) => {
                            navigator.clipboard.writeText(window.location.href);
                            setCopied("copy link");
                            e.stopPropagation();
                          }}
                        >
                          <div className="icon">
                            <CopyIcon />
                          </div>
                          <span>
                            {copied === "copy link" ? "Copied" : "Copy link"}
                          </span>
                        </Dropdown.Item>
                      )}
                      {hasPermission(
                        permissionConstants.permissions
                          .TASK_DETAILS_MODAL_COPY_ID,
                      ) && (
                        <Dropdown.Item
                          className="dropdown-item d-flex"
                          onClick={(e) => {
                            navigator.clipboard.writeText(
                              ticket
                                ? setTicketAllocationDatas?.customId
                                : setTicketAllocationDatas?.customId,
                            );
                            setCopied("copy id");
                            e.stopPropagation();
                          }}
                        >
                          <div className="icon">
                            <CopyId />
                          </div>
                          <span>
                            {copied === "copy id" ? "Copied" : "Copy ID"}
                          </span>
                        </Dropdown.Item>
                      )}
                      {hasPermission(
                        permissionConstants.permissions
                          .TASK_DETAILS_MODAL_NEW_TAB,
                      ) && (
                        <Dropdown.Item
                          className="dropdown-item d-flex"
                          onClick={() => {
                            if (ticket) {
                              window.open(
                                `${window.location.protocol}//${
                                  window.location.host
                                }/${shortCode}/${
                                  !key ? "list" : key
                                }/${listId}/Ticket/${
                                  setTicketAllocationDatas._id
                                }`,
                              );
                            } else {
                              window.open(
                                `${window.location.protocol}//${
                                  window.location.host
                                }/${shortCode}/${
                                  !key ? "list" : key
                                }/${listId}/Task/${
                                  setTicketAllocationDatas._id
                                }`,
                              );
                            }
                          }}
                        >
                          <div className="icon">
                            <NewTab projectId={projectId} />
                          </div>
                          <span>New Tab</span>
                        </Dropdown.Item>
                      )}
                      {commonPermission(
                        permissionConstants.permissions
                          .COMMON_TICKET_LIST_ACTIONS_DELETE,
                      ) && (
                        <Dropdown.Item
                          className="dropdown-item d-flex delete-task"
                          onClick={() => {
                            handleDeleteClick(setTicketAllocationDatas?.title);
                          }}
                        >
                          <div className="icon">
                            <img src={deleteIcon} alt="icon" />
                          </div>
                          <span>Delete</span>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <DeleteModal
                    name={fileToDelete?.name}
                    onDelete={handleConfirmDelete}
                    onCancel={handleCloseModal}
                    show={isDeleteModalOpen}
                  />
                </div>
              </div>
            )
          )}
        </div>

        <div className="task-modal-header-col task-modal-header-rgt d-flex align-items-center">
          <div className="task-details-block d-flex align-items-center">
            {hasPermission(
              permissionConstants.permissions.TASK_DETAILS_MODAL_CREATEDAT,
            ) &&
              (isGetTicket || isGetTicketAllocation ? (
                <div className="timer-block timer-b">
                  <CommonSkeleton width={100} />
                </div>
              ) : (
                <div className="timer-block timer-b">
                  <div className="t-label">CREATED</div>
                  <div className="t-det">
                    {formatDate(setTicketAllocationDatas?.createdAt)}
                  </div>
                </div>
              ))}
            {hasPermission(
              permissionConstants.permissions.TASK_DETAILS_MODAL_TIME_TRACKER,
            ) &&
              (isGetTicket || isGetTicketAllocation ? (
                <CommonSkeleton width={100} />
              ) : (
                <TimerComponent
                  task={setTicketAllocationDatas}
                  ticket={ticket}
                />
              ))}
            {isGetTicket || isGetTicketAllocation ? (
              <CommonSkeleton width={100} />
            ) : (
              hasPermission(
                permissionConstants.permissions
                  .TASK_DETAILS_MODAL_ESTIMATION_HOUR,
              ) && (
                <div className="timer-block">
                  <div className="t-label">ESTIMATE</div>
                  <div className="t-det d-flex align-items-center">
                    <span className="icon-block">
                      <img src={sandTime} alt="sandTime" />
                    </span>{" "}
                    <input
                      className="estimateInp"
                      type="text"
                      value={hour}
                      onChange={handleInputChanges}
                      disabled={ticket}
                      onKeyDown={(event) => {
                        const { key, target } = event;
                        const currentValue = target.value;
                        if (
                          !/^\d$/.test(key) &&
                          key !== "Backspace" &&
                          key !== "Delete"
                        ) {
                          event.preventDefault();
                        }
                        const proposedValue =
                          currentValue.slice(0, target.selectionStart) +
                          key +
                          currentValue.slice(target.selectionEnd);
                        if (proposedValue < 1 || proposedValue > 10) {
                          event.preventDefault();
                        }
                      }}
                    />
                    hr
                  </div>
                </div>
              )
            )}
            {isGetTicket || isGetTicketAllocation ? (
              <div className="circle-skeleton">
                <CommonSkeleton width={100} />
              </div>
            ) : (
              hasPermission(
                permissionConstants.permissions
                  .TASK_DETAILS_MODAL_ESTIMATION_HOUR,
              ) && (
                <div className="timer-block timer-block-points">
                  <div className="sprint-points-btn">
                    <img src={sprint} alt="sprint" />
                  </div>
                </div>
              )
            )}
            {commonPermission(
              permissionConstants.permissions.COMMON_TICKET_LIST_STARTDATE,
            ) &&
              (isGetTicket || isGetTicketAllocation ? (
                <CommonSkeleton width={100} />
              ) : (
                <div className="timer-block start-date-b">
                  <div className="t-label">START DATE</div>
                  <div
                    className="t-det text-center date"
                    onClick={() => {
                      setStartDatedatePicker(true);
                    }}
                    type="button"
                  >
                    {setTicketAllocationDatas?.startDate ? (
                      new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "2-digit",
                      })?.format(new Date(setTicketAllocationDatas?.startDate))
                    ) : (
                      <img
                        src={calendar}
                        alt=""
                        onClick={() => {
                          setStartDatedatePicker(true);
                        }}
                      />
                    )}
                    {commonPermission(
                      permissionConstants.permissions
                        .COMMON_TICKET_LIST_STARTDATE_EDIT,
                    ) && (
                      <ReactDatePicker
                        className="form-control date"
                        selected={
                          setTicketAllocationDatas?.startDate
                            ? new Date(setTicketAllocationDatas.startDate)
                            : null
                        }
                        onChange={(date, event) => {
                          const utcDate = moment(date).utc().toDate();
                          setStartDate(utcDate);

                          if (!ticket) {
                            updateTicketAllocationThunk({
                              id: setTicketAllocationDatas?._id,
                              patchData: { startDate: utcDate },
                            });
                          } else {
                            updateTicketThunk({
                              id: setTicketAllocationDatas?._id,
                              patchData: { startDate: utcDate },
                            });
                          }
                          event.stopPropagation();
                          setStartDatedatePicker(false);
                        }}
                        minDate={new Date()}
                        dateFormat="dd/MM/yy"
                        onClickOutside={() => setStartDatedatePicker(false)}
                        open={startDatedatePicker}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        filterDate={(date) => {
                          const dateMoment = moment(date);

                          if ([0, 6].includes(dateMoment.day())) {
                            return false;
                          }
                          if (
                            setTicketAllocationDatas.dueDate &&
                            dateMoment.isAfter(
                              setTicketAllocationDatas.dueDate,
                              "day",
                            )
                          ) {
                            return false;
                          }
                          return true;
                        }}
                        dayClassName={(date) => {
                          const yesterday = moment()
                            .subtract(1, "days")
                            .startOf("day");
                          const dateMoment = moment(date);

                          if (
                            dateMoment.isBefore(yesterday) ||
                            [0, 6].includes(dateMoment.day())
                          ) {
                            return "disabled-date";
                          }

                          return null;
                        }}
                      />
                    )}
                  </div>
                  <span className="arrow-st">
                    <ArrowRightLg />
                  </span>
                </div>
              ))}
            {commonPermission(
              permissionConstants.permissions.COMMON_TICKET_LIST_DUE_DATE,
            ) &&
              (isGetTicket || isGetTicketAllocation ? (
                <CommonSkeleton width={100} />
              ) : (
                <div className="timer-block">
                  <div className="t-label">DUE DATE</div>
                  <div
                    className={`t-det text-center date ${
                      isDateInPast(setTicketAllocationDatas?.dueDate)
                        ? "due-date"
                        : ""
                    }`}
                    onClick={() => {
                      setEndDatedatePicker(true);
                    }}
                    type="button"
                  >
                    {setTicketAllocationDatas?.dueDate ? (
                      new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "2-digit",
                      })?.format(new Date(setTicketAllocationDatas?.dueDate))
                    ) : (
                      <img
                        src={calendar}
                        alt=""
                        onClick={() => {
                          setEndDatedatePicker(true);
                        }}
                      />
                    )}

                    {commonPermission(
                      permissionConstants.permissions
                        .COMMON_TICKET_LIST_DUE_DATE_EDIT,
                    ) && (
                      <ReactDatePicker
                        className="form-control date"
                        selected={
                          setTicketAllocationDatas?.dueDate
                            ? new Date(setTicketAllocationDatas.dueDate)
                            : null
                        }
                        onChange={(date, event) => {
                          const utcDate = moment(date).utc().toDate();
                          if (!ticket) {
                            updateTicketAllocationThunk({
                              id: setTicketAllocationDatas?._id,
                              patchData: { dueDate: utcDate },
                            });
                          } else {
                            updateTicketThunk({
                              id: setTicketAllocationDatas?._id,
                              patchData: { dueDate: utcDate },
                            });
                          }
                          event.stopPropagation();
                          setEndDatedatePicker(false);
                        }}
                        minDate={
                          (StartDate && StartDate) ||
                          setTicketAllocationDatas?.startDate ||
                          new Date()
                        }
                        dateFormat="dd/MM/yy"
                        onClickOutside={() => setEndDatedatePicker(false)}
                        open={endDatedatePicker}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        filterDate={(date) => {
                          const dateMoment = moment(date);
                          if ([0, 6].includes(dateMoment.day())) {
                            return false;
                          }
                          if (
                            setTicketAllocationDatas.startDate &&
                            dateMoment.isBefore(
                              setTicketAllocationDatas.startDate,
                              "day",
                            )
                          ) {
                            return false;
                          }
                          return true;
                        }}
                        dayClassName={(date) => {
                          const dateMoment = moment(date);
                          if (
                            setTicketAllocationDatas?.startDate &&
                            dateMoment.isBefore(
                              setTicketAllocationDatas?.startDate,
                              "day",
                            )
                          ) {
                            return "disabled-date";
                          }
                          return "";
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
          {commonPermission(
            permissionConstants.permissions.COMMON_TICKET_LIST_ASSIGNEE,
          ) &&
            (isGetTicket || isGetTicketAllocation ? (
              <div className="circle-skeleton">
                <CommonSkeleton width={100} />
              </div>
            ) : (
              <div className="rgt-btn-block">
                <div className="dropdown task-watch-dropdown">
                  <Dropdown
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdown(!dropdown);
                    }}
                  >
                    <Dropdown.Toggle id="info-toggle-btn" variant="default">
                      <span className="def-btn dropdown-toggle" type="button">
                        <img src={view} alt="view" />
                        <span className="view-count">
                          {ticket
                            ? [
                                ...new Set(
                                  setTicketAllocationDatas?.allocations?.flatMap(
                                    (allocation) =>
                                      allocation.assignedUsers?.map(
                                        (assignedUser) => assignedUser.user,
                                      ),
                                  ),
                                ),
                              ]?.length
                            : setTicketAllocationDatas?.assignedUsers?.length}
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    {dropdown && userCount > 0 && (
                      <Profile
                        TaskData={setTicketAllocationDatas}
                        dropDownVisibility
                        addUser
                        setAddUser={setAddUser}
                        ticket
                        checklist={ticket ? "ticketUserView" : "taskUserView"}
                        onAllocateUser={() => {}}
                        onDeallocateUser={() => {}}
                        view
                      />
                    )}
                  </Dropdown>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className="task-body-wrapper d-flex height-auto pt-1"
        ref={messagesRef}
      >
        <div className="task-body-col task-body-col-left">
          <div className="task-body-block custom-scroll ps-3 pb-1 pt-3">
            {!ticket && (
              <div>
                {isGetTicket || isGetTicketAllocation ? (
                  <div className="task-title">
                    <CommonSkeleton height={30} />
                  </div>
                ) : (
                  hasPermission(
                    permissionConstants.permissions.TASK_DETAILS_MODAL_TITLE,
                  ) && (
                    <>
                      <h6>Task</h6>
                      <div className="task-title">
                        <input
                          className="task-title-name task_name"
                          style={{ border: "none" }}
                          value={setTicketAllocationDatas?.ticketInfo?.title}
                          disabled={!ticket}
                        />
                      </div>
                    </>
                  )
                )}
                {isGetTicket || isGetTicketAllocation ? (
                  <div className="sub-task-block">
                    <CommonSkeleton height={50} />
                  </div>
                ) : (
                  hasPermission(
                    permissionConstants.permissions
                      .TASK_DETAILS_MODAL_DESCRIPTION,
                  ) && (
                    <div className="sub-task-block">
                      <textarea
                        className="textarea-edit resizable-textarea custom-scroll"
                        placeholder="No Description"
                        value={
                          setTicketAllocationDatas?.ticketInfo?.description
                        }
                        disabled={!ticket}
                      />
                    </div>
                  )
                )}
              </div>
            )}
            {isGetTicket || isGetTicketAllocation ? (
              <div className="task-title">
                <CommonSkeleton height={30} />
              </div>
            ) : (
              hasPermission(
                permissionConstants.permissions.TASK_DETAILS_MODAL_TITLE,
              ) && (
                <div className="task-title">
                  <input
                    className="task-title-name task_name"
                    placeholder="Enter The Title"
                    style={{ border: "none" }}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={() => handleFieldEditFinish("title")}
                    disabled={!ticket}
                  />
                </div>
              )
            )}
            {isGetTicket || isGetTicketAllocation ? (
              <div className="sub-task-block">
                <CommonSkeleton height={50} />
              </div>
            ) : (
              hasPermission(
                permissionConstants.permissions.TASK_DETAILS_MODAL_DESCRIPTION,
              ) && (
                <div className="sub-task-block">
                  <textarea
                    className="textarea-edit resizable-textarea custom-scroll"
                    placeholder="No Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={() => handleFieldEditFinish("description")}
                  />
                </div>
              )
            )}

            {isGetTicket || isGetTicketAllocation ? (
              <CommonSkeleton height={50} />
            ) : (
              <div className="custom-task-cover">
                {hasPermission(
                  permissionConstants.permissions.TASK_DETAILS_MODAL_PROGRESS,
                ) &&
                  ticket && (
                    <div className="d-flex task-progress-wrapper">
                      <div className="task-progress-ctrl">
                        <div className="progress-ctrl">
                          {/* <img src={setProgress} alt="" /> */}
                          <SetProgress />
                        </div>
                        <div className="progress-label">Task Progress</div>
                      </div>
                      <div className="task-progressbar-block">
                        <div className="progress-container">
                          <div
                            id="progress-bar"
                            className="progress-bar"
                            style={{ width: `${progressPercentage}%` }}
                          />
                        </div>
                        <span id="progress-bar-text">
                          {progressPercentage <= 0
                            ? "0%"
                            : `${progressPercentage.toFixed(0)}%`}
                        </span>
                      </div>
                    </div>
                  )}

                {setTicketAllocationDatas?.allocations && (
                  <div className="todo-content-cover">
                    {(hasPermission(
                      permissionConstants.permissions
                        .TASK_DETAILS_MODAL_CREATE_SUBTASK,
                    ) ||
                      hasPermission(
                        permissionConstants.permissions
                          .TASK_DETAILS_MODAL_CREATE_CHECKLIST,
                      )) && (
                      <div className="todo-title-wrapper d-flex align-items-center">
                        <div className="block-lft d-flex align-items-center">
                          <div className="title-block">Create</div>

                          <Dropdown className="dropdown custom-drop-menu task-settings-dropdown drop-new">
                            <Dropdown.Toggle
                              id="info-toggle"
                              className="select_cstm d-flex btn-sm dropdown-toggle"
                              name="Add"
                              variant="default"
                            >
                              {selectedOption || "Add"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropeffect pt-2 pb-2 z-9999">
                              {hasPermission(
                                permissionConstants.permissions
                                  .TASK_DETAILS_MODAL_CREATE_CHECKLIST,
                              ) && (
                                <Dropdown.Item
                                  className="dropdown-item d-flex"
                                  eventKey="checklist"
                                  onClick={() =>
                                    handleSelectChange("checklist")
                                  }
                                >
                                  <div className="icon">
                                    <CheckListIcon />
                                  </div>
                                  <span> CheckList</span>
                                </Dropdown.Item>
                              )}
                              {commonPermission(
                                permissionConstants.permissions
                                  .COMMON_TICKET_LIST_ADD_TASK,
                              ) && (
                                <Dropdown.Item
                                  className="dropdown-item d-flex"
                                  eventKey="subtask"
                                  onClick={() => handleSelectChange("subtask")}
                                >
                                  <div className="icon">
                                    <SubChecklistIcon />
                                  </div>
                                  <span> Subtask</span>
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}

                    {hasPermission(
                      permissionConstants.permissions
                        .TASK_DETAILS_MODAL_LIST_ALLOCATIONS,
                    ) && (
                      <div className="sub-task-cover">
                        <div className="sub-task-title d-flex align-items-center">
                          <div className="title-block">
                            {setTicketAllocationDatas?.allocations.length}{" "}
                            SUBTASKS
                          </div>
                        </div>

                        <div className="sub-task-list-cover ps-0">
                          {isGetTicket || isGetTicketAllocation
                            ? Array.from({
                                length:
                                  setTicketAllocationDatas?.allocations.length,
                              }).map((_, i) => (
                                <div
                                  className="sub-task-list-wrapper d-flex align-items-center"
                                  key={i}
                                >
                                  <CommonSkeleton height={25} width={170} />
                                </div>
                              ))
                            : setTicketAllocationDatas?.allocations?.map(
                                (e, index) => {
                                  const subTaskStatus =
                                    ticketStatus?.status?.find(
                                      (status) => status._id === e.ticketStatus,
                                    );

                                  const eventStatusColors = subTaskStatus
                                    ? subTaskStatus.colorInfo
                                    : null;

                                  // eslint-disable-next-line no-nested-ternary
                                  return isTicketPatch &&
                                    e._id === subTaskState ? (
                                    <div
                                      className="sub-task-list-wrapper d-flex align-items-center"
                                      key={index}
                                    >
                                      <CommonSkeleton width={250} />
                                    </div>
                                  ) : (
                                    <div
                                      className="sub-task-list-wrapper d-flex align-items-center"
                                      key={index}
                                    >
                                      {commonPermission(
                                        permissionConstants.permissions
                                          .COMMON_TICKET_LIST_STATUS,
                                      ) && (
                                        <button
                                          className="sub-task-status-btn status-1"
                                          type="button"
                                          style={{
                                            backgroundColor: eventStatusColors,
                                          }}
                                          onClick={(event) => {
                                            setSubTaskState(e._id);
                                            event.stopPropagation();
                                            setStatusModal(index);
                                          }}
                                          disabled={
                                            !commonPermission(
                                              permissionConstants.permissions
                                                .COMMON_TICKET_LIST_STATUS_EDIT,
                                            )
                                          }
                                        />
                                      )}
                                      {statusModal === index ? (
                                        <Popup
                                          data={ticketStatus?.status}
                                          updateTaskStatus={(ticketStatus) => {
                                            const TicketData = {
                                              allocations: (
                                                setTicketAllocationDatas?.allocations ||
                                                []
                                              )
                                                .map((allocation) => {
                                                  if (
                                                    allocation._id === e._id
                                                  ) {
                                                    setSubTaskState(
                                                      allocation._id,
                                                    );
                                                    return {
                                                      _id: allocation._id,
                                                      ticketStatus,
                                                      assignedUsers:
                                                        allocation.assignedUsers,
                                                      title: allocation.title,
                                                      estimationHours:
                                                        allocation.estimationHours,
                                                    };
                                                  }
                                                  return {
                                                    _id: allocation._id,
                                                    ticketStatus:
                                                      allocation.ticketStatus,
                                                    assignedUsers:
                                                      allocation.assignedUsers,
                                                    title: allocation.title,
                                                    estimationHours:
                                                      allocation.estimationHours,
                                                  };
                                                })
                                                .map(
                                                  ({
                                                    _id,
                                                    ticketStatus,
                                                    assignedUsers,
                                                    title,
                                                    estimationHours,
                                                  }) => ({
                                                    _id,
                                                    ticketStatus,
                                                    assignedUsers,
                                                    title,
                                                    estimationHours,
                                                  }),
                                                ),
                                            };

                                            updateTicketThunk({
                                              id: setTicketAllocationDatas?._id,
                                              patchData: TicketData,
                                            });
                                          }}
                                        />
                                      ) : null}

                                      {commonPermission(
                                        permissionConstants.permissions
                                          .COMMON_TICKET_LIST_ASSIGNEE,
                                      ) && (
                                        <div
                                          className="user-group subtask d-flex"
                                          key={index}
                                        >
                                          <Profile
                                            TaskData={e}
                                            dropDownVisibility={
                                              addUser === e._id
                                            }
                                            addUser={addUser}
                                            setAddUser={setAddUser}
                                            ticket
                                            setNewSubTaskUserPopup={
                                              setNewSubTaskUserPopup
                                            }
                                            allocatedId={e._id}
                                            onAllocateUser={handleAllocateUser}
                                            onDeallocateUser={
                                              handleDeallocateUser
                                            }
                                          />
                                          <button
                                            className="user-def add-user-btn"
                                            type="button"
                                            onClick={(each) => {
                                              each.stopPropagation();
                                              setAddUser(e._id);
                                              setSubTaskState(e._id);
                                            }}
                                            disabled={
                                              !commonPermission(
                                                permissionConstants.permissions
                                                  .COMMON_TICKET_LIST_ASSIGNEE_EDIT,
                                              )
                                            }
                                          >
                                            <svg
                                              id="user_account_people_man"
                                              data-name="user, account, people, man"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="11.624"
                                              height="13.737"
                                              viewBox="0 0 11.624 13.737"
                                            >
                                              <path
                                                id="Path_1424"
                                                data-name="Path 1424"
                                                d="M14.9,16.045a.528.528,0,1,0-.743.751,4.722,4.722,0,0,1,1.41,3.38c0,.646-1.852,1.585-4.755,1.585s-4.755-.939-4.755-1.586a4.723,4.723,0,0,1,1.392-3.362.528.528,0,1,0-.747-.747A5.773,5.773,0,0,0,5,20.176c0,1.716,2.994,2.642,5.812,2.642s5.812-.926,5.812-2.642A5.77,5.77,0,0,0,14.9,16.045Z"
                                                transform="translate(-5 -9.081)"
                                                fill="#b08bfc"
                                              />
                                              <path
                                                id="Path_1425"
                                                data-name="Path 1425"
                                                d="M12.7,10.4A3.7,3.7,0,1,0,9,6.7,3.7,3.7,0,0,0,12.7,10.4Zm0-6.34A2.642,2.642,0,1,1,10.057,6.7,2.642,2.642,0,0,1,12.7,4.057Z"
                                                transform="translate(-6.887 -3)"
                                                fill="#b08bfc"
                                              />
                                            </svg>
                                            <span className="add-icon">
                                              <img src={addFill} alt="" />
                                            </span>
                                          </button>
                                        </div>
                                      )}
                                      <div
                                        className="tech"
                                        type="button"
                                        onClick={(each) => {
                                          each.stopPropagation();
                                          setBackToTicket(
                                            setTicketAllocationDatas?._id,
                                          );
                                          TicketAllocationByIdGet({
                                            params: e._id,
                                            listId: {
                                              listId,
                                              page: 1,
                                              limit: 5,
                                            },
                                          });
                                          isTicket(false);
                                        }}
                                      >
                                        {e.title}
                                      </div>
                                      {hasPermission(
                                        permissionConstants.permissions
                                          .TASK_DETAILS_MODAL_ALLOCATIONS_DESCRIPTION,
                                      ) && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="tooltip-id">
                                              {e.description
                                                ? e.description
                                                : "No description found"}
                                            </Tooltip>
                                          }
                                        >
                                          <button
                                            className="def-btn"
                                            type="button"
                                          >
                                            <img src={ham} alt="ham" />
                                          </button>
                                        </OverlayTrigger>
                                      )}
                                      {hasPermission(
                                        permissionConstants.permissions
                                          .TASK_DETAILS_MODAL_ALLOCATIONS_ATTACHMENTS,
                                      ) && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="tooltip-id">
                                              {e.attachments?.length > 0
                                                ? e.attachments.map(
                                                    (e) => e.name,
                                                  )
                                                : "No attachment found"}
                                            </Tooltip>
                                          }
                                        >
                                          <button
                                            className="def-btn"
                                            type="button"
                                          >
                                            <img
                                              src={attachment}
                                              alt="attachment"
                                            />
                                          </button>
                                        </OverlayTrigger>
                                      )}
                                      {hasPermission(
                                        permissionConstants.permissions
                                          .TASK_DETAILS_MODAL_ALLOCATIONS_HOURS,
                                      ) && (
                                        <div className="time">
                                          <span>
                                            <img
                                              src={sandTime}
                                              alt="sandTime"
                                            />
                                          </span>
                                          <span>{e.estimationHours}</span>
                                        </div>
                                      )}
                                    </div>
                                  );
                                },
                              )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {showSubTaskField && (
                  <>
                    <form onSubmit={handleAddSubtask}>
                      <div className="input-w-icons more-usr-dropdown-01">
                        <div className="input-otr">
                          <div className="drp-chk-list">
                            <Select
                              classNamePrefix="react-select"
                              className="react-select-container"
                              options={selectedTechnology}
                              value={newSubtaskTitle}
                              name=""
                              id=""
                              onChange={(value) => setNewSubtaskTitle(value)}
                              menuPlacement="top"
                            />
                          </div>
                        </div>
                        <div className="btn-_groups">
                          <Dropdown
                            show={isDropdownOpen}
                            onToggle={handleToggleClick}
                          >
                            <Dropdown.Toggle
                              variant="default"
                              className="btn_controler"
                            >
                              <Estimate />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={(e) => e.stopPropagation()}
                              >
                                <input
                                  type="text"
                                  placeholder="Estimation Hour"
                                  value={newSubtaskEstimation}
                                  onChange={(e) =>
                                    setNewSubtaskEstimation(e.target.value)
                                  }
                                />
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <button
                            className="btn_controler"
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setNewSubtaskUserPoup(true);
                            }}
                          >
                            <UserGroup />

                            <div className="add-User">+</div>
                          </button>
                          {(newSubTaskUserPopup || chosenUsers.length > 0) && (
                            <Profile
                              dropDownVisibility
                              ticket
                              addUser={newSubTaskUserPopup}
                              setAddUser={setAddUser}
                              setNewSubTaskUserPopup={setNewSubTaskUserPopup}
                              setNewSubtaskUser="setNewSubtaskUser"
                              onChosenUsersChange={handleChosenUsersChange}
                              chosenUsers={chosenUsers}
                              setChosenUsers={setChosenUsers}
                            />
                          )}
                          <button
                            className="btn_controler add-btn"
                            type="submit"
                          >
                            <AddBtn />
                          </button>
                          <button
                            className="btn_controler delete-btn"
                            type="button"
                            onClick={resetFields}
                          >
                            <img src={deleteIcon} alt="del" />
                          </button>
                        </div>
                      </div>
                    </form>
                    {subtaskErrorMessage && (
                      <div className="text-danger">{subtaskErrorMessage}</div>
                    )}
                  </>
                )}

                {(showCheckList || !ticket) && (
                  <CheckList
                    task={setTicketAllocationDatas}
                    ticket={ticket}
                    updateTicketThunk={updateTicketThunk}
                    patchTicketAllocationAction={updateTicketAllocationThunk}
                    isUpdateTicketThunk={isTicketPatch}
                    ispatchTicketAllocationAction={isLoadingTaskPatch}
                    setSubTaskState={setSubTaskState}
                    checked={checked}
                    setChecked={setChecked}
                    hasPermission={hasPermission}
                  />
                )}
              </div>
            )}
            {isGetTicket || isGetTicketAllocation ? (
              <CommonSkeleton height={150} />
            ) : (
              <FileAttachment
                attachment={setTicketAllocationDatas?.attachments}
                id={setTicketAllocationDatas?._id}
                ticket={ticket}
                selectedFile={
                  ticket ? isLoadingTicket : isLoadingTicketAllocation
                }
                hasPermission={hasPermission}
              />
            )}
          </div>
          {isGetTicket || isGetTicketAllocation ? (
            <div className="center-skeleton">
              <CommonSkeleton width={250} />
            </div>
          ) : (
            <div className="file-upload-block">
              <div className="file-input-cover">
                <input
                  multiple
                  type="file"
                  name="file"
                  id="file-input"
                  className="file-input"
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />
                {hasPermission(
                  permissionConstants.permissions
                    .TASK_DETAILS_MODAL_ATTACHMENT_UPLOAD,
                ) && (
                  <label className="file-input-label" htmlFor="file-input">
                    <img src={upload} alt="upload" />
                    <div>
                      Drop Files here to attach or{" "}
                      <span className="browse">browse</span>
                    </div>
                    <div>&nbsp;&nbsp;{selectedFile?.name}</div>
                  </label>
                )}
              </div>
              <div className="text-danger text-center">{errorMessage}</div>
            </div>
          )}
        </div>
        <ChatSection
          isGetTicket={isGetTicket}
          isGetTicketAllocation={isGetTicketAllocation}
          setTicketAllocationDatas={setTicketAllocationDatas}
          ticket={ticket}
          setAddUser={setAddUser}
          hasPermission={hasPermission}
        />
      </div>
    </>
  );
  useEffect(() => {
    const conditionFunction = setTicketAllocationDatas?.ticketInfo?._id;
    const value = findAllocationByCondition(
      key === "" || !key ? ticketData : ticketAllocations,
      conditionFunction,
      setMatchedArray,
    );
    setMatchedArray(value);
  }, [ticketData, ticketAllocations, key, setTicketAllocationDatas]);

  function findAllocationByCondition(data, conditionFunction) {
    let matchedArrays = [];
    if (key === "" || !key) {
      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const group of data) {
          if (group.data && group.data.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of group.data) {
              if (item.allocations && item.allocations.length > 0) {
                const matchingAllocations = item.allocations?.filter(
                  ({ ticket }) => ticket === conditionFunction,
                );

                if (matchingAllocations?.length > 0) {
                  matchedArrays = matchingAllocations;
                }
              }
            }
          }
        }
      }
    } else if (key === "board") {
      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const group of data) {
          if (group.data && group.data.length > 0) {
            const matchingAllocations = group.data?.filter(
              (ticket) => ticket?.ticketInfo?._id === conditionFunction,
            );

            if (matchingAllocations?.length > 0) {
              matchedArrays = matchingAllocations;
            }
          }
        }
      }
    } else {
      const matchingAllocations = data?.filter(
        (e) => e.ticketInfo?._id === conditionFunction,
      );
      if (matchingAllocations?.length > 0) {
        matchedArrays = matchingAllocations;
      }
    }

    return matchedArrays;
  }
  const renderHeader = () => {
    return (
      <>
        {isGetTicket || isGetTicketAllocation ? (
          <div className="sprint-title-block">
            <CommonSkeleton width={250} />
          </div>
        ) : (
          <div className="sprint-title-block">
            {BackToTicket && (
              <Button
                className="btn back-btn-in-modal"
                onClick={() => {
                  TicketByIdGet({
                    params: BackToTicket,
                    listId: { listId, page: 1, limit: 20 },
                  });
                  isTicket(true);
                  setBackToTicket("");
                }}
              >
                <span className="btn-icon">
                  <ArrowRightIcon />
                </span>
                Back
              </Button>
            )}
            {hasPermission(
              permissionConstants.permissions.TASK_DETAILS_MODAL_BREADCRUMBS,
            ) && (
              <div className="d-flex sprint-block-cover align-items-center">
                <div className="d-flex sprint-sm-block-cover align-items-center">
                  {(isTicketPatch ||
                    isLoadingTaskPatch ||
                    isTaskCreate ||
                    isTaskDelete) && (
                    <div className="animate-progress top-position" />
                  )}
                  <div className="sprint-block">
                    <Link
                      to={`/project/${shortCode}`}
                      onClick={() => {
                        setShowModal(false);
                        setSelectedTicket("");
                      }}
                    >
                      {shortCode}
                    </Link>
                  </div>
                  <div className="sprint-block">
                    <Link
                      to={window.location.pathname}
                      onClick={() => {
                        setShowModal(false);
                        setSelectedTicket("");
                      }}
                    >
                      {listName}
                    </Link>
                  </div>
                  <div className="sprint-block">{title}</div>
                </div>
                {!ticket && (
                  <div className="num-block">
                    <NumericFormat
                      className="num-inp"
                      allowNegative={false}
                      value={inputValue}
                      onChange={handleInputChange}
                      isAllowed={(values) => {
                        if (!values.value) return true;
                        const { floatValue } = values;
                        return floatValue <= matchedArray?.length;
                      }}
                    />
                    <span>0f {matchedArray?.length} </span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {isGetTicket || isGetTicketAllocation ? (
          <div className="header-btn-block d-flex align-items-center">
            <span className="modal-title-btn me-1 minimize-btn">
              <CommonSkeleton width={30} />
            </span>
          </div>
        ) : (
          <div className="header-btn-block d-flex align-items-center">
            <button
              className="modal-title-btn me-1 minimize-btn"
              type="button"
              onClick={() => {
                setShowModal(false);

                const existingTaskValue =
                  JSON.parse(localStorage.getItem("taskValue")) || [];
                const existingTicket = existingTaskValue.find(
                  (e) => e.id === setTicketAllocationDatas._id,
                );
                if (!existingTicket) {
                  existingTaskValue.push({
                    id: setTicketAllocationDatas._id,
                    title: setTicketAllocationDatas.title,
                    ticket,
                  });

                  localStorage.setItem(
                    "taskValue",
                    JSON.stringify(existingTaskValue),
                  );
                }
                if (selectedTicket) {
                  setSelectedTicket("");
                }
                const baseUrl = window.location.pathname;
                navigate(baseUrl);
              }}
            >
              <img src={minimize} alt="minimise" />
            </button>
          </div>
        )}
      </>
    );
  };

  const onGitModalOpen = () => {
    setGitModalOpen(true);
  };

  return (
    <div
      onClick={() => {
        setStatusModal("");
        setAddUser(false);
        setDropdown(false);
      }}
    >
      <CustomModal
        className={`task-details-modal ${isDeleteModalOpen && "delete-open"}`}
        dialgName="modal-dialog-centered"
        contentClassName="modal-content border-0"
        createModal
        show={showModal}
        closeModal={() => {
          setShowModal(false);
          dispatch(setTicketAllocationDataById(""));
          if (selectedTicket) {
            setSelectedTicket("");
          }
          const baseUrl = window.location.pathname;
          navigate(baseUrl);
        }}
        closeButton
        header={renderHeader()}
        body={renderBody()}
        disableCenter
      />
      {isGitHubModalOpen ? (
        <GitHubModal
          projectId={setTicketAllocationDatas?.project}
          taskName={setTicketAllocationDatas?.title}
          taskId={setTicketAllocationDatas?.customId}
          setGitModalOpen={setGitModalOpen}
          ticket={selectedTicket}
        />
      ) : null}
    </div>
  );
};
TaskDetail.propTypes = {
  setShowModal: PropTypes.func,
  setSelectedTicket: PropTypes.func,
  showModal: PropTypes.bool,
  selectedTicket: PropTypes.bool,
  ticket: PropTypes.bool,
  isTicket: PropTypes.func,
};
export default TaskDetail;
