// import DrawingBoard from "react-drawing-board";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useBottomScrollListener } from "react-bottom-scroll-listener";
import useElementScrollPosition from "../../../components/Feed/scroll";

import { withTitle } from "../../../hoc/withTitle";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import addIcon from "../../../assets/images/add-plus.svg";
import "../../../design/custom/pages/notes-list.scss";
import "../../../design/custom/pages/activity-log.scss";
import "../../../design/custom/pages/report-list.scss";
import AddNotes from "./AddNotes";
import { useThunk } from "../../../hooks/useThunk";
import { addNotes } from "../../../store/slices/notes/thunks/postNotes";
import { getNotes } from "../../../store/slices/notes/thunks/getNotes";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import Threedots from "../../../components/DropDowns/Threedots";
import editIcon from "../../../assets/images/icon-edit.svg";
import deleteIcon from "../../../assets/images/delete_icon.svg";
import okIcon from "../../../assets/images/ok-icon-001.svg";
import { deleteNotes } from "../../../store/slices/notes/thunks/deleteNotes";
import { Deletemodal } from "../../../components/DropDowns/DeleteModal";
import { updateNotes } from "../../../store/slices/notes/thunks/updateNotes";
import { useFilter } from "../../../context/useFilter";
import EditHistory from "./EditHistory";
import { alertActions } from "../../../store/slices/alert";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

const Notes = () => {
  const { filter } = useFilter();
  const dispatch = useDispatch();
  // state
  const [showAddNotes, setShowAddNotes] = useState(false);
  const [showEditHistory, setShowEditHistory] = useState(false);
  // const [increment, setIncrement] = useState(12);
  const [deleteModal, setDeleteModal] = useState({
    openclose: false,
    name: "",
    id: "",
  });
  const [rename, setRename] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);
  const [operations, setOperations] = useState([]);

  const [pagination, setPagination] = useState({ page: 1, limit: 50 });

  console.log("showAddNotes", showAddNotes);
  console.log("itemDetails", itemDetails);
  console.log("operafgdfgdftions", operations, setOperations);
  console.log("operations", operations);
  console.log("rename", rename);

  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.WIDGETS,
        permissionConstants.submodules.NOTES,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  // action
  const [CreateNotes, isLoading] = useThunk(addNotes);
  const [GetNotes, isLoadingGetNotes] = useThunk(getNotes);
  const [DeleteNotes, isLoadingdeleteNotes] = useThunk(deleteNotes);
  const [UpdateNotes] = useThunk(updateNotes);

  // reducer
  const { notesList } = useSelector((state) => state.notes);
  console.log("notesList", notesList?.totalCount);

  const scroll = useElementScrollPosition("page-content", ".row");
  console.log("scroll", scroll);

  useEffect(() => {
    if (
      typeof pagination?.page === "number" &&
      // eslint-disable-next-line no-restricted-globals
      !isNaN(pagination?.page) &&
      scroll !== null
    ) {
      GetNotes({
        page: pagination.page,
        limit: pagination.limit,
        search: filter?.search,
      });
    } else {
      GetNotes({
        page: 1,
        limit: 50,
        search: filter?.search,
      });
    }
  }, [pagination?.page, filter?.search]);

  useEffect(() => {
    if (scroll >= 0 && scroll <= 1 && scroll !== null && scroll) {
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }, [scroll]);

  const handleDeleteClick = (id, title) => {
    console.log("object", id);
    setDeleteModal({
      openclose: true,
      name: title,
      id,
    });
  };

  const handleNotesDelete = (id) => {
    DeleteNotes(id);
    setDeleteModal({
      openclose: false,
      name: "",
      id: "",
    });
  };

  const handleRename = (id, title, row) => {
    setItemDetails(row);
  };

  const renameOnBlur = (row) => {
    const formData = new FormData();
    formData.append("fileName", rename);
    if (rename === null) dispatch(alertActions.error("Value already exists"));
    else UpdateNotes({ id: row?._id, data: formData });
    setItemDetails(null);
    setRename(null);
  };

  const renameOnKeyDown = (e, row) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      const formData = new FormData();
      formData.append("fileName", rename);
      if (rename === null) dispatch(alertActions.error("Value already exists"));
      else UpdateNotes({ id: row?._id, data: formData });
      setItemDetails(null);
      setRename(null);
    }
  };

  const handleEditHistory = (id, title, row) => {
    setShowEditHistory(row);
  };

  let ActionButtons = [
    {
      name: "Rename",
      onclickfunction: handleRename,
      className: "edit-text-color",
      icon: editIcon,
      permission: permissionConstants.permissions.NOTES_RENAME,
    },
    {
      name: "Delete",
      onclickfunction: handleDeleteClick,
      className: "text-danger",
      icon: deleteIcon,
      permission: permissionConstants.permissions.NOTES_DELETE,
    },
    {
      name: "Edit History",
      onclickfunction: handleEditHistory,
      className: "edit-text-color",
      icon: okIcon,
      permission: permissionConstants.permissions.NOTES_EDIT_HISTORY,
    },
  ];

  if (hasPermission)
    ActionButtons = ActionButtons.filter(
      (e) => e.permission && hasPermission(e.permission),
    );

  // const handleScrollBottom = () => {
  //   if (notesList?.totalCount > increment) {
  //     setIncrement(increment + 4);
  //   }
  // };
  // const scrollRef = useBottomScrollListener(() => {
  //   console.log("dfgdfgdfgdf");
  //   handleScrollBottom();
  // });

  return (
    <>
      {" "}
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.NOTES_SEARCH,
        )}
      />
      <div className="page-content">
        <div className="container-fluid">
          <div className="notes-wrap">
            <div className="row">
              <div className="col sub-wrp">
                <h5 className="sub-t">Recent</h5>
                {hasPermission(permissionConstants.permissions.NOTES_ADD) && (
                  <button
                    type="button"
                    className="btn add-btn me-1"
                    onClick={(row) => setShowAddNotes(row)}
                  >
                    Add
                    <span>
                      <img className="ms-1 " src={addIcon} alt="" />
                    </span>
                  </button>
                )}
              </div>
            </div>
            <div className="row border-0">
              <div className="col d-flex">
                <div className="notes-list-wrp custom-scrol">
                  {notesList?.data?.map((item) => (
                    <div key={item._id} className="thumb-hldr">
                      <div
                        className="img-wrp"
                        type="button"
                        onClick={() => {
                          if (
                            hasPermission(
                              permissionConstants.permissions.NOTES_EDIT,
                            )
                          ) {
                            setShowAddNotes(item);
                          }
                        }}
                      >
                        {isLoadingGetNotes ? (
                          <CommonSkeleton height={100} />
                        ) : (
                          // <DrawingBoard
                          //   userId="user1"
                          //   // eslint-disable-next-line no-unsafe-optional-chaining
                          //   operations={[...item?.file]}
                          // />
                          <img src={item?.dataUrl} alt="" />
                        )}
                      </div>
                      <div className="caption">
                        {isLoadingGetNotes ? (
                          <CommonSkeleton width={250} height={20} />
                        ) : (
                          <>
                            <input
                              type="text"
                              className="note-title"
                              defaultValue={item?.fileName}
                              onChange={({ target }) => {
                                setRename(target.value);
                              }}
                              onBlur={() => renameOnBlur(item)}
                              onKeyDown={(e) => renameOnKeyDown(e, item)}
                              disabled={itemDetails?._id !== item?._id}
                            />
                            {!!ActionButtons.length && (
                              <Threedots
                                type={item?.fileName}
                                dropDownVisibility
                                TaskData={item}
                                ActionButtons={ActionButtons}
                                className="more-option"
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!showAddNotes && (
        <AddNotes
          showModal={showAddNotes}
          closeModal={() => {
            setShowAddNotes(false);
          }}
          createNotes={CreateNotes}
          isLoading={isLoading}
          UpdateNotes={UpdateNotes}
        />
      )}
      {!!deleteModal && (
        <Deletemodal
          ModalShow={deleteModal}
          deleteFunc={handleNotesDelete}
          closeModal={() => {
            setDeleteModal({
              openclose: false,
              name: "",
              id: "",
            });
          }}
          cancelModal={() => {
            setDeleteModal({
              openclose: false,
              name: "",
              id: "",
            });
          }}
          loaderButton={isLoadingdeleteNotes}
        />
      )}
      {!!showEditHistory && (
        <EditHistory
          showModal={showEditHistory}
          closeModal={() => {
            setShowEditHistory(false);
          }}
        />
      )}
    </>
  );
};

export default withTitle(Notes);
