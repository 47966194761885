import NotifyIcon from "../../assets/images/notify-icon.svg";
import "../../design/custom/pages/warning-page.scss";

const NoNotificationYet = () => {
  return (
    <div className="warning-page-cover">
      <div className="warning-content-block text-center">
        <div className="text-center img-block">
          <img src={NotifyIcon} alt="" />
        </div>
        <h4>No Notification Yet</h4>
        <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout.
        </p>
      </div>
    </div>
  );
};

export default NoNotificationYet;
