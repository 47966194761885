import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { useContext, useEffect, useCallback, useState } from "react";
import Highcharts from "highcharts";
import { performanceGraphOptions } from "../../../config/ChartOptions";
import LanguageContext from "../../../context/LanguageContext";
import CustomDateRange from "../../common/DateRangePicker";
import CommonSkeleton from "../../SkeletonLoader/CommonSkeleton";

const Performancegraph = ({
  data,
  label = [],
  showCustomDateRange,
  onPerformanceDateChange,
  loading,
}) => {
  const generateChart = useCallback(() => {
    if (!loading) {
      const options = performanceGraphOptions(data, label);
      Highcharts?.chart("performanceChart", options);
    }
  }, [data, label]);

  useEffect(() => {
    generateChart();
  }, [generateChart]);

  const {
    languageData: { lineChart },
  } = useContext(LanguageContext);

  const titleTooltip = (
    <Tooltip id={lineChart.title}>{lineChart.performanceGraphContent}</Tooltip>
  );

  const handlePerformanceDateCallback = (start, end) => {
    setPerformanceDate({ start, end });
    onPerformanceDateChange({ start, end });
  };
  const [performanceDate, setPerformanceDate] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });

  return (
    <div className="card-box card-box-h dashboard-cards">
      <div className="header-otr d-flex flex-wrap">
        <OverlayTrigger placement="top" overlay={titleTooltip}>
          <div className="title-md">{lineChart.title}</div>
        </OverlayTrigger>
        {showCustomDateRange && (
          <CustomDateRange
            start={performanceDate.start}
            end={performanceDate.end}
            change={handlePerformanceDateCallback}
          />
        )}
      </div>

      <div className="chart-cover">
        {loading ? (
          <div className="mb-3">
            <CommonSkeleton height={200} />
          </div>
        ) : (
          <figure className="highcharts-figure">
            <div id="performanceChart" className="chart-content" />
          </figure>
        )}
      </div>
    </div>
  );
};

Performancegraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  showCustomDateRange: PropTypes.bool,
  onPerformanceDateChange: PropTypes.func,
  label: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

export default Performancegraph;
