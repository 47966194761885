import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { getNotes } from "./getNotes";
import { alertActions } from "../../alert";
import notesServices from "../../../../services/notesServices";

export const deleteNotes = createAsyncThunk(
  "delete-notes",
  async (id, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await notesServices.deleteNotes(id);
      console.log("successMessage", successMessage);
      dispatch(alertActions.success(successMessage));
      dispatch(
        getNotes({
          page: 1,
          limit: 50,
          // search: filter?.search,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
