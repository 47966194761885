/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { NumericFormat } from "react-number-format";

import Button from "../../../components/Button/index";

import closeIcon from "../../../assets/images/close.svg";
import SortIcon from "../../../assets/images/sort.svg";
import { AddFilterIcon, ClearAllIcon, TrashIcon } from "../SubheaderIcons";
import { useThunk } from "../../../hooks/useThunk";
import { getFilterById } from "../../../store/slices/filter/thunks/getFilterById";
import "../../../design/custom/pages/teams.scss";
import defFlagImg from "../../../assets/images/flag-def.svg";
import orangeFlagImg from "../../../assets/images/flag-orange.svg";
import blueFlagImg from "../../../assets/images/flag-blue.svg";
import redFlagImg from "../../../assets/images/flag-red.svg";
import outlineFlagImg from "../../../assets/images/priority.svg";
import flagFilled from "../../../assets/images/flagFilled.svg";
import checkIcon from "../../../assets/images/check_icon.svg";
import defaultFlag from "../../../assets/images/flag-outline.svg";

import "../../../design/custom/pages/projectmanagement-list.scss";
import { getFirstLetters } from "../../../utils/utils";
import { useFilter } from "../../../context/useFilter";

const Filter = ({ handleFilter }) => {
  const initalFilterValue = {
    where: "",
    condition: "",
    option: "",
    gate: "AND",
  };
  const { filter } = useFilter();
  const [toggle, setToggle] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [allFilters, setAllFilters] = useState(filter.filter);
  const [dropDownData, setDropDownData] = useState([]);
  const [imgArray, setimgArray] = useState([]);
  const [isOpen, setIsOpen] = useState(null);
  const { listId } = useParams();

  const [filterById, isLoading] = useThunk(getFilterById);
  const { filterDropdown, filterIdData } = useSelector(
    (state) => state.filterDropdownData,
  );
  const { ticketStatus } = useSelector((s) => s.ticketReducer);
  const [whereOptions, setWhereOptions] = useState([]);
  const selectRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    setAllFilters([initalFilterValue]);
  }, [location.pathname]);

  useEffect(() => {
    if (filterIdData) {
      const index = dropDownData.findIndex(
        (item) => item._id === filterIdData._id,
      );
      if (index === -1) {
        setDropDownData([...dropDownData, filterIdData]);
      } else {
        const updatedDropDownData = [...dropDownData];
        updatedDropDownData[index] = filterIdData;
        setDropDownData(updatedDropDownData);
      }
    }
  }, [filterIdData, allFilters]);
  useEffect(() => {
    if (allFilters[0]?.where === "") {
      setWhereOptions(
        filterDropdown?.map((item) => ({
          value: item._id,
          label: item.where,
        })),
      );
    } else {
      setWhereOptions(
        filterDropdown
          .filter(
            (each) => !allFilters.some((e) => e.where?.value === each._id),
          )
          .map((item) => {
            return { value: item._id, label: item.where };
          }),
      );
    }
  }, [allFilters, filterDropdown]);
  const conditionOptions =
    filterDropdown
      ?.find((item) => item.where === selectedFilter)
      ?.condition?.map((item) => ({ value: item, label: item })) ?? [];
  useEffect(() => {
    handleFilter(allFilters);
  }, [allFilters]);
  const handleFilterChange = (field, item, index) => {
    setAllFilters((prevFilters) => {
      if (prevFilters.length)
        return prevFilters.map((i, position) => {
          if (position === index) {
            return { ...i, [field]: item };
          }
          return i;
        });
      return [...prevFilters, { [field]: item }];
    });
  };
  const handleDeleteFilter = (index) => {
    const updatedFilters = allFilters.filter((_, i) => i !== index);
    setAllFilters(updatedFilters);
  };

  let flagImg = <img style={{ border: "black" }} src={outlineFlagImg} alt="" />;

  const getInitailBackground = (shortName) => {
    return (
      <div className="avathar-sm avathar-01">
        <span className="name-ltr">{shortName}</span>
      </div>
    );
  };
  const nameInitials = (e) => {
    const nameParts = e.label?.split(" ");
    const initials = nameParts
      ?.map((part) => part.charAt(0)?.toUpperCase())
      .join("");
    return initials;
  };
  const CustomValueContainer = ({ getValue, children, ...props }) => {
    const selectedOptions = getValue();
    const threshold = 0;
    if (selectedOptions.length <= threshold) {
      return children;
    }
    const key = props.selectProps.statusKey;
    switch (key) {
      case "Assignee":
      case "Uploaded By":
      case "Deleted By":
      case "Updated By":
      case "Created By": {
        return (
          <div {...props} className="wrap">
            {selectedOptions.slice(0, 4).map((e, index) => (
              <div key={index} className="sctm-d-flex">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`index-${index}`}>{e.label}</Tooltip>}
                >
                  <div
                    className="avthar-img-otr"
                    style={{ borderRadius: "25px" }}
                  >
                    {e.photo ? (
                      imgArray?.includes(e.value) ? (
                        <div className="avathar-sm avathar-01">
                          <span>{nameInitials(e)}</span>
                        </div>
                      ) : (
                        <div className="avathar-sm">
                          <img
                            src={`https://erptest.spericorn.com/repo/uploads/${e.photo}`}
                            alt=""
                          />
                        </div>
                      )
                    ) : (
                      <div className="avathar-sm avathar-01">
                        <span>{nameInitials(e)}</span>
                      </div>
                    )}
                    {selectedOptions.length < 2 && <span>{e.label}</span>}
                  </div>
                </OverlayTrigger>
              </div>
            ))}

            {selectedOptions.length > 4 && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="users">
                    {selectedOptions.slice(4).map((e) => (
                      <div key={e.id}>{e.label}</div>
                    ))}
                  </Tooltip>
                }
              >
                <span className="moreCount">
                  {" "}
                  +{selectedOptions.length - 4} more{" "}
                </span>
              </OverlayTrigger>
            )}
          </div>
        );
      }
      case "Status": {
        return (
          <div
            {...props}
            className="name-block align-items-center d-flex customStatus"
          >
            {selectedOptions.map((e, index) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`index-${index}`}>
                    <div>{e.label}</div>
                  </Tooltip>
                }
              >
                <>
                  <div
                    className="filterStatus"
                    style={{
                      backgroundColor: statusColor(e),
                    }}
                  >
                    <div
                      className="statusBox"
                      style={{
                        backgroundColor: statusColor(e),
                      }}
                    />
                  </div>
                  {selectedOptions.length < 2 && <span>{e.label}</span>}
                </>
              </OverlayTrigger>
            ))}
          </div>
        );
      }
      case "Priority": {
        return (
          <div {...props} className="d-flex filterPriority">
            {selectedOptions.map((e) => (
              <>
                <div className="priorityArea">
                  <div className="priorityFlag">{Priority(e.label)}</div>
                </div>

                <div className="member-list">
                  <span className="name">{e.label}</span>
                </div>
              </>
            ))}
          </div>
        );
      }
      default:
        return null;
    }
  };

  CustomValueContainer.propTypes = {
    getValue: PropTypes.func.isRequired,
    children: PropTypes.node,
    selectProps: PropTypes.node,
    statusKey: PropTypes.node,
  };

  const Priority = (title) => {
    switch (title) {
      case "Low":
        flagImg = (
          <img
            className="name-ltr"
            style={{ border: "rgb(216 216 216)" }}
            src={flagFilled}
            alt=""
          />
        );
        break;
      case "Normal":
        flagImg = (
          <img
            className="name-ltr"
            style={{ border: "blue" }}
            src={blueFlagImg}
            alt=""
          />
        );
        break;
      case "High":
        flagImg = (
          <img
            className="name-ltr"
            style={{ border: "#ffcc00" }}
            src={orangeFlagImg}
            alt=""
          />
        );
        break;
      case "Urgent":
        flagImg = (
          <img
            className="name-ltr"
            style={{ border: "#f50000" }}
            src={redFlagImg}
            alt=""
          />
        );
        break;
      case "No Priority":
        flagImg = (
          <img
            className="name-ltr"
            style={{ border: "grey" }}
            src={defFlagImg}
            alt=""
          />
        );
        break;
      default:
        flagImg = (
          <img
            className="name-ltr"
            style={{ border: "black", width: "12px", color: "black" }}
            src={outlineFlagImg}
            alt=""
          />
        );
    }
    return flagImg;
  };

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };
  const statusColor = (data) => {
    switch (data.label) {
      case "TODO":
        return "#87909E";
      case "IN PROGRESS":
        return "#298CC8";
      case "READY FOR QA":
        return "#C55029";
      case "COMPLETE":
        return "#6BC950";
      default:
        return "";
    }
  };
  const CustomOption123 = ({
    Icon,
    isSelected,
    children,
    innerProps,
    data,
    ...rest
  }) => {
    const style = {};
    const props = {
      ...innerProps,
      style,
    };
    const names = data.label.split(" ");
    const shortName = names
      .slice(0, 2)
      .map((name) => name[0]?.toUpperCase())
      .join("");
    const key = rest.selectProps.statusKey;
    switch (key) {
      case "Assignee":
      case "Created By":
      case "Uploaded By":
        return (
          <components.Option
            {...rest}
            isSelected={isSelected}
            innerProps={props}
            // ref={dropdownRef}
          >
            <div
              className="custom"
              style={{ display: "flex", alignItems: "center" }}
            >
              {data.photo ? (
                imgArray?.includes(data?.value) ? (
                  getInitailBackground(shortName)
                ) : (
                  <div className="user-profile-thumb avathar-sm">
                    <img
                      onError={(target) => handleOnError(target, data?.value)}
                      onLoad={(target) => {
                        target.target.style.display = "block";
                      }}
                      src={`https://erptest.spericorn.com/repo/uploads/${data.photo}`}
                      alt=""
                    />
                  </div>
                )
              ) : (
                getInitailBackground(shortName)
              )}
              <span>{children}</span>
              <div className="ch-bx-otr ">
                <label className="check-bx-cstm chkbox-wrp assignee-chkbox">
                  <input
                    type="checkbox"
                    onChange={() => {}}
                    checked={isSelected}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </components.Option>
        );
      case "Status":
        return (
          <components.Option
            {...rest}
            isSelected={isSelected}
            innerProps={props}
          >
            <div
              className="name-block align-items-center d-flex customStatus"
              style={{ cursor: "pointer" }}
              // onClick={() =>
              //   handleFilterChange(
              //     "option",
              //     [...allFilters[index].option, option.data],
              //     index,
              //   )
              // }
            >
              <div
                className="filterStatus"
                style={{
                  backgroundColor: ticketStatus.status.map((e) =>
                    e.title.includes(data?.label) ? e.colorInfo : "",
                  ),
                  // marginRight: "8px",
                }}
              >
                <div
                  className="statusBox"
                  style={{
                    // width: "12px",
                    // height: "12px",
                    backgroundColor: statusColor(data),
                  }}
                />
              </div>
              <div className="member-list">
                <span className="name">{data?.label}</span>
              </div>
              <div className="ch-bx-otr">
                {/* <label className="check-bx-cstm ">
                  <input
                    type="checkbox"
                    onChange={() => {}}
                    checked={isSelected}
                  />
                  <span className="checkmark_01" />
                </label> */}
                {isSelected && (
                  <div className="ok icons">
                    <img src={checkIcon} alt="icon" />
                  </div>
                )}
              </div>
            </div>
          </components.Option>
        );
      default: {
        return "";
      }
    }
  };
  CustomOption123.propTypes = {
    Icon: PropTypes.node,
    isSelected: PropTypes.node,
    children: PropTypes.node,
    innerProps: PropTypes.node,
    data: PropTypes.node,
    selectProps: PropTypes.node,
    statusKey: PropTypes.node,
  };
  const customSingleSelect = (option, index, { key }) => {
    const { value, label, photo } = option;
    const names = label.split(" ");
    const shortName = names
      .slice(0, 2)
      .map((name) => name[0]?.toUpperCase())
      .join("");
    switch (key) {
      case "Updated By":
      case "Deleted By":
      case "Created By": {
        return (
          <div
            className="name-block align-items-center d-flex"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleFilterChange("option", value, index);
            }}
          >
            {photo ? (
              imgArray?.includes(value) ? (
                getInitailBackground(shortName)
              ) : (
                <div className="avathar-sm">
                  <img
                    onError={(target) => handleOnError(target, value)}
                    // eslint-disable-next-line no-return-assign
                    onLoad={(target) => (target.target.style.display = "block")}
                    className="name-ltr"
                    src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
                    alt="profile pic"
                  />
                </div>
              )
            ) : (
              getInitailBackground(shortName)
            )}
            <div className="member-list">
              <span className="name">{label}</span>
            </div>
          </div>
        );
      }
      case "Priority": {
        return (
          <div
            className="d-flex filterPriority"
            onClick={() => handleFilterChange("option", value, index)}
          >
            <div className="priorityArea">
              <div
                className="priorityFlag"
                style={{
                  backgroundColor: statusColor(value),
                }}
              >
                {Priority(label)}
              </div>
            </div>
            <div className="member-list">
              <span className="name">{label}</span>
            </div>
          </div>
        );
      }
      default:
        return (
          <div onClick={() => handleFilterChange("option", value, index)}>
            {label}
          </div>
        );
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  const filterField = (index) => {
    return (
      <div
        className="form-group filter-value-list d-flex align-items-center"
        key={index}
      >
        <div className="px-1 mx-75">
          {index === 0 ? (
            <span className="label">Where</span>
          ) : (
            allFilters.length >= 1 && (
              <div className="custom-small-sel and pe-1 mx-75">
                <Button
                  className={`frm-control d-flex w-100 filter-Toggle-Btn custom-small-sel ${
                    allFilters[index].gate === "AND" ? "and" : "or"
                  }`}
                  onClick={() => {
                    setToggle(!toggle);
                    setAllFilters(
                      allFilters.map((item) => {
                        return { ...item, gate: toggle ? "OR" : "AND" };
                      }),
                    );
                  }}
                >
                  <span className="txt">{toggle ? "AND" : "OR"}</span>
                  <span>
                    <img className="opacity-50" src={SortIcon} alt="" />
                  </span>
                </Button>
              </div>
            )
          )}
        </div>
        <div className="middle-wrp">
          <div className="mx-single custom-small-sel px-1">
            <Select
              classNamePrefix="react-select"
              className="react-select-container"
              options={whereOptions}
              value={allFilters[index].where}
              onChange={(item) => {
                filterById({ where: item.value, listId });
                setSelectedFilter(item.label);
                handleFilterChange("where", item, index);
                handleFilterChange("option", "", index);
              }}
              placeholder="Select filter"
            />
          </div>
          {allFilters[index].where && (
            <>
              {["is set", "is not set"].includes(
                allFilters[index].condition.value,
              ) ? (
                <div className="mx-double custom-small-sel px-1">
                  <Select
                    classNamePrefix="react-select"
                    className="react-select-container"
                    options={conditionOptions}
                    onChange={(item) =>
                      handleFilterChange("condition", item, index)
                    }
                    value={allFilters[index].condition}
                  />
                </div>
              ) : (
                <>
                  <div className="mx-single custom-small-sel px-1">
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container"
                      options={conditionOptions}
                      onChange={(item) =>
                        handleFilterChange("condition", item, index)
                      }
                      value={allFilters[index].condition}
                    />
                  </div>
                  <div
                    className="mx-single custom-small-sel px-1"
                    ref={selectRef}
                    onClick={() =>
                      setIsOpen((prevIndex) =>
                        prevIndex !== index ? index : null,
                      )
                    }
                  >
                    {[
                      "Start Date",
                      "End Date",
                      "Date Created",
                      "Date Updated",
                      "Last Worked On",
                      "Deleted On",
                      "Next Run Date",
                      "Last Run Date",
                      "Last Error",
                      "Date",
                      "Uploaded On",
                    ].includes(allFilters[index].where.label) ? (
                      <DatePicker
                        className="form-control"
                        selected={allFilters[index].option}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(item) => {
                          item = moment(item).endOf("day").toDate();
                          handleFilterChange("option", item, index);
                        }}
                        placeholderText="select date"
                        dateFormat="dd-MM-yy"
                      />
                    ) : [
                        "Status",
                        "Assignee",
                        "Created By",
                        "Uploaded By",
                      ].includes(allFilters[index].where.label) ? (
                      <Select
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        className="react-select-container react-input-select filterUserSel"
                        statusKey={allFilters[index].where.label}
                        index={index}
                        options={
                          dropDownData.find(({ _id }) => {
                            return _id === allFilters[index].where?.value;
                          })?.option
                        }
                        isMulti
                        isLoading={isLoading}
                        placeholder="Select option"
                        onChange={(item) =>
                          handleFilterChange("option", item, index)
                        }
                        value={allFilters[index].option}
                        menuIsOpen={index === isOpen}
                        components={{
                          Option: (props) => {
                            if (
                              allFilters[index].where.label === "Assignee" ||
                              allFilters[index].where.label === "Created By" ||
                              allFilters[index].where.label === "Uploaded By" ||
                              allFilters[index].where.label === "Status"
                            ) {
                              return <CustomOption123 {...props} />;
                            }
                            // return <CustomOption {...props} />;
                          },
                          ValueContainer: (props) => {
                            if (
                              allFilters[index].where.label === "Assignee" ||
                              allFilters[index].where.label === "Created By" ||
                              allFilters[index].where.label === "Uploaded By" ||
                              allFilters[index].where.label === "Status"
                            ) {
                              return <CustomValueContainer {...props} />;
                            }
                            return <components.ValueContainer {...props} />;
                          },
                        }}
                      />
                    ) : [
                        "Tickets Taken",
                        "Tickets Completed",
                        "Tickets To Do",
                        "Tickets In Progress",
                        "Tickets Under Review",
                        "Total Working Hours",
                        "Productive Hours",
                        "Total Tickets",
                        "Hours Allocated Per Day",
                        "No. Of Projects Allocated",
                        "No. of QA Rejections",
                        "No. Of Leaves",
                        "Total No. Of Working Days",
                        "Total Active Projects",
                        "No. of likes",
                        "No. Of comments",
                        "No. of Hours",
                      ].includes(allFilters[index].where.label) ? (
                      <NumericFormat
                        className="form-control"
                        allowNegative={false}
                        onChange={(e) =>
                          handleFilterChange("option", e.target.value, index)
                        }
                        placeholder="enter a number"
                      />
                    ) : (
                      <Select
                        classNamePrefix="react-select"
                        className="react-select-container react-input-select"
                        options={
                          dropDownData.find(({ _id }) => {
                            return _id === allFilters[index].where?.value;
                          })?.option
                        }
                        placeholder="select option"
                        closeMenuOnSelect
                        onChange={(item) =>
                          handleFilterChange("option", item, index)
                        }
                        value={allFilters[index].option}
                        statusKey={allFilters[index].where.label}
                        index={index}
                        styles={{
                          singleValue: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                          }),
                        }}
                        formatOptionLabel={(option) =>
                          customSingleSelect(option, index, {
                            key: allFilters[index].where.label,
                          })
                        }
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </>
              )}
              {/* <div class="mx-double custom-small-sel px-1">
              <select name="" class="form-select frm-control" id="">
                <option value="">Select tags</option>
                <option value="">Demo</option>
              </select>
            </div> */}
            </>
          )}
        </div>
        <div className="trash-wrp">
          {allFilters.find((e) => e.where) && (
            <Button
              className="btn-acnt px-0"
              type="button"
              onClick={() =>
                index === 0 && allFilters.length === 1
                  ? setAllFilters([initalFilterValue])
                  : handleDeleteFilter(index)
              }
            >
              <TrashIcon />
            </Button>
          )}
        </div>
      </div>
    );
  };
  const filterFields = [];
  for (let i = 0; i < allFilters.length; i += 1) {
    filterFields.push(filterField(i));
  }

  return (
    <Dropdown.Menu bsPrefix="dropdown-menu dropeffect">
      <div className="drop-hdr d-flex">
        <div className="title">Filters</div>
        <Dropdown.Item bsPrefix="close-drop" varient="">
          <img src={closeIcon} alt="icon" />
        </Dropdown.Item>
      </div>
      <div>{filterFields}</div>
      <div className="row">
        <div className="col d-flex btn-ftr">
          <Button
            className="sm-text filter-add-btn d-flex align-items-center"
            onClick={() => setAllFilters([...allFilters, initalFilterValue])}
            disabled={
              !allFilters.some(
                (e, index) => index === allFilters.length - 1 && e.option,
              )
            }
          >
            <span className="d-flex me-1">
              <AddFilterIcon />
            </span>
            Add filter
          </Button>
          {allFilters.length - 1 > 0 && (
            <Button
              className="sm-text filter-clear ms-auto d-flex align-items-center"
              onClick={() => setAllFilters([initalFilterValue])}
            >
              <span className="d-flex me-1">
                <ClearAllIcon />
              </span>
              <span>Clear All</span>
            </Button>
          )}
        </div>
      </div>
    </Dropdown.Menu>
  );
};

Filter.propTypes = {
  handleFilter: PropTypes.func,
  // data: PropTypes.node,
};
export default Filter;
