import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatDateStructure } from "../../../utils/utils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

export const cols = (hasPermission) => [
  {
    name: "Ticket ID",
    selector: "ticketId",
    titleClasses: "left-txt ps-0 width-001",
    colClassName: "left-txt ps-0",
    cell: ({ row }) => (
      <div className="tbl-cont">{row.payload?.ticketId || "-"}</div>
    ),
    activitySort: true,
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions.PROJECT_MANAGEMENT_ACTIVITY_LOG_TICKET_ID,
    ),
  },
  {
    name: "Task Title",
    selector: "ticketName",
    cell: ({ row }) => (
      <div className="tbl-cont">{row?.payload?.ticketName || "-"}</div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_ACTIVITY_LOG_TASK_TITLE,
    ),
  },
  {
    name: "List",
    selector: "listId",
    cell: ({ row }) => (
      <div className="tbl-cont ">{row.payload?.listId || "-"}</div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions.PROJECT_MANAGEMENT_ACTIVITY_LOG_LIST,
    ),
  },
  {
    name: "Sprint",
    selector: "sprint",
    cell: ({ row }) => (
      <div className="tbl-cont ">{row.payload?.sprint || "-"}</div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions.PROJECT_MANAGEMENT_ACTIVITY_LOG_SPRINT,
    ),
  },
  {
    name: "Category",
    selector: "category",
    cell: ({ row }) => (
      <div className="tbl-cont ">{row?.payload?.category || "-"}</div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions.PROJECT_MANAGEMENT_ACTIVITY_LOG_CATEGORY,
    ),
  },
  {
    name: "User",
    selector: "user",
    cell: ({ row }) => (
      <div className="tbl-cont ">{row?.payload?.userName || "-"}</div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions.PROJECT_MANAGEMENT_ACTIVITY_LOG_USER,
    ),
  },
  {
    name: "Remark",
    selector: "remark",
    cell: ({ row }) => (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{row?.payload?.remarks}</Tooltip>}
      >
        <div className="tbl-cont " data-for="tooltip">
          {row?.payload?.remarks || "-"}
        </div>
      </OverlayTrigger>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions.PROJECT_MANAGEMENT_ACTIVITY_LOG_REMARK,
    ),
  },
  {
    name: "Role",
    selector: "role",
    cell: ({ row }) => (
      <div className="tbl-cont ">{row?.payload?.role || "-"}</div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
  },
  {
    name: "Date & Time",
    selector: "date",
    cell: ({ row }) => (
      <div className="tbl-cont ">
        {formatDateStructure(row.createdAt) || "-"}
      </div>
    ),
    activitySort: true,
    titleClasses: "text-start",
    colClassName: "text-start",
    icon: true,
    permission: hasPermission(
      permissionConstants.permissions
        .PROJECT_MANAGEMENT_ACTIVITY_LOG_DATE_AND_TIME,
    ),
  },
];
