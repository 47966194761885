import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { gitHubAction } from "..";
import { gitAccessTokenPost } from "../../../../services/gitHubService";
// import { getUserProfile } from "../../userProfile/thunks/getUserProfile";

export const getGitAccessToken = createAsyncThunk(
  "github/accessToken",
  async (code, { dispatch }) => {
    // dispatch(alertActions.clear());
    try {
      const { data } = await gitAccessTokenPost(code);
      dispatch(gitHubAction.gitSuccessStatus(true));
      dispatch(gitHubAction.setAccessToken(data.tokenResponse));
      // dispatch(getUserProfile());
      dispatch(alertActions.success(data.message));
      dispatch(gitHubAction.setSuccess(true));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
