export const PhoneIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.542"
    height="9.541"
    viewBox="0 0 9.542 9.541"
  >
    <path
      id="call"
      d="M32.432,31.346l-.945.945a.306.306,0,0,1-.081.061c-1.335.719-3.9-.624-5.073-1.864l-.035-.036c-1.24-1.177-2.583-3.739-1.864-5.073a.3.3,0,0,1,.061-.08l.945-.946a.326.326,0,0,1,.244-.1h.008a.324.324,0,0,1,.249.112l1.219,1.316a.346.346,0,0,1-.009.482l-.787.787a.951.951,0,0,0-.273.828A3.469,3.469,0,0,0,27.1,29.654c.012.012.023.024.035.035a3.466,3.466,0,0,0,1.878,1.006.949.949,0,0,0,.829-.274l.786-.786a.348.348,0,0,1,.483-.01l1.316,1.219a.348.348,0,0,1,.009.5Zm.412-.957L31.529,29.17a.966.966,0,0,0-1.343.026l-.787.787a.333.333,0,0,1-.3.1,2.876,2.876,0,0,1-1.543-.843.126.126,0,0,1-.012-.012,2.879,2.879,0,0,1-.841-1.542.336.336,0,0,1,.1-.3l.787-.786a.966.966,0,0,0,.025-1.344L26.4,23.94a.97.97,0,0,0-1.4-.026l-.945.945a.939.939,0,0,0-.168.225,2.617,2.617,0,0,0-.261,1.473,5.419,5.419,0,0,0,.409,1.6A8.262,8.262,0,0,0,25.87,30.9l.014.012a8.26,8.26,0,0,0,2.745,1.836,5.5,5.5,0,0,0,1.6.409c.1.008.194.011.287.011A2.472,2.472,0,0,0,31.7,32.9a.946.946,0,0,0,.226-.168l.945-.946a.968.968,0,0,0-.027-1.4Z"
      transform="translate(-23.614 -23.63)"
      fill="currentcolor"
      fillRule="evenodd"
    />
  </svg>
);
export const EmailIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.65"
    height="7.988"
    viewBox="0 0 10.65 7.988"
  >
    <path
      id="envelope"
      d="M9.652,59.882H1a1,1,0,0,0-1,1v5.991a1,1,0,0,0,1,1H9.652a1,1,0,0,0,1-1V60.88A1,1,0,0,0,9.652,59.882Zm0,.666a.331.331,0,0,1,.128.026l-4.454,3.86L.871,60.573A.331.331,0,0,1,1,60.548Zm0,6.656H1a.333.333,0,0,1-.333-.333V61.277l4.441,3.849a.333.333,0,0,0,.436,0l4.441-3.849v5.594A.333.333,0,0,1,9.652,67.2Z"
      transform="translate(0 -59.882)"
      fill="currentcolor"
    />
  </svg>
);

export const DesignationIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.327"
    height="9.144"
    viewBox="0 0 10.327 9.144"
  >
    <g id="suitcase" transform="translate(0 -29.314)">
      <g
        id="Group_59986"
        data-name="Group 59986"
        transform="translate(0 29.314)"
      >
        <path
          id="Path_10405"
          data-name="Path 10405"
          d="M8.734,31.014h-1.5V30.08a.77.77,0,0,0-.773-.766h-2.6a.77.77,0,0,0-.773.766v.935h-1.5A1.6,1.6,0,0,0,0,32.607v4.55a1.3,1.3,0,0,0,1.3,1.3H9.025a1.3,1.3,0,0,0,1.3-1.3v-4.55A1.6,1.6,0,0,0,8.734,31.014ZM3.7,30.08a.159.159,0,0,1,.166-.159h2.6a.159.159,0,0,1,.166.159v.935H3.7ZM9.72,37.157a.7.7,0,0,1-.694.694H1.3a.7.7,0,0,1-.694-.694V34.322a1.312,1.312,0,0,0,.31.142l.018,0,3.092.756v.25a.711.711,0,0,0,.711.711H5.59a.711.711,0,0,0,.711-.711v-.25l3.092-.756.018,0a1.313,1.313,0,0,0,.31-.142v2.835ZM4.633,35.475v-.856a.1.1,0,0,1,.1-.1H5.59a.1.1,0,0,1,.1.1v.856a.1.1,0,0,1-.1.1H4.737A.1.1,0,0,1,4.633,35.475ZM9.72,33.219a.693.693,0,0,1-.482.663L6.3,34.6a.711.711,0,0,0-.71-.693H4.737a.711.711,0,0,0-.71.693l-2.938-.718a.693.693,0,0,1-.482-.663v-.612a.987.987,0,0,1,.986-.986h7.14a.987.987,0,0,1,.986.986v.612Z"
          transform="translate(0 -29.314)"
          fill="currentcolor"
        />
      </g>
    </g>
  </svg>
);

export const DepartmentIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.217"
    height="7.478"
    viewBox="0 0 11.217 7.478"
  >
    <g id="laptop" transform="translate(0 -85.333)">
      <g
        id="Group_59987"
        data-name="Group 59987"
        transform="translate(0 85.333)"
      >
        <path
          id="Path_10406"
          data-name="Path 10406"
          d="M10.983,91.409h-.7V86.268a.936.936,0,0,0-.935-.935H1.87a.936.936,0,0,0-.935.935v5.141h-.7A.234.234,0,0,0,0,91.643v.234a.936.936,0,0,0,.935.935h9.348a.936.936,0,0,0,.935-.935v-.234A.234.234,0,0,0,10.983,91.409ZM1.4,86.268A.468.468,0,0,1,1.87,85.8H9.348a.468.468,0,0,1,.467.467v5.141H1.4Zm8.88,6.076H.935a.468.468,0,0,1-.467-.467H4.343l.165.165a.234.234,0,0,0,.165.068h1.87a.234.234,0,0,0,.165-.068l.165-.165H10.75A.468.468,0,0,1,10.282,92.344Z"
          transform="translate(0 -85.333)"
          fill="currentcolor"
        />
      </g>
    </g>
  </svg>
);

export const EmpIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.346"
    height="9.877"
    viewBox="0 0 12.346 9.877"
  >
    <g id="Black_line" transform="translate(-1 -8.2)">
      <g id="Group_59985" data-name="Group 59985" transform="translate(1 8.2)">
        <path
          id="Path_10400"
          data-name="Path 10400"
          d="M10.843,30.744h2.229a.344.344,0,0,0,.343-.343V27.743a.344.344,0,0,0-.343-.343H10.843a.344.344,0,0,0-.343.343v2.641A.338.338,0,0,0,10.843,30.744Zm.343-2.658h1.543v1.955H11.186Z"
          transform="translate(-8.871 -24.108)"
          fill="currentcolor"
        />
        <path
          id="Path_10401"
          data-name="Path 10401"
          d="M32.743,28.486h4.9a.343.343,0,0,0,0-.686h-4.9a.343.343,0,1,0,0,.686Z"
          transform="translate(-27.016 -24.439)"
          fill="currentcolor"
        />
        <path
          id="Path_10402"
          data-name="Path 10402"
          d="M32.743,40.686h4.9a.343.343,0,0,0,0-.686h-4.9a.343.343,0,0,0,0,.686Z"
          transform="translate(-27.016 -34.547)"
          fill="currentcolor"
        />
        <path
          id="Path_10403"
          data-name="Path 10403"
          d="M10.843,52.786H19.5a.343.343,0,0,0,0-.686h-8.66a.344.344,0,0,0-.343.343A.333.333,0,0,0,10.843,52.786Z"
          transform="translate(-8.871 -44.572)"
          fill="currentcolor"
        />
        <path
          id="Path_10404"
          data-name="Path 10404"
          d="M1,17.048a1.032,1.032,0,0,0,1.029,1.029H12.317a1.032,1.032,0,0,0,1.029-1.029V10.223a1.032,1.032,0,0,0-1.029-1.029H8.956V8.543A.344.344,0,0,0,8.614,8.2H5.733a.344.344,0,0,0-.343.343v.652H2.029A1.032,1.032,0,0,0,1,10.223ZM6.076,8.886H8.271v.96H6.076Zm-4.39,1.338a.344.344,0,0,1,.343-.343H5.39v.309a.344.344,0,0,0,.343.343H8.614a.344.344,0,0,0,.343-.343V9.88h3.361a.344.344,0,0,1,.343.343v6.842a.344.344,0,0,1-.343.343H2.029a.344.344,0,0,1-.343-.343Z"
          transform="translate(-1 -8.2)"
          fill="currentcolor"
        />
      </g>
    </g>
  </svg>
);
