import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { getUserProjects } from "./getUserProjects";
import { deleteList } from "../../../../services/listService";
import { getTrash } from "../../trash/thunks/getTrash";

export const listDelete = createAsyncThunk(
  "list/delete",
  async function (id, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const response = await deleteList(id);
      const { message: successMessage } = response.data;
      dispatch(alertActions.success(successMessage));
      dispatch(getTrash({ page: 1, limit: 10 }));
      dispatch(getUserProjects({}));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
