/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/prop-types */
import { useState } from "react";

const EditForm = ({
  patchFunction,
  id,
  fieldName,
  IsEditable,
  value,
  type,
}) => {
  const [state, setState] = useState(value);
  return (
    <form
      className="task-hr-form"
      style={{ display: "flex" }}
      onSubmit={(e) => {
        e.preventDefault();
        patchFunction("allocations", {
          id,
          patchData: { [fieldName]: state },
        });
        IsEditable(null);
      }}
    >
      <input
        type={type || "text"}
        value={state}
        onChange={(e) => setState(e.target.value)}
        autoFocus
      />
      <button
        onClick={() => {
          IsEditable(null);
          setState(value);
        }}
        className="btn btn-close"
        type="button"
      />
    </form>
  );
};

export default EditForm;
