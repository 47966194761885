import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import technologyIconServices from "../../../../../services/technologyIconServices";
import { alertActions } from "../../../alert";
import { getTechnologyIcon } from "./getTechnologyIcon";

export const addTechnologyIcon = createAsyncThunk(
  "technologyIcon",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await technologyIconServices.addTechnologyIcon(payload);

      dispatch(getTechnologyIcon());
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(technologyIconServices.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
