import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
// import { getFeed } from "./getFeed";
import { patchFeedService } from "../../../../services/feedService";
import { setPatchedFeedUserProfile } from "..";

export const profileFeedPatch = createAsyncThunk(
  "patchFeed",
  async function (formData, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const { data } = await patchFeedService(formData);
      //   dispatch(getFeed());
      dispatch(setPatchedFeedUserProfile(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
