import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";

import { todoActions } from "../index";
import todoService from "../../../../services/todoService";

export const createTodo = createAsyncThunk(
  "todo/add",
  async function (data, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { message: successMessage },
      } = await todoService.addTodo(data);
      dispatch(alertActions.success(successMessage));
      dispatch(todoActions.setCreateTodo(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(todoActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
