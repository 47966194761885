import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import sprintService from "../../../../services/sprintService";
import { sprintActions } from "../index";
import { getUserProjects } from "../../project/thunks/getUserProjects";
import { getSprint } from "./getSprint";

export const sprintCreate = createAsyncThunk(
  "sprint/create",
  async function ({ payload, navigate }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const response = await sprintService.postSprintService(payload);
      const { data, message: successMessage } = response.data;
      dispatch(sprintActions.setSprint(successMessage));
      dispatch(getSprint(payload?.project));
      dispatch(getUserProjects({}));
      dispatch(alertActions.success(successMessage));
      if (navigate && data.sprints[0]._id) navigate(data.sprints[0]._id);
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(sprintActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
