import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { getUserProjects } from "./getUserProjects";
import { postList } from "../../../../services/listService";
import { projectActions } from "..";

export const listCreate = createAsyncThunk(
  "list/create",
  async function ({ postData, navigate }, { dispatch }) {
    dispatch(alertActions.clear());
    dispatch(
      projectActions.setListCreateSuccessMessage({ successMessage: null }),
    );
    try {
      const response = await postList(postData);
      const { message: successMessage, data } = response.data;
      dispatch(projectActions.setListCreateSuccessMessage({ successMessage }));
      dispatch(alertActions.success(successMessage));
      if (navigate && data?._id) navigate(data?._id);
      dispatch(getUserProjects({}));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
