/* eslint-disable no-unused-vars */
import io from "socket.io-client";
import { decryptAccessToken } from "../utils/encryptionUtils";

const token = localStorage.getItem("accessToken");
const decryptedAccessToken =
  token && decryptAccessToken(token, process.env.REACT_APP_SECRET_KEY);
const socket = io(process.env.REACT_APP_API_ENDPOINT, {
  extraHeaders: {
    Authorization: decryptedAccessToken,
  },
});
socket.on("connect", () => {
  console.log("Connected to the server");
});

export default socket;

// const createWebSocketConnection = (token) => {
//   const socket = io(process.env.REACT_APP_API_ENDPOINT, {
//     extraHeaders: {
//       Authorization: token,
//     },
//   });
//   socket.on("connect", () => {
//     console.log("Connected to the server");
//   });
//   return socket;
// };

// export default createWebSocketConnection;

// const token = localStorage.getItem("accessToken");

// socket.onAny((event, data) => {
//   console.log(`Received event '${event}':`, data);
// });
// Add event listeners or perform any necessary setup

// export default socket;
