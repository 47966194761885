import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { documentActions } from "..";

export const clearDocument = createAsyncThunk(
  "documents/clearReducer",
  async (payload, { dispatch }) => {
    try {
      dispatch(documentActions.setClearDocument(payload));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
