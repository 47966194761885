export const WorkingHours = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="47"
    viewBox="0 0 47 47"
  >
    <g
      id="Group_60173"
      data-name="Group 60173"
      transform="translate(-9093.246 -1367.245)"
    >
      <g id="Group_60172" data-name="Group 60172">
        <circle
          id="Ellipse_482"
          data-name="Ellipse 482"
          cx="23.5"
          cy="23.5"
          r="23.5"
          transform="translate(9093.246 1367.245)"
          fill="#de484145"
        />
        <g
          id="vuesax_bold_timer"
          data-name="vuesax/bold/timer"
          transform="translate(9103.246 1377.245)"
        >
          <g id="timer">
            <path
              id="Vector"
              d="M9.793,0a9.8,9.8,0,1,0,9.793,9.8A9.8,9.8,0,0,0,9.793,0Zm.847,9.431a.847.847,0,0,1-1.694,0V3.784a.847.847,0,1,1,1.694,0Z"
              transform="translate(3.761 5.252)"
              fill="#de4841"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M7.342,1.638H.813A.81.81,0,0,1,0,.825.82.82,0,0,1,.813,0H7.342a.81.81,0,0,1,.813.813A.82.82,0,0,1,7.342,1.638Z"
              transform="translate(9.477 2.259)"
              fill="#de4841"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H27.108V27.108H0Z"
              transform="translate(27.108 27.108) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const WorkingHourNormal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.139"
    height="17.326"
    viewBox="0 0 13.139 17.326"
  >
    <path
      id="wait"
      d="M48.426,16.137H47.407V14.311a5.8,5.8,0,0,0-2.855-4.782,1.036,1.036,0,0,1-.371-.866,1.036,1.036,0,0,1,.371-.866,5.8,5.8,0,0,0,2.855-4.782V1.189h1.019a.594.594,0,0,0,0-1.189H36.475a.594.594,0,1,0,0,1.189h1.019V3.015A5.8,5.8,0,0,0,40.349,7.8a1.036,1.036,0,0,1,.371.866,1.036,1.036,0,0,1-.371.867,5.8,5.8,0,0,0-2.855,4.782v1.826H36.475a.594.594,0,0,0,0,1.189H48.426a.595.595,0,0,0,0-1.189Zm-9.743-1.826a4.768,4.768,0,0,1,2.328-3.794,2.2,2.2,0,0,0,.9-1.854,2.195,2.195,0,0,0-.9-1.854,4.768,4.768,0,0,1-2.328-3.794V1.189h7.535V3.015A4.768,4.768,0,0,1,43.89,6.809a2.2,2.2,0,0,0-.9,1.854,2.2,2.2,0,0,0,.9,1.854,4.768,4.768,0,0,1,2.329,3.794v1.826H38.683Z"
      transform="translate(-35.881)"
      fill="#7a8188"
    />
  </svg>
);

export const WorkingHourRed = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.139"
    height="17.326"
    viewBox="0 0 13.139 17.326"
  >
    <path
      id="wait"
      d="M48.426,16.137H47.407V14.311a5.8,5.8,0,0,0-2.855-4.782,1.036,1.036,0,0,1-.371-.866,1.036,1.036,0,0,1,.371-.866,5.8,5.8,0,0,0,2.855-4.782V1.189h1.019a.594.594,0,0,0,0-1.189H36.475a.594.594,0,1,0,0,1.189h1.019V3.015A5.8,5.8,0,0,0,40.349,7.8a1.036,1.036,0,0,1,.371.866,1.036,1.036,0,0,1-.371.867,5.8,5.8,0,0,0-2.855,4.782v1.826H36.475a.594.594,0,0,0,0,1.189H48.426a.595.595,0,0,0,0-1.189Zm-9.743-1.826a4.768,4.768,0,0,1,2.328-3.794,2.2,2.2,0,0,0,.9-1.854,2.195,2.195,0,0,0-.9-1.854,4.768,4.768,0,0,1-2.328-3.794V1.189h7.535V3.015A4.768,4.768,0,0,1,43.89,6.809a2.2,2.2,0,0,0-.9,1.854,2.2,2.2,0,0,0,.9,1.854,4.768,4.768,0,0,1,2.329,3.794v1.826H38.683Z"
      transform="translate(-35.881)"
      fill="#FD7878"
    />
  </svg>
);

export const WorkingHourOrange = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.139"
    height="17.326"
    viewBox="0 0 13.139 17.326"
  >
    <path
      id="wait"
      d="M48.426,16.137H47.407V14.311a5.8,5.8,0,0,0-2.855-4.782,1.036,1.036,0,0,1-.371-.866,1.036,1.036,0,0,1,.371-.866,5.8,5.8,0,0,0,2.855-4.782V1.189h1.019a.594.594,0,0,0,0-1.189H36.475a.594.594,0,1,0,0,1.189h1.019V3.015A5.8,5.8,0,0,0,40.349,7.8a1.036,1.036,0,0,1,.371.866,1.036,1.036,0,0,1-.371.867,5.8,5.8,0,0,0-2.855,4.782v1.826H36.475a.594.594,0,0,0,0,1.189H48.426a.595.595,0,0,0,0-1.189Zm-9.743-1.826a4.768,4.768,0,0,1,2.328-3.794,2.2,2.2,0,0,0,.9-1.854,2.195,2.195,0,0,0-.9-1.854,4.768,4.768,0,0,1-2.328-3.794V1.189h7.535V3.015A4.768,4.768,0,0,1,43.89,6.809a2.2,2.2,0,0,0-.9,1.854,2.2,2.2,0,0,0,.9,1.854,4.768,4.768,0,0,1,2.329,3.794v1.826H38.683Z"
      transform="translate(-35.881)"
      fill="#FFA04E"
    />
  </svg>
);

export const ProjectsAllocated = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="47"
    viewBox="0 0 47 47"
  >
    <g
      id="Group_60174"
      data-name="Group 60174"
      transform="translate(-879 -109)"
    >
      <circle
        id="Ellipse_482"
        data-name="Ellipse 482"
        cx="23.5"
        cy="23.5"
        r="23.5"
        transform="translate(879 109)"
        fill="#ef934d45"
      />
      <g id="start-up" transform="translate(891 121)">
        <path
          id="Path_22"
          data-name="Path 22"
          d="M73.221,283.862a.677.677,0,0,0,.126.782l.165.165c.478-.566,1.035-1.181,1.711-1.857.048-.048.1-.071.145-.115L74.3,281.773C73.685,282.892,73.324,283.657,73.221,283.862Zm0,0"
          transform="translate(-69.85 -269.065)"
          fill="#ef934d"
        />
        <path
          id="Path_23"
          data-name="Path 23"
          d="M164.744,389.707c-.677.677-1.29,1.235-1.854,1.714l.224.224a.676.676,0,0,0,.788.123c.35-.181.9-.44,2.071-1.091l-1.115-1.115c-.044.048-.067.1-.115.145Zm0,0"
          transform="translate(-155.544 -371.992)"
          fill="#ef934d"
        />
        <path
          id="Path_24"
          data-name="Path 24"
          d="M65.74,311.511c-.489-.489-1.864-.051-2.51.6a17.991,17.991,0,0,0-3.575,4.6.677.677,0,0,0,.9.9,18.123,18.123,0,0,0,4.584-3.587c.71-.711,1.061-2.044.6-2.508Zm0,0"
          transform="translate(-56.901 -297.261)"
          fill="#ef934d"
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M2.112,165.4.2,167.318a.677.677,0,0,0,.653,1.132,5.559,5.559,0,0,1,2.557-.237,36.3,36.3,0,0,1,2.8-4.273c-.574-.368-2.47-.163-4.1,1.464Zm0,0"
          transform="translate(-0.001 -156.397)"
          fill="#ef934d"
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M246.013,382.532a.675.675,0,0,0,.782-.126l1.914-1.913c1.481-1.482,1.742-3.394,1.406-4a38.624,38.624,0,0,1-4.276,2.779,8.289,8.289,0,0,1-.191,2.557A.675.675,0,0,0,246.013,382.532Zm0,0"
          transform="translate(-234.561 -359.512)"
          fill="#ef934d"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M285.237,137.9a2.03,2.03,0,1,0,2.87,0A2.032,2.032,0,0,0,285.237,137.9Zm0,0"
          transform="translate(-271.806 -131.116)"
          fill="#ef934d"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M393.336.661a.676.676,0,0,0-.624-.624,13.374,13.374,0,0,0-4.939.575,5.68,5.68,0,0,0,1.683,3.3,5.662,5.662,0,0,0,3.369,1.68,13.99,13.99,0,0,0,.511-4.936Zm0,0"
          transform="translate(-370.284 -0.001)"
          fill="#ef934d"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M127.4,27.787A7.107,7.107,0,0,1,125.364,24c-4.063,1.666-7.952,5.3-11.025,10.416l1.527,1.527a2.849,2.849,0,0,1,3.113.268,2.846,2.846,0,0,1,.267,3.112l1.577,1.577c3.933-2.37,8.511-6.1,10.458-11.072a7.073,7.073,0,0,1-3.883-2.036Zm-.957,5.741a3.383,3.383,0,1,1,0-4.784A3.387,3.387,0,0,1,126.441,33.527Zm0,0"
          transform="translate(-109.182 -22.914)"
          fill="#ef934d"
        />
      </g>
    </g>
  </svg>
);

export const TeamLead = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="47"
    viewBox="0 0 47 47"
  >
    <g
      id="Group_58972"
      data-name="Group 58972"
      transform="translate(-1114 -348.5)"
    >
      <g
        id="Group_57770"
        data-name="Group 57770"
        transform="translate(-8380.676 -1021.176)"
      >
        <circle
          id="Ellipse_482"
          data-name="Ellipse 482"
          cx="23.5"
          cy="23.5"
          r="23.5"
          transform="translate(9494.676 1369.676)"
          fill="#5c79ee45"
        />
      </g>
      <g
        id="Layer_93"
        data-name="Layer 93"
        transform="translate(1126.211 361.551)"
      >
        <g id="group" transform="translate(1 -37.119)">
          <g
            id="Group_60161"
            data-name="Group 60161"
            transform="translate(16.017 48.264)"
          >
            <g id="Group_60160" data-name="Group 60160">
              <path
                id="Path_9804"
                data-name="Path 9804"
                d="M400.085,273.32h-1.591a4.14,4.14,0,0,1,.251,1.423v6.015a1.774,1.774,0,0,1-.1.594h2.631a1.784,1.784,0,0,0,1.782-1.782v-3.279A2.974,2.974,0,0,0,400.085,273.32Z"
                transform="translate(-398.494 -273.32)"
                fill="#5c79ee"
              />
            </g>
          </g>
          <g
            id="Group_60163"
            data-name="Group 60163"
            transform="translate(0 48.265)"
          >
            <g
              id="Group_60162"
              data-name="Group 60162"
              transform="translate(0 0)"
            >
              <path
                id="Path_9805"
                data-name="Path 9805"
                d="M4.311,274.744a4.139,4.139,0,0,1,.251-1.423H2.971A2.974,2.974,0,0,0,0,276.292v3.279a1.784,1.784,0,0,0,1.782,1.782H4.414a1.774,1.774,0,0,1-.1-.594Z"
                transform="translate(0 -273.321)"
                fill="#5c79ee"
              />
            </g>
          </g>
          <g
            id="Group_60165"
            data-name="Group 60165"
            transform="translate(5.5 46.717)"
          >
            <g
              id="Group_60164"
              data-name="Group 60164"
              transform="translate(0 0)"
            >
              <path
                id="Path_9806"
                data-name="Path 9806"
                d="M143.438,234.815H139.8a2.974,2.974,0,0,0-2.971,2.971V243.8a.594.594,0,0,0,.594.594h8.391a.594.594,0,0,0,.594-.594v-6.015A2.974,2.974,0,0,0,143.438,234.815Z"
                transform="translate(-136.829 -234.815)"
                fill="#5c79ee"
              />
            </g>
          </g>
          <g
            id="Group_60167"
            data-name="Group 60167"
            transform="translate(6.717 38.84)"
          >
            <g
              id="Group_60166"
              data-name="Group 60166"
              transform="translate(0 0)"
            >
              <path
                id="Path_9807"
                data-name="Path 9807"
                d="M170.687,38.84a3.572,3.572,0,1,0,3.573,3.573A3.577,3.577,0,0,0,170.687,38.84Z"
                transform="translate(-167.114 -38.84)"
                fill="#5c79ee"
              />
            </g>
          </g>
          <g
            id="Group_60169"
            data-name="Group 60169"
            transform="translate(1.344 42.17)"
          >
            <g id="Group_60168" data-name="Group 60168">
              <path
                id="Path_9808"
                data-name="Path 9808"
                d="M36.115,121.689a2.671,2.671,0,1,0,2.672,2.672A2.675,2.675,0,0,0,36.115,121.689Z"
                transform="translate(-33.443 -121.689)"
                fill="#5c79ee"
              />
            </g>
          </g>
          <g
            id="Group_60171"
            data-name="Group 60171"
            transform="translate(13.891 42.17)"
          >
            <g id="Group_60170" data-name="Group 60170">
              <path
                id="Path_9809"
                data-name="Path 9809"
                d="M348.279,121.689a2.672,2.672,0,1,0,2.672,2.672A2.675,2.675,0,0,0,348.279,121.689Z"
                transform="translate(-345.607 -121.689)"
                fill="#5c79ee"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Client = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="47"
    viewBox="0 0 47 47"
  >
    <g
      id="Group_58972"
      data-name="Group 58972"
      transform="translate(-1114 -348.5)"
    >
      <g
        id="Group_57770"
        data-name="Group 57770"
        transform="translate(-8380.676 -1021.176)"
      >
        <circle
          id="Ellipse_482"
          data-name="Ellipse 482"
          cx="23.5"
          cy="23.5"
          r="23.5"
          transform="translate(9494.676 1369.676)"
          fill="#ac80f745"
        />
      </g>
      <path
        id="clerk-with-tie"
        d="M25.328,7.268c-.3,1.162-1.013,1.418-1.391,1.469a7.66,7.66,0,0,1-1.258,1.9,2.5,2.5,0,0,1-.791.48,3.488,3.488,0,0,1-2.565,0,2.5,2.5,0,0,1-.791-.48,7.657,7.657,0,0,1-1.258-1.9c-.378-.051-1.087-.307-1.391-1.469a1.848,1.848,0,0,1,.124-1.505,1.175,1.175,0,0,1,.361-.357c0-.124.006-.279.02-.456a2.135,2.135,0,0,1-.148-2.031,1.809,1.809,0,0,1,1.7-1.317,2.951,2.951,0,0,1,.912-1,3.516,3.516,0,0,1,2.02-.6,4.558,4.558,0,0,1,2.7.943c1.358,1,1.242,3.221,1.208,3.624a6.014,6.014,0,0,1,.064.838,1.175,1.175,0,0,1,.361.357A1.849,1.849,0,0,1,25.328,7.268ZM29.3,15.919V19.07a.446.446,0,0,1-.446.446h-16.5a.446.446,0,0,1-.446-.446V15.919A3.124,3.124,0,0,1,13.884,13l3.9-1.561a.446.446,0,0,1,.586.263l.981,2.713.194-.559-.309-.756a.446.446,0,0,1,.413-.615h1.907a.446.446,0,0,1,.413.615l-.309.756.194.559.981-2.713a.446.446,0,0,1,.586-.263L27.327,13A3.124,3.124,0,0,1,29.3,15.919Zm-2.376.343a.446.446,0,0,0-.446-.446h-3a.446.446,0,0,0-.446.446v1.392a.446.446,0,0,0,.446.446h3a.446.446,0,0,0,.446-.446Z"
        transform="translate(1116.894 362.242)"
        fill="#ac80f7"
      />
    </g>
  </svg>
);

export const Remarks = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="47"
    viewBox="0 0 47 47"
  >
    <g
      id="Group_58972"
      data-name="Group 58972"
      transform="translate(-1114 -348.5)"
    >
      <g
        id="Group_57770"
        data-name="Group 57770"
        transform="translate(-8380.676 -1021.176)"
      >
        <circle
          id="Ellipse_482"
          data-name="Ellipse 482"
          cx="23.5"
          cy="23.5"
          r="23.5"
          transform="translate(9494.676 1369.676)"
          fill="#65bd5047"
        />
        <path
          id="chat"
          d="M14.329,0H6.146A6.145,6.145,0,0,0,.008,6.138v4.774A6.146,6.146,0,0,0,5.8,17.04v2.845a.581.581,0,0,0,.581.579.574.574,0,0,0,.407-.17l.64-.64a8.836,8.836,0,0,1,6.289-2.605h.607a6.145,6.145,0,0,0,6.138-6.138V6.138A6.145,6.145,0,0,0,14.329,0ZM5.8,9.888A1.364,1.364,0,1,1,7.169,8.524,1.365,1.365,0,0,1,5.8,9.888Zm4.433,0A1.364,1.364,0,1,1,11.6,8.524,1.365,1.365,0,0,1,10.237,9.888Zm4.433,0a1.364,1.364,0,1,1,1.364-1.364A1.365,1.365,0,0,1,14.67,9.888Z"
          transform="translate(9507.938 1382.944)"
          fill="#65bd50"
        />
      </g>
    </g>
  </svg>
);

export const UserReport = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.94"
    height="15.939"
    viewBox="0 0 15.94 15.939"
  >
    <g id="Group_60172" data-name="Group 60172" transform="translate(-552 -18)">
      <path
        id="Path_9776"
        data-name="Path 9776"
        d="M-408.457,252.332c0-.231,0-.435,0-.639a1.214,1.214,0,0,1,1.2-1.2q2.48-.011,4.961,0a1.211,1.211,0,0,1,1.192,1.186c.006.209,0,.419,0,.661a6.538,6.538,0,0,1,.832.005,1.82,1.82,0,0,1,1.62,1.851c0,1.538,0,3.077,0,4.615v.236c.25.034.5.055.737.1a3.678,3.678,0,0,1,2.939,3.77,3.679,3.679,0,0,1-3.22,3.473,4.64,4.64,0,0,1-.534.032q-5.143,0-10.286,0a1.83,1.83,0,0,1-1.878-1.591,2.16,2.16,0,0,1-.019-.343q0-5.113,0-10.226a1.841,1.841,0,0,1,1.935-1.936Zm7.366,1.227a1.245,1.245,0,0,1-1.132,1.218c-.229.017-.46,0-.689.007a1.255,1.255,0,0,1-1.3-.834.726.726,0,0,0-.3-.324.586.586,0,0,0-.831.331,1.233,1.233,0,0,1-1.267.828c-.217,0-.434,0-.651,0a1.2,1.2,0,0,1-.877-.409,1.3,1.3,0,0,1-.32-.814c-.191,0-.375,0-.559,0a.612.612,0,0,0-.658.658q0,5.161,0,10.323a.616.616,0,0,0,.671.665h7.432c.051,0,.1-.007.178-.013a3.816,3.816,0,0,1-.928-2.3.693.693,0,0,1-.744.479c-.3-.012-.6,0-.9,0h-3.812a.631.631,0,0,1-.627-.384.615.615,0,0,1,.617-.841c1.558,0,3.116,0,4.674-.005a.721.721,0,0,1,.774.43c.013-.016.019-.02.019-.025.007-.044.014-.088.02-.132a3.624,3.624,0,0,1,2.32-3.074c.107-.045.116-.11.116-.205,0-.549,0-1.1,0-1.647q0-1.638,0-3.275a.609.609,0,0,0-.572-.645C-400.658,253.55-400.868,253.559-401.091,253.559Zm4.895,9.193a2.455,2.455,0,0,0-2.428-2.451,2.454,2.454,0,0,0-2.477,2.441,2.454,2.454,0,0,0,2.456,2.461A2.455,2.455,0,0,0-396.2,262.752Zm-11.019-11.029v1.834c.2,0,.4.006.6,0a.2.2,0,0,0,.131-.1,1.8,1.8,0,0,1,1.729-1.113,1.777,1.777,0,0,1,1.687,1.143.188.188,0,0,0,.129.1c.2,0,.4-.02.6-.032v-1.828Z"
        transform="translate(962.91 -232.491)"
        fill="#A2A5AC"
      />
      <path
        id="Path_9777"
        data-name="Path 9777"
        d="M-359.892,430.23h2.356a.625.625,0,0,1,.7.617.623.623,0,0,1-.689.608h-4.75a.623.623,0,0,1-.69-.608.622.622,0,0,1,.7-.617Z"
        transform="translate(917.418 -403.033)"
        fill="#A2A5AC"
      />
      <path
        id="Path_9778"
        data-name="Path 9778"
        d="M-360.822,382.308c.492,0,.983,0,1.475,0a.62.62,0,0,1,.672.606.62.62,0,0,1-.678.619q-1.465,0-2.93,0a.621.621,0,0,1-.681-.616.621.621,0,0,1,.688-.609Z"
        transform="translate(917.418 -357.563)"
        fill="#A2A5AC"
      />
      <path
        id="Path_9779"
        data-name="Path 9779"
        d="M-182.119,455.24c-.13.437.129.7.411.966a.6.6,0,0,1-.267,1.039.574.574,0,0,1-.565-.152,8.222,8.222,0,0,1-.631-.64.748.748,0,0,1-.165-.412c-.022-.407-.012-.816-.006-1.225a.609.609,0,0,1,.6-.616.615.615,0,0,1,.622.618C-182.116,454.959-182.119,455.1-182.119,455.24Z"
        transform="translate(746.993 -425.778)"
        fill="#A2A5AC"
      />
    </g>
  </svg>
);

export const SearchIcon = () => (
  <svg
    id="search-normal"
    xmlns="http://www.w3.org/2000/svg"
    width="11.621"
    height="11.621"
    viewBox="0 0 11.621 11.621"
  >
    <path
      id="Vector"
      d="M8.724,4.362A4.362,4.362,0,1,1,4.362,0,4.362,4.362,0,0,1,8.724,4.362Z"
      transform="translate(0.918 0.918)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M.918.918,0,0"
      transform="translate(9.642 9.642)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M0,0H11.02V11.02H0Z"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const ResourceIcon = () => (
  <svg width="16.662" height="11.502" viewBox="0 0 16.662 11.502">
    <g
      id="Group_58849"
      data-name="Group 58849"
      transform="translate(-6418.949 -6942.496)"
    >
      <g
        id="user_4_"
        data-name="user (4)"
        transform="translate(6421.829 6899.93)"
      >
        <g
          id="Group_1149"
          data-name="Group 1149"
          transform="translate(0 42.666)"
        >
          <g id="Group_1148" data-name="Group 1148" transform="translate(0 0)">
            <path
              id="Path_57"
              data-name="Path 57"
              d="M6.82,48.212a2.974,2.974,0,1,0-2.933,0A5.008,5.008,0,0,0,0,52.94v.73a.3.3,0,0,0,.595,0v-.73a4.563,4.563,0,0,1,4.759-4.326,4.563,4.563,0,0,1,4.759,4.326v.73a.3.3,0,0,0,.595,0v-.73A5.008,5.008,0,0,0,6.82,48.212ZM2.974,45.64A2.379,2.379,0,1,1,5.354,48.02,2.382,2.382,0,0,1,2.974,45.64Z"
              transform="translate(0 -42.666)"
              fill="currentcolor"
              stroke="currentcolor"
              strokeWidth="0.2"
            />
            <path
              id="Path_58"
              data-name="Path 58"
              d="M332.272,89.7a2.377,2.377,0,0,0-1.307-4.364.3.3,0,1,0,0,.595,1.785,1.785,0,1,1,0,3.569.3.3,0,1,0,0,.595,3.445,3.445,0,0,1,3.569,3.3v.568a.3.3,0,1,0,.595,0v-.568A3.932,3.932,0,0,0,332.272,89.7Z"
              transform="translate(-321.447 -84.144)"
              fill="currentcolor"
              stroke="currentcolor"
              strokeWidth="0.2"
            />
          </g>
        </g>
      </g>
      <path
        id="Path_1464"
        data-name="Path 1464"
        d="M333.523,89.7a2.377,2.377,0,0,1,1.307-4.364.3.3,0,1,1,0,.595,1.784,1.784,0,1,0,0,3.569.3.3,0,1,1,0,.595,3.445,3.445,0,0,0-3.569,3.3v.568a.3.3,0,0,1-.595,0v-.568A3.932,3.932,0,0,1,333.523,89.7Z"
        transform="translate(6088.382 6858.451)"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.2"
      />
    </g>
  </svg>
);

export const CommentGreen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.022"
    height="16.33"
    viewBox="0 0 17.022 16.33"
  >
    <g
      id="Group_59746"
      data-name="Group 59746"
      transform="translate(-2115.741 -233.995)"
    >
      <path
        id="Subtraction_4"
        data-name="Subtraction 4"
        d="M13936.676,383.325a1.4,1.4,0,0,1-.767-.227l-3.373-2.25h-2.984A4.31,4.31,0,0,1,13925,376.3v-4.748a4.31,4.31,0,0,1,4.553-4.552h7.918a4.309,4.309,0,0,1,4.552,4.552V376.3a4.272,4.272,0,0,1-3.96,4.521v1.123a1.383,1.383,0,0,1-1.386,1.386Zm-5.938-10.612a.595.595,0,0,0,0,1.19h5.543a.595.595,0,0,0,0-1.19Z"
        transform="translate(-11809.258 -133)"
        fill="#65bd50"
      />
    </g>
  </svg>
);

export const NoCommentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.021"
    height="16.332"
    viewBox="0 0 17.021 16.332"
  >
    <g
      id="Group_59747"
      data-name="Group 59747"
      transform="translate(-2115.743 -233.994)"
    >
      <g id="Group_59746" data-name="Group 59746">
        <path
          id="Vector"
          d="M6.135,1.188H.594A.6.6,0,0,1,0,.594.6.6,0,0,1,.594,0H6.135a.6.6,0,0,1,.594.594A.6.6,0,0,1,6.135,1.188Z"
          transform="translate(2120.888 239.713)"
          fill="#868991"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M11.677,16.332a1.407,1.407,0,0,1-.768-.23L7.537,13.854H4.552A4.309,4.309,0,0,1,0,9.3V4.552A4.309,4.309,0,0,1,4.552,0h7.917a4.309,4.309,0,0,1,4.552,4.552V9.3a4.269,4.269,0,0,1-3.958,4.52v1.124a1.369,1.369,0,0,1-.728,1.219A1.4,1.4,0,0,1,11.677,16.332ZM4.552,1.18A3.154,3.154,0,0,0,1.188,4.544v4.75a3.154,3.154,0,0,0,3.365,3.365H7.719a.569.569,0,0,1,.333.1l3.523,2.343a.2.2,0,0,0,.309-.166V13.252a.6.6,0,0,1,.594-.594,3.154,3.154,0,0,0,3.365-3.365V4.544A3.154,3.154,0,0,0,12.477,1.18Z"
          transform="translate(2115.743 233.994)"
          fill="#868991"
        />
      </g>
    </g>
  </svg>
);

export const LeaveIcon = () => (
  <svg
    id="note-remove"
    xmlns="http://www.w3.org/2000/svg"
    width="24.793"
    height="24.793"
    viewBox="0 0 24.793 24.793"
  >
    <path
      id="Vector"
      d="M5.134,0H2.1A1.928,1.928,0,0,0,0,2.1V5.134a1.928,1.928,0,0,0,2.1,2.1H5.134a1.928,1.928,0,0,0,2.1-2.1V2.1A1.928,1.928,0,0,0,5.134,0Zm.517,4.711a.667.667,0,0,1,0,.94.708.708,0,0,1-.475.186.683.683,0,0,1-.465-.186l-1.105-1.1-1.074,1.1a.7.7,0,0,1-.486.186.683.683,0,0,1-.465-.186.667.667,0,0,1,0-.94l1.105-1.1-1.1-1.085a.665.665,0,0,1,.94-.94l1.074,1.1,1.1-1.1a.665.665,0,0,1,.94.94L4.556,3.616Z"
      transform="translate(1.033 1.033)"
      fill="#ff8891"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M7.035.661a.61.61,0,0,1-.186.444L1.157,6.839a.649.649,0,0,1-.486.207A.667.667,0,0,1,0,6.384V2.769A2.819,2.819,0,0,1,2.851.01C3.833,0,5.2,0,6.364,0A.661.661,0,0,1,7.035.661Z"
      transform="translate(15.175 15.682)"
      fill="#ff8891"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M7.035.661a.61.61,0,0,1-.186.444L1.157,6.839a.649.649,0,0,1-.486.207A.667.667,0,0,1,0,6.384V2.769A2.819,2.819,0,0,1,2.851.01C3.833,0,5.2,0,6.364,0A.661.661,0,0,1,7.035.661Z"
      transform="translate(15.175 15.682)"
      fill="#ff8891"
    />
    <path
      id="Vector-4"
      data-name="Vector"
      d="M14.6,0H8.264A1.036,1.036,0,0,0,7.231,1.033V4.649a3.1,3.1,0,0,1-3.1,3.1h-3.1A1.036,1.036,0,0,0,0,8.781V15.63a5.03,5.03,0,0,0,5.031,5.031H10.01a1.036,1.036,0,0,0,1.033-1.033V16.384a4.359,4.359,0,0,1,4.4-4.308c.548-.01,1.88-.01,3.151-.01a1.029,1.029,0,0,0,1.033-1.033v-6A5.03,5.03,0,0,0,14.6,0ZM6.426,15.506H3.7a.78.78,0,0,1,0-1.56H6.426a.78.78,0,0,1,0,1.56Zm2.882-3.833H3.7a.78.78,0,0,1,0-1.56H9.308a.78.78,0,1,1,0,1.56Z"
      transform="translate(2.583 2.066)"
      fill="#ff8891"
    />
    <path
      id="Vector-5"
      data-name="Vector"
      d="M0,0H24.793V24.793H0Z"
      transform="translate(24.793 24.793) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);
