/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
// import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import ImgEstimate from "../../../../assets/images/Estimate.svg";
import Imgmenu from "../../../../assets/images/menu.svg";
import "../../../../design/custom/pages/time-estimate.scss";
import Profile from "../../../../components/DropDowns/Profile";
import { Priority } from "../../../../components/DropDowns/priority";
import { Opencalender } from "../../../../components/DropDowns/Calender";
import Threedots from "../../../../components/DropDowns/Threedots";
import EditForm from "./EditForm";
import CommonSkeleton from "../../../../components/SkeletonLoader/CommonSkeleton";

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const month = date.toLocaleString("en-US", { month: "short" });
  const day = date.getDate();
  return `${month} ${day}`;
};

const CardContent = ({
  Allocations,
  setEditableTitle,
  editableTitle,
  setSelectedTicket,
  setPatchToggle,
  PatchToggle,
  isTicket,
  ActionButtons,
  patchFunction,
  selectedRows,
  IsEditLoading,
  setSelectedRows,
  timeEstimatePermission,
  assigneePermission,
  priorityPermission,
  dueDatePermission,
  viewPermission,
  dueDatePermissionEdit,
  priorityPermissionEdit,
  assigneePermissionEdit,
  timeEstimatePermissionEdit,
}) => {
  // const [dropdownVisible, setDropdownVisible] = useState(false);
  // console.log("dropdownVisible", dropdownVisible);

  const formattedEndDate = formatDate(Allocations?.dueDate);
  const date = `${formattedEndDate}`;

  const renderTitle = () => {
    if (editableTitle === Allocations?._id) {
      return (
        <EditForm
          patchFunction={patchFunction}
          id={Allocations?._id}
          fieldName="title"
          IsEditable={setEditableTitle}
          value={Allocations?.title}
        />
      );
    }
    return (
      <div style={{ cursor: "pointer" }}>
        <div
          onClick={() => {
            if (viewPermission) {
              setSelectedTicket(Allocations?.ticketInfo?._id);
              isTicket(true);
            }
          }}
        >
          {Allocations?.ticketInfo?.title}
        </div>
        <div
          onClick={() => {
            if (viewPermission) {
              setSelectedTicket(Allocations?._id);
              isTicket(false);
            }
          }}
        >
          {Allocations?.title}
          {Allocations?.description && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ zIndex: 9999 }}>
                  {Allocations?.description}
                </Tooltip>
              }
            >
              <span
                className="ms-1 menu"
                style={{ position: "relative", display: "inline-block" }}
              >
                <img src={Imgmenu} alt="icon" style={{ cursor: "pointer" }} />
              </span>
            </OverlayTrigger>
          )}
        </div>
      </div>
    );
  };

  return IsEditLoading === Allocations?._id ? (
    <div className="mb-2" style={{ marginLeft: "12%" }}>
      <CommonSkeleton height={120} width={250} />
    </div>
  ) : (
    <div
      className={
        selectedRows?.length > 0 &&
        selectedRows.some((e) => e._id === Allocations._id)
          ? "project-cards active"
          : selectedRows?.length > 0
          ? "project-cards active border-0"
          : "project-cards"
      }
    >
      <div className="sct-top">
        <div className="chekbxOtr">
          <label className="custom_radio_btn" htmlFor="myCheckbox">
            <input
              checked={selectedRows.some((e) => e._id === Allocations._id)}
              type="checkbox"
              name="checkbox"
              id="myCheckbox"
            />
            <span
              onClick={() => {
                if (selectedRows.some((e) => e._id === Allocations._id)) {
                  setSelectedRows(
                    selectedRows.filter(
                      (eachRow) => eachRow._id !== Allocations._id,
                    ),
                  );
                } else {
                  setSelectedRows([...selectedRows, Allocations]);
                }
              }}
              className="checkmark"
            />
          </label>
        </div>

        <div className="project-cards-head spc">
          <div className="project-card-name-area">
            <div className="project-card-project-info d-flex">
              <ul className="project-card-nav z-1">
                <div className="project-name-head">
                  <div className="project-task-name">{renderTitle()}</div>
                </div>
              </ul>
              <div className="project-card-team-info ms-auto">
                {assigneePermission && (
                  <Profile
                    TaskData={Allocations}
                    dropDownVisibility={assigneePermission}
                    type="allocations"
                    dbName="assignedUsers"
                    patchFunction={patchFunction}
                    className="info-td d-flex pe-2 justify-content-end"
                    dropDownVisibilityPermissionConstant={
                      assigneePermissionEdit
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="project-task-view-area spc">
          <div className="info-items d-flex">
            {priorityPermission && (
              <Priority
                TaskData={Allocations}
                dropDownVisibility
                type="allocations"
                dbName="priority"
                patchFunction={patchFunction}
                dropDownVisibilityPermissionConstant={priorityPermissionEdit}
              />
            )}
            <div className="info d-flex align-items-center justify-content-center">
              {dueDatePermission && (
                <Opencalender
                  TaskData={Allocations}
                  dropDownVisibility
                  type="allocations"
                  dbName="dueDate"
                  patchFunction={patchFunction}
                  date={date}
                  dropDownVisibilityPermissionConstant={dueDatePermissionEdit}
                />
              )}
            </div>
            {timeEstimatePermission && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip style={{ zIndex: 9999 }}>Time Estimate</Tooltip>
                }
              >
                <div className="info d-flex align-items-center justify-content-center ">
                  <img
                    onClick={() => {
                      if (timeEstimatePermissionEdit)
                        setPatchToggle(Allocations?._id);
                    }}
                    src={ImgEstimate}
                    alt=""
                    style={{ cursor: "pointer" }}
                    className="icon-info"
                  />
                  {PatchToggle === Allocations?._id ? (
                    <EditForm
                      patchFunction={patchFunction}
                      id={Allocations?._id}
                      fieldName="estimationHours"
                      IsEditable={setPatchToggle}
                      value={Allocations?.estimationHours}
                      type="number"
                    />
                  ) : (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (timeEstimatePermissionEdit)
                          setPatchToggle(Allocations?._id);
                      }}
                    >
                      {`${Allocations?.estimationHours || "-"}`}
                    </div>
                  )}
                </div>
              </OverlayTrigger>
            )}

            {ActionButtons && (
              <Threedots
                dropDownVisibility
                type="allocations"
                TaskData={Allocations}
                ActionButtons={ActionButtons}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const ProjectCards = ({
  Content,
  Status,
  editableTitle,
  setEditableTitle,
  setSelectedTicket,
  isTicket,
  ActionButtons,
  patchFunction,
  setPatchToggle,
  PatchToggle,
  setSelectedRows,
  selectedRows,
  IsEditLoading,
  timeEstimatePermission,
  assigneePermission,
  priorityPermission,
  dueDatePermission,
  viewPermission,
  timeEstimatePermissionEdit,
  assigneePermissionEdit,
  priorityPermissionEdit,
  dueDatePermissionEdit,
}) => {
  return Content?.map((Allocations) => (
    <Draggable
      key={Allocations._id}
      draggableId={Allocations._id}
      index={Content.indexOf(Allocations)}
    >
      {(provider) => (
        <div
          ref={provider.innerRef}
          {...provider.draggableProps}
          {...provider.dragHandleProps}
        >
          <CardContent
            Status={Status}
            Allocations={Allocations}
            setEditableTitle={setEditableTitle}
            editableTitle={editableTitle}
            setSelectedTicket={setSelectedTicket}
            isTicket={isTicket}
            ActionButtons={ActionButtons}
            patchFunction={patchFunction}
            setPatchToggle={setPatchToggle}
            PatchToggle={PatchToggle}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            IsEditLoading={IsEditLoading}
            timeEstimatePermission={timeEstimatePermission}
            assigneePermission={assigneePermission}
            priorityPermission={priorityPermission}
            dueDatePermission={dueDatePermission}
            viewPermission={viewPermission}
            timeEstimatePermissionEdit={timeEstimatePermissionEdit}
            assigneePermissionEdit={assigneePermissionEdit}
            priorityPermissionEdit={priorityPermissionEdit}
            dueDatePermissionEdit={dueDatePermissionEdit}
          />
        </div>
      )}
    </Draggable>
  ));
};

ProjectCards.propTypes = {
  Content: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
  Status: PropTypes.shape({
    title: PropTypes.string,
  }),
};
CardContent.propTypes = {
  listInfo: PropTypes.shape({ name: PropTypes.string }),
  ticketInfo: PropTypes.shape({
    title: PropTypes.string,
    _id: PropTypes.string,
  }),
  setSelectedTicket: PropTypes.func,
  isTicket: PropTypes.func,
  editableTitle: PropTypes.bool,
  setEditableTitle: PropTypes.bool,
};

export default ProjectCards;
