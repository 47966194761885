import Gateway from "../gateway/gateway";

const addNotes = (data) =>
  Gateway.post("/notes", data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

const getNotes = (params) => Gateway.get("/notes", { params });

const deleteNotes = (id) => Gateway.delete(`/notes/${id}`);

const updateNotes = ({ id, data }) =>
  Gateway.patch(`/notes/${id}`, data, {
    headers: { "Content-Type": "Multipart/formdata" },
  });

export default {
  addNotes,
  getNotes,
  deleteNotes,
  updateNotes,
};
