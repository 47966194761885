/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEqual, debounce } from "lodash";
import "../../../design/custom/pages/projectmanagement-board.scss";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import Cards from "./components/Cards";
import { useThunk } from "../../../hooks/useThunk";
import { deleteTicketAllocation } from "../../../store/slices/ticket/thunks/deleteTicketAllocation";
import { getProjectsById } from "../../../store/slices/project/thunks/getProjectById";
import { useFilter } from "../../../context/useFilter";
import { getTicketAllocations } from "../../../store/slices/ticket/thunks/getTicketAllocations";
import { getUser, patchTicket, patchTicketAllocation } from "../../../store";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import deleteSvg from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/icon-edit.svg";
import {
  setSuccess,
  setTicketAllocationData,
} from "../../../store/slices/ticket";
import { filterQuery } from "../components/Gantt/filter";
import { Deletemodal } from "../../../components/DropDowns/DeleteModal";
import BulkActions from "../../../components/BulkActions/BulkActions";
import TableHeaderGroupBySwither from "./components/HeaderGroupBySwither";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import {
  hasSubModulePermission,
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";
import { alertActions } from "../../../store/slices/alert";
import UserNoData from "../../UserProfile/UserNoData";

const TicketManagementBoard = () => {
  const { listId, shortCode, type } = useParams();
  const { filter } = useFilter();
  const [filterData] = useThunk(getFilterDropdown);
  const [deleteTicket, deleteLoader] = useThunk(deleteTicketAllocation);
  const [projectByIdThunk] = useThunk(getProjectsById);
  const [userByProjectThunk] = useThunk(getUser);
  const [patchTicketAllocationThunk] = useThunk(patchTicketAllocation);
  const [fetchTicketThunk, ticketBoardLoader] = useThunk(getTicketAllocations);
  const [patchTickets] = useThunk(patchTicket);

  const { ticketAllocations, Success, ticketCount } = useSelector(
    ({ ticketReducer }) => ticketReducer,
  );
  const { projectData, projectById } = useSelector((state) => state.project);
  const { userProfile } = useSelector((state) => state.userProfile);
  const { ticketStatus } = useSelector((s) => s.ticketReducer);

  const initialParams = {
    [type === "sprint" ? "sprintId" : "listId"]: listId,
    groupBy:
      filter?.groupBy === "none" || filter?.groupBy === ""
        ? "ticketStatus"
        : filter.groupBy,
    page: 1,
    limit: 15,
    type: "board",
  };
  const [allocationData, setallocationData] = useState([]);
  const [oldAllocationCount, setOldAllocationCount] = useState([]);
  const [PatchToggle, setPatchToggle] = useState(null);
  const [editableTitle, setEditableTitle] = useState(false);
  const [selectedTicket, setSelectedTicket, setShowModal, ticket, isTicket] =
    useOutletContext();
  const [deleteModal, setdeleteModal] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [editLoader, setEditLoader] = useState("");
  const [TicketCount, setTicketCount] = useState([]);
  const [GetState, setGetState] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const prevPageRef = useRef(null);

  useEffect(() => {
    if (ticketCount?.length > 0) {
      setTicketCount(ticketCount);
    }
  }, [ticketCount]);

  useEffect(() => {
    setGetState(true);
    const isTicketDataInTaskData = allocationData?.some((item) =>
      ticketAllocations?.some((newItem) =>
        newItem.data.every((newDataItem) =>
          item.data.some((oldDataItem) => isEqual(oldDataItem, newDataItem)),
        ),
      ),
    );

    if (allocationData.length > 0 && isTicketDataInTaskData) return;

    if (ticketAllocations?.length > 0) {
      const updatedTicketData = allocationData.map((originalItem) => {
        const newItem = ticketAllocations.find(
          (newItem) => newItem.groupById === originalItem.groupById,
        );

        if (newItem) {
          return {
            groupById: originalItem.groupById,
            data: [...originalItem.data, ...newItem.data],
          };
        }
        return originalItem;
      });
      const oldData = allocationData.map((e) => e.groupById);
      const newData = ticketAllocations.map((e) => e.groupById);
      const checkExists = newData.some((element) => oldData.includes(element));

      if (!checkExists) updatedTicketData.push(...ticketAllocations);

      setallocationData(updatedTicketData);
    }
  }, [ticketAllocations]);

  useEffect(() => {
    filterData("board");
  }, []);

  useEffect(() => {
    if (selectedTicket) {
      if (ticket) {
        setSearchParams({ TicketId: selectedTicket });
      } else {
        setSearchParams({ Task: selectedTicket });
      }
      setShowModal(true);
    }
  }, [selectedTicket]);

  useEffect(() => {
    const project = projectData?.filter((item) => item.shortCode === shortCode);
    if (project && project[0]?._id) {
      projectByIdThunk(project[0]?._id);
      userByProjectThunk(project[0]?._id);
    }
  }, [projectData]);

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      fetchTicketThunk({ params, key: "board" });
    }

    return () => {
      dispatch(setTicketAllocationData({ data: null, reset: true }));
    };
  }, [params]);

  const matches = filterQuery(filter.filter);

  const previousMatchesRef = useRef(matches);

  useEffect(() => {
    if (!isEqual(matches, previousMatchesRef.current)) {
      setParams({ ...params, matches });
      setallocationData([]);
      previousMatchesRef.current = matches;
    }
  }, [matches]);

  useEffect(() => {
    let updatedParams = { ...params };
    const initialParams = {
      page: 1,
      limit: 15,
      type: "board",
    };
    if (filter.me) {
      setallocationData([]);
      updatedParams = { ...updatedParams, ...initialParams, me: filter.me };
    }
    if (!filter.me) {
      setallocationData([]);
      updatedParams = { ...updatedParams, ...initialParams, me: "" };
    }
    if (filter.search) {
      setallocationData([]);
      updatedParams = {
        ...updatedParams,
        ...initialParams,
        title: filter.search,
      };
    }
    if (!filter.search) {
      setallocationData([]);
      updatedParams = { ...updatedParams, ...initialParams, title: "" };
    }
    if (filter.assignee) {
      setallocationData([]);
      updatedParams = {
        ...updatedParams,
        ...initialParams,
        assignee: filter.assignee,
      };
    }
    if (filter.groupBy) {
      setallocationData([]);
      updatedParams = {
        ...updatedParams,
        ...initialParams,
        groupBy:
          filter?.groupBy === "none" || filter?.groupBy === ""
            ? "ticketStatus"
            : filter.groupBy,
      };
    }

    setParams(updatedParams);
  }, [filter.search, filter.me, filter.assignee, filter.show, filter.groupBy]);

  useEffect(() => {
    if (allocationData?.length > 0) {
      setOldAllocationCount((prevCount) => {
        return (
          allocationData?.map(({ data }) => ({
            count: data?.length || 0,
          })) || prevCount
        );
      });
    } else {
      setOldAllocationCount([{ count: 0 }]);
    }
  }, [allocationData]);

  useEffect(() => {
    const { key, success, type, payload } = Success;

    if (key === "patch") {
      if (success) {
        if (success.length > 0) {
          let updatedData = allocationData;
          if (payload && Object.keys(payload)?.includes(params?.groupBy)) {
            const taskStatusUpdated = updatedData.map((item) => ({
              ...item,
              data: item.data.filter(
                (subItem) => !success.some((i) => i._id === subItem._id),
              ),
            }));

            setallocationData(
              taskStatusUpdated?.map((i) =>
                i.groupById === payload?.[params?.groupBy]
                  ? { groupById: i.groupById, data: [...success, ...i.data] }
                  : i,
              ),
            );
          } else {
            success?.forEach((s) => {
              updatedData = updatedData?.map(({ groupById, data }) => {
                const updatedData = data?.map((allocation) =>
                  allocation?._id === s?._id ? s : allocation,
                );
                return { groupById, data: updatedData };
              });
            });

            setallocationData(updatedData);
          }
        } else {
          setallocationData(
            allocationData?.map(({ groupById, data }) => {
              const updatedData = data?.map((allocation) =>
                allocation?._id === success?._id ? success : allocation,
              );
              return { groupById, data: updatedData };
            }),
          );
        }
        setEditLoader("");
      }
    }
    if (key === "delete") {
      if (type !== "allocation") return;

      if (success?.length > 0) {
        const ids = success?.map((e) => e._id);
        setallocationData(
          allocationData?.map(({ groupById, data }) => {
            const updatedData = data?.filter(
              (allocation) => !ids.includes(allocation?._id),
            );
            return { groupById, data: updatedData };
          }),
        );
      } else {
        setallocationData(
          allocationData?.map(({ groupById, data }) => {
            const updatedData = data?.filter(
              (allocation) => allocation?._id !== success?._id,
            );
            return { groupById, data: updatedData };
          }),
        );
      }
    }
    if (key === "create") {
      let indexCount;
      if (allocationData.length >= 1) {
        setallocationData(
          allocationData?.map(({ groupById, data }, index) => {
            if (groupById === success?.groupById) {
              indexCount = index;
              const updatedData = [...success?.data[0]?.allocations, ...data];
              return { groupById, data: updatedData };
            }
            return { groupById, data };
          }),
        );
      } else {
        setallocationData([success] || []);
      }
    }

    dispatch(setSuccess(false));
  }, [Success]);

  useEffect(() => {
    if (GetState) {
      setGetState(false);
      return;
    }

    if (oldAllocationCount.length > 0 && allocationData) {
      const newAllocationCount = allocationData.map(({ data }) => ({
        count: data?.length || 0,
      }));

      let updatedCount = oldAllocationCount.map((e, index) => ({
        count: newAllocationCount[index]?.count - e?.count,
      }));

      updatedCount = TicketCount?.map((e, index) => {
        if (updatedCount[index]?.count > 0) {
          return { count: e.count + updatedCount[index]?.count };
        }
        if (updatedCount[index]?.count < 0) {
          return { count: e.count + updatedCount[index]?.count };
        }
        return { count: e.count };
      });

      setTicketCount(updatedCount);
    }
  }, [oldAllocationCount, allocationData]);

  const hasPermission = (permission, subModule) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules[subModule],
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const patchFunction = (type, patchData) => {
    setEditLoader(patchData?.id);

    if (type === "Ticket") patchTickets(patchData);
    if (type === "allocations") patchTicketAllocationThunk(patchData);
  };

  const handleDragAndDrop = (result) => {
    if (!result.destination) return;

    const completed = ticketStatus?.status?.find(
      ({ title }) => title === "COMPLETE",
    );

    const currentTicket = allocationData
      ?.map(({ data }) => data?.find(({ _id }) => _id === result.draggableId))
      .filter((e) => e !== undefined);

    const currentStatus = ticketStatus?.status?.find(
      ({ _id }) => _id === currentTicket[0]?.ticketStatus,
    );

    const completePermission =
      !hasPermission(
        permissionConstants.permissions.COMMON_TICKET_MARK_AS_COMPLETED,
        "COMMON_TICKET_SETTINGS",
      ) && result.destination.droppableId === completed?._id;

    if (completePermission) {
      dispatch(
        alertActions.error(
          `You dont have permission to change the status from ${currentStatus?.title} to COMPLETE.`,
        ),
      );
      return;
    }

    if (currentStatus?._id === result.destination.droppableId) return;

    patchTicketAllocationThunk({
      id: result.draggableId,
      patchData: { [params.groupBy]: result.destination.droppableId },
    });

    const filteredsss = allocationData
      ?.map(({ groupById, data }) => ({
        groupById,
        data: data?.find(
          (allocation) => allocation?._id === result.draggableId,
        ),
      }))
      .filter(({ data }) => data !== undefined);

    if (filteredsss[0]?.data?.ticketStatus === completed?._id) {
      return;
    }

    setallocationData((prevAllocationData) =>
      prevAllocationData?.map(({ groupById, data }) => {
        const filteredData = data?.filter(
          (allocation) => allocation?._id !== result.draggableId,
        );

        let updatedData;

        if (result.destination.droppableId === groupById) {
          updatedData = [...filteredData, filteredsss[0]?.data];
        } else {
          updatedData = filteredData;
        }

        return { groupById, data: updatedData };
      }),
    );
  };

  const closeDeleteModal = () => {
    setdeleteModal({
      openclose: false,
      type: "",
      name: "",
      id: "",
    });
  };

  const handleTicketDelete = (id, type) => {
    if (type === "allocations") {
      closeDeleteModal();
      deleteTicket({ id });
    }
  };

  const handleDeleteModalClick = (id, type, TaskData) => {
    setdeleteModal({
      openclose: true,
      type,
      name: TaskData?.title,
      id,
    });
  };

  const scrollContainerRefs = useRef([]);

  const [ParamsForAllScrolls, setParamsForAllScrolls] = useState([]);

  useEffect(() => {
    if (TicketCount?.length > 0 && ParamsForAllScrolls?.length <= 0) {
      setParamsForAllScrolls(
        TicketCount?.map((e, index) => {
          return {
            ...params,
            [type === "sprint" ? "sprintId" : "listId"]: listId,
            groupBy: filter.groupBy || "ticketStatus",
            page: 1,
            limit: 15,
            dataCount: index,
          };
        }),
      );
    }
  }, [TicketCount]);

  useEffect(() => {
    setParamsForAllScrolls(
      ParamsForAllScrolls?.map((e) =>
        e.dataCount === params.dataCount ? params : e,
      ),
    );
  }, [params.page]);

  const fetchMoreData = (index) => {
    if (
      ParamsForAllScrolls[index]?.limit * ParamsForAllScrolls[index]?.page <=
      TicketCount[ParamsForAllScrolls[index]?.dataCount]?.count
    ) {
      const updatedParams = ParamsForAllScrolls?.map((e, i) =>
        i === index
          ? {
              ...e,
              page: e.page + 1,
              limit: 15,
              type: "",
              groupBy: filter.groupBy || "ticketStatus",
            }
          : { ...e, groupBy: filter.groupBy || "ticketStatus" },
      );
      setParams(updatedParams[index]);
    }
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  useEffect(() => {
    const handleScroll = debounce(() => {
      scrollContainerRefs.current.forEach((scrollContainer, index) => {
        if (scrollContainer) {
          const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
          const isAtBottom =
            Math.ceil(scrollTop + clientHeight) >= scrollHeight;
          if (isAtBottom) {
            fetchMoreData(index);
          }
        }
      });
    }, 100);

    scrollContainerRefs.current.forEach((scrollContainer) => {
      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", handleScroll, {
          passive: true,
        });
      }
    });

    return () => {
      scrollContainerRefs.current.forEach((scrollContainer) => {
        if (scrollContainer) {
          scrollContainer.removeEventListener("scroll", handleScroll);
        }
      });
    };
  }, [TicketCount, fetchMoreData, scrollContainerRefs]);

  const handleTitleEdit = (id) => setEditableTitle(id);
  const closeAction = () => {
    setSelectedRows([]);
  };

  const ActionButtons = [
    {
      name: "Delete",
      onclickfunction: handleDeleteModalClick,
      className: "text-danger",
      icon: deleteSvg,
      permissionConstant: "COMMON_TICKET_LIST_ACTIONS_DELETE",
      subModuleConstant: "COMMON_TICKET_SETTINGS",
    },
    // {
    //   name: "Rename",
    //   onclickfunction: handleTitleEdit,
    //   className: "w-75 ms-1",
    //   icon: editIcon,
    //   permissionConstant: "PROJECT_MANAGEMENT_BOARD_RENAME",
    //   subModuleConstant: "PROJECT_MANAGEMENT_BOARD_VIEW",
    // },
  ];

  const chechkData = allocationData?.filter(({ data }) => data?.length > 0);

  return (
    <>
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_BOARD_SEARCH,
          "PROJECT_MANAGEMENT_BOARD_VIEW",
        )}
        showFilter={hasPermission(
          permissionConstants.permissions
            .PROJECT_MANAGEMENT_BOARD_CUSTOM_FILTER,
          "PROJECT_MANAGEMENT_BOARD_VIEW",
        )}
        showGroupBy={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_BOARD_GROUPBY,
          "PROJECT_MANAGEMENT_BOARD_VIEW",
        )}
        showMe={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_BOARD_ME,
          "PROJECT_MANAGEMENT_BOARD_VIEW",
        )}
        showAssignee
        type="board"
      />
      <BulkActions
        selectedTasks={selectedRows}
        setSelectedTasks={setSelectedRows}
        closeAction={closeAction}
        showAssignee={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_ASSIGNEE_EDIT,
          "COMMON_TICKET_SETTINGS",
        )}
        showCopy
        showDelete={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_ACTIONS_DELETE,
          "COMMON_TICKET_SETTINGS",
        )}
        showDueDate={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_DUE_DATE_EDIT,
          "COMMON_TICKET_SETTINGS",
        )}
        showMoveSubtask
        showPriority={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_PRIORITY_EDIT,
          "COMMON_TICKET_SETTINGS",
        )}
        showStatus={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_STATUS_EDIT,
          "COMMON_TICKET_SETTINGS",
        )}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="project-tile-area d-flex">
              {ticketBoardLoader &&
                allocationData?.length <= 0 &&
                Array?.from({ length: 4 }).map(() => (
                  <div className="d-flex flex-column">
                    <div className="d-flex ms-2">
                      <CommonSkeleton height={60} width={240} />
                    </div>
                    <div className="ms-2">
                      {Array?.from({ length: 50 }).map(() => (
                        <CommonSkeleton height={120} width={240} />
                      ))}
                    </div>
                  </div>
                ))}
              {chechkData?.length <= 0 && !ticketBoardLoader && <UserNoData />}
              {chechkData?.length > 0 && (
                <DragDropContext onDragEnd={handleDragAndDrop}>
                  {allocationData?.length > 0 &&
                    allocationData?.map(({ groupById, data }, index) => (
                      <Droppable droppableId={groupById} key={groupById}>
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <div className="project-process" key={groupById}>
                              <TableHeaderGroupBySwither
                                groupById={groupById}
                                groupBy={filter.groupBy}
                                dataLength={
                                  TicketCount?.length > 0 &&
                                  TicketCount[index]?.count
                                }
                              />
                              <div
                                className="project-process-card custom-scroll"
                                ref={(ref) => {
                                  if (ref) {
                                    if (ref.scrollHeight > ref.clientHeight) {
                                      scrollContainerRefs.current[index] = ref;
                                    }
                                  }
                                }}
                              >
                                <Cards
                                  Status={groupById}
                                  Content={data}
                                  editableTitle={editableTitle}
                                  setEditableTitle={setEditableTitle}
                                  projectById={projectById}
                                  setSelectedTicket={setSelectedTicket}
                                  isTicket={isTicket}
                                  handleDeleteModalClick={
                                    handleDeleteModalClick
                                  }
                                  ActionButtons={
                                    hasPermission(
                                      permissionConstants.permissions
                                        .PROJECT_MANAGEMENT_BOARD_ACTIONS,
                                      "PROJECT_MANAGEMENT_BOARD_VIEW",
                                    ) &&
                                    ActionButtons?.filter((e) =>
                                      hasPermission(
                                        e.permissionConstant,
                                        e.subModuleConstant,
                                      ),
                                    )
                                  }
                                  patchFunction={patchFunction}
                                  setPatchToggle={setPatchToggle}
                                  PatchToggle={PatchToggle}
                                  setSelectedRows={setSelectedRows}
                                  selectedRows={selectedRows}
                                  IsEditLoading={editLoader}
                                  timeEstimatePermission={hasPermission(
                                    permissionConstants.permissions
                                      .PROJECT_MANAGEMENT_BOARD_SHOW_TIME_ESTIMATE,
                                    "PROJECT_MANAGEMENT_BOARD_VIEW",
                                  )}
                                  assigneePermission={hasPermission(
                                    permissionConstants.permissions
                                      .COMMON_TICKET_LIST_ASSIGNEE,
                                    "COMMON_TICKET_SETTINGS",
                                  )}
                                  priorityPermission={hasPermission(
                                    permissionConstants.permissions
                                      .COMMON_TICKET_LIST_PRIORITY,
                                    "COMMON_TICKET_SETTINGS",
                                  )}
                                  dueDatePermission={hasPermission(
                                    permissionConstants.permissions
                                      .COMMON_TICKET_LIST_DUE_DATE,
                                    "COMMON_TICKET_SETTINGS",
                                  )}
                                  timeEstimatePermissionEdit={hasPermission(
                                    permissionConstants.permissions
                                      .PROJECT_MANAGEMENT_BOARD_SHOW_TIME_ESTIMATE_EDIT,
                                    "PROJECT_MANAGEMENT_BOARD_VIEW",
                                  )}
                                  assigneePermissionEdit={hasPermission(
                                    permissionConstants.permissions
                                      .COMMON_TICKET_LIST_ASSIGNEE_EDIT,
                                    "COMMON_TICKET_SETTINGS",
                                  )}
                                  priorityPermissionEdit={hasPermission(
                                    permissionConstants.permissions
                                      .COMMON_TICKET_LIST_PRIORITY_EDIT,
                                    "COMMON_TICKET_SETTINGS",
                                  )}
                                  dueDatePermissionEdit={hasPermission(
                                    permissionConstants.permissions
                                      .COMMON_TICKET_LIST_DUE_DATE_EDIT,
                                    "COMMON_TICKET_SETTINGS",
                                  )}
                                  viewPermission={hasSubModulePermission(
                                    userProfile?.formattedPermissions,
                                    "TASK_DETAILS_MODAL",
                                  )}
                                />
                                {allocationData[index]?.data?.length <
                                  TicketCount[index]?.count && (
                                  <div style={{ marginLeft: "12%" }}>
                                    <CommonSkeleton height={120} width={240} />
                                  </div>
                                )}
                              </div>
                              {provided.placeholder}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    ))}
                </DragDropContext>
              )}
            </div>
          </div>
        </div>
      </div>

      {deleteModal?.openclose ? (
        <Deletemodal
          loaderButton={deleteLoader}
          ModalShow={deleteModal}
          closeModal={closeDeleteModal}
          cancelModal={closeDeleteModal}
          deleteFunc={handleTicketDelete}
          type={deleteModal?.type}
        />
      ) : null}
    </>
  );
};

export default TicketManagementBoard;
