import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

export const cols = [
  {
    name: "Name",
    selector: "name",
    icon: true,
    hasPermission: permissionConstants.permissions.REPORTS_LIST_NAME,
  },
  {
    name: "Total No. Of Working Days",
    selector: "workingDaysInMonth",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_TOTAL_NUMBER_OF_WORKING_DAYS,
  },
  {
    name: "Hours Allocated Per Day",
    selector: "totalAllocatedHoursPerDay",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_HOURS_ALLOCATED_PER_DAY,
  },
  {
    name: " Total Hours Worked",
    selector: "totalHoursWorked",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_TOTAL_HOURS_WORKED,
  },
  {
    name: " Total Productive Hours",
    selector: "totalProductiveHour",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_TOTAL_HOURS_WORKED,
  },
  {
    name: "Total Active Projects",
    selector: "totalActiveProjects",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_TOTAL_ACTIVE_PROJECTS,
  },
  {
    name: "Total Tickets",
    selector: "totalTickets",
    hasPermission: permissionConstants.permissions.REPORTS_LIST_TOTAL_TICKETS,
  },
  {
    name: " Last Worked On",
    selector: "lastWorkedOn",
    hasPermission: permissionConstants.permissions.REPORTS_LIST_LAST_WORKED_ON,
  },

  {
    name: "No. Of Projects Allocated",
    selector: "totalAllocatedProjects",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_NO_OF_PROJECTS_ALLOCATED,
  },
  {
    name: "No. Of QA Rejections",
    selector: "totalQaRejections",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_NO_OF_QA_REJECTIONS,
  },
  {
    name: "No. Of First Approved Tickets",
    selector: "firstApprovedTickets",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_NO_OF_QA_REJECTIONS,
  },

  {
    name: "No. Of Leaves",
    selector: "leavesTakenInMonth",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_TOTAL_NUMBER_OF_LEAVES,
  },
  {
    name: "Quality Index",
    selector: "qualityIndex",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_TOTAL_NUMBER_OF_LEAVES,
  },
  {
    name: "Performance Index",
    selector: "performanceIndex",
    hasPermission:
      permissionConstants.permissions.REPORTS_LIST_TOTAL_NUMBER_OF_LEAVES,
  },
];
