/* eslint-disable no-nested-ternary */
import { RightArrow } from "./icons";
import { getInitials, formatDateStructure } from "../../utils/utils";
import ContentLayout from "./ContentLayout";

function renderNotificationContent(
  item,
  index,
  userProfileData,
  renderCommentTooltip,
) {
  switch (item?.activityTypeData?.templateKey) {
    case "task":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="usr-name">
            {item?.ActivityLogs?.payload?.ticketName}
          </div>
        </ContentLayout>
      );
    case "assignedtome":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="usr-group">
            <div className={`usr-avt bg-${(index % 4) + 1}`}>
              {getInitials(item?.ActivityLogs?.payload?.newAssignee)}
            </div>
          </div>
          <span className="auth-info">You</span>
          <div className="usr-name">{item.ActivityLogs?.payload?.language}</div>
        </ContentLayout>
      );
    case "comment":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <span className="cmt-txt">{item.activityTypeData?.name}</span>
          <div className="cmt-block-cover">
            <div className="cmt-block">
              {renderCommentTooltip(item.ActivityLogs?.payload?.comment)}
            </div>
          </div>
        </ContentLayout>
      );
    case "changeStatus":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="notification-item-block">
            <div
              className={`notification-item  notification-text ${
                item.ActivityLogs?.payload?.prevData === "TODO"
                  ? "todo"
                  : item.ActivityLogs?.payload?.prevData === "COMPLETE"
                  ? "closed"
                  : item.ActivityLogs?.payload?.prevData === "READY FOR QA"
                  ? "ready-qa"
                  : "in-progress"
              }`}
            >
              {item.ActivityLogs?.payload?.prevData}
            </div>

            <span className="status-arw">
              <img src={RightArrow} alt="" />
            </span>
            <div
              className={`notification-item  notification-text ${
                // eslint-disable-next-line no-nested-ternary
                item.ActivityLogs?.payload?.newData === "TODO"
                  ? "todo"
                  : item.ActivityLogs?.payload?.newData === "COMPLETE"
                  ? "closed"
                  : item.ActivityLogs?.payload?.newData === "READY FOR QA"
                  ? "ready-qa"
                  : "in-progress"
              }`}
            >
              {item.ActivityLogs?.payload?.newData}
            </div>
          </div>
        </ContentLayout>
      );
    case "date":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          {item.ActivityLogs?.payload?.prevData?.length ? (
            <div className="notification-item-block me-2">
              <div className="notification-item sprint-box calendar">
                {formatDateStructure(item.ActivityLogs?.payload?.prevData)}
              </div>
              <span className="status-arw">
                <img src={RightArrow} alt="" />
              </span>
              <div className="notification-item sprint-box calendar">
                {formatDateStructure(item.ActivityLogs?.payload?.newData)}
              </div>
            </div>
          ) : (
            <div className="notification-item-block me-2">
              <div className="notification-item sprint-box calendar">
                {formatDateStructure(item.ActivityLogs?.payload?.newData)}
              </div>
            </div>
          )}
        </ContentLayout>
      );
    case "due-change":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          {item.ActivityLogs?.payload?.prevData?.length ? (
            <div className="notification-item-block me-2">
              <div className="notification-item sprint-box calendar">
                {formatDateStructure(item.ActivityLogs?.payload?.prevData)}
              </div>
              <span className="status-arw">
                <img src={RightArrow} alt="" />
              </span>
              <div className="notification-item sprint-box calendar">
                {formatDateStructure(item.ActivityLogs?.payload?.newData)}
              </div>
            </div>
          ) : (
            <div className="notification-item-block me-2">
              <div className="notification-item sprint-box calendar">
                {formatDateStructure(item.ActivityLogs?.payload?.newData)}
              </div>
            </div>
          )}
        </ContentLayout>
      );
    case "duedate":
      return (
        <ContentLayout date={item.createdAt}>
          <div className="notification-text overdue">
            {item.activityTypeData?.name}
          </div>
          <span className="overdue-date">
            {formatDateStructure(item.ActivityLogs?.payload?.date)}
          </span>
          {/* <span className="reschedule">Reschedule</span> */}
        </ContentLayout>
      );
    case "assignee-change":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text auth-highlight text-white">
            {item.activityTypeData?.name}
          </div>
          <div className="usr-group">
            {item.ActivityLogs?.payload?.newData?.map((assignee) => (
              <div key={assignee} className="usr-avt bg-4">
                {getInitials(assignee)}
              </div>
            ))}
          </div>
          {/* <span className="auth-info">You</span> */}
        </ContentLayout>
      );
    case "title-update":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <span className="cmt-txt old">
            {item.ActivityLogs?.payload?.prevData}
          </span>
          <span className="cmt-txt">{item.ActivityLogs?.payload?.newData}</span>
        </ContentLayout>
      );
    case "des-update":
      return (
        <>
          <ContentLayout date={item.createdAt}>
            {renderName(item, userProfileData, index)}
            <span className="cmt-txt">{item.activityTypeData?.name}</span>
          </ContentLayout>
          <div className="task-details-wrapper d-flex">
            <div className="task-details-block">
              <div className="update-blks new">
                <h5>NEW</h5>
                <p>{item.ActivityLogs?.payload?.prevData}</p>
              </div>
              <div className="update-blks">
                <h5>PREVIOUS</h5>
                <p>{item.ActivityLogs?.payload?.prevData}</p>
              </div>
            </div>
          </div>
        </>
      );
    case "checklist" || "checklist-removed":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="usr-name">
            {"checklist"
              ? item?.ActivityLogs?.payload?.newData
              : item?.ActivityLogs?.payload?.prevData}
          </div>
        </ContentLayout>
      );
    case "changePriority":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="notification-item-block me-2">
            <div className="notification-item sprint-box">
              {item?.ActivityLogs?.payload?.projectName}
            </div>
          </div>
          <div className="notification-item-block">
            <div className="notification-item medium">
              {item?.ActivityLogs?.payload?.prevData}
            </div>
            <span className="status-arw">
              <img src={RightArrow} alt="" />
            </span>
            <div className="notification-item high">
              {item?.ActivityLogs?.payload?.newData}
            </div>
          </div>
        </ContentLayout>
      );
    case "estimation":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="notification-item-block me-2">
            <div className="notification-item sprint-box">
              {item?.ActivityLogs?.payload?.projectName}
            </div>
          </div>
          <span className="cmt-txt time">
            {item?.ActivityLogs?.payload?.newData}
          </span>
        </ContentLayout>
      );

    case "doc-add":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <span className="cmt-txt attachment me-3">
            {item.ActivityLogs?.payload?.newData}
          </span>
          {/* {item?.ActivityLogs?.payload?.docsName?.map((item) => (
              <span className="cmt-txt attachment me-3">{item}</span>
            ))} */}
        </ContentLayout>
      );
    case "sprint-created":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="notification-item-block">
            <div className="notification-item sprint-box">
              {item?.ActivityLogs?.payload?.sprint}
            </div>
          </div>
        </ContentLayout>
      );
    case "sprint-completed":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="notification-item-block">
            <div className="notification-item sprint-box">
              {item?.ActivityLogs?.payload?.sprint}
            </div>
          </div>
        </ContentLayout>
      );
    case "sprint-activated":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="notification-item-block">
            <div className="notification-item sprint-box">
              {item?.ActivityLogs?.payload?.sprint}
            </div>
          </div>
        </ContentLayout>
      );
    case "allocation":
      return (
        <ContentLayout date={item.createdAt}>
          {renderName(item, userProfileData, index)}
          <div className="notification-text">{item.activityTypeData?.name}</div>
          <div className="notification-item-block">
            <div className="notification-item sprint-box">
              {item?.ActivityLogs?.payload?.projectName}
            </div>
          </div>
        </ContentLayout>
      );
    default:
      return null;
  }
}

export const renderName = (item, userProfileData, index) => {
  return (
    <>
      <div
        className={`usr-avt bg-${(index % 4) + 1}`}
        onClick={(e) =>
          typeof userProfileData === "function" &&
          userProfileData(e, item?.ActivityLogs?.payload?.userId)
        }
      >
        {getInitials(item?.ActivityLogs?.payload?.userName)}
      </div>
      <div
        className="usr-name"
        onClick={(e) =>
          typeof userProfileData === "function" &&
          userProfileData(e, item?.ActivityLogs?.payload?.userId)
        }
      >
        {item?.ActivityLogs?.payload?.userName}
      </div>
    </>
  );
};

export default renderNotificationContent;
