import { isEqual } from "lodash";

import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AllocationTable from "./AllocationTable";
import "../../../design/custom/pages/allocations.scss";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import { useThunk } from "../../../hooks/useThunk";
import { getAllocationLists, getJokes } from "../../../store";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import { useFilter } from "../../../context/useFilter";
import { filterQuery } from "./AllocationFilter";
import NoDataPage from "../../NoDataPage/NoDataPage";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

function AllocationList() {
  const [fetchAllocationLists, isLoadingAllocation] =
    useThunk(getAllocationLists);
  const { allocationData } = useSelector((state) => state.allocation);
  const [filterData] = useThunk(getFilterDropdown);

  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.ALLOCATION,
        permissionConstants.submodules.ALLOCATION_LIST,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };
  const allocationTitles =
    hasPermission(permissionConstants.permissions.ALLOCATION_SEARCH) &&
    allocationData &&
    Object.keys(allocationData);

  // change title colour for diffrent index
  function getTitleClassName(title, index) {
    const classNames = ["bg-01", "bg-02", "bg-03"]; // Define your desired class names
    const defaultClassName = "default-class"; // Default class name for other titles

    // Use the index modulo the length of classNames to cycle through the array
    const selectedClassName =
      classNames[index % classNames.length] || defaultClassName;

    return selectedClassName;
  }

  // search query

  const [params, setParams] = useState({});

  const { filter } = useFilter();

  const matches = filterQuery(filter.filter);

  const previousMatchesRef = useRef(matches);

  useEffect(() => {
    if (!isEqual(matches, previousMatchesRef.current)) {
      setParams({ ...params, matches });
      previousMatchesRef.current = matches;
    }
  }, [matches]);

  useEffect(() => {
    let updatedParams = { ...params };
    updatedParams = { ...updatedParams, me: filter.me };

    updatedParams = { ...updatedParams, search: filter.search };

    if (filter.role) updatedParams = { ...updatedParams, role: filter.role };
    if (filter.project)
      updatedParams = { ...updatedParams, role: filter.project };

    setParams(updatedParams);
  }, [filter.search, filter.me, filter.assignee, filter.role]);

  useEffect(() => {
    fetchAllocationLists(params);
  }, [params]);

  // const formattedDate = moment().format("MMMM D, YYYY");

  useEffect(() => {
    filterData("allocation");
  }, []);
  useEffect(() => {
    fetchAllocationLists();
  }, []);

  const [getJokesData] = useThunk(getJokes);

  useEffect(() => {
    if (!allocationTitles) getJokesData();
  }, [allocationTitles]);

  return (
    <>
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.ALLOCATION_SEARCH,
        )}
        showFilter={hasPermission(
          permissionConstants.permissions.ALLOCATION_FILTER,
        )}
        showMe={hasPermission(
          permissionConstants.permissions.ALLOCATION_ME_FILTER,
        )}
        // showAssignee
        // showOtherOptions
        // showExport
        showAllocationDate
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            {/* <div className="d-one ps-2 pt-3"> {formattedDate}</div> */}

            <div className="group-list-cover d-flex flex-column overflow-main allocation-module otr-001">
              <div className="group-outer custom-scroll">
                <div className="group-head-main d-flex sticky-element">
                  {/* <div className="group-head">
                    <small> {formattedDate}</small>
                  </div> */}
                </div>
                <div className="table-cover-main">
                  {allocationTitles ? (
                    allocationTitles?.map((each, index) => (
                      <AllocationTable
                        key={index}
                        isLoading={isLoadingAllocation}
                        title={each}
                        data={allocationData[each]}
                        buttonClassName={getTitleClassName(each, index)}
                        hasPermission={hasPermission}
                        params={params}
                      />
                    ))
                  ) : (
                    <NoDataPage />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllocationList;
