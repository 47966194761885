/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { meetingScheduleActions } from "../../../store/slices/meetingSchedule";
import "../../../design/custom/pages/conf_hall_booking.scss";
import "../../../design/custom/pages/allocation-hour.scss";

import {
  CalendarIcon,
  AddUsersIcon,
  ReloadIcon,
  ReminderICon,
  UsersAddIcon,
} from "./MeetingSvgIcons";
import closeIcon from "../../../assets/images/close__icon.svg";
import { getAllocationProjectsLists } from "../../../store/slices/allocationList/thunks/getAllocationProjects";
import { useThunk } from "../../../hooks/useThunk";
import { getAllUsersList } from "../../../store/slices/allocationList/thunks/getAllUsersList";
import { getFirstLetters } from "../../../utils/utils";
import { getMultyUserProfile, getTeam } from "../../../store";
import { postMeeting } from "../../../store/slices/meetingSchedule/thunks/postMeeting";
import { userProfileActions } from "../../../store/slices/userProfile";
import { updateMeeting } from "../../../store/slices/meetingSchedule/thunks/updateMeeting";
import { getNameAndUniqId } from "../../../components/common/iconDefaultName";

function AddMeetingSlider() {
  const [titleValue, settitleValue] = useState("");
  const [description, setdescription] = useState("");
  const [online, setonline] = useState(false);
  const [conferenceHall, setConferenceHall] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedProjects, setselectedProjects] = useState(null);
  const [timeSchedule, settimeSchedule] = useState({});
  const [errors, setErrors] = useState({});
  const { showMeetingSlider } = useSelector((state) => state.meeting);
  const { allocatioProjects } = useSelector((state) => state.allocation);
  const [allUserReports] = useThunk(getAllUsersList);
  const { allUserReportData } = useSelector((state) => state.allocation);
  const [getProjects] = useThunk(getAllocationProjectsLists);
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const [updateMeetingById, updateLoading] = useThunk(updateMeeting);
  const [imgArray, setimgArray] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [recursion, setrecursion] = useState("Once");
  const [selectedDays, setSelectedDays] = useState([]);
  const [team, membersLoading] = useThunk(getTeam);
  const [addMeeting, addMeetingLoader] = useThunk(postMeeting);
  const { teamData } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  const reminderOptions = [
    { value: 10, label: "10 Mins Before" },
    { value: 30, label: "Half Hour Before" },
    { value: 60, label: "One Hour Before" },
  ];
  const [reminder, setreminder] = useState({
    value: 10,
    label: "10 Mins Before",
  });
  // image error handling
  const [imgError, setimgError] = useState(false);

  const handleOnProjectError = () => {
    setimgError(true);
  };

  const handleTitleValue = (e) => {
    setErrors({});
    settitleValue(e.target.value);
  };

  const handleDayCheckboxChange = (day) => {
    setSelectedDays((prevSelectedDays) => {
      const isSelected = prevSelectedDays?.some(
        (selectedDay) => selectedDay?.value === day?.value,
      );

      const updatedSelectedDays = isSelected
        ? prevSelectedDays?.filter(
            (selectedDay) => selectedDay?.value !== day?.value,
          )
        : [...prevSelectedDays, day];

      return updatedSelectedDays;
    });
  };

  const handleTimeScheduleClick = (label, id) => {
    settimeSchedule((prevSchedule) => {
      // Check if the current label is already selected
      if (prevSchedule[label] === id) {
        const { [label]: omitted, ...rest } = prevSchedule;
        return rest;
        // eslint-disable-next-line no-else-return
      } else {
        return {
          ...prevSchedule,
          [label]: id,
        };
      }
    });
  };
  // time selecting
  const timeScheduler = [
    { id: 1, label: "09:00 AM - 9:30 AM" },
    { id: 2, label: "09:30 AM - 10:00 AM" },
    { id: 3, label: "10:00 AM - 10:30 AM" },
    { id: 4, label: "10:30 AM - 11:00 AM" },
    { id: 5, label: "11:00 AM - 11:30 AM" },
    { id: 6, label: "11:30 AM - 12:00 PM" },
    { id: 7, label: "12:00 PM - 12:30 PM" },
    { id: 8, label: "12:30 PM - 01:00 PM" },
    { id: 9, label: "01:00 PM - 01:30 PM" },
    { id: 10, label: "01:30 PM - 02:00 PM" },
    { id: 11, label: "02:00 PM - 02:30 PM" },
    { id: 12, label: "02:30 PM - 03:00 PM" },
    { id: 13, label: "03:00 PM - 03:30 PM" },
    { id: 14, label: "03:30 PM - 04:00 PM" },
    { id: 15, label: "04:00 PM - 04:30 PM" },
    { id: 16, label: "04:30 PM - 05:00 PM" },
    { id: 17, label: "05:00 PM - 05:30 PM" },
    { id: 18, label: "05:30 PM - 06:00 PM" },
  ];

  const handleProjectSelect = (selectedOption) => {
    setselectedProjects(selectedOption);
  };

  const customFormatOptionLabel = ({ label, image, id }) => (
    <div className="custom-option-label custom-scroll">
      {image && !imgError ? (
        <img
          onError={handleOnProjectError}
          src={`https://erptest.spericorn.com/repo/uploads/${image}`}
          alt=""
        />
      ) : (
        <div
          className={`avathar-sm avathar-0${
            (getNameAndUniqId(id).uniqId % 4) + 1
          }`}
        >
          {getFirstLetters(label)}
        </div>
      )}

      <div className="member-list">
        <span className="name">{label}</span>
      </div>
    </div>

    // <div className="custom-option-label custom-scroll">
    //   {image && !imgError ? (
    //     <img
    //       onError={handleOnProjectError}
    //       src={`https://erptest.spericorn.com/repo/uploads/${image}`}
    //       alt=""
    //     />
    //   ) : (
    //     <div
    //       className={`avathar-sm avathar-0${
    //         (getNameAndUniqId(id).uniqId % 4) + 1
    //       }`}
    //     >
    //       {getFirstLetters(label)}
    //     </div>
    //   )}

    //   <div className="member-list">
    //     <span className="name">{label}</span>
    //   </div>
    // </div>
  );

  // eslint-disable-next-line no-unused-vars
  const handleReminderSelect = (val, _) => {
    const selectedReminder = reminderOptions?.find(
      (option) => option.label === val,
    );
    setreminder(selectedReminder);
  };

  const handleUsersSelect = (selectedOption) => {
    const { userId } = selectedOption;

    // Check if the selected option is already in the selectedUsers array
    if (!selectedUserIds.includes(userId)) {
      // Update selectedUserIds and selectedUsers
      setSelectedUserIds((prevSelectedUserIds) => [
        ...prevSelectedUserIds,
        userId,
      ]);
      setselectedUsers((prevSelectedUsers) => [
        ...prevSelectedUsers,
        { ...selectedOption },
      ]);
    } else {
      setErrors({ duplicateSelection: "User is already selected." });
    }
  };

  const handleOnError = (e, id) => {
    setimgArray((prevImgArray) => [...prevImgArray, id]);
    e.target.style.display = "none";
  };

  const handleOnLoad = (e) => {
    e.target.style.display = "block";
  };

  const allocationProjectOptions =
    allocatioProjects?.map((project) => ({
      id: project?._id,
      value: project?.name,
      label: project?.name,
      image: project?.logo,
    })) || [];

  const handleSelectRecursion = (e) => {
    setrecursion(e);

    // If "Every Weakdays" is selected, set the selectedDays state to an empty array
    if (e === "Every Weakdays") {
      setSelectedDays([]);
    }
  };

  const formatUsersOptionLabel = ({ label, data }) => {
    // Destructure the photo
    const { photo } = data;

    const shouldDisplayPlaceholder = imgArray?.includes(label);

    return (
      <>
        {photo ? (
          shouldDisplayPlaceholder ? (
            <div className="name userProf bg-7"> {getFirstLetters(label)}</div>
          ) : (
            <img
              onError={(e) => handleOnError(e, label)}
              onLoad={(e) => handleOnLoad(e)}
              src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
              alt=""
            />
          )
        ) : (
          <div className="name userProf bg-7">{getFirstLetters(label)}</div>
        )}

        <div className="member-list">
          <span className="name">{label}</span>
        </div>
      </>
    );
  };

  // remove  selected user

  const handleRemoveSelectedUsers = (id) => {
    const updatedUsers = selectedUsers?.filter((user) => user?.userId !== id);
    setselectedUsers(updatedUsers);
    if (selectedUsers && selectedUsers.length > 0) {
      setselectedProjects(null);
    }
  };

  const handleSubmitSchedule = () => {
    const meetingType = online
      ? "online"
      : conferenceHall
      ? "conferenceHall"
      : "";

    const timeScheduleData = Object?.entries(timeSchedule)?.map(
      ([label, id]) => ({
        time: label,
        id,
      }),
    );

    const transformedDays = selectedDays?.map(({ label, value }) => ({
      label,
      value,
    }));

    const recurringData = {
      label: recursion,
      selectedDays: transformedDays,
    };

    const usersMap = selectedUsers?.map((e) => e?.userId);

    const newErrors = {};

    // Check if either "Online" or "Conference Hall" is selected
    if (!(online || conferenceHall)) {
      // Set an error message for the selected option
      newErrors.option = "Please select either Online or Conference Hall";
    }

    // Check if title is provided
    if (!titleValue) {
      // Set an error message for the title
      newErrors.title = "Please provide a title";
    } else if (titleValue?.length > 18) {
      // Check if the title has more than 10 words

      newErrors.title = "Title can have up to 10 words";
    }

    // Check if description is provided
    if (!description) {
      // Set an error message for the title
      newErrors.description = "Please provide a description";
    }
    // Check if selectedUsers is provided
    if (!selectedUsers?.length) {
      // Set an error message for the title
      newErrors.selectedUsers = "Please select employee";
    }
    // Check if startDate is provided
    if (!startDate) {
      // Set an error message for the title
      newErrors.startDate = "Please select Date";
    }
    // Check if timeSchedule is provided
    if (!timeSchedule || Object?.keys(timeSchedule)?.length === 0) {
      // Set an error message for the time schedule
      newErrors.timeSchedule = "Please set at least one time range";
    }

    // Update the error state
    setErrors(newErrors);

    // If there are errors, stop further execution
    if (Object?.keys(newErrors).length > 0) {
      return;
    }
    const meetingData = {
      type: meetingType,
      title: titleValue,
      description,
      date: startDate,
      team: selectedProjects?.id,
      timeSlot: timeScheduleData,
      invites: usersMap,
      recurring: recurringData,
      reminder,
    };

    if (showMeetingSlider?._id && !updateLoading && !membersLoading) {
      updateMeetingById({
        postData: meetingData,
        id: showMeetingSlider?._id,
        params: {
          page: 1,
          limit: 6,
        },
        reset: true,
      });
    } else if (
      !showMeetingSlider?._id &&
      !membersLoading &&
      !addMeetingLoader
    ) {
      addMeeting({
        postData: meetingData,
        params: {
          page: 1,
          limit: 6,
        },
        reset: true,
      });
    }
  };
  useEffect(() => {
    if (!addMeetingLoader && !showMeetingSlider?._id) {
      setonline(false);
      setConferenceHall(false);
      setselectedProjects(null);
      settitleValue("");
      settimeSchedule([]);
      setselectedUsers([]);
      setdescription("");
      setStartDate(new Date());
      setrecursion("Once");
      setreminder({ value: 10, label: "10 Mins Before" });
    }
  }, [!addMeetingLoader, !showMeetingSlider?._id]);

  useEffect(() => {
    // Check if teamData is available and has projectAggregation
    if (teamData && teamData?.projectAggregation) {
      // Extract users from projectAggregation
      const teamUsers = teamData.projectAggregation?.map((e) => e);

      // Set the initial selectedUsers state with teamUsers
      setselectedUsers(teamUsers);
    }
  }, [teamData]);

  useEffect(() => {
    setrecursion(selectedDays?.length > 0 ? "Every Weekdays" : "Once");
  }, [selectedDays]);

  useEffect(() => {
    if (selectedProjects?.id)
      team({
        projectId: selectedProjects?.id,
        page: 1,
        limit: 6,
      });
  }, [selectedProjects?.id]);
  useEffect(() => {
    if (showMeetingSlider) {
      getProjects();
      allUserReports();
    }
  }, [showMeetingSlider]);

  useEffect(() => {
    if (showMeetingSlider?._id) {
      if (showMeetingSlider?.type === "online") {
        setonline(true);
      } else {
        setConferenceHall(true);
      }
      const foundProject = allocatioProjects?.find(
        (project) => project?._id === showMeetingSlider?.team,
      );

      const ProjectResult = foundProject
        ? { id: foundProject._id, label: foundProject.name }
        : null;

      const updatedTimeSchedule = { ...timeSchedule };

      showMeetingSlider?.timeSlot?.forEach((slot) => {
        // Assuming slot.time exists and matches the keys in timeSchedule
        updatedTimeSchedule[slot.time] = slot.id;
      });

      const Ivitees = showMeetingSlider?.invites?.map(
        ({ employeeName, _id }) => ({
          user: employeeName,
          userId: _id,
        }),
      );

      if (!updateLoading) {
        setrecursion(showMeetingSlider?.recurring?.label);
        setSelectedDays(showMeetingSlider?.recurring?.selectedDays);
        setreminder(showMeetingSlider?.reminder);
        setselectedProjects(ProjectResult);
        settitleValue(showMeetingSlider?.title);
        settimeSchedule(updatedTimeSchedule);
        setselectedUsers(Ivitees);
        setdescription(showMeetingSlider?.description);
        setStartDate(new Date(showMeetingSlider?.date));
      }
    }
  }, [allocatioProjects, showMeetingSlider?._id]);

  return (
    <>
      <div className={`overlay ${showMeetingSlider ? "show" : ""} `} />
      <div
        className={`slide-details-lg-cvr conf-slide ${
          showMeetingSlider ? "show" : ""
        } `}
      >
        <div className="profle-hdr spacing pb-0 pdg-lf-rt">
          <div className="step-1">
            <div className="title-top">{`${
              !showMeetingSlider?._id ? "Create New Meeting" : "Edit Meeting"
            }`}</div>

            <button
              className="btn-close"
              type="button"
              onClick={() => {
                setonline(false);
                setConferenceHall(false);
                setselectedProjects(null);
                settitleValue("");
                settimeSchedule([]);
                setselectedUsers([]);
                setStartDate(new Date());
                setrecursion("Once");
                setreminder({ value: 10, label: "10 Mins Before" });
                setdescription("");
                dispatch(
                  meetingScheduleActions.setShowMeetingSlider(
                    !showMeetingSlider,
                  ),
                );
              }}
            />
          </div>
        </div>

        <div className="content-area-side pdg-lf-rt custom-scroll">
          <div className="forms__group ">
            <div className="form-outer-col users-drp">
              <ul className="chk-inputs">
                <li>
                  <label
                    className="radio-box-wrp active mt-3"
                    style={{
                      opacity: 0.5,
                      pointerEvents: "none",
                    }}
                  >
                    Online
                    <input
                      type="radio"
                      id="age1"
                      name="online"
                      value={online}
                      checked={online === true}
                      onChange={() => {
                        setErrors({});
                        setonline(false);
                        setConferenceHall(false);
                      }}
                    />
                    <span className="radio-mark" />
                  </label>
                </li>
                <li>
                  <label className="radio-box-wrp mt-3">
                    Conference Hall
                    <input
                      type="radio"
                      id="age2"
                      name="conference"
                      value={conferenceHall}
                      checked={conferenceHall === true}
                      onChange={() => {
                        setErrors({});
                        setConferenceHall(true);
                        setonline(false);
                      }}
                    />
                    <span className="radio-mark" />
                  </label>
                </li>
              </ul>
              {errors.option && (
                <small style={{ color: "red" }}>{errors.option}</small>
              )}
            </div>
            <div className="form-outer-col">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Add Title
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Add Title"
                value={titleValue}
                onChange={handleTitleValue}
              />
              {errors.title && (
                <small style={{ color: "red" }}>{errors.title}</small>
              )}
            </div>
            <div className="form-outer-col">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Add Description
              </label>
              <textarea
                name="Add Description"
                className="form-control text-area"
                placeholder="Add Description"
                value={description}
                onChange={(e) => {
                  setErrors({});
                  setdescription(e.target.value);
                }}
              />
              {errors.description && (
                <small style={{ color: "red" }}>{errors.description}</small>
              )}
            </div>

            <div className="form-outer-col">
              <div className="drop__outer">
                <div className="otr-drop">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Date
                  </label>

                  <div className="dropdown">
                    <DatePicker
                      minDate={new Date()}
                      icon={<CalendarIcon />}
                      className="btn btn-secondary dropdown-toggle "
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd-MMMM-yyyy"
                      showIcon
                    />
                  </div>
                  {errors.startDate && (
                    <small style={{ color: "red" }}>{errors.startDate}</small>
                  )}
                </div>
                <div className="otr-drop">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Project
                  </label>
                  <div className="dropdown">
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container meeting-project"
                      aria-label="Default select example"
                      options={allocationProjectOptions}
                      onChange={handleProjectSelect}
                      formatOptionLabel={customFormatOptionLabel}
                      value={selectedProjects}
                      isSearchable
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-outer-col">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Choose a Time Slot
              </label>
              <div className="tag_line_wrp">
                {timeScheduler?.map(({ id, label }) => (
                  <button
                    key={id}
                    className={`tag-line bg-${
                      timeSchedule[label] ? "02" : "01"
                    }`}
                    type="button"
                    onClick={() => {
                      setErrors({});
                      handleTimeScheduleClick(label, id);
                    }}
                  >
                    <span>{label}</span>
                  </button>
                ))}
              </div>
              {errors.timeSchedule && (
                <small style={{ color: "red" }}>{errors.timeSchedule}</small>
              )}
            </div>

            <div className="form-outer-col user__view">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Invitees
              </label>

              <div className="avatar__group_otr">
                <div className="ftr d-flex">
                  {selectedUsers?.map(
                    (empl, index) =>
                      index < 2 && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`${empl?.user}-tooltip`}>
                              {empl?.user}
                            </Tooltip>
                          }
                        >
                          <div className="avatar__group" key={index}>
                            <div
                              className={`avathar-sm avathar-0${
                                (index % 4) + 1
                              }`}
                            >
                              <div className="online" />
                              <div
                                className="close_circle"
                                onClick={() =>
                                  handleRemoveSelectedUsers(empl?.userId)
                                }
                              >
                                <img src={closeIcon} alt="" />
                              </div>

                              {empl?.photo ? (
                                imgArray?.includes(empl?.user) ? (
                                  <div className="name userProf bg-7">
                                    {getFirstLetters(empl?.user)}
                                  </div>
                                ) : (
                                  <img
                                    onError={(error) =>
                                      handleOnError(error, empl?.user)
                                    }
                                    onLoad={(error) => handleOnLoad(error)}
                                    src={`https://erptest.spericorn.com/repo/uploads/${empl?.photo}`}
                                    alt=""
                                  />
                                )
                              ) : (
                                <span> {getFirstLetters(empl?.user)}</span>
                              )}
                            </div>

                            {/* {/ more dropdown  /} */}
                          </div>
                        </OverlayTrigger>
                      ),
                  )}

                  {membersLoading && (
                    <span className="btn-loader">
                      <span className="spinner-border spinner-border-sm" />
                    </span>
                  )}

                  <div className="avatar__group">
                    {/* {/ more dropdown  /} */}

                    <Dropdown>
                      {selectedUsers?.length > 2 && (
                        <Dropdown.Toggle
                          id="checklist-toggles"
                          className="avathar-sm avathar-more"
                        >
                          <div className="avatar__group">
                            <div className="online" />

                            <span> + {(selectedUsers?.length ?? 0) - 2}</span>
                          </div>
                        </Dropdown.Toggle>
                      )}
                      {selectedUsers?.length > 2 && (
                        <Dropdown.Menu className="no-bg  pb-1 dropdown-menu right ">
                          <div className="list-items-main custom-scroll list-items-1 ps-2 pe-1 allocation-drp-dwn right">
                            <ul className="p-0">
                              {selectedUsers?.map(
                                ({ user, userId, photo }, index) =>
                                  index >= 2 && (
                                    <li
                                      className="d-flex align-items-center"
                                      key={index}
                                    >
                                      <div className="d-flex align-items-center">
                                        {photo ? (
                                          imgArray?.includes(userId) ? (
                                            <div
                                              className={`avathar-sm ms-0  avathar-0${
                                                (index % 3) + 1
                                              }`}
                                            >
                                              <div
                                                className="close_circle"
                                                onClick={() =>
                                                  handleRemoveSelectedUsers(
                                                    userId,
                                                  )
                                                }
                                              >
                                                <img src={closeIcon} alt="" />
                                              </div>
                                              <span>
                                                {getFirstLetters(user)}
                                              </span>
                                            </div>
                                          ) : (
                                            <div
                                              className={`avathar-sm ms-0  avathar-0${
                                                (index % 3) + 1
                                              }`}
                                            >
                                              <div
                                                className="close_circle"
                                                onClick={() =>
                                                  handleRemoveSelectedUsers(
                                                    userId,
                                                  )
                                                }
                                              >
                                                <img src={closeIcon} alt="" />
                                              </div>
                                              <img
                                                onError={(target) =>
                                                  handleOnError(target, userId)
                                                }
                                                onLoad={(target) => {
                                                  target.target.style.display =
                                                    "block";
                                                }}
                                                className="usrImg"
                                                src={`https://erptest.spericorn.com/repo/uploads/${photo}`}
                                                alt=""
                                              />
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`avathar-sm ms-0  avathar-0${
                                              (index % 4) + 1
                                            }`}
                                          >
                                            <div
                                              className="close_circle"
                                              onClick={() =>
                                                handleRemoveSelectedUsers(
                                                  userId,
                                                )
                                              }
                                            >
                                              <img src={closeIcon} alt="" />
                                            </div>
                                            <span>
                                              {user?.slice(0, 2).toUpperCase()}
                                            </span>
                                          </div>
                                        )}
                                        <div className="rgt_content d-flex flex-column">
                                          <div
                                            className={
                                              photo
                                                ? "title_fst ms-1"
                                                : "title_fst"
                                            }
                                          >
                                            {user}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="ms-auto profile-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          getMultyUsers(userId);
                                          dispatch(
                                            userProfileActions.setShowUserProfile(
                                              true,
                                            ),
                                          );
                                        }}
                                      >
                                        Profile
                                      </div>
                                    </li>
                                  ),
                              )}
                            </ul>
                          </div>
                        </Dropdown.Menu>
                      )}
                    </Dropdown>
                  </div>
                </div>

                <div className="avatar__group add-user-group no-bg">
                  <div className="avathar-sm avathar-more ">
                    <div className="online" />
                    {/* <!-- <div className="close_circle"><img src="img/close__icon.svg" alt=""></div> --> */}

                    <AddUsersIcon />

                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="react-select-container icon-add"
                      >
                        {/* <span className="add-icon"> */}
                        <UsersAddIcon /> {/* </span> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="custom-scroll meeting-drp team-drp">
                        {allUserReportData
                          ?.filter((user) => {
                            // Check if the user is not in projectAggregation
                            const isUserInProject =
                              teamData?.projectAggregation?.some(
                                (e) => e.userId === user?._id,
                              );
                            const isUserSelected = selectedUserIds?.includes(
                              user?._id,
                            );
                            return !isUserInProject && !isUserSelected;
                          })
                          .map((user) => (
                            <Dropdown.Item
                              onClick={() => {
                                setErrors({});
                                handleUsersSelect({
                                  userId: user?._id,
                                  user: user?.employeeName,
                                  photo: user?.photo,
                                });
                              }}
                              key={user?._id}
                              eventKey={user?._id}
                              className="custom-option-label custom-scroll"
                            >
                              {formatUsersOptionLabel({
                                label: user?.employeeName,
                                data: { photo: user?.photo },
                              })}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              {errors.selectedUsers && (
                <small style={{ color: "red" }}>{errors.selectedUsers}</small>
              )}
            </div>

            <div className="form-outer-col">
              <div className="drop__outer">
                <div className="otr-drop">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Recurring
                  </label>
                  <div className="dropdown">
                    {/* <button
                    className="btn btn-secondary dropdown-toggle "
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="icon">
                      <ReloadIcon />
                    </div>
                    // <span>Every Weekdays</span>
                  </button> */}

                    <Dropdown onSelect={handleSelectRecursion}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div className="icon">
                          <ReloadIcon /> <span>{recursion}</span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="weekday-dropdown">
                        <Dropdown.Item
                          eventKey="Every Weakdays"
                          data-value="1"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Dropdown>
                            <Dropdown.Toggle variant="">
                              Every Weakdays
                            </Dropdown.Toggle>
                            <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                              <Dropdown.ItemText>
                                Select Days:
                              </Dropdown.ItemText>
                              {[
                                {
                                  value: 0,
                                  label: "Sunday",
                                },
                                { value: 1, label: "Monday" },
                                { value: 2, label: "Tuesday" },
                                { value: 3, label: "Wednesday" },
                                { value: 4, label: "Thursday" },
                                { value: 5, label: "Friday" },
                                { value: 6, label: "Saturday" },
                              ].map((day) => (
                                <Dropdown.Item
                                  className="week-label"
                                  key={day?.value}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent the click event from propagating to the parent dropdown
                                    handleDayCheckboxChange(day);
                                  }}
                                >
                                  <input
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent the click event from propagating to the parent dropdown
                                      handleDayCheckboxChange(day);
                                    }}
                                    type="checkbox"
                                    id={day?.value}
                                    checked={selectedDays?.some(
                                      (selectedDay) =>
                                        selectedDay?.value === day?.value,
                                    )}
                                  />
                                  <label htmlFor={day?.value}>
                                    {day?.label}
                                  </label>
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="Every Working Days"
                          data-value="2"
                        >
                          Every Working Days
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Once" data-value="3">
                          Once
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="otr-drop">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Remind
                  </label>
                  <div className="dropdown">
                    {/* <button
                    className="btn btn-secondary dropdown-toggle "
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="icon">
                      <ReminderICon />
                    </div>
                    <span>10 Mins Before</span>
                  </button> */}
                    <Dropdown onSelect={handleReminderSelect}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div className="icon">
                          <ReminderICon /> {reminder?.label}
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="weekday-dropdown">
                        {reminderOptions?.map((option) => (
                          <Dropdown.Item
                            key={option.value}
                            data-value={option.value}
                            eventKey={option?.label}
                          >
                            {option.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ftr_btn_sct pdg-lf-rt">
          <button
            className="btn-001 ms-auto"
            type="button"
            onClick={handleSubmitSchedule}
          >
            Schedule{" "}
            {addMeetingLoader || updateLoading ? (
              <span className="btn-loader">
                <span className="spinner-border spinner-border-sm" />
              </span>
            ) : null}
          </button>
        </div>
      </div>
    </>
  );
}

export default AddMeetingSlider;
