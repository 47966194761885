import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";

import Button from "../../../../components/Button/index";

// move the other two to correct locations
import "../../../../design/custom/components/toggleswitch.scss";
import "../../../../design/custom/structure/pageContents.scss";
// import { ProfilePicIcon } from "../../assets/svgIcons";
import {
  OFF,
  TOP_RIGHT,
  FULL_DAY,
  DATE_MONTH_YEAR,
  toastPosition,
  timeFormat,
  dateFormat,
  timeZones,
} from "./constants";
import { useThunk } from "../../../../hooks/useThunk";
import { patchUserData } from "../../../../store/slices/auth/thunks/patchUserData";
import {
  DepartmentIco,
  DesignationIco,
  EmailIco,
  EmpIco,
  PhoneIco,
} from "./svgIcons";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../../utils/permissionUtils";
import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";
import { getColors } from "../../../../store/slices/color/thunks/getThemeColors";

const BasicSettings = () => {
  const [shownav, setShownav] = useOutletContext();
  const [patchUserDataThunk, isLoading] = useThunk(patchUserData);
  const [getThemeColorThunk, isThemeColorsLoading] = useThunk(getColors);

  const { userProfile } = useSelector(({ auth }) => auth);

  const {
    userProfile: { userProfile: userData },
    colors: { colorData },
  } = useSelector((state) => state);

  const initialState = {
    toastPosition: OFF,
    timeFormat: FULL_DAY,
    dateFormat: DATE_MONTH_YEAR,
    timeZone: "Asia/Kolkata",
  };

  const [userInputs, setUserInputs] = useState(initialState);
  // image error handling
  const [imgError, setimgError] = useState(false);

  const handleOnError = () => {
    setimgError(true);
  };

  useEffect(() => {
    getThemeColorThunk();
  }, []);

  useEffect(() => {
    if (userData?.primaryColor && colorData)
      setUserInputs({ ...userInputs, primaryColor: userData?.primaryColor });
  }, [colorData, userData]);

  useEffect(() => {
    if (userData) {
      const {
        toastPosition = initialState.toastPosition,
        dateFormat = initialState.dateFormat,
        timeFormat = initialState.timeFormat,
        timeZone = initialState.timeZone,
      } = userData;
      setUserInputs({
        ...userInputs,
        toastPosition,
        dateFormat,
        timeFormat,
        timeZone,
      });
    }
  }, [userData]);

  const formattedpermissionList = subModulePermissions(
    userData?.formattedPermissions ?? [],
    permissionConstants.modules.SETTINGS,
    permissionConstants.submodules.BASIC_SETTINGS,
  );

  const hasPermssion = (permission) => {
    return modulePermissionHasPermissions(formattedpermissionList, permission);
  };

  const sidenavToggle = () => {
    setShownav(!shownav);
  };

  const onChange = (key, value) => {
    setUserInputs({ ...userInputs, [key]: value });
  };

  const getFirstLetters = (name) => {
    const words = name ? name.split(" ") : [];

    if (words.length >= 2) {
      const firstLetters = words.slice(0, 2).map((word) => word[0]);
      return firstLetters.join("").toUpperCase();
    }
    if (words.length === 1) {
      return words[0][0].toUpperCase();
    }
    return "";
  };

  if (!userProfile && isThemeColorsLoading) {
    return (
      <div className="page-container">
        <div className="loader-container">
          <div className="loader" />
        </div>
      </div>
    );
  }
  return (
    <div className="page-container">
      <div className="page-contents inner-page-contents settings-page">
        <div className="row">
          <div className="col d-flex align-items-start offset-blk">
            <Button className="btn btn-hamburg" onClick={sidenavToggle}>
              <span />
              <span />
              <span />
            </Button>
            <h2 className="main-title">{userData?.employeeName}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex">
            <div className="right-content">
              {hasPermssion(
                permissionConstants.permissions.MY_SETTINGS_SHOW_PROFILE,
              ) && (
                <div className="new-profile-area">
                  <div className="profile-identity-wrp">
                    <div className="profile-wrp  d-flex align-items-center">
                      <div className="profile-pic">
                        {userData?.photo && !imgError ? (
                          <img
                            onError={handleOnError}
                            src={`https://erptest.spericorn.com/repo/uploads/${userData?.photo}`}
                            alt=""
                          />
                        ) : (
                          <div
                            style={{
                              backgroundColor: colorData?.find(
                                (e) => e._id === userInputs.primaryColor,
                              )?.colorCode,
                            }}
                            className="circle"
                          >
                            {getFirstLetters(userData?.employeeName ?? "")}
                          </div>
                        )}
                      </div>
                      {hasPermssion(
                        permissionConstants.permissions
                          .MY_SETTINGS_SHOW_USER_NAME,
                      ) && (
                        <div className="profile-blks">
                          <h4>
                            {userData?.employeeName}
                            <span className="status online">Active</span>
                          </h4>
                        </div>
                      )}
                    </div>
                    <div className="profile-name">
                      <div className="profile-blks">
                        <p>
                          <span className="d-flex align-items-center">
                            <span>
                              <EmpIco />
                            </span>
                            EMP ID
                          </span>
                          {userData?.employeeId ?? "-"}
                        </p>
                      </div>
                      {hasPermssion(
                        permissionConstants.permissions
                          .MY_SETTINGS_SHOW_PHONE_NUMBER,
                      ) && (
                        <div className="profile-blks">
                          <p>
                            <span className="d-flex align-items-center">
                              <span>
                                <PhoneIco />
                              </span>
                              Phone Number
                            </span>
                            {userData?.contactNumber ?? "-"}
                          </p>
                        </div>
                      )}
                      {hasPermssion(
                        permissionConstants.permissions
                          .MY_SETTINGS_SHOW_EMAIL_ADDRESS,
                      ) && (
                        <div className="profile-blks">
                          <p>
                            <span className="d-flex align-items-center">
                              <span>
                                <EmailIco />
                              </span>
                              Email Address
                            </span>
                            {userData?.email ?? "-"}
                          </p>
                        </div>
                      )}
                      {hasPermssion(
                        permissionConstants.permissions
                          .MY_SETTINGS_SHOW_USER_DESIGNATION,
                      ) && (
                        <div className="profile-blks">
                          <p>
                            <span className="d-flex align-items-center">
                              <span>
                                <DesignationIco />
                              </span>
                              Designation
                            </span>
                            {userData?.designation?.name ?? "-"}
                          </p>
                        </div>
                      )}
                      {hasPermssion(
                        permissionConstants.permissions
                          .MY_SETTINGS_SHOW_USER_DEPARTMENT,
                      ) && (
                        <div className="profile-blks">
                          <p>
                            <span className="d-flex align-items-center">
                              <span>
                                <DepartmentIco />
                              </span>
                              Department
                            </span>
                            {userData?.department?.name ?? "-"}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {hasPermssion(
                permissionConstants.permissions.MY_SETTINGS_UPDATE,
              ) && (
                <div className="form-wrp">
                  {hasPermssion(
                    permissionConstants.permissions
                      .MY_SETTINGS_YOUR_COLOR_SCHEME,
                  ) && (
                    <>
                      <h2 className="main-title mb-1">Select theme</h2>
                      <ul className="theme-color-wrp d-flex flex-wrap align-items-center">
                        {colorData?.map((eachColor) => (
                          <li
                            style={{ backgroundColor: eachColor.colorCode }}
                            className={`color-pallette ${
                              eachColor._id === userInputs.primaryColor
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              onChange("primaryColor", eachColor._id)
                            }
                          />
                        ))}
                      </ul>
                    </>
                  )}

                  {hasPermssion(
                    permissionConstants.permissions.MY_SETTINGS_LANGUAGE,
                  ) && (
                    <>
                      <h2 className="main-title">Region settings</h2>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="usr-inputs">
                            <label htmlFor="" className="form-label">
                              Timezone
                            </label>
                            <Select
                              classNamePrefix="react-select"
                              className="react-select-container"
                              options={timeZones}
                              name=""
                              id=""
                              onChange={(selectedZone) =>
                                onChange("timeZone", selectedZone.value)
                              }
                            />
                            <label className="chkbox-wrp mt-3">
                              Notify me of Timezone changes
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {hasPermssion(
                    permissionConstants.permissions.MY_SETTINGS_TIME_FORMAT,
                  ) && (
                    <div className="row">
                      <div className="col">
                        <div className="usr-inputs">
                          <label htmlFor="" className="form-label">
                            Time format
                          </label>
                          <ul className="chk-inputs">
                            {timeFormat.map((eachFormat) => (
                              <li key={eachFormat.value}>
                                <label className="radio-box-wrp active mt-3">
                                  {eachFormat.label}
                                  <input
                                    checked={
                                      userInputs.timeFormat === eachFormat.value
                                    }
                                    type="radio"
                                    value={eachFormat.value}
                                    onChange={({ target: { value } }) =>
                                      onChange("timeFormat", value)
                                    }
                                  />
                                  <span className="radio-mark" />
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {hasPermssion(
                    permissionConstants.permissions.MY_SETTINGS_DATE_FORMAT,
                  ) && (
                    <div className="row">
                      <div className="col">
                        <div className="usr-inputs">
                          <label htmlFor="" className="form-label">
                            Date format
                          </label>
                          <ul className="chk-inputs">
                            {dateFormat.map((eachFormat) => (
                              <li key={eachFormat.value}>
                                <label className="radio-box-wrp active mt-3">
                                  {eachFormat.label}
                                  <input
                                    type="radio"
                                    checked={
                                      eachFormat.value === userInputs.dateFormat
                                    }
                                    value={eachFormat.value}
                                    onChange={({ target: { value } }) =>
                                      onChange("dateFormat", value)
                                    }
                                  />
                                  <span className="radio-mark" />
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {hasPermssion(
                    permissionConstants.permissions
                      .MY_SETTINGS_FLYOUT_TOAST_MESSAGE,
                  ) && (
                    <div className="preference-blk">
                      <h2 className="main-title">Preferences</h2>
                      <div className="switch-blks">
                        <div className="d-flex align-items-center">
                          <div className="content-blks">
                            <h4 className="switch-title">
                              Flyout Toast Message
                            </h4>
                            <p className="switch-cont">
                              When performing actions, toast messages may appear
                              in the bottom left-hand of your screen. You can
                              disable that here.
                            </p>
                          </div>
                          <div className="toggle-btn-blk">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={!(userInputs.toastPosition === OFF)}
                                onChange={() => {
                                  if (userInputs.toastPosition === OFF)
                                    onChange("toastPosition", TOP_RIGHT);
                                  else onChange("toastPosition", OFF);
                                }}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>
                        <div className="input-drawer">
                          {userInputs.toastPosition !== "OFF" ? (
                            <ul className="chk-inputs">
                              {toastPosition.map((eachPosition) => (
                                <li key={eachPosition.value}>
                                  <label className="radio-box-wrp active mt-3">
                                    {eachPosition.label}
                                    <input
                                      checked={
                                        userInputs.toastPosition ===
                                        eachPosition.value
                                      }
                                      onChange={() =>
                                        onChange(
                                          "toastPosition",
                                          eachPosition.value,
                                        )
                                      }
                                      type="radio"
                                    />
                                    <span className="radio-mark" />
                                  </label>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {hasPermssion(permissionConstants.permissions.MY_SETTINGS_UPDATE) && (
        <div className="border-top setting-footer d-flex">
          <Button
            className={`btn btn-primary btn-save ms-auto d-flex align-items-center ${
              isLoading && "isLoading disable"
            }`}
            onClick={() => {
              let patchData = {
                timeZone: userInputs.timeFormat,
                toastPosition: userInputs.toastPosition,
                dateFormat: userInputs.dateFormat,
                timeFormat: userInputs.timeFormat,
              };
              if (userInputs.primaryColor)
                patchData = {
                  ...patchData,
                  primaryColor: userInputs.primaryColor,
                };
              if (!isLoading) patchUserDataThunk(patchData);
            }}
          >
            {isLoading && (
              <span className="btn-loader">
                <span className="spinner-border spinner-border-sm" />
              </span>
            )}
            Save Changes
          </Button>
        </div>
      )}
    </div>
  );
};

export default BasicSettings;
