import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { cronjobActions } from "../index";

export const clearReducer = createAsyncThunk(
  "cronjob/resetReducer",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      dispatch(cronjobActions.resetCronjobReducer());
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(cronjobActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
