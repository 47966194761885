import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import moment from "moment";
import { PreviousIcons, NextIcons } from "./TimesheetSvg";

const TimesheetCalendar = ({ onMonthYearSelect }) => {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    onMonthYearSelect(currentMonth, currentYear);
  }, [currentMonth, currentYear]);

  const handleDateChange = (date, isTodayButton) => {
    setSelectedDate(date);
    setCurrentMonth(date.getMonth());
    setCurrentYear(date.getFullYear());

    if (isTodayButton) {
      setShowDatePicker(false);
    }
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
    setSelectedDate(new Date(currentYear, currentMonth - 1));
  };

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
    setSelectedDate(new Date(currentYear, currentMonth + 1));
  };

  const formattedCurrentMonthYear = moment()
    .year(currentYear)
    .month(currentMonth)
    .format("MMMM YYYY");

  return (
    <div className="calendar">
      <div className="calendar-header">
        <div className="header-text">
          <span onClick={() => handleDateChange(new Date(), true)} />
          <button type="button" onClick={goToPreviousMonth}>
            <span className="prev">
              <PreviousIcons />
            </span>
          </button>
          <button type="button" onClick={goToNextMonth}>
            <span className="next">
              <NextIcons />
            </span>
          </button>

          <span className="custom-toolbar-label" onClick={toggleDatePicker}>
            {formattedCurrentMonthYear}
          </span>

          {showDatePicker && (
            <div className="date_index">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                dateFormat="MM/yyyy"
                showMonthYearPicker
                onClickOutside={() => setShowDatePicker(false)}
                maxDate={moment().toDate()} // Set a maximum date}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TimesheetCalendar.propTypes = {
  onMonthYearSelect: PropTypes.func,
};

export default TimesheetCalendar;
