import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AddFolderIcon,
  AddListIcon,
  AddProjectIcon,
  AddSprintIcon,
} from "../svgIcons";
import CreateSprint from "../../../pages/Sprint/createSprint";
import "../../../design/custom/structure/sidenav.scss";
import { useThunk } from "../../../hooks/useThunk";
import { listCreate } from "../../../store/slices/project/thunks/postList";
import { folderCreate } from "../../../store/slices/project/thunks/postFolder";
import AddListOption from "./AddListOption";
import { getSprint } from "../../../store";
import { alertActions } from "../../../store/slices/alert";
import { sprintActions } from "../../../store/slices/sprint";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

const AddSprintPopUp = ({
  switchModal,
  modalSwitch,
  selectedProject,
  showList,
  showFolder,
  showSprint,
  folder,
  shortCode,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addListThunk, onCreateListLoading] = useThunk(listCreate);
  const [addFolderThunk, onCreateLoading] = useThunk(folderCreate);
  const [getSprints] = useThunk(getSprint);

  const { folderCreateSuccessMessage, listCreateSuccessMessage } = useSelector(
    ({ project }) => project,
  );

  const initialState = { listName: "", folderName: "", folderLists: [] };
  const [inputs, setInputs] = useState(initialState);
  const onClick = (value, projectId) => {
    switchModal(value);
    if (value === "sprint") {
      getSprints(projectId);
    }
  };
  const [listRef, setListRef] = useState("");

  const { userProfile } = useSelector((state) => state.userProfile);

  // permissions
  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.BASIC_PROJECT_SETTINGS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const addListOnSubmit = () => {
    if (selectedProject && inputs.listName.trim()) {
      let postData = { name: inputs.listName.trim(), project: selectedProject };
      if (folder) postData = { ...postData, folder };
      addListThunk({
        postData,
        navigate: (id) => navigate(`/project/${shortCode}/list/${id}`),
      });
    } else dispatch(alertActions.error("List can't be empty"));
  };

  const handleChange = (key, value) => {
    setInputs({ ...inputs, [key]: value });
  };

  const uniqueRegEx = (listName) => new RegExp(`^${listName}$`, "i");

  useEffect(() => {
    if (folderCreateSuccessMessage) {
      setInputs(initialState);
      document.querySelector(".add-sprint").click();
    }
  }, [folderCreateSuccessMessage]);

  useEffect(() => {
    if (listCreateSuccessMessage) {
      setInputs(initialState);
    }
  }, [listCreateSuccessMessage]);

  const modalToDisplay = () => {
    switch (modalSwitch) {
      case "list":
        return (
          <AddListOption
            onSubmit={() => {
              addListOnSubmit();
            }}
            value={inputs.listName}
            handleChange={handleChange}
            loading={onCreateListLoading}
          />
        );
      case "folder":
        return (
          <div className="createSubmenu">
            <div className="pb-2">
              <label className="mb-1">Folder Name</label>
              <input
                className="form-control"
                value={inputs.folderName}
                onChange={({ target: { value } }) =>
                  handleChange("folderName", value)
                }
              />
            </div>
            <form
              className="pb-2"
              onSubmit={(e) => {
                e.preventDefault();
                if (listRef.length) {
                  const duplicateList = inputs.folderLists.find((each) =>
                    uniqueRegEx(each).test(listRef),
                  );
                  if (!duplicateList) {
                    handleChange("folderLists", [
                      ...inputs.folderLists,
                      listRef,
                    ]);
                    setListRef("");
                  }
                }
              }}
            >
              <label className="mb-1">List</label>
              <div className="inpWrp">
                <input
                  className="form-control"
                  value={listRef}
                  onChange={({ target: { value } }) => setListRef(value)}
                />
                <button
                  type="submit"
                  className={`btn btn-submit ${
                    listRef.trim()?.length ? "show" : ""
                  }`}
                >
                  <svg width="12.319" height="9.186" viewBox="0 0 12.319 9.186">
                    <path
                      id="Icon_awesome-check"
                      data-name="Icon awesome-check"
                      d="M4.184,13.583l-4-4a.616.616,0,0,1,0-.871l.871-.871a.616.616,0,0,1,.871,0l2.7,2.7L10.4,4.758a.616.616,0,0,1,.871,0l.871.871a.616.616,0,0,1,0,.871L5.055,13.583A.616.616,0,0,1,4.184,13.583Z"
                      transform="translate(0 -4.577)"
                      fill="currentcolor"
                    />
                  </svg>
                </button>
              </div>
            </form>
            <div className="d-flex gap-2 flex-wrap pb-2">
              {inputs.folderLists.map((each) => (
                <div className="button-container" key={each}>
                  <span
                    className="close-button"
                    onClick={() =>
                      handleChange(
                        "folderLists",
                        inputs.folderLists.filter(
                          (eachList) => eachList !== each,
                        ),
                      )
                    }
                  >
                    &times;
                  </span>
                  <div className="button-box">{each}</div>
                </div>
              ))}
            </div>

            <button
              type="button"
              className={`btn btn-primary ${
                onCreateLoading && "isLoading disable"
              }`}
              onClick={() => {
                if (inputs.folderName.trim().length) {
                  addFolderThunk({
                    postData: {
                      name: inputs.folderName,
                      project: selectedProject,
                      lists: inputs.folderLists,
                    },
                    navigate: (id) =>
                      navigate(`/project/${shortCode}/list/${id}`),
                  });
                }
              }}
            >
              {onCreateLoading && (
                <span className="btn-loader">
                  <span className="spinner-border spinner-border-sm" />
                </span>
              )}
              Save
            </button>
          </div>
        );
      case "sprint":
        return (
          <CreateSprint
            shortCode={shortCode}
            showModal={modalSwitch}
            setShowModal={() => switchModal("")}
            selectedProject={selectedProject}
          />
        );
      default:
        return null;
    }
  };

  return (showList &&
    hasPermission(permissionConstants.permissions.PROJECT_LIST_CREATE)) ||
    (showFolder &&
      hasPermission(permissionConstants.permissions.PROJECT_FOLDER_CREATE)) ||
    (showSprint &&
      hasPermission(permissionConstants.permissions.PROJECT_SPRINT_CREATE)) ? (
    <Dropdown
      bsPrefix="add-sprint"
      autoClose="outside"
      onToggle={() => {
        setInputs(initialState);
        if (modalSwitch !== "sprint") switchModal("");
      }}
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <AddProjectIcon />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {showList &&
          hasPermission(
            permissionConstants.permissions.PROJECT_LIST_CREATE,
          ) && (
            <Dropdown.Item onClick={() => onClick("list")}>
              <span className="pe-2">
                <AddListIcon />
              </span>
              List
            </Dropdown.Item>
          )}
        {showFolder &&
          hasPermission(
            permissionConstants.permissions.PROJECT_FOLDER_CREATE,
          ) && (
            <Dropdown.Item onClick={() => onClick("folder")}>
              <span className="pe-2">
                <AddFolderIcon />
              </span>
              Folder
            </Dropdown.Item>
          )}
        {showSprint &&
          hasPermission(
            permissionConstants.permissions.PROJECT_SPRINT_CREATE,
          ) && (
            <Dropdown.Item
              onClick={() => {
                onClick("sprint", selectedProject);
                dispatch(sprintActions.setSprint(""));
              }}
            >
              <span className="pe-2">
                <AddSprintIcon />
              </span>
              Sprint
            </Dropdown.Item>
          )}
        {modalToDisplay()}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

AddSprintPopUp.propTypes = {
  switchModal: PropTypes.func,
  modalSwitch: PropTypes.string,
  selectedProject: PropTypes.string,
  showList: PropTypes.bool,
  showFolder: PropTypes.bool,
  showSprint: PropTypes.bool,
  folder: PropTypes.string,
  shortCode: PropTypes.string,
};

export default AddSprintPopUp;
