import { useState, useEffect } from "react";

function useMainScrollPosition(className1, className2) {
  const [scrollPosition, setScrollPosition] = useState(null);

  const handleScroll = (e) => {
    const { scrollHeight, clientHeight, scrollTop } = e.target;
    const currentScrollPosition = scrollHeight - (scrollTop + clientHeight);
    if (currentScrollPosition !== scrollPosition)
      setScrollPosition(currentScrollPosition);
  };

  useEffect(() => {
    const divElements = document.querySelector(`.${className1}`);
    if (divElements) {
      const mainElement = divElements.querySelector(className2);
      mainElement?.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (divElements) {
        const mainElement = divElements.querySelector("div");
        mainElement?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [className1]);

  return scrollPosition;
}

export default useMainScrollPosition;
