import { createSlice } from "@reduxjs/toolkit";

export const name = "projectDashboard";

const createReducers = () => {
  const setProjectDashboardCounts = (state, { payload }) => {
    console.log("three", payload);
    state.projectDashboardCount = payload;
  };
  const setProjectDashboardTasks = (state, { payload }) => {
    state.projectDashboardTasks = payload;
  };
  const setProjectPerformData = (state, { payload }) => {
    state.performChartData = payload;
  };
  return {
    setProjectDashboardCounts,
    setProjectDashboardTasks,
    setProjectPerformData,
  };
};

function createInitialState() {
  return {
    projectDashboardCount: [],
    projectDashboardTasks: [],
    performChartData: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const projectManagementActions = slice.actions;
export const projectManagementReducer = slice.reducer;
