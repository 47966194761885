import PropTypes from "prop-types";
import { SearchIco } from "../svgIcons";

const SearchBar = ({ value, onChange }) => {
  return (
    <div className="sidenavSearch">
      <div className="searchOuter">
        <div className="ico">
          <SearchIco />
        </div>
        <input
          type="text"
          placeholder="search"
          className="form-control"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchBar;
