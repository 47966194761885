import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import projectDashboardServices from "../../../../services/projectManagementService";
import { projectManagementActions } from "../index";

export const getProjectDashboardCounts = createAsyncThunk(
  "projectDashboard-count",
  async function (payload, { dispatch }) {
    console.log("one");
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await projectDashboardServices.projectDashboardCounts(payload);
      dispatch(projectManagementActions.setProjectDashboardCounts(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(projectManagementActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
