/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../utils/permissionUtils";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

export const Status = ({
  TaskData,
  patchFunction,
  dbName,
  type,
  dropDownVisibility,
  dropDownVisibilityPermissionConstant,
  needCount,
}) => {
  const { ticketStatus } = useSelector((s) => s.ticketReducer);
  const { userProfile } = useSelector((state) => state.userProfile);

  const handleStatusClick = (id) => {
    patchFunction(type, {
      id: TaskData._id,
      patchData: { [dbName]: id },
      needCount,
    });
  };

  const commonPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules.COMMON_TICKET_SETTINGS,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const completePermission = commonPermission(
    permissionConstants.permissions.COMMON_TICKET_MARK_AS_COMPLETED,
  );

  const checkComplete =
    ticketStatus?.status?.find((e) => e.title === "COMPLETE")?._id ===
    TaskData[dbName];

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="status-info"
      ref={ref}
      onClick={(e) => {
        if (!checkComplete) {
          if (dropDownVisibility && dropDownVisibilityPermissionConstant) {
            e.preventDefault();
            onClick(e);
          }
        }
      }}
    >
      {children}
    </div>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div ref={ref} className={className} aria-labelledby={labeledBy}>
          {children}
        </div>
      );
    },
  );

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {ticketStatus?.status?.map(({ _id, title, colorInfo }) =>
          TaskData[dbName] === _id ? (
            <div
              style={{ backgroundColor: colorInfo }}
              className="status ms-1 "
            >
              {title}
            </div>
          ) : null,
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        className="color-overlay-status dropdown-menu"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "black",
          }}
          className="status-drp-dwn  drp-items"
        >
          {ticketStatus?.status?.map((e) =>
            e.title === "COMPLETE" ? (
              completePermission && (
                <div key={e.title}>
                  <span
                    className="status-color"
                    style={{ color: e.colorInfo }}
                    onClick={() => {
                      handleStatusClick(e._id);
                    }}
                  >
                    <span
                      className="color-square"
                      style={{
                        backgroundColor: e.colorInfo,
                        marginRight: "8px",
                      }}
                    />
                    {e.title}
                  </span>
                </div>
              )
            ) : (
              <div key={e.title}>
                <span
                  className="status-color"
                  style={{ color: e.colorInfo }}
                  onClick={() => {
                    handleStatusClick(e._id);
                  }}
                >
                  <span
                    className="color-square"
                    style={{
                      backgroundColor: e.colorInfo,
                      marginRight: "8px",
                    }}
                  />
                  {e.title}
                </span>
              </div>
            ),
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
Status.propTypes = {
  TaskData: PropTypes.isRequired,
};
