import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import { getUsersFeed } from "../../../../services/feedService";
import { setUsersFeedByID } from "..";

export const getUsersFeedById = createAsyncThunk(
  "UsersFeed",
  async function ({ id, params, reset }, { dispatch }) {
    console.log("ben", id, params);
    dispatch(alertActions.clear());
    try {
      const { data } = await getUsersFeed(id, params);
      dispatch(setUsersFeedByID({ data, reset }));
      // dispatch(feedAction.successMessage(data.message));F
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
