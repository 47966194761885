import { Outlet, useOutletContext } from "react-router-dom";
import "../../design/custom/pages/user-settings.scss";

const UserSettings = () => {
  const [shownav, setShownav] = useOutletContext();

  return <Outlet context={[shownav, setShownav]} />;
};

export default UserSettings;
