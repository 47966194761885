import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import myNotificationsService from "../../../../services/MyNotificationService";
import { myNotificationsActions } from "../index";
import { getActivityNotifications } from "./getActivityNotifications";

export const updateNotificationSettings = createAsyncThunk(
  "myNotifications",
  async function ({ patchData, id }, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await myNotificationsService.notificationSettingsUpdate(
        id,
        patchData,
      );
      dispatch(getActivityNotifications());
      dispatch(myNotificationsActions.setNotificationSettings(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(myNotificationsActions.setErrorMesage(errorMessage));
      dispatch(alertActions.error(errorMessage));
    }
  },
);
