import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert";
import { commonAction } from "..";
import ticketService from "../../../../services/common";

export const getTicketPriority = createAsyncThunk(
  "ticketPriority",
  async function (payload, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await ticketService.getTicketPriority();
      dispatch(commonAction.setTicketPriority(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
