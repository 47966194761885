import PropTypes from "prop-types";

const OtherProjectsModal = ({ data, show }) => {
  const projectLength = data?.length;

  // const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = useState(null);

  // const handleProjectInfoButtonClick = ({ id, description }) => {
  //   setIsProjectInfoModalOpen(
  //     (prevIsProjectInfoModalOpen) => !prevIsProjectInfoModalOpen,
  //   );
  //   onShowOtherProjectInfo(!isProjectInfoModalOpen);
  //   on;
  // };

  return (
    <div
      className={`dropdown-menu dropeffect pt-2 pb-2 ${show ? "show" : ""}`}
      aria-labelledby="dropdownMenuButton1"
    >
      <div className="dropdown-item no-hover searcharea d-flex align-items-center no-bg  pb-1 ">
        Other Projects without allocation : {projectLength}{" "}
        {/* <input
          type="text"
          className="form-control no-hover"
          placeholder="Search Projects"
        /> */}
      </div>
      <div className="divitions pb-1 pt-1">
        <hr className="mt-0 mb-0" />
      </div>

      <div className="custom-scroll drop-content-scroll">
        {data?.length > 0 ? (
          data?.map((e) => (
            <button
              type="button"
              className="dropdown-item d-flex dropdown-toggle"
              // onClick={() =>
              //   handleProjectInfoButtonClick({
              //     id: e?.id,
              //     description: e?.description,
              //   })
              // }

              // id="dropdownMenuButton2"
              // data-bs-toggle="dropdown"
              // aria-expanded="false"
            >
              {e?.photo && (
                <div className="pro-icon">
                  <img src={e?.photo} alt="" />
                </div>
              )}
              <span>{e?.projectName}</span>
              {/* <div className="info">Info</div> */}
            </button>
          ))
        ) : (
          <button
            type="button"
            className="dropdown-item d-flex dropdown-toggle"
          >
            <span>No Other Projects</span>
          </button>
        )}
      </div>
    </div>
  );
};

OtherProjectsModal.propTypes = {
  show: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      projectName: PropTypes.string,
      // Add more PropTypes for other properties as needed
    }),
  ),
};

export default OtherProjectsModal;
