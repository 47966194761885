import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import PublicRoutes from "./PublicRoute";
import PrivateRoutes from "./PrivateRoute";
import Layout from "../structure/Layout";
import Authentication from "../pages/Authentication/Loadable";
import TicketManagement from "../pages/TicketManagement";
import Teams from "../pages/Project/Components/Teams";
import DashBoard from "../pages/Dashboard";
import TicketManagementLayout from "../pages/TicketManagement/TicketManagementLayout";
import Technology from "../pages/Technology/loadable";
// import DashBoard from "../pages/Dashboard";
import ProjectDashboard from "../pages/ProjectManagement/loadable";
// import Teams from "../pages/Teams/index";
import ActivityLog from "../pages/TicketManagement/ActivityLog";
import TrashListing from "../pages/Trash/loadable";
import Calendar from "../pages/TicketManagement/calendar/loadable";
import Department from "../pages/Permissions/Department/Loadable";
import Role from "../pages/Permissions/Role/Loadable";
import PermissionRoutes from "./PermissionRoutes";
import Gantt from "../pages/TicketManagement/components/Gantt";
import Notification from "../pages/Notification";
import SettingsLayout from "../structure/SettingsLayout";
import UserSettings from "../pages/UserSettings";
import BasicSettings from "../pages/UserSettings/components/BasicSettings";
import TicketManagementBoard from "../pages/TicketManagement/TicketManagementBoard";
import TicketList from "../pages/TicketManagement/TicketManagementList/index";
import Documents from "../pages/Document";
import PageNotFound from "../pages/404Page/pageNotFound";
import ReportListing from "../pages/Report/List/index";
import ReportManagement from "../pages/Report/index";
import ReportCalendar from "../pages/Report/calendar";
import ReportDashboard from "../pages/Report/dashboard";
import MyNotification from "../pages/UserSettings/components/MyNotification/MyNotification";
import { Integrations } from "../pages/UserSettings/components/Integrations";
import AllocationDashboard from "../pages/Allocations/dashboard";
import AllocationManagement from "../pages/Allocations/index";
import AllocationList from "../pages/Allocations/AllocationList";
import Cronjob from "../pages/Setup/Server/Cronjob";

import TimeSheetPage from "../pages/Timesheet";

// import permissionConstants from "../utils/permissionUtils/permissionConstants";
import NewTabModal from "../pages/TaskModal/NewTab";
import FeedPost from "../pages/Feed";
import permissionConstants from "../utils/permissionUtils/permissionConstants";
import ActivityLogs from "../pages/ActivityLogs";
import Design from "../pages/Setup/WhiteLabel/Design";
import EmailAccount from "../pages/Setup/WhiteLabel/EmailAccount";
import Icons from "../pages/Setup/WhiteLabel/Icons";
import Todo from "../pages/widgets/todo";
import Notes from "../pages/widgets/notes";
import MeetingSchedule from "../pages/widgets/meetingSchedule";
import EmployeeListing from "../pages/EmployeeMgmt/EmployeeListing";
import ClientManagement from "../pages/ClientMgmnt/ClientManagement";

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <PublicRoutes />,
        children: [
          { path: "/", element: <Authentication title="PMS | Login" /> },
        ],
      },
      {
        element: <PrivateRoutes />,
        children: [
          {
            element: <NewTabModal />,
            path: "/:shortCode/:key/:listId/:type/:id",
          },
          {
            element: <Layout />,
            children: [
              {
                path: "/dashboard",
                id: "dashboard",
                element: <DashBoard title="PMS | Dashboard" />,
              },
              {
                path: "/trash",
                element: <TrashListing title="Trash" />,
                id: "trash",
              },
              {
                path: "/cron-job",
                element: <Cronjob title="Cron Job" />,
                id: "cronjob",
              },
              {
                path: "/design",
                element: <Design title="Design" />,
                id: "design",
              },
              {
                path: "/email-account",
                element: <EmailAccount title="Email Account" />,
                id: "email-account",
              },
              {
                path: "/icons/:iconHandler",
                element: <Icons title="Icons" />,
                id: "icons",
              },
              {
                path: "/docs",
                element: <Documents title="Documents" />,
                id: "docs",
              },
              {
                path: "report",
                element: <ReportManagement title="Report" />,
                id: "report",
                children: [
                  {
                    path: "",
                    element: <ReportListing />,
                    id: "reportlist",
                  },
                  {
                    path: "calendar",
                    element: <ReportCalendar />,
                    id: "reportcalendar",
                  },
                  {
                    path: "dashboard",
                    element: <ReportDashboard />,
                    id: "reportDashboard",
                  },
                ],
              },
              {
                element: (
                  <PermissionRoutes
                    permission={permissionConstants.modules.ALLOCATION}
                  />
                ),
                children: [
                  {
                    path: "allocation",
                    element: <AllocationManagement title="Allocation" />,
                    id: "allocation",
                    children: [
                      {
                        path: "",
                        element: <AllocationList />,
                        id: "listUser",
                      },
                      {
                        path: "dashboard",
                        element: <AllocationDashboard />,
                        id: "allocationDashboard",
                      },
                    ],
                  },
                  {
                    element: <PrivateRoutes />,
                    children: [
                      {
                        path: "/todo",
                        element: <Todo title="Todo" />,
                        id: "todo",
                      },
                      {
                        path: "/notes",
                        element: <Notes title="Notes" />,
                        id: "notes",
                      },
                      {
                        path: "/meeting",
                        element: <MeetingSchedule title="Meeting Scheduler" />,
                        id: "meeting",
                      },
                    ],
                  },
                ],
              },
              {
                element: (
                  <PermissionRoutes
                    permission={permissionConstants.modules.ALLOCATION}
                  />
                ),
                children: [
                  {
                    path: "/timesheet",
                    element: <TimeSheetPage title="Timesheet" />,
                    id: "timesheet",
                  },
                ],
              },
              {
                path: "/activitylogs",
                element: <ActivityLogs title="ActivityLog" />,
                id: "activitylogs",
              },
              {
                path: "/feed",
                element: <FeedPost title="Feed" />,
                id: "feed",
              },
              {
                element: (
                  <PermissionRoutes
                    permission={permissionConstants.submodules.DEPARTMENT}
                    isSubModule
                  />
                ),
                children: [
                  {
                    path: "/departments",
                    element: <Department title="Category" />,
                  },
                ],
              },
              {
                element: (
                  <PermissionRoutes
                    permission={permissionConstants.submodules.CLIENT_LIST}
                    isSubModule
                  />
                ),
                children: [
                  {
                    path: "/client",
                    element: <ClientManagement title="Client List" />,
                  },
                ],
              },
              {
                element: (
                  <PermissionRoutes
                    permission={permissionConstants.submodules.EMPLOYEE_LIST}
                    isSubModule
                  />
                ),
                children: [
                  {
                    path: "/employee",
                    element: <EmployeeListing title="Employee List" />,
                  },
                ],
              },
              {
                element: (
                  <PermissionRoutes
                    permission={permissionConstants.submodules.ROLE}
                    isSubModule
                  />
                ),
                children: [
                  {
                    path: "/roles",
                    element: <Role title="Permission" />,
                  },
                ],
              },
              {
                path: "notifications/:isRead",
                element: <Notification title="Notifications" />,
                id: "notifications",
              },

              {
                path: "/project/:shortCode/",
                id: "project",
                element: <TicketManagement title="Project Management" />,
                children: [
                  {
                    element: <TicketManagementLayout />,
                    children: [
                      {
                        path: "",
                        element: <ProjectDashboard />,
                      },
                      {
                        path: "docs",
                        element: <Documents />,
                      },
                      {
                        path: "overview",
                        element: <div>Project Overview</div>,
                      },
                      {
                        element: (
                          <PermissionRoutes
                            permission={
                              permissionConstants.submodules
                                .PROJECT_MANAGEMENT_TEAMS
                            }
                            isSubModule
                          />
                        ),
                        children: [
                          {
                            path: "teams",
                            element: <Teams title="Teams" />,
                            id: "teams",
                          },
                        ],
                      },
                      {
                        path: "technology",
                        element: <Technology />,
                      },
                    ],
                  },
                  {
                    path: ":type/:listId",
                    children: [
                      {
                        element: (
                          <PermissionRoutes
                            permission={
                              permissionConstants.submodules
                                .PROJECT_MANAGEMENT_LIST_VIEW
                            }
                            to="board"
                            isSubModule
                          />
                        ),
                        children: [
                          {
                            path: "",
                            element: <TicketList />,
                            id: "list",
                          },
                        ],
                      },
                      {
                        element: (
                          <PermissionRoutes
                            permission={
                              permissionConstants.submodules
                                .PROJECT_MANAGEMENT_BOARD_VIEW
                            }
                            to="gantt"
                            isSubModule
                          />
                        ),
                        children: [
                          {
                            path: "board",
                            element: <TicketManagementBoard />,
                            id: "board",
                          },
                        ],
                      },
                      {
                        element: (
                          <PermissionRoutes
                            permission={
                              permissionConstants.submodules
                                .PROJECT_MANAGEMENT_GANTT_VIEW
                            }
                            to="calendar"
                            isSubModule
                          />
                        ),
                        children: [
                          {
                            path: "gantt",
                            element: <Gantt />,
                            id: "gantt",
                          },
                        ],
                      },
                      {
                        element: (
                          <PermissionRoutes
                            permission={
                              permissionConstants.submodules
                                .PROJECT_MANAGEMENT_CALENDER
                            }
                            to="activitylog"
                            isSubModule
                          />
                        ),
                        children: [
                          {
                            path: "calendar",
                            element: <Calendar />,
                            id: "calendar",
                          },
                        ],
                      },
                      {
                        element: (
                          <PermissionRoutes
                            permission={
                              permissionConstants.submodules
                                .PROJECT_MANAGEMENT_ACTIVITY_LOG
                            }
                            isSubModule
                          />
                        ),
                        children: [
                          {
                            path: "activitylog",
                            element: <ActivityLog />,
                            id: "activityLog",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            element: <SettingsLayout />,
            children: [
              {
                path: "/user-settings",
                element: <UserSettings />,
                children: [
                  {
                    element: (
                      <PermissionRoutes
                        permission={
                          permissionConstants.submodules.BASIC_SETTINGS
                        }
                        to="integrations"
                        isSubModule
                      />
                    ),
                    children: [
                      {
                        path: "",
                        element: <BasicSettings />,
                      },
                    ],
                  },
                  { path: "integrations", element: <Integrations /> },
                  { path: "notifications", element: <MyNotification /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
