import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { pushNotificationActions } from "../store/slices/PushNotification";

const firebaseConfig = {
  apiKey: "AIzaSyBM2ItnE33XflhYOpXhPGOvk4_Eg-zlE6g",
  authDomain: "clickup-erp-5-april.firebaseapp.com",
  projectId: "clickup-erp-5-april",
  storageBucket: "clickup-erp-5-april.appspot.com",
  messagingSenderId: "376457887030",
  appId: "1:376457887030:web:47788c33a84983a677c797",
  measurementId: "G-ED7MQ51V3D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const getTokens = (setTokenFound, dispatch) => {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");

      return getToken(messaging, {
        vapidKey:
          "BMjPpEDJi9Q3KiN-hDKIieuFLZCOUcTiQ9zLKXc5feMUPvvKAQRxlJGbzNGVtz-OVRh8L1MQWmmt5J7-OK0MPms",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("currentToken: ", currentToken);
            dispatch(pushNotificationActions.setNotifyToken(currentToken));
            setTokenFound(true);
          } else {
            console.log("Cannot get token");
            setTokenFound(false);
          }
        })
        .catch((err) => {
          console.log("Error in getting token", err);
        });
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Messagepayload", payload);
      resolve(payload);
    });
  });
