import Gateway from "../gateway/gateway";

export const getDocuments = (params) => Gateway.get("/documents", { params });
export const postDocuments = (updatedValue) =>
  Gateway.post("/documents", updatedValue, {
    headers: { "Content-Type": "Multipart/formdata" },
  });
export const getDocumentsById = (id) => Gateway.get(`/documents/${id}`);
export const getDelete = (params) =>
  Gateway.delete(`/documents/${params.id}`, { params });
