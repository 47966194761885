import { createSlice } from "@reduxjs/toolkit";

export const name = "theme";

const createReducers = () => {
  const isDarkMode = (state, { payload }) => {
    state.darkMode = payload;
  };

  return {
    isDarkMode,
  };
};

function createInitialState() {
  return {
    darkMode: localStorage.getItem("theme") ?? false,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const { isDarkMode } = slice.actions;
export const themeReducer = slice.reducer;
