import "./design/app.scss";
import "react-toastify/dist/ReactToastify.css";

// import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useEffect } from "react";

import { ToastContainer, Zoom } from "react-toastify";
import { LanguageProvider } from "./context/LanguageContext";
import { TitleProvider } from "./context/TitleContext";

import Toaster from "./components/Toaster";

// import createWebSocketConnection from "./helpers/socketIO";
import PushNotification from "./components/PushNotification.js/PushNotification";
import socket from "./helpers/socketIO";

const App = () => {
  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);
  return (
    <>
      <ToastContainer
        closeButton
        transition={Zoom}
        icon={false}
        theme="light"
      />
      <Toaster />
      <HelmetProvider>
        <TitleProvider>
          <LanguageProvider>
            <Outlet />
          </LanguageProvider>
        </TitleProvider>
        <PushNotification />
      </HelmetProvider>
    </>
  );
};

export default App;
