import { createSlice } from "@reduxjs/toolkit";

export const name = "notes";

const createReducers = () => {
  const setNotesList = (state, { payload }) => {
    state.notesList = payload.data.data;
  };

  const singlenotes = (state, { payload }) => {
    state.singlenotes = payload.data.data;
  };

  return {
    setNotesList,
    singlenotes,
  };
};

function createInitialState() {
  return {
    notesList: [],
    singlenotes: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const notesActions = slice.actions;
export const notesReducer = slice.reducer;
