import { createSlice } from "@reduxjs/toolkit";

export const name = "github";

const createReducers = () => {
  const setAccessToken = (state, action) => {
    console.log("action.payload", action);
    state.gitAccessToken = action.payload;
    state.successMessage = action.payload.successMessage;
  };
  const disconnectGit = (state, action) => {
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };
  const getRepos = (state, action) => {
    state.successMessage = action.payload.successMessage;
    state.gitRepos = action.payload;
    state.errorMessage = "";
  };
  const getProjectRepos = (state, action) => {
    state.successMessage = action.payload.successMessage;
    state.ProjectRepositories = action.payload.getProjectRepo;
    state.errorMessage = "";
  };
  const getUserDetails = (state, { action, payload }) => {
    console.log("payload", payload);
    state.successMessage = action.payload.successMessage;
    state.gitUserDetails = payload;
    state.errorMessage = "";
  };
  const postRepoDetails = (state, { action, payload }) => {
    state.successMessage = action.payload.successMessage;
    state.gitRepoDetailsPost = payload;
    state.errorMessage = "";
  };
  const getTableDetails = (state, action) => {
    state.successMessage = action.payload.successMessage;
    state.getUserTableDetails = action.payload;
    state.errorMessage = "";
  };
  //
  const createGitBranchName = (state, action) => {
    state.successMessage = action.payload.successMessage;
    state.getCreatedGitBranchName = action.payload;
    state.errorMessage = "";
  };
  const createGitBranch = (state, action) => {
    console.log("action", action);
    // state.successMessage = action.payload.successMessage;
    state.getCreatedGitBranch = action.payload.success;
    state.errorMessage = "";
  };
  // eslint-disable-next-line no-unused-vars
  const createGitPr = (state, { action, payload }) => {
    state.successMessage = payload.pullRequests;
    state.getCreateGitPr = payload.success;
    state.errorMessage = "";
  };

  const resetCreatePr = (state) => {
    state.getCreateGitPr = false;
  };
  // eslint-disable-next-line no-unused-vars
  const getAllPullRequests = (state, { action, payload }) => {
    // state.successMessage = action.payload.successMessage;
    state.getAllPullRequestsData = payload;
    state.errorMessage = "";
  };
  const getAllBranchs = (state, { action, payload }) => {
    console.log(action);
    // state.successMessage = action.payload.successMessage;
    state.getAllBranchsData = payload.branches;
    state.errorMessage = "";
  };

  // eslint-disable-next-line no-unused-vars
  const getCommits = (state, { action, payload }) => {
    // state.successMessage = action.payload.successMessage;
    state.getCommitsData = payload;
    state.errorMessage = "";
  };
  const editTableData = (state, { action, payload }) => {
    state.successMessage = action.payload.successMessage;
    state.editTableDatas = payload;
    state.errorMessage = "";
  };
  const deleteTableData = (state, { action, payload }) => {
    state.successMessage = action.payload.successMessage;
    state.deleteTableDatas = payload;
    state.errorMessage = "";
  };
  const getAllGitContents = (state, { payload }) => {
    // state.successMessage = payload.successMessage;
    state.gitContents = payload;
    state.errorMessage = "";
  };

  const getUserProjectRepos = (state, { payload }) => {
    state.successMessage = payload.success;
    state.userRepositories = payload.gitUserRepo;
    state.errorMessage = "";
  };
  const getFolderStructure = (state, { payload }) => {
    // state.successMessage = payload.successMessage;
    state.gitFolders = payload;
    state.errorMessage = "";
  };
  const resetCreatedGitBranch = (state) => {
    state.getCreatedGitBranch = false;
  };
  const gitSuccessStatus = (state, { payload }) => {
    state.gitSuccess = payload;
  };

  return {
    setAccessToken,
    disconnectGit,
    getRepos,
    getUserDetails,
    getTableDetails,
    postRepoDetails,
    createGitBranchName,
    createGitBranch,
    createGitPr,
    resetCreatePr,
    getAllPullRequests,
    getAllBranchs,
    editTableData,
    deleteTableData,
    getProjectRepos,
    getAllGitContents,
    getUserProjectRepos,
    getCommits,
    getFolderStructure,
    resetCreatedGitBranch,
    gitSuccessStatus,
  };
};

function createInitialState() {
  return {
    gitAccessToken: "",
    errorMessage: "",
    successMessage: "",
    gitRepos: "",
    gitUserDetails: "",
    gitRepoDetailsPost: "",
    getUserTableDetails: "",
    getCreatedGitBranchName: "",
    getCreatedGitBranch: "",
    getCreateGitPr: false,
    getAllPullRequestsData: [],
    getAllBranchsData: [],
    editTableDatas: "",
    deleteTableDatas: "",
    ProjectRepositories: [],
    gitContents: [],
    userRepositories: [],
    getCommitsData: [],
    gitFolders: [],
    gitSuccess: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const gitHubAction = slice.actions;
export const gitHubReducer = slice.reducer;
