import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import timesheetService from "../../../../services/timesheet";
import { timesheetActions } from "..";
import { alertActions } from "../../alert/index";

export const getUserTimesheet = createAsyncThunk(
  "get-timesheet",
  async (payload, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const {
        data: { data },
      } = await timesheetService.getTimesheet(payload);

      dispatch(timesheetActions.setTimesheet(data));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
