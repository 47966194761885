import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import moment from "moment";
import { useThunk } from "../../../hooks/useThunk";
import CustomSelect from "../../../components/CustomSelect";
import CustomModal from "../../../components/CustomModal";
import CustomTable from "../../../components/CustomTable";
import Button from "../../../components/Button/index";
import CronActivityLog from "./CronActivityLog";
import CommonSkeleton from "../../../components/SkeletonLoader/CommonSkeleton";
import { Deletemodal } from "../../../components/DropDowns/DeleteModal";
import { cronjobSingleData } from "../../../store/slices/cronjob/thunk/getByIdCronjob";
import { postCronjob } from "../../../store/slices/cronjob/thunk/postCronjob";
import { updateCronjob } from "../../../store/slices/cronjob/thunk/updateCronjob";
import { getCronjobHistory } from "../../../store/slices/cronjob/thunk/getCronjobHistory";
import { getCommandList } from "../../../store/slices/cronjob/thunk/commandList";
import { clearReducer } from "../../../store/slices/cronjob/thunk/resetCronReducer";
import { cronjobTrigger } from "../../../store/slices/cronjob/thunk/triggerCronjob";

import "react-datepicker/dist/react-datepicker.css";
import { IconOverview, HistoryIcon } from "./svgIcons";
import calenderImg from "../../../assets/images/calendar-sm.svg";
import copyImg from "../../../assets/images/copy-2.svg";
import hourGlass from "../../../assets/images/hours-01.svg";
import infoIcon from "../../../assets/images/information_icon.svg";
import callIcon from "../../../assets/images/call_ic_icon.svg";
import blueCircle from "../../../assets/images/round-img-001.svg";
import infoIcon_cron from "../../../assets/images/info.svg";

const AddEditCronjob = ({ showModal, closeModal, onEdit, toggleChange }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [notificationModal, setNotificationModal] = useState(null);
  const [copy, setCopy] = useState("Copy to clipboard");
  const [Field, setField] = useState({
    day: null,
    week: null,
    month: null,
    timeHour: null,
    timeMinutes: null,
  });
  const [infoText, setInfoText] = useState(null);
  const [increment, setIncrement] = useState(20);

  const [commandListAll] = useThunk(getCommandList);
  const [cronjobDataById, summaryLoader] = useThunk(cronjobSingleData);
  const [createCronjob, createLoader] = useThunk(postCronjob);
  const [updateCronjobValue, editLoader] = useThunk(updateCronjob);
  const [cronjobHistoryById, historyLoader] = useThunk(getCronjobHistory);
  const [resetReducerValue] = useThunk(clearReducer);
  const [trigger, triggerLoader] = useThunk(cronjobTrigger);

  const [cronjobField, setCronjobField] = useState({
    title: "",
    desc: "",
    interval: null,
    day: null,
    week: null,
    month: null,
    timeHour: null,
    timeMinutes: null,
    command: "",
    isActive: null,
  });

  console.log("cronjobField", cronjobField);

  const [errorField, setErrorField] = useState({
    title: "",
    desc: "",
    interval: null,
    day: null,
    week: null,
    month: null,
    timeHour: null,
    timeMinutes: null,
    command: null,
    isActive: null,
  });

  const { cronjobById, cronjobHistory, successMessage, commandList } =
    useSelector((state) => state.cronjob);

  console.log("errorField", errorField);

  console.log("cronjobHistory", cronjobHistory);

  const scrollRef = useBottomScrollListener(() => handleScrollBottom());

  useEffect(() => {
    if (successMessage) {
      setNotificationModal(null);
      setEditMode(false);
    }
    if (successMessage && showModal) {
      resetFieldValue();
    }
  }, [successMessage]);

  useEffect(() => {
    if (activeTab === 2 && onEdit)
      cronjobHistoryById({ id: onEdit, params: { limit: increment } });
  }, [activeTab, increment]);

  useEffect(() => {
    if (onEdit) {
      cronjobDataById(onEdit);
      setEditMode(false);
      setActiveTab(1);
      setIncrement(20);
    }
    if (showModal) {
      setEditMode(true);
      setActiveTab(1);
    }
    commandListAll();
    setCopy("Copy to clipboard");
  }, [onEdit, showModal]);

  useEffect(() => {
    if (cronjobById) {
      setCronjobField({
        title: cronjobById?.title,
        desc: cronjobById?.desc,
        interval: `${cronjobById?.interval}`,
        day: cronjobById?.day,
        week: cronjobById?.week,
        month: cronjobById?.month,
        timeHour: cronjobById?.timeHour,
        timeMinutes: cronjobById?.timeMinutes,
        command: cronjobById?.command,
        isActive: cronjobById?.isActive,
      });
    }
  }, [cronjobById]);

  useEffect(() => {
    setErrorField({
      ...errorField,
      day: null,
      week: null,
      month: null,
      timeHour: null,
      timeMinutes: null,
      command: null,
    });

    switch (getInterval(cronjobField.interval)) {
      case "BY MINUTE":
        setField({
          day: null,
          week: null,
          month: null,
          timeHour: null,
          timeMinutes: true,
        });
        setInfoText(
          `To run a cron job every ${cronjobField.timeMinutes} minutes`,
        );
        break;
      case "HOURLY":
        setField({
          day: null,
          week: null,
          month: null,
          timeHour: null,
          timeMinutes: true,
        });
        setInfoText(
          `To run a cron job every hour at the ${cronjobField.timeMinutes}th minute`,
        );
        break;
      case "DAILY":
        setField({
          day: null,
          week: null,
          month: null,
          timeHour: true,
          timeMinutes: true,
        });
        setInfoText(
          `To run a cron job every day at ${cronjobField.timeHour}:${cronjobField.timeMinutes}`,
        );
        break;
      case "WEEKLY":
        setField({
          day: null,
          week: true,
          month: null,
          timeHour: true,
          timeMinutes: true,
        });
        setInfoText(
          `To run a cron job every ${
            moment.weekdays()[cronjobField.week - 1] ?? null
          } at ${cronjobField.timeHour}:${cronjobField.timeMinutes}`,
        );
        break;
      case "MONTHLY":
        setField({
          day: true,
          week: null,
          month: null,
          timeHour: true,
          timeMinutes: true,
        });
        setInfoText(
          `To run a cron job on the day ${cronjobField.day} of every month at ${cronjobField.timeHour}:${cronjobField.timeMinutes}`,
        );
        break;
      case "YEARLY":
        setField({
          day: true,
          week: null,
          month: true,
          timeHour: true,
          timeMinutes: true,
        });
        setInfoText(
          `To run a cron job on ${
            moment.months()[cronjobField.month - 1] ?? null
          } ${cronjobField.day} at ${cronjobField.timeHour}:${
            cronjobField.timeMinutes
          } every year`,
        );
        break;
      default:
        setCronjobField({ ...cronjobField, interval: "3" });
        break;
    }
  }, [cronjobField]);

  const handleScrollBottom = () => {
    if (cronjobHistory.totalCount > increment) {
      setIncrement(increment + 5);
    }
  };

  const getInterval = (row) => {
    const intervalMap = {
      1: "BY MINUTE",
      2: "HOURLY",
      3: "DAILY",
      4: "WEEKLY",
      5: "MONTHLY",
      6: "YEARLY",
    };

    return intervalMap[row] || "";
  };

  const validate = (name, value) => {
    let errorMessage = "";
    switch (name) {
      case "title":
        errorMessage = value.trim()?.length > 30 ? "Title is too long." : "";
        break;
      case "desc":
        errorMessage =
          value.trim()?.length > 300
            ? "Maximum length for description is reached"
            : "";
        break;
      default:
        break;
    }

    setCronjobField({ ...cronjobField, [name]: value });

    setErrorField({
      ...errorField,
      [name]: errorMessage,
    });
    return !errorMessage;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    validate(name, value);
  };

  const submitValidation = (obj) => {
    let formValid = true;

    Object.entries(obj).forEach(([key, value]) => {
      if (value === null || value === undefined || value === "") {
        setErrorField((prevState) => ({
          ...prevState,
          [key]: `${key} is Required`,
        }));
        formValid = false;
      }
    });

    const OnChangeErrExist = Object.values(errorField).every(
      (i) => i === null || i === "",
    );

    if (!OnChangeErrExist) return false;

    return formValid && obj;
  };

  const checkFieldValid = (obj) => {
    const validField = {};
    Object.keys(obj).forEach((key) => {
      if (!(key in Field) || Field[key]) {
        validField[key] = obj[key];
      }
    });

    return submitValidation(validField);
  };

  const handleSubmit = () => {
    const postCronjob = {
      title: cronjobField.title,
      desc: cronjobField.desc,
      interval: Number(cronjobField.interval),
      command: cronjobField.command,
      day: cronjobField?.day,
      week: cronjobField?.week,
      month: cronjobField?.month,
      timeHour: cronjobField?.timeHour,
      timeMinutes: cronjobField?.timeMinutes,
    };

    if (checkFieldValid(postCronjob)) {
      if (onEdit)
        updateCronjobValue({
          updatedValue: checkFieldValid(postCronjob),
          id: onEdit,
        });
      else createCronjob(checkFieldValid(postCronjob));
    }
  };

  const resetFieldValue = () => {
    setCronjobField({
      title: "",
      desc: "",
      interval: null,
      day: null,
      week: null,
      month: null,
      timeHour: null,
      timeMinutes: null,
      command: "",
    });
    setErrorField({
      title: "",
      desc: "",
      interval: null,
      day: null,
      week: null,
      month: null,
      timeHour: null,
      timeMinutes: null,
      command: null,
    });
  };

  const renderHeader = () => (
    <div className="tab-header-lg cstm-spcg">
      <ul>
        <li>
          <button
            className={`tb-btn ${activeTab === 1 ? "active" : ""}`}
            type="button"
            onClick={() => setActiveTab(1)}
          >
            <span>
              <IconOverview />
            </span>
            Summary
          </button>
        </li>

        <li>
          <button
            className={`tb-btn ${activeTab === 2 ? "active" : ""}`}
            type="button"
            onClick={() => setActiveTab(2)}
          >
            <span>
              <HistoryIcon />
            </span>
            History
          </button>
        </li>
        <li>
          <button
            className={`tb-btn ${activeTab === 3 ? "active" : ""}`}
            type="button"
            onClick={() => setActiveTab(3)}
          >
            <span>
              <IconOverview />
            </span>
            Activity Log
          </button>
        </li>
      </ul>
      <div className="right-control-head">
        <div className="dropdown-popover">
          <button
            className="popover-btn  dropdown-toggle"
            type="button"
            onClick={() =>
              setNotificationModal({
                name: cronjobField.title,
                docId: onEdit,
                openclose: true,
              })
            }
          >
            <img src={callIcon} alt="..." />
          </button>
          <div className="dropdown-menu-p-over">
            <div className="d-flex flex-wrap">
              <div className="top">
                <div className="info-icon">
                  <img src={infoIcon} alt="..." />
                </div>
                <span>Info</span>
              </div>
              <div className="info-txt">
                Click here to manually trigger the Cron Job immediately.
              </div>
            </div>
          </div>
        </div>

        <div
          className="swith-outer"
          onClick={() => {
            toggleChange({ id: onEdit, isActive: !cronjobField.isActive });
            setCronjobField((prevValue) => ({
              ...prevValue,
              isActive: !cronjobField.isActive,
            }));
          }}
        >
          <div className="switch-txt">
            {cronjobField.isActive ? "On" : "Off"}
          </div>
          <div className="switch">
            <input type="checkbox" checked={cronjobField.isActive} />
            <span className="slider round" />
          </div>
        </div>
      </div>
    </div>
  );

  const renderSummary = () => {
    const RangeDropdown = [
      {
        title: "Day",
        fieldName: "day",
        List: Array.from({ length: 30 }, (_, index) => index + 1),
      },
      { title: "Week", fieldName: "week", List: moment.weekdays() },
      { title: "Month", fieldName: "month", List: moment.months() },
    ].filter(({ fieldName }) => Field[fieldName]);

    return (
      <div
        className={`conetnt_area_main cstm-spcg ${!editMode && "summaryMode"}`}
      >
        <div className="row">
          <div className="col-lg-4 col-md-6 form-outer-col">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Title <span>*</span>
            </label>
            {summaryLoader ? (
              <CommonSkeleton />
            ) : (
              <input
                name="title"
                type="text"
                className="form-control"
                placeholder="Add Title"
                readOnly={!editMode}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={cronjobField.title}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            )}
            <span className="text-capitalize" style={{ color: "red" }}>
              {editMode && errorField.title}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col form-outer-col mb-2">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Description <span>*</span>
            </label>
            {summaryLoader ? (
              <CommonSkeleton height={140} />
            ) : (
              <textarea
                name="desc"
                className="form-control text-area-lg"
                placeholder="Add Description"
                readOnly={!editMode}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={cronjobField.desc}
              />
            )}
            <span style={{ color: "red" }}>
              {editMode && errorField.desc && "Description is Required"}
            </span>
          </div>
        </div>

        <div className="chron-details summaryDetail">
          <div className="row">
            <div className="col-lg-3 smm-col">
              <div className="form-label">Start Date</div>
              {summaryLoader ? (
                <CommonSkeleton />
              ) : (
                <div className="smm-val">
                  <span className="ico">
                    <img src={calenderImg} alt="" />
                  </span>
                  <span className="txt">
                    {moment(cronjobById?.startDate).format("DD MMMM YYYY")}
                  </span>
                </div>
              )}
            </div>

            <div className="col-lg-3 smm-col">
              <div className="form-label">Interval</div>
              {summaryLoader ? (
                <CommonSkeleton />
              ) : (
                <div className="smm-val">
                  <span className="ico">
                    <img src={hourGlass} alt="" />
                  </span>
                  <span className="txt">
                    {getInterval(cronjobById?.interval)}
                  </span>
                </div>
              )}
            </div>

            <div className="col-lg-3 smm-col">
              <div className="form-label">Time</div>
              {summaryLoader ? (
                <CommonSkeleton />
              ) : (
                <div className="smm-val">
                  <span className="ico">
                    <img src={calenderImg} alt="" />
                  </span>
                  <span className="txt">{infoText}</span>
                </div>
              )}
            </div>

            <div className="col-12 smm-command">
              <div className="form-label">Command</div>
              {summaryLoader ? (
                <CommonSkeleton />
              ) : (
                <div className="smm-val">
                  <span className="txt">{cronjobField?.command}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="copy_cron_cmd">{copy}</Tooltip>}
                  >
                    <span
                      className="ico"
                      onClick={() => {
                        setCopy("Copied");
                        navigator.clipboard.writeText(cronjobField?.command);
                      }}
                    >
                      <img src={copyImg} alt="" />
                    </span>
                  </OverlayTrigger>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="chron-details editDetail">
          <div className="row">
            <div className="col col-range-slider">
              <label htmlFor="customRange" className="form-label ">
                Cron Job Interval
              </label>
              <div id="slider-labels " className="slider-labels">
                <span>BY MINUTE</span>
                <span>HOURLY</span>
                <span>DAILY</span>
                <span>WEEKLY</span>
                <span>MONTHLY</span>
                <span>YEARLY</span>
              </div>
              <input
                name="interval"
                type="range"
                className="form-range customRange"
                min="1"
                max="6"
                step="1"
                id="customRange"
                value={cronjobField.interval}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>

          <div className="row form-align g-5">
            {RangeDropdown?.map((each) => (
              <div
                className="col-md-4 form-outer-col inr-col "
                key={each.title}
              >
                <label htmlFor="exampleInputEmail1" className="form-label">
                  {each.title}
                  <span> *</span>
                </label>
                <div className="drop__outer drop__outer_col drop__outer_col___01">
                  <div className="dropdown">
                    <CustomSelect
                      options={each.List?.map((val, index) => ({
                        value: index + 1,
                        label: val,
                      }))}
                      classNamePrefix="react-select"
                      // className="floating-input react-select-container"
                      className="react-select-container"
                      onChange={({ value }) =>
                        setCronjobField((prevValue) => ({
                          ...prevValue,
                          [each.fieldName]: value,
                        }))
                      }
                      value={cronjobField[each.fieldName]}
                      // showIndicators
                      maxMenuHeight={180}
                    />
                  </div>
                  <span className="text-capitalize" style={{ color: "red" }}>
                    {editMode && errorField[each.fieldName]}
                  </span>
                </div>
              </div>
            ))}

            <div className="col-md-4 form-outer-col ">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Time <span>*</span>
              </label>

              <div className="otr-row drop__outer">
                {Field.timeHour && (
                  <div className="hrs-min-otr">
                    <div className="dropdown">
                      <CustomSelect
                        classNamePrefix="react-select"
                        options={Array.from({ length: 24 }, (_, index) => ({
                          value: index,
                          label: index,
                        }))}
                        className="react-select-container"
                        onChange={({ value }) =>
                          setCronjobField((prevValue) => ({
                            ...prevValue,
                            timeHour: value,
                          }))
                        }
                        value={cronjobField.timeHour}
                        maxMenuHeight={100}
                      />
                    </div>
                    <div className="hrs-min">Hrs</div>
                  </div>
                )}
                {Field.timeMinutes && (
                  <div className="hrs-min-otr">
                    <div className="dropdown">
                      <CustomSelect
                        classNamePrefix="react-select"
                        options={Array.from({ length: 60 }, (_, index) => ({
                          value: index,
                          label: index,
                        }))}
                        className="react-select-container"
                        onChange={({ value }) =>
                          setCronjobField((prevValue) => ({
                            ...prevValue,
                            timeMinutes: value,
                          }))
                        }
                        value={cronjobField.timeMinutes}
                        maxMenuHeight={100}
                        // menuPosition="fixed"
                      />
                    </div>
                    <div className="hrs-min">Mins</div>
                  </div>
                )}
              </div>
              <span className="text-capitalize" style={{ color: "red" }}>
                {editMode &&
                  (errorField.timeHour || errorField.timeMinutes) &&
                  "Time is Required"}
              </span>
            </div>
            <div className="col-8 form-outer-col">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Command <span>*</span>
              </label>
              <CustomSelect
                classNamePrefix="react-select"
                options={commandList?.map((i) => ({
                  label: i,
                  value: i,
                }))}
                className="react-select-container"
                onChange={({ value }) =>
                  setCronjobField((prevValue) => ({
                    ...prevValue,
                    command: value,
                  }))
                }
                showIndicator
                value={cronjobField.command}
                maxMenuHeight={100}
                // menuPosition="fixed"
              />
              <span className="text-capitalize" style={{ color: "red" }}>
                {editMode && errorField.command}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHistory = () => {
    const renderDateFormat = (row) =>
      row
        ? moment(moment(row).toLocaleString()).format("MMMM Do YYYY, HH:mm")
        : "-";

    const renderErrorDate = (row) =>
      row.action.includes("error") ? "Error encountered" : "-";

    return (
      <div className="conetnt_area_main cstm-spcg table-spcg">
        <div className="content-tables tables_modal">
          <div className="table-responsive custom-scroll table-helight">
            <div className="table-responsive">
              <CustomTable
                tableClassName=""
                divClassName=""
                columns={[
                  {
                    name: "Status",
                    selector: "action",
                    activitySort: true,
                    colClassName: "left-txt",
                    contentClass: "tbl-cont fisrt_th",
                  },
                  // {
                  //   name: "Interval",
                  //   // selector: "date",
                  //   activitySort: true,
                  //   colClassName: "left-txt",
                  //   contentClass: "tbl-cont",
                  // },
                  {
                    name: "Last Run Date",
                    selector: "date",
                    activitySort: true,
                    colClassName: "left-txt",
                    contentClass: "tbl-cont min-width-auto",
                    cell: ({ row }) => renderDateFormat(row.date),
                  },
                  {
                    name: "Last Error",
                    // selector: "date",
                    activitySort: true,
                    colClassName: "left-txt",
                    contentClass: "tbl-cont",
                    cell: ({ row }) => renderErrorDate(row),
                  },
                ]}
                data={cronjobHistory.logs}
                isLoading={historyLoader}
                pagination={{ limit: cronjobHistory.totalCount }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderActivityLog = () =>
    activeTab === 3 && <CronActivityLog ActivityId={onEdit} />;

  const renderFooter = () => (
    <div className="modal-footer border-0 ftr-flex">
      {editMode && (
        <div className="d-flex">
          <div className="form-outer-col">
            <div className="info-bar">
              <div className="icon">
                <img src={infoIcon_cron} alt="..." />
              </div>
              <span>{infoText?.includes("null") ? "-" : infoText}</span>
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <button
          type="button"
          className="btn  btn-001 ms-auto"
          onClick={() => setEditMode(true)}
        >
          Edit
        </button>
      )}
      {editMode && (
        <Button
          type="button"
          onClick={() => handleSubmit()}
          className={`btn  btn-001 btn-002 ${
            (createLoader || editLoader) && "isLoading disable"
          }`}
          disabled={createLoader || editLoader}
        >
          {(createLoader || editLoader) && (
            <span className="btn-loader">
              <span className="spinner-border spinner-border-sm" />
            </span>
          )}{" "}
          Save
        </Button>
      )}
      {/* <button type="button" className="btn  btn-001">
        Cancel
      </button> */}
    </div>
  );

  const tabContentMap = {
    1: renderSummary(),
    2: renderHistory(),
    3: renderActivityLog(),
  };

  return (
    <>
      <CustomModal
        dialgName="modal-dialog"
        className="modal cronjob-modal"
        bodyClassname="border-0 custom-scroll"
        renderOnScroll={scrollRef}
        header={
          onEdit ? (
            renderHeader()
          ) : (
            <div className="modal-title">Add Cron Job</div>
          )
        }
        body={tabContentMap[activeTab]}
        footer={activeTab === 1 && renderFooter()}
        createModal
        show={showModal || !!onEdit}
        closeModal={() => {
          resetReducerValue();
          closeModal(null);
          resetFieldValue();
        }}
        closeButton
      />
      {notificationModal && (
        <Deletemodal
          icon={blueCircle}
          text="Are you sure you want to execute"
          ModalShow={notificationModal}
          cancelModal={() => setNotificationModal(null)}
          deleteFunc={() => trigger({ cronId: notificationModal.docId })}
          closeModal={() => setNotificationModal(null)}
          loaderButton={triggerLoader}
        />
      )}
    </>
  );
};

AddEditCronjob.propTypes = {
  onEdit: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  toggleChange: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
    }),
    PropTypes.oneOf([null]),
  ]),
};

export default AddEditCronjob;
