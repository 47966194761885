import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import noDataImage from "../../assets/images/no-data-found.svg";

const UserNoData = ({ customData }) => {
  const { jokesData } = useSelector((state) => state.jokes);

  return (
    <div className="no-data-wrp">
      <img src={noDataImage} alt="" />
      <div className="title">{customData || " No Data Found !"}</div>
      <div className="tag-line">{jokesData}</div>
    </div>
  );
};

export default UserNoData;
UserNoData.propTypes = {
  customData: PropTypes.string,
};
