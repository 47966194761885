import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import searchNormalIcon from "../../assets/images/search-normal.svg";
// import useThrottledInput from "../../hooks/useThrottledInput";
import closeIcon from "../../assets/images/close.svg";
import Button from "../Button/index";

const Search = ({ handleFilter }) => {
  // const [inputValue, throttledChangeHandler] = useThrottledInput("", 80);
  // useEffect(() => {
  //   handleFilter(inputValue);
  // }, [inputValue]);

  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();

  useEffect(() => {
    setSearchTerm("");
  }, [location.pathname]);

  useEffect(() => {
    setSearchTerm("");
  }, [location.pathname]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleFilter(searchTerm);
    }, 80);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // return (
  //   <input
  //     autoFocus
  //     type='text'
  //     autoComplete='off'
  //     className='live-search-field'
  //     placeholder='Search here...'
  //     onChange={(e) => setSearchTerm(e.target.value)}
  //   />
  // )
  return (
    <div className="search-area d-flex align-items-center">
      <div className="search-icon">
        <img src={searchNormalIcon} alt="Icon" />
      </div>
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <Button
        className={`searchClose ${searchTerm ? "show" : ""}`}
        onClick={() => setSearchTerm("")}
      >
        <img src={closeIcon} alt="close" />
      </Button>
    </div>
  );
};

Search.propTypes = {
  handleFilter: PropTypes.func,
};

export default Search;
