import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { navBarActions } from "..";
import navBarService from "../../../../services/navBarServices";
import { alertActions } from "../../alert";

export const getProjectIcon = createAsyncThunk(
  "list-projectIcons",
  async (params, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const { data } = await navBarService.getProjectIcon(params);

      dispatch(
        navBarActions.setProjectIcon({
          data,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
