import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { reportActions } from "../index";
import reportService from "../../../../services/reportService";
import { alertActions } from "../../alert";

export const getReportTaskAction = createAsyncThunk(
  "get/task",
  async (params, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await reportService.getReportTask(params);
      dispatch(
        reportActions.setReportTask({
          data,
          successMessage,
        }),
      );
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
