import Gateway from "../gateway/gateway";

// validate access token from Google login
const doLoginValidate = (idToken, deviceToken, deviceType, tokenType) =>
  Gateway.post("auth/sign-in/google", {
    idToken,
    deviceToken,
    deviceType,
    tokenType,
  });

export default { doLoginValidate };
