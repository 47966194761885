import { createSlice } from "@reduxjs/toolkit";

export const name = "pushNotification";

const createReducers = () => {
  const setPushNotification = (state, action) => {
    state.pushNotifyData = action.payload;
    state.successMessage = action.payload.successMessage;
    state.errorMessage = "";
  };

  const setNotifyToken = (state, { payload }) => {
    state.notifyToken = payload;
  };
  return {
    setPushNotification,
    setNotifyToken,
  };
};

function createInitialState() {
  return {
    pushNotifyData: null,
    notifyToken: null,
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const pushNotificationActions = slice.actions;
export const pushNotificationReducer = slice.reducer;
