import { createSlice } from "@reduxjs/toolkit";

export const name = "common";

const createReducers = () => {
  const setTicketStatus = (state, { payload }) => {
    state.ticketStatusData = payload.status.status;
  };
  const setTicketPriority = (state, { payload }) => {
    state.ticketPriority = payload.priority;
  };
  const setUsers = (state, { payload }) => {
    state.allUsers = payload.userdata;
  };
  return {
    setTicketStatus,
    setTicketPriority,
    setUsers,
  };
};

function createInitialState() {
  return {
    ticketStatusData: [],
    ticketPriority: [],
    allUsers: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const commonAction = slice.actions;
export const commonReducer = slice.reducer;
