export const iconsRender = (array) => {
  const repeatedItems = [...Array(100)].flatMap(() => array);
  return repeatedItems;
};

export const searchFunction = (searchValue, arrayState, toggleOptions, id) => {
  const filteredState = arrayState
    .filter((e) => {
      if (!id) {
        const folderIndex = e?.folders
          ? e?.folders.findIndex(
              (eachFolder) =>
                searchValue &&
                eachFolder?.name.toLowerCase().startsWith(searchValue),
            )
          : -1;
        const listIncluded = e?.lists.some(
          (e) => searchValue && e?.name.toLowerCase().startsWith(searchValue),
        );
        const sprintIncluded = e?.sprint.some(
          (e) => searchValue && e?.name.toLowerCase().startsWith(searchValue),
        );

        const folderListIndex = e?.folders?.findIndex((each) =>
          each?.list.some(
            (eachList) =>
              searchValue &&
              eachList?.name.toLowerCase().startsWith(searchValue),
          ),
        );

        const folderListIncluded = folderListIndex >= 0;
        const folderNameIncluded = folderIndex >= 0;
        const doesProjectName = e?.name.toLowerCase().startsWith(searchValue);
        return (
          doesProjectName ||
          folderNameIncluded ||
          listIncluded ||
          sprintIncluded ||
          folderListIncluded
        );
      }
      return true;
    })
    .map((e, projectIndex) => {
      const isSingleProject = id;
      if (isSingleProject && e._id !== id) return e;
      const doesProjectName = e?.name.toLowerCase().startsWith(searchValue);
      let newFolders = [];
      let newLists = [];
      let newSprints = [];
      if (doesProjectName) return e;
      if (e.folders && e.folders[0]) {
        newFolders = e.folders.filter(
          (f) =>
            f?.list?.some((each) =>
              each.name.toLowerCase().startsWith(searchValue),
            ) || f.name.toLowerCase().startsWith(searchValue),
        );

        newFolders = newFolders.map((e, folderIndex) => {
          if (e?.name.toLowerCase().startsWith(searchValue)) return e;
          let newLists = [];
          if (e.list && e.list[0]) {
            newLists = e.list.filter((l) =>
              l.name.toLowerCase().startsWith(searchValue),
            );
          }
          if (newLists?.length)
            toggleOptions = [
              ...toggleOptions,
              `${projectIndex.toString()}-${folderIndex.toString()}`,
            ];
          return { ...e, list: newLists };
        });
      }

      if (e.lists && e.lists[0]) {
        newLists = e.lists.filter((f) =>
          f.name.toLowerCase().startsWith(searchValue),
        );
      }
      if (e.sprint && e.sprint[0]) {
        newSprints = e.sprint.filter((f) =>
          f.name.toLowerCase().startsWith(searchValue),
        );
      }

      if (newFolders?.length || newLists?.length || newSprints?.length)
        toggleOptions = [...toggleOptions, projectIndex.toString()];

      return {
        ...e,
        folders: newFolders ?? e.folders ?? [],
        lists: newLists ?? e.lists ?? [],
        sprint: newSprints ?? e.sprint ?? [],
      };
    });
  return { filteredState, toggleOptions };
};
