/* eslint-disable react/prop-types */
import { useEffect, useRef } from "react";

const OutsideClickHandler = ({ onOutsideClick, children }) => {
  const containerRef = useRef();

  const handleClick = (event) => {
    console.log(Array.from(event.target.classList)[0]?.startsWith("epr-"));
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      !Array.from(event.target.classList)[0]?.startsWith("epr-")
    ) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return <div ref={containerRef}>{children}</div>;
};

export default OutsideClickHandler;
