import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { alertActions } from "../../alert/index";
import sprintService from "../../../../services/sprintService";
import { getTrash } from "../../trash/thunks/getTrash";
import { getUserProjects } from "../../project/thunks/getUserProjects";

export const sprintDelete = createAsyncThunk(
  "sprint/delete",
  async function (id, { dispatch }) {
    dispatch(alertActions.clear());
    try {
      const response = await sprintService.deleteSprint(id);
      const { message: successMessage } = response.data;
      dispatch(alertActions.success(successMessage));
      dispatch(getTrash({ page: 1, limit: 10 }));
      dispatch(getUserProjects({}));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
