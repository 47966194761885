export const Like = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.663"
    height="17.684"
    viewBox="0 0 19.663 17.684"
  >
    <path
      id="Vector"
      d="M9.645,16.083a1.98,1.98,0,0,1-1.126,0C5.885,15.184,0,11.434,0,5.077A5.062,5.062,0,0,1,5.049,0,5.009,5.009,0,0,1,9.082,2.034a5.037,5.037,0,0,1,9.082,3.042C18.163,11.434,12.278,15.184,9.645,16.083Z"
      transform="translate(0.75 0.75)"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
export const Comment = () => (
  <span className="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.021"
      height="16.332"
      viewBox="0 0 17.021 16.332"
    >
      <g
        id="Group_59439"
        data-name="Group 59439"
        transform="translate(45.01 -1.338)"
      >
        <path
          id="Vector"
          d="M6.135,1.188H.594A.6.6,0,0,1,0,.594.6.6,0,0,1,.594,0H6.135a.6.6,0,0,1,.594.594A.6.6,0,0,1,6.135,1.188Z"
          transform="translate(-39.865 7.719)"
          fill="currentcolor"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M11.677,16.332a1.407,1.407,0,0,1-.768-.23L7.537,13.854H4.552A4.309,4.309,0,0,1,0,9.3V4.552A4.309,4.309,0,0,1,4.552,0h7.917a4.309,4.309,0,0,1,4.552,4.552V9.3a4.269,4.269,0,0,1-3.958,4.52v1.124a1.369,1.369,0,0,1-.728,1.219A1.4,1.4,0,0,1,11.677,16.332ZM4.552,1.18A3.154,3.154,0,0,0,1.188,4.544v4.75a3.154,3.154,0,0,0,3.365,3.365H7.719a.569.569,0,0,1,.333.1l3.523,2.343a.2.2,0,0,0,.309-.166V13.252a.6.6,0,0,1,.594-.594,3.154,3.154,0,0,0,3.365-3.365V4.544A3.154,3.154,0,0,0,12.477,1.18Z"
          transform="translate(-45.01 1.338)"
          fill="currentcolor"
        />
      </g>
    </svg>
  </span>
);
export const PhotoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Group_60185"
    data-name="Group 60185"
    width="16.089"
    height="16.089"
    viewBox="0 0 16.089 16.089"
  >
    <path
      id="Vector"
      d="M3.647,1.823A1.823,1.823,0,1,1,1.823,0,1.823,1.823,0,0,1,3.647,1.823Z"
      transform="translate(3.54 3.54)"
      fill="currentcolor"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M14.534,4.6h-.36V8.129l-.1-.084a1.708,1.708,0,0,0-2.161,0L8.727,10.78a1.708,1.708,0,0,1-2.161,0l-.26-.215a1.7,1.7,0,0,0-2.023-.107L1.417,12.381a4.086,4.086,0,0,1-.268-1.509V4.451a2.952,2.952,0,0,1,3.3-3.3h6.275V.789A1.6,1.6,0,0,1,10.9,0H4.451A4.089,4.089,0,0,0,0,4.451v6.42a5.151,5.151,0,0,0,.429,2.176,4.081,4.081,0,0,0,4.022,2.275h6.42a4.089,4.089,0,0,0,4.451-4.451V4.421A1.6,1.6,0,0,1,14.534,4.6Z"
      transform="translate(0 0.766)"
      fill="currentcolor"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M3.808,0H1.555A1.4,1.4,0,0,0,.176.766,1.6,1.6,0,0,0,0,1.555V3.808A1.43,1.43,0,0,0,1.555,5.363H3.808A1.6,1.6,0,0,0,4.6,5.187a1.4,1.4,0,0,0,.766-1.379V1.555A1.43,1.43,0,0,0,3.808,0ZM3.294,3.992a1.422,1.422,0,0,1-.613.222A2.145,2.145,0,0,1,1.065,2.819,1.407,1.407,0,0,1,1,2.383,1.286,1.286,0,0,1,1.05,2a1.124,1.124,0,0,1,.736-.8.946.946,0,0,1,.46-.031.929.929,0,0,1,.429.184.991.991,0,0,1,.9-.153A1.265,1.265,0,0,1,4.3,2.819a1.662,1.662,0,0,1-.115.268A2.2,2.2,0,0,1,3.294,3.992Z"
      transform="translate(10.726)"
      fill="currentcolor"
    />
  </svg>
);
export const GroupIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.592"
    height="17.009"
    viewBox="0 0 16.592 17.009"
  >
    <g
      id="Group_60198"
      data-name="Group 60198"
      transform="translate(-918.995 -195)"
    >
      <path
        id="Vector"
        d="M2.484,4.794a.614.614,0,0,0-.174,0,2.405,2.405,0,1,1,.174,0Z"
        transform="translate(929.417 195)"
        fill="#ac80f7"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M3.481,3.9a5.159,5.159,0,0,1-3.439.7,5.426,5.426,0,0,0,.49-2.235A5.1,5.1,0,0,0,0,.052a5.182,5.182,0,0,1,3.473.69A1.76,1.76,0,0,1,3.481,3.9Z"
        transform="translate(931.129 201.653)"
        fill="#ac80f7"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M2.31,4.794a.614.614,0,0,1,.174,0,2.4,2.4,0,1,0-.174,0Z"
        transform="translate(920.378 195)"
        fill="#ac80f7"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M3.784,2.362a5.41,5.41,0,0,0,.49,2.26,5.134,5.134,0,0,1-3.29-.714,1.764,1.764,0,0,1,0-3.165A5.1,5.1,0,0,1,4.308.036,5.282,5.282,0,0,0,3.784,2.362Z"
        transform="translate(918.995 201.653)"
        fill="#ac80f7"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M2.966,5.708a.938.938,0,0,0-.216,0,2.866,2.866,0,1,1,.216,0Z"
        transform="translate(924.441 200.816)"
        fill="#ac80f7"
      />
      <path
        id="Vector-6"
        data-name="Vector"
        d="M.941.717a1.706,1.706,0,0,0,0,3.049,5.077,5.077,0,0,0,5.2,0,1.706,1.706,0,0,0,0-3.049A5.077,5.077,0,0,0,.941.717Z"
        transform="translate(923.766 207.527)"
        fill="#ac80f7"
      />
    </g>
  </svg>
);

export const PublicIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.083"
    height="16.083"
    viewBox="0 0 16.083 16.083"
  >
    <g
      id="Group_60190"
      data-name="Group 60190"
      transform="translate(-1002 -311)"
    >
      <path
        id="Vector"
        d="M3.691,3.675c-.024,0-.056.016-.08.016A8.046,8.046,0,0,1,0,.08C0,.056.016.024.016,0a25.646,25.646,0,0,0,3,.675A26.977,26.977,0,0,0,3.691,3.675Z"
        transform="translate(1002.852 322.531)"
        fill="currentcolor"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M3.739.088A8.117,8.117,0,0,1,0,3.763,24.577,24.577,0,0,0,.732.675,25.154,25.154,0,0,0,3.723,0C3.715.032,3.739.064,3.739.088Z"
        transform="translate(1013.491 322.531)"
        fill="currentcolor"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M3.8,3.8A25.446,25.446,0,0,0,.732,3.072,22.447,22.447,0,0,0,0,0,8.083,8.083,0,0,1,3.8,3.8Z"
        transform="translate(1013.491 311.788)"
        fill="currentcolor"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M3.755.024a25.128,25.128,0,0,0-.667,2.991A23.215,23.215,0,0,0,0,3.739,8.117,8.117,0,0,1,3.675,0C3.7,0,3.731.024,3.755.024Z"
        transform="translate(1002.788 311.853)"
        fill="currentcolor"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M5.613,3.691A25.193,25.193,0,0,0,0,3.691,22.816,22.816,0,0,1,.82.426,1.315,1.315,0,0,0,.836.249,8.368,8.368,0,0,1,2.806,0,8.3,8.3,0,0,1,4.769.249a1.335,1.335,0,0,0,.024.177A23.088,23.088,0,0,1,5.613,3.691Z"
        transform="translate(1007.235 311)"
        fill="currentcolor"
      />
      <path
        id="Vector-6"
        data-name="Vector"
        d="M3.691,5.613a22.55,22.55,0,0,1-3.265-.82,1.315,1.315,0,0,0-.177-.016A8.368,8.368,0,0,1,0,2.806,8.3,8.3,0,0,1,.249.844,1.335,1.335,0,0,0,.426.82,23.94,23.94,0,0,1,3.691,0,26.194,26.194,0,0,0,3.691,5.613Z"
        transform="translate(1002 316.235)"
        fill="currentcolor"
      />
      <path
        id="Vector-7"
        data-name="Vector"
        d="M3.691,2.806a8.368,8.368,0,0,1-.249,1.97,1.315,1.315,0,0,0-.177.016A24.239,24.239,0,0,1,0,5.613,25.193,25.193,0,0,0,0,0,22.816,22.816,0,0,1,3.265.82a.67.67,0,0,0,.177.024A8.354,8.354,0,0,1,3.691,2.806Z"
        transform="translate(1014.392 316.235)"
        fill="currentcolor"
      />
      <path
        id="Vector-8"
        data-name="Vector"
        d="M5.613,0a22.55,22.55,0,0,1-.82,3.265,1.335,1.335,0,0,0-.024.177,8.3,8.3,0,0,1-1.962.249,8.368,8.368,0,0,1-1.97-.249A1.315,1.315,0,0,0,.82,3.265,23.94,23.94,0,0,1,0,0,25.162,25.162,0,0,0,2.806.177,25.041,25.041,0,0,0,5.613,0Z"
        transform="translate(1007.235 323.392)"
        fill="currentcolor"
      />
      <path
        id="Vector-9"
        data-name="Vector"
        d="M6.243,6.243a24.156,24.156,0,0,1-6.053,0A24.156,24.156,0,0,1,.19.19a24.156,24.156,0,0,1,6.053,0A24.156,24.156,0,0,1,6.243,6.243Z"
        transform="translate(1006.825 315.825)"
        fill="currentcolor"
      />
    </g>
  </svg>
);
export function AllEmojiShow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.994"
      height="22.994"
      viewBox="0 0 22.994 22.994"
    >
      <g
        id="Group_60632"
        data-name="Group 60632"
        transform="translate(-1288 -1130.999)"
      >
        <path
          id="Path_10705"
          data-name="Path 10705"
          d="M-1033.544-144.088a11.5,11.5,0,0,1,11.5,11.6,11.5,11.5,0,0,1-11.628,11.389,11.491,11.491,0,0,1-11.364-11.628A11.494,11.494,0,0,1-1033.544-144.088Zm0,21.432a9.951,9.951,0,0,0,9.947-9.784,9.961,9.961,0,0,0-9.958-10.089,9.972,9.972,0,0,0-9.917,9.88A9.959,9.959,0,0,0-1033.549-122.656Z"
          transform="translate(2333.035 1275.087)"
          fill="currentcolor"
        />
        <path
          id="Path_10706"
          data-name="Path 10706"
          d="M-798.884,405.179a6.047,6.047,0,0,1-4.26-1.647,4.866,4.866,0,0,1-1.238-1.9.776.776,0,0,1,.472-1.015.78.78,0,0,1,.993.481,3.638,3.638,0,0,0,1.759,1.965,4.363,4.363,0,0,0,2.182.548,4.257,4.257,0,0,0,3.54-1.688,4.614,4.614,0,0,0,.45-.845.777.777,0,0,1,.687-.5.735.735,0,0,1,.736.42.793.793,0,0,1,.021.677,5.134,5.134,0,0,1-1.986,2.473,5.864,5.864,0,0,1-2.7.977C-798.468,405.154-798.714,405.166-798.884,405.179Z"
          transform="translate(2098.451 744.049)"
          fill="currentcolor"
        />
        <path
          id="Path_10707"
          data-name="Path 10707"
          d="M-785.11,145.079a1.314,1.314,0,0,1-1.323-1.312,1.321,1.321,0,0,1,1.33-1.321,1.322,1.322,0,0,1,1.311,1.328A1.316,1.316,0,0,1-785.11,145.079Z"
          transform="translate(2080.902 995.721)"
          fill="currentcolor"
        />
        <path
          id="Path_10708"
          data-name="Path 10708"
          d="M-488.763,145.082a1.317,1.317,0,0,1-1.317-1.306,1.322,1.322,0,0,1,1.324-1.327,1.322,1.322,0,0,1,1.317,1.322A1.316,1.316,0,0,1-488.763,145.082Z"
          transform="translate(1791.963 995.718)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}

export function ImageUploadEmoji() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_60634"
        data-name="Group 60634"
        transform="translate(-1327 -1116)"
      >
        <g
          id="Group_60633"
          data-name="Group 60633"
          transform="translate(0 -14)"
        >
          <path
            id="Vector"
            d="M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z"
            transform="translate(1329 1132)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
            transform="translate(1334 1136)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,6.953l4.93-3.31a2.253,2.253,0,0,1,2.64.14l.33.29a2.229,2.229,0,0,0,2.82,0L14.88.5A2.229,2.229,0,0,1,17.7.5l1.63,1.4"
            transform="translate(1329.67 1141.998)"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(1327 1130)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
}
