import PropTypes from "prop-types";
import CustomModal from "../../components/CustomModal";
import trash from "../../assets/images/confirm-trash.svg";

const DeleteModal = ({ onDelete, onCancel, show, message }) => {
  const renderHeader = () => {
    return (
      <div className="text-center mx-auto">
        <div className="icons-delete mb-3">
          <img src={trash} alt="trash" />
        </div>
        <h5 id="exampleModalLabel" className="conf-msg">
          {message || "Are you sure you want to delete the task?"}
        </h5>
        {/* <h5 className="conf-msg">
          <b>{name}</b>?
        </h5> */}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <div className="btn-block">
          <button
            className="modal-btn btn-confirm btn"
            type="button"
            onClick={onDelete}
          >
            Confirm
          </button>
          <button
            className="modal-btn btn-cancel btn"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <CustomModal
      className="modal fade delete-conf comment-del"
      dialgName="modal-dialog-centered modal-lg"
      header={renderHeader()}
      footer={renderFooter()}
      bodyClassname="modal-body"
      createModal={7}
      disableCenter={7}
      show={show}
      closeModal={onCancel}
    />
  );
};

DeleteModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  show: PropTypes.bool,
  message: PropTypes.string,
};

export default DeleteModal;
