/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState, useRef } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "react-date-range/dist/styles.css";
import _isEqual from "lodash/isEqual";
import "react-date-range/dist/theme/default.css";
import TicketManagementList from "../../../components/common/TaskManagementCommonTable";
import deleteSvg from "../../../assets/images/delete.svg";
import { useThunk } from "../../../hooks/useThunk";
import {
  deleteTickets,
  deleteTicketAllocation,
  getTicket,
  getUser,
  patchTicketAllocation,
  patchTicket,
  getTicketAllocations,
} from "../../../store";
import { config } from "./Config";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import { Deletemodal } from "../../../components/DropDowns/DeleteModal";
import { getFilterDropdown } from "../../../store/slices/filter/thunks/getFilterDropdown";
import { useFilter } from "../../../context/useFilter";
import {
  setSuccess,
  setAddTaskModal,
  setTicketCount,
  setGetState,
} from "../../../store/slices/ticket";
import { filterQuery } from "../components/Gantt/filter";
import BulkActions from "../../../components/BulkActions/BulkActions";
import {
  hasSubModulePermission,
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../utils/permissionUtils";
import permissionConstants from "../../../utils/permissionUtils/permissionConstants";

const TicketList = () => {
  const [filterData] = useThunk(getFilterDropdown);
  const [deleteTicket, deleteTicketLoader] = useThunk(deleteTickets);
  const [deleteTicketAllocations, deleteAllocationLoader] = useThunk(
    deleteTicketAllocation,
  );
  const [projectByIdThunk] = useThunk(getUser);
  const [ticket, isLoading] = useThunk(getTicket);
  const [patchTicketAllocations] = useThunk(patchTicketAllocation);
  const [ticketAllocation, isLoading1] = useThunk(getTicketAllocations);
  const [selectedRows, setSelectedRows] = useState([]);
  const [patchTickets] = useThunk(patchTicket);
  const { shortCode, listId, type } = useParams();

  const [AccordionIndex, setAccordionIndex] = useState([]);
  const [oldAllocationCount, setOldAllocationCount] = useState([]);
  const [oldTicketCount, setOldTicketCount] = useState([]);
  const [state, setstate] = useState({
    configData: config,
    modalData: false,
    deleteModal: { openclose: false, type: "", id: "" },
    EditLoader: false,
    TicketData: [],
    AllocationData: [],
    TicketCount: [],
  });

  const [params, setParams] = useState({
    [type === "sprint" ? "sprintId" : "listId"]: listId,
    page: 1,
    limit: 25,
    groupBy: "ticketStatus",
    dataCount: 0,
    TicketCount: [],
  });

  const {
    configData,
    deleteModal,
    EditLoader,
    AllocationData,
    TicketData,
    TicketCount,
  } = state;

  const { projectData } = useSelector((state) => state.project);
  const { userProfile } = useSelector((state) => state.userProfile);

  const {
    ticketData,
    Success,
    ticketAllocations,
    ticketStatus,
    ticketCount,
    TableHeading,
    GetState,
  } = useSelector(({ ticketReducer }) => ticketReducer);

  const { filter } = useFilter();

  const dispatch = useDispatch();

  useEffect(() => {
    setParams({
      ...params,
      [type === "sprint" ? "sprintId" : "listId"]: listId,
      page: 1,
      limit: 25,
      groupBy: filter.groupBy || "ticketStatus",
      dataCount: 0,
      TicketCount: [],
    });
    setstate({ ...state, TicketData: [], AllocationData: [] });
    setAccordionIndex([]);
    setGetState(true);
    setOldAllocationCount([{ count: 0 }]);
    setOldTicketCount([{ count: 0 }]);
  }, [listId, filter.show.includes("SeperateTasks")]);

  useEffect(() => {
    if (ticketCount.length > 0) {
      setstate({ ...state, TicketCount: ticketCount });
    }
  }, [ticketCount]);

  const common = (data) => {
    if (filter.groupBy === "ticketStatus" || !filter.groupBy) {
      return ticketStatus?.status
        ?.map(({ _id, title }) => {
          const matchingAllocation = data?.find(
            (allocation) => allocation.groupById === _id,
          );
          return {
            groupById: matchingAllocation?.groupById,
            statusTitle: title,
            data: matchingAllocation ? matchingAllocation.data : [],
          };
        })
        .filter((e) => e.groupById !== undefined);
    }
    return data;
  };
  useEffect(() => {
    if (TableHeading?._id !== listId) return;
    let allocations = ticketAllocations;
    if (ticketAllocations?.length > 0 && !ticketAllocations[0]?.groupById) {
      allocations = [{ groupById: "", data: ticketAllocations }];
    }

    const isTicketDataInTaskData = AllocationData?.some((item) =>
      allocations?.some(
        (newItem) =>
          newItem.groupById === item.groupById &&
          newItem?.data?.every((newDataItem) =>
            item?.data?.some((oldDataItem) =>
              _isEqual(oldDataItem, newDataItem),
            ),
          ),
      ),
    );

    if (AllocationData.length > 0 && isTicketDataInTaskData) {
      if (
        !filter.show.includes("closedSubtasks") &&
        params.groupBy !== "none"
      ) {
        setstate((prevState) => ({
          ...prevState,
          AllocationData: common(AllocationData),
        }));
      }
      return;
    }

    if (allocations?.length > 0) {
      const updatedTicketData = AllocationData.map((originalItem) => {
        const newItem = allocations.find(
          (newItem) => newItem.groupById === originalItem.groupById,
        );

        if (newItem) {
          return {
            groupById: originalItem.groupById,
            data: [...originalItem.data, ...newItem.data],
          };
        }
        return originalItem;
      });

      const oldData = AllocationData.map((e) => e.groupById);
      const newData = allocations.map((e) => e.groupById);
      const checkExists = newData.some((element) => oldData.includes(element));

      if (!checkExists) updatedTicketData.push(...allocations);

      const finalTicketData = common(updatedTicketData);

      if (filter.show.includes("SeperateTasks")) {
        if (filter?.groupBy === "none") {
          setstate({ ...state, AllocationData: finalTicketData });
        } else setstate({ ...state, AllocationData: finalTicketData });
      }
      if (!filter.show.includes("SeperateTasks")) {
        setstate({ ...state, AllocationData: finalTicketData });
      }
    }
  }, [
    ticketAllocations,
    filter.show.includes("SeperateTasks"),
    filter.show.includes("closedSubtasks"),
  ]);

  useEffect(() => {
    if (TableHeading?._id !== listId) return;
    const isTicketDataInTaskData = TicketData?.some((item) =>
      ticketData?.some(
        (newItem) =>
          newItem.groupById === item.groupById &&
          newItem?.data?.every((newDataItem) =>
            item?.data?.some((oldDataItem) =>
              _isEqual(oldDataItem, newDataItem),
            ),
          ),
      ),
    );

    if (TicketData.length > 0 && isTicketDataInTaskData) {
      if (!filter.show.includes("closedTasks")) {
        setstate((prevState) => ({
          ...prevState,
          TicketData: common(TicketData),
        }));
      }
      return;
    }

    if (ticketData.length > 0) {
      const updatedTicketData = TicketData.map((originalItem) => {
        const newItem = ticketData.find(
          (newItem) => newItem.groupById === originalItem.groupById,
        );

        if (newItem) {
          return {
            groupById: originalItem.groupById,
            data: [...originalItem.data, ...newItem.data],
          };
        }
        return originalItem;
      });

      const oldData = TicketData.map((e) => e.groupById);
      const newData = ticketData.map((e) => e.groupById);
      const checkExists = newData.some((element) => oldData.includes(element));

      if (!checkExists) updatedTicketData.push(...ticketData);

      const finalTicketData = common(updatedTicketData);

      setstate((prevState) => ({ ...prevState, TicketData: finalTicketData }));
    }
  }, [ticketData, filter.show.includes("closedTasks")]);

  const isMounted = useRef(false);

  const prevPageRef = useRef(null);

  useEffect(() => {
    prevPageRef.current = params.page;
  }, [params.page]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      dispatch(setGetState(true));
      if (filter?.groupBy === "none" || filter.show.includes("SeperateTasks")) {
        ticketAllocation({ params, key: "list" });
        return;
      }
      ticket(params);
    }
  }, [params]);

  useEffect(() => {
    if (!GetState) {
      dispatch(setGetState(true));
      return;
    }
    let totalCount = 0;
    let remainingCount = 0;
    const filteredTicketCount = ticketCount;

    filteredTicketCount?.map((e, index) => {
      if (AccordionIndex.includes(index)) return;
      if (index <= params?.dataCount) {
        remainingCount += e.count;
      }
      totalCount += e.count;
      return totalCount;
    });

    if (filteredTicketCount?.length > 0) {
      if (
        remainingCount < 25 &&
        totalCount > filteredTicketCount[params?.dataCount]?.count
      ) {
        if (filteredTicketCount?.length === 0) return;
        if (filteredTicketCount.length - 1 === params?.dataCount) return;
        setParams({ ...params, dataCount: params.dataCount + 1 });
      }
    }
  }, [ticketCount, AccordionIndex?.length > 0]);

  const matches = filterQuery(filter.filter);

  const stateUpdate = useCallback(
    matches && matches[Object.keys(matches)[0]].map(({ value }) => value),
    [filter.filter],
  );

  useEffect(() => {
    setstate({ ...state, TicketData: [], AllocationData: [] });
    dispatch(setGetState(true));
  }, [stateUpdate, filter.show.includes("SeperateTasks")]);

  useEffect(() => {
    setParams({
      [type === "sprint" ? "sprintId" : "listId"]: listId,
      page: 1,
      limit: 25,
      groupBy: filter.groupBy || "ticketStatus",
      dataCount: 0,
      TicketCount: [],
      matches,
    });

    dispatch(setGetState(true));
  }, [stateUpdate]);

  useEffect(() => {
    dispatch(setTicketCount({ totalCount: [] }));
    setstate({ ...state, TicketCount: [] });
  }, [listId]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    dispatch(setGetState(true));
    let updatedParams = { ...params };
    const initialParams = { page: 1, limit: 25, dataCount: 0, TicketCount: [] };
    if (filter.me) {
      setstate({ ...state, TicketData: [], AllocationData: [] });
      updatedParams = { ...updatedParams, ...initialParams, me: filter.me };
    }

    if (!filter.me) {
      setstate({ ...state, TicketData: [], AllocationData: [] });
      updatedParams = { ...updatedParams, ...initialParams, me: "" };
    }
    if (filter.search) {
      setstate({ ...state, TicketData: [], AllocationData: [] });
      updatedParams = {
        ...updatedParams,
        ...initialParams,
        title: filter.search,
      };
    }
    if (!filter.search) {
      setstate({ ...state, TicketData: [], AllocationData: [] });
      updatedParams = { ...updatedParams, ...initialParams, title: "" };
    }
    if (filter.assignee) {
      setstate({ ...state, TicketData: [], AllocationData: [] });
      updatedParams = {
        ...updatedParams,
        ...initialParams,
        assignee: filter.assignee,
      };
    }
    if (filter.groupBy) {
      setstate({ ...state, TicketData: [], AllocationData: [] });
      updatedParams = {
        ...updatedParams,
        ...initialParams,
        groupBy: filter.groupBy,
      };
    }
    if (listId) {
      if (type === "sprint") {
        delete updatedParams.listId;
        updatedParams = { ...updatedParams, sprintId: listId };
      } else {
        delete updatedParams.sprintId;
        updatedParams = { ...updatedParams, listId };
      }
    }
    setParams(updatedParams);
  }, [filter.search, filter.me, filter.assignee, filter.groupBy, listId]);

  useEffect(() => {
    const project = projectData?.filter((item) => item.shortCode === shortCode);
    if (project && project[0]?._id) {
      projectByIdThunk(project[0]?._id);
    }
  }, [projectData[0]]);

  useEffect(() => {
    filterData("list");
  }, []);

  useEffect(() => {
    if (filter.show.includes("SeperateTasks") || filter?.groupBy === "none") {
      if (AllocationData?.length > 0) {
        setOldAllocationCount((prevCount) => {
          return (
            AllocationData?.map(({ data }) => ({
              count: data?.length || 0,
            })) || prevCount
          );
        });
      } else {
        setOldAllocationCount([{ count: 0 }]);
      }
    } else {
      if (TicketData?.length > 0) {
        setOldTicketCount((prevCount) => {
          return (
            TicketData?.map(({ groupById, data }) => ({
              groupById,
              data: data?.filter((e) => e.allocations?.length !== 0),
            }))?.map(({ data }) => ({
              count: data?.length || 0,
            })) || prevCount
          );
        });
      } else {
        setOldTicketCount([{ count: 0 }]);
      }
    }
  }, [AllocationData, TicketData]);

  useEffect(() => {
    const { key, success, type, payload } = Success;

    if (key === "patch") {
      if (Array.isArray(success)) {
        if (
          filter.show.includes("SeperateTasks") ||
          filter?.groupBy === "none"
        ) {
          if (payload && Object.keys(payload)?.includes(params?.groupBy)) {
            setstate({
              ...state,
              AllocationData: AllocationData?.map(({ groupById, data }) => {
                let filteredData = data?.filter(
                  (allocation) =>
                    !success.map((e) => e?._id)?.includes(allocation?._id),
                );
                if (success?.[0]?.[params?.groupBy] === groupById) {
                  filteredData = [...filteredData, ...success];

                  return { groupById, data: filteredData };
                }
                return { groupById, data: filteredData };
              }),
              EditLoader: "",
            });
          } else {
            setstate({
              ...state,
              EditLoader: false,
              AllocationData: AllocationData?.map(({ groupById, data }) => {
                const updatedData = data?.map((allocation) => {
                  const updatedAllocation = success?.find(
                    (e) => e._id === allocation?._id,
                  );

                  if (updatedAllocation) {
                    return { ...updatedAllocation, type: "allocations" };
                  }

                  return allocation;
                });

                return { groupById, data: updatedData };
              }),
            });
          }
        } else if (payload && Object.keys(payload)?.includes(params?.groupBy)) {
          let totalCount = 0;

          let totalDataCount = 0;
          TicketData?.map(({ data }, index) => {
            totalDataCount += data.length;
            return totalDataCount;
          });

          ticketCount?.map((e, index) => {
            if (
              !filter.show.includes("closedTasks") &&
              ticketCount[index + 1] === undefined
            )
              return;
            totalCount += e.count;
            return totalCount;
          });

          const groupIds = TicketData?.map((e) => e.groupById);

          let updatedTicketData = TicketData?.map(({ groupById, data }) => {
            const newData = data?.map((ticket) => ({
              ...ticket,
              allocations: success
                ?.map((e) => e?.ticketInfo?._id)
                ?.includes(ticket?._id)
                ? ticket?.allocations?.filter(
                    (allocation) =>
                      !success?.map((e) => e?._id)?.includes(allocation?._id),
                  )
                : ticket?.allocations,
            }));

            let updatedData = newData?.map((ticket) => {
              if (success?.[0]?.[params?.groupBy] === groupById) {
                if (
                  success?.map((e) => e?.ticketInfo?._id)?.includes(ticket?._id)
                ) {
                  const findTicket = success?.filter(
                    (e) => e?.ticketInfo?._id === ticket?._id,
                  );
                  if (ticket?.allocations?.length > 0) {
                    return {
                      ...ticket,
                      allocations: [
                        ...findTicket,
                        ...(ticket?.allocations || []),
                      ],
                    };
                  } else {
                    return { ...ticket, allocations: findTicket };
                  }
                }
              }
              return ticket;
            });

            if (
              !updatedData?.some((ticket) =>
                success?.map((e) => e?.ticketInfo?._id)?.includes(ticket?._id),
              ) &&
              groupById === success?.[0]?.[params?.groupBy]
            ) {
              success?.map((e) =>
                updatedData?.push({
                  ...e?.ticket,
                  type: "Ticket",
                  ticketStatus: null,
                  allocations: [e],
                }),
              );
            }

            return { groupById, data: updatedData };
          });

          if (!groupIds?.includes(success?.[0]?.[params?.groupBy])) {
            if (totalDataCount >= totalCount) {
              const updatedData = success?.map((e) => ({
                ...e?.ticket,
                type: "Ticket",
                ticketStatus: null,
                allocations: [e],
              }));
              updatedTicketData = [
                ...updatedTicketData,
                {
                  groupById: success?.[0]?.[params?.groupBy],
                  data: updatedData,
                },
              ];
            }
          }

          if (Object.keys(payload)?.includes("ticketStatus")) {
            updatedTicketData = common(updatedTicketData);
          }

          setstate({
            ...state,
            TicketData: updatedTicketData?.map(({ groupById, data }) => ({
              groupById,
              data: data?.filter((e) => e.allocations?.length !== 0),
            })),
            EditLoader: "",
          });
        } else {
          setstate({
            ...state,
            TicketData: TicketData?.map(({ groupById, data }) => {
              const updatedData = data?.map((Ticket) => {
                const updatedAllocations = Ticket?.allocations?.map((e) => {
                  if (success?.find((j) => j._id === e._id)) {
                    return success?.find((j) => j._id === e._id);
                  }
                  return e;
                });

                return { ...Ticket, allocations: updatedAllocations };
              });
              return { groupById, data: updatedData };
            }),
            EditLoader: "",
          });
        }
      }
      if (!Array.isArray(success)) {
        if (
          filter.show.includes("SeperateTasks") ||
          filter?.groupBy === "none"
        ) {
          if (payload && Object.keys(payload)?.includes(params?.groupBy)) {
            const findData = AllocationData?.find(
              (e) => success[params?.groupBy] === e?.groupById,
            );
            if (findData) {
              return setstate({
                ...state,
                AllocationData: AllocationData?.map(({ groupById, data }) => {
                  const filteredData = data?.filter(
                    (allocation) => allocation?._id !== success._id,
                  );
                  if (success[params?.groupBy] === groupById) {
                    return {
                      groupById,
                      data: [...filteredData, success],
                    };
                  }
                  return { groupById, data: filteredData };
                }),
                EditLoader: "",
              });
            } else {
              return setstate({
                ...state,
                AllocationData: [
                  ...AllocationData?.map(({ groupById, data }) => {
                    const filteredData = data?.filter(
                      (allocation) => allocation?._id !== success._id,
                    );
                    return { groupById, data: filteredData };
                  }),
                  { groupById: success[params?.groupBy], data: [success] },
                ],
                EditLoader: "",
              });
            }
          } else {
            setstate({
              ...state,
              AllocationData: AllocationData?.map(({ groupById, data }) => {
                const updatedData = data?.map((Ticket) =>
                  Ticket?._id === success?._id
                    ? { ...success, type: "allocations" }
                    : Ticket,
                );
                return { groupById, data: updatedData };
              }),
              EditLoader: "",
            });
          }
        } else if (payload && Object.keys(payload)?.includes(params?.groupBy)) {
          let totalCount = 0;

          let totalDataCount = 0;
          TicketData?.map(({ data }, index) => {
            totalDataCount += data.length;
            return totalDataCount;
          });

          ticketCount?.map((e, index) => {
            if (
              !filter.show.includes("closedTasks") &&
              ticketCount[index + 1] === undefined
            )
              return;
            totalCount += e.count;
            return totalCount;
          });

          const groupIds = TicketData?.map((e) => e.groupById);

          let updatedTicketData = TicketData?.map(({ groupById, data }) => {
            const newData = data?.map((ticket) => ({
              ...ticket,
              allocations:
                ticket?._id === success?.ticketInfo?._id
                  ? ticket?.allocations?.filter(
                      (allocation) => allocation?._id !== success?._id,
                    )
                  : ticket?.allocations,
            }));

            let updatedData = newData?.map((ticket) => {
              if (success[params?.groupBy] === groupById) {
                if (success?.ticketInfo?._id === ticket?._id) {
                  if (ticket?.allocations?.length > 0) {
                    return {
                      ...ticket,
                      allocations: [success, ...(ticket?.allocations || [])],
                    };
                  } else {
                    return { ...ticket, allocations: [success] };
                  }
                }
              }
              return ticket;
            });
            if (
              !updatedData?.some(
                (ticket) => ticket?._id === success?.ticketInfo?._id,
              ) &&
              groupById === success[params?.groupBy]
            ) {
              updatedData?.push({
                ...success?.ticket,
                type: "Ticket",
                ticketStatus: null,
                allocations: [success],
              });
            }

            return { groupById, data: updatedData };
          });

          if (!groupIds?.includes(success[params?.groupBy])) {
            if (totalDataCount >= totalCount) {
              const updatedData = [
                {
                  ...success?.ticket,
                  type: "Ticket",
                  ticketStatus: null,
                  allocations: [success],
                },
              ];
              updatedTicketData = [
                ...updatedTicketData,
                { groupById: success[params?.groupBy], data: updatedData },
              ];
            }
          }

          if (Object.keys(payload)?.includes("ticketStatus")) {
            updatedTicketData = common(updatedTicketData);
          }

          setstate({
            ...state,
            TicketData: updatedTicketData?.map(({ groupById, data }) => ({
              groupById,
              data: data?.filter((e) => e.allocations?.length !== 0),
            })),
            EditLoader: "",
          });
        } else {
          setstate({
            ...state,
            TicketData: TicketData?.map(({ groupById, data }) => {
              const updatedData = data?.map((Ticket) => {
                if (success?.allocations) {
                  if (Ticket?._id === success?._id) {
                    return { ...success, allocations: Ticket?.allocations };
                  }
                } else {
                  const updatedAllocations = Ticket?.allocations?.map((e) => {
                    if (e?._id === success?._id) {
                      return success;
                    }
                    return e;
                  });
                  return { ...Ticket, allocations: updatedAllocations };
                }
                return Ticket;
              });
              return { groupById, data: updatedData };
            }),
            EditLoader: "",
          });
        }
      }
    }

    if (key === "delete") {
      if (type === "Ticket") {
        setstate({
          ...state,
          TicketData: TicketData?.map(({ groupById, data }) => {
            const updatedData = data?.filter(
              (Ticket) => Ticket?._id !== success?._id,
            );
            return { groupById, data: updatedData };
          }),
          deleteModal: {
            openclose: false,
            type: "",
            name: "",
            id: "",
          },
          EditLoader: "",
        });
      }

      if (type === "allocation") {
        if (
          filter.show.includes("SeperateTasks") ||
          filter?.groupBy === "none"
        ) {
          if (Array.isArray(success)) {
            const deletedTicketIds = success?.map((e) => e?._id);
            setstate({
              ...state,
              AllocationData: AllocationData?.map(({ groupById, data }) => {
                const updatedData = data?.filter(
                  (Ticket) => !deletedTicketIds?.includes(Ticket?._id),
                );
                return { groupById, data: updatedData };
              }),
              deleteModal: {
                openclose: false,
                type: "",
                name: "",
                id: "",
              },
              EditLoader: "",
            });
          } else {
            setstate({
              ...state,
              AllocationData: AllocationData?.map(({ groupById, data }) => {
                const updatedData = data?.filter(
                  (Ticket) => Ticket?._id !== success?._id,
                );
                return { groupById, data: updatedData };
              }),
              deleteModal: {
                openclose: false,
                type: "",
                name: "",
                id: "",
              },
              EditLoader: "",
            });
          }
        } else {
          if (Array.isArray(success)) {
            const deletedTicketIds = success?.map((e) => e?._id);
            setstate({
              ...state,
              TicketData: TicketData?.map(({ groupById, data }) => {
                const updatedData = data?.map((ticket) => ({
                  ...ticket,
                  allocations: ticket?.allocations?.filter(
                    (allocation) =>
                      !deletedTicketIds?.includes(allocation?._id),
                  ),
                }));
                return { groupById, data: updatedData };
              }),
              deleteModal: {
                openclose: false,
                type: "",
                name: "",
                id: "",
              },
              EditLoader: "",
            });
          } else {
            setstate({
              ...state,
              TicketData: TicketData?.map(({ groupById, data }) => {
                const updatedData = data?.map((ticket) => ({
                  ...ticket,
                  allocations: ticket?.allocations?.filter(
                    (allocation) => allocation?._id !== success?._id,
                  ),
                }));
                return { groupById, data: updatedData };
              }),
              deleteModal: {
                openclose: false,
                type: "",
                name: "",
                id: "",
              },
              EditLoader: "",
            });
          }
        }
      }
    }

    if (key === "create") {
      if (filter.show.includes("SeperateTasks")) {
        const findData = AllocationData?.find(
          ({ groupById }) => groupById === success?.groupById,
        );

        if (findData) {
          const newAllocationData = AllocationData?.map(
            ({ groupById, data }) => {
              if (groupById === success?.groupById) {
                const updatedData = [...success?.data[0]?.allocations, ...data];

                return { groupById, data: updatedData };
              }
              return { groupById, data };
            },
          );
          return setstate({ ...state, AllocationData: newAllocationData });
        } else {
          setstate({
            ...state,
            AllocationData: [
              {
                groupById: success?.groupById,
                data: success?.data[0]?.allocations,
              },
              ...AllocationData,
            ],
          });
        }
      }

      if (
        filter?.groupBy === "none" ||
        (filter?.groupBy === "none" && filter.show.includes("SeperateTasks"))
      ) {
        if (AllocationData.length >= 1) {
          setstate({
            ...state,
            AllocationData: AllocationData?.map(({ groupById, data }) => {
              const updatedData = [...success?.data[0]?.allocations, ...data];
              return { groupById, data: updatedData };
            }),
          });
        } else {
          setstate({
            ...state,
            AllocationData:
              [{ groupById: "", data: success?.data[0]?.allocations }] || [],
          });
        }
      } else {
        if (TicketData.length >= 1) {
          const findData = TicketData?.find(
            ({ groupById }) => groupById === success?.groupById,
          );
          if (findData) {
            setstate({
              ...state,
              TicketData: TicketData?.map(({ groupById, data }) => {
                if (groupById === success?.groupById) {
                  const updatedData = [success?.data[0], ...data];
                  return { groupById, data: updatedData };
                }
                return { groupById, data };
              }),
            });
          } else {
            setstate({
              ...state,
              TicketData: [success, ...TicketData],
            });
          }
        } else {
          setstate({
            ...state,
            TicketData: [success] || [],
          });
        }
      }
    }

    dispatch(setSuccess(false));
  }, [Success?.success]);

  useEffect(() => {
    if (GetState) return;

    const oldCount =
      filter.show.includes("SeperateTasks") || filter?.groupBy === "none"
        ? oldAllocationCount
        : oldTicketCount;
    const Data =
      filter.show.includes("SeperateTasks") || filter?.groupBy === "none"
        ? AllocationData
        : TicketData?.map(({ groupById, data }) => ({
            groupById,
            data: data?.filter((e) => e.allocations?.length !== 0),
          }));

    if (oldCount.length > 0 && Data) {
      const newAllocationCount = Data?.map(({ data }) => ({
        count: data?.length,
      }));

      let updatedCount = (
        newAllocationCount?.length >= oldCount?.length
          ? newAllocationCount
          : oldCount
      ).map((e, index) => ({
        count:
          (newAllocationCount?.[index]?.count || 0) -
          (oldCount?.[index]?.count || 0),
      }));

      if (ticketCount?.length > 0) {
        updatedCount = updatedCount?.map((e, index) => {
          if (ticketCount[index]?.count > 0) {
            return {
              count: e.count + ticketCount[index]?.count,
            };
          }

          if (ticketCount[index]?.count < 0) {
            return {
              count: e.count + ticketCount[index]?.count,
            };
          }

          return { count: e.count };
        });
      }

      setstate({ ...state, TicketCount: updatedCount });

      dispatch(setTicketCount({ totalCount: updatedCount }));
    }
  }, [oldAllocationCount, oldTicketCount, AllocationData, TicketData]);

  const fetchMoreData = () => {
    if (isLoading) return;
    if (params.limit * params.page <= ticketCount[params?.dataCount]?.count) {
      setParams((prevParams) => ({
        ...prevParams,
        page: prevParams.page + 1,
        limit: 25,
      }));
    }
    if (params.limit * params.page >= ticketCount[params?.dataCount]?.count) {
      if (ticketCount.length - 1 === params?.dataCount) return;
      if (params.page <= prevPageRef.current) {
        setParams((prevParams) => ({
          ...prevParams,
          page: 1,
          limit: 25,
          dataCount: prevParams.dataCount + 1,
        }));
      }
    }
  };

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const isAtBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
        if (isAtBottom) {
          fetchMoreData();
        }
      }
    }, 100);

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ticketCount, TicketCount, params]);

  const ModalShow = (data) => setstate({ ...state, modalData: data });

  const hasPermission = (permission, subModule) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.PROJECTS_MANAGEMENT,
        permissionConstants.submodules[subModule],
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  const handleTicketDelete = (id, type, needCount) => {
    if (type === "Ticket") {
      deleteTicket({ id });
    }
    if (type === "allocations") {
      deleteTicketAllocations({ id, needCount });
    }
  };

  const handleDeleteModalClick = (
    id,
    type,
    TaskData,
    fullTaskData,
    needCount,
  ) => {
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: true,
        type,
        name: TaskData?.title,
        id,
        needCount,
      },
    });
  };

  const closeDeleteModal = () => {
    setstate({
      ...state,
      deleteModal: {
        openclose: false,
        type: "",
        name: "",
        id: "",
      },
    });
  };

  console.log("ticketCount", ticketCount);

  const patchFunction = (types, patchData, field) => {
    setstate({ ...state, EditLoader: patchData?.id });

    if (field === "ticketStatus") {
      patchData = {
        ...patchData,
        listId: { [type === "sprint" ? "sprintId" : "listId"]: listId },
      };
    }
    if (types === "Ticket") {
      patchTickets(patchData);
    }
    if (types === "allocations") {
      patchTicketAllocations(patchData);
    }
  };

  const headerActionConfig = [
    hasPermission(
      permissionConstants.permissions.COMMON_TICKET_LIST_ADD_TASK,
      "COMMON_TICKET_SETTINGS",
    ) && {
      ButtonInnerText: " + NEW TASK",
      onClickFunction: () => dispatch(setAddTaskModal(true)),
      isVisible: true,
      toolTipContent: "Add new Ticket",
    },
  ];

  const ActionButtons = [
    {
      name: "Delete",
      onclickfunction: handleDeleteModalClick,
      className: "text-danger",
      icon: deleteSvg,
      permissionConstant: "COMMON_TICKET_LIST_ACTIONS_DELETE",
    },
  ];

  const handleSelectClick = (selectedValue) => {
    const updatedData = configData?.map((item) => {
      if (item?.name === selectedValue)
        if (item?.isVisible) item.isVisible = false;
        else item.isVisible = true;
      return item;
    });
    setstate({ ...state, configData: updatedData });
  };

  const closeAction = () => {
    setSelectedRows([]);
  };
  const nextTable = (index, AccordionOpen) => {
    const Data =
      filter.show.includes("SeperateTasks") || filter?.groupBy === "none"
        ? AllocationData
        : TicketData;

    if (!AccordionOpen) {
      setAccordionIndex([...AccordionIndex, index]);
    }
    if (!AccordionOpen) {
      if (Data[index + 1]?.data) return;
      if (ticketCount.length - 1 === index) return;
      setParams({ ...params, dataCount: index + 1, page: 1, limit: 25 });
    }
    if (AccordionOpen) {
      setAccordionIndex(AccordionIndex?.filter((e, ind) => index !== ind));
    }
    if (AccordionOpen) {
      if (Data[index + 1]?.data) {
        if (ticketCount[index]?.count > Data[index]?.data?.length) {
          setstate({
            ...state,
            TicketData: TicketData?.slice(0, index + 1),
            AllocationData: AllocationData?.slice(0, index + 1),
          });
        }
        if (ticketCount[index]?.count > Data[index]?.data?.length) {
          setParams({
            ...params,
            page: Data[index].data.length / params.limit + 1,
            limit: 25,
            dataCount: index,
          });
        }
        return;
      }
      if (ticketCount.length - 1 === index) return;
      setParams({ ...params, page: 1, limit: 25, dataCount: index });
    }
  };

  return (
    <>
      {deleteModal?.openclose ? (
        <Deletemodal
          loaderButton={deleteTicketLoader || deleteAllocationLoader}
          ModalShow={deleteModal}
          closeModal={closeDeleteModal}
          cancelModal={closeDeleteModal}
          deleteFunc={handleTicketDelete}
          type={deleteModal?.type}
          needCount={deleteModal?.needCount}
        />
      ) : null}
      <BulkActions
        selectedTasks={selectedRows}
        setSelectedTasks={setSelectedRows}
        closeAction={closeAction}
        showAssignee={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_ASSIGNEE_EDIT,
          "COMMON_TICKET_SETTINGS",
        )}
        showCopy
        showDelete={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_ACTIONS_DELETE,
          "COMMON_TICKET_SETTINGS",
        )}
        showDueDate={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_DUE_DATE_EDIT,
          "COMMON_TICKET_SETTINGS",
        )}
        showMoveSubtask
        showPriority={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_PRIORITY_EDIT,
          "COMMON_TICKET_SETTINGS",
        )}
        showStatus={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_STATUS_EDIT,
          "COMMON_TICKET_SETTINGS",
        )}
      />
      <SubHeader
        showSearchBar={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_LIST_SEARCH,
          "PROJECT_MANAGEMENT_LIST_VIEW",
        )}
        showFilter={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_LIST_CUSTOM_FILTER,
          "PROJECT_MANAGEMENT_LIST_VIEW",
        )}
        showGroupBy={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_LIST_GROUP_BY,
          "PROJECT_MANAGEMENT_LIST_VIEW",
        )}
        showMe={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_LIST_ME,
          "PROJECT_MANAGEMENT_LIST_VIEW",
        )}
        showAssignee
        showShowBtn={hasPermission(
          permissionConstants.permissions.PROJECT_MANAGEMENT_LIST_SHOW_BUTTON,
          "PROJECT_MANAGEMENT_LIST_VIEW",
        )}
        type="list"
      />

      <TicketManagementList
        headerActionConfig={headerActionConfig}
        configData={configData
          ?.map((e) => {
            if (e.dropDownVisibilityPermissionConstant) {
              return {
                ...e,
                dropDownVisibilityPermissionConstant: hasPermission(
                  e.dropDownVisibilityPermissionConstant,
                  e.subModuleConstant,
                ),
              };
            }
            return e;
          })
          .filter((e) =>
            hasPermission(e?.permissionConstant, e.subModuleConstant),
          )}
        TaskData={
          (filter?.groupBy || params?.groupBy) === "none" ||
          filter.show.includes("SeperateTasks")
            ? AllocationData?.length <= 0
              ? []
              : AllocationData
            : TicketData
        }
        showTableName
        tableName2={TableHeading?.name?.split("(")[0]}
        taskAdd={hasPermission(
          permissionConstants.permissions.COMMON_TICKET_LIST_ADD_TASK,
          "COMMON_TICKET_SETTINGS",
        )}
        taskEdit={hasSubModulePermission(
          userProfile?.formattedPermissions,
          "TASK_DETAILS_MODAL",
        )}
        ActionButtons={
          hasPermission(
            permissionConstants.permissions.PROJECT_MANAGEMENT_LIST_ACTIONS,
            "PROJECT_MANAGEMENT_LIST_VIEW",
          ) &&
          ActionButtons?.filter((e) =>
            hasPermission(e.permissionConstant, "COMMON_TICKET_SETTINGS"),
          )
        }
        handleSelectClick={handleSelectClick}
        ModalShow={ModalShow}
        isLoading={isLoading || isLoading1}
        patchFunction={patchFunction}
        groupBy={filter?.groupBy}
        showSubTasks={filter.show.includes("closedSubtasks")}
        ExpandAll={filter.show.includes("expandAll")}
        AddTask={() => dispatch(setAddTaskModal(true))}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        EditLoader={EditLoader}
        taskView={hasSubModulePermission(
          userProfile?.formattedPermissions,
          "TASK_DETAILS_MODAL",
        )}
        checkComplete={filter.show.includes(
          params?.groupBy === "none" || filter.show.includes("SeperateTasks")
            ? "closedSubtasks"
            : "closedTasks",
        )}
        scrollContainerRef={scrollContainerRef}
        TicketCount={ticketCount?.filter((e) => e?.count !== 0)}
        dataCount={params.dataCount}
        filter={filter}
        nextTable={nextTable}
        completeStatus={ticketStatus?.status?.find(
          (e) => e.title === "COMPLETE",
        )}
      />
    </>
  );
};
export default TicketList;
