import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomModal from "../../../../components/CustomModal";
import CustomSelect from "../../../../components/CustomSelect";
import { useThunk } from "../../../../hooks/useThunk";
import {
  addTechnologyIcon,
  getDepartmentList,
  getTechnologyIconById,
  updateTechnologyIcon,
} from "../../../../store";
import { alertActions } from "../../../../store/slices/alert";

import "../../../../design/custom/pages/manage-icons.scss";
import "../../../../design/custom/pages/white-label-elemet.scss";
import closeIcon from "../../../../assets/images/close-01.svg";

const AddModalTechnology = ({ showModal, closeModal }) => {
  // state
  const [technologyDetails, setTechnologyDetails] = useState({
    department: { value: "", error: "" },
    category: { value: "", error: "" },
    technologies: { value: "", error: "" },
    icon: { value: "", error: "" },
  });
  const [tech, setTech] = useState([]);
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState({});

  // action
  const [GetDepartmentList] = useThunk(getDepartmentList);
  const [GetTechnologyIconById] = useThunk(getTechnologyIconById);
  const [AddTechnologyIcon, isLoading] = useThunk(addTechnologyIcon);
  const [UpdateTechnologyIcon, isLoadingUpdateTechnologyIcon] =
    useThunk(updateTechnologyIcon);

  // reducer
  const {
    department: { departmentList },
  } = useSelector((state) => state);
  const { singleTechnologyIcon } = useSelector((state) => state.icon);
  const { value: values } = useSelector((state) => state.alert);

  const getDepartmentOptions = () =>
    departmentList?.result
      ?.map(({ _id, name }) => ({ value: _id, label: name }))
      ?.sort((a, b) => a?.label?.localeCompare(b?.label));

  const getCategoryOptions = () =>
    singleTechnologyIcon?.data?.map((categoryData) => ({
      value: categoryData?.category,
      label: categoryData?.category,
    }));

  const getTechnologyOptions = () =>
    tech?.map((technologies) => ({
      value: technologies?._id,
      label: technologies?.name,
    }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (values?.type === "success") {
      closeModal();
      dispatch(alertActions.clear());
    }
  }, [values]);

  useEffect(() => {
    GetDepartmentList({
      page: "1",
      limit: "10",
    });
  }, []);

  useEffect(() => {
    if (singleTechnologyIcon?.data && showModal._id) {
      setTechnologyDetails({
        department: {
          value: singleTechnologyIcon?.data?.map(
            (i) => i?.departmentInfo?._id,
          )[0],
          error: "",
        },
        category: {
          value: singleTechnologyIcon?.data?.map((i) => i.category)[0],
          error: "",
        },
        technologies: {
          value: singleTechnologyIcon?.data?.map(
            (i) => i?.technologyDetails?._id,
          )[0],
          error: "",
        },
        icon: { value: showModal?.icon, error: "" },
      });
      setImage(showModal?.icon);
      setTech(singleTechnologyIcon?.data?.map((i) => i?.technologyDetails));
    }
  }, [singleTechnologyIcon?.data]);

  useEffect(() => {
    if (showModal?._id) {
      GetTechnologyIconById({
        id: showModal?._id,
        techId: showModal?.technologyId,
      });
    } else {
      setTechnologyDetails({
        department: { value: "", error: "" },
        category: { value: "", error: "" },
        technologies: { value: "", error: "" },
        icon: { value: "", error: "" },
      });
    }
  }, [showModal?._id]);

  const validate = (tempData = technologyDetails) => {
    const newErrors = {};
    if (!tempData?.department?.value) {
      newErrors.department = "Department is required";
    }
    if (!tempData?.category?.value) {
      newErrors.category = "Category is required";
    }
    if (!tempData?.technologies?.value) {
      newErrors.technologies = "Technology is required";
    }
    if (!tempData?.icon?.value && !image) {
      newErrors.icon = "Icon is required";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleChange = (value, key) => {
    if (key === "icon") {
      if (value?.size > 500 * 1024) {
        setErrors({
          ...errors,
          icon: "File size exceeds 500kb. Please select a smaller file.",
        });
        return;
      }
      if (value.type === "image/jpeg" || value.type === "image/png") {
        const reader = new FileReader();

        reader.onload = (event) => {
          const imageUrl = event.target.result;
          const img = new Image();

          img.onload = () => {
            if (img.width === 30 && img.height === 30) {
              setImage(imageUrl);
            } else {
              setErrors({
                ...errors,
                icon: "The image needs to be 30x30 pixels in size.",
              });
            }
          };

          img.src = imageUrl;
        };
        reader.readAsDataURL(value);
      } else {
        setErrors({
          ...errors,
          icon: "Invalid file format. Please select a JPG or PNG file.",
        });
        return;
      }
    }

    if (key === "department") {
      GetTechnologyIconById({ id: value, techId: showModal?.technologyId });
    }

    if (key === "category") {
      setTech(
        singleTechnologyIcon?.data?.filter(
          ({ category }) => category === value,
        )[0]?.technologies,
      );
    }

    const tempData = structuredClone(technologyDetails);
    tempData[key].value = value;
    if (Object.keys(errors).length) validate(tempData);
    setTechnologyDetails(tempData);
  };

  const handleClick = () => {
    const newErrors = validate();
    if (!Object.keys(newErrors).length) {
      const formData = new FormData();
      formData.append("department", technologyDetails?.department?.value);
      formData.append("category", technologyDetails?.category?.value);
      formData.append("technologies", technologyDetails?.technologies?.value);
      if (typeof technologyDetails?.icon?.value !== "string")
        formData.append("icon", technologyDetails?.icon?.value);
      if (isLoading || isLoadingUpdateTechnologyIcon) {
        setTechnologyDetails({
          department: { value: "", error: "" },
          category: { value: "", error: "" },
          technologies: { value: "", error: "" },
          icon: { value: "", error: "" },
        });
      }
      if (showModal?._id) {
        UpdateTechnologyIcon({ id: showModal?._id, data: formData });
      } else {
        AddTechnologyIcon(formData);
      }
    }
  };

  const renderHeader = () => (
    <>
      {" "}
      <h3 className="modal-title" id="addTechIconsLabel">
        {showModal?._id ? "Edit" : "Add"} Technology Icon
      </h3>
    </>
  );

  const renderBody = () => (
    <>
      {" "}
      <div className="">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3 position-relative">
              <label htmlFor="" className="form-label">
                Department<sup className="text-danger">*</sup>
              </label>
              <CustomSelect
                options={getDepartmentOptions()}
                classNamePrefix="react-select"
                className="react-select-container form-control"
                placeholder="Enter Department"
                value={technologyDetails?.department?.value}
                onChange={({ value }) => {
                  handleChange(value, "department");
                }}
                isDisabled={showModal?._id}
              />
              <span className="error__msg">{errors?.department}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3 position-relative">
              <label htmlFor="" className="form-label">
                Category<sup className="text-danger">*</sup>
              </label>
              <CustomSelect
                options={getCategoryOptions()}
                classNamePrefix="react-select"
                className="react-select-container form-control"
                placeholder="Enter Category"
                value={technologyDetails?.category?.value}
                onChange={({ value }) => {
                  handleChange(value, "category");
                }}
                isDisabled={
                  showModal?._id || technologyDetails?.department?.value === ""
                }
              />
              <span className="error__msg">{errors?.category}</span>
            </div>
          </div>

          <div className="col-12">
            <div className="mb-3 position-relative">
              <label htmlFor="" className="form-label">
                Technology<sup className="text-danger">*</sup>
              </label>
              <CustomSelect
                classNamePrefix="react-select"
                className="react-select-container form-control"
                placeholder="Enter technology"
                options={getTechnologyOptions()}
                value={technologyDetails?.technologies?.value}
                onChange={({ value }) => {
                  handleChange(value, "technologies");
                }}
                isDisabled={
                  showModal?._id || technologyDetails?.category?.value === ""
                }
              />
              <span className="error__msg">{errors?.technologies}</span>
            </div>
          </div>
          <div className="col-6">
            <div className="upload-btn-wrapper">
              <label className="file-label" htmlFor="">
                Upload File{" "}
                <span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="id"
                        style={{
                          textAlign: "left",
                          whiteSpace: "nowrap",
                          left: 0,
                        }}
                      >
                        <table className="text-start">
                          <tr>
                            <td>
                              <span className="fw-semibold">Max size:</span>
                            </td>
                            <td>500kb</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-semibold">Dimension:</span>
                            </td>
                            <td>30x30px</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-semibold">Valid type:</span>
                            </td>
                            <td>JPG, PNG</td>
                          </tr>
                        </table>
                      </Tooltip>
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="info"
                      width="10.24"
                      height="10.24"
                      viewBox="0 0 10.24 10.24"
                    >
                      <g id="Group_59384" data-name="Group 59384">
                        <g id="Group_59383" data-name="Group 59383">
                          <path
                            id="Path_10390"
                            data-name="Path 10390"
                            d="M5.12,0a5.12,5.12,0,1,0,5.12,5.12A5.117,5.117,0,0,0,5.12,0Zm0,9.526A4.406,4.406,0,1,1,9.526,5.12,4.411,4.411,0,0,1,5.12,9.526Z"
                            fill="currentcolor"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_59386"
                        data-name="Group 59386"
                        transform="translate(4.601 4.268)"
                      >
                        <g id="Group_59385" data-name="Group 59385">
                          <path
                            id="Path_10391"
                            data-name="Path 10391"
                            d="M230.578,213.4c-.3,0-.519.128-.519.317v2.568c0,.162.216.323.519.323s.526-.162.526-.323v-2.568C231.1,213.525,230.868,213.4,230.578,213.4Z"
                            transform="translate(-230.059 -213.397)"
                            fill="currentcolor"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_59388"
                        data-name="Group 59388"
                        transform="translate(4.568 2.684)"
                      >
                        <g id="Group_59387" data-name="Group 59387">
                          <path
                            id="Path_10392"
                            data-name="Path 10392"
                            d="M228.928,134.208a.486.486,0,1,0,.546.479A.522.522,0,0,0,228.928,134.208Z"
                            transform="translate(-228.375 -134.208)"
                            fill="currentcolor"
                          />
                        </g>
                      </g>
                    </svg>
                  </OverlayTrigger>
                </span>{" "}
              </label>

              <div
                className={
                  image ? "hiddenUpload uploaded" : "hiddenUpload mt-0"
                }
              >
                <input
                  type="file"
                  onChange={({ target: { files } }) =>
                    handleChange(files[0], "icon")
                  }
                />
                <span className="error__msg">{errors?.icon}</span>

                <div className="uploaded-itm">
                  <div className="uploaded-itm-inner">
                    <img src={image} alt="icon" />
                  </div>
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => {
                      setImage("");
                      setTechnologyDetails({
                        ...technologyDetails,
                        icon: { value: "" },
                      });
                    }}
                  >
                    <img src={closeIcon} alt="close" />
                  </button>
                </div>
                <div className="upload-label-task">
                  <span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="id"
                          style={{
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            left: 0,
                          }}
                        >
                          <table className="text-start">
                            <tr>
                              <td>
                                <span className="fw-semibold">Valid type:</span>
                              </td>
                              <td>.jpg,.png</td>
                            </tr>
                          </table>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.662"
                        height="11.51"
                        viewBox="0 0 14.662 11.51"
                      >
                        <g
                          id="cloud-computing"
                          transform="translate(0 -55.031)"
                        >
                          <g
                            id="Group_1262"
                            data-name="Group 1262"
                            transform="translate(0 55.032)"
                          >
                            <g
                              id="Group_1261"
                              data-name="Group 1261"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_4026"
                                data-name="Path 4026"
                                d="M12.216,58.846a5.04,5.04,0,0,0-9.914.861,2.749,2.749,0,0,0,.458,5.461H5.051v-.916H2.76a1.833,1.833,0,0,1,0-3.665.458.458,0,0,0,.458-.458,4.123,4.123,0,0,1,8.169-.8.458.458,0,0,0,.389.367,2.291,2.291,0,0,1-.312,4.559H9.632v.916h1.833a3.207,3.207,0,0,0,.751-6.322Z"
                                transform="translate(0 -55.032)"
                                fill="currentcolor"
                              />
                              <path
                                id="Path_4027"
                                data-name="Path 4027"
                                d="M182.868,249.144l-1.833,1.833.646.646,1.054-1.049v4.394h.916v-4.394l1.049,1.049.646-.646-1.833-1.833A.458.458,0,0,0,182.868,249.144Z"
                                transform="translate(-175.851 -243.457)"
                                fill="currentcolor"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </OverlayTrigger>
                  </span>{" "}
                  Upload File
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer pe-0">
        <button
          type="button"
          className="btn btn-primary btn-save"
          onClick={handleClick}
        >
          {isLoading || isLoadingUpdateTechnologyIcon ? "Loading.." : "Save"}
        </button>
      </div>
    </>
  );

  return (
    <CustomModal
      className="modal fade add-whitelabels-modal show "
      dialgName="modal-lg"
      bodyClassname=""
      header={renderHeader()}
      body={renderBody()}
      createModal
      show={!!showModal}
      closeModal={closeModal}
      closeButton
    />
  );
};
AddModalTechnology.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.bool,
};
export default AddModalTechnology;
