import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import departmentServices from "../../../../services/departmentServices";
import { departmentActions } from "..";
import { alertActions } from "../../alert/index";

export const getDepartmentList = createAsyncThunk(
  "list-department",
  async ({ page, limit, sortOrder, sortBy, search }, { dispatch }) => {
    dispatch(alertActions.clear());
    try {
      const {
        data: { data },
      } = await departmentServices.getDepartmentList({
        page,
        limit,
        sortOrder,
        sortBy,
        search,
      });

      dispatch(departmentActions.setDepartmentList({ data }));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
