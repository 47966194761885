import PropTypes from "prop-types";
import { formatDate } from "./Utils";
import fileIcon from "../../../../../assets/images/folder-open.svg";
import folderGreyIcon from "../../../../../assets/images/folder-gray.svg";

const getParentFolderPath = (path) => {
  const segments = path.split("/");
  if (segments.length > 1) {
    return segments.slice(0, -1).join("/");
  }

  return "/";
};

const Contents = ({ subContents, handleFileClick, setSelectedFolderPath }) => {
  const sortedContents = subContents?.sort((a, b) => {
    if (a.type === "tree" && b.type !== "tree") return -1;
    if (a.type !== "tree" && b.type === "tree") return 1;
    return 0;
  });
  console.log("sortedContents", sortedContents);
  const parentFolderPath =
    subContents?.length > 0 ? getParentFolderPath(subContents[0].path) : "/";
  return (
    <div className="table-responsive custom-scroll">
      <table className="table custom-table docs-table">
        <tbody>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Last commit message</th>
            <th scope="col">Last commit date</th>
          </tr>
          {parentFolderPath !== "/" && (
            <tr>
              <td>
                {" "}
                <span className="icons">
                  <img
                    src={folderGreyIcon}
                    alt=""
                    onClick={() =>
                      setSelectedFolderPath(
                        getParentFolderPath(parentFolderPath),
                      )
                    }
                  />
                  ...
                </span>
              </td>
              <td />
              <td />
            </tr>
          )}
          {sortedContents?.map((item, index) => (
            <tr key={index}>
              <td>
                {item.type === "tree" ? (
                  <span
                    className="fileItem folder"
                    onClick={() => setSelectedFolderPath(item?.path)}
                  >
                    <span className="icons">
                      <img src={folderGreyIcon} alt="" />
                    </span>
                    {item?.path.split("/").pop()}
                  </span>
                ) : (
                  <span
                    onClick={() => handleFileClick(item?.path)}
                    className="fileItem file"
                  >
                    <span className="icons">
                      <img src={fileIcon} alt="" />
                    </span>
                    {item?.path.split("/").pop()}
                  </span>
                )}
              </td>
              <td>{item?.commit?.message}</td>
              <td>{formatDate(item?.commit?.dateTime)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const FolderLists = ({
  folderContents,
  handleFileClick,
  setSelectedFolderPath,
}) => {
  const subContents = folderContents[0]?.sub;

  return (
    <div className="integration-table-wrp filesTable">
      <Contents
        subContents={subContents}
        handleFileClick={handleFileClick}
        setSelectedFolderPath={setSelectedFolderPath}
      />
    </div>
  );
};

Contents.propTypes = {
  subContents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      path: PropTypes.string,
      commit: PropTypes.shape({
        message: PropTypes.string,
        dateTime: PropTypes.string,
      }),
    }),
  ),
  handleFileClick: PropTypes.func.isRequired,
  setSelectedFolderPath: PropTypes.func.isRequired,
};

FolderLists.propTypes = {
  folderContents: PropTypes.arrayOf(
    PropTypes.shape({
      sub: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          path: PropTypes.string,
          commit: PropTypes.shape({
            message: PropTypes.string,
            dateTime: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  handleFileClick: PropTypes.func.isRequired,
  setSelectedFolderPath: PropTypes.func.isRequired,
};
