import PropTypes from "prop-types";
// import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useParams } from "react-router-dom";
// import CustomTable from "../CustomTable";
import "../../design/custom/pages/TaskCard.scss";
import {
  taskCell,
  priorityCell,
  statusCell,
  timeEstimateCell,
  tasksIcon,
} from "./Taskcell";
// import { ThreeDotIco } from "../../structure/SideNav/svgIcons";
import CustomTable from "../CustomTable";

function TaskCard({ title, tasks, isLoading }) {
  const route = useLocation();
  const { shortCode } = useParams();

  const columns = [
    {
      name: "",
      selector: "",
      colClassName: "text-center",
      cell: (row) => tasksIcon(row),
    },
    {
      name: "Task",
      selector: "title",
      titleClasses: "left-txt ps-0 width-001",
      activitySort: true,
      colClassName: "left-txt ps-0",
      cell: (row) => taskCell(row),
    },
    {
      name: "Due Date",
      activitySort: true,
      selector: "dueDateFormatted",
    },
    {
      name: "Priority",
      activitySort: true,
      selector: "flagIcon",
      cell: (row) => priorityCell(row),
    },
    ...(route.pathname === `/project/${shortCode}`
      ? [
          {
            name: "Sub Task",
            activitySort: true,
            selector: "subTasks",
          },
        ]
      : [
          {
            name: "Status",
            activitySort: true,
            selector: "ticketStatusName",
            cell: (row) => statusCell(row),
          },
          {
            name: "Date Created",
            activitySort: true,
            selector: "createdDateFormatted",
          },
        ]),
    {
      name: "Time Estimate",
      activitySort: true,
      selector: "estimate",
      cell: (row) => timeEstimateCell(row),
    },
    {
      name: "Sprints",
      activitySort: true,
      selector: "sprint",
    },
  ];

  return (
    <div className="card-box dashboard-cards">
      <div className="header-otr d-flex flex-wrap">
        <div className="title-md">{title}</div>
        <div className="rgt-sctn">
          {/* <div className="drop-down-menu">
            <Dropdown bsPrefix="dropdown">
              <Dropdown.Toggle
                variant=""
                bsPrefix="drop-dwn-btn dropdown-toggle"
              >
                <ThreeDotIco />
              </Dropdown.Toggle>
              <Dropdown.Menu bsPrefix="dropdown-menu">
                <Dropdown.Item bsPrefix="dropdown-item">Action</Dropdown.Item>
                <Dropdown.Item bsPrefix="dropdown-item">
                  Another Action
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
      </div>
      <div className="content-tables">
        <CustomTable
          tableClassName="table td-center"
          divClassName="table-responsive custom-scroll "
          columns={columns}
          data={tasks}
          pagination={{
            currentPage: 1,
            limit: tasks?.length ? 10 : 3,
          }}
          count={tasks?.length}
          isLoading={isLoading}
          showPagination={false}
        />
      </div>
    </div>
  );
}

TaskCard.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      taskName: PropTypes.string,
      subtask: PropTypes.string,
      subtaskCount: PropTypes.string,
      dueDate: PropTypes.string,
      flagIcon: PropTypes.string,
      statusText: PropTypes.string,
      createdDate: PropTypes.string,
      estimate: PropTypes.string,
      sprint: PropTypes.string,
    }),
  ),
};

export default TaskCard;
