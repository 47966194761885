import { useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import { ArrowIcon } from "./svgIconNotes";
import CustomTable from "../../../components/CustomTable";
import { formatDateStructure, getInitials } from "../../../utils/utils";

const EditHistory = ({ showModal, closeModal }) => {
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
  });
  console.log("showModal", showModal);
  console.log("setPaginationData", setPaginationData);

  const cols = [
    {
      name: "User",
      selector: "userName",
      cell: ({ row, index }) => (
        <div className="tbl-cont ">
          <div className="name-block align-items-center d-flex">
            <span className={`name-ltr bg-${(index % 4) + 1}`}>
              {console.log("row?.updatedBy?.employeeName", row)}
              {getInitials(row?.updatedBy?.employeeName)}
            </span>
            <div className="">{row?.updatedBy?.employeeName}</div>
          </div>
        </div>
      ),
      activitySort: true,
      titleClasses: "text-start",
      colClassName: "text-start",
      icon: true,
    },
    // {
    //   name: "Action",
    //   selector: "action",
    //   cell: ({ row }) => (
    //     <div className="tbl-cont ">{row?.payload?.action}</div>
    //   ),
    //   activitySort: true,
    //   titleClasses: "text-start",
    //   colClassName: "text-start",
    //   icon: true,
    // },

    {
      name: "Date & Time",
      selector: "date",
      cell: ({ row }) => (
        <div className="tbl-cont ">{formatDateStructure(row?.updatedAt)}</div>
      ),
      activitySort: true,
      titleClasses: "text-start",
      colClassName: "text-start",
      icon: true,
    },
    {
      name: "Remark",
      selector: "remarks",
      cell: ({ row }) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip">{row?.remarks}</Tooltip>}
        >
          <div className="tbl-cont " data-for="tooltip">
            {row?.remarks || "-"}
          </div>
        </OverlayTrigger>
      ),
      activitySort: true,
      titleClasses: "text-start",
      colClassName: "text-start",
      icon: true,
    },
  ];
  const renderHeader = () => (
    <div className="modal-hdr-wrp">
      <button type="button" className="btn back-btn" onClick={closeModal}>
        <span>
          <ArrowIcon />
        </span>
        Back
      </button>
      <div className="modal-title">
        <span>Activity Log</span>
      </div>
    </div>
  );

  const renderBody = () => (
    <div className="edit-history-table-wrp">
      <CustomTable
        columns={cols}
        pagination={{
          paginationData,
        }}
        data={showModal?.editHistory}
      />
    </div>
  );

  return (
    <CustomModal
      className="modal fade note-list-modal show"
      show={!!showModal}
      closeModal={closeModal}
      header={renderHeader()}
      body={renderBody()}
      createModal
      closeButton
    />
  );
};
EditHistory.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
};
export default EditHistory;
