import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import {
  oneLight,
  nightOwl,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import "../../../../../design/custom/pages/githubVariationModal.scss";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useThunk } from "../../../../../hooks/useThunk";
import { getGitCommits } from "../../../../../store/slices/gitIntegration/thunks/getGitCommits";
import CommonSkeleton from "../../../../../components/SkeletonLoader/CommonSkeleton";
import UserNoData from "../../../../UserProfile/UserNoData";
import CustomModal from "../../../../../components/CustomModal";
import permissionConstants from "../../../../../utils/permissionUtils/permissionConstants";
import { ExpandIco } from "./svgIcons";

SyntaxHighlighter.registerLanguage("jsx", jsx);

// commit list component
const Commits = ({ commonSl, cmtCount, tablesearchValue, hasPermission }) => {
  const [getCommit, isLoading] = useThunk(getGitCommits);

  // state variable

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileselected, setfileselected] = useState("");
  const [patch, setPatch] = useState();
  const [changes, setChanges] = useState([]);

  const [dropdownStates, setDropdownStates] = useState(null);
  const [activeButton, setActiveButton] = useState("Unified");

  const { userProfile } = useSelector((state) => state.userProfile);

  const highlighterStyle = userProfile?.theme === "Dark" ? nightOwl : oneLight;
  // Handle opening the modal
  const handleOpenModal = (filedata) => {
    setfileselected(filedata);
    setPatch(filedata.patch);
    setIsModalOpen(true);
  };

  const { getCommitsData } = useSelector((state) => state.github);
  useEffect(() => {
    if (commonSl !== "") {
      getCommit(commonSl);
    }
  }, [commonSl]);

  const data = getCommitsData?.length;

  // Filter branches based on searchQuery
  const filteredCommit = tablesearchValue
    ? getCommitsData.filter((commit) =>
        commit.commitId.toLowerCase().includes(tablesearchValue.toLowerCase()),
      )
    : getCommitsData;

  useEffect(() => {
    cmtCount(data);
  }, [cmtCount, data]);

  // date formatting

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  // set username

  function getShortName(user) {
    const names = user?.split(" ");
    const shortName = names
      ?.slice(0, 2)
      .map((name) => name[0].toUpperCase())
      .join("");
    return shortName;
  }

  // commit id display
  function extractFirstTenCharacters(inputString) {
    return inputString.slice(0, 10);
  }

  const toggleDropdown = (commitId) => {
    setDropdownStates(commitId);
  };

  // filename details extract
  function getLastPortionOfFilePath(filePath) {
    const parts = filePath?.split("/");
    return parts[parts.length - 1];
  }

  const parsePatch = (patch) => {
    let addedLineNumber = 0;
    let removedLineNumber = 0;

    return patch?.split("\n").map((line, index) => {
      if (line.startsWith("+")) {
        addedLineNumber += 1;
        return {
          type: "added",
          content: line,
          lineNumber: addedLineNumber,
        };
      }

      if (line.startsWith("-")) {
        removedLineNumber += 1;
        return {
          type: "removed",
          content: line,
          lineNumber: removedLineNumber,
        };
      }

      if (line.startsWith("@@")) {
        const infoRegex = /^@@ -(\d+),(\d+) \+(\d+),(\d+) @@/;
        const match = line.match(infoRegex);
        if (match) {
          const [, oldStart, oldLines, newStart, newLines] = match;
          addedLineNumber = parseInt(newStart, 10);
          removedLineNumber = parseInt(oldStart, 10);

          return {
            type: "info",
            content: line,
            lineNumber: index + 1,
            info: {
              oldStart: parseInt(oldStart, 10),
              oldLines: parseInt(oldLines, 10),
              newStart: parseInt(newStart, 10),
              newLines: parseInt(newLines, 10),
            },
          };
        }
      }

      addedLineNumber += 1;
      removedLineNumber += 1;

      return line.startsWith(" ")
        ? {
            type: "unchanged",
            content: line,
            lineNumber: addedLineNumber,
          }
        : null;
    });
  };

  useEffect(() => {
    const parsedChanges = parsePatch(patch);
    setChanges(parsedChanges);
  }, [patch]);

  const handleClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const renderButton = (buttonType, label) => (
    <button
      key={buttonType}
      type="button"
      className={`btn-01 ${activeButton === buttonType ? "active" : ""}`}
      onClick={() => handleClick(buttonType)}
    >
      {label}
    </button>
  );

  const renderUnified = (item) => {
    if (!item || !item?.type) {
      return null;
    }

    const { type, lineNumber, content } = item;

    if (type === "removed" || type === "added" || type === "unchanged") {
      const isRemoved = type === "removed";
      const isAdded = type === "added";
      const isUnchanged = type === "unchanged";

      const lineNumberLeft = isRemoved || isUnchanged ? lineNumber : "";
      const lineNumberRight = isAdded || isUnchanged ? lineNumber : "";

      // eslint-disable-next-line no-nested-ternary
      const variationClass = isRemoved
        ? "removed"
        : isAdded
        ? "current"
        : "nochange";
      return (
        <div className={`variation-rows ${variationClass}`}>
          <div className="line-wrps">
            <div className="blks">{lineNumberLeft}</div>
            <div className="blks">{lineNumberRight}</div>
          </div>
          <div className="variation-info">
            <SyntaxHighlighter
              language="jsx"
              style={highlighterStyle}
              wrapLongLines
            >
              {content}
            </SyntaxHighlighter>
          </div>
        </div>
      );
    }
  };

  const renderSplit = (patch) => {
    if (!patch) {
      return null;
    }

    const lines = patch ? patch.split("\n") : [];
    let lineNumberRemoved = -1; //  line number for removed lines
    let lineNumberCurrent = -1; //  line number for current lines

    const renderRemovedLines = lines.map((line, index) => {
      if (line.startsWith("@@")) {
        const match = line.match(/@@ -(\d+),\d+ \+(\d+),\d+ @@/) || [];
        if (match) {
          lineNumberRemoved = parseInt(match[1], 10);
          lineNumberCurrent = parseInt(match[2], 10);
        }
        return null;
      }

      // eslint-disable-next-line no-multi-assign
      const currentLineNumber = (lineNumberRemoved += 1);

      return (
        line.startsWith("-") && (
          <div key={index} className="variation-rows diff-chkr removed">
            <div className="line-wrps">
              <div className="single-blk">{currentLineNumber}</div>
            </div>
            <div className="variation-info">
              <SyntaxHighlighter language="jsx" style={highlighterStyle}>
                {line.substring(1)}
              </SyntaxHighlighter>
            </div>
          </div>
        )
      );
    });

    const renderCurrentLines = lines.map((line, index) => {
      if (line.startsWith("+")) {
        // eslint-disable-next-line no-multi-assign
        const currentLineNumber = (lineNumberCurrent += 1);
        return (
          <div key={index} className="variation-rows diff-chkr current">
            <div className="line-wrps">
              <div className="single-blk">{currentLineNumber}</div>
            </div>
            <div className="variation-info">
              <SyntaxHighlighter language="jsx" style={highlighterStyle}>
                {line.substring(1)}
              </SyntaxHighlighter>
            </div>
          </div>
        );
      }
      return null;
    });

    return (
      <div className="row">
        <div className="col-6 d-flex flex-column">
          <div className="deleted-items">{renderRemovedLines}</div>
        </div>
        <div className="col-6 d-flex flex-column">
          <div className="current-items">{renderCurrentLines}</div>
        </div>
      </div>
    );
  };

  const fileName = fileselected.filename || "";

  const lastSlashIndex = fileName.lastIndexOf("/");
  const directoryPath =
    lastSlashIndex > -1 ? fileName.substring(0, lastSlashIndex) : "";
  const actualFileName = fileName.substring(lastSlashIndex + 1);
  const directoryPathWithSlash = directoryPath
    ? `${commonSl?.value}/${directoryPath}`
    : `${commonSl?.value}`;
  function renderHeader() {
    return (
      <>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => setIsModalOpen(false)}
        />
        <div className="gitdetials-head">
          <h5 className="title">
            {directoryPathWithSlash}/<b>{actualFileName}</b>
            <span>
              {`Showing 1 changed file with ${fileselected.additions} additions and ${fileselected.deletions} deletions.`}
            </span>
          </h5>
          <div className="lft-btngrp">
            <div className="btn-group">
              {renderButton("Split", "Split")}
              {renderButton("Unified", "Unified")}
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderBody() {
    return (
      <>
        {changes?.map((item, index) => (
          <>
            {activeButton === "Unified" && (
              <>
                {item?.type === "info" ? (
                  <div className="variation-rows merged" key={index}>
                    <div className="line-wrps">
                      <div className="single-blk">
                        <ExpandIco />
                      </div>
                    </div>

                    <div className="variation-info">
                      <pre>{item?.type === "info" ? item?.content : ""}</pre>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {renderUnified(item)}
              </>
            )}
            {activeButton === "Split" && (
              <div className="row">
                <div className="col-6 d-flex flex-column">
                  {item?.type === "removed" && renderSplit(item?.type, item)}
                </div>

                <div className="col-6 d-flex flex-column">
                  {item?.type === "added" && renderSplit(item?.type, item)}
                </div>
              </div>
            )}
          </>
        ))}
        {activeButton === "Split" ? renderSplit(patch) : null}
      </>
    );
  }

  return (
    <div className="single-bg-wrp">
      <div className="integration-table-wrp">
        <div className="table-responsive custom-scroll">
          {commonSl !== "" && filteredCommit && filteredCommit.length > 0 ? (
            <table className="table custom-table docs-table">
              <tbody>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Commit ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Repo</th>
                  <th scope="col">Branch</th>
                  <th scope="col">By</th>
                  <th scope="col">File</th>
                </tr>
                {isLoading
                  ? Array?.from({
                      length: 8,
                    })?.map((_, index) => (
                      <td key={index}>
                        {Array?.from({
                          length: filteredCommit.length,
                        })?.map((_, index) => (
                          <div className="td-cnt" key={index}>
                            <div className="txt">
                              <CommonSkeleton width={100} />
                            </div>
                          </div>
                        ))}
                      </td>
                    ))
                  : filteredCommit?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.commitMessage}</td>
                        <td>
                          {hasPermission(
                            permissionConstants.permissions
                              .TASK_DETAILS_MODAL_GITHUB_COMMITS_VIEW,
                          ) ? (
                            <a
                              href={`https://github.com/${item?.commitUserName}/${commonSl?.value}/commit/${item?.commitId}`}
                              style={{ cursor: "pointer" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {extractFirstTenCharacters(item?.commitId)}
                            </a>
                          ) : (
                            extractFirstTenCharacters(item?.commitId)
                          )}
                        </td>

                        <td>{formatDate(item?.commitDate)}</td>
                        <td>{commonSl?.value}</td>
                        <td>{item?.branchName}</td>
                        <td className="text-left">
                          <div className="usr-blk">
                            <span className="usr">
                              {item?.commitUserAvatar ? (
                                <img
                                  className="name-ltr"
                                  src={item?.commitUserAvatar}
                                  alt="profile pic"
                                />
                              ) : (
                                getShortName(item?.commitUserName)
                              )}
                            </span>
                            {item?.commitUserName}
                          </div>
                        </td>
                        <td
                          className="dropdown-container updatedFilesDDlist"
                          onClick={() => toggleDropdown(item?.commitId)}
                        >
                          <div className="dropdown-trigger">
                            {item?.filesCount} Files
                          </div>
                          <Dropdown
                            onToggle={() => toggleDropdown(null)}
                            show={dropdownStates === item?.commitId}
                            style={{ position: "absolute" }}
                            drop="up"
                          >
                            <Dropdown.Toggle
                              as="div"
                              id={`dropdown-${index}`}
                            />
                            <Dropdown.Menu className="updatedFilesDD">
                              <p className="updTitle">Updated Files</p>
                              <div className="updatedFiles custom-scroll">
                                {item?.fileChanges?.map(
                                  (fileChange, fileIndex) => (
                                    <Dropdown.Item
                                      key={fileIndex}
                                      className="listItem"
                                      onClick={() => {
                                        if (
                                          hasPermission(
                                            permissionConstants.permissions
                                              .TASK_DETAILS_MODAL_GITHUB_COMMITS_CHANGED_FILES,
                                          )
                                        ) {
                                          handleOpenModal(fileChange);
                                        }
                                      }}
                                    >
                                      <span className="fileName">
                                        {getLastPortionOfFilePath(
                                          fileChange?.filename,
                                        )}
                                      </span>
                                      <span
                                        className={`count ${
                                          fileChange.additions > 0
                                            ? "added"
                                            : "unchanged"
                                        }`}
                                      >
                                        {fileChange.additions > 0
                                          ? `+ ${fileChange.additions}`
                                          : fileChange.additions}
                                      </span>
                                      <span
                                        className={`count ${
                                          fileChange.deletions > 0
                                            ? "removed"
                                            : "unchanged"
                                        }`}
                                      >
                                        {fileChange.deletions > 0
                                          ? `- ${fileChange.deletions}`
                                          : fileChange.deletions}
                                      </span>
                                    </Dropdown.Item>
                                  ),
                                )}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          ) : (
            <UserNoData />
          )}
        </div>
      </div>
      <CustomModal
        className="modal fade github-variation-modal"
        dialgName="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        show={isModalOpen}
        header={renderHeader()}
        bodyClassname="custom-scroll"
        closeModal={() => setIsModalOpen(false)}
        body={renderBody()}
        createModal={7}
        disableCenter={7}
      />
    </div>
  );
};
export default Commits;

Commits.propTypes = {
  commonSl: PropTypes.string,
  cmtCount: PropTypes.func,
  tablesearchValue: PropTypes.string,
  hasPermission: PropTypes.func,
};
