import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { alertActions } from "../../alert/index";
import { gitHubAction } from "..";
import { createBranch } from "../../../../services/gitHubService";

export const postCreateBranch = createAsyncThunk(
  "github/accessToken",
  async (
    { branchName, repoName, baseBranch, owner, projectId, ticket },
    { dispatch },
  ) => {
    dispatch(alertActions.clear());
    try {
      console.log("ticket", ticket);
      const { data } = await createBranch(
        branchName,
        repoName,
        baseBranch,
        owner,
        projectId,
        ticket,
      );
      dispatch(gitHubAction.createGitBranch(data));
      dispatch(alertActions.success(data.branches));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
