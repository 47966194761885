import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import "../../../design/custom/pages/reports.scss";
import SubHeader from "../../../structure/SubHeader/SubHeader";
import LanguageContext from "../../../context/LanguageContext";
import Counters from "./counter";
import TicketTypeProject from "../../../assets/images/project-icon.svg";
import TicketTypeTasks from "../../../assets/images/open-tasks-icon.svg";
import TicketTypeHoursCompleted from "../../../assets/images/hours-completed-icon.svg";
import TicketTypeProductive from "../../../assets/images/productive-icon.svg";
import TicketTypeQuality from "../../../assets/images/quality-icon.svg";
import TicketTypePerformance from "../../../assets/images/performance-icon.svg";
import { useThunk } from "../../../hooks/useThunk";
import { getReports } from "../../../store/slices/report/thunks/getReport";
import { reportActions } from "../../../store/slices/report";
import Performancegraph from "../../../components/DashBoard/Charts/PerformanceGraph";
import QualityIndex from "../../../components/DashBoard/Charts/QualityIndex";
import { getReportDashboard } from "../../../store/slices/report/thunks/getReportDashboard";
import TaskCard from "../../../components/DashBoard/Tasks";
import { getReportDashboardPerformanceChart } from "../../../store/slices/report/thunks/getReportDashboardPerformanceChart";
import { getReportDashboardQualityChart } from "../../../store/slices/report/thunks/getReportQuality";
import { getReportDashboardTaskChart } from "../../../store/slices/report/thunks/getReportTask";
import { useFilter } from "../../../context/useFilter";
import Projectgraph from "../../../components/DashBoard/Charts/projectGraph";

function Dashboard() {
  const { filter } = useFilter();
  const dispatch = useDispatch();
  const [report, isLoading] = useThunk(getReports);
  const [activity] = useThunk(getReportDashboard);
  const [performance, performanceLoading] = useThunk(
    getReportDashboardPerformanceChart,
  );
  const [quality, qualityLoading] = useThunk(getReportDashboardQualityChart);
  const [tasks, taskLoading] = useThunk(getReportDashboardTaskChart);
  const otherUser = filter?.users;
  const { authData } = useSelector((state) => state.auth);
  const userId = authData?._id;

  const loginUser = otherUser || userId;

  const {
    reportData,
    reportDashboardData,
    reportDashboardPerformanceChartData,
    reportDashboardQualityChartData,
    reportDashboardTaskData,
  } = useSelector((state) => state.report);
  const { ticketStatusData } = useSelector((state) => state.common);

  const convertColorCodeToStatusName = (colorCode) => {
    switch (colorCode) {
      case "IN PROGRESS":
        return "inprgs";
      case "TODO":
        return "todo";
      case "READY FOR QA":
        return "redy-qa";
      case "COMPLETE":
        return "completed";
      default:
        return "todo";
    }
  };
  const queuedTasks = reportDashboardData?.map((e) => {
    const matchingStatus = ticketStatusData?.find(
      (status) => status._id === e?.ticketStatus,
    );
    const sprint = e.Sprints;
    const estimate = e.estimationHours;
    const title = e.ticketName;
    const ticketStatusColor = matchingStatus
      ? convertColorCodeToStatusName(matchingStatus.title)
      : "status not available";
    return {
      ...e,
      dueDateFormatted: e.dueDate ? moment(e.dueDate).format("MMMM D") : "",
      createdDateFormatted: e.createdAt
        ? moment(e.createdAt).format("MMMM D")
        : "",
      ticketStatusColor,
      sprint,
      estimate,
      title,
      ticketStatusName: matchingStatus
        ? matchingStatus.title
        : "Status Not Found",
    };
  });
  const {
    languageData: { activityPage },
  } = useContext(LanguageContext);
  console.log("activityPage", activityPage);
  const chartPerformIndexData = reportDashboardPerformanceChartData?.map(
    (e) => e.PerformanceIndex,
  );
  const chartPerformIndexDates = reportDashboardPerformanceChartData?.map(
    (e) => e.label,
  );

  const chartQualityIndexData = reportDashboardQualityChartData?.map(
    (e) => e.QualityIndex,
  );
  const chartQualityIndexDates = reportDashboardQualityChartData?.map(
    (e) => e.label,
  );

  // const userId = "64d9c2c406bcd3670836b8cf";
  const [performanceDate, setPerformanceDate] = useState({
    start: "",
    end: "",
  });
  const handlePerformanceDateChange = (newPerformanceDate) => {
    setPerformanceDate(newPerformanceDate);
  };
  const start =
    performanceDate.start && performanceDate.start.format("YYYY-MM-DD");
  const end = performanceDate.end && performanceDate.end.format("YYYY-MM-DD");
  const params = {
    start,
    end,
    loginUser,
  };
  const chartParams = {
    startDate: start,
    endDate: end,
    id: loginUser,
  };
  useEffect(() => {
    if (loginUser && performanceDate?.start && performanceDate?.end)
      report(params);
    return () => {
      dispatch(reportActions.clearReportData(reportData));
    };
  }, [performanceDate?.start, performanceDate?.end, loginUser]);
  useEffect(() => {
    if (loginUser && performanceDate?.start && performanceDate?.end)
      activity(params);
  }, [performanceDate?.start, performanceDate?.end, loginUser]);
  useEffect(() => {
    if (loginUser && performanceDate?.start && performanceDate?.end)
      performance(chartParams);
  }, [performanceDate?.start, performanceDate?.end, loginUser]);
  useEffect(() => {
    if (loginUser && performanceDate?.start && performanceDate?.end)
      quality(chartParams);
  }, [performanceDate?.start, performanceDate?.end, loginUser]);
  useEffect(() => {
    if (loginUser && performanceDate?.start && performanceDate?.end)
      tasks(chartParams);
  }, [performanceDate?.start, performanceDate?.end, loginUser]);
  const counterImageMap = [
    TicketTypeHoursCompleted,
    TicketTypeProject,
    TicketTypeTasks,

    TicketTypeProductive,
    TicketTypeQuality,
    TicketTypePerformance,
  ];
  let cardDetails = {};
  if (reportData && reportData.reports)
    cardDetails = {
      "Total Productive Hours": reportData?.reports[0]?.totalProductiveHour,
      "No of Projects Allocated":
        reportData?.reports[0]?.totalAllocatedProjects,
      "Total Tickets": reportData?.reports[0]?.totalTickets,
      "Days Working in Month": reportData?.reports[0]?.workingDaysInMonth,
      "No of Leaves": reportData?.reports[0]?.leavesTakenInMonth,
    };

  // counter data and its image
  const cardDetailsArray = Object.entries(cardDetails).map(
    ([title, value]) => ({ title, value }),
  );

  const CounterData = cardDetailsArray.map((counter, index) => ({
    ...counter,
    icon: counterImageMap[index], // Assuming counterImageMap is an array.
  }));

  const chartTaskIndexData = reportDashboardTaskData?.map(
    (e) => e.TaskCompletion,
  );
  const chartTaskIndexDates = reportDashboardTaskData?.map((e) => e.label);

  const aggregatedData = {};

  // Iterate through chartTaskIndexData
  chartTaskIndexData.forEach((innerArray) => {
    // Iterate through the inner array for a specific date
    innerArray.forEach((entry) => {
      const { shortCode, count } = entry;
      if (!aggregatedData[shortCode]) {
        aggregatedData[shortCode] = [];
      }
      aggregatedData[shortCode].push(count);
    });
  });

  // Convert the aggregatedData object into the seriesData array
  const seriesData = Object.entries(aggregatedData).map(([name, data]) => ({
    name,
    data,
  }));

  return (
    <>
      <SubHeader
        showDateSelect
        showUsers
        onPerformanceDateChange={handlePerformanceDateChange}
      />

      <div className="container-fluid pt-2">
        {/* Counter */}
        <Counters data={CounterData} isLoading={isLoading} />

        {/* </div> */}
        <div className="row cutom-row ">
          <div className="col-md-6">
            {/* line chart */}

            <Projectgraph
              data={seriesData}
              label={chartTaskIndexDates}
              loading={taskLoading}
            />
          </div>
          <div className="col-md-6">
            {/* line chart */}

            <Performancegraph
              data={chartPerformIndexData}
              label={chartPerformIndexDates}
              loading={performanceLoading}
            />
          </div>
        </div>

        <div className="row cutom-row">
          <div className="col-md-6">
            {/* bar chart */}

            <QualityIndex
              data={chartQualityIndexData}
              label={chartQualityIndexDates}
              loading={qualityLoading}
            />
          </div>
          <div className="col-md-6">
            {/* tasks details */}
            <TaskCard
              title={activityPage?.activityLog}
              tasks={queuedTasks}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
