import { createSlice } from "@reduxjs/toolkit";

export const name = "trash";

const createReducers = () => {
  const setErrorMesage = (state, action) => {
    state.trashData = null;
    state.errorMessage = action.payload;
    state.successMessage = "";
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const setTrashData = (state, { payload }) => {
    state.trashData = payload.data;
    state.successMessage = payload.successMessage;
  };
  return { setTrashData, setErrorMesage, resetErrorMesage };
};

function createInitialState() {
  return {
    trashData: null,
    errorMessage: "",
    successMessage: "",
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const trashActions = slice.actions;
export const trashReducer = slice.reducer;
