import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import designService from "../../../../../services/designServices";
import { alertActions } from "../../../alert";

export const updateDesign = createAsyncThunk(
  "update-design",
  async ({ id, data }, { dispatch }) => {
    dispatch(alertActions.clear());

    try {
      const {
        data: { message: successMessage },
      } = await designService.updateDesignList({
        id,
        data,
      });
      // console.log("successMessage", successMessage);
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
