import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import EmojiPicker from "emoji-picker-react";
import { getFirstLetters } from "../../utils/utils";
import {
  AllEmojiShow,
  CommandSend,
  ExportMediaEmoji,
  ImageUploadEmoji,
  TagUserEmoji,
} from "../../pages/UserProfile/svgIcons";
import PdfImage from "../../assets/images/pdf.svg";
import xlsImage from "../../assets/images/xls.svg";
import DocImage from "../../assets/images/doc.svg";
import PptImage from "../../assets/images/ppt.svg";
import deleteIcon from "../../assets/images/delete_icon.svg";
import RemoveImage from "../../assets/images/remove-red.svg";
import UserMention from "./userMention";
import { useThunk } from "../../hooks/useThunk";
import { patchFeed } from "../../store/slices/feed/thunks/patchFeed";
import { getMultyUserProfile } from "../../store";
import { Download } from "../../pages/TaskModal/svgicons";
import { findUserWithVariations, replaceUserMentions } from "./Common";
import { Deletemodal } from "../DropDowns/DeleteModal";
import permissionConstants from "../../utils/permissionUtils/permissionConstants";

const Comment = ({
  feedId,
  commentData,
  authData,
  setShowComment,
  MoreBtnClick,
  numCommentsToShow,
  hasPermission,
  commentLength,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [mediaUploads, setMediaUploads] = useState([]);
  const [showRemove, setShowRemove] = useState(false);
  const [atPressed, setAtPressed] = useState(false);
  const [message, setMessage] = useState("");
  const [atText, setAtText] = useState("");
  const { allUsers } = useSelector((state) => state.common);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const messagesRef = useRef(null);
  const [feedPatch, isPatch] = useThunk(patchFeed);
  const [image, setImage] = useState([]);
  const [deleteModal, setDeleteModal] = useState("");
  const [getMultyUsers] = useThunk(getMultyUserProfile);
  const dispatch = useDispatch();
  const getFileIcon = (extension) => {
    const iconMappings = {
      pdf: PdfImage,
      doc: DocImage,
      docx: DocImage,
      xls: xlsImage,
      xlsx: xlsImage,
      ppt: PptImage,
      pptx: PptImage,
    };
    return iconMappings[extension];
  };
  const fileTypeIcons = {
    pdf: PdfImage,
    doc: DocImage,
    docx: DocImage,
    xls: xlsImage,
    xlsx: xlsImage,
    ppt: PptImage,
    pptx: PptImage,
  };

  const onClickAllEmogy = (e) => {
    setShowEmojiPicker((prevEmojiPickerId) =>
      prevEmojiPickerId ? null : feedId,
    );
    e.stopPropagation();
  };
  const handleFileDownload = (file) => {
    setDownloadingFile(file);

    setTimeout(() => {
      setDownloadingFile(false);
    }, 2000);
  };
  const handleImageUpload = (e) => {
    const fileArray = Array.from(e.target.files);
    const selectedImages = fileArray.filter((file) =>
      file.type?.startsWith("image/"),
    );
    if (selectedImages) {
      const imageUrls = selectedImages.map((image) => image);
      setImage([...image, ...imageUrls]);
    }
    e.target.value = null;
  };

  const handleFileMouseEnter = (mediaindex) => {
    setShowRemove(mediaindex);
  };
  const toggleEmojiPicker = (emojiObject, e) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    e.stopPropagation();
  };
  const handleFileMouseLeave = () => {
    setShowRemove(false);
  };
  const handleFileRemoveClick = (index, type) => {
    if (type === "file") {
      const value = mediaUploads.filter((e, fileIndex) => fileIndex !== index);
      setMediaUploads(value);
    } else {
      const value = image.filter((e, imageIndex) => imageIndex !== index);
      setImage(value);
    }
  };
  function handleMediaExport(event) {
    const selectedFiles = event.target.files;
    if (selectedFiles || selectedFiles.length > 0) {
      setMediaUploads((prevMediaUploads) => [
        ...prevMediaUploads,
        ...Array.from(selectedFiles),
      ]);
    }
    // event.target.value = null;
  }

  const handleCommentSend = () => {
    if (image.length > 0 || message.trim() !== "" || mediaUploads.length > 0) {
      const formData = new FormData();

      formData.append("type", "comment");
      const usernamePattern = /@(\w+(\s\w+){0,2})/g;
      const mentionedUserIds = [];
      let modifiedMessage = message;
      const matches = message.match(usernamePattern);
      if (matches) {
        // eslint-disable-next-line no-restricted-syntax
        for (const match of matches) {
          const mentionedUsername = match.substr(1);

          const mentionedUser = findUserWithVariations(
            mentionedUsername,
            allUsers,
          );

          if (mentionedUser) {
            mentionedUserIds.push(mentionedUser._id);

            modifiedMessage = modifiedMessage.replace(
              new RegExp(`@${mentionedUser?.employeeName}`, "g"),
              `{{${mentionedUser._id}}}`,
            );
          }
        }
      }

      formData.append("message", modifiedMessage);

      mediaUploads.forEach((file) => {
        formData.append("attachment", file);
      });
      image.forEach((file) => {
        formData.append("attachment", file);
      });
      formData.append("feedId", feedId);
      feedPatch({ formData, isEdit: false, userId: authData?._id });

      setMessage("");
      setShowEmojiPicker(false);
      setMediaUploads([]);
      setImage([]);
    }

    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };
  const deleteComment = (id) => {
    const formData = new FormData();
    formData.append("type", "deleteComment");
    formData.append("id", id);
    formData.append("feedId", feedId);
    feedPatch({ formData, isEdit: false, userId: authData?._id });
  };

  useEffect(() => {
    if (atPressed) {
      const lastIndex = message.lastIndexOf("@");
      if (lastIndex >= 0) {
        const textAfterAt = message.slice(lastIndex + 1);
        if (textAfterAt.includes(" ")) {
          setAtText("");
        } else {
          setAtText(textAfterAt);
        }
      }
    }
  }, [message, atPressed]);

  return (
    <>
      <div
        className="editor-field-wrp"
        ref={messagesRef}
        onClick={(e) => {
          setShowComment(feedId);
          e.stopPropagation();
        }}
      >
        {authData?.photo ? (
          <div className="user-pic">
            <img
              src={`https://erptest.spericorn.com/repo/uploads/${authData?.photo}`}
              alt="profile pic"
            />
          </div>
        ) : (
          <div className="user-pic">
            <div className="avathar-sm avathar-01">
              <span>{getFirstLetters(authData?.employeeName)}</span>
            </div>
          </div>
        )}

        <div className="editor-field">
          <div className="editinput-wrp">
            {hasPermission(permissionConstants.permissions.FEED_SAVE_POSTS) && (
              <textarea
                className="editor-input"
                placeholder="Comment"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onClick={() => setShowEmojiPicker(false)}
                onKeyDown={(e) => {
                  if (e.key === "@") {
                    const isAtStartOfWordOrSentence =
                      message.charAt(e.target.selectionStart - 1).match(/\s/) ||
                      e.target.selectionStart === 0;

                    if (isAtStartOfWordOrSentence) {
                      setAtPressed(true);
                      setAtText("");
                    } else {
                      setAtPressed(false);
                    }
                  } else if (atPressed && e.key === " ") {
                    setAtPressed(false);
                  } else if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleCommentSend();
                  } else if (e.key === "Backspace") {
                    const caretPosition = e.target.selectionStart;
                    const deletedChar = message.charAt(caretPosition - 1);
                    if (deletedChar === "@") {
                      setAtPressed(false);
                    }
                  }
                }}
              />
            )}
            <UserMention
              dropDownVisibility={atPressed}
              onAllocateUser={(userId) => {
                const user = allUsers.find((e) => e._id === userId?.user);
                if (user && atText) {
                  const updatedMessage = message.replace(
                    `@${atText}`,
                    `@${user?.employeeName}`,
                  );
                  setMessage(updatedMessage);
                  setAtText("");
                } else {
                  setMessage((prevMessage) => {
                    const lastIndex = prevMessage.lastIndexOf("@");
                    if (lastIndex !== -1) {
                      const messageAfterLastAt = prevMessage.substring(
                        lastIndex + 1,
                      );
                      if (!messageAfterLastAt.includes(" ")) {
                        const updatedMessage = `${prevMessage.substring(
                          0,
                          lastIndex,
                        )}@${user?.employeeName} `;
                        return updatedMessage;
                      }
                    }

                    return `${prevMessage}@${user.employeeName} ${" "}`;
                  });
                  setAtText("");
                }
                setAtPressed(false);
              }}
              atText={atText}
            />
            <div className="cta-wrp">
              <button className="attach" type="button">
                <ExportMediaEmoji />

                <input
                  type="file"
                  className=" hiddenUpload"
                  id="image-upload"
                  accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleMediaExport}
                  multiple
                />
              </button>
              <button
                className="attach"
                type="button"
                onClick={(e) => {
                  setAtPressed(!atPressed);
                  setShowEmojiPicker(false);
                  setAtText("");
                  e.stopPropagation();
                }}
              >
                <TagUserEmoji />
              </button>
              <div className="expression-blk">
                <button
                  className="emoji"
                  type="button"
                  onClick={(e) => {
                    onClickAllEmogy(e);
                  }}
                >
                  <AllEmojiShow />
                </button>
                <div className="emo-set">
                  {showEmojiPicker === feedId && (
                    <EmojiPicker
                      lazyLoadEmojis
                      onEmojiClick={toggleEmojiPicker}
                      native
                      autoFocus
                      width={800}
                    />
                  )}
                </div>
              </div>
              <button className="photos" type="button">
                <input
                  type="file"
                  id="image-upload"
                  className=" hiddenUpload"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(e)}
                />
                <ImageUploadEmoji />
              </button>
              <button
                className="send-msg"
                type="button"
                onClick={handleCommentSend}
              >
                {isPatch ? (
                  <span className="btn-loader">
                    <span className="spinner-border spinner-border-sm" />
                  </span>
                ) : (
                  (message || image) && <CommandSend />
                )}
              </button>
            </div>
          </div>
          <ul className="editor-uploads">
            {mediaUploads &&
              mediaUploads?.map((media, mediaindex) => (
                <li
                  onMouseEnter={() => handleFileMouseEnter(mediaindex)}
                  onMouseLeave={() => handleFileMouseLeave(mediaindex)}
                >
                  <img
                    src={
                      fileTypeIcons[
                        media?.name.split(".").pop().toLowerCase()
                      ] || PdfImage
                    }
                    alt="documents"
                  />
                  {showRemove === mediaindex && (
                    <span className="remove-icon">
                      <img
                        src={RemoveImage}
                        alt=""
                        onClick={() =>
                          handleFileRemoveClick(mediaindex, "file")
                        }
                      />
                    </span>
                  )}
                  <span className="file-name">{media?.name}</span>
                </li>
              ))}
            {image.length > 0 &&
              image?.map((file, index) => (
                <li key={index}>
                  <span className="remove-icon">
                    <img
                      src={RemoveImage}
                      alt=""
                      onClick={() => handleFileRemoveClick(index, "image")}
                    />
                  </span>
                  <img src={URL.createObjectURL(file)} alt="" />
                </li>
              ))}
          </ul>
        </div>
      </div>
      {commentData?.map((comment) => (
        <div className="editor-field-wrp">
          {allUsers.find((user) => user._id === comment.user)?.photo ? (
            <div className="user-pic">
              <img
                src={`https://erptest.spericorn.com/repo/uploads/${
                  allUsers.find((user) => user._id === comment.user)?.photo
                }`}
                alt="profile pic"
              />
            </div>
          ) : (
            <div className="user-pic">
              <div className="avathar-sm avathar-01">
                <span>
                  {getFirstLetters(
                    allUsers.find((user) => user._id === comment.user)
                      ?.employeeName,
                  )}
                </span>
              </div>
            </div>
          )}
          <div className="comment-field">
            <div className="user-title">
              {allUsers.find((user) => user._id === comment.user)?.employeeName}
            </div>
            <div className="attached-items-wrp">
              {replaceUserMentions(
                comment?.text,
                allUsers,
                getMultyUsers,
                dispatch,
              )}
              {comment.attachment.length > 0 &&
                comment.attachment.map((each) => (
                  <div
                    className={`image-attached ${
                      ["jpg", "pdf", "jpeg"].includes(each.name.split(".")[1])
                        ? "attachment-comment"
                        : ""
                    } ${
                      !["jpg", "png", "jpeg"].includes(each.name.split(".")[1])
                        ? "pdf"
                        : ""
                    }`}
                  >
                    <div className="attached-items">
                      <Link
                        to={each.path}
                        className="btn download-attachments"
                        download
                        onClick={(file) => {
                          setShowComment(feedId);
                          file.stopPropagation();
                          handleFileDownload(each);
                        }}
                      >
                        {downloadingFile === each ? (
                          <span className="btn-loader m-0">
                            <span className="spinner-border spinner-border-sm m-0" />
                          </span>
                        ) : (
                          <Download />
                        )}
                      </Link>
                      {["jpg", "png", "jpeg"].includes(
                        each.name.split(".")[1],
                      ) ? (
                        <img src={each.path} alt={each?.name} />
                      ) : (
                        <img
                          src={getFileIcon(each?.name.split(".")[1])}
                          alt={each?.name}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {comment.user === authData?._id && (
              <div className="btn-remove-otr">
                <button
                  className="btn btn-remove"
                  type="button"
                  onClick={(each) => {
                    each.stopPropagation();
                    setShowComment(feedId);
                    setDeleteModal({
                      id: comment._id,
                      name: replaceUserMentions(
                        comment?.text,
                        allUsers,
                        getMultyUsers,
                        dispatch,
                      ),
                      openclose: true,
                    });
                  }}
                >
                  <img src={deleteIcon} alt="" style={{ height: 13 }} />
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      {commentLength - numCommentsToShow >= 1 && (
        <span
          onClick={(event) => {
            event.stopPropagation();
            MoreBtnClick();
          }}
        >
          +{" "}
          {commentLength - numCommentsToShow >= 1 &&
            commentLength - numCommentsToShow}
          more btn
        </span>
      )}
      <Deletemodal
        ModalShow={deleteModal}
        closeModal={() => setDeleteModal("")}
        cancelModal={() => setDeleteModal("")}
        deleteFunc={(id) => deleteComment(id)}
      />
    </>
  );
};
Comment.propTypes = {
  feedId: PropTypes.string,
  commentData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      user: PropTypes.string,
      text: PropTypes.string,
      attachments: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  authData: PropTypes.shape({
    _id: PropTypes.string,
    employeeName: PropTypes.string,
    photo: PropTypes.string,
  }),
  setShowComment: PropTypes.func,
  MoreBtnClick: PropTypes.func,
  numCommentsToShow: PropTypes.number,
  commentLength: PropTypes.number,
  hasPermission: PropTypes.func,
};

export default Comment;
