import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchTicketAllocationData } from "../../../../services/ticketAllocationService";
import { alertActions } from "../../alert";
import {
  setGetState,
  setPatchedTicketAllocationData,
  // setPatchedTicketAllocationData,
  setSuccess,
  setTicketAllocationDataById,
} from "..";
import { getTicket } from "./getTicket";
import { getTicketsById } from "./getTicketsById";

export const patchTicketAllocation = createAsyncThunk(
  "patch/ticket",
  async ({ id, patchData, ticketId, listId, needCount }, { dispatch }) => {
    try {
      let data;
      let successMessage;
      if (Array.isArray(id)) {
        data = await Promise.all(
          id.map(async (i, index) => {
            if (Array.isArray(patchData)) {
              const { data } = await patchTicketAllocationData(
                i,
                patchData[index],
              );
              return data.data;
            }
            const { data } = await patchTicketAllocationData(i, patchData);
            return data.data;
          }),
        );
        successMessage = "Allocation Updated Successfully";
        dispatch(setGetState(false));

        dispatch(
          setSuccess({ key: "patch", success: data, payload: patchData }),
        );
      } else {
        const { data: responseData } = await patchTicketAllocationData(
          id,
          patchData,
          needCount,
        );
        data = responseData.data;
        successMessage = responseData.message;
        dispatch(setGetState(false));

        dispatch(
          setSuccess({ key: "patch", success: data, payload: patchData }),
        );
      }
      dispatch(alertActions.success(successMessage));

      if (listId) {
        dispatch(getTicket(listId));
      }
      if (!ticketId) dispatch(setTicketAllocationDataById(data));
      dispatch(setPatchedTicketAllocationData(data));
      if (ticketId) {
        dispatch(
          getTicketsById({
            params: ticketId,
            listId: { listId },
          }),
        );
      }
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
