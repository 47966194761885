import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { withTitle } from "../../../../hoc/withTitle";
import { useThunk } from "../../../../hooks/useThunk";
import {
  getEmailAccountList,
  deleteEmailAccount,
  updateEmailAccount,
} from "../../../../store";
import { useFilter } from "../../../../context/useFilter";
import { Deletemodal } from "../../../../components/DropDowns/DeleteModal";

import "../../../../design/custom/pages/email-account.scss";
import AddIcon from "../../../../assets/images/icon-plus.svg";

import SubHeader from "../../../../structure/SubHeader/SubHeader";
import AddModalEmail from "./AddModalEmail";
import EmailTable from "./EmailTable";

import {
  modulePermissionHasPermissions,
  subModulePermissions,
} from "../../../../utils/permissionUtils";
import permissionConstants from "../../../../utils/permissionUtils/permissionConstants";

const EmailAccount = () => {
  const { filter } = useFilter();

  // state
  const [addshowModal, setAddShowModal] = useState(false);
  const [increment, setIncrement] = useState(15);
  const [deleteModal, setDeleteModal] = useState({
    openclose: false,
    name: "",
    id: "",
  });

  // action
  const [EmailAccountList, isLoading] = useThunk(getEmailAccountList);
  const [DeleteEmailAccount, isLoadingDeleteEmail] =
    useThunk(deleteEmailAccount);
  const [UpdateEmailAccount] = useThunk(updateEmailAccount);

  // reducer
  const { emailAccountList } = useSelector((state) => state.emailAccount);
  const { userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    renderEmailList();
  }, [increment, filter.search]);

  const renderEmailList = () => {
    const params = {
      page: 1,
      limit: increment,
      search: filter?.search,
    };
    EmailAccountList(params);
  };

  const handleIsDefault = (ID, title, data) => {
    const formData = new FormData();
    formData.append("isDefault", data?.isDefault);
    UpdateEmailAccount({ id: ID, data: formData });
  };

  const handleDeleteClick = (id, title) => {
    setDeleteModal({
      openclose: true,
      name: title,
      id,
    });
  };

  const handleEmailAccountDelete = (id) => {
    DeleteEmailAccount(id);
    setDeleteModal({
      openclose: false,
      name: "",
      id: "",
    });
  };

  const hasPermission = (permission) => {
    if (userProfile?.formattedPermissions) {
      const permissions = subModulePermissions(
        userProfile?.formattedPermissions,
        permissionConstants.modules.SETUP,
        permissionConstants.submodules.WHITELABEL_EMAIL_ACCOUNT,
      );
      return modulePermissionHasPermissions(permissions, permission);
    }
    return false;
  };

  return (
    <>
      {" "}
      <SubHeader showSearchBar />
      <div className="page-contents inner-page-contents">
        <div className="container-fluid">
          <div className="email-account-outer">
            <div className="email-account-header">
              <div className="lft-section">
                <h4>{emailAccountList?.total} Accounts Added</h4>
                <h3>Manage Email Accounts</h3>
              </div>
              {hasPermission(
                permissionConstants.permissions.EMAIL_ACCOUNT_ADD,
              ) && (
                <div className="rgt-section">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(row) => {
                      setAddShowModal(row);
                    }}
                  >
                    Add
                    <img className="ms-1" src={AddIcon} alt="addIcon" />
                  </button>
                </div>
              )}
            </div>
            <BottomScrollListener
              onBottom={() =>
                increment < emailAccountList?.total &&
                setIncrement(increment + 5)
              }
            >
              {hasPermission(
                permissionConstants.permissions.EMAIL_ACCOUNT_LIST,
              ) && (
                <EmailTable
                  emailData={emailAccountList}
                  edit={(id, type, data) => {
                    setAddShowModal(data);
                  }}
                  isDefault={handleIsDefault}
                  delete={handleDeleteClick}
                  isLoading={isLoading || isLoadingDeleteEmail}
                  hasPermission={hasPermission}
                />
              )}
            </BottomScrollListener>
            {!!addshowModal && (
              <AddModalEmail
                EmailAccountList={EmailAccountList}
                emailData={emailAccountList}
                showModal={addshowModal}
                closeModal={() => {
                  setAddShowModal(false);
                }}
              />
            )}
            {!!deleteModal && (
              <Deletemodal
                ModalShow={deleteModal}
                deleteFunc={handleEmailAccountDelete}
                closeModal={() => {
                  setDeleteModal({
                    openclose: false,
                    name: "",
                    id: "",
                  });
                }}
                cancelModal={() => {
                  setDeleteModal({
                    openclose: false,
                    name: "",
                    id: "",
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTitle(EmailAccount);
