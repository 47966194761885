import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getTicketStatus } from "../../store/slices/ticket/thunks/getTicketStatus";
import { useThunk } from "../../hooks/useThunk";
import useTitleContext from "../../hooks/useTitle";
import "../../design/custom/pages/documentsTable.scss";
import "../../design/custom/pages/createTask.scss";
import TicketCreatePopup from "./TicketPopup";
import { getTicketAllocationsById, getTicketsById } from "../../store";

const TicketManagement = () => {
  const { shortCode, listId, type } = useParams();
  const { setTitle } = useTitleContext();
  const params = useLocation();

  const { projectData } = useSelector((state) => state.project);

  const [showModal, setShowModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState("");
  const [ticket, isTicket] = useState(false);

  const [status] = useThunk(getTicketStatus);
  const [searchParams, setSearchParams] = useSearchParams();
  const [TicketByIdGet] = useThunk(getTicketsById);
  const [TicketAllocationByIdGet] = useThunk(getTicketAllocationsById);

  console.log(searchParams);

  useEffect(() => {
    if (projectData && shortCode) {
      if (listId && type === "sprint") {
        const matchingSprint = projectData
          .map((project) =>
            project?.sprint.find((sprint) => sprint._id === listId),
          )
          .find(Boolean);

        if (matchingSprint && type === "sprint") {
          setTitle(matchingSprint.name?.split("(")[0]);
        }
      } else {
        const projectName = projectData.find(
          (each) => each.shortCode === shortCode,
        );
        if (projectName) setTitle(projectName.name);
      }
    }
  }, [projectData, shortCode, listId]);

  useEffect(() => {
    status();
  }, []);
  useEffect(() => {
    if (selectedTicket) {
      if (ticket) {
        setSearchParams({ TicketId: selectedTicket });
      } else {
        setSearchParams({ Task: selectedTicket });
      }
      setShowModal(true);
    }
  }, [selectedTicket]);

  useEffect(() => {
    // if (
    //   hasSubModulePermission(
    //     userProfile?.formattedPermissions,
    //     permissionConstants.submodules.TASK_DETAILS_MODAL,
    //   )
    // ) {
    const queryString = params.search;
    if (queryString) {
      const paramValue = queryString.startsWith("?TicketId");
      if (paramValue) {
        isTicket(true);
        const id = queryString?.split("=")[1];
        TicketByIdGet({
          params: id,
          listId: { listId, page: 1, limit: 20 },
        });
      } else {
        isTicket(false);
        const id = queryString?.split("=")[1];
        TicketAllocationByIdGet({
          params: id,
          listId: { listId, page: 1, limit: 20 },
        });
      }
      setShowModal(true);
    }
    // }
  }, []);

  return (
    <div>
      <Outlet
        context={[
          selectedTicket,
          setSelectedTicket,
          setShowModal,
          ticket,
          isTicket,
        ]}
      />

      <TicketCreatePopup
        showModal={showModal}
        setShowModal={setShowModal}
        selectedTicket={selectedTicket}
        isTicket={isTicket}
        ticket={ticket}
        setSelectedTicket={setSelectedTicket}
        setSearchParams={setSearchParams}
      />
    </div>
  );
};

export default TicketManagement;
