import { createSlice } from "@reduxjs/toolkit";

export const name = "emailAccount";

const createReducers = () => {
  const setEmailAccountList = (state, { payload }) => {
    state.emailAccountList = payload.data.data;
  };

  const singleEmailAccount = (state, { payload }) => {
    state.singleEmailAccount = payload.data.data;
  };

  return {
    setEmailAccountList,
    singleEmailAccount,
  };
};

function createInitialState() {
  return {
    emailAccountList: [],
    singleEmailAccount: [],
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const emailAccountActions = slice.actions;
export const emailAccountReducer = slice.reducer;
