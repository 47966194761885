import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ticketService from "../../../../services/ticketService";
import { alertActions } from "../../alert";
import { setAddTaskModal, setSuccess } from "..";
import { getUserProjects } from "../../project/thunks/getUserProjects";

export const postTicket = createAsyncThunk(
  "post/ticket",
  async (payload, { dispatch }) => {
    try {
      const {
        data: { message: successMessage, data: successData },
      } = await ticketService.postTicketData(payload);
      dispatch(alertActions.success(successMessage));
      if (payload.id) {
        let localContents = localStorage.getItem("formValues");
        localContents = JSON.parse(localContents).filter(
          (each) => each.id !== payload.id,
        );
        localStorage.setItem("formValues", JSON.stringify(localContents));
      }
      dispatch(
        setSuccess({
          key: "create",
          success: successData?.ticketData[0],
          type: "Ticket",
        }),
      );
      dispatch(getUserProjects({}));
      if (successMessage) dispatch(setAddTaskModal(false));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
