/* eslint-disable */

import imageArrowRight from "../../../../assets/images/arrow-right-02.svg";
import imageArrowLeft from "../../../../assets/images/arrow-left.svg";
import propbase from "../../../../assets/images/pro-propbase.svg";
import { getFirstLetters } from "../../../../utils/utils";
import { getNameAndUniqId } from "../../../../components/common/iconDefaultName";
import { useState } from "react";

const ProjectInfoModal = ({ show, onClose, descriptions }) => {
  const isValidURL = (url) => {
    const pattern = /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*\/?/;
    return pattern.test(url);
  };

  // image error handling
  const [imgError, setimgError] = useState(false);

  const handleOnProjectError = () => {
    setimgError(true);
  };

  console.log("dddddddd", descriptions);

  return (
    <div
      className={`dropdown-menu dropeffect pt-2 pb-2  noAllocation-menu ${
        show ? "show" : ""
      }`}
      aria-labelledby="dropdownMenuButton1"
    >
      <div className="dropdown-item no-hover searcharea d-flex align-items-center no-bg  pb-1">
        <button
          className="title px-0"
          type="button"
          onClick={() => onClose(false)}
        >
          <small className="bk">
            <img src={imageArrowLeft} alt="" />
          </small>
          Back to Project List
        </button>
      </div>
      <div className="divitions pb-1 pt-1">
        <hr className="mt-0 mb-0" />
      </div>

      <div className="dropdown-item d-flex item-custom ">
        {descriptions?.logo && !imgError ? (
          <div className="pro-icon">
            <img
              onError={handleOnProjectError}
              src={`https://erptest.spericorn.com/repo/uploads/${descriptions?.logo}`}
              alt=""
            />
          </div>
        ) : (
          <div
            className={`avathar-sm avathar-0${
              (getNameAndUniqId(descriptions?._id).uniqId % 4) + 1
            }`}
          >
            {getFirstLetters(descriptions?.name)}
          </div>
        )}

        <p>
          {descriptions?.name}
          <br />
          <small>Approved Hours : 324</small>
        </p>
      </div>
      <div className="dropdown-item d-flex flex-wrap content">
        <p>{descriptions?.description}</p>

        {/* <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry’s
        </p> */}
      </div>
      <button
        className="dropdown-item d-flex flex-wrap avathar-otr"
        type="button"
      >
        <div className="pro-icon">
          {isValidURL("dfdfh") ? (
            <img src={MultyUsersData?.photo} alt="" />
          ) : (
            <div className="name userProf bg-7">
              {getFirstLetters("Kevin Goos")}
            </div>
          )}

          {/* <img src="img/profile image.png" alt="" /> */}
        </div>
        <div className="cvr">
          <p>Kevin Goos</p>
          <small>Chatbot</small>
        </div>

        <div className="arw-rgt">
          <img src={imageArrowRight} alt="" />
        </div>
      </button>
    </div>
  );
};

export default ProjectInfoModal;
