import { userProfileActions } from "../../store/slices/userProfile";

export function replaceUserMentions(text, userList, MultyUser, dispatch) {
  const userIdPattern = /{{(\w+)}}/g;

  const parts = text?.split(userIdPattern);

  return parts?.map((userId, index) => {
    if (index % 2 === 1) {
      const mentionedUser = userList?.find((user) => user._id === userId);
      if (mentionedUser) {
        return (
          <span
            key={index}
            type="button"
            onClick={(f) => {
              f.stopPropagation();

              MultyUser(userId);
              dispatch(userProfileActions.setShowUserProfile(true));
            }}
          >
            @{mentionedUser.employeeName}
          </span>
        );
      }
    }
    return userId;
  });
}

export function findUserWithVariations(mentionedUsername, allUsers) {
  const usernames = mentionedUsername?.split(" ");
  let i = 0;

  while (i < usernames.length) {
    let usernameToSearch = usernames[i];

    const mentionedUser = allUsers.find(
      (user) => user.employeeName === usernameToSearch,
    );

    if (mentionedUser) {
      return mentionedUser;
    }

    let j = 1;

    while (j < 3 && i + j < usernames.length) {
      usernameToSearch += ` ${usernames[i + j]}`;

      const mentionedUserWithInitial = allUsers.find(
        // eslint-disable-next-line no-loop-func
        (user) => user.employeeName === usernameToSearch,
      );

      if (mentionedUserWithInitial) {
        return mentionedUserWithInitial;
      }

      j += 1;
    }

    i += 1;
  }

  return null;
}
