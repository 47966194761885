/* eslint-disable no-unused-expressions */
/* eslint-disable no-lonely-if */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unknown-property */
import PropTypes, { object } from "prop-types";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { Deletemodal } from "../DropDowns/DeleteModal";
import Button from "../Button/index";

// import tool1 from "../../assets/images/tool-1.svg";
// import tool2 from "../../assets/images/tool-2.svg";
// import tool3 from "../../assets/images/tool-3.svg";
// import tool5 from "../../assets/images/tool-5.svg";
// import tool6 from "../../assets/images/tool-6.svg";
// import tool7 from "../../assets/images/tool-7.svg";
// import tool8 from "../../assets/images/tool-8.svg";
// import tool9 from "../../assets/images/tool-9.svg";
// import tool10 from "../../assets/images/tool-10.svg";
// import tool11 from "../../assets/images/tool-11.svg";
// import tool13 from "../../assets/images/tool-13.svg";
// import tool14 from "../../assets/images/tool-14.svg";
// import tool15 from "../../assets/images/tool-15.svg";
// import tool16 from "../../assets/images/tool-16.svg";
// import tool17 from "../../assets/images/tool-17.svg";

// import statusIcon from "../../assets/images/Status_icon.svg";
// import dateIcon from "../../assets/images/date-icon-bulk.svg";
// import priorityIcon from "../../assets/images/Priority_icon.svg";
import deleteIcon from "../../assets/images/delete_icon.svg";
import copyIcon from "../../assets/images/tool-12.svg";
import closeIcon from "../../assets/images/close.svg";
import Profile from "../DropDowns/Profile";
import TaskStatusModal from "../TaskStatusModal";
import { Priority } from "../DropDowns/priority";
// import { Status } from "../DropDowns/Status";
import { useThunk } from "../../hooks/useThunk";

// import { getUser } from "../../store";
import {
  deleteTickets,
  deleteTicketAllocation,
  getTicket,
  getUser,
  patchTicketAllocation,
  patchTicket,
  getTicketAllocations,
} from "../../store";
import { Status } from "../DropDowns/Status";
import {
  CopyToClipboard,
  DateIcon,
  Tool16,
  Tool2,
  Tool3,
  Tool6,
  Tool9,
} from "./components/BulkActionIcons";
import { Opencalender } from "../DropDowns/Calender";

const BulkActions = ({
  selectedTasks = [],
  setSelectedTasks,
  closeAction,
  showStatus = false,
  showDueDate = false,
  showPriority = false,
  showDelete = false,
  showAssignee = false,
  showMoveSubtask = false,
  showCopy = false,
}) => {
  const ref = useRef(null);
  const [showTaskStatusModal, setShowTaskStatusModal] = useState(false);
  const [showDateSelect, setShowDateSelect] = useState(false);
  const [clipboardData, setClipboardData] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const { projectData } = useSelector(({ project }) => project);
  const { ticketStatus } = useSelector(({ ticketReducer }) => ticketReducer);
  const { shortCode, listId } = useParams();
  const buttonRef = useRef(null);
  const [projectByIdThunk] = useThunk(getUser);
  const [patchTickets] = useThunk(patchTicket);
  const [patchTicketAllocations, patchLoading] = useThunk(
    patchTicketAllocation,
  );
  const [deleteTicketAllocations, deleteLoading] = useThunk(
    deleteTicketAllocation,
  );
  const [ticketAllocations] = useThunk(getTicketAllocations);

  const { Success } = useSelector((s) => s.ticketReducer);
  const url = window.location.href;
  // console.log("location", window.location.href);
  const copyDropdown = [
    { label: "Task Name", value: "title" },
    { label: "Task URL", value: "url" },
    { label: "Task ID", value: "_id" },
    { label: "Assignee", value: "assignedUsers" },
    { label: "Status", value: "ticketStatus" },
  ];
  const [state, setstate] = useState({
    deleteModal: { openclose: false, type: "", id: "" },
  });
  const { deleteModal } = state;
  useEffect(() => {
    const project = projectData?.filter((item) => item.shortCode === shortCode);
    if (project && project[0]?._id) {
      projectByIdThunk(project[0]?._id);
    }
  }, [projectData[0]]);

  useEffect(() => {
    const { success, key } = Success;
    if (success?.length > 0 && key !== "delete") {
      setSelectedTasks && setSelectedTasks(success);
    }
  }, [Success?.success]);

  const handleTicketDelete = (id, type) => {
    // const id = selectedTasks.map((e) => e._id);
    // if (type === "Ticket") {
    //   deleteTicket({ id });
    //   ticket({ listId });
    //   closeDeleteModal();
    // }
    if (type === "allocations") {
      deleteTicketAllocations({ id });
      // ticket({ listId });
      ticketAllocations({ params: { listId } });
      closeDeleteModal();
      closeAction();
    }
  };

  const handleDeleteModalClick = () => {
    const id = selectedTasks.map((e) => e._id);
    const type = "allocations";
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: true,
        type,
        name: selectedTasks.map((e) => e.title).join(","),
        id,
      },
    });
  };
  const closeDeleteModal = () => {
    setstate({
      ...state,
      deleteModal: {
        ...deleteModal,
        openclose: false,
        type: "",
        name: "",
        id: "",
      },
    });
  };

  const patchFunction = (type, patchData) => {
    // console.log(type, patchData);
    const ticketStatus = patchData;
    if (type === "Ticket") patchTickets(patchData);
    if (type === "status")
      patchTicketAllocations({
        id: selectedTasks.map((e) => e._id),
        patchData: { ticketStatus },
      });
    if (type === "allocations") patchTicketAllocations(patchData);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowTaskStatusModal(false);
      }
    };
    if (showTaskStatusModal) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showTaskStatusModal]);

  const handleCopyClick = (data) => {
    const jsonString = JSON.stringify(data, null, 2);
    navigator.clipboard.writeText(jsonString).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    });
  };
  // console.log("selectedTasks", selectedTasks);
  // console.log(clipboardData);
  return (
    <>
      {deleteModal?.openclose ? (
        <Deletemodal
          ModalShow={deleteModal}
          closeModal={closeDeleteModal}
          cancelModal={closeDeleteModal}
          deleteFunc={handleTicketDelete}
          type={deleteModal?.type}
        />
      ) : null}
      <div
        className={`action_toolbar d-flex align-items-center justify-content-between ${
          selectedTasks?.length > 0 && "showTool"
        } `}
        onClick={() => setShowTaskStatusModal(false)}
      >
        <div className="left-control">
          <div className="chekbxOtr">
            <label className="custom_radio_btn" for="myCheckbox">
              {" "}
              <span className="checkText">{`${selectedTasks.length} task selected`}</span>
              <input
                type="checkbox"
                name="checkbox"
                checked
                id="myCheckbox"
                // onClick={() => closeAction("close")}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="center-toolbar" ref={ref}>
          {showAssignee && (
            <OverlayTrigger
              placement="bottom"
              container={ref}
              overlay={<Tooltip id="profile">Profile</Tooltip>}
            >
              <button className="tool">
                {/* <img src={tool2} alt="icon" /> */}
                <Profile
                  dropDownVisibility
                  dropDownVisibilityPermissionConstant
                  selectedTasks={selectedTasks}
                  TaskData={selectedTasks}
                  patchFunction={(e, data) => patchFunction(e, data)}
                  dbName="assignedUsers"
                  type="allocations"
                  icon={<Tool2 />}
                  // allocatedUser={(e) => console.log(e)}
                />
              </button>
            </OverlayTrigger>
          )}
          {showStatus && (
            <OverlayTrigger
              placement="bottom"
              container={ref}
              overlay={<Tooltip id="status">Status</Tooltip>}
            >
              <button
                className="tool"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowTaskStatusModal(!showTaskStatusModal);
                }}
              >
                {/* <img src={tool3} alt="icon" /> */}
                <Tool3 />
                {showTaskStatusModal && (
                  <TaskStatusModal
                    dropDownVisibilityPermissionConstant
                    data={ticketStatus?.status}
                    updateTaskStatus={(e) => patchFunction("status", e)}
                    dropDownVisibility
                    TaskData={selectedTasks}
                    dbName="ticketStatus"
                    type="allocations"
                    icon={<Tool3 />}
                  />
                )}
              </button>
            </OverlayTrigger>
          )}
          {showDueDate && (
            <button
              className="tool"
              onClick={() => setShowDateSelect(!showDateSelect)}
            >
              <Opencalender
                dropDownVisibilityPermissionConstant
                selectedTasks={selectedTasks}
                TaskData={selectedTasks}
                dropDownVisibility
                type="allocations"
                patchFunction={(e, data) => patchFunction(e, data)}
                dbName="dueDate"
                icon={<DateIcon />}
                tooltipPosition="bottom"
              />
            </button>
          )}
          {showMoveSubtask && null}
          {/* (
            <OverlayTrigger
              placement="bottom"
              container={ref}
              overlay={<Tooltip id="moveSubtask">Move Subtask</Tooltip>}
            >
              <button className="tool">
                <Tool6 />
              </button>
            </OverlayTrigger>
          ) */}
          {/* } */}
          {showPriority && (
            <button className="tool">
              <Priority
                dropDownVisibilityPermissionConstant
                selectedTasks={selectedTasks}
                dropDownVisibility
                TaskData={selectedTasks}
                patchFunction={(e, data) => patchFunction(e, data)}
                dbName="priority"
                type="allocations"
                icon={<Tool9 />}
                tooltipPosition="bottom"
              />
            </button>
          )}
          {showDelete && (
            <OverlayTrigger
              placement="bottom"
              container={ref}
              overlay={<Tooltip id="delete">Delete</Tooltip>}
            >
              <button class="tool" onClick={() => handleDeleteModalClick()}>
                {/* <img src={tool16} alt="icon" /> */}
                <Tool16 />
              </button>
            </OverlayTrigger>
          )}
        </div>
        <div className="right-control">
          <div className="tool-forms d-flex align-items-center">
            <div className="dropdown custom-drop-menu no-width no-margin bulk-drp">
              {showCopy && (
                <Dropdown bsPrefix="dropdown-toggle">
                  <Dropdown.Toggle
                    bsPrefix="copy-clickboard control"
                    varient=""
                  >
                    <span className="icon">
                      <CopyToClipboard />
                    </span>
                    <span>Copy to clipboard </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    bsPrefix="dropdown-menu dropeffect pt-2 pb-2 drop-align-cstm"
                    onClick={(e) => e.stopPropagation()}
                    varient=""
                  >
                    {copyDropdown?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        bsPrefix="dropdown-item d-flex dropdown-toggle custom_switch"
                        // onClick={(e) => {
                        //   e.stopPropagation();
                        //   // if (clipboardData[item.value]) {
                        //   //   setClipboardData(
                        //   //     clipboardData.filter(
                        //   //       (value) => value !== item.value,
                        //   //     ),
                        //   //   );
                        //   // } else {
                        //   setClipboardData({
                        //     ...clipboardData,
                        //     ...selectedTasks.reduce((acc, e) => {
                        //       if (item.value !== "route") {
                        //         return {
                        //           ...acc,
                        //           [item.value]: acc[item.value]
                        //             ? [...acc[item.value], e[item.value]]
                        //             : [e[item.value]],
                        //         };
                        //       }
                        //       return {
                        //         [item.value]: location,
                        //       };
                        //     }, {}),
                        //   });
                        //   // }
                        // }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setClipboardData((prevClipboardData) => {
                            const updatedClipboardData = {
                              ...prevClipboardData,
                            };

                            if (updatedClipboardData[item.value]) {
                              delete updatedClipboardData[item.value];
                            } else {
                              if (item.value !== "url") {
                                if (item.value === "ticketStatus") {
                                  const a = selectedTasks.map(
                                    (e) => e[item.value],
                                  );
                                  const titles = a.map((id) => {
                                    const foundItem =
                                      ticketStatus?.status?.find(
                                        ({ _id }) => id === _id,
                                      );
                                    return foundItem ? foundItem.title : null;
                                  });

                                  updatedClipboardData[item.value] = titles;
                                } else {
                                  updatedClipboardData[item.value] =
                                    selectedTasks.map((e) => e[item.value]);
                                }
                              } else {
                                updatedClipboardData.url = url;
                              }
                            }
                            return updatedClipboardData;
                          });
                        }}
                      >
                        <span>{item.label}</span>
                        <div className="switch">
                          <input
                            type="checkbox"
                            onChange={() => {}}
                            checked={clipboardData[item.value]}
                          />
                          <span className="slider round" />
                        </div>
                      </Dropdown.Item>
                    ))}

                    <Dropdown.Item
                      bsPrefix="lg-button-otr d-flex text-decoration-none"
                      varient=""
                    >
                      <Button
                        className="lg-button "
                        type="button"
                        onClick={() => handleCopyClick(clipboardData)}
                      >
                        <span>{isCopied ? "Copied" : "Copy to Clipboard"}</span>
                      </Button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            {/* <div className="inputcvr">
            <input
              type="text"
              className="control"
              placeholder="Type ‘/‘ for commands"
            />
          </div> */}
          </div>
        </div>
        {(patchLoading || deleteLoading) && (
          <div className="animate-progress" />
        )}
        <button className="dismiss-btn" onClick={() => closeAction()}>
          <div className="otr">
            <span>
              <img src={closeIcon} alt="" />
            </span>{" "}
            DISMISS
          </div>
        </button>
      </div>
    </>
  );
};

BulkActions.propTypes = {
  selectedTasks: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedTasks: PropTypes.func,
  closeAction: PropTypes.func,
  showStatus: PropTypes.bool,
  showDueDate: PropTypes.bool,
  showPriority: PropTypes.bool,
  showDelete: PropTypes.bool,
  showAssignee: PropTypes.bool,
  showMoveSubtask: PropTypes.bool,
  showCopy: PropTypes.bool,
};

export default BulkActions;
