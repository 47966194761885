/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationActions } from "../index";
import notificationService from "../../../../services/notificationService";
import _ from "lodash";
import { alertActions } from "../../alert";

export const readThunk = createAsyncThunk(
  `update/notification`,
  async (params, { dispatch }) => {
    try {
      const {
        data: { data, message: successMessage },
      } = await notificationService.patchNotificationData(params);
      dispatch(
        notificationActions.setNotificationUpdate({
          data,
          successMessage,
        }),
      );
      dispatch(alertActions.success(successMessage));
    } catch (error) {
      const errorMessage = _.isString(error)
        ? error
        : error.response.data.message;
      dispatch(alertActions.error(errorMessage));
    }
  },
);
