import { createSlice } from "@reduxjs/toolkit";

export const name = "cronjob";

const createReducers = () => {
  const setErrorMesage = (state, { payload }) => {
    state.departmentData = null;
    state.successMessage = "";
    state.errorMessage = payload;
  };
  const resetErrorMesage = (state) => {
    state.errorMessage = "";
  };
  const cronjobListAll = (state, { payload }) => {
    state.successMessage = "";
    state.cronjobData = payload;
    state.errorMessage = "";
  };
  const cronjobById = (state, { payload }) => {
    state.successMessage = "";
    state.cronjobById = payload;
    state.errorMessage = "";
  };
  const cronjobHistory = (state, { payload }) => {
    state.successMessage = "";
    state.cronjobHistory = payload;
    state.errorMessage = "";
  };
  const cronjobActivitylog = (state, { payload }) => {
    state.successMessage = "";
    state.activityLog = payload;
    state.errorMessage = "";
  };

  const postCronjobResult = (state, { payload }) => {
    state.successMessage = payload.message;
    state.errorMessage = "";
  };
  const deleteCronjobResult = (state, { payload }) => {
    state.successMessage = payload.message;
    state.errorMessage = "";
  };
  const updateCronjobResult = (state, { payload }) => {
    state.successMessage = payload.message;
    state.errorMessage = "";
  };
  const triggerCron = (state, { payload }) => {
    state.successMessage = payload.message;
    state.errorMessage = "";
  };
  const commandListReducer = (state, { payload }) => {
    state.commandList = payload.data;
  };
  const resetCronjobReducer = (state) => {
    state.activityLog = {};
    state.cronjobHistory = {};
  };
  return {
    setErrorMesage,
    resetErrorMesage,
    cronjobListAll,
    cronjobById,
    cronjobHistory,
    cronjobActivitylog,
    postCronjobResult,
    deleteCronjobResult,
    updateCronjobResult,
    triggerCron,
    commandListReducer,
    resetCronjobReducer,
  };
};

function createInitialState() {
  return {
    errorMessage: "",
    successMessage: "",
    cronjobData: [],
    cronjobHistory: {},
    activityLog: {},
  };
}

const initialState = createInitialState();
const reducers = createReducers();

const slice = createSlice({
  name,
  initialState,
  reducers,
});

export const cronjobActions = slice.actions;
export const cronjobReducer = slice.reducer;
