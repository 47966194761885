import PropTypes from "prop-types";

const AddListOption = ({ onSubmit, value, handleChange, loading = false }) => {
  return (
    <div className="createSubmenu">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <label className="mb-1">List Name</label>
        <div className="inpWrp">
          <input
            className="form-control"
            value={value}
            onChange={({ target: { value } }) =>
              handleChange("listName", value)
            }
          />

          <button
            type="submit"
            className={`btn btn-submit ${value?.trim()?.length ? "show" : ""}`}
          >
            {!loading ? (
              <svg width="12.319" height="9.186" viewBox="0 0 12.319 9.186">
                <path
                  id="Icon_awesome-check"
                  data-name="Icon awesome-check"
                  d="M4.184,13.583l-4-4a.616.616,0,0,1,0-.871l.871-.871a.616.616,0,0,1,.871,0l2.7,2.7L10.4,4.758a.616.616,0,0,1,.871,0l.871.871a.616.616,0,0,1,0,.871L5.055,13.583A.616.616,0,0,1,4.184,13.583Z"
                  transform="translate(0 -4.577)"
                  fill="currentcolor"
                />
              </svg>
            ) : (
              <span className="btn-loader">
                <span className="spinner-border spinner-border-sm" />
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

AddListOption.propTypes = {
  onSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  loading: PropTypes.bool,
};

export default AddListOption;
